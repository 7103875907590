import { isNullOrUndefined } from "../../../../utils/object.utils";

export const updateFieldsOnBlur = (field, item) => {
  if (isNullOrUndefined(item)) return;
  let change = {};
  let { total, ofCapital, onCapital } = item;
  total = total || 0;
  ofCapital = ofCapital || 0;
  onCapital = onCapital || 0;
  const sumForTotal = (ofCapital !== 0 || onCapital !== 0) ? ofCapital + onCapital : '';

  if (field === 'total') {
    if (ofCapital && total >= ofCapital && !onCapital) {
      change.onCapital = (total - ofCapital) || '';
    } else if (onCapital && total >= onCapital && !ofCapital) {
      change.ofCapital = (total - onCapital) || '';
    } else if (ofCapital && onCapital && (total <= sumForTotal)) {
      change.ofCapital = '';
      change.onCapital = '';
    } else if (!total && (!onCapital || !ofCapital)) {
      change.ofCapital = '';
      change.onCapital = '';
    }
  } else if (field === 'ofCapital' && total) {
    if (onCapital) {
      change.total = sumForTotal;
    } else if (ofCapital) {
      if (ofCapital >= total) {
        change.total = ofCapital;
      } else {
        change.onCapital = total - ofCapital;
      }
    }
  } else if (field === 'onCapital' && total) {
    if (ofCapital) {
      change.total = sumForTotal;
    } else if (onCapital) {
      if (onCapital >= total) {
        change.total = onCapital;
      } else {
        change.ofCapital = total - onCapital;
      }
    }
  } else {
    change.total = sumForTotal;
  }
  for (const [key, total] of Object.entries(change)) {
    if (total < 0) {
      change[key] = 0;
    }
  }
  return change;
}