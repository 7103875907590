import { Select } from 'antd';
import { ArrowDownIcon } from '../../../../assets/icons/common/common-icons';
import { CURRENCY_SYMBOL } from '../../../../common/constants/currency-symbol.const';
import { useRef } from 'react';

export const DeepAnalysisResultCurrencyDropdown = (props) => {
    const { Option } = Select;
    const selectRef = useRef(null);
    return (
        <Select
            value={props.value}
            ref={selectRef}
            className="input_switcher_default_select"
            dropdownClassName='formDisplayerDefaultSelect'
            suffixIcon={<ArrowDownIcon className="select_arrow" />}
            showSearch={true}
            onChange={(value) => {props.onChange(value); selectRef.current.blur()}}
        >
            {
                Object.keys(CURRENCY_SYMBOL).map((key) => {
                    return (<Option key={key} value={key}>{key}</Option>);
                })
            }
        </Select>
    )
};