import React, { useState } from 'react';
import moment from 'moment';
import { displayMoneyValue } from '../../../../../../../common/utils/number-display.utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import { EventRow, ActualEventRowBadgeWrapper, EventRowSection, EventRowIndicationAndDateWrapper, IndicationWrapper, CellText, EventAmount, EventRemarks, EventHoverColumn, HoverColumnRemarksWrapper, HoverColumnActionsWrapper } from '../AssetEventRow.styles';
import { ApproveIcon } from '../../../../../../../assets/icons/common/common-icons';
import { EditEvent } from '../../Actuals/EditEvent';
// import { contributionOnly, distributionOnly } from '../../Actuals/constants/createEvent.events.const';
import { addMonths } from '../../../../../../../common/utils/dates.utils';
import { HoveredRemarks } from '../HoveredRemarks';
import { EditRowIcon } from '../assets/icons';
import { eventTypeGenerator } from '../../Actuals/constants/createEventUtils';

const contributionOnly = eventTypeGenerator(true);
const distributionOnly = eventTypeGenerator(false, true);

export function AssetTransactionRow(props) {
  const { transaction, parentId, isIncognito, itemCurrency, isHoldingsView, holdings, itemCategoryId, isMarked } = props;
  const today = new Date();
  const pointData = addMonths(today,transaction.month); 
  const eventDate = moment(pointData);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const isConnectedFrom = ('' + transaction.associateId) !== '' + parentId;
  const isDistribution = transaction.value * (isConnectedFrom ? -1 : 1) > 0;
  const { confirm } = Modal;
  const [isHoveredOn, setIsHoveredOn] = useState(false);

  const saveChanges = (formValues, eventDate) => {
    setShowEditEventModal(false);

    const updatedTransaction = {
      value: formValues.transactionValue * (isDistribution ? 1 : -1),
      date: eventDate,
      remarks: formValues.eventRemarks,
      id: transaction.id,
      currency: itemCurrency
    }

    props.rowUpdated && props.rowUpdated(updatedTransaction);
  }

  const showDeleteTransactionConfirm = () => {
    confirm({
      title: `Do you want to delete this transaction?`,
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        props.rowDeleted && props.rowDeleted(transaction.id);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  const oldEventType = isDistribution ? 'Distribution' : 'Contribution';

  const formSections = isDistribution ? distributionOnly : contributionOnly;
  const formData = {
    eventTitle: isDistribution ? 'Distribution' : 'Contribution',
    transactionValue: Math.abs(transaction?.value),
    eventRemarks: transaction.remarks ? transaction.remarks : '',
  }

  return (
    <>
      <EventRow key={transaction.id} hasHappened={true} isMarked={isMarked} onClick={() => setShowEditEventModal(true)}
         onMouseEnter={()=>{setIsHoveredOn(true)}} onMouseLeave={()=>{setIsHoveredOn(false)}}>
      <EventRowSection>
      <EventRowIndicationAndDateWrapper>
          <IndicationWrapper></IndicationWrapper>
          <CellText>{eventDate.format('MM-YYYY')}</CellText>
        </EventRowIndicationAndDateWrapper>
        </EventRowSection>
        {/* <EventRowSection>
          <EventRowDateWrapper isHidden={!transaction.isFirstInMonth && !isFirstRow} isCanceled={transaction.isCanceled}>
            <EventRowShortnameMonth>{eventDate.format('MMM')}</EventRowShortnameMonth>
            <EventRowFullYear>{eventDate.format('YYYY')}</EventRowFullYear>
          </EventRowDateWrapper>
        </EventRowSection> */}
        <EventRowSection >
          <CellText>{oldEventType}</CellText>
        </EventRowSection>
        {/* <EventRowSection flex={1}>
          <EventRowSectionMainTxt>
            {displayMoneyValue(Math.abs(transaction.value), transaction.currency || itemCurrency || 'USD', isIncognito, (isHoldingsView && holdings) ? holdings : 100)}
          </EventRowSectionMainTxt>
          <EventRowSectionAltTxt>
            {oldEventType}
          </EventRowSectionAltTxt>
        </EventRowSection> */}

      <EventRowSection >
            <EventAmount>
            {displayMoneyValue(Math.abs(transaction.value), transaction.currency || itemCurrency || 'USD', isIncognito, (isHoldingsView && holdings) ? holdings : 100)}
            </EventAmount>
        </EventRowSection> 

        <EventRowSection></EventRowSection>

        <EventRowSection hiddenOverflow className="hideOnHover">
            <EventRemarks>{transaction.remarks}</EventRemarks>
        </EventRowSection>

        <EventRowSection className="hideOnHover">
          <ActualEventRowBadgeWrapper><ApproveIcon /> Actual</ActualEventRowBadgeWrapper>
        </EventRowSection>

        <EventRowSection hiddenOverflow className="showOnHover">
          <EventHoverColumn>
            <HoverColumnRemarksWrapper>
              {  ( isHoveredOn   )  && <HoveredRemarks remarks={transaction.remarks}></HoveredRemarks> }
            </HoverColumnRemarksWrapper>
            <HoverColumnActionsWrapper>
                <EditRowIcon />
            </HoverColumnActionsWrapper>
          </EventHoverColumn>
        </EventRowSection> 
      </EventRow >
      {showEditEventModal && <EditEvent event={transaction} itemCategoryId={itemCategoryId}
        formData={formData} 
        formSections={formSections}
        oldEventType={oldEventType}
        onSave={({ formValues, eventDate }) => { saveChanges(formValues, eventDate) }}
        onDelete={showDeleteTransactionConfirm}
        onClose={() => { setShowEditEventModal(false) }} />
      }
    </>
  )
}
