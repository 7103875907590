import React, { useRef } from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import { FormFooterButtonsWrapper, FormFooterStepsWrapper, FormStepFooter, StepWrapper, FormFooterStepPoint, WizardButton, StepWrapperOnBoarding, ContinueButtonWrapper } from '../AddItem.styles';
import { ASSET_CATEGORIES_ADD_ITEM_FORMS } from '../../data/add-item-forms/assets-add-item-forms.constant';
import { FormWithStore } from '../../../form-displayer-connector/components/FormWithStore';
import { useFormHook } from '../../../form-displayer-connector/data/useFormHook';
import { LoadingOutlined } from '@ant-design/icons';
import { formatItemEvent } from '../../../../common/utils/mixpanel.utils';
import { ArrowIcon } from '../../../user/components/PaymentRejectedModal/assets/PaymentIcons';
import { PrimaryButtonWithIcon } from '../../../../common/components/buttons/NewButtons.styles';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';

export function AddItemFormStep(props) {
  const { history, isOnBoarding, onAddItem } = props;
  const { mixpanelTrack } = useAnalytics();
  const formName = 'addItemForm';
  const { createItemStore, wealthStore, userStore, filtersStore, uiStore, } = useStore();
  // const formSections = createItemStore.itemType === ADD_ITEM_TYPE.ASSET ? ASSET_CATEGORIES_ADD_ITEM_FORMS['' + createItemStore.categoryId] : LIABILITY_CATEGORIES_ADD_ITEM_FORMS['' + createItemStore.categoryId];
  const formSections =  ASSET_CATEGORIES_ADD_ITEM_FORMS['' + createItemStore.categoryId];
  const addItemForm = useFormHook(formName);
  const defaultValues =  useRef({ beneficiary: userStore.defaultBeneficiary, ...createItemStore.newItemDefaultValues});
  
  const handleNext = async (nextIndex) => {
    const nextSection = addItemForm.sections?.[nextIndex];
    const isSkipped = nextSection?.isHidden || false;
    const sectionValidations = addItemForm.currentSectionErrors;
    if (sectionValidations.isValid) {
      if (addItemForm.currentSectionIndex !== addItemForm.sections.length - 1) {
        if (nextIndex === addItemForm.sections.length - 1) return addItemForm.setStep(nextIndex);
        if (isSkipped) return handleNext(nextIndex + 1);
        addItemForm.setStep(nextIndex);
      } else {
        const asset = await createItemStore.saveItem(addItemForm.valuesForSave, wealthStore, history, isOnBoarding, onAddItem, filtersStore);
        wealthStore.getLastChanges(true);
        mixpanelTrack('Item Created', formatItemEvent(asset));
      }
    } else {
      addItemForm.setErrors(sectionValidations.errors);
      addItemForm.setBottomErrors(sectionValidations.bottomErrors);
    }
  }

  const handleBack = async (prevIndex) => {
    if (prevIndex === 0) return addItemForm.setStep(0);
    const previousSection = addItemForm.sections?.[prevIndex];
    const isSkipped = previousSection?.isHidden || false;
    if (isSkipped) return handleBack(prevIndex - 1);
    addItemForm.setStep(prevIndex);
    // addItemForm.setStep(addItemForm.currentSectionIndex - 1)
  }

  return (<Observer>{() => (
    <>
      {isOnBoarding ? <StepWrapperOnBoarding>
        <FormWithStore fullWidth formName={formName} sections={formSections} defaultValues={defaultValues.current} scrollContainerId={'add-item-modal-form-wrapper'} isStepsDisplay />
      </StepWrapperOnBoarding> : <StepWrapper id="add-item-modal-form-wrapper">
        <FormWithStore formName={formName} sections={formSections} defaultValues={defaultValues.current} scrollContainerId={'add-item-modal-form-wrapper'} isStepsDisplay />
      </StepWrapper>}
      
        
      
      <FormStepFooter>
        {addItemForm && <>
          <FormFooterButtonsWrapper>
            {addItemForm.currentSectionIndex !== 0 && <WizardButton inline onClick={() => {handleBack(addItemForm.currentSectionIndex - 1)}}>Back</WizardButton>}
          </FormFooterButtonsWrapper>
          <FormFooterStepsWrapper>
            {addItemForm.sections.map((section, index) => !section.isHidden ? (
              <FormFooterStepPoint key={index} isSelected={index === addItemForm.currentSectionIndex} />
            ) : null)}
          </FormFooterStepsWrapper>
          <FormFooterButtonsWrapper isNext>
            <PrimaryButtonWithIcon isIconAfterText onClick={() => {handleNext(addItemForm.currentSectionIndex + 1)}}>{addItemForm.currentSectionIndex !== addItemForm.sections.length - 1 ?  <ContinueButtonWrapper>{uiStore.isDesktopView ? 'Continue ' : ''}<ArrowIcon /></ContinueButtonWrapper> : createItemStore.isSavingItem ? <LoadingOutlined /> : 'Done'}</PrimaryButtonWithIcon>
            {/* <WizardButton inline black margin='0px' onClick={handleNext}>{addItemForm.currentSectionIndex !== addItemForm.sections.length - 1 ?  <ContinueButtonWrapper>Continue <ArrowIcon /></ContinueButtonWrapper> : createItemStore.isSavingItem ? <LoadingOutlined /> : 'Done'}</WizardButton> */}
          </FormFooterButtonsWrapper>
        </>}
      </FormStepFooter>
    </>
  )}</Observer>)
}