import { Observer } from 'mobx-react';
import React from 'react';
import { useHistory } from "react-router-dom";
import { ArrowDownIcon, CloseSymmetricIcon, PlusIcon } from '../../../assets/icons/common/common-icons';
import { useStore } from '../../../modules/app/data/root.store';
import { AssetClassIcon } from '../../../modules/assets/components/AssetClassIcon';
import { navigateToUrl } from '../../utils/navigation.utils';
import { displayMoneyValue } from '../../utils/number-display.utils';
import { BaseModal } from '../BaseModal/BaseModal';
import { ArrowIconWRapper, AssetClassSubtitle, AssetTextWrapper, AssetTitle, ButtonIconWrapper, DeleteLiabilityIconWrapper, ItemLogoWrapper, LiabilityIconWrapper, LiabilityLeftSideWrapper, LiabilityRightSideWrapper, LiabilityValueWrapper, LinkLiabilityButton, LinkedLiabilitiesAssetWrapper, LinkedLiabilitiesCloseBtnWrapper, LinkedLiabilitiesHeaderSubTitle, LinkedLiabilitiesHeaderTextWrapper, LinkedLiabilitiesHeaderTitle, LinkedLiabilitiesHeaderWrapper, LinkedLiabilitiesModalFooterWrapper, LinkedLiabilitiesModalWrapper } from './ConnectCollateralModal.styles';

export function ConnectedCollateralsModal({ onClose, showUnlinkAssetConfirm, onOpenConnectCollateralModal }) {
  let history = useHistory();
  const { uiStore, metadataStore, itemStore } = useStore();

  const handleUnlinkAsset = (liabilityId) => {
    onClose?.()
    showUnlinkAssetConfirm?.(liabilityId)
  }

  const handleLinkLiability = () => {
    onClose?.()
    onOpenConnectCollateralModal?.()
  }

  const handleNavigateToConnectedLiability = (liabilityId) => {
    navigateToUrl(history, `/wealth/assets/loans/${liabilityId}`);
    onClose?.();
  }

  const outstandingLoanValue = () => {
    return itemStore.connectedCollaterals?.reduce((acc, col) => acc + (parseFloat(col.usdValue) * (col.holdings / 100)), 0);
  }

  return (<Observer>{() => (
    <BaseModal onBackDropClick={onClose} width={'538px'} height={'auto'} borderRadius={6}>
      <LinkedLiabilitiesHeaderWrapper>
        <LinkedLiabilitiesHeaderTextWrapper>
          <LinkedLiabilitiesHeaderTitle>
            Link a loan against cash value
          </LinkedLiabilitiesHeaderTitle>
          <LinkedLiabilitiesHeaderSubTitle>Outstanding loans: {displayMoneyValue(outstandingLoanValue(), itemStore.item?.currency)}</LinkedLiabilitiesHeaderSubTitle>
          <LinkedLiabilitiesHeaderSubTitle>Available loan balance: {displayMoneyValue((itemStore.item?.value * ((itemStore.item?.availableCashValueBorrowPercentage / 100) || 1)) - outstandingLoanValue(), itemStore.item?.currency)}</LinkedLiabilitiesHeaderSubTitle>
        </LinkedLiabilitiesHeaderTextWrapper>
        <LinkedLiabilitiesCloseBtnWrapper onClick={onClose}>
          <CloseSymmetricIcon />
        </LinkedLiabilitiesCloseBtnWrapper>
      </LinkedLiabilitiesHeaderWrapper>
      <LinkedLiabilitiesModalWrapper>
        {itemStore.connectedCollaterals?.map((liability, index) => (
          <LinkedLiabilitiesAssetWrapper key={index}>
            <LiabilityLeftSideWrapper>
              {metadataStore.itemsMeta[liability.liabilityId]?.logoBase64 ?
                <ItemLogoWrapper>
                  <img alt={metadataStore.itemsMeta[liability.liabilityId]?.connectedInstitutionName} src={'data:image/png;base64,' + metadataStore.itemsMeta[liability.liabilityId]?.logoBase64} />
                </ItemLogoWrapper> :
                metadataStore.itemsMeta[liability.liabilityId]?.logoUrl ?
                  <ItemLogoWrapper>
                    <img alt={metadataStore.itemsMeta[liability.liabilityId]?.connectedInstitutionName} src={metadataStore.itemsMeta[liability.liabilityId]?.logoUrl} />
                  </ItemLogoWrapper> :
                  <LiabilityIconWrapper color={"#EB7E7E"} >
                    <AssetClassIcon name={'lia-loans'} />
                  </LiabilityIconWrapper>}
              <AssetTextWrapper>
                <AssetTitle>{liability.title}{liability.name ? ', ' + liability.name : ''}</AssetTitle>
                <AssetClassSubtitle>Loans</AssetClassSubtitle>
              </AssetTextWrapper>
            </LiabilityLeftSideWrapper>
            <LiabilityRightSideWrapper>
              <LiabilityValueWrapper>{displayMoneyValue(liability.value, liability.currency, uiStore.isIncognitoMode, liability.holdings)}</LiabilityValueWrapper>
              <ArrowIconWRapper onClick={() => handleNavigateToConnectedLiability(liability.liabilityId)}><ArrowDownIcon /></ArrowIconWRapper>
              <DeleteLiabilityIconWrapper onClick={() => handleUnlinkAsset([liability.id])}><CloseSymmetricIcon /></DeleteLiabilityIconWrapper>
            </LiabilityRightSideWrapper>
          </LinkedLiabilitiesAssetWrapper>))}
      </LinkedLiabilitiesModalWrapper>
      <LinkedLiabilitiesModalFooterWrapper>
        <LinkLiabilityButton onClick={handleLinkLiability} >
          <ButtonIconWrapper><PlusIcon /></ButtonIconWrapper>
          Link a loan
        </LinkLiabilityButton>
      </LinkedLiabilitiesModalFooterWrapper>
    </BaseModal>
  )}</Observer>)
}
