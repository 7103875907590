import { assetCommonFields } from "../../../wealth/pages/asset/components/assetFields"

export const expendFormFieldsByKeys = (formSections) => {
    return formSections.map(section=>{
        return {...section,rows:section.rows.map(row=>{
            return {...row,fields: row.fields ? row.fields.map(field=>{
                const {key,...customProps} = field;
                return {...assetCommonFields[key],key,...customProps};
            }) : []}
        })}
    })
}

export const expendFormFieldsFnByKeys = (sectionsFn) => { 
    return (callBacks) => {
        const sections = sectionsFn(callBacks);
        const expended = expendFormFieldsByKeys(sections);
        return expended;
    }
}

export const filterSectionsFieldsByKeys = (formSections,fieldsToFilter) => {
    return formSections.map(section=>{
        return {...section,rows:section.rows.map(row=>{
            return {...row,fields: row.fields.filter(field=>
                !fieldsToFilter.includes(field.key)
            )}
        }).filter(row=> row.hasOwnProperty('type') || row.fields.length)}
    }).filter(section=>section.rows.length)
}

export const filterSectionsFieldsFnByKeys = (sectionsFn,fieldsToFilter) => { 
    return (callBacks) => {
        const sections = sectionsFn(callBacks);
        const filtered = filterSectionsFieldsByKeys(sections,fieldsToFilter);
        return filtered;
    }
}