import { Tooltip } from "antd";
import { FieldLabelToolTipTriggerWrapper } from "../../../../common/components/form-displayer/FormDisplayer.styles";
import { TooltipIcon } from "../../../../assets/icons/common/common-icons";
import { isNullOrUndefinedOrEmptyString } from "../../../../common/utils/object.utils";


export const containerRow = ({isEditForm, updateField, validations = []}) => ({fields:[
    {key: 'container', type:'container-autocomplete' ,
    label: () => (<div><span >Investment account</span><br/>
    <div style={{display:'flex' , alignItems:'center'}}>
      <span style={{fontWeight: 450, fontSize: '12px' , color:'#585D66'}}>Name the account that contains your securities</span>
      <Tooltip title={isEditForm ? 'Name the account holding your assets or liabilities. This helps with easier identification and filtering' : 'This name will help you identify and view its associated securities'} ><FieldLabelToolTipTriggerWrapper ><TooltipIcon /></FieldLabelToolTipTriggerWrapper></Tooltip>
      </div>
      </div>),
       onChange: ({value, formValues}) => {
        updateField('container', {withHoverAction: isEditForm && !isNullOrUndefinedOrEmptyString(value.container) })
      },
      generatePropsFn: (values) => {
        return {
             withHoverAction: isEditForm && !isNullOrUndefinedOrEmptyString(values.container),
        }
      },
      setFnForSave: (isDistribution, value, formValues) => (value === '') ? null : value,
      ...(validations.length > 0 ? {
        validations
    } : {}),
      }
]})