import React from 'react';
import { TierBadgeWrapper } from './TierBadge.styles';
import { ModifiedTooltip } from '../../../../common/components/ModifiedTooltip/ModifiedTooltip';

const FamilyOfficeSvg = ({color}) => {
  return (
    <svg width="13" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.7381 7.08294L3 4.40437V10.1187H8H13V4.40437L10.2619 7.08294L8 2.61865L5.7381 7.08294Z" fill={color || "#fff"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 12.3807H3.5V11.1807H12.5V12.3807Z" fill={color || "#fff"} />
    </svg>
  )
}


export const TierBadge = ({ tier , suffixIcon , noBg , showToolTip, tooltipContent, onClick, isClickable}) => {

  const badgeTypes = {
    READ_ONLY: {
      title: `Read-only mode`,
      tooltipContent: `Account in read-only mode.\nClick to upgrade your plan`,
      style: {
        color: '#6B9DFF', borderColor: '#6B9DFF',
      }
    },
    DEMO: {
      title: `Demo`,
      tooltipContent: `This is a demo portfolio.\nClick to start using your own data`,
      style: {
        color: '#6B9DFF', borderColor: '#6B9DFF',
      }
    },
    TIER_1: {
      title: `Starter`,
      tooltipContent:'Click to upgrade your plan',
      style: {
        color: '#DC9F8B', borderColor: '#DC9F8B',
      },
    },
    TIER_2: {
      title: `Plus`,
      tooltipContent: `You are a Vyzer Plus Member.\nClick to upgrade your plan`,
      style: {
        color: '#ffffff', borderColor: '#546481', boxShadow: '1px -1px 4px rgba(255, 255, 255, 0.25)'
      },
    },
    TIER_3: {
      title: `Premium`,
      tooltipContent: `You are a Vyzer Premium Member.\nClick to upgrade your plan`,
      style: {
        color: '#ffffff', borderColor: '#546481', boxShadow: '1px -1px 4px rgba(255, 255, 255, 0.25)'
      },
    },
    TIER_4: {
      title: `Elite`,
      tooltipContent: `Ultimate Wealth Management Experience`,
      suffixIcon: <FamilyOfficeSvg />,
      style: {
        color: '#ffffff', borderColor: '#546481', boxShadow: '1px -1px 4px rgba(255, 255, 255, 0.25)'
      },
    },
    
  }

  return (
    <ModifiedTooltip 
       
      title={showToolTip ? (tooltipContent ? tooltipContent : badgeTypes[tier]?.tooltipContent || '') : ''} 
      //isLongText={ !isNullOrUndefined(isLongToolTipText) ? isLongToolTipText : badgeTypes[badgeType]?.longToolTip || false } 
      overlayInnerStyle={{ whiteSpace: 'pre-line' }} placement={"bottom"}
      >
      <TierBadgeWrapper style={{ ...badgeTypes[tier]?.style }} noBg={noBg} onClick={onClick} isClickable={isClickable}>
        {badgeTypes[tier]?.title || tier}
        {suffixIcon ? suffixIcon : badgeTypes[tier]?.suffixIcon || null}
      </TierBadgeWrapper>
    </ModifiedTooltip>
    
  )
}