import { expendFormFieldsFnByKeys, filterSectionsFieldsFnByKeys } from "../add-item-forms/form-utils";
import { createCashAccountsAddItemSections, createCashAccountsPhysicalCashAddItemSections } from "../add-item-forms/assets/cash-accounts-forms";
import { createLongTermAddItemSections, createLongTermLifeInsuranceEditItemSections } from "../add-item-forms/assets/long-term-saving-forms";
import { createCryptoAddItemSections } from "../add-item-forms/assets/crypto-forms";
import { createRealEstateDevelopmentSoleOwnershipAddItemSections, createRealEstateDevelopmentWithPartnersAddItemSections, createRealEstateRentalPropertySoleOwnershipAddItemSections, createRealEstateRentalPropertyWithPartnersAddItemSections, createRealEstateResidenceAddItemSections } from "../add-item-forms/assets/real-estate-forms";
import { createP2pLendingPlatformAddItemSections, createPrivateLoanAddItemSections } from "../add-item-forms/assets/private-loans-OLD-forms";
import { createEquityAddItemSections, createOptionsAddItemSections, createTraditionalBusinessAddItemSections } from "../add-item-forms/assets/private-businesses-forms";
import { createDebtFundAddItemSections, createHedgeFundAddItemSections, createPrivateEquityAddItemSections, createRealEstateFundAddItemSections, createVentureCapitalAddItemSections } from "../add-item-forms/assets/funds-forms";
import { createOtherAddItemSections } from "../add-item-forms/assets/other-forms";
import { getTranslations } from "../../../../common/translations/translations";
import { createLoanAddItemSections } from "../add-item-forms/assets/loans-forms";
import { createAmortizationLoanAddItemSections } from "../add-item-forms/assets/amortization-loans-forms";
import { creditCardAddItemSections } from "../add-item-forms/assets/credit-cards-forms";
import { createPrivateLoanAmortizedAddItemSections } from "../add-item-forms/assets/private-loan-amortized-forms";
import { createGeneralBrokerageAddItemSections, createPublicMarketsTickersAddItemSections } from "../add-item-forms/assets/public-markets-forms";
import { createPrivateLoanNonAmortizedAddItemSections } from "../add-item-forms/assets/private-loan-non-amortized-forms";

const {RETIREMENT_TITLE_DESCRIPTION, CHILDRENS_SAVINGS_TITLE_DESCRIPTION, OTHER_TITLE_DESCRIPTION} = getTranslations().new_item_modal.long_term_savings;

export const ASSET_CATEGORIES_FORMS = {
    // cash accounts
    1 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createCashAccountsAddItemSections(true))
        , ['value', 'currency']), // checking account
    2 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createCashAccountsAddItemSections(true))
        , ['value', 'currency']), // savings account
    3 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createCashAccountsAddItemSections(true))
        , ['value', 'currency']), // money market account
    4 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createCashAccountsAddItemSections(true))
        , ['value', 'currency']), // cash deposit
    5 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createCashAccountsPhysicalCashAddItemSections(true))
        , ['value', 'currency']), // physical cash
    // long term savings
    6 : filterSectionsFieldsFnByKeys(
         expendFormFieldsFnByKeys(createLongTermAddItemSections(RETIREMENT_TITLE_DESCRIPTION, true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency' ]), // pension
    10 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createLongTermAddItemSections(CHILDRENS_SAVINGS_TITLE_DESCRIPTION, true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency' ]), // children savings
    11 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createLongTermAddItemSections(OTHER_TITLE_DESCRIPTION, true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency' ]), // other
    40 : expendFormFieldsFnByKeys(createLongTermLifeInsuranceEditItemSections(true)), // life insurance
    // crypto
    12 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createCryptoAddItemSections(true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency']),
    36 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createCryptoAddItemSections(true))
        , ['value', 'currency', 'startDate', 'cryptoDistributions', 'quantity', 'masterCrypto']), // tickers
    // public markets
    13 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createGeneralBrokerageAddItemSections(true))
    , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency' ]), // general brokerage account
    39 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createPublicMarketsTickersAddItemSections(true))
        , ['sharesPurchased', 'pricePerShare', 'pricePerShareCurrency', 'value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency','sharesDistributions' ]), // tickers
    // real estate
    15 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createRealEstateRentalPropertySoleOwnershipAddItemSections(true))
        , ['value', 'currency', 'connectedLiability', 'startDate', 'cashInvested', 'cashInvestedCurrency' ]), // rental property sole ownership
    16 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createRealEstateRentalPropertyWithPartnersAddItemSections(true))
        , ['value', 'currency', 'connectedLiability', 'startDate', 'cashInvested', 'cashInvestedCurrency', 'expectedAnnualCashReturn', 'expectedDistributionStartDate' , ]), // rental property with partners
    17 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createRealEstateDevelopmentSoleOwnershipAddItemSections(true))
        , ['value', 'currency', 'connectedLiability', 'startDate', 'cashInvested', 'cashInvestedCurrency' ]), // development sole ownership
    18 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createRealEstateDevelopmentWithPartnersAddItemSections(true))
        , ['value', 'currency', 'connectedLiability', 'startDate', 'cashInvested', 'cashInvestedCurrency', 'expectedAnnualCashReturn', 'expectedDistributionStartDate' , ]), // development with partners
    21 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createRealEstateResidenceAddItemSections(true))
        , ['value', 'currency', 'connectedLiability', 'startDate', 'cashInvested', 'cashInvestedCurrency' ]), // residence
    // private loans
    22 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createPrivateLoanAddItemSections(true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency' ]), // private loan
    23 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createP2pLendingPlatformAddItemSections(true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency' ]), // p2p lending platform
    38 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createPrivateLoanAmortizedAddItemSections(true))
        , ['value', 'currency', 'startDate' , 'cashInvested', 'cashInvestedCurrency', 'cashReceived', 'cashReceivedCurrency'  ]), // private loan amortization
    41 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createPrivateLoanNonAmortizedAddItemSections(true))
        , ['value', 'currency', 'startDate' , 'cashInvested', 'cashInvestedCurrency', 'cashReceived', 'cashReceivedCurrency'  ]), // private loan non amortized
    // private businesses
    24 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createEquityAddItemSections(true))
        , ['sharesPurchased', 'pricePerShare', 'pricePerShareCurrency', 'value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency','sharesDistributions' ]), // equity
    25 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createOptionsAddItemSections(true))
        , ['sharesPurchased', 'pricePerShare', 'pricePerShareCurrency', 'value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency' ]), // options
    31 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createTraditionalBusinessAddItemSections(true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency']), // traditional business
    // funds
    14 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createHedgeFundAddItemSections(true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency', 'cashReceived', 'cashReceivedCurrency', 'expectedAnnualCashReturn', 'expectedDistributionStartDate' , ]), // hedge fund 
    19 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createRealEstateFundAddItemSections(true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency', 'cashReceived', 'cashReceivedCurrency', 'expectedAnnualCashReturn', 'expectedDistributionStartDate' , ]), // real estate
    26 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createPrivateEquityAddItemSections(true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency', 'cashReceived', 'cashReceivedCurrency', 'expectedAnnualCashReturn', 'expectedDistributionStartDate' , ]), // private equity
    27 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createVentureCapitalAddItemSections(true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency', 'cashReceived', 'cashReceivedCurrency', 'expectedAnnualCashReturn', 'expectedDistributionStartDate' , ]), // venture capital
    32 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createDebtFundAddItemSections(true))
        , ['value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency', 'cashReceived', 'cashReceivedCurrency', 'expectedAnnualCashReturn', 'expectedDistributionStartDate' , ]), // debt fund
    // other
    28 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createOtherAddItemSections(true))
        , ['value', 'currency', 'startDate', 'purchasePrice', 'purchasePriceCurrency', 'cashInvested', 'cashInvestedCurrency' ]), // collectables
    29 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createOtherAddItemSections(true))
        , ['value', 'currency', 'startDate', 'purchasePrice', 'purchasePriceCurrency', 'cashInvested', 'cashInvestedCurrency' ]), // prescious metals & materials
    30 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createOtherAddItemSections(true))
        , ['value', 'currency', 'startDate', 'purchasePrice', 'purchasePriceCurrency', 'cashInvested', 'cashInvestedCurrency' ]), // vehicles
    33 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createLoanAddItemSections(true))
        , ['value', 'currency', 'startDate' , 'cashInvested', 'cashInvestedCurrency', 'cashReceived', 'cashReceivedCurrency'  ]), // loans ( liability )
    34 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(creditCardAddItemSections(true))
        , ['value', 'currency', 'startDate' ]), // credit card
    35: filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createEquityAddItemSections(true))
    , ['sharesPurchased', 'pricePerShare', 'pricePerShareCurrency', 'value', 'currency', 'startDate', 'cashInvested', 'cashInvestedCurrency','sharesDistributions' ]), // equity 
    37 : filterSectionsFieldsFnByKeys(
        expendFormFieldsFnByKeys(createAmortizationLoanAddItemSections(true))
        , ['value', 'currency', 'startDate' , 'cashInvested', 'cashInvestedCurrency', 'cashReceived', 'cashReceivedCurrency'  ]), // amortization loans ( liability )
    
}

