import styled from '@emotion/styled/macro';
import { flexCenter, textOverflow } from '../../styles/combos.styles';
import { colors, shadows } from '../../styles/theme.styles';

export const ButtonWrapper = styled.div`
    width:${props=>props.width || '100%'};
    ${flexCenter};
    justify-content:space-between;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: ${shadows.toggleShadow};
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 8px 8px 8px 12px;
    color:${props=>props.isPlaceHolder && props.placeHolderColor ? props.placeHolderColor : colors.darkGray1};

    cursor: pointer;
    :hover{
        /* box-shadow: ${shadows.toggleShadowHover} */
        border-color: ${props=>props.buttonHoverBorderColor};
    };

    :active{
        /* box-shadow: ${shadows.toggleShadowActive} */
        border-color: #1A1B1D;
    };
    ${props=>props.customStyle? props.customStyle : `` }

    &.ant-dropdown-disabled {
        background: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
    }

`;
export const ButtonText = styled.div`
    ${textOverflow};
    margin-right: 8px;
`;

export const ButtonArrowWrapper = styled.div`
    transition:transform 0.2s;
    ${flexCenter};
    ${props=>props.isOpen ? `transform:rotate(180deg)` : ``}
`;

export const MenuWrapper = styled.div`
    width:${props=>props.width || '100%'};
    background: #FFFFFF;
    box-shadow: ${shadows.toggleShadow};
    border-radius: ${props=> props.borderRadius ? props.borderRadius : '4px' }; 
    font-weight: 500; 
    font-size: 14px;
    line-height: 17px;
    /* padding: 8px; */
    color:${colors.darkGray1};
    cursor: pointer;
    max-height:300px;
    overflow-y:auto;  
    margin-top:8px;
`;

export const ArrowDownWrapper = styled.div`
    ${flexCenter};
    width: 12px;
    height: 12px;
    background: ${colors.lightGray2};
    border-radius: 24px;
    margin: 0px 4px;
    position:absolute;
    right:0px;
    top:6px;
`;

export const MenuItemWrapper = styled.div`
    padding: 8px 16px;
    position:relative;
    color: ${props=>props.menuItemColor};
    // border-left:2px solid ${colors.lightGray1};
    ::before{
        content:' ';
        position:absolute;
        left:0;
        top:0%;
        height:100%;
        width:0px;
        background-color:${colors.darkGray1};
        // transition:all 0.2s;
    }
    :hover{
        background-color:${colors.lightGray1};
        //border-left:2px solid ${colors.darkGray1};
        ::before{
            width:2px;
            /* top:0%;
            height:100%; */
        }
    }
    ${flexCenter};
    justify-content:space-between;
    cursor: pointer;

    ${props=>props.withCheckMark && `
        height: 44px;
    `}
    
    ${props=>props.withBorders && `
        :not(:last-of-type){
            border-bottom: 1px solid ${colors.gray3};
        }
    `}

`

export const MenuItemIconWrapper = styled.div`
    height:24px;
    width:24px;
    ${flexCenter};
    svg{
        height:24px;
        width:24px;
    }
    margin-right:12px;
`

export const MenuItemText = styled.div`
    flex:1;
    font-size:16px;
    line-height:19px;
`

export const MenuSeparators = styled.div`
    width:100%;
    height:1px;
    background-color:${colors.gray3};
    margin:4px 0px;
`;


export const ClearIcon = styled.button`
    border: 0;
    padding: 0;
    cursor: pointer;
    background: none;
    & + .select-arrow {
        display: none;
    }
    svg {
        width: 12px;
        fill: ${colors.gray2};
    }
`

export const CheckmarkIconWrapper = styled.div`
    svg{
        path{
            stroke:black;
        };
    };
`;
