import React from 'react'
import { EmptyStateWrapper, NoTransactionsSkeletonWrapper } from './EmptyState.styles'
import { ReactComponent as NoTransactionsSkeleton } from './assets/no-transactions-skeleton.svg';
import { Observer } from 'mobx-react';

export const CompletelyEmptyState = (props) => {
  return (<Observer>{() => (
    <EmptyStateWrapper>
      <NoTransactionsSkeletonWrapper><NoTransactionsSkeleton /></NoTransactionsSkeletonWrapper>
    </EmptyStateWrapper>
  )}</Observer>)
}
