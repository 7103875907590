import React from 'react'
import { SearchAltTextWrapper, SearchIconEmptyStateWrapper, SearchTextWrapper, SponsorParameterFilterEmptyStateWrapper } from './SponsorParameterFilter.styles'
import {ReactComponent as EmptySearchResultsIcon} from './icons/search-icon.svg';

export const SponsorParameterFilterEmptyState = () => {
  return (
    <SponsorParameterFilterEmptyStateWrapper>
        <SearchIconEmptyStateWrapper><EmptySearchResultsIcon /></SearchIconEmptyStateWrapper>
        <SearchTextWrapper>No results</SearchTextWrapper>
        <SearchAltTextWrapper>You don’t have any institution/sponsor<br/>at this name.</SearchAltTextWrapper>
    </SponsorParameterFilterEmptyStateWrapper>
  )
}
