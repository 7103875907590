import moment from "moment";
import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { disabledMoreThanToday } from "../../../../../common/utils/dates.utils";
import { isNullOrUndefinedOrEmptyString } from "../../../../../common/utils/object.utils";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { containerRow } from "../../common-fields/container-field.const";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { isBetweenZeroAndHundred, isRequired } from "../../input-validations";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";

const monthsPerPeriodMap = {
    'Monthly' : 1,
    'Quarterly' : 3,
    'Semi-annually' : 6,
    'Annually' : 12
}

export const createGeneralBrokerageAddItemSections = (isEditForm) => {
    return ({updateValue,updateField,updateSection}) => {
        const { brokerage_accounts } = getTranslations().new_item_modal;
        return ([
            {
                title:'General info',
                rows:[
                    ...masterInstitutionInputRows(brokerage_accounts.NAME_LABEL,'The company that manages this Public market account', 3, false, updateValue, !isEditForm),
                    {fields:[
                        {key:'title', validations:[isRequired]}
                    ]},
                    {fields:[
                        {key:'startDate',
                        onChange: ({value, formValues}) => {
                            updateField('expectedAnnualRevenue', {startDate: moment(value)})
                            updateField('expectedAnnualExpense', {startDate: moment(value)})
                        },
                        validations:[isRequired],
                        disabledDate: disabledMoreThanToday}
                    ]},
                    {fields:[
                        {key:'value', type: 'numberWithHoldings', label: brokerage_accounts.VALUE_LABEL, 
                        onBlur:({value})=>{
                            updateValue('cashInvested',value);
                            updateField('expectedAnnualRevenue',{relativeAmount: value});
                            updateField('expectedAnnualExpense',{relativeAmount: value});
                        },
                        description:'This public market account current value, which will be added to your net worth.', validations:[isRequired]
                        ,generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                        }},
                        {key:'currency',label:' ',size:0.3 ,onChange:({value})=>{
                            // updateValue('cashInvestedCurrency',value);
                            updateField('value',{prefix:CURRENCY_SYMBOL[value] || ''});
                            updateField('cashInvested',{prefix:CURRENCY_SYMBOL[value] || ''})
                            updateField('expectedMonthlyContribution',{prefix:CURRENCY_SYMBOL[value] || ''})
                            
                            // updateValue('cashReceivedCurrency',value);
                        }}
                    ]},
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : [
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden:true,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                    onBlur:({value})=>{
                                        updateField('value',{holdings:value || ''});
                                        updateField('cashInvested',{holdings:value || ''});
                                        updateField('expectedMonthlyContribution',{holdings:value || ''});
                                        updateField('expectedAnnualRevenue',{holdings:value || ''});
                                        updateField('expectedAnnualExpense',{holdings:value || ''});
                                    },
                                    generatePropsFn : (values)=>{
                                        return {isHidden: values.holdings === 100}
                                    }
                                }
                            ]
                        },
                        {
                            fields:[
                                {type:'checkbox', key:'isPartialHolding',checkboxLabel: 'This asset is 100% owned by me', description: 'If you are a co-owner of this account, uncheck this box.' ,
                                fieldCustomStyle: {marginTop:'-24px'},
                                defaultValue: true,
                                    onChange: ({value})=>{
                                        const newHoldingValue = value ? 100 : 50;
                                        updateValue('holdings',newHoldingValue);
                                        updateField('holdings',{isHidden:value});
                                        updateField('value',{holdings:newHoldingValue});
                                        updateField('cashInvested',{holdings:newHoldingValue});
                                        updateField('expectedMonthlyContribution',{holdings:newHoldingValue});
                                        updateField('expectedAnnualRevenue',{holdings:newHoldingValue || ''});
                                        updateField('expectedAnnualExpense',{holdings:newHoldingValue || ''});
                                    }
                                },
                            ]
                        },
                       ]),
                ]
        },{
                title: 'Performance', 
                generatePropsFn:(values)=>{
                    return {isHidden : values.isConnected}
                    // console.log("TO", values);
                },
                rows:[
                  {fields:[
                      {key:'cashInvested', label: 'Initial investment', description:'The initial contribution amount', type: 'numberWithHoldings',prefix:CURRENCY_SYMBOL['USD'],defaultValueFn:(values)=>values.value},
                      // {key:'cashInvestedCurrency',size:0.3 , label: ' ', defaultValue: 'USD'}
                  ]},
                  {fields:[
                      {key:'expectedIRR', label: brokerage_accounts.EXPECTED_ANNUAL_PERFORMANCE_LABEL, defaultValue: 4,
                      description:'How much you expect your portfolio to increase in value annually (regardless of additional contributions made)'}
                  ]},
                  {fields:[
                      {key:'expectedDistributions', type:'radio', label: () => <div>Do you have expected <span style={{fontWeight:700}}>distributions</span>?</div>, 
                      defaultValueFn: (values) => { return !isEditForm ? false : (values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false )}, 
                      // defaultValue:  true,
                      onChange: ({value, formValues}) => {
                          updateValue('distributionReinvested', false);
                          updateField('distributionReinvested', { isHidden: !value, value: false });
                          updateSection('distributionScheduleTable', { isHidden: !value });
                          if (!value) {
                              updateValue('expectedAnnualRevenue', []);
                              updateField('expectedAnnualRevenue', {
                                customAnnualReturnToolTipText: `The total amount you expect to receive on this investment annually. 
                                The percentage rate is based on your asset's initial investment ${(CURRENCY_SYMBOL[formValues.currency] + formValues.cashInvested) || ''}`
                              });
                          }
                      },
                      optionType: 'button',
                      options:[
                          {label: 'Yes', value: true},
                          {label: 'No', value: false}
                      ],
                  }
                  ]},
                  {fields:[
                      {key:'distributionReinvested', label: '', checkboxLabel: () => <div>My distributions are <span style={{fontWeight:700}}>reinvested</span></div> , type:'checkbox' , 
                      description: 'Check this box if your distributions are reinvested into the fund',
                      isHidden: true,
                      onChange:({value, formValues})=> {
                          updateValue('isDistributionsByPercentage', value);
                          updateField('expectedAnnualRevenue', {
                            isPercentageDisplay: value,
                            customAnnualReturnToolTipText: value ? `The annual rate of return you expect to reinvest, each year.` : `The total amount you expect to receive on this investment annually. 
                            The percentage rate is based on your asset's initial investment ${(CURRENCY_SYMBOL[formValues.currency] + formValues.cashInvested) || ''}`
                          });
                          updateField('expectedAnnualCashReturn', { withNumber: !value, label: `Expected annual${value ? '' : ' cash'} return` });
                          updateSection('distributionScheduleTable' , {title : value ? 'Target reinvested distribution schedule' : 'Target cash distribution schedule'});
                          // update distributions schedule values to percentages / fixed number;
                          const updatedScheduleValues = formValues.expectedAnnualRevenue?.map(row => {
                          
                          const newValue = row.value === '' ? (
                              formValues.cashInvested && formValues.expectedAnnualCashReturn ? (
                                  value ?    
                                  parseFloat((formValues.expectedAnnualCashReturn / formValues.cashInvested * 100).toFixed(2)) : 
                                  formValues.cashInvested / 100 * formValues.expectedAnnualCashReturn
                              ) : ''  
                            ) :( value ?
                              parseFloat((row.value / formValues.cashInvested * 100).toFixed(2)) :
                              (formValues.cashInvested / 100 * row.value) );
                            return { ...row, value: newValue }
                          })
                          updateValue('expectedAnnualRevenue', updatedScheduleValues);
                      }},
                      {
                          key:'isDistributionsByPercentage', 
                          isHidden:true,
                          defaultValue : false
                      },
                  ]},

                  {fields:[
                      {key:'expectedContributions', type:'radio', label: () => <div>Do you have expected <span style={{fontWeight:700}}>contributions</span>?</div>, 
                      defaultValueFn: (values) => { return !isEditForm ? false : (values.expectedAnnualExpense ? values.expectedAnnualExpense.length > 0 : false )}, 
                      // defaultValue:  true,
                      onChange: ({value}) => {
                          updateSection('contributionsScheduleTable', { isHidden: !value });
                      },
                      optionType: 'button',
                      options:[
                          {label: 'Yes', value: true},
                          {label: 'No', value: false}
                      ],
                  }
                  ]},
              ],
              // ...(isEditForm ? [] : [])
            },
            {
                key: 'distributionScheduleTable',
                fullWidth:true,
                generatePropsFn : (values)=>{
                    return {
                        isHidden: !isEditForm ? true : !(values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false ) ,
                        title: values.distributionReinvested ? 'Target reinvested distribution schedule' : 'Target cash distribution schedule'
                    }
                },
                rows: [
                    {fields:[
                        {key:'expectedDistributionPeriod', label: 'Distribution frequency', description: '',
                        type:'select',
                        withDescriptionInOption: true,
                        defaultValue: 'Monthly',
                        withSuffixIcon: true,
                        options: [
                            {value:'Monthly',label:'Monthly'},
                            {value:'Quarterly',label:'Quarterly', description:'Transactions date: Apr, Jul, Oct, Jan (next year).'},
                            {value:'Semi-annually',label:'Semi-annually', description:'Transactions date: Jul and Jan (next year).'},
                            {value:'Annually',label:'Annually', description:'Transactions date: Jan (next year).'},
                            {value:'Custom',label:'Custom...', },
                        ],
                        onChange: ({value, formValues}) => {
                            updateField('expectedDistributionStartDate', { isHidden: value !== 'Custom' });
                            updateField('expectedDistributionCustomInterval', { isHidden: value !== 'Custom' });
                            updateField('expectedDistributionCustomPeriod', { isHidden: value !== 'Custom' });
                            updateField('expectedAnnualRevenue', { 
                                monthsPerPeriod: monthsPerPeriodMap[value] ? monthsPerPeriodMap[value] : formValues.expectedDistributionCustomInterval || 1 ,
                                period: value === 'Custom' ? 'Periodic' : value
                            });
                        },
                    }
                    ]},
                    // {fields:[
                    //     {key:'expectedDistributionStartDate', clearable: true ,  label:'Transactions start date', description:`Enter the frequency's first payment date.`, type:'date',
                    //         generatePropsFn: (formValues) => {
                    //             return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' }
                    //         },
                    //         onChange:({value , formValues})=>{
                    //             updateField('expectedAnnualRevenue',{startDate: value ? value : formValues.startDate});
                                
                    //             if (!formValues.isExpectedAnnualRevenueTouched){
                    //                 const updatedScheduleValues = formValues.expectedAnnualRevenue.map((item,index)=>{
                    //                     return {...item,date :  moment(value).utc().add(index,'y').set("D",15).startOf("D")}
                    //                 });
                    //                 updateValue('expectedAnnualRevenue', updatedScheduleValues);
                    //             }
                    //         }
                    //     }
                    // ]},
                    {fields:[
                        {key:'expectedDistributionCustomInterval', label:'Repeat every:', type:'number', withArrows:true,
                        maxValue: 15, minValue: 0, defaultValue:2, decimalScale: 0,
          
                        generatePropsFn: (formValues) => {
                            return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' }
                        },
                        onChange: ({value, formValues}) => {
                            updateField('expectedAnnualRevenue', {monthsPerPeriod: value * ( formValues.expectedDistributionCustomPeriod === 'Years' ? 12 : 1 ) })  
                        },
                        validations:[
                            {
                                fn: (value) => {
                                    return value < 16;
                                },
                                message:'Maximum period allowed: 15'
                            }
                        ]},
                        {key:'expectedDistributionCustomPeriod', type:'select', defaultValue:'Months',
                        withDescriptionInOption: true,
                        withSuffixIcon: true,
                        generatePropsFn: (formValues) => {
                            return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' }
                        },
                        onChange: ({value, formValues}) => {
                            updateField('expectedAnnualRevenue', {monthsPerPeriod: formValues.expectedDistributionCustomInterval * ( value === 'Years' ? 12 : 1 ) })  
                        },
                        options:[
                            // {value: 'Weeks', label:'Weeks'},
                            {value: 'Months', label:'Months'},
                            {value: 'Years', label:'Years'},
                        ]},
                    ]},
                    {fields:[
                        {
                            key:'expectedAnnualRevenue', 
                            label: '',
                            type:'scheduleTable',
                            defaultValue: [],
                            onChange: ({value, formValues}) => {
                                if (!formValues.isExpectedAnnualRevenueTouched){
                                    updateValue('isExpectedAnnualRevenueTouched' , true);
                                }
                            },
                            showHistory: false,
                            showPercentage: true,
                            generatePropsFn : (values)=> {
                                const exPer = values.expectedDistributionPeriod;
                                return {
                                    prefix: CURRENCY_SYMBOL[values.currency] || 'USD', 
                                    placeholder: CURRENCY_SYMBOL[values.currency],
                                    holdings:values.holdings , 
                                    startDate: values.startDate,
                                    relativeAmount: values.cashInvested || values.value,
                                    isPercentageDisplay: values.distributionReinvested,
                                    monthsPerPeriod: monthsPerPeriodMap[values.expectedDistributionPeriod] ? monthsPerPeriodMap[values.expectedDistributionPeriod] : (values.expectedDistributionCustomInterval || 1 ),
                                    period: !exPer ? 'Monthly' : exPer === 'Custom' ? 'Periodic' : exPer,
                                    customAnnualReturnToolTipText: values.distributionReinvested ? `The annual rate of return you expect to reinvest, each year.` : `The total amount you expect to receive on this investment annually. 
                                    The percentage rate is based on your asset's initial investment ${(CURRENCY_SYMBOL[values.currency] + values.cashInvested) || ''}`
                                }
                            },
                            validations: [
                                {
                                  fn: (value, formValues) => {
                                    return Array.isArray(value) ? (value.length === 0 || !value.some(item=> isNullOrUndefinedOrEmptyString(item.value)) ) : true ; 
                                  },
                                  // message: 'Empty value for a row is not valid',
                                  bottomErrorMessage: (value, formValues) => {
                                    return `Empty value for a row is not valid`; // `must be equal to ${formValues.transactionValue}.`
                                  },
                                },
                            ]
                        },
                        {
                            key:'isExpectedAnnualRevenueTouched', 
                            isHidden:true,
                            defaultValue : isEditForm
                        },
                    ]},
                ]
            },


            {
                key: 'contributionsScheduleTable',
                fullWidth:true,
                title: 'Target contribution schedule',
                generatePropsFn : (values)=>{
                    return {
                        isHidden: !isEditForm ? true : !(values.expectedAnnualExpense ? values.expectedAnnualExpense.length > 0 : false ) ,
                    }
                },
                rows: [
                    {fields:[
                        {key:'expectedContributionPeriod', label: 'Contribution frequency', description: '',
                        type:'select',
                        withDescriptionInOption: true,
                        defaultValue: 'Monthly',
                        withSuffixIcon: true,
                        options: [
                            {value:'Monthly',label:'Monthly'},
                            {value:'Quarterly',label:'Quarterly', description:'Transactions date: Apr, Jul, Oct, Jan (next year).'},
                            {value:'Semi-annually',label:'Semi-annually', description:'Transactions date: Jul and Jan (next year).'},
                            {value:'Annually',label:'Annually', description:'Transactions date: Jan (next year).'},
                            {value:'Custom',label:'Custom...', },
                        ],
                        onChange: ({value, formValues}) => {
                            updateField('expectedContributionStartDate', { isHidden: value !== 'Custom' });
                            updateField('expectedContributionCustomInterval', { isHidden: value !== 'Custom' });
                            updateField('expectedContributionCustomPeriod', { isHidden: value !== 'Custom' });
                            updateField('expectedAnnualExpense', { 
                                monthsPerPeriod: monthsPerPeriodMap[value] ? monthsPerPeriodMap[value] : formValues.expectedDistributionCustomInterval || 1 ,
                                period: value === 'Custom' ? 'Periodic' : value
                            });
                        },
                    }
                    ]},
                    // {fields:[
                    //     {key:'expectedDistributionStartDate', clearable: true ,  label:'Transactions start date', description:`Enter the frequency's first payment date.`, type:'date',
                    //         generatePropsFn: (formValues) => {
                    //             return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' }
                    //         },
                    //         onChange:({value , formValues})=>{
                    //             updateField('expectedAnnualRevenue',{startDate: value ? value : formValues.startDate});
                                
                    //             if (!formValues.isExpectedAnnualRevenueTouched){
                    //                 const updatedScheduleValues = formValues.expectedAnnualRevenue.map((item,index)=>{
                    //                     return {...item,date :  moment(value).utc().add(index,'y').set("D",15).startOf("D")}
                    //                 });
                    //                 updateValue('expectedAnnualRevenue', updatedScheduleValues);
                    //             }
                    //         }
                    //     }
                    // ]},
                    {fields:[
                        {key:'expectedContributionCustomInterval', label:'Repeat every:', type:'number', withArrows:true,
                        maxValue: 15, minValue: 0, defaultValue:2, decimalScale: 0,
          
                        generatePropsFn: (formValues) => {
                            return { isHidden: formValues.expectedContributionPeriod !== 'Custom' }
                        },
                        onChange: ({value, formValues}) => {
                            updateField('expectedAnnualExpense', {monthsPerPeriod: value * ( formValues.expectedContributionCustomPeriod === 'Years' ? 12 : 1 ) })  
                        },
                        validations:[
                            {
                                fn: (value) => {
                                    return value < 16;
                                },
                                message:'Maximum period allowed: 15'
                            }
                        ]},
                        {key:'expectedContributionCustomPeriod', type:'select', defaultValue:'Months',
                        withDescriptionInOption: true,
                        withSuffixIcon: true,
                        generatePropsFn: (formValues) => {
                            return { isHidden: formValues.expectedContributionPeriod !== 'Custom' }
                        },
                        onChange: ({value, formValues}) => {
                            updateField('expectedAnnualExpense', {monthsPerPeriod: formValues.expectedContributionCustomInterval * ( value === 'Years' ? 12 : 1 ) })  
                        },
                        options:[
                            // {value: 'Weeks', label:'Weeks'},
                            {value: 'Months', label:'Months'},
                            {value: 'Years', label:'Years'},
                        ]},
                    ]},
                    {fields:[
                        {
                            key:'expectedAnnualExpense', 
                            label: '',
                            // label: expectedAnnualRevenueLabel, 
                            // description: expectedAnnualRevenueDescription,
                            type:'scheduleTable',
                            defaultValue: [],
                            onChange: ({value, formValues}) => {
                                if (!formValues.isExpectedAnnualExpenseTouched){
                                    updateValue('isExpectedAnnualExpenseTouched' , true);
                                }
                            },
                            showHistory: false,
                            showPercentage: true,
                            customAnnualReturnLabel: 'Annual contribution %',
                            customColumnsSize: ['105px','105px','105px','116px','auto'],
                            generatePropsFn : (values)=> {
                                const exPer = values.expectedContributionPeriod;
                                return {
                                    prefix: CURRENCY_SYMBOL[values.currency] || 'USD', 
                                    placeholder: CURRENCY_SYMBOL[values.currency],
                                    holdings:values.holdings , 
                                    startDate: values.startDate,
                                    relativeAmount: values.cashInvested || values.value,
                                    // isPercentageDisplay: values.distributionReinvested,
                                    monthsPerPeriod: monthsPerPeriodMap[values.expectedContributionPeriod] ? monthsPerPeriodMap[values.expectedContributionPeriod] : (values.expectedContributionCustomInterval || 1 ),
                                    period: !exPer ? 'Monthly' : exPer === 'Custom' ? 'Periodic' : exPer,
                                    customAnnualReturnToolTipText: `The total amount you expect to contribute to this investment annually.`
                                }
                            },
                            validations: [
                                {
                                  fn: (value, formValues) => {
                                    return Array.isArray(value) ? (value.length === 0 || !value.some(item=> isNullOrUndefinedOrEmptyString(item.value)) ) : true ; 
                                  },
                                  // message: 'Empty value for a row is not valid',
                                  bottomErrorMessage: (value, formValues) => {
                                    return `Empty value for a row is not valid`; // `must be equal to ${formValues.transactionValue}.`
                                  },
                                },
                            ]
                        },
                        {
                            key:'isExpectedAnnualExpenseTouched', 
                            isHidden:true,
                            defaultValue : isEditForm
                        },
                    ]},
                ]
            },
            {
                title:`More Info`,
                rows:[
                    ...(isEditForm ? [
                        {
                            fields:[
                                {key:'defaultCashAccountId', description: brokerage_accounts.DEFAULT_CASH_ACCOUNT_DESCRIPTION}
                            ]
                        },
                        {
                            fields:[
                                {key:'holdings',  label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isLocked: true, UnlockComponent:HoldingsUnlockModal,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                // onBlur:({value})=>{
                                //     updateField('monthlyRent',{holdings:value || ''});
                                //     updateField('expectedMonthlyExpenses',{holdings:value || ''});
                                //     updateField('expectedSalePrice',{holdings:value || ''});
                                // },
                                }
                            ]
                        },
                    ] : []),
                    beneficiaryRow({label: brokerage_accounts.BENEFICIARY_LABEL}),
                    ...(isEditForm ? [
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {fields:[
                        {key:'remarks', label: 'Remarks'}
                    ]}
                ]
            }
        ])
    }
}

const COMPANY_SECTOR_OPTIONS = [
    { value: 'Information Technology', label: 'Information Technology' },
    { value: 'Health Care', label: 'Health Care' },
    { value: 'Financials', label: 'Financials' },
    { value: 'Consumer Discretionary', label: 'Consumer Discretionary' },
    { value: 'Communication Services', label: 'Communication Services' },
    { value: 'Industrials', label: 'Industrials' },
    { value: 'Consumer Staples', label: 'Consumer Staples' },
    { value: 'Energy', label: 'Energy' },
    { value: 'Utilities', label: 'Utilities' },
    { value: 'Real Estate', label: 'Real Estate' },
    { value: 'Materials ', label: 'Materials' },
]

export const createPublicMarketsTickersAddItemSections = (isEditForm) => {
    return ({updateValue,updateField}) => {
        const { brokerage_accounts } = getTranslations().new_item_modal;
        return ([
            {
                title:'General info',
                rows:[
                    ...masterInstitutionInputRows(brokerage_accounts.NAME_LABEL,brokerage_accounts.NAME_DESCRIPTION, 3, false, updateValue, !isEditForm, 
                        // [{
                        //     fn: (value, formValues) => {
                        //         if (!value || !formValues.name) {
                        //             return false;
                        //         }
                        //         return true
                        //     },
                        //     message: `Required`
                        // },]
                    ),
                    containerRow({isEditForm, updateField}),
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : [
                        beneficiaryRow({label: 'Holding entity'}),
                        {fields:[
                            {
                                key:'currency', label: 'Transaction currency',
                                description: 'The currency used for buying and selling the holdings',
                                onChange:({value,formValues})=>{
                                    updateField('stocks',{currency: value})
                                }
                            }
                        ]},
                    ]),
                ]
            },{
                title: isEditForm ? 'Ticker info' : () => (<div style={{display:'flex', flexDirection:'column', gap:'6px'}}>
                    <div>Add Holdings</div>
                    <div style={{color:'#9097A3', fontFamily:'Circular', textTransform:'none', fontSize:'14px', fontWeight:450}}>Add your current holdings here. Historical information, such as purchase, sales and cost basis, can be added later.</div>
                    </div>),
                fullWidth: true,
                // margin: '0 -24px',
                rows:[
                    ...(isEditForm ? [
                        {fields:[
                            {key:'title', label:'Ticker name', disabled: true,
                                defaultValue: '',
                            }
                        ]},
                        {fields:[
                            {key:'stockType', label:'Type', disabled: true, type:'text', defaultValueFn: (values) => values.stock.type }
                        ]},
                        {fields:[
                            {key:'exchangePlatform', label: 'Exchange', type:'text', disabled: true, defaultValueFn: (values) => values.stock.exchange}
                        ]},
                        {fields:[
                            { key: 'companySector', type: 'select', label: 'Company sector', description: '', options: COMPANY_SECTOR_OPTIONS, allowClear: true }
                        ]},
                        {fields:[
                            {key:'marketCap', type:'text', label:'Market Capitalization', disabled: true, defaultValue: 'Loading...'}
                        ]},

                    ] : [
                        {fields:[
                            {key: 'stocks', type:'tickerTradingTable',
                            currency: 'USD',
                            defaultValue: [{ quantity: '', searchStr: '', selectedObject: null, pricePerShare: '', openOnLoad: true }],
                            validations: [
                                {
                                    fn: (value) => {
                                        if (!value.length) {
                                            return false;
                                        }
    
                                        const isValid = value.map(row=>{
                                            if(!row.quantity || !row.selectedObject) {
                                                return false;
                                            }
                                            return true;
                                        })
                                        // console.log(isValid);
                                        if(isValid.includes(false)){
                                            return false;
                                        }
                                        // console.log(value[0]);
                                        return true //false;
                                    },
                                    message: `Required`
                                },
                            ],
                            setFnForSave: (isDistribution, value, formValues) => {
                                const normalizedStocks = value.slice().map(stock => {
                                    const { symbol, instrument_name: name, exchange, mic_code: micCode, instrument_type: type , currency } = stock.selectedObject;
                                    const { pricePerShare, quantity:numberOfShares , symbolCurrencyPrice } = stock;
                                    return {
                                        symbol,
                                        name,
                                        exchange,
                                        micCode,
                                        type,
                                        currency,
                                        symbolCurrencyPrice,
                                        pricePerShare: +pricePerShare,
                                        numberOfShares: +numberOfShares
                                    }
                                })
                                return normalizedStocks;
                            }
                            }
                        ]},
                        {fields:[
                            {key:'title', isHidden:true, disabled: true,
                                defaultValue: '',
                            }
                        ]}
                    ])
                ]
            },
            ...(isEditForm ? [{
                title:`More Info`,
                rows:[
                    beneficiaryRow({label: 'Holding entity'}),
                    {
                        fields:[
                            {key:'defaultCashAccountId', description: brokerage_accounts.DEFAULT_CASH_ACCOUNT_DESCRIPTION}
                        ]
                    },
                    // containerRow({isEditForm, updateField}),
                    {
                        fields:[
                            {key:'holdings',  label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isLocked: true, UnlockComponent:HoldingsUnlockModal,
                            validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                            validations:[isBetweenZeroAndHundred],
                            }
                        ]
                    },
                    ...(isEditForm ? [
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {fields:[
                        {key:'remarks', label: 'Remarks' }
                    ]}
                ]
            }] : [])
        ])
    }
} 
