import styled from "@emotion/styled/macro";
import { colors } from "../../../../common/styles/theme.styles";



export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  flex: 1;
  position: relative;
  text-align: center;
  height: 100%;
`;

export const MobileAppModalWrapper = styled.div`
        width: 100%;
        padding:0 24px;
        flex:1;
        display:flex; 
        flex-direction: column;
        background: url('${props => props.bgImage}') no-repeat center right;
        background-size: cover;
        overflow-y: auto;
`;

export const ModalCloseButtonWrapper = styled.div`
    position:absolute;
    right:12px;
    top:12px;
    width:24px;
    height:24px;
    cursor: pointer;
    svg {
      line {
        stroke: white;
      }
    }
`;

export const ModalTitle = styled.div`
  text-align: center;
  font-family:Circular;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-top: 84px;
  color:#fff;
`;

export const PlansTitle = styled.div`
  font-size: 14px;
font-style: normal;
font-weight: 450;
line-height: normal;
color:${colors.gray3};
margin-top: 40px;
padding-bottom: 12px;
`;

export const PlansListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:8px;
`;

export const PlanItemWrapper = styled.div`
  padding:16px;
  border-radius: 10px;
  border: 1px rgba(255, 255, 255, 0.27);
  background: rgba(246, 248, 251, 0.15);
  backdrop-filter: blur(6.400000095367432px);
  display: flex;
  gap:16px;
  align-items: center;
`;

export const PlanIconWrapper = styled.div`
  min-width: 40px;
  display: flex;
`;

export const PlanItemTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PlanItemTitle = styled.div`
  font-family: Domine;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color:#fff;
  display: flex;
  gap: 8px;
`;


export const PlanItemDescription = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 17px;
  color:${colors.gray3};
  padding-right: 48px;
`;

export const ActionButton = styled.div`
    display: flex;
    gap:8px;
    min-height: 56px;    
    justify-content: center;
    align-items: center;
    align-self:center;
    width:264px;
    border-radius: 30px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    ${props=>props.black ? `
        color:#fff;
        border:1px solid #fff;
      ` : `
      background:  #FFF;
      color:${colors.darkGray1};
      `}
    ${props=>props.disabled ? `
      opacity: 0.5; 
    `:``}
`;
