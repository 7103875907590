import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../common/styles/combos.styles";
import { colors, shadows } from "../../../common/styles/theme.styles";


export const PanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height:100%;
    flex:1;
`;

export const PanelHeader = styled.div`
    display: flex;
    flex-direction:column;
    padding:16px;
    background-color:${colors.lightGray1};
    border-bottom:1px solid ${colors.gray3};
`;

export const PanelHeaderRowWrapper = styled.div`
    display: flex;
    align-items: center;
`


export const HeaderTitle = styled.div`
    flex: 1;
    font-size:16px;
    font-weight:500;
`

export const HeaderSubTitle = styled.div`
    color:${colors.darkGray3};
    font-size:14px;
`

export const HeaderCloseButton = styled.div`
    ${flexCenter};
    cursor: pointer;
    svg {
        path {
            stroke-width: 1.5;
        }
    }
`;

export const FiltersPanelContentWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    flex:1;
    overflow-y:scroll;
    user-select: none;
`

export const ParameterPanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom:1px solid ${colors.gray3};
`;

export const ParamHeaderWrapper = styled.div`
    width:100%;
    ${flexCenter};
    padding:16px;
    justify-content:flex-start;
    position: relative;
    cursor: pointer;
    ${props=>props.isOpen ? `
        background-color:${colors.lightGray1};
    ` : ``}
    :hover {
        background-color:${colors.lightGray1};
        ::after{
            content:'';
            position:absolute;
            left:0;
            top:0;
            width:2px;
            height:100%;
            background-color:${colors.darkGray1};
        }
    }
`;


export const ParamContentWrapper = styled.div`
    width:100%;
    ${flexCenter};
    justify-content:flex-start;
`;

export const ParamIconWrapper = styled.div`
    width:24px;
    height:24px;
    margin-right:8px;
    svg{
        width:24px;
        height:24px;
    }
`
export const ParamTitle = styled.div`
    padding-left: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    flex:1;
    color: ${props=>props.disabled ? colors.darkGray3 : ``};
    display:flex;
    align-items:center;
    .attr-lock-icon{
        margin-left:8px;
        path{
            fill:${colors.darkGray3}
        }
    }

`;

export const ParamTitleSelectionCount = styled.div`
    ${flexCenter}
    background: ${colors.darkGray1};
    border-radius: 8px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    min-width: 16px;
    width: 16px;
    height:16px;
`;

export const ParamPanelAnimationWrapper = styled.div`
    transform: scaleY(${props=>props.isOpen ? '1' : '0'});
`;

export const ParamRowWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;
    box-shadow: ${shadows.toggleShadow};
    border-radius: 26px;
`

export const ParamRowTitle = styled.div`
    ${flexCenter}
    font-size: 13px;
    line-height: 16px;
    color: ${colors.darkGray1};
    padding: 6px 8px;
`


export const PanelFooter = styled.div`
    ${flexCenter};
    padding: 16px 20px;
    gap: 24px;
    /* background-color:${colors.lightGray1}; */
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05);
`;

export const ClearFilterButton = styled.div`
    ${flexCenter};
    color: ${colors.darkGray1};
    font-size: 14px;
    line-height: 17px;
    /* padding: 10px 8px; */
    min-width: 50px;
    min-height: 36px;
    cursor: pointer;
`;

export const ApplyFilterButton = styled.div`
    ${flexCenter};
    color: #fff;
    background: ${colors.darkGray1};
    opacity: ${props=>props.isDisabled ? `0.3` : `1`};
    border-radius: 4px;
    min-width: 200px;
    min-height: 36px;
    /* padding: 10px 64px; */
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    `;

export const EmptyParamWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 18px;
`;

export const HashtagIconWrapper = styled.div`
    height: 24px;
    margin-bottom: 4px;
`;

export const EmptyParamTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 4px;
    color: ${colors.darkGray1};
`;

export const EmptyParamText = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${colors.darkGray3};
`;

export const AttributeTitle = styled.div`
    color: ${props=>props.disabled ? colors.darkGray3 : ``};
`;

export const SubAttributesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 14px;
  padding: 0px 0px 6px;
`;


export const SubAttributeWrapper = styled.div`
    display:flex; 
    gap: 8px;
    cursor: pointer;
    padding: 8px 24px;
    :hover{
        background-color:${colors.lightGray1};
    }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-checkbox-input, .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    :focus{
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color:${colors.darkGray1};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
  }
  .ant-checkbox-checked{
    .ant-checkbox-inner {
        background-color:${colors.darkGray1};
        border-color:${colors.darkGray1};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
        border-radius: 4px;
        ::after{
          transform: rotate(45deg) scale(1) translate(-56%, -58%);
        }
    }
    ::after {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  .ant-checkbox-disabled {
    .ant-checkbox-inner {
        background: #D5D8DC;
        box-shadow: none;
    }
  }
  :hover{
    .ant-checkbox-inner {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  &.ant-checkbox-wrapper-checked:hover{
    .ant-checkbox-inner {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
`

export const MaxSelectionMessageWrapper = styled.div`
    ${flexCenter};
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 88px;
    box-shadow: ${shadows.toggleShadow};
    border-radius: 4px;
    gap: 8px;
    padding: 8px 12px;
    background: #fff;
    opacity: ${props => props.isVisible ? 1 : 0};
    pointer-events: ${props => props.isVisible ? 'auto' : 'none'};
    transition: all 400ms;
    `;

export const MaxSelectionMessageText = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #FF0000;
    white-space: nowrap;
`;

export const ShowMoreButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
    padding: 14px 0;
    color: ${colors.darkGray2};
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    ${props=>props.isOpen ? `
        svg {
            transform: rotate(180deg);
        }
    ` : ``}
`;