import styled from "@emotion/styled";
import { MenuItemIconWrapper, MenuItemText, MenuItemWrapper, MenuWrapper } from "../../../../../../common/components/DropDownMenu/DropDownMenu.styles";


export const MoreActionsButton = styled.button`
    color: white;
    background: transparent;
    border: 0;
    // aspect-ratio: 1/1;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background: rgba(255,255,255,0.2);
    }
`

export const MoreActionsMenuWrapper = styled(MenuWrapper)`
  background: #000;
  padding: 0;
  border: 1px solid rgba(200,200,200,.3);
  width: fit-content;
`

export const MoreActionsItemWrapper = styled(MenuItemWrapper)`
&:first-of-type {border-radius: 3px 3px 0 0;}
&:first-of-type {border-radius: 0 0 3px 3px;}
&:only-child {border-radius: 3px;}

&:hover {
  background: rgba(255,255,255,.3);
}
${props=>props.isDisabled ? `
  cursor: default;
  opacity: 0.4;
` : ``};
`

export const MoreActionsItemIconWrapper = styled(MenuItemIconWrapper)`
  color: #fff;
`

export const MoreActionsItemText = styled(MenuItemText)`
  color: #fff;
`