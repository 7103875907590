import React from 'react';
import { Observer} from 'mobx-react';
import { useStore } from '../../../../../../app/data/root.store';
import { ClassViewStatInfoBoxWrapper, MobileCarouselBoxWrapper, MobileStatBoxWrapper, MobileStatsWrapper, StatsColumn, StatsRow } from '../../../../asset/components/AssetOverviewHeroContent.styles';
import { StatInfoWrapper } from '../../../../asset/components/Overview/StatInfoBox';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function WealthFiltersHeroDataBox(props) {
  const {uiStore} = useStore();
  return (<Observer>{() => (uiStore.isDesktopView ? <WealthFiltersHeroDataBoxDesktop/> : <WealthFiltersHeroDataBoxMobile />)}</Observer>);
}  

    

function WealthFiltersHeroDataBoxDesktop(props) {
  const {wealthStore, uiStore} = useStore();

  return ( <Observer>{()=>(

      <>
       <StatsRow>
                        {wealthStore.heroFiltersDisplayData?.stats.slice(0, 2).map((stat, ind) => (
                          <StatsColumn key={ind}>
                            <ClassViewStatInfoBoxWrapper isClassView>
                              <StatInfoWrapper isClassView stat={stat} itemData={wealthStore.heroMetricsData} isIncognito={uiStore.isIncognitoMode} isHoldingView={true} hasLLMInProcessItem={wealthStore.hasLLMInProcessItem}/>
                              
                            </ClassViewStatInfoBoxWrapper>
                          </StatsColumn>
                        ))}
                      </StatsRow>
                      {/* <AssetCardMetricsRowSeparator isClassView={true} /> */}
                      <StatsRow>
                        {wealthStore.heroFiltersDisplayData?.stats.slice(2, 4).map((stat, ind) => (
                          <StatsColumn key={ind}>
                            {wealthStore.heroMetricsData.hasOwnProperty(stat.dataKey) &&
                              <ClassViewStatInfoBoxWrapper isClassView>
                                <StatInfoWrapper isClassView stat={stat} itemData={wealthStore.heroMetricsData} isIncognito={uiStore.isIncognitoMode} isHoldingView={true} hasLLMInProcessItem={wealthStore.hasLLMInProcessItem}/>
                              </ClassViewStatInfoBoxWrapper>}
                          </StatsColumn>
                        ))}
                      </StatsRow>
      </>



  )}</Observer>)
}   

function WealthFiltersHeroDataBoxMobile(props) {
  const {wealthStore, uiStore} = useStore();

  return ( <Observer>{()=>(
      <MobileStatsWrapper>
        <Slider dots={false} infinite={true} centerMode={true} arrows={false}>
            {wealthStore.heroFiltersDisplayData?.stats.filter(i=>i.title || i.isArray).map((stat, ind) => (
                          <MobileCarouselBoxWrapper key={ind}>
                            <MobileStatBoxWrapper >
                              <StatInfoWrapper isClassView stat={stat} itemData={wealthStore.heroMetricsData} isIncognito={uiStore.isIncognitoMode} isHoldingView={true} hasLLMInProcessItem={wealthStore.hasLLMInProcessItem}/>
                            </MobileStatBoxWrapper>
                          </MobileCarouselBoxWrapper>
              ))}
        </Slider>
          {/* <StatsRow>
                        {wealthStore.heroFiltersDisplayData?.stats.slice(0, 2).map((stat, ind) => (
                          <StatsColumn key={ind}>
                            <ClassViewStatInfoBoxWrapper isClassView>
                              <StatInfoWrapper isClassView stat={stat} itemData={wealthStore.heroMetricsData} isIncognito={uiStore.isIncognitoMode} isHoldingView={true} />
                              
                            </ClassViewStatInfoBoxWrapper>
                          </StatsColumn>
                        ))}
                      </StatsRow> */}
                      {/* <AssetCardMetricsRowSeparator isClassView={true} /> */}
                      {/* <StatsRow>
                        {wealthStore.heroFiltersDisplayData?.stats.slice(2, 4).map((stat, ind) => (
                          <StatsColumn key={ind}>
                            {wealthStore.heroMetricsData.hasOwnProperty(stat.dataKey) &&
                              <ClassViewStatInfoBoxWrapper isClassView>
                                <StatInfoWrapper isClassView stat={stat} itemData={wealthStore.heroMetricsData} isIncognito={uiStore.isIncognitoMode} isHoldingView={true} />
                              </ClassViewStatInfoBoxWrapper>}
                          </StatsColumn>
                        ))}
                      </StatsRow> */}
      </MobileStatsWrapper>
  )}</Observer>)
}   