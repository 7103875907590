import React , {useState } from 'react';
import {  Input } from "antd";
import PlacesAutocomplete from 'react-places-autocomplete';
import { getTranslations } from '../../../../translations/translations';

export function AddressSearchInput(props) {

    const [address,setAddress] = useState(props.value);

    const handleChange = address => {
        props.valueChanged && props.valueChanged(address);
        setAddress(address);
    };

    const handleSelect = address => {
        props.valueChanged && props.valueChanged(address);
        setAddress(address);
    };

    return (
          
            <PlacesAutocomplete
                value={address||''}
                onChange={handleChange}
                onSelect={handleSelect}
                debounce={300}
            >

{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
  const {LOCATION_SEARCH_INPUT_PLACEHOLDER} = getTranslations().common.address_search_input;
  return (
          <div className="location-search-input-wrapper">
            <Input
              {...getInputProps({
                placeholder: LOCATION_SEARCH_INPUT_PLACEHOLDER,
                disabled: props.disabled
              })}
            />
            {(loading || suggestions.length) ? 
            <div className="location-search-input-suggetions">
              {loading && <div className="location-search-option">Loading...</div>}
              {suggestions.map((suggestion,index) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#f5f5f5', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div key={suggestion+index}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span className="location-search-option">{suggestion.description}</span>
                  </div>
                );
              })}
              <div className="location-search-poweredByGoogleImage">
              <img src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png" alt="Powered by Google"></img>
              </div>
            </div> : null}
          </div>
        )
        }}


            </PlacesAutocomplete>
    )

}