import styled from '@emotion/styled/macro';
import { colors } from '../../../../common/styles/theme.styles';
import { Input } from 'antd';

export const HeaderWrapper = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px 24px;
    border-bottom: 1px solid ${colors.gray3};
`;

export const HeaderTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.darkGray1};
    `;

export const CloseBtnWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const ModalContentWrapper = styled.div`
    padding: 32px 32px 40px ;
    border-bottom: 1px solid ${colors.gray3};
    ${props=>props.isResetSuccessful ? `
        display: flex;
        flex-direction: column;
        align-items: center;
    ` : ``};
`;

export const ModalInputWrapper = styled.div`
    margin-bottom: 32px;
`;

export const ModalInputLabel = styled.div`
    margin-bottom: 4px;
    font-weight: 450;
    font-size: 13px;
    line-height: 16px;
    color: ${colors.darkGray2};
`;

export const ActionButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 12px 24px;
`;

export const ModalLoginPasswordInput = styled(Input.Password)`
    height: 34px;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    background-color:#FFFFFF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    border: none;
    
    &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled){
        border: 1px solid transparent;
    }

    &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
        border: 1px solid #1A1B1D;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
    }

    &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus-within{
        border: 1px solid #1A1B1D;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
    }
`;

export const DataBoxInputWrapper = styled.div`
    margin-bottom: 32px;
    display:flex;
    flex-direction:column;
`;


