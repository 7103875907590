import { Observer } from 'mobx-react';
import React from 'react'
import { useHistory } from "react-router-dom";
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import { getUrlDisplayName } from '../../../../../common/utils/string.utils';
import { ArrowDownIcon } from '../../../../../assets/icons/common/common-icons';
import { ItemIconWrapper } from '../../../../../common/components/ItemLink/ItemLink.styles';
import { useStore } from '../../../../app/data/root.store';
import { AssetClassIcon } from '../../../../assets/components/AssetClassIcon';
import {
    LoanPageConnectedAssetsTxtWrraper,
    LoanPageConnectedAssetTitle, LoanPageConnectedAssetCategory, LoanPageConnectedAssetRowAction, ItemLogoWrapper, LiabilityItemWrapper, ConnectedLiabilitiesTooltipWrapper
} from './AssetOverviewHeroContent.styles';

export const AssetClosedConnectedLiabilities = (props) => {
    const { item } = props;
    let history = useHistory();
    const { metadataStore } = useStore();

    

    return (<Observer>{() => (
        <ConnectedLiabilitiesTooltipWrapper>
            {item.connectedLiabilities.map((connectedItem)=>{
                const itemClassId = metadataStore.itemsMeta[connectedItem.liabilityId].classId;
                const itemClass = metadataStore.classesObj[itemClassId];
                const itemCategoryId = metadataStore.itemsMeta[connectedItem.liabilityId].categoryId;
                const itemCategory = metadataStore.categories.find(category => category.id === itemCategoryId);

                return (  <LiabilityItemWrapper key={connectedItem.liabilityId} onClick={() => navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(itemClass.title)}/${connectedItem.liabilityId}`)}>
                {metadataStore.itemsMeta[connectedItem.liabilityId]?.logoBase64 ?
                    <ItemLogoWrapper>
                        <img alt={metadataStore.itemsMeta[connectedItem.liabilityId]?.connectedInstitutionName} src={'data:image/png;base64,' + metadataStore.itemsMeta[connectedItem.liabilityId]?.logoBase64} />
                    </ItemLogoWrapper> :
                    metadataStore.itemsMeta[connectedItem.liabilityId]?.logoUrl ?
                        <ItemLogoWrapper>
                            <img alt={metadataStore.itemsMeta[connectedItem.liabilityId]?.connectedInstitutionName} src={metadataStore.itemsMeta[connectedItem.liabilityId]?.logoUrl} />
                        </ItemLogoWrapper> :
                        <ItemIconWrapper style={{ marginLeft: '8px' }} color={itemClass.color} >
                            <AssetClassIcon name={itemClass.icon} />
                        </ItemIconWrapper>}
                <LoanPageConnectedAssetsTxtWrraper>
                    <LoanPageConnectedAssetTitle >
                        {metadataStore.itemsMeta[connectedItem.liabilityId].title}
                    </LoanPageConnectedAssetTitle>
                    <LoanPageConnectedAssetCategory>
                        {itemCategory.title}
                    </LoanPageConnectedAssetCategory>
                </LoanPageConnectedAssetsTxtWrraper>
                <LoanPageConnectedAssetRowAction>
                    <ArrowDownIcon />
                </LoanPageConnectedAssetRowAction>
            </ LiabilityItemWrapper>)
            })}
      
        </ConnectedLiabilitiesTooltipWrapper>
    )}</Observer>)

}