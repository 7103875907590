import React from 'react';

export const InstitutionLogo = ({ provider, institution, customStyles, customWrapperStyles }) => {
  let logo = null;
  const { logoBase64, logoUrl, institutionName } = institution;
  const defaultStyle = { borderRadius: '4px', width: '32px', height: '32px' };
  const style = { ...defaultStyle, ...customStyles };
  const defaultWrapperStyle = {};
  const wrapperStyle = { ...defaultWrapperStyle, ...customWrapperStyles }

  if (provider === 'plaid' ) {
    logo = (
      <div style={wrapperStyle}>
        {logoBase64 && <img style={style} alt={institutionName} src={`data:image/png;base64,${logoBase64}`} />}
        {!logoBase64 && logoUrl && <img style={style} alt={institutionName} src={logoUrl} />}
      </div>
    );
  } else if (provider === 'saltEdge' || provider === 'vip') {
    const { logoURL, name } = institution;

    logo = (
      <div style={wrapperStyle}>
        {logoBase64 && <img style={style} alt={name} src={`data:image/png;base64,${logoBase64}`} />}
        {!logoBase64 && logoURL && <img style={style} alt={name} src={logoURL} />}
      </div>
    );
  } 


  return logo;
};