import { Tooltip } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { ClockIcon } from '../../../../../../../assets/icons/common/common-icons'
import { displayMoneyValue } from '../../../../../../../common/utils/number-display.utils'
import { useStore } from '../../../../../../app/data/root.store'
import { EventLabelWithPredictedAmountWrapper, EventPredictedAmount, EventRowClockIconWrapper } from './PredictedAmountDisplay.styles'

export const PredictedAmountDisplay = observer(({ predictedAmount, tooltipText = 'This is an expected event', marginLeft = 0 }) => {
  const { itemStore, uiStore } = useStore();
  const { item } = itemStore;
  const { isIncognitoMode } = uiStore;

  return (
    <EventPredictedAmount marginLeft={marginLeft}>
      {displayMoneyValue(Math.abs(predictedAmount), item.currency, isIncognitoMode, (itemStore.isHoldingsView && item.holdings) ? item.holdings : 100)}
      <EventRowClockIconWrapper>
        <Tooltip title={tooltipText} placement="top" overlayStyle={{maxWidth:'300px'}} >
          <ClockIcon />
        </Tooltip>
      </EventRowClockIconWrapper>
    </EventPredictedAmount>
  )
})

export const createLabelFnWithPredictedValue = (label, predictedAmount, predictedToolTipText) => {
  return () =>{
    return <EventLabelWithPredictedAmountWrapper>
        {label}
        {predictedAmount ?  
            <PredictedAmountDisplay 
                predictedAmount={predictedAmount} 
                marginLeft={8}
                tooltipText={predictedToolTipText} /> 
        : null}
    </EventLabelWithPredictedAmountWrapper>
  } 
}