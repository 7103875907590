import React from 'react'
import { ActualMetricBar, ItemMetricBarsWrapper, TargetMetricBar } from './OverviewTab.styles';

export const maxMetricGraphBarHeight = 48;
export const ItemMetricBars = (props) => {
    const { stat, itemData, selectedTimeFrameIndex } = props;
    const actualValue = itemData[stat.dataKey][selectedTimeFrameIndex].actual;
    const targetValue = itemData[stat.dataKey][selectedTimeFrameIndex].target;

    return ( (typeof actualValue === 'number' && typeof targetValue === 'number') ?
        // (actualValue > 0 || targetValue > 0 || true) &&
        <ItemMetricBarsWrapper>
            {/* {parseFloat(targetValue.toFixed(2))}{stat.type === 'perc' ? '%' : 'x'} */}
            <TargetMetricBar height={targetValue >= actualValue && targetValue !== 0 ? maxMetricGraphBarHeight : targetValue === 0 ? '2' : ''+ targetValue / actualValue * maxMetricGraphBarHeight} />
            <ActualMetricBar height={actualValue >= targetValue && actualValue !== 0 ? maxMetricGraphBarHeight : actualValue === 0 ? '2' : ''+ actualValue / targetValue * maxMetricGraphBarHeight} />
            {/* {targetValue >= actualValue && targetValue !== 0 ? <TargetMetricBar height={32} /> : targetValue === 0 ? <TargetMetricBar height={2} /> : <TargetMetricBar height={targetValue / actualValue * 32} />}
            {actualValue >= targetValue && actualValue !== 0 ? <ActualMetricBar height={32} /> : actualValue === 0 ? <ActualMetricBar height={2} /> : <ActualMetricBar height={actualValue / targetValue * 32} />} */}
            {/* {parseFloat(actualValue.toFixed(2))}{stat.type === 'perc' ? '%' : 'x'} */}
        </ItemMetricBarsWrapper> : null
    )
}

