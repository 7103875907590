import React, { useEffect, useRef } from 'react';
import { Observer } from 'mobx-react';
import { useHistory } from 'react-router-dom'
import { useStore } from '../../app/data/root.store';
import { Drawer } from "antd";
import { ApplyFilterButton, ClearFilterButton, FiltersPanelContentWrapper, HeaderCloseButton, HeaderTitle, PanelFooter, PanelHeader, PanelWrapper, ParameterPanelWrapper, ParamHeaderWrapper, ParamIconWrapper, ParamTitle, ParamTitleSelectionCount } from './FiltersPanel.styles';
import { ArrowDownIcon, ModalCloseIcon } from '../../../assets/icons/common/common-icons';
import { FilterParameterIcon } from './FilterParameterIcon';
import { ParamTypeSwitcher } from './filters-parameters-types/FilterParameterTypeSwitcher';
import { navigateToUrl } from '../../../common/utils/navigation.utils';
import { appliedFiltersEvents } from '../data/filters.mixpanel.events';
import { filtersOrder } from '../data/filters.store';
import { useAnalytics } from '../../app/data/AnalyticsProvider';
import { ROUTES } from '../../../common/constants/routes.const';

export function FiltersPanel(props) {
    const { filtersStore , uiStore} = useStore();
    
    // console.log(JSON.stringify(filtersStore.appliedFilters));

    const handleFiltersPanelContentOnClose = () => {
        filtersStore.closeAllParameters();
        filtersStore.setShowDrawer(false);
    }

    return (<Observer>{() => (
        <Drawer
            closable={false}
            visible={filtersStore.showDrawer}
            width={  340  }
            height={'calc(100% - 32px)'}
            className={uiStore.isDesktopView ? 'filtersDrawerContainer' : 'filtersDrawerContainer filtersDrawerContainer_mobile'}
            placement={uiStore.isDesktopView ? 'left' : 'bottom'}
            destroyOnClose={true}
            onClose={handleFiltersPanelContentOnClose} >
            <FiltersPanelContent />
        </Drawer>
    )}</Observer>)
}

function FiltersPanelContent(props) {
    const { filtersStore, wealthStore } = useStore();
    let history = useHistory();
    const { mixpanelTrack } = useAnalytics()

    const handleClearFilterButtonOnClick = () => {
        filtersStore.handleClearFilterSelections(false);
        filtersStore.closeAllParameters();
    }

    const handleApplyFilterButtonOnClick = () => {
        if (!filtersStore.isApplyFilterDisabled){
            filtersStore.setAppliedFiltersFromSelection();
            // filtersStore.setIsAnyFilterApplied(true);
            filtersStore.setShowDrawer(false);
            navigateToUrl(history, ROUTES.wealthOverview.url);
            wealthStore.refreshWealthData();
            mixpanelTrack('Filters Applied', appliedFiltersEvents(filtersStore.appliedFilters))
        }
    }

    const handleHeaderCloseButtonOnClick = () => {
        filtersStore.closeAllParameters();
        filtersStore.setShowDrawer(false);
    }

    const applyButton = useRef()
    const handleKeyboardEvent = (e) => {
        if (e.key === 'Enter'){
            applyButton.current && applyButton.current.click()
        } 
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyboardEvent)
        return () => {
            window.removeEventListener('keydown', handleKeyboardEvent)
            filtersStore.setSelectedStateBasedOnAppliedFilters()
        };
    }, [filtersStore])


    return (<Observer>{() => (
        <PanelWrapper>
            <PanelHeader>
                <HeaderTitle>Apply Filters</HeaderTitle>
                <HeaderCloseButton onClick={handleHeaderCloseButtonOnClick}><ModalCloseIcon /> </HeaderCloseButton>
            </PanelHeader>
            <FiltersPanelContentWrapper>
                {filtersStore.parameters.map((param, ind) => (
                    <ParameterPanelWrapper key={filtersOrder[ind]} >
                        <ParamHeader paramKey={filtersOrder[ind]} paramIndex={ind} param={param} />
                        {/* <ParamPanelAnimationWrapper isOpen={param.isOpen} > */}
                        {param.isOpen && <ParamTypeSwitcher paramIndex={ind} param={param} />}
                        {/* </ParamPanelAnimationWrapper> */}
                    </ParameterPanelWrapper>
                ))}
            </FiltersPanelContentWrapper>
            <PanelFooter>
                <ClearFilterButton onClick={handleClearFilterButtonOnClick}>Clear</ClearFilterButton>
                <ApplyFilterButton ref={applyButton} isDisabled={filtersStore.isApplyFilterDisabled} 
                    onClick={()=> handleApplyFilterButtonOnClick() }>Apply {filtersStore.pendingFiltersCount > 1 ? `${filtersStore.pendingFiltersCount} filters` : filtersStore.pendingFiltersCount === 1 ? `filter` : '' } </ApplyFilterButton>
            </PanelFooter>
        </PanelWrapper>
    )}</Observer>)
}

function ParamHeader({ paramIndex, paramKey, param }) {
    const { filtersStore } = useStore();

    return (<Observer>{() => (
        <ParamHeaderWrapper onClick={() => { filtersStore.setParameterIsOpen(paramIndex, !param.isOpen) }} isOpen={param.isOpen}>
            <ParamIconWrapper>
                <FilterParameterIcon name={paramKey} />
            </ParamIconWrapper>
            <ParamTitle>{param.title}</ParamTitle>
            {param.selectedCount > 0 ?
                <ParamTitleSelectionCount>{param.selectedCount}</ParamTitleSelectionCount> :
                <ArrowDownIcon style={{ transition: '0.2s', transform: ` rotate(${param.isOpen ? -180 : 0}deg)` }} />}
        </ParamHeaderWrapper>
    )}</Observer>)
}


