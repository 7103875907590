import { DatePicker, Dropdown, Drawer } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ArrowDownIcon, ModalCloseIcon } from '../../../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../../../common/components/BaseModal/BaseModal';
import { CommonBadge } from '../../../../../../common/components/CommonBadge/CommonBadge';
import { DropDownButtonText, DropDownButtonWrapper } from '../../../../../../common/components/buttons/Buttons.styles';
import { FieldLabel } from '../../../../../../common/components/form-displayer/FormDisplayer.styles';
import { enabledBetween } from '../../../../../../common/utils/dates.utils';
import { displayMoneyValue } from '../../../../../../common/utils/number-display.utils';
import { isNullOrUndefinedOrEmptyString } from '../../../../../../common/utils/object.utils';
import { useStore } from '../../../../../app/data/root.store';
import { AssetClassIcon } from '../../../../../assets/components/AssetClassIcon';
import { FormWithStore } from '../../../../../form-displayer-connector/components/FormWithStore';
import { useFormHook } from '../../../../../form-displayer-connector/data/useFormHook';
import { getEventCreatedMixpanelData } from '../../../../data/wealth.mixpanel-events';
import { fetchCryptoCoinValueAtDate, fetchPpsAtDate } from '../../../../data/wealth.service';
import { ActualsMenuItem, ActualsMenuItemTitle, ActualsMenuWrapper } from '../AssetPage.styles';
import { ItemIconAndTitleWrapper, ItemIconWrapper, ItemLogoWrapper, ItemTitleOverflowText, ItemTitleWrapper, VerticalSeparator } from '../AssetPageHeader.styles';
import { CreateEventModalButton, CreateEventModalClose, CreateEventModalContent, CreateEventModalHeader, CreateEventModalTitle, EventTypeDropdownWrapper, EventTypeFieldMessage, EventTypeFieldWrapper, EventTypeStyle, EventValuesAtDateWrapper, ExtraFooterTextWrapper, FormStepFooter, StepWrapper } from './CreateEvent.styles';
import { customValueAtDateText, customValueAtDateType, findAttributeValueAtDate, findValueAtDate } from './EditEvent.utils';
import { HoldingsBadgeText, HoldingsBadgeTriangle, HoldingsBadgeWrapper } from './LinkEventTransactionMenu.styles';
import { ASSET_CATEGORIES_CREATE_EVENT, ASSET_CATEGORIES_DISPLAY_ATTRIBUTES_VALUE_AT_DATE, DEFAULT_EVENTS_TYPES } from './constants/createEvent.events.const';
import { useAnalytics } from '../../../../../app/data/AnalyticsProvider';


export const CreateEvent = (props) => {
    const { uiStore } = useStore();
    return (
        <Observer>{() => (
            uiStore.isDesktopView ? 
            ReactDOM.createPortal(<BaseModal width={'512px'} height={'auto'} borderRadius={8}>
                <CreateEventContent {...props} />
            </BaseModal> , document.body) : 
            <Drawer
                closable={false}
                visible={true}
                width={  340  }
                height={'100%'}
                className={'editEventDrawerContainer_mobile'}
                placement={'bottom'}
                destroyOnClose={true}
                onClose={()=>{ props.onClose()}} >
             <CreateEventContent {...props} /> 
        </Drawer>
        )}</Observer>
    )
}

export const CreateEventContent = (props) => {
    const { id, eventTypeIndex, item, onClose } = props;
    const formName = 'createEvent';
    const [eventType, setEventType] = useState(eventTypeIndex ?? -1);
    const [eventDate, setEventDate] = useState(moment());
    const [valueAtDate, setValueAtDate] = useState();
    const { eventsStore, uiStore, wealthStore, itemStore, userStore } = useStore();
    const [eventFormData, setEventFormData] = useState({});
    const [sectionsState, setSectionsState] = useState([]);
    const createEventForm = useFormHook(formName);
    const { mixpanelTrack } = useAnalytics()
    const [isLoading, setIsLoading] = useState(false);
    const [shouldFetchPpsAtDate, setShouldFetchPpsAtDate] = useState(false);

    const { isDemoMode } = userStore;

    const handleCreateEvent = async () => {
        const today = new Date(); // .toISOString();
        const relevantDate = moment(eventDate || today).utc().set('D',15).startOf('D');
        const { values } = createEventForm;
        const sectionValidations = createEventForm.currentSectionErrors;
        if (!sectionValidations.isValid){
            createEventForm.setErrors(sectionValidations.errors);
            createEventForm.setBottomErrors(sectionValidations.bottomErrors);
            return false;
        } 

        const eventBody = {
            date: relevantDate,
            title: createEventTypes[eventType].title || null,
            remarks: values.eventRemarks,
            eventType: createEventTypes[eventType].eventType || createEventTypes[eventType].title || 'Custom',
            // description: createEventTypes[eventType].title || 'Custom',
            creationType: 'manually',
            eventData: {
                transactions:[ 
                    ...(values.transactionValue ? [
                        {
                            date: relevantDate,
                            value: createEventTypes[eventType].isDistribution ? values.transactionValue : values.transactionValue * -1
                        }
                    ] : []),
                    ...(createEventTypes[eventType].otherTrnsFn ? createEventTypes[eventType].otherTrnsFn(eventDate, values, []) : [])
                ],
                attributes: createEventTypes[eventType].attributesToSend ?
                    createEventTypes[eventType].attributesToSend.map(attr => {
                        const attrValue = attr.setFnForSave ? attr.setFnForSave(createEventTypes[eventType].isDistribution, values[attr.fieldKey] , values) : values[attr.fieldKey]
                        if (attrValue === null) {return null}
                        return {
                        date: relevantDate,
                        data: {
                            [attr.dataKey]: attrValue
                            // [attr.dataKey]: values[attr.fieldKey]
                        }
                    }}).filter(attr=>attr) : [],
                // valueUpdates: ((values.updatedValue || values.updatedValue === 0) && (+values.updatedValue !== +valueAtDate)) ? [
                valueUpdates: ((values.updatedValue || values.updatedValue === 0) ) ? [
                    {
                        date: relevantDate,
                        value: values.updatedValue
                    }
                ] : []
            }
        }
        setIsLoading(true);
        const { date: createdEventDate } = await eventsStore.createEvent(id, eventBody, itemStore);
        wealthStore.setGetLastChangesDataAfterExitPage();
        if (createEventTypes[eventType].showTargetModalAfterCreate){
            const haveTargets = itemStore.itemEvents.slice().reverse().find((eve)=> !eve.id && !eve.eventId);
            if (haveTargets){
                itemStore.setShowEditTargetModal(true,createEventTypes[eventType].targetFieldLink)
            }
        }
        mixpanelTrack('Event Created', getEventCreatedMixpanelData(createEventForm, createdEventDate, eventType, isDemoMode));
        onClose && onClose();
    }

    const createEventTypes = ASSET_CATEGORIES_CREATE_EVENT.hasOwnProperty(item.categoryId) ? ASSET_CATEGORIES_CREATE_EVENT[item.categoryId] : DEFAULT_EVENTS_TYPES;
    const isOnlySingleCreateEventType = createEventTypes?.filter(event => !event.isPredicted && !event.isHidden)?.length === 1;

    useEffect(() => {
        setSectionsState(() => createEventTypes[eventType] ? createEventTypes[eventType].sections : []);
        
        // set form data
        const formAttributes = {}
        if (createEventTypes[eventType]?.valuesForEditFromItem) {
            // console.log(createEventTypes[eventTypeIndex].isDistributionReversed);
            createEventTypes[eventType].valuesForEditFromItem.forEach(element => {
                // console.log(element.dataKey);
                formAttributes[element.dataKey] = element.getEditValueFn ? element.getEditValueFn({value: item[element.itemDataKey] }) :  item[element.itemDataKey];
            });
        }
        if (createEventTypes[eventType]?.valuesForCreateEventFromItem) {
            // console.log(createEventTypes[eventTypeIndex].isDistributionReversed);
            createEventTypes[eventType].valuesForCreateEventFromItem.forEach(element => {
                // console.log(element.dataKey);
                formAttributes[element.dataKey] = element.getEditValueFn ? element.getEditValueFn({value: item[element.itemDataKey] }) :  item[element.itemDataKey];
            });
        }
        setEventFormData({
            ...item,
            currentItemValue: item?.value,
            ...formAttributes
        })

        return () => {
            // cleanup
        }
    }, [eventType, item.categoryId, createEventTypes, item])

    useEffect(() => {
        const valueAtDate = findValueAtDate(itemStore?.itemEvents || [] ,eventDate ,null); // item.valueUpdates.find(vu=>moment(vu.date) < moment());
        setValueAtDate(valueAtDate ? valueAtDate.value : 0);

        const displayedAttributesAtDate = ASSET_CATEGORIES_DISPLAY_ATTRIBUTES_VALUE_AT_DATE[item.categoryId] || [];
        let attributesAtDate = {}
        // console.log(itemStore?.item?.attributesHistory);
        for (let index = 0; index < displayedAttributesAtDate.length; index++) {
            const atrDisplayObj = displayedAttributesAtDate[index];
            // console.log(itemStore?.item?.attributesHistory ,atrDisplayObj.dataKey , itemStore?.item?.attributesHistory[atrDisplayObj.dataKey] );
            const atrAtDate = findAttributeValueAtDate(itemStore?.itemEvents || [], atrDisplayObj.dataKey, eventDate , null)
            attributesAtDate[atrDisplayObj.dataKey+'AtDate'] = atrAtDate ? atrAtDate.data[atrDisplayObj.dataKey] : 0;
        }
        
        valueAtDate && setTimeout(() => {
            createEventForm && createEventForm.updateValue('valueAtDate', valueAtDate.value);
            for (const propKey in attributesAtDate){
                createEventForm && createEventForm.updateValue(propKey,  attributesAtDate[propKey]);
            }
        }, 0)
        return () => {
            // cleanup
        }
    }, [item.valueUpdates,eventDate,createEventForm,eventType, itemStore?.itemEvents, item.categoryId ])

    useEffect(() => {
        const fetchStockPriceAtDate = async () => {
            try {
                const price = await fetchPpsAtDate(item.stock?.symbol, item.stock?.exchange, item.stock?.currency, moment(eventDate).endOf('M').format('YYYY-MM-DD'), item.currency);
                if (price) {
                    setTimeout(() => {
                        if (createEventForm){
                            createEventForm.updateValue('pricePerShare', parseFloat(price).toFixed(2));
                            if (createEventForm.values.newNumberOfShares && createEventForm.values.hasOwnProperty('transactionValue') ){
                                createEventForm.updateValue('transactionValue', parseFloat(createEventForm.values.newNumberOfShares * price).toFixed(2));
                            }
                        }  
                    }, 0);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setShouldFetchPpsAtDate(false)
            }
        };
        const fetchCryptoCoinPriceAtDate = async () => {
            try {
                const price = await fetchCryptoCoinValueAtDate(item?.cryptoCoin?.symbol, moment(eventDate).endOf('M').format('YYYY-MM-DD'),item?.currency);
                if (price) {
                    setTimeout(() => {
                        if (createEventForm){
                            const itemCurrencyRate = price;
                            createEventForm.updateValue('newPricePerCoin', parseFloat(itemCurrencyRate).toFixed(2));
                            if (createEventForm.values.newQuantity && !createEventForm.values.doNotUpdateTransactionValue){
                                createEventForm.updateValue('transactionValue', parseFloat(createEventForm.values.newQuantity * itemCurrencyRate).toFixed(2));
                            }
                        }  
                    }, 0);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setShouldFetchPpsAtDate(false)
            }
        };
        if (item.categoryId === 39 && item?.stock?.symbol && item.currency && shouldFetchPpsAtDate) {
            fetchStockPriceAtDate();
        }
        if (item.categoryId === 36 && item?.cryptoCoin?.symbol && item.currency && shouldFetchPpsAtDate) {
            fetchCryptoCoinPriceAtDate();
        }
    }, [item.categoryId, item.stock?.symbol, item.stock?.currency, item.stock?.exchange, item.cryptoCoin?.symbol, item.currency, eventDate, createEventForm, shouldFetchPpsAtDate]);    


    const handleEventTypeDropdownSelect = (index) => {
        setEventType(index);
        // const valueAtDate = item.valueUpdates.find(vu=>moment(vu.date) < eventDate);
        // if (valueAtDate){
        //     setValueAtDate(valueAtDate.value);
        //     setTimeout(()=>{
        //         createEventForm.updateValue('valueAtDate',valueAtDate.value);
        //     },0)
            
        // }
    }

    useEffect(() => {
        if(isOnlySingleCreateEventType) {
            setEventType(0);
        }
      return () => { }
    }, [isOnlySingleCreateEventType])

    const handleEventDateChange = (momentDate) => {
        setEventDate(momentDate);
        setShouldFetchPpsAtDate(true);
    }

    const getValueAtDateTextAndValue = () => {
        const valueAtDateText = customValueAtDateText[itemStore.item.category.id] || 'Value at date:';
        const valueAtDateType = customValueAtDateType[itemStore.item.category.id] || 'money';
        const displayedAttributesAtDate = ASSET_CATEGORIES_DISPLAY_ATTRIBUTES_VALUE_AT_DATE[itemStore.item.category.id] || [];
        const isHiddenValueAtDate = createEventTypes[eventType].hideValueAtDate
        const hiddenAttributesAtDate = createEventTypes[eventType].hiddenAdditionalDataAtDate || [];
        return (
            <EventValuesAtDateWrapper>
            {!isHiddenValueAtDate ? <EventTypeFieldMessage>
                {valueAtDateText}&nbsp;
                { valueAtDateType === 'money' && displayMoneyValue(valueAtDate, itemStore.item.currency, uiStore.isIncognitoMode, (itemStore.isHoldingsView && itemStore.item.holdings) ? itemStore.item.holdings : 100) }
                { valueAtDateType === 'number' && ( valueAtDate || 0 ) }
            </EventTypeFieldMessage> : null}
            {displayedAttributesAtDate.filter(da=>!hiddenAttributesAtDate.includes(da.dataKey)).map((da)=>(
                <EventTypeFieldMessage key={da.dataKey}>
                    {da.title}&nbsp;
                    { da.type === 'money' && displayMoneyValue(createEventForm?.values[da.dataKey+'AtDate'] || 0, itemStore.item.currency, uiStore.isIncognitoMode, (itemStore.isHoldingsView && itemStore.item.holdings) ? itemStore.item.holdings : 100) }
                    { da.type  === 'number' && ( createEventForm?.values[da.dataKey+'AtDate'] || 0 ) }
                </EventTypeFieldMessage>
            ))}
            </EventValuesAtDateWrapper>
        )
    }

    const startDate = moment(item.startDate);
    const [isShowingDatePickerExtraFooter, setIsShowingDatePickerExtraFooter] = useState(moment(startDate).year() === moment().year() && moment(startDate).month() !== 0);

    const ExtraFooter = () => {
        return (<ExtraFooterTextWrapper>
          Events cannot be created before the 'Item created' event ({moment(item.startDate).format('MMMM YYYY')})
        </ExtraFooterTextWrapper>)
    }
        
    return (
        <Observer>{() => (<>
        
   

    {/* return ReactDOM.createPortal(
        <Observer>{() => ( */}
            {/* <BaseModal width={'512px'} height={'auto'} borderRadius={8}> */}
                <CreateEventModalHeader>
                    <CreateEventModalTitle>
                        Create new event
                    </CreateEventModalTitle>

                    <VerticalSeparator />

                    <ItemIconAndTitleWrapper>
                        {itemStore?.item?.logoBase64 ?
                            <ItemLogoWrapper iconWidth={'28px'} iconHeight={'28px'}><img alt={itemStore.item.connectedInstitutionName} src={'data:image/png;base64,' + itemStore?.item?.logoBase64} /></ItemLogoWrapper>
                            :
                            itemStore?.item?.logoUrl ?
                                <ItemLogoWrapper iconWidth={'28px'} iconHeight={'28px'}><img alt={itemStore.item.connectedInstitutionName} src={itemStore?.item?.logoUrl} /></ItemLogoWrapper>
                                :
                                <ItemIconWrapper iconWidth={'28px'} iconHeight={'28px'} color={itemStore.item.categoryClass.color}>
                                    <AssetClassIcon name={itemStore.item.categoryClass.icon} />
                                </ItemIconWrapper>}
                        <ItemTitleWrapper style={{width:'calc(100% - 64px)'}}>
                            <ItemTitleOverflowText>
                                {itemStore.item.category.id === 39 ? 
                                <>
                                    {itemStore.item.title}, {itemStore.item.type}{itemStore.item.name ? `, ${itemStore.item.name}` : ''}
                                </> : 
                                <>
                                    {itemStore.item.title !== itemStore.item.name && itemStore.item.name ? `${itemStore.item.name}, ` : ``}
                                    {itemStore.item.title}
                                </>}
                            </ItemTitleOverflowText>

                            {itemStore.item.closedAt && <CommonBadge badgeType={'closed'} />}
                            {!isNullOrUndefinedOrEmptyString(itemStore.item.holdings) && itemStore.item.holdings !== 100 &&
                                <HoldingsBadgeWrapper style={{ marginLeft: '8px' , marginTop:'2px' }}>
                                    <HoldingsBadgeText>
                                        {itemStore.item.holdings}% Ownership
                                    </HoldingsBadgeText>
                                    <HoldingsBadgeTriangle />
                                </HoldingsBadgeWrapper>
                            }
                        </ItemTitleWrapper>
                    </ItemIconAndTitleWrapper>

                    <CreateEventModalClose onClick={onClose}>
                        <ModalCloseIcon />
                    </CreateEventModalClose>
                </CreateEventModalHeader>

                <StepWrapper id="create-event-modal-form-wrapper">
                    <CreateEventModalContent>
                        <EventTypeDropdownWrapper>
                            <EventTypeStyle>
                                <EventTypeFieldWrapper>
                                    <FieldLabel className={"fd-inputLabel"}>Choose event</FieldLabel>
                                    <Dropdown
                                        overlay={
                                            CreateEventTypeMenu({
                                                events: createEventTypes,
                                                setItem: (index) => handleEventTypeDropdownSelect(index),
                                                isValueDisabled: item.plaidConnectedAccountId && !item.isLoginRequired,
                                            })
                                        }
                                        trigger={['click']} placement="bottomLeft"
                                        disabled={isOnlySingleCreateEventType}
                                    >
                                        <DropDownButtonWrapper style={{ backgroundColor: 'white' }}>
                                            <DropDownButtonText>{createEventTypes[eventType] ? createEventTypes[eventType].title : 'Select event'}</DropDownButtonText>
                                            {!isOnlySingleCreateEventType ? <ArrowDownIcon /> : null}
                                        </DropDownButtonWrapper>
                                    </Dropdown>
                                </EventTypeFieldWrapper>
                                <EventTypeFieldWrapper>
                                    <FieldLabel className={"fd-inputLabel"}>Date</FieldLabel>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        picker="month"
                                        inputReadOnly
                                        disabledDate={(currentDate) => enabledBetween(currentDate, item.startDate, new Date())}
                                        allowClear={false}
                                        value={eventDate}
                                        format={(val) => moment(val).format("MMMM YYYY")}
                                        onChange={(val) => {
                                            handleEventDateChange(val ? val : moment())
                                        }}
                                        onPanelChange={(val) => {
                                            const valYear = moment(val).year();
                                            setIsShowingDatePickerExtraFooter(
                                              valYear < startDate.year() || (valYear === startDate.year() && startDate.month() !== 0)
                                            );
                                        }}
                                        renderExtraFooter={ uiStore.isDesktopView && isShowingDatePickerExtraFooter ? ExtraFooter : null}
                                        dropdownClassName='createEventDatePicker'
                                    />
                                    {!createEventForm?.values.isInfoAtDateHidden ? getValueAtDateTextAndValue() : null}
                                </EventTypeFieldWrapper>
                            </EventTypeStyle>
                        </EventTypeDropdownWrapper>
                        <FormWithStore formName={formName} sections={sectionsState} 
                            isIncognito={uiStore.isIncognitoMode} 
                            defaultValues={eventFormData} 
                        scrollContainerId={'create-event-modal-form-wrapper'} />

                    </CreateEventModalContent>
                </StepWrapper>
                <FormStepFooter>
                    <CreateEventModalButton onClick={handleCreateEvent}>
                        {isLoading === true ? <LoadingOutlined /> : 'Create'}
                    </CreateEventModalButton>
                </FormStepFooter>
            {/* </BaseModal> */}
    </>)}</Observer>)
    //     )}
    // </Observer>, document.body)

}



function CreateEventTypeMenu(props) {
    return (
        <ActualsMenuWrapper>
            {props.events.map((event, index) => (
                event.isPredicted || event.isHidden ? null :
                <ActualsMenuItem onClick={() => { props.setItem(index) }} key={index} isHidden={index === 0 && props.isValueDisabled && !event.isAlwaysVisible} >
                    <ActualsMenuItemTitle>{event.title}</ActualsMenuItemTitle>
                    {/* <ActualsMenuItemDescription>{event.description}</ActualsMenuItemDescription> */}
                </ActualsMenuItem>
            ))}
        </ActualsMenuWrapper>
    )
}