import { observer } from 'mobx-react';
import React from 'react';
import { BaseModal } from '../../common/components/BaseModal/BaseModal';
import { PrimaryButton } from '../../common/components/buttons/NewButtons.styles';
import { ReactComponent as PfsIllustration } from './assets/pfs-illustration.svg';
import { ModalCloseButtonWrapper, RequestPfsModalSubTitle, RequestPfsModalTitle } from './components/Reports.styles';

export const RequestPfsModal = observer(({ onClose }) => {

  return (
    <BaseModal width={'640px'} height={'352px'} borderRadius={8}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', padding: '32px 0 40px 0' }}>
        <PfsIllustration />
        <RequestPfsModalTitle>Processing Your PFS</RequestPfsModalTitle>
        <RequestPfsModalSubTitle>Your Personal Financial Statement (PFS) will be emailed to you within 48 hours, in a password-protected file.</RequestPfsModalSubTitle>
        <PrimaryButton style={{ width: '140px', height: '43px', borderRadius: '8px' }} onClick={onClose}>Got it</PrimaryButton>
        <ModalCloseButtonWrapper onClick={onClose}><ModalCloseButton /></ModalCloseButtonWrapper>
      </div>
    </BaseModal>
  )
})

const ModalCloseButton = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="6.28033" y1="5.83362" x2="18.3011" y2="17.8544" stroke="#9097A3" strokeWidth="1.5" />
      <line x1="5.92682" y1="17.8544" x2="17.9476" y2="5.83362" stroke="#9097A3" strokeWidth="1.5" />
    </svg>
  )
}