import { Observer } from 'mobx-react';
import React from 'react';
import { CommunityIcon, MobileBackButton } from '../../../../assets/icons/common/common-icons';
import { useStore } from "../../../app/data/root.store";
import { WealthHeroTitle } from '../../../wealth/pages/overview/components/hero/WealthHeroTitle';
import { CommunityFiltersPieChart } from '../charts/CommunityFiltersPieChart/CommunityFiltersPieChart';
import { CommunityPieChart } from '../charts/CommunityPieChart/CommunityPieChart';
import { ChartWrapper, HeroContent, HeroGraphPane, HeroHeader, HeroMetricsPane, HeroTitle, HeroWrapper } from './CommunityOverviewHero.styles';
import { CommunityOverviewHeroData } from './CommunityOverviewHeroData';
import { MobileBackButtonWrapper } from '../../../layout/components/MobileLayout.styles';

export function CommunityOverviewHero() {
  const { communityStore, uiStore  } = useStore();

  const handleBackButtonClick = () => {
    if ( communityStore.selectedClassId === 0  ){
      uiStore.setIsAppMobileMenuOpen(true);
    } else {
      communityStore.setUnSelectedClass();
    }
  }

  return (<Observer>{() => (

    <HeroWrapper borderlessBottom>
      <HeroHeader isFixed={uiStore.isMobileHeaderFixed}>
        {!uiStore.isDesktopView && <MobileBackButtonWrapper onClick={handleBackButtonClick}><MobileBackButton /></MobileBackButtonWrapper>}
        <HeroTitle>  
          {communityStore.isOverviewView || communityStore.classFilterTagForHeaderIcon.length === 0 ?
            <><CommunityIcon /> Community</>
            :
            <WealthHeroTitle tag={communityStore.classFilterTagForHeaderIcon} isCommunity />
          }

        </HeroTitle>
      </HeroHeader>
      <HeroContent>
        

          <HeroGraphPane>
            <ChartWrapper>
              {communityStore.isOverviewView ?
                <CommunityPieChart height={276} /> : <CommunityFiltersPieChart height={276} />}
            </ChartWrapper>
          </HeroGraphPane>

          <HeroMetricsPane>
            <CommunityOverviewHeroData />
          </HeroMetricsPane>

        
      </HeroContent>
    </HeroWrapper>
  )}</Observer>)
}   