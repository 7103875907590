// Country names object using 3-letter country codes to reference country name
// ISO 3166 Alpha-3 Format: [3 letter Country Code]: [Country Name]
// Sorted alphabetical by country name (special characters on bottom)
export const countryListAlpha3 = {
    "AFG": "Afghanistan",
    "ALB": "Albania",
    "DZA": "Algeria",
    "ASM": "American Samoa",
    "AND": "Andorra",
    "AGO": "Angola",
    "AIA": "Anguilla",
    "ATA": "Antarctica",
    "ATG": "Antigua and Barbuda",
    "ARG": "Argentina",
    "ARM": "Armenia",
    "ABW": "Aruba",
    "AUS": "Australia",
    "AUT": "Austria",
    "AZE": "Azerbaijan",
    "BHS": "Bahamas (the)",
    "BHR": "Bahrain",
    "BGD": "Bangladesh",
    "BRB": "Barbados",
    "BLR": "Belarus",
    "BEL": "Belgium",
    "BLZ": "Belize",
    "BEN": "Benin",
    "BMU": "Bermuda",
    "BTN": "Bhutan",
    "BOL": "Bolivia (Plurinational State of)",
    "BES": "Bonaire, Sint Eustatius and Saba",
    "BIH": "Bosnia and Herzegovina",
    "BWA": "Botswana",
    "BVT": "Bouvet Island",
    "BRA": "Brazil",
    "IOT": "British Indian Ocean Territory (the)",
    "BRN": "Brunei Darussalam",
    "BGR": "Bulgaria",
    "BFA": "Burkina Faso",
    "BDI": "Burundi",
    "CPV": "Cabo Verde",
    "KHM": "Cambodia",
    "CMR": "Cameroon",
    "CAN": "Canada",
    "CYM": "Cayman Islands (the)",
    "CAF": "Central African Republic (the)",
    "TCD": "Chad",
    "CHL": "Chile",
    "CHN": "China",
    "CXR": "Christmas Island",
    "CCK": "Cocos (Keeling) Islands (the)",
    "COL": "Colombia",
    "COM": "Comoros (the)",
    "COD": "Congo (the Democratic Republic of the)",
    "COG": "Congo (the)",
    "COK": "Cook Islands (the)",
    "CRI": "Costa Rica",
    "HRV": "Croatia",
    "CUB": "Cuba",
    "CUW": "Curaçao",
    "CYP": "Cyprus",
    "CZE": "Czechia",
    "CIV": "Côte d'Ivoire",
    "DNK": "Denmark",
    "DJI": "Djibouti",
    "DMA": "Dominica",
    "DOM": "Dominican Republic (the)",
    "ECU": "Ecuador",
    "EGY": "Egypt",
    "SLV": "El Salvador",
    "GNQ": "Equatorial Guinea",
    "ERI": "Eritrea",
    "EST": "Estonia",
    "SWZ": "Eswatini",
    "ETH": "Ethiopia",
    "FLK": "Falkland Islands (the) [Malvinas]",
    "FRO": "Faroe Islands (the)",
    "FJI": "Fiji",
    "FIN": "Finland",
    "FRA": "France",
    "GUF": "French Guiana",
    "PYF": "French Polynesia",
    "ATF": "French Southern Territories (the)",
    "GAB": "Gabon",
    "GMB": "Gambia (the)",
    "GEO": "Georgia",
    "DEU": "Germany",
    "GHA": "Ghana",
    "GIB": "Gibraltar",
    "GRC": "Greece",
    "GRL": "Greenland",
    "GRD": "Grenada",
    "GLP": "Guadeloupe",
    "GUM": "Guam",
    "GTM": "Guatemala",
    "GGY": "Guernsey",
    "GIN": "Guinea",
    "GNB": "Guinea-Bissau",
    "GUY": "Guyana",
    "HTI": "Haiti",
    "HMD": "Heard Island and McDonald Islands",
    "VAT": "Holy See (the)",
    "HND": "Honduras",
    "HKG": "Hong Kong",
    "HUN": "Hungary",
    "ISL": "Iceland",
    "IND": "India",
    "IDN": "Indonesia",
    "IRN": "Iran (Islamic Republic of)",
    "IRQ": "Iraq",
    "IRL": "Ireland",
    "IMN": "Isle of Man",
    "ISR": "Israel",
    "ITA": "Italy",
    "JAM": "Jamaica",
    "JPN": "Japan",
    "JEY": "Jersey",
    "JOR": "Jordan",
    "KAZ": "Kazakhstan",
    "KEN": "Kenya",
    "KIR": "Kiribati",
    "PRK": "Korea (the Democratic People's Republic of)",
    "KOR": "Korea (the Republic of)",
    "KWT": "Kuwait",
    "KGZ": "Kyrgyzstan",
    "LAO": "Lao People's Democratic Republic (the)",
    "LVA": "Latvia",
    "LBN": "Lebanon",
    "LSO": "Lesotho",
    "LBR": "Liberia",
    "LBY": "Libya",
    "LIE": "Liechtenstein",
    "LTU": "Lithuania",
    "LUX": "Luxembourg",
    "MAC": "Macao",
    "MDG": "Madagascar",
    "MWI": "Malawi",
    "MYS": "Malaysia",
    "MDV": "Maldives",
    "MLI": "Mali",
    "MLT": "Malta",
    "MHL": "Marshall Islands (the)",
    "MTQ": "Martinique",
    "MRT": "Mauritania",
    "MUS": "Mauritius",
    "MYT": "Mayotte",
    "MEX": "Mexico",
    "FSM": "Micronesia (Federated States of)",
    "MDA": "Moldova (the Republic of)",
    "MCO": "Monaco",
    "MNG": "Mongolia",
    "MNE": "Montenegro",
    "MSR": "Montserrat",
    "MAR": "Morocco",
    "MOZ": "Mozambique",
    "MMR": "Myanmar",
    "NAM": "Namibia",
    "NRU": "Nauru",
    "NPL": "Nepal",
    "NLD": "Netherlands (the)",
    "NCL": "New Caledonia",
    "NZL": "New Zealand",
    "NIC": "Nicaragua",
    "NER": "Niger (the)",
    "NGA": "Nigeria",
    "NIU": "Niue",
    "NFK": "Norfolk Island",
    "MNP": "Northern Mariana Islands (the)",
    "NOR": "Norway",
    "OMN": "Oman",
    "PAK": "Pakistan",
    "PLW": "Palau",
    "PSE": "Palestine, State of",
    "PAN": "Panama",
    "PNG": "Papua New Guinea",
    "PRY": "Paraguay",
    "PER": "Peru",
    "PHL": "Philippines (the)",
    "PCN": "Pitcairn",
    "POL": "Poland",
    "PRT": "Portugal",
    "PRI": "Puerto Rico",
    "QAT": "Qatar",
    "MKD": "Republic of North Macedonia",
    "ROU": "Romania",
    "RUS": "Russian Federation (the)",
    "RWA": "Rwanda",
    "REU": "Réunion",
    "BLM": "Saint Barthélemy",
    "SHN": "Saint Helena, Ascension and Tristan da Cunha",
    "KNA": "Saint Kitts and Nevis",
    "LCA": "Saint Lucia",
    "MAF": "Saint Martin (French part)",
    "SPM": "Saint Pierre and Miquelon",
    "VCT": "Saint Vincent and the Grenadines",
    "WSM": "Samoa",
    "SMR": "San Marino",
    "STP": "Sao Tome and Principe",
    "SAU": "Saudi Arabia",
    "SEN": "Senegal",
    "SRB": "Serbia",
    "SYC": "Seychelles",
    "SLE": "Sierra Leone",
    "SGP": "Singapore",
    "SXM": "Sint Maarten (Dutch part)",
    "SVK": "Slovakia",
    "SVN": "Slovenia",
    "SLB": "Solomon Islands",
    "SOM": "Somalia",
    "ZAF": "South Africa",
    "SGS": "South Georgia and the South Sandwich Islands",
    "SSD": "South Sudan",
    "ESP": "Spain",
    "LKA": "Sri Lanka",
    "SDN": "Sudan (the)",
    "SUR": "Suriname",
    "SJM": "Svalbard and Jan Mayen",
    "SWE": "Sweden",
    "CHE": "Switzerland",
    "SYR": "Syrian Arab Republic",
    "TWN": "Taiwan",
    "TJK": "Tajikistan",
    "TZA": "Tanzania, United Republic of",
    "THA": "Thailand",
    "TLS": "Timor-Leste",
    "TGO": "Togo",
    "TKL": "Tokelau",
    "TON": "Tonga",
    "TTO": "Trinidad and Tobago",
    "TUN": "Tunisia",
    "TUR": "Turkey",
    "TKM": "Turkmenistan",
    "TCA": "Turks and Caicos Islands (the)",
    "TUV": "Tuvalu",
    "UGA": "Uganda",
    "UKR": "Ukraine",
    "ARE": "United Arab Emirates (the)",
    "GBR": "United Kingdom of Great Britain and Northern Ireland (the)",
    "UMI": "United States Minor Outlying Islands (the)",
    "USA": "United States of America (the)",
    "URY": "Uruguay",
    "UZB": "Uzbekistan",
    "VUT": "Vanuatu",
    "VEN": "Venezuela (Bolivarian Republic of)",
    "VNM": "Viet Nam",
    "VGB": "Virgin Islands (British)",
    "VIR": "Virgin Islands (U.S.)",
    "WLF": "Wallis and Futuna",
    "ESH": "Western Sahara",
    "YEM": "Yemen",
    "ZMB": "Zambia",
    "ZWE": "Zimbabwe",
    "ALA": "Åland Islands"
}

export const countryListPhone = {
    "AFG": { code: "+93", alpha2: "AF", flag: "🇦🇫" },
    "ALA": { code: "+358", alpha2: "AX", flag: "🇦🇽" },
    "ALB": { code: "+355", alpha2: "AL", flag: "🇦🇱" },
    "DZA": { code: "+213", alpha2: "DZ", flag: "🇩🇿" },
    "ASM": { code: "+1684", alpha2: "AS", flag: "🇦🇸" },
    "AND": { code: "+376", alpha2: "AD", flag: "🇦🇩" },
    "AGO": { code: "+244", alpha2: "AO", flag: "🇦🇴" },
    "AIA": { code: "+1264", alpha2: "AI", flag: "🇦🇮" },
    "ATA": { code: "+672", alpha2: "AQ", flag: "🇦🇶" },
    "ATG": { code: "+1268", alpha2: "AG", flag: "🇦🇬" },
    "ARG": { code: "+54", alpha2: "AR", flag: "🇦🇷" },
    "ARM": { code: "+374", alpha2: "AM", flag: "🇦🇲" },
    "ABW": { code: "+297", alpha2: "AW", flag: "🇦🇼" },
    "AUS": { code: "+61", alpha2: "AU", flag: "🇦🇺" },
    "AUT": { code: "+43", alpha2: "AT", flag: "🇦🇹" },
    "AZE": { code: "+994", alpha2: "AZ", flag: "🇦🇿" },
    "BHS": { code: "+1242", alpha2: "BS", flag: "🇧🇸" },
    "BHR": { code: "+973", alpha2: "BH", flag: "🇧🇭" },
    "BGD": { code: "+880", alpha2: "BD", flag: "🇧🇩" },
    "BRB": { code: "+1246", alpha2: "BB", flag: "🇧🇧" },
    "BLR": { code: "+375", alpha2: "BY", flag: "🇧🇾" },
    "BEL": { code: "+32", alpha2: "BE", flag: "🇧🇪" },
    "BLZ": { code: "+501", alpha2: "BZ", flag: "🇧🇿" },
    "BEN": { code: "+229", alpha2: "BJ", flag: "🇧🇯" },
    "BMU": { code: "+1441", alpha2: "BM", flag: "🇧🇲" },
    "BTN": { code: "+975", alpha2: "BT", flag: "🇧🇹" },
    "BOL": { code: "+591", alpha2: "BO", flag: "🇧🇴" },
    "BIH": { code: "+387", alpha2: "BA", flag: "🇧🇦" },
    "BWA": { code: "+267", alpha2: "BW", flag: "🇧🇼" },
    "BVT": { code: "+47", alpha2: "BV", flag: "🇧🇻" },
    "BRA": { code: "+55", alpha2: "BR", flag: "🇧🇷" },
    "IOT": { code: "+246", alpha2: "IO", flag: "🇮🇴" },
    "BRN": { code: "+673", alpha2: "BN", flag: "🇧🇳" },
    "BGR": { code: "+359", alpha2: "BG", flag: "🇧🇬" },
    "BFA": { code: "+226", alpha2: "BF", flag: "🇧🇫" },
    "BDI": { code: "+257", alpha2: "BI", flag: "🇧🇮" },
    "KHM": { code: "+855", alpha2: "KH", flag: "🇰🇭" },
    "CMR": { code: "+237", alpha2: "CM", flag: "🇨🇲" },
    "CAN": { code: "+1", alpha2: "CA", flag: "🇨🇦" },
    "CPV": { code: "+238", alpha2: "CV", flag: "🇨🇻" },
    "CYM": { code: "+345", alpha2: "KY", flag: "🇰🇾" },
    "CAF": { code: "+236", alpha2: "CF", flag: "🇨🇫" },
    "TCD": { code: "+235", alpha2: "TD", flag: "🇹🇩" },
    "CHL": { code: "+56", alpha2: "CL", flag: "🇨🇱" },
    "CHN": { code: "+86", alpha2: "CN", flag: "🇨🇳" },
    "CXR": { code: "+61", alpha2: "CX", flag: "🇨🇽" },
    "CCK": { code: "+61", alpha2: "CC", flag: "🇨🇨" },
    "COL": { code: "+57", alpha2: "CO", flag: "🇨🇴" },
    "COM": { code: "+269", alpha2: "KM", flag: "🇰🇲" },
    "COG": { code: "+242", alpha2: "CG", flag: "🇨🇬" },
    "COD": { code: "+243", alpha2: "CD", flag: "🇨🇩" },
    "COK": { code: "+682", alpha2: "CK", flag: "🇨🇰" },
    "CRI": { code: "+506", alpha2: "CR", flag: "🇨🇷" },
    "CIV": { code: "+225", alpha2: "CI", flag: "🇨🇮" },
    "HRV": { code: "+385", alpha2: "HR", flag: "🇭🇷" },
    "CUB": { code: "+53", alpha2: "CU", flag: "🇨🇺" },
    "CYP": { code: "+357", alpha2: "CY", flag: "🇨🇾" },
    "CZE": { code: "+420", alpha2: "CZ", flag: "🇨🇿" },
    "DNK": { code: "+45", alpha2: "DK", flag: "🇩🇰" },
    "DJI": { code: "+253", alpha2: "DJ", flag: "🇩🇯" },
    "DMA": { code: "+1767", alpha2: "DM", flag: "🇩🇲" },
    "DOM": { code: "+1849", alpha2: "DO", flag: "🇩🇴" },
    "ECU": { code: "+593", alpha2: "EC", flag: "🇪🇨" },
    "EGY": { code: "+20", alpha2: "EG", flag: "🇪🇬" },
    "SLV": { code: "+503", alpha2: "SV", flag: "🇸🇻" },
    "GNQ": { code: "+240", alpha2: "GQ", flag: "🇬🇶" },
    "ERI": { code: "+291", alpha2: "ER", flag: "🇪🇷" },
    "EST": { code: "+372", alpha2: "EE", flag: "🇪🇪" },
    "ETH": { code: "+251", alpha2: "ET", flag: "🇪🇹" },
    "FLK": { code: "+500", alpha2: "FK", flag: "🇫🇰" },
    "FRO": { code: "+298", alpha2: "FO", flag: "🇫🇴" },
    "FJI": { code: "+679", alpha2: "FJ", flag: "🇫🇯" },
    "FIN": { code: "+358", alpha2: "FI", flag: "🇫🇮" },
    "FRA": { code: "+33", alpha2: "FR", flag: "🇫🇷" },
    "GUF": { code: "+594", alpha2: "GF", flag: "🇬🇫" },
    "PYF": { code: "+689", alpha2: "PF", flag: "🇵🇫" },
    "ATF": { code: "+262", alpha2: "TF", flag: "🇹🇫" },
    "GAB": { code: "+241", alpha2: "GA", flag: "🇬🇦" },
    "GMB": { code: "+220", alpha2: "GM", flag: "🇬🇲" },
    "GEO": { code: "+995", alpha2: "GE", flag: "🇬🇪" },
    "DEU": { code: "+49", alpha2: "DE", flag: "🇩🇪" },
    "GHA": { code: "+233", alpha2: "GH", flag: "🇬🇭" },
    "GIB": { code: "+350", alpha2: "GI", flag: "🇬🇮" },
    "GRC": { code: "+30", alpha2: "GR", flag: "🇬🇷" },
    "GRL": { code: "+299", alpha2: "GL", flag: "🇬🇱" },
    "GRD": { code: "+1473", alpha2: "GD", flag: "🇬🇩" },
    "GLP": { code: "+590", alpha2: "GP", flag: "🇬🇵" },
    "GUM": { code: "+1671", alpha2: "GU", flag: "🇬🇺" },
    "GTM": { code: "+502", alpha2: "GT", flag: "🇬🇹" },
    "GGY": { code: "+44", alpha2: "GG", flag: "🇬🇬" },
    "GIN": { code: "+224", alpha2: "GN", flag: "🇬🇳" },
    "GNB": { code: "+245", alpha2: "GW", flag: "🇬🇼" },
    "GUY": { code: "+592", alpha2: "GY", flag: "🇬🇾" },
    "HTI": { code: "+509", alpha2: "HT", flag: "🇭🇹" },
    "HMD": { code: "+672", alpha2: "HM", flag: "🇭🇲" },
    "VAT": { code: "+379", alpha2: "VA", flag: "🇻🇦" },
    "HND": { code: "+504", alpha2: "HN", flag: "🇭🇳" },
    "HKG": { code: "+852", alpha2: "HK", flag: "🇭🇰" },
    "HUN": { code: "+36", alpha2: "HU", flag: "🇭🇺" },
    "ISL": { code: "+354", alpha2: "IS", flag: "🇮🇸" },
    "IND": { code: "+91", alpha2: "IN", flag: "🇮🇳" },
    "IDN": { code: "+62", alpha2: "ID", flag: "🇮🇩" },
    "IRN": { code: "+98", alpha2: "IR", flag: "🇮🇷" },
    "IRQ": { code: "+964", alpha2: "IQ", flag: "🇮🇶" },
    "IRL": { code: "+353", alpha2: "IE", flag: "🇮🇪" },
    "IMN": { code: "+44", alpha2: "IM", flag: "🇮🇲" },
    "ISR": { code: "+972", alpha2: "IL", flag: "🇮🇱" },
    "ITA": { code: "+39", alpha2: "IT", flag: "🇮🇹" },
    "JAM": { code: "+1876", alpha2: "JM", flag: "🇯🇲" },
    "JPN": { code: "+81", alpha2: "JP", flag: "🇯🇵" },
    "JEY": { code: "+44", alpha2: "JE", flag: "🇯🇪" },
    "JOR": { code: "+962", alpha2: "JO", flag: "🇯🇴" },
    "KAZ": { code: "+7", alpha2: "KZ", flag: "🇰🇿" },
    "KEN": { code: "+254", alpha2: "KE", flag: "🇰🇪" },
    "KIR": { code: "+686", alpha2: "KI", flag: "🇰🇮" },
    "PRK": { code: "+850", alpha2: "KP", flag: "🇰🇵" },
    "KOR": { code: "+82", alpha2: "KR", flag: "🇰🇷" },
    "XKX": { code: "+383", alpha2: "XK", flag: "🇽🇰" },
    "KWT": { code: "+965", alpha2: "KW", flag: "🇰🇼" },
    "KGZ": { code: "+996", alpha2: "KG", flag: "🇰🇬" },
    "LAO": { code: "+856", alpha2: "LA", flag: "🇱🇦" },
    "LVA": { code: "+371", alpha2: "LV", flag: "🇱🇻" },
    "LBN": { code: "+961", alpha2: "LB", flag: "🇱🇧" },
    "LSO": { code: "+266", alpha2: "LS", flag: "🇱🇸" },
    "LBR": { code: "+231", alpha2: "LR", flag: "🇱🇷" },
    "LBY": { code: "+218", alpha2: "LY", flag: "🇱🇾" },
    "LIE": { code: "+423", alpha2: "LI", flag: "🇱🇮" },
    "LTU": { code: "+370", alpha2: "LT", flag: "🇱🇹" },
    "LUX": { code: "+352", alpha2: "LU", flag: "🇱🇺" },
    "MAC": { code: "+853", alpha2: "MO", flag: "🇲🇴" },
    "MKD": { code: "+389", alpha2: "MK", flag: "🇲🇰" },
    "MDG": { code: "+261", alpha2: "MG", flag: "🇲🇬" },
    "MWI": { code: "+265", alpha2: "MW", flag: "🇲🇼" },
    "MYS": { code: "+60", alpha2: "MY", flag: "🇲🇾" },
    "MDV": { code: "+960", alpha2: "MV", flag: "🇲🇻" },
    "MLI": { code: "+223", alpha2: "ML", flag: "🇲🇱" },
    "MLT": { code: "+356", alpha2: "MT", flag: "🇲🇹" },
    "MHL": { code: "+692", alpha2: "MH", flag: "🇲🇭" },
    "MTQ": { code: "+596", alpha2: "MQ", flag: "🇲🇶" },
    "MRT": { code: "+222", alpha2: "MR", flag: "🇲🇷" },
    "MUS": { code: "+230", alpha2: "MU", flag: "🇲🇺" },
    "MYT": { code: "+262", alpha2: "YT", flag: "🇾🇹" },
    "MEX": { code: "+52", alpha2: "MX", flag: "🇲🇽" },
    "FSM": { code: "+691", alpha2: "FM", flag: "🇫🇲" },
    "MDA": { code: "+373", alpha2: "MD", flag: "🇲🇩" },
    "MCO": { code: "+377", alpha2: "MC", flag: "🇲🇨" },
    "MNG": { code: "+976", alpha2: "MN", flag: "🇲🇳" },
    "MNE": { code: "+382", alpha2: "ME", flag: "🇲🇪" },
    "MSR": { code: "+1664", alpha2: "MS", flag: "🇲🇸" },
    "MAR": { code: "+212", alpha2: "MA", flag: "🇲🇦" },
    "MOZ": { code: "+258", alpha2: "MZ", flag: "🇲🇿" },
    "MMR": { code: "+95", alpha2: "MM", flag: "🇲🇲" },
    "NAM": { code: "+264", alpha2: "NA", flag: "🇳🇦" },
    "NRU": { code: "+674", alpha2: "NR", flag: "🇳🇷" },
    "NPL": { code: "+977", alpha2: "NP", flag: "🇳🇵" },
    "NLD": { code: "+31", alpha2: "NL", flag: "🇳🇱" },
    "BES": { code: "+599", alpha2: "BQ", flag: "🇧🇶" },
    "NCL": { code: "+687", alpha2: "NC", flag: "🇳🇨" },
    "NZL": { code: "+64", alpha2: "NZ", flag: "🇳🇿" },
    "NIC": { code: "+505", alpha2: "NI", flag: "🇳🇮" },
    "NER": { code: "+227", alpha2: "NE", flag: "🇳🇪" },
    "NGA": { code: "+234", alpha2: "NG", flag: "🇳🇬" },
    "NIU": { code: "+683", alpha2: "NU", flag: "🇳🇺" },
    "NFK": { code: "+672", alpha2: "NF", flag: "🇳🇫" },
    "MNP": { code: "+1670", alpha2: "MP", flag: "🇲🇵" },
    "NOR": { code: "+47", alpha2: "NO", flag: "🇳🇴" },
    "OMN": { code: "+968", alpha2: "OM", flag: "🇴🇲" },
    "PAK": { code: "+92", alpha2: "PK", flag: "🇵🇰" },
    "PLW": { code: "+680", alpha2: "PW", flag: "🇵🇼" },
    "PSE": { code: "+970", alpha2: "PS", flag: "🇵🇸" },
    "PAN": { code: "+507", alpha2: "PA", flag: "🇵🇦" },
    "PNG": { code: "+675", alpha2: "PG", flag: "🇵🇬" },
    "PRY": { code: "+595", alpha2: "PY", flag: "🇵🇾" },
    "PER": { code: "+51", alpha2: "PE", flag: "🇵🇪" },
    "PHL": { code: "+63", alpha2: "PH", flag: "🇵🇭" },
    "PCN": { code: "+64", alpha2: "PN", flag: "🇵🇳" },
    "POL": { code: "+48", alpha2: "PL", flag: "🇵🇱" },
    "PRT": { code: "+351", alpha2: "PT", flag: "🇵🇹" },
    "PRI": { code: "+1939", alpha2: "PR", flag: "🇵🇷" },
    "QAT": { code: "+974", alpha2: "QA", flag: "🇶🇦" },
    "ROU": { code: "+40", alpha2: "RO", flag: "🇷🇴" },
    "RUS": { code: "+7", alpha2: "RU", flag: "🇷🇺" },
    "RWA": { code: "+250", alpha2: "RW", flag: "🇷🇼" },
    "REU": { code: "+262", alpha2: "RE", flag: "🇷🇪" },
    "BLM": { code: "+590", alpha2: "BL", flag: "🇧🇱" },
    "SHN": { code: "+290", alpha2: "SH", flag: "🇸🇭" },
    "KNA": { code: "+1869", alpha2: "KN", flag: "🇰🇳" },
    "LCA": { code: "+1758", alpha2: "LC", flag: "🇱🇨" },
    "MAF": { code: "+590", alpha2: "MF", flag: "🇲🇫" },
    "SPM": { code: "+508", alpha2: "PM", flag: "🇵🇲" },
    "VCT": { code: "+1784", alpha2: "VC", flag: "🇻🇨" },
    "WSM": { code: "+685", alpha2: "WS", flag: "🇼🇸" },
    "SMR": { code: "+378", alpha2: "SM", flag: "🇸🇲" },
    "STP": { code: "+239", alpha2: "ST", flag: "🇸🇹" },
    "SAU": { code: "+966", alpha2: "SA", flag: "🇸🇦" },
    "SEN": { code: "+221", alpha2: "SN", flag: "🇸🇳" },
    "SRB": { code: "+381", alpha2: "RS", flag: "🇷🇸" },
    "SYC": { code: "+248", alpha2: "SC", flag: "🇸🇨" },
    "SLE": { code: "+232", alpha2: "SL", flag: "🇸🇱" },
    "SGP": { code: "+65", alpha2: "SG", flag: "🇸🇬" },
    "SVK": { code: "+421", alpha2: "SK", flag: "🇸🇰" },
    "SVN": { code: "+386", alpha2: "SI", flag: "🇸🇮" },
    "SLB": { code: "+677", alpha2: "SB", flag: "🇸🇧" },
    "SOM": { code: "+252", alpha2: "SO", flag: "🇸🇴" },
    "ZAF": { code: "+27", alpha2: "ZA", flag: "🇿🇦" },
    "SSD": { code: "+211", alpha2: "SS", flag: "🇸🇸" },
    "SGS": { code: "+500", alpha2: "GS", flag: "🇬🇸" },
    "ESP": { code: "+34", alpha2: "ES", flag: "🇪🇸" },
    "LKA": { code: "+94", alpha2: "LK", flag: "🇱🇰" },
    "SDN": { code: "+249", alpha2: "SD", flag: "🇸🇩" },
    "SUR": { code: "+597", alpha2: "SR", flag: "🇸🇷" },
    "SJM": { code: "+47", alpha2: "SJ", flag: "🇸🇯" },
    "SWZ": { code: "+268", alpha2: "SZ", flag: "🇸🇿" },
    "SWE": { code: "+46", alpha2: "SE", flag: "🇸🇪" },
    "CHE": { code: "+41", alpha2: "CH", flag: "🇨🇭" },
    "SYR": { code: "+963", alpha2: "SY", flag: "🇸🇾" },
    "TWN": { code: "+886", alpha2: "TW", flag: "🇹🇼" },
    "TJK": { code: "+992", alpha2: "TJ", flag: "🇹🇯" },
    "TZA": { code: "+255", alpha2: "TZ", flag: "🇹🇿" },
    "THA": { code: "+66", alpha2: "TH", flag: "🇹🇭" },
    "TLS": { code: "+670", alpha2: "TL", flag: "🇹🇱" },
    "TGO": { code: "+228", alpha2: "TG", flag: "🇹🇬" },
    "TKL": { code: "+690", alpha2: "TK", flag: "🇹🇰" },
    "TON": { code: "+676", alpha2: "TO", flag: "🇹🇴" },
    "TTO": { code: "+1868", alpha2: "TT", flag: "🇹🇹" },
    "TUN": { code: "+216", alpha2: "TN", flag: "🇹🇳" },
    "TUR": { code: "+90", alpha2: "TR", flag: "🇹🇷" },
    "TKM": { code: "+993", alpha2: "TM", flag: "🇹🇲" },
    "TCA": { code: "+1649", alpha2: "TC", flag: "🇹🇨" },
    "TUV": { code: "+688", alpha2: "TV", flag: "🇹🇻" },
    "UGA": { code: "+256", alpha2: "UG", flag: "🇺🇬" },
    "UKR": { code: "+380", alpha2: "UA", flag: "🇺🇦" },
    "ARE": { code: "+971", alpha2: "AE", flag: "🇦🇪" },
    "GBR": { code: "+44", alpha2: "GB", flag: "🇬🇧" },
    "USA": { code: "+1", alpha2: "US", flag: "🇺🇸" },
    "URY": { code: "+598", alpha2: "UY", flag: "🇺🇾" },
    "UZB": { code: "+998", alpha2: "UZ", flag: "🇺🇿" },
    "VUT": { code: "+678", alpha2: "VU", flag: "🇻🇺" },
    "VEN": { code: "+58", alpha2: "VE", flag: "🇻🇪" },
    "VNM": { code: "+84", alpha2: "VN", flag: "🇻🇳" },
    "VGB": { code: "+1284", alpha2: "VG", flag: "🇻🇬" },
    "VIR": { code: "+1340", alpha2: "VI", flag: "🇻🇮" },
    "WLF": { code: "+681", alpha2: "WF", flag: "🇼🇫" },
    "YEM": { code: "+967", alpha2: "YE", flag: "🇾🇪" },
    "ZMB": { code: "+260", alpha2: "ZM", flag: "🇿🇲" },
    "ZWE": { code: "+263", alpha2: "ZW", flag: "🇿🇼" }
};

export const alpha3ByCountryCode = Object.entries(countryListPhone).reduce((acc, [key, value]) => {
    acc[value.code] = key;
    return acc;
}, {});