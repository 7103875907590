import { Player } from '@lottiefiles/react-lottie-player'
import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal'
import { ModifiedTooltip } from '../../../../common/components/ModifiedTooltip/ModifiedTooltip'
import { PrimaryButton } from '../../../../common/components/buttons/NewButtons.styles'
import { displayMoneyValue } from '../../../../common/utils/number-display.utils'
import { useStore } from '../../../app/data/root.store'
import magicBoxAiAnimatedIconData from '../ManualAnalysisPendingResultModal/assets/magic-box-animated-icon-gray.json'
import { DeepAnalysisResultsRow } from './DeepAnalysisResultsRow'
import { DeepAnalysisInProgressPrompt, DeepAnalysisReviewResultContent, DeepAnalysisReviewResultFooter, DeepAnalysisReviewResultHeader, DeepAnalysisReviewResultHeaderRow, DeepAnalysisReviewResultHeaderSubtitle, DeepAnalysisReviewResultHeaderTitle, DeepAnalysisReviewResultHero, DeepAnalysisReviewResultHeroBox, DeepAnalysisReviewResultHeroBoxTitle, DeepAnalysisReviewResultHeroBoxValue, DeepAnalysisReviewResultsWrapper, RowTitle } from './DeepAnalysisReviewResultModal.styles'
import { ReactComponent as ModalCloseIcon } from './assets/modal-close-icon.svg'
import { DeepAnalysisReviewDeleteModal } from './DeepAnalysisReviewDeleteModal'
import { ChatRow } from '../../../user-activation-flow/components/ChatRow/ChatRow';
import { AI_LOADING_ACCOUNT, AI_LOADING_MSG_WELCOME } from '../../data/magic-box-ai-store';
import { useTypewriter } from '../../hooks/useTypewriter';
import { useTrackEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';

export const DeepAnalysisReviewResultModal = observer(({ onFlowStepChange }) => {
  const { uiStore, metadataStore, magicBoxAiStore, userStore } = useStore();
  const contentRef = useRef(null);
  const [editedRowIndex, setEditedRowIndex] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { trackEvent } = useAnalytics();
  const [isResultsReceived,setIsResultsReceived] = useState(false);

  const assetClasses = metadataStore.assetClassWithCategoriesForMagicBoxAi;
  const liabilityClasses = metadataStore.liabilityClassesWithCategoriesForMagicBoxAi;
  const relevantClasses = [...assetClasses, ...liabilityClasses];
  const { totals, processedLLMResult, llmResult } = magicBoxAiStore;

  useTrackEvent('Popup Viewed', {
    title: 'Magic Box AI deep analysis results',
    url: window.location.href
  })

  useEffect(() => {
    if (llmResult &&  !isResultsReceived) {
      setIsResultsReceived(true);
      trackEvent('Magic box ai results received', {
        url: window.location.href
      });
    }
  }, [llmResult,isResultsReceived,trackEvent]);

  const handleCategoryChange = (index, categoryId) => magicBoxAiStore.changeReviewedItemCategory(index, categoryId);

  const onScroll = () => {
    if (contentRef.current) {
      const { scrollTop } = contentRef.current
      setIsSticky(scrollTop > 8);
    }
  }

  const onDeleteItem = (itemForDelete) => {
    magicBoxAiStore.deleteReviewedItem(itemForDelete);
    setEditedRowIndex(null);
  }
  const onAddItems = async () => {
    setIsLoading(true);
    await magicBoxAiStore.addItems();
    setIsLoading(false);
  }

  const onClick = async () => {
    if (llmResult) {
      onAddItems();
      trackEvent('CTA Clicked', {
        cta_text: 'Add Items',
        location: isWelcome ? 'Magic box ai activation flow' : 'Magic box ai page',
        // destination: 'manual magic box',
        type: "Button",
        url: window.location.href
      })
      trackEvent('Magic box ai items added', {
        // cta_text: 'Add Items',
        // location: isWelcome ? 'Magic box ai activation flow' : 'Magic box ai page',
        // destination: 'manual magic box',
        // type: "Button",
        url: window.location.href
      })
    } else {
      trackEvent('CTA Clicked', {
        cta_text: isWelcome ? 'Go to sync bank' : 'Continue to explore',
        location: isWelcome ? 'Magic box ai activation flow' : 'Magic box ai page',
        // destination: 'manual magic box',
        type: "Button",
        url: window.location.href
      })
      if (isWelcome) {
        onFlowStepChange(1);
      } else {
        window.location.href = 'wealth';
      }
    }
  }

  const isWelcome = userStore.isWelcome;
  const pluralSign = processedLLMResult?.length > 1 ? 's' : '';
  const btnText = llmResult ? `Continue with ${processedLLMResult.length} item${pluralSign}` : (isWelcome ? 'Go to sync bank' : 'Continue to explore');
  const onCloseModal = () => { setShowDeleteModal(true) };
  const onDeleteFile = async () => {
    await magicBoxAiStore.deleteDocument();
    setShowDeleteModal(false);
  };

  return (
    <BaseModal borderRadius={8} width={'960px'} customModalWrapperStyles={{ maxWidth: '960px' }} noSidebarMargin={isWelcome}>
      <div style={{ display: 'flex', flexDirection: 'column', }}>
        <DeepAnalysisReviewResultHeader>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: '30px' }}>
            <DeepAnalysisReviewResultHeaderTitle>Review the Magic Box AI initial results</DeepAnalysisReviewResultHeaderTitle>
            <DeepAnalysisReviewResultHeaderSubtitle>This classification will create an asset / liability in that selected class.</DeepAnalysisReviewResultHeaderSubtitle>
          </div>
          <ModalCloseIcon style={{ cursor: 'pointer' }} onClick={onCloseModal} />
        </DeepAnalysisReviewResultHeader>

        {llmResult ?
          <ReviewReadyResultHeader totals={totals} isFetchAnyRate={magicBoxAiStore.isFetchAnyRate} isIncognitoMode={uiStore.isIncognitoMode} /> :
          <ReviewLoadingHeader isWelcome={isWelcome} />
        }

        <ReviewHeaderRow totals={totals} isSticky={isSticky} hasResult={!!llmResult} />
      </div>

      <DeepAnalysisReviewResultContent ref={contentRef} onScroll={onScroll}>
        <DeepAnalysisReviewResultsWrapper>
          {
            llmResult ?
              <>
                {
                  processedLLMResult?.map((item, itemIndex) => (
                    <DeepAnalysisResultsRow
                      key={itemIndex}
                      item={item}
                      itemIndex={itemIndex}
                      relevantClasses={relevantClasses}
                      scrollToMe={magicBoxAiStore.showMissingFieldsError && magicBoxAiStore.firstAccountWithoutAllFields === itemIndex ? magicBoxAiStore.showMissingFieldsError : 0}
                      handleCategoryChange={(_classId, categoryId) => handleCategoryChange(itemIndex, categoryId)}
                      isEdited={editedRowIndex === itemIndex}
                      onEditedIndexChange={(index) => setEditedRowIndex(index)}
                      onClickDelete={() => onDeleteItem(itemIndex)}
                    />
                  ))
                }

                <DeepAnalysisResultsRow
                  isNew
                  itemIndex={-1}
                  relevantClasses={relevantClasses}
                  isEdited={editedRowIndex === -1}
                  onEditedIndexChange={(index) => setEditedRowIndex(index)}
                />
              </>
              :
              <SkeletonRows count={8} />
          }
        </DeepAnalysisReviewResultsWrapper>
      </DeepAnalysisReviewResultContent>

      <DeepAnalysisReviewResultFooter>
        <FooterNote show={!!llmResult} />
        <PrimaryButton onClick={onClick} disabled={magicBoxAiStore.isTyping} style={{ borderRadius: '8px', minWidth: '190px' }}>{isLoading ? <LoadingOutlined /> : `${btnText}`}</PrimaryButton>
      </DeepAnalysisReviewResultFooter>

      {showDeleteModal && <DeepAnalysisReviewDeleteModal onClose={() => setShowDeleteModal(false)} onDelete={onDeleteFile} />}

    </BaseModal>
  )
})

const InfoSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5 8.00087C13.5 11.0384 11.0366 13.5 7.99913 13.5C4.96165 13.5 2.5 11.0384 2.5 8.00087C2.5 4.96165 4.96165 2.5 7.99913 2.5C11.0366 2.5 13.5 4.96165 13.5 8.00087Z" fill="#9097A3" />
      <path d="M7.54102 7.28125H8.45754V10.9478H7.54102V7.28125Z" fill="white" stroke="white" strokeWidth="0.251429" />
      <path d="M7.54102 5.56568C7.54102 5.31259 7.74619 5.10742 7.99928 5.10742C8.25237 5.10742 8.45754 5.31259 8.45754 5.56568C8.45754 5.81877 8.25237 6.02394 7.99928 6.02394C7.74619 6.02394 7.54102 5.81877 7.54102 5.56568Z" fill="white" stroke="white" strokeWidth="0.628571" />
    </svg>
  )
};

const ReviewHeaderRow = ({ totals, isSticky, hasResult }) => {
  const count = hasResult ? `(${(totals.assets.count + totals.liabilities.count)})` : '';
  return (
    <DeepAnalysisReviewResultHeaderRow isSticky={isSticky}>
      <RowTitle >Asset / liability name {count} </RowTitle>
      <div style={{ display: 'flex', width: '508px', alignItems: 'center' }}>
        <RowTitle style={{ width: '164px' }}>Current value</RowTitle>
        <RowTitle>Choose category</RowTitle>
      </div>

    </DeepAnalysisReviewResultHeaderRow>
  );
};

const ReviewReadyResultHeader = ({ totals, isFetchAnyRate, isIncognitoMode }) => {
  return (
    <DeepAnalysisReviewResultHero>
      <DeepAnalysisReviewResultHeroBox>
        <DeepAnalysisReviewResultHeroBoxTitle>Total Assets ({totals.assets.count})</DeepAnalysisReviewResultHeroBoxTitle>
        <DeepAnalysisReviewResultHeroBoxValue>{isFetchAnyRate ? <LoadingOutlined /> : displayMoneyValue(totals.assets.sum, 'USD', isIncognitoMode, 100)}</DeepAnalysisReviewResultHeroBoxValue>
      </DeepAnalysisReviewResultHeroBox>
      <DeepAnalysisReviewResultHeroBox>
        <DeepAnalysisReviewResultHeroBoxTitle>Total Liabilities ({totals.liabilities.count})</DeepAnalysisReviewResultHeroBoxTitle>
        <DeepAnalysisReviewResultHeroBoxValue>{isFetchAnyRate ? <LoadingOutlined /> : displayMoneyValue(totals.liabilities.sum, 'USD', isIncognitoMode, 100)}</DeepAnalysisReviewResultHeroBoxValue>
      </DeepAnalysisReviewResultHeroBox>
      <DeepAnalysisReviewResultHeroBox>
        <DeepAnalysisReviewResultHeroBoxTitle>Total Net</DeepAnalysisReviewResultHeroBoxTitle>
        <DeepAnalysisReviewResultHeroBoxValue>{isFetchAnyRate ? <LoadingOutlined /> : displayMoneyValue((totals.assets.sum - totals.liabilities.sum), 'USD', isIncognitoMode, 100)}</DeepAnalysisReviewResultHeroBoxValue>
      </DeepAnalysisReviewResultHeroBox>
    </DeepAnalysisReviewResultHero>

  );
};

const ReviewLoadingHeader = ({ isWelcome }) => {
  const { magicBoxAiStore } = useStore();

  const displayedText = useTypewriter({
    text: ((isWelcome) ? AI_LOADING_MSG_WELCOME : AI_LOADING_ACCOUNT),
    setIsTyping: useCallback((isTyping) => magicBoxAiStore.setIsTyping(isTyping), [magicBoxAiStore])
  });

  return (
    <DeepAnalysisReviewResultHero>
      <ChatRow duration={0}>
        <div style={{minHeight: '63.5px'}}>
          <span style={{ fontWeight: 600 }}>Analyzing... </span>
          <span>{displayedText}</span>
        </div>
      </ChatRow>
    </DeepAnalysisReviewResultHero>);
};

const FooterNote = ({ show }) => {
  return (
    <DeepAnalysisInProgressPrompt>
      {
        show &&
        <>
          <Player
            loop={true}
            src={magicBoxAiAnimatedIconData}
            speed={1}
            autoplay
            style={{ width: '16px', height: '16px', margin: '0', }}
          />
          <ModifiedTooltip title={`The initial data will appear in your portfolio immediately, while the deep analysis process may take up to 24 hours. Once complete, comprehensive details on your investments and liabilities will be updated. We’ll notify you by email when the update is finished.`}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>Deep analysis is now in progress... This can take up to 24h<InfoSvg /></div>
          </ModifiedTooltip>
        </>}
    </DeepAnalysisInProgressPrompt>
  );
};

const SkeletonRows = ({ count }) => {
  return (
    Array.from({ length: count }, (_, index) => (
      <DeepAnalysisResultsRow key={index} showSkeleton={true}/>
    ))
  );
};