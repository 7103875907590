import React from 'react';
import {
  ItemContent,
  ItemLogoWrapper,
  ItemText, ItemTitle,
  LogoPlaceHolder,
  SelectedCheckMarkSvgWrapper,
  Separator, TickerAutoCompleteResultRowWrapper
} from './CryptoHoldingsTable.styles';

export const CryptoHoldingsTableAutoCompleteRow = ({ coin, handleItemClick, searchStr, isSeparator, isTickerSelected }) => {

  const handleResultRowClick = () => {
    if (isTickerSelected) {
      return;
    }
    handleItemClick?.(coin)
  }

  return (
    !isSeparator ? <TickerAutoCompleteResultRowWrapper
      isTickerSelected={isTickerSelected}
      onMouseDown={handleResultRowClick}
    >
      
           {/* <LogoPlaceHolder >
             {(ticker.name && ticker.name !== '') ? ticker.name.charAt(0).toUpperCase() : (ticker.symbol && ticker.symbol !== '') ? ticker.symbol.charAt(0).toUpperCase() : (ticker.type && ticker.type !== '') ? ticker.type.charAt(0).toUpperCase() : ''}
           </LogoPlaceHolder> */}
      
      {coin.imageUrl ? <ItemLogoWrapper><img alt={coin.coinName} src={coin.imageUrl} /></ItemLogoWrapper>
        : <LogoPlaceHolder >{coin.name.charAt(0).toUpperCase()}</LogoPlaceHolder>
      }
      <ItemContent>
        <ItemText>
          {/* <ItemTitle>{hightlightSearchTerm(coin.coinName)} , {coin.symbol}</ItemTitle> */}
          <ItemTitle>{coin.coinName} , {coin.symbol}</ItemTitle>
          {/* <ItemMoreInfos>
            <MoreInfosItem>
              {ticker.instrument_type} , {ticker.exchange}
            </MoreInfosItem>
          </ItemMoreInfos> */}
        </ItemText>
      </ItemContent>
      {isTickerSelected ? <SelectedCheckMarkSvgWrapper><SelectedCheckMarkSvg /></SelectedCheckMarkSvgWrapper> : null}
    </TickerAutoCompleteResultRowWrapper> : <Separator />
  )
}

const SelectedCheckMarkSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5103 8.604L10.5059 15.6125L6.99983 12.1075" stroke="black" strokeWidth="2" />
    </svg>
  )
}