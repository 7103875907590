export const categoriesTickersAttributes = {
  13: [
      {title:'Performance', atrKey: 'performance', type:'percentage', withSignColors:true},
      {title:'Gain', atrKey: 'gain', type:'money', withSignColors:true},
      {title:'Quantity', atrKey: 'quantity', type:'number'},
      {title:'Close price', atrKey: 'currentPrice', type:'money'},
      {title:'Cost per share', atrKey: 'costPerShare', type:'money'},
      {title:'Total cost', atrKey: 'costBasis', type:'money'},
  ],
}

export const connectedBrokerAgePageTickersAttributes = [
  {title:'Performance', dataKey: 'performance', type:'percentage', withSignColors:true},
  {title:'Gain', dataKey: 'gain', type:'money', withSignColors:true},
  {title:'Quantity', dataKey: 'quantity', type:'number'},
  {title:'Close price', dataKey: 'currentPrice', type:'money'},
  // {title:'Cost per share', dataKey: 'costPerShare', type:'money'},
  // {title:'Total cost', dataKey: 'costBasis', type:'money'},
]