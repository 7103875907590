//import React, { useEffect, useRef } from 'react';
import { Checkbox, Popover } from 'antd';
import { Observer } from 'mobx-react';

import { useEffect , useState } from 'react';
import { ArrowDownIcon, CheckmarkIcon } from '../../../../assets/icons/common/common-icons';
import { useStore } from '../../../app/data/root.store';
import { updateActivationStep } from '../../../user/data/user.service';
import { CloseButtonWrapper, CompletedText, Header, HoverArrowIcon, PaneClosedButton, PaneClosedButtonTitle, PaneWrapper, ProgressBarFill, ProgressBarWrapper, StepActionsWrapper, StepContent, StepHeader, StepMark, StepMenuButton, StepMenuCheckboxWrapper, StepMenuWrapper, StepSubTitle, StepsWrapper, StepTitle, StepWrapper, Title, TitleAndCloseButton } from './ChecklistPane.styles';
import { AddAssetManuallyStep, BookACallStep, PlanYourCashFlowStep, QuickIntroStep, SyncYourCashAccountsStep, UploadYourFinancialStatementsStep } from './ChecklistSteps';

const activationSteps = [
    { id: 1 , title : 'Learn about Vyzer' , innerComp : QuickIntroStep , isDone : true },
    { id: 2 , title : 'Upload documents' , innerComp : UploadYourFinancialStatementsStep , doneKey : 'magicBox' },
    { id: 3 , title : 'Sync your cash accounts' , innerComp : SyncYourCashAccountsStep , doneKey : 'syncCashAccount' },
    { id: 4 , title : 'Add your assets' , innerComp : AddAssetManuallyStep , doneKey : 'addAssetManually' },
    { id: 5 , title : 'Plan your cash flow' , innerComp : PlanYourCashFlowStep  , doneKey : 'futureCashFlow' },
]

export function ActivationChecklistPane(props) {
    const [activeStepId,setActiveStepId] = useState(0)  
    // console.log(JSON.stringify(filtersStore.appliedFilters));
    const { uiStore, userStore } = useStore();

    useEffect(() => {
        if (userStore.isTier4 && activationSteps[0].id !== 0) {
          activationSteps.unshift({ id: 0 , title : 'Book a call with your personal account manager', innerComp : BookACallStep , doneKey : 'bookACall' });
        } else if(activationSteps.at(-1).id !== 6) {
            activationSteps.push({ id: 6 , title : 'Book a call with your personal account manager', subTitle: '(optional)', innerComp : BookACallStep , doneKey : 'bookACall' })
        }
      
      //   return () => {}
      }, [userStore])
    
    useEffect(() => {
        const doneList = userStore.getStartedDoneSteps;
        const currentStep = !doneList.includes('magicBox') ? 2 : !doneList.includes('syncCashAccount') ? 3 : !doneList.includes('addAssetManually') ? 4 : 5;
        setActiveStepId(currentStep);
        return () => { }
      }, [userStore,userStore.getStartedDoneSteps]);

      const handleMarkAsComplete = (itemDoneKey) => {
        updateActivationStep({['flows.getStartedPane.' + itemDoneKey] : true});
        userStore.setGetStartedStepAsComplete(itemDoneKey);
    }

    return (<Observer>{() => (
        <PaneWrapper>
            <Header>
                <TitleAndCloseButton>
                    <Title>Get started</Title>
                    <CloseButtonWrapper onClick={()=>{uiStore.setIsRightMenuOpen(false)}}><CloseButtonSvg /></CloseButtonWrapper>
                </TitleAndCloseButton>
                <ProgressBarWrapper ><ProgressBarFill complete={userStore.completePercentages}/></ProgressBarWrapper>
                <CompletedText>{userStore.completePercentages}% completed 🎉</CompletedText>
                
            </Header>
            <StepsWrapper>
                {activationSteps.map((item,index)=>(
                    <ActivationChecklistStep key={item.id} item={item} index={index}
                        setActive={()=>{setActiveStepId(item.id)}}
                        isActive={activeStepId=== item.id}
                        isDone={item.isDone || userStore.getStartedDoneSteps.includes(item.doneKey)}
                        markAsComplete={()=>{handleMarkAsComplete(item.doneKey)}} />
                ))}
            </StepsWrapper>
            <PaneClosedButton show={!uiStore.isRightMenuOpen} onClick={()=>{uiStore.setIsRightMenuOpen(true)}} isLowerPosition={userStore.isReadOnlyMode || userStore.isPaymentFailedMode}>
                <TitleAndCloseButton>
                    <PaneClosedButtonTitle>Get started</PaneClosedButtonTitle>
                    <PaneClosedButtonTitle>{userStore.completePercentages}%</PaneClosedButtonTitle>
                </TitleAndCloseButton>
                <ProgressBarWrapper ><ProgressBarFill complete={userStore.completePercentages}/></ProgressBarWrapper>
            </PaneClosedButton>
        </PaneWrapper>
    )}</Observer>)
}

export function ActivationChecklistStep(props) {
    const { item , isDone, isActive, index, setActive , markAsComplete } = props;
    // console.log(JSON.stringify(filtersStore.appliedFilters));
    const stepMenu = () => {

        // const handleBillingPageOnClick = (e) => {
        //   if (!userStore.isRedeemed) {
        //     e.preventDefault();
        //     setIsUserDetailsSelected(false);
        //     billingStore.setShowPricingTableModal(true);
        //   }
        // }
    
        // const handleConnectedAccountsClick = (e) => {
        //   if (userStore.isDemoMode) {
        //     e.preventDefault();
        //     setIsUserDetailsSelected(false);
        //     return billingStore.setShowPricingTableModal(true);
        //   }
        // }
    
        return (
          <StepMenuWrapper>
                
                <StepMenuCheckboxWrapper >
                    <Checkbox onChange={()=>{markAsComplete()}}>Mark as complete</Checkbox>
                </StepMenuCheckboxWrapper>
          </StepMenuWrapper>
        )
    }

    return (
        <StepWrapper isActive={isActive} onClick={setActive}>
            <StepHeader>
                <StepMark isActive={isActive} isDone={isDone}>{ isDone ? <CheckmarkIcon/> : index+1}</StepMark>
                <StepTitle>{item.title}{item.subTitle && <StepSubTitle>{item.subTitle}</StepSubTitle>}</StepTitle>
                <StepActionsWrapper>
                    {!isActive && <HoverArrowIcon >
                        <ArrowDownIcon />
                    </HoverArrowIcon>}
                    {isActive && !isDone && <Popover overlayClassName='checklist-popover' trigger={['click']} placement="topRight" arrowContent={false} content={stepMenu()}><StepMenuButton><ThreeDotsIcon/></StepMenuButton></Popover> }
                </StepActionsWrapper>
            </StepHeader>
            {isActive &&<StepContent>
                 
                    {item.innerComp()}
                
            </StepContent>}
        </StepWrapper>
    )
}

const CloseButtonSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.58496 8.50928L12.1708 12.0951L8.58496 15.681" stroke="#ACB1BB" strokeWidth="1.5"/>
            <path d="M13.835 8.50928L17.4208 12.0951L13.835 15.681" stroke="#ACB1BB" strokeWidth="1.5"/>
        </svg>
    )
}

const ThreeDotsIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="1" cy="1" r="1" transform="matrix(1 1.74846e-07 1.74846e-07 -1 1 7)" fill="black"/>
            <circle cx="1" cy="1" r="1" transform="matrix(1 1.74846e-07 1.74846e-07 -1 5 7)" fill="black"/>
            <circle cx="1" cy="1" r="1" transform="matrix(1 1.74846e-07 1.74846e-07 -1 9 7)" fill="black"/>
        </svg>
    )
}




