import { Observer } from 'mobx-react';
import React from 'react';
import { useHistory } from "react-router-dom";
import { ArrowDownIcon, CloseSymmetricIcon, PlusIcon } from '../../../assets/icons/common/common-icons';
import { useStore } from '../../../modules/app/data/root.store';
import { AssetClassIcon } from '../../../modules/assets/components/AssetClassIcon';
import { navigateToUrl } from '../../utils/navigation.utils';
import { displayMoneyValue } from '../../utils/number-display.utils';
import { BaseModal } from '../BaseModal/BaseModal';
import { ArrowIconWRapper, AssetClassSubtitle, AssetTextWrapper, AssetTitle, ButtonIconWrapper, DeleteLiabilityIconWrapper, ItemLogoWrapper, LiabilityIconWrapper, LiabilityLeftSideWrapper, LiabilityRightSideWrapper, LiabilityValueWrapper, LinkedLiabilitiesAssetWrapper, LinkedLiabilitiesBalanceTitle, LinkedLiabilitiesCloseBtnWrapper, LinkedLiabilitiesHeaderTextWrapper, LinkedLiabilitiesHeaderTitle, LinkedLiabilitiesHeaderWrapper, LinkedLiabilitiesModalFooterWrapper, LinkedLiabilitiesModalWrapper, LinkLiabilityButton } from './ConnectedLiabilitiesModal.styles';
import { ModifiedTooltip } from '../ModifiedTooltip/ModifiedTooltip';

export function ConnectedLiabilitiesModal(props) {
  let history = useHistory();
  const { onClose, item, showUnlinkAssetConfirm, onOpenConnectLiabilityModal } = props;
  const { uiStore, metadataStore } = useStore();

  const remainingBalance = () => {
    return item.connectedLiabilities.reduce((accumulator, liability) =>
      accumulator + liability.usdValue
      , 0);
  }

  const handleUnlinkAsset = (liabilityId) => {
    onClose()
    showUnlinkAssetConfirm(liabilityId)
  }

  const handleLinkLiability = () => {
    if (item.closedAt !== null){return}
    onClose()
    onOpenConnectLiabilityModal()
  }

  return (<Observer>{() => (
    <BaseModal onBackDropClick={onClose} width={'538px'} height={'auto'} borderRadius={6}>
      <LinkedLiabilitiesHeaderWrapper>
        <LinkedLiabilitiesHeaderTextWrapper>
          <LinkedLiabilitiesHeaderTitle>
            Link liability
          </LinkedLiabilitiesHeaderTitle>
          <LinkedLiabilitiesBalanceTitle>
            Remaining balance: {displayMoneyValue(remainingBalance(), 'USD', uiStore.isIncognitoMode)}
          </LinkedLiabilitiesBalanceTitle>
        </LinkedLiabilitiesHeaderTextWrapper>
        <LinkedLiabilitiesCloseBtnWrapper onClick={onClose}>
          <CloseSymmetricIcon />
        </LinkedLiabilitiesCloseBtnWrapper>
      </LinkedLiabilitiesHeaderWrapper>
      <LinkedLiabilitiesModalWrapper>
        {item.connectedLiabilities.map((liability, index) => (
          <LinkedLiabilitiesAssetWrapper key={index}>
            <LiabilityLeftSideWrapper>
              {metadataStore.itemsMeta[liability.liabilityId]?.logoBase64 ?
                <ItemLogoWrapper>
                  <img alt={metadataStore.itemsMeta[liability.liabilityId]?.connectedInstitutionName} src={'data:image/png;base64,' + metadataStore.itemsMeta[liability.liabilityId]?.logoBase64} />
                </ItemLogoWrapper> :
                metadataStore.itemsMeta[liability.liabilityId]?.logoUrl ?
                  <ItemLogoWrapper>
                    <img alt={metadataStore.itemsMeta[liability.liabilityId]?.connectedInstitutionName} src={metadataStore.itemsMeta[liability.liabilityId]?.logoUrl} />
                  </ItemLogoWrapper> :
                  <LiabilityIconWrapper color={"#EB7E7E"} >
                    <AssetClassIcon name={'lia-loans'} />
                  </LiabilityIconWrapper>}
              <AssetTextWrapper>
                <AssetTitle>{liability.title}{liability.name ? ', ' + liability.name : ''}</AssetTitle>
                <AssetClassSubtitle>Loans</AssetClassSubtitle>
              </AssetTextWrapper>
            </LiabilityLeftSideWrapper>
            <LiabilityRightSideWrapper>
              <LiabilityValueWrapper>{displayMoneyValue(liability.value, liability.currency, uiStore.isIncognitoMode)}</LiabilityValueWrapper>
              <ArrowIconWRapper onClick={() => navigateToUrl(history, `/wealth/assets/loans/${liability.liabilityId}`)}><ArrowDownIcon /></ArrowIconWRapper>
              <ModifiedTooltip title={'Unlink loan'} >
              <DeleteLiabilityIconWrapper onClick={() => handleUnlinkAsset(liability.id)}><CloseSymmetricIcon /></DeleteLiabilityIconWrapper>
              </ModifiedTooltip>
            </LiabilityRightSideWrapper>
          </LinkedLiabilitiesAssetWrapper>))}
      </LinkedLiabilitiesModalWrapper>
      <LinkedLiabilitiesModalFooterWrapper>
        <LinkLiabilityButton onClick={handleLinkLiability} disabled={item.closedAt !== null} >
          <ButtonIconWrapper><PlusIcon /></ButtonIconWrapper>
          Link liability
        </LinkLiabilityButton>
      </LinkedLiabilitiesModalFooterWrapper>
    </BaseModal>
  )}</Observer>)
}
