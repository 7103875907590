export const saltedgeAccountTypes = {
  isCashAccount: (nature) => ['account', 'checking'].includes(nature),
  isSaltedgeCreditCardAccount: (nature) =>
    ['card', 'debit_card', 'credit_card'].includes(nature),
  isSaltedgeLoanAccount: (nature) =>
    ['loan', 'mortgage', 'credit'].includes(nature),
  isCreditOrLoanAccounts: (nature) =>
    saltedgeAccountTypes.isSaltedgeCreditCardAccount(nature) ||
    saltedgeAccountTypes.isSaltedgeLoanAccount(nature),
};

export const isCashAccount = (account) => saltedgeAccountTypes.isCashAccount(account.nature);
export const isCreditCard = (account) => saltedgeAccountTypes.isSaltedgeCreditCardAccount(account.nature);
export const isLiability = (account) => saltedgeAccountTypes.isCreditOrLoanAccounts(account.nature);