import React from 'react';
// import { useLocation } from "react-router-dom";
import { Observer } from 'mobx-react';

import { useStore } from '../../../../../../app/data/root.store';
// import { useHistory } from "react-router-dom";

// import { displayMoneyValue } from '../../../../../common/utils/number-display.utils';
import {
    ConnectedAccountsIcon,
    //SortIcon 
} from '../../../../../../../assets/icons/common/common-icons';

// import { navigateToUrl } from '../../../../../../common/utils/navigation.utils';
import { ChartWrapper, HeroContent, HeroGraphPane, HeroMetricsPane, SyncingValueIndicatorWrapper } from '../../../../../components/WealthLayout.styles';
import { WealthPieChart } from '../../../../../components/charts/WealthPieChart/WealthPieChart';

import { SyncWhiteIconWrapper } from '../../../../asset/components/Overview/OverviewTab.styles';
import { WealthOverviewHeroDataBox } from './WealthOverviewHeroDataBox';
// import { NetWorthLineChart } from '../../../../components/charts/NetWorthLineChart/NetWorthLineChart';

// import { ActionLockedIconWithTooltip } from '../../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';

import { WealthFiltersHeroDataBox } from './WealthFiltersHeroDataBox';
import { FiltersPieChart } from '../../../../../components/charts/FiltersPieChart/FiltersPieChart';
import { FiltersHeroEmptyState } from '../FiltersHeroEmptyState/FiltersHeroEmptyState';
import { ClassPageEmptyState } from '../../../../class/components/ClassPageEmptyState';
import { WealthAllocationPieChart } from '../../../../../components/charts/WealthPieChart/WealthAllocationsPieChart';
import { FiltersHeroCustomClassEmptyState } from '../FilterHeroCustomClassEmptyState/FilterHeroCustomClassEmptyState';


export function OverviewHeroContent(props) {
    
    const { wealthStore, filtersStore, metadataStore } = useStore();

    return (<Observer>{() => (
      
                <HeroContent>
                    {wealthStore.isEmptyFilterResults ?
                    filtersStore.singleClassFilter ?
                    <ClassPageEmptyState />
                    :
                    metadataStore.customClasses?.length !== 0 && filtersStore.isOnlyOneCustomClassAndOneOrZeroSubClassFilter ?
                    <FiltersHeroCustomClassEmptyState />
                    :
                     <FiltersHeroEmptyState /> : <>
                        {/* {(selectedTab === 'overview' || wealthStore.isAnyFilterApplied) && <> */}
                        <HeroGraphPane>
                        <ChartWrapper>
                            {(wealthStore.isAllocationsViewMode || !wealthStore.isAnyFilterApplied ) && wealthStore.allocateBy !== 'accounts' ?
                                ( ['classes','categories'].includes(wealthStore.allocateBy) ?  <WealthPieChart height={276} /> : <WealthAllocationPieChart height={276} /> ) : <FiltersPieChart height={276} /> }
                                {wealthStore.isCheckingUpdates && <SyncingValueIndicatorWrapper><SyncWhiteIconWrapper><ConnectedAccountsIcon /></SyncWhiteIconWrapper>Syncing values...</SyncingValueIndicatorWrapper>}
                            </ChartWrapper>
                        </HeroGraphPane>

                        {(wealthStore.isAllocationsViewMode && wealthStore.allocateBy === 'classes' ) || !wealthStore.isAnyFilterApplied ?
                            <HeroMetricsPane><WealthOverviewHeroDataBox /></HeroMetricsPane> : (
                                wealthStore.heroFiltersDisplayData?.stats?.length > 0 ?  
                                <HeroMetricsPane><WealthFiltersHeroDataBox /></HeroMetricsPane> : null
                            )}                        
                    </>}
                </HeroContent>
    )}</Observer>)
}   