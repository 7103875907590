import React from 'react';
import { ModalCloseIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { CommonBadge } from '../../../../common/components/CommonBadge/CommonBadge';
import { displayMoneyValue } from '../../../../common/utils/number-display.utils';
import { useStore } from '../../../app/data/root.store';
import { ModalButton, ModalClose, ModalContent, ModalFooter, ModalHeader, ModalTitle } from '../../../wealth/pages/asset/components/Events/AssetEventModal.styles';
import { InstitutionLogo } from '../../utils/InstitutionLogo';
import { AccountBalance, AccountBalanceAndActions, DisableModalAccountRowSubTitle, DisableModalAccountRowTitle, DisableModalAccountRowTitlesWrapper, DisableModalAccountRowWrapper, ModalPromptWrapper, PromptQuestion } from './ConnectedInstitutionModal.styles';
// import { AccountAltSeparator } from './ConnectedInstitutionsList.styles';

export const VipConnectionOnModal = ({ handleOk, title, onClose, institution, provider, amount, amountCurrency, isIncognito, astCategoryId, astTitle, hideDeleteOption }) => {
    const { metadataStore } = useStore();


    return (
        <BaseModal width={'600px'} height={'auto'}>
            <ModalHeader style={{ boxShadow: 'inset 0px -1px 0px #DDE3EB' }}>
                <ModalTitle style={{color:'#20CD7B'}}>
                  Successful sync account request
                </ModalTitle>
                <ModalClose onClick={onClose}>
                    <ModalCloseIcon />
                </ModalClose>
            </ModalHeader>
            <ModalContent style={{ gap: '24px', padding: '24px 68px 32px 68px' }}>
                <ModalPromptWrapper>
                    <PromptQuestion style={{paddingInline: '70px',textAlign: 'center', fontSize: '16px'}}>
                      This account is now auto-synced with Vyzer's VIP Syncing Service:
                    </PromptQuestion>
                    <DisableModalAccountRowWrapper style={{marginBottom:'20px'}}>

                        <InstitutionLogo institution={institution} provider={provider} customWrapperStyles={{ paddingLeft: '12px' }} />

                        <DisableModalAccountRowTitlesWrapper>
                            <DisableModalAccountRowTitle>{title} 
                            <div style={{ paddingRight: '8px' }}>
                                <CommonBadge badgeType={'vipService'} />
                            </div>
                            </DisableModalAccountRowTitle>
                            <DisableModalAccountRowSubTitle>
                                <span>{metadataStore.categoriesObj[astCategoryId].title}</span>
                                {/* <AccountAltSeparator />
                                <span>{astTitle}</span> */}
                            </DisableModalAccountRowSubTitle>
                        </DisableModalAccountRowTitlesWrapper>

                        <AccountBalanceAndActions style={{ marginRight: '16px' }}>
                            <AccountBalance isPositive={amount > 0}>
                                {displayMoneyValue(amount, amountCurrency, isIncognito, 100)}
                            </AccountBalance>
                        </AccountBalanceAndActions>


                    </DisableModalAccountRowWrapper>      
                    {/* Are you sure that you want to un-sync <span style={{ color: '#979797' }}>{props.title}</span>?
                    {`\n\n${props.isActionLocked ? `This asset will be removed from your assets list and moved to Archived.
                    You will be able to re-enable it only with ` :
                        `Vyzer will no longer sync your account (asset) and your data will not be updated.
                    \nYou can always re-sync your account from the "Synced accounts" tab under "My account", this will automatically create a new connected account (asset) and you will be able to delete the inactive one.`}`}
                    {props.isActionLocked && <VyzerMembershipLink onClick={handleVyzerMembershipOnClick}>Vyzer Membership</VyzerMembershipLink>} */}
                </ModalPromptWrapper>
            </ModalContent>
            <ModalFooter style={{ boxShadow: 'inset 0px -1px 0px #DDE3EB', display: 'flex', justifyContent: 'flex-end' }}>
                {/* <SecondaryButton onClick={onClose} style={{ padding: '5px 16px' }}>Cancel</SecondaryButton> */}
                <ModalButton onClick={() => { handleOk?.() }} >
                    Got it
                </ModalButton>
            </ModalFooter>
        </BaseModal>
    )
}