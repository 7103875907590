
import { Dropdown } from "antd";
import { Observer } from 'mobx-react';
import moment from 'moment';
import React, { useState } from 'react';
import { ArrowDownIcon, DeleteIcon, InstitutionPlaceholderIcon, ThreeDotsIcon } from '../../../../assets/icons/common/common-icons';
import { CommonBadge } from '../../../../common/components/CommonBadge/CommonBadge';
import { useStore } from '../../../app/data/root.store';
import { ConnectedInstitutionDeleteModal } from './ConnectedInstitutionDeleteModal';
import { FlexSpace, InstitutionHeader, InstitutionHeaderWrapper, InstitutionIconWrapper, LastSyncPrompt, LogoPlaceHolder, PanelHeaderRightButtonsWrapper, PanelHeaderStatusWrapper, PanelHeaderWrapper, ThreeDotsIconWrapper, VipPendingText } from './ConnectedInstitutionsList.styles';
import { CommonActionMenuItem, CommonActionMenuWrapper, DeleteIconContainer } from '../../../../common/styles/menus.styles';
import { RefreshIcon, WebSiteIcon, instSyncTimeAgo } from './institutionPanel.commons';
import { cleanURL, addHttpPrefixIfMissing } from "../../../../common/utils/string.utils";
import { ActionLockedIconWithTooltip } from "../../../../common/components/ActionLocked/ActionLockedIconWithTooltip";

export const VipConnectedInstitutionPanelHeaderRow = (props) => {
    const { institution, isOpen } = props;
    const [showConnectedInstitutionDeleteModal, setShowConnectedInstitutionDeleteModal] = useState(false);
    const { connectedInstitutionsStore, userStore, billingStore, metadataStore } = useStore();
    const connectedAccountsLength = institution.accounts?.filter(acc => acc.vipInstitutionConnection === 'connected').length || 0;
    const hasAnyConnectedAccount = connectedAccountsLength > 0;

    const handleUpdateMetadata = () => {
        metadataStore.fetchData();
    }

    const deleteInstitutionAccounts = (deleteType) => {
        return connectedInstitutionsStore.deleteAccounts('vip', institution.id, deleteType, () => {
            handleUpdateMetadata();
        });
    }

    const handlePanelOnClick = (e) => {
        if (userStore.isDemoOrReadOnlyMode) {
            billingStore.setShowPricingTableModal(true);
            e.stopPropagation();
        }
    }

    const handleDeleteInstitution = (action) => {
        if (action === 'manual') {
            deleteInstitutionAccounts('soft delete');
        }

        else if (action === 'archive') {
            deleteInstitutionAccounts('archive delete');
        }

        else if (action === 'delete' || !hasAnyConnectedAccount) {
            deleteInstitutionAccounts('hard delete');
        }

        handleUpdateMetadata();
        setShowConnectedInstitutionDeleteModal(false);
    }

    return (<Observer>{() => (
        <PanelHeaderWrapper onClick={handlePanelOnClick}>
            <InstitutionIconWrapper>
                {institution.logoBase64 ? <img style={{ width: '28px', height: '28px' }} alt={institution.institutionName} src={'data:image/png;base64,' + institution.logoBase64} /> :
                    institution.logoURL ? <img style={{ width: '28px', height: '28px' }} alt={institution.institutionName} src={institution.logoURL} /> :
                    <LogoPlaceHolder><InstitutionPlaceholderIcon /></LogoPlaceHolder>}
            </InstitutionIconWrapper>
            <InstitutionHeaderWrapper>
                <InstitutionHeader>{institution.institutionName} {institution.accounts?.length ? `(${institution.accounts.length})` : ''}</InstitutionHeader>
            </InstitutionHeaderWrapper>


            <FlexSpace />
            <PanelHeaderStatusWrapper>
                {institution.status === 'pending' && <VipPendingText><RefreshIcon />Pending</VipPendingText>}
                <CommonBadge badgeType={'vipService'} color={institution.status === 'pending' ? '#ACB1BB' : '#DEB482'} tooltipContent={institution.status === 'pending' ? 'Establishing the VIP Syncing Service can take some time.\nWe will email you when the process is complete.' : 'This is a VIP service.\nContact us for any question'} />
                {institution.status !== 'pending' && institution.latestPulling && <LastSyncPrompt>Last sync: {instSyncTimeAgo(moment(institution.latestPulling).toDate())}</LastSyncPrompt>}
            </PanelHeaderStatusWrapper>
            <PanelHeaderRightButtonsWrapper>
                <ArrowDownIcon style={{ transition: '0.2s', transform: `rotate(${isOpen ? -180 : 0}deg)` }} />
                <Dropdown overlay={InstitutionActionMenu({
                    institutionId: institution.institutionId,
                    instUrl: institution.instUrl,
                    handleOnInstitutionDelete: () => setShowConnectedInstitutionDeleteModal(true),
                    onUpdateAccountsSuccess: (data) => { connectedInstitutionsStore.fetchVipAccounts(institution.id, true); },
                    userStore: userStore,
                })}
                    getPopupContainer={() => document.getElementById('syncedAccountsPage')}
                    trigger={['click']} placement='bottomRight'>
                    <ThreeDotsIconWrapper isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
                        <ThreeDotsIcon />
                    </ThreeDotsIconWrapper>
                </Dropdown>
            </PanelHeaderRightButtonsWrapper>


            {showConnectedInstitutionDeleteModal && <div onClick={(e) => e.stopPropagation()}>
                <ConnectedInstitutionDeleteModal
                    onClose={() => setShowConnectedInstitutionDeleteModal(false)}
                    handleDelete={handleDeleteInstitution}
                    connectedAccountsLength={connectedAccountsLength}
                    title={institution.institutionName}
                    institution={institution}
                    provider={'vip'}
                    hideDeleteOption={true} />
            </div>}
        </PanelHeaderWrapper>
    )}</Observer>)
}

// function InstitutionActionMenu(props) {
//     return (
//         <InstitutionActionWrapper onClick={(e) => e.stopPropagation()}>
//             <InstitutionActionItem onClick={() => props.handleOnInstitutionDelete()}>
//                 <InstitutionActionItemTitle>Delete</InstitutionActionItemTitle>
//             </InstitutionActionItem>
//         </InstitutionActionWrapper>
//     )
// }

function InstitutionActionMenu({ instUrl, handleOnInstitutionDelete, userStore }) {

    const handleDeleteInstitution = () => {
        if(!userStore.isActionBlockedByRole('deleteConnectedInstitution')){
            handleOnInstitutionDelete();
        }
    }

    return (
        <CommonActionMenuWrapper onClick={(e) => e.stopPropagation()}>
            {instUrl &&
                <CommonActionMenuItem>
                    <a href={addHttpPrefixIfMissing(instUrl)} target="_blank" rel="noreferrer">
                        <WebSiteIcon />{cleanURL(instUrl)}
                    </a>
                </CommonActionMenuItem>
            }
            <CommonActionMenuItem color={'red'} onClick={handleDeleteInstitution}>
                <DeleteIconContainer color={'red'}><DeleteIcon /></DeleteIconContainer>Delete {userStore.isActionBlockedByRole('deleteConnectedInstitution') && <ActionLockedIconWithTooltip isRoleBlocked={true} />}
            </CommonActionMenuItem>
        </CommonActionMenuWrapper>
    )
}