import React, { useEffect, 
  // useState
 } from 'react'
import { AssetEventsList } from './AssetEventsList';
import { Observer } from 'mobx-react';
import {
  // ArrowIconWrapper, ArrowRowWrapper,EventTimeLine,
  EventSliderWrapper, 
  //  NotificationsCountsWrapper, PastNotificationsCount, TransactionsNotificationsCount 
} from './AssetEventRow.styles';
import { useStore } from '../../../../../app/data/root.store';

export const AssetEventSlider = (props) => {
  const { isIncognito, openEditEventModal, onCloseEditEventModal } = props;
  const { itemStore, eventsStore } = useStore();
  // const [itemLoadOnce, setItemLoadOnce] = useState(false);

  useEffect(() => {
    itemStore.setEventsSliderDefaultIndex(itemStore.startAndTodayIndex);
  }, [itemStore])
  

  return (
    <Observer>{() => (
      <EventSliderWrapper>
        {/* <EventTimeLine /> */}
        <AssetEventsList events={itemStore.itemEvents} item={itemStore.item} isIncognito={isIncognito} startIndex={itemStore.eventsSliderShowFutureEvents ? 0 : itemStore.eventsSliderStartIndex} markedEventIndex={eventsStore.markedEventIndex} visibleItems={itemStore.eventsSliderVisibleItems} openEditEventModal={openEditEventModal} onCloseEditEventModal={onCloseEditEventModal} />
      </EventSliderWrapper>
    )}</Observer>)
}
