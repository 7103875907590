import styled from "@emotion/styled/macro";

export const ImageTile = styled.div`
    background: ${props => props.fallbackColor} url(${props => props.image});
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: ${props => props.borderRadius || 10}px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    ${props=>props.size ? `
        width: ${props.size}px;
        height: ${props.size}px;
        svg {
            width: ${props.size - 8 < 40 ? props.size - 8 : 40}px;
            height: ${props.size - 8 < 40 ? props.size - 8 : 40}px;
        }
    ` : ``}
    ${props=>props.isSelectable ? `
    :hover {
        ::after {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 14px;
            border: 2px solid #000;
            background-color: transparent;
            margin: -4px;
        }
    }
    ${props.isSelected ? `
        ::after {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 14px;
            border: 2px solid #000;
            background-color: transparent;
            margin: -4px;
        }
        ` : ``}
    ` : ``}
`;
