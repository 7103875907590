import React from 'react'
import { Observer } from 'mobx-react';
// import { useStore } from '../../../app/data/root.store';
// import { PricingModal } from '../PricingModal/PricingModal';
import { ReadOnlyBanner } from '../../../layout/components/Banner/ReadOnlyBanner';

export const ReadOnlyMode = (props) => {
  // const { billingStore } = useStore();

  return (<Observer>{() => (
    <>
      <ReadOnlyBanner />
      {/* {billingStore.isShowPricingTablePopup && <PricingModal onBackDropClick={() => { billingStore.setShowPricingTableModal(false)}} />} */}
    </>
  )}</Observer>)
}
