import { Observer } from 'mobx-react';
import React from 'react';
import { percentageDisplay } from '../../../../common/utils/number-display.utils';
import { useStore } from '../../../app/data/root.store';
import { CommunityMetricsPaneWrapper, CommunityOverviewHeroDataHeader, CommunityOverviewHeroDataWrapper, CommunityPillarPercentage, CommunityPillarRectangle, CommunityPillarRectangleHoverEffect, CommunityPillarRectangleWrapper, CommunityPillarTotal, CommunityPillarWrapper, SingleClassTitle, SingleClassTitleWrapper, VerticalSeparator } from './CommunityOverviewHero.styles';
import { CommunityPillar } from './CommunityPillar';

export const CommunityOverviewHeroData = (props) => {
  
  const { communityStore, userStore, uiStore } = useStore();

  return (<Observer>{() => (
    <CommunityMetricsPaneWrapper>
      <CommunityOverviewHeroDataHeader>
        {communityStore.isClassView ?
          <SingleClassTitleWrapper>
            <SingleClassTitle color={communityStore.selectedClass?.color}>{communityStore.selectedClass?.title}</SingleClassTitle> allocation by net worth
          </SingleClassTitleWrapper> :
          'Community Net Worth'}
      </CommunityOverviewHeroDataHeader>
      <CommunityOverviewHeroDataWrapper isClassView={communityStore.isClassView}>

        {communityStore.isClassView ? <>
          <CommunityPillarWrapper maxPillarPercentages={communityStore.maxPillarPercentages} isClassView>
            <CommunityPillarRectangleWrapper >
              <CommunityPillarPercentage>
                {percentageDisplay(communityStore.selectedClass?.myAllocation?.totalWorthPercentage || 0)}
                {uiStore.isDesktopView && <CommunityPillarRectangleHoverEffect>
                  {percentageDisplay(communityStore.selectedClass?.myAllocation?.totalWorthPercentage || 0)} of your portfolio is invested in {communityStore.selectedClass?.title}.
                </CommunityPillarRectangleHoverEffect>}
              </CommunityPillarPercentage>
              <CommunityPillarRectangle isDimmed={communityStore.pillarHoverData?.index !== -1 && communityStore.pillarHoverData?.index !== 4} classColor={'#6392DA'} 
              height={communityStore.selectedClass?.myAllocation?.totalWorthPercentage} 
              maxPillarPercentages={communityStore.maxPillarPercentages}/>
            </CommunityPillarRectangleWrapper>
            <CommunityPillarTotal style={{ color: '#6392DA' }} isClassView={communityStore.isClassView}>{userStore.firstNameCapitalize}</CommunityPillarTotal>
          </CommunityPillarWrapper>
          <VerticalSeparator />
        </> : null}

        {communityStore.pillarsData.slice(0, 4).map((pillarData, pillarIndex) => (
          <CommunityPillar maxPillarPercentages={communityStore.maxPillarPercentages} isMobileView={!uiStore.isDesktopView} key={pillarIndex} pillarIndex={pillarIndex} pillarData={pillarData} communityStore={communityStore} isClassView={communityStore.isClassView && communityStore.selectedClass} classDetails={communityStore.selectedClass} />
        ))}

      </CommunityOverviewHeroDataWrapper>
    </CommunityMetricsPaneWrapper>
  )}</Observer>)
}