import moment from "moment";
import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { disabledMonthBefore } from "../../../../../common/utils/dates.utils";
import { containerRow } from "../../common-fields/container-field.const";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { isBetweenZeroAndHundred, isNotNegative, isRequired } from "../../input-validations";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { isNullOrUndefinedOrEmptyString } from "../../../../../common/utils/object.utils";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";


export const createPrivateLoanNonAmortizedAddItemSections = (isEditForm) => {
  return ({ updateValue, updateField }) => {
    return [
      {
        title: 'General info',
        rows: [
          {
            fields: [
              {
                key: 'borrower', type: 'text', label: 'Borrower', description: 'The person or entity responsible for repaying the loan', validations: [isRequired],
                onBlur: ({ value, formValues }) => {
                  if (!formValues.title){
                    updateValue('title', value);
                  } 
                }
              },
            ]
          },
          {
            fields: [
              { key: 'title', label: 'Loan name', description: `This is the name you'll use to search for this private loan in Vyzer`, validations: [isRequired] },
            ]
          },
          ...masterInstitutionInputRows('Agent / Platform', 'The platform or agent servicing this loan', 1, false, updateValue),
          beneficiaryRow({label: 'Holding entity / Policy owner', description:`The person or company listed as the asset's owner`}),
          ...(isEditForm ? [
            ...customClassAndSubClassRows({updateValue,updateField}),
          ] : [

          ]),
        ]
      },
      {
        ...(isEditForm ? {} : {title: 'Loan info',}),
        rows: [
          ...(isEditForm ? [] : [
          {
            fields: [
              {
                key: 'startDate', label: 'Initial loan date',
                description: 'Enter the date this loan was first issued',
                validations: [isRequired],
                // defaultValueFn: () => { return new Date() },
                onChange: ({ value , formValues }) => {
                  const oneMonthFromStartDate = moment(value).add(1, 'month');
                  updateField('privateLoanNonAmortizedPayments',{startDate: oneMonthFromStartDate} )
                  updateField('expectedEndDate',{disabledDate : (current) => disabledMonthBefore(current,oneMonthFromStartDate)} )
                  if (!isEditForm) {
                    lastPaymentEventCreation({formValues, updateValue});
                  }
                },
              }
            ]
          },
          {
            fields: [
              {
                key: 'value', type: 'numberWithHoldings', label: 'Initial loan amount', description: 'The amount you lent to the borrower on the initial loan date.', validations: [isRequired],
                onBlur: ({ value , formValues }) => {
                  updateValue('cashInvested', value);
                  if (!isEditForm) {
                    lastPaymentEventCreation({formValues, updateValue});
                  }
                }, generatePropsFn: (values) => {
                  return { prefix: CURRENCY_SYMBOL[values.currency] || '' }
                }
              },
              {
                key: 'currency', size: 0.3, onChange: ({ value }) => {
                  updateValue('cashInvestedCurrency', value);
                  updateField('value', { prefix: CURRENCY_SYMBOL[value] || '' });
                  updateField('cashInvested', { prefix: CURRENCY_SYMBOL[value] || '' });
                }
              },
            ]
          },
            {
              fields: [
                {
                  key: 'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden: true,
                  validValues: ({ floatValue }) => { return !floatValue || (floatValue >= 0 && floatValue <= 100) },
                  validations:[isBetweenZeroAndHundred],
                  onBlur: ({ value }) => {
                    updateField('value', { holdings: value || '' });
                    updateField('cashInvested', { holdings: value || '' });
                    updateField('privateLoanNonAmortizedPayments',{ holdings: value || '' });
                  },
                  generatePropsFn: (values) => {
                    return { isHidden: values.holdings === 100 }
                  }
                }
              ]
            },
            {
              fields: [
                {
                  type: 'checkbox', key: 'isPartialHolding', checkboxLabel: 'This loan is 100% lent by me', description: 'Uncheck this box if you co-own this asset',
                  fieldCustomStyle: { marginTop: '-24px' },
                  defaultValue: true,
                  onChange: ({ value }) => {
                    const newHoldingValue = value ? 100 : 50;
                    updateValue('holdings', newHoldingValue);
                    updateField('holdings', { isHidden: value });
                    updateField('value', { holdings: newHoldingValue });
                    updateField('cashInvested', { holdings: newHoldingValue });
                    updateField('privateLoanNonAmortizedPayments',{ holdings: newHoldingValue });
                  }
                },
              ]
            },
          {
            fields: [
              {
                key: 'interest',
                label: 'Expected annual interest rate',
                // description: 'Enter the initial interest rate of the loan',
                // validations: [isRequired],
                specificDecimalScale: 3,
                showHistory: false,
                defaultValue: 0,
                onBlur: ({ value, formValues }) => {
                  if (!isEditForm) {
                    lastPaymentEventCreation({formValues, updateValue});
                  }
                },
                validations: [isNotNegative]
              }
            ]
          },
        ]),
        {
          fields: [
            {
              key: 'expectedEndDate',
              label: 'Expected payoff date',
              isHidden: isEditForm,
              description: 'The date which the borrower is expected to have completed the repayment of the loan amount along with interest.',
              // validations: [isRequired],
              generatePropsFn: (formValues) => {
                return {
                  disabledDate: (current) => disabledMonthBefore(current, moment(formValues.startDate).add(1, 'month')),
                }
              },
              onChange: ({value, formValues}) => {
                if (!isEditForm) {
                  lastPaymentEventCreation({formValues, updateValue});
                }
              },
              setFnForSave: (isDistribution, value, formValues) => {
                const { privateLoanNonAmortizedPayments } = formValues;
                if (!privateLoanNonAmortizedPayments || !privateLoanNonAmortizedPayments.length) return value;
                return privateLoanNonAmortizedPayments[privateLoanNonAmortizedPayments.length - 1].date;
              },
            }
          ]
        },
        ]
      },
      {
        ...(isEditForm ? {title: 'Loan info',} : {
          title: 'Target payment events',
          margin: '0 -24px',
        }),
        fullWidth: true,
        rows: [
          {fields: [
            {
              key:'privateLoanNonAmortizedPayments',
              label: 'Expected payments',
              labelId: 'private-loan-non-amortized-payments',
              description: 'The amount you committed, divided into several "call events".',
              type:'singleLoanPaymentEventScheduler',
              // defaultValue:[],
              generatePropsFn : (values) => {
                  return {
                    prefix: CURRENCY_SYMBOL[values.currency] || 'USD',
                    placeholder: CURRENCY_SYMBOL[values.currency],
                    startDate: values.startDate,
                    holdings: values.holdings,
                }
              },
              headerTooltip: `The sum of each single expected payment, including principal and interest.`,
              onShow:({values})=>{
                  updateValue('privateLoanNonAmortizedPayments', [{value:"",date: moment(values.startDate).add(1, 'month'),remarks: "",tempId: 1}]);
              },
              // ClickToShowComponent:SingleEventSchedulerPrevComponent
              setFnForSave: (isDistribution, value, formValues) => {
                return value?.filter(i => i.principal || i.interest || i.value)
                    .map(({ id, principal, interest, value: rowValue, remarks, date }) => {
                        const principalValue = principal || (!interest && rowValue) || '';
                        return {
                            id,
                            value: {
                                principal: principalValue,
                                interest,
                            },
                            remarks,
                            date,
                        };
                    });
              },            
              overrideValueFn: (formValues) => {
                  return formValues.privateLoanNonAmortizedPayments?.map(dataRow=> ({
                      id: dataRow.id,
                      value: dataRow.value.principal + dataRow.value.interest,
                      principal: dataRow.value.principal,
                      interest: dataRow.value.interest,
                      date: dataRow.date,
                      remarks: dataRow.remarks,
                  }));
              },
            }
          ]},
          ...(isEditForm ? [
            {
              fields: [
                {
                  key: 'interest',
                  label: 'Expected annual interest rate',
                  labelId: 'expected-interest',
                  // description: 'Enter the initial interest rate of the loan',
                  // validations: [isRequired],
                  specificDecimalScale: 3,
                  showHistory: false,
                  defaultValue: 0,
                  onBlur: ({ value, formValues }) => {
                    if (!isEditForm) {
                      lastPaymentEventCreation({formValues, updateValue});
                    }
                  },
                  validations: [isNotNegative]
                }
              ]
            },
            {
              fields: [
                  { key: 'expectedIRR', setFnForSave: (isDistribution, value, formValues) => {
                    return isNullOrUndefinedOrEmptyString(value) ? 0 : value
                  } }
              ]
            },
          ] : []),
        ]
      },
      {
        title: 'More info',
        rows: [
          ...(isEditForm ? [
            {
              fields: [
                  {
                      key: 'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isLocked: true, UnlockComponent: HoldingsUnlockModal,
                      validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                      validations:[isBetweenZeroAndHundred],
                  }
              ]
            },
            containerRow({isEditForm, updateField}),
          ] : [
            {
              fields: [
                  { key: 'expectedIRR', setFnForSave: (isDistribution, value, formValues) => {
                    return isNullOrUndefinedOrEmptyString(value) ? 0 : value
                  } }
              ]
            },
          ]),
          ...(isEditForm ? [
            riskLevelRow({updateField}),
            liquidityRow({updateField}),
          ] : []),
          {
            fields: [
              { key: 'remarks' }
            ]
          }
        ]
      }
    ]
  }
}

const lastPaymentEventCreation = ({formValues, updateValue}) => {
  const { startDate, value, expectedEndDate, interest } = formValues;
  if (startDate && value && interest >= 0 && expectedEndDate) {
    const periodInMonths = moment(expectedEndDate).diff(moment(startDate), 'months');
    const monthlyRate = interest / 100 / 12;
    const compoundedAmount = value * Math.pow(1 + monthlyRate, periodInMonths);
    const compoundedLastInterestPayment = compoundedAmount - value;
    updateValue('privateLoanNonAmortizedPayments', [
      {
        value: value + compoundedLastInterestPayment,
        principal: value,
        interest: compoundedLastInterestPayment,
        date: moment(expectedEndDate).set("D", 15).startOf("D"),
        remarks: "",
        tempId: 1,
      }
    ]);
  }
}