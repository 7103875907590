import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PasswordDisplayer } from '../../../../common/components/PasswordDisplayer/PasswordDisplayer';
import { postData } from '../../../../common/utils/network.utils';
import { useStore } from '../../../app/data/root.store';
import { AUTH_STEPS } from '../../../user/data/user.store';
import { BackButtonWrapper, ForgotPasswordStepWrapper, InputLabel, InputWrapper, LoginForm, LoginFormButton, SetPasswordStepHeader } from '../LoginPage.styles';
import { isValidPassword, transformEmailForMixpanel } from '../../../../common/utils/string.utils';
import { errorNotification } from '../../../layout/components/Notifications/notifications';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { useRecaptchaHook } from '../useRecaptchaHook';

export const SetPasswordStep = observer((props) => {
  const { userStore } = useStore();
  const passwordInputRef = useRef(null);
  const { verifyCode, email, isInvitation, invitationToken } = userStore.authFields;
  const [createRecapcthaToken, onRecaptchaReset, MemoizedRecaptchaComponent] = useRecaptchaHook();
  const { mixpanel, trackEvent, mixpanelReset } = useAnalytics();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const passKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleSetPassword();
    }
  }

  const handleSetPassword = async () => {
    

    const isPasswordValid = isValidPassword(passwordInputRef.current.state.value);
    if (!isPasswordValid) {
      return errorNotification('Password must be at least 8 characters and contain 1 number, 1 lowercase letter, 1 uppercase letter, and 1 special character');
    }

    const urlParams = new URLSearchParams(location.search);
    const paramsToSend = {
      utm_source: urlParams.get('utm_source') || mixpanel.get_property('utm_source'),
      utm_medium: urlParams.get('utm_medium') || mixpanel.get_property('utm_medium'),
      utm_campaign: urlParams.get('utm_campaign') || mixpanel.get_property('utm_campaign'),
      utm_id: urlParams.get('utm_id') || mixpanel.get_property('utm_id')
    };

    const queryString = Object.keys(paramsToSend).filter(key => paramsToSend[key]).map(key => key + '=' + paramsToSend[key]).join('&');

    setIsLoading(true);

    try {
      const regUrl = (isInvitation ? "user/invitation-register" : "user/set-password") + (queryString ? ("?" + queryString) : '');
      let recaptchaToken = '';
      if (isInvitation) {
        recaptchaToken = await createRecapcthaToken();
      }

      mixpanel.identify(transformEmailForMixpanel(email));

      const data = await postData(regUrl, {
        email,
        password: passwordInputRef.current.state.value,
        ...(isInvitation ? { token: invitationToken, recaptchaToken } : { code: verifyCode })
      });

      if (data.success) {
        window.location.reload();
      } else {
        // Handle unsuccessful set password response
        mixpanelReset()
      }
    } catch (error) {
      // Handle error
      mixpanelReset()
    } finally {
      onRecaptchaReset && onRecaptchaReset();
      setIsLoading(false);
      trackEvent('CTA Clicked', {
        cta_text: "Create account",
        location: "Set password page",
        destination: 'Welcome page',
        type: "Button",
        url: window.location.href
      });
    }
  };

  return (
    <>
      <BackButtonWrapper onClick={() => { userStore.setAuthStep(userStore.prevAuthStep === AUTH_STEPS.INVITATION ? AUTH_STEPS.INVITATION : AUTH_STEPS.LOG_IN_STEP) }}><BackArrowSvg /> Back</BackButtonWrapper>
      <ForgotPasswordStepWrapper>
        <SetPasswordStepHeader>Set a secured password</SetPasswordStepHeader>
        <LoginForm>
          <InputWrapper style={{ marginBottom: '40px' }}>
            <InputLabel>Password</InputLabel>
            <PasswordDisplayer showHints ref={passwordInputRef} passKeyPress={passKeyPress} placeholder='Create a secure password' />
          </InputWrapper>
          <LoginFormButton onClick={handleSetPassword}>{isLoading ? <LoadingOutlined /> : 'Create account'}</LoginFormButton>
        </LoginForm>
        <MemoizedRecaptchaComponent />
      </ForgotPasswordStepWrapper>
    </>
  )
})

const BackArrowSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6803 16.0137L10.347 12L13.6803 7.98633" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}