import React, { useRef , useEffect }  from 'react';
import Chart from 'chart.js/auto';
// import Chart from "chart.js";
// import { numberWithCommas } from '../../utils/number-display.utils';


export function ChartComponent(props) {

    const {type, chartId, data, options, plugins } = props;
    const chartCanvasRef = useRef(null);
    const chartEntityRef = useRef(null);

    const renderChart = () => {
        if (!chartCanvasRef.current) return;
    
        chartEntityRef.current = new Chart(chartCanvasRef.current, {
          type : type || 'doughnut',
          data,
          options: options && { ...options },
          plugins,
        });
        
        // reforwardRef(ref, chartRef.current);
    };

    const destroyChart = () => {
        // console.log('Destroying');
        if (chartEntityRef.current) {
            chartEntityRef.current.destroy();
            chartEntityRef.current = null;
        }
    }
    

    // useEffect(() => {
    //     // console.log("A1",chartEntityRef.current,props.options,props.data);
    //     console.log("A1");
    //     if ( chartEntityRef.current !== null || props.options === null || props.data === null )
    //     return;
    //     console.log("A2");
    //     const canvasContext = chartCanvasRef.current; // .getContext("2d");
    //     const config = {
    //         type: props.type || 'doughnut',
    //         data: props.data,
    //         options:props.options,
    //         plugins: props.plugins  || []
    //     }
    //     const chartEnt =  new Chart(canvasContext, config);
    //     chartEntityRef.current = chartEnt;
    //     // console.log("Hello A");
    //     return () => {
    //         if (chartEntityRef.current) {
    //             console.log("Destroying");
    //             chartEntityRef.current.destroy();
    //             chartEntityRef.current = null;
    //         }
    //         //chartEnt.destroy();
    //     }
    // }, [props.type,props.data,props.options,props.plugins]); 

    useEffect(() => {
        // console.log("Hello B", chartEntityRef.current , props.data); 
        // chartEntityRef.current.data.datasets.forEach((element,index) => {
        //     element.data = props.data.datasets[index].data;
        // });
        // console.log(chartEntityRef.current.data , props.data);
        if (chartEntityRef.current){
            if (chartEntityRef.current.config.data.datasets && chartEntityRef.current.config.data.datasets.length === props.data.datasets.length ){
                // chartEntityRef.current.config.data = props.data;
                 // chartEntityRef.current.config.data.datasets[0] = props.data.datasets[0];
                 // Object.assign(chartEntityRef.current.config.data.datasets[0], props.data.datasets[0]);
                 chartEntityRef.current.config.data.datasets.forEach((element, index) => {
                    Object.assign(element, props.data.datasets[index]);
                 });
                 if (props.data.labels && props.data.labels.length){
                    chartEntityRef.current.config.data.labels = props.data.labels;
                 }
                 // currentData.labels = nextLabels;
            } else {
                chartEntityRef.current.data = props.data;
            }
            chartEntityRef.current.update();
        }
        return () => {}
    }, [props.data]); 

    useEffect(() => {
        // console.log("Hello C", chartEntityRef.current , props.options); 
        if (chartEntityRef.current){
            chartEntityRef.current.options = props.options; // data.datasets.forEach((element,index) => {        
            chartEntityRef.current.update();
        }
        return () => {}
    }, [props.options]); 

    useEffect(() => {
        renderChart();
    
        return () => destroyChart();
    }, []); /* eslint-disable-line  react-hooks/exhaustive-deps */


    return (
        <canvas
            {...(chartId ? {id : chartId} : {})}
            width={props.width}
            height={props.height}
            ref={chartCanvasRef}
        />
    )
}