
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import { CreatePortfolioMessage, EmptyStateText, EmptyStateWrapper, LinkSpan, MobileUserMenuWrapper, MoreInfo, NameAndInfo, NameText, PortfolioItemSelectedCircle, PortfolioItemWrapper, PortfoliosCenterContent, PortfoliosListWrapper, PortfoliosSubTitle, PortfoliosTitle, PortfoliosWrapper, RolesNameIconWrapper } from './portfolios.styles';
import { PrimaryButton } from '../../../../common/components/buttons/NewButtons.styles';
import { permissionsTexts } from '../Roles/RoleRow';
import { ls } from '../../../../common/utils/localstorage.util';
import { UserProfileMenu } from '../../../layout/components/Sidebar/UserProfileMenu';

const PortfoliosPage = observer(() => {
    const { userStore, uiStore } = useStore();

    const [selectedPortfolioIndex, setSelectedPortfolioIndex] = useState(userStore.isViewingOwnPortfolio && userStore.isGranteeHavePortfolio ? -1 : (userStore.currentPortfolioDetails.index || 0));
    // const [userHaveIsOwnPortfolio, setUserHaveIsOwnPortfolio] = useState(false); 
    const isAfterInvitation = ls.get('isAfterInvitation');

    const handleSwitchPortfolio = () => {
      if (isAfterInvitation) {
        ls.remove('isAfterInvitation');
      }
      if (selectedPortfolioIndex === -1) {
        userStore.switchAccount(userStore.data?.granteeProfile?.id || userStore.data.id);
      } else {
        userStore.switchAccount(userStore.sharedPortfolios[selectedPortfolioIndex].ownerId);
      }
    }

    const startMyPortfolio = () => {
      if (isAfterInvitation) {
        ls.remove('isAfterInvitation');
      }
      userStore.startMyPortfolio();
    }

    return (
        <PortfoliosWrapper>
            {/* {uiStore.isDesktopView ? <></> :
            <OnBoardingHeader  whiteBG />
            } */}
            <PortfoliosCenterContent>
                <PortfoliosTitle>Welcome{isAfterInvitation && !userStore.isGranteeHavePortfolio && userStore.sharedPortfolios.length === 1 ? ` to Vyzer` : ``}, {userStore.granteeFirstNameCapitalize}</PortfoliosTitle>
                <PortfoliosSubTitle>Select a portfolio:</PortfoliosSubTitle>
                {( userStore.isGranteeHavePortfolio || userStore.sharedPortfolios.length > 0 ) &&
                <PortfoliosListWrapper>
                  { userStore.isGranteeHavePortfolio ? 
                     <PortfolioItemWrapper key={'owner-row'} isSelected={selectedPortfolioIndex === -1}
                       onClick={() => { setSelectedPortfolioIndex(-1) }}>
                          <PortfolioItemSelectedCircle isSelected={selectedPortfolioIndex === -1} />
                            <RolesNameIconWrapper isOwner={true}>
                                {userStore.granteeFirstNameFirstLetterCapitalize}
                            </RolesNameIconWrapper>
                            <NameAndInfo>
                                <NameText>{userStore.granteeFullName}</NameText>
                                <MoreInfo>Personal portfolio · Owner</MoreInfo>
                            </NameAndInfo>
                      </PortfolioItemWrapper> : null }
                     {userStore.sharedPortfolios.map((portfolio, index) => (
                       <PortfolioItemWrapper key={index} isSelected={selectedPortfolioIndex === index}
                       onClick={() => { setSelectedPortfolioIndex(index) }}>
                           <PortfolioItemSelectedCircle isSelected={selectedPortfolioIndex === index} />
                           <RolesNameIconWrapper isOwner={portfolio.isOwner} color={portfolio.color} cIndex={index}>
                               {portfolio.name.charAt(0).toUpperCase()}
                           </RolesNameIconWrapper>
                           <NameAndInfo>
                               <NameText>{portfolio.name}</NameText>
                               <MoreInfo>{permissionsTexts[portfolio.role]} · {portfolio.roleDescription}</MoreInfo>
                           </NameAndInfo>
                       </PortfolioItemWrapper> 
                     ))}
                </PortfoliosListWrapper> }
                { ( userStore.isGranteeHavePortfolio || userStore.sharedPortfolios.length > 0 ) ? <PrimaryButton style={{width:'100%', height:'56px' , borderRadius:'8px'}} onClick={handleSwitchPortfolio} >Continue</PrimaryButton> : 
                   <EmptyStateWrapper>
                    <EmptyStateSvg /> 
                    <EmptyStateText>You have no shared portfolios.</EmptyStateText>
                  </EmptyStateWrapper>
                }
                {!userStore.isGranteeHavePortfolio && <CreatePortfolioMessage>Want to try Vyzer? <LinkSpan onClick={startMyPortfolio}>Start my portfolio</LinkSpan> </CreatePortfolioMessage> }
                {!uiStore.isDesktopView && <MobileUserMenuWrapper><UserProfileMenu /></MobileUserMenuWrapper>}
            </PortfoliosCenterContent>
        </PortfoliosWrapper>
        
    );
});

export default PortfoliosPage;

const EmptyStateSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="90" height="60" viewBox="0 0 90 60" fill="none">
  <line x1="15" y1="53.2895" x2="90" y2="53.2895" stroke="#9097A3" strokeWidth="0.789474"/>
  <path d="M66.3159 56.0526L82.8949 56.0526" stroke="#9097A3" strokeWidth="0.789474"/>
  <path d="M33.1663 6.82272C32.8429 5.75611 31.8298 5.13155 30.6352 5.13155C29.3996 5.13155 28.356 5.80085 28.0479 6.9184C27.3668 9.38927 26.4475 14.5004 26.4475 24.0789C26.4475 31.3278 28.2472 36.5296 30.1243 39.986C32.2274 43.8585 36.6724 45.3947 41.2218 45.3947C41.7037 45.3947 42.1581 45.1404 42.5562 44.6658C42.9551 44.1901 43.2695 43.5221 43.455 42.7874C43.6402 42.0541 43.6902 41.2808 43.5841 40.6068C43.4772 39.9284 43.2199 39.3915 42.8308 39.0698C38.5969 35.5698 34.9213 30.6395 34.9213 24.0789C34.9213 14.3665 33.9023 9.25008 33.1663 6.82272Z" fill="#DDE3EB" stroke="#9097A3" strokeWidth="0.789474"/>
  <path d="M32.0763 5.61128C32.0126 5.5493 31.9271 5.52637 31.8382 5.52637C31.5061 5.52637 31.2475 5.83801 31.2945 6.16681C31.5117 7.68521 31.9738 12.3006 31.9738 24.079C31.9738 33.614 35.1538 37.0919 37.5746 38.3581C39.2142 39.2156 41.1477 39.4549 42.8506 40.1786C44.3258 40.8056 44.4185 40.7219 43.1376 39.7582C38.6585 36.3885 34.7369 31.3594 34.7369 24.079C34.7369 9.07025 32.5765 6.09824 32.0763 5.61128Z" fill="#F7F8F9"/>
  <path d="M41.337 3.65996C41.5761 1.77955 43.1869 0.394737 45.0944 0.394737C47.0806 0.394737 48.745 1.89713 48.9477 3.87301L50.1318 15.4185L50.8695 56.3594C50.8908 57.5403 49.7826 58.4204 48.6352 58.1335C46.2508 57.5374 43.7527 57.5365 41.3681 58.1326C40.2164 58.4206 39.1158 57.55 39.1424 56.3731C39.338 47.7201 39.9358 21.9484 40.2625 15.4144C40.4865 10.9358 40.9654 6.58212 41.337 3.65996Z" fill="#F7F8F9" stroke="#9097A3" strokeWidth="0.789474"/>
  <path d="M41.8612 3.14546C42.063 1.77871 43.2425 0.789551 44.6241 0.789551C44.8284 0.789551 44.9909 0.960884 44.9802 1.16492L44.2105 15.7738L44.9649 55.02C44.9856 56.0942 44.2806 57.0477 43.2476 57.3429L41.542 57.8302C40.5168 58.1231 39.5017 57.3495 39.5297 56.2836C39.7323 48.5742 40.427 22.3089 40.6578 15.7738C40.8349 10.7608 41.4529 5.91126 41.8612 3.14546Z" fill="#DDE3EB"/>
  <path d="M58.9122 7.86893C59.0457 6.75407 59.991 5.9211 61.1178 5.9211C62.0351 5.9211 62.8424 6.47499 63.1314 7.32468C64.1088 10.1981 65.9211 16.3312 65.9211 22.2647C65.9211 26.2745 63.5585 30.9324 61.1601 34.6128C58.5355 38.6404 53.8499 40.6579 48.9818 40.6579C48.5899 40.6579 48.2112 40.4283 47.8758 39.966C47.5414 39.505 47.2877 38.8579 47.1549 38.1557C47.0223 37.4549 47.016 36.7303 47.1487 36.1247C47.2828 35.5122 47.5449 35.0775 47.8986 34.8624C52.5581 32.0284 56.6103 28.1324 57.1819 22.9915C58.0592 15.1017 58.6129 10.3678 58.9122 7.86893Z" fill="#F7F8F9" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="62.3128" y1="11.4514" x2="67.8391" y2="10.6619" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="57.0832" y1="11.5295" x2="61.5055" y2="14.9363" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="38.1354" y1="26.5295" x2="42.5578" y2="29.9363" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="64.9576" y1="22.4325" x2="61.7996" y2="24.5625" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="42.0042" y1="48.518" x2="37.2673" y2="50.648" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="50.7471" y1="5.06407" x2="47.5892" y2="7.19412" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="37.3262" y1="35.0641" x2="34.1683" y2="37.1941" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="35.3717" y1="9.21419" x2="33.167" y2="12.3205" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="54.8463" y1="24.3435" x2="56.6879" y2="29.6134" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="42.2149" y1="11.7119" x2="44.0566" y2="16.9818" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="23.859" y1="29.4622" x2="28.8598" y2="31.9433" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="24.6491" y1="13.857" x2="29.6498" y2="16.3381" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="32.3772" y1="23.875" x2="36.8968" y2="20.5983" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="47.926" y1="23.4834" x2="52.4456" y2="20.2067" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="59.6908" y1="33.9797" x2="65.264" y2="33.66" stroke="#9097A3" strokeWidth="0.789474"/>
  <line x1="48.1351" y1="46.5048" x2="53.7084" y2="46.1852" stroke="#9097A3" strokeWidth="0.789474"/>
</svg>
)

