import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../../../common/styles/combos.styles';
import { colors, shadows } from '../../../../../../common/styles/theme.styles';
import NumberFormat from 'react-number-format';

export const InputsRowWrapper = styled.div`
    background-color:#fff;
    box-shadow:${shadows.cardShadow};
    border: 1px solid #DDE3EB;
    border-radius: 8px;
    width:100%;
    margin-top:8px;
    display:flex;
    flex-direction:row;
    align-items: stretch;
    padding:10px;
    justify-content:space-between;
    min-height:53px;
`;

export const AddLinkButton = styled.div`
    background-color:#fff;
    box-shadow:${shadows.cardShadow};
    border-radius:8px;
    margin-top:8px;
    padding:8px 14px 8px 8px;
    min-height:53px;
    cursor: pointer;
    color:${colors.darkGray2};
    font-size:14px;
    ${flexCenter};
    display:inline-flex;
    svg{margin:4px;}
`;

export const AddButton = styled.div`
    cursor: pointer;
    color:${colors.darkGray2};
    font-size:14px;
    ${flexCenter};
    justify-content:flex-start;
    svg{margin:4px;}
    flex:1;
`;

export const NameInput = styled.input`
    border:none;
    outline:none; 
    margin-left:4px;
    flex:1;
    ::-webkit-input-placeholder { /* Edge */
        color: ${colors.darkGray2};
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${colors.darkGray2};
    }

    ::placeholder {
        color: ${colors.darkGray2};
    }
`;

export const CategorySelectorWrapper = styled.div`
    ${flexCenter}
    border-left:1px solid ${colors.gray3};
    padding-left:24px;
    padding-right:24px;
`;

export const CategorySelectButtonCustomStyles = {
    boxShadow: 'none',
    border: 'none',
    fontWeight: 'normal'
    
    //backgroundColor:'red'
}

export const ClassIconWrapper = styled.div`
    /* margin-left:24px; */
    height:20px;
    width:20px;
    min-width:20px;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg{
        width:14px;
        height:14px;
    }
    path{  
        fill:#fff;
    }
`;

export const ValueInputWrapper = styled.div`
    ${flexCenter}
    border-left:1px solid ${colors.gray3};
    padding-left:24px;
    ${props=>props.isHidden &&
    'display:none'};
`;

export const StyledValueInput = styled(NumberFormat)`
    border: none;
    outline:none;
    ::-webkit-input-placeholder { /* Edge */
        color: ${colors.darkGray2};
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${colors.darkGray2};
    }

    ::placeholder {
        color: ${colors.darkGray2};
    }
`;

export const ActionsWrapper = styled.div`
    width:100%;
    margin-top:8px;
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    // padding:10px;
    // justify-content:space-between;
`;

export const CreateButton = styled.button`
    background-color:${colors.darkGray1};
    border-radius:2px;
    border:1px solid ${colors.darkGray1};
    padding:2px 16px;
    color:#fff;
    font-size:16px;
    margin-right:2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:disabled {
        cursor: default;
        opacity:0.3;
    }
`;