import { Checkbox, DatePicker, Input, Tooltip } from "antd";
import moment from 'moment';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { TooltipIcon } from '../../../../../assets/icons/common/common-icons';
import { colors } from '../../../../styles/theme.styles';
import { disabledLessThanDate } from '../../../../utils/dates.utils';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../../utils/object.utils';
import { CheckboxWrapper } from "../../FormDisplayer.styles";
import { AmountWrapper, HoldingsBadgeText, HoldingsBadgeTriangle, HoldingsBadgeWrapper, InputWithBudgeWrapper } from '../ScheduleTable/ScheduleTable.styles';
import { AmountPercentageWrapper, HeaderCell, PrevComponentButton, PrevComponentButtons, PrevComponentDescription, PrevComponentWrapper, RowCell, RowWrapper, TableBottomActions, TableHeaderRow, TooltipWrapper, Wrapper } from './SimpleExitEventRow.styles';

const tableColumns = ['Date', 'Total distribution', 'Notes'];

const { TextArea } = Input;

export function SimpleExitEventRow({ onChange, disabled, value, rowValuePreFix, startDate, headerTooltip, holdings, autoCalculateTooltip }) {
    const [focusedInput, setFocusedInput] = useState(0);

    const handleFieldChange = (fieldKey, fieldValue) => {
        const currentFieldValue = value?.[fieldKey];
        if (currentFieldValue !== fieldValue) {
            let updatedValue = {
                ...value,
                [fieldKey]: fieldValue
            };
            if (fieldKey === 'date'){
                const isCurrentValueEmpty = isNullOrUndefinedOrEmptyString(currentFieldValue);
                if (fieldValue === null){
                    updatedValue = {}
                } else if (isCurrentValueEmpty) {
                    updatedValue.isAutoCalculated = true;
                    updatedValue.stopCreatingFutureEventsAfterExitEvent = true;
                }
            } 
            onChange?.(updatedValue);
        }
    }

    const handleDisabledDates = (current) => {
        const isEarlierThanStartDate = disabledLessThanDate(current, startDate);
        return isEarlierThanStartDate;
    }

    return (
        <Wrapper>
            <TableHeaderRow onClick={(e) => { e.stopPropagation() }} isWithHoldings={holdings !== 100}>
                {tableColumns.map((columnLabel, ind) => (
                    columnLabel === 'Notes' && holdings !== 100 ? null :
                        <HeaderCell key={'hc1' + ind} isAmount={columnLabel === 'Amount'}>
                            <span>{columnLabel}</span>
                            {columnLabel === 'Expected payment' ? <Tooltip title={headerTooltip} placement="topLeft">
                                <AmountPercentageWrapper>{headerTooltip && <TooltipWrapper><TooltipIcon /></TooltipWrapper>}</AmountPercentageWrapper>
                            </Tooltip> : null}
                        </HeaderCell>
                ))}
            </TableHeaderRow>

            <RowWrapper onClick={(e) => { e.stopPropagation(); }} isWithHoldings={holdings !== 100} >

                {/* date */}
                <RowCell>
                    <DatePicker
                        picker="month"
                        allowClear={true}
                        format={'MMM YYYY'}
                        disabled={disabled}
                        style={{ borderRadius: '6px' }}
                        disabledDate={handleDisabledDates}
                        value={value?.date ? moment(value?.date) : null} onChange={(val) => { handleFieldChange('date', (val ? val.format() : val)) }} />
                </RowCell>

                {/* total */}
                <RowCell>
                    {!isNullOrUndefined(holdings) && holdings !== 100 && <AmountWrapper>
                        <NumberFormat
                            thousandSeparator={true}
                            customInput={Input}
                            prefix={rowValuePreFix}
                            placeholder={rowValuePreFix}
                            style={{ flex: 1, borderRadius: '6px 0 0 6px', borderColor: 'transparent', backgroundColor: 'transparent' }}
                            value={(isNullOrUndefinedOrEmptyString(value?.total)) ? '' : parseFloat((value?.total * (holdings / 100)).toFixed(2))}
                            autoComplete="off"
                            allowNegative={false}
                            disabled={disabled || value?.isAutoCalculated}
                            onValueChange={(values) => {
                                if ('' + value?.total !== values.value && focusedInput === 1 &&
                                    (!isNullOrUndefined(value?.total) || !isNullOrUndefined(values.floatValue))) {
                                    handleFieldChange('total', (values.floatValue || values.floatValue === 0) ? values.floatValue * (100 / holdings) : null)
                                }
                            }}
                        />
                    </AmountWrapper>}
                    <InputWithBudgeWrapper isWithHoldings={holdings !== 100}>
                        <NumberFormat
                            thousandSeparator={true}
                            customInput={Input}
                            prefix={rowValuePreFix}
                            placeholder={rowValuePreFix}
                            style={{
                                width: '100%', borderRadius: '6px',
                                ...(holdings !== 100 ? { paddingLeft: '30px', color: colors.holdingsValueColor, borderRadius: '0 6px 6px 0' } : {})
                            }}
                            value={(isNullOrUndefinedOrEmptyString(value?.total)) ? '' : parseFloat((+value?.total).toFixed(2))}
                            autoComplete="off"
                            onFocus={() => { setFocusedInput(1) }}
                            allowNegative={false}
                            disabled={disabled || value?.isAutoCalculated}
                            onValueChange={(values) => { handleFieldChange('total', values.floatValue) }}
                        />
                        {holdings !== 100 && <HoldingsBadgeWrapper><HoldingsBadgeText></HoldingsBadgeText><HoldingsBadgeTriangle /></HoldingsBadgeWrapper>}
                    </InputWithBudgeWrapper>
                </RowCell>

                {/* remarks */}
                {holdings === 100 ? <RowCell>
                    <TextArea rows={1}
                        disabled={disabled}
                        style={{ resize: 'none', borderRadius: '6px' }}
                        value={value?.remarks} onChange={(e) => { handleFieldChange('remarks', e.target.value) }} />
                </RowCell> : null}
            </RowWrapper>


            <TableBottomActions>
            <CheckboxWrapper>
                    <Checkbox type="checkbox" name={''} checked={value?.isAutoCalculated} disabled={disabled || !value?.date}
                        onChange={(e) => { handleFieldChange('isAutoCalculated', e.target.checked) }}>Calculate exit event amount automatically</Checkbox>
                        { autoCalculateTooltip && <Tooltip overlayStyle={{minWidth:'300px'}}  title={autoCalculateTooltip} placement="top" ><TooltipIcon  /></Tooltip> }
                </CheckboxWrapper>
                <CheckboxWrapper>
                    <Checkbox type="checkbox" name={''} checked={value?.stopCreatingFutureEventsAfterExitEvent} disabled={disabled || !value?.date}
                        onChange={(e) => { handleFieldChange('stopCreatingFutureEventsAfterExitEvent', e.target.checked) }}>Stop creating future event after exit event</Checkbox>
                        <Tooltip overlayStyle={{minWidth:'310px'}}  title={'Uncheck this box to allow creating expected events after the exit date.'} placement="top" ><TooltipIcon  /></Tooltip>
                </CheckboxWrapper>
            </TableBottomActions>
        </Wrapper>
    )
}

export const SingleEventSchedulerPrevComponent = ({ show }) => {
    return (
        <PrevComponentWrapper>
            <PrevComponentDescription>Do you have any additional commitment?</PrevComponentDescription>
            <PrevComponentButtons>
                <PrevComponentButton isSelected >No</PrevComponentButton>
                <PrevComponentButton onClick={show}>Yes, I expect future capital calls</PrevComponentButton>
            </PrevComponentButtons>
        </PrevComponentWrapper>
    )
}