import { makeAutoObservable } from "mobx";
import { createHoldingEntity, deleteHoldingEntity, getHoldingEntityData, updateHoldingEntity, } from "./holding-entity.service";

export class HoldingEntityStore {

  showModal = false;
  isLoaded = false;
  data = [];

  setShowModal = (show) => {
    this.showModal = show;
  }

  constructor(metadataStore, itemStore, filtersStore) {
    makeAutoObservable(this);
    this.metadataStore = metadataStore;
    this.itemStore = itemStore;
    this.filtersStore = filtersStore;
  }

  setLoading(isLoading) {
    if (isLoading) {
      this.isLoading = true;
    } else {
      this.isLoaded = true;
      this.isLoading = false;
    }
  }

  fetchData = async () => {
    this.setLoading(true);
    try {
      const data = await getHoldingEntityData();
      this.setData(data);
    }
    catch (e) { }
    finally {
      this.setLoading(false);
    }
  }

  setData(data) {
    this.data = data;
  }

  get holdingEntities() {
    return this.metadataStore.holdingEntities.slice();
  }

  async createHoldingEntity(data) {
    try {
      const result = await createHoldingEntity(data);
      this.setData([...this.data, result]);
      this.itemStore.setItemUpdated(true);
      this.filtersStore.fetchData();
      this.metadataStore.fetchData();
    }
    catch (e) { }
  }

  async updateHoldingEntity(data) {
    try {
      const result = await updateHoldingEntity(data);
      const updatedData = this.data.map(item => item.id === result.id ? result : item);
      this.setData(updatedData);
      this.itemStore.setItemUpdated(true);
      this.filtersStore.fetchData();
      this.metadataStore.fetchData();
    } catch (error) {

    }
  }

  async deleteHoldingEntity(id) {
    try {
      await deleteHoldingEntity(id);
      this.setData(this.data.filter(item => item.id !== id));
      this.itemStore.setItemUpdated(true);
      this.filtersStore.fetchData();
      this.metadataStore.fetchData();
    } catch (error) { }
  }

}