import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/react';
import { colors } from '../../styles/theme.styles';

const dotsColors = '#fff'; // #9880ff
const dotsSecondColors = '#fff';
export const dotCarousel = keyframes`
    0% {
      box-shadow: 9984px 0 0 -1px ${dotsColors}, 9999px 0 0 1px ${dotsColors}, 10014px 0 0 -1px ${dotsColors};
    }
    50% {
      box-shadow: 10014px 0 0 -1px ${dotsSecondColors}, 9984px 0 0 -1px ${dotsSecondColors}, 9999px 0 0 1px ${dotsSecondColors};
    }
    100% {
      box-shadow: 9999px 0 0 1px ${dotsColors}, 10014px 0 0 -1px ${dotsColors}, 9984px 0 0 -1px ${dotsColors};
    }
`;

const blackDotsColors = colors.darkGray1;
const blackDotsSecondColors = colors.darkGray1;
export const dotCarouselBlack = keyframes`
    0% {
      box-shadow: 9984px 0 0 -1px ${blackDotsColors}, 9999px 0 0 1px ${blackDotsColors}, 10014px 0 0 -1px ${blackDotsColors};
    }
    50% {
      box-shadow: 10014px 0 0 -1px ${blackDotsSecondColors}, 9984px 0 0 -1px ${blackDotsSecondColors}, 9999px 0 0 1px ${blackDotsSecondColors};
    }
    100% {
      box-shadow: 9999px 0 0 1px ${blackDotsColors}, 10014px 0 0 -1px ${blackDotsColors}, 9984px 0 0 -1px ${blackDotsColors};
    }
`;

export const DotsLoader = styled.div`
    position: relative;
    left: -9999px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${props=>props.black ? blackDotsColors : dotsColors};
    color: ${props=>props.black ? blackDotsColors : dotsColors};
    box-shadow: 9984px 0 0 0 ${props=>props.black ? blackDotsColors : dotsColors}, 9999px 0 0 0 ${props=>props.black ? blackDotsColors : dotsColors}, 10014px 0 0 0 ${props=>props.black ? blackDotsColors : dotsColors};
    animation: ${ props=>props.black ? dotCarouselBlack : dotCarousel} 1.5s infinite linear;
`;