import { displayMoneyValue } from "../../../../common/utils/number-display.utils";

const CactusSvg = `<svg width="93" height="62" viewBox="0 0 93 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="15.2119" y1="55.9077" x2="92.5001" y2="55.9077" stroke="#9097A3" stroke-width="0.813559"/>
<path d="M68.0933 58.7559L85.178 58.7559" stroke="#9097A3" stroke-width="0.813559"/>
<path d="M33.9325 8.02454C33.5992 6.92539 32.5552 6.28178 31.3241 6.28178C30.0508 6.28178 28.9754 6.97149 28.6579 8.12314C27.956 10.6694 27.0086 15.9364 27.0086 25.8072C27.0086 33.2773 28.8632 38.6377 30.7976 42.1996C32.9649 46.1902 37.5455 47.7733 42.2337 47.7733C42.7302 47.7733 43.1986 47.5113 43.6088 47.0221C44.0199 46.532 44.3439 45.8436 44.535 45.0864C44.7259 44.3307 44.7774 43.5339 44.668 42.8393C44.5579 42.1402 44.2928 41.5869 43.8917 41.2554C39.5287 37.6486 35.7409 32.5679 35.7409 25.8072C35.7409 15.7985 34.6909 10.526 33.9325 8.02454Z" fill="#F1F3F5" stroke="#9097A3" stroke-width="0.813559"/>
<path d="M32.8092 6.775C32.7436 6.71113 32.6554 6.6875 32.5638 6.6875C32.2215 6.6875 31.9551 7.00865 32.0036 7.34748C32.2273 8.91221 32.7035 13.6684 32.7035 25.8061C32.7035 35.6321 35.9805 39.2161 38.4752 40.5209C40.1648 41.4046 42.1573 41.6511 43.9122 42.3969C45.4324 43.043 45.5279 42.9568 44.2079 41.9637C39.5922 38.4912 35.551 33.3087 35.551 25.8061C35.551 10.3395 33.3246 7.27682 32.8092 6.775Z" fill="white"/>
<path d="M42.3525 4.76381C42.5989 2.82603 44.2588 1.39897 46.2245 1.39897C48.2714 1.39897 49.9865 2.9472 50.1954 4.98336L51.4156 16.881L52.1758 59.071C52.1977 60.288 51.0557 61.1949 49.8734 60.8993C47.4163 60.285 44.8419 60.284 42.3846 60.8984C41.1977 61.1951 40.0635 60.2979 40.0909 59.0852C40.2925 50.1681 40.9086 23.6102 41.2453 16.8769C41.476 12.2617 41.9696 7.77512 42.3525 4.76381Z" fill="white" stroke="#9097A3" stroke-width="0.813559"/>
<path d="M42.8928 4.23442C43.1007 2.82597 44.3162 1.80664 45.7399 1.80664C45.9505 1.80664 46.118 1.9832 46.1069 2.19346L45.3137 17.248L46.0912 57.6916C46.1125 58.7986 45.386 59.7812 44.3215 60.0853L42.5638 60.5875C41.5073 60.8893 40.4613 60.0921 40.4901 58.9938C40.6989 51.0491 41.4149 23.9825 41.6527 17.248C41.8352 12.082 42.4721 7.08461 42.8928 4.23442Z" fill="#F1F3F5"/>
<path d="M60.4635 9.10153C60.6011 7.95266 61.5752 7.09428 62.7364 7.09428C63.6817 7.09428 64.5136 7.66506 64.8115 8.54067C65.8187 11.5017 67.6863 17.8219 67.6863 23.9365C67.6863 28.0687 65.2516 32.8687 62.78 36.6613C60.0753 40.8118 55.2468 42.8909 50.2302 42.8909C49.8263 42.8909 49.436 42.6542 49.0905 42.1778C48.7458 41.7028 48.4844 41.0359 48.3475 40.3123C48.2109 39.5901 48.2044 38.8434 48.3411 38.2193C48.4794 37.5882 48.7495 37.1402 49.1139 36.9185C53.9156 33.9981 58.0914 29.9832 58.6804 24.6855C59.5845 16.555 60.1551 11.6766 60.4635 9.10153Z" fill="white" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="63.9679" y1="12.7926" x2="69.6628" y2="11.9791" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="58.5788" y1="12.8731" x2="63.1361" y2="16.3838" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="39.0534" y1="28.3301" x2="43.6107" y2="31.8409" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="66.6938" y1="24.1087" x2="63.4395" y2="26.3038" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="43.0399" y1="50.9901" x2="38.1585" y2="53.1852" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="52.0497" y1="6.21224" x2="48.7955" y2="8.40727" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="38.2192" y1="37.1263" x2="34.9649" y2="39.3213" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="36.2053" y1="10.4874" x2="33.9333" y2="13.6884" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="56.2737" y1="26.0787" x2="58.1715" y2="31.5094" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="43.2571" y1="13.0611" x2="45.1549" y2="18.4918" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="24.341" y1="31.3524" x2="29.4943" y2="33.9092" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="25.1554" y1="15.2723" x2="30.3087" y2="17.8291" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="33.1194" y1="25.5964" x2="37.7769" y2="22.2198" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="49.1426" y1="25.1921" x2="53.8001" y2="21.8155" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="61.2658" y1="36.008" x2="67.0091" y2="35.6785" stroke="#9097A3" stroke-width="0.813559"/>
<line x1="49.3581" y1="48.9162" x2="55.1013" y2="48.5867" stroke="#9097A3" stroke-width="0.813559"/>
</svg>`

export const customTooltip = (getTooltipItems, isIncognito, hasAnyEvents) => ({
    // Disable the on-canvas tooltip§
    enabled: false,
    // mode: 'index',
    // intersect: false,
    // filter: (toolTipItem)=> {
    //     console.log("1");
    //     return true;
    //     // return toolTipItem.datasetIndex === 0
    //     // console.log("hello", toolTipItem);
    // },
    // mode: 'nearest',
    // mode: 'index',
    // intersect: false,
    external: function (tooltipModel) {
        
        // Tooltip Element
        var tooltipEl = document.getElementById('cash-flow-line-chart-tooltip');

        // Create element on first render
        // console.log("OK1")
        if (!tooltipEl) {
            // console.log("OK2")

            tooltipEl = document.createElement('div');
            tooltipEl.id = 'cash-flow-line-chart-tooltip';
            tooltipEl.className = 'cashFlow-chart-tooltip-wrapper'
            // tooltipEl.innerHTML = '<table></table>';
            tooltipEl.innerHTML = '<div class="tooltip-content-wrapper"><div class="tooltip-top"></div><div class="tooltip-bottom"></div><div class="nwlc-arrow-div"></div></div>';
            document.body.appendChild(tooltipEl);

        }
        // console.log(tooltipModel.dataPoints);
        // const tooltipText = renderFunction();
        // let bottomItemsCount = 0;
        if (tooltipModel.tooltip.dataPoints && tooltipModel.tooltip.dataPoints.length) {
            const currentDataPoint = tooltipModel.tooltip.dataPoints[0];
            const tooltipItems = getTooltipItems(currentDataPoint.dataIndex);
            
            const topValue = '<span class="tooltip-date">' + tooltipItems.lastDayOfMonth + '</span><span class="tooltip-value">' + displayMoneyValue(currentDataPoint.raw, 'USD', isIncognito) + `</span><span class="tooltip-title">Expected cash account${currentDataPoint.datasetIndex === 0 ? 's' : ''} balance</span>`;
            let bottomValue = '';

            if (currentDataPoint.datasetIndex === 0) {
                bottomValue=`<div class="cashFlow-tooltipItemRow">
                                    <div class="item-label">Expected remaining Income</div>
                                    <div class="item-value green">${displayMoneyValue(tooltipItems.remainingCashIn, 'USD', isIncognito)}</div>
                                </div>
                            <div class="cashFlow-tooltipItemRow">
                                <div class="item-label">Expected remaining Outcome</div>
                                <div class="item-value">${displayMoneyValue(tooltipItems.remainingCashOut, 'USD', isIncognito)}</div>
                            </div>`
                
                // tooltipItems.forEach(element => {
                //     let logoValue
                //     if (element.logo.type === 'logoBase64') {
                //         logoValue = `<div class="tooltip-logo-wraper" ><img src="data:image/png;base64,` + element.logo.location + `"  class="tooltip-logo"/></div>`
                //     } else if (element.logo.type === 'logoUrl') {
                //         logoValue = '<div class="tooltip-logo-wraper"><img  src="'+ element.logo.location+ '" class="tooltip-logo" /></div>'
                //     } else if (element.logo.type === 'other') {
                //         logoValue = '<div class="tooltip-logo-wraper"><img src="'+otherLogo+'" class="tooltip-logo" /></div>'
                //     } else if (element.logo.type === 'default') {
                //         logoValue = '<div class="tooltip-logo-wraper"><img  src="'+defaultLogo+'" class="tooltip-logo" /></div>'
                //     }
                //     bottomValue += '<div class="cashFlow-tooltipItemRow">' + logoValue + '<div class="item-label">' + element.label + '</div><div class="item-value">' + displayMoneyValue(element.value, 'USD', element.isIncognito) + '</div></div>'
                // });
                // bottomItemsCount = tooltipItems.length;
            }
            var tooltipTop = tooltipEl.querySelector('.tooltip-top');
            var tooltipBottom = tooltipEl.querySelector('.tooltip-bottom');
            tooltipTop.innerHTML = !hasAnyEvents ? '' : topValue;
            tooltipBottom.innerHTML = !hasAnyEvents ? `
            <div class="cashFlow-tooltipEmptyState">
            ${CactusSvg}
            <div>You have no data yet</div>
            </div>` : bottomValue;
            if(!hasAnyEvents) {
                // var tooltipContent = tooltipEl.querySelector('.cashFlow-chart-tooltip-wrapper');
                tooltipEl.style.width = '235px';
                tooltipEl.style.height = '162px';
                tooltipEl.style.display = 'grid';
                tooltipEl.style.placeItems = 'center';

                // tooltipEl.style.backgroundColor = 'red';
            }
        }
        // Set Text
        // var innerHtml = '';
        // mmm.forEach(function(account, i) {
        //     var colors = tooltipModel.labelColors[i];
        //     var style = 'background:' + colors.backgroundColor;
        //     style += '; border-color:' + colors.borderColor;
        //     style += '; border-width: 2px';
        //     var span = '<span style="' + style + '"></span>';
        //     innerHtml += '<tr><td>' + span + body + '</td></tr>';
        // });

        // Hide if no tooltip
        // document.getElementById("cash-flow-chart")
        tooltipModel.chart.canvas.style.cursor = tooltipModel.tooltip.opacity !== 0 ? "pointer" : "default";
        if (tooltipModel.tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set caret Position
        // tooltipEl.classList.remove('above', 'below', 'no-transform');
        // if (tooltipModel.yAlign) {
        //     tooltipEl.classList.add(tooltipModel.yAlign);
        // } else {
        //     tooltipEl.classList.add('no-transform');
        // }

        // function getBody(bodyItem) {
        //     return bodyItem.lines;
        // }

        // Set Text
        // if (tooltipModel.body) {
        //     var titleLines = tooltipModel.title || [];
        //     var bodyLines = tooltipModel.body.map(getBody);

        //     var innerHtml = '<thead>';

        //     titleLines.forEach(function(title) {
        //         innerHtml += '<tr><th>' + title + '</th></tr>';
        //     });
        //     innerHtml += '</thead><tbody>';

        //     bodyLines.forEach(function(body, i) {
        //         var colors = tooltipModel.labelColors[i];
        //         var style = 'background:' + colors.backgroundColor;
        //         style += '; border-color:' + colors.borderColor;
        //         style += '; border-width: 2px';
        //         var span = '<span style="' + style + '"></span>';
        //         innerHtml += '<tr><td>' + span + body + '</td></tr>';
        //     });
        //     innerHtml += '</tbody>';

        //     var tableRoot = tooltipEl.querySelector('table');
        //     tableRoot.innerHTML = innerHtml;
        // }

        // `this` will be the overall tooltip
        const chartPosition = tooltipModel.chart.canvas.getBoundingClientRect();
        const tooltipPositions = tooltipEl.getBoundingClientRect();
        
        
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = chartPosition.left + window.pageXOffset + tooltipModel.tooltip.caretX - (tooltipPositions.width / 2) + 'px';
        tooltipEl.style.top = chartPosition.top + window.pageYOffset + tooltipModel.tooltip.caretY - tooltipPositions.height - 20 + 'px';
        // tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        // tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        // tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        // tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
    }
})


export const singleAccountCustomTooltip = (getTooltipItems, isIncognito) => ({
    // Disable the on-canvas tooltip
    enabled: false,
    // mode: 'index',
    // intersect: false,
    custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        // console.log("OK1")
        if (!tooltipEl) {
            // console.log("OK2")

            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.className = 'cashFlow-chart-tooltip-wrapper'
            // tooltipEl.innerHTML = '<table></table>';
            tooltipEl.innerHTML = '<div class="tooltip-content-wrapper"><div class="tooltip-top"></div><div class="tooltip-bottom"></div></div>';
            document.body.appendChild(tooltipEl);

        }
        // console.log(tooltipModel.dataPoints);
        // const tooltipText = renderFunction();
        // let bottomItemsCount = 0;
        if (tooltipModel.dataPoints && tooltipModel.dataPoints.length) {
            const currentDataPoint = tooltipModel.dataPoints[0];
            const topValue = '<span class="tooltip-date">' + currentDataPoint.xLabel + '</span><span class="tooltip-value">' + displayMoneyValue(currentDataPoint.yLabel, 'USD', isIncognito) + '</sapn>';
            let bottomValue = '';
            var tooltipTop = tooltipEl.querySelector('.tooltip-top');
            var tooltipBottom = tooltipEl.querySelector('.tooltip-bottom');
            tooltipTop.innerHTML = topValue;
            tooltipBottom.innerHTML = bottomValue;
        }
        // Set Text
        // var innerHtml = '';
        // mmm.forEach(function(account, i) {
        //     var colors = tooltipModel.labelColors[i];
        //     var style = 'background:' + colors.backgroundColor;
        //     style += '; border-color:' + colors.borderColor;
        //     style += '; border-width: 2px';
        //     var span = '<span style="' + style + '"></span>';
        //     innerHtml += '<tr><td>' + span + body + '</td></tr>';
        // });

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set caret Position
        // tooltipEl.classList.remove('above', 'below', 'no-transform');
        // if (tooltipModel.yAlign) {
        //     tooltipEl.classList.add(tooltipModel.yAlign);
        // } else {
        //     tooltipEl.classList.add('no-transform');
        // }

        // function getBody(bodyItem) {
        //     return bodyItem.lines;
        // }

        // Set Text
        // if (tooltipModel.body) {
        //     var titleLines = tooltipModel.title || [];
        //     var bodyLines = tooltipModel.body.map(getBody);

        //     var innerHtml = '<thead>';

        //     titleLines.forEach(function(title) {
        //         innerHtml += '<tr><th>' + title + '</th></tr>';
        //     });
        //     innerHtml += '</thead><tbody>';

        //     bodyLines.forEach(function(body, i) {
        //         var colors = tooltipModel.labelColors[i];
        //         var style = 'background:' + colors.backgroundColor;
        //         style += '; border-color:' + colors.borderColor;
        //         style += '; border-width: 2px';
        //         var span = '<span style="' + style + '"></span>';
        //         innerHtml += '<tr><td>' + span + body + '</td></tr>';
        //     });
        //     innerHtml += '</tbody>';

        //     var tableRoot = tooltipEl.querySelector('table');
        //     tableRoot.innerHTML = innerHtml;
        // }

        // `this` will be the overall tooltip
        const chartPosition = tooltipModel.chart.canvas.getBoundingClientRect();
        const tooltipPositions = tooltipEl.getBoundingClientRect();
        
        
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = chartPosition.left + window.pageXOffset + tooltipModel.tooltip.caretX - (tooltipPositions.width / 2) + 'px';
        tooltipEl.style.top = chartPosition.top + window.pageYOffset + tooltipModel.tooltip.caretY - tooltipPositions.height - 20 + 'px';
        // tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        // tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        // tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        // tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
    }
})