import { LoadingOutlined } from '@ant-design/icons'
import { useCallback, useEffect } from 'react'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { useStore } from '../../../app/data/root.store'
import { AiBetaText, DocumentDetailsWrapper, DocumentIconWrapper, DocumentRowWrapper, DocumentSize, DocumentTitle, DeepAnalysisSuccessBody, DeepAnalysisSuccessModalUpperSection, AIBetaTextWrapper } from './DeepAnalysisSuccessModal.styles'
import { ReactComponent as AiBetaIcon } from './assets/ai-beta-icon.svg'
import { docTypes } from './assets/docTypes'
import { DocumentStatusBadge, DocumentStatusIconWrapper } from '../MagicBox.styles'
import { DocumentStatusIcon } from '../MagicBoxActivationFlow'
import { LLM_FAILED_MESSAGE, LLM_IN_PROCESS_MESSAGE } from '../../data/magic-box-ai-store'
import { ReactComponent as EraseIcon } from '../DeepAnalysisReviewResultModal/assets/erase-icon.svg';
import { useTypewriter } from '../../hooks/useTypewriter'
import { useAnalytics } from '../../../app/data/AnalyticsProvider'

export const DeepAnalysisSuccess = observer(() => {
  const { magicBoxAiStore } = useStore();
  const {trackEvent} = useAnalytics();

  const llmStatus = magicBoxAiStore.file?.llmStatus;
  const status = magicBoxAiStore.file?.status;
  const aiDescription = magicBoxAiStore.file?.llmDescription;
  const fileName = magicBoxAiStore.file?.originalName;
  const fileType = fileName?.split('.').pop()?.toLowerCase();
  const fileSize = magicBoxAiStore.file?.size ? `${(magicBoxAiStore.file?.size / 1024).toFixed(0)}kb` : '';

  const displayedText = useTypewriter({
    text: (('in process' === llmStatus) ? LLM_IN_PROCESS_MESSAGE : (aiDescription ?? LLM_FAILED_MESSAGE)),
    typingEffect: ('Pending' === status),
    setIsTyping: useCallback((isTyping) => magicBoxAiStore.setIsTyping(isTyping), [magicBoxAiStore])
  });

  useEffect(() => {
    if (llmStatus !== 'in process') {
      if (aiDescription) {
        trackEvent('AI Description viewed', { description: aiDescription });
      } else {
        trackEvent('AI failed message viewed', { text: LLM_FAILED_MESSAGE });
      }
    }
  }, [aiDescription, trackEvent, llmStatus]);

  const onDelete = () => magicBoxAiStore.deleteDocument();

  return (
    <DeepAnalysisSuccessBody>
      <DeepAnalysisSuccessModalUpperSection>
        <DocumentRowWrapper>
          <DocumentIconWrapper>{docTypes[fileType]}</DocumentIconWrapper>
          <DocumentDetailsWrapper>
            <DocumentTitle>{fileName}</DocumentTitle>
            <DocumentSize>{fileSize}</DocumentSize>
          </DocumentDetailsWrapper>
          <EndIcon llmStatus={llmStatus} onDelete={onDelete} />
        </DocumentRowWrapper>
        <AIBetaTextWrapper>
          <AiBetaIcon style={{ marginBottom: '-2px' }} />
          <AiBetaText>
            {displayedText}
          </AiBetaText>
        </AIBetaTextWrapper>
      </DeepAnalysisSuccessModalUpperSection>
    </DeepAnalysisSuccessBody>
  );
});

const EndIcon = ({ llmStatus, onDelete }) => {
  switch (llmStatus) {
    case 'in process':
      return <LoadingOutlined style={{ fontSize: '24px' }} />;
    case 'rejected':
      return <RejectedBadge />;
    case 'timeout':
    case 'failed':
    case 'done':
      return <DeleteIcon onClick={onDelete} />;
    default:
      return null;
  }
}

const DeleteIcon = ({ onClick }) => {
  return (
    <Tooltip title="Delete file.">
      <div onClick={onClick} style={{ display: 'flex' }}>
        <EraseIcon />
      </div>
    </Tooltip>
  );
};

const RejectedBadge = () => {
  return (
    <Tooltip title="Magic Box AI rejected this file because it does not contain any portfolio-related data.">
      <DocumentStatusBadge status={'Rejected'} style={{ marginBottom: 0 }}>
        <DocumentStatusIconWrapper>
          <DocumentStatusIcon status={'Rejected'} />
        </DocumentStatusIconWrapper>
        {'Rejected'}
      </DocumentStatusBadge>
    </Tooltip>
  )
};

