import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../common/styles/combos.styles';
import { colors, views } from '../../../common/styles/theme.styles';

export const LogoLoaderWrapper = styled.div`
    ${flexCenter};
    background-color: #fff;
    padding:24px;
    border: 1px solid #DDE3EB;
    border-radius: 8px;
`;

export const NotificationsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 340px;
    background-color: white;
    border: 1px solid #DDE3EB;
    border-radius: 8px;
    ${views.tablet}{
        border-radius: 0;
        background-color: ${colors.darkHeroBackground};
        width:100%;
        border:0;
    }
`;

export const NotificationRowWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 12px;
    padding: 21px 10px 24px 24px;
    cursor: pointer;
    gap: 12px;
    ${views.desktop}{
        &:not(:last-child){
            border-bottom: 1px solid #DDE3EB;
        }
        :first-of-type{
            &:hover{border-radius: 8px 8px 0 0}
        }
        :last-child{
            &:hover{border-radius: 0 0 8px 8px}
        }
        
        &:hover{background-color:#F7F8F9}
    }
    
    ${views.tablet}{
        border-bottom:1px solid #3B3F46;
    }

   
`;

export const NotificationRowSection = styled.div`
    overflow: hidden;
    flex-grow: 1;
`;

export const NotificationEventStatusWrapper = styled.div`
display: inline-flex;
align-items: center;
height: 20px;
padding: 0px 10px 1px 10px;
background-color: #F1F3F5;
border-radius: 27px;
font-size: 13px;
font-weight: 400;
color: #585D66;
margin-bottom: 8px;

${views.tablet}{
    background-color: ${props => props.isEventPassedType ? '#F77' : '#82AAFA'};
    color:${colors.darkHeroBackground};
    border-radius: 8px;
    height: 24px;
    line-height: 24px;
    svg{
        path{
            ${props => props.isEventPassedType ? `
                fill:${colors.darkHeroBackground};
            ` : `
                stroke:${colors.darkHeroBackground};
            `
    }
    }
}

${NotificationRowWrapper}:hover & {
        background-color: #EBECEE;
    }
`

export const NotificationStatusIconWrapper = styled.div`
height: 11.25px;
width: 10.5px;
margin-right: ${props => props.marginRight ? props.marginRight : 8}px;
color: #585D66;
margin-bottom: 5px;
`

export const NotificationTransactionWrapper = styled.div`
margin-bottom: 4px;
gap: 2px;
display: flex;
align-items: center;
`

export const NotificationAmountWrapper = styled.span`
font-size: 20px;
line-height: 24px;
font-weight: 500;
color: #1A1B1D;
${views.tablet}{
    color: #fff;
}
`

export const NotificationTransactionIconWrapper = styled.span`
margin-left: 2px;
height: 16px;
svg {
    vertical-align: middle;
}
`

export const NotificationDateAndBankWrapper = styled.span`
font-size: 12px;
font-weight: 400;
color: #9097A3;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`

export const NotificationDateWrapper = styled.span`
font-size: 12px;
padding-left: 6px;
padding-right: 2px;
white-space:nowrap;
${views.tablet}{
    color: #9097A3;
}
`

export const NotificationAcountwrapper = styled.span`
font-size: 12px;
padding-left: 2px;
overflow:hidden;
padding-left: 2px;
text-overflow:ellipsis;
white-space:nowrap;
`

export const NotificationAssetAndEventWrapper = styled.div`
/* width: 236px; */
/* max-width: 100%; */
overflow: hidden;
white-space:nowrap;
text-overflow: ellipsis;
flex-grow: 1;
`

const ellipsis = styled.div`
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
`

export const NotificationAssetWrapper = styled(ellipsis)`
color: #1A1B1D; 
font-weight: 500;
font-size: 13px; 
line-height: 16px;
${views.tablet}{
    color: #fff;
}
`

export const NotificationEventWrapper = styled(ellipsis)`
color: #585D66; 
font-weight: 400;
font-size: 13px; 
line-height: 16px;
${views.tablet}{
    color: #fff;
}
`

export const NotificationMagicBoxAiWrapper = styled(ellipsis)`
color: #1A1B1D; 
font-weight: ${props => props.weight ? props.weight : 500};
font-size: 13px; 
line-height: 17px;
${props => props.underline ? 'text-decoration-line: underline;' : ''};
${views.tablet}{
    color: #fff;
}
`

export const CancelSection = styled.div`
    
`;

export const NotificationRowActionSection = styled(NotificationRowSection)`
    justify-content: start;
    min-width: 120px;
`;

export const ArrowRightIconWrapper = styled.div`
    display: grid;
    place-items: center;
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
    margin-right: 8px;
    svg {
        width: 14px;
        height: 14px;
        path {
            stroke-width: 1.5;
        }
    }
`;

export const NotificationRowCloseWrapper = styled.div`
visibility: hidden;
    display: grid;
    margin-left: auto;
    order: 2;
    place-items: center;
    width: 20px;
    height: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    border-radius: 35px;
    svg {
        width: 14px;
        height: 14px;
        path {
            stroke: #ACB1BB;
        }
    }

    ${NotificationRowWrapper}:hover & {
        visibility: visible;
    }

    
`;

export const ItemIconWrapper = styled.div`
    height:22px;
    width:22px;
    min-width:22px;
    border-radius: 50%;
    background-color:${props => props.backgroundColor ? props.backgroundColor : '#fff'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:4px;
    flex-shrink: 0;
    
    svg{
        width:14px;
        height:14px;
    }
    path{
        // transition:all 0.2s;
        /* stroke:#4F699D; */
        fill:#fff;
        height: 13px;
        width: 13px;
    }
`;

export const NotificationsEmptyStateWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    padding: 32px;
    /* background: #F7F8F9; */
    /* margin-bottom: 16px; */
    border-radius: 8px 0px 8px 8px;
    height: 158px;
    width: 330px;
    ${views.tablet}{
        width:100% ;
        margin-top: 48px;
        background-color: ${colors.darkHeroBackground};
        color: #fff;
    }
`;

export const NotificationsEmptyStateIconWrapper = styled.div``;

export const NotificationsEmptyStateTitle = styled.div`
    color: #1A1B1D;
    font-size: 12px;
    line-height: 140.84%;
    white-space: pre-line;
    text-align: center;
    ${views.tablet}{
        color: #fff;
    }
`;

export const NotificationsEmptyStateLearnMoreWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding-top: 24px;
    cursor: pointer;
`;

export const NotificationsCashRelatedEmptyStateWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    padding: 32px;
    border-radius: 4px;
    width: ${props => props.fullWidth ? '100%' : '330px'};
`;

export const NotificationsCashRelatedEmptyStateHeader = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 120.5%;
    color: ${colors.darkGray1};
    padding-bottom: 6px;
`;

export const NotificationsCashRelatedEmptyStateTitle = styled.div`
    font-weight: 450;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: ${colors.darkGray1};
    padding-bottom: 32px;
`;

export const ActionButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ActionButton = styled.div`
    background-color: ${props => props.black ? colors.darkGray1 : colors.lightGray1} ;
    color: ${props => props.black ? '#fff' : '#000'};
    ${props => props.black ? `` : `border: 1px solid rgba(221, 227, 235, 0.7);`};
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    min-width: 200px;
    padding: ${props => props.isBack ? '10px 20px' : '8px 16px'};
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 1s;
    ${props => props.withIcon ? `gap: 6px` : ``};
    ${props => props.isBack ? `margin-right: auto` : ``};
    ${props => props.disabled ? `opacity: 0.2; cursor: default;` : ``}
`;

export const InvitationAcceptedRoleRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
`;

export const RolesNameIconWrapper = styled.div`
    ${flexCenter}
    width: 30px;
    min-width: 30px;
    height:30px;
    border-radius: 10px;
    color:${colors.darkGray1};
    background-color:${props => props.color || colors.lightGray1}; };
`;

export const InvitationAcceptedRowNameAndMail = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
`;

export const InvitationAcceptedRowNameText = styled.div`
    color: ${colors.darkGray1};
    font-family: Circular;
    font-style: normal;
    line-height: normal;
    font-size: 13px;
    font-weight: 500;
    ${views.tablet}{
        color: #fff;
    }
`;

export const InvitationAcceptedRowEmail = styled.div`
    color: ${colors.darkGray2};
    font-family: Circular;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    ${views.tablet}{
        color: #fff;
    }
`;