import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../../../common/styles/theme.styles';
import { Link } from "react-router-dom";
import { keyframes } from '@emotion/react';
import { InstitutionsLoadMoreSkeleton } from '../../../../community/icons/community-page-icons';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const skeletonLoading = keyframes`
    0% {
        opacity: 0.2;
        /* background-color: hsl(200, 20%, 70%); */
    }
    
    100% {
        opacity: 1;
        /* background-color: hsl(266, 26%, 95%); */
    }
`

export const PageWrapper = styled.div`
    width:100%;
    padding-bottom:16px;
    // color:#2A2D33;
    /* text-align:left;
    display:flex;
    flex-direction:row; */
    /* height: 100%; */
    /* padding-top:16px; */
` 

export const ItemsWrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    ${PageWrapper}.itemView &{
        padding:0 8px;
    }
`;


export const InstitutionBreakdownHoverEffectWrapper = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    position: absolute;
    left: -14px;
    top: -9px;
    // transform: translateX(-50%) translateY(-100%);
    transform: translateY(-100%);
    z-index: 3;
    width: 340px;
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid ${colors.gray3};
    box-shadow: 0px 1.53461px 6.13845px rgba(0, 0, 0, 0.05), 0px 0px 3.06922px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    backdrop-filter: blur(5px);
    padding: 8px 14px 4px;

    :after {
        content: '';
        position: absolute;
        left: 18px;
        transform:  translateY(50%);
        bottom: -4px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #fff;
        z-index: -1;
    }
    :before{
        content: '';
        position: absolute;
        left: 16px;
        transform:  translateY(50%);
        bottom: -4px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 8px solid #d9d9d9;
        z-index: -1;
    }
`;

export const SelectedInstitutionRowWrapper = styled.div`
    ${flexCenter};
    width: 100%;
    padding: 8px 0;
    :first-of-type {
        border-bottom: 1px solid rgba(47, 69, 85, 0.15);
    }
`;
export const ItemSignWrapper = styled.div`
    position: relative;
`;

export const ItemLogoWrapper = styled.div`
    height:26px;
    width:26px;
    border-radius: 50%;
    margin-right:12px;
    /* margin-left:24px; */
    img{
        border-radius: 50%;
        height:26px;
        width:26px;
        object-fit: contain;
        background-color: #fff;
    }
`;

export const ItemIconWrapper = styled.div`
    /* margin-left:24px; */
    height:26px;
    width:26px;
    border-radius: 23px;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    svg{
        width:16px;
        height:16px;
    }
    path{
        // transition:all 0.2s;
        /* stroke:#4F699D; */
        fill:#fff;
    }
`;

export const ContainerSvgWrapper = styled.div`
    ${flexCenter};
    border-radius: 23px;
    // background-color: #EDEADE;
    margin-right: 12px;
    svg {
        width: 26px;
        height: 26px;
    }
`;

export const LogoPlaceHolder = styled.div`
    ${flexCenter}
    height:26px;
    width:26px;
    background-color: #B3E8CF;
    font-weight: 500;
    color: ${colors.darkGray1};
    border-radius:50%;
    margin-right: 12px;
    svg{
        height:26px;
        width:26px;
    }
`;

export const ItemContent = styled.div`
    flex:1;
    max-width: calc(100% - 38px);
    overflow: hidden;
`;

export const AssetClassIconWrapper = styled.div`
    width: 40px;
    height: 40px;   
    border-radius: 4px;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width:20px;
        height:20px;
    }
    path{
        /* stroke:#4F699D; */
        fill:#623e2e;
    }
`

export const ItemDescription = styled.div`
    flex:1;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    padding:0 16px 0 0;
`

export const ItemText = styled.div`
    display:inline-flex;
    flex-direction:column;
    ${views.tablet}{
        align-items: flex-start;
    }
`;

export const ItemTitle = styled.div`
  font-size:14px;
  font-weight:500;
  display: flex;
  align-items: center;
`;
export const ItemMoreInfos = styled.div`
    font-size:12px;
    color: #9097A3;
    display:flex;
    flex-direction:row;
    align-items:center;
    ${views.tablet}{
        ${props=>props.keepRowForMobile ? `` : `
            flex-direction:column;;
            align-items:flex-start;
        `}
    }
`;

export const MoreInfosItem = styled.div`
    font-size:12px;
    color: #9097A3;
    &:not(:last-child){padding-right:8px;}
    display: flex;
    align-items: center;
    ${props=>props.leftMargin ? `margin-left:${props.leftMargin}px` : ``}
`;

export const TotalInvestmentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const TotalInvestmentTitle = styled.div`
    color: ${colors.darkGray2};
    font-size: 12px;
    line-height: 15px;
`;


export const ItemViewItemValue = styled.div`
    font-size:14px;
    color:#9D9D9D;
`;

export const ItemValue = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    line-height:19px;
    /* padding-right:32px; */
    display:flex;
    flex-direction:column;
    align-items:flex-end;
    text-align: right;
    // flex: 1;
`;

export const HoldingValue = styled.div`
    font-size:14px;
    // padding-right:8px;
    // font-weight: 300;
`;

export const ValueRemarks = styled.div`
    font-size: 12px;
    color:${colors.darkGray2};
`;

export const AssetTotalValue = styled.div`
    display: flex;
    align-items: center;
    font-size:14px;
    font-weight: 500;
    // padding-right:8px;
    // font-weight: 300;
    color:${colors.holdingsBadgeColor};
    /* color:#9097A3; */
`;

export const HoldingsBadgeSquare = styled.div`
    margin-left: 4px;
    width: 8px;
    height: 10px;
    background-color: ${colors.holdingsBadgeColor};
    border-radius: 0px 0px 0px 0px;
`;

export const HoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid ${colors.holdingsBadgeColor};
`;

export const ItemTitleHoldingsBadgeWrapper = styled.div`
    display:  inline-flex;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    margin-left:4px;
    // transform:translateY(-1px);
`;


export const ItemTitleHoldingsBadgeText = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.assetHoldingsBadgeBgColor};
    color:${colors.assetHoldingsBadgeTextColor};
    padding: 1px 0px 1px 4px;
    border-radius: 2px 0px 0px 2px;
`;

export const ItemTitleHoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid ${colors.assetHoldingsBadgeBgColor};
`;


export const ClassPageEmptyStateWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    /* padding: 75px 0px; */
    ${views.tablet}{
        padding:24px 0;
    }
`;

export const ClassPageEmptyStateTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
`;

export const ClassPageEmptyStateAddItemText = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: ${colors.gray2};
    white-space: pre-line;
    text-align: center;
    ${views.tablet}{
        padding:0 32px;
    }
`;

export const ManuallyAddClassButton = styled.div`
    ${flexCenter};
    gap: 10px;
    cursor: pointer;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 20px;
    border: 1px solid #FFFFFF;
    border-radius: 6px;
    > svg {
        path {
            stroke: #fff;
        }
        width: 16px;
        height: 16px;
    }
`;

export const UploadMagicBoxButton = styled.div`
    ${flexCenter};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color:#fff;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 6px;
    background-color: ${props=>props.classColor};
`;

export const SyncPopupButton = styled(UploadMagicBoxButton)`
    
`;

export const ActionsWrapper = styled.div`
    ${flexCenter};
    padding-top: 32px;
    gap: 16px;
`;

export const FilterWrapper = styled.div`
    ${flexCenter}
    .filter-label{
        margin-right:8px;
    }
`;

export const HeaderTitle = styled.div`
    flex:1;
    padding:8px;
    font-size:14px;
    color:${colors.darkGray1};
    font-weight:500;
`;

export const CashFlowLinkWrapper = styled(Link)`
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
    height:70px;
    ${flexCenter};
`;

export const CashFlowLinkText = styled.div`
    color:#fff;
    
    font-size:14px;
    font-weight:500;
    margin-right:8px;
    position:relative;
    ${flexCenter};
    ::after{
        content:'';
        position:absolute;
        width:0%;
        height:1px;
        left:50%;
        bottom:0;
        background-color:#fff;
        transition:all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
    }
    ${CashFlowLinkWrapper}:hover &{
        ::after{
            width:100%;
            left:0%;
        }
        // color:#2299ff;
    }
`;

export const TableRowWrapper = styled.div`
        display:grid;
        grid-template-columns: ${props=> props.isCashFlowDataView ? 
        `auto repeat(${props.matricesColumns}, 96px)` : 
        `auto ${props.isWithDataLayersButton ? '106px' : ''} ${props.matricesColumns ? `repeat(${props.matricesColumns}, 104px)` : ``} 120px;`} 
        
`;
export const TableHeaderWrapper = styled(TableRowWrapper)`
    background-color:#fff;
    position:sticky;
    top:${props => props.withStickyBreadcrumbs ? '53' : '0'}px;
    min-height:48px;
    padding:8px 0;
    border-bottom:1px solid ${colors.gray3};
    z-index:2;
    // ::after{
    //     content:'';
    //     position:absolute;;
    //     top:0;
    //     left: 0;
    //     right: 0;
    //     bottom:0;
    //     background-color: red;
    // }
    
    ${props=>props.isFixed ? `
        position: fixed;
        top:64px;
        width:100%;
    ` : `
    `};

    ${views.tablet}{
        ${props=>props.keepDesktopView ? `
            z-index: 1;
        ` : `
            grid-template-columns: auto minmax(40%, max-content);
        `}
    }
`;

export const TableHeaderCell = styled.div`
    font-size:12px;
    line-height:14px;
    color:${colors.gray1};
    ${flexCenter};
    /* justify-content:${props=>props.isTitle ? 'flex-start' : 'flex-end'}; */
    justify-content:${props=>props.isValue ? 'flex-end' : 'flex-start'};
    // text-align:${props=>props.isTitle ? 'left' : 'right'};
    padding:0px 8px;
    // border-right:1px solid ${colors.gray3};
    position: relative;
    ${props=>props.clickable ? 'cursor:pointer;' : ''}
    ::after{
        content:' ';
        position:absolute;
        right:0;
        width:1px;
        top:calc(50% - 8px);
        height:16px;
        // background-color:${colors.gray3};
    }
    :first-of-type{padding-left:24px;}
    :last-of-type{padding-right:24px;
        border-right:none;
        ::after{
            content:none;
        }
    }
`;

export const TableHeaderSortIconWrapper = styled.div`
    margin-right:8px;
    ${props=>props.isAsc ? 'transform: rotate(180deg)' : ''} 
`;

export const TableCell = styled.div`
    ${flexCenter};
    // justify-content:${props=>props.isTitle ? 'flex-start' : 'flex-end'};
    justify-content:${props=>props.isValue ? 'flex-end' : 'flex-start'};

    ${props=>props.isCashFlow ? `
        flex-direction: column;
        align-items: flex-end;
    ` : ``}
    font-weight: ${props=>props.bold ? 500 : 400};
    font-size:14px;
    line-height:19px;
    padding:0px 8px;
    
    :last-of-type{padding-right:24px;}
    position:relative;
    ${views.tablet}{
        :first-of-type{padding-left:20px;}
    }
    ${props=>props.isBulkEditMode ? 'padding-left:12px' : `
        padding-left:10px;
        // :first-of-type{padding-left:24px;}
    `}
    ${props=>props.withColors ? props.isPositive ? `color: ${colors.inflow2}` : `color: ${colors.outflow}` : ``};   
`;

export const CashFlowValue = styled.div`
    color: ${props => props.isInflow ? colors.inflowText : colors.outflow};
`;

export const CashFlowConnectedItemToolTipWrapper = styled.div`
    width:100%;
    > span {
        text-align: right;
        > div {
            padding: 0 4px;
        }
    }
`;

export const ItemSelectedCheckboxWrapper = styled.div`
    cursor: pointer;
    margin-left: -8px;
    ${props=>props.isBulkEditMode || props.isHeaderItem ? `
        padding: 12px 8px;
        ` : `
        padding: 12px 5px;
        `}
    
    opacity: 0;
    ${props=>props.isSelected || props.isHeaderItem || props.isBulkEditMode ? `
        opacity: 1;
    ` : ``}
    ${props=>props.disabled ? `visibility: hidden`:``}
`;

export const ItemSelectedCheckbox = styled.div`
    border-radius: 3px;
    width: 12px;
    height: 12px;
    ${flexCenter};
    ${props=>props.isSelected ? `
        background-color: ${colors.darkGray1};
    ` : props.isPartialSelected ? `
        border: 1px solid ${colors.darkGray1};
    ` : `
    border: 1px solid ${colors.gray3};
    box-shadow: ${shadows.toggleShadow} ;
    `}
`;

export const ListItemWrapper = styled(TableRowWrapper)`
    ${ props=> props.noBorder ? `` : `
        :not(:last-of-type){
            border-bottom:1px solid ${props.borderBottomColor ? props.borderBottomColor : colors.lightGray2};
        }
    `}
    
    padding:${props => props.isCashFlowDataView && props.isClassItem ? '9' : '12'}px 0;
    /* :not(:last-child){
        margin-bottom: 8px;
    }  */
    // cursor: pointer;
    cursor: ${props=> props.notClickable ? 'default' : 'pointer'};
    transition:background-color 0.2s;
    :hover{
        background-color:${colors.lightGray1};
        ${ItemSelectedCheckboxWrapper} {
            ${views.desktop} {
                opacity: 1;
            }
        }
        background: linear-gradient(90deg, #000 2px, #F7F8F9 2px);
    }
    ${props=>props.isTotal ? `background-color:${colors.lightGray1};cursor:default;border-top: 1px solid #e4e4e4;border-radius:0 0 8px 8px;` : ``}
    ${props=>props.lastRounded ? `:last-of-type{border-radius:0 0 8px 8px}` : ``}
    .ant-checkbox  .ant-checkbox-inner{
        width: 12px;
        height: 12px;
        border-radius: 3px; 
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
        border-color: ${colors.gray3};
        
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #000;
        border-color: #000;
        ::after {
            transform: rotate(45deg) scale(0.7) translate(-90%, -67%)
        }
        
    }
    ${props=>props.isSelected ? `
        background: ${colors.lightGray1};
        ` : ``}
`;

export const ClassPageItemsListViewWrapper = styled.div`position:relative`;
export const ClassPageTickersListViewWrapper = styled.div``;

export const LogoLoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 200px;
`;

export const ClassPageTickersListViewEmptyStateWrapper = styled.div`
    ${flexCenter};
    flex-direction: column;
    gap: 24px;
    min-height: 300px;
`;

export const ClassPageTickersListViewEmptyStateText = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
`;

export const InstitutionsLoadMoreSkeletonSvg = styled(InstitutionsLoadMoreSkeleton)`
    animation: ${skeletonLoading} 1s linear infinite alternate;
`;

export const ShowClosedItemsWrapper = styled.div`
    display: flex;
    height: 36px;
    padding: 6px 0;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    ${views.desktop} {
        :hover {
            background: ${colors.lightGray1};
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
    cursor: pointer;

    ${views.tablet}{
        height: 64px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        align-self: stretch;
`;

export const ShowClosedItemsTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const BulkEditHeaderWrapper = styled.div`
    background-color:#fff;
    position:sticky;
    top:${props => props.withStickyBreadcrumbs ? '104' : '51'}px;
    min-height:60px;
    height:60px;
    padding:8px 12px;
    border-bottom:1px solid ${colors.gray3};
    z-index:2;
    display: flex;
    align-items: center;
    animation: ${fadeIn} 0.3s ease-out forwards;

    // ${props=>props.isFixed ? `
    //     position: fixed;
    //     top:64px;
    //     width:100%;
    // ` : `
    // `};

    // ${views.tablet}{
    //     ${props=>props.keepDesktopView ? `
    //         z-index: 1;
    //     ` : `
    //         grid-template-columns: auto minmax(40%, max-content);
    //     `}
    // }
`;

export const BulkEditHeaderText = styled.div`
    color:${colors.darkGray1};
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px;
`;

export const BulkEditHeaderEditButton = styled.div`
        cursor: pointer;
        border-radius:20px;
        padding:0px 16px;
        ${flexCenter};
        background-color: ${colors.darkGray1};
        gap:8px;
        height: 36px;
        font-size: 16px;
        color: #fff;
        font-weight: 450;
        ${props=>props.disabled ? `opacity:0.6;cursor: default;` : ``}
`;

export const BulkEditHeaderCancelEditButton = styled.div`
        cursor: pointer;
        border-radius:20px;
        padding:0px 16px 0 12px;
        ${flexCenter};
        background-color: ${colors.darkGray1};
        gap:6px;
        height: 36px;
        font-size: 16px;
        color: #fff;
        font-weight: 450;
        box-shadow: ${shadows.toggleShadow};
        ${props=>props.disabled ? `opacity:0.6;cursor: default;` : ``}
`;

export const CancelButtonIconWrapper = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #E8E9ED;
    ${flexCenter}
`;
