import { Observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import {
  // ModalCloseIcon,
  ArrowRightIcon
} from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { useStore } from '../../../app/data/root.store';
import { ReactComponent as TitleLIlu } from './assets/title-l.svg';
import { ReactComponent as TitleRIlu } from './assets/title-r.svg';
import { ReactComponent as VyzerLogo } from './assets/vyzer-logo-with-glow.svg';
import {
  BottomMessage, MiddleMessage, ModalBgWrapper,
  // ModalCloseButtonWrapper,
  ModalContent, ModalHeader, ModalLogoWrapper, StartButton, TitleText, TitleWrapper
} from './WelcomeModal.styles';
import { LogoLoader } from '../../../../common/components/LogoLoader/LogoLoader';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { ROUTES } from '../../../../common/constants/routes.const';

export const WelcomeModal = (props) => {
  const { onBackDropClick , noSidebarMargin = false } = props;
  const { userStore, wealthStore, filtersStore , uiStore } = useStore();
  const { mixpanelTrack } = useAnalytics()
  const pageLocation = wealthStore.isMainResults ? 'My Portfolio main page' : 'My Portfolio filters' + (filtersStore.singleClassFilter ? ' - single class '  + filtersStore.singleClassFilter.title: '')

  const postAffiliateCb = useCallback(() => {
      if (window.PostAffTracker){
        var sale = window.PostAffTracker.createSale();
        sale.setOrderID(`${userStore.data.id}_subscribed`);
        sale.setProductID('vyzer subscription');
        sale.setData1(userStore.data.id);
        sale.setData2(userStore.fullName);
        window.PostAffTracker.track();
       }
  },[userStore])

  useEffect(() => {
    mixpanelTrack('Popup Viewed', {
      destination : ROUTES.wealthOverview.url,
      location: pageLocation,
      title: `Welcome to your Wealth Management Hub`,
      cta_text: 'Start',
      type: 'Welcome Modal',
      is_demo: userStore.isDemoMode
    })
  
    // return () => {}
  }, [mixpanelTrack, pageLocation, userStore.isDemoMode])
  

  const handleStartClick = () => {
    mixpanelTrack('Popup Clicked', {
      click_type: 'CTA click',
      destination : ROUTES.wealthOverview.url,
      location: pageLocation,
      title: `Welcome to your Wealth Management Hub`,
      cta_text: 'Start',
      type: 'Welcome Modal',
      is_demo: userStore.isDemoMode
    })
    onBackDropClick && onBackDropClick();
  }

  useEffect(() => {

    if (userStore.isDemoTier) {
      // handleRefreshUserData();
      setTimeout(async () => {
        const userData = await userStore.getData();
        if (['DEMO'].includes(userData.profile.tier)) {
          onBackDropClick?.();
        } else {
          userStore.setUserTierAndPaymentStatus(userData.profile.tier, userData.profile.paymentStatus, userData.tierLimitations);
          uiStore.setIsRightMenuOpen(false);
          if (!userStore.isFreeTier){
            postAffiliateCb();
          }
        }
      }, 3000)
    } else {
      if (!userStore.isFreeTier){
        postAffiliateCb();
      }
    }
    return () => { }
  }, [userStore, onBackDropClick,uiStore,postAffiliateCb])



  return (<Observer>{() => (
    <BaseModal width={'880px'} height={'auto'} borderRadius={8} onBackDropClick={onBackDropClick} noSidebarMargin={noSidebarMargin}>
      {userStore.isDemoMode ?
        <div style={{ display: 'grid', placeItems: 'center', flex: 1, minHeight: '560px' }}>
          <LogoLoader />
        </div>
        : <>
        <ModalBgWrapper>
            {/* <ModalCloseButtonWrapper onClick={onBackDropClick}>
                <ModalCloseIcon />
            </ModalCloseButtonWrapper> */}
            <ModalHeader>
                <ModalLogoWrapper>
                  <VyzerLogo />
                </ModalLogoWrapper>
                <TitleWrapper>
                  <TitleLIlu />
                  <TitleText >
                    Welcome to your private<br/>investment concierge
                  </TitleText>
                  <TitleRIlu />
                </TitleWrapper>
            </ModalHeader>
            <ModalContent>
              <MiddleMessage>
                {userStore.firstNameCapitalize}, you're just a few steps away from bringing<br/>together your entire portfolio.  
              </MiddleMessage>
              {userStore.isFreeTier && <BottomMessage>
                  Enjoy your exploration.
              </BottomMessage>}
              <StartButton onClick={handleStartClick}>Start <ArrowRightIcon /></StartButton>
            </ModalContent>
            
           
            
        </ModalBgWrapper>
        </>}
    </BaseModal>
  )}</Observer>)
}


