import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors } from "../../../../common/styles/theme.styles";

export const SessionExpirationModalWrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  gap: 40px;
  padding: 25px 60px;
`;

export const SessionExpirationModalTitleWrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
`;

export const SessionExpirationModalTitleMain = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
`;

export const SessionExpirationModalTitleAlt = styled(SessionExpirationModalTitleMain)`
  font-weight: 400;
  font-size: 14px;
`;

export const SessionExpirationIconWrapper = styled.div`
  ${flexCenter};
`;

export const LoginButtonWrapper = styled.div`
  background-color: ${colors.darkGray1};
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  width: 80%;
  margin-bottom: 15px;
  cursor: pointer;
`;

export const LoginButtonText = styled.div`
  ${flexCenter};
  padding: 10px;
`;