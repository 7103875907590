import { Tooltip } from 'antd';
import React from 'react'
import { ActionLockedIconWithTooltipWrapper, LockIconWrapper, TooltipCompWrapper } from './ActionLocked.styles';
import { ReactComponent as LockIcon } from './icons/LockIcon.svg';
import { ReactComponent as UnlockIconTooltip } from './icons/unlock-icon.svg';
import { observer } from 'mobx-react';
import { useStore } from '../../../modules/app/data/root.store';

export const ActionLockedIconWithTooltip = observer(({ iconColor, marginTop, marginLeft, isRoleBlocked, placement }) => {
  const { userStore } = useStore();

  return (
    <ActionLockedIconWithTooltipWrapper marginLeft={marginLeft}>
      <Tooltip placement={placement || 'top'} overlayInnerStyle={{borderRadius:'6px', background: 'rgba(0, 0, 0, 0.9)'}} title={ <TooltipComp isRoleBlocked={isRoleBlocked} actionBlockedByRolePrompt={userStore.actionBlockedByRolePrompt} />}>
        <LockIconWrapper marginTop={marginTop} iconColor={iconColor}>
          <LockIcon />
        </LockIconWrapper>
      </Tooltip>
    </ActionLockedIconWithTooltipWrapper>
  )
})

const TooltipComp = ({ isRoleBlocked, actionBlockedByRolePrompt }) => {
  return (
    <TooltipCompWrapper onClick={(e)=>{e.stopPropagation()}} withLineBreak={isRoleBlocked}>
      {isRoleBlocked ? null : <LockIconWrapper iconColor={'white'}>
        <UnlockIconTooltip />
      </LockIconWrapper>}
      {isRoleBlocked ? actionBlockedByRolePrompt : 'Unlock with Vyzer Membership'}
    </TooltipCompWrapper>
  )
}