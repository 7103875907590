import React from 'react';
import { CommonPopoverHoverEffectContent, CommonPopoverHoverEffectWrapper, CommonPopoverWrapper } from './CommonPopover.styles';

const placements = {
  'bottomRight': { right: 0, top: '100%' },
  'bottomLeft': { left: 0, top: '100%' },
  'bottomCenter': { left: '50%', transform: `translateX(-50%)`, top: '100%' },
  'topRight': { right: 0, bottom: '100%' },
  'topLeft': { left: 0, bottom: '100%' },
  'topCenter': { left: '50%', bottom: '100%', transform: `translateX(-50%)` },
  'rightTop': { top: 0, left: '100%' },
  'rightCenter': { top: '50%', left: '100%', transform: `translateY(-50%)` },
  'rightBottom': { bottom: 0, left: '100%' },
  'leftTop': { top: 0, right: '100%', },
  'leftCenter': { top: '50%', right: '100%', transform: `translateY(-50%)` },
  'leftBottom': { bottom: 0, right: '100%', },
};

const defaultOffsetValue = '8px';

const offsets = {
  'bottomRight': { marginTop: defaultOffsetValue },
  'bottomLeft': { marginTop: defaultOffsetValue },
  'bottomCenter': { marginTop: defaultOffsetValue },
  'topRight': { marginBottom: defaultOffsetValue },
  'topLeft': { marginBottom: defaultOffsetValue },
  'topCenter': { marginBottom: defaultOffsetValue },
  'rightTop': { marginLeft: defaultOffsetValue },
  'rightCenter': { marginLeft: defaultOffsetValue },
  'rightBottom': { marginLeft: defaultOffsetValue },
  'leftTop': { marginRight: defaultOffsetValue },
  'leftCenter': { marginRight: defaultOffsetValue },
  'leftBottom': { marginRight: defaultOffsetValue },
}

export const CommonPopover = ({ popoverTrigger, popoverHover, popoverTriggerStyle, popoverHoverStyle, placement = 'bottomRight' }) => {
  return (
    <CommonPopoverWrapper style={{ ...popoverTriggerStyle }}>
      {popoverTrigger}
      <CommonPopoverHoverEffectWrapper style={{ ...placements[placement] }}>
        <CommonPopoverHoverEffectContent style={{ ...popoverHoverStyle, ...offsets[placement] }}>{popoverHover}</CommonPopoverHoverEffectContent>
      </CommonPopoverHoverEffectWrapper>
    </CommonPopoverWrapper>
  )
}