import React from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import { IconTextWrapper, IconWrapper, TextWrapper, ActionButtonWrapper, ActionButtonText, ActionButtonIcon } from './Banner.styles'
import { ArrowIcon, TagErrorIcon } from './icons/banner-icons'
import { Banner } from './Banner';
import { PaymentRejectedModal } from '../../../user/components/PaymentRejectedModal/PaymentRejectedModal';
import { LoadingOutlined } from '@ant-design/icons';

export function PaymentErrorBanner() {
    const { billingStore, userStore } = useStore();

    const handleBannerClick = async () => {
        const paymentLink = await billingStore.fetchBillingUrl();
        window.open(paymentLink.url,"_self");
    }

    return <Observer>{() => (
        <>
            <Banner bannerBgc={'#9D1A59'}
                titleComponent={
                    <>
                        <IconTextWrapper>
                            <IconWrapper><TagErrorIcon /></IconWrapper>
                            <TextWrapper>{userStore.data.profile?.firstName}, your membership payment was unsuccessful</TextWrapper>
                        </IconTextWrapper>
                    </>}
                buttonComponent={<>
                    <ActionButtonWrapper onClick={() => handleBannerClick()}>
                        <ActionButtonText>Update payment information</ActionButtonText>
                        <ActionButtonIcon>
                            {billingStore.isLoading ? <LoadingOutlined /> :<ArrowIcon />} </ActionButtonIcon>
                    </ActionButtonWrapper>
                </>}
            />
            { (userStore.data?.profile?.showPaymentRejectedPopup?.show || billingStore.isShowPricingTablePopup) && <PaymentRejectedModal onBackDropClick={()=>{userStore.closePaymentRejectedModal();billingStore.setShowPricingTableModal(false)}} />}
        </>
    )}
    </Observer>
}


