import { useEffect } from 'react';
import { VipSyncEmailSentLookForwardText, VipSyncEmailSentTitle, VipSyncEmailSentToText, VipSyncEmailSentWrapper } from '../../../AddItem.styles';
import { useAnalytics } from '../../../../../app/data/AnalyticsProvider';

export const VipSyncEmailSent = ({ userStore, isActivationFlow, item }) => {
  const { mixpanelTrack } = useAnalytics();

  useEffect(() => {
    mixpanelTrack('VIP Sync Service Completed', {
      step_name: 'Request successfully submitted',
      step_number: 3,
      institution: item.name,
    });

    // return () => {}
  }, [mixpanelTrack, item.name])

  return (
    <VipSyncEmailSentWrapper>
      <EmailSvg />
      <VipSyncEmailSentTitle>We'll email an agreement<br /> for your signature shortly</VipSyncEmailSentTitle>
      <VipSyncEmailSentToText>To: {userStore.granteeEmail}</VipSyncEmailSentToText>
      {!isActivationFlow ? <VipSyncEmailSentLookForwardText>We look forward to providing you<br />with our VIP Syncing Service.</VipSyncEmailSentLookForwardText> : null}
    </VipSyncEmailSentWrapper>
  )
}

const EmailSvg = () => {
  return (
    <svg width="56" height="47" viewBox="0 0 56 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 21L27.2468 1.57936C27.6909 1.2378 28.3091 1.2378 28.7532 1.57936L54 21" stroke="#1A1B1D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="9" y="6" width="38" height="36" rx="1.23529" fill="#F7F8F9" stroke="#1A1B1D" strokeWidth="1.25" strokeLinejoin="round" />
      <path d="M1 21.9549C1 21.0622 1.91794 20.4642 2.73456 20.825L27.0707 31.5782C27.3822 31.7158 27.7367 31.7188 28.0505 31.5863L53.6372 20.783C54.4515 20.4392 55.3529 21.0371 55.3529 21.9211V44.7645C55.3529 45.4467 54.7999 45.9998 54.1176 45.9998H2.2353C1.55306 45.9998 1 45.4467 1 44.7645V21.9549Z" fill="#F7F8F9" stroke="#1A1B1D" strokeWidth="1.25" strokeLinejoin="round" />
      <path d="M21.9998 29.3222L11.8086 37.3516" stroke="#1A1B1D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34.3528 29.3222L44.5439 37.3516" stroke="#1A1B1D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.818 23.534C32.69 23.534 34.238 21.536 34.238 18.854C34.238 15.488 31.448 12.968 27.794 12.968C23.996 12.968 20.81 15.83 20.81 20.006C20.81 24.092 23.798 26.846 28.01 26.846C29.558 26.846 31.394 26.234 32.438 25.244L31.7 24.272C30.62 25.136 29.324 25.532 28.01 25.532C24.41 25.532 22.286 23.174 22.286 20.024C22.286 16.586 24.644 14.282 27.812 14.282C30.854 14.282 32.852 16.244 32.852 18.872C32.852 20.672 31.916 22.292 30.908 22.292C30.404 22.292 30.224 21.968 30.224 21.554C30.224 21.392 30.242 21.23 30.278 21.05L31.25 16.532H29.828L29.666 17.486C29.396 16.676 28.496 16.298 27.74 16.298C25.796 16.298 24.14 18.08 24.14 20.492C24.14 22.346 25.274 23.57 26.894 23.57C27.668 23.57 28.496 23.156 29.036 22.382C29.234 23.174 29.972 23.534 30.818 23.534ZM29.27 19.34C29.216 21.032 28.37 22.256 27.146 22.256C25.976 22.256 25.616 21.392 25.616 20.294C25.616 18.962 26.462 17.648 27.758 17.648C28.73 17.648 29.27 18.278 29.27 19.34Z" fill="#DEB482" />
    </svg>
  )
}