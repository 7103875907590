import React  from 'react';
import { Observer} from 'mobx-react';
import { useStore } from '../../../app/data/root.store';

import { CategoryPanel, ClassCategoryStepWrapper, ClassButton, ClassesPanel, ClassesSeparator, ClassIconWrapper, ClassTitle, HoverClassMessage, SelectClassMessage, SelectClassMessageWrapper, SelectedClassDescription, SelectedClassTitle, ClassCategoryGroupTitle } from '../CreateItemModal.styles';
import { AssetClassIcon } from '../../../assets/components/AssetClassIcon';


import { AddItemCategoryStep } from './AddItemCategoryStep';
import {ReactComponent as SelectClassImage} from '../../assets/select-class-message-image.svg';
import { UpgradePortfolioButton } from '../../../../common/components/buttons/NewButtons.styles';
import { UpgradeCrownIcon } from '../../../../assets/icons/common/common-icons';

const classesToKeepCategorySelectionAlsoWithOneCategory = [5];

export function CreateItemClassCategoryStep(props) {

    const {createItemStore, metadataStore, userStore , uiStore, billingStore} = useStore();    
    
    const handleClassItemOnClick = (classItem) => {
        createItemStore.selectClass(classItem.id);
        if (createItemStore.selectedClassCategories.length === 1 && !classesToKeepCategorySelectionAlsoWithOneCategory.includes(classItem.id) ){
            if (userStore.isDemoOrReadOnlyMode){
                uiStore.setShowDemoLimitationModal(true);
                createItemStore.selectClass(0);
            } else {
                if (userStore.isCreateItemManuallyLimited){
                    createItemStore.setShowAddManualItemLimitModal(true);
                } else {
                    createItemStore.selectCategory(createItemStore.selectedClassCategories[0].id);
                }
            }
        }
    }

    return (<Observer>{()=>(
        <>
        <ClassCategoryStepWrapper>
            {metadataStore.isLoaded && <>
                {(uiStore.isDesktopView || createItemStore.classId === 0) &&   
                    <ClassesPanel>
                    {(userStore.isFreeTier && !uiStore.isDesktopView && ((createItemStore.isManuallyStep && userStore.isCreateItemManuallyLimited) || (createItemStore.isSyncingStep && userStore.isAddSyncInstitutionLimited))) && <UpgradePortfolioButton style={{padding:'24px 0'}} onClick={()=>{billingStore.setShowPricingTableModal(true)}}><UpgradeCrownIcon />Upgrade</UpgradePortfolioButton>}

                                {(userStore.isTier2or3 && !uiStore.isDesktopView &&
                                    ((createItemStore.isManuallyStep && userStore.isCreateItemManuallyLimited) || (createItemStore.isSyncingStep && userStore.isAddSyncInstitutionLimited))) 
                                    && <UpgradePortfolioButton blueBg style={{padding:'24px 0'}} onClick={()=>{billingStore.setShowPricingTableModal(true)}}><UpgradeCrownIcon />Upgrade</UpgradePortfolioButton>}
                    <ClassCategoryGroupTitle>Select Asset Class</ClassCategoryGroupTitle>
                    {  metadataStore.assetClasses.map((classItem)=>(
                        <ClassButtonItem key={classItem.id} classItem={classItem} handleClassItemOnClick={handleClassItemOnClick} selectedClassId={createItemStore.classId} />
                        // <ClassButton key={classItem.id} onClick={() => handleClassItemOnClick('asset',classItem)}
                        // // <ClassButton key={classItem.id} onClick={()=>{createItemStore.selectClass(classItem.id)}}
                        //     className={createItemStore.itemType === 'asset' && createItemStore.classId === classItem.id ? 'selected' : ''} >
                        //     <ClassIconWrapper color={classItem.color} size={32} iconSize={20}>
                        //         <AssetClassIcon name={classItem.icon} />
                        //     </ClassIconWrapper>
                        //     <ClassTitle>{classItem.title}</ClassTitle>
                        // </ClassButton>
                    ))}
                    <ClassesSeparator />
                    <ClassCategoryGroupTitle>Select Liability Class</ClassCategoryGroupTitle>
                    {  metadataStore.liabilityClasses.map((classItem)=>(
                        <ClassButtonItem key={classItem.id} classItem={classItem} handleClassItemOnClick={handleClassItemOnClick} selectedClassId={createItemStore.classId} />
                    ))}
                    </ClassesPanel>
                }
                 {(uiStore.isDesktopView || createItemStore.classId !== 0) &&   
                <CategoryPanel>
                    {createItemStore.classId === 0 &&
                        <SelectClassMessageWrapper>
                            {/* <SyncOnlinePlatformButton /> */}
                            {/* <SelectClassMessage>Or select a class to add<br/>new {createItemStore.itemType === ADD_ITEM_TYPE.ASSET ? 'Asset' : 'Liability'}</SelectClassMessage> */}
                            <SelectClassImage />
                            <SelectClassMessage>Select a class to add new asset / liability</SelectClassMessage>
                        </SelectClassMessageWrapper>
                    }
                    {createItemStore.classId !== 0 && 
                    <HoverClassMessage>
                    <ClassIconWrapper color={createItemStore.selectedClass.color} size={38} iconSize={24}>
                             <AssetClassIcon name={createItemStore.selectedClass.icon} />
                    </ClassIconWrapper>
                    <SelectedClassTitle>{createItemStore.selectedClass.title}</SelectedClassTitle>
                    <SelectedClassDescription>{createItemStore.selectedClass.description}</SelectedClassDescription>
                    <AddItemCategoryStep isOnBoarding={true}/>
                </HoverClassMessage>
                    
                    }
                </CategoryPanel>}
            </>}
        </ClassCategoryStepWrapper>
        
        </>
    )}</Observer>)
}

function ClassButtonItem(props) {
    const {classItem, handleClassItemOnClick, selectedClassId} = props;
    return (
        <ClassButton onClick={() => handleClassItemOnClick(classItem)}
        // <ClassButton key={classItem.id} onClick={()=>{createItemStore.selectClass(classItem.id)}}
            className={selectedClassId === classItem.id ? 'selected' : ''} >
            <ClassIconWrapper color={classItem.color} size={32} iconSize={20}>
                <AssetClassIcon name={classItem.icon} />
            </ClassIconWrapper>
            <ClassTitle>{classItem.title}</ClassTitle>
        </ClassButton>
    )
}