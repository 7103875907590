import styled from '@emotion/styled/macro';
import bgImg from './assets/bg.png';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../../common/styles/theme.styles';
import { keyframes } from '@emotion/react';

const colorChange = keyframes`
    0% {
      fill:#fff;
    }
    40% {
      fill:#fff;
    }
    50%{
      fill:aqua;
    }
    60%{
      fill:#fff;
    }
    100% {
      fill:#fff;
    }
`;

export const ModalBgWrapper = styled.div`
     background: url(${bgImg}) no-repeat;
     background-size: 100% 223px;
     border-radius:8px;
     position: relative;
     display:flex;
     flex-direction:column;
     align-items:center;
`;

export const ModalCloseButtonWrapper = styled.div`
    position:absolute;
    right:16px;
    top:16px;
    width:24px;
    height:24px;
    cursor: pointer;
    path{
      stroke: ${colors.darkGray3};
    }
`;

export const ModalHeader = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
  height:223px;
  svg {
    path {
      width: 98px;
      height: 98px;

    }
  }
  /* svg{
    rect:nth-child(1){
      animation: ${colorChange} 0.8s infinite linear;
    }
    rect:nth-child(3){
      animation: ${colorChange} 0.8s 0.2s infinite linear;
    }
    rect:nth-child(2){
      animation: ${colorChange} 0.8s 0.4s infinite linear;
    }
    rect:nth-child(4){
      animation: ${colorChange} 0.8s 0.6s infinite linear;
    }
  } */
`;

export const ModalContent = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-bottom: ${props => props.isTier4 ? '32' : '48'}px;
  ${views.mobile} {
    padding-bottom: 0;
  }
`;

export const TitleWrapper = styled.div`
  ${flexCenter};
  /* padding-top:40px; */
  padding-bottom:24px;
`;

export const LogoDecorationsWrapper = styled.div`
  display: flex;
  align-items: center;
  svg:first-of-type, svg:last-of-type{
    width: 60px;
    height: 102px;
    path {
      stroke: #fff;
      fill: #fff;
    }
  }
`;

export const TitleText = styled.div`
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 103%;
  color: ${colors.darkGray1};
  text-align: center;
  padding-top: 45px;
  white-space: pre-line;
`;

export const StartButton = styled.div`
  background-color: ${colors.darkGray1};
  padding: 12px 24px;
  height:38px;
  box-shadow: 0px 1px 10px rgba(0, 71, 255, 0.15);
  border-radius: 25px;
  ${flexCenter};
  cursor: pointer;
  font-size:16px;
  font-weight:500;
  color:#fff;
  margin-bottom:16px;
  svg{
    margin-left:8px;
    path{
      stroke:#fff;
    }
  }
`;

export const MiddleMessage = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-align:center;
  padding-top: 40px;
  padding-bottom: 12px;
  color: ${colors.darkGray1};
`;

export const BottomMessage = styled.div`
  font-size:16px;
  color:${colors.darkGray1};
  text-align:center;
  line-height: 20px;
  white-space: pre-line;
`;

export const ClickToDownload = styled.div`
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: ${colors.darkGray2};
  padding-top: 4px;
`;

export const MobileStoresWrapper = styled.div`
  ${flexCenter};
  gap: 4px;
  padding-top: 16px;
`;

export const ActionButton = styled.div`
  ${flexCenter};
  padding: 12px 24px;
  height:38px;
  cursor: pointer;
  min-width: 144px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  box-shadow: ${shadows.toggleShadow};
  border-radius: 6px;
  ${props=>props.black ? `
  background-color: ${colors.darkGray1};
  color: #fff;
  ` : `
  background-color: #fff;
  color: ${colors.darkGray1};
  `};
  ${props=>props.isBack ? `margin-right: auto` : ``};
`;

export const VipSyncWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #DEB482;
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  margin-top: 10px;
  border-radius: 6px;
  background: #FFF;
  padding-left: 12px;
  padding-right: 20px;
  box-shadow: ${shadows.toggleShadow};
  cursor: pointer;
  min-height: 40px;
`;