import {
    getData, postData, postFormData, deleteData
} from '../../../common/utils/network.utils';


export const getMagicBoxData = async () => {
    return (await getData("magic-box")).data;
}

export const getLastByUser = async () => {
    return (await getData("magic-box/last")).data;
}

export const getLLMResult = async (fileId) => {
    return (await getData(`magic-box/llm_results/${fileId}`)).data;
}

export const uploadDocuments = async (formData) => {
    return (await postFormData(`magic-box`,formData))
}

export const uploadLLMDocument = async (formData) => {
    return (await postFormData(`magic-box/llm`,formData))
}

export const deleteDocument = async (documentId) => {
    return (await deleteData(`magic-box/${documentId}`));
}

export const confirmUploadedFiles = async (ids) => {
    return (await postData("magic-box/confirm",{ids})).data;
}

export const confirmLLM = async (fileId) => {
    return (await postData(`magic-box/confirm/${fileId}`)).data;
}

export const getFileLLMStatus = async (fileId) => {
    return (await getData(`magic-box/llm_status/${fileId}`)).data;
}

export const hasLLMResult = async (fileId) => {
    return (await getData(`magic-box/has_llm_result/${fileId}`)).data;
}

export const getCurrencyRate = async (currency) => {
    return (await getData(`currencies/live-currency-rate/${currency}`)).data;
}

export const addItems = async (items) => {
    return (await postData("assets/bulk",items));
}

export const updateFileNotes = async (fileId,notes) => {
    return (await postData(`magic-box/${fileId}/notes`,{notes})).data;
}

export const approveFile = async (fileId, notes) => {
    return (await postData(`magic-box/approve/${fileId}`,{notes})).data;
}