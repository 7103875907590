import { Input } from 'antd';
import React from 'react';
import NumberFormat from 'react-number-format';
import { DeleteIcon, PlusIcon } from '../../../../../assets/icons/common/common-icons';
import { isNullOrUndefined } from '../../../../utils/object.utils';
import { ActionButtonWrapper, ColumnContainer, CreateButton, LifeInsuranceBeneficiaryAdvancedLabel, LifeInsuranceBeneficiarySimpleLabel, LifeInsuranceBeneficiaryToggle, LifeInsuranceBeneficiaryWrapper, RowActionsCell, RowContainer, TableBottomActions, TableScrollContent, Wrapper } from './LifeInsuranceBeneficiary.styles';

const initialValue = {
  mode: 'simple',
  beneficiary: '',
  primaryArray: [
    { beneficiary: '', holdings: 100 }
  ],
  secondaryArray: [
    { beneficiary: '', holdings: 100 }
  ],
}

export const LifeInsuranceBeneficiary = (props) => {
  const { onChange, disabled, value, withError } = props;

  const handleAddRowClick = (targetArray) => {
    if (disabled){return}
    const maxValueOfTempId = Math.max(...value[targetArray].map(u => u.tempId || 0), 0);
    const calculatedHoldings = 100 - value[targetArray].reduce((acc, row) => acc + row.holdings, 0);

    const newRow = {
      beneficiary: '',
      holdings: calculatedHoldings < 0 ? 0 : calculatedHoldings,
      tempId: maxValueOfTempId + 1,
    }
    onChange?.({
      ...value,
      [targetArray]: [...value[targetArray], newRow]
    });
  }

  const handleAttributeRowUpdated = (updatedIndex, targetArray, data, key) => {
    const updatedArray = value?.[targetArray]?.map((item, index) => (index === updatedIndex ? data : item));
    onChange?.({
      ...value,
      [targetArray]: updatedArray
    });
  }

  const handleAttributeRowDeleted = (deletedIndex, targetArray) => {
    const updatedArray = value?.[targetArray]?.filter((item, index) => index !== deletedIndex);
    onChange?.({
      ...value,
      [targetArray]: updatedArray
    });
  }

  return (
    <Wrapper>
      <TableScrollContent>
        {value?.mode === 'simple' ? <LifeInsuranceBeneficiaryWrapper style={{flexDirection:'column', padding:0}}>
          <LifeInsuranceBeneficiarySimpleLabel>Beneficiary</LifeInsuranceBeneficiarySimpleLabel>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ flex: 1 }}>
              <Input
                value={value?.beneficiary}
                disabled={disabled}
                onChange={(e) => { onChange?.({ ...value, beneficiary: e.target.value }) }}
              />
            </div>
            <LifeInsuranceBeneficiaryToggle disabled={disabled} onClick={() => { if (!disabled){onChange?.({ ...value, mode: 'advanced' })} }}>
              <PlusIcon />
              Advanced
            </LifeInsuranceBeneficiaryToggle>
          </div>
        </LifeInsuranceBeneficiaryWrapper> :
          <>
            <LifeInsuranceBeneficiaryWrapper>
              <LifeInsuranceBeneficiaryAdvancedLabel style={{ flex: 1 }}>Advanced Beneficiary allocation</LifeInsuranceBeneficiaryAdvancedLabel>
              <div style={{ display: 'flex', gap: '2px', ...(disabled ? {cursor: 'not-allowed' , opacity:'0.4'} : {cursor: 'pointer'} ) }} onClick={() => { if (!disabled){ onChange?.(initialValue) }}}>
                <TrashSvg />
                Clear
              </div>
            </LifeInsuranceBeneficiaryWrapper>

            <div style={{display: 'grid',gridTemplateColumns:'1fr 1fr'}}>
              <div>Primary Beneficiary</div>
              <div>Ownership percentage</div>
            </div>
            {value?.primaryArray?.map((row, index) => (
              <HistoryRow key={row.id || ('p' + row.tempId)}
                index={index}
                item={row} atrKey={row.atrKey}
                disabled={disabled}
                withError={withError && value?.primaryArray.reduce((acc, row) => acc + row.holdings, 0) > 100}
                rowUpdated={(data, key) => { handleAttributeRowUpdated(index, 'primaryArray', data, key) }}
                rowDeleted={() => handleAttributeRowDeleted(index, 'primaryArray')} />
            ))}

            <TableBottomActions>
              <CreateButton disabled={disabled} onClick={() => { handleAddRowClick('primaryArray') }}>
                <PlusIcon />
                <span className='button-text'>Add primary beneficiary</span>
              </CreateButton>
            </TableBottomActions>

            <div style={{display: 'grid',gridTemplateColumns:'1fr 1fr'}}>
              <div>Secondary Beneficiary</div>
              <div>Ownership percentage</div>
            </div>
            {value?.secondaryArray?.map((row, index) => (
              <HistoryRow key={row.id || ('s' + row.tempId)}
                index={index}
                disabled={disabled}
                item={row} atrKey={row.atrKey}
                withError={withError && value?.secondaryArray.reduce((acc, row) => acc + row.holdings, 0) > 100}
                rowUpdated={(data, key) => { handleAttributeRowUpdated(index, 'secondaryArray', data, key) }}
                rowDeleted={() => handleAttributeRowDeleted(index, 'secondaryArray')} />
            ))}

            <TableBottomActions>
              <CreateButton disabled={disabled} onClick={() => { handleAddRowClick('secondaryArray') }}>
                <PlusIcon />
                <span className='button-text'>Add secondary beneficiary</span>
              </CreateButton>
            </TableBottomActions>
          </>
        }
      </TableScrollContent>
    </Wrapper>
  )
}

const HistoryRow = (props) => {
  const { item, disabled, rowDeleted, withError } = props;

  const handleFieldChange = (field, value) => {
    props.rowUpdated({
      ...item,
      [field]: value
    }, field)
  }

  return (
    <RowContainer>
      <ColumnContainer>
        <Input
          type='text'
          disabled={disabled}
          className={`innerTable`}
          value={item.beneficiary}
          onChange={(e) => { handleFieldChange('beneficiary', e.target.value) }}
        />
      </ColumnContainer>
      <ColumnContainer>
        <NumberFormat
          thousandSeparator={true}
          suffix={'%'} decimalScale={item.specificDecimalScale || 2}
          max={100}
          placeholder={'%'}
          customInput={Input}
          disabled={disabled}
          className={`innerTable${withError ? ' inputEntryError' : ''}`}
          style={{ ...item.isLocked ? ({ pointerEvents: 'none' }) : ({}), color: '#9097A3' }}
          value={(isNullOrUndefined(item.holdings)) ? '' : item.holdings}
          autoComplete="off"
          {...(item.validValues ? { isAllowed: item.validValues } : {})}
          onValueChange={(values) => { handleFieldChange('holdings', (values.floatValue || values.floatValue === 0) ? values.floatValue : null) }}
        />
      </ColumnContainer>
      { !disabled && <RowActionsCell>
        <ActionButtonWrapper
          onClick={() => { rowDeleted?.() }} >
          <DeleteIcon />
        </ActionButtonWrapper>
      </RowActionsCell> }
    </RowContainer>
  )
}

const TrashSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.1668 6.33398C12.1668 6.33398 11.7363 11.5961 11.5513 13.251L4.50461 13.2551C4.31846 11.5856 3.8335 6.33398 3.8335 6.33398" fill="#585D66" />
      <path d="M12.1668 6.33398C12.1668 6.33398 11.7363 11.5961 11.5513 13.251L4.50461 13.2551C4.31846 11.5856 3.8335 6.33398 3.8335 6.33398H12.1668Z" stroke="#585D66" strokeWidth="0.5" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M6.5 8L6.5 11.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.5 8L9.5 11.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 4.5H4" stroke="#585D66" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
      <mask id="path-6-inside-1_8755_50619" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M11 4.03525C10.2816 4.03525 10.0629 3.44902 10.028 2.83813C10.0026 2.39435 9.67439 2 9.22989 2H6.77011C6.32561 2 5.99736 2.39435 5.972 2.83813C5.93708 3.44902 5.71835 4.03525 5 4.03525" />
      </mask>
      <path d="M11 5.23525C11.6627 5.23525 12.2 4.69799 12.2 4.03525C12.2 3.37251 11.6627 2.83525 11 2.83525V5.23525ZM5 2.83525C4.33726 2.83525 3.8 3.37251 3.8 4.03525C3.8 4.69799 4.33726 5.23525 5 5.23525V2.83525ZM10.028 2.83813L8.82996 2.9066L10.028 2.83813ZM9.22989 0.8H6.77011V3.2H9.22989V0.8ZM11 2.83525C10.9873 2.83525 11.0363 2.83387 11.1106 2.87313C11.1858 2.91286 11.2333 2.96594 11.2557 2.99943C11.2738 3.02647 11.2671 3.02773 11.2549 2.98082C11.243 2.93504 11.2315 2.86534 11.226 2.76965L8.82996 2.9066C8.85234 3.29817 8.93705 3.84885 9.25972 4.33212C9.63612 4.89586 10.2489 5.23525 11 5.23525V2.83525ZM4.77395 2.76965C4.76848 2.86534 4.75696 2.93504 4.74505 2.98082C4.73285 3.02773 4.72624 3.02647 4.7443 2.99943C4.76665 2.96594 4.81419 2.91286 4.88937 2.87313C4.96366 2.83387 5.01271 2.83525 5 2.83525V5.23525C5.75114 5.23525 6.36388 4.89586 6.74028 4.33212C7.06295 3.84885 7.14766 3.29817 7.17004 2.9066L4.77395 2.76965ZM6.77011 0.8C5.53413 0.8 4.82603 1.85842 4.77395 2.76965L7.17004 2.9066C7.16746 2.95173 7.15081 3.00624 7.09968 3.06218C7.0411 3.12629 6.92419 3.2 6.77011 3.2V0.8ZM9.22989 3.2C9.07581 3.2 8.9589 3.12629 8.90032 3.06218C8.84919 3.00624 8.83254 2.95173 8.82996 2.9066L11.226 2.76965C11.174 1.85842 10.4659 0.8 9.22989 0.8V3.2Z" fill="#585D66" mask="url(#path-6-inside-1_8755_50619)" />
    </svg>
  )
}