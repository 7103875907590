import { colors } from "../../../common/styles/theme.styles"

export const allocateByTitles = {
    classes : 'Classes',
    accounts: 'Accounts',
    categories : 'Categories',
    entities : 'Entities',
    currencies: 'Currencies',
    risk: 'Risk levels',
    liquidity: 'Liquidity levels',
    institution: 'Institutions',
    customClasses: 'Custom classes',
    customSubClasses : 'Custom sub-classes',
    propertyType : 'Property type',
    companySector : 'Company sector',
}

export const allocationByFilterOptions = {
    entities : {filterPropName : 'holdingEntity' , filterType: 'list'},
    currencies : {filterPropName : 'currencies' , filterType: 'list'},
    risk : {filterPropName : 'riskLevel' , filterType: 'list'},
    liquidity : {filterPropName : 'liquidityLevel' , filterType: 'list'},
    institution: {filterPropName : 'institutions' , filterType: 'list'},
    propertyType : {filterPropName : 'propertyType' , filterType: 'list'},
    companySector : {filterPropName : 'companySector' , filterType: 'list'},
}

export const riskFixedGroups = [
    { label : 'Low', value : 'Low' , icon : 'riskLow' , color: '#C0D681' },
    { label : 'Medium', value : 'Medium' , icon : 'riskMedium' , color: '#EFB764' },
    { label : 'High', value : 'High' , icon : 'riskHigh' , color: '#F17389' },
    { label : 'Unspecified' , value : null , icon : 'riskUnspecified' , color: colors.darkGray2},
]

export const liquidityFixedGroups = [
    { label : '01 (Non-liquid)', value : 1 , icon : 'liquidity' , color: '#373255' },
    { label : '02', value : 2 , icon : 'liquidity' , color: '#564B97' },
    { label : '03', value : 3 , icon : 'liquidity' , color: '#5775C3' },
    { label : '04' , value : 4 , icon : 'liquidity' , color: '#3CB4CF' },
    { label : '05 (Fully liquid)' , value : 5 , icon : 'liquidity' , color: '#84E9FF'},
    { label : 'Unspecified' , value : null , icon : 'liquidity' , color: colors.darkGray2},
]