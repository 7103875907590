import styled from "@emotion/styled/macro";

// import { colors, shadows, views } from "../../../../common/styles/theme.styles";
import { flexVerticalCenter } from "../../../../common/styles/combos.styles";
import { colors } from "../../../../common/styles/theme.styles";


export const HeaderWrapper = styled.div`
  ${flexVerticalCenter}
  height:56px;
  width:100%;
  background-color: #F7F8F9;
  z-index: 2;
  font-size: 13px;
  font-weight: 500;
  color:${colors.darkGray3};
  padding-inline:24px;
`;
