import { displayMoneyValue, quantityFormatWithCommas } from "../../../../../../common/utils/number-display.utils"
import { BaseAdditionalAmountData } from "./AssetEventRow.styles"

export const categoriesEventTitle = {
    41: { 'Principal / Interest payment' : 'Payment' }
}

export const categoriesEventAmountTitle = {
    // 36: 'Cost',
    40: 'Cash flow',
}

export const categoriesEventAmountComputedTitle = {
    16: ['Cashflow','Amount'],
    18: ['Cashflow','Amount'],
    14: ['Cashflow','Amount'],
    19: ['Cashflow','Amount'],
    26: ['Cashflow','Amount'],
    27: ['Cashflow','Amount'],
    32: ['Cashflow','Amount'],
}

export const categoriesEventAssetValueTitle = {
    36: 'Balance',
    37: 'Loan balance',
    38: 'Loan balance',
    39: 'Balance',
    40: 'Cash value',
    41: 'Loan balance',
}

export const categoriesEventsAttributes = {
    36: [
        {title:'Quantity', atrKey: 'quantity', type:'cryptoQuantity'  , withHoldings: true },
        {title:'Price', atrKey: 'pricePerCoin' , type:'money', digits : 2 },
    ],
    24: [
        {title:'PPS', atrKey: 'pricePerShare', type:'money'},
        {title:'Shares', atrKey: 'sharesPurchased'},
    ],
    25: [
        {title:'PPS', atrKey: 'pricePerShare', type:'money'},
        {title:'Shares', atrKey: 'sharesPurchased'},
    ],
    35: [
        {title:'PPS', atrKey: 'pricePerShare', type:'money', digits: 4},
        {title:'Shares +/-', atrKey: 'numberOfShares' , subKey: 'sharesType' , withHoldings: true, type:'quantityWithCommas'},
    ],
    39: [
        {title:'PPS', atrKey: 'pricePerShare', type:'money', digits: 4},
        {title:'Shares +/-', atrKey: 'numberOfShares' , subKey: 'sharesType' , withHoldings: true, type:'quantityWithCommas'},
    ],
}

export const categoriesEventsAfterAmountAttributes = {
    37: [
        {title:'Interest', atrKey: 'interestPayment', isPredictedAttribute: true , type: 'money'},
        {title:'Principal', atrKey: 'principalPayment', isPredictedAttribute: true, type:'money'},
    ],
    38: [
        {title:'Interest', atrKey: 'interestPayment', isPredictedAttribute: true , type: 'money'},
        {title:'Principal', atrKey: 'principalPayment', isPredictedAttribute: true, type:'money'},
    ],
    40: [
        {title:'Death benefit', atrKey: 'deathBenefit', type: 'money'},
    ],
    41: [
        {title:'Principal', atrKey: 'principalPayment', isPredictedAttribute: true, type:'money'},
        {title:'Interest', atrKey: 'interestPayment', isPredictedAttribute: true , type: 'money'},
    ]
}

export const categoriesShowPositiveValue = [36,37]

const displayOfCapitalAmount = (event, itemCurrency , isIncognito , itemHoldings) => {
    const ofCapitalAttribute = event.eventData?.attributes.find(atr => atr.data.hasOwnProperty('ofCapital'));
    if (ofCapitalAttribute) {
        return <BaseAdditionalAmountData>({displayMoneyValue(ofCapitalAttribute.data['ofCapital'],itemCurrency,isIncognito,itemHoldings)} of capital)</BaseAdditionalAmountData>
    }
    return null;
};

export const categoriesTransactionAmountAdditionalDataFunction = {
    14: displayOfCapitalAmount,
    19: displayOfCapitalAmount,
    26: displayOfCapitalAmount,
    27: displayOfCapitalAmount,
    32: displayOfCapitalAmount,
    16: displayOfCapitalAmount,
    18: displayOfCapitalAmount
}

// const displayQuantity = ({value}) => {
//     return quantityFormat(value);
// }

const stockQuantity = ({value, stock}) => {
    return quantityFormatWithCommas(value) + ' ' + stock?.symbol;
}

const cryptoQuantity = ({value, cryptoCoin}) => {
    return displayMoneyValue(value , cryptoCoin?.symbol , false  );
}



export const categoriesValueDisplayFunction = {
    36: cryptoQuantity,
    39: stockQuantity
}