import React from 'react';
import { CheckmarkIcon, ClockIcon, SimulationsIcon } from '../../../../assets/icons/common/common-icons';

export const TransactionsStatusIcon = ({iconName}) => {
    switch (iconName) {
        case 'default':
          return <ClockIcon />;
        case 'simulation':
            return <SimulationsIcon />;
        case 'happened':
            return <CheckmarkIcon />;
        default:
          return null;
      }
};