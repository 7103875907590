import React from 'react';
import { EmptyStateStarsIcon } from '../../../assets/icons/common/common-icons';
import { NotificationsEmptyStateIconWrapper, NotificationsEmptyStateLearnMoreWrapper, NotificationsEmptyStateTitle, NotificationsEmptyStateWrapper } from './Notifications.styles';

export const NotificationsEmptyState = () => {

  const handleLearnMoreOnClick = () => {
    window.open('https://help.vyzer.co/en/articles/7033045-notifications', '_blank');
  }

  return (
    <NotificationsEmptyStateWrapper>
      <NotificationsEmptyStateIconWrapper>
        <EmptyStateStarsIcon />
      </NotificationsEmptyStateIconWrapper>
      <NotificationsEmptyStateTitle>
        {`Looking good!\nNo notifications right now`}
      </NotificationsEmptyStateTitle>
      <NotificationsEmptyStateLearnMoreWrapper onClick={handleLearnMoreOnClick}><QuestionOutlinedSvg /> Learn more</NotificationsEmptyStateLearnMoreWrapper>
    </NotificationsEmptyStateWrapper>
  )
}

const QuestionOutlinedSvg = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9243 11.648V11.5827C10.9243 11.1362 11.1312 10.7987 11.5123 10.5264L11.9588 10.2107C12.6012 9.75333 13.0368 9.14356 13.0368 8.28333C13.0368 7.18355 12.1548 6.11644 10.467 6.11644C8.85544 6.11644 7.97344 7.24889 7.97344 8.42489C7.97344 8.58822 7.98433 8.76244 8.02789 8.91489L9.39989 8.96933C9.36722 8.87133 9.35633 8.75156 9.35633 8.62089C9.35633 8.01111 9.69389 7.39044 10.467 7.39044C11.1966 7.39044 11.5559 7.86956 11.5559 8.37044C11.5559 8.69711 11.4143 8.99111 11.0877 9.23067L10.565 9.62267C9.94433 10.0909 9.70478 10.6244 9.70478 11.2016C9.70478 11.3649 9.72656 11.5064 9.74833 11.648H10.9243ZM9.49789 13.2269C9.49789 13.706 9.879 14.0871 10.3581 14.0871C10.8372 14.0871 11.2292 13.706 11.2292 13.2269C11.2292 12.7478 10.8372 12.3558 10.3581 12.3558C9.879 12.3558 9.49789 12.7478 9.49789 13.2269Z" fill="#585D66" />
      <rect x="3.5" y="3" width="14" height="14" rx="7" stroke="#585D66" strokeWidth="1.5" />
    </svg>
  )
}