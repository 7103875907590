import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../styles/combos.styles';
import { colors, shadows } from '../../../../styles/theme.styles';

export const Wrapper = styled.div`
    width: 100%;
    border-radius: 9px;
    display:flex;
    flex-direction:column;
    padding:16px 0px 0px;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    padding:0 16px 8px;
    color: ${colors.darkGray1};
`;

export const TableRowSizes = styled.div`
    display: grid;
    grid-template-columns: 130px 264px auto;
`;

export const TableHeaderRow = styled(TableRowSizes)`
    gap:4px;
`;

export const HeaderCell = styled.div`
    font-size:14px;
    color:#9D9D9D;
    ${flexCenter};
    justify-content: ${props=>props.isAmount ? 'space-between' : 'flex-start'};
`;

export const AmountPercentageWrapper = styled.div`
    ${flexCenter};
`

export const TooltipWrapper = styled.div`
    display: flex;
    margin-left: 4px;
`;

export const TableScrollContent = styled.div`
    .ant-picker , .ant-input{
        border-radius:4px;
        &:hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
            box-shadow: none;
        }
    }

    .ant-input{
       
    }
    .ant-input-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }
`;

export const TableBottomActions = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    padding-top:8px;
`;

export const RowWrapper = styled(TableRowSizes)`
    padding: 8px 0px;
    gap: 4px;
    position:relative;
    :last-of-type{
        padding-bottom:0px;
    }
    min-height:48px;

    ${props=>props.isEdited ? `
    ${RowActionsCell} {
        opacity: 1;
    }
 ` : ``}
`;

export const RowCell = styled.div`
    font-size:16px;
    line-height:24px;
    color: ${colors.darkGray1};
    align-items: center;
    display:flex;
`;

export const RowDCellDisplayedText = styled.div`
    padding:0 12px;
`;

export const RowActionsCell = styled.div`
    align-items: center;
    position:absolute;
    left:100%;
    top:10px;
    display:flex;

    opacity:0;
    ${RowWrapper}:hover &{
        opacity:1;
    }
    &:hover{
        opacity:1;
    }
`;

export const ActionButtonWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px;
    svg{
        height: 24px;
        path {
            stroke: #000;
        }
    }
`;

export const SaveButton = styled.div`
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:80px;
    color:#3F3F45;
    font-size:16px;
    height: 32px;
    margin-right: 16px;
    cursor: pointer;
`;


export const CreateButton = styled.div`
    ${flexCenter};
    cursor: pointer;
    color:${colors.darkGray1};
    border:1px solid ${colors.darkGray1};
    padding:2px 28px 2px 24px;
    border-radius:2px;
    font-size:16px;
    font-weight: 500;
    svg{
        margin-right:4px;
        stroke:${colors.darkGray1};
    }
    .button-text{
        margin-left:4px;
    }
`;

export const PrevComponentWrapper = styled.div`
    display:flex;
    flex-direction:column;
    margin-bottom:16px;
`;
export const PrevComponentDescription = styled.div`
    color:${colors.darkGray1};
    font-size:18px;
    margin-bottom:8px;
`;
export const PrevComponentButtons = styled.div`
    ${flexCenter};
    justify-content:flex-start;
    gap:16px;
`;
export const PrevComponentButton = styled.div`
    border-radius:6px; 
    padding:4px 16px;
    font-size:16px;
    font-weight:500;
    box-shadow:${shadows.toggleShadow};
    color: ${colors.darkGray1};
    background-color: #fff;
    border: 1px solid ${props=>props.isSelected ? colors.darkGray1 : '#fff'}; 
    cursor: pointer;
`;
export const CapitalCallsTitle = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    font-weight:500;
`;
export const CapitalCallsTitleAdditionalText = styled.div`
    font-size:14px;
    margin-left:4px;
    color:${colors.darkGray3};
`;
