import { getData, putData, postData, deleteData } from '../../../common/utils/network.utils';

export const fetchUserData = async () => {
    return (await getData('user')).data;
}

export const fetchProfileData = async () => {
    return (await getData('user/profile')).data;
}

export const logOut = async () => {
    return (await postData('user/logout')).data;
}

export const saveProfileData = async (data) => {
    return (await putData('user/profile',data));
}

export const updatePhoneNumber = async (data) => {
    return (await putData('profile/update-phone-number',data));
}

export const saveGranteeProfileData = async (data) => {
    return (await putData('user/grantee-profile',data));
}

export const changePassword = async (data) => {
    return (await postData('user/change-password',data));
}

export const updateProfileSettings = async (section, data) => {
    return (await putData(`profile/settings/${section}`,data));
}

export const updateGranteeProfileSettings = async (section, data) => {
    return (await putData(`profile/grantee-settings/${section}`,data));
}

export const updateActivationStep = async (data) => {
    return (await putData('profile/update-activation-flow',data));
}

export const sendCancelSurvey = async (data) => {
    return (await postData('profile/feedback',data));
}

export const getRoles = async (ownerId) => {
    return ((await getData('authorization/granted-permissions')).data);
}

export const grantPermission = async (data) => {
    return (await postData('authorization',data));
}

export const resendInvitationMail = async (rowId) => {
    return (await postData('authorization/resend-invitation/' + rowId));
}

export const updatePermission = async (data) => {
    return (await putData('authorization',data));
}

export const deletePermission = async (rowId) => {
    return (await  deleteData('authorization/granted-permission/' +rowId));
}

export const switchAccount = async (ownerId) => {
    return (await postData('authorization/switch-account',{ownerId}));
}

export const startMyPortfolio = async () => {
    return (await postData('user/start-my-portfolio',{}));
}

