import styled from '@emotion/styled/macro';

export const PieWrapper = styled.div`
    position:relative;
    width:100%;
    max-width:276px;
    // padding:16px 0;
    ${props=>props.withBottomPadding ? `padding-bottom: 8px;` : ``}
`

export const PieTextContent = styled.div`
    position:absolute;
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    top:${props=>props.contentTop ? props.contentTop : '0px'};
    pointer-events:none;
`

export const PieTextLabel = styled.div`
    color:#9097A3;
    font-size:16px;
    line-height:12px;
`

export const PieTextBigValue = styled.div`
    color:#FFFFFF;
    font-size:${props=>props.isIncognito ? '22px' : props.fontSize ? props.fontSize : '32px'};
`

export const ClassIconWrapper = styled.div`
    width: 25px;
    height: 25px;   


    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width:25px;
        height:25px;
    }
    path{
        /* stroke:#4F699D; */
        fill:${props=>props.color || '#9097A3'};
    }
`

export const ClassTitleText = styled.div`
    font-size:16px;
    color: ${props=>props.color || '#fff'};
`;
export const ClassSumText = styled.div`
    color: #9097A3;
    font-size:14px;
`;