import { Checkbox } from 'antd';
import { Observer } from 'mobx-react';
import React from 'react';
import { ArrowDownIcon, CheckmarkIcon } from '../../../../../assets/icons/common/common-icons';
import { useStore } from '../../../../app/data/root.store';
import { AssetClassIcon } from '../../../../assets/components/AssetClassIcon';
import { CategoriesCardContent, CategoriesCardHeader, CategoryItem, ClassButton, ClassCategoriesButtonText, ClassCategoriesCardWrapper, ClassItemWrapper, ClassRowCategoriesButton, ClassRowWrapper, ClassesListWrapper, LiabilityIconWrapper, OptionLogoWrapper, SelectedCategoriesCount } from './LeveragedAssetsParameterFilter.styles';

const dataKeys = ['leveragedAssetsId', 'notLeveragedAssetsId'];

export const LeveragedAssetsParameterFilter = ({ param }) => {
  const { filtersStore, metadataStore } = useStore();

  const handleOptionClick = (optionKey, isSelected) => {
    filtersStore.setLeveragedAssetsSelected(optionKey, !isSelected);
  }

  return (<Observer>{() => (
    <ClassesListWrapper>
      {dataKeys.map((dataKey) => {
        const relevantParam = param[dataKey];
        return (
          <ClassItemWrapper key={dataKey}>
            <ClassRowWrapper>
              <ClassButton
                onClick={() => { handleOptionClick(dataKey, relevantParam.isSelected) }}
                isSelected={relevantParam.isSelected}>
                Is{dataKey === 'leveragedAssetsId' ? '' : <span style={{ fontWeight: 700, paddingInline: '4px' }}>{`not`}</span>} leveraged {relevantParam.isSelected ? <CheckmarkIcon /> : ''}
              </ClassButton>
              {dataKey === 'leveragedAssetsId' ? <ClassRowCategoriesButton onClick={() => { filtersStore.handleOpenCloseLoansWithCollaterals() }}>
                {((relevantParam.isSelected && relevantParam.options.length > 0) || relevantParam.isOpen) && <>
                  <ClassCategoriesButtonText>Filter by loan</ClassCategoriesButtonText>
                  {!relevantParam.isAllCategoriesSelected && (relevantParam.selectedCategoriesCount > 0) && <SelectedCategoriesCount>{relevantParam.selectedCategoriesCount}</SelectedCategoriesCount>}
                  <ArrowDownIcon style={{ transition: '0.2s', transform: ` rotate(${relevantParam.isOpen ? -180 : 0}deg)` }} />
                </>}
              </ClassRowCategoriesButton> : null}
            </ClassRowWrapper>
            {relevantParam.isOpen && <ClassCategoriesCardWrapper>
              <CategoriesCardHeader>
                <Checkbox checked={relevantParam.selectedCount === relevantParam.options.length} onChange={() => { filtersStore.handleSelectAllLoanWithCollateral(dataKey, relevantParam.selectedCount !== relevantParam.options.length) }}>Select a linked loan</Checkbox>
              </CategoriesCardHeader>
              <CategoriesCardContent>
                {relevantParam.options.map(option => {
                  const relevantLoan = metadataStore.loansObj[option.liabilityId]; 
                  return (
                    relevantLoan ? <CategoryItem key={option.liabilityId}>
                      <Checkbox checked={option.isSelected}
                        style={{ display: 'flex', alignItems: 'center' }}
                        onChange={() => { filtersStore.handleSelectLoanWithCollateral(option.liabilityId, !option.isSelected) }} >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {relevantLoan.logoBase64 ? <OptionLogoWrapper><img alt={relevantLoan.connectedInstitutionName} src={'data:image/png;base64,' + relevantLoan.logoBase64} /></OptionLogoWrapper> :
                            relevantLoan.logoURL ? <OptionLogoWrapper><img alt={relevantLoan.connectedInstitutionName} src={relevantLoan.logoURL} /></OptionLogoWrapper> :
                              <LiabilityIconWrapper color={"#EB7E7E"} >
                                <AssetClassIcon name={'lia-loans'} />
                              </LiabilityIconWrapper>
                          }
                          {relevantLoan?.title}
                        </div>
                      </Checkbox>
                    </CategoryItem> : null
                  )
                })}
              </CategoriesCardContent>
            </ClassCategoriesCardWrapper>}
          </ClassItemWrapper>
        )
      })}
    </ClassesListWrapper>
  )}</Observer>)
}

