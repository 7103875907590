import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { sizes, views } from '../../../../common/styles/theme.styles';


export const BannerWrapper = styled.div`
    width: 100%;
    background: ${props => props.bannerBgc ? props.bannerBgc : 'black'};
    color: ${props => props.bannerTxtColor ? props.bannerTxtColor : '#FFFFFF'};
    ${views.tablet}{
        height: 48px;
        display: flex;
    }
`

export const BannerContentWrapper = styled.div`
    width: 100%;
    /* max-width: ${sizes.wealthContentWidth + 32}px; */
    /* padding: 0 16px; */
    /* margin: 0 auto; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 32px;
    gap: 24px;
    ${views.tablet}{
        ${flexCenter};
        justify-content: space-between;
        padding:0 12px 0 16px;
    }
`

export const IconTextWrapper = styled.div`
    display: flex; 
    align-items: center;
`

export const IconWrapper = styled.div`
    height: 15px;
`

export const TextWrapper = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 20px 10px;
`

export const ActionButtonWrapper = styled.div`
    display: flex; 
    align-items: center;
    border: 1px solid #FFFFFF;
    border-radius: 6px;
    padding: 9.5px 20px;
    cursor: pointer;
`


export const ActionButtonText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
`

export const ActionButtonIcon = styled.div`
    height:17px;
    padding-left: 10px;
        path{
          stroke: ${props => props.bannerButtonTxtColor ? props.bannerButtonTxtColor : 'currentColor'};
        }
`;

export const UnlockIconWrapper = styled.div`
    ${flexCenter};
    padding-right:  6px;
    padding-left: 0;
`;

export const ReadOnlyAndDemoModeActionButtonWrapper = styled.div`
    background: ${props => props.bannerButtonBgc ? props.bannerButtonBgc : 'pink'};
    color: ${props => props.bannerButtonTxtColor ? props.bannerButtonTxtColor : '#FFFFFF'};
    display: flex; 
    align-items: center;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 42px;
    padding: 7.5px 24px;
    cursor: pointer;
`

export const ReadOnlyAndDemoModeIconTextWrapper = styled.div`
    display: flex; 
    align-items: center;
`

export const ReadOnlyAndDemoModeIconWrapper = styled.div`
    height: 24px;
    line, path{
          stroke: ${props => props.bannerTxtColor ? props.bannerTxtColor : '#FFFFFF'};
        }
`

export const ReadOnlyAndDemoModeTextWrapper = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 20px 10px 20px 0;
`

export const MobileTextWrapper = styled.div`
    font-weight:400;
    font-size: 15px;
    font-family: 'Domine';
    line-height: 18px; 
    color:#fff;
`;

export const MobileActionButtonWrapper = styled.div`
       padding:8px 0px 8px 8px;
       cursor: pointer; 
       display: flex; 
       align-items: center;
       font-weight: 450;
       font-size: 14px;
`;
