import styled from "@emotion/styled/macro";

export const CommonPopoverHoverEffectWrapper = styled.div`
  background-color: transparent;
  position: absolute;
  pointer-events: none;
  opacity:0;
`;

export const CommonPopoverHoverEffectContent = styled.div``;

export const CommonPopoverWrapper = styled.div`
  position: relative;
  :hover {
    ${CommonPopoverHoverEffectWrapper} {
      display: flex;
      z-index: 999;
      opacity:1;
      pointer-events:auto;
    }
  }
`;