import { Checkbox, Dropdown } from 'antd';
import { Observer } from 'mobx-react';
import React from 'react';
import { ArrowDownIcon, CheckmarkIcon } from '../../../../../../../assets/icons/common/common-icons';
import { BlackMenuItemWrapper, BlackMenuWrapper } from '../../../../../../../common/components/DropDownMenu/DropDownMenu.styles';
import { ModifiedTooltip } from '../../../../../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { displayMoneyValue } from '../../../../../../../common/utils/number-display.utils';
import { useStore } from '../../../../../../app/data/root.store';
import { eventTypesOptions, inflowOutflowTimeFrameOptions, } from '../../../../../../data-layers/data/inflow-outflow-data-layers.store';
import { ArrowButtonArrow, ArrowButtonWrapper, ArrowGridCellWrapper, BarsBottomText, BarsGridCellWrapper, BarsWrapper, DataBar, DataBarTooltipContent, DataBarTooltipFooter, DataBarTooltipHeader, DataBarTooltipInflowWrapper, DataBarTooltipOutflowWrapper, DataBarTooltipWrapper, DataBarWrapper, DiagonalLines, FlexSpace, HeaderInfoLabel, HeaderInfoLabelBox, HeaderWrapper, HeroContentWrapper, HeroDataWrapper, MobileBottomMapItem, SubHeader, TargetDataBar, TimeFrameButtonLabel, TimeFrameButtonValueLabel, TimeFramesButton, TooltipDataActual, TooltipDataTarget, TooltipDataTargetWrapper, TooltipDataTitle } from './InflowOutflowHeroContent.styles';
import { useAnalytics } from '../../../../../../app/data/AnalyticsProvider';

export function InflowOutflowHeroContent(props) {

  const {
    // filtersStore ,
    uiStore,
    wealthStore,
    inflowOutflowDataLayersStore } = useStore();
  const { trackEvent } = useAnalytics();

  const handleOnEventsTypeChange = (value) => {
    inflowOutflowDataLayersStore.setSelectedEventTypes(value);
  }

  return (<Observer>{() => (

    <HeroContentWrapper>
      <HeaderWrapper>
        {!uiStore.isDesktopView && <><FlexSpace /></>}

        <Dropdown
          overlay={
            TimeFrameDropDown({
              value: inflowOutflowDataLayersStore.timeFrame,
              isMobileView: !uiStore.isDesktopView,
              onTimeFrameChange: (value) => { inflowOutflowDataLayersStore.setTimeFrame(value) }
            })
          }
          getPopupContainer={() => document.getElementById('wealthPageContent')}
          trigger={['click']} placement="bottomCenter" >
          <TimeFramesButton>
            <TimeFrameButtonLabel>Timeframe:</TimeFrameButtonLabel>
            <TimeFrameButtonValueLabel>{inflowOutflowDataLayersStore.selectedTimeFrameLabel}</TimeFrameButtonValueLabel>
            <ArrowDownIcon />
          </TimeFramesButton>
        </Dropdown>

        {!wealthStore.isOnlyCashOrCreditItems && <Dropdown
          overlay={
            EventsTypeDropDown({
              selectedEventTypes: inflowOutflowDataLayersStore.selectedEventTypes,
              onEventsTypeChange: handleOnEventsTypeChange,
              onToggleAllEventTypes: () => { inflowOutflowDataLayersStore.toggleAllEventTypes(); trackEvent('Inflow/Outflow event type selection'); },
            })
          }
          getPopupContainer={() => document.getElementById('wealthPageContent')}
          trigger={['click']} placement="bottomCenter" >
          <TimeFramesButton>
            <TimeFrameButtonLabel>Events type:</TimeFrameButtonLabel>
            <TimeFrameButtonValueLabel>{inflowOutflowDataLayersStore.selectedEventTypes.length === eventTypesOptions.length ? 'All' : 'Custom'}</TimeFrameButtonValueLabel>
            <ArrowDownIcon />
          </TimeFramesButton>
        </Dropdown>}

        {uiStore.isDesktopView && <><FlexSpace />
          <HeaderInfoLabel inflow><HeaderInfoLabelBox inflow />Inflow</HeaderInfoLabel>
          <HeaderInfoLabel outflow><HeaderInfoLabelBox outflow />Outflow</HeaderInfoLabel>
          <HeaderInfoLabel><HeaderInfoLabelBox isTarget />Target</HeaderInfoLabel>
          <ModifiedTooltip placement={'bottom'} title={<LearnMoreToolTipContent />} overlayStyle={{ width: '340px', maxWidth: '340px' }} overlayInnerStyle={{ border: '1px solid #585D66' }} ><HeaderInfoLabel>Learn more</HeaderInfoLabel></ModifiedTooltip>
        </>}

      </HeaderWrapper>
      <HeroDataWrapper dataColumns={inflowOutflowDataLayersStore.columns.length} >
        <ArrowGridCellWrapper leftArrow>
          {inflowOutflowDataLayersStore.columns.length !== 1 &&
            <ArrowButtonWrapper
              disabled={!wealthStore.isInflowNavigateLeftEnable}
              onClick={() => { if (wealthStore.isInflowNavigateLeftEnable) { inflowOutflowDataLayersStore.setEndpointIndex(inflowOutflowDataLayersStore.endpointIndex + 1) } }}><ArrowButtonArrow isLeft /></ArrowButtonWrapper>}
        </ArrowGridCellWrapper>
        {wealthStore.inflowOutflowTotalColumns.map((col) => (
          <BarsGridCellWrapper key={col.label}>
            <BarsWrapper isSingleLine={inflowOutflowDataLayersStore.columns.length === 1}>
              <DataBarWrapper>
                {col.targetInflow > col.inflow && <TargetDataBar inflow height={(col.targetInflow - col.inflow) * wealthStore.inflowOutflowBarsRatio} />}
                <DataBar inflow height={col.inflow * wealthStore.inflowOutflowBarsRatio} />
              </DataBarWrapper>
              <DataBarWrapper>
                {col.targetOutflow < col.outflow && <TargetDataBar height={(col.outflow - col.targetOutflow) * wealthStore.inflowOutflowBarsRatio} />}
                <DataBar height={Math.abs(col.outflow) * wealthStore.inflowOutflowBarsRatio} />
              </DataBarWrapper>
              {uiStore.isDesktopView && <DataBarTooltip cl={col} withTarget={!wealthStore.isOnlyCashOrCreditItems} />}
            </BarsWrapper>
            <BarsBottomText>{col.label}</BarsBottomText>
            {!uiStore.isDesktopView && <DataBarTooltip cl={col} withTarget={!wealthStore.isOnlyCashOrCreditItems} />}
          </BarsGridCellWrapper>
        ))}
        <ArrowGridCellWrapper>
          {inflowOutflowDataLayersStore.columns.length !== 1 &&
            <ArrowButtonWrapper disabled={inflowOutflowDataLayersStore.endpointIndex === 0}
              onClick={() => { if (inflowOutflowDataLayersStore.endpointIndex !== 0) { inflowOutflowDataLayersStore.setEndpointIndex(inflowOutflowDataLayersStore.endpointIndex - 1) } }}><ArrowButtonArrow /></ArrowButtonWrapper>}
        </ArrowGridCellWrapper>
      </HeroDataWrapper>

      {!uiStore.isDesktopView && <MobileBottomMapItem>
        <HeaderInfoLabel inflow><HeaderInfoLabelBox inflow />Inflow</HeaderInfoLabel>
        <HeaderInfoLabel outflow><HeaderInfoLabelBox outflow />Outflow</HeaderInfoLabel>
        <HeaderInfoLabel><HeaderInfoLabelBox isTarget />Target</HeaderInfoLabel>
        <ModifiedTooltip placement={'top'} title={<LearnMoreToolTipContent />} overlayStyle={{ width: '340px', maxWidth: '340px' }} overlayInnerStyle={{ border: '1px solid #585D66' }} ><HeaderInfoLabel>Learn more</HeaderInfoLabel></ModifiedTooltip>
      </MobileBottomMapItem>}
    </HeroContentWrapper>
  )}</Observer>)
}

function TimeFrameDropDown(props) {
  const { value, onTimeFrameChange, isMobileView } = props
  return (
    <BlackMenuWrapper>
      {inflowOutflowTimeFrameOptions.filter(item => { return isMobileView ? item.value !== 'months' : true }).map(item => (
        <BlackMenuItemWrapper key={item.value} onClick={() => { onTimeFrameChange(item.value) }}>{item.label} {value === item.value && <CheckmarkIcon />}</BlackMenuItemWrapper>
      ))}
    </BlackMenuWrapper>
  )
}

function EventsTypeDropDown({ selectedEventTypes, onEventsTypeChange, onToggleAllEventTypes, }) {

  const handleCheckboxChange = (e, newValue) => {
    onEventsTypeChange(newValue);
  };

  return (
    <BlackMenuWrapper style={{ width: 'auto' }} >
      <BlackMenuItemWrapper style={{ borderBottom: '1px solid #34373A' }} onClick={(e) => { e.stopPropagation() }}>
        <Checkbox
          style={{ color: '#fff' }}
          key={'all'}
          checked={selectedEventTypes.length === eventTypesOptions.length}
          onChange={onToggleAllEventTypes}
        >
          All
        </Checkbox>
      </BlackMenuItemWrapper>
      <SubHeader>Inflows</SubHeader>
      {eventTypesOptions.slice(0, 2).map(({ label, value }) => (
        <BlackMenuItemWrapper key={value} onClick={(e) => { e.stopPropagation() }}>
          <Checkbox
            style={{ color: '#fff' }}
            checked={selectedEventTypes.includes(value)}
            onChange={(e) => handleCheckboxChange(e, value)}
          >
            {label}
          </Checkbox>
        </BlackMenuItemWrapper>
      ))}
      <SubHeader>Outflows</SubHeader>
      {eventTypesOptions.slice(2).filter(i => !i.hidden).map(({ label, value }) => (
        <BlackMenuItemWrapper key={value} onClick={(e) => { e.stopPropagation() }}>
          <Checkbox
            style={{ color: '#fff' }}
            checked={selectedEventTypes.includes(value)}
            onChange={(e) => handleCheckboxChange(e, value)}
          >
            {label}
          </Checkbox>
        </BlackMenuItemWrapper>
      ))}
    </BlackMenuWrapper>
  );
}

function LearnMoreToolTipContent() {
  return (
    <div style={{ fontSize: '14px', padding: '4px' }}>
      The cash inflows / outflows are calculated based on <b>actual cash events,</b> such as cash distributions.<br /><br />
      The bar graph displays the total inflow / outflow from the list below, as well as the target (sum of the expected events from each item).<br /><br />
      Note: This view eliminates cash accounts.<br />To view Cash accounts transaction inflow and outflow go to Cash account class page.

    </div>
  )
}

export const DataBarTooltip = ({ cl, withTarget }) => {
  return (
    <DataBarTooltipWrapper>
      <DataBarTooltipHeader>{cl.label}</DataBarTooltipHeader>
      <DataBarTooltipContent>
        <DataBarTooltipInflowWrapper>
          <TooltipDataTitle>Total Inflow</TooltipDataTitle>
          <TooltipDataActual>{displayMoneyValue(cl.inflow, 'USD')}</TooltipDataActual>
          {withTarget && <>
            <TooltipDataTargetWrapper><DiagonalLines />Target</TooltipDataTargetWrapper>
            <TooltipDataTarget>{displayMoneyValue(cl.targetInflow, 'USD')}</TooltipDataTarget>
          </>}
        </DataBarTooltipInflowWrapper>
        <DataBarTooltipOutflowWrapper>
          <TooltipDataTitle>Total Outflow</TooltipDataTitle>
          <TooltipDataActual>{displayMoneyValue(cl.outflow, 'USD')}</TooltipDataActual>
          {withTarget && <>
            <TooltipDataTargetWrapper><DiagonalLines isOutflow />Target</TooltipDataTargetWrapper>
            <TooltipDataTarget>{displayMoneyValue(cl.targetOutflow, 'USD')}</TooltipDataTarget>
          </>}
        </DataBarTooltipOutflowWrapper>
      </DataBarTooltipContent>
      <DataBarTooltipFooter>Net Inflow: {displayMoneyValue(cl.inflow + cl.outflow, 'USD')}</DataBarTooltipFooter>
    </DataBarTooltipWrapper>
  )
}