import React, { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../modules/app/data/root.store';
import { ActionLockedWrapper } from './ActionLocked.styles';

export const ActionLocked = observer(({ action, blockCb, children }) => {
  const { billingStore, userStore } = useStore();
  const actionLockedWrapperRef = useRef(null);

  const isBlockedByRole = useCallback(() => {
    return userStore.isActionBlockedByRole(action);
  }, [userStore, action]);

  const isActionBlocked = useCallback(() => {
    return userStore.isActionBlockedByPaymentStatus(action);
  }, [userStore, action]);

  useEffect(() => {
    const handleActionLockedWrapperRefOnClick = (e) => {
      if (isBlockedByRole()) {
        e.stopPropagation();
        blockCb?.();
        userStore.setShowLimitedSharedAccess(true);
      } else if (isActionBlocked()) {
        e.stopPropagation();
        billingStore.setShowPricingTableModal(true);
        blockCb?.();
      }
    };

    const actionLockedWrapperRefCurrent = actionLockedWrapperRef.current;
    if (actionLockedWrapperRefCurrent) {
      actionLockedWrapperRefCurrent.addEventListener('click', handleActionLockedWrapperRefOnClick);
    }

    return () => {
      if (actionLockedWrapperRefCurrent) {
        actionLockedWrapperRefCurrent.removeEventListener('click', handleActionLockedWrapperRefOnClick);
      }
    };
  }, [isBlockedByRole, isActionBlocked, userStore, billingStore, blockCb, action]);

  return (
      <ActionLockedWrapper ref={actionLockedWrapperRef}>
        {children}
      </ActionLockedWrapper>
  );
});
