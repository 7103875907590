import { makeAutoObservable } from "mobx";
import { fetchPanelData, fetchPlaidOrSaltedgeInstitutionAccounts, fetchZaboInstitutionBalances, createZaboAccount,  connectAccounts, deleteAccounts, disconnectAccount, connectAssetToVipServiceInstitution, vipInstitutionUpdateLastSync, vipInstitutionChangeStatus, syncInstitution, syncAllInstitutions } from "./connected-institutions.service";
import { errorNotification, successNotification } from "../../layout/components/Notifications/notifications";
import { akoyaSubTypeToVyzerCategoryMapping, akoyaTypeToVyzerClassMapping, plaidSubTypeToVyzerCategoryMapping, plaidTypeAndSubTypeToVyzerClassMapping, plaidTypeToVyzerClassMapping, saltedgeTypeToVyzerClassMapping } from "./connected-institutions.consts";
import { unSupportedConnectedAccountCategories } from "../../metadata/data/metadata.store";

export class ConnectedInstitutionsStore{

    
    isLoading = false;
    plaidInstitutions = [];
    zaboInstitutions = [];
    saltEdgeInstitutions = [];
    akoyaInstitutions = [];
    vipInstitutions = [];
    isSyncAllOnceLoading = false;
    
    constructor(metadataStore){
        makeAutoObservable(this);
        this.metadataStore = metadataStore;
    }

    fetchData = async () =>{
        this.setLoading(true);
        try {
            const data = await fetchPanelData();
            this.setData(data);
        } 
        catch (e) {} 
        finally {
            this.setLoading(false);
        }
    }

    syncAllData = async () => {
        const updatePromises = [];
        if (!this.isSyncAllLoading){
        try {

            this.plaidInstitutions.filter(inst=>inst.status === 'connected').forEach(inst=>{
                updatePromises.push(this.syncPlaidInstitution(inst.id));
            })

            this.saltEdgeInstitutions.filter(inst=>inst.status === 'connected').forEach(inst=>{
                updatePromises.push(this.syncSaltEdgeInstitution(inst.id));
                
            })

            this.akoyaInstitutions.filter(inst=>inst.status === 'connected').forEach(inst=>{
                updatePromises.push(this.syncAkoyaInstitution(inst.id));
            })
            await Promise.all(updatePromises);    
            return true;
        } catch (e) {
            return false;
        }
        // finally {}
        }
    }

    syncAllInstitutionsOnce = async () => {
        this.setIsSyncAllOnceLoading(true);
        try {
            await syncAllInstitutions();
            return true;
        } 
        catch (e) {
            return false;
        } 
        finally {
            this.setIsSyncAllOnceLoading(false);
        }
    }

    syncPlaidInstitution = async(piId) => {
        const inst = this.plaidInstitutions.find(ci=>ci.id===piId);
        inst.isSyncing = true;
        try {
            const syncData = await syncInstitution('plaid',piId);
            if (syncData && syncData.success){
                inst.latestPulling = new Date();
                if (inst.accountsLoaded){
                    this.fetchPlaidAccounts(piId,true);
                }
            }
        }
        catch (e) {}
        finally {
            inst.isSyncing = false;
        }
    }

    syncSaltEdgeInstitution = async(iId) => {
        const inst = this.saltEdgeInstitutions.find(ci=>ci.id===iId);
        inst.isSyncing = true;
        try {
            const syncData = await syncInstitution('saltEdge',iId);
            if (syncData && syncData.success){
                inst.latestPulling = new Date();
                if (inst.accountsLoaded){
                    this.fetchSaltedgeAccounts(iId,true);
                }
            }
        }
        catch (e) {} 
        finally {
            inst.isSyncing = false;
        }
    }

    syncAkoyaInstitution = async(iId) => {
        const inst = this.akoyaInstitutions.find(ci=>ci.id===iId);
        inst.isSyncing = true;
        try {
            const syncData = await syncInstitution('akoya',iId);
            if (syncData && syncData.success){
                inst.latestPulling = new Date();
                if (inst.accountsLoaded){
                    this.fetchAkoyaAccounts(iId,true);
                }
            }
        }
        catch (e) {} 
        finally {
            inst.isSyncing = false;
        }
    }
    

    fetchPlaidAccounts = async (piId , fetchAnyway = false) => {
        const inst = this.plaidInstitutions.find(ci=>ci.id===piId);
        if (inst && ( !inst.accountsLoaded || fetchAnyway)){
            inst.isLoadingAccounts = true;
            const instAccounts = await fetchPlaidOrSaltedgeInstitutionAccounts('plaid',piId);
            this.setPlaidInstitutionAccount(piId,instAccounts);
            // inst.accounts = instAccounts;
            // inst.accountsLoaded = true;
        }
    }

    fetchSaltedgeAccounts = async (seiId, fetchAnyway = false) => {
        const inst = this.saltEdgeInstitutions.find(ci=>ci.id===seiId);
        if (inst && (!inst.accountsLoaded || fetchAnyway)){
            inst.isLoadingAccounts = true;
            const instAccounts = await fetchPlaidOrSaltedgeInstitutionAccounts('saltEdge',seiId);
            this.setSaltedgeInstitutionAccount(seiId,instAccounts);
            // inst.accounts = instAccounts;
            // inst.accountsLoaded = true;
        }
    }

    fetchAkoyaAccounts = async (seiId, fetchAnyway = false) => {
        const inst = this.akoyaInstitutions.find(ci=>ci.id===seiId);
        if (inst && (!inst.accountsLoaded || fetchAnyway)){
            inst.isLoadingAccounts = true;
            const instAccounts = await fetchPlaidOrSaltedgeInstitutionAccounts('akoya',seiId);
            this.setAkoyaInstitutionAccount(seiId,instAccounts);
            // inst.accounts = instAccounts;
            // inst.accountsLoaded = true;
        }
    }

    fetchZaboBalances = async (ziId) => {
        const inst = this.zaboInstitutions.find(zi=>zi.id===ziId);
        if (inst && !inst.accountsLoaded){
            inst.isLoadingAccounts = true;
            const instAccounts = await fetchZaboInstitutionBalances(ziId);
            this.setZaboInstitutionBalances(ziId,instAccounts);
            // inst.accounts = instAccounts;
            // inst.accountsLoaded = true;
        }
    }

    fetchVipAccounts = async (vipIId, fetchAnyway = false) => {
        const inst = this.vipInstitutions.find(ci=>ci.id===vipIId);
        if (inst && (!inst.accountsLoaded || fetchAnyway)){
            inst.isLoadingAccounts = true;
            const instAccounts = await fetchPlaidOrSaltedgeInstitutionAccounts('vip',vipIId);
            this.setVipInstitutionAccount(vipIId,instAccounts);
            // inst.accounts = instAccounts;
            // inst.accountsLoaded = true;
        }
    }

    setPlaidInstitutionAccount(instId,accounts){
        // console.log(accounts);
        const updatedInsts = this.plaidInstitutions.map(inst=> (inst.id === instId ? {
            ...inst,
            // accounts,
            accounts: accounts.map(ac=>{
                const classId = plaidTypeAndSubTypeToVyzerClassMapping[ac.accountType + '_' + ac.accountSubType] ||  plaidTypeToVyzerClassMapping[ac.accountType] ||  null;
                const firstCategoryInClass = this.metadataStore.categories.find(category=>category.classId === classId && !category.isHidden && !unSupportedConnectedAccountCategories.includes(category.id));
                const categoryId = plaidSubTypeToVyzerCategoryMapping[ac.accountSubType] || ( firstCategoryInClass ? firstCategoryInClass.id : null );      
                return {
                    ...ac,
                    isCheckingClass: classId === 1,
                    classId,
                    categoryId
                }
            }),
            accountsLoaded : true,
            isLoadingAccounts : false
        } : inst));
        // console.log(updatedInsts);
        this.plaidInstitutions = updatedInsts;
    }

    setSaltedgeInstitutionAccount(instId,accounts){
        const updatedInsts = this.saltEdgeInstitutions.map(inst=> (inst.id === instId ? {
            ...inst,
            accounts: accounts.map(ac=>{
                const classId = saltedgeTypeToVyzerClassMapping[ac.accountType] || ( this.classId === 0 ? null : this.classId );
                const firstCategoryInClass = this.metadataStore.categories.find(category=>category.classId === classId && !unSupportedConnectedAccountCategories.includes(category.id));
                const categoryId = firstCategoryInClass?.id;         // plaidSubTypeToVyzerCategoryMapping[ac.accountSubtype] || firstCategoryInClass.id;             
                return {
                    ...ac,
                    isCheckingClass: classId === 1,
                    classId,
                    categoryId
                }
            }),
            accountsLoaded : true,
            isLoadingAccounts : false
        } : inst));
        this.saltEdgeInstitutions = updatedInsts;
    }

    setAkoyaInstitutionAccount(instId,accounts){
        const updatedInsts = this.akoyaInstitutions.map(inst=> (inst.id === instId ? {
            ...inst,
            accounts: accounts.map(ac=>{
                const classId = akoyaTypeToVyzerClassMapping[ac.accountType] || ( this.classId === 0 ? null : this.classId );
                const firstCategoryInClass = this.metadataStore.categories.find(category=>category.classId === classId && !unSupportedConnectedAccountCategories.includes(category.id));
                const categoryId = akoyaSubTypeToVyzerCategoryMapping[ac.accountSubType] || ( firstCategoryInClass ? firstCategoryInClass.id : null ); //firstCategoryInClass?.id;

                return {
                    ...ac,
                    isCheckingClass: classId === 1,
                    classId,
                    categoryId
                }
            }),
            accountsLoaded : true,
            isLoadingAccounts : false
        } : inst));
        this.akoyaInstitutions = updatedInsts;
    }

    setVipInstitutionAccount(instId,accounts){
        const updatedInsts = this.vipInstitutions.map(inst=> (inst.id === instId ? {
            ...inst,
            accounts,
            accountsLoaded : true,
            isLoadingAccounts : false
        } : inst));
        this.vipInstitutions = updatedInsts;
    }

    setZaboInstitutionBalances(instId,accounts){
        const updatedInsts = this.zaboInstitutions.map(inst=> (inst.id === instId ? {
            ...inst,
            accounts,
            accountsLoaded : true,
            isLoadingAccounts : false
        } : inst));
        this.zaboInstitutions = updatedInsts;
    }

    updateAccounts(instId,account){
        // console.log({instId, account});
        const updatedInsts = this.plaidInstitutions.map(inst=> (inst.id === instId ? {
            ...inst,
            accounts: inst.accounts.map(cia=>cia.accountId === account.accountId ? account : cia),
        } : inst));
        this.plaidInstitutions = updatedInsts;
    }
    updateSaltedgeAccounts(instId,account){
        const updatedInsts = this.saltEdgeInstitutions.map(inst=> (inst.id === instId ? {
            ...inst,
            accounts: inst.accounts.map(cia=>cia.id === account.id ? account : cia)
        } : inst));
        this.saltEdgeInstitutions = updatedInsts;
    }

    updateZaboBalances(instId,balances){
        const updatedInsts = this.zaboInstitutions.map(inst=> (inst.id === instId ? {
            ...inst,
            accounts: balances,
        } : inst));
        this.zaboInstitutions = updatedInsts;
    }

    setLoading(isLoading){
        this.isLoading = isLoading;
    }

    setData(data) {
        // this.assets = data.assets;
        // this.liabilities = data.liabilities;
        // this.institutions = data.connectedInstitutions;
        this.plaidInstitutions = data.plaidConnectedInstitutions;
        // this.plaidInstitutions = data.plaidConnectedInstitutions.map(i=>({...i,isLoadingAccounts:true}));
        // this.zaboInstitutions = data.zaboConnectedAccounts;
        this.saltEdgeInstitutions = data.saltEdgeInstitutions;
        this.akoyaInstitutions = data.akoyaInstitutions;
        this.vipInstitutions = data.vipInstitutions;
    }

    setIsSyncAllOnceLoading(isLoading){
        this.isSyncAllOnceLoading = isLoading;
    }

    setPlaidInstitutions(institutions) {
        this.plaidInstitutions = institutions;
    }

    setSaltEdgeInstitutions(institutions) {
        this.saltEdgeInstitutions = institutions;
    }

    setAkoyaInstitutions(institutions) {
        this.akoyaInstitutions = institutions;
    }

    setVipInstitutions(institutions) {
        this.vipInstitutions = institutions;
    }


    connectZaboAccount = async(data) => {
        try {
            const createAccountResponse = await createZaboAccount(data);
            console.log("Zabo create account response", createAccountResponse);
        } 
        catch (e) {
            // console.log("here error" , e);
            errorNotification('Something went wrong');
        } 
        finally {}   
    }

    connectFromPanel = async(instId, account, source, onDone) => {
        let ci;
        const updateActions = [];
        const updatedAccountsIds = [];
        if(source === 'plaid') {
            ci = this.plaidInstitutions.find(inst => inst.id === instId);
            // console.log(JSON.stringify(ci));
            updatedAccountsIds.push(ci.accounts.find(cia=>cia.accountId === account.accountId).accountId);
        } else if (source === 'saltEdge') {
            ci = this.saltEdgeInstitutions.find(inst => inst.id === instId);
            updatedAccountsIds.push(ci.accounts.find(cia=>cia.id === account.id).id);
        } else {
            ci = this.akoyaInstitutions.find(inst => inst.id === instId);
            updatedAccountsIds.push(ci.accounts.find(cia=>cia.accountId === account.accountId).accountId);
        }

        const {accounts, ...ciProps} = ci;
        // console.log(account);
        updateActions.push({
            ...account,
            source,
            connectedInstitution: ciProps
        })

        const newAssetsCreated = await connectAccounts({accounts: updateActions, returnCreated : true})
        if (Array.isArray(newAssetsCreated) && newAssetsCreated.length > 0){
            if(source === 'plaid') {
                const updatedInsts = this.plaidInstitutions.map(inst=> ( inst.id === instId ? {
                    ...inst,
                    accounts: inst.accounts.map(ac=> (ac.accountId === account.accountId ? {
                        ...ac,
                        astId: newAssetsCreated[0].id,
                        astCategoryId: account.action.categoryId,
                        astTitle: account.name
                    } : ac ))
                } : inst));
                this.plaidInstitutions = updatedInsts;
            } else if(source === 'saltEdge') {
                const updatedInsts = this.saltEdgeInstitutions.map(inst=> ( inst.id === instId ? {
                    ...inst,
                    accounts: inst.accounts.map(ac=> (ac.id === account.id ? {
                        ...ac,
                        astId: newAssetsCreated[0].id,
                        astCategoryId: account.action.categoryId,
                        astTitle: account.name
                    } : ac ))
                } : inst));
                this.saltEdgeInstitutions = updatedInsts;
            } else if(source === 'akoya') {
                const updatedInsts = this.akoyaInstitutions.map(inst=> ( inst.id === instId ? {
                    ...inst,
                    accounts: inst.accounts.map(ac=> (ac.accountId === account.accountId ? {
                        ...ac,
                        astId: newAssetsCreated[0].id,
                        astCategoryId: account.action.categoryId,
                        astTitle: account.name
                    } : ac ))
                } : inst));
                this.akoyaInstitutions = updatedInsts;
            } 
            
        }
        onDone?.();
    }

    disconnectAccount = async (accountId, source) => {
        const accountsToDisable = [{source, accountId}];
        try {
            await disconnectAccount({accounts: accountsToDisable});
            successNotification('Item disconnected');
            this.disconnectAccountLocally(accountId, source)
        } catch (error) {
            errorNotification('Something went wrong');
        }
    }

    disconnectAccountLocally = (accountId, source) => {
        if(source === 'plaid') {
            const updatedInsts = this.plaidInstitutions.map(inst=> ( inst.accounts?.find(ac=>ac.accountId === accountId) ? {
                ...inst,
                accounts: inst.accounts.map(ac=> (ac.accountId === accountId ? {
                    ...ac,
                    astId: null
                } : ac ))
            } : inst));
            this.plaidInstitutions = updatedInsts;
        } else if(source === 'saltEdge') {
            const updatedInsts = this.saltEdgeInstitutions.map(inst=> ( inst.accounts?.find(ac=>ac.id === accountId) ? {
                ...inst,
                accounts: inst.accounts.map(ac=> (ac.id === accountId ? {
                    ...ac,
                    astId: null
                } : ac ))
            } : inst));
            this.saltEdgeInstitutions = updatedInsts;
        } else if(source === 'akoya') {
            const updatedInsts = this.akoyaInstitutions.map(inst=> ( inst.accounts?.find(ac=>ac.accountId === accountId) ? {
                ...inst,
                accounts: inst.accounts.map(ac=> (ac.accountId === accountId ? {
                    ...ac,
                    astId: null
                } : ac ))
            } : inst));
            this.akoyaInstitutions = updatedInsts;
        }
    }

    plaidReconnectSuccess(instId){
        const updatedInsts = this.plaidInstitutions.map(inst=> (inst.id === instId ? {
            ...inst,
            status : "connected"
        } : inst));
        this.plaidInstitutions = updatedInsts;
    }

    saltedgeReconnectSuccess(instId,status){
        const updatedInsts = this.saltEdgeInstitutions.map(inst=> (inst.id === instId ? {
            ...inst,
            status,
        } : inst));
        this.saltEdgeInstitutions = updatedInsts;
    }

    akoyaReconnectSuccess(instId){
        const updatedInsts = this.akoyaInstitutions.map(inst=> (inst.id === instId ? {
            ...inst,
            status : "connected"
        } : inst));
        this.akoyaInstitutions = updatedInsts;
    }

    deleteAccounts = async (source, instId, deleteType, onDone) => {
        try {
            await deleteAccounts(source, instId, {deleteType});
            if (source === 'plaid'){
                this.setPlaidInstitutions(this.plaidInstitutions.filter(inst => inst.id !== instId))
            }
            if (source === 'saltEdge') {
                this.setSaltEdgeInstitutions(this.saltEdgeInstitutions.filter(inst => inst.id !== instId))
            }
            if (source === 'akoya') {
                this.setAkoyaInstitutions(this.akoyaInstitutions.filter(inst => inst.id !== instId))
            }
            if (source === 'vip'){
                this.setVipInstitutions(this.vipInstitutions.filter(inst => inst.id !== instId))
            }
            successNotification('Items deleted');
            onDone?.();
        }
        catch (e) {
            errorNotification('Something went wrong');
        }
        finally {}
    }

    connectAssetToVipService = async(instId,assetId,removeConnection, archiveAsset) => {
        try {
            await connectAssetToVipServiceInstitution(instId, assetId,removeConnection, archiveAsset);
            //this.setPlaidInstitutions(this.plaidInstitutions.filter(inst => inst.id !== instId))
            // this.setSaltEdgeInstitutions(this.saltEdgeInstitutions.filter(inst => inst.id !== instId))
            this.fetchVipAccounts(instId,true);
            successNotification('Item added');
        }
        catch (e) {
            errorNotification('Something went wrong');
        }
        finally {}
    }

    vipUpdateLastSync = async(instId) => {
        try {
            await vipInstitutionUpdateLastSync(instId);
            const updatedVipInsts = this.vipInstitutions.map(inst=>inst.id === instId ? {
                ...inst,
                latestPulling:new Date()
            } : inst)
            this.setVipInstitutions(updatedVipInsts);
            successNotification('Item updated');
        }
        catch (e) {
            errorNotification('Something went wrong');
        }
        finally {}
    }

    vipChangeStatus = async(instId) => {
        try {
            await vipInstitutionChangeStatus(instId);
            const updatedVipInsts = this.vipInstitutions.map(inst=> inst.id === instId ? {
                ...inst,
                status: inst.status === 'pending' ? 'connected' : 'pending'
            } : inst)
            this.setVipInstitutions(updatedVipInsts);
            successNotification('Item updated');
        }
        catch (e) {
            errorNotification('Something went wrong');
        }
        finally {}
    }

    get connectedInstitutionsCount() {
        return this.plaidInstitutions?.length + this.saltEdgeInstitutions?.length + this.akoyaInstitutions?.length + this.vipInstitutions?.length;
    }

    get isSyncAllLoading(){
        return  this.isSyncAllOnceLoading || [...this.plaidInstitutions,...this.saltEdgeInstitutions,...this.akoyaInstitutions].some(inst=>inst.isSyncing);
    }
}