import React, { useEffect, useRef, useState } from 'react';
import { getData } from '../../../../common/utils/network.utils';
import { useStore } from '../../../app/data/root.store';

export const AkoyaLink = (props) => {
  const { connectorId, onSuccess, onError, reconnectId, isDisabled, waitingForResponseCB, checkInstitutionExistence } = props;
  const [shouldKeepPulling, setShouldKeepPulling] = useState(true);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const pullingIntervalRef = useRef(null);
  const windowClosedIntervalRef = useRef(null);
  const akoyaTab = useRef(null);
  const { uiStore } = useStore();

  const initAkoyaLink = async () => {

    const res = await getData(`akoya${reconnectId ? '/update?inst_id=' + reconnectId : '?connectorId=' + connectorId}`); 
    const { institutionId, authLink } = res.data
    if ( uiStore.isMobileAppView ) {
      if (window.ReactNativeWebView){
        window.ReactNativeWebView.postMessage(JSON.stringify({ action:'open_url' , data : {url:authLink} }));
      }
    } else {
      akoyaTab.current = window.open(authLink);
    }
    setIsWaitingForResponse(true);
    waitingForResponseCB?.(true);

    pullingIntervalRef.current = setTimeout(() => { pullNewAccounts(institutionId, true) }, 5000);
  }


  const pullNewAccounts = async (institutionId, keepPulling) => {
    try {
      const res = await getData('akoya/is-institution-ready/' + institutionId);

      if (res.data && res.data.isReady ) {
        setIsWaitingForResponse(false);
        waitingForResponseCB?.(false);
        if (res.data.error) {
          onError?.({error:res.data.error , accounts: res.data.accounts} );
          // errorNotification(res.data.error.client_message || 'Something went wrong');
        } else {
          onSuccess?.(res.data.accounts);
        }
      } else {
        if (keepPulling) {
          pullingIntervalRef.current = setTimeout(() => {
            pullNewAccounts(institutionId, !akoyaTab?.current?.closed);
          }, 3000);
        } else {
          setIsWaitingForResponse(false);
          waitingForResponseCB?.(false);
        }
      }
    } catch (err) {
      if (keepPulling) {
        pullingIntervalRef.current = setTimeout(() => {
          pullNewAccounts(institutionId, keepPulling);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (!shouldKeepPulling) {
      clearTimeout(pullingIntervalRef.current);
    }
    return () => { }
  }, [shouldKeepPulling]);

  useEffect(() => {
    if (!isWaitingForResponse) {
      clearTimeout(windowClosedIntervalRef.current);
    }
    return () => { }
  }, [isWaitingForResponse]);

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(pullingIntervalRef.current);
  //   }
  // }, []);

  const handleLinkClick = (event) => {
    if (isDisabled) return;
    event.stopPropagation();
    if (!checkInstitutionExistence || !checkInstitutionExistence?.()) {
      initAkoyaLink();
    }
  }

  return <>
    {isWaitingForResponse && props.loadingComp ? <>
      {React.cloneElement(props.loadingComp, {
        onClick: () => { setShouldKeepPulling(false); setIsWaitingForResponse(false) }
      })}
    </> : <>
      {React.cloneElement(props.children, {
        onClick: handleLinkClick
      })}
    </>}
  </>
}