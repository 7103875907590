import React, { useEffect, useState } from 'react';
import { BlinkingCursor } from './ChatRow.styles';

export const AnimatedText = ({ text, delay = 0, animationSpeed = 2000, style }) => {
  const [displayText, setDisplayText] = useState('');
  const [textAnimationEnd, setTextAnimationEnd] = useState(false);

  useEffect(() => {
    let i = 0;
    let interval;
    const timeout = setTimeout(() => {
      interval = setInterval(() => {
        setDisplayText((prevText) => prevText + text[i]);
        i++;
        if (i === text.length) {
          clearInterval(interval)
          setTextAnimationEnd(true);
        };
      }, animationSpeed / text.length);
    }, delay);
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [text, delay, animationSpeed]);

  return (
    <span style={style}>{displayText}{!textAnimationEnd && <BlinkingCursor>I</BlinkingCursor>}</span>
  )
}
