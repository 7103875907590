import { Observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LogoLoader } from '../../../common/components/LogoLoader/LogoLoader';
import { navigateToUrl } from '../../../common/utils/navigation.utils';
import { useStore } from '../../app/data/root.store';
import { NotificationRow } from './NotificationRow';
import { LogoLoaderWrapper, NotificationsWrapper } from './Notifications.styles';
import { NotificationsCashRelatedEmptyState } from './NotificationsCashRelatedEmptyState';
import { NotificationsEmptyState } from './NotificationsEmptyState';
import { useSwipeable } from 'react-swipeable';

export const Notifications = ({ onItemClick , isMobile , onClose }) => {

  const history = useHistory();
  const { notificationsStore, wealthStore, metadataStore } = useStore();

  const onRead = async (notificationsIds) => {
    await notificationsStore.setNotificationMarkedAsRead([notificationsIds], wealthStore);
  }

  const onSeen = async ({ itemId, itemType }) => {
    await notificationsStore.setNotificationMarkedAsSeen(itemId, itemType);
  }

  const onActionClick = (notification) => {
    // notificationsStore.navigateToUrl(history, notification.destinationUrl);
    navigateToUrl(history, notification.destinationUrl);
    onItemClick && onItemClick(notification)
  }

  // const onClose = () => {
  //   // onItemClick && onItemClick();
  // }

  const swipeHandlers = useSwipeable( isMobile? {
    onSwipedRight: (eventData) => {
      onClose && onClose();
      // handleCloseOnClick(eventData.event)
    }
  } : {});

  return (
    <Observer>{() => (
      notificationsStore.isFetchingNotifications ?
        <LogoLoaderWrapper><LogoLoader wbg /></LogoLoaderWrapper> :
        notificationsStore.displayedNotifications.length ?
          <NotificationsWrapper {...swipeHandlers}>
            {notificationsStore.sortedNotifications.map((notification, index) =>
              <NotificationRow key={index}
                isMobile={isMobile}
                notification={notification}
                onActionClick={() => { onActionClick(notification) }}
                onRead={() => { onRead(notification.id) }}
                onSeen={() => { onSeen(notification) }}
                onClose={() => {}}
              />)
            }
          </NotificationsWrapper> :
            metadataStore.hasNonCashAccountAssets ? 
              <NotificationsEmptyState />
            : <NotificationsCashRelatedEmptyState onItemClick={onItemClick} hasNoSyncedCashAccounts={metadataStore.cashAccounts.length === 0} /> 
           
            
            

    )}</Observer>
  )
}