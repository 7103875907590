import { Observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTrackEvent, useTrackGTMEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { useStore } from '../../../app/data/root.store';
import { ArrowDownIcon } from '../../assets/userActivationIcons';
// import { AnimatedText } from '../ChatRow/AnimatedText';
import { ChatRow } from '../ChatRow/ChatRow';

// import { useAnimationsStepDelayedHook } from '../useAnimationsStepDelayedHook';
import { ActionButton, ActionButtonsWrapper, ActivationFlowStepWrapper, AnimatedQuickIntroVideoWrapper, HeightAnimationWrapper, StepContent, StepFooter } from '../UserActivationFlowPage.styles';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';

export const QuickIntroStep = (props) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const { uiStore } = useStore();
  // const animationStep = useAnimationsStepDelayedHook({ animationsStepDelayed: [0, 0, 0, 0] });
  const { trackEvent } = useAnalytics()
  const mixpanelStepsData = {step_name: 'Learn about Vyzer', step_number: 1};
  const youtubeVideoUrlParams = '?rel=0&autoplay=1';

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVideoPlaying(false);
    }, 30000);
    return () => {
      clearTimeout(timeout);
    }
  }, [])

  const handleFlowStepChange = (delta) => {
    if (delta > 0) { 
      uiStore.setActivationStepAsDone(0);
      trackEvent('Onboarding Step Completed', mixpanelStepsData);
    }
    uiStore.setActivationStepIndex(uiStore.activationStepIndex + delta);
  }

  const handleContinueOnClick = () => {
    if (isVideoPlaying) return;
    handleFlowStepChange(1);
  }

  useTrackEvent('Onboarding Step Viewed', mixpanelStepsData);
  useTrackGTMEvent('Onboarding Step Viewed', mixpanelStepsData);

  return (<Observer>{() => (
    <ActivationFlowStepWrapper>      
        <StepContent withFixedActions>
          <HeightAnimationWrapper show={true} height={uiStore.isDesktopView ? 416 : 336} duration={0} >
            <ChatRow duration={0} >
              {/* <AnimatedText animationSpeed={0} delay={0} text={`To help you get started, watch this video with Litan, Co-Founder/CEO of Vyzer.`} /> */}
              <span>To help you get started, watch this video with Litan, Co-Founder/CEO of Vyzer.</span>
              <AnimatedQuickIntroVideoWrapper>
                <iframe src={`https://www.youtube.com/embed/MNCsAHhLQEc${youtubeVideoUrlParams}`}
                  allow='autoplay; encrypted-media'
                  allowFullScreen
                  title='video'
                  width={'100%'}
                  height={uiStore.isDesktopView ? 360 : 240}
                />
              </AnimatedQuickIntroVideoWrapper>
            </ChatRow>
          </HeightAnimationWrapper>
        </StepContent>

      
        <StepFooter isFixedToBottom>
          <ActionButtonsWrapper>
            {/* {uiStore.isDesktopView &&  <ActionButton isBack onClick={() => { handleFlowStepChange(-1) }}>Back</ActionButton>} */}
            {isVideoPlaying  ? <div onClick={() => { handleFlowStepChange(1) }} style={{ display: 'grid', placeItems: 'center', cursor: 'pointer', padding: uiStore.isDesktopView ? '0' : '0 16px' }}>Skip</div> : null}
            <ActionButton fullWidth disabled={isVideoPlaying} onClick={handleContinueOnClick} withIcon black style={uiStore.isDesktopView ? { padding: '8px 12px 8px 24px' }: {}}>Continue{uiStore.isDesktopView && <ArrowDownIcon />}</ActionButton>
          </ActionButtonsWrapper>
        </StepFooter>

    </ActivationFlowStepWrapper>
  )}</Observer>)
}
