import { Popover, Switch } from 'antd';
import { useLocation } from 'react-router-dom';
import { Observer } from 'mobx-react';
import { useStore } from "../../../app/data/root.store"
import { SideBarUpgradeButton, UserMenuButtonWrapper, UserProfileWrapper, UserWithSharedPortfolioMenuButtonWrapper } from "./Sidebar.styled";
import { useEffect, useState } from 'react';
import { HeaderUserIconLetter, HeaderUserIconWrapper, IncognitoTitle, SectionTitle, SeparatorLine, SettingMenuHeaderWrapper, SettingsMenuIncognitoButton, SettingsMenuLink, SettingsMenuLogoutButton, SettingsMenuWrapper, UserEmailText, UserNameText } from './UserProfile.styles';
import { ReactComponent as IncognitoIcon } from './icons/incognito-icon.svg';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { UpgradeCrownIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { ProfilePage } from '../../../user/components/Profile.page';
import { BillingPage } from '../../../user/components/Billing/Billing.page';
import { DesktopSVGLogo } from '../DesktopLogo/DesktopLogo';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { ROUTES } from '../../../../common/constants/routes.const';

const links = [
  { path: ROUTES.profile.url },
  { path: ROUTES.preferences.url },
  { path: ROUTES.billing.url },
  { path: ROUTES.accounts.url },
  { path: ROUTES.roles.url },
  { path: ROUTES.portfolios.url },
];

export const UserProfileMenu = ({isActivation}) => {

  const { userStore, uiStore, filtersStore, billingStore } = useStore();
  const location = useLocation();
  const [isSelected, setIsSelected] = useState('');
  const [isUserDetailsSelected, setIsUserDetailsSelected] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [showBillingPopup, setShowBillingPopup] = useState(false);
  const { mixpanelReset } = useAnalytics();
  
  useEffect(() => {
    // console.log(location.pathname);
    if (links.find(link => link.path === location.pathname)) {
      setIsSelected(location.pathname);
    }

    return () => {
      setShowProfilePopup(false);
      setShowBillingPopup(false);
      setIsSelected('');
    }
  }, [location])

  const logout = () => {
    if (filtersStore.countActiveFilters) {
      filtersStore.handleClearFilterSelections();
    }
    mixpanelReset();
    userStore.logOut();
  }

  const settingsMenu = (isIncognito) => {

    const handleBillingPageOnClick = (e) => {
      if (userStore.isDemoMode) {
        e.preventDefault();
        setIsUserDetailsSelected(false);
        if (userStore.isGranteeHavePortfolio){
          billingStore.setShowPricingTableModal(true);
        }
      }
      else if(isActivation){ 
        e.preventDefault(); setShowBillingPopup(true)
      } 
    }

    const handleConnectedAccountsClick = (e) => {
      if (userStore.isDemoMode) {
        e.preventDefault();
        setIsUserDetailsSelected(false);
        return billingStore.setShowPricingTableModal(true);
      }
    }

    return (
      <>
      
      <SettingsMenuWrapper>
        <SettingMenuHeaderWrapper>
          <HeaderUserIconWrapper>
            <HeaderUserIconLetter>
                {userStore.granteeFirstNameFirstLetterCapitalize}
            </HeaderUserIconLetter>
          </HeaderUserIconWrapper>
          <UserNameText>
            {userStore.granteeFullName}
          </UserNameText>
          <UserEmailText>{userStore.granteeEmail}</UserEmailText>
        </SettingMenuHeaderWrapper>
        {userStore.isRelevantVariant !== 3 && !userStore.isQuestionnaire && <SettingsMenuIncognitoButton disabled={isActivation} onClick={(e) => { if(isActivation){ e.preventDefault()} else { uiStore.setIsIncognitoMode(!uiStore.isIncognitoMode) }}}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IncognitoIcon />
            <IncognitoTitle>Incognito Mode</IncognitoTitle>
          </div>
          <div>
            <Switch
              checked={isIncognito}
              size="small"
              className='userProfileIncognitoSwitch' />
          </div>
        </SettingsMenuIncognitoButton>}
        { !userStore.isHavingSharedPortfolios && userStore.isGranteeHavePortfolio && !userStore.isQuestionnaire&& <>
        <SeparatorLine />
        <SectionTitle>Portfolio Settings</SectionTitle>
        
        { !isActivation && <>
          <SettingsMenuLink selected={ROUTES.accounts.url === isSelected} onClick={handleConnectedAccountsClick} to={ROUTES.accounts.url}>{ROUTES.accounts.title}{userStore.isDemoMode && <ActionLockedIconWithTooltip iconColor={'grey'} marginTop={'-2px'} />}</SettingsMenuLink>
          {userStore.isFreeTier && <SideBarUpgradeButton isProfileMenu onClick={()=>{setIsUserDetailsSelected(false);billingStore.setShowPricingTableModal(true)}} ><UpgradeCrownIcon />Upgrade</SideBarUpgradeButton>}
          {userStore.isTier2 && <SideBarUpgradeButton isBlueBtn isProfileMenu onClick={()=>{setIsUserDetailsSelected(false);billingStore.setShowPricingTableModal(true)}} ><UpgradeCrownIcon />Upgrade</SideBarUpgradeButton>}
        </> }
        <SettingsMenuLink selected={ROUTES.preferences.url === isSelected} to={ROUTES.preferences.url} onClick={(e)=> { isActivation && e.preventDefault()}} disabled={isActivation}>{ROUTES.preferences.title}</SettingsMenuLink>
        { !isActivation && <SettingsMenuLink selected={ROUTES.roles.url === isSelected} to={ROUTES.roles.url} onClick={(e)=> { isActivation && e.preventDefault()}} disabled={isActivation}>{ROUTES.roles.title}</SettingsMenuLink> }
        </> }
        <SeparatorLine />
        <SectionTitle>Account Settings</SectionTitle>
        <SettingsMenuLink selected={ROUTES.profile.url === isSelected} to={ROUTES.profile.url} onClick={(e)=> {  if(isActivation){ e.preventDefault();  setShowProfilePopup(true)} else { setIsUserDetailsSelected(false);}}}>
          {ROUTES.profile.title}
          <div style={{flex:1}} />
          {ROUTES.profile.url === isSelected ? <LinkSelectedIcon /> : !userStore.isViewingOwnPortfolio ? <UserArrowLinkSvg /> : null}
        </SettingsMenuLink>
        {userStore.isHavingSharedPortfolios && <SettingsMenuLink selected={ROUTES.portfolios.url === isSelected} to={ROUTES.portfolios.url} onClick={(e)=> { setIsUserDetailsSelected(false);}}>
          {ROUTES.portfolios.title}
          <div style={{flex:1}} />
          {ROUTES.portfolios.url === isSelected ? <LinkSelectedIcon /> : !userStore.isViewingOwnPortfolio ? <UserArrowLinkSvg /> : null }
          
        </SettingsMenuLink>}
        {!userStore.isFreeUser && !userStore.isHavingSharedPortfolios && userStore.isRelevantVariant !== 3 && !userStore.isQuestionnaire && <SettingsMenuLink selected={ROUTES.billing.url === isSelected}  onClick={handleBillingPageOnClick} to={ROUTES.billing.url}>{ROUTES.billing.title}{userStore.isDemoMode && <ActionLockedIconWithTooltip iconColor={'grey'} marginTop={'-2px'} />}</SettingsMenuLink> }
        <SettingsMenuLogoutButton onClick={logout}>Log out</SettingsMenuLogoutButton>
      </SettingsMenuWrapper>
      {showProfilePopup &&   <BaseModal onBackDropClick={()=>{setShowProfilePopup(false)}}  borderRadius={8} customModalWrapperStyles={{...(userStore.isQuestionnaire ? {height:'auto'} : {})}}>
        <ProfilePage isPopup={true} onClosePopup={()=>{setShowProfilePopup(false)}}/>
      </BaseModal> }
      {showBillingPopup &&   <BaseModal onBackDropClick={()=>{setShowBillingPopup(false)}}  borderRadius={8} >
        <BillingPage isPopup={true} onClosePopup={()=>{setShowBillingPopup(false)}}/>
      </BaseModal> }
      </>
    )
  }

  return <Observer>{() => (
    <>
      {(userStore.isHavingSharedPortfolios || !userStore.isGranteeHavePortfolio) && userStore.isRelevantVariant !== 3 && !userStore.isQuestionnaire ? <UserWithSharedPortfolioMenuButtonWrapper>
        {!uiStore.isSideMenuMinimized && !userStore.isEmptySharedSideMenu &&  <DesktopSVGLogo />}
        <Popover onVisibleChange={(visible) => setIsUserDetailsSelected(visible)} visible={isUserDetailsSelected} trigger="click" placement="rightBottom" overlayClassName={'userProfilePopover' + (userStore.isDemoMode ? ' demo' : '')} 
          content={settingsMenu(uiStore.isIncognitoMode)}>
        <UserMenuButtonWrapper className='userMenuUserButtonWrapper' isSelected={isUserDetailsSelected} style={uiStore.isSideMenuMinimized || userStore.isEmptySharedSideMenu ? {marginLeft:'10px'} : {}} >
          <UserButtonSvg />
        </UserMenuButtonWrapper>
        </Popover>
      </UserWithSharedPortfolioMenuButtonWrapper> : 
      <Popover onVisibleChange={(visible) => setIsUserDetailsSelected(visible)} visible={isUserDetailsSelected} trigger="click" placement="rightBottom" overlayClassName={'userProfilePopover' + (userStore.isDemoMode ? ' demo' : '')} 
        content={settingsMenu(uiStore.isIncognitoMode)}>
        <UserProfileWrapper isSideMenuMinimized={uiStore.isSideMenuMinimized && !userStore.isWelcome} isSelected={isUserDetailsSelected} className={ uiStore.isUserMenuActive ? 'active' : ''}>
          <div className="profile-image">{userStore.data.profile?.firstName ? userStore.data.profile?.firstName.charAt(0).toUpperCase() : <DefaultProfileImageSvg />}</div>
          {(!uiStore.isSideMenuMinimized || userStore.isWelcome) && !userStore.isQuestionnaire &&
          <div className="user-details">
            <div className="user-name-text">My account</div>
            {/* <div className="user-name-text">{`${isUserDetailsSelected ? 'My Account' : userStore.data.profile?.firstName}${userStore.data.profile?.lastName && !isUserDetailsSelected ? ` ${userStore.data.profile?.lastName}` : ''}`}</div> */}
            {/* {!isUserDetailsSelected ? <div className="user-email-text">{userStore.data.email}</div> : null} */}
          </div>}
        </UserProfileWrapper>
      </Popover>} 
    </>
    
  )}</Observer>
}

const UserButtonSvg = ({isUserDetailsSelected}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.883 3.36084C9.43772 3.36084 7.47427 5.32396 7.47427 7.76915C7.47427 10.2152 9.43763 12.1784 11.883 12.1784C14.3284 12.1784 16.2918 10.2152 16.2918 7.76915C16.2918 5.32396 14.3283 3.36084 11.883 3.36084ZM9.82226 13.7495C7.44917 13.7495 5.52539 15.6733 5.52539 18.0464C5.52539 19.2123 6.47055 20.1575 7.63647 20.1575H15.889C17.0549 20.1575 18 19.2123 18 18.0464C18 15.6733 16.0763 13.7495 13.7032 13.7495H9.82226Z" fill="white"/>
    </svg>
  )
}

const UserArrowLinkSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9685 8.875C10.9685 8.875 15.234 8.875 17.1662 8.875C17.1662 10.8072 17.1662 15.0727 17.1662 15.0727" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.25 16.7949L16.8958 9.14907" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}

const LinkSelectedIcon = () => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5105 8.604L10.506 15.6125L6.99995 12.1075" stroke="white" strokeWidth="2"/>
    </svg>
  )
}

const DefaultProfileImageSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.04297 7.36845C7.04297 4.67778 9.20354 2.51758 11.8943 2.51758C14.5851 2.51758 16.7457 4.67778 16.7457 7.36845C16.7457 10.06 14.5852 12.2203 11.8943 12.2203C9.20344 12.2203 7.04297 10.06 7.04297 7.36845ZM4.89844 18.6775C4.89844 16.0661 7.01535 13.9492 9.62668 13.9492H13.8972C16.5085 13.9492 18.6254 16.0661 18.6254 18.6775C18.6254 19.9604 17.5854 21.0005 16.3024 21.0005H7.22145C5.93849 21.0005 4.89844 19.9604 4.89844 18.6775Z" fill="white"/>
    </svg>
  )
}