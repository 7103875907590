import { Observer } from 'mobx-react';
import React from 'react'
import { useHistory } from "react-router-dom";
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import { getUrlDisplayName } from '../../../../../common/utils/string.utils';
import { ArrowDownIcon } from '../../../../../assets/icons/common/common-icons';
import { ItemIconWrapper } from '../../../../../common/components/ItemLink/ItemLink.styles';
import { useStore } from '../../../../app/data/root.store';
import { AssetClassIcon } from '../../../../assets/components/AssetClassIcon';
import {
    ChartAssetDataboxWrapperToolTip, LoanPageConnectedAssetsTxtWrraper,
    LoanPageConnectedAssetTitle, LoanPageConnectedAssetCategory, LoanPageConnectedAssetRowAction, ItemLogoWrapper
} from './AssetOverviewHeroContent.styles';

export const LiabilityConnectedAsset = (props) => {
    const { item } = props;
    let history = useHistory();
    const { metadataStore } = useStore();

    const itemClassId = metadataStore.itemsMeta[item.connectedAssets[0].assetId].classId;
    const itemClass = metadataStore.classesObj[itemClassId];
    const itemCategoryId = metadataStore.itemsMeta[item.connectedAssets[0].assetId].categoryId
    const itemCategory = metadataStore.categories.find(category => category.id === itemCategoryId)

    return (<Observer>{() => (
        <ChartAssetDataboxWrapperToolTip onClick={() => navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(itemClass.title)}/${item.connectedAssets[0].assetId}`)}>
            {metadataStore.itemsMeta[item.connectedAssets[0].assetId]?.logoBase64 ?
                <ItemLogoWrapper>
                    <img alt={metadataStore.itemsMeta[item.connectedAssets[0].assetId]?.connectedInstitutionName} src={'data:image/png;base64,' + metadataStore.itemsMeta[item.connectedAssets[0].assetId]?.logoBase64} />
                </ItemLogoWrapper> :
                metadataStore.itemsMeta[item.connectedAssets[0].assetId]?.logoUrl ?
                    <ItemLogoWrapper>
                        <img alt={metadataStore.itemsMeta[item.connectedAssets[0].assetId]?.connectedInstitutionName} src={metadataStore.itemsMeta[item.connectedAssets[0].assetId]?.logoUrl} />
                    </ItemLogoWrapper> :
                    <ItemIconWrapper style={{ marginLeft: '16px' }} color={itemClass.color} >
                        <AssetClassIcon name={itemClass.icon} />
                    </ItemIconWrapper>}
            <LoanPageConnectedAssetsTxtWrraper>
                <LoanPageConnectedAssetTitle >
                    {metadataStore.itemsMeta[item.connectedAssets[0].assetId].title}
                </LoanPageConnectedAssetTitle>
                <LoanPageConnectedAssetCategory>
                    {itemCategory.title}
                </LoanPageConnectedAssetCategory>
            </LoanPageConnectedAssetsTxtWrraper>
            <LoanPageConnectedAssetRowAction>
                <ArrowDownIcon />
            </LoanPageConnectedAssetRowAction>
        </ ChartAssetDataboxWrapperToolTip>
    )}</Observer>)

}