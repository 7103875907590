import styled from '@emotion/styled/macro';
import { shadows, colors, views } from '../../../../common/styles/theme.styles';
import { flexCenter, flexColumn } from '../../../../common/styles/combos.styles';
import { MobileHeader } from '../../../layout/components/MobileLayout.styles';
import { PrimaryButtonWithIcon } from '../../../../common/components/buttons/NewButtons.styles';

export const RolesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${views.desktop}{
        gap: 16px;
    }
`;

export const RolesMobileHeader = styled(MobileHeader)``;

export const HeaderTitle = styled.div`
    font-weight: 450;
    font-size: 18px;
    color: #fff;
`;

export const TitlesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${views.desktop}{
        gap: 10px;
    }
    
`;

export const AltTitle = styled.div`
    font-size: 14px;
    line-height: 17px;
    font-weight:450;
    color:#fff;
    ${views.tablet}{
        color: ${colors.gray2};
        flex:1;
        text-align: center;
    }
`;

export const HeroWrapper = styled.div`
    
    background-color: ${colors.darkHeroBackground};
    /* Card Shadow */
    box-shadow: ${shadows.cardShadow};
    border: 1px solid #DDE3EB;
    border-radius: 8px;
    // padding:62px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    // margin-bottom:16px;
    ${views.desktop}{
        height:227px;
    }
    ${views.tablet}{
        border:none;
        border-radius: 0;
        padding-bottom:24px;
    }
`

export const HeroFlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    ${views.tablet}{
        flex-direction: column;
        gap:24px;
    }
    flex:1;
`;

export const HeroTitle = styled.div`
    font-size: 24px;
    line-height:29px;
    font-weight:500;
    color:#FFFFFF;
    margin:0 0 8px 0;
    ${views.tablet}{
        // color: ${colors.gray2};
        font-size: 24px;
        font-weight: 700;
        flex:1;
        text-align: center;
    }
`;

export const LeftPanelWrapper = styled.div`
    ${flexCenter};
    flex-direction: column;
    flex: 1.2;
    align-items: flex-start;
`;

export const LeftPanelContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 24px 0 56px;
    ${views.tablet}{
        padding: 0 24px;
        width:100%;
    }
`;

export const RightPanelWrapper = styled.div`
    ${flexCenter};
    flex: 0.8;
    
    ${views.desktop}{
        background: url('${props => props.bgImage}') no-repeat center right;
        background-size: cover;
    }
    
    ${views.tablet}{
        padding: 0 12px;
        min-height: min(50vw, 280px);
        background: url('${props => props.mobileBgImage}') no-repeat center center;
        background-size: contain;
    }
`;


export const RolesContentBox = styled.div`
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow:${shadows.cardShadow};
    border-radius: 8px;
    // padding: 24px 24px 12px;
    ${views.tablet}{
        border-radius: 0px;
    }
`;

export const ContentHeaderWrapper = styled.div`
    padding: 24px;
    border-bottom:1px solid #DDE3EB;
    position:relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ContentHeaderTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

export const ContentHeaderActionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:8px;
`;


export const HeaderButton = styled(PrimaryButtonWithIcon)`
    height: 38px;
`

export const HeaderInvisibleButton = styled(HeaderButton)`
    color: ${colors.darkGray2};
    background-color:transparent;
    border:none;
    box-shadow: none;
    font-size: 14px;
    font-weight: 450;
    :hover{
        background-color: ${colors.lightGray2};
    }
    
`;

export const ModalCloseButtonWrapper = styled.div`
    position:absolute;
    right:16px;
    top:16px;
    width:24px;
    height:24px;
    cursor: pointer;
`;

const RolesTableRowSizes = styled.div`
    display: grid;
    grid-template-columns:  minmax(220px,400px) minmax(90px, 180px) minmax(90px, 120px) minmax(120px,180px) 84px;
`

export const RolesTableHeaderRow = styled(RolesTableRowSizes)`
    position:sticky;
    top:-48px;
    padding: 24px 0;
    z-index:1;
    background-color: #fff;
`;

export const RolesTableHeaderCell = styled.div`
    text-align:left;
    font-size:12px;
    font-weight: 450;
    color:${colors.darkGray3};
    padding-left:14px;
    :first-of-type {
        padding-left:24px;
    }
    :last-of-type {
        text-align: right;
        padding-left:0px;
        padding-right:24px;
    }
`;

export const RolesTableCell = styled.div`
    ${flexCenter}
    justify-content: flex-start;
    text-align:left;
    font-size:14px;
    line-height: 14px;
    font-weight: 450;
    color:${colors.darkGray1};
    padding-left:14px;
    :first-of-type {
        padding-left:24px;
    }
    :last-of-type {
        text-align: right;
        padding-left:0px;
        padding-right:24px;
    }
`

export const RolesNameCell = styled(RolesTableCell)`
    gap:8px;
`;


export const RolesNameIconWrapper = styled.div`
    ${flexCenter}
    width: 30px;
    min-width: 30px;
    height:30px;
    border-radius: 10px;
    color:${props=>(props.isDemoRole || props.isOwner) ? '#fff' : colors.darkGray1 };
    background-color:${props=>props.isDemoRole ? '#2565E7' : props.isOwner ? '#111213' : props.color};
`;

export const RolesNameAndMail = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:2px;
    max-width: 100%;
    overflow: hidden;
`;

export const RoleNameText = styled.div`
    
`;
export const RoleNameEmail = styled.div`
    font-size: 12px;
    color:${colors.darkGray3};
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const HoverActionsWrapper = styled(RolesTableCell)`
    opacity: 0;
    gap: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const RolesTableRow = styled(RolesTableRowSizes)`
    top:0;
    padding: 12px 0;
    :hover {
        background-color:${colors.lightGray1};
        ${HoverActionsWrapper} {
            opacity: 1;
        }
    }
    cursor: ${props=> !props.isOwner ? 'pointer' : 'default'};
`;

export const TableActionButtonWrapper = styled.div`
    ${flexCenter}
    cursor: pointer;
    opacity: ${props=>props.disabled ? 0.3 : 1};
`;

export const InviteRowButton = styled.div`
    ${flexCenter};
    justify-content: flex-start;
    gap:8px;
    border:1px dashed ${colors.gray3};
    border-radius: 8px;
    padding:8px 11px;
    margin:8px 12px 24px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 450;
    color:${colors.darkGray2};
    cursor: pointer;
    ${views.desktop}{
        :hover{
            border-color:${colors.darkGray3};
        }
    }
    ${views.tablet}{
        gap: ${props=> props.white ? 8 : 2}px;
        margin: ${props=> props.white ? '0 24px 24px' : '0 24px 16px'};
        border:1px solid ${props=> props.white ? colors.gray3 : colors.darkGray1};
        background-color: ${props=> props.white ? '#fff' : colors.darkGray1};
        box-shadow: ${shadows.toggleShadowMobile};
        justify-content: center;
        padding:16px 24px;
        border-radius: 12px;
        font-size: 16px;
        line-height: 16px;
        height:56px;
        font-weight: 500;
        color: ${props=> props.white ? colors.darkGray2 : '#fff'};
        
    }
`;

export const InvitePlusButtonWrapper = styled.div`
    width: 30px;
    min-width: 30px;
    height:30px;
    border-radius: 10px;
    ${flexCenter}
    background-color: ${colors.lightGray2};
    ${views.tablet} {
        background-color: transparent;
        width: 22px;
        height:22px;
        svg {
            width: 16px;
            height: 16px;
            path {
                stroke:#fff;
            }
        }
    }
`;

// export const InviteButtonText 

export const RolesListWrapper = styled.div`
    ${views.tablet}{
        padding:24px;
        display: flex;
        flex-direction: column;
        gap:16px;
    }
`;

export const MobileRowWrapper = styled.div`
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border:1px solid ${colors.gray3};
    border-radius: 12px;
    ${props=>props.isOpen ? `
        border:none;
        box-shadow: ${shadows.popupShadowMobile};
    ` : ``}
`;

export const MobileRowHeaderWrapper = styled.div`
    ${flexCenter}
    justify-content: flex-start;
    gap:8px;
    padding:16px 24px 16px 16px; 
`;

export const MobileHeaderNameAndInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:2px;
`;

export const MobileHeaderNameText = styled.div`
    font-size: 16px;
    line-height: 16px;
    font-weight: 450;
    color:${colors.darkGray1};
`;
export const MobileHeaderMoreInfo = styled.div`
    font-size: 13px;
    line-height:13px;
    color:${colors.darkGray2};
`;

export const ArrowDownIconWrapper = styled.div`
    width: 28px;
    min-width: 28px;
    height: 28px;
    ${flexCenter};
    border-radius: 50px;
    transition: all 0.4s ease;
    ${props=>props.isOpen ? `
        background-color: #E8E9ED;
        transform: rotate(180deg);
    ` : ``}
    
`;

export const MobileRowContent = styled.div`
    ${flexColumn}
    gap:8px;
    padding-bottom: 16px;
`;

export const MobileRowContentInfoRow = styled.div`
    ${flexCenter}
    justify-content: flex-start;
    gap:4px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 450; 
    padding:0px 16px; 
    .label{    
        color:${colors.darkGray2};
    }
    .info{
        color:${colors.darkGray3};
    }
    
`;

export const MobileContentButtons = styled.div`
    border-top:1px solid ${colors.gray3};
    ${flexCenter};
    height: 58px;
    align-items: stretch;
`

export const MobileContentButton = styled.div`
    flex:1;
    ${flexCenter}
    font-size: 16px;
    font-weight: 450;
    ${props=>props.border ? `
        border-right: 1px solid ${colors.gray3};
    ` : ``}
    color: ${props => props.disabled ? 'rgb(189 189 189)' : '#000'};
`;

export const DrawerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height:100%;
    flex:1;
`;

export const DrawerHeader = styled.div`
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px 2px 24px;
  color: ${colors.darkGray1};
  font-size: 16px;
  font-weight: 450;
  ${props=>props.isDemoRole ? `
    background-color: ${colors.blueAzul};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  ` : ``}
`;

export const EditRoleContentWrapper = styled.div`
    ${flexColumn}
    align-items: center;
    gap:16px;
    padding:16px 20px 32px 20px;
    flex:1;
    overflow-y: auto;
    ${props=>props.withJustifyContentCenter ? `
        justify-content: center;
    ` : ``}
`;

export const EditRoleInputRow = styled.div`
    display: flex;
    align-items: center;
    text-align:left;
    width: 100%;

    &.ant-dropdown-disabled {
        background: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
    }

    .ant-picker, .ant-input {
        outline: none;
        border-radius:8px;
        :hover, :active, :focus {
            box-shadow: none;
            border: 1px solid ${colors.gray3};
        }
    }

    .ant-picker-focused{
        border-color:#7d7d7d!important;
        box-shadow: none!important;
    }
    .ant-input-borderless {
        background-color: #fff;
    }
    ${props=>props.disabled ? `
        background-color: ${colors.lightGray1};
    ` : `
        cursor: pointer;
    `}
`

export const InputWithLabelOnTopWrapper = styled.div`
    position: relative;
    width: 100%;

    .ant-dropdown-trigger {

      .simple_select_button_text {
        padding-top: 18px;
        font-size: 18px;
        color:${colors.darkGray1};
        font-weight:450;
      }
      .select-arrow{
        width:18px;
        height:18px;
        margin-right: 4px; 
      }
      ${props=>props.withError ? `
            border: 1px solid red;
        ` : ``}
    }

    .ant-input{
        padding-top: 18px;
        font-size: 18px;
        :hover{
            border-color: #1A1B1D;
        }
        ${props=>props.withError ? `
            border: 1px solid red;
        ` : ``}

    }

    .ant-picker .ant-picker-input input{
      padding-top: 18px;
      font-size: 18px;
      color:${colors.darkGray1};
      font-weight:450;
    }
    .ant-picker .ant-picker-input .ant-picker-suffix{
      font-size:18px;
    }
`;

export const FloatingLabeInInput = styled.div`
    position:absolute;
    top:6px;
    left:12px;
    font-size: 13px;
    color:${colors.darkGray2};
    font-weight: 450;
    pointer-events: none;
    z-index: 2;
`;

export const PermissionsListWrapper = styled.div`
    ${flexColumn};
    gap:8px;
`;
export const EditRoleLabelText = styled.div`
    font-size: 13px;
    font-weight: 500;
    color:#585D66;
`

export const PermissionTypeBoxWrapper = styled.div`
    ${flexCenter};
    cursor: pointer;
    justify-content: flex-start;
    padding:12px 20px 16px;
    border-radius:8px;
    gap:16px;
    border:1px solid ${props=>props.isSelected ? colors.darkGray1 : colors.gray3 };
    box-shadow: ${props=>props.isSelected ? `none` : shadows.toggleShadowMobile};
`;

export const PermissionSelectButton = styled.div`
    width:16px;
    min-width:16px;
    height:16px;
    border-radius: 50px;
    ${flexCenter};
    border:1px solid ${props=>props.isSelected ? colors.darkGray1 : colors.gray3 };
    ${props=>props.isSelected ? `
        :after{
            content: '';
            width:8px;
            min-width:8px;
            height:8px;
            background-color: ${ colors.darkGray1 };
            border-radius: 50px;
        }
    ` : ``}
    
`;  
export const PermissionText = styled.div`
    ${flexColumn};
    align-items: flex-start;
    .title{
        font-size: 14px;
        font-weight: 500;
        color:${colors.darkGray1};
    }
    .description{
        font-size: 13px;
        font-weight: 450;
        color:${colors.darkGray2};
        white-space: pre-line;
    }
`;

export const DrawerFooter = styled.div`
    border-top: 1px solid ${colors.gray3};
    padding:24px;
    ${flexCenter};
    justify-content: space-between;
    @media (max-width: 420px) {
        flex-direction: column-reverse;
        align-items: stretch;
        gap: 8px;
    }
`;

export const BackButtonWrapper = styled.div`
    display: flex;
    padding: 6px 12px 6px 0px;
    align-items: center;
    cursor: pointer;
`;

export const ConfirmationViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const ConfirmationViewTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
`;

export const ConfirmationViewTitle = styled.div`
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const ConfirmationViewText = styled.div`
    color: ${colors.darkGray1};
    text-align: center;
    font-family: Circular;
    font-size: 13px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    max-width: 378px;
`;

export const ConfirmationViewRow = styled.div`
    display: inline-flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid ${colors.gray3};
    margin-bottom: 32px;
`;

export const ConfirmationViewInfoBulletsContainer = styled.div`
    display: inline-flex;
    padding-top: 10px;
    padding-right: 10px;
    align-items: center;
    background: ${colors.lightGray1};
    color: ${colors.darkGray2};
    font-family: Circular;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const ConfirmationViewRowNameAndMail = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const ConfirmationViewRowNameText = styled.div`
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const ConfirmationViewRowEmail = styled.div`
    color: ${colors.darkGray2};
    font-family: Circular;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const ConfirmationViewRowRoleInfo = styled.div`
    margin-left: 32px;
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const DoneViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
`;

export const DoneViewText = styled.div`
    color: ${colors.darkGray2};
    text-align: center;
    font-family: Circular;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-bottom: 6px;
`;

export const RevokeViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: 12px;
`;

export const RevokeViewText = styled.div`
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 22px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    padding-left: 16px;
`;

export const DemoRoleHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DemoRoleHeaderTitle = styled.div`
    color: #fff;
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const DemoRoleHeaderSubtitle = styled.div`
    color: ${colors.gray3};
    font-family: Circular;
    font-size: 13px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const UpgradeTierToAccessRolesText = styled.div`
    color: ${colors.darkGray2};
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    ${views.tablet}{
        text-align: center;
    }
`;

export const AutoCompleteContainer = styled.div`
    width:100%;
    position: relative;
    .ant-select{
        width: 100%;
        font-size:18px;
    }

    .ant-select-arrow path{
        stroke:#000;
    }
    .ant-select-disabled .ant-select-arrow path{
        stroke: rgba(0, 0, 0, 0.25);
    }

    .ant-select .ant-select-selector{
        border-color:${props=>props.withError ? '#FF7676' : '#d9d9d9'};
    }

    .ant-select-single .ant-select-selector,.ant-select-single .ant-select-selector .ant-select-selection-item{
        height:60px;
        line-height:50px;
        border-radius: 8px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
        line-height:50px;
        margin-top: 10px;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
        border-color:#7d7d7d;
    }
    .ant-select-focused .ant-select-selector{
        border-color:#7d7d7d!important;
        box-shadow: none!important;
    }
    .ant-select .ant-select-selector .ant-select-selection-search > input{
        margin-top: 20px;
    }
    // .ant-select-selection-search {
    //     .input {
    //         margin-top: 10px;
    //     }
    // }
`;