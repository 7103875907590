import { Drawer, Input } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import NumberFormat from 'react-number-format';
import { DeleteIcon, PlusIcon } from '../../../../../assets/icons/common/common-icons';
import { fetchCryptoCoinUsdValue, searchCryptoCoins } from '../../../../../modules/add-item/data/add-item.service';

import { errorNotification, successNotification } from '../../../../../modules/layout/components/Notifications/notifications';
import { displayMoneyValue } from '../../../../utils/number-display.utils';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../../utils/object.utils';
import { LogoLoader } from '../../../LogoLoader/LogoLoader';
import { CryptoHoldingsTableAutoCompleteRow } from './CryptoHoldingsTableAutoCompleteRow';
import { ActionButtonWrapper, ArrowUpSvgWrapper, CloseButtonWrapper, CreateButton, DrawerHeaderTitle, DrawerInputWrapper, HeaderCell, ItemLogoWrapper, ListAndLoaderWrapper, LogoPlaceHolder, QuantitySuffixWrapper, RowActionsCell, RowCell, RowWrapper, SearchInput, SearchInputAndResultsWrapper, SearchTickersMobileHeader, SearchTickersMobileHeaderTitleAndClose, SearchTickersMobileWrapper, SearchingLoaderWrapper, SelectedTickerTextWrapper, SelectedTickerWrapper, TableBottomActions, TableHeaderRow, TableScrollContent, TickerAutoCompleteWrapper, TickerResultsListWrapper, Wrapper } from './CryptoHoldingsTable.styles';
import { defaultCryptos } from './default-cryptos.const';


const tableColumns = ['Coin name', 'Current number of coins'];
const inputSearchDebounce = debounce((cb) => { cb() }, 1000);

export const CryptoHoldingsTable = ({ onChange, value, withError, isDesktopView, currency }) => {
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [shouldFetchPrices, setShouldFetchPrices] = useState(true);

  

useEffect(() => {
  const selected = value.filter(row => !isNullOrUndefined(row.selectedObject))
    .map(row => {
      return row.selectedObject.symbol;
    });

  setSelectedTickers(selected.length ? selected : []);
}, [value]);

useEffect(() => {
  let mounted = true;
  
  const fetchPrices = async () =>{
    const pricesPromises = value.map(row => !isNullOrUndefined(row.selectedObject) ? fetchCryptoCoinUsdValue(row.selectedObject.symbol, currency) : null );
    const pricesResponse = await Promise.allSettled(pricesPromises);
    
    if (mounted) {
      let updatedSortedSchedule = value.map((item, index) =>  ({
        ...item,
        pricePerShare:pricesResponse[index]?.value?.itemCurrencyPrice ,
        usdPrice : pricesResponse[index]?.value?.usdPrice
      }));
      setShouldFetchPrices(false);
      onChange?.(updatedSortedSchedule);
    }
  }
  if (shouldFetchPrices){
    fetchPrices();  
  }
  return () => mounted = false; 

}, [value,currency,onChange,shouldFetchPrices]);
  

  const handleAddRowClick = () => {
    const maxValueOfTempId = Math.max(...value.map(u => u.tempId || 0), 0);
    const newRow = {
      searchStr: '',
      quantity: '',
      selectedObject: null,
      pricePerShare: '',
      tempId: maxValueOfTempId + 1,
    }
    onChange?.([...value, newRow]);
  }

  const handleAttributeRowUpdated = (updatedIndex, data) => {
    let updatedSortedSchedule = value.map((item, index) => (index === updatedIndex ? data : item));
    onChange?.(updatedSortedSchedule);
  }

  const handleAttributeRowDeleted = (deletedIndex) => {
    const updatedSchedule = value.filter((item, index) => index !== deletedIndex);
    onChange?.(updatedSchedule);
  }

  return (
    <Wrapper>
      <TableHeaderRow onClick={(e) => { e.stopPropagation() }}>
        {tableColumns.map((columnLabel, ind) => (
          <HeaderCell key={'hc1' + ind} >
            <span>{columnLabel}</span>
          </HeaderCell>
        ))}
      </TableHeaderRow>
      <TableScrollContent>
        {value?.map((row, index) => (
          <HistoryRow key={row.id || ('t' + row.tempId)}
            index={index}
            item={row} atrKey={row.atrKey}
            isDesktopView={isDesktopView}
            withError={withError}
            currency={currency}
            selectedTickers={selectedTickers}
            rowUpdated={(data) => { handleAttributeRowUpdated(index, data) }}
            rowDeleted={() => handleAttributeRowDeleted(index)} />
        ))}
      </TableScrollContent>
      <TableBottomActions>
        <CreateButton onClick={() => { handleAddRowClick(value) }}>
          <PlusIcon />
          <span className='button-text'>Add ticker</span>
        </CreateButton>
      </TableBottomActions>
      {/* <div style={{ marginTop: 'auto' }}>*You can add historical information, including purchases & sales, cost basis, etc. later.</div> */}
    </Wrapper>
  )
}

const HistoryRow = ({ item, isDesktopView, rowDeleted, withError, rowUpdated, selectedTickers, currency }) => {
  const handleFieldChange = (field, value) => {
    rowUpdated?.({
      ...item,
      [field]: value
    })
  }

  const [isSearchingTickers, setIsSearchingTickers] = useState(false);
  const [tickerSearchResults, setTickerSearchResults] = useState([...defaultCryptos]);
  const [isFocusingInput, setIsFocusingInput] = useState(false);
  const [inputPosition , setInputPosition] = useState({x:0,y:0});
  const [isSelectTickerDrawerOpen,setIsSelectTickerDrawerOpen] = useState(false)

  const listRef = useRef();
  const searchInputRef = useRef();

  const searchForTickers = async (str) => {
    if (str === '') {
      setTickerSearchResults([]);
      setIsSearchingTickers(false);
    } else {
      setIsSearchingTickers(true);
      try {
        const data = await searchCryptoCoins(str);
        setTickerSearchResults([...data.items]);
      }
      //catch (e) {} 
      finally {
        setIsSearchingTickers(false);
      }
    }
  }

  const handleChange = (e) => {
    rowUpdated?.({
      ...item,
      searchStr: e.target.value,
      selectedObject: null,
    })
    setIsSearchingTickers(true);
    inputSearchDebounce(() => {
      searchForTickers(e.target.value);
    })
  }

  const handleFocusIn = () => {
    if (item.searchStr === '') {
      setTickerSearchResults([...defaultCryptos]);
    }
    // get input position for portal
    // console.log(searchInputRef.current);
    setTimeout(() => {
      if (searchInputRef.current){
        const inputPos = searchInputRef.current.getBoundingClientRect();
        // console.log(inputPos);
        setInputPosition({x:inputPos.x,y:inputPos.y + 48})
      }
    }, 0);
    setIsFocusingInput(true);
    setIsSelectTickerDrawerOpen(true);
  }

  const handleBlur = (field) => {
    if (isDesktopView){
      setIsFocusingInput(false);
      setIsSelectTickerDrawerOpen(false);
    }
  }

  const handleCloseDrawer = () => {
      setIsFocusingInput(false);
      setIsSelectTickerDrawerOpen(false);
  }

  const handleTickerClick = async (coin) => {
    let currencyValue;
    try {
      currencyValue = await fetchCryptoCoinUsdValue(coin.symbol, currency);
      successNotification('Successfully fetched usd value');
    } catch (error) {
      errorNotification('Failed fetching usd value');
    }
    rowUpdated?.({
      ...item,
      selectedObject: coin,
      openOnLoad: false,
      pricePerShare: currencyValue.itemCurrencyPrice,
      usdPrice : currencyValue.usdPrice
    })
    setIsFocusingInput(false);
    setIsSelectTickerDrawerOpen(false);
  }
  


  return (
    <RowWrapper onClick={(e) => { e.stopPropagation() }}>
      <RowCell style={{ position: 'relative' }}>
        <TickerAutoCompleteWrapper>
          <SearchInputAndResultsWrapper ref={searchInputRef}>

            <SearchInput type="text"
              className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.searchStr) ? ' inputEntryError' : ''}`}
              style={{ borderRadius: '8px 0 0 8px' }}
              value={item?.searchStr}
              onChange={handleChange}
              onFocus={handleFocusIn}
              autoFocus={isDesktopView && item?.openOnLoad}
              onBlur={handleBlur}
              placeholder='Search crypto coin'
            />

            { ( isFocusingInput || isSelectTickerDrawerOpen) && (tickerSearchResults.length > 0 || isSearchingTickers) &&
              <>
              { isDesktopView && createPortal(
                <ListAndLoaderWrapper left={inputPosition.x} top={inputPosition.y}>
                  <TickerResultsListWrapper ref={listRef}>
                    {tickerSearchResults.map((coin, ind) => {
                      const { symbol } = coin;
                      const isTickerSelected = selectedTickers.includes(`${symbol}`);
                      return <CryptoHoldingsTableAutoCompleteRow key={ind} searchStr={item?.searchStr} coin={coin} isSeparator={coin.isSeparator} handleItemClick={handleTickerClick} isTickerSelected={isTickerSelected} />
                    })}
                  </TickerResultsListWrapper>
                  {isSearchingTickers && <SearchingLoaderWrapper> <LogoLoader wbg /> </SearchingLoaderWrapper>}
                </ListAndLoaderWrapper>,document.getElementById('root')) 
        
                }
                <ArrowUpSvgWrapper><ArrowUpSvg /></ArrowUpSvgWrapper>
              </>
            }

            { !isDesktopView && 
                      <Drawer
                        placement="left"
                        className={'tickersSearchDrawerWrapper_mobile'}
                        width={'100%'}
                        visible={isSelectTickerDrawerOpen}
                        closable={false}
                        onClose={()=>{handleCloseDrawer()}} >
                        <SearchTickersMobileWrapper>
                          {isSelectTickerDrawerOpen && <SearchTickersMobileHeader>
                              <SearchTickersMobileHeaderTitleAndClose>
                              <CloseButtonWrapper onClick={()=>{handleCloseDrawer()}}>
                              <BackArrowIcon />
                          </CloseButtonWrapper>
                                  <DrawerHeaderTitle>Add a Holding</DrawerHeaderTitle>
                                  
      
                              </SearchTickersMobileHeaderTitleAndClose>
      
                                <DrawerInputWrapper >
      
                                <SearchInput type="text"
                    
                    className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.searchStr) ? ' inputEntryError' : ''}`}
                     style={{ borderRadius: '0px 0 0 0px' }}
                    value={item?.searchStr}
                    onChange={handleChange}
                    
                    autoFocus={true}
                    // onBlur={() => setIsFocusingInput(false)}
                    placeholder='Search crypto coin'
                  />
      
                                </DrawerInputWrapper>
                          </SearchTickersMobileHeader>} 
              
                        {/* <TickerResultsListWrapper ref={listRef}> */}
                          {tickerSearchResults.map((coin, ind) => {
                            const { symbol } = coin;
                            const isTickerSelected = selectedTickers.includes(`${symbol}`);
                            return <CryptoHoldingsTableAutoCompleteRow key={ind} searchStr={item?.searchStr} coin={coin} isSeparator={coin.isSeparator} handleItemClick={handleTickerClick} isTickerSelected={isTickerSelected} />
                          })}
                        {/* </TickerResultsListWrapper> */}
                        </SearchTickersMobileWrapper>
                        {isSearchingTickers && <SearchingLoaderWrapper> <LogoLoader wbg /> </SearchingLoaderWrapper>}
                      
                      </Drawer>
            }

            {!isFocusingInput && item?.selectedObject ? <SelectedTickerWrapper>
              <div style={{ paddingRight: '8px' }}>
                {item.selectedObject.imageUrl ? <ItemLogoWrapper><img alt={item.symbol} src={item.selectedObject.imageUrl} /></ItemLogoWrapper> :
                  <LogoPlaceHolder>
                    {(item.selectedObject.name && item.selectedObject.name !== '') ? item.selectedObject.name.charAt(0).toUpperCase() : (item.selectedObject.symbol && item.selectedObject.symbol !== '') ? item.selectedObject.symbol.charAt(0).toUpperCase() : (item.selectedObject.type && item.selectedObject.type !== '') ? item.selectedObject.type.charAt(0).toUpperCase() : ''}
                  </LogoPlaceHolder>
                }
              </div>
              <SelectedTickerTextWrapper>
              {item.selectedObject.coinName} , {item.selectedObject.symbol}
              </SelectedTickerTextWrapper>
            </SelectedTickerWrapper> : null}

          </SearchInputAndResultsWrapper>
        </TickerAutoCompleteWrapper>
        {item?.pricePerShare && !isFocusingInput ?
          <QuantitySuffixWrapper>
            <div>{displayMoneyValue(parseFloat((+item?.pricePerShare).toFixed(2)), currency)}</div>
          </QuantitySuffixWrapper>
          : null}
      </RowCell>
      <RowCell style={{ position: 'relative' }}>
        <NumberFormat
          thousandSeparator={true}
          customInput={Input}
          className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.quantity) ? ' inputEntryError' : ''}`}
          allowNegative={false}
          style={{ borderRadius: '0 8px 8px 0' }}
          value={(isNullOrUndefinedOrEmptyString(item.quantity)) ? '' : parseFloat((+item?.quantity).toFixed(4))}
          
          onValueChange={(values) => { handleFieldChange('quantity', values.floatValue) }}
          placeholder='Quantity'
          required={true}
        />
        {item?.pricePerShare && item.quantity ?
          <QuantitySuffixWrapper>
            <div>{displayMoneyValue(parseFloat((+item.quantity * item?.pricePerShare).toFixed(2)), currency)}</div>
          </QuantitySuffixWrapper>
          : null}
      </RowCell>
      <RowActionsCell>
        <ActionButtonWrapper
          onClick={rowDeleted} >
          <DeleteIcon />
        </ActionButtonWrapper>
      </RowActionsCell>
    </RowWrapper>
  )
}

const ArrowUpSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.9867 13.6807L12.0004 10.3473L16.014 13.6807" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}

const BackArrowIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6667 2.6665L5.33341 7.99984L10.6667 13.3332" stroke="#9097A3" strokeWidth="1.6" />
    </svg>
  )
}