import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
// import { disabledMoreThanToday } from "../../../../../common/utils/dates.utils";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";
import { containerRow } from "../../common-fields/container-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { isBetweenZeroAndHundred, isRequired } from "../../input-validations"

export const createCryptoAddItemSections = (isEditForm) => {
    return ({updateField,updateValue}) => {
        const { crypto } = getTranslations().new_item_modal;
        // const {CASH_INVESTED_LABEL} = getTranslations().common.asset_fields;
        return ([
            {
                title:'General info',
                rows:[
                    ...masterInstitutionInputRows(crypto.NAME_LABEL,crypto.NAME_DESCRIPTION, 9, true, updateValue),
                    {fields:[
                        {key:'title', validations:[isRequired]}
                    ]},
                    beneficiaryRow({}),
                    {fields:[
                        {key:'value', type: 'numberWithHoldings', label: crypto.VALUE_LABEL, description: crypto.VALUE_DESCRIPTION, validations:[isRequired]
                        ,generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                        }
                        ,onBlur:({value})=>{
                            updateValue('cashInvested',value);
                        },
                        },    
                        {key:'currency',size:0.3,
                            onChange:({value})=>{
                                updateField('value',{prefix:CURRENCY_SYMBOL[value] || ''});
                            }
                        }
                        ,
                        {
                            key:'cashInvested', 
                            isHidden:true,
                            defaultValueFn:(values)=>values.value
                        },
                    ]},
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : [
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden:true,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                    onBlur:({value})=>{
                                        updateField('value',{holdings:value || ''});
                                        updateField('cashInvested',{holdings:value || ''});
                                    },
                                    generatePropsFn : (values)=>{
                                        return {isHidden: values.holdings === 100}
                                    }
                                }
                            ]
                    },
                        {
                            fields:[
                                {type:'checkbox', key:'isPartialHolding',checkboxLabel: 'This asset is 100% owned by me', description: 'If you are a co-owner of this account, uncheck this box.' ,
                                fieldCustomStyle: {marginTop:'-24px'},
                                defaultValue: true,
                                    onChange: ({value})=>{
                                        const newHoldingValue = value ? 100 : 50;
                                        updateValue('holdings',newHoldingValue);
                                        updateField('holdings',{isHidden:value});
                                        updateField('value',{holdings:newHoldingValue});
                                        updateField('cashInvested',{holdings:newHoldingValue});
                                    }
                                },
                            ]
                        },
                        ]),
                ]
            },{
                title: 'Projection', 
                rows:[
                    {fields:[
                        {key:'annualIncreaseInValue' , label: crypto.ANNUAL_INCREASE_IN_VALUE_LABEL,
                        description: crypto.ANNUAL_INCREASE_IN_VALUE_DESCRIPTION}
                    ]},
                ]
            },
            // {
            //     title: 'Performance', 
            //     rows:[
            //         {fields:[
            //             {key:'startDate', label: crypto.START_DATE_LABEL, description: crypto.START_DATE_DESCRIPTION, 
            //             validations:[isRequired],
            //             disabledDate: disabledMoreThanToday }
            //         ]},
            //         ...(isEditForm ? [] : [
            //         {fields:[
            //                 {key:'cashInvested', type: 'numberWithHoldings',label: CASH_INVESTED_LABEL, description: crypto.CASH_INVESTED_DESCRIPTION, prefix:CURRENCY_SYMBOL['USD']},
            //                 {key:'cashInvestedCurrency',size:0.3 , label: ' ', defaultValue: 'USD', disabled:false,onChange:({value})=>{
            //                     updateField('cashInvested',{prefix:CURRENCY_SYMBOL[value] || ''});
            //                 }}
            //         ]},
            //         ])
            //     ]
            // },
            {
                title:`More Info`,
                rows:[
                    ...(isEditForm ? [
                        containerRow({isEditForm, updateField}),
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isLocked: true, UnlockComponent:HoldingsUnlockModal,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                // onBlur:({value})=>{
                                //     updateField('monthlyRent',{holdings:value || ''});
                                //     updateField('expectedMonthlyExpenses',{holdings:value || ''});
                                //     updateField('expectedSalePrice',{holdings:value || ''});
                                // },
                                }
                            ]
                        },
                        {
                            fields:[
                                {key:'defaultCashAccountId'}
                            ]
                        },
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {fields:[
                        {key:'remarks'}
                    ]}
                ]
            }
        ])
    }
}
