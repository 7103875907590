import React, { useState } from 'react';
import { CommonBadge } from '../../../../common/components/CommonBadge/CommonBadge';
import { displayMoneyValue } from '../../../../common/utils/number-display.utils';
import { AssetClassIcon } from '../../../assets/components/AssetClassIcon';
import { ItemIconWrapper as AssetItemIconWrapper, CryptoCoinIconPlaceholder, HeaderSearchBoxResultRowWrapper, HighlightedSearchText, ItemContent, ItemLogoWrapper, ItemMoreInfos, ItemText, ItemTitle, ItemTitleHoldingsBadgeText, ItemTitleHoldingsBadgeTriangle, ItemTitleHoldingsBadgeWrapper, ItemValue, MoreInfosItem, StockLogoWrapper, TickerIconPlaceholder } from './HeaderSearchBox.styles';
import { isNullOrUndefinedOrEmptyString } from '../../../../common/utils/object.utils';

export const HeaderSearchBoxResultRow = (props) => {
    const { item, inputValue, showValueInRow = false, isLightTheme = false } = props;

    const hightlightSearchTerm = (title) => {
        if (inputValue !== null && !isNullOrUndefinedOrEmptyString(title)) {
            const index = title.toLowerCase().indexOf(inputValue.toLowerCase());
            if (index !== -1) {
                const length = inputValue.length;
                const prefix = title.substring(0, index);
                const suffix = title.substring(index + length);
                const match = title.substring(index, index + length);
                return (
                    <span>{prefix}<HighlightedSearchText>{match}</HighlightedSearchText>{suffix}</span>
                )
            }
        }
        return title;
    }
    return (
        <HeaderSearchBoxResultRowWrapper isFullWidth={showValueInRow}>
            {
                item.stock ? <StockLogo option={item.stock} /> :
                item.cryptoCoin ? <CryptoCoinLogo option={item.cryptoCoin} /> :
                    item.logoBase64 ? <ItemLogoWrapper><img alt={item.connectedInstitutionName} src={'data:image/png;base64,' + item.logoBase64} /></ItemLogoWrapper> :
                        item.logoUrl ? <ItemLogoWrapper><img alt={item.connectedInstitutionName} src={item.logoUrl} /></ItemLogoWrapper> :
                            <AssetItemIconWrapper color={item.classColor}>
                                <AssetClassIcon name={item.classIcon} />
                            </AssetItemIconWrapper>
            }
            <ItemContent>
                <ItemText>
                    <ItemTitle isLightTheme={isLightTheme}>
                        {hightlightSearchTerm(item.title)}
                        {item.holdings !== 100 && <ItemTitleHoldingsBadgeWrapper><ItemTitleHoldingsBadgeText>{item.holdings}%</ItemTitleHoldingsBadgeText><ItemTitleHoldingsBadgeTriangle /> </ItemTitleHoldingsBadgeWrapper>}
                        {item.isClosed && <CommonBadge badgeType={'closed'} />}
                    </ItemTitle>
                    <ItemMoreInfos>
                        <MoreInfosItem>
                            {item.categoryTitle}
                        </MoreInfosItem>
                        {item.connectedProvider === 'vip' ?
                            <CommonBadge badgeType={'vipService'} title={'VIP'} color={'#DEB482'} />
                            : item.isLoginRequired ?
                                <CommonBadge badgeType={'loginRequired'} />
                                : (item.isConnected ?
                                    <CommonBadge badgeType={'connected'} />
                                    : null)}
                    </ItemMoreInfos>
                </ItemText>
            </ItemContent>
            {showValueInRow ? <ItemValue>{displayMoneyValue(item.value, item.currency)}</ItemValue> : null}
        </HeaderSearchBoxResultRowWrapper>
    )
}

const StockLogo = ({ option }) => {
    const [isCorruptedImage, setIsCorruptedImage] = useState(false);

    return (
        <>
            {option.logo && !isCorruptedImage ? <StockLogoWrapper><img onError={() => { setIsCorruptedImage(true) }} alt={option.connectedInstitutionName} src={option.logo} /></StockLogoWrapper> :
                <TickerIconPlaceholder>{(option.name && option.name !== '') ? option.name.charAt(0).toUpperCase() : (option.symbol && option.symbol !== '') ? option.symbol.charAt(0).toUpperCase() : (option.type && option.type !== '') ? option.type.charAt(0).toUpperCase() : ''}</TickerIconPlaceholder>}
        </>
    )
}

const CryptoCoinLogo = ({ option }) => {
    const [isCorruptedImage, setIsCorruptedImage] = useState(false);

    return (
        <>
            {option.imageUrl && !isCorruptedImage ? <StockLogoWrapper><img onError={() => { setIsCorruptedImage(true) }} alt={option.symbol} src={option.imageUrl} /></StockLogoWrapper> :
                <CryptoCoinIconPlaceholder>{(option.name && option.name !== '') ? option.name.charAt(0).toUpperCase() : (option.symbol && option.symbol !== '') ? option.symbol.charAt(0).toUpperCase() : (option.type && option.type !== '') ? option.type.charAt(0).toUpperCase() : ''}</CryptoCoinIconPlaceholder>}
        </>
    )
}