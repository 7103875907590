import styled from '@emotion/styled/macro';
import { colors, sizes, views } from '../../../common/styles/theme.styles';
import { flexCenter } from '../../../common/styles/combos.styles';

export const OnboardingLayoutWrapper = styled.div`
    ${flexCenter};
    height: 100%;
`;

export const WelcomeBlocker = styled.div`
    position: fixed;
    top:64px;
    bottom:0;
    right:0;
    left:320px;
    // background-color:#fff;
    opacity:${props=>props.isAnimateOut ? '0' : '0.6'};
    transition: opacity 0.7s;
    // overflow-y: scroll;
    // padding:0px 0px 16px;
    // align-items: center;
    
`   

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // background:#F1F3F5;
  // background: linear-gradient(180deg, rgba(241,243,245,1) 0%, rgba(251,252,252,1) 50%, rgba(255,255,255,1) 100%);
  // text-align: center;
  align-items: center;
  color: #000;
  -webkit-overflow-scrolling: touch;
  ${views.tablet}{
    min-height: 100vh;
  }
`


export const MenuAndContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 1;
    // overflow-y: scroll;
    // padding:0px 0px 16px;
    // align-items: center;
    // background-color: #fcfdff;
    position:relative;
    overflow:hidden;
`   

export const OnBoardingHeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    // justify-content: space-between;
    align-items: center;
    // background-color: ${props=> props.displayStyle === 'black' ? '#282d33' : '#FAFAFA'};
    // transition: background-color 0.3s;
    height: ${sizes.headerHeight};
    min-height: ${sizes.headerHeight};
    
    box-shadow: inset 0px -1px 0px #DDE3EB;
    
    justify-content:center;
`   

export const PageContent = styled.div`
    width: 100%;
    // max-width:calc(100% - 320px);
    display: flex;
    flex-direction: column;
    flex: 1 1;
    // justify-content:center;
    // align-items:center;
    overflow-y: auto;
    // padding-right:${props=>props.marginCenter ? '220px' : '0'};
    z-index: 2;
    transition:padding 0.3s;
    /* overflow-y: auto; */
    /* padding:0px 0px 16px; */
    background-color: #fff;
    ${views.tablet}{
        // padding-top: 72px;
        ${props => props.noPaddingBottom ? `` : `padding-bottom:120px;`}
        ${props => props.alignCenter ? `justify-content: center` : ``}
        
    }
`;

const animatePositions = [
    {x:0,sec:0},
    {x:-100,sec:0.4},
    {x:100,sec:0},
    {x:0,sec:0.4},
    {x:100,sec:0.4},
    {x:-100,sec:0},
]

export const OnBoardingTitle = styled.div`
    color: #2A2D33;
    font-size:24px;
    font-weight:500;
    margin-bottom:18px;
    text-align:center;
    margin-top:40px;
`;

export const OnBoardingSubTitle = styled.div`
    color: #2A2D33;
    font-size:16px;
    line-height:19px;
    text-align:center;
    margin-bottom:32px;
    max-width: 500px;
`;


export const OnBoardingBoxWrapper = styled.div`
    background: #FAFBFC;
    border: 1px solid #DDE3EB;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    // background: #FFFFFF;
    // box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
    width:100%;
    padding:24px;
    margin-top:24px;
    margin-bottom:40px;
`

export const AddItemBoxWrapper = styled.div`
   background: #FAFBFC;
    border: 1px solid #DDE3EB;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width:${props=>props.isPlaidAccountsStep ? '720' : '512'}px;;
    padding:${props=>props.isSelectClassStep ? '24' : '0'}px;
    // padding:40px 72px 48px;
    // margin-top:24px;
    margin-bottom:40px;
`;

export const AddItemAnimationWrapper = styled.div`
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    // flex:1;
    transform: translateX(${props=>animatePositions[props.animatePos].x}%); 
    transition: transform ${props=>animatePositions[props.animatePos].sec}s;
`;


export const ClassNavigationPanel = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-around;
    padding:16px; 
`;

export const PrevNextButtonWrapper = styled.div`
    width:200px;
    text-align:${props=>props.isNext? 'right' : 'left'};
`;

export const PrevNextButton = styled.div`
    color:#9D9D9D;
    cursor: pointer;
    padding:16px;
    font-size:16px;
`;

export const SelectClassStepWrapper = styled.div`

`;

export const ShowMoreButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const SelectClassButtonWrapper = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.32), 0px 2px 4px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding:16px;
    cursor: pointer;
    :not(:last-child){
        margin-bottom:16px;
    }
    display:flex;
    // display:${props=>props.show ? 'flex' : 'none'};
    flex-direction:row;
    align-items:center;
    
`;

export const IconWrapper = styled.div`
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color:${props=>props.color};   
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:16px;
    border-radius: 4px;
    svg{
        width:20px;
        height:20px;
    }
    path{
        transition:all 0.2s;
        /* stroke:#4F699D; */
        fill:#2A2D33;
    }
    ${SelectClassButtonWrapper}:hover & {
        path{
            fill:#fff;
        }
    }
`;

export const TextWrapper = styled.div`
    color:#2A2D33;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
`;

export const ClassTitle = styled.div`
    
    margin-bottom:4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
`;

export const ClassDescription = styled.div`
    color:${colors.gray1};
    font-size: 14px;
    line-height: 17px;
`;

export const CompleteButton = styled.div`
    background: #2A2D33;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding:8px 80px;
    font-size:14px;
    color:#fff;
    cursor: pointer;
`;

export const BackToClassesTitle = styled.div`
    font-size:18px;
    cursor: pointer;
    margin-right:24px;
    display: flex;
    align-items: center;
`;