import { Observer } from 'mobx-react';
import React from 'react';
import { ModalCloseIcon } from '../../../../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../../../../common/components/BaseModal/BaseModal';
import { useStore } from '../../../../../../app/data/root.store';
import { ActionButton, ModalCloseButtonWrapper, ModalContent, ModalFooter, ModalHeader, DeleteComponent, DeleteComponentChild, DeleteComponentIcon, DeleteComponentMainText, DeleteComponentSecondaryText, WhiteEraseIcon, BulkDeleteLoadingIcon, BulkEditButtonText } from './BulkEditModal.styles';
import { BulkEditErrorIcon } from '../../icons/icons';
import { EraseIcon } from '../../../../asset/Icons/icons'


export const BulkDeleteModal = (props) => {
  const { onBackDropClick, handleActionClick } = props;
  const { wealthStore } = useStore();

  const handleDeleteItems = () => handleActionClick('bulkDelete');

  const numItems = wealthStore.selectedItemsForBulkEdit.length;
  const pluralSuffix = (1 === numItems) ? '' : 's';
  const message = `Are you sure you want to delete ${numItems} item${pluralSuffix} and all its financial data?`;
  const buttonText = `Delete ${numItems} item${pluralSuffix}`;

  return (<Observer>{() => (
    <BaseModal width={'600px'} height={'auto'} borderRadius={8} onBackDropClick={onBackDropClick}>
      <ModalHeader noBorderBottom>
        <ModalCloseButtonWrapper onClick={onBackDropClick}>
          <ModalCloseIcon />
        </ModalCloseButtonWrapper>
      </ModalHeader>
      <ModalContent padding={"12px 24px"}>
        <DeleteComponent>
          <DeleteComponentChild>
            <DeleteComponentIcon>
              <BulkEditErrorIcon />
            </DeleteComponentIcon>
            <DeleteComponentMainText>{message}</DeleteComponentMainText>
            <DeleteComponentSecondaryText>Please note: This action can’t be undo</DeleteComponentSecondaryText>
          </DeleteComponentChild>
        </DeleteComponent>
      </ModalContent>
      <ModalFooter padding={"12px 24px"}>
        <ActionButton onClick={handleDeleteItems} backgroundColor={"var(--Color-System-Error, #F00)"} whiteText withIcon isDelete>
        {wealthStore.isLoadingDelete ? 
        <BulkDeleteLoadingIcon /> :
        <>
          <WhiteEraseIcon>
            <EraseIcon />
          </WhiteEraseIcon>
          <BulkEditButtonText>
            {buttonText}
          </BulkEditButtonText>
        </>
      }
              
        </ActionButton>
      </ModalFooter>
    </BaseModal>
  )}</Observer>)
}


