import { LoadingOutlined } from '@ant-design/icons';
import { Button, Dropdown } from "antd";
import { Observer } from 'mobx-react';
import moment from 'moment';
import React, { useState } from 'react';
import { ArrowDownIcon, DeleteIcon, InstitutionPlaceholderIcon, ThreeDotsIcon } from '../../../../assets/icons/common/common-icons';
import { CommonBadge } from '../../../../common/components/CommonBadge/CommonBadge';
import { useStore } from '../../../app/data/root.store';
import { SaltedgeLink } from '../saltedge-link/SaltedgeLink';
import { ConnectedInstitutionDeleteModal } from './ConnectedInstitutionDeleteModal';
import { FlexSpace, InstitutionHeader, InstitutionHeaderWrapper, InstitutionIconWrapper, LastSyncPrompt, LogoPlaceHolder, PanelHeaderRightButtonsWrapper, PanelHeaderStatusWrapper, PanelHeaderWrapper, SaltedgeLinkWrapper, SyncIconWrapper, ThreeDotsIconWrapper } from './ConnectedInstitutionsList.styles';
import { CommonActionMenuItem, CommonActionMenuWrapper, DeleteIconContainer } from '../../../../common/styles/menus.styles';
import { RefreshIcon, WebSiteIcon, instSyncTimeAgo } from './institutionPanel.commons';
import { addHttpPrefixIfMissing, cleanURL } from '../../../../common/utils/string.utils';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';

export const SaltEdgeConnectedInstitutionPanelHeaderRow = (props) => {
    const { institution, isOpen } = props;
    const [showConnectedInstitutionDeleteModal, setShowConnectedInstitutionDeleteModal] = useState(false);
    const { connectedInstitutionsStore, userStore, billingStore, metadataStore, uiStore } = useStore();
    const connectedAccountsLength = institution.accounts?.filter(acc => acc.astId && !acc.notFound).length || 0;
    const hasAnyConnectedAccount = connectedAccountsLength > 0;

    const handleUpdateMetadata = () => {
        metadataStore.fetchData();
    }

    const deleteInstitutionAccounts = (deleteType) => {
        return connectedInstitutionsStore.deleteAccounts('saltEdge', institution.id, deleteType, () => {
            handleUpdateMetadata();
        });
    }

    const handleReconnect = (status) => {
        connectedInstitutionsStore.saltedgeReconnectSuccess(institution.id, status);
        connectedInstitutionsStore.fetchSaltedgeAccounts(institution.id, true)
    }

    const handlePanelOnClick = (e) => {
        if (institution.status === 'login-required' && userStore.isDemoOrReadOnlyMode) {
            billingStore.setShowPricingTableModal(true);
            e.stopPropagation();
        }
    }

    const handleDeleteInstitution = (action) => {
        if (action === 'manual') {
            deleteInstitutionAccounts('soft delete');
        }

        else if (action === 'archive') {
            deleteInstitutionAccounts('archive delete');
        }

        else if (action === 'delete' || !hasAnyConnectedAccount) {
            deleteInstitutionAccounts('hard delete');
        }

        handleUpdateMetadata();
        setShowConnectedInstitutionDeleteModal(false);
    }

    return (<Observer>{() => (
        <PanelHeaderWrapper onClick={handlePanelOnClick}>
            <InstitutionIconWrapper>
                {institution.logoBase64 ? <img style={{ width: '28px', height: '28px' }} alt={institution.institutionName} src={'data:image/png;base64,' + institution.logoBase64} /> :
                    institution.logoURL ? <img style={{ width: '28px', height: '28px' }} alt={institution.institutionName} src={institution.logoURL} /> :
                    <LogoPlaceHolder><InstitutionPlaceholderIcon /></LogoPlaceHolder>}
            </InstitutionIconWrapper>
            <InstitutionHeaderWrapper>
                <InstitutionHeader>{institution.institutionName} {institution.accounts?.length ? `(${institution.accounts.length})` : ''}</InstitutionHeader>
            </InstitutionHeaderWrapper>
            {uiStore.isDesktopView && <SaltedgeLinkWrapper>
                {institution.status === 'login-required' && <>
                    <SaltedgeLink reconnectId={institution.institutionId} onSuccess={(data) => { handleReconnect(data.status) }} loadingComp={<Button ><LoadingOutlined /></Button>} isDisabled={userStore.isDemoOrReadOnlyMode}>
                        <CommonBadge isCTA badgeType={'loginRequired'} lastSync={institution.latestPulling} />
                    </SaltedgeLink>
                </>}
            </SaltedgeLinkWrapper> }

            <FlexSpace />
            <PanelHeaderStatusWrapper>
                <CommonBadge badgeType={institution.status === 'login-required' ? 'loginRequired' : 'connected'} lastSync={institution.latestPulling} />
                {institution.isSyncing ? <LastSyncPrompt><SyncIconWrapper><RefreshIcon /></SyncIconWrapper>Syncing...</LastSyncPrompt> : institution.latestPulling && <LastSyncPrompt>Last sync: {instSyncTimeAgo(moment(institution.latestPulling).toDate())}</LastSyncPrompt>}
            </PanelHeaderStatusWrapper>
            <PanelHeaderRightButtonsWrapper>
                <ArrowDownIcon style={{ transition: '0.2s', transform: `rotate(${isOpen ? -180 : 0}deg)` }} />
                <Dropdown overlay={InstitutionActionMenu({
                    institutionId: institution.institutionId,
                    instUrl: institution.instUrl,
                    handleOnInstitutionDelete: () => setShowConnectedInstitutionDeleteModal(true),
                    onUpdateAccountsSuccess: (data) => { connectedInstitutionsStore.fetchSaltedgeAccounts(institution.id, true); },
                    userStore: userStore,
                })}
                    getPopupContainer={() => document.getElementById('syncedAccountsPage')}
                    trigger={['click']} placement='bottomRight'>
                    <ThreeDotsIconWrapper isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
                        <ThreeDotsIcon />
                    </ThreeDotsIconWrapper>
                </Dropdown>
            </PanelHeaderRightButtonsWrapper>


            {showConnectedInstitutionDeleteModal && <div onClick={(e) => e.stopPropagation()}>
                <ConnectedInstitutionDeleteModal
                    onClose={() => setShowConnectedInstitutionDeleteModal(false)}
                    handleDelete={handleDeleteInstitution}
                    connectedAccountsLength={connectedAccountsLength}
                    title={institution.institutionName}
                    institution={institution}
                    isDesktopView={uiStore.isDesktopView}
                    provider={'saltEdge'} />
            </div>}
        </PanelHeaderWrapper>
    )}</Observer>)
}

// function InstitutionActionMenu(props) {
//     return (
//         <InstitutionActionWrapper onClick={(e) => e.stopPropagation()}>
//             <InstitutionActionItem onClick={() => props.handleOnInstitutionDelete()}>
//                 <InstitutionActionItemTitle>Delete</InstitutionActionItemTitle>
//             </InstitutionActionItem>
//         </InstitutionActionWrapper>
//     )
// }

function InstitutionActionMenu({ institutionId, onUpdateAccountsSuccess, instUrl, handleOnInstitutionDelete, userStore }) {

    const handleDeleteInstitution = () => {
        if(!userStore.isActionBlockedByRole('deleteConnectedInstitution')){
            handleOnInstitutionDelete();
        }
    }

    return (
        <CommonActionMenuWrapper onClick={(e) => e.stopPropagation()}>
            <SaltedgeLink isDisabled={userStore.isActionBlockedByRole('authorizeConnectedInstitution')} reconnectId={institutionId} onSuccess={onUpdateAccountsSuccess}
                loadingComp={<CommonActionMenuItem><LoadingOutlined /></CommonActionMenuItem>}
            >
                <CommonActionMenuItem>
                    <RefreshIcon />Missing account? Authorize {userStore.isActionBlockedByRole('authorizeConnectedInstitution') && <ActionLockedIconWithTooltip isRoleBlocked={true} />}
                </CommonActionMenuItem>
            </SaltedgeLink>
            {instUrl &&
                <CommonActionMenuItem>
                    <a href={addHttpPrefixIfMissing(instUrl)} target="_blank" rel="noreferrer">
                        <WebSiteIcon />{cleanURL(instUrl)}
                    </a>
                </CommonActionMenuItem>
            }
            <CommonActionMenuItem color={'red'} onClick={handleDeleteInstitution}>
                <DeleteIconContainer color={'red'}><DeleteIcon /></DeleteIconContainer>Delete {userStore.isActionBlockedByRole('deleteConnectedInstitution') && <ActionLockedIconWithTooltip isRoleBlocked={true} />}
            </CommonActionMenuItem>
        </CommonActionMenuWrapper>
    )
}