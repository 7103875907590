import styled from "@emotion/styled/macro";
import { flexCenter } from "../../styles/combos.styles";
import { colors } from "../../styles/theme.styles";
import { Skeleton } from "antd";

export const CategorySelectorTriggerButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  min-height: 38px;
  background: #FFFFFF;
  box-shadow: 0px 1px 8px rgba(78, 97, 101, 0.1), 0px 0px 1px rgba(78, 97, 101, 0.16);
  border-radius: 8px;
  
  border: 1px solid ${props => props.withError ? 'red' : props.isOpen ? colors.darkGray1 : '#fff'};
`;

export const CategorySelectorActionMenuWrapper = styled.div`
    position: relative;
`;

export const CategorySelectorActionMenuContent = styled.div`
    position: absolute;
    white-space: nowrap;
    border: 1px solid #DDE3EB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
`;

export const CategorySelectorActionMenuItem = styled.div`
    display: flex;
    align-items: center;
    // gap: 8px;
    height: 44px;
    padding: 8px 16px;
    cursor: pointer;
    animation: all 0.2s;
    ${props => props.selectedColor ? `background-color: ${props.selectedColor}` : ``};
    :hover {
      ${props => props.selectedColor ? `` : `background: linear-gradient( 90deg, #000  2px, #F7F8F9 2px)`};
    }
    :not(:first-of-type) {
      border-top: 1px solid ${props => props.isIgnoredMenuItem ? colors.gray2 : colors.lightGray2};
    }
`;

export const CategorySelectorActionMenuItemContentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 6px;
`;

export const CategorySelectorActionMenuItemTitleWrapper = styled.div`
  flex: 1;
  ${props => props.isSelected ? `
  color: #fff;
  font-weight: 500;
  ` : ``};
`;

export const MultipleCategoriesSelectorActionMenuItemWrapper = styled.div`
   display: flex;
   flex-direction: column;
   :not(:first-of-type) {
    border-top: 1px solid ${colors.lightGray2};
   }
`;

export const ArrowDownWrapper = styled.div`
  margin-left: auto;
  transition:transform 0.2s;
  ${flexCenter};
  ${props => props.isOpen ? `transform:rotate(180deg)` : ``};
`;

export const ClassArrowDownWrapper = styled(ArrowDownWrapper)`
  ${props => props.isOpen ? `
  transform:rotate(180deg);
  svg {
    path {
      stroke: #fff;
    }
  }
  ` : ``};
`;

export const CheckMarkSvgWrapper = styled.div`
  margin-left: auto;
  ${flexCenter};
`;

export const CategoryCheckMarkSvgWrapper = styled(CheckMarkSvgWrapper)`
  svg {
    path {
      stroke: ${colors.darkGray1};
    }
  }
`;

export const ClassIconWrapper = styled.div`
    height:26px;
    width:26px;
    min-width: 26px;
    border-radius: 2px;
    background-color:${props => props.bgColor ? props.bgColor : '#82AAFA'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:8px;
    svg{
        width:16px;
        height:16px;
    }
    path{
        fill:#fff;
    }
`;

export const ClassIconWrapperMenuItem = styled(ClassIconWrapper)`
  height:16px;
  width:16px;
  min-width: 16px;
  margin-right:6px;
  background-color: transparent;//${props => props.selectedColor ? props.selectedColor : '#fff'};
  path{
    ${props => props.isIgnored ? `` : `
      fill: ${props.selectedColor ? '#fff' : colors.darkGray2};
    `}
  }
`;

export const IgnoreClassSvgWrapper = styled.div`
  ${flexCenter};
  margin-right: 8px;
`;

export const ClassCategoryTitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ClassTitle = styled.div`
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.withError ? 'red' : colors.darkGray1};
`;

export const CategoryTitle = styled.div`
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.darkGray2};
`;

export const HiddenCategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  background-color: #fff;
`;

export const HiddenCategoryMenuItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  border-radius: 6px;
  padding: 0 16px;
  cursor: pointer;
  :hover {
    ${props => props.isSelected ? `` : `
    ${HiddenCategoryMenuItemTitle} {
      background: ${colors.lightGray1};
    }
  `};
  }
`;

export const HiddenCategoryMenuItemTitle = styled.div`
  display: flex;
  border-radius: 6px;
  padding: 8px 16px;
  flex: 1;
`;

export const CategorySelectorSkeleton = styled(Skeleton)`
  .ant-skeleton-header{
    padding-right: 8px;

    .ant-skeleton-avatar{
      margin: 4px 0;
    }
  }

  .ant-skeleton-title, .ant-skeleton-paragraph {
    margin: 0 !important;
  }

  .ant-skeleton-title{
   height: 14px;
    margin: 2px 0 !important;
  }
  
  .ant-skeleton-paragraph{
    height: 12px;
    
    li{
      height: 100%;
    }
  }
`;