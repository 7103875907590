import styled from "@emotion/styled/macro";
import { colors } from "../../../../../common/styles/theme.styles";

export const ClassTitleText = styled.div`
    color: ${colors.darkGray3};
    width:50%;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical; 
`;

export const PieTextDescription = styled.div`
  color: ${props=>props.color ? props.color : `#fff`};
  font-size: 13px;
  line-height: 143.5%;
`;
