import { Observer } from 'mobx-react'
import React, { useState } from 'react'
import { ArrowDownIcon } from '../../../../../assets/icons/common/common-icons'
import { ActionLockedIconWithTooltip } from '../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip'
import { useStore } from '../../../../app/data/root.store'
import { CardTitle, TitlesWrapper, TransactionsCardHeader } from '../TransactionsPanel.styles'
import { ActionButton, GetFamiliarWithCashflowCardWrapper, GetFamiliarWithCashflowEmptyStateWrapper, GetFamiliarWithCashflowSubtitle } from './EmptyState.styles'

export const GetFamiliarWithCashflowEmptyState = (props) => {
  const { setShowCostOfLivingModal } = props;
  const { createItemStore, cashFlowStore,userStore,billingStore } = useStore();
  const [actions, setActions] = useState([
    { actionCb: () => {
      if(userStore.isDemoOrReadOnlyMode) {
        billingStore.setShowPricingTableModal(true);
      } else {
        cashFlowStore.setEditedScenario({});
        cashFlowStore.setShowScenariosPanel(true);
      }
     }, actionText: 'Add scenario', subTitle: 'Explore how potential income or expenses may affect your cash flow projections.', title: 'Try the Scenarios tool', isOpen: true ,isLockedForDemo : true },
    { actionCb: () => { setShowCostOfLivingModal && setShowCostOfLivingModal(true) }, actionText: 'Household cost of living', subTitle: 'Adding your monthly W-2 income and cost of living expenses can help you project your cash flow more accurately.', title: 'Enter household income and expenses', isOpen: false },
    { actionCb: () => {
      if (userStore.isActionBlockedByRole('cashflowNoDataEventsOrCostOfLivingAddItem')) {
        userStore?.setShowLimitedSharedAccess(true);
      } else if (userStore.isDemoOrReadOnlyMode) {
        billingStore.setShowPricingTableModal(true);
      } else {
      createItemStore.setChooseMethodStep();
      createItemStore.setShowModal(true);} 
    }, actionText: 'Add asset', subTitle: 'Add your passive income, distributions, capital calls and other expected cash events of your assets / liabilities in your portfolio.', title: 'Add income-generating assets', isOpen: false ,isLockedForDemo : true },
    { actionCb: () => {
      if (userStore.isActionBlockedByRole('cashflowNoDataEventsOrCostOfLivingAddItem')) {
        userStore?.setShowLimitedSharedAccess(true);
      } else if (userStore.isDemoOrReadOnlyMode) {
        billingStore.setShowPricingTableModal(true);
      } else {
        createItemStore.setInstitutionStep('bank');
        createItemStore.setShowModal(true);
      }
    }, actionText: 'Sync cash account', subTitle: 'Your cash account balances serve as the baseline for projected cash flow. Sync your account for real-time updates.', title: 'Sync cash account', isOpen: false ,isLockedForDemo : true },
  ])

  const handleCardCollapseClick = (cardIndex) => {
    const alreadyOpenIndex = actions.findIndex(a => a.isOpen);
    if (alreadyOpenIndex === cardIndex) return;
    const updatedActions = actions.map((card, index) => index === cardIndex ? { ...card, isOpen: true } : { ...card, isOpen: false });
    setActions(updatedActions);
  }

  return (<Observer>{() => (
    <GetFamiliarWithCashflowEmptyStateWrapper>
      {actions.map((actionCard, index) => (
        <GetFamiliarWithCashflowCardWrapper onClick={() => { handleCardCollapseClick(index) }} key={index} isCardOpen={actionCard.isOpen}>
          <TransactionsCardHeader isCollapsible>
            <TitlesWrapper>
              <CardTitle>{actionCard.title} <ArrowDownIcon style={{ transition: '0.2s', transform: ` rotate(${actionCard.isOpen ? -180 : 0}deg)` }} /></CardTitle>
              {actionCard.isOpen && <GetFamiliarWithCashflowSubtitle>{actionCard.subTitle}</GetFamiliarWithCashflowSubtitle>}
            </TitlesWrapper>
          </TransactionsCardHeader>
          {actionCard.isOpen && <div style={{ padding: '0 16px 20px' }}><ActionButton onClick={() => { actionCard.actionCb && actionCard.actionCb() }} black withIcon><WhitePlusSvg /> {actionCard.actionText} {actionCard.isLockedForDemo && (userStore.isDemoOrReadOnlyMode || userStore.isActionBlockedByRole('cashflowNoDataEventsOrCostOfLivingAddItem')) ? <ActionLockedIconWithTooltip iconColor={'#fff'} marginTop={'-2px'} isRoleBlocked={userStore.isActionBlockedByRole('cashflowNoDataEventsOrCostOfLivingAddItem')} /> : null}</ActionButton></div>}
        </GetFamiliarWithCashflowCardWrapper>
      ))}
    </GetFamiliarWithCashflowEmptyStateWrapper>
  )}</Observer>)
}

const WhitePlusSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 14L8 2" stroke="white" strokeWidth="1.5" strokeLinejoin="round"/>
      <path d="M14 8H2" stroke="white" strokeWidth="1.5" strokeLinejoin="round"/>
    </svg>
  )
}