import styled from "@emotion/styled";
import { colors, views } from "../../../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../../../common/styles/combos.styles";

export const MobileAssetDocumentsTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // padding: 24px;
  gap: 16px;
  
`;

export const DraggAreaWrapper = styled.div`
  border: 1px dashed ${colors.gray2};
  border-radius: 8px;
  justify-content:center;
  position: relative;
  cursor: pointer;
  display: flex;
  width: calc(100% - 48px);
  padding: 24px 32px 32px 32px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin:24px 24px 0;
`;

export const LoadingLogoWrapper = styled.div`
  padding-top:16px;
`;

export const DragDropPromptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const DragAreaDescription = styled.div`
  color: ${colors.darkGray1};
  text-align: center;
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const ChooseFilesButton = styled.div`
  display: flex;
  height: 48px;
  border-radius: 8px;
  background: ${colors.darkGray1};
  width: 100%;
  max-width: 280px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
`;

export const DocumentsCountRow = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 20px;
  align-items: center;
  gap: 10px;
  color: ${colors.darkGray3};
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const MobileDocumentRowWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 20px 12px 20px;
  align-items: center;
  gap: 8px;
  position: relative;

  ::after {
      content:' ';
      position: absolute;
      left:0;
      right:0;
      bottom:0;
      border-bottom: 1px solid ${colors.lightGray2};
  }
`;

export const DocumentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1;
`;

export const DocumentTitle = styled.div`
  color: ${colors.darkGray1};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  overflow-wrap: anywhere;
  max-width: 100%;
`;

export const DocumentDescription = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  color: ${colors.darkGray3};
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 18px;
`;

export const DocumentActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .ant-dropdown-trigger {
      width: 16px;
      height: 16px;
  }
`;

export const ActionsOverlayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border: 1px solid #DDE3EB;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  white-space: nowrap;
  ${views.tablet}{
    position: relative;
    border:none;
    box-shadow: none;
  }
`;

export const ActionsOverlayContent = styled.div`
  ${views.desktop}{
    position: absolute;
  }
  width: 180px;
  border: 1px solid #DDE3EB;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  top: -28px;
  left: 100%;
  margin-bottom: 12px;
  margin-right: -4px;
`;

export const ActionMenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 44px;
  padding: 8px 16px;
  padding-left: 12px;
  cursor: pointer;
  background-color: #FFFFFF;

  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background: linear-gradient(90deg, #000 2px, #F7F8F9 2px);
  }

  .anticon {
    margin-right: 8px;
  }
  ${views.tablet}{
    :not(:last-of-type){
        border-bottom: 1px solid ${colors.gray3};
    }
  }
`;

export const ActionButtonWrapper = styled.div`
    ${flexCenter};
    cursor: pointer;
`;

export const DrawerHeader = styled.div`
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px 2px 24px;
  color: ${colors.darkGray1};
  font-size: 18px;
  font-weight: 500;
`;

export const DocumentsTableCell = styled.div`
    display: flex;
    align-items: center;
    // padding-inline: 4px;
    text-align:left;
    width: 100%;

    &.ant-dropdown-disabled {
        background: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
    }

    .ant-picker, .ant-input {
        :hover, :active, :focus {
            border: 1px solid ${colors.darkGray1};
        }
    }

    .ant-picker-focused{
        border-color:#7d7d7d!important;
        box-shadow: none!important;
    }
    .ant-input-borderless {
        background-color: #fff;
    }
    ${props=>props.disabled ? `
        background-color: ${colors.lightGray1};
    ` : `
        cursor: pointer;
    `}
`

export const DocumentsTableCellText = styled.div`
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 18px;
    padding-inline: 12px;
    border: 1px solid transparent;
    overflow-wrap: anywhere;
    cursor: default;
`

export const InputWithLabelOnTopWrapper = styled.div`
    position: relative;
    width: 100%;

    .ant-dropdown-trigger {

      .simple_select_button_text {
        padding-top: 18px;
        font-size: 18px;
        color:${colors.darkGray1};
        font-weight:450;
      }
      .select-arrow{
        width:18px;
        height:18px;
        margin-right: 4px; 
      }
    }

    .ant-picker .ant-picker-input input{
      padding-top: 18px;
      font-size: 18px;
      color:${colors.darkGray1};
      font-weight:450;
    }
    .ant-picker .ant-picker-input .ant-picker-suffix{
      font-size:18px;
    }
`;

export const FloatingLabeInInput = styled.div`
    position:absolute;
    top:6px;
    left:12px;
    font-size: 13px;
    color:${colors.darkGray2};
    font-weight: 450;
    pointer-events: none;
    z-index: 2;
`;