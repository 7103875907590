import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useStore } from '../../../app/data/root.store';
import { ShowClosedItemsTextWrapper, ShowClosedItemsWrapper } from '../class/components/ClassPage.styles';
import { CommonBadge } from '../../../../common/components/CommonBadge/CommonBadge';

export const ToggleClosedItemsVisibility = observer(({closedCount}) => {
  const { wealthStore } = useStore();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    if (wealthStore.isShowClosedAssets) {
      wealthStore.removeClosedItemsFromBulkEditSelection();
    }
    wealthStore.setIsShowClosedAssets(!wealthStore.isShowClosedAssets);
  };

  return (
    <ShowClosedItemsWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {isHovered ?
        (wealthStore.isShowClosedAssets ? <HideClosedItemsSvg /> : <ShowClosedItemsSvg />)
        :
        (wealthStore.isShowClosedAssets ? <ShowClosedItemsSvg /> : <HideClosedItemsSvg />)
      }
      <ShowClosedItemsTextWrapper> {wealthStore.isShowClosedAssets ? 'Hide' : 'Show'} closed items ({closedCount}) <CommonBadge badgeType={'closed'} /> </ShowClosedItemsTextWrapper>
    </ShowClosedItemsWrapper>
  )
})

const ShowClosedItemsSvg = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3 10.4154C3 10.4154 5.61167 16.2487 10.5 16.2487C15.3883 16.2487 18 10.4154 18 10.4154C18 10.4154 15.3883 4.58203 10.5 4.58203C5.61167 4.58203 3 10.4154 3 10.4154ZM4.34656 10.4154C4.39675 10.5068 4.45431 10.6087 4.51916 10.7191C4.81412 11.2212 5.25192 11.8864 5.82368 12.5461C6.99037 13.8922 8.56316 15.0487 10.5 15.0487C12.4368 15.0487 14.0096 13.8922 15.1763 12.5461C15.7481 11.8864 16.1859 11.2212 16.4808 10.7191C16.5457 10.6087 16.6032 10.5068 16.6534 10.4154C16.6032 10.3239 16.5457 10.2221 16.4808 10.1117C16.1859 9.60952 15.7481 8.94433 15.1763 8.28464C14.0096 6.93853 12.4368 5.78203 10.5 5.78203C8.56316 5.78203 6.99037 6.93853 5.82368 8.28464C5.25192 8.94433 4.81412 9.60952 4.51916 10.1117C4.45431 10.2221 4.39675 10.3239 4.34656 10.4154ZM4.09443 9.92319C4.09425 9.92281 4.09423 9.92277 4.09437 9.92307L4.09443 9.92319ZM4.09437 10.9077C4.09423 10.908 4.09425 10.9079 4.09443 10.9075L4.09437 10.9077Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13 10.4164C13 11.7971 11.8803 12.916 10.4996 12.916C9.11893 12.916 8 11.7971 8 10.4164C8 9.03495 9.11893 7.91602 10.4996 7.91602C11.8803 7.91602 13 9.03495 13 10.4164Z" stroke="black" strokeWidth="1.2"/>
    </svg>
  )
}

const HideClosedItemsSvg = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4673 5.98046C13.3831 5.17384 12.057 4.58203 10.5 4.58203C5.61167 4.58203 3 10.4154 3 10.4154C3 10.4154 4.35241 13.436 6.93238 15.13L7.7004 14.1974C6.99062 13.7515 6.36366 13.1691 5.82368 12.5461C5.25192 11.8864 4.81412 11.2212 4.51916 10.7191C4.45431 10.6087 4.39675 10.5068 4.34656 10.4154C4.39675 10.3239 4.45431 10.2221 4.51916 10.1117C4.81412 9.60952 5.25192 8.94433 5.82368 8.28464C6.99037 6.93853 8.56316 5.78203 10.5 5.78203C11.7145 5.78203 12.7858 6.23674 13.7036 6.90778L14.4673 5.98046ZM16.2719 7.72182L15.4933 8.66723C15.9123 9.19581 16.243 9.70675 16.4808 10.1117C16.5457 10.2221 16.6032 10.3239 16.6534 10.4154C16.6032 10.5068 16.5457 10.6087 16.4808 10.7191C16.1859 11.2212 15.7481 11.8864 15.1763 12.5461C14.0096 13.8922 12.4368 15.0487 10.5 15.0487C10.4138 15.0487 10.3283 15.0464 10.2436 15.0419L9.34166 16.1371C9.71229 16.2093 10.0985 16.2487 10.5 16.2487C15.3883 16.2487 18 10.4154 18 10.4154C18 10.4154 17.412 9.10211 16.2719 7.72182Z" fill="black"/>
      <line x1="5.94988" y1="16.7845" x2="16.7832" y2="3.84504" stroke="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.5 10.4164C8.5 9.31099 9.39518 8.41602 10.4996 8.41602C11.2071 8.41602 11.8289 8.78325 12.1846 9.33769L12.8466 8.54694C12.2968 7.8576 11.4497 7.41602 10.4996 7.41602C8.84269 7.41602 7.5 8.75891 7.5 10.4164C7.5 11.5564 8.13575 12.5478 9.0721 13.0553L9.73428 12.2644C9.00961 11.964 8.5 11.2498 8.5 10.4164Z" fill="black"/>
    </svg>
  )
}