import React, { useState } from 'react';
import { UnmountClosed } from 'react-collapse';
import { PlaidInstitutionAccounts } from './PlaidInstitutionAccounts';
import { SaltedgeInstitutionAccounts } from './SaltedgeInstitutionAccounts';
import { PlaidConnectedInstitutionPanelHeaderRow } from './PlaidConnectedInstitutionPanelHeaderRow';
import { SaltEdgeConnectedInstitutionPanelHeaderRow } from './SaltEdgeConnectedInstitutionPanelHeaderRow';
import { InstitutionItemContentWrapper, InstitutionItemHeaderWrapper, InstitutionItemWrapper, InstitutionsListWrapper } from './ConnectedInstitutionsList.styles';
import { VipConnectedInstitutionPanelHeaderRow } from './VipConnectedInstitutionPanelHeaderRow';
import { VipInstitutionAccounts } from './VipInstitutionAccounts';
import { AkoyaConnectedInstitutionPanelHeaderRow } from './akoyaConnectedInstitutionPanelHeaderRow';
import { AkoyaInstitutionAccounts } from './AkoyaInstitutionAccounts';

export function ConnectedInstitutionsList(props) {
  const { plaidConnectedInstitutions, saltEdgeInstitutions, akoyaInstitutions, vipInstitutions, onPlaidReconnect, onSaltedgeReconnect, isIncognito, params  } = props;

  return (
    <InstitutionsListWrapper>

      {akoyaInstitutions.map(ci => (
        <InstitutionItem key={'sei' + ci.id}
          isOpenWithParam={params?.provider === 'akoya' && +params?.instId === ci.id}
          header={({ isOpen }) => (<AkoyaConnectedInstitutionPanelHeaderRow institution={ci} onSaltedgeReconnect={onSaltedgeReconnect} isOpen={isOpen} />)}
          content={<AkoyaInstitutionAccounts inst={ci} accounts={ci.accounts} isIncognito={isIncognito} isLoginRequired={ci.status === 'login-required'} />}
        />
      ))}

      {plaidConnectedInstitutions.map(ci => (
        <InstitutionItem key={'pi' + ci.id}
          isOpenWithParam={params?.provider === 'plaid' && +params?.instId === ci.id}
          header={({ isOpen }) => (<PlaidConnectedInstitutionPanelHeaderRow institution={ci} onPlaidReconnect={onPlaidReconnect} isOpen={isOpen}/>)}
          content={<PlaidInstitutionAccounts inst={ci} accounts={ci.accounts} isIncognito={isIncognito} isLoginRequired={ci.status === 'login-required'} />}
        />
      ))}
      {saltEdgeInstitutions.map(ci => (
        <InstitutionItem key={'sei' + ci.id}
          isOpenWithParam={params?.provider === 'saltEdge' && +params?.instId === ci.id}
          header={({ isOpen }) => (<SaltEdgeConnectedInstitutionPanelHeaderRow institution={ci} onSaltedgeReconnect={onSaltedgeReconnect} isOpen={isOpen} />)}
          content={<SaltedgeInstitutionAccounts inst={ci} accounts={ci.accounts} isIncognito={isIncognito} isLoginRequired={ci.status === 'login-required'} />}
        />
      ))}

      {vipInstitutions.map(ci => (
        <InstitutionItem key={'sei' + ci.id}
          isOpenWithParam={params?.provider === 'vip' && +params?.instId === ci.id}
          header={({ isOpen }) => (<VipConnectedInstitutionPanelHeaderRow institution={ci} isOpen={isOpen} />)}
          content={<VipInstitutionAccounts inst={ci} accounts={ci.accounts} isIncognito={isIncognito} isLoginRequired={ci.status === 'login-required'} />}
        />
      ))}


    </InstitutionsListWrapper>)
}

export function InstitutionItem(props) {
  const [isOpen, setIsOpen] = useState(props.isOpenWithParam || false);
  return (
    <InstitutionItemWrapper>
      <InstitutionItemHeaderWrapper isOpen={isOpen} onClick={() => { setIsOpen(!isOpen); props.onOpenClose?.() }}>
        {props.header({ isOpen })}
      </InstitutionItemHeaderWrapper>
      <UnmountClosed isOpened={isOpen}>
        <InstitutionItemContentWrapper>
          {props.content}
        </InstitutionItemContentWrapper>
      </UnmountClosed>
    </InstitutionItemWrapper>
  )
}


