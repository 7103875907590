import { sharesTypeOptionsDefault } from "../../../../../../../common/components/form-displayer/input-types/SharesTradingTable/SharesTradingTable";
import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrZeroOrEmptyString } from "../../../../../../../common/utils/object.utils";
import { isRequired, isRequiredAndNotZero } from "../../../../../../add-item/data/input-validations";
import { eventTypeGenerator } from "./createEventUtils";
import { floatFixed } from "../../../../../../../common/utils/number-display.utils";

const valueUpdateOnly = eventTypeGenerator(false, false, true);
// const contributionOnly = eventTypeGenerator(true);
const distributionOnly = eventTypeGenerator(false, true);
// const createNewLoanOnly = eventTypeGenerator(false, false, true);
// const contributionAndValueUpdate = eventTypeGenerator(true, false, true);
const distributionAndValueUpdate = eventTypeGenerator(false, true, true);
// const newLoanAndValueUpdate = eventTypeGenerator(false, false, true, [newLoanField]);
const itemCreatedValueUpdate = eventTypeGenerator(true, false, true);

export const sharesEventTypeGenerator = (customRows = []) => {
  return ({updateValue}) => ([
    {
      title: '',
      rows: [
        // ...commonRows,
        // ...(withContribution || withDistribution ? [{fields:[
        //     {   
        //         key:'transactionValue', type: 'numberWithHoldings', label: withContribution ? 'Contribution value' : 'Distribution value', 
        //         validations:[isRequiredAndNotZero],
        //         defaultValue:null,
        //         generatePropsFn : (values)=>{
        //             return {
        //                 holdings: values.holdings,
        //                 prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
        //                 placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
        //                 decimalScale : 2, 
        //             }
        //         },
        //         onBlur:({value,formValues})=>{
        //           if (!isNullOrUndefinedOrEmptyString(value) && formValues.pricePerShare){
        //             if(formValues.hasOwnProperty('updatedValue')&& isNullOrUndefinedOrEmptyString(formValues.updatedValue)){
        //                 const newVal = ( +formValues.valueAtDate || +formValues.value ) + ( value * (withContribution ? 1 : -1));
        //                 updateValue('updatedValue', newVal > 0 ? newVal : 0 );
        //             }
        //             if (isNullOrUndefinedOrEmptyString(formValues.newNumberOfShares)) {
        //               updateValue('newNumberOfShares', floatFixed(value / formValues.pricePerShare , 2));
        //             }
        //           }
        //         }
        //     }   
        // ]}] : []),
        ...customRows.map(cr=> typeof cr === 'function' ? cr({updateValue}) : cr),
        {fields:[],type:'separator'},
        {fields: [{key:'eventRemarks', type: 'textarea', label: 'Remarks' , rows:2 , placeholder:'Add notes to remember'}]}
      ]
    }
  ])
}



const pricePerShareField = {
  fields: [
    {
      key: 'pricePerShare', type: 'number', label: 'Price per share',
      generatePropsFn: (values) => {
        return {
          prefix: CURRENCY_SYMBOL[values.currency] || '',
          placeholder: CURRENCY_SYMBOL[values.currency] || '',
        }
      }
    }
  ]
};
const pricePerShareAttr = { fieldKey: 'pricePerShare', dataKey: 'pricePerShare' };

const sharesPurchasedField = {
  fields: [
    { key: 'newSharesPurchased', type: 'number', label: 'New number of shares' }
  ]
};
const sharesPurchasedAttr = { fieldKey: 'newSharesPurchased', dataKey: 'sharesPurchased' };

const exercisePricePerShareField = {
  fields: [
    { key: 'exercisePricePerShare', type: 'number', label: 'New exercise price' }
  ]
};
const exercisePricePerShareAttr = { fieldKey: 'newExercisePricePerShare', dataKey: 'exercisePricePerShare' };

const numberOfSharesField = {
  fields: [
    { key: 'newNumberOfShares', type: 'number', label: 'Number of purchased shares' }
  ]
};
const numberOfSharesAttr = { fieldKey: 'newNumberOfShares', dataKey: 'numberOfShares', setFnForSave: (isDistribution, value) => value * (isDistribution ? -1 : 1), getEditValueFn: ({ value }) => Math.abs(value) };


const sharesTypeField = {
  fields: [
    {
      key: 'newSharesType', type: 'select', label: 'Shares type', fieldCustomStyle: {maxWidth:'100%', overflow:'hidden'}, withCustomOption:true, allowClear: true, options:[], generatePropsFn: (values) => {
        return {
          options: [...new Set([...sharesTypeOptionsDefault, ...(values.sharesTypes ? values?.sharesTypes : [])])].map(type=> ({value: type, label: type}))
        }
      }
    },
  ]
};
const sharesTypeAttr = { fieldKey: 'newSharesType', dataKey: 'sharesType' };

const transactionValueFieldFn = (isContribution) => ({updateValue}) => {
  return {fields:[
    {   
        key:'transactionValue', type: 'numberWithHoldings', label: isContribution ? 'Contribution value' : 'Distribution value', 
        validations:[isRequiredAndNotZero],
        defaultValue:null,
        generatePropsFn : (values)=>{
            return {
                holdings: values.holdings,
                prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                decimalScale : 2, 
            }
        },
        onBlur:({value,formValues})=>{
          if (!isNullOrUndefinedOrEmptyString(value) && formValues.pricePerShare){
            if(formValues.hasOwnProperty('updatedValue')&& isNullOrUndefinedOrEmptyString(formValues.updatedValue)){
                const newVal = ( +formValues.valueAtDate || +formValues.value ) + ( value * (isContribution ? 1 : -1));
                updateValue('updatedValue', newVal > 0 ? newVal : 0 );
            }
            if (isNullOrUndefinedOrEmptyString(formValues.newNumberOfShares)) {
              updateValue('newNumberOfShares', floatFixed(value / formValues.pricePerShare , 2));
            }
          }
        }
    }   
]}
}

const pricePerShareFieldFn = (updateValueBaseOnTotal) => ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'pricePerShare', type: 'number', label: 'Price per share',
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
          }
        },
        onBlur: ({ value, formValues }) => {
          if (updateValueBaseOnTotal && !isNullOrUndefinedOrZeroOrEmptyString(value) && formValues.totalNumberOfShares) {
            updateValue('updatedValue', floatFixed(value * formValues.totalNumberOfShares,2));
          }
        }
          // if (!isNullOrUndefinedOrZeroOrEmptyString(formValues.pricePerShare) && formValues.newNumberOfShares && !formValues.updatedValue) {
          //   updateValue('updatedValue', parseFloat(value * formValues.newNumberOfShares).toFixed(2));
          // }
          // else if (!isNullOrUndefinedOrZeroOrEmptyString(formValues.pricePerShare) && !formValues.newNumberOfShares && formValues.updatedValue) {
          //   updateValue('newNumberOfShares', parseFloat(value * formValues.updatedValue).toFixed(2));
          // }
      }
    ]
  };
}

const numberOfSharesFieldFn = ({ updateValue, isDistribution,label }) => {
  return {
    fields: [
      {
        key: 'newNumberOfShares', type: 'number', label,
        onBlur: ({ value, formValues }) => {
          if (!isNullOrUndefinedOrEmptyString(value) && !isNullOrUndefinedOrEmptyString(formValues.pricePerShare)){
            if(formValues.hasOwnProperty('transactionValue') && isNullOrUndefinedOrEmptyString(formValues.transactionValue)){
              const trnVal =  ( value * formValues.pricePerShare);
              updateValue('transactionValue', trnVal > 0 ? trnVal : 0 );
            }
            if(formValues.hasOwnProperty('updatedValue') ){ // && isNullOrUndefinedOrEmptyString(formValues.updatedValue)){
              // console.log(formValues);
              let newVal = 0;
              if(isDistribution) { // e.g. Sale of shares
                if(isNullOrUndefinedOrEmptyString(formValues?.totalNumberOfShares)) { // Edit event
                  newVal = (+formValues.valueAtDate || +formValues.value) + value * formValues.pricePerShare
                }
                else { // Create event
                  newVal =  ( (formValues?.totalNumberOfShares - value) * formValues.pricePerShare);
                }
              }
              else { // e.g. Buy (Purchase of shares) | Grant
                if(isNullOrUndefinedOrEmptyString(formValues?.totalNumberOfShares)) { // Edit event
                  newVal = value * formValues.pricePerShare + (+formValues.valueAtDate || +formValues.value)
                }
                else { // Create event
                  newVal =  ( (formValues?.totalNumberOfShares + value) * formValues.pricePerShare);
                }
              }
              // const newVal = ( +formValues.valueAtDate || +formValues.value ) + ( (value + formValues?.totalNumberOfShares) * formValues.pricePerShare * (isDistribution ? -1 : 1));
              updateValue('updatedValue', newVal > 0 ? newVal : 0 );
            }
          }
        },
        setFnForSave: (isDistribution, value) => { 
          // console.log(value,isDistribution);
           return value * (isDistribution ? -1 : 1)}
      }
    ]
  }
}

const purchasedSharesFnRow = (label,isContribution) => (props) => {
  return {
    fields: [
      ...numberOfSharesFieldFn({...props,label,isDistribution : !isContribution}).fields,
      ...sharesTypeField.fields
    ]
  }
}

const visibleValueUpdateFn = ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated asset value',
        validations: [isRequired],
        showPrevComp: false,
        // showPrevComp: withContribution || withDistribution,
        // ClickToShowComponent: ({ show }) => (<AddValueUpdatePrevButton onClick={show}><PlusOutlined /> Add Change in value</AddValueUpdatePrevButton>),
        // defaultValueFn: (values)=>{
        //     return values.value ? values.value : ''
        // },
        defaultValue: null,
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            holdings: values.holdings,
            decimalScale: 2,
          }
        },

        onBlur: ({ value, formValues }) => {
          // if (!isNullOrUndefinedOrZeroOrEmptyString(formValues.updatedValue) && formValues.pricePerShare && !formValues.newNumberOfShares) {
          //   updateValue('newNumberOfShares', parseFloat((value / formValues.pricePerShare).toFixed(2)));
          // }
          // else if (!isNullOrUndefinedOrZeroOrEmptyString(formValues.updatedValue) && !formValues.pricePerShare && formValues.newNumberOfShares) {
          //   updateValue('pricePerShare', parseFloat((value / formValues.newNumberOfShares).toFixed(2)));
          // }
        }
      }
    ]
  }
}

const valueUpdateFieldWithDefault = {
  fields: [
    {
      key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated asset value',
      validations: [],
      showPrevComp: false,
      defaultValueFn: (values) => values.value,
      generatePropsFn: (values) => {
        return {
          prefix: CURRENCY_SYMBOL[values.currency] || '',
          placeholder: CURRENCY_SYMBOL[values.currency] || '',
          holdings: values.holdings,
          decimalScale: 2,
        }
      },
    }
  ]
}

export const privateBusinessEquityEvents = [
  { title: 'Purchase of shares', sections: eventTypeGenerator(true, false, true, [pricePerShareField, sharesPurchasedField]), attributesToSend: [pricePerShareAttr, sharesPurchasedAttr] },
  { title: 'Sale of shares', description: 'Cash lent', sections: eventTypeGenerator(false, true, true, [pricePerShareField, sharesPurchasedField]), isDistribution: true, attributesToSend: [pricePerShareAttr, sharesPurchasedAttr] },
  { title: 'Update asset value', description: 'Cash Received', sections: eventTypeGenerator(false, false, true, [pricePerShareField]), attributesToSend: [pricePerShareAttr] },
  { title: 'Dividend', description: 'Cash Received', sections: distributionOnly, isDistribution: true },
  { title: 'Annual dividend', isPredicted: true, sections: distributionAndValueUpdate, isDistribution: true },
  { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false },
]

export const newPrivateBusinessEquityEvents = [
  { title: 'Buy (Purchase of shares)', eventType: 'Purchase of shares', sections: sharesEventTypeGenerator([pricePerShareFieldFn(false), purchasedSharesFnRow('Number of purchased shares',true), transactionValueFieldFn(true), visibleValueUpdateFn]), attributesToSend: [pricePerShareAttr, numberOfSharesAttr, sharesTypeAttr], valuesForEditFromItem: [{dataKey:'sharesTypes', itemDataKey:'sharesTypes'}] },
  { title: 'Sale of shares', description: 'Cash lent', sections: sharesEventTypeGenerator( [pricePerShareFieldFn(false), purchasedSharesFnRow('Number of shares sold',false), transactionValueFieldFn(false), visibleValueUpdateFn]), isDistribution: true, attributesToSend: [pricePerShareAttr, numberOfSharesAttr,sharesTypeAttr], valuesForEditFromItem: [{dataKey:'sharesTypes', itemDataKey:'sharesTypes'}] },
  { title: 'Grant', description: 'Grant', sections: sharesEventTypeGenerator( [pricePerShareFieldFn(false), purchasedSharesFnRow('Number of shares',true),visibleValueUpdateFn]),  attributesToSend: [numberOfSharesAttr,sharesTypeAttr, pricePerShareAttr], valuesForEditFromItem: [{dataKey:'sharesTypes', itemDataKey:'sharesTypes'}] },
  { title: 'Update asset value (PPS)', eventType: 'Update asset value', description: 'Cash Received', sections: sharesEventTypeGenerator( [pricePerShareFieldFn(true), valueUpdateFieldWithDefault]), attributesToSend: [pricePerShareAttr] , valuesForEditFromItem: [{dataKey:'totalNumberOfShares' , itemDataKey: 'totalNumberOfShares'}] },
  { title: 'Dividend', description: 'Cash Received', sections: distributionOnly, isDistribution: true },
  { title: 'Annual dividend', isPredicted: true, sections: distributionAndValueUpdate, isDistribution: true },
  { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: sharesEventTypeGenerator( [pricePerShareFieldFn(false), purchasedSharesFnRow('Number of purchased shares',true), visibleValueUpdateFn]), attributesToSend: [pricePerShareAttr, numberOfSharesAttr, sharesTypeAttr], valuesForEditFromItem: [{dataKey:'sharesTypes', itemDataKey:'sharesTypes'}], deleteEnable: false},
  // { title: 'Item created', isHidden: true, sections: itemCreatedValueUpdate },
]

export const privateBusinessOptionsEvents = [
  { title: `Update company's current pps`, sections: eventTypeGenerator(false, false, true, [pricePerShareField]), attributesToSend: [pricePerShareAttr] },
  { title: 'Options exercised', description: 'Cash lent', sections: eventTypeGenerator(true, false, true, [pricePerShareField, exercisePricePerShareField, numberOfSharesField]), attributesToSend: [pricePerShareAttr, exercisePricePerShareAttr, numberOfSharesAttr] },
  { title: 'More options granted', description: 'Cash Received', sections: eventTypeGenerator(false, false, true, [exercisePricePerShareField, numberOfSharesField]), attributesToSend: [exercisePricePerShareAttr, numberOfSharesAttr] },
  { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false },
]