import { Observer } from 'mobx-react';
import React from 'react';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { CashflowIcon, InstitutionIcon, OwnershipIcon, WelcomeModalArrowIcon, WelcomeModalCommunityIcon } from '../../icons/community-page-icons';
import { ActionButton, ModalBgWrapper, ModalContent, ModalFooter, ModalHeader, ModalPromptRow, ModalPromptWrapper, ModalTitle } from './CommunityWelcomeModal.styles';

export const CommunityWelcomeModal = ({ onClose, isMobile }) => {

  return (<Observer>{() => (
    <BaseModal width={'580px'} height={isMobile ? 'auto' : '376px'} borderRadius={'8'}>
      <ModalBgWrapper>
        <ModalHeader>
          <WelcomeModalCommunityIcon />
          <ModalTitle>Invest smarter with the<br />Vyzer Community</ModalTitle>
        </ModalHeader>
      </ModalBgWrapper>
      <ModalContent>
        <ModalPromptWrapper>
          <ModalPromptRow><CashflowIcon />Find current, accurate strategies from other investors</ModalPromptRow>
          <ModalPromptRow><OwnershipIcon />Learn from high net worth portfolios</ModalPromptRow>
          <ModalPromptRow><InstitutionIcon />View institutions to invest in for every asset class</ModalPromptRow>
        </ModalPromptWrapper>
      </ModalContent>
      <ModalFooter>
        <ActionButton onClick={onClose} black >Let’s go<WelcomeModalArrowIcon /></ActionButton>
      </ModalFooter>
    </BaseModal>
  )}</Observer>)
}
