import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from "react-router-dom";
import { MobileBackButton } from '../../../../assets/icons/common/common-icons';
import { ROUTES } from '../../../../common/constants/routes.const';
import { navigateToUrl } from '../../../../common/utils/navigation.utils';
import { useStore } from '../../../app/data/root.store';
import { AppHeader } from '../../../layout/components/Header/Header';
import { PageWrapper, RightSidePushMenuWrapper } from "../../../layout/components/Layout.styles";
import { MobileBackButtonWrapper } from '../../../layout/components/MobileLayout.styles';
import { CardContentBoxWrapper } from '../../../wealth/components/wealth.commons.styles';
import { DataBoxFormContentWrapper, DataBoxFormWrapper, DataBoxInputWrapper, HeaderTitle, ProfileMobileHeader } from '../Profile.styles';
import { EditHoldingEntity } from './EditHoldingEntity';
import { HoldingEntityEmptyStateRowIconWrapper, HoldingEntityEmptyStateRowTextWrapper, HoldingEntityEmptyStateRowWrapper } from './HoldingEntityListContent.styles';
import { HoldingEntityRow } from './HoldingEntityRow';

export const HoldingEntityListContent = observer(() => {
  const { uiStore, holdingEntityStore, metadataStore } = useStore();

  const handleHoldingEntityEmptyRowClick = () => {
    holdingEntityStore.setShowModal(true);
  }

  return (
    <>
      <CardContentBoxWrapper noBoxShadow borderless style={{ paddingBottom: '16px' }}>
        <DataBoxFormContentWrapper>

          <DataBoxFormWrapper>
            <DataBoxInputWrapper style={{ gap: '8px' }}>

              {metadataStore.sortedBeneficiaries.map((item, index) => {
                return <HoldingEntityRow key={index} id={item.id} item={item} />
              })}

              <HoldingEntityEmptyStateRowWrapper onClick={handleHoldingEntityEmptyRowClick}>
                <HoldingEntityEmptyStateRowIconWrapper><EmptyStatePlusIcon /></HoldingEntityEmptyStateRowIconWrapper>
                <HoldingEntityEmptyStateRowTextWrapper>Create new{uiStore.isDesktopView ? ' holding entity' : ''}... </HoldingEntityEmptyStateRowTextWrapper>
              </HoldingEntityEmptyStateRowWrapper>
            </DataBoxInputWrapper>
          </DataBoxFormWrapper>

          {/* <DataBoxInfoWrapper>
            <InfoBoxWrapper>
              <InfoBoxTitle>
                What are custom classes?
              </InfoBoxTitle>
              <InfoBoxDescription>
                With custom classes and sub-classes, you can organize your portfolio for tailored wealth management. <a target='_blank' rel='noreferrer' href='https://help.vyzer.co/en/articles/9607213-how-to-set-custom-classes-in-vyzer'>Learn more</a>
              </InfoBoxDescription>
            </InfoBoxWrapper>
          </DataBoxInfoWrapper> */}

        </DataBoxFormContentWrapper>
      </CardContentBoxWrapper>

      {(holdingEntityStore.showModal || !uiStore.isDesktopView) &&
        <EditHoldingEntity
          onClose={() => { holdingEntityStore.setShowModal(false); }}
          drawerVisible={holdingEntityStore.showModal}
          onSave={(data) => { holdingEntityStore.createHoldingEntity(data); holdingEntityStore.setShowModal(false); }}
        />}
    </>
  );
})

export const HoldingEntityListMobilePage = observer(({ isWelcome }) => {
  const { uiStore } = useStore();
  let history = useHistory();

  return (<>
    {!isWelcome && <>
      {uiStore.isDesktopView ? <AppHeader /> : <ProfileMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
        <MobileBackButtonWrapper onClick={() => { navigateToUrl(history, ROUTES.preferences.url) }}><MobileBackButton /></MobileBackButtonWrapper>
        <HeaderTitle>{ROUTES.entitiesManagement.title}</HeaderTitle>
      </ProfileMobileHeader>}
    </>}
    <RightSidePushMenuWrapper />
    <PageWrapper withHeader>
      <HoldingEntityListContent />
    </PageWrapper>
  </>
  )
})

const EmptyStatePlusIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 12.25L7 1.75" stroke="#9097A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.25 7H1.75" stroke="#9097A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}