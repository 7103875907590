import { Observer } from 'mobx-react';
import React from 'react';
import { ModalCloseIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { capitalize } from '../../../../common/utils/string.utils';
import { useStore } from '../../../app/data/root.store';
import { GetInTouchDropDown } from '../../../onboarding/components/GetInTouchDropDown/GetInTouchDropDown';
import { ArrowIcon, PaymentRejectedIcon, TagErrorIcon } from './assets/PaymentIcons';
import { LoadingOutlined } from '@ant-design/icons';
import { ModalBannerText, ModalBannerWrapper, ModalButton, ModalClose, ModalContent, ModalFooter, ModalHeader, ModalPromptWrapper, ModalTitle } from './PaymentRejectedModal.styles';

export const PaymentRejectedModal = (props) => {

  const {onBackDropClick} = props;
  const { userStore, billingStore } = useStore();

  const handleUpdatePaymentClick = async () => {
    const paymentLink = await billingStore.fetchBillingUrl();
    userStore.closePaymentRejectedModal();
    window.open(paymentLink.url,"_self");
}

  return (<Observer>{() => (
    <BaseModal width={'480px'} height={'528px'} borderRadius={'8'}>
      <ModalHeader>
        <ModalClose onClick={onBackDropClick}>
          <ModalCloseIcon />
        </ModalClose>
      </ModalHeader>
      <ModalContent>
        <ModalBannerWrapper><TagErrorIcon /><ModalBannerText>Membership payment failed</ModalBannerText></ModalBannerWrapper>
        <ModalTitle>
          {userStore.firstNameCapitalize}, your membership payment<br />
          was unsuccessful
        </ModalTitle>
        <ModalPromptWrapper>
        {/* */}
          Please note that we were not able to process your payment ({capitalize(userStore.data?.profile?.showPaymentRejectedPopup?.type)} #...{userStore.data?.profile?.showPaymentRejectedPopup?.last4Digits}).<br />
          Please update your payment information to maintain full access to your account.
        </ModalPromptWrapper>
        <PaymentRejectedIcon />
      </ModalContent>
      <ModalFooter>
        <ModalButton onClick={() => { handleUpdatePaymentClick() }}>
          Update payment information {billingStore.isLoading ? <LoadingOutlined /> :<ArrowIcon />} 
        </ModalButton>
        <GetInTouchDropDown />
        {/* <ContactUsLink onClick={() => { alert('link to contact') }}>Any question? Contact us</ContactUsLink> */}
      </ModalFooter>
    </BaseModal>
  )}</Observer>)

}