// import debounce from 'lodash/debounce';
import { Observer } from 'mobx-react';

import React , {useState} from 'react';
import { displayMoneyFormatter, displayMoneyValue, numberWithCommas } from '../../../../common/utils/number-display.utils';
import { useStore } from '../../../app/data/root.store';
import { AssetClassIcon } from '../../../assets/components/AssetClassIcon';
import { HoldingValue, InstitutionBreakdownHoverEffectWrapper, ItemContent, ItemIconWrapper, ItemLogoWrapper, ItemMoreInfos, ItemSignWrapper, ItemText, ItemTitle, ItemValue, ListItemWrapper, SelectedInstitutionRowWrapper, TableCell, TotalInvestmentTitle, TotalInvestmentWrapper, ValueRemarks } from '../../../wealth/pages/class/components/ClassPage.styles';
import { InstitutionLogoPlaceHolder, MobileSelectedInstitutionRowWrapper, SelectedInstitutionBreakdownMobileWrapper } from '../CommunityPage.styles';
import {ReactComponent as InstitutionPlaceholderIcon} from '../../../../common/components/form-displayer/input-types/InstitutionAutoComplete/icons/institution-placeholder-icon.svg';
import { colors } from '../../../../common/styles/theme.styles';

// const scrollBottomDebounce = debounce((cb) => { cb() }, 200);

export const CommunityInstitutionsListView = (props) => {
  //const [mobileSelectedIndex, setMobileSelectedIndex] = useState(-1);
  const {
    // wealthStore,
    communityStore,
    uiStore
  } = useStore();

  // const history = useHistory();
  // const communityPageContentElement = document.querySelector('#communityPageContent');

  // useEffect(() => {
  //   communityStore.fetchInstitutions();

  //   return () => {
  //     communityStore.setCursor(25);
  //   }
  // }, [communityStore])

  // const handleAssetClick = (item) => {
  //   navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(item.categoryClass.title)}/${item.id}`);
  // }

  // useEffect(() => {
  //   if (communityPageContentElement) {
  //     communityPageContentElement.addEventListener('scroll', () => {
  //       const { scrollTop, scrollHeight, clientHeight } = communityPageContentElement;
  //       if (scrollTop + clientHeight === scrollHeight) {
  //         scrollBottomDebounce(() => {
  //           communityStore.callNext()
  //           // alert('fetch more!')
  //           // checkForLoadingMore();
  //         })
  //       }
  //     })
  //   }
  //   // return () => { }
  // }, [communityPageContentElement, communityStore])

  // console.log(wealthStore.itemsList.filter(i => i.isConnected));
  return (<Observer>{() => (
    <>
    <div style={{height:'0' , overflow:'hidden', display:'none'}}>{communityStore.itemsSortDirection}</div>
    <div  style={{height:'0' , overflow:'hidden', display:'none'}}>{communityStore.itemsSortBy}</div>
      {        
        communityStore.sortedInstitutions.map((item,index) => (
            <InstitutionRow isMobileSelected={communityStore.mobileSelectedInstitutionIndex===index} setMobileSelectedIndex={(ind)=>{communityStore.setMobileSelectedInstitutionIndex(ind)}} isDesktopView={uiStore.isDesktopView } index={index} key={index} item={item} isBeforeLast={index===communityStore.sortedInstitutions.length - 2} isClassView={communityStore.isClassView} ab={props.ab}/>
          ))}
          {/* {communityStore.hasMoreInstitutions ? <InstitutionsLoadMoreSkeletonSvg /> : null} */}
    </>
  )}</Observer>)
}

const InstitutionRow = ({item , isBeforeLast, isClassView , isDesktopView, index, isMobileSelected, setMobileSelectedIndex, ab}) => {
  const [isHoveredOn, setIsHoveredOn] = useState(false);
  
  return (
    <>    <ListItemWrapper notClickable matricesColumns={isDesktopView ? 1 : 0} borderBottomColor={isBeforeLast ? colors.gray3 : null } onClick={()=>{setMobileSelectedIndex(isMobileSelected ? -1 : index); ab()}} >
              <TableCell>
                <ItemSignWrapper onMouseEnter={()=>{setIsHoveredOn(true)}} onMouseLeave={()=>{setIsHoveredOn(false)}}>
                  {item.logoBase64 ? <ItemLogoWrapper><img alt={item.connectedInstitutionName} src={'data:image/png;base64,' + item.logoBase64} /></ItemLogoWrapper> :
                    item.logoURL ? <ItemLogoWrapper><img alt={item.connectedInstitutionName} src={item.logoURL} /></ItemLogoWrapper> :
                    <InstitutionLogoPlaceHolder><InstitutionPlaceholderIcon /></InstitutionLogoPlaceHolder>
                  }
                  {isDesktopView && isHoveredOn && !(item.title === 'Other' && isClassView ) && <InstitutionBreakdownHoverEffect item={item} />} 
                </ItemSignWrapper>

                <ItemContent>
                  <ItemText onMouseEnter={()=>{setIsHoveredOn(true)}} onMouseLeave={()=>{setIsHoveredOn(false)}}>
                    <ItemTitle>
                      {item.title}
                    </ItemTitle>
                    { item.title === "Other" &&
                    <ItemMoreInfos>Personal investments / small institutions</ItemMoreInfos>}
                  </ItemText>
                </ItemContent>

              </TableCell>
{/* 
              {[
                { dataKey: 'communityTotalInvestments', title: '# Investments', subAttributes: [], type: 'text', path: 'totalInvestments', },
                // { dataKey: 'communityValueChangeOverWeek', title: 'Value change', subAttributes: [], type: 'percentage', path: 'valueChangeOverWeek', },
              ].map(dl => (
                <TableCell key={dl.dataKey}>
                  {RenderDataValue(dl, item, isIncognito)}
                </TableCell>
              ))} */}

              {isDesktopView && <TableCell>
                  {numberWithCommas(item.numberOfInvestments)}
              </TableCell>}

              <TableCell isValue>


                <ItemValue>
                  <HoldingValue>{displayMoneyValue(item.totalValue, 'USD')}</HoldingValue>
                  {!isDesktopView && <ValueRemarks>({numberWithCommas(item.numberOfInvestments)} items)</ValueRemarks>}
                </ItemValue>
              </TableCell>
            </ListItemWrapper>
            {!isDesktopView && isMobileSelected && <SelectedInstitutionBreakdownMobile item={item} />}
            </>

  )
}


const InstitutionBreakdownHoverEffect = (props) => {
  const { item } = props;
  return (
    <InstitutionBreakdownHoverEffectWrapper>
      <SelectedInstitutionRowWrapper>
        {item.logoBase64 ? <ItemLogoWrapper><img alt={item.connectedInstitutionName} src={'data:image/png;base64,' + item.logoBase64} /></ItemLogoWrapper> :
          item.logoURL ? <ItemLogoWrapper><img alt={item.connectedInstitutionName} src={item.logoURL} /></ItemLogoWrapper> :
          <InstitutionLogoPlaceHolder><InstitutionPlaceholderIcon /></InstitutionLogoPlaceHolder>
        }
        <ItemContent>
          <ItemText >
            <ItemTitle>
              {item.title}
            </ItemTitle>
          </ItemText>
        </ItemContent>
        <TotalInvestmentWrapper>
          <TotalInvestmentTitle>Total investment value</TotalInvestmentTitle>
          <div>{displayMoneyFormatter(item.totalClassesValue ? item.totalClassesValue : item.totalValue, 'USD')}</div>
        </TotalInvestmentWrapper>
      </SelectedInstitutionRowWrapper>
      {        
        item.classes.map((assetClass, ind) => (
          <SelectedInstitutionRowWrapper key={ind}>
                <ItemIconWrapper color={assetClass.color}>
                  <AssetClassIcon name={assetClass.icon} />
                </ItemIconWrapper>
            
            <ItemContent>
              <ItemText>
                <ItemTitle>
                  {assetClass.title}
                </ItemTitle>
              </ItemText>
            </ItemContent>
            <div>{displayMoneyFormatter(assetClass.value, 'USD')}</div>
          </SelectedInstitutionRowWrapper>
        ))}
    </InstitutionBreakdownHoverEffectWrapper>
  )
}

const SelectedInstitutionBreakdownMobile = (props) => {
  const { item } = props;
  return (
    <SelectedInstitutionBreakdownMobileWrapper>
      {        
        item.classes.map((assetClass, ind) => (
          <MobileSelectedInstitutionRowWrapper key={ind}>
                <ItemIconWrapper color={assetClass.color}>
                  <AssetClassIcon name={assetClass.icon} />
                </ItemIconWrapper>
            
            <ItemContent>
              <ItemText>
                <ItemTitle>
                  {assetClass.title}
                </ItemTitle>
              </ItemText>
            </ItemContent>
            <div>{displayMoneyFormatter(assetClass.value, 'USD')}</div>
          </MobileSelectedInstitutionRowWrapper>
        ))}
    </SelectedInstitutionBreakdownMobileWrapper>
  )
}