import React from 'react';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { ModalWrapper, HeaderWrapper, ExportModalTitle, CloseBtnWrapper, ModalContentWrapper, ActionButtonsWrapper } from './ExportAllDataModal.styles'
import { PrimaryButtonWithIcon, SecondaryButtonBorderless } from '../../../../common/components/buttons/NewButtons.styles';
import { ReactComponent as CloseXIcon } from './asstes/close-x-icon.svg';
import { DownloadIcon } from '../../../../assets/icons/common/common-icons';
import { handleUrlFileDownload } from '../../../../common/utils/network.utils';
import * as moment from 'moment';
import { errorNotification } from '../../../layout/components/Notifications/notifications';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;


export function ExportAllDataModal(props) {
    
    const { onClose , isMobileAppView } = props;
    const handleExportDownload = async () => {
        if ( isMobileAppView ) {
            if (window.ReactNativeWebView){
                window.ReactNativeWebView.postMessage(JSON.stringify({ 
                action:'download_file_api_request' , 
                data : {
                    url:`${baseApiUrl}reports/data-export`,
                    body:{
                        dataExportType: "default"
                    } ,
                    fileName : `vyzer_export_${moment().format('L')}.xlsx`
                } 
                }));
            }
        } else {
            try {
                await handleUrlFileDownload({
                    url: `${baseApiUrl}reports/data-export`,
                    body: {dataExportType: "default"},
                    fileName: `vyzer_export_${moment().format('L')}.xlsx`
                });
                onClose()
            } catch(e) {
                errorNotification(e.message)
            }
        }
    }
    return (

        <BaseModal onBackDropClick={onClose} width={'494px'} height={'auto'} borderRadius={4}  >
            <ModalWrapper>
                <HeaderWrapper>
                    <ExportModalTitle>Export all data</ExportModalTitle>
                    <CloseBtnWrapper onClick={onClose}>
                        <CloseXIcon />
                    </CloseBtnWrapper>
                </HeaderWrapper>
                <ModalContentWrapper>You are about to export all of your account into an excel file.</ModalContentWrapper>
                <ActionButtonsWrapper>
                    <SecondaryButtonBorderless onClick={() => onClose()}>Cancel</SecondaryButtonBorderless>
                    <PrimaryButtonWithIcon onClick={handleExportDownload}><DownloadIcon />Yes, export all</PrimaryButtonWithIcon>
                </ActionButtonsWrapper>
            </ModalWrapper>
        </BaseModal>
    )


}