import styled from '@emotion/styled/macro';
import { flexCenter, flexVerticalCenter, 
    // ScrollPanelWithShadows
 } from '../../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../../common/styles/theme.styles';



export const DrawerOrPanelInnerContentWrapper = styled.div`
    height:100%;
    flex:1;
    display:flex;
    flex-direction:column;
    ${views.desktop}{
        overflow-y:hidden;
    }
    
`;


export const DatePickerWrapper = styled.div`
    min-height:44px;
    ${props=>props.isSmallScreen ? 'padding: 16px 16px 0 16px' : ''};
`;

export const BottomActionsWrapper = styled.div`
    ${props=>props.isSmallScreen ? 'padding: 0px 16px 16px 16px' : 'padding-bottom:16px'};
    
    /* ${props=>props.isSmallScreen ? `
        position: absolute;
        bottom: 20px;
        width: 100%;
        padding: 0 16px;
    ` : ``}; */
`;

export const TransactionsContentWrapper = styled.div`
    flex:1;
    padding: ${props=>props.isSmallScreen ? '12px 16px' : '12px 0'};
    gap:12px;
    display:flex;
    flex-direction:column;
    ${views.desktop}{
        overflow-y: hidden;
    }
`;

export const ExpectedTrnsCardWrapper = styled.div`
    /* flex:1; */
    display:flex;
    flex-direction:column;
    /* max-height: 70%; */
    
    ${views.desktop}{
        overflow-y: hidden;
    }
    background: #FDFDFD;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
`;

export const ActualTrnsCardWrapper = styled.div`
    display:flex;
    flex-direction:column;
    ${views.desktop}{
        overflow-y: hidden;
        min-height: ${props=>props.isCardOpen ? `40%` : `58px`};
    }
    
    /* max-height:40%;     */

    background: #FDFDFD;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
`;

export const CardContent = styled.div`
    ${views.desktop}{
        overflow-y:auto;
    }
   
`

export const TransactionsPanelWrapper = styled.div`
    
    display:flex;
    flex-direction: column;
    transition:left 0.2s;
    /* width:340px; */
    /* width: 100%; */
    // background-color:blue;
    z-index:1;
    text-align: left;
    max-height: 100%;
    height: 100%;
    ${props=>props.isSmallScreen ? `padding:16px;` : ``}
    overflow: hidden;
    /* padding:16px 24px;
    background-color: #f1f3f5;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 5%); */
`;

export const TransactionsPanelBigScreenWrapper = styled.div`
    // position: fixed;
    display:flex;
    flex-direction: column;
    transition:right 0.2s;
    /* width:340px; */
    max-width: 400px;
    min-width: 200px;
    flex:1;
    // width: calc((100% - 864px) /2);
    margin-left: 16px;
    top:${props=>props.isWithBanner ? '178' : '125'}px;
    /* top:136px; */
    bottom:40px;
    right: ${props=>props.show ? 'calc(50% + 416px)' : '105%'};
    // left:${props=>props.show ? '0' : '-464'}px;
    // background-color:blue;
    z-index:1;
    text-align: left;
    
`;

export const TransactionsPanelContentBox = styled.div`
    /* background-color:${colors.lightGray1}; */
    /* box-shadow:${shadows.cardShadow}; */
    border-radius: 8px;
    flex: 1;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const HeaderWrapper = styled.div`
    background: #f7f8f9;
    border-radius:8px 8px 0 0;
    padding: 16px;
    position:relative;
    border-bottom:1px solid #DDE3EB;
`;

export const CloseButtonWrapper = styled.div`
    position:absolute;
    top:16px;
    right:10px;
    position:absolute;
    cursor: pointer;
    transform: rotate(180deg);
    svg{
        width:22px;
    }
`;

export const HeaderTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
`;

export const EventsCounts = styled.span`
    font-size:12px;
    color:#9097A3;
`;

export const HeaderStats = styled.div`
    display:flex;
    margin-top:8px;
`
export const HeaderCountBox = styled.div`
    display:flex;
    flex-direction:column;
    margin-right: ${props=>props.cashIn ? '16px' : colors.darkGray1};
`;

export const CountBoxTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${colors.darkGray1};
    flex: 1;
`;

export const CashBox = styled.div`
    font-weight: 500;
    font-size: 14px;
    display:flex;
    align-items:center;
    color: ${colors.darkGray1};
    ${props=>props.cashIn ? `
      color: ${colors.inflow2};
      margin-right: 16px;
    ` : ``};
`;

export const CashCircle = styled.div`
    width:10px;
    height:10px;
    background-color:${props=>props.cashIn ? '#49AE9C' : '#EB8787'};
    border-radius:50%;
    margin-right:8px;
`;

// export const ListWrapper = styled(ScrollPanelWithShadows)`
export const ListWrapper = styled.div`
    // padding: 8px 0 4px 0;
    
    padding:8px 0;
    flex:1;
    opacity: ${props => props.isAnimate ? 0 : 1};
    transition: opacity 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
    ${views.desktop}{
        overflow-y: auto;
    }

`

export const TransactionItemWrapper = styled.div`
    display:flex;
    align-items:center;
    padding: 8px 24px;
    :not(:last-of-type) {
        border-bottom: 1px solid ${colors.lightGray2};
    }
`;

export const TransactionsDetails = styled.div`
    flex:1;
    display:flex;
    align-items:center;
    overflow: hidden;
`;

export const AccountIcon = styled.div``;

export const ItemIconWrapper = styled.div`
    // margin-left:24px;
    height:28px;
    width:28px;
    min-width:28px;
    min-height:28px;
    border-radius: 50%;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    svg{
        width:18px;
        height:18px;
    }
    path{
        // transition:all 0.2s;
        /* stroke:#4F699D; */
        
        ${props=>props.isSimulation ? `` : `fill: #fff;`}
    }
`;


export const TransactionAndAssetDetails = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    flex: 1;
    overflow: hidden;
`;

export const ItemTitleWrapper = styled.div`
    display:flex;
    ${flexCenter};
    max-width: 100%;
    flex:1;
    width: 100%;
    justify-content: flex-start;
`;

export const ItemTitle = styled.div`
    font-size:12px;
    line-height: 14px;
    color:${colors.darkGray1};
    ${flexVerticalCenter};
`;

export const ItemTitleLink = styled.div`
    font-size:12px;
    line-height: 14px;
    color:${colors.darkGray1};
    cursor: pointer;
    position:relative;
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    ::after{
        content:'';
        position:absolute;
        width:0%;
        height:1px;
        left:50%;
        bottom:0;
        background-color:black;
        transition:all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
    }
    &:hover{
        ::after{
            width:100%;
            left:0%;
        }
        // color:#2299ff;
    }
`;

export const HoldingsBadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    margin-left:4px;
    margin-right:12px;
`;

export const HoldingsBadgeText = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.assetHoldingsBadgeBgColor};
    color:${colors.assetHoldingsBadgeTextColor};
    padding: 1px 0px 1px 4px;
    border-radius: 2px 0px 0px 2px;
`;

export const HoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid ${colors.assetHoldingsBadgeBgColor};
`;

export const SimulationBadge = styled.div`
    background: #E9F0FD;
    border-radius: 2px;
    padding:4px 8px;
    margin-left:8px;
    color:#2565E7;
    font-size:10px;
    line-height:12px;
    font-weight:600;
    position: relative;
    display:flex;
    align-items:center;
    ::before{
        content: '';
        //position:absolute;
        /* left:2px;
        top:2px; */
        width:8px;
        height:8px;
        display:block;
        background-color:#2565E7;
        border-radius:50%;
        margin-right:4px;
    }    
`;

export const ItemDescription = styled.div`
    font-size:12px;
    color:${colors.darkGray3};
`;

export const TransactionItemCashBox= styled.div`
    font-size: 14px;
    font-weight: 500;
    color:#1A1B1D;
    display:flex;
    align-items:center;
    color: ${props=>props.cashIn ? '#6EBA7F' : colors.darkGray1};
`;

export const TransactionItemPredictedAmount = styled.div`
    ${flexCenter};
    font-size: 12px;
    color: ${colors.darkGray3};
`;

export const ClockIconWrapper = styled.div`
    ${flexCenter};
    width: 11px;
    height: 11px;
    svg {
        width: 11px;
        height: 11px;
        circle {
            stroke: ${colors.darkGray3};
        }
        path {
            stroke: ${colors.darkGray3};
        }
    }
`;

export const TransactionItemPredictedAmountWrapper = styled.div`
    ${flexCenter};
    gap: 2px;
`;

export const TransactionItemCashCircle = styled.div`
    width:8px;
    height:8px;
    background-color:${props=>props.cashIn ? '#49AE9C' : '#EB8787'};
    border-radius:50%;
    margin-right:8px;
`;

export const TransactionApprovedWrapper = styled.div`
    width:14px;
    height:14px;
    background-color:black;
    border-radius:2px;
    ${flexCenter}; 
    // margin-right:-4px;
    margin-left:4px;
`;

export const GroupListWrapper = styled.div`
    padding: 0 0 4px 0;
`

export const AccountTransactionsGroup = styled.div`
    margin-bottom: 8px;
    &:not(:last-child){
        border-bottom: 1px solid #e0e0e0;
    }
`;

export const DatapointTransactionsListItem = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    font-size:14px;
    padding: 4px 0;
    &:not(:last-child){
        border-bottom: 1px solid #e0e0e0;
    }
`;

// export const ItemDescription = styled.div`
//     max-width:240px;
//     padding-right:16px;
// `;

export const ItemValue = styled.div`
    padding:2px;
    background-color:${props=>props.isPositive ? '#E4FDE6' : '#FDE4E4'};
    color:${props=>props.isPositive ? '#3AD249' : '#EF6262'};
`;

export const GroupDate = styled.div`
    color: #808080;
`

export const TransactionsMonthPickerWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.darkGray1};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    /* width: fit-content; */
    position: relative;
    /* padding: 4px 0; */
    .ant-picker{
        ${flexCenter};
        flex: 1;
        /* width: 128px; */
        padding:5px 6px 6px 8px;
        background-color: #000;
        border: 1px solid #000;
        border-radius: 8px;
        .ant-picker-suffix {
            color: #fff;
        }
        .ant-picker-input {
            width: fit-content;
            ${views.tablet}{
                flex-direction: row-reverse;
                gap: 8px;
                flex:1;
            }
        }
    }
    .ant-picker:hover, .ant-picker-focused {
        border: 1px solid #000;
        box-shadow: none;
    }
    .ant-picker-input > input {
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }

 
`;

export const PreviousMonthButtonWrapper = styled.button`
    ${flexCenter};
    padding: 14px;
    background-color: #000;
    border-right: 1px solid ${colors.darkGray2};
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-radius: 8px 0 0 8px;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    svg{
        transform: rotate(90deg);
        width: 16px;
        height: 16px;
        path{
            fill:${props=>props.disabled ? colors.darkGray2 : `#fff`};
        }
    }
`;

export const NextMonthButtonWrapper = styled(PreviousMonthButtonWrapper)`
    border-right: none;
    border-radius: 0 8px 8px 0;
    border-left: 1px solid ${colors.darkGray2};
    svg{
        transform: rotate(-90deg);
        width: 16px;
        height: 16px;
        path{
            fill:${props=>props.disabled ? colors.darkGray2 : `#fff`};
        }
    }
`;

export const TransactionsStatusIconWrapper = styled.div`
    ${flexCenter};
    padding: 4px;
    background: ${props=>props.bgColor ? props.bgColor : '#fff'};
    width: 22px;
    height: 22px;
    border-radius: 4px;
    margin-left: 8px;
    svg {
        width: 14px;
        height: 14px;
    }
    ${props=>props.isSimulation ? `` : props.hasHappened ? `
    svg {
        path {
            stroke: #2565E7;
        }
    }
    ` : `
    svg{
        circle{
            stroke: #E48A04;
        }
        path{
            stroke: #E48A04;
        }
    }`}
`;

export const EmptyActualEventsStateWrapper = styled.div`
    ${flexCenter};
    padding: 24px 0;
    color: ${colors.darkGray3};
`;

export const CreateNewAssetButton = styled.div`
    ${flexCenter};
    padding: 6px 0;
    gap: 8px;
    min-height: 56px;

    background: #FDFDFD;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
`;

export const EventRowLinkedTransactionIconWrapper = styled.div`
    width:15px;
    min-width:15px;
    height:15px;
    background-color: ${props => props.isSuggested ? '#738EF1' : props.isDistribution ? colors.inflow2 : colors.darkGray2  };
    border-radius:50%;
    display:flex;
    margin-left: 4px;
    ${flexCenter};
    svg {
        width:12px;
        height:12px;
        path {
            fill: #ffffff;
        }
    }
`;

export const ActualTransactionItemRowWrapper = styled.div`
    display:flex;
    align-items:center;
    padding: 8px 24px;
    :not(:last-of-type) {
        border-bottom: 1px solid ${colors.lightGray2};
    }
`;

export const ExpectedTransactionItemRowWrapper = styled.div`
    display:flex;
    align-items:center;
    padding: 8px 24px;
    :not(:last-of-type) {
        border-bottom: 1px solid ${colors.lightGray2};
    }
`;

export const TransactionsCardHeader = styled.div`
  ${flexCenter};
  width: 100%;
  padding: 20px 16px;
  ${props=>props.isOpen ? `border-bottom: 1px solid ${colors.gray3};` : ``};
  ${props=>props.isCollapsible || props.isCentered ? `cursor: pointer` : ``};
`;

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: ${props=>props.isCentered ? 'center' : 'space-between'};
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${colors.darkGray1};
  flex: 1;
`;

export const CardSubtitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.darkGray2};
`;