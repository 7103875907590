import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../common/styles/combos.styles";
import { colors, shadows, views } from "../../../common/styles/theme.styles";
import { keyframes } from "@emotion/react";
import { ReactComponent as CloseIcon } from './DeepAnalysisSuccessModal/assets/close-icon.svg'

export const SectionWrapper = styled.div`
    display:flex;
    // padding:24px 240px 16px 240px;
    // padding:24px 16px;
    /* padding:${props => props.padding ? props.padding : '24px'}; */
    padding: 24px 0;
    flex-direction:column;
    width:100%;
    /* max-width: ${props => props.fullWidth ? '100%' : '512px'}; */
    /* margin: 0 auto; */
    width: 100%;
    // background-color:${props => props.index % 2 === 1 ? '#fbfcfc' : '#fff'};
    /* ${props => props.horizntalSections ? `
        flex-direction:row;
        &:not(:last-child){
            border-bottom: 1px solid #E5E5E5;
        }
    ` : `
        flex-direction:column;
    `} */
        
    ${views.tablet}{
        ${props => props.withTopBorder ? `border-top: 1px solid ${colors.gray3};` : ``}
    }

`;

export const FileDropAreaWrapper = styled.div`
    position: relative;
    ${flexCenter};
    flex-direction: column;
    width: 100%;
    background-color: ${props => props.isHoverActive ? colors.lightGray1 : 'transparent'};
    border: 1px dashed ${colors.darkGray3};
    border-radius: 12px;
    ${props=> props.showPointer ? `cursor:pointer;` : ``}
    ${views.tablet}{
        border-radius: 0px;
        border:none;
        height: auto;
        padding:${props => !props.isActivationFlow ? '24px 0' : '40px 0 24px'};
        background-color: #fff;
    }
`;

export const FileDropAreaBody = styled.div`
    ${flexCenter};
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    gap: 16px;
    padding-bottom: 80px;
    ${({ isWhite }) => isWhite ? `background: var(--Main-Colors-White, #FFF);` : ``}
    border-radius: inherit;
 `;

export const FileDropAreaFailedWrapper = styled(FileDropAreaWrapper)`
    border: 1px solid ${colors.gray3};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
`;

export const FileDropArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    ${views.tablet}{
        width: 80%;
        gap: 12px;
    }
`;

export const PromptWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

export const AltTextPrompt = styled.div`
    color: ${colors.darkGray3};
    font-size: 14px;
    font-weight: 450;
    line-height: 130%;
    text-align: center;
`;

export const MainTextPrompt = styled(AltTextPrompt)`
    color: ${colors.darkGray1};
    font-size: 16px;
    font-weight: 500;
`;

export const LighterMainTextPrompt = styled(MainTextPrompt)`
    font-weight: 450;
    font-size: 14px;
    padding-top: 4px;
`;

export const WrongFileContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

export const ActionsWrapper = styled.div`
    ${flexCenter};
    justify-content: space-between;
    width: 100%;
    padding: 16px 24px;
`;

export const ActionButton = styled.div`
    ${flexCenter};
    gap: 8px;
    background: ${colors.darkGray1};
    width: ${props => props.width ? props.width : 'auto'};
    box-shadow: ${shadows.tagsShadow};
    border-radius: 8px;
    padding:${props => props.withIcon ? '8px 40px 8px 32px' : '8px 40px'};
    font-size:16px;
    font-weight: 450;
    color:#fff;
    cursor: pointer;
    .anticon{
        padding:0 8px 0 0;
    }
    ${props => props.disabled ? `opacity:0.6;cursor:default` : ``}
    svg{
        width: 24px;
        height: 24px;
    }
`;

export const ActionLinkButton = styled.a`
    ${flexCenter};
    gap: 8px;
    width: ${props => props.width ? props.width : 'auto'};
    box-shadow: ${shadows.tagsShadow};
    border-radius: 8px;
    padding:${props => props.withIcon ? '8px 40px 8px 32px' : '8px 40px'};
    font-size:16px;
    font-weight: 450;
    color: ${colors.darkGray1};
    :hover {
        color: ${colors.darkGray1};
    }
    cursor: pointer;
    .anticon{
        padding:0 8px 0 0;
    }
    ${props => props.disabled ? `opacity:0.6;cursor:default` : ``}
    svg{
        width: 24px;
        height: 24px;
    }
`

export const PlusIconWrapper = styled.div`
    width: 16px;
    height: 16px;
    svg{
        width: 16px;
        height: 16px;
        path{
            stroke: #fff;
        }
    }
`;

export const IllustrationIconWrapper = styled.div`
    // padding-bottom:8px;
`;

export const UploadAnimatedBarWrapper = styled.div`
    background-color: ${colors.gray3};
    height: 2px;
    position: relative;
    width: 100%;
`;

const progressBar = keyframes`
    0% { left:0; width: 0; }
    50% { left:0; width: 100% }
    100% { left:100%; width: 0 }
`;

export const UploadAnimatedBar = styled.div`
    background-color: ${colors.darkGray1};
    height: 2px;
    width: 100%;
    position: absolute;
    /* transition : width 5s ease; */
    animation: ${progressBar} 5s ease-in-out infinite;
`;

export const IllustrationIconTextTopRow = styled.div`
    color: ${colors.darkGray1};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    ${views.tablet}{
        font-weight: 450;
        text-align:center;
    }
`;

export const IllustrationIconTextSecondRow = styled.div`
    color: ${colors.darkGray1};
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    ${views.tablet}{
        color:${colors.darkGray2};
        text-align: center;
    }
`;

export const ImportPersonalBalanceWrapper = styled.div`
    display: inline-flex;
    height: 64px;
    padding: 12px 24px 14px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 39px;
    border: 1px solid ${colors.gray3};
    background: #FFF;
    box-shadow: ${shadows.cardShadow}
`;

export const ActivationFlowFilesWrapper = styled.div`
    padding-top: ${props => props.withPadding ? '32' : '0'}px;
`;

export const DocumentRow = styled.div`
    ${flexCenter};
    justify-content: ${props => props.spaceBetween ? 'space-between' : 'flex-start'};
    width: 100%;

    ${props => props.isActivation ? `
        justify-content: space-between;
    ` : ``}

    ${props => props.withModal ? `
        :first-of-type {
            border-top: 1px solid ${colors.gray3};
        }
        border-bottom: 1px solid ${colors.gray3};
        padding: 16px;
        width: 380px;
    ` : `
        padding: 8px 0;
    `};
    
`;

export const DeleteIconWrapper = styled.div`
    cursor: pointer;
    padding: 4px 4px 4px 0px;
    svg {
        path {
            stroke: #000 //#FF947C;
        }
    }
    ${flexCenter};
`;

export const DocumentStatusBadge = styled.div`
    display: flex;
    justify-content: flex-end;
    background-color: ${props => props.status ? props.status === 'Done' ? '#72E1AC' : props.status === 'Rejected' ? '#FF947C' : null : null};
    color: ${props => props.status && (props.status === 'Pending' || props.status === 'Processing') ? null : '#fff'};
    box-shadow: 0px 1.23288px 2.46575px rgba(255, 255, 255, 0.04), 0px 0px 1.23288px rgba(255, 255, 255, 0.16);
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    padding: ${props => props.status && (props.status === 'Pending' || props.status === 'Processing') ? '4px 8px 0' : '4px 8px'} 4px 8px;
    border-radius: 4px;
    margin-bottom:4px;
    gap: 2px;
    flex-shrink: 0;
`;

export const DocumentStatusIconWrapper = styled.div`
    ${flexCenter};
    svg{
        width: 16px;
        height: 16px;
    }
`;

export const ActivationFlowFilesHeaderRow = styled.div`
    display: flex;
    height: 24px;
    padding: 0px 48px 8px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: ${colors.darkGray3};
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const MagicboxAIModalHeaderTextWrapper = styled.div`
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const MagicboxAIModalHeaderModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px 12px 24px;
`;

export const MagicboxAIModalHeaderCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

export const MagicboxAIModalHeaderModalTitle = styled.div`
  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const MagicBoxAiPageDropAreaSkipButton = styled.div`
    display: flex;
    width: 408px;
    height: 56px;
    padding: 12px 8px 14px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    // margin-bottom: 53px;
`;

export const MagicBoxAiPageDropAreaSkipButtonText = styled.div`
    color: var(--Dark-Gray-1, #1A1B1D);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    text-decoration-line: underline;
`;

export const MagicBoxAiPageButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
`;