
export const defaultCryptos = [
    {
        coinName: "Bitcoin",
        externalId: "1182",
        fullName: "Bitcoin (BTC)",
        id: 7621,
        imageUrl: "https://logo.twelvedata.com/crypto/btc.png",
        name: "BTC/USD",
        symbol: "BTC",
    },
    {
        coinName: "Ethereum",
        dataAvailableFrom: null,
        externalId: "7605",
        fullName: "Ethereum (ETH)",
        id: 7697,
        imageUrl: "https://logo.twelvedata.com/crypto/eth.png",
        name: "ETH/USD",
        symbol: "ETH",
    },
    {
        coinName: "Binance Coin",
        dataAvailableFrom: null,
        externalId: "204788",
        fullName: "Binance Coin (BNB)",
        id: 7952,
        imageUrl: "https://logo.twelvedata.com/crypto/bnb.png",
        name: "BNB/USD",
        symbol: "BNB",
    },
    {
        coinName: "Buff Doge Coin",
        dataAvailableFrom: null,
        externalId: "949406",
        fullName: "Buff Doge Coin (DOGECOIN)",
        id: 7113,
        imageUrl: "https://logo.twelvedata.com/crypto/doge.png",
        name: "DOGE/USD",
        symbol: "DOGE",
    },
    {
        coinName: "USD Coin",
        dataAvailableFrom: null,
        externalId: "925809",
        fullName: "USD Coin (USDC)",
        id: 2482,
        imageUrl: "https://logo.twelvedata.com/crypto/usdc.png",
        name: "USDC/USD",
        symbol: "USDC",
    },
];
  