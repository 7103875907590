import * as moment from "moment";
import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
// import { floatFixed } from "../../../../../../../common/utils/number-display.utils";
// import { calcMonthLeft } from "../../../../../../../common/utils/liabilities.utils";
import { isNullOrUndefinedOrEmptyString } from "../../../../../../../common/utils/object.utils";
import { isRequiredAndNotZero } from "../../../../../../add-item/data/input-validations";
import { PredictedAmountDisplay, createLabelFnWithPredictedValue } from "../../Events/PredictedAmountDisplay/PredictedAmountDisplay";
// import { eventTypeGenerator } from "./createEventUtils";

// const valueUpdateOnly = eventTypeGenerator(false, false, true);
// const contributionOnly = eventTypeGenerator(true);
// const distributionOnly = eventTypeGenerator(false, true);
// const createNewLoanOnly = eventTypeGenerator(false, false, true);
// const contributionAndValueUpdate = eventTypeGenerator(true, false, true);
// const distributionAndValueUpdate = eventTypeGenerator(false, true, true);
// const newLoanAndValueUpdate = eventTypeGenerator(false, false, true, [newLoanField]);


export const lifeInsuranceEventTypeGenerator = (customRows = []) => {
  return ({ updateValue , updateField }) => ([
    {
      title: '',
      rows: [
        ...customRows.map(cr => typeof cr === 'function' ? cr({ updateValue, updateField }) : cr),
        { fields: [], type: 'separator' },
        { fields: [{ key: 'eventRemarks', type: 'textarea', label: 'Notes', rows: 2, placeholder: 'Add notes to remember' }] }
      ]
    }
  ])
}

const cashValueUpdateRow = (showOnlyIfReinvested) => {
  return {
    fields: [
      {key:'updatedValue', type: 'numberWithHoldings', label: 'Updated cash value' ,
      validations: [],
      generatePropsFn : (values)=>{
          return {
              prefix: CURRENCY_SYMBOL[values.currency] || '',
              placeholder:CURRENCY_SYMBOL[values.currency] || '',
              holdings: values.holdings,
              decimalScale : 2, 
              isHidden: showOnlyIfReinvested && values.isDistributionReinvested === false,
              skipValidation: showOnlyIfReinvested && values.isDistributionReinvested === false,
          }
      }
      }
    ]
  }
}

const deathBenefitRow = (showOnlyIfReinvested) => {
  return{
    fields: [
      {
        key: 'deathBenefit', type: 'number',  label: 'Updated death benefits',validations: [],
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            isHidden: showOnlyIfReinvested && values.isDistributionReinvested === false,
            skipValidation: showOnlyIfReinvested && values.isDistributionReinvested === false,
          }
        },
      },
    ]
  }
};
const deathBenefitAttr = {
  fieldKey: 'deathBenefit',
  dataKey: 'deathBenefit',
  // getEditValueFn: ({ value }) => floatFixed(value * 100, 3),
  // setFnForSave: (isDistribution, value) => (value / 100)
};

const deathBenefitAttrForCreate = {dataKey: 'deathBenefit' , itemDataKey:'deathBenefit' , getEditValueFn: ({ value }) => null };

const premiumPaymentRowFn = ({updateValue}) => {
  return {
    fields: [
      {
        key: 'basePayment', type: 'number', label: 'Base payment',
        description: <div>The total annual premium can be divided into two main components: the obligatory <b>Base</b> premium and any <b>Paid Up Additions</b> (PUAs). Your insurance company provides this exact breakdown in the policy illustration, as you defined in the ‘Asset info’ tab.</div>,
        size: 0.5,
        decimalScale: 2,
        generatePropsFn: (values) => {
          const labelFn = ()=>{
            return <div style={{display:'flex'}}>
                {'Base payment'}
                {values.predictedAttributes?.basePayment ?  
                    <PredictedAmountDisplay 
                        predictedAmount={values.predictedAttributes?.basePayment} 
                        marginLeft={8}
                        tooltipText={`The expected base amount (obligation)`} /> 
                : null}
            </div>
          } 
          return {
            label:labelFn,
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            // label: `Interest rate (${floatFixed(values.interestPayment * 12 / values.valueAtDate * 100, 3)}%)`
          }
        },
        onBlur: ({ value, formValues }) => {
          if ( !formValues.isTransactionLinked) {
            const trnValue = ( value || 0 ) + ( formValues.puaPayment || 0);
            updateValue('transactionValue', trnValue);
          }
        },
        validations: [{
          fn: (value, formValues) => { return validateTransactionValueWithBaseAndPua(formValues) },
          message:' ',
        }],
        validateFieldsOnChange: ['validationBasePua','puaPayment'],
        showValidationErrorOnBlur: true,
        validateFieldsOnBlur: ['validationBasePua','puaPayment'],
      },
      {
        key: 'puaPayment', type: 'number', label: 'PUA payment',
        size: 0.5,
        decimalScale: 2,
        generatePropsFn: (values) => {
          const labelFn = ()=>{
            return <div style={{display:'flex'}}>
                PUA payment
                {values.predictedAttributes?.puaPayment ?  
                    <PredictedAmountDisplay 
                        predictedAmount={values.predictedAttributes?.puaPayment} 
                        marginLeft={8}
                        tooltipText={`The expected PUA amount (optional)`} /> 
                : null}
            </div>
          } 
          return {
            label:labelFn,
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            disabled: values.isConnected && moment(values.eventDate).isAfter(moment(), 'M'),
          }
        },
        onBlur: ({ value, formValues }) => {
          if ( !formValues.isTransactionLinked) {
            const trnValue = ( value || 0 ) + ( formValues.basePayment || 0);
            updateValue('transactionValue', trnValue);
          }
        },
        validations: [{
          fn: (value, formValues) => { return isNullOrUndefinedOrEmptyString(value) || validateTransactionValueWithBaseAndPua(formValues) },
          message:' ',
        }],
        validateFieldsOnChange: ['validationBasePua','basePayment'],
        showValidationErrorOnBlur: true,
        validateFieldsOnBlur: ['validationBasePua','basePayment'],
      }
    ]
  }
};

const premiumPaymentValidationRowFn = ({updateValue,updateField}) => {
  return {
        fields:[
          {key : 'validationBasePua' ,  type : 'validation' , fieldCustomStyle: {marginTop:'-24px'},
          // showValidationErrorOnChange: true,
          validations: [
            {
              fn: (value, formValues) => {
                return validateTransactionValueWithBaseAndPua(formValues); // formValues.isTransactionLinked && formValues.isDistributionOfCapital ? Math.trunc(formValues.onCapital + formValues.ofCapital) === Math.trunc(formValues.transactionValue) : true;
              },
              bottomErrorMessage: (value, formValues) => {
                return `Invalid amount. The sum of 'Base' and 'Pua' payments must be equal to the ${formValues.isTransactionLinked ? 'Linked transaction' : 'Total distribution'} amount.`;
              },
            },
          ], } , 
          
          {key: 'isTransactionLinked' , isHidden:true,
            onChange: ({value,formValues})=>{
              updateField('transactionValue',{disabled:value })
              // updateField('transactionValue', {isHidden: value})
              // updateField('isDistributionOfCapital', {fieldCustomStyle: {marginTop: value ? '' : '-24px'},})
              // if (formValues.isDistributionOfCapital){
              //   updateField('transactionValue',{disabled:true })
              //   if (value) {
              //     updateValue('ofCapital', formValues.transactionValue)
              //     updateValue('onCapital', null)
              //     updateValue('updatedValue', +formValues.valueAtDate - formValues.transactionValue)
              //   }
              // }
            },
            validateFieldsOnChange: ['validationBasePua'],
          }
        ]
  }
};

const basePaymentAttr = { fieldKey: 'basePayment', dataKey: 'basePayment' };

const puaPaymentAttr = { fieldKey: 'puaPayment', dataKey: 'puaPayment' };


const transactionValueFieldFn = () => ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'transactionValue', type: 'numberWithHoldings', description: 'This is the total amount paid in this transaction.',
        validations: [isRequiredAndNotZero],
        defaultValue: null,
        label: 'Distribution amount',
        generatePropsFn: (values) => {
          return {
            holdings: values.holdings,
            prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
            decimalScale: 2,
            disabled: values.isConnected && moment(values.eventDate).isSameOrAfter(moment(), 'M'),
          }
        },
      }
    ]
  }
}

const ppContributionValueFieldFn = () => ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'transactionValue', type: 'numberWithHoldings', description: 'Total contribution amount.',
        validations: [isRequiredAndNotZero],
        defaultValue: null,
        generatePropsFn: (values) => {
          const labelFn = createLabelFnWithPredictedValue('Total contribution amount',values.eventPredictedAmount,`The expected contribution amount`)
          return {
            label: labelFn,
            holdings: values.holdings,
            prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
            decimalScale: 2,
            disabled: values.isConnected && moment(values.eventDate).isSameOrAfter(moment(), 'M'),
          }
        },
        onBlur: ({ value, formValues }) => {
          if ( !isNullOrUndefinedOrEmptyString(value) ) {
            if ( !isNullOrUndefinedOrEmptyString(formValues.basePayment) ){
              const totalMinusBase = value - formValues.basePayment;
              if (totalMinusBase > 0){
                updateValue('puaPayment', totalMinusBase);
              } else {
                updateValue('basePayment', value);
                updateValue('puaPayment', 0);
              }
            } else if ( !isNullOrUndefinedOrEmptyString(formValues.puaPayment) ){
              const totalMinusPua = value - formValues.puaPayment;
              if (totalMinusPua > 0){
                updateValue('basePayment', totalMinusPua);
              } else {
                updateValue('puaPayment', value);
                updateValue('basePayment', 0);
              }
            } else {
                updateValue('basePayment', value);
            }
          }
        },
      }
    ]
  }
}


const distributionReinvestedRowFn = ({ updateValue, updateField }) => {
  return {
    fields: [
      {
        type:'checkbox', key:'isDistributionReinvested',checkboxLabel: 'This distribution is reinvested ', description: 'The distribution displays Return ON Capital (profit) by default. Check this box to view it as Return OF Capital.' ,
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
          }
        },
        defaultValue: true,
        onChange: ({value,formValues})=>{
          updateValue('isLinkedTrnHidden', value);
          updateValue('isInfoAtDateHidden', !value);
          updateField('deathBenefit', {isHidden : !value , skipValidation: !value})
          updateValue('deathBenefit', null);
          updateField('updatedValue', {isHidden : !value, skipValidation: !value})
          updateValue('updatedValue', null);
        },
        fieldCustomStyle: {marginTop: '-24px'},
      },
      {key: 'isInfoAtDateHidden' , isHidden:true, 
        defaultValueFn: (values) => values.isDistributionReinvested,
      },
      {key: 'isLinkedTrnHidden' , isHidden:true, 
        defaultValueFn: (values) => values.isDistributionReinvested,
      },
      {key: 'isTransactionLinked' , isHidden:true,
            onChange: ({value,formValues})=>{
              //updateField('transactionValue', {isHidden: value})
              updateField('isDistributionReinvested', {disabled: value})
            },
            // validateFieldsOnChange: ['validationOfOnCapital'],
      }
      
    ]
  }
};
const distributionReinvestedAttr = {
  fieldKey: 'isDistributionReinvested',
  dataKey: 'isDistributionReinvested',
  // getEditValueFn: ({ value }) => floatFixed(value * 100, 3),
  // setFnForSave: (isDistribution, value) => (value / 100)
};




// const paymentEventDeleteEnableFn = (isItemConnected,isLastPredictedApprovedEvent) => {
//   return (isItemConnected) ? false : isLastPredictedApprovedEvent;
// }

// const paymentEventSaveEnableFn = (hasHappened,isItemConnected) => {
//   return !(isItemConnected && !hasHappened);
// }

const saveDividendsTrns = (eventDate, formValues, eventTransactions) => {
  return (formValues.isDistributionReinvested && formValues.transactionValue) ? [
    {
      date: eventDate,
      value:  Math.abs(formValues.transactionValue) * -1,
      ...(eventTransactions && eventTransactions.length > 1 ? {
        id: eventTransactions[1].id
      } : {})
    }
  ] : [];
}

export const lifeInsuranceEvents = [
  { title: 'Premium payment' , 
    sections: lifeInsuranceEventTypeGenerator([
      ppContributionValueFieldFn(),
      premiumPaymentRowFn,
      premiumPaymentValidationRowFn,
      cashValueUpdateRow(false),
      deathBenefitRow(false),
    ]) , attributesToSend: [basePaymentAttr,puaPaymentAttr,deathBenefitAttr], 
    valuesForCreateEventFromItem:[deathBenefitAttrForCreate],   
  },
  { title: 'Death benefit update', eventType: 'Death benefit update',
    sections: lifeInsuranceEventTypeGenerator([deathBenefitRow(false)]), attributesToSend: [deathBenefitAttr], isAlwaysVisible: true, hideLinkedTrn: true, hideValueAtDate: true },
  
  { title: 'Cash value update', eventType:'Value update', sections: lifeInsuranceEventTypeGenerator([cashValueUpdateRow(false)]) , hiddenAdditionalDataAtDate: ['deathBenefit'] },
  { title: 'Dividend', description: 'Cash Received', 
    sections: lifeInsuranceEventTypeGenerator([
      transactionValueFieldFn(),
      distributionReinvestedRowFn,
      cashValueUpdateRow(true),
      deathBenefitRow(true),
    ]), 
    otherTrnsFn: saveDividendsTrns,
    valuesForCreateEventFromItem:[deathBenefitAttrForCreate],
    attributesToSend: [deathBenefitAttr, distributionReinvestedAttr], isDistribution: true },
  { title: 'Withdrawal',
    sections: lifeInsuranceEventTypeGenerator([
      transactionValueFieldFn(),
      cashValueUpdateRow(false),
      deathBenefitRow(false),
    ]), 
    valuesForCreateEventFromItem:[deathBenefitAttrForCreate],
    attributesToSend: [deathBenefitAttr], isDistribution: true },
  { title: 'Value update', eventType:'Update asset value', hideLinkedTrn: true, isHidden: true, sections: [
    {
      title: '',
      rows:[
        cashValueUpdateRow(false),
        deathBenefitRow(false),
      ]
    }
  ], attributesToSend: [deathBenefitAttr] },
  { title: 'Item created', hideLinkedTrn: true, isHidden: true, sections: [
    {
      title: '',
      rows:[
        cashValueUpdateRow(false),
        deathBenefitRow(false),
      ]
    }
  ], attributesToSend: [deathBenefitAttr], deleteEnable: false },
]

const validateTransactionValueWithBaseAndPua = (formValues) => {  return  Math.trunc((formValues.basePayment || 0) + (formValues.puaPayment||0)) === Math.trunc(formValues.transactionValue)  };
