import React, { useEffect, useRef } from 'react';
import { ModalBackDrop, ModalWrapper } from './BaseModal.styles';
import { useModalUpwardCorrectionHook } from './useModalUpwardCorrectionHook';

export function BaseModal(props) {
    const { onBackDropClick , width , height , borderRadius , noSidebarMargin, customBackDropStyle, customModalWrapperStyles, customBackDropChild } = props;
    const modalWrapperRef = useRef();
    const isCorrectingUpward = useModalUpwardCorrectionHook(modalWrapperRef);

    useEffect(() => {
        const htmlBodyTag = document.body;
        // document.body.className = 'mobile_prevent_scroll';
        // const htmlTag = document.getElementsByTagName('html')[0];
        // const htmlBodyTag = document.getElementsByTagName('body')[0];
        // //htmlTag.className = 'mobile_prevent_scroll';
        // // setTimeout(()=>{
        // //     htmlBodyTag.className = 'mobile_prevent_scroll';
        // //     alert("A");
        // // },100)
        
        // document.getElementById('mobile_footer').style.display = 'none';
        
        // let scrollPosition = window.scrollY ;
        htmlBodyTag.style.overflow = 'hidden'; 
        htmlBodyTag.style.touchAction = 'none';
        htmlBodyTag.className = 'ant-scrolling-effect';
        // htmlTag.style.top = `-${scrollPosition}px`;
        // htmlTag.style.position = 'fixed';
        
        return () => {
        //     htmlTag.style.removeProperty('overflow');
               htmlBodyTag.style.removeProperty('overflow');
               htmlBodyTag.style.removeProperty('touch-action');
               htmlBodyTag.className = '';
        //     htmlTag.style.removeProperty('position');
        //     htmlTag.style.removeProperty('top');
        //     window.scrollTo(0, scrollPosition);
        //     document.getElementById('mobile_footer').style.display = 'flex';

          //   htmlTag.className = '';
            // htmlBodyTag.className = '';
        // let scrollPosition = window.scrollY ;
            // htmlTag.className = '';
           //  document.getElementsByTagName('html')[0].className = '';
        }
      }, [])   

    return (
        <ModalBackDrop onClick={(e)=>{onBackDropClick && onBackDropClick(); e.stopPropagation();}} style={{...(customBackDropStyle ? customBackDropStyle : {})}}>
            <ModalWrapper onClick={(e)=>{e.stopPropagation()}} width={width} height={height} borderRadius={borderRadius} isCorrectingUpward={isCorrectingUpward} noSidebarMargin={noSidebarMargin} ref={modalWrapperRef} style={{...(customModalWrapperStyles ? customModalWrapperStyles : {})}}>
                {props.children}
            </ModalWrapper>
            {customBackDropChild ? customBackDropChild : null}
        </ModalBackDrop>
    )

}