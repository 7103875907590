import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { Observer } from 'mobx-react';
import { useStore } from '../../../../../app/data/root.store';
import moment from 'moment';
import { LoaderWrapper, LinkEventTransactionMenuItem, LinkEventTransactionMenuItemDescription, LinkEventTransactionMenuItemAmount, LinkEventTransactionMenuItemWrapper, LinkEventTransactionMenuWrapper, LinkEventTransactionMenuItemDate, LinkEventTransactionMenuItemMainTxt, SearchInput, LinkEventTransactionScrollPanel, ItemLogoWrapper, InstitutionPlaceholderIconWrapper, ValueAndDateWrapper, HoldingsBadgeWrapper, HoldingsBadgeText, HoldingsBadgeTriangle } from './LinkEventTransactionMenu.styles';
import { displayMoneyValue } from '../../../../../../common/utils/number-display.utils';
import {ReactComponent as InstitutionPlaceholderIcon} from './icons/institution-placeholder-icon.svg';
import { SearchIconWrapper } from '../../../../../layout/components/HeaderSearchBox/HeaderSearchBox.styles';
import { ReactComponent as SearchResultsIcon } from './icons/search-icon.svg';
import { getTransactionMatchEventProperties, getTransactionMenuOpenProperties } from '../../../../data/wealth.mixpanel-events';
import { useAnalytics } from '../../../../../app/data/AnalyticsProvider';

const inputSearchDebounce = debounce((cb) => { cb() }, 1000);
const scrollBottomDebounce = debounce((cb) => { cb() }, 200);

export const LinkEventTransactionMenu = (props) => {

    const {handleSelectTransaction} = props;
    const { eventsStore, metadataStore, itemStore } = useStore();
    const [searchInput, setSearchInput] = useState('');
    const { mixpanelTrack } = useAnalytics()
    const listRef = useRef();

    useEffect(() => {
        eventsStore.fetchTransactions('', itemStore.item.holdings);
        mixpanelTrack('Event Transactions Menu Opened',getTransactionMenuOpenProperties(itemStore.item));
        // console.log(searchInputRef.current);
        // searchInputRef.current && searchInputRef.current.focus();
        return () => {
            // cleanup
        }
    }, [eventsStore,mixpanelTrack,itemStore.item])
    

    const transactionItemClicked = (transaction) => {
        mixpanelTrack('Transaction Matched',getTransactionMatchEventProperties(transaction,metadataStore.itemsMeta[transaction.associateId]));
        handleSelectTransaction && handleSelectTransaction(transaction);
    }

    
    const handleChange = (e) => {
        setSearchInput(e.target.value);
        inputSearchDebounce(() => {
            eventsStore.fetchTransactions(e.target.value, itemStore.item.holdings);
        })
    }

    const onScroll = () => {
        if (listRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                if (!eventsStore.isLoadingMoreTransactions && eventsStore.nextLink) {
                    scrollBottomDebounce(() => {
                        eventsStore.callNext();
                    })
                }
            }
        }
    };

    
    return (
        <Observer>{() => (
            <LinkEventTransactionMenuWrapper style={{ minWidth: 'inherit' }} >
                <SearchInput type="text" placeholder='Search transactions'
                value={searchInput} onChange={handleChange}
                // value={eventsStore.filterStr} onChange={(e)=>{eventsStore.setFilterStr(e.target.value)}}
                //ref={searchInputRef} 
                
                // value={transactionsStore.filtersValues.str} onChange={(e)=>{transactionsStore.setFilterStr(e.target.value)}} 
                prefix={ <SearchIconWrapper><SearchResultsIcon /></SearchIconWrapper>} />
                {/* prefix={ <SearchIcon />} /> */}
                <LinkEventTransactionScrollPanel ref={listRef} onScroll={onScroll}>
                { eventsStore.isLoadingTransactions ? <LoaderWrapper>
                    {/* <LogoLoaderWithText wbg text={'Retrieving transactions'} /> */}
                </LoaderWrapper> : <>

                {eventsStore.optionalTransactions.map((transaction, index) => (
                    <LinkEventTransactionMenuItem
                    onClick={() => {transactionItemClicked(transaction)}} 
                    key={index}
                    // isHidden={index === 0 && props.isValueDisabled}
                    >
                        <LinkEventTransactionMenuItemWrapper>

                            {metadataStore.itemsMeta[transaction.associateId]?.logoBase64 ? <ItemLogoWrapper><img alt={metadataStore.itemsMeta[transaction.associateId]?.connectedInstitutionName} src={'data:image/png;base64,' + metadataStore.itemsMeta[transaction.associateId]?.logoBase64} /></ItemLogoWrapper> :
                            metadataStore.itemsMeta[transaction.associateId]?.logoUrl ? <ItemLogoWrapper><img alt={metadataStore.itemsMeta[transaction.associateId]?.connectedInstitutionName} src={metadataStore.itemsMeta[transaction.associateId]?.logoUrl} /></ItemLogoWrapper> : <InstitutionPlaceholderIconWrapper><InstitutionPlaceholderIcon /></InstitutionPlaceholderIconWrapper>}
                            {/* <div style={{display:'flex', justifyContent:'center',alignItems:'center', borderRadius:'500px', width:'30px', height:'30px', backgroundColor:'red'}}></div> */}

                            {/* <LinkEventTransactionMenuIcon img={transaction.bankIcon}>{transaction.bankIcon}</LinkEventTransactionMenuIcon> */}
                            <ValueAndDateWrapper >
                                <LinkEventTransactionMenuItemMainTxt>
                                    <LinkEventTransactionMenuItemAmount>
                                        {displayMoneyValue(Math.abs(transaction.value),transaction.currency)}
                                    </LinkEventTransactionMenuItemAmount>
                                </LinkEventTransactionMenuItemMainTxt>

                                <LinkEventTransactionMenuItemDate>
                                    {moment(transaction.date).format("MMM DD, YYYY")}
                                </LinkEventTransactionMenuItemDate>
                                {(transaction.holdings !== 100 && transaction.holdings !== null) ? <HoldingsBadgeWrapper style={{marginTop:'4px'}}>
                                    <HoldingsBadgeText>
                                        {transaction.holdings}%
                                    </HoldingsBadgeText>
                                    <HoldingsBadgeTriangle />
                                </HoldingsBadgeWrapper> : null}
                            </ValueAndDateWrapper>
                            <LinkEventTransactionMenuItemDescription>
                                    {transaction.remarks}
                            </LinkEventTransactionMenuItemDescription>
                        </LinkEventTransactionMenuItemWrapper>
                    </LinkEventTransactionMenuItem>
                ))}

{/*                 
                {transactionsDummies.map((transaction, index) => (
                    <LinkEventTransactionMenuItem
                    // onClick={() => {props.setItem(index)}} 
                    key={index}
                    // isHidden={index === 0 && props.isValueDisabled}
                    >
                        <LinkEventTransactionMenuItemWrapper>

                        <LinkEventTransactionMenuIcon img={transaction.bankIcon}>{transaction.bankIcon}</LinkEventTransactionMenuIcon>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <LinkEventTransactionMenuItemMainTxt>
                                <LinkEventTransactionMenuItemAmount>
                                    {transaction.amount}
                                </LinkEventTransactionMenuItemAmount>
                                <LinkEventTransactionMenuItemDate>
                                    {transaction.date}
                                </LinkEventTransactionMenuItemDate>
                            </LinkEventTransactionMenuItemMainTxt>
                            <LinkEventTransactionMenuItemDescription>
                                    {transaction.description}
                            </LinkEventTransactionMenuItemDescription>
                        </div>
                        </LinkEventTransactionMenuItemWrapper>
                    </LinkEventTransactionMenuItem>
                ))} */}

                </>}
                </LinkEventTransactionScrollPanel>
            </LinkEventTransactionMenuWrapper>
           )}
           </Observer>)
}