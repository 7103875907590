import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../../../../common/styles/combos.styles";
import { colors, views } from "../../../../../../../common/styles/theme.styles";

export const FiltersHeroEmptyStateWrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  flex: 1;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  gap: 12px;
`;

export const CustomClassEmptyStateTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Circular;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CustomClassEmptyStateSubTitle = styled.div`
  color: ${colors.darkGray3};
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  text-align: center;
  padding: 0px 24px;
`;

export const CustomClassEmptyStateCTA = styled.div`
  display: inline-flex;
  height: 44px;
  padding: 0px 16px;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #FFF;
  margin-top: 4px;
  cursor: pointer;
  ${views.tablet}{
    margin-bottom: 24px;
  }
`;

export const CustomClassEmptyStateLink = styled.a`
  display: inline-flex;
  height: 44px;
  padding: 0px 16px;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #FFF;
  margin-top: 4px;
  cursor: pointer;
  color:#fff;
  margin-bottom: 24px;
  :hover{
    color:#fff;
  }
`;