const privateBusinessEquityAttributes = [
    {attributeKey:'pricePerShare',dataType:'money' , title:'Price per share'},
    {attributeKey:'sharesPurchased',dataType:'number', title:'Current share amount'}
]
const privateBusinessOptionsAttributes = [
    {attributeKey:'pricePerShare',dataType:'money' , title:'Price per share'},
    {attributeKey:'numberOfShares',dataType:'number', title:'Options'}
]

export const ASSET_CATEGORIES_ACTUALS_ATTRIBUTES = {
    24: privateBusinessEquityAttributes,
    25: privateBusinessOptionsAttributes
}

export const LIABILITY_CATEGORIES_ACTUALS_ATTRIBUTES = {
    
}