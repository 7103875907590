export const holdingEntityBackgroundColors = [
  '#FECACA',
  '#FED7AA',
  '#FDE68A',
  '#EEE4AF',
  '#DBE2C6',
  '#CCE994',
  '#B6E4C1',
  '#BFD9DA',
  '#BAE6FD',
  '#C7D2FE',
  '#DACEE0',
  '#F5D0FE',
  '#FBCFE8',
  '#EDD9D0',
  '#DDD7D4',
  '#E2E8F0',
];
