import { getData, postData } from '../../../common/utils/network.utils';

// export const connectAccounts = async (accounts,institution) => {
//     return (await  postData("connected-accounts/add",{accounts,institution}));
// }

export const connectAccounts = async (data) => {
    return (await postData('accounts/connect_multiple_accounts',{accounts:data , returnCreated : true}));
}

export const saveItem = async (data) => {
    return (await  postData('assets',data));
}

export const saveStocks = async (data) => {
    return (await  postData('assets/stocks',data));
}

export const saveCryptoCoins = async (data) => {
    return (await  postData('assets/crypto-coins',data));
}

export const searchInstitutions = async(str,page,limit) => {
    return (await getData('institutions?sch='+str + (page? '&page=' + page : '') + (limit? '&limit=' + limit : ''))).data;
}

export const searchTickers = async(str) => {
    return (await getData('currencies/search-for-symbol?symbol='+str)).data;
}

export const fetchTickerRealTimePrice = async(symbol,exchange,symbolCurrency,itemCurrency) => {
    return (await getData(`currencies/real-time-price?symbol=${symbol}&exchange=${exchange}&symbol_currency=${symbolCurrency}&item_currency=${itemCurrency}`)).data;    
}

export const fetchTickerMarketCap = async(symbol,exchange) => {
    return (await getData('currencies/market-cap?symbol='+symbol + '&exchange=' + exchange)).data;
}

export const searchCryptoCoins = async(str,page,limit) => {
    return (await getData('currencies/cryptocurrencies?sch='+str + (page? '&page=' + page : '') + (limit? '&limit=' + limit : ''))).data;
}

export const fetchCryptoCoinUsdValue = async(symbol,currency) => {
    return (await getData(`currencies/single-symbol-price?symbol=${symbol}&currencies=${currency}`)).data;
}

export const createVipSync = async(data) => {
    return (await postData('vip', {institutionId: data}));
} 