import styled from "@emotion/styled/macro";
import { colors, views } from "../../../../common/styles/theme.styles";
import { Input, Select } from "antd";
import { flexCenter } from "../../../../common/styles/combos.styles";

const inputPaddingLeft = 32;
const mobileInputHeight = 36;

export const SearchIconWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 10px;
    left: 12px;
    align-items: center;
    svg{
        width: 16px;
        height: 16px;
        circle, line {
            stroke: #9097A3;
        }
    }
`;

export const PhoneInputBoxWrapper = styled.div`
    width: 100%;
    position: relative;
    .ant-input-focus-visible {
        outline: none;
    }
     .ant-input {
        background-color:${colors.lightGray2};
        border: 1px solid ${colors.lightGray2};
        box-shadow: none;
        border-radius: 26px;
        height: ${mobileInputHeight}px;
        padding-left: ${inputPaddingLeft}px;
    }
    .ant-input:hover {
        border: 1px solid ${colors.gray3};
        box-shadow: none;
        height: ${mobileInputHeight}px;
    }
`;

export const PhoneInputWrapper = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    flex:1;
    min-width: 342px;
    border-radius: 12px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 100%;
        self-align: center;
    }
    .ant-select .ant-select-selector .ant-select-selection-search-input {
        height: 100%;
    }
    .ant-input-affix-wrapper {
        height: auto;
        flex: 1;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    border: 1px solid transparent;
    ${({withError}) => withError ? `
        border: 1px solid red;
        ` : ``
    }
`;

export const StyledSelect = styled(Select)`
    width:100%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background:#fff;
    border:none;
    .ant-select-selector{
        border:none!important;
        border-radius:6px!important;
        box-shadow: none!important;
    }
    &.ant-select-focused{
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 1), 0px 2px 4px rgba(0, 0, 0, 0.1);
        .ant-select-selector{                
            // border-color:red!important;
            // box-shadow: none!important;
        }
    } 
`;

export const StyledInput = styled(Input)`
    /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8), 0px 2px 4px rgba(0, 0, 0, 0.04) !important; */
    box-shadow:  ${props => props.email ? 'none' : '0px 0px 1px rgba(0, 0, 0, 0.3)' } !important;
    border-radius: 6px;
    background:${props => props.email ? '#F7F8F9' : '#fff'};
    border:none;
    padding: 5px 11px;
    &:focus{
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 1), 0px 2px 4px rgba(0, 0, 0, 0.1);
    }
    &.lockedMonthlyIncome{
        background-color: #f5f5f5;
    }
`;

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  color: ${colors.darkGray1};
  font-size: 16px;
  font-weight: 450;
  box-shadow: 0px -1px 0px 0px #DDE3EB inset;
  ${views.tablet} {
    padding:12px 0;
  }
`;

export const DrawerCloseButtonWrapper = styled.div`
    ${flexCenter}
    cursor: pointer;
    padding: 4px;
`;

export const SearchInput = styled(Input)`
  height: 48px;
  border: 1px solid ${colors.darkGray2};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  &:hover{
      border-color:#7d7d7d!important;
      box-shadow: none;
  }
  &:focus{
      border-color:#7d7d7d!important;
      box-shadow: none;
  }
  &.ant-input-affix-wrapper-focused{
      border-color:#7d7d7d;
      box-shadow: none;
  }
`