import styled from '@emotion/styled/macro'
import { animateSpin } from '../../../../common/styles/animations.styles';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors, sizes, views } from '../../../../common/styles/theme.styles';


const vars = {
    backgroundColor: '#ffffff',
    borderBottomColor: '#DDE3EB',
    logoSeparatorColor: '#DDE3EB'
}

export const HeaderWrapper = styled.div`

    display: flex;
    // grid-area: header;
    align-items: center;
    padding-inline: 20px;
    /* border-bottom: 1px solid #DDE3EB; */
    width: 100%;
    



    /* 
    justify-content: center;
    align-items: center;
    // background-color: ${props=> props.displayStyle === 'black' ? '#282d33' : '#FAFAFA'};
    // transition: background-color 0.3s;
    height:${sizes.headerHeight};
    min-height:${sizes.headerHeight};
    
    border-bottom:1px solid ${vars.borderBottomColor};
    background-color:${vars.backgroundColor};
    
    position: relative;
    ${views.mobile} {
        ${props=>props.showBackButton ? `justify-content: space-between; padding: 0 12px;` : null}
    }
    & > span {
        display: inline-block;
        width: 24px;
    } */

    ${views.tablet}{
        background-color: #fff;
    }
`

export const OnBoardingHeaderWrapper = styled.div`

    display: flex;
    align-items: center;
    padding-inline: 20px;
    width: 100%;
    height:${sizes.headerHeight};
    min-height:${sizes.headerHeight};
    ${views.tablet}{
        ${props=>props.isLoginLayout || props.whiteBg ? `background-color: #fff;` : ``}
        position: fixed;
        z-index: 3;
        pointer-events: none;
    }
`

export const SignUpButton = styled.div`
    background-color:black;
    color:#fff;
    border:1px solid #000;
    padding: 8px 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height:auto;
    text-align: center;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition:all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 6px;
    &:hover{background-color:#211c52;}
    right: 24px;
    position: absolute;
`


export const SearchBoxWrapper = styled.div`
    display:flex;
    flex:1;
    justify-content:center;
    // padding-left:90px;
    padding-left:138px;
    @media (max-width: 1440px) {
        // padding-left:0;
        padding-left:calc(50vw - 585px);
    }
    @media (max-width: 1160px) {
        padding-left:0;
    }
`;


export const BackButtonWrapper = styled.div`
    width:24px;
    ${flexCenter}
    margin-right:16px;
    cursor: pointer;
`;

// export const LeftContentWrapper = styled.div``

export const SearchBox = styled.div`
    max-width: 380px;
    flex:1;
    /* display:flex; */
    /* align-items:center; */
`;

export const HeaderActions = styled.div`
    display:flex; 
    flex:1; 
    justify-content:flex-end; 
    align-items:center;
    gap:8px;
`;

export const HeaderButton = styled.div`
    ${flexCenter};
    height:32px;
    font-size:16px;
    font-weight:450;
    outline:none;
    border:none;
    border-radius:6px;
    cursor:pointer;
    gap: 4px;
    padding: 0 8px;
`;

export const FiltersButton = styled(HeaderButton)`
    background-color:${colors.lightGray2};
    color:${colors.darkGray1};
    :hover{
        background-color:${colors.gray3};
    }
    margin-left:8px;
    border-radius: 26px;
`;

export const CreateButton = styled(HeaderButton)`
    height: 32px;
    ${props=>props.createBlack ? `
        background-color:${colors.darkGray1};
        color:#fff;
        svg path{stroke:#fff;}
        :hover{
            background-color:${colors.gray3};
            color:${colors.darkGray1};
            svg path{stroke:${colors.darkGray1};}
        }
    ` : `
        background-color:${colors.lightGray1};
        color:${colors.darkGray1};
        :hover{
            background-color:${colors.gray3};
        }
    `}
    padding: 10px 20px 10px 18px;  
`;

export const UpgradeButton = styled(HeaderButton)`
    height: 32px;
    background-color:${colors.upgradeOrange};
    color:#fff;
    :hover{
        background-color:${colors.upgradeOrangeHover};
    }
    padding: 10px 16px 10px 12px;
`;

export const NotificationsButtonWrapper = styled.div`
    box-shadow: ${props=>props.isOpenState ? '0px 2px 4px rgb(0 0 0 / 4%), 0px 0px 2px rgb(0 0 0 / 16%)' : ''} ;
    border-radius: 6px;
    // padding: 12px;
    width:42px;
    height:32px;
    ${flexCenter};
    font-size:24px;
    font-weight:300;
    color:${colors.darkGray1};
    position: relative;
    cursor:pointer;
    :hover{
        box-shadow: 0px 2px 4px rgb(0 0 0 / 4%), 0px 0px 2px rgb(0 0 0 / 16%);
    }
    .userMenuArrowIcon{
        min-width:16px;
        margin-right:-3px;
    }
    ${props=>props.isOpenState ? `
        background-color:${colors.darkGray1};
        svg path{stroke:#fff;}
    ` : ``}
`;


export const NotificationsBellIconBadge = styled.div`
    position: absolute;
    top: 2px;
    right: 10px;
    width: 12px;
    height: 12px;
    background-color: ${colors.redNotification};
    border: 1px solid ${props=>props.isOpenState ? colors.darkGray1 : '#fff'};
    border-radius: 20px;
`;

export const UserMenuButton = styled.div`
    ${flexCenter};
    font-size:24px;
    font-weight:300;
    color:${colors.darkGray1};
`;

export const LinksStatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
    .header-status-link{
        padding:0px 16px;
        font-weight: 500;
        font-size: 14px;
        color: ${props=> props.white ? '#fff' : '#848C9A'};
        cursor: pointer;
        transition: all 0.2s;
        margin-top: 0px;
        display: flex;
        &:hover{
            color: ${props=> props.white ? '#e6eeff' : '#2B3549'};
        }
        &.selected{
            color: ${props=> props.white ? '#fff' : '#2B3549'};
            transform: scale(1.15) translateY(-0.5px);
        }
    }
`

export const ActionsWrapper = styled.div`
    width:200px;
    display: flex;
    padding-right:12px;
    justify-content: flex-end;
    align-items:center;
`;

export const ActionButton = styled.div`
    height: 32px;
    width: 32px;
    background-color: #4e5156;
    color: #fff;
    margin:0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    path{
        stroke: #fff;
    }
    .user-letter{
        font-size: 16px;
        text-transform: uppercase;
    }
`;

export const IncognitoSwitchWrapper = styled.div`
margin-right:8px;
.ant-switch{
    border: 1px solid #fff;
    height: 24px;
    background-color: #4e5156;
} 
.ant-switch-checked {
    background-color: #4e5156;
}
/* .ant-switch-handle::before{
    border-radius:0px;
} */
`;

export const MenuWrapper = styled.div`
    display: flex;
    flex-direction: row;
    .header-status-link{
        padding:0px 16px;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        opacity:0.4;
        // color: ${props=> props.white ? '#fff' : '#848C9A'};
        cursor: pointer;
        transition: all 0.2s;
        margin-top: 0px;
        display: flex;
        align-items:center;
        .link-text{
            padding-left:8px;
        }
        path{
            transition: stroke 0.2s;
            stroke: #848C9A;
        }
        &:hover{
            opacity:1;
            /* color: #2B3549;
            path{
                stroke: #2B3549;
            } */
            //color: ${props=> props.white ? '#e6eeff' : '#2B3549'};
        }
        &.selected{
            opacity:1;
            // color: #2B3549;
            // color: ${props=> props.white ? '#fff' : '#2B3549'};
            transform: scale(1.15) translateY(-0.5px);
            /* path{
                stroke: #2B3549;
            } */
        }
        
    }
`;

export const SyncRedIconWrapper = styled.div`
    svg{
        path{
            fill:#EB8787;
        }
    }
    animation-name: ${animateSpin};
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    ${flexCenter};
`;


export const NotificationsMenuWrapper = styled.div`
    /* width:400px; */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    /* margin-right:-112px; */
`;

export const CreateMenuItemWrapper = styled.div`
    ${flexCenter};
    gap:8px;
    padding:6px 0;
`;
export const CreateMenuItemIcon = styled.div`
    width: 64px;
    ${flexCenter};
`;
export const CreateMenuItemTextContent = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
`;
export const CreateMenuItemBadge = styled.div`
    background-color: #ECEDA6;
    color: ${colors.darkGray1};
    box-shadow: 0px 1.23288px 2.46575px rgba(255, 255, 255, 0.04), 0px 0px 1.23288px rgba(255, 255, 255, 0.16);
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    padding:4px 8px;
    border-radius: 3px;
    margin-bottom:4px;
`;
export const RecommendedItemBadge = styled(CreateMenuItemBadge)`
    background-color: #A6EDCF;
`;
export const NewItemBadge = styled(CreateMenuItemBadge)`
    background-color: #F7C7E4;
`;
export const CreateMenuItemTitle = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    font-weight:500;
    line-height:19px;
    margin-bottom:4px;
`;
export const CreateMenuItemSubTitle = styled.div`
    color:${colors.darkGray3};
    font-size: 12px;
    line-height: normal;
`;

export const CreateMenuAddManual = styled.div`
    ${flexCenter};
    border-top:1px solid ${colors.gray3};
    color:${colors.darkGray1};
    font-size:14px;
    width:100%;
    padding:16px;
    text-align:center;
`;

export const MenuSectionHeader = styled.div`
    color: ${colors.darkGray3};
    font-family: Circular;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 16px;
    padding-inline: 16px;
    padding-top: 4px;
`;