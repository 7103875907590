import {
    getData, patchData,
    // postData
} from '../../../common/utils/network.utils';

// export const setWelcomeDone = async () => {
//     return (await  postData("user/onBoardingWelcomeDone",{}));
// }

// export const setCreateItemDone = async () => {
//     return (await  postData("user/onBoardingCreateItemDone",{}));
// }

// export const setOnBoardingComplete = async () => {
//     return (await  postData("user/onBoardingComplete",{}));
// }

export const getNotificationsData = async () => {
    return (await getData("notifications")).data;
}

export const postNotificationMarkAsRead = async (notificationsIds) => {
    return (await patchData(`notifications/markasread`, {notificationsIds}));
}

export const postNotificationMarkAsSeen = async (notificationsIds) => {
    return (await patchData(`notifications/markasseen`, {notificationsIds}));
}
