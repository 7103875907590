import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { postData } from '../../../../common/utils/network.utils';
import { useStore } from '../../../app/data/root.store';
import { AUTH_STEPS } from '../../../user/data/user.store';
import { BackButtonWrapper, ContinueButton, ForgotPasswordStepWrapper, FormMessage, InputLabel, InputWrapper, LoginForm, LoginInput, LinkSpan, SignUpStepHeader, SignUpStepSubHeader } from '../LoginPage.styles';
import { useRecaptchaHook } from '../useRecaptchaHook';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';

export const ForgotPasswordStep = observer((props) => {
  const { userStore } = useStore()
  const [message, setMessage] = useState('');
  const { trackEvent } = useAnalytics();
  const [createRecapcthaToken, onRecaptchaReset, MemoizedRecaptchaComponent] = useRecaptchaHook();
  const { email } = userStore.authFields;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailInputChange = (e) => {
    userStore.setAuthFields('email', e.target.value);
  }

  const handleSendCodeClick = async () => {
    trackEvent('CTA Clicked', {
      cta_text: "Send code",
      location: "Forget password page",
      destination: 'Set password page',
      type: "Button",
      url: window.location.href
    })
    if (emailRegex.test(email)) {
      const recaptchaToken = await createRecapcthaToken();
      postData("user/forgot-password", { email , recaptchaToken })
        .then(data => {
          if (data.success) {
            userStore.setAuthFields('userHasPassword', data.hasPassword);
            userStore.setAuthStep(AUTH_STEPS.RESET_PASSWORD_STEP);
          }
        }, error => {
          if (error.message) {
            setMessage(<div>This email has no account in Vyzer yet. <LinkSpan onClick={()=>{userStore.setAuthStep(AUTH_STEPS.SIGN_UP_STEP)}}>Create your account</LinkSpan></div>)
            // setMessage(Array.isArray(error.message) ? capitalizeTheFirstLetter(error.message) : error.message);
          }
        }).finally(()=>{
          onRecaptchaReset && onRecaptchaReset();
        });
    } else {
      setMessage("Please fill email");
    }
  }

  return (
    <>
      <BackButtonWrapper onClick={() => { userStore.setAuthStep(AUTH_STEPS.LOG_IN_STEP) }}><BackArrowSvg /> Back</BackButtonWrapper>
      <ForgotPasswordStepWrapper>
        <SignUpStepHeader>Forgot Your Password?</SignUpStepHeader>
        <SignUpStepSubHeader>Enter your Vyzer account email.</SignUpStepSubHeader>
        <LoginForm>
          <InputWrapper>
            <InputLabel>Email</InputLabel>
            <LoginInput onChange={handleEmailInputChange} type="text" value={email} name="email" />
          </InputWrapper>
          <FormMessage haveMessage={message !== ''}>
            {message}
          </FormMessage>
        </LoginForm>
        <ContinueButton disabled={!emailRegex.test(email)} onClick={handleSendCodeClick}>Send code</ContinueButton>
        <MemoizedRecaptchaComponent />
      </ForgotPasswordStepWrapper>

    </>
  )
})

const BackArrowSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6803 16.0137L10.347 12L13.6803 7.98633" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}