import React from 'react'
import { UserProfileMenu } from '../../../layout/components/Sidebar/UserProfileMenu'
import { OnboardingSidebarWrapper, UserProfileMenuWrapper } from './OnboardingSidebar.styles'
import { StepsPath } from './StepsPath'
import { observer } from 'mobx-react'
import { useStore } from '../../../app/data/root.store'

export const OnboardingSidebar = observer(() => {
  const { onBoardingStore } = useStore();
  
  // console.log({hiddenStepsCount, sl: steps.length, psl: pathSteps.length});

  const handleJumpToStep = (index) => {
    // const stepKey = onBoardingStore.questionnaireStepsData[index + hiddenStepsCount].key;
    if (index >= onBoardingStore.sideBarSteps.length) return;
    const stepKey = onBoardingStore.sideBarSteps[index].key;
    onBoardingStore.jumpToStep(stepKey);
  }

  return (
    <OnboardingSidebarWrapper>
      <a style={{ width: 'auto', boxShadow: 'none', padding: '0 4px', display: 'flex', pointerEvents: 'auto' }} href="https://vyzer.co/" target="_blank" rel="noreferrer">
        <VyzerGraySymbol />
      </a>
      {!onBoardingStore.shouldHideSideBarSteps && <StepsPath stepsLength={onBoardingStore.stepsPathLength} currentStep={onBoardingStore.currentSideBarStepIndex} completedSteps={onBoardingStore.sideBarSteps} onJumpToStep={handleJumpToStep} />}
      <div style={{ flex: 1 }} />
      <UserProfileMenuWrapper>
        <UserProfileMenu isActivation />
      </UserProfileMenuWrapper>
    </OnboardingSidebarWrapper>
  )
})

const VyzerGraySymbol = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1942 0H9.86411C4.41631 0 0 4.41631 0 9.86411V22.1942C0 27.642 4.41631 32.0584 9.86411 32.0584H22.1942C27.642 32.0584 32.0584 27.642 32.0584 22.1942V9.86411C32.0584 4.41631 27.642 0 22.1942 0Z" fill="#3F4449" />
      <path d="M10.1244 9.16626L17.7824 22.7359L15.9981 25.8974L6.55566 9.16626H10.1244ZM13.6931 9.16626L15.4598 12.297H20.1047L17.7824 16.4122L19.5668 19.5737V19.5743L25.4408 9.16626H13.6931Z" fill="white" />
    </svg>
  )
}