import styled from '@emotion/styled/macro';
import { flexCenter, ScrollPanelWithShadows } from '../../../../../../common/styles/combos.styles';
import { colors, views, shadows } from '../../../../../../common/styles/theme.styles';
import { PrimaryButton, SecondaryButtonWithIcon } from '../../../../../../common/components/buttons/NewButtons.styles';

export const EventTypeDropdownWrapper = styled.div`
    ${flexCenter}
`;

export const EventTypeFieldWrapper = styled.div`
    // width: 224px;
    
    width: 100%;
`;

export const OptionalText = styled.span`
    color: ${colors.darkGray2};
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-left: 4px;
`;

export const EventValuesAtDateWrapper = styled.div`
    padding: 4px 12px;
    display: flex;
    flex-direction: column;
    // gap: 4px;
`;

export const EventTypeFieldMessage = styled.div`
    display:block;
    color: #818181;
    font-size: 12px;
`;

export const EventTypeStyle = styled.div`
    margin:  ${props=>props.isHeader ? '20' : '12'}px 24px;
    width: 100%;
    gap: 16px;
    display:flex;
    justify-content:center;
    align-items:flex-start;
    border-bottom: ${props=>props.isHeader ? `1px solid ${colors.gray3}` : null};
    .ant-picker {
        :hover {
            border-color:#7d7d7d;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0.5px 4px , rgba(0, 0, 0, 0.3) 0px 0px 1px;
        }
        &.ant-picker-focused {
            border-color:#7d7d7d;
            box-shadow: none;
        }
    }
`;
export const LinkTransactionTypeStyle  = styled(EventTypeStyle)`
   
`;

export const LinkTransactionFieldWrapper = styled.div`
    ${views.desktop}{
        width:464px;
    }
    ${views.tablet}{
        width: 100%;
        padding:0 24px;
        max-width: 512px;
    }
`;

export const CreateEventModalHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
    color: #000000d9;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
`;

export const CreateEventModalTitle = styled.div`
    margin: 0;
    color: #000000d9;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
`;

export const CreateEventModalClose = styled.div`
    display: grid;
    margin-left: auto;
    order: 2;
    place-items: center;
    width: 32px;
    cursor: pointer;
`;

export const CreateEventModalContent = styled.div`
    background-color: #FDFEFE;
    border-radius: 0px 0px 8px 8px;
    .fd-sectionWrapper{
        padding-top:0;
        padding-bottom:0;
        ${views.tablet}{
            padding: 0 24px;
        }
    }
    //// very ugly, do not use important ever - TODO: fix soon with new form displayer style.
    .fd-inputLabel{
        font-weight: 500!important;
        font-size: 14px!important;
        color: ${colors.darkGray2};
    }
    .fd-inputLabel-text{
        font-weight: 500!important;
        font-size: 14px!important;
    }
    textarea {
        min-height: 64px;
    }
    .ant-input{
        font-size: 14px!important;
        border-radius: 6px!important;
        border: 1px solid ${colors.gray3};
        height: 40px;
        padding: 10px 8px 10px 12px;

        :hover {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0.5px 4px , rgba(0, 0, 0, 0.3) 0px 0px 1px;
        }
    }
    .ant-input-affix-wrapper{
        .ant-input{
            box-shadow:none!important;
        }
    }
    .ant-picker{
        font-size: 14px!important;
        border-radius: 6px!important;
        height:40px;

        :hover {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0.5px 4px , rgba(0, 0, 0, 0.3) 0px 0px 1px;
        }
    }
    .ant-dropdown-trigger{
        border-radius: 6px!important;
        border: 1px solid #d9d9d9;
        height:40px;

        :hover {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0.5px 4px , rgba(0, 0, 0, 0.3) 0px 0px 1px;
        }
    }    
`;

export const CreateEventModalButton = styled(PrimaryButton)`
    border: none;
    border-radius: 8px;
    height: 40px;
    min-width: 124px;
    box-shadow: ${shadows.toggleShadow};
    font-size: 15px;
    font-weight: 500;
    padding: 8px 20px;
`;

export const DeleteIconWrapper = styled(SecondaryButtonWithIcon)`
    ${flexCenter}
    padding: 12px 20px 12px 16px;
    gap: 4px;
    height: 40px;
    color: #ff0000;
`;

export const CancelButton = styled.div`
    ${flexCenter}
    cursor: pointer;
    background: ${colors.darkGray1};
    color: #fff;
    padding: 12px 20px 12px 16px;
    border-radius: 4px;
    gap: 10px;
`;

export const EditTargetWrapper = styled(SecondaryButtonWithIcon)`
    ${flexCenter}
    gap: 6px;
    height: 40px;
    cursor: pointer;
    color: ${colors.darkGray1};
    padding: 12px 20px 12px 16px;
    margin-left: 6px;
    border-radius: 8px;
    background: #fff;
    box-shadow: ${shadows.toggleShadow};
`;

export const DeleteEventModalButton = styled.button`
    height: 33px;
    background-color: inherit;
    color: #ff0000;
    border: none;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    padding: 8px 0px;
    ${flexCenter}
    ${props => props.disabled ? `
        opacity: 0.4;
    ` : `` }
`;

export const EventTransactionWrapper = styled.div`
    position: relative;
    background: white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.04);
    margin-top: 8px;
    width: 464px;
    display:flex;
    flex-direction:row;
    /* height: 102px; */
    flex-direction:row;
    padding:16px;
    align-items:flex-start;
    ${views.tablet}{
        max-width: 100%;
    }
`;

export const EventTransactionIconColumnWrapper = styled.div`
    padding-right:16px;
`

export const EventTransactionInstitutionLogoWrapper = styled.div`
    height:30px;
    width:30px;
    min-width:30px;
    border-radius: 50%;
    /* margin-right:12px; */
    img{
        height:30px;
        width:30px;
    }
    /* margin-top:4px; */
`;

export const EventTransactionInstitutionPlaceholderIconWrapper = styled.div`
    ${flexCenter};
    width: 30px;
    min-width:30px;
    height: 30px;
    background-color: ${colors.darkGray3};
    border-radius: 50%;
    /* margin-top:4px; */
`;

export const EventTransactionDataColumnWrapper = styled.div`
    flex-direction:column;
`


export const EventTransactionWrapperCutout = styled.div`
    position: absolute;
    top: -5px;
    left: 0px;
    width: 100%;
    height: 5px;
    background-image: url("${props => props.img}");
    background-repeat: repeat-x;
    transform: rotate(180deg);
`;

export const LinkedEventTransactionTitleAndAction = styled.div`
    ${flexCenter}
    justify-content:space-between;
`;

export const LinkedEventTransactionPriceAndDate = styled.div`
    display:flex;
    flex-direction:column;
`

export const LinkedEventTransactionActionsWrapper = styled.div`

`;

export const LinkedTransactionPrice = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color:${colors.darkGray1};
    ${flexCenter};
    justify-content:start;
    margin-bottom:4px;
`;

export const SuggestedBadge = styled.div`
    margin-left:8px;
    background-color: #82AAFA;
    padding:2px 6px 2px 4px;
    border-radius:4px;
    color:#fff;
    ${flexCenter}
    line-height:14px;
    font-size:12px;
    svg{
        margin-right:2px;
        width:14px;
        height:14px;
    }
`;

export const LinkedTransactionDate = styled.div`
    font-size: 12px;
    line-height: 14px;
    color:${colors.gray1};
`;

export const LinkedTransactionDescription = styled.div`
    font-family: 'Roboto Mono', monospace;
    font-size: 12px;
    line-height: 16px;
    color:${colors.darkGray1};
    padding-top:8px;
    text-transform:uppercase;
`;

export const AddValueUpdatePrevButton = styled.div`
    /* border: 1px dashed gray; */
    /* border-radius:4px; */
    /* padding:8px; */
    /* text-align:center; */
    cursor: pointer;
    margin-bottom:22px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 4px;
`;

export const CloseXIconWrapper = styled.div`
    display:flex;
    align-items: center;
    cursor: pointer;
    /* width: 10px;
    height: 10px; */
    margin-left: 8px;
    svg{
        path{
            stroke: #000;
        }
    }
`;

export const StepWrapper = styled(ScrollPanelWithShadows)`
    display:flex;
    flex-direction:column;
    flex:1;
    /* padding:0 80px; */
    // padding:${props=>props.widthPadding ? '16px' : '0'};
`;

export const FormStepFooter = styled.div`
    width:100%;
    padding:24px;
    border-top: 1px solid #e3e3e3;
    justify-content:space-between;
    flex-direction: row-reverse;
    display:flex;
    align-items:center;
`;

export const ActionsBottomMessage = styled.span`
    white-space: pre-line;
`

export const ExtraFooterTextWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 0;
    margin-inline: 8px;
    color: ${colors.darkGray2};
    font-family: Circular;
    font-size: 13px;
    font-weight: 450;
    line-height: normal;
`;