import styled from '@emotion/styled/macro';
import { colors, views } from '../../styles/theme.styles'
import { flexCenter } from '../../styles/combos.styles';

export const PrimaryButton = styled.div`
    height: 38px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${props => props.padding ? props.padding : '8px 20px'};
    gap: 10px;
    background-color: ${props => props.disabled ? 'grey' : colors.darkGray1};
    box-shadow: ${props => props.disabled ? `` : `0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)`};
    border-radius: ${({ borderRadius }) => borderRadius || '6'}px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

    ${props => props.disabled ? `` : `
    :hover{
        background: #353A44;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.04);
    };

    :active{
        background: #353A44;
        opacity: 0.8;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.04);
    };
    `}
`;

export const PrimaryButtonWithIcon = styled.div`
    height: ${props => props.height ? props.height : 38}px;
    ${flexCenter};
    justify-content: center;
    padding: ${props => props.isIconAfterText ? '10px 18px 10px 20px' : '10px 20px 10px 18px'};
    gap: 6px;
    background-color: ${props => props.disabled ? 'grey' : colors.darkGray1};
    box-shadow: ${props => props.disabled ? `` : `0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)`};
    border-radius: ${props => props.borderRadius || '6'}px;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: ${props => props.color || '#FFFFFF'};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    ${props => props.isProcessing ? `pointer-events: none; user-select: none;` : null}
    
    ${props => props.disabled ? `` : `
    :hover{
        background: #353A44;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    };

    :active{
        background: #353A44;
        opacity: 0.8;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    };
    > svg{
        ${props.noStroke ? `` : `
            path{stroke:${props.iconStrokeColor || '#fff'}}
        `}
    }
    `}

    ${views.tablet}{
        ${props => props.mobileHeight ? `height:${props.mobileHeight}px;` : ``}
        ${props => props.mobileBorderRadius ? `border-radius:${props.mobileBorderRadius}px;` : ``}
        
    }
`;

export const SecondaryButton = styled.div`
    height: 38px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${props => props.padding ? props.padding : '0 20px'};
    gap: 8px;
    background: #FFFFFF;
    box-shadow: ${props => props.disabled ? `` : `0px 1px 6px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)`};
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: ${colors.darkGray1};
    border: 1px solid transparent;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.disabled ? '0.4' : '1'};

    :hover{
        border: 1px solid #BDC6DA;
    };

    :active{
        border: 1px solid #1A1B1D;
    };

    ${views.tablet}{
        ${props => props.mobileHeight ? `height:${props.mobileHeight}px;` : ``}
        ${props => props.mobileBorderRadius ? `border-radius:${props.mobileBorderRadius}px;` : ``}    
    }
`;

export const SecondaryButtonWithIcon = styled.div`
    height: 38px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${props => props.isIconAfterText ? '0 18px 0 20px' : '0 20px 0 18px'};
    gap: 8px;
    background: #FFFFFF;
    box-shadow: ${props => props.disabled ? `` : `0px 1px 6px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)`};
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: ${colors.darkGray1};
    border: 1px solid transparent;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.disabled ? '0.4' : '1'};

    :hover{
        border: 1px solid #BDC6DA;
    };

    :active{
        border: 1px solid #1A1B1D;
    };
       ${views.tablet}{
        ${props => props.mobileHeight ? `height:${props.mobileHeight}px;` : ``}
        ${props => props.mobileBorderRadius ? `border-radius:${props.mobileBorderRadius}px;` : ``}    
    }
`;

export const SecondaryButtonBorderless = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    gap: 8px;
    background: #FFFFFF;
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    cursor: pointer;
    color: ${colors.darkGray1};
    ${props => props.isResetSuccessful ? `
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    ` : ``};
    :hover{
        color: ${colors.darkGray2};
    };

    :active{
        color: ${colors.darkGray2};
        opacity: 0.8;
    }
`;

export const BlueButton = ({ isSpaceBetween, withIcon, iconColor, isInverted, children, ...rest }) => {
    return (
        <BlueButtonContainer isSpaceBetween={isSpaceBetween} withIcon={withIcon} iconColor={iconColor} isInverted={isInverted} {...rest}>
            {children}
        </BlueButtonContainer>
    );
};

const BlueButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.isSpaceBetween ? 'space-between' : 'center'};
    background-color: ${props => props.isInverted ? '#fff' : colors.blueAzul};
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    border-radius: 26px;
    color: ${props => props.isInverted ? colors.blueAzul : '#fff'};
    min-width: 192px;
    padding: 11px ${props => props.withIcon ? '10px' : '16px'} 11px 16px;
    cursor: pointer;
    ${props => props.isSpaceBetween ? `` : `gap: 4px`};

    :hover {
        background-color: ${props => props.isInverted ? colors.blueAzul : '#1A56CF'};
        ${props => props.isInverted ? `
        color: #fff;
        svg {
            path {
                stroke: #fff;
            }
        }
        ` : ''};
    }

    :active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #1A56CF;
    }

    ${props => props.withIcon && props.iconColor ? `
        svg {
            path {
                stroke: ${props.isInverted ? props.iconColor : '#fff'};
            }
            width: 20px;
            height: 20px;
        }
    `: ``}
`;

export const DemoStartPortfolioButton = styled.div`
  ${flexCenter}
  justify-content: space-between;
  background: #2565E7;
  border-radius: 26px;
  color:#fff;
  margin:0 16px;
  height: ${props => props.height || 40}px;
  padding:0 26px;
  font-weight: 500;
  font-size: 14px;
  svg{
    margin-left: 8px;
    path{
        stroke:#fff;
      }
  } 
  :hover{
    background-color:#1A56CF;
 }
  cursor: pointer;
  ${views.tablet}{
    font-size: 12px;
  }
`;


export const UpgradePortfolioButton = styled.div`
${flexCenter};
height: ${props => props.height ? props.height : '32'}px;
font-size:16px;
font-weight:450;
outline:none;
border:none;
border-radius:6px;
cursor:pointer;
gap: 4px;
padding: 0 8px;
background-color:${props => props.blueBg ? colors.blueAzul : colors.upgradeOrange};
color:#fff;
:hover{
    background-color:${props => props.blueBg ? '#1A56CF' : colors.upgradeOrangeHover};
}
padding: 10px 16px 10px 12px;
cursor: pointer;
`;

export const WhiteButton = styled.div`
    ${flexCenter};
    height: ${props => props.height ? props.height : '32'}px;
    font-size:14px;
    font-weight:450;
    outline:none;
    border:none;
    border-radius:6px;
    cursor:pointer;
    gap: 4px;
    padding: 0 20px;
    color:${colors.darkGray1};
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.3);
    background-color:#fff;
    :hover{
        background-color:${colors.lightGray2};
    }
    cursor: pointer;
`;

export const DeleteButton = styled(SecondaryButtonWithIcon)`
    ${flexCenter}
    padding: 12px 20px 12px 16px;
    gap: 4px;
    color: #ff0000;
    ${props => props.withInvertedColors ? `
        color: #fff;
        background: ${colors.outflowText};
        padding: 10px 24px;
        :hover{
            border: 1px solid ${colors.outflowText};
        };
    
        :active{
            border: 1px solid ${colors.outflowText};
        };
    ` : ``}
`;

export const PrimaryDeleteButton = styled(PrimaryButtonWithIcon)`
    border-radius: 8px;
    background: var(--Color-System-Error, #F00);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    
    :hover{
        background: #ff1a1a;
        filter: brightness(1.2);
    };
    
    :active{
        background: #ff1a1a;
        filter: brightness(1.2);
    };
`;

