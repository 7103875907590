import { LoadingOutlined } from '@ant-design/icons';
import { Radio, Select, Space, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ArrowDownIcon, DownloadIcon, MobileLeftCornerMask, MobileRightCornerMask, TooltipIcon } from '../../../assets/icons/common/common-icons';
import { LimitationModal } from '../../../common/components/LimitationModal/LimitationModal';
import { ModifiedTooltip } from '../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { PrimaryButtonWithIcon, SecondaryButtonWithIcon } from '../../../common/components/buttons/NewButtons.styles';
import { startIntercomNewMessage } from '../../../common/utils/intercom.utils';
import { ls } from '../../../common/utils/localstorage.util';
import { handleUrlFileDownload } from '../../../common/utils/network.utils';
import { isNullOrUndefinedOrEmptyString } from '../../../common/utils/object.utils';
import { ActivationChecklistPane } from '../../activation/components/checklist-pane/ChecklistPane';
import { useStore } from '../../app/data/root.store';
import { AppHeader } from '../../layout/components/Header/Header';
import { PageWrapper, RightSidePushMenuWrapper } from '../../layout/components/Layout.styles';
import { errorNotification, successNotification } from '../../layout/components/Notifications/notifications';
import { DataBoxSubTitle, DataBoxTitle, StyledDatePicker, StyledSelect } from '../../user/components/Profile.styles';
import { RequestPfsModal } from '../RequestPfsModal';
import { ReactComponent as CustomReportIcon } from '../assets/custom-report-icon.svg';
import { ReactComponent as PDFicon } from '../assets/pdf-icon.svg';
import { ReactComponent as XLSicon } from '../assets/xls-icon.svg';
import { ActionButtonsWrapper, CheckboxWrapper, CopyTextWrapper, DataBox, DataBoxHeader, DataBoxHeaderWrapper, DataBoxInputElement, DataBoxInputLabel, DataBoxInputWrapper, FormContentWrapper, GroupedByWrapper, HeaderTitle, MobileHeaderWrapper, PfsPasswordWrapper, ReportsContentWrapper, ReportsMobileHeader, StyledCheckbox } from './Reports.styles';
import { ReportsDataBox } from './ReportsDataBox';
import { ReportsEmptyHero } from './ReportsEmptyHero';
import { AppLoader } from '../../../common/components/Loaders/AppLoader';

// const fileTypesOptions = [{ label: 'PDF file', value:'pdf'  }, { label: 'CSV file', value: 'csv'  }, { label: 'Excel (xlsx)', value:'xls' }];
const FILE_DATE_FORMAT = 'YYYY-M-D';

export const ReportsPage = observer((props) => {
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
  const { Option } = Select;
  const { reportsStore, metadataStore, userStore, billingStore, uiStore } = useStore();
  const [holdingEntities, setHoldingEntities] = useState([]);
  const [isAllEntitiesChecked, setIsAllEntitiesChecked] = useState(true);
  const [holdingEntitiesInputValue, setHoldingEntitiesInputValue] = useState('All entities');
  const [reportOpenByDeepLink, setReportOpenByDeepLink] = useState('');
  const [processingReport, setProcessingReport] = useState(false);
  const [reportDate, setReportDate] = useState(moment());
  const [isPfsProcessing, setIsPfsProcessing] = useState(false);
  const [groupedBySelection, setGroupedBySelection] = useState('');
  // eslint-disable-next-line
  const [pfsRequestSent, setPfsRequestSent] = useState(ls.get('pfsRequestSent'));
  const [isGeneralReportProcessing, setIsGeneralReportProcessing] = useState(false);
  const [pfsPass, setPfsPass] = useState('');
  const selectedFileType = 'pdf';

  const reportsData = {
    'pfs': {
      title: 'Personal Financial Statement (PFS)',
      subtitle: userStore.isFreeTier || userStore.isDemoOrReadOnlyMode ? 'Upgrade to request a PFS' : 'Request a PFS document.',
      isLimited: userStore.isDemoOrReadOnlyMode || !userStore.userLimitations.reporting,
      headerSvg: <XLSicon />,
      alwaysOpen: userStore.isDemoOrReadOnlyMode || userStore.isFreeTier,
      defaultOpen: reportOpenByDeepLink === 'pfs',
    },
    'balanceSheet': {
      title: 'Balance sheet',
      subtitle: 'Total assets / liabilities and net worth report',
      isLimited: userStore.isDemoOrReadOnlyMode || !userStore.userLimitations.reporting,
      headerSvg: <PDFicon />,
      alwaysOpen: false,
      defaultOpen: false, // reportOpenByDeepLink === 'balanceSheet', prep for air-condition
    },
    'generalReport': {
      title: 'General report',
      subtitle: 'Export a general summary of all of your assets.',
      isLimited: userStore.isDemoOrReadOnlyMode || !userStore.userLimitations.reporting,
      headerSvg: <XLSicon />,
      alwaysOpen: false,
      defaultOpen: false, // reportOpenByDeepLink === 'generalReport', prep for air-condition
    },
    'customReports': {
      title: 'Custom reports',
      subtitle: <>
      Request comprehensive reports to see detailed allocations, cash flow and more.
      {!userStore.isTier4 && <> <br/> This service is only available on Elite plan.</>}
    </>,
      //`Request comprehensive reports to see detailed allocations, cash flow and more. ${!userStore.isTier4 ? <br/> + 'This service is only available on Elite plan.' : ''}`,
      isLimited: userStore.isDemoOrReadOnlyMode || !userStore.userLimitations.customReporting,
      headerSvg: <CustomReportIcon />,
      alwaysOpen: false,
      defaultOpen: false, // reportOpenByDeepLink === 'customReports', prep for air-condition
    },
  }

  useEffect(() => {
    const fetchData = async () => {
      await reportsStore.fetchData();
      if (reportsStore.data.length) {
        setPfsPass(reportsStore.data[0].password);
      }
    };
  
    fetchData();
  }, [reportsStore]);

  useEffect(() => {
    const reportNameParam = new URLSearchParams(window.location.search).get('rt');
    const reportName = !isNullOrUndefinedOrEmptyString(reportNameParam) ? reportNameParam : '';
    setReportOpenByDeepLink(reportName);
    const cleanURL = window.location.pathname;
    window.history.replaceState({}, '', cleanURL);
    return () => { }
  }, [])

  useEffect(() => {
    if (metadataStore.isLoaded) {
      setHoldingEntities(metadataStore.beneficiaries.map(entity => ({ ...entity, checked: true })));
    }
    //   return () => {}
  }, [metadataStore.isLoaded, metadataStore.beneficiaries])

  const handleEntitiesSelection = (e, entityName, entityChecked) => {
    e.stopPropagation();
    e.preventDefault();

    const relevantEntityIndex = holdingEntities.findIndex(entity => entity.name === entityName);
    holdingEntities[relevantEntityIndex] = { ...holdingEntities[relevantEntityIndex], checked: !entityChecked };
    setHoldingEntities(holdingEntities);
    if (holdingEntities.every(entity => entity.checked === false)) {
      setHoldingEntitiesInputValue('Please choose at least one entity');
    } else if (holdingEntities.every(entity => entity.checked === true)) {
      setIsAllEntitiesChecked(true);
      setHoldingEntitiesInputValue('All entities')
    } else {
      holdingEntitiesNamesValue();
      if (isAllEntitiesChecked) setIsAllEntitiesChecked(false)
    }
  }

  const handleSelectAll = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const checkedEntities = holdingEntities
    if (isAllEntitiesChecked) {
      setIsAllEntitiesChecked(false)
      setHoldingEntitiesInputValue('Please choose entities')
      checkedEntities.forEach((entity) => { entity.checked = false })

    } else if (!isAllEntitiesChecked) {
      setIsAllEntitiesChecked(true);
      setHoldingEntitiesInputValue('All entities')
      checkedEntities.forEach((entity) => { entity.checked = true })
    }

    setHoldingEntities([...checkedEntities])
  }

  const holdingEntitiesNamesValue = () => {
    const checkedEntities = holdingEntities.filter(entity => entity.checked === true)
    const names = checkedEntities.map(entity => entity.name)
    setHoldingEntitiesInputValue(names.join(', '))
  }

  const handleAllEntitiesDeselected = (e) => {
    if (holdingEntities.every(entity => entity.checked === false)) {
      handleSelectAll(e);
    }
  }

  const handleExportDataParams = async () => {
    if ( uiStore.isMobileAppView ) {
      if (window.ReactNativeWebView){
        window.ReactNativeWebView.postMessage(JSON.stringify({ 
          action:'download_file_api_request' , 
          data : {
            url:`${baseApiUrl}reports/balance`,
            body:{
              reportDate: moment(reportDate).set('D',15).toISOString(),
              holdingEntities: holdingEntitiesInputValue === 'All entities' ? [] :
                holdingEntities.filter(entity => entity.checked).map(entity => entity.name),
              groupBy: groupedBySelection
            } ,
            fileName : `balance-sheet-${moment(reportDate).format(FILE_DATE_FORMAT)}.pdf`
          } 
        }));
      }
    } else {
    try {
      if (processingReport) return;
      if (!reportDate || !moment().isValid(reportDate)) throw Error('Please select a valid date');
      setProcessingReport(true)
      switch (selectedFileType) {
        case 'pdf':
          await handleUrlFileDownload({
            url: `${baseApiUrl}reports/balance`,
            body: {
              reportDate: moment(reportDate).set('D',15).toISOString(),
              holdingEntities: holdingEntitiesInputValue === 'All entities' ? [] :
                holdingEntities.filter(entity => entity.checked).map(entity => entity.name),
              groupBy: groupedBySelection
            },
            fileName: `balance-sheet-${moment(reportDate).format(FILE_DATE_FORMAT)}.pdf`
          })
          setProcessingReport(false)
          break;
        case 'xls':
          // handleDownload({
          //     url: `${baseApiUrl}reports/data-export`,
          //     body: {dataExportType: "default"},
          //     fileName: `balance_sheet_${moment().format('L')}.xlsx`
          // })
          // throw new Error('Not supported')
          break;
        case 'csv':
          // throw new Error('Not supported')
          break;
        default:
          throw new Error('Unknown file requested')
      }
    } catch (e) {
      errorNotification(e.message)
      setProcessingReport(false)
    }
   }
  }

  const handleGeneralReport = async () => {
    if (isGeneralReportProcessing) return;
    if ( uiStore.isMobileAppView ) {
      if (window.ReactNativeWebView){
        window.ReactNativeWebView.postMessage(JSON.stringify({ 
          action:'download_file_api_request' , 
          data : {
            url:`${baseApiUrl}reports/data-export`,
            body:{dataExportType: "general"}, 
            fileName : `vyzer-general-report-${moment().format(FILE_DATE_FORMAT)}.xlsx`
          }
        }));
      }
    } else {
    setIsGeneralReportProcessing(true);
      try {
        await handleUrlFileDownload({
          url: `${baseApiUrl}reports/data-export`,
          body: { dataExportType: "general" },
          fileName: `vyzer-general-report-${moment().format(FILE_DATE_FORMAT)}.xlsx`
        })
      } catch (e) {
        errorNotification(e.message);
      } finally {
        setIsGeneralReportProcessing(false);
      }
    }
  }

  const disabledDate = (current) => {
    return current && moment(current).isAfter(Date.now());
  }

  const handleFormat = (value) => {
    return moment(value).format('MMMM YYYY')
  }

  const handleUpgradeTierClick = async () => {
    // const paymentLink = await billingStore.fetchBillingUrl()
    billingStore.setShowPricingTableModal(true);
  }

  const RequestPFSOnClick = async () => {
    if (!userStore.userLimitations.reporting || userStore.isDemoOrReadOnlyMode) {
      return billingStore.setShowPricingTableModal(true);
    }
    if (isPfsProcessing || pfsRequestSent) return;
    setIsPfsProcessing(true);
    try {
      await reportsStore.getReportByType('personal_finance_statement')
      setPfsPass(reportsStore.data[0].password)
      // await new Promise(resolve => setTimeout(resolve, 1000));
      uiStore.setShowRequestPfsModal(true);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsPfsProcessing(false)
    }
  }

  const CustomReportOnClick = () => {
    startIntercomNewMessage();
    // startIntercomNewMessage(`Hello Vyzer Team,\n\nI am interested in obtaining a custom report tailored to my specific needs.\n\nCould you please assist me in setting this up? I would appreciate any guidance on the next steps in this process.`);
  }

  const handleCopyPfsPass = () => {
    navigator.clipboard.writeText(pfsPass);
    successNotification('Password copied');
  }

  const handleDownloadSamplePfsOnClick = async () => {

    if ( uiStore.isMobileAppView ) {
      if (window.ReactNativeWebView){
        window.ReactNativeWebView.postMessage(JSON.stringify({ 
          action:'download_file_api_request' , 
          data : {
            url:`${baseApiUrl}reports/download-sample`,
            body:{type:'pfs_sample'}, 
            fileName : `Vyzer PFS - Demo Data.xlsx`
          }
        }));
      }
    } else {
      await handleUrlFileDownload({
        url: `${baseApiUrl}reports/download-sample`,
        body: {
          type: 'pfs_sample'
        },
        fileName: `Vyzer PFS - Demo Data.xlsx`
      })
    }
    
  }

return (
  <>
  {uiStore.isDesktopView ? <AppHeader /> : 
      <ReportsMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
        <HeaderTitle>Reports</HeaderTitle>
      </ReportsMobileHeader> } 
    <RightSidePushMenuWrapper>
      {userStore.isViewingOwnPortfolio && userStore.isGetStarted && <ActivationChecklistPane />}
    </RightSidePushMenuWrapper>
    {!metadataStore.isLoaded || reportsStore.isLoading  ?  <AppLoader backdrop /> :
      <><PageWrapper withHeader id='reportsPage' >
        <ReportsContentWrapper>
          <ReportsEmptyHero />

          <>
            {(userStore.isFreeTier || metadataStore.totalItemsCount > 0) &&
              <>
              {!uiStore.isDesktopView &&<MobileHeaderWrapper>
                  Select report
                  <MobileLeftCornerMask style={{position:'absolute', top:'0' , left:'0'}}/>
                  <MobileRightCornerMask style={{position:'absolute', top:'0' , right:'0'}}/>
                </MobileHeaderWrapper>}
              <DataBox>
                {uiStore.isDesktopView &&
                <DataBoxHeaderWrapper>
                  <DataBoxHeader>
                    <DataBoxTitle>Reports</DataBoxTitle>
                    <DataBoxSubTitle>Generate and export financial statements</DataBoxSubTitle>
                  </DataBoxHeader>
                </DataBoxHeaderWrapper> }

                <ReportsDataBox {...reportsData.pfs} >
                  <>
                    {reportsStore.data.length || pfsRequestSent ? <FormContentWrapper>
                      <DataBoxInputWrapper>
                        <DataBoxInputLabel style={{ fontSize: '14px', paddingBottom: '6px' }}>The PFS will be sent to your email within 48 hours</DataBoxInputLabel>
                        <DataBoxInputElement style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                          <PfsPasswordWrapper prefix={<div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}><PfsLockSvg /> File password: </div>} value={pfsPass} />
                          <ModifiedTooltip title={'Copy your file password'}>
                            <CopyTextWrapper onClick={handleCopyPfsPass}>Copy <CopyIcon /></CopyTextWrapper>
                          </ModifiedTooltip>
                        </DataBoxInputElement>
                      </DataBoxInputWrapper>
                    </FormContentWrapper> : null}
                    <ActionButtonsWrapper> 
                      <PrimaryButtonWithIcon style={{ height: '38px' }} onClick={RequestPFSOnClick} isProcessing={pfsRequestSent || isPfsProcessing}>
                        {isPfsProcessing ? <LoadingOutlined /> : pfsRequestSent ? <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}><WhiteCheckMark /> Request Sent</div> : 'Request statement'}
                      </PrimaryButtonWithIcon>
                      <ModifiedTooltip title={'Download sample report with demo data'}>
                        <SecondaryButtonWithIcon style={{  height: '38px' }} onClick={handleDownloadSamplePfsOnClick}>
                          <BlackDownloadSvg /> Download sample
                        </SecondaryButtonWithIcon>
                      </ModifiedTooltip>
                    </ActionButtonsWrapper>
                  </>
                </ReportsDataBox>

                <ReportsDataBox {...reportsData.balanceSheet} >
                  <>
                    <FormContentWrapper>
                      <DataBoxInputWrapper>
                        <DataBoxInputLabel withIcon>
                          Report date
                          <Tooltip title={'Generate report from a specific date in order to get a snap-shot of your wealth at date.'} placement="top"><TooltipIcon /></Tooltip>
                        </DataBoxInputLabel>
                        <DataBoxInputElement>
                          <StyledDatePicker
                            value={reportDate}
                            name='reportDate'
                            picker='month'
                            onChange={(value) => { setReportDate(value || moment(new Date().toISOString())) }}
                            disabledDate={disabledDate}
                            format={handleFormat} />
                          {/* name='reportDate' onChange={(val) => { handleFieldChange('reportDate', val) }} /> */}
                        </DataBoxInputElement>
                      </DataBoxInputWrapper>
                      <DataBoxInputWrapper>
                        <DataBoxInputLabel>
                          Holding entity
                        </DataBoxInputLabel>
                        <DataBoxInputElement>
                          <StyledSelect name='holdingEntity' onBlur={handleAllEntitiesDeselected}
                            value={holdingEntitiesInputValue}
                            suffixIcon={<ArrowDownIcon />}
                            getPopupContainer={() => document.getElementById('reportsPage')}
                            >
                            <Option key='all-entities' value='all-entities'>
                              <CheckboxWrapper width={'100%'} onClick={(e) => handleSelectAll(e)}>
                                <StyledCheckbox type="checkbox" name='all entities' checked={isAllEntitiesChecked}>All entities</StyledCheckbox>
                              </CheckboxWrapper>
                            </Option>
                            {holdingEntities.map((entity, index) => (
                              <Option key={entity.name} value={index}>
                                <CheckboxWrapper width={'100%'} onClick={(e) => handleEntitiesSelection(e, entity.name, entity.checked)}>
                                  <StyledCheckbox type="checkbox" checked={entity.checked}>{entity.name}</StyledCheckbox>
                                </CheckboxWrapper>
                              </Option>))
                            }
                          </StyledSelect>
                        </DataBoxInputElement>
                      </DataBoxInputWrapper>
                      <CheckboxWrapper>
                        {/* <StyledCheckbox type="checkbox" name='inputKey' onClick={() => setIsGroupAssetsByClasses(!isGroupAssetsByClassesChecked)} checked={isGroupAssetsByClassesChecked}> */}
                        <GroupedByWrapper>
                          Group assets by:
                          <Radio.Group onChange={(e) => { setGroupedBySelection(e.target.value) }} value={groupedBySelection}>
                            <Space direction='vertical'>
                              <Radio value='classes'>Classes</Radio>
                              <Radio value='customClasses'>Custom classes</Radio>
                              <Radio defaultChecked value=''>None</Radio>
                            </Space>
                          </Radio.Group>
                        </GroupedByWrapper>
                        {/* </StyledCheckbox> */}
                      </CheckboxWrapper>
                    </FormContentWrapper>
                    <ActionButtonsWrapper>
                      <PrimaryButtonWithIcon style={{ width: '202px', height: '38px' }} onClick={handleExportDataParams} isProcessing={processingReport}>
                        {processingReport ? <LoadingOutlined /> : <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}><DownloadIcon /> Create & download</div>}
                      </PrimaryButtonWithIcon>
                      {/* <SimpleSelect value={selectedFileType}
                              buttonStyle={{borderRadius:'8px'}}
                              placement={"bottomLeft"}
                              width={'140px'}
                              options={fileTypesOptions}
                              withCheckMark
                              withBorders
                              borderRadius={'8px'}
                              onChange={(val) => { setSelectedFileType(val) }} /> */}
                    </ActionButtonsWrapper>
                  </>
                </ReportsDataBox>

                <ReportsDataBox {...reportsData.generalReport} >
                  <ActionButtonsWrapper>
                    <PrimaryButtonWithIcon style={{ width: '202px', height: '38px' }} onClick={handleGeneralReport} isProcessing={isGeneralReportProcessing}>
                      {isGeneralReportProcessing ? <LoadingOutlined /> : <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}><DownloadIcon />Create & download</div>}
                    </PrimaryButtonWithIcon>
                  </ActionButtonsWrapper>
                </ReportsDataBox>

                <ReportsDataBox {...reportsData.customReports} >
                  <ActionButtonsWrapper>
                    <PrimaryButtonWithIcon onClick={CustomReportOnClick}>
                      Request custom report
                    </PrimaryButtonWithIcon>
                  </ActionButtonsWrapper>
                </ReportsDataBox>

              </DataBox></>
            }</>

        </ReportsContentWrapper>
      </PageWrapper>
        {userStore.data?.profile?.showFreeTierReportsPopup && <LimitationModal modalType={'reports'} onUpgrade={() => { handleUpgradeTierClick() }} onClose={() => { userStore.closeFreeTierReportsModal() }} />}
        {uiStore.showRequestPfsModal ? <RequestPfsModal onClose={() => { uiStore.setShowRequestPfsModal(false); ls.set('pfsRequestSent', true); setPfsRequestSent(true); }} /> : null}
      </>
    }</>
)
})

const BlackDownloadSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 15.25L12 4.75" stroke="#1A1B1D" strokeWidth="1.5" />
      <path d="M15.5 11.75L12 15.25L8.5 11.75" stroke="#1A1B1D" strokeWidth="1.5" />
      <path d="M5 13.3895V18.25C5 18.8023 5.44772 19.25 6 19.25L18 19.25C18.5523 19.25 19 18.8023 19 18.25L19 13.25" stroke="#1A1B1D" strokeWidth="1.5" />
    </svg>
  )
}

const WhiteCheckMark = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5106 8.60403L10.5061 15.6125L7.00008 12.1076" stroke="white" strokeWidth="2" />
    </svg>
  )
}

const PfsLockSvg = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.99989 1.99686C7.0738 1.99686 5.51239 3.55826 5.51239 5.48436V6.89061H3.375V15.3281H14.625V6.89061H12.4874V5.48436C12.4874 3.55826 10.926 1.99686 8.99989 1.99686ZM11.1374 6.89061V5.48436C11.1374 4.30385 10.1804 3.34686 8.99989 3.34686C7.81938 3.34686 6.86239 4.30385 6.86239 5.48436V6.89061H11.1374ZM8.99989 8.29686C8.31644 8.29686 7.76239 8.85091 7.76239 9.53436C7.76239 10.0153 8.03672 10.4321 8.43744 10.637V12.5156C8.43744 12.8263 8.68928 13.0781 8.99994 13.0781C9.3106 13.0781 9.56244 12.8263 9.56244 12.5156V10.6369C9.9631 10.4321 10.2374 10.0152 10.2374 9.53436C10.2374 8.85091 9.68334 8.29686 8.99989 8.29686Z" fill="black" />
    </svg>
  )
}

const CopyIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.0625" y="4.375" width="6.5625" height="7.875" rx="0.4375" stroke="#2565E7" strokeWidth="1.05" />
      <path d="M5.25 2.625H10.5C10.9832 2.625 11.375 3.01675 11.375 3.5V9.625" stroke="#2565E7" strokeWidth="1.05" />
    </svg>
  )
}