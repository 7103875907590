// import moment from "moment";
import { CURRENCY_SYMBOL } from "../../../../common/constants/currency-symbol.const"

export const exitEventFieldRow = ({ isEditForm, autoCalculateTooltip, validations = [] }) => ({
  fields: [
    {
      key: 'exitEvent', type: 'exitEventRow',
      label: 'Expected exit event',
      labelId: 'expected-exit-event',
      generatePropsFn: (formValues) => {
        return {
          prefix: CURRENCY_SYMBOL[formValues.currency] || 'USD',
          placeholder: CURRENCY_SYMBOL[formValues.currency],
          holdings: formValues.holdings,
          startDate: formValues.startDate,
        }
      },
      autoCalculateTooltip,
      ...(validations.length > 0 ? {
        validations
      } : {}),
      setFnForSave: (isDistribution, value, formValues) => {
        return isEditForm ? value : (formValues?.expectedEndDate ? {date: formValues?.expectedEndDate, isAutoCalculated:true, stopCreatingFutureEventsAfterExitEvent: true} : {})
      },
    }
  ]
})

export const simpleExitEventFieldRow = ({ isEditForm, autoCalculateTooltip, validations = [] }) => ({
  fields: [
    {
      key: 'exitEvent', type: 'simpleExitEventRow',
      label: 'Expected exit event',
      labelId: 'expected-exit-event',
      generatePropsFn: (formValues) => {
        return {
          prefix: CURRENCY_SYMBOL[formValues.currency] || 'USD',
          placeholder: CURRENCY_SYMBOL[formValues.currency],
          holdings: formValues.holdings,
          startDate: formValues.startDate,
        }
      },
      ...(validations.length > 0 ? {
        validations
      } : {}),
      autoCalculateTooltip,
      setFnForSave: (isDistribution, value, formValues) => {
        return isEditForm ? value : (formValues?.expectedEndDate ? {date: formValues?.expectedEndDate, isAutoCalculated:true, stopCreatingFutureEventsAfterExitEvent: true} : {})
      },
    }
  ]
})