import styled from "@emotion/styled/macro";
import { colors, shadows } from "../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../common/styles/combos.styles";

export const ModalBalanceWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    gap: 8px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 4px;
`;

export const ModalBalanceTitle = styled.div`
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #979797;
`;

export const ModalBalanceAmount = styled.div`
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: #2A2D33;
`;

export const ModalSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray2};
`;

export const ModalEditNameWrapper = styled(ModalSectionWrapper)`
    .ant-input{
        color:${props=>props.textColor && '#FF7676' };
        font-size:18px;
        border-color:${props=>props.withError ? '#FF7676' : '#d9d9d9'};
        border-radius: 8px;
        &:hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
            box-shadow: none;
        }
    }

    .ant-input-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }
`;
export const ModalPromptWrapper = styled.div`
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #585D66;
    white-space: pre-line;
`;

export const PromptQuestion = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray1};
    padding-bottom: 4px;
`;

export const RadioSelectedOptionWrapper = styled.div`
    ${flexCenter};
    box-shadow: ${shadows.toggleShadow};
    border-radius: 25px;
    border: 1px solid ${colors.gray3};
`;

export const OwnershipTextWrapper = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: ${props=>props.disabled ? colors.gray3 : null};
    pointer-events: none;
`;
export const HoldingsInputWrapper = styled(ModalEditNameWrapper)``;

export const VyzerMembershipLink = styled.div`
    text-decoration: underline;
`;

export const AccountRowWrapper = styled.div`
    display:flex;
    flex:1;
    align-items: center;
    padding:16px 0px;
    background-color: ${colors.lightGray1};
    border-radius: 8px;
`;

export const DisableModalAccountRowWrapper = styled(AccountRowWrapper)`
    border: 1px solid ${colors.darkGray1};
    margin-top: 16px;
    margin-bottom: 56px;
    // display:flex;
    // flex:1;
    // align-items: center;
    // padding:16px 0px;
    // background-color: ${colors.lightGray1};
    // border-radius: 8px;
`;

export const DisableModalAccountRowTitlesWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 8px;
`;

export const DisableModalAccountRowTitle = styled.div`
    display: flex;
    align-items: center;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray1};
    overflow-wrap: anywhere;
`;

export const DisableModalAccountRowSubTitle = styled.div`
    display: flex;
    align-items: center;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    color: ${colors.darkGray3};
`;

export const AccountBalanceAndActions = styled.div`
    display:flex;
    flex-direction:row;
    align-items:flex-end;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 12px;
`;

export const AccountBalance = styled.div`
    display: flex;
    font-size: 14px;
    line-height: 18px;
    ${props=>props.isPositive ? `
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        color: #20CD7B;
    ` : ``};
`;