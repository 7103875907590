import { Observer } from 'mobx-react';
import React from 'react'
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { BottomMessage, LineSeparator, ModalBgWrapper, ModalCloseButtonWrapper, ModalContent, TitleText } from './GoodbyeModal.styles';
import { ModalCloseIcon } from '../../../../assets/icons/common/common-icons';
import { useStore } from '../../../app/data/root.store';
import {ReactComponent as MessageImg } from './assets/goodbye-img.svg';
import { GetInTouchDropDown } from '../GetInTouchDropDown/GetInTouchDropDown';
import { useTrackEvent } from '../../../../hooks/useTrackEvent/useTrackEvent'
// import { ModalBackDrop, ModalWrapper } from '../../../../common/components/BaseModal/BaseModal.styles'
// import {
//   EyesIcon,
//   // JumpIcon
// } from './assets/icons';
// import {
//   CloseButtonWrapper, DemoModalHeader, DemoModeModalActionsWrapper, DemoModeModalIconsWrapper, DemoModeModaltext, DemoModeModalTitle, DemoModeModalWrapper, LetsStartButtonWrapper,
//   // StayInDemoButtonWrapper
// } from './DemoModeModal.styles';

export const GoodByeModal = (props) => {
  const { onBackDropClick } = props;
  const { userStore } = useStore();

  useTrackEvent('Popup Viewed', {
    type: 'Goodbye Modal', 
    title: 'Sorry you are leaving',
  });

  return (<Observer>{() => (
    <BaseModal width={'500px'} height={'auto'} borderRadius={8} >
        <ModalBgWrapper>
            <ModalCloseButtonWrapper onClick={onBackDropClick}>
                <ModalCloseIcon />
            </ModalCloseButtonWrapper>
            <ModalContent>
              <TitleText>Sorry you are leaving, {userStore.firstNameCapitalize}!</TitleText>
              <BottomMessage>
              Renew your membership any time to reactivate your account.<br/>
              In the meantime, your data is secure and in read-only mode;<br/>
              Vyzer will continue to send you account notifications and sync<br/>
              your accounts.
              </BottomMessage>
              <MessageImg />
            </ModalContent>
            <LineSeparator />
            <GetInTouchDropDown />
        </ModalBgWrapper>
    </BaseModal>
  )}</Observer>)
}