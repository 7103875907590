import React , { useState , useCallback , useEffect } from 'react';
import { Observer } from 'mobx-react';
// import moment from 'moment';
import { useHistory } from "react-router-dom";
import { navigateToUrl } from '../../../../../common/utils/navigation.utils'; 
import { PieWrapper } from './ItemPieChart.styles';
// import { Doughnut } from 'react-chartjs-2';
import { useStore } from '../../../../app/data/root.store';
import { ChartComponent } from '../../../../../common/components/charts/ChartComponent';
import { customTooltip } from './chartTooltips';
// import { AssetConnectedLiability } from '../../../pages/asset/components/AssetConnectedLiability';
// import { displayMoneyFormatter, displayMoneyValue } from '../../../../../common/utils/number-display.utils';
// import { customTooltip } from './chartTooltips';

export function ItemPieChart(props) {
  const { itemStore, tickersStore, metadataStore } = useStore();
  let history = useHistory();
  const hasConnectedLiability = itemStore.item?.connectedLiabilities?.length > 0;
  const hasTickers = itemStore.isShowingTickers;
  const [chartOptions, setChartOptions] = useState({cutout:'95%'});
  const liabilitiesLength = itemStore.item.connectedLiabilities.length
  // let liabilityId
  const getPieToolTipData =  useCallback((dataSetIndex, dataIndex) => {
   //const getPieToolTipData =  (dataSetIndex, dataIndex) =>{
    if(dataIndex < liabilitiesLength){
      const liabilityId = itemStore.item.connectedLiabilities[dataIndex].liabilityId;
      const liability =  metadataStore.loansObj[liabilityId];
      return { liability }
    }
  },[liabilitiesLength,itemStore,metadataStore]);

  const getChartConfig =  useCallback(() => {
    return { 
      maintainAspectRatio: true,
      cutout: (hasConnectedLiability ? 85 : hasTickers ? 90 : 95) + '%',
      // tooltips: false,
      // tooltips: { enabled: false },
      ...(hasTickers ? {
        onHover: (event, chartElement) => {
          // event.target.style.cursor = 'default';
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          if (chartElement.length) {
            const { _datasetIndex: dataSetIndex, _index: dataIndex } = chartElement[0];
            // const relevantItem = wealthStore.selectedClass.items[dataIndex];
            const relevantItem = (dataSetIndex === 0 ? {
              title: tickersStore.tickers[dataIndex].name,
              value: tickersStore.tickers[dataIndex].value,
              currency: tickersStore.tickers[dataIndex].isoCurrencyCode,
            } : hasConnectedLiability && dataSetIndex === 2 && dataIndex < itemStore.item.connectedLiabilities.length ?
              itemStore.item.connectedLiabilities[0] : null);
              itemStore.setAssetOverviewHeroHoverData(relevantItem ? {
              // dataSetIndex,
              // dataIndex,
              show: true,
              title: relevantItem.title,
              value: relevantItem.value,
              currency: relevantItem.currency,
              isHoveredOnce: true,
              ...(hasConnectedLiability && dataSetIndex === 2 ? {isConnectedLiabilityHovered: true}: {}),
            } : { show: false, isHoveredOnce: true })
          } else {
            itemStore.setAssetOverviewHeroHoverData({
              show: false,
              isHoveredOnce: true,
              isConnectedLiabilityHovered: false,
            })
          }
        }
      } :
      !hasTickers && hasConnectedLiability ? {
        onHover: (event, chartElement) => {
        event.native.target.style.cursor = 'default';
        // event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        if (chartElement.length) {
          const { _datasetIndex: dataSetIndex, _index: dataIndex } = chartElement[0];
          if (chartElement[0].datasetIndex === 2 && chartElement[0].index < liabilitiesLength) {
            event.native.target.style.cursor = 'pointer';
          } 

          const relevantItem = (hasConnectedLiability && dataSetIndex === 2 && dataIndex < itemStore.item.connectedLiabilities.length ?
            itemStore.item.connectedLiabilities[0] : null);
            itemStore.setAssetOverviewHeroHoverData(relevantItem ? {
            // dataSetIndex,
            // dataIndex,
            show: true,
            title: relevantItem.name,
            value: relevantItem.value,
            currency: relevantItem.isoCurrencyCode || relevantItem.currency,
            isHoveredOnce: true,
            ...(hasConnectedLiability && dataSetIndex === 2 ? {isConnectedLiabilityHovered: true}: {}),
          } : { show: false, isHoveredOnce: true })
        } else {
          itemStore.setAssetOverviewHeroHoverData({
            show: false,
            isHoveredOnce: true,
            isConnectedLiabilityHovered: false,
          })
        }
      }} : {}),
      onClick:(event,chartElement) => {
        if (chartElement[0]?.datasetIndex && chartElement[0].datasetIndex === 2 && chartElement[0].index < liabilitiesLength) {
          const liabilityId = itemStore.item.connectedLiabilities[chartElement[0].index].liabilityId;
          navigateToUrl(history, `/wealth/assets/loans/${liabilityId} `)
          document.getElementById('itempage-chartjs-tooltip').style.opacity = 0;
        } 
      },
      plugins:{
         tooltip: customTooltip(getPieToolTipData),
        // tooltip: {enabled: false},      
      }
    }
  }, [itemStore, tickersStore.tickers, hasConnectedLiability, hasTickers, liabilitiesLength,getPieToolTipData,history])

  useEffect(() => {
    
    // optionsRef.current = getChartConfig();
    setChartOptions(getChartConfig());
    return () => {
    }
  }, [getChartConfig])

  // const getOptions = (hasConnectedLiability, hasTickers) => {
  //   return {
  //     maintainAspectRatio: true,
  //     cutoutPercentage: hasConnectedLiability ? 85 : hasTickers ? 90 : 95,
  //     // tooltips: false,
  //     tooltips: { enabled: false },
  //     ...(hasTickers ? {
  //       onHover: (event, chartElement) => {
  //         event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
  //         if (chartElement.length) {
  //           const { _datasetIndex: dataSetIndex, _index: dataIndex } = chartElement[0];

  //           // const relevantItem = wealthStore.selectedClass.items[dataIndex];
  //           const relevantItem = (dataSetIndex === 0 ? {
  //             title: tickersStore.tickers[dataIndex].name,
  //             value: tickersStore.tickers[dataIndex].value,
  //             currency: tickersStore.tickers[dataIndex].isoCurrencyCode,
  //           } : hasConnectedLiability && dataSetIndex === 2 && dataIndex < itemStore.item.connectedLiabilities.length ?
  //             itemStore.item.connectedLiabilities[0] : null);
  //             itemStore.setAssetOverviewHeroHoverData(relevantItem ? {
  //             // dataSetIndex,
  //             // dataIndex,
  //             show: true,
  //             title: relevantItem.title,
  //             value: relevantItem.value,
  //             currency: relevantItem.currency,
  //             isHoveredOnce: true,
  //             ...(hasConnectedLiability && dataSetIndex === 2 ? {isConnectedLiabilityHovered: true}: {}),
  //           } : { show: false, isHoveredOnce: true })
  //         } else {
  //           itemStore.setAssetOverviewHeroHoverData({
  //             show: false,
  //             isHoveredOnce: true,
  //             isConnectedLiabilityHovered: false,
  //           })
  //         }
  //       }
  //     } :
  //     !hasTickers && hasConnectedLiability ? {
  //       onHover: (event, chartElement) => {
  //       event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
  //       if (chartElement.length) {
  //         const { _datasetIndex: dataSetIndex, _index: dataIndex } = chartElement[0];

  //         const relevantItem = (hasConnectedLiability && dataSetIndex === 2 && dataIndex < itemStore.item.connectedLiabilities.length ?
  //           itemStore.item.connectedLiabilities[0] : null);
  //           itemStore.setAssetOverviewHeroHoverData(relevantItem ? {
  //           // dataSetIndex,
  //           // dataIndex,
  //           show: true,
  //           title: relevantItem.name,
  //           value: relevantItem.value,
  //           currency: relevantItem.isoCurrencyCode,
  //           isHoveredOnce: true,
  //           ...(hasConnectedLiability && dataSetIndex === 2 ? {isConnectedLiabilityHovered: true}: {}),
  //         } : { show: false, isHoveredOnce: true })
  //       } else {
  //         itemStore.setAssetOverviewHeroHoverData({
  //           show: false,
  //           isHoveredOnce: true,
  //           isConnectedLiabilityHovered: false,
  //         })
  //       }
  //     }} : {})
  //   }
  // }

  return (<Observer>{() => (
    <PieWrapper withBottomPadding={!itemStore.item.isConnected}>
      <ChartComponent type='doughnut' data={itemStore.itemPieChartData} options={chartOptions} />
      {/* <Doughnut
        data={itemStore.itemPieChartData}
        // width={244}
        // height={260}
        width={300}
        height={276}
        options={getOptions(itemStore.item?.connectedLiabilities?.length > 0, itemStore.isShowingTickers)} /> */}
   
    </PieWrapper>
  )}</Observer>)
}   