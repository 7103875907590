import styled from "@emotion/styled";
import { PrimaryButton, SecondaryButton } from "../../../../../../common/components/buttons/NewButtons.styles";
import { colors, shadows, views } from "../../../../../../common/styles/theme.styles";

export const CloseItemModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CloseItemModalHeader = styled.div`
  display: flex;
  flex:1;
  height: 64px;
  padding: 16px 15px 16px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0px -1px 0px 0px #DDE3EB inset;
`;

export const CloseItemModalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${colors.darkGray1};
  font-size: 16px;
  font-weight: 500;
`;

export const ModalCloseButtonWrapper = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

export const ItemTitleWrapper = styled.div`
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${props => props.isFixed ? '#fff' : colors.darkGray1};
    align-items: center;
    padding-right: 16px;
    max-width: 40vw;
    // ${views.tablet} {
    //     flex-direction: column;
    //     align-items: flex-start;
    //     gap: 4px;
    // }
`;

export const CloseItemContentWrapper = styled.div`
  display: flex;
  width: 600px;
  padding: ${props => props.confirmClose ? `32px 64px 50px` : `32px 68px`};
  flex-direction: column;
  align-items: center;
  gap: 44px;
  // flex-shrink: 0;
  color: ${props => props.confirmClose ? colors.darkGray2 : colors.darkGray1};
  text-align: center;
  font-size: ${props => props.confirmClose ? '12' : '14'}px;
  font-weight: 450;
  line-height: 21px;
  align-self: center;
  ${views.tablet}{
    width:100%;
    padding:32px 24px;
    gap:16px;
  }
  overflow-y: auto;
`;

export const CloseItemContentPromptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  white-space: pre-line;
`;

export const PromptTitle = styled.div`
  color: ${colors.darkGray1};
  text-align: center;
  font-family: Domine;
  font-size: 22px;
  font-weight: 400;
`;

export const CloseItemFieldsWrapper = styled.div`  
  display: flex;
  width: 464px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  ${views.tablet}{
    width:100%;
  }
`;

export const CloseItemFieldWithLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const FieldLabel = styled.div`
  color: ${colors.darkGray1};
  font-size: 15px;
  font-weight: 500;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  ${shadows.cardShadow};
`;

export const RadioOption = styled.div`
  display: flex;
  height: 56px;
  padding-right: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border: 1px solid ${colors.gray3};
  :first-of-type {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  :last-of-type {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  :hover {
    border: 1px solid ${props=>props.isBlueBorder ? colors.blueAzul : colors.darkGray2};
  }
  ${props => props.isActive ? `
  border: 1px solid ${props.isBlueBorder ? colors.blueAzul : colors.darkGray2};
  ${props.isBlueBorder ? `color: ${colors.blueAzul};` : ``}
  ` : ``}
  
`;

export const RadioOptionCircleWrapper = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 30px;
  border: 1px solid var(--Gray-3, #DDE3EB);
`;

export const OptionCircleWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

export const CloseItemNoteText = styled.div`
  color: ${colors.blueAzul};
  font-weight: 500;
`;

export const CloseItemFooterWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 24px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  box-shadow: 0px 1px 0px 0px #E7E8EA inset;
`;

export const ActionButton = styled(PrimaryButton)`
  height: auto;
  min-height: 38px;
  text-align: center;
  align-self: stretch;
  background-color: ${colors.blueAzul};
  :hover {
    background-color: ${colors.blueAzul};
    opacity: 0.9;
    ${props => props.disabled ? `
    opacity: 0.3;
  ` : ``}
  }
  ${props => props.disabled ? `
    opacity: 0.3;
  ` : ``}
`;

export const KeepAssetButton = styled(SecondaryButton)`
  height: auto;
  min-height: 38px;
  padding: 8px 20px;
  text-align: center;
  align-self: stretch;
  :hover {
    ${props=>props.disabled ? `border: 1px solid transparent;` : ``}
  }
`;

export const StyledLink = styled.span`
  color: ${colors.darkGray1};
  cursor: pointer;
  text-decoration: underline;
  :hover {
    color: ${colors.darkGray2};
  }
`;