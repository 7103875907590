import styled from '@emotion/styled/macro';
import { colors, views } from '../../../../../common/styles/theme.styles';
import { flexCenter } from '../../../../../common/styles/combos.styles';

export const Wrapper = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    font-size:14px;
    line-height:16px;
    font-weight:400;
    color:${colors.darkGray1};
    padding: 12px 24px;
    cursor: pointer;
    &:hover{
        background-color:#F1F3F5;
        :last-of-type {
            border-radius: 0 0 8px 8px;
        }
    }
`

export const TextContentWrapper = styled.div`
    display:flex;
    flex-direction:column;
`;

export const FrequencyIcon = styled.div`
    width:26px;   
`;

export const TextWrapper = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
`;

export const Title = styled.div`
    display: flex;
    /* font-size:14px;
    font-weight:400; */
    padding-bottom:4px;
`;

const SimulationRowBadge = styled.div`
    ${flexCenter};
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    gap: 4px;
    padding: 2px 6px;
    border-radius: 4px;
`;

export const OverdueBadgeWrapper = styled(SimulationRowBadge)`
    background-color: #FAE4E4;
    color: #E43A04;
    margin-left: 6px;
    svg{
        width: 13px;
        min-width:13px;
        height: 13px;
    }
`;

export const PeriodWrapper = styled.div`
    display: flex;
    gap: 4px;
`;

export const DateText = styled.div`
    font-size:14px;
    color:${colors.darkGray3};
`;

export const Period = styled(DateText)``;

export const ValueWrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.cashIn ? '#6EBA7F' : colors.darkGray1};
`;

export const Amount = styled.div`

`;

export const SwitchWrapper = styled.div`
    margin-left:16px;
    .ant-switch{
        /* border-radius:0px; */
        margin-top:-2px;
    } 
    .ant-switch-checked {
        background: #B704E4;
    }
    /* .ant-switch-handle::before{
        border-radius:0px;
    } */
`;

export const ActionMenuWrapper = styled.div`
    margin-left:8px;
    padding:0px 4px;
    cursor: pointer;
`;

export const ThreeDotsIconWrapper = styled.div`
    display: grid;
    place-items: center;
    cursor: pointer;
    /* padding: 12px 4px; */
    // transform:rotate(90deg);
    svg{ width: 20px}
`;

export const SimulationRowActionMenuWrapper = styled.div`
    position: relative;
`;

export const SimulationRowActionMenuContent = styled.div`
    ${views.desktop}{
        position: absolute;
    }
    width: 180px;
    border: 1px solid #DDE3EB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    top: -28px;
    left: 100%;
    margin-left: 20px;
`;

export const SimulationRowActionMenuItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
    padding: 8px;
    padding-left: 12px;
    cursor: pointer;
    background-color: #FFFFFF;
    animation: all 0.2s;

    :first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    :last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    :hover {
        background: linear-gradient( 90deg, #000  2px, #F7F8F9 2px);
    }
`;