import { keyframes } from '@emotion/react';

export const animateSpin = keyframes`
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
`;

export const easeIntoView = keyframes`
    0% {
       opacity: 0;
       transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

export const rotateMount = (rotateFrom, rotateTo) => keyframes`
    0% {
        opacity: 0;
        transform: rotate(${rotateFrom}deg) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: rotate(${rotateTo}deg) scale(1);
    }
`;

export const rotateUnmount = (rotateFrom, rotateTo) => keyframes`
    0% {
       opacity: 1;
       /* transform: scale(0); */
       transform: rotate(${rotateFrom}deg) scale(1);
    }
    100% {
        opacity: 0;
        transform: rotate(${rotateTo}deg) scale(0.5);
        /* transform: scale(1); */
    }
`;