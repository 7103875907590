import { useEffect } from "react";
import { useAnalytics } from "../../modules/app/data/AnalyticsProvider";

export const useTrackEvent = (eventName, eventParams = {}, dependencies = [], identify) => {
    const { mixpanel, mixpanelTrack } = useAnalytics();
    useEffect(() => {
        mixpanel.config && mixpanelTrack(eventName, eventParams);
    }, [mixpanel, mixpanelTrack, eventName, ...dependencies]); /* eslint-disable-line react-hooks/exhaustive-deps */
}

export const useTrackGTMEvent = (eventName, eventParams = {}, dependencies = []) => {
    const { sendDataToGTM } = useAnalytics();
    useEffect(() => {
        sendDataToGTM(eventName, eventParams);
    }, [sendDataToGTM, eventName, ...dependencies]); /* eslint-disable-line react-hooks/exhaustive-deps */
};