import { observer } from 'mobx-react'
import { Drawer } from 'antd'
import React from 'react'
import { LinkArrowIcon } from '../../../assets/icons/common/common-icons'
import { useTrackEvent } from '../../../hooks/useTrackEvent/useTrackEvent'
import { useStore } from '../../../modules/app/data/root.store'
import { isNullOrUndefinedOrEmptyString } from '../../utils/object.utils'
import { BaseModal } from '../BaseModal/BaseModal'
import { ModalFooter } from '../BaseModal/BaseModal.styles'
import { CommonBadge } from '../CommonBadge/CommonBadge'
import { PrimaryButtonWithIcon } from '../buttons/NewButtons.styles'
import { DescriptionWrapper, IconWrapper, LimitationModalWrapper, LinkArrowIconWrapper, MaybeLaterButton, ModalCloseButtonWrapper, ModalContent, ModalLeftPaneWrapper, TitleWrapper } from './LimitationModal.styles'
import { AddItemsLimitation, CommunityLimitation, DemoDataLimitation, MagicBoxLimitation, ReportsLimitation, ScenarioLimitation, VipSyncLimitation } from './icons/limitation-icons'
import AddItemsPng from './images/add-items-bg.png'
import CommunityPng from './images/community-bg.png'
import DemoPortfolioPng from './images/demo-portfolio-bg.png'
import MagicBoxPng from './images/magic-box-bg.png'
import MagicBoxMobilePng from './images/magic-box-mobile-bg.png'
import ScenarioPng from './images/scenario-bg.png'
import VipSyncPng from './images/vip-sync-bg.png'
import { useAnalytics } from '../../../modules/app/data/AnalyticsProvider'


const numOfFilesText = (sum, itemsText, innerText) => {
  if (isNullOrUndefinedOrEmptyString(sum)) return '';
  return `${sum === 1 ? 'One' : sum} ${innerText ? innerText + ' ' : ''}${itemsText}${sum !== 1 ? 's' : ''}`
}

export const LimitationModal = observer((props) => {
  const { uiStore } = useStore();
  return (
    uiStore.isDesktopView ? 
  <BaseModal width={'726px'} height={'456px'} borderRadius={8} >
      <LimitationModalContent {...props} />
  </BaseModal> : 
              <Drawer
              closable={false}
              visible={true}
              width={  340  }
              height={'100%'}
              className={'limitationWarningDrawerContainer_mobile'}
              placement={'bottom'}
              destroyOnClose={true}
              onClose={()=>{ props.onClose()}} >
           <LimitationModalContent {...props} isMobile={true}/>
      </Drawer>
  )
});

export const LimitationModalContent = observer(({ modalType, onClose, onStartMyPortfolio, onUpgrade, paramsForText, isMobile }) => {
  const { mixpanelTrack } = useAnalytics();
  const { userStore } = useStore();


  const modalTypes = {
    magicBox: {
      limitationIcon: <MagicBoxLimitation />,
      title: 'Magic Box',
      mixpanelTitle: 'Magic Box Limit',
      description: `You have reached the limit of ${(paramsForText?.[0]) || ''} files this month. Upgrade now to gain more magic box capacity or wait till next month, to seamlessly set up or streamline updates.`,
      bgImage: MagicBoxPng,
      mobileBgImage: MagicBoxMobilePng
    },
    magicBoxFilesLeft: {
      limitationIcon: <MagicBoxLimitation />,
      title: 'Magic Box',
      mixpanelTitle: 'Magic Box Limit',
      description: `Upload Limit Notice -  Your plan allows ${numOfFilesText(paramsForText?.[1], 'file') || ''} per month. You have ${numOfFilesText(paramsForText?.[0], 'upload') || ''} left this month.\nUpgrade for more`,
      sidePadding: 56,
      bgImage: MagicBoxPng,
      mobileBgImage: MagicBoxMobilePng
    },
    scenarios: {
      limitationIcon: <ScenarioLimitation />,
      title: 'Scenarios',
      mixpanelTitle: 'Scenarios Limit',
      description: `You have reached the limit of ${(paramsForText?.[0]) || ''} cash flow scenarios. Upgrade to create more scenarios.`,
      bgImage: ScenarioPng,
    },
    reports: {
      limitationIcon: <ReportsLimitation />,
      title: 'Reports',
      mixpanelTitle: 'Report Page Limit',
      description: `Upgrade to create, view, and export financial statements.`,
      bgImage: ScenarioPng,
    },
    community: {
      limitationIcon: <CommunityLimitation />,
      title: 'Community Benchmarking',
      mixpanelTitle: 'Community Page Limit',
      description: `Upgrade to access investment strategies and insights from other investors.`,
      bgImage: CommunityPng,
    },
    vipSync: {
      limitationIcon: <VipSyncLimitation />,
      title: 'VIP Syncing Service',
      mixpanelTitle: 'VIP Sync Limit',
      description:  userStore.isTier3 ? `You’ve reached the limit of 10 VIP Synced institutions. Upgrade now to unlock syncing with all portals and effortlessly enhance your financial overview.` : `Connect and update all your online portals with Vyzer's VIP Syncing Service, exclusive to premium plans.
      Upgrade to fully automate your portfolio administration.`,
      bgImage: VipSyncPng,
    },
    addItems: {
      limitationIcon: <AddItemsLimitation />,
      title: 'Add items',
      mixpanelTitle: 'Add Item Limit',
      description: `You’ve reached the limit of ${(paramsForText?.[0]) || ''} manual items. Upgrade now to add more and get a comprehensive view of your wealth.`,
      bgImage: AddItemsPng,
    },
    instSync: {
      limitationIcon: <VipSyncLimitation />,
      title: 'Sync Accounts',
      mixpanelTitle: 'Sync Limit',
      description: `You’ve reached the limit of ${(paramsForText?.[0]) || ''} institutions to be synced. Upgrade now to sync all of your accounts and get an holistic view of your financial life.`,
      bgImage: AddItemsPng,
    },
    demoPortfolio: {
      limitationIcon: <DemoDataLimitation />,
      title: 'This is a demo-data account',
      mixpanelTitle: 'Demo Portfolio Limit',
      description: `You can add your own portfolio in a click.\n Unlock the Vyzer experience.`,
      sidePadding: 56,
      bgImage: DemoPortfolioPng,
    },
    readOnlyPortfolio: {
      limitationIcon: <DemoDataLimitation />,
      title: 'Your account is Read-Only.',
      mixpanelTitle: 'Read Only Portfolio Limit',
      description: `Elevate your wealth journey with Vyzer Membership`,
      sidePadding: 104,
      bgImage: DemoPortfolioPng,
    },
  }

  const { limitationIcon, title, mixpanelTitle, description, bgImage, mobileBgImage, sidePadding } = modalTypes[modalType];

  const isDemoPortfolioType = ['demoPortfolio', 'readOnlyPortfolio'].includes(modalType);
  const isReadOnlyMode = modalType === 'readOnlyPortfolio';

  const handleUpgradeClick = () => {
    mixpanelTrack('Popup Clicked', {
      click_type: 'CTA click',
      cta_text: 'Upgrade',
      title: mixpanelTitle,
      tier: userStore.data?.profile?.tier,
      url: window.location.href
    });
    onUpgrade?.();
    onClose?.();
  }

  const handleMaybeLaterClick = () => {
    mixpanelTrack('Popup Clicked', {
      click_type: 'CTA click',
      cta_text: 'Maybe later',
      title: mixpanelTitle,
      tier: userStore.data?.profile?.tier,
      url: window.location.href
    });
    onClose?.();
  }

  const handleStartMyPortfolioClick = () => {
    mixpanelTrack('Popup Clicked', {
      click_type: 'CTA click',
      cta_text: 'Start My Portfolio',
      title: mixpanelTitle,
      tier: userStore.data?.profile?.tier,
      url: window.location.href
    });
    onStartMyPortfolio?.();
    onClose?.();
  }

  useTrackEvent('Popup Viewed', {
    title: mixpanelTitle,
    tier: userStore.data?.profile?.tier,
    url: window.location.href
  })

  return (
      <LimitationModalWrapper>
        <ModalLeftPane bgImage={ mobileBgImage ? mobileBgImage : bgImage} isDemoPortfolioType={isDemoPortfolioType} />
        <div style={{ display: 'flex', flexDirection: 'column', flex: '3', position: 'relative' }}>
          {isDemoPortfolioType ? <ModalCloseButtonWrapper onClick={onClose}><ModalCloseButton /></ModalCloseButtonWrapper> : null}
          <ModalContent>
            <IconWrapper>{limitationIcon}</IconWrapper>
            <TitleWrapper>{title}</TitleWrapper>
            <DescriptionWrapper sidePadding={sidePadding}>{description}</DescriptionWrapper>
            {isDemoPortfolioType ? <PrimaryButtonWithIcon onClick={handleStartMyPortfolioClick} style={{ marginTop: '32px', backgroundColor: '#2565E7', borderRadius: '34px', width: (isReadOnlyMode ? 220 : 222) + 'px', lineHeight: '18px' }}>{isReadOnlyMode ? 'Upgrade My Account' : 'Start My Portfolio Free'}<LinkArrowIconWrapper>
              <LinkArrowIcon />
            </LinkArrowIconWrapper></PrimaryButtonWithIcon> : null}
          </ModalContent>
          {!isDemoPortfolioType ? <ModalFooter style={{ borderTop: 'none', flexDirection: 'column', gap: '12px', alignItems: 'center' }}>
            <PrimaryButtonWithIcon onClick={handleUpgradeClick} style={{ borderRadius: '34px', width: '190px' }} noStroke><UpgradeSvg /> Upgrade</PrimaryButtonWithIcon>
            <MaybeLaterButton onClick={handleMaybeLaterClick}>Maybe later</MaybeLaterButton>
          </ModalFooter> : null}
        </div>
      </LimitationModalWrapper>
  )
})

function ModalLeftPane({ bgImage, isDemoPortfolioType }) {
  return (
    <ModalLeftPaneWrapper bgImage={bgImage}>
      <div style={{ paddingTop: '20px', paddingLeft: '24px' }}>
        {!isDemoPortfolioType ? <CommonBadge badgeWrapperStyle={{ width: '130px', marginLeft: '0', marginBottom: '4px', maxHeight: '18px' }} badgeType={'limitedFeature'} tooltipContent={''} color={'#F3A154'} /> : null}
        Access full financial control now.
      </div>
    </ModalLeftPaneWrapper>
  )
}

const UpgradeSvg = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.67262 8.85526L4.25 5.50705V12.6499H10.5H16.75V5.50705L13.3274 8.85526L10.5 3.2749L7.67262 8.85526Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.125 15.4775H4.875V13.9775H16.125V15.4775Z" fill="white" />
    </svg>
  )
}

const ModalCloseButton = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="6.28033" y1="5.83393" x2="18.3011" y2="17.8547" stroke="black" strokeWidth="1.5" />
      <line x1="5.92676" y1="17.8544" x2="17.9476" y2="5.83362" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}