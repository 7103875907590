import styled from "@emotion/styled/macro";
import { colors } from "../../styles/theme.styles";

export const LimitedAccessModalWrapper = styled.div`
  color: #fff;
  display: flex;
  padding: 16px 20px 24px 20px;
  background-color: ${colors.darkGray1};
  flex-direction: column;
  align-items: center;
  gap: 24px;
  ${props=>props.withBorderRadius ? `
    border-radius: 8px;
  ` : ``}
`;

export const LimitedAccessModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

export const LimitedAccessTitle = styled.div`
  text-align: center;
  font-family: Circular;
  font-size: 22px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
`;

export const LimitedAccessDescription = styled.div`
  text-align: center;
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
`;

export const LimitedAccessButtonWrapper = styled.div`
  display: flex;
  width: 146px;
  padding: 14px 24px 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: 16px;
  border-radius: 25px;
  background: #fff;
  cursor: pointer;

  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

