import React, { useEffect, useState } from 'react'
// import { WizardButton } from '../../../../add-item/components/AddItem.styles';
import { AssetClassIcon } from '../../../../../assets/components/AssetClassIcon';
import { InputsRowWrapper, AddButton, NameInput, CategorySelectorWrapper, ClassIconWrapper, CategorySelectButtonCustomStyles, ValueInputWrapper, StyledValueInput, CreateButton, AddLinkButton } from './ClassPageQuickAdd.styles';
import { useStore } from '../../../../../app/data/root.store';
import { Observer } from 'mobx-react';
import { SimpleSelect } from '../../../../../../common/components/DropDownMenu/SimpleSelect';
import { PlusIcon } from '../../../../../../assets/icons/common/common-icons';
import { isNullOrUndefined } from '../../../../../../common/utils/object.utils';
import { colors } from '../../../../../../common/styles/theme.styles';
import { ActionLockedIconWithTooltip } from '../../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';

const classesValuePlaceholder = {
    5 : 'Initial lent amount'
}

const categoriesValuePlaceholder = {
    40: 'Current cash value'
}

export const ClassPageQuickAdd = (props) => {
    const [showNameInput,setShowNameInput] = useState(false);
    const [itemName,setItemName] = useState('');
    const [itemCategory,setItemCategory] = useState(0);
    const [itemValue,setItemValue] = useState(null);
    
    const { metadataStore, createItemStore, userStore, billingStore , filtersStore } = useStore();
    //const { id, icon, color , isLiability } = wealthStore.selectedClass;
    const { tagColor : color, icon, isLiability, id: classId } = filtersStore.singleClassFilter || {};

    const syncButtonClasses = [1,3,10,11];

    const handleNameInputBlur = (e) => {
        setShowNameInput(e.target.value !== '')
    }

    const handleCreateClick = () => {
        if (userStore.isActionBlockedByRole('classPageQuickAdd')) {
            userStore.setShowLimitedSharedAccess(true);
        } else if (userStore.isDemoOrReadOnlyMode) {
            return billingStore.setShowPricingTableModal(true);
        } else if (userStore.isCreateItemManuallyLimited){
            createItemStore.setShowAddManualItemLimitModal(true);
        } else {
            createItemStore.openModalForSpecificCategory(classId,itemCategory,{title:itemName,value:itemValue || 0})
            setItemName('');
            setShowNameInput(false);
            setItemCategory(0);
            setItemValue(null);
        }
    }

    const handleSyncAdd = () => {
        if (userStore.isActionBlockedByRole('classPageQuickAdd')) {
            return userStore.setShowLimitedSharedAccess(true);
        } else if (userStore.isDemoOrReadOnlyMode) {
            return billingStore.setShowPricingTableModal(true);
        }
        // createItemStore.setInstitutionStep(classId === 3 ? 'investor' : 'bank');
        createItemStore.setInstitutionStep('bank');
        createItemStore.setShowModal(true);
    }

    const isDisabled = () => {
        if (itemCategory === 35 && itemName) return false;
        return itemName === '' || itemCategory === 0 || itemValue === null;
    }

    useEffect(() => {
        const classCategories = metadataStore.categories.filter(category=>category.classId === classId && !category.isHidden);
        if (classCategories.length === 1){
            setItemCategory(classCategories[0].id)
        }
        
        return () => {
            // cleanup
        }
    },[metadataStore,classId]);
    

    return (<Observer>{() => (
        <>
            { syncButtonClasses.includes(classId) ? <AddLinkButton onClick={handleSyncAdd}><PlusIcon />Add new
            {(userStore?.isDemoOrReadOnlyMode || userStore.isActionBlockedByRole('classPageQuickAdd')) && <ActionLockedIconWithTooltip iconColor={'gray'} isRoleBlocked={userStore.isActionBlockedByRole('classPageQuickAdd')} />}</AddLinkButton> :
        

        <InputsRowWrapper>
            {!showNameInput && <AddButton onClick={()=>{setShowNameInput(true)}}><PlusIcon />Add {isLiability ? 'liability' : 'asset'}</AddButton>}
            {showNameInput && <NameInput autoFocus 
                placeholder={`${isLiability ? 'Liability' : 'Asset'} name`}
                onChange={(e)=>{setItemName(e.target.value)}} 
                value={itemName}
                onBlur={handleNameInputBlur} />}

                {/* {metadataStore.categories.filter(category=>category.classId === classId && !category.isHidden).length > 1 &&  */}
            <CategorySelectorWrapper>
                  <ClassIconWrapper color={color} >
                    <AssetClassIcon name={icon} /> 
                    </ClassIconWrapper>
                            <SimpleSelect value={itemCategory} 
                              placement={"bottomRight"} 
                              placeHolder={'Category'}
                              placeHolderColor={colors.darkGray2}
                              buttonStyle={CategorySelectButtonCustomStyles}
                              scrollContainerId={props.scrollContainerId}
                              options={
                                [
                                    // {value:0,label:'All categories'},
                                ...metadataStore.categories.filter(category=>category.classId === classId && !category.isHidden ).map((category)=>(
                                  {value:category.id,label:category.title}
                                ))]} 
                                onChange={(val)=>{setItemCategory(val)}} />
                            {/* <span className="filter-label">Category: </span>
                            <Select size='small' value={categoryFilter} onChange={(val)=>{setCategoryFilter(val)}} style={{width: '160px'}} dropdownStyle={{minWidth:'260px'}} >
                              <Option key={1} value={0} >All</Option>
                              {metadataStore[classType === 'assets' ? 'assetCategories' : 'liabilityCategories'].filter(category=>category.classId === wealthStore.selectedClass.id).map((category)=>(
                                <Option key={category.id} value={category.id} >{category.title}</Option>
                              ))}
                            </Select> */}
            </CategorySelectorWrapper> 
            {/* }    */}
            <ValueInputWrapper isHidden= {itemCategory === 35 ? true : false}>

            <StyledValueInput 
                    thousandSeparator={true} 
                    // customInput={Input}
                    // suffix={field.suffix}
                    prefix={'$'}
                    // style={isIncognito && field.shouldBeHiddenIncognito ? {WebkitTextSecurity:'disc'}:{}} 
                    // value={( isNullOrUndefined(value) || value === 0) ? '' : value}
                    value={( isNullOrUndefined(itemValue) ) ? '' : itemValue}
                    //  value={value == null ? '' : value} /// == and not === because it will work with undifined as well , think about change to function to check null or undifined.
                    // disabled={field.disabled || (isItemConnected && field.disableIfConnected)} 
                    autoComplete="off"
                    // onValueChange={(values) => {handleChange(inputKey,values.floatValue ? values.floatValue : 0)}}
                    onValueChange={(values) => {
                        // console.log(value,values);
                        if ( itemValue !== values.floatValue && 
                             (!isNullOrUndefined(itemValue) || !isNullOrUndefined(values.floatValue))){
                                setItemValue(( values.floatValue || values.floatValue === 0 ) ? values.floatValue : null)
                            //handleChange(inputKey,( values.floatValue || values.floatValue === 0 ) ? values.floatValue : null)
                        }
                    }}
                    // onBlur={(e)=>{handleBlur && handleBlur(inputKey)}}
                    placeholder={classesValuePlaceholder[classId] || categoriesValuePlaceholder[itemCategory] || 'Current value'}
                    // decimalScale={field.decimalScale}
                    allowNegative={false}
                    // ref={inputRef} 
                    // onFocus={()=>{handleNumberTextFocus(field.type)}} 
                    // onBlur={()=>{handleNumberBlur(field.type)}} 
                />
            </ValueInputWrapper>
                
            

            {/*<ClassPageEmptyStateTitle>No assets</ClassPageEmptyStateTitle>
            <ClassPageEmptyStateAddItemText>{`It’s time to add some ${title}.`}</ClassPageEmptyStateAddItemText>
            <WizardButton onClick={handleAddClassItemOnClick} black style={{margin: '8px 0px 0px 0px'}}>Add {title}</WizardButton> */}
                {showNameInput && 
        
            <CreateButton onClick={handleCreateClick} disabled={isDisabled()} >
                Create {(userStore?.isDemoOrReadOnlyMode || userStore.isActionBlockedByRole('classPageQuickAdd')) && <ActionLockedIconWithTooltip iconColor={'#fff'} isRoleBlocked={userStore.isActionBlockedByRole('classPageQuickAdd')} />}
            </CreateButton>}
        </InputsRowWrapper>}
   
        </>
    )}</Observer>)
}
