import { deleteData, getData, postData, putData } from "../../common/utils/network.utils";

export const getCustomClassData = async () => {
  return (await getData('custom-class')).data;
}
export const createCustomClass = async (data) => {
  return (await postData('custom-class', data));
}

export const updateCustomClass = async (data) => {
  return (await putData('custom-class', data));
}

export const deleteCustomClass = async (id) => {
  return (await deleteData(`custom-class/${id}`));
}

export const updateCustomClassesOrder = async (order) => {
  return (await putData('custom-class/update-order', {order}));
}

export const createCustomSubClass = async ({customClassId, ...data}) => {
  return (await postData(`custom-class/${customClassId}/custom-sub-class`, [data]));
}

export const updateCustomSubClass = async ({customClassId, ...data}) => {
  return (await putData(`custom-class/${customClassId}/custom-sub-class`, data));
}

export const deleteCustomSubClass = async ({customClassId,...data}) => {
  return (await deleteData(`custom-class/${customClassId}/custom-sub-class/${data.id}`, data));
}


