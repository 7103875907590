import React from 'react';
import { FiltersHeroEmptyStateWrapper } from './FiltersHeroEmptyState.styles';
import { ReactComponent as FiltersHeroEmptyStateIcon } from './filters-hero-empty-state-icon.svg';

export const FiltersHeroEmptyState = () => {
  return (
    <FiltersHeroEmptyStateWrapper>
      <FiltersHeroEmptyStateIcon />
      No result for this filter
    </FiltersHeroEmptyStateWrapper>
  )
}