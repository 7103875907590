const darkHeroBackground = '#111213';

export const projectionOverviewLineChartData = (newWorthData) => {
    const currentYear = new Date().getFullYear()-3;
    return {
        labels: new Array(33).fill(1).map((item,ind)=>currentYear+ind),
        datasets: [
            {
                // label: '# of cool',
                data : newWorthData.slice(0,4),
                fill: false,
                backgroundColor: '#F8A87C',
                borderColor: '#F8A87C',
                // pointRadius:0.5,
                pointBorderWidth:0.6,
                borderWidth:1,
                borderDash: [1,3],
                label:'onTop'
            },
            {
                // label: '# of cool',
                data : newWorthData.slice(0,4),
                fill: false,
                backgroundColor: '#000',
                borderColor: '#000',
                // pointRadius:0.5,
                pointBorderWidth:0.6,
                borderWidth:1,
                label:'onTopBlack'
            },
            {
            // label: '# of cool',
            data : newWorthData.slice(),
            fill: false,
            backgroundColor: '#fff',
            borderColor: '#fff',
            // pointRadius:0.5,
            pointBorderWidth:0.6,
            borderWidth:1,
            label:'rest'
            },
        ]
    }
}

export const projectionClassLineChartData = (classData) => {
    const currentYear = new Date().getFullYear()-3;
    return {
        labels: new Array(30).fill(1).map((item,ind)=>currentYear+ind),
        datasets: [
            {
                // label: '# of cool',
                data : classData?.slice(0,4),
                fill: false,
                backgroundColor: '#F8A87C',
                borderColor: '#F8A87C',
                // pointRadius:0.5,
                pointBorderWidth:0.6,
                borderWidth:1,
                borderDash: [1,3],
                label:'onTop'
            },
            {
                // label: '# of cool',
                data : classData?.slice(0,4),
                fill: false,
                backgroundColor: '#000',
                borderColor: '#000',
                // pointRadius:0.5,
                pointBorderWidth:0.6,
                borderWidth:1,
                label:'onTopBlack'
            },
            {
            // label: '# of cool',
            data : classData?.slice(),
            fill: false,
            backgroundColor: '#fff',
            borderColor: '#fff',
            // pointRadius:0.5,
            pointBorderWidth:0.6,
            borderWidth:1,
            label:'rest'
            }
        ]
    }
}

export const wealthOverviewPieChartData = (totalNetWorthUsdValue,assetsGroups) => {
    const totalAssetsValue = assetsGroups.filter(g=>!g.isLiability && g.totalUsdValue > 0).map(group=>group.totalUsdValue).reduce((a,b)=>a+b,0);
    const sliceMinWidth = totalAssetsValue*0.02;
    return {
        datasets: [
        // ...(this.isLiabilityClassView ? [] : 
        //     [
                {
            data: totalNetWorthUsdValue === 0 ? [1] : assetsGroups.filter(g=>!g.isLiability && g.totalUsdValue > 0).map(group=> Math.max(group.totalUsdValue, sliceMinWidth)),
            // backgroundColor: '#585D66',
            backgroundColor: assetsGroups.filter(g=>!g.isLiability && g.totalUsdValue > 0).map(group=>group.color),
            //this.assetsGroups.map(group=>group.color),
            // //this.selectedAssetClassIndex > -1 ? this.assetsGroups.map((group,groupIndex)=>{return groupIndex===this.selectedAssetClassIndex ? group.color : '#E5E5E5'}) : '#8BEDCB', 
            // this.selectedAssetClassIndex > -1 ? this.assetsGroups.map((group,groupIndex)=>{return groupIndex===this.selectedAssetClassIndex ? group.color : '#585D66'}) : '#585D66', 
            // this.totalNetWorthUsdValue === 0 ? '#e3e3e3' :
            // (this.selectedAssetClassIndex > -1 ?
            //     this.assetsGroups.map((group,groupIndex)=>{return groupIndex===this.selectedAssetClassIndex ? '#3F3F45' : '#dddee0'}) : ( this.isOnBoarding ? '#dddee0' : '#3F3F45' ) ), 
            
            // backgroundColor: '#3F3F45;',
            // hoverBackgroundColor: assetsGroups.map(group=>group.color),
            borderColor:darkHeroBackground,
            //borderWidth: assetsGroups.map((group,groupIndex)=>{return groupIndex===this.selectedAssetClassIndex ? 0 : 6}),
            borderWidth: 6,
            hoverBorderColor:darkHeroBackground,
            label:'Assets',
            // labels: assetsGroups.map(group=>group.title),
            },
        // ]),
        // ...(this.isAssetClassView  ? [] : 
        // [   
            {
            data: [...assetsGroups.filter(g=>g.isLiability || g.totalUsdValue < 0).map(group=> group.totalUsdValue > 0 ? Math.max(Math.abs(group.totalUsdValue), sliceMinWidth) : 0 ),totalNetWorthUsdValue],
            // backgroundColor: [
            //     ...this.liabilitiesGroups.map((group,groupIndex)=>{return this.selectedLiabilityClassIndex > -1 ? (groupIndex===this.selectedLiabilityClassIndex ? group.color : '#E5E5E5') : group.color}) , '#fff' 
            // ],
            backgroundColor: [ ...assetsGroups.filter(g=>g.isLiability || g.totalUsdValue < 0).map(group=>group.color), darkHeroBackground ],
            // backgroundColor: [
               // ...liabilitiesGroups.map((group,groupIndex)=>'#aaadb2') , darkHeroBackground 
                // ...this.liabilitiesGroups.map((group,groupIndex)=>{return this.selectedLiabilityClassIndex > -1 ? ( groupIndex===this.selectedLiabilityClassIndex ? '#3F3F45' : '#aaadb2') : '#aaadb2'}) , darkHeroBackground  
            // ],
            
            // data: [this.liabilitiesTotalUsdValue, this.totalNetWorthUsdValue],
            // data:[500000,-600000],
            // backgroundColor:['red','#fff'],
            borderColor:darkHeroBackground,
            borderWidth: 6,
            // borderWidth:this.liabilitiesGroups.map((group,groupIndex)=>{return groupIndex===this.selectedLiabilityClassIndex ? 0 : 6}),
            hoverBorderColor:darkHeroBackground,
            label:'Liabilities',
            hoverBackgroundColor:[...assetsGroups.filter(g=>g.isLiability || g.totalUsdValue < 0).map(group=>group.color),darkHeroBackground]
            // labels: this.assetsGroups.map(group=>group.title),
            // label: 'Offices',
            // labels: ['London', 'New York', 'Paris']
            // hoverBorderWidth:0
            //cutoutPercentage:5,
            }
        //])
        ]
    };
}

export const wealthOverviewCategoriesPieChartData = (totalNetWorthUsdValue,categories) => {
    const totalAssetsValue = categories.filter(g=>!g.isLiability && g.usdValue > 0).map(group=>group.usdValue).reduce((a,b)=>a+b,0);
    // const totalLiabilityValue = categories.filter(g=>g.isLiability || g.totalUsdValue < 0).map(group=>Math.abs(group.totalUsdValue)).reduce((a,b)=>a+b,0);
    const sliceMinWidth = totalAssetsValue*0.02;
    const loanCategoriesLength = categories.filter(g=>g.isLiability || g.usdValue < 0).length;

    let pieDataSets = [
        {
            data: totalNetWorthUsdValue === 0 ? [1] : categories.filter(g=>!g.isLiability && g.usdValue > 0).map(group=> Math.max(group.usdValue, sliceMinWidth)),
            backgroundColor: categories.filter(g=>!g.isLiability && g.usdValue > 0).map(group=>group.color),
            borderColor:darkHeroBackground,
            borderWidth: 6,
            hoverBorderColor:darkHeroBackground,
            label:'Assets',
            },
            {
            data: [...categories.filter(g=>g.isLiability || g.usdValue < 0).map(group=> Math.max(Math.abs(group.usdValue), sliceMinWidth) ),...(totalAssetsValue === 0 ? [] : [totalNetWorthUsdValue]) ],
            backgroundColor: [ ...categories.filter(g=>g.isLiability || g.usdValue < 0).map(group=>group.color), darkHeroBackground ],
            borderColor:darkHeroBackground,
            borderWidth: 6,
            hoverBorderColor:darkHeroBackground,
            label:'Liabilities',
            hoverBackgroundColor:[...categories.filter(g=>g.isLiability || g.usdValue < 0).map(group=>group.color),darkHeroBackground]
            }
    ]

    if (totalAssetsValue === 0) {
        pieDataSets = pieDataSets.reverse();
    }

    return {
        datasets: totalAssetsValue===0 && loanCategoriesLength === 0 ? [] :  pieDataSets
    };
}

export const wealthOverviewAllocationPieChartData = (totalNetWorthUsdValue,allocations) => {
    // const totalLiabilityValue = categories.filter(g=>g.isLiability || g.totalUsdValue < 0).map(group=>Math.abs(group.totalUsdValue)).reduce((a,b)=>a+b,0);
    const sliceMinWidth = totalNetWorthUsdValue*0.02;
    return {
        datasets: totalNetWorthUsdValue === 0 ? [] : [
            {
                data: totalNetWorthUsdValue === 0 ? [1] : allocations.map(group=> Math.max(group.usdValue, sliceMinWidth)),
                backgroundColor: allocations.map(group=>group.color),
                borderColor:darkHeroBackground,
                borderWidth: 6,
                hoverBorderColor:darkHeroBackground,
                label:'Allocations',
            }
        ]
    };
}


export const wealthOverviewFiltersPieChartData = (totalNetWorthUsdValue,items) => {
    
    
    const assetItems = items.filter(item => item.isAsset && item.usdValue > 0);
    const liabilitiesItems = items.filter(item => !item.isAsset || item.usdValue < 0); 
    const liabilitiesItemsIds = liabilitiesItems.map(lia=>lia.id);
    const itemsWithLiabilities = items.filter(item=>item?.connectedLiabilities?.length > 0);

    const connectedLiabilities = itemsWithLiabilities?.map(iwl=>iwl.connectedLiabilities).flat();
    // remove connected liabilities that are included in liabilities items    
    const displayedLiabilitiesItems = [
        ...liabilitiesItems,
        ...connectedLiabilities.filter(clia=>!liabilitiesItemsIds.includes(clia.liabilityId))
    ]

    // const totalItemsWorthUsdValue = items.reduce((a,b)=>a+b.usdValue,0);
    // console.log(assetItems.reduce((acc,item)=>acc + item.usdValue, 0))
    // console.log(displayedLiabilitiesItems.reduce((acc,item)=>acc + Math.abs(item.usdValue), 0))
    const liabilitiesBlackSlice = (
        assetItems.reduce((acc,item)=>acc + item.usdValue, 0) - displayedLiabilitiesItems.reduce((acc,item)=>acc+ Math.abs((item.usdValue||item.value)),0)) || 0;
        //itemsWithLiabilities?.reduce((acc, item)=>acc + item.matrices.connectedLiabilities.reduce((acc, liability)=>acc + liability.usdValue, 0), 0)) || 0;
    
    const sliceMinWidth = totalNetWorthUsdValue*0.03;

    return {
        datasets: [
            ...( assetItems.length > 0 ? [{
                data: assetItems.map(asset=> Math.max(asset.usdValue, sliceMinWidth) ),
                backgroundColor: assetItems.map(asset=>asset.color),
                borderColor:darkHeroBackground,
                
                // hoverBorderColor:darkHeroBackground,
                label:'Assets',
                borderWidth: 6,
                // labels: this.assetClass.items?.map(asset=>asset.title),
            }] : [] ),
            ...( displayedLiabilitiesItems?.length > 0 ? [
                {
                    data: [...displayedLiabilitiesItems.map(liability=> Math.abs((liability.usdValue||liability.value))), liabilitiesBlackSlice < 0 ? 0 : liabilitiesBlackSlice],
                        // itemsWithLiabilities?.map(item=>item?.connectedLiability?.usdValue / ( item.usdValue * ((item.holdings || 100)/100)))
                        
                    // ],
                    backgroundColor:  [...displayedLiabilitiesItems.map(liability=> liability.color || '#D37A7A'),darkHeroBackground],
                    // hoverBackgroundColor: ['#D37A7A',darkHeroBackground],
                    label:'Liability',
                    // hoverBackgroundColor: this.assetsGroups.map(group=>group.color),
                    // hoverBorderColor: darkHeroBackground,
                    borderColor:darkHeroBackground,
                    borderWidth: 6,
                    // weight: 0.5
                }
            ] : [] )
        ]
    };
}