import { getTranslations } from "../../../../../../../common/translations/translations";
import { customClassAndSubClassRows } from "../../../../../../add-item/data/common-fields/custom-class-and-sub-class-field.const";
import { liquidityRow } from "../../../../../../add-item/data/common-fields/liquidity-field.const";
import { riskLevelRow } from "../../../../../../add-item/data/common-fields/risk-level-field.const";


const {asset_fields} = getTranslations().common;
const HoldingEntitySections = ({updateField,updateValue}) => {
    return [
        {
            title: '',
            fullWidth: true,
            // padding:'24px 0',
            rows: [
                {fields:[

                    {
                    key:'beneficiary', 
                    type:'beneficiary-select',
                    label: 'Holding entity', 
                     description: asset_fields.BENEFICIARY_DESCRIPTION,
                    }
                ]},
            ]
        }
    ]

} 

const CustomClassSections = ({updateField,updateValue}) => {
    return [
        {
            title: '',
            fullWidth: true,
            // padding:'24px 0',
            rows: customClassAndSubClassRows({updateField,updateValue})
            
        }
    ]
}

const RiskLevelSections = ({updateField,updateValue}) => {
    return [
        {
            title: '',
            fullWidth: true,
            // padding:'24px 0',
            rows: [
                riskLevelRow({updateField})
            ]
        }
    ]
}

const LiquidityLevelSections = ({updateField,updateValue}) => {
    return [
        {
            title: '',
            fullWidth: true,
            // padding:'24px 0',
            rows: [
                liquidityRow({updateField})
            ]
        }
    ]
}



export const AttributeSections = {
    beneficiary: HoldingEntitySections,
    customClass : CustomClassSections,
    riskLevel : RiskLevelSections,
    liquidity: LiquidityLevelSections,
}