import styled from '@emotion/styled/macro';
import { colors, shadows } from '../../../../../../common/styles/theme.styles';
import { flexCenter } from '../../../../../../common/styles/combos.styles';

export const DraggAreaWrapper = styled.div`
    margin: 40px;
    border: 1px dashed ${colors.gray2};
    border-radius: 8px;
    height:192px;
    display:flex;
    text-align:center;
    align-items:center;
    justify-content:center;
    position:relative;
    cursor: pointer;
`;

export const LoadingLogoWrapper = styled.div`
    padding-top:16px;
`;

export const DragDropPromptWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

export const DragAreaText = styled.div`
    color: ${colors.darkGray1};
    text-align: center;
    font-family: Circular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const DragAreaDescription = styled.div`
    color: ${colors.darkGray1};
    text-align: center;
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const ChooseFilesButton = styled.div`
    display: flex;
    height: 40px;
    border-radius: 8px;
    background: ${colors.darkGray1};
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-family: Circular;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
`;

export const DocumentsTableWrapper = styled.div`
`;

export const DocumentsHeaderTableRow = styled.div`
    position:sticky;
    top:0;
    display: grid;
    padding: 0px 24px 8px 24px;
    grid-template-columns:  280px 144px 112px auto 192px;
    z-index:1;
`;

export const DocumentsTableHeaderCell = styled.div`
    text-align:left;
    font-size:14px;
    color:#9D9D9D;
    padding-left:14px;
    :last-of-type {
        text-align: right;
    }
`;

export const HoverActionsWrapper = styled.div`
    opacity: 0;
    position: absolute;
    right: 0;
    gap: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
`;

export const DocumentsTableRow = styled.div`
    display: grid;
    grid-template-columns:  280px 144px 112px auto 192px;
    position:relative;
    padding: 8px 0px 8px 24px;
    :hover {
        background-color:${colors.lightGray1};
        ${HoverActionsWrapper} {
            opacity: 1;
        }
    }
    ${props=>props.isFirst ? `` : `
    ::after {
        content:' ';
        position: absolute;
        left: 24px;
        right: 24px;
        border-top: 1px solid ${colors.lightGray2};
    }
    `}
    ${props=>props.isEdited ? `` : `
        cursor: pointer;
    `}
`;

export const DocumentsTableCell = styled.div`
    display: flex;
    align-items: center;
    padding-inline: 4px;
    text-align:left;
    min-height: 35px;

    &.ant-dropdown-disabled {
        background: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
    }

    .ant-picker, .ant-input {
        :hover, :active, :focus {
            border: 1px solid ${colors.darkGray1};
        }
    }

    .ant-picker-focused{
        border-color:#7d7d7d!important;
        box-shadow: none!important;
    }
    .ant-input-borderless {
        background-color: #fff;
    }
    ${props=>props.disabled ? `
        background-color: #f5f5f5;
    ` : `
        cursor: pointer;
    `}
`
export const DocumentsTableCellText = styled.div`
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 18px;
    padding-inline: 12px;
    border: 1px solid transparent;
    overflow-wrap: anywhere;
    cursor: default;
`

export const DocumentsTablesActionsWrapper = styled.div`
    position:absolute;
    right: 20px;
    gap: 8px;
    top: 50%;
    transform: translateY(-50%);
    // ${DocumentsTableRow}:hover &{
        display:flex;
    // }
`;

export const ActionButtonWrapper = styled.div`
    ${flexCenter};
    cursor: pointer;

    ${props=>props.disabled ? `
        cursor: not-allowed;
        opacity: 0.4;
    ` : ``}
`;

export const SaveChangesButtonWrapper = styled.div`
    display: flex;
    height: 32px;
    padding: 0px 20px 0px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: ${colors.darkGray1};
    box-shadow: ${shadows.toggleShadow};
    color: #fff;
    font-family: Circular;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
`;