import React from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
// import { Line } from 'react-chartjs-2';

// import { Line } from 'react-chartjs-2';
// import { NewSimulationDrawer } from './NewSimulationDrawer/NewSimulationDrawer';
// import { putData } from '../../../common/utils/network.utils';
import { customTooltip } from './graphTooltips';
import './graphTooltips.css';


// import { SimulationsDrawer } from './SimulationsDrawer/SimulationsDrawer';






// import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ChartComponent } from '../../../../common/components/charts/ChartComponent';
import { colors } from '../../../../common/styles/theme.styles';
import { displayMoneyFormatter } from '../../../../common/utils/number-display.utils';

export function CashFlowChart(props) {
  
  const { cashFlowStore, uiStore } = useStore();
  







  // const handleClickOnAccountLink = () => { // FROM cash flow page
  //   filtersStore.handleClearFilterSelections();
  //   filtersStore.handleSelectClass(1);
  //   filtersStore.setAppliedFiltersFromSelection();
  //   wealthStore.refreshWealthData(false, history);
  // }


  // useEffect(() => {
  //   console.log(cashFlowStore?.data.costOfLiving?.showModal);
  //   setShowCostOfLivingModal(cashFlowStore?.data.costOfLiving?.showModal)
  //   // return () => {}
  // }, [cashFlowStore.data.costOfLiving.showModal])


  // const isDebugMode = new URLSearchParams(useLocation().search).get('showtable') === 'y';

  // const getDisplayDate = (date) => {
  //     const d = new Date(date);
  //     return (("0"+(d.getMonth()+1)).slice(-2) + "/" +d.getFullYear());
  // }

  const getTooltipItems = (dataIndex) => {
    const lastDayOfMonth = moment().add(dataIndex,"M").endOf("M").format("MMMM Do, YYYY")
    const {remainingCashIn, remainingCashOut} = cashFlowStore.monthsList[cashFlowStore.monthsList.length - (dataIndex + 1)];
    return {
      remainingCashIn, remainingCashOut, lastDayOfMonth
    }
    // return cashFlowStore.data.cashFlowChartData.map((cd, index) => {
    //   let logo
    //   // if (cd.id !== 'Other') {
    //   //   console.log(metadataStore.itemsMeta[cd.id])
    //   // }
    //   if (cd.id === 'other') {
    //     logo =  { type: 'other', location: 'null' }
    //   } else if (metadataStore.itemsMeta[cd.id].logoBase64) {
    //     logo = { type: 'logoBase64', location: metadataStore.itemsMeta[cd.id].logoBase64 }
    //   } else if (metadataStore.itemsMeta[cd.id].logoUrl) {
    //     logo = { type: 'logoUrl', location: metadataStore.itemsMeta[cd.id].logoUrl }
    //   } else {
    //     logo = { type: 'default', location: 'null' }
    //   }

    //   // console.log(logo);
    //   return {
    //     label: cd.label,
    //     value: cd.data[dataIndex],
    //     logo: logo,
    //     isIncognito: uiStore.isIncognitoMode,
    //   }
    // });
  }


  const chartOptions = (isIncognito, hasAnyEvents, isDesktop) => ({
    maintainAspectRatio: false,
    // animations:false,  
    
    interaction:{
      mode: 'nearest',
      intersect: false,  
      axis: cashFlowStore.displayedAccounts.length > 0 ? 'xy' : 'x'
    },
    scales: {
      y: {
        ticks: {
          display:isDesktop,
          drawOnChartArea:false,
          callback: function (value, index, values) {
            // return '$' + numberWithCommas(value);
            return displayMoneyFormatter(value, 'USD');
          },
          beginAtZero: true,
          // maxTicksLimit: 10
          // padding: 16
        },
        grid:{
          display: isDesktop,
          tickLength: 0,
          color:colors.lightGray2
        },
        border:{
          display:false,
        },
        // suggestedMin: -1000,
        // suggestedMax: 1000
      },
      x: {
        stacked: true,
        offset:1,
        ticks: {
          display:isDesktop,
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 12,
          // stepSize: Math.round((Math.max.apply(Math, 20) / 10)/5)*5,
          // beginAtZero: true,
          // precision: 0,
          callback: function (value, index, values) {
            return moment().add(value,'M').format('MMM YY');
            // console.log(value);
            // return displayMoneyValue(value, 'USD', isIncognito);
          },
        }, 
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false
        },
        border:{
          display:isDesktop,
          color:colors.lightGray2
          //
        }
      }
    },

    onClick:(event, chartElement) => {
      if (chartElement.length) {
        cashFlowStore.setDisplayTransactions(chartElement[0].datasetIndex, chartElement[0].index)
        if ( isDesktop){
          cashFlowStore.setShowTransactionsPanel(true);
          document.getElementById('cash-flow-line-chart-tooltip').style.opacity = 0;
        }
      }
    
  }, 
  ...(isDesktop?{}:{

    onHover:(event, chartElement) => {
      if (chartElement.length) {
        if ( chartElement[0].datasetIndex !== cashFlowStore.hoverDataIndex || chartElement[0].index !== cashFlowStore.hoverMonthIndex ){
          cashFlowStore.setDisplayTransactions(chartElement[0].datasetIndex, chartElement[0].index)
          cashFlowStore.setHoveredState(chartElement[0].datasetIndex, chartElement[0].index)
        }
      }
    
  }

  })  
  ,  
    // tooltips: customTooltip(getTooltipItems, isIncognito),
    plugins:{
      legend: {
        display:false,
        // position: 'bottom',
        // labels: {
        //   filter: function (item, cData) {
        //     return item.datasetIndex > 0 && !item.hidden; //true;
        //   }
        // },
        onClick: function (evt, legendItem) {
          //console.log("Yo",evt);
          evt.native.stopPropagation();
          // cashFlowStore.setDisplayTransactions(legendItem.datasetIndex, -1)
          // setShowTransactionsDrawer(true);
        }
      },
      htmlLegend: {
        // ID of the container to put the legend in
        containerID: 'legend-container',
      },
       tooltip: isDesktop ? customTooltip(getTooltipItems, isIncognito, hasAnyEvents): {enabled: false},

      
    },
  })

  const morePlugins = (isDesktop) => ( isDesktop ? [
    {
      id:"coolPlugin",
      beforeDatasetsDraw:function (c, options){
        
        const { ctx , chartArea , scales } = c;
        ctx.save();
        ctx.beginPath();
        
        const pointZeroHeight = scales.y.getPixelForValue(0);
        ctx.moveTo(chartArea.left,pointZeroHeight);
        ctx.lineTo(chartArea.right,pointZeroHeight);

        // ctx.moveTo(chartArea.left,chartArea.top);
        // ctx.lineTo(chartArea.right,chartArea.top);

        // ctx.moveTo(chartArea.left,chartArea.top + chartArea.height);
        // ctx.lineTo(chartArea.right,chartArea.top + chartArea.height);
        ctx.lineWidth = 1;
        ctx.strokeStyle = colors.gray2;
        ctx.setLineDash([5, 15]);
        ctx.stroke();

        // if (c.data.datasets.length) {
        //   // c.data.datasets.forEach((element, index) => {
            
        //     const dataset = c.data.datasets[0];
            
        //     if (!dataset.hidden){
              
        //       // const pointZero = scales.y.getPixelForValue(0);
        //       const currentChartHeight = chartArea.height - chartArea.top ;
        //       const pointZeroPercentage = pointZeroHeight / currentChartHeight;
        //       // console.log(pointZeroHeight, chartArea.height, chartArea.top, pointZeroPercentage);
        //       console.log(pointZeroPercentage);
        //       // console.log("Here", pointZero);
        //       // console.log(currentChartHeight, chartArea.height , chartArea.height - chartArea.top )
        //       const gradientFill = c.ctx.createLinearGradient(0, 0, 0, currentChartHeight);
        //       gradientFill.addColorStop(0, 'transparent');
        //       if (pointZeroPercentage < 1)  {
        //         // console.log("AA" , pointZeroPercentage);
        //         gradientFill.addColorStop(pointZeroPercentage, 'transparent');
        //         gradientFill.addColorStop(pointZeroPercentage + 0.0001, '#FFD2D280');
        //         gradientFill.addColorStop(1, '#FF585880');
        //       } else {
        //         gradientFill.addColorStop(1, 'transparent');
        //       }
        //       dataset.fill = 'origin';
        //       dataset.backgroundColor = 'green'; // gradientFill;
        //     }
            
        //     // var yPos = yScale.getPixelForValue(0);
        //     // const pointPercentage = yPos / c.height;
        //     // console.log( c.height)
        //     // var gradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);
        //     // gradientFill.addColorStop(0, 'transparent');
        //     // if (pointPercentage < 1)  {
        //     //   gradientFill.addColorStop(pointPercentage - 0.01, 'transparent');
        //     //   gradientFill.addColorStop(pointPercentage + 0.01, '#FF585880');
        //     //   gradientFill.addColorStop(1, '#FF585880');
        //     // } else {
        //     //   gradientFill.addColorStop(1, 'transparent');
        //     // }
            
        //     // // const model = c.controller.getDatasetMeta(index);
        //     // // console.log(model);
        //     // // var model = c.data.datasets[index]._meta[Object.keys(dataset._meta)[0]].$filler.el._model;
        //     // // model.backgroundColor = gradientFill;
  
        //     // 
        //     // console.log( dataset)
  
  
        //     // var borderFill = c.ctx.createLinearGradient(0, 0, 0, c.height);
        //     // borderFill.addColorStop(0, 'green');
        //     // borderFill.addColorStop(yPos / c.height - 0.00, 'green');
        //     // borderFill.addColorStop(yPos / c.height + 0.00, 'red');
        //     // borderFill.addColorStop(1, 'red');
  
          
        //     //model.borderColor = borderFill;
        //     // console.log(model);
        //   // });
        // }


        ctx.restore();
      },
    },{
      id:'htmlLegend', 
      afterUpdate(chart, args, options) {
        // const legendsContainer  = getOrCreateLegendList(chart, options.containerID);
        // const legendsContainer = document.getElementById(options.containerID);
        const legendsContainer = document.getElementById("legend-container");
        

        // Remove old legend items
        legendsContainer.innerHTML = '';
    
        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
    
        items.forEach(item => {
          if (item.datasetIndex === 0 
             || item.hidden
          ){
            return;
          }
          const li = document.createElement('div');
          li.style.alignItems = 'center';
          // li.style.cursor = 'pointer';
          li.style.display = 'flex';
          li.style.flexDirection = 'row';
          li.style.marginLeft = '10px';
          li.style.marginRight = '10px';
    
          // li.onclick = () => {
          //   const {type} = chart.config;
          //   if (type === 'pie' || type === 'doughnut') {
          //     // Pie and doughnut charts only have a single dataset and visibility is per item
          //     chart.toggleDataVisibility(item.index);
          //   } else {
          //     chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          //   }
          //   chart.update();
          // };
    
          // Color box
          const boxSpan = document.createElement('span');
          // console.log(item);
          boxSpan.style.background = item.strokeStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = item.lineWidth + 'px';
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '10px';
          boxSpan.style.marginRight = '8px';
          boxSpan.style.width = '10px';
    
          // Text
          const textContainer = document.createElement('p');
          textContainer.style.color = item.fontColor;
          textContainer.style.margin = 0;
          textContainer.style.padding = 0;
          // textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
    
          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);
    
          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          legendsContainer.appendChild(li);
        });
      }
    }
  ] : [])


  return (<Observer>{() => (
        // <Line  data={cashFlowStore.chartData} options={chartOptions(uiStore.isIncognito)}  plugins={morePlugins} />
        <ChartComponent chartId={'cash-flow-chart'} type="line" data={cashFlowStore.chartData} options={chartOptions(uiStore.isIncognito, cashFlowStore.hasCostOfLiving || cashFlowStore.hasAnyCashDataEvents || cashFlowStore?.data?.simulations.length > 0 ,uiStore.isDesktopView)} plugins={morePlugins(uiStore.isDesktopView)} />
  )}</Observer>)
}