import styled from '@emotion/styled/macro';
import { colors, sizes } from '../../../../../../common/styles/theme.styles';
import { flexCenter } from '../../../../../../common/styles/combos.styles';

export const CenterContent = styled.div`
    width: 100%;
    // max-width: 816px;
    max-width: ${sizes.wealthContentWidth + 32}px;
    margin: 0 auto;
    min-height:100%;
`;

export const AssetVipSyncingTabWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const NewVipSyncBadge = styled.div`
    ${flexCenter};
    padding: 3px 7px;
    width: fit-content;
    border-radius: 36px;
    background: #DEB482;
    color: #fff;
    font-family: Circular;
    font-size: 12px;
    font-weight: 500;
`;

export const PromptWrapper = styled.div`
    color: ${colors.darkGray1};
    text-align: center;
    font-family: Domine;
    font-size: 18px;
    font-weight: 400;
    white-space: pre-line;
    padding-top: 8px;
`;

export const ConsentReminder = styled.div`
    color: #DEB482;
    text-align: center;
    font-family: Circular;
    font-size: 14px;
    font-weight: 450;
    white-space: pre-line;
    padding-top: 6px;
`;

export const CloseButtonWrapper = styled.div`
    display: inline-flex;
    align-self: flex-end;
    padding: 10px 0 10px;
    cursor: pointer;
`;