import styled from '@emotion/styled/macro';
import { colors, mobileVars, views } from '../../../../../../common/styles/theme.styles';

export const TabWrapper = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    margin-bottom: -24px;
`;

export const FormWrapper = styled.div`
    flex:1;
    
    // border-left:1px solid #e3e3e3;
    ${views.desktop}{
        padding:0 60px;
    }

    ${views.tablet}{
        ${props=>props.haveChanges ? `padding-bottom:56px;` : ``}
    }
`;

export const MenuWrapper = styled.div`
    width:188px;
    display:flex;
    flex-direction:column;
    padding-top:40px;
    position:relative;
`;

export const MenuStickyWrapper = styled.div`
    position:sticky;
    top:40px;
`;

export const MenuItem = styled.a`
    padding:8px 16px;
    font-size:14px;
    // font-weight:${props=>props.isSelected ? '600' : '300'};
    font-weight:300;
    color:#3F3F45;
    letter-spacing: 2px;
    // background-color:${props=>props.isSelected ? '#F2F4F6' : 'none'};
    cursor: pointer;
    display: block;
    &:hover{
        background-color:#F2F4F6;
        font-weight:600;
        color:#3F3F45;
    }
`;


export const ActionsWrapper  = styled.div`
     width:100%;
     padding:0 16px;
     position:relative;
     position:sticky;
     bottom:-56px;
     background-color:#fff;
     z-index:2;
     ${views.tablet}{
        position: fixed;
        bottom: ${props => props.isMobileFooterShowing ? `calc( env(safe-area-inset-bottom) + ${mobileVars.footerHeight} )` : '0'};
        left:0;
     }
`;

export const ActionsStickyWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    /* max-width: 512px; */
    margin: 0 auto;
    padding: 16px 24px;
    border-top: 1px solid #f0f0f0;
    margin:0 -32px;
    ${views.tablet}{
        margin:0 -16px;
     }
    // position:sticky;
    // top:40px;
`;


export const ActionButton = styled.div`
    background-color: ${props=>props.disabled ? '#8c8c8c' : props.white ? '#ffffff' : '#2A2D33'} ;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    padding:8px 16px;
    font-size:14px;
    color: ${props=> props.white ? '#000' : '#fff' };
    cursor: ${props=>props.disabled ? 'not-allowed' : 'pointer'};
    text-align:center;
    transition:all 0.2s;
`;

export const AssetClosedTabMessage = styled.div`
     display: flex;
     align-items: center;
     line-height: 14px;
     color: ${colors.darkGray2};
     gap:4px;
     span {
        font-size: 12px;
        font-weight: 450;
     }
`;