import { Observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import Lottie from 'react-lottie-player';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { useStore } from '../../../app/data/root.store';
import {
  BottomMessage,
  LogoWrapper,
  MiddleMessage,
  ModalCloseButtonWrapper,
  ModalContent,
  StartButton
} from './MobileWelcomeModal.styles';
import confettiAnimation from './assets/Confetti.json';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { ROUTES } from '../../../../common/constants/routes.const';

const welcomeText = {
  'TIER_1': 'Starter',
  'TIER_2': 'Plus',
  'TIER_3': 'Premium',
  'TIER_4': 'Elite',
}

export const MobileWelcomeModal = (props) => {
  const { onBackDropClick, onClose } = props;
  const { userStore, wealthStore, filtersStore } = useStore();
  const { mixpanelTrack } = useAnalytics()
  const pageLocation = wealthStore.isMainResults ? 'My Portfolio main page' : 'My Portfolio filters' + (filtersStore.singleClassFilter ? ' - single class ' + filtersStore.singleClassFilter.title : '')

  const postAffiliateCb = useCallback(() => {
    if (window.PostAffTracker) {
      var sale = window.PostAffTracker.createSale();
      sale.setOrderID(`${userStore.data.id}_subscribed`);
      sale.setProductID('vyzer subscription');
      sale.setData1(userStore.data.id);
      sale.setData2(userStore.fullName);
      window.PostAffTracker.track();
    }
  }, [userStore])

  useEffect(() => {
    mixpanelTrack('Popup Viewed', {
      destination: ROUTES.wealthOverview.url,
      location: pageLocation,
      title: `Welcome to your private investment concierge`,
      cta_text: 'Start',
      type: 'Welcome Modal',
      is_demo: userStore.isDemoMode
    })

    // return () => {}
  }, [mixpanelTrack, pageLocation, userStore.isDemoMode])


  const handleStartClick = () => {
    mixpanelTrack('Popup Clicked', {
      click_type: 'CTA click',
      destination: ROUTES.wealthOverview.url,
      location: pageLocation,
      title: `Welcome to your private investment concierge`,
      cta_text: 'Start',
      type: 'Welcome Modal',
      is_demo: userStore.isDemoMode
    })
    onClose?.();
  }

  useEffect(() => {
    if (!userStore.isFreeTier) {
      postAffiliateCb();
    }
    return () => { }
  }, [userStore, postAffiliateCb])


  return (<Observer>{() => (
    <BaseModal width={'90%'} height={'auto'} borderRadius={8} onBackDropClick={onBackDropClick} noSidebarMargin={true} customBackDropStyle={{ zIndex: '99999999', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      customBackDropChild={
        <Lottie
          loop={true}
          animationData={confettiAnimation}
          speed={1}
          play
          style={{ position: 'absolute', zIndex: 1002, height: '100vh', width:'75vh', top:0 }}
        />
      }
    >
      <ModalContent>
        <ModalCloseButtonWrapper onClick={onClose}><ModalCloseButton /></ModalCloseButtonWrapper>
        <LogoWrapper />
        <MiddleMessage>Welcome to Vyzer {welcomeText[userStore.data?.profile?.tier]}!</MiddleMessage>
        <BottomMessage>Congratulations! Your journey with the ultimate investment management platform has just begun!</BottomMessage>
        <StartButton onClick={handleStartClick}>Let’s Do It</StartButton>
      </ModalContent>
    </BaseModal>
  )}</Observer>)
}

const ModalCloseButton = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="8" width="28" height="28" rx="14" fill="#E8E9ED" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.4194 27.7302L16.5199 17.8307L17.5806 16.77L27.4801 26.6695L26.4194 27.7302Z" fill="#585D66" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.4801 17.8306L17.5806 27.7301L16.5199 26.6694L26.4194 16.7699L27.4801 17.8306Z" fill="#585D66" />
    </svg>
  )
}