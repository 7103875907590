import { Tabs } from 'antd';
import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LogoLoader } from '../../../../../../common/components/LogoLoader/LogoLoader';
import { navigateToUrl } from '../../../../../../common/utils/navigation.utils';
import { useStore } from '../../../../../app/data/root.store';
import { CardContentBoxWrapper } from '../../../../components/wealth.commons.styles';
import { AssetOverviewHeroContent } from '../AssetOverviewHeroContent';
import '../AssetPage.css';
import { CenterContent, PageWrapper, TabTitleText, TabTitleWrapper, TabsContentWrapper, TabsWrapper } from '../AssetPage.styles';
import { AssetPageHeader } from '../AssetPageHeader';
import { ConnectedStockForm } from './ConnectedStockInfoForm';

const eligibleTabs = ['info'];

export function ConnectedStockAssetPage() {
  let { id, stockId, activeTab, className } = useParams();
  let history = useHistory();
  const { wealthStore, itemStore, uiStore, tickersStore, filtersStore, userStore } = useStore();
  const { TabPane } = Tabs;

  const handleTabChange = (key) => {
    navigateToUrl(history, (activeTab ? '' : (id + '/')) + key, true)
  }

  useEffect(() => {
    (async function getItem() {
      await itemStore.getItemData(id);
      itemStore.setStockAssetPageView(stockId);
      if (filtersStore.countActiveFilters === 0) {
        filtersStore.handleSelectClass(itemStore.item.categoryClass.id);
        filtersStore.setAppliedFiltersFromSelection();
        wealthStore.refreshWealthData(false);
      }
    })();
    return () => {
      itemStore.setSingleTickerView(false)
      itemStore.setHoldingsView(true);
      itemStore.setItemUpdated(true); // just to revalidate the page to prevent showing old information between pages
      tickersStore.setData([]);
    }
  }, [id,stockId, itemStore, filtersStore, wealthStore, tickersStore]);

  useEffect(() => {
    if (!activeTab || eligibleTabs.includes(activeTab)) {
      itemStore.setActiveTabUrl(activeTab);
    } else {
      navigateToUrl(history, `/wealth/assets/${className}/${id}`, true);
    }
  }, [activeTab, itemStore, history, id, className, userStore]);

  useEffect(() => {
    wealthStore.setSelectedClassUrlName(className);
  }, [className, wealthStore]);

  return (
    <Observer>{() => (
      <PageWrapper>
        {(!itemStore.isLoadedOnce || !itemStore.item?.id || !tickersStore.tickers.length) ?
          <LogoLoader backdrop absolute />
          :
          <>
            <CardContentBoxWrapper>
              <AssetPageHeader />
              <AssetOverviewHeroContent />
            </CardContentBoxWrapper>
            <CardContentBoxWrapper marginTop={16}>
              <TabsWrapper>
            
                <Tabs activeKey={itemStore.activeTab} onChange={handleTabChange}>

                  <TabPane key='info' tab={<TabTitleWrapper><TabTitleText>Asset info</TabTitleText></TabTitleWrapper>} />

                </Tabs>

              </TabsWrapper>
              <TabsContentWrapper id='tabs-content-wrapper'>

                {itemStore.activeTab === 'info' && <CenterContent>
                  <ConnectedStockForm stock={itemStore.item} parentAssetId={id} isIncognito={uiStore.isIncognitoMode}  />
                </CenterContent>}

              </TabsContentWrapper>

            </CardContentBoxWrapper></>}
      </PageWrapper>
    )}</Observer>)
}

