import React from 'react'
import { BackgroundImage, CloseButton, Content, CreateButton, LeftPane, Overlay, RightPane, SubTitle, Title } from './CustomClassEmptyStateModal.styles'
import CustomClassesEmptyStateBg from './assets/custom-classes-empty-state-bg.png';
import CustomClassesEmptyStateMobileBg from './assets/custom-classes-empty-state-mobile-bg.png';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../../app/data/root.store';
import { ROUTES } from '../../../../../common/constants/routes.const';
import { BaseModal } from '../../../../../common/components/BaseModal/BaseModal';
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import ReactDOM from 'react-dom';

export const CustomClassEmptyStateModal = observer(({ onClose, onCtaClick }) => {
  const { uiStore } = useStore();
  let history = useHistory();

  return (
    ReactDOM.createPortal(
    <BaseModal width={'880px'} height={uiStore.isDesktopView ? '412px' : '508px'} borderRadius={12}>
      <Overlay>
        <BackgroundImage imageUrl={uiStore.isDesktopView ? CustomClassesEmptyStateBg : CustomClassesEmptyStateMobileBg} />
        <LeftPane>
          <Content>
            <Title>Create custom asset classes and sub-asset classes</Title>
            <SubTitle>Use custom classes to visualize your wealth.</SubTitle>
            <CreateButton onClick={() => { onCtaClick?.(); navigateToUrl(history, uiStore.isDesktopView ? (ROUTES.preferences.url + '?spt=customClass') : ROUTES.customClassList.url) }}><PlusSvg /> Create custom classes</CreateButton>
          </Content>
        </LeftPane>
        <RightPane>
          <CloseButton onClick={onClose}>{uiStore.isDesktopView ? <ModalCloseButtonSvg /> : <ModalMobileCloseButtonSvg />}</CloseButton>
        </RightPane>
      </Overlay>
    </BaseModal>, document.body)
  )
})

const ModalCloseButtonSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2L14 14M14 2L2 14" stroke="#75818D" strokeWidth="1.5" />
    </svg>
  )
}

const ModalMobileCloseButtonSvg = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="8" width="28" height="28" rx="14" fill="#585D66" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.4194 27.7297L16.5199 17.8302L17.5806 16.7695L27.4801 26.669L26.4194 27.7297Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.4801 17.8311L17.5806 27.7306L16.5199 26.6699L26.4194 16.7704L27.4801 17.8311Z" fill="white" />
    </svg>
  )
}

const PlusSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 14L8 2" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M14 8H2" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  )
}