import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`

`;

export const YesNoButtonsWrapper = styled.div`
    display:flex;
    flex-direction:row;
`;

export const YesNoButton = styled.div`
    cursor: pointer;
    height: 38px;
    font-size:18px;
    width:122px;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:${props=>props.isSelected ? '#E9EDF1' : 'none'};
    padding:0px 24px;
    border-radius:2px;
    border : 1px solid #222222;
    &:hover{
        background-color: ${props=> props.black ? '#000' : '#f1f4f8'};
        border : 1px solid ${props=> props.black ? '#000' : '#222222'};;
    }
`;

export const SelectLiabilityWrapper = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    .ant-select{
        max-width:212px;
    }
    .ant-select-selection-placeholder{
        line-height:38px;
        color:#000;
        path {
            stroke:#000;
        }
        
    }
    .ant-select-disabled .ant-select-arrow path{
        stroke: rgba(0, 0, 0, 0.25);
    }
`;

export const CloseButtonWrapper = styled.div`
    padding:8px;
    cursor: pointer;
    margin-left:8px;
`
// export const Liability


export const LiabilitiesSelectOptionWrapper = styled.div`
    display:flex;
    justify-content:space-between;
    flex-direction:row-reverse;
    padding:8px 16px;
`;

export const LiabilityOptionText = styled.span`   
font-size:18px; 
overflow: hidden;
text-overflow: ellipsis;
padding-right: 8px;
`
export const LiabilityOptionValue = styled.span`
    font-size:18px;
`;
