import React from 'react'
import { observer } from 'mobx-react'
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal'
import { PrimaryButton } from '../../../../common/components/buttons/NewButtons.styles'
import { ModifiedTooltip } from '../../../../common/components/ModifiedTooltip/ModifiedTooltip'
import { DocumentDetailsWrapper, DocumentIconWrapper, DocumentRowWrapper, DocumentTitle } from '../DeepAnalysisSuccessModal/DeepAnalysisSuccessModal.styles'
import DeepAnalysisCoverImg from './assets/deep-analysis-cover.png'
import { DeepAnalysisPendingResultSubtitle, DeepAnalysisPendingResultTitle } from './ManualAnalysisPendingResultModal.styles'
import { useStore } from '../../../app/data/root.store'
import { docTypes } from '../DeepAnalysisSuccessModal/assets/docTypes'
import { MagicBoxAnimatedIconGrey } from '../MagicBoxAnimatedIconGrey'
import { useTrackEvent } from '../../../../hooks/useTrackEvent/useTrackEvent'
import { useAnalytics } from '../../../app/data/AnalyticsProvider'


export const ManualAnalysisPendingResultModal = observer(({ onClose }) => {
  

  const { magicBoxAiStore, userStore } = useStore();
  const { trackEvent } = useAnalytics();
  
  const fileName = magicBoxAiStore.file?.originalName;
  const fileType = fileName?.split('.').pop()?.toLowerCase();

  const noMargin = userStore.isWelcome;

  useTrackEvent('Popup Viewed', {
    title: 'Magic Box AI Manual Process Notice.',
    url: window.location.href
  })

  const onDone = () => {
    trackEvent('CTA Clicked', {
      cta_text: 'Got it',
      location: 'Magic box ai deep analysis results',
      destination: 'done',
      type: "Button",
      url: window.location.href
    });
    onClose();
  }

  return (
    <BaseModal borderRadius={8} height={'526px'} width={'480px'} noSidebarMargin={noMargin}>
      <img src={DeepAnalysisCoverImg} alt="Magic Box AI" style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8, maxWidth: '100%' }} />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px' }}>

        <DocumentRowWrapper>
          <DocumentIconWrapper>{docTypes[fileType]}</DocumentIconWrapper>
          <DocumentDetailsWrapper overflowHidden>
           <DocumentTitle overflowHidden>{fileName}</DocumentTitle>
          </DocumentDetailsWrapper>
          <ModifiedTooltip title={`This file is in our deep analysis. This data will appear in your portfolio within 24 hours.`}>
            <MagicBoxAnimatedIconGrey />
          </ModifiedTooltip>
        </DocumentRowWrapper>

        <div style={{ paddingTop: '32px', fontSize: '30px' }}>🎉</div>
        <DeepAnalysisPendingResultTitle>Deep Analysis...</DeepAnalysisPendingResultTitle>
        <DeepAnalysisPendingResultSubtitle>Your file moved to analyzing process.<br />this process can take up to 24h.</DeepAnalysisPendingResultSubtitle>
        <PrimaryButton style={{ borderRadius: '40px', width: '144px', height: '48px' }} onClick={onDone}>Got it</PrimaryButton>
      </div>
    </BaseModal>
  )
});