import { makeAutoObservable } from "mobx";
import { errorNotification } from "../../layout/components/Notifications/notifications";
import { getMagicBoxData, uploadDocuments, deleteDocument, confirmUploadedFiles, updateFileNotes } from "./magic-box.service";
import moment from "moment";

export const MAGIC_BOX_FORM_STEPS = {
    DROP_FILES: 'drop_files',
    DONE_SUCCESS: 'done_success',
    DONE_FAILED_FILE_SIZE: 'done_failed_file_size',
    DONE_FAILED_FILE_FORMAT: 'done_failed_file_format',
}

export class MagicBoxStore {
    // showModal = false;
    isLoading = true;
    isUploading = false;
    formStep = MAGIC_BOX_FORM_STEPS.DROP_FILES;
    uploadingFiles = [];
    uploadedFiles = [];
    showLimitationPopup = false;
    filesLeftLimitationPopup = { show: false, sum: 0 };
    shouldStartLearnTour = false;


    constructor() {
        makeAutoObservable(this);
    }

    setLoading(isLoading) {
        this.isLoading = isLoading;
    }

    setUploading(isUploading) {
        this.isUploading = isUploading;
    }

    fetchData = async () => {
        this.setLoading(true);
        try {
            const data = await getMagicBoxData();
            this.setData(data);
        }
        catch (e) { }
        finally {
            this.setLoading(false);
        }
    }

    uploadDocuments = async (acceptedFiles, fileRejections) => {
        // console.log(acceptedFiles,fileRejections)
        if (fileRejections.length > 0) {
            if (fileRejections.find(rej => rej.errors.find(error => error.code === 'file-invalid-type'))) {
                this.setFormStep(MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_FORMAT);
            } else {
                this.setFormStep(MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_SIZE);
            }
        } else if (acceptedFiles.length > 0) {
            this.setUploading(true);
            try {

                let formData = new FormData();
                Array.from(acceptedFiles).forEach(file => {
                    formData.append('files', file, file.name);
                })
                const uploadedDocuments = await uploadDocuments(formData);
                if (uploadedDocuments.statusCode) {
                    errorNotification('Something went wrong');
                } else {
                    // successNotification('Uploaded successfully')
                    this.setUploadingFiles([...this.uploadingFiles, ...uploadedDocuments.map(item => ({ ...item, status: 'Processing' }))]);
                    // this.setData([...this.uploadingFiles,...uploadedDocuments.documents]);
                }
                // const data = await getMagicBoxData();
                // this.setData(data);
                this.setFormStep(MAGIC_BOX_FORM_STEPS.DONE_SUCCESS);
            }
            catch (e) { }
            finally {
                this.setUploading(false);
            }
        }
    }

    async updateNotes(index, notes) {
        try {
            const updatedFile = { ...this.uploadedFiles[index], notes };
            await updateFileNotes(this.uploadedFiles[index].id, notes);

            const updatedList = [...this.uploadedFiles];
            updatedList[index] = updatedFile;
            this.setData(updatedList);
        } catch (e) {}
    }

    setData(data) {
        this.uploadedFiles = data;
    }

    setUploadingFiles(data) {
        this.uploadingFiles = data;
    }

    async approveUploadingFiles(userStore) {
        const fileIds = this.uploadingFiles.map(f => f.id);
        if (fileIds.length) {
            // if(!isActivationFlow) {
            this.setData([...this.uploadingFiles, ...this.uploadedFiles]);
            this.setUploadingFiles([]);
            // }
            this.setFormStep(MAGIC_BOX_FORM_STEPS.DROP_FILES);
            try {
                await confirmUploadedFiles(fileIds);
                userStore.updateData();
            }
            catch (e) { }
            finally { }
        } else {
            this.setFormStep(MAGIC_BOX_FORM_STEPS.DROP_FILES);
        }
    }

    deleteFile(fileId) {
        deleteDocument(fileId);
        const updatedList = this.uploadingFiles.filter(f => f.id !== fileId);
        this.setUploadingFiles(updatedList);
        if (updatedList.length === 0) {
            this.setFormStep(MAGIC_BOX_FORM_STEPS.DROP_FILES);
        }
    }

    // setShowModal(show) {
    //     this.showModal = show;
    // }

    setFormStep(step) {
        this.formStep = step;
    }

    setShowLimitationPopup(show) {
        this.showLimitationPopup = show;
    }

    setShowFilesLeftLimitationPopup(show, sum = 0) {
        this.filesLeftLimitationPopup = { show, sum };
    }

    setShouldStartLearnTour(start) {
        this.shouldStartLearnTour = start;
    }

    get uploadThisMonthAmount() {
        return this.uploadedFiles.filter(f => moment().isSame(moment(f.createdAt), 'M')).length;
    }

    get hasAnyFileUpload() {
        return this.uploadingFiles.length > 0 || this.uploadedFiles.length > 0;
    }

    get filesInProcessCount() {
        return this.uploadedFiles?.reduce((acc, document) => acc + (document.status === 'Processing' ? 1 : 0), 0);
    }

    get filesDoneCount() {
        return this.uploadedFiles?.reduce((acc, document) => acc + (document.status === 'Done' ? 1 : 0), 0);
    }

    get sortedUploadedFiles() {
        return this.uploadedFiles.slice().sort((a, b) => {
            const statuses = ['In process', 'Processing', 'Rejected', 'Done']; // this applies to the sorting order
            const statusA = statuses.indexOf(a.status);
            const statusB = statuses.indexOf(b.status);
            return statusA - statusB;
        });
    }

    get showModal() {
        const stepsWithModal = [MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_FORMAT, MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_SIZE, MAGIC_BOX_FORM_STEPS.DONE_SUCCESS];
        return stepsWithModal.includes(this.formStep);
    }
}