import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors } from "../../../../common/styles/theme.styles";

export const PlanFutureCashFlowHeaderWrapper = styled.div`
  display: flex;
  background-color: ${colors.darkHeroBackground};
  border-radius: 8px 8px 0px 0px;
  padding: 10px;
`;

export const PlanFutureCashFlowHeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 6px 14px;
`;

export const PlanFutureCashFlowHeaderTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 120.5%;
  color: #fff;
`;

export const PlanFutureCashFlowHeaderSubtitle = styled.div`
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.gray2};
`;

export const LearnButtonWrapper = styled.div`
  ${flexCenter};
  cursor: pointer;
  background-color: #2D2F33;
  gap: 8px;
  padding: 6px 8px;
  border: 1px solid ${colors.darkGray2};
  border-radius: 6px;
  color: ${colors.gray3};
  height: 32px;
  margin-left: auto;
`;