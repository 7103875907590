import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import EmptyCashflowAnimData from './assets/empty-cashflow-animation-data.json';
import { PlanFutureCashFlowEmptyStateIllustrationWrapper, PlanFutureCashFlowEmptyStatePrompt, PlanFutureCashFlowEmptyStateTextWrapper, PlanFutureCashFlowEmptyStateTitle, PlanFutureCashFlowEmptyStateWrapper } from './EmptyState.styles';

export const PlanFutureCashFlowEmptyState = ({isMobile}) => {
  return (
    <PlanFutureCashFlowEmptyStateWrapper>
      <PlanFutureCashFlowEmptyStateTextWrapper>
        <PlanFutureCashFlowEmptyStateTitle>{`Smartly plan your\nfuture cash flow.`}</PlanFutureCashFlowEmptyStateTitle>
        <PlanFutureCashFlowEmptyStatePrompt>{`Forecast your future cash flow based on your portfolio's expected inflows and outflows, your household's cost of living, and any custom scenarios you may have.`}</PlanFutureCashFlowEmptyStatePrompt>
      </PlanFutureCashFlowEmptyStateTextWrapper>
      <PlanFutureCashFlowEmptyStateIllustrationWrapper>
        <Player
          autoplay={true}
          loop={true}
          src={EmptyCashflowAnimData}
          speed={1}
          style={ isMobile ? {width:'100%'} : { zIndex: '2', width: '666px' }}
        />
      </PlanFutureCashFlowEmptyStateIllustrationWrapper>
    </PlanFutureCashFlowEmptyStateWrapper>
  )
}
