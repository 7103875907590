import { Input } from 'antd';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { ModalCloseIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { CategorySelector } from '../../../../common/components/CategorySelector/CategorySelector';
import { SecondaryButton } from '../../../../common/components/buttons/NewButtons.styles';
import { displayMoneyValue } from '../../../../common/utils/number-display.utils';
import { AccountTitleAndCategory, AddedAccountName } from '../../../add-item/components/AddItem.styles';
import { useStore } from '../../../app/data/root.store';
import { ModalButton, ModalClose, ModalContent, ModalFooter, ModalHeader, ModalTitle } from '../../../wealth/pages/asset/components/Events/AssetEventModal.styles';
import { InstitutionLogo } from '../../utils/InstitutionLogo';
import { AccountBalance, AccountBalanceAndActions, AccountRowWrapper, HoldingsInputWrapper, ModalEditNameWrapper, ModalSectionWrapper } from './ConnectedInstitutionModal.styles';

export const ConnectedInstitutionModal = (props) => {
  const { institution, account, isLiability, isCreditCard, isOtherAccount, amount, amountCurrency, isIncognito, provider, handleOk, onClose, isDesktopView } = props;
  const { metadataStore } = useStore();
  const [connectedAccountName, setConnectedAccountName] = useState(account.name || account.accountName);
  const [connectedAccountHoldings, setConnectedAccountHoldings] = useState(account.holdings || 100);
  // console.log(account.isCheckingClass , account.categoryId )
  const [selectedCategory, setSelectedCategory] = useState(account.categoryId ? account.categoryId : account.isCheckingClass ? 1 : isCreditCard ? 34 : isLiability ? 37 : null);
  const relevantClasses = isLiability || isCreditCard ? metadataStore.liabilityClassesWithCategoriesForSync : 
    account.isCheckingClass ? metadataStore.classesWithCategoriesForChecking :
    isOtherAccount ? metadataStore.classesWithCategoriesForOther : metadataStore.classesWithCategoriesForAssetsWithoutChecking;

  const handleSelectedClassOnChange = (classId, categoryId) => {
    setSelectedCategory(categoryId);
  }

  return (
    <BaseModal width={'600px'} height={'auto'} borderRadius={'8'}>
      <ModalHeader style={{ boxShadow: 'inset 0px -1px 0px #DDE3EB', borderTopRightRadius: '8px', borderTopLeftRadius: '8px' }}>
        <ModalTitle>
          Choose {isLiability ? 'liability' : 'asset'} category
        </ModalTitle>
        <ModalClose onClick={onClose}>
          <ModalCloseIcon />
        </ModalClose>
      </ModalHeader>
      <ModalContent style={{ display: 'flex', flexDirection: 'column', gap: '24px', backgroundColor: '#fff', padding: isDesktopView ? '24px 68px 32px 68px' : '16px' , overflowY : 'auto' }}>

        <ModalSectionWrapper>
          Selected account
          <AccountRowWrapper>

            <InstitutionLogo institution={institution} provider={provider} customWrapperStyles={{ marginLeft: '12px' }} />

            <AccountTitleAndCategory>
              <AddedAccountName>{account.name || account.accountName}</AddedAccountName>
            </AccountTitleAndCategory>

            <AccountBalanceAndActions>
              <AccountBalance isPositive={amount > 0}>
                {displayMoneyValue(amount, amountCurrency, isIncognito, 100)}
              </AccountBalance>
            </AccountBalanceAndActions>

          </AccountRowWrapper>
        </ModalSectionWrapper>

        <ModalSectionWrapper>
          Choose category
          <CategorySelector options={relevantClasses} value={selectedCategory} onCategoryChange={handleSelectedClassOnChange} />
        </ModalSectionWrapper>

        <ModalEditNameWrapper>
          {isLiability ? 'Liability' : 'Asset'} name
          <Input value={connectedAccountName} onChange={(e) => setConnectedAccountName(e.target.value)} />
        </ModalEditNameWrapper>

        <HoldingsInputWrapper>
          My ownership
          <NumberFormat
            customInput={Input}
            value={connectedAccountHoldings}
            onBlur={(e) => { setConnectedAccountHoldings(parseFloat(e.target.value)) }}
            placeholder={'%'}
            suffix={'%'}
          />
        </HoldingsInputWrapper>

      </ModalContent>
      <ModalFooter style={{ boxShadow: 'inset 0px -1px 0px #DDE3EB', borderBottomRightRadius: '8px', borderBottomLeftRadius: '8px', display: 'flex', justifyContent: 'space-between' }}>
        <SecondaryButton onClick={onClose} style={{ padding: '5px 16px' }}>Cancel</SecondaryButton>
        <ModalButton disabled={selectedCategory === null} onClick={() => { handleOk?.(selectedCategory, connectedAccountName, connectedAccountHoldings) }}>
          Create new {isLiability ? 'Liability' : 'Asset'}
        </ModalButton>
      </ModalFooter>
    </BaseModal>
  )
}