import { getData, postData, putData } from '../../../common/utils/network.utils';

export const fetchPanelData = async () => {
    return (await getData('accounts/panel')).data;
}

export const syncAllInstitutions = async () => {
    return (await getData('accounts/sync-institutions')).data;
}

export const syncInstitution = async(source,iId) => {
    return (await getData(`accounts/sync-institution/${source}/${iId}`)).data;
}

export const fetchPlaidOrSaltedgeInstitutionAccounts = async (source,piId) => {
    return (await getData(`accounts/${source}/${piId}/balances`)).data;
}

export const fetchZaboInstitutionBalances = async (ziId) => {
    return (await getData(`accounts/zabo/${ziId}/balances`)).data;
}

export const connectPlaidAccounts = async (data) => {
    return (await postData('connected-accounts/connect',data)).data;
}


export const connectAccounts = async (data) => {
    return (await postData('accounts/connect_multiple_accounts',data));
}

export const disconnectAccount = async (data) => {
    return (await postData('accounts/disconnect_account',data)).data;
}

export const createZaboAccount = async (zaboLinkdata) => {
    return (await postData('zabo/account',zaboLinkdata)).data;
}

export const createZaboBalances = async (balancesData) => {
    return (await postData('zabo/balance',balancesData)).data;
}

export const deleteAccounts = async (source, instId, deleteType) => {
    return (await postData(`accounts/${source}/${instId}`,deleteType)).data;
}

export const connectAssetToVipServiceInstitution = async (instId, assetId, removeConnection, archiveAsset) => {
    return (await postData(`assets/vip-connection`,{itemId: assetId , vipId : instId, removeConnection, archiveAsset})).data;
}

export const vipInstitutionUpdateLastSync = async (instId) => {
    return (await putData(`accounts/vip/${instId}/updateLastSync`,{})).data;
}

export const vipInstitutionChangeStatus = async (instId) => {
    return (await putData(`accounts/vip/${instId}/changeStatus`,{})).data;
}
