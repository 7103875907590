import React, { useState, } from 'react';
import { ArrowDownIconWrapper, HoverActionsWrapper, MobileContentButton, MobileContentButtons, MobileHeaderMoreInfo, MobileHeaderNameAndInfo, MobileHeaderNameText, MobileRowContent, MobileRowContentInfoRow, MobileRowHeaderWrapper, MobileRowWrapper, RoleNameEmail, RoleNameText, RolesNameAndMail, RolesNameCell, RolesNameIconWrapper, RolesTableCell, RolesTableRow, TableActionButtonWrapper } from './Roles.styles';
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import { ArrowDownIcon } from '../../../../assets/icons/common/common-icons';
import { EditRole } from './EditRole';
import { ModifiedTooltip } from '../../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { capitalize } from '../../../../common/utils/string.utils';
import { ROUTES } from '../../../../common/constants/routes.const';

export const permissionsTexts = {
  'GUEST' : 'View only',
  'EDITOR' : 'View and edit',
  'OWNER' : 'Full access',
  'ADMIN' : 'Full access',
}

export const RoleRow = (props) => {
  const { uiStore , userStore, billingStore } = useStore();

  const updateRole = (data) => {
    userStore.updateRole(data);
  }

  const deleteRole = (id) => {
    userStore.deleteRole(id);
  }

  const resendInvitation = (id) => {
    userStore.resendInvitation(id);
  }

  const showBillingModal = async () => {
    if (userStore.isDemoMode && userStore.isRelevantVariant === 6) {
      await billingStore.setStarterPlan();
      window.location.href = `${ROUTES.wealthOverview.url}?subscribed_success&sw`;
    } else {
      billingStore.setShowPricingTableModal(true);
    }
  }

  const PropsWithMethods = { ...props, updateRole, deleteRole, resendInvitation, showBillingModal };

  return (
      <Observer>{() => (
          uiStore.isDesktopView ? 
          <RoleRowDesktop {...PropsWithMethods} /> : 
          <RoleRowMobile {...PropsWithMethods} />
      )}</Observer>
  )
}




export function RoleRowDesktop({ item , updateRole , deleteRole , resendInvitation, showBillingModal, isDemoRole = false }) {
  
  //const [isHoverOn, setIsHoverOn] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  return (
    <RolesTableRow 
      isOwner={item.isOwner}
      onClick={  ()=>{ if (!item.isOwner){ setIsEdit(true)}}}
      // onClick={() => { onEditedIndexChange?.(documentIndex) }}
       //onMouseEnter={() => { setIsHoverOn(true) }} onMouseLeave={() => { setIsHoverOn(false) }} 
      >

      <RolesNameCell>
        <RolesNameIconWrapper isOwner={item.isOwner} isDemoRole={isDemoRole} color={item.color}>
            {item.granteeName.charAt(0).toUpperCase()}
        </RolesNameIconWrapper>
        <RolesNameAndMail>
            <RoleNameText>{capitalize(item.granteeName)}</RoleNameText>
            <RoleNameEmail>{item.granteeEmail}</RoleNameEmail>
        </RolesNameAndMail>
      
      </RolesNameCell>

      <RolesTableCell>
        {item.roleDescription}
      </RolesTableCell>

      <RolesTableCell>
        {permissionsTexts[item.role]}
      </RolesTableCell>

      <RolesTableCell>
        {''+item.lastActivity}
      </RolesTableCell>

      {!item.isOwner ? <HoverActionsWrapper>
        {!isDemoRole && !item.isActive && 
        <ModifiedTooltip title={item.canReSend ? "Resend invitation" : "Invitation sent"}>
        <TableActionButtonWrapper onClick={(e)=>{e.stopPropagation();resendInvitation(item.id)}} disabled={!item.canReSend}>
            <EmailIcon />
        </TableActionButtonWrapper></ModifiedTooltip>}
        <ModifiedTooltip title="Edit Role & Permissions"><TableActionButtonWrapper onClick={()=>{setIsEdit(true)}}>
            <EditIcon />
        </TableActionButtonWrapper>
        </ModifiedTooltip>
      </HoverActionsWrapper> : <div /> }
      {isEdit && <EditRole isEdit item={item}  onClose={()=>{setIsEdit(false)}} onSave={(data)=>{updateRole(data);setIsEdit(false)}} onDelete={(id)=>{deleteRole(id);setIsEdit(false)}} isDemoRole={isDemoRole} onStartPortfolioClick={showBillingModal} />}
    </RolesTableRow>
  )
}

export function RoleRowMobile({ item , itemIndex, updateRole , deleteRole, resendInvitation, showBillingModal, isDemoRole = false }) {
  
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
  
    return (
        <>
      <MobileRowWrapper isOpen={isOpen} key={itemIndex} onClick={()=>{setIsOpen(!isOpen)}}
        // onClick={() => { onEditedIndexChange?.(documentIndex) }}
         //onMouseEnter={() => { setIsHoverOn(true) }} onMouseLeave={() => { setIsHoverOn(false) }} 
        >
            <MobileRowHeaderWrapper >
                    <RolesNameIconWrapper isOwner={item.isOwner} isDemoRole={isDemoRole} color={item.color}>
                    {item.granteeName.charAt(0).toUpperCase()}
                </RolesNameIconWrapper>
                <MobileHeaderNameAndInfo>
                    <MobileHeaderNameText>{item.granteeName}</MobileHeaderNameText>
                    <MobileHeaderMoreInfo>{item.roleDescription} · {permissionsTexts[item.role]}</MobileHeaderMoreInfo>
                </MobileHeaderNameAndInfo>
                <div style={{flex:1}} />
                <ArrowDownIconWrapper isOpen={isOpen}>
                    <ArrowDownIcon />
                </ArrowDownIconWrapper>
            </MobileRowHeaderWrapper>
            {isOpen && <><MobileRowContent>
                <MobileRowContentInfoRow>
                    <div className='label'>Email:</div>
                    <div className='info'>{item.granteeEmail}</div>
                </MobileRowContentInfoRow>
                <MobileRowContentInfoRow>
                    <div className='label'>Last activity:</div>
                    <div className='info'>{item.lastActivity}</div>
                </MobileRowContentInfoRow>
                </MobileRowContent>
                {!item.isOwner && <MobileContentButtons>
                    {!isDemoRole && <MobileContentButton onClick={(e)=>{e.stopPropagation();if (item.canReSend){resendInvitation(item.id)}}}  disabled={!item.canReSend} border>{item.canReSend ? "Resend invitation" : "Invitation sent"}</MobileContentButton>}
                    <MobileContentButton onClick={(e)=>{e.stopPropagation();setIsEdit(true)}}>Edit permissions</MobileContentButton>
                </MobileContentButtons>}
            </>}
  
      
        
  
        {/* <HoverActionsWrapper>
          <TableActionButtonWrapper>
              <EmailIcon />
          </TableActionButtonWrapper>
          <TableActionButtonWrapper>
              <EditIcon />
          </TableActionButtonWrapper>
        </HoverActionsWrapper> */}
       
      </MobileRowWrapper>
      { isOpen &&  <EditRole isEdit item={item} onClose={()=>{setIsEdit(false)}} visible={isEdit} onSave={(data)=>{updateRole(data);setIsEdit(false)}} onDelete={(id)=>{deleteRole(id);setIsEdit(false)}} isDemoRole={isDemoRole} onStartPortfolioClick={showBillingModal} />}
      </>
    )
  }

const EmailIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
        <rect width="38" height="38" rx="19" fill="#DDE3EB" fillOpacity="0.5"/>
        <path d="M9 14.2353C9 13.5531 9.55306 13 10.2353 13H27.7647C28.4469 13 29 13.5531 29 14.2353V23.7647C29 24.4469 28.4469 25 27.7647 25H10.2353C9.55306 25 9 24.4469 9 23.7647V14.2353Z" stroke="#1A1B1D" strokeWidth="1.5" strokeLinejoin="round"/>
        <path d="M12 16L19 20L26 16" stroke="#1A1B1D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }
  
  const EditIcon = () => {
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="38" height="38" rx="19" fill="#DDE3EB" fillOpacity="0.5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.1401 11.2937C24.9443 11.099 24.6273 11.0997 24.4322 11.2951L17.2467 18.4933C17.1869 18.5533 17.1433 18.6274 17.12 18.7087L16.407 21.2016C16.3638 21.3526 16.5041 21.492 16.6553 21.4483L19.1508 20.7269C19.2323 20.7033 19.3064 20.6596 19.3662 20.5996L24.6413 15.3152L22.5307 13.2046L23.2378 12.4975L25.3478 14.6075L26.5517 13.4014C26.7468 13.2059 26.7462 12.8897 26.5503 12.6951L25.1401 11.2937Z" fill="#1A1B1D"/>
        <path d="M25 19V24C25 24.5523 24.5523 25 24 25H14C13.4477 25 13 24.5523 13 24V14C13 13.4477 13.4477 13 14 13H19" stroke="#1A1B1D" strokeWidth="1.5"/>
        </svg>
        
    )
  }