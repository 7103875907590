import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../styles/combos.styles";
import { colors, shadows } from "../../../../styles/theme.styles";

export const Wrapper = styled.div`
    width: 100%;
    border-radius: 9px;
    display:flex;
    flex-direction:column;
`;

export const TableRowSizes = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    // padding: 8px 0;
`;

export const TableHeaderRow = styled(TableRowSizes)`
`;

export const HeaderCell = styled.div`
    color: ${colors.darkGray1};
    font-size:16px;
    line-height: 16px;
    font-weight: normal;
`;

export const TableScrollContent = styled.div`
    .ant-picker , .ant-input{
        border-radius:0px;
        &:hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
            box-shadow: none;
        }
    }

    .ant-input-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }

    .ant-dropdown-trigger{
        font-size: 14px;
        padding-left: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 0px;
    }
`;

export const TableBottomActions = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    padding-top:8px;
    gap: 26px;
`;

export const CreateButton = styled.div`
  ${flexCenter};
  padding: 6px 16px;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid ${colors.gray3};
  background: #fff;
  box-shadow: ${shadows.toggleShadow};
  cursor: pointer;
  cursor: pointer;
  ${props=> props.disabled ? `
  opacity: 0.4;
  cursor: not-allowed;
` : ``}
`;

export const RowWrapper = styled(TableRowSizes)`
    // padding: 8px 0px;
    padding-top: 4px;
    position:relative;
    :last-of-type{
        padding-bottom:0px;
    }
    min-height:48px;
`;

export const RowCell = styled.div`
    font-size:14px;
    line-height:24px;
    color: ${colors.darkGray1};
    align-items: center;
    display:flex;

    :first-of-type {
        .ant-input{
            border-radius: 4px 0 0 4px;
        }
        .ant-picker{
            border-radius: 4px 0 0 4px;
        }
        .ant-dropdown-trigger{
            border-radius: 4px 0 0 4px;
        }
    }
    .ant-input{
        height:38px;
        font-size: 14px;
        &.inputEntryError{
            border: 1px solid #FF7676;
        }
    }
    .ant-picker{
        font-size: 14px;
    }
    .ant-picker > .ant-picker-input > input{
        font-size: 14px;
    }
    .ant-input-affix-wrapper{
        height:38px;
        .ant-input {
            font-size: 14px;
            height:22px;
        }
    }

    ${props => !props.isEditMode ? `
    .ant-input-affix-wrapper {
        .ant-input {
            font-size: 14px;
            background-color: transparent;
            border-color: transparent;
        }
    }
    ` : null}
`;

export const InputWithBadgeWrapper = styled.div`
    position:relative;
    width:${props => props.isWithHoldings ? '50' : '100'}%;
    .ant-input{
        font-size: 14px;
    }
`

export const HoldingsBadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    position:absolute;
    left:8px;
    top: 50%;
    transform: translateY(-50%);
    
`;

export const HoldingsBadgeText = styled.div`
    background-color: ${colors.holdingsBadgeColor};
    width:8px;
    height:12px;
    border-radius: 2px 0px 0px 2px;
`;

export const HoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid ${colors.holdingsBadgeColor};
`;

export const RowActionsCell = styled.div`
    align-items: center;
    display:flex;
    position:absolute;
    left:100%;
    top:10px;
    opacity:0;
    ${RowWrapper}:hover &{
        opacity:1;
    }
    &:hover{
        opacity:1;
    }
    
`;

export const ActionButtonWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px;
    svg{
        height: 24px;
        path {
            stroke: #000;
        }
    }
`;

export const PlusIconWrapper = styled.div`
    ${flexCenter};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    box-shadow: ${shadows.toggleShadow};
`;