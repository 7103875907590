import styled from '@emotion/styled/macro';
import { colors } from '../../../../common/styles/theme.styles';

export const ModalWrapper  = styled.div`
    padding: 18px 20px 13px 24px;
    background: #F7F8F9;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.32), 0px 4px 16px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    height: 100%;
`;

export const HeaderWrapper  = styled.div`
    /* height: 56px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
`;

export const ExportModalTitle  = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${colors.darkGray1};    
`;

export const CloseBtnWrapper  = styled.div`
    cursor: pointer;    
`;

export const ModalContentWrapper  = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${colors.darkGray1};
    padding-bottom: 28px;
    border-bottom: 1px solid black;
    margin-bottom: 12px;
`;

export const ActionButtonsWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 12px;
    margin-right: 4px;
`