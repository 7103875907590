import { observer } from 'mobx-react'
import React from 'react'
import { useStore } from '../../../modules/app/data/root.store'
import { ImageTile } from './CommonCustomClassIcon.styles'
import { customClassSvgObj } from '../../../modules/user/components/CustomClassList/new-svgs/new-custom-svgs';
import { customClassColorsObj } from '../../../modules/user/components/CustomClassList/icons/custom-colors';
import { AllocationCustomClassIcon, AllocationSubCustomClassIcon } from '../../../modules/allocations/components/AllocationIcon';

export const CommonCustomClassIcon = observer(({customClassId, isEmptySubClass, borderRadius, size}) => {
    const { metadataStore } = useStore()
    const { customClasses } = metadataStore

    const customClass = customClasses.find((customClass) => customClass.id === customClassId)
    const SVG = customClassSvgObj[customClass?.icon] || ( isEmptySubClass ? AllocationSubCustomClassIcon : AllocationCustomClassIcon );
    const { colorImage, fallbackColor } = customClassColorsObj[customClass?.colorCode] || {colorImage:'', fallbackColor:'#585D66'}

    return (
        <ImageTile
            image={colorImage}
            fallbackColor={fallbackColor}
            size={size || 32}
            borderRadius={borderRadius || 0}
        >
            <SVG />
        </ImageTile>
    )
})
