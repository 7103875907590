import styled from "@emotion/styled";
import { ModalHeader } from "../../../../../../../common/components/BaseModal/BaseModal.styles";
import { colors } from "../../../../../../../common/styles/theme.styles";

export const AmortizationUpdateModalHeader = styled(ModalHeader)`
  display: flex;
  align-items: center;
  gap: 16px;

  h3 {
    white-space: nowrap;
    font-size: 16px;
  }
  & > svg:last-child {
    margin-left: auto;
  }

  /* & > *:first-child {
    padding-right: 16px;
  } */
`

export const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
  border-left: 1px solid ${colors.gray3};
  padding-left: 16px;
  
  .icon {
    flex-shrink: 0;
    border-radius: 50%;
  }

  h4 {
    display: block;
    font-size: 16px;
    line-height: 1.3em;
  }

  & + svg {
    width: 50px;
    box-sizing: content-box;
    cursor: pointer;
    align-self: flex-start;
    position: relative;
    top: -12px;
    right: -12px;
  }
`