import TextArea from 'antd/lib/input/TextArea'
import React from 'react'

const sharedStyles = {
  resize: 'none',
  borderRadius: '6px',
  lineHeight: '22px',
  fontSize: '14px',
  overflow: 'hidden',
  gridArea: ' 1/1/2/2',
  outline: 'none',
  boxShadow: 'none',
  overflowWrap: 'anywhere',
  padding: '4px 11px'
}

export const ModifiedTextArea = ({ value, onChange, isEdited = false, isHoverOn = false, customStyle = {}, ...props }) => {
  return (
    <div style={{ display: 'grid', flex:1 }}>
      <TextArea
        {...props}
        value={value}
        onChange={onChange}
        rows={1}
        style={{ ...((isEdited || isHoverOn) ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }), ...sharedStyles, ...customStyle }}
        placeholder={(isEdited || isHoverOn) ? 'Add any remakes...' : ''} />
      <div style={{ whiteSpace: 'pre-wrap', visibility: 'hidden', ...sharedStyles, ...customStyle }}>{value}</div>
    </div>
  )
}
