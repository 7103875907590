import React, { useRef, useState , useEffect} from 'react';
import { CardContentBoxWrapper } from '../../wealth/components/wealth.commons.styles';
import { Observer } from 'mobx-react';
import { MobileContentHeader, MobileHeaderTab, MobileHeaderTabsWrapper } from './CashFlowPage.styles';
import { MobileLeftCornerMask, MobileRightCornerMask } from '../../../assets/icons/common/common-icons';
import { Tabs } from 'antd';
import { TransactionsPanel } from './TransactionsPanel/TransactionsPanel';
import { SimulationsPanel } from './SimulationsPanel/SimulationsPanel';
import { useAnalytics } from '../../app/data/AnalyticsProvider';
// import { EllipsisOutlined } from '@ant-design/icons'

export function CashFlowMobileBottomPanel(props) {
    // const { item , isSelected, onClick } = props;
    const { isIncognito, setShowCostOfLivingModal } = props;
    const tableHeaderRef = useRef(null);
    const [isTableHeaderFixed,setIsTableHeaderFixed] = useState(false);
    const [selectedTab,setSelectedTab] = useState('events');
    const { TabPane } = Tabs;
    const { mixpanelTrack } = useAnalytics();

    const checkScroll = () => {
    
      if (tableHeaderRef.current) {
        const { y } = tableHeaderRef.current.getBoundingClientRect();
        // console.log(y);
        setIsTableHeaderFixed(y < 64);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', checkScroll);
      
      // Initial check to set the state correctly on mount
      checkScroll();
  
      return () => window.removeEventListener('scroll', checkScroll);
    }, []);


    const handleTabClick = (viewMode) => {
      setSelectedTab(viewMode);
      mixpanelTrack('Tab Clicked', {
        destination : viewMode,
        location: "Cash flow",
        tab_text: viewMode,
        type: 'tab',
        url: window.location.href
      })
    }

    
    return (<Observer>{() => (
      <>
        <div ref={tableHeaderRef} style={{width:'100%' , height: isTableHeaderFixed ? '50px' : 0}}></div>
        <CardContentBoxWrapper borderlessTop>
            <MobileContentHeader  isFixed={isTableHeaderFixed}>
              <MobileHeaderTabsWrapper>
              <Tabs activeKey={selectedTab} onChange={handleTabClick}>
                <TabPane
                  key='events'
                  tab={
                    <MobileHeaderTab isSelected={selectedTab === 'events'} >Expected Events</MobileHeaderTab>
                  }
                />
                <TabPane
                  key='scenarios'
                  tab={
                    <MobileHeaderTab isSelected={selectedTab === 'scenarios'} >Scenarios</MobileHeaderTab>
                  }
                />
              </Tabs>
              </MobileHeaderTabsWrapper>
              <MobileLeftCornerMask style={{position:'absolute', top:'0' , left:'0'}}/>
              <MobileRightCornerMask style={{position:'absolute', top:'0' , right:'0'}}/>
            </MobileContentHeader>   
              {selectedTab === 'events' && <TransactionsPanel mobileMode isIncognito={isIncognito} setShowCostOfLivingModal={setShowCostOfLivingModal} /> }
              {selectedTab === 'scenarios' && <SimulationsPanel />}
            {/* <h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2>
            <h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2>
            <h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2>
            <h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2><h2>Hello</h2> */}
        </CardContentBoxWrapper>
      </>
        
    )}</Observer>)
}