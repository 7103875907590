import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors, shadows } from "../../../../common/styles/theme.styles";

export const PaneWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height:100%;
    flex:1;
    background-color:#FBFBFC;
    width:260px;
    border-left:1px solid ${colors.gray3};
`;

export const Header = styled.div`
    position:relative;
    display:flex;
    flex-direction: column;
    border-bottom:1px solid ${colors.lightGray2};
    padding:12px 16px;
    gap:6px;
`;

export const TitleAndCloseButton = styled.div`
    ${flexCenter};
    justify-content: space-between;
`;

export const Title = styled.div`
    font-weight:500;
    font-size:14px;
    color:${colors.darkGray1};
`;

export const CloseButtonWrapper = styled.button`
    width:24px;
    height:24px;
    border-radius:28px;
    padding:0px;
    background-color: #FBFBFC;
    cursor:pointer;
    border:none;
    ${flexCenter} 
    :hover{
        background-color:${colors.lightGray2};
    }
`;


export const ProgressBarWrapper = styled.div`
    width:100%;
    height:7px;
    border-radius:10px;
    background: rgba(192, 212, 252, 0.3);

`;

export const ProgressBarFill = styled.div`
    width:${props=>props.complete}%;
    height:7px;
    border-radius:9px;
    background: #F33DAA;
`
export const CompletedText = styled.div`
font-weight:700;
font-size:12px;
color:${colors.darkGray1};
`;

export const StepsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding:18px 8px;
    gap:8px;
`;

export const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    // border: 1px solid gray;
    border-radius:8px;
    background-color:${props=>props.isActive ? 'rgba(41, 68, 96, 0.05)' : 'none'};
    :hover{
        background-color:rgba(41, 68, 96, 0.05);
    }
    padding:8px;
    cursor:${props=>props.isActive ? 'default' : 'pointer' };
`;

export const StepHeader = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
`;

export const StepMark = styled.div`
    ${flexCenter}
    width: 16px;
    min-width: 16px;
    height: 16px;
    background: ${props=> props.isDone ? colors.darkGray1  : '#FFFFFF'} ;
    border: ${props=>props.isActive || props.isDone ? `1.5px solid ${colors.darkGray1}` : '1px solid #C0D4FC'}; ;
    border-radius: 25px;
    font-weight: ${props=>props.isActive ? 700 : 450};
    font-size: 12px;
    line-height: 16px;
    color: ${props=>props.isActive ? colors.darkGray1 : '#87A1D5'} ;
    margin-right:8px;
    margin-top:1px;
`



export const StepTitle = styled.div`
    flex:1;
    font-weight: 450;
    font-size: 14px;
    line-height: 17px;
    color:${colors.darkGray1};
    ${props => props.withArrow ? `
        display: flex;
        align-items: center;
        gap:8px;
    `: ``}
    
`;

export const ActionArrowButtonWrapper = styled.div`
    display: flex;
    margin-left: 8px;
`;

export const StepSubTitle = styled.span`
    display:block;
    color:#ACB1BB;
`;

export const StepActionsWrapper = styled.div`
    width:20px;
    ${flexCenter}
`;

export const HoverArrowIcon = styled.div`
    display:none;
    ${StepWrapper}:hover &{
        display:flex;
    }
    margin-top:2px;
`;

export const StepMenuButton = styled.div`
    cursor:pointer;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    cursor:pointer;
`;

export const StepContent = styled.div`
    padding: 12px 20px 8px;
    
`;

export const StepActionButton = styled.div`
    ${flexCenter}
    ${props=>props.fullWidth ? `width:100%;justify-content:flex-start;` : ''}
    display:inline-flex;
    padding: 0px 18px 0px 16px;
    gap: 6px;
    height: 36px;
    background: ${colors.lightGray1};
    border: 1px solid ${colors.gray3};
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color:${colors.darkGray1};
    cursor:pointer;
    :not(:last-of-type){
        margin-bottom:8px;
    }
`;

export const StepActionButtonLink = styled.a`
${flexCenter}
${props=>props.fullWidth ? `width:100%;justify-content:flex-start;` : ''}
display:inline-flex;
padding: 0px 18px 0px 16px;
gap: 6px;
height: 36px;
background: ${colors.lightGray1};
border: 1px solid ${colors.gray3};
box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
border-radius: 25px;
font-weight: 450;
font-size: 14px;
line-height: 18px;
color:${colors.darkGray1};
cursor:pointer;
:not(:last-of-type){
    margin-bottom:8px;
}
:hover{
    color: #1A1B1D;
}
`;

export const StepDescription = styled.div`
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
    color:${colors.darkGray3};
    margin-bottom:12px;
`;

export const StepSingleButton = styled.div`
${flexCenter}
display:inline-flex;
padding: 0px 18px 0px 16px;
gap: 6px;
height: 36px;
background: ${colors.darkGray1};
border-radius: 25px;
font-weight: 450;
font-size: 14px;
line-height: 18px;
color:#fff;
cursor:pointer;
:not(:last-of-type){
    margin-bottom:8px;
}
`;

export const PaneClosedButton = styled.div`
    position: fixed;
    right: ${props => props.show ? 0 : -150}px;
    top: 124px;
    width: 150px;
    height: 46px;
    background-color:#fff;
    border-radius: 28px 0 0 28px;
    z-index:999;
    transition: 0.2s;
    box-shadow: ${shadows.menuPopup};
    cursor:pointer;
    display:flex;
    flex-direction: column;
    padding:8px 16px;
`

export const PaneClosedButtonTitle = styled.div`
    font-weight:450;
    font-size:13px;
    color:${colors.darkGray1};
`;

export const StepMenuWrapper = styled.div`
    ${flexCenter}
    font-weight: 450;
    font-size: 13px;
    line-height: 17px;
`;

export const StepMenuCheckboxWrapper = styled.div`
    //margin-left:16px;
    .ant-checkbox-wrapper{
        width:16px;
        height:16px;
        border-radius:4px;
        flex-direction:row-reverse;
        width: 100%;
        /* transform:scale(1.5) */
    }
    .ant-checkbox .ant-checkbox-inner{
        border-color:${colors.darkGray1};
        /* width: 26px;
        height: 26px; */
    }  
    .ant-checkbox + span {
        padding-left:0px;
        padding-right:24px;
    }
    .ant-checkbox-checked .ant-checkbox-inner{
        background-color: ${colors.darkGray1};
    }  
`;

export const IntroModalHeader = styled.div`
    ${flexCenter};
    justify-content: space-between;
    padding:16px 16px 13px 24px;
    box-shadow: inset 0px -1px 0px #DDE3EB;
    h2{
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        color: ${colors.darkGray1};
    }
    .content{

    }
`

export const IntroModalContent = styled.div`
    padding:24px;
    iframe{
        border: none;
        border-radius: 8px;
    }
`;

export const IntroModalCloseBtnWrapper = styled.div`
    cursor: pointer;
    height: 16px;
    width: 16px;

    svg{
        path{
            height: 12px;
            width: 12px;
        }
    }
`;

