import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { isNullOrUndefinedOrEmptyString } from "../../../../../../../common/utils/object.utils";
import { isRequired, isRequiredAndNotZero } from "../../../../../../add-item/data/input-validations";
import { createLabelFnWithPredictedValue } from "../../Events/PredictedAmountDisplay/PredictedAmountDisplay";
import { AddValueUpdatePrevButton } from "../CreateEvent.styles";
import { eventTypeGenerator } from "./createEventUtils";
import { PlusOutlined } from '@ant-design/icons';

// const contributionOnly = eventTypeGenerator(true);
const valueUpdateOnly = eventTypeGenerator(false, false, true);
const contributionAndValueUpdate = eventTypeGenerator(true, false, true);
const distributionAndValueUpdate = eventTypeGenerator(false, true, true);
// const distributionOnly = eventTypeGenerator(false, true);
const itemCreatedValueUpdate = eventTypeGenerator(true, false, true);

export const sharesEventTypeGenerator = (customRows = []) => {
  return ({updateValue}) => ([
    {
      title: '',
      rows: [
        ...customRows.map(cr=> typeof cr === 'function' ? cr({updateValue}) : cr),
        {fields:[],type:'separator'},
        {fields: [{key:'eventRemarks', type: 'textarea', label: 'Remarks' , rows:2 , placeholder:'Add notes to remember'}]}
      ]
    }
  ])
}

const valueUpdateFn = (isPredicted) => ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated asset value',
        validations: [],
        showPrevComp: true,
        ClickToShowComponent: ({ show }) => (<AddValueUpdatePrevButton onClick={show}><PlusOutlined /> Add Change in value</AddValueUpdatePrevButton>),
        defaultValueFn:(values) => (+values.valueAtDate + values.transactionValue),
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            holdings: values.holdings,
            decimalScale: 2,
            showPrevComp: values.eventId || !isPredicted ? true : false
          }
        }
      }
    ]
  }
}

const privateBusinessTraditionalDistributionSections = () => ({updateValue, updateField}) => {
  return [
    {
      title: '',
      rows: [
        {fields:[
          {   
              key:'transactionValue', type: 'numberWithHoldings', label: 'Distribution value', 
              validations:[isRequiredAndNotZero],
              defaultValue:null,
              generatePropsFn : (values)=>{
                  const labelFn = createLabelFnWithPredictedValue('Distribution value',values.eventPredictedAmount,`The expected distribution amount`)
                  return {
                      label: labelFn,
                      holdings: values.holdings,
                      prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      decimalScale : 2,
                      disabled: (values.onCapital || values.ofCapital),
                      isHidden: values.isTransactionLinked
                  }
              },
              onChange: ({value}) => {
                updateField('isDistributionOfCapital', {disabled: isNullOrUndefinedOrEmptyString(value)});
              },
          },
        ]},
        {fields:[
          {   
              key:'contributionTransactionValue', type: 'numberWithHoldings', label:  'Contribution value', 
              validations:[],
              defaultValueFn: (values) => values.eventId ? ( values.allTransactions?.length ? Math.abs(values.allTransactions[1].value) : null ) : values.transactionValue,
              generatePropsFn : (values)=>{
                  return {
                      isHidden: !values.allTransactions?.length,
                      holdings: values.holdings,
                      prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      decimalScale : 2, 
                  }
              },
            }
        ]},

      {
        fields:[
          {
            key: 'isTransactionLinked' , isHidden:true,
            onChange: ({value,formValues})=>{
              updateField('transactionValue', {isHidden: value})
              updateField('isDistributionOfCapital', {fieldCustomStyle: {marginTop: value ? '' : '-24px'},})
              if (formValues.isDistributionOfCapital){
                updateField('transactionValue',{disabled:true })
                if (value) {
                  updateValue('ofCapital', formValues.transactionValue)
                  updateValue('onCapital', null)
                  updateValue('updatedValue', +formValues.valueAtDate - formValues.transactionValue)
                }
              }
            },
            validateFieldsOnChange: ['validationOfOnCapital'],
          }
        ]
      },

        {fields: [
            {
              key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated asset value',
              showPrevComp: false,
              // defaultValueFn: (values) => 10,
              generatePropsFn: (values) => {
                return {
                  prefix: CURRENCY_SYMBOL[values.currency] || '',
                  placeholder: CURRENCY_SYMBOL[values.currency] || '',
                  holdings: values.holdings,
                  decimalScale: 2,
                }
              },
            }
          ]
        },
        // ...customRows.map(cr => typeof cr === 'function' ? cr({ updateValue }) : cr),
        {fields:[],type:'separator'},
        {fields: [{key:'eventRemarks', type: 'textarea', label: 'Remarks' , rows:2 , placeholder:'Add notes to remember'}]},
      ]
    }
]};

const exitEventSections = (fieldRows = []) => ({updateValue, updateField}) => {
  return [
    {
      title: '',
      rows: [
        {fields:[
          {   
              key:'transactionValue', type: 'numberWithHoldings', label: 'Total distribution amount', 
              validations:[isRequired],
              defaultValue:null,
              generatePropsFn : (values)=>{
                const labelFn = createLabelFnWithPredictedValue('Total distribution amount',values.eventPredictedAmount,`The expected distribution amount`)
                  return {
                      label:labelFn,
                      holdings: values.holdings,
                      prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      decimalScale : 2,
                      isHidden: values.isTransactionLinked,
                  }
              },
              onChange: ({value, formValues}) => {
                const newUpdatedValue = +formValues.valueAtDate - value > 0 ? +formValues.valueAtDate - value : 0;
                updateValue('updatedValue', newUpdatedValue);

              },
          },    
        ]},

        ...fieldRows.map(fr=> typeof fr === 'function' ? fr({updateValue}) : fr),
        {fields:[],type:'separator'},
        {fields: [{key:'eventRemarks', type: 'textarea', label: 'Remarks' , rows:2 , placeholder:'Add notes to remember'}]},
        
      ]
    }
]};

export const privateBusinessTraditionalEvents = [
  {title: 'More cash invested' , sections: contributionAndValueUpdate},
  {title:'Contribution', isHidden: true, description:'Cash you added to this investment',sections:contributionAndValueUpdate },
  {title:'Distribution', isHidden: true, description:'Cash you received from this investment', sections: distributionAndValueUpdate , isDistribution: true},
  {title: 'Cash distributed' , description:'Cash you received from this investment', sections: distributionAndValueUpdate, isDistribution: true },
  {title:'Annual dividend', eventType: 'Annual dividend' , isPredicted: true , sections: privateBusinessTraditionalDistributionSections(), isDistribution: true },
  {title:'Value update', description:'Will be reflected in your net worth' , sections:valueUpdateOnly},
  {title: 'Exit event', sections: exitEventSections([valueUpdateFn(false)]), isDistribution: true  , assetInfoLabelId: 'expected-exit-event'},
  {title: 'Item created' , isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false},
]

