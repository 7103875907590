import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrZeroOrEmptyString } from "../../../../../../../common/utils/object.utils";
import { isRequired, isRequiredAndNotZero } from "../../../../../../add-item/data/input-validations";
import { eventTypeGenerator } from "./createEventUtils";
import { floatFixed } from "../../../../../../../common/utils/number-display.utils";
import { createLabelFnWithPredictedValue } from "../../Events/PredictedAmountDisplay/PredictedAmountDisplay";

// const contributionOnly = eventTypeGenerator(true);
const valueUpdateOnly = eventTypeGenerator(false, false, true);
const contributionAndValueUpdate = eventTypeGenerator(true, false, true);
const distributionAndValueUpdate = eventTypeGenerator(false, true, true);
const distributionOnly = eventTypeGenerator(false, true);
const itemCreatedValueUpdate = eventTypeGenerator(true, false, true);


export const sharesEventTypeGenerator = (customRows = []) => {
  return ({updateValue}) => ([
    {
      title: '',
      rows: [
        ...customRows.map(cr=> typeof cr === 'function' ? cr({updateValue}) : cr),
        {fields:[],type:'separator'},
        {fields: [{key:'eventRemarks', type: 'textarea', label: 'Remarks' , rows:2 , placeholder:'Add notes to remember'}]}
      ]
    }
  ])
}


const pricePerShareAttr = { fieldKey: 'pricePerShare', dataKey: 'pricePerShare' };
const quantityAttr = { fieldKey: 'newQuantity', dataKey: 'quantity', setFnForSave: (isDistribution, value) => value * (isDistribution ? -1 : 1), getEditValueFn: ({ value }) => Math.abs(value) };
const numberOfSharesAttr = { fieldKey: 'newNumberOfShares', dataKey: 'numberOfShares', setFnForSave: (isDistribution, value) => value * (isDistribution ? -1 : 1), getEditValueFn: ({ value }) => Math.abs(value) };


const transactionValueFieldFn = (isContribution) => ({updateValue}) => {
  return {fields:[
    {   
        key:'transactionValue', type: 'numberWithHoldings', label: isContribution ? 'Contribution value' : 'Distribution value', 
        validations:[isRequiredAndNotZero],
        defaultValue:null,
        // disabled:true,
        generatePropsFn : (values)=>{
            return {
                holdings: values.holdings,
                prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                decimalScale : 2, 
            }
        },
        onBlur:({value,formValues})=>{
          if (!isNullOrUndefinedOrEmptyString(value) && formValues.newNumberOfShares){
            // debugger;
            // if(formValues.hasOwnProperty('updatedValue')&& isNullOrUndefinedOrEmptyString(formValues.updatedValue)){
            //   const newVal = ( +formValues.valueAtDate || +formValues.value ) + ( value * (isContribution ? 1 : -1));
            //   updateValue('updatedValue', newVal > 0 ? newVal : 0 );
            // }
            // if(formValues.hasOwnProperty('updatedValue')&& isNullOrUndefinedOrEmptyString(formValues.updatedValue)){
            //     const newVal = ( +formValues.valueAtDate || +formValues.value ) + ( value * (isContribution ? 1 : -1));
            //     updateValue('updatedValue', newVal > 0 ? newVal : 0 );
            // }
            if (!isNullOrUndefinedOrEmptyString(formValues.newNumberOfShares)) {
              updateValue('pricePerShare', floatFixed(value / formValues.newNumberOfShares , 2));
            }
          }
        }
    }   
]}
}

const pricePerShareFieldFn = (updateValueBaseOnTotal) => ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'pricePerShare', type: 'number', label: 'Price per share', validations: [isRequired],
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
          }
        },
        onBlur: ({ value, formValues }) => {
          if (updateValueBaseOnTotal && !isNullOrUndefinedOrZeroOrEmptyString(value) && formValues.totalNumberOfShares) {
            updateValue('updatedValue', floatFixed(value * formValues.totalNumberOfShares,2));
          }
          if(formValues.hasOwnProperty('newNumberOfShares') && !isNullOrUndefinedOrEmptyString(formValues.newNumberOfShares) && !formValues.doNotUpdateTransactionValue){
            const trnVal =  ( value * formValues.newNumberOfShares);
            updateValue('transactionValue', trnVal > 0 ? trnVal : 0 );
          }
        }
          // if (!isNullOrUndefinedOrZeroOrEmptyString(formValues.pricePerShare) && formValues.newNumberOfShares && !formValues.updatedValue) {
          //   updateValue('updatedValue', parseFloat(value * formValues.newNumberOfShares).toFixed(2));
          // }
          // else if (!isNullOrUndefinedOrZeroOrEmptyString(formValues.pricePerShare) && !formValues.newNumberOfShares && formValues.updatedValue) {
          //   updateValue('newNumberOfShares', parseFloat(value * formValues.updatedValue).toFixed(2));
          // }
      }
    ]
  };
}

const numberOfSharesFieldFn = ( isDistribution,label) => ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'newNumberOfShares', type: 'numberWithHoldings', label,
        generatePropsFn: (values) => {
          return {
            absSuffix: `${values.stockDetails.symbol} ${values.holdings === 100 ? `, ${values.stockDetails.type}` : ''}`,
            holdings: values.holdings,
          }
        },
        // suffix: 'SPOT, Stock',
        validations: [isRequired],
        onBlur: ({ value, formValues }) => {
          if (!isNullOrUndefinedOrEmptyString(value) && !isNullOrUndefinedOrEmptyString(formValues.pricePerShare)){
            //if(formValues.hasOwnProperty('transactionValue') && isNullOrUndefinedOrEmptyString(formValues.transactionValue)){
            if(formValues.hasOwnProperty('transactionValue') && !formValues.doNotUpdateTransactionValue ){
              const trnVal =  ( value * formValues.pricePerShare);
              updateValue('transactionValue', trnVal > 0 ? trnVal : 0 );
            }
            if(formValues.hasOwnProperty('valueAtDate') ){ // && isNullOrUndefinedOrEmptyString(formValues.updatedValue)){
              const newVal = ( +formValues.valueAtDate + ( value * (isDistribution ? -1 : 1) ))
              updateValue('updatedValue', newVal > 0 ? newVal : 0 );
            }
          }
        },
        setFnForSave: (isDistribution, value) => { 
           return value * (isDistribution ? -1 : 1)}
      }
    ]
  }
}

// const purchasedSharesFnRow = (label,isContribution) => (props) => {
//   return {
//     fields: [
//       ...numberOfSharesFieldFn({...props,label,isDistribution : !isContribution}).fields,
//       ...sharesTypeField.fields
//     ]
//   }
// }



const visibleValueUpdateFn = ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated number of shares',
        // validations: [isRequired],
        showPrevComp: false,
        // showPrevComp: withContribution || withDistribution,
        // ClickToShowComponent: ({ show }) => (<AddValueUpdatePrevButton onClick={show}><PlusOutlined /> Add Change in value</AddValueUpdatePrevButton>),
        // defaultValueFn: (values)=>{
        //     return values.value ? values.value : ''
        // },
        disabled: true,
        defaultValue: null,
        generatePropsFn: (values) => {
          return {
            // prefix: CURRENCY_SYMBOL[values.currency] || '',
            // placeholder: CURRENCY_SYMBOL[values.currency] || '',
            holdings: values.holdings,
            decimalScale: 2,
          }
        },
        
        onBlur: ({ value, formValues }) => {
          // if (!isNullOrUndefinedOrZeroOrEmptyString(formValues.updatedValue) && formValues.pricePerShare && !formValues.newNumberOfShares) {
          //   updateValue('newNumberOfShares', parseFloat((value / formValues.pricePerShare).toFixed(2)));
          // }
          // else if (!isNullOrUndefinedOrZeroOrEmptyString(formValues.updatedValue) && !formValues.pricePerShare && formValues.newNumberOfShares) {
          //   updateValue('pricePerShare', parseFloat((value / formValues.newNumberOfShares).toFixed(2)));
          // }
        }
      }
    ]
  }
}


const grantHiddenValuesSectionFn = ({ updateValue }) => {
  return {
  fields: [{
      key:'doNotUpdateTransactionValue', 
      isHidden:true,
      defaultValue:true
            // defaultValue:1,
  }]
  }
}
// const valueUpdateFieldWithDefault = {
//   fields: [
//     {
//       key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated asset value',
//       validations: [],
//       showPrevComp: false,
//       defaultValueFn: (values) => values.value,
//       generatePropsFn: (values) => {
//         return {
//           prefix: CURRENCY_SYMBOL[values.currency] || '',
//           placeholder: CURRENCY_SYMBOL[values.currency] || '',
//           holdings: values.holdings,
//           decimalScale: 2,
//         }
//       },
//     }
//   ]
// }

const pricePerShareValueForEdit = {dataKey: 'pricePerShare' , itemDataKey:'stockLastPrice' , getEditValueFn: ({ value }) => parseFloat(value).toFixed(2) };
const stockDetails = {dataKey:'stockDetails', itemDataKey:'stock'}

export const singleTickerEvents = [
  { title: 'Buy (Purchase of shares)', 
    eventType: 'Purchase of shares', 
    sections: sharesEventTypeGenerator([pricePerShareFieldFn(false), numberOfSharesFieldFn(false,'Number of purchased shares'), transactionValueFieldFn(true), visibleValueUpdateFn]),
     attributesToSend: [pricePerShareAttr, numberOfSharesAttr], 
     valuesForEditFromItem: [stockDetails],
     valuesForCreateEventFromItem:[pricePerShareValueForEdit]
  },
  { title: 'Sale of shares', sections: sharesEventTypeGenerator( [pricePerShareFieldFn(false), numberOfSharesFieldFn(true,'Number of shares sold'), transactionValueFieldFn(false), visibleValueUpdateFn]), isDistribution: true, 
    attributesToSend: [pricePerShareAttr, numberOfSharesAttr],
    valuesForEditFromItem: [stockDetails],
    valuesForCreateEventFromItem:[pricePerShareValueForEdit]
  },
  { title: 'Grant', description: 'Grant', sections: sharesEventTypeGenerator( [pricePerShareFieldFn(false), numberOfSharesFieldFn(false,'Number of granted shares'),visibleValueUpdateFn,grantHiddenValuesSectionFn]),  
    attributesToSend: [numberOfSharesAttr, pricePerShareAttr], 
    valuesForEditFromItem: [stockDetails],
    valuesForCreateEventFromItem:[pricePerShareValueForEdit]
  },
  { title: 'Dividend', description: 'Cash Received', sections: distributionOnly, isDistribution: true },
  // { title: 'Annual dividend', isPredicted: true, sections: distributionAndValueUpdate, isDistribution: true },
  // { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: sharesEventTypeGenerator( [pricePerShareFieldFn(false), 
    // quantityFieldFn(false,'Number of purchased shares') , 
    numberOfSharesFieldFn(false , 'Number of purchased shares' ) ,
    transactionValueFieldFn(true) , visibleValueUpdateFn]), attributesToSend: [pricePerShareAttr, numberOfSharesAttr, quantityAttr], 
    valuesForEditFromItem: [stockDetails], deleteEnable: false},
  // { title: 'Item created', isHidden: true, sections: itemCreatedValueUpdate },
]


const publicMarketDistributionSections = (isManualReInvest) => ({updateValue, updateField}) => {
  return [
    {
      title: '',
      rows: [
        {fields:[
          {   
              key:'transactionValue', type: 'numberWithHoldings', label: 'Distribution value', 
              validations:[isRequiredAndNotZero],
              defaultValue:null,
              generatePropsFn : (values)=>{
                  const labelFn = createLabelFnWithPredictedValue('Distribution value',values.eventPredictedAmount,`The expected distribution amount`)
                  return {
                      label: labelFn,
                      holdings: values.holdings,
                      prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      decimalScale : 2,
                      disabled: (values.onCapital || values.ofCapital),
                      isHidden: values.isTransactionLinked
                  }
              },
              onChange: ({value}) => {
                updateField('isDistributionOfCapital', {disabled: isNullOrUndefinedOrEmptyString(value)});
              },
              onBlur:({value,formValues})=>{
                if (value && (isManualReInvest || formValues.isReinvestedEvent)){
                  // console.log("Yo yo")
                  updateValue('contributionTransactionValue', value );
                  const newVal = ( +formValues.valueAtDate || +formValues.value ) +  value ;
                  updateValue('updatedValue', newVal > 0 ? newVal : 0 );
                }
              }
          },
          ...( isManualReInvest ?  [{
            key:'isDistributionReinvested', 
            isHidden:true,
            // defaultValue:1,
            defaultValueFn:(values)=>1
          }] : [  
          ] )  
        ]},
        {fields:[
          {   
              key:'contributionTransactionValue', type: 'numberWithHoldings', label:  'Contribution value (reinvested)', 
              validations:[],
              defaultValueFn: (values) => values.eventId ? ( values.allTransactions?.length ? Math.abs(values.allTransactions[1].value) : null ) : values.transactionValue,
              generatePropsFn : (values)=>{
                  return {
                      isHidden:  !(isManualReInvest || (values.isReinvestedEvent && !values.eventId) || values.allTransactions?.length),
                      holdings: values.holdings,
                      prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      decimalScale : 2, 
                  }
              },
            }
        ]},

      {
        fields:[

          
          {key: 'isTransactionLinked' , isHidden:true,
            onChange: ({value,formValues})=>{
              updateField('transactionValue', {isHidden: value})
              updateField('isDistributionOfCapital', {fieldCustomStyle: {marginTop: value ? '' : '-24px'},})
              if (formValues.isDistributionOfCapital){
                updateField('transactionValue',{disabled:true })
                if (value) {
                  updateValue('ofCapital', formValues.transactionValue)
                  updateValue('onCapital', null)
                  updateValue('updatedValue', +formValues.valueAtDate - formValues.transactionValue)
                }
              }
            },
            validateFieldsOnChange: ['validationOfOnCapital'],
          }
        ]
      },

        {fields: [
            {
              key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated asset value',
              showPrevComp: false,
              defaultValueFn: (values) => { return (isManualReInvest || (values.distributionReinvested && !values.eventId)) ? ( values.eventId ? values.updatedValue : +values.valueAtDate + values.transactionValue) : null},
              // defaultValueFn: (values) => 10,
              generatePropsFn: (values) => {
                return {
                  isHidden: !(isManualReInvest || values.isReinvestedEvent || values.ofCapital),
                  prefix: CURRENCY_SYMBOL[values.currency] || '',
                  placeholder: CURRENCY_SYMBOL[values.currency] || '',
                  holdings: values.holdings,
                  decimalScale: 2,
                }
              },
            }
          ]
        },
        // ...customRows.map(cr => typeof cr === 'function' ? cr({ updateValue }) : cr),
        {fields:[],type:'separator'},
        {fields: [{key:'eventRemarks', type: 'textarea', label: 'Remarks' , rows:2 , placeholder:'Add notes to remember'}]},
      ]
    }
]};

const saveDistributionWithReinvestedTrns = (eventDate, formValues, eventTransactions) => {
  return (formValues.distributionReinvested || formValues.isDistributionReinvested) && formValues.contributionTransactionValue ? [
    {
      date: eventDate,
      value:  Math.abs(formValues.contributionTransactionValue) * -1,
      ...(eventTransactions && eventTransactions.length > 1 ? {
        id: eventTransactions[1].id
      } : {})
    }
  ] : [];
}

export const publicMarketEvents = [
  {title: 'More cash invested' , sections: contributionAndValueUpdate},
  {title:'Contribution', isHidden: true, description:'Cash you added to this investment',sections:contributionAndValueUpdate },
  {title:'Distribution', isHidden: true, description:'Cash you received from this investment', sections: distributionAndValueUpdate , isDistribution: true},
  {title: 'Cash distributed' , description:'Cash you received from this investment', sections: distributionAndValueUpdate, isDistribution: true },
  {title:'Distribution reinvested', sections: publicMarketDistributionSections(true), valuesForEditFromItem: [{dataKey:'distributionReinvested', itemDataKey:'distributionReinvested'}], isDistribution: true , otherTrnsFn: saveDistributionWithReinvestedTrns},
  {title:'Annual dividend', eventType: 'Annual dividend' , isPredicted: true , sections: publicMarketDistributionSections(false), valuesForEditFromItem: [{dataKey:'distributionReinvested', itemDataKey:'distributionReinvested'}], isDistribution: true , otherTrnsFn: saveDistributionWithReinvestedTrns },
  {title:'Value update', description:'Will be reflected in your net worth' , sections:valueUpdateOnly},
  {title: 'Item created' , isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false},
]

