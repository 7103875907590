import styled from "@emotion/styled/macro";
import { colors, views } from "../../../common/styles/theme.styles";

export const ConciergeRightPaneWrapper = styled.div`
  display: none;
  flex: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  gap: 24px;

  ${views.desktop} {
    display: flex;
  }

  
`;

export const ConciergeRightPaneTitle = styled.div`
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: ${colors.lightGray2};
  padding: 0 16px;
  max-width: 400px;
`;