import React from 'react'
import { LearnButtonWrapper, PlanFutureCashFlowHeaderSubtitle, PlanFutureCashFlowHeaderTextWrapper, PlanFutureCashFlowHeaderTitle, PlanFutureCashFlowHeaderWrapper } from './PlanFutureCashFlowHeader.styles'
import { startIntercomTourById } from '../../../../common/utils/intercom.utils';

export const PlanFutureCashFlowHeader = () => {
  return (
    <PlanFutureCashFlowHeaderWrapper>
      <PlanFutureCashFlowHeaderTextWrapper>
        <PlanFutureCashFlowHeaderTitle>Smartly plan your future cash flow</PlanFutureCashFlowHeaderTitle>
        <PlanFutureCashFlowHeaderSubtitle>Displays based on your assets & liabilities expected events</PlanFutureCashFlowHeaderSubtitle>
      </PlanFutureCashFlowHeaderTextWrapper>
      <LearnButtonWrapper onClick={() => { startIntercomTourById('398462'); }}><LearnSvg /> Learn</LearnButtonWrapper>
    </PlanFutureCashFlowHeaderWrapper>
  )
}

const LearnSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1268 15.4986C10.0847 15.4995 10.0424 15.5 10 15.5C6.96243 15.5 4.5 13.0376 4.5 10C4.5 6.96243 6.96243 4.5 10 4.5C13.0376 4.5 15.5 6.96243 15.5 10C15.5 10.1606 15.4931 10.3195 15.4796 10.4766L16.9192 11.0669C16.9724 10.719 17 10.3627 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C10.2252 17 10.4479 16.9894 10.6676 16.9686L10.1268 15.4986Z" fill="#DDE3EB" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.3697 9.20134C12.0365 8.21228 11.1014 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.0004 8.08759 11.8635 8.93648 12.2632L9.56695 13.9768C7.56149 13.7609 6 12.0628 6 10C6 7.79086 7.79086 6 10 6C12.1653 6 13.9288 7.72049 13.9979 9.86899L12.3697 9.20134Z" fill="#DDE3EB" />
      <path d="M10 10L19 13.6906L15.305 15.6136L13.5871 19.75L10 10Z" fill="#DDE3EB" />
    </svg>
  )
}