import styled from '@emotion/styled/macro';
import { colors } from '../../../../common/styles/theme.styles';

export const ModalBgWrapper = styled.div`
     border-radius:8px;
     position: relative;
     display:flex;
     flex-direction:column;
     align-items:center;
`;

export const ModalCloseButtonWrapper = styled.div`
    position:absolute;
    right:16px;
    top:16px;
    width:24px;
    height:24px;
    cursor: pointer;
    path{
      stroke:${colors.darkGray3};
    }
`;


export const ModalContent = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-top:32px;
`;


export const TitleText = styled.div`
  color:${colors.darkGray1};
  font-size:22px;
  line-height:28px;
  font-weight:600;
  text-align: center;
  margin-bottom: 16px;
`;


export const BottomMessage = styled.div`
  font-size:14px;
  color:${colors.darkGray2};
  margin-bottom:20px;
  text-align:center;
`;

export const LineSeparator = styled.div`
  width:100%;
  height:1px;
  background-color:#EBE6E8;
  margin:24px 0;
`;