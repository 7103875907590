import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { ActionLocked } from '../../../../../../common/components/ActionLocked/ActionLocked';
import { LogoLoader } from '../../../../../../common/components/LogoLoader/LogoLoader';
import { useStore } from '../../../../../app/data/root.store';
import { ChooseFilesButton, DocumentsCountRow, DocumentsTableCell, DocumentsTableCellText, DragAreaDescription, DragDropPromptWrapper, DraggAreaWrapper, DrawerHeader, FloatingLabeInInput, InputWithLabelOnTopWrapper, LoadingLogoWrapper, MobileAssetDocumentsTabWrapper } from './MobileAssetDocumentsTab.styles';
import { MobileDocumentRow } from './MobileDocumentRow';
import { DatePicker, Drawer } from 'antd';
import { SimpleSelect } from '../../../../../../common/components/DropDownMenu/SimpleSelect';
import { colors, shadows } from '../../../../../../common/styles/theme.styles';
import moment from 'moment';
import { ModifiedTextArea } from '../../../../../../common/components/ModifiedTextArea/ModifiedTextArea';

export const MobileAssetDocumentsTab = observer(() => {
  const { documentsStore, userStore, billingStore, wealthStore , uiStore } = useStore();
  let history = useHistory();
  const [editedDocumentIndex, setEditedDocumentIndex] = useState(null);
  
  useEffect(() => {
    return () => {
      wealthStore.refreshWealthData(false, history);
    }
    // eslint-disable-next-line
  }, [])

  const addDocuments = useCallback((acceptedFiles) => {
    documentsStore.uploadDocuments(acceptedFiles)
  }, [documentsStore]);

  const onDrop = useCallback(acceptedFiles => {
    if (documentsStore.isLoading) return;
    // Do something with the files
    if (acceptedFiles.length > 0) {
      if (userStore.isReadOnlyMode) {
        return billingStore.setShowPricingTableModal(true);
      }
      addDocuments(acceptedFiles);
    }
  }, [addDocuments, userStore.isReadOnlyMode, billingStore, documentsStore.isLoading])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxSize: 26214400 })

  const onDocumentDelete = (documentId) => {
    documentsStore.deleteDocument(documentId);
  }
  const onDocumentUpdate = (document) => {
    documentsStore.updateDocument(document);
  }

  return (
    <MobileAssetDocumentsTabWrapper>

      <ActionLocked action={'itemUploadDocument'}>
        <DraggAreaWrapper {...getRootProps()} >
          <input {...getInputProps()} />
          {documentsStore.isLoading ? <LoadingLogoWrapper><LogoLoader wbg style={{ marginTop: '-16px' }} /></LoadingLogoWrapper> :
            <DragDropPromptWrapper>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingInline: '16px' }}>
                <UploadSvg />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
                  <DragAreaDescription>Upload documents related to this asset</DragAreaDescription>
                </div>
              </div>
              <ChooseFilesButton>Upload</ChooseFilesButton>
            </DragDropPromptWrapper>
          }
        </DraggAreaWrapper>
      </ActionLocked>

      <div>
        <DocumentsCountRow>Documents {documentsStore.documents.length ? `(${documentsStore.documents.length})` : ''}</DocumentsCountRow>
        {documentsStore.documents.map((document, index) => (
          <MobileDocumentRow document={document} key={document.id}
            uiStore={uiStore}
            rowUpdated={onDocumentUpdate}
            rowDeleted={onDocumentDelete}
            documentIndex={index}
            editedIndex={editedDocumentIndex}
            onEditedIndexChange={(ind) => { setEditedDocumentIndex(ind) }}
          />
        ))}
      </div>


      <Drawer
        closable={false}
        visible={editedDocumentIndex !== null}
        width={340}
        height={'auto'}
        className={'asset_actions_DrawerContainer_mobile'}
        placement='bottom'
        destroyOnClose={true}
        onClose={() => { setEditedDocumentIndex(null) }} >
        {DocumentEditDrawerContent({
          currentDocument: documentsStore.documents?.[editedDocumentIndex],
          onDocumentDelete,
          rowUpdated: onDocumentUpdate,
          onClose: () => { setEditedDocumentIndex(null) }
        })}
      </Drawer>
    </MobileAssetDocumentsTabWrapper>
  )
})

const UploadSvg = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.56462 23.2647L7.4522 23.2595C4.97424 23.1431 3 21.0958 3 18.5882C3 16.0055 5.09373 13.9118 7.67647 13.9118C7.75669 13.9118 7.83633 13.9138 7.91536 13.9177L9.59922 14.002L9.48701 12.3198C9.47608 12.156 9.47052 11.9905 9.47052 11.8235C9.47052 7.77886 12.7494 4.5 16.7941 4.5C20.5314 4.5 23.6168 7.30078 24.0622 10.9172L24.2004 12.039L25.3169 12.2154C27.9703 12.6346 29.9995 14.9346 29.9995 17.7058C29.9995 20.7759 27.5107 23.2646 24.4407 23.2646H22.8337L22.8337 23.2647H20.3236V24.7647H22.9371H24.4391H24.4407L24.4407 24.7646H24.4423C24.7742 24.7646 25.1008 24.7416 25.4206 24.6972L25.4222 24.6969C28.8561 24.2192 31.4995 21.2712 31.4995 17.7058C31.4995 14.1851 28.9219 11.2664 25.551 10.7338C25.0141 6.37485 21.2982 3 16.7941 3C12.2263 3 8.46917 6.47091 8.0163 10.9192C7.98603 11.2165 7.97052 11.5182 7.97052 11.8235C7.97052 12.0239 7.9772 12.2226 7.99034 12.4196C7.88637 12.4144 7.78173 12.4118 7.67647 12.4118C7.28751 12.4118 6.90694 12.4477 6.53792 12.5165C3.67062 13.0508 1.5 15.566 1.5 18.5882C1.5 21.9006 4.10748 24.604 7.38184 24.7578V24.7647H8.88184H13.2647V23.2647H7.56462Z" fill="#1A1B1D" />
      <path d="M17 17V28M17 17L13.5 20M17 17L20.5 20" stroke="#1A1B1D" strokeWidth="1.5" />
    </svg>
  )
}

const DocumentEditDrawerContent = ({ currentDocument, rowUpdated, onClose }) => {
  // const defaultValues = currentDocument;

  const [updatedDocument, setUpdatedDocument] = useState(currentDocument)

  useEffect(() => {
    setUpdatedDocument(currentDocument);
    // return () => {}
  }, [currentDocument])


  const handleFieldChange = (field, value) => {
    setUpdatedDocument({
      ...updatedDocument,
      [field]: value
    })
  }

  // const cancelChanges = (e) => {
  //   e.stopPropagation();
  //   setUpdatedDocument(defaultValues);
  //   onClose?.();
  // }

  const saveChanges = (e) => {
    e.stopPropagation();
    onClose?.();
    rowUpdated?.(updatedDocument);
  }

  return (
    <>
      <DrawerHeader>Edit Document <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '8px' }} onClick={onClose}><CloseSvg /></div></DrawerHeader>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', padding: '16px 20px 32px 20px' }}>
          
        <DocumentsTableCell>
          <InputWithLabelOnTopWrapper>
          <FloatingLabeInInput>Document name</FloatingLabeInInput>
          <DocumentsTableCellText
            disabled
            style={{ boxShadow: shadows.toggleShadow, color: colors.gray2, fontSize:'18px', borderRadius: '6px', paddingTop:'18px', width: '100%', height: '64px', display:'flex', alignItems:'center' }}
          >
            {updatedDocument?.originalName}
          </DocumentsTableCellText>
          </InputWithLabelOnTopWrapper>
        </DocumentsTableCell>

        <DocumentsTableCell>
          <InputWithLabelOnTopWrapper>
          <FloatingLabeInInput>Type</FloatingLabeInInput>
          <SimpleSelect value={updatedDocument?.type}
            onChange={(val) => { handleFieldChange('type', val) }} style={{ width: '100%' }}
            options={[
              { value: 'General', label: 'General' },
              { value: 'K-1', label: 'K-1' },
              { value: 'Tax', label: 'Tax' },
              { value: 'Periodic reports', label: 'Periodic reports' },
            ]}
            className={'document_tab_mobile_select_value'}
            buttonStyle={{ borderRadius: '6px', fontWeight: '450', color: '#3F3F45', boxShadow: 'none', border: `1px solid ${colors.gray3}`, height: '64px', }}
            buttonHoverBorderColor={colors.darkGray1}
            menuItemColor={'#3F3F45'}
          />
          </InputWithLabelOnTopWrapper>
        </DocumentsTableCell>

        <DocumentsTableCell>
          <InputWithLabelOnTopWrapper>
            <FloatingLabeInInput>Report year</FloatingLabeInInput>
          
          <DatePicker
            picker="year"
            inputReadOnly
            dropdownClassName='documentRowDatePicker'
            value={updatedDocument?.year ? moment().year(updatedDocument?.year) : null}
            onChange={(val) => { handleFieldChange('year', (val ? val.year() : updatedDocument?.date)) }}
            style={{ width: '100%', borderRadius: '6px', height: '64px', }}
          />
          </InputWithLabelOnTopWrapper>
        </DocumentsTableCell>

        <DocumentsTableCell>
        <InputWithLabelOnTopWrapper>
          <FloatingLabeInInput>Remarks</FloatingLabeInInput>
          <ModifiedTextArea
            value={updatedDocument?.remarks}
            isEdited
            customStyle={{ height: '91px' , paddingTop:'24px' }}
            onChange={(e) => { handleFieldChange('remarks', e.target.value) }}
          />
          </InputWithLabelOnTopWrapper>
        </DocumentsTableCell>

        <ChooseFilesButton onClick={saveChanges}>Save</ChooseFilesButton>
      </div>
    </>
  )
}

const CloseSvg = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill="#E8E9ED" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.4194 19.7302L8.51991 9.83068L9.58057 8.77002L19.4801 18.6695L18.4194 19.7302Z" fill="#585D66" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.4802 9.8306L9.58068 19.7301L8.52002 18.6694L18.4195 8.76994L19.4802 9.8306Z" fill="#585D66" />
    </svg>
  )
}