import { Observer } from 'mobx-react';
import React , {useState, useEffect} from 'react';
import { useStore } from '../../../app/data/root.store';
import { useLocation , useHistory } from "react-router-dom";
import adamChatImg from '../../assets/adam-chat.png'
import { ArrowDownIcon, BookDemoCallIcon, IntercomIcon } from '../../assets/userActivationIcons';
import { AnimatedText } from '../ChatRow/AnimatedText';
import { ChatRow } from '../ChatRow/ChatRow';
import { useAnimationsStepDelayedHook } from '../useAnimationsStepDelayedHook';
import { ActionButton, ActionButtonsWrapper, AdamTitle, ActivationFlowStepWrapper, HeightAnimationWrapper, StepContent, StepFooter, StepHeader, AdamImage } from '../UserActivationFlowPage.styles';
import { WelcomeModal } from '../../../onboarding/components/WelcomeModal/WelcomeModal';
import { removeUrlParams } from '../../../../common/utils/navigation.utils';
import { useTrackEvent, useTrackGTMEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { CalendlyPopupWidget } from '../../../../common/components/CalendlyPopupWidget/CalendlyPopupWidget';
import { startIntercomNewMessage } from '../../../../common/utils/intercom.utils';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';



export const MeetAdamStep = (props) => {
  const { userStore , uiStore } = useStore();
  const urlParams = new URLSearchParams(useLocation().search);
  const [showWelcomeModal, setShowWelcomeModal] = useState(urlParams.get('subscribed_success') !== null && urlParams.get('sw') !== null);
  
  const history = useHistory();
  useEffect(() => {
    removeUrlParams(history)
  }, [history])

  return (
    <ActivationFlowStepWrapper>

    <StepHeader>
      <img src={adamChatImg} alt={'Adam'} width={150} height={150} />
      {uiStore.isDesktopView && <>
      <AdamTitle>Adam<br /><span style={{fontSize: '15px' , fontWeight: '500'}}>Your personal account manager</span>{userStore.isTier4 ? <><br /><span style={{fontSize: '15px' , fontWeight: '500'}}>+1 (302) 205-9009</span></> : null}</AdamTitle>
      </>}
    </StepHeader>

    { showWelcomeModal ? <WelcomeModal noSidebarMargin onBackDropClick={()=>{setShowWelcomeModal(false)}} /> : <MeetAdamStepAnimationContent {...props} />}

    </ActivationFlowStepWrapper>
  )

}

export const MeetAdamStepAnimationContent = (props) => {
  const animationStep = useAnimationsStepDelayedHook({ animationsStepDelayed: [500, 600, 800, 200, 0] });
  const { userStore, uiStore } = useStore();
  const { trackEvent } = useAnalytics()
  const mixpanelStepsData = {step_name: 'Meet your account manager', step_number: 1};
  const [isCalendlyBooked, setIsCalendlyBooked] = useState(false);
  const [isCalendlyClosed, setIsCalendlyClosed] = useState(false);

  // const handleBookDemoCall = () => {
  //   // window.open('https://u19503600.ct.sendgrid.net/ls/click?upn=PL88FYPFYE-2FyOdvx870-2B21oHKpb1f5fyhzERZjGy6ea7lmbWdp4S7jMtbqe81LVAt5O6IGIYcIPk8ArmVKUUBsQNPKZi2-2BfaOTyw5HqkYM0-3D5iLb_Ri8PiHMKLT1UEvhi2-2B-2F0b3VZQhQW5d3JSOQKU3aAbZiymSmQS1ZYjv9ztAayMbUa3L55e3sfzeOUcYEKLxI1S1kRRPYmBLRYe1W3clxoAaWjEsMYHn3598VqVVtAtDwCudQlY9K-2Fd4eJapNiWmyOkl0kQoe2Jrmx6q2Y2U7TpXdmZQt37l2uSwhXQDyxxA4tHvLSoYkvWCcj7z0dDyRtupk1cQW3NcHuHzhW3e9715j4t5Yo-2BDebS7XtqGnl-2BuI0ftcrMA8Mivp2-2BVdIXKIAEwF6EFsKTCa3fLLPrMEc3yU3mUTRnIDhFPy0S8oMkmoQ6DTw9KTw8LjUWuUpeDOXH8e86J3DkYdQ8hOkBXjUxrM-3D', '_blank')
  //   // handleFlowStepChange(1);
  //   // if(isCalendlyBooked) {
  //   //   handleFlowStepChange(1);
  //   // }
  //   setIsCalendlyClosed(true);
  // }

  const handleScheduleSuccess = () => {
    setIsCalendlyBooked(true);
    userStore.setGetStartedStepAsComplete('bookACall');
  }

  const handleFlowStepChange = (delta) => {
    uiStore.setActivationStepAsDone(0);
    uiStore.setActivationStepIndex(uiStore.activationStepIndex + delta);
    trackEvent('Onboarding Step Completed', mixpanelStepsData);
  }

  useTrackEvent('Onboarding Step Viewed', mixpanelStepsData);
  useTrackGTMEvent('Onboarding Step Viewed', mixpanelStepsData);

  return (<Observer>{() => (
    
      <HeightAnimationWrapper show={animationStep > 0} duration={600} height={320} >
        {animationStep > 1 &&
          <StepContent paddingTop={ uiStore.isDesktopView ? 112 : 24}>
            <ChatRow duration={500} minHeight={70} textOnly={!uiStore.isDesktopView}>
              {animationStep > 2 &&
                <AnimatedText delay={0} animationSpeed={1500} text={
                  userStore.isTier4 ? `Hello ${userStore.firstNameCapitalize}, it's a pleasure to meet you.
I want to assure you that I am here to assist you.
You can reach me through various channels, including a live chat, online meeting, or by calling me at +1 (302) 205-9009.` :
                  `Hi ${userStore.firstNameCapitalize}, pleasure to meet you.\nI’m here to help you get started with Vyzer.`
                  } />}
            </ChatRow>
            {isCalendlyClosed && isCalendlyBooked ? <ChatRow duration={500}>
              <AnimatedText delay={1000} animationSpeed={1500} text={`Great! a meeting was successfully scheduled. A confirmation was sent to your email.`} />
            </ChatRow> : null}
          </StepContent>}

        {animationStep > 3 && (uiStore.isDesktopView || uiStore.isActivationStepAnimated === 0 ) &&
          <StepFooter>
            <ActionButtonsWrapper isMobileReversed>
              <CalendlyPopupWidget onScheduleSuccess={handleScheduleSuccess} onClose={()=>{setIsCalendlyClosed(true)}} triggerComponent={<ActionButton withIcon><BookDemoCallIcon /> Book a demo call</ActionButton>} />
              <ActionButton withIcon onClick={startIntercomNewMessage}><IntercomIcon /> Chat with Adam</ActionButton>
              <ActionButton withIcon onClick={() => { handleFlowStepChange(1) }} black style={ uiStore.isDesktopView ? { padding: '8px 12px 8px 24px' } : {}}>{uiStore.isDesktopView ? <>Get started <ArrowDownIcon /></> : `Let's dive in`}</ActionButton>
            </ActionButtonsWrapper>
          </StepFooter>}
      </HeightAnimationWrapper>
  )}</Observer>)
}

export const AdamImageComponent = ({size, marginBottom = 8}) => {
  return (
    <AdamImage src={adamChatImg} alt={'Adam'} size={size} marginBottom={marginBottom} />
  )
}