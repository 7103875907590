import { useDropzone } from 'react-dropzone'
import { FileDropArea, FileDropAreaWrapper, FileDropAreaBody, ImportPersonalBalanceWrapper, UploadAnimatedBar, UploadAnimatedBarWrapper } from './MagicBoxAiActivationFlow.styles'
import EmptyStateBalanceSheetPhoto from '../assets/empty-state-balance-sheet-photo.png'
import EmptyStateBalanceSheetPhotoGrey from '../assets/empty-state-balance-sheet-photo-grey.png'
import FilesLogos from '../assets/files-logos.png'
import MagicBoxAiCover from '../assets/magic-box-ai-cover.png'
import { MAX_MAGIC_BOX_FILE_SIZE_MB, SUPPORTED_FILE_FORMATS } from '../data/magic-box-ai-store'
import { useStore } from '../../app/data/root.store'
import { DeepAnalysisSuccess } from './DeepAnalysisSuccessModal/DeepAnalysisSuccess'
import { useCallback } from 'react'

export const MagicBoxAIDropArea = ({ gray, skipButton }) => {
    const { magicBoxAiStore } = useStore();

    const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
        if ((true === magicBoxAiStore.isUploading)) return;
        await magicBoxAiStore.uploadDocument(acceptedFiles, fileRejections);
        if (1 !== acceptedFiles.length) return;
    }, [magicBoxAiStore]);

    const maxSizeInBytes = (MAX_MAGIC_BOX_FILE_SIZE_MB * (2 ** 20));
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxSize: maxSizeInBytes, accept: SUPPORTED_FILE_FORMATS });
    const emptyStateImage = gray ? EmptyStateBalanceSheetPhotoGrey : EmptyStateBalanceSheetPhoto;

    return (
        <FileDropAreaWrapper {...getRootProps()} isHoverActive={isDragActive} isActivationFlow showPointer={(!magicBoxAiStore.file || magicBoxAiStore.isUploading)} >
            <FileDropAreaBody isWhite={true}>
                <img src={MagicBoxAiCover} alt="Magic Box AI" style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12, maxWidth: '100%' }} />
                {
                    (!magicBoxAiStore.file || magicBoxAiStore.isUploading) ?
                        <div style={{ minHeight: '242px' }}>
                            <img src={emptyStateImage} alt="Empty State Balance Sheet" style={{ width: '100%', maxWidth: '464px', height: 'auto', marginTop: 24 }} />
                            <input {...getInputProps()} disabled={(magicBoxAiStore.isUploading)} />

                            <FileDropArea>
                                {
                                    (magicBoxAiStore.isUploading) ?
                                        <UploadAnimatedBarWrapper>
                                            <UploadAnimatedBar />
                                        </UploadAnimatedBarWrapper>
                                        :
                                        <>
                                            <ImportPersonalBalanceWrapper>
                                                <img src={FilesLogos} alt="Files Logos" style={{ width: 55, height: 43 }} />
                                                Import Personal Balance Sheet / Spreadsheet Tracker
                                            </ImportPersonalBalanceWrapper>
                                            {skipButton}
                                        </>
                                }
                            </FileDropArea>
                        </div> :
                        <DeepAnalysisSuccess />
                }
            </FileDropAreaBody>
        </FileDropAreaWrapper>
    )
};