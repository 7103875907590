import mixpanel from "mixpanel-browser";
import React, { createContext, useContext } from "react";
import { useStore } from "./root.store";

const analyticsContext = createContext({});
const useAnalytics = () => useContext(analyticsContext);

const AnalyticsProvider = ({ children }) => {
  const { userStore } = useStore();
  const mixpanelDebug = localStorage.getItem('__mixpanelDebugOn__') !== null;
  const isGTMDebugOn = process.env.NODE_ENV !== 'development' ? {} : { debug_mode: 1, traffic_type: 'internal' };
  const queryParameters = new URLSearchParams(window.location.search);
  const isOptOut = !!queryParameters.get('du');
  const token = process.env.REACT_APP_MIXPANEL_TOKEN;

  // Mixpanel Initialization
  if (!!token) {
    const defaultMixpanelConfig = {
      cross_subdomain_cookie: false,
    }
    mixpanel.init(token, { ...defaultMixpanelConfig, ...(mixpanelDebug ? { debug: true } : {}) });
  }
  if (isOptOut) {
    mixpanel.opt_out_tracking({ persistence_type: 'cookie', cookie_expiration: 1, cookie_prefix: '__mp_du_session', secure_cookie: true }); // Opt the user out of tracking
  }

  // GTM Data Layer Initialization
  window.dataLayer = window.dataLayer || [];

  const trackEvent = (eventName, eventParams) => {
    // Send data to GTM
    // TODO: need logic to filter out events according to ga4 spec on the ticket
    sendDataToGTM(eventName, eventParams);

    // Send data to Mixpanel
    mixpanelTrack(eventName, eventParams);
  };

  const sendDataToGTM = (eventName, eventParams) => {
    if (isOptOut) {
      console.log('Opted out of tracking');
      return;
    }

    const formattedEventName = eventName.toLowerCase().replace(/\s+/g, '_'); // string to lower case and replace spaces with underscores(_)

    window.dataLayer.push({
      event: formattedEventName,
      user_id: userStore?.data?.id || '',
      ...eventParams,
      ...isGTMDebugOn
    });
  };

  const mixpanelTrack = (eventName, eventParams) => {
    if (isOptOut) {
      console.log('Opted out of tracking');
      return;
    }
    mixpanel.track(eventName, modifiedEventParams(eventParams));
  };

  const modifiedEventParams = (eventParams) => {
    return {
      ...eventParams,
      ...(!userStore.isViewingOwnPortfolio && userStore.isHavingSharedPortfolios ? {
        account_owner_id: userStore?.data?.id || '',
      } : {})
    };
  }

  return (
    <analyticsContext.Provider value={{
      // identifyUser,
      // setMixpanelProfile,
      trackEvent,
      mixpanelTrack,
      mixpanel,
      mixpanelReset: () => mixpanel.reset(),
      sendDataToGTM
    }}>
      {children}
    </analyticsContext.Provider>
  );
};

export { AnalyticsProvider, useAnalytics };