import styled from '@emotion/styled/macro';
import { keyframes } from "@emotion/react";
import { WizardButton } from '../../../add-item/components/AddItem.styles';
import { MobileHeader, MobileHeaderTitle } from '../../../layout/components/MobileLayout.styles';
import { colors, views } from '../../../../common/styles/theme.styles';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
    margin-bottom: 16px;
`

export const SyncedInstitutionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    gap: 16px;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    width: 100%;
    ${views.tablet}{
        padding:0;
        gap:0;
        border-radius: 0px;
    }
`;

export const ConnectedInstitutionsTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1A1B1D;
`;


export const ConnectedInstitutionsAltTitle = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #979797;
`;

export const SyncAllNowButton = styled(WizardButton)`
    display: flex;
    justify-content: center;
    height: 32px;
    gap: 8px;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    margin: 0px 8px;
`;

export const SyncAllIconWrapper = styled.div`
width: 16px;
height: 16px;
animation: ${spin} ${props=> props.isLoading ? 2 : 0}s linear infinite;
`;

export const LogoLoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    padding-top: 100px;
`;

export const SyncedInstitutionsMobileHeader = styled(MobileHeader)`
  background-color: #fff;
  border-bottom: 1px solid ${colors.gray3};
`;

export const SyncedInstitutionsMobileHeaderTitle = styled(MobileHeaderTitle)`
    color: ${colors.darkGray1};
`;

export const MobileContentHeader = styled.div`
    display: flex;
    padding: 14px 20px 6px;
    align-items: flex-start;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    color: ${colors.darkGray2}
`;