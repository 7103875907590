import styled from "@emotion/styled/macro";
import { flexCenter, flexColumn } from "../../../../common/styles/combos.styles";
import { colors, shadows } from "../../../../common/styles/theme.styles";
import { Skeleton } from "antd";

export const DeepAnalysisReviewResultHeader = styled.div`
  ${flexCenter};
  align-items: flex-start;
  gap: 6px;
  padding: 16px;
`;

export const DeepAnalysisReviewResultHeaderTitle = styled.div`
  color: ${colors.DarkGray1};
  font-family: Circular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DeepAnalysisReviewResultHeaderSubtitle = styled(DeepAnalysisReviewResultHeaderTitle)`
  color: ${colors.darkGray2};
  font-size: 14px;
  font-weight: 450;
`;

export const DeepAnalysisReviewResultHero = styled.div`
  display: flex;
  padding: 16px 40px;
  align-items: center;
  gap: 12px;
`;

export const DeepAnalysisReviewResultHeroBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex:1;
  height: 120px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.gray3};
`;

export const DeepAnalysisReviewResultHeroBoxTitle = styled.div`
  color: ${colors.darkGray2};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const DeepAnalysisReviewResultHeroBoxValue = styled.div`
  color: ${colors.DarkGray1};
  font-family: Circular;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DeepAnalysisReviewResultHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  padding: 0px 40px;
  flex-shrink: 0;
  background: #FFF;
  border-bottom: ${({ isSticky }) => isSticky ? `1px solid ${colors.gray3}` : 'none'};
`;

export const RowTitle = styled.div`
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: ${colors.darkGray2};
  font-size: 14px;
  font-weight: 450;
`;

export const DeepAnalysisReviewResultContent = styled.div`
  ${flexColumn};
  flex:1;
  overflow-y: auto;
`;

export const DeepAnalysisReviewResultsWrapper = styled.div`
  display: flex;
  width: 100%;
  // width: 880px;
  padding: 16px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const DeepAnalysisReviewResultsRow = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  background: ${colors.lightGray1};
  padding: 12px 8px;
  border-radius: 12px;
  height: 72px;
  cursor: pointer;
  ${({ isEdited, isLoading }) => (isEdited || isLoading) ? `
    cursor: default;
    ` : ``}
  ${({ isLoading }) => isLoading ? `
    pointer-events: none;
    ` : ``}
`;

export const DeepAnalysisReviewResultsRowValue = styled.div`
  width: 132px;
`;

export const DeepAnalysisReviewResultsRowCategory = styled.div`
  
`;

export const DeepAnalysisReviewResultFooter = styled.div`
  ${flexCenter};
  justify-content: space-between;
  padding: 16px 24px;
  border-top: 1px solid ${colors.gray3};
  background: #FFF;
  box-shadow: ${shadows.cardShadow};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const DeepAnalysisInProgressPrompt = styled.div`
  ${flexCenter};
  gap: 6px;
  color: ${colors.darkGray2};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const ResultRowCell = styled.div`
    display: flex;
    align-items: center;
    padding-inline: 4px;
    width: 100%;
    text-align: left;
    min-height: 35px;
    position: relative;

    .ant-input,
    .ant-input-affix-wrapper {
        background: ${colors.lightGray1};
        border: 1px solid transparent;
        transition: all 0.3s;

        &:hover,
        &:focus,
        &:active {
            border-color: ${colors.darkGray1};
            box-shadow: none;
        }
    }

    .ant-input-affix-wrapper {
        .ant-input {
            font-size: 14px;
            background-color: transparent;
            border-color: transparent;

            &:hover,
            &:focus,
            &:active {
                border-color: transparent;
            }
        }
    }

    .ant-input-group-wrapper{
      display: flex;
      align-items: center;
      align-self: stretch;
      border-radius: 8px 0px 0px 8px;
      background: var(--Main-Colors-White, #FFF);
      height: 48px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;

        &:hover,
        &:focus,
        &:active {
          border-radius: 8px;
          border-color: var(--Main-Colors-Black, #000);

          /* Menu Popup */
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
        }

        .ant-input-wrapper.ant-input-group{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1 0 0;
          align-self: stretch;
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          height: 100%;

          .ant-input{
            display: flex;
            padding: 8px 12px;
            align-items: center;
            flex: 1 0 0;
            align-self: stretch;
            width: 92px;

            border-radius: 8px 0px 0px 8px;
            background: var(--Main-Colors-White, #FFF);

            color: var(--Dark-Gray-1, #1A1B1D);
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            
            font-size: 14px;
            background-color: transparent;
            border-color: transparent;

            &:hover,
            &:focus,
            &:active {
                border-color: transparent;
            }
          }

          .ant-input-group-addon{
            display: flex;
            width: 64px;
            padding: 8px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            height: 100%;

            border-radius: 0px 8px 8px 0px;
            border: none;
            border-left: 1px solid var(--Border-Border-01, #DDE3EB);
            background: var(--Main-Colors-White, #FFF);

            .ant-select-selector{
              box-shadow: none;
              background-color: transparent;
              border-color: transparent;
              outline: 0;

              .ant-select-selection-item,
              .ant-select-selection-search {
                outline: 0;
              }
            }
            
            .ant-select-focused .ant-select-selector{
              color: #bfbfbf !important;
            }
          }
        }
    }

    ${props => props.isActive ? `
        .ant-picker,
        .ant-input,
        .ant-input-affix-wrapper {
            background-color: #fff;
            // border-color: ${colors.darkGray1};
        }
    ` : ''}
`

export const DeleteActionWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

// export const AddNewItemButtonWrapper = styled.div`
//     ${flexCenter};
//     justify-content: flex-start;
//     gap:8px;
//     border:1px dashed ${colors.gray3};
//     border-radius: 8px;
//     padding:8px 11px;
//     margin:8px 12px 24px;
//     cursor: pointer;
//     font-size: 14px;
//     font-weight: 450;
//     color:${colors.darkGray2};
//     cursor: pointer;
//     ${views.desktop}{
//         :hover{
//             border-color:${colors.darkGray3};
//         }
//     }
// `;


export const ReviewNameSkeleton = styled(Skeleton)`
display: flex;
align-items: center;
gap: 7px;

.ant-skeleton-title{
  margin: 6px 0 !important;
}

.ant-skeleton-content {
  .ant-skeleton-title{}
}
`;

export const ReviewValueSkeleton = styled(ReviewNameSkeleton)`
    padding: 0px 8px;
`;