import { Observer } from 'mobx-react';
import React from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { useStore } from '../../../app/data/root.store';
import { ActivationFlowStepRowWrapper, ActivationFlowStepsMenuWrapper, ActivationStepStatusIconWrapper, ActivationStepTitleWrapper, JumpToPortfolioButton } from './ActivationFlowStepsMenu.styles';
import { ActivationStepActiveIcon, ActivationStepDoneIcon, ActivationStepPendingIcon } from './icons/sidebar-icons';
import { UserProfileMenu } from './UserProfileMenu';
import { navigateToUrl } from '../../../../common/utils/navigation.utils';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { ROUTES } from '../../../../common/constants/routes.const';

export const ActivationFlowStepsMenu = () => {
  const { trackEvent } = useAnalytics();
  const { uiStore } = useStore();
  const location = useLocation();
  let history = useHistory();

  const handleActivationFlowStepRowWrapperOnClick = (stepIndex) => {
    trackEvent('CTA Clicked', {
      cta_text: uiStore.activationFlowSteps[stepIndex].title,
      location: "Activation flow steps menu",
      destination: uiStore.activationFlowSteps[stepIndex].ctaDestination,
      type: "Button",
      url: window.location.href
    })
    if (location.pathname !== ROUTES.onboarding.url) {
      navigateToUrl(history, ROUTES.onboarding.url);
    }
    if (uiStore.isActivationStepAnimated !== 0) return;
    uiStore.setActivationStepIndex(stepIndex);
  }

  const handleSkipButton = async () => {
    trackEvent('Onboarding Step Completed', { step_name: 'Sync your accounts', step_number: 4 }); //TODO12833
    await uiStore.setWelcomeDone();

    window.location.href = ROUTES.wealthOverview.url;
    return;
  }

  return (<Observer>{() => (
    <>
      <ActivationFlowStepsMenuWrapper>
        {uiStore.activationFlowSteps.map((step, index) => (
          <ActivationFlowStepRowWrapper isActive={uiStore.activationStepIndex === index} key={index} onClick={() => { handleActivationFlowStepRowWrapperOnClick(index) }}>
            <ActivationStepStatusIconWrapper>
              {uiStore.activationStepIndex === index ? <ActivationStepActiveIcon /> : step.isDone ? <ActivationStepDoneIcon /> : <ActivationStepPendingIcon />}
            </ActivationStepStatusIconWrapper>
            <ActivationStepTitleWrapper>{step.title}</ActivationStepTitleWrapper>
            {step.title === 'Add assets through the Magic Box AI' && <AiStarsSvg />}
          </ActivationFlowStepRowWrapper>
        ))}
      </ActivationFlowStepsMenuWrapper>
      <ul style={{ gap: '16px' }}>
        <li>
          <JumpToPortfolioButton isLastStep={uiStore.activationFlowSteps.length - 1 === uiStore.activationStepIndex} onClick={handleSkipButton}>Continue to My Portfolio <ArrowRightSvg /></JumpToPortfolioButton>
        </li>
        <li><UserProfileMenu isActivation /></li>
      </ul>
    </>
  )}</Observer>)
}

const ArrowRightSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M13.5 9L4.5 9M13.5 9L9.75 4.5M13.5 9L9.75 13.5" stroke="#A6EDCF" strokeWidth="1.5" />
  </svg>
)

const AiStarsSvg = () => {
  return (
  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 0L11.0627 4.93729L16 6.5L11.0627 8.06271L9.5 13L7.93729 8.06271L3 6.5L7.93729 4.93729L9.5 0Z" fill="white"/>
      <path d="M2.5 0L3.10104 1.89896L5 2.5L3.10104 3.10104L2.5 5L1.89896 3.10104L0 2.5L1.89896 1.89896L2.5 0Z" fill="white"/>
  </svg>
  )
}