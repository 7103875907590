import React, { useEffect, useState } from 'react';
import { Button } from "antd";
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import { LogoLoader } from '../../../../common/components/LogoLoader/LogoLoader';
import { EditModeRow, InstitutionAccountsListWrapper, InstitutionAccountsPanelContentWrapper, InstitutionAccountsPanelWrapper } from './ConnectedInstitutionsList.styles';
import { VipInstitutionAccountRow } from './VipInstitutionAccountRow';
import { HeaderSearchBox } from '../../../layout/components/HeaderSearchBox/HeaderSearchBox';

export function VipInstitutionAccounts(props) {
  const { inst, accounts, isIncognito, isLoginRequired } = props;
  const { connectedInstitutionsStore } = useStore();
  const isEditMode = new URLSearchParams(window.location.search).get('edit') === '1';
  const [selectedAssetToAdd, setSelectedAssetToAdd] = useState(null);
  
  const handleConnectAssetToVip = () => {
    connectedInstitutionsStore.connectAssetToVipService(inst.id,selectedAssetToAdd.id,false,false);
    setSelectedAssetToAdd(null);
  }

  const handleUpdateLastSync = () => {
    connectedInstitutionsStore.vipUpdateLastSync(inst.id);
  }

  const handleChangeStatus = () => {
    connectedInstitutionsStore.vipChangeStatus(inst.id);
  }

  useEffect(() => {
    connectedInstitutionsStore.fetchVipAccounts(inst.id);

    return () => {
      //cleanup
    }
  }, [inst, connectedInstitutionsStore]);

  return (<Observer>{() => (
    <InstitutionAccountsPanelWrapper>
      {inst.isLoadingAccounts ? <div style={{ padding: '8px' }} className="loading-wrapper">
        <LogoLoader wbg />
      </div> :
        (inst.accountsLoaded ?
          <InstitutionAccountsPanelContentWrapper>
            {inst.showErrorMessage ?
              <div>
                <div>An error occurred while trying to get Instituition accounts</div>
                <div><Button onClick={() => { connectedInstitutionsStore.fetchAccounts(inst.id) }} type="link" >Try again</Button>, or contact support</div>
              </div> :
              <InstitutionAccountsListWrapper>
                {accounts.map(ac => (
                  <VipInstitutionAccountRow key={ac.id} account={ac} inst={inst} isIncognito={isIncognito} isLoginRequired={isLoginRequired} />
                ))}
              </InstitutionAccountsListWrapper>
            }
            {isEditMode && <>
              <EditModeRow>
                <span>Add asset: </span>
                { selectedAssetToAdd === null ? <div style={{width:'50%'}} ><HeaderSearchBox onSelect={(inst)=>{setSelectedAssetToAdd(inst)}}/></div> : 
                <><div>{selectedAssetToAdd.title}</div><button onClick={()=>{setSelectedAssetToAdd(null)}}>X</button><button onClick={()=> handleConnectAssetToVip()}>Connect</button></> }
                
              </EditModeRow>
              <EditModeRow>
                <button onClick={()=> handleUpdateLastSync()}>Update last sync to now</button>
                <button onClick={()=> handleChangeStatus()}>Change status to {inst.status === 'pending' ? 'Connected' : 'Pending'} </button>
              </EditModeRow>
            </>}
          </InstitutionAccountsPanelContentWrapper> : <></>)
      }
    </InstitutionAccountsPanelWrapper>
  )}</Observer>)
}