import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import { CheckmarkIcon } from '../../../../assets/icons/common/common-icons';
import { useTrackEvent, useTrackGTMEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { useStore } from '../../../app/data/root.store';
import { MagicBoxActivationFlow } from '../../../magic-box/components/MagicBoxActivationFlow';
import { SecuredMagicBoxIcon } from '../../assets/userActivationIcons';
// import { AnimatedText } from '../ChatRow/AnimatedText';
import { ChatRow } from '../ChatRow/ChatRow';
import { useAnimationsStepDelayedHook } from '../useAnimationsStepDelayedHook';
// import { useAnimationsStepDelayedHook } from '../useAnimationsStepDelayedHook';
import { ActionButton, ActionButtonsWrapper, AnimatedActivationFlowWrapper, CheckmarkIconWrapper, ActivationFlowStepWrapper, 
  // ProTipBadge,
   SecuredPromptHoverEffect, SecuredPromptWrapper, StepContent, StepFooter } from '../UserActivationFlowPage.styles';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';

export const MagicBoxStep = (props) => {
  const { magicBoxStore, magicBoxAiStore, userStore, uiStore } = useStore();
  // const animationStep = useAnimationsStepDelayedHook({ animationsStepDelayed: [500, 4800, 1000, 1200, 1500, 2200] });
  const animationStep = useAnimationsStepDelayedHook({ animationsStepDelayed: [700] });
  const [isDone, setIsDone] = useState(false);
  const { trackEvent } = useAnalytics()
  const mixpanelStepsData = {step_name: 'Upload documents', step_number: 2};

  const handleContinueOnClick = () => {
    magicBoxStore.approveUploadingFiles(userStore, true);
    handleFlowStepChange(1);
  }

  const handBackOnClick = () => {
    if (magicBoxAiStore.switchToManualReview && !magicBoxStore.hasAnyFileUpload){
      magicBoxAiStore.setSwitchToManualReview(false);
    } else {
      handleFlowStepChange(-1);
    }
  }

  const handleFlowStepChange = (delta) => {
    if (delta) {
      uiStore.setActivationStepAsDone(1);
      trackEvent('Onboarding Step Completed', mixpanelStepsData);
    }
    uiStore.setActivationStepIndex(uiStore.activationStepIndex + delta);
  }

  const handleImDoneOnClick = () => {
    if (!magicBoxStore.hasAnyFileUpload) return;
    if (!isDone) {
      setIsDone(true);
      setTimeout(()=>{
        window.scrollTo(0, document.body.scrollHeight);
      },200);
      
      // objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

  useTrackEvent('Onboarding Step Viewed', mixpanelStepsData);
  useTrackGTMEvent('Onboarding Step Viewed', mixpanelStepsData);

  return (<Observer>{() => (
    <ActivationFlowStepWrapper>

      <StepContent withFixedActions>
      <ChatRow duration={0}>
          <span>
            <span style={{ fontWeight: 600 }}>Seamless setup: </span>
            Import your investment tracker / personal balance sheet</span>
        </ChatRow>
      

        <AnimatedActivationFlowWrapper padding={uiStore.isDesktopView ? '16px 0 16px 62px': ''}><MagicBoxActivationFlow /></AnimatedActivationFlowWrapper>


        {magicBoxAiStore.switchToManualReview && (0 === magicBoxStore.uploadedFiles.length) && <ChatRow textOnly={!uiStore.isDesktopView}>
          <span>Okay, no worries. To set up your Vyzer portfolio, you can upload any document detailing your investment information.</span>
        </ChatRow>}

        {isDone && <ChatRow textOnly={!uiStore.isDesktopView}>
            <span>Great job! 👏 Your files was uploaded successfully.  
You’ll receive an email once your assets have been set up. Remember, it can take up to 48 hours.</span>
        </ChatRow>}
      </StepContent>

      <StepFooter isFixedToBottom>
        <ActionButtonsWrapper>
          {uiStore.isDesktopView && <ActionButton isBack onClick={() => { handBackOnClick() }}>Back</ActionButton>}
          {!magicBoxStore.hasAnyFileUpload ? <div onClick={() => { handleFlowStepChange(1) }} style={{ display: 'grid', placeItems: 'center', cursor: 'pointer', padding: uiStore.isDesktopView ? '0' : '0 16px' }}>Skip</div> : null}
          {((!isDone) && (magicBoxStore.uploadingFiles.length > 0)) ?
            <ActionButton disabled={!magicBoxStore.hasAnyFileUpload} onClick={handleImDoneOnClick} withIcon black>
              <CheckmarkIconWrapper><CheckmarkIcon /></CheckmarkIconWrapper> I'm done
            </ActionButton> :
            <ActionButton onClick={handleContinueOnClick} withIcon black>Continue</ActionButton>}
        </ActionButtonsWrapper>
      </StepFooter>

        {(uiStore.isDesktopView && animationStep > 0) && 
        <SecuredPromptWrapper>
          <SecuredMagicBoxIcon />
          Secured
          <SecuredPromptHoverEffect>Don’t worry, Vyzer doesn’t share any of your information. Your data is encrypted at-rest and in-transit.</SecuredPromptHoverEffect>
        </SecuredPromptWrapper> }
    </ActivationFlowStepWrapper>
  )}</Observer>)
}