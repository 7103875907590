import styled from '@emotion/styled/macro';
import { flexCenter } from '../../styles/combos.styles';
import { colors } from '../../styles/theme.styles';

export const ActionLockedWrapper = styled.span`
  cursor: pointer;
`;

export const ActionLockedIconWithTooltipWrapper = styled.div`
  ${flexCenter};
  margin-left: ${props=>props.marginLeft ? props.marginLeft : '4px'};
`;

export const TooltipCompWrapper = styled.div`
  /* ${flexCenter}; */
  display: flex;
  gap: 4px;
  font-size: 12px;
  ${props=>props.withLineBreak ? `
    white-space: pre-wrap;
  ` : ``}
`;

export const LockIconWrapper = styled.div`
  ${flexCenter};
  width: 16px;
  height: 16px;
  ${props=>props.marginTop ? `margin-top: ${props.marginTop};` : ``}
  svg {
    margin: 0!important;
    path {
      fill:${props=>props.iconColor ? props.iconColor : colors.darkGray2};
    }
  }
`;
