import React, { useState } from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../../app/data/root.store';
import { RangeParameterFilterWrapper, FilterOptionsWrapper } from './RangeParameterFilter.styles';
import { Slider } from 'antd';
import { colors } from '../../../../../common/styles/theme.styles';
import NumberFormat from 'react-number-format';

export const RangeParameterFilter = ({param,paramIndex}) => {
    const { filtersStore } = useStore();
		const { from, to } = filtersStore.parameters[paramIndex].value;
		const { from: minFrom, to: maxTo } = filtersStore.parameters[paramIndex].defaultValue;
    const [assetValue, setAssetValue] = useState([from, to]);

		const handleInputChange = (values) => {
			setAssetValue(values);
			handleFilterUpdate(values);
		}

		const handleFilterUpdate = (values) => {
			const [from, to] = values;
			filtersStore.setRangeValues(paramIndex, from, to);
		}

		const handleStyle = {
			width: '26px',
			height: '26px',
			marginTop: '-12px',
			border: 0,
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)'
		}

    return (<Observer>{() => (
        <FilterOptionsWrapper>
					<RangeParameterFilterWrapper>
						<NumberFormat 
							value={assetValue[0]}
							// min={minFrom} 
							// max={assetValue[1]}
							className={'range-number-input'}
							allowNegative={false}
							decimalScale={0}
							prefix={'$ '}
							thousandSeparator=","
							onValueChange={(from) => handleInputChange([from.value, assetValue[1]])} />

						&#8208;

						<NumberFormat 
							value={assetValue[1]}
							// min={assetValue[0]} 
							// max={maxTo} 
							allowNegative={false}
							decimalScale={0}
							prefix={'$ '}
							thousandSeparator=","
							className={'range-number-input'}
							onValueChange={(to) => handleInputChange([assetValue[0], to.value])} />
					</RangeParameterFilterWrapper>
					<Slider range 
									value={assetValue}
									tooltipVisible={false} 
									defaultValue={assetValue} 
									min={minFrom}
									max={maxTo} 
									onAfterChange={(values) => handleFilterUpdate(values)} 
									onChange={(values) => setAssetValue(values)} 
									trackStyle={[{ backgroundColor: colors.darkGray2}]} 
									railStyle={{ backgroundColor: colors.gray3 }} 
									handleStyle={[handleStyle, handleStyle]} />
        </FilterOptionsWrapper>
    )}</Observer>)
}
