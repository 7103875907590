import { Input } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { isNullOrUndefinedOrEmptyString } from '../../../../common/utils/object.utils';
import { useTrackEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { useStore } from '../../../app/data/root.store';
import { AdamImageComponent } from '../../../user-activation-flow/components/steps/MeetAdamStep';
import { questionnaireIcons } from './assets/questionnaireIcons';
import { AnswerItemWrapper, AnswersWrapper, OtherAnswerTextAreaWrapper, QuestionnaireContentWrapper, QuestionnaireContinueButton, QuestionnaireFooter, QuestionnaireStepHeaderTextContent, QuestionnaireStepHeaderWrapper, QuestionnaireStepTitle, QuestionnaireSubTitle, QuestionnaireTitleWrapper, QuestionnaireTopSubTitle, StepWrapper, Wrapper } from './OnBoardingQuestionnaire.styles';
import { OnBoardingQuestionnaireHeader } from './OnBoardingQuestionnaireHeader';
import { newQuestionnaireStepsData } from './QuestionnaireSteps.const';
import { AppLoader } from '../../../../common/components/Loaders/AppLoader';

const {
  EditPencilSvg,
} = questionnaireIcons;

const { TextArea } = Input;

export const OnBoardingQuestionnaire = observer(() => {
  const { onBoardingStore, userStore, uiStore } = useStore();
  const questionnaireStepsWrapper = useRef();
  const { mixpanelTrack } = useAnalytics();
  

  const [currentStepContentData, setCurrentStepContentData] = useState({});


  const checkScroll = () => {
    const { scrollHeight, clientHeight, scrollTop } = questionnaireStepsWrapper.current;
    if (scrollHeight > clientHeight) {
      onBoardingStore.setIsQuestionnaireFooterSticky(true)
    } else {
      onBoardingStore.setIsQuestionnaireFooterSticky(false)
    }
    if (scrollTop >= 57) {
      onBoardingStore.setIsQuestionnareTitleSticky(true);
    } else {
      onBoardingStore.setIsQuestionnareTitleSticky(false);
    }
  }

  useEffect(() => {
    const resizeListener = () => {
      if (questionnaireStepsWrapper.current) {
        const { scrollHeight, clientHeight } = questionnaireStepsWrapper.current;
        if (scrollHeight > clientHeight) {
          onBoardingStore.setIsQuestionnaireFooterSticky(true)
        } else {
          onBoardingStore.setIsQuestionnaireFooterSticky(false)
        }
      }
    }
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [onBoardingStore]);

  useEffect(() => {
    questionnaireStepsWrapper.current.scrollTop = 0;
    window.scrollTo(0, 1);

    // console.log("Step change", onBoardingStore.questionnaireStep);
    const initialData = {
      // name: userStore.firstNameCapitalize + (userStore.data.profile?.lastName ? (' ' + userStore.data.profile?.lastName) : ''),
      firstNameCapitalize: userStore.firstNameCapitalize,
      isMobileView: !uiStore.isDesktopView,
    }
    const stepData = newQuestionnaireStepsData[onBoardingStore.questionnaireStep] ?? {};
    const currentStepData = typeof stepData === 'function' ? stepData(initialData) : stepData;
    // console.log("Step data", currentStepData);
    setCurrentStepContentData(currentStepData);

  }, [userStore, onBoardingStore.questionnaireStep, uiStore.isDesktopView]);

  const {
    adamImageSize = 48,
    showBackButton = true,
    showContent = true,
    showFooter = true,
    whyDoWeAskContent = null,
    title = null,
    topSubTitle = null,
    subTitle = null,
    nextButtonText = 'Continue',
    isValidInput = () => true,
    answerOptions = null,
    answerType = 'continue',
    answerSelectionLimit = null,
    answerWrapperCustomStyles = {},
    answerCustomStyles = {},
    stepHeader: CurrentStepHeader = () => null,
    stepContent: CurrentStepContent = null,
    stepFooter: CurrentStepFooter = null,
    nextStep: currentStepNextStep = null,
    nextStepFn: currentStepNextStepFn = null,
    withoutFullWidth = false,
    withRandomize = false,
    mixpanelStepData = {},
  } = currentStepContentData;

  const handleContinue = () => {
    if (isValidInput(onBoardingStore.currentStepData)) {
      const nextStep = currentStepNextStepFn ? currentStepNextStepFn(onBoardingStore.currentStepData) : currentStepNextStep;
      mixpanelTrack('Questionnaire Step Completed', {...mixpanelStepData, step_name: onBoardingStore.questionnaireStep, step_selections: onBoardingStore.currentStepData});
      onBoardingStore.handleNext(nextStep);
    }
  }

  const handleOtherInputClick = (e) => {
    if ((onBoardingStore.currentStepData?.value || []).includes('other')) {
      e.stopPropagation();
    }
  }
  const handleOtherInputChange = (value) => {
    onBoardingStore.updateCurrentStepData('other', value);
  }
  const handleOtherInputTextAreaBlur = (value) => {
    if (isNullOrUndefinedOrEmptyString(value)) {
      onBoardingStore.updateCurrentStepData('value', (onBoardingStore.currentStepData?.value || []).filter(v => v !== 'other'));
      onBoardingStore.deleteCurrentStepData('other');
      if (answerType === 'singleChoiceWithOther') {
        onBoardingStore.deleteCurrentStepData('value');
      }
    }
  }

  const handleAnswerClick = (answer) => {
    // console.log('Answer clicked', answer)
    if (answerType === 'goToStep') {
      onBoardingStore.updateCurrentStepData('value', answer.value);
      mixpanelTrack('Questionnaire Step Completed', {...mixpanelStepData, step_name: onBoardingStore.questionnaireStep, step_selections: answer.value});
      onBoardingStore.handleNext(answer.goToStep);
    }

    if (answerType === 'singleChoice') {
      onBoardingStore.updateCurrentStepData('value', answer.value);
    }
    if (answerType === 'singleChoiceWithOther') {
      if (answer.value === 'other') {
        onBoardingStore.updateCurrentStepData('value', ['other']);
      } else {
        onBoardingStore.deleteCurrentStepData('other');
        onBoardingStore.updateCurrentStepData('value', answer.value);
        mixpanelTrack('Questionnaire Step Completed', {...mixpanelStepData, step_name: onBoardingStore.questionnaireStep, step_selections: answer.value});
        onBoardingStore.handleNext(answer.goToStep);
      }
    }
    if (answerType === 'multiChoice') {
      const isAnswerSelected = (onBoardingStore.currentStepData?.value || []).includes(answer.value);
      const isLimitReached = answerSelectionLimit && (onBoardingStore.currentStepData?.value || []).length >= answerSelectionLimit;
      if (isLimitReached && !isAnswerSelected) {
        return;
      }
      onBoardingStore.updateCurrentStepData('value', isAnswerSelected ? (onBoardingStore.currentStepData?.value || []).filter(v => v !== answer.value) : [...(onBoardingStore.currentStepData?.value || []), answer.value]);
    }


  }

  // Seeded random number generator
  const seededRandom = (seed) => {
    var x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
  }

  // Fisher-Yates shuffle
  const shuffleArray = (array, seed) => {
    let rng = seededRandom(seed);
    // if array contains an answer with value 'other', move it to the end of the array after the randomization
    const otherAnswer = array.find(answer => answer.value === 'other');
    if (otherAnswer) {
      array = array.filter(answer => answer.value !== 'other');
    }
    // shuffle the array
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(rng * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
      rng = seededRandom(rng * 10000); // update the RNG with a new seed
    }
    // if array contains an answer with value 'other', move it to the end of the array
    if (otherAnswer) {
      array.push(otherAnswer);
    }
    return array;
  }
  
  useTrackEvent('Questionnaire Step Viewed', {step_name: onBoardingStore.questionnaireStep}, [onBoardingStore.questionnaireStep]);

  return (
    <Wrapper ref={questionnaireStepsWrapper} onScroll={checkScroll}>
      {onBoardingStore.isLoadingQuestionnaireRequest ? <AppLoader backdrop absolute mobileFixed logoMargin={!uiStore.isDesktopView || uiStore.isMobileAppView ? '0 0 0 0' : '0 0 0 80px'}/> : null} 
        <OnBoardingQuestionnaireHeader showBackButton={showBackButton} whyDoWeAskContent={whyDoWeAskContent} />
        <StepWrapper >

          <QuestionnaireStepHeaderWrapper >
            <AdamImageComponent size={adamImageSize} />
            <QuestionnaireStepHeaderTextContent isAnimate={onBoardingStore.isAnimateStep}>
              {title &&
                <QuestionnaireTitleWrapper >
                  {topSubTitle && <QuestionnaireTopSubTitle>{topSubTitle}</QuestionnaireTopSubTitle>}
                  <QuestionnaireStepTitle >{title}</QuestionnaireStepTitle>
                  {subTitle && <QuestionnaireSubTitle >{subTitle}</QuestionnaireSubTitle>}
                </QuestionnaireTitleWrapper>}

              <CurrentStepHeader />
            </QuestionnaireStepHeaderTextContent>
          </QuestionnaireStepHeaderWrapper>

          {showContent ?
            <QuestionnaireContentWrapper height={'200px'} isAnimate={onBoardingStore.isAnimateStep} withoutFullWidth={withoutFullWidth}>
              {CurrentStepContent ? <CurrentStepContent /> : <>
                {answerOptions && <AnswersWrapper style={answerWrapperCustomStyles}>
                  {[...(withRandomize ? shuffleArray(answerOptions.slice(), userStore.data.id) : answerOptions)].map((answer, index) => {
                    // const isSelected = answerType !== 'goToStep' && (Array.isArray(onBoardingStore.currentStepData?.value) ? onBoardingStore.currentStepData?.value.includes(answer.value) : onBoardingStore.currentStepData?.value === answer.value);
                    const isSelected = (Array.isArray(onBoardingStore.currentStepData?.value) ? onBoardingStore.currentStepData?.value.includes(answer.value) : onBoardingStore.currentStepData?.value === answer.value);
                    return (
                      <AnswerItemWrapper key={index} index={index} onClick={() => handleAnswerClick(answer)} style={answer.isOtherInput ? {...(!uiStore.isDesktopView ? answer.icon ? {maxHeight:80} : {maxHeight:64} : {})} : {...answerCustomStyles, ...(!uiStore.isDesktopView ? answer.icon ? {maxHeight:80} : {maxHeight:64} : {})}} isSelected={ isSelected } isOtherInput={answer.isOtherInput } isOtherWithTextArea={answer.isOtherInput && ( !isNullOrUndefinedOrEmptyString(onBoardingStore.currentStepData?.other) || isSelected)} >
                      { answer.isOtherInput && ( !isNullOrUndefinedOrEmptyString(onBoardingStore.currentStepData?.other) || isSelected) ? 
                      <OtherAnswerTextAreaWrapper>
                        <TextArea 
                          style={{ resize:'none', width: '100%', minHeight:'70px', borderRadius: '8px', border: 'none', padding: '8px 12px', fontSize: '14px', fontWeight:'500', lineHeight: '16px' }} 
                          placeholder='Type here...' value={onBoardingStore.currentStepData?.other} autoFocus onChange={(e)=>handleOtherInputChange(e.target.value)} onBlur={(e)=>handleOtherInputTextAreaBlur(e.target.value)} onClick={(e)=>{handleOtherInputClick(e)}} />
                      </OtherAnswerTextAreaWrapper> : <>
                            {answer.icon}
                            {answer.label}
                            {answer.isOtherInput && <EditPencilSvg />}
                          </>}
                      </AnswerItemWrapper>
                    )
                  })}
                </AnswersWrapper>}
              </>}
            </QuestionnaireContentWrapper> : <div style={{ height: '48px' }} />
          }

          {showFooter && <QuestionnaireFooter>
            {CurrentStepFooter ? <CurrentStepFooter /> :
              <QuestionnaireContinueButton disabled={!isValidInput(onBoardingStore.currentStepData)} onClick={handleContinue}>{nextButtonText}</QuestionnaireContinueButton>}
          </QuestionnaireFooter>}

        </StepWrapper>

      

    </Wrapper>
  )
})