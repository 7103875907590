import { ReactComponent as LayersIconSvg } from './Layers Icon.svg';
import { ReactComponent as BankIconSvg } from './Bank Icon.svg';
import { ReactComponent as BarsIconSvg } from './Bars Icon.svg';
import { ReactComponent as BriefcaseIconSvg } from './Briefcase Icon.svg';
import { ReactComponent as ClockIconSvg } from './Clock Icon.svg';
import { ReactComponent as DollarIconSvg } from './Dollar Icon.svg';
import { ReactComponent as GoldIconSvg } from './Gold Icon.svg';
import { ReactComponent as GraphIconSvg } from './Graph Icon.svg';
import { ReactComponent as HouseIconSvg } from './House Icon.svg';
import { ReactComponent as LockIconSvg } from './Lock Icon.svg';
import { ReactComponent as MoneyIconSvg } from './Money Icon.svg';
import { ReactComponent as PercentageIconSvg } from './Percentage Icon.svg';
import { ReactComponent as PieChartIconSvg } from './PieChart Icon.svg';
import { ReactComponent as PigIconSvg } from './Pig.svg';
import { ReactComponent as ShieldIconSvg } from './Shield Icon.svg';
import { ReactComponent as TargetIconSvg } from './Target Icon.svg';

export const customClassSvgs = [
  { svgName: 'LayersSvg', svgContent: LayersIconSvg },
  { svgName: 'BankSvg', svgContent: BankIconSvg },
  { svgName: 'BarsSvg', svgContent: BarsIconSvg },
  { svgName: 'BriefcaseSvg', svgContent: BriefcaseIconSvg },
  { svgName: 'ClockSvg', svgContent: ClockIconSvg },
  { svgName: 'DollarSvg', svgContent: DollarIconSvg },
  { svgName: 'GoldSvg', svgContent: GoldIconSvg },
  { svgName: 'GraphSvg', svgContent: GraphIconSvg },
  { svgName: 'HouseSvg', svgContent: HouseIconSvg },
  { svgName: 'LockSvg', svgContent: LockIconSvg },
  { svgName: 'MoneySvg', svgContent: MoneyIconSvg },
  { svgName: 'PercentageSvg', svgContent: PercentageIconSvg },
  { svgName: 'PieChartSvg', svgContent: PieChartIconSvg },
  { svgName: 'PigSvg', svgContent: PigIconSvg },
  { svgName: 'ShieldSvg', svgContent: ShieldIconSvg },
  { svgName: 'TargetSvg', svgContent: TargetIconSvg },
];

export const customClassSvgObj = customClassSvgs.reduce((acc, { svgName, svgContent }) => {
  acc[svgName] = svgContent;
  return acc;
}, {});
