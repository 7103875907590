import CustomColor1 from './CustomColor_1.jpg';
import CustomColor2 from './CustomColor_2.jpg';
import CustomColor3 from './CustomColor_3.jpg';
import CustomColor4 from './CustomColor_4.jpg';
import CustomColor5 from './CustomColor_5.jpg';
import CustomColor6 from './CustomColor_6.jpg';
import CustomColor7 from './CustomColor_7.jpg';
import CustomColor8 from './CustomColor_8.jpg';
import CustomColor9 from './CustomColor_9.jpg';
import CustomColor10 from './CustomColor_10.jpg';
import CustomColor11 from './CustomColor_11.jpg';
import CustomColor12 from './CustomColor_12.jpg';
import CustomColor13 from './CustomColor_13.jpg';
import CustomColor14 from './CustomColor_14.jpg';
import CustomColor15 from './CustomColor_15.jpg';
import CustomColor16 from './CustomColor_16.jpg';

export const customColors = [
  { colorName: 'customColor13', colorImage: CustomColor13, fallbackColor: '#B3A1E7' },
  { colorName: 'customColor1', colorImage: CustomColor1, fallbackColor: '#A0ADB8' },
  { colorName: 'customColor2', colorImage: CustomColor2, fallbackColor: '#AAC8D4' },
  { colorName: 'customColor3', colorImage: CustomColor3, fallbackColor: '#C3B4B6' },
  { colorName: 'customColor4', colorImage: CustomColor4, fallbackColor: '#C2918B' },
  { colorName: 'customColor5', colorImage: CustomColor5, fallbackColor: '#EAB6D0' },
  { colorName: 'customColor6', colorImage: CustomColor6, fallbackColor: '#EB9583' },
  { colorName: 'customColor7', colorImage: CustomColor7, fallbackColor: '#E9AE7A' },
  { colorName: 'customColor8', colorImage: CustomColor8, fallbackColor: '#F7D595' },
  { colorName: 'customColor9', colorImage: CustomColor9, fallbackColor: '#CECAAC' },
  { colorName: 'customColor10', colorImage: CustomColor10, fallbackColor: '#B4C1B2' },
  { colorName: 'customColor11', colorImage: CustomColor11, fallbackColor: '#78A6B4' },
  { colorName: 'customColor12', colorImage: CustomColor12, fallbackColor: '#65AE9F' },
  { colorName: 'customColor14', colorImage: CustomColor14, fallbackColor: '#BCAEA4' },
  { colorName: 'customColor15', colorImage: CustomColor15, fallbackColor: '#AC9C77' },
  { colorName: 'customColor16', colorImage: CustomColor16, fallbackColor: '#D5C972' },
];

export const customClassColorsObj = customColors.reduce((acc, color) => {
  acc[color.colorName] = {colorImage: color.colorImage , fallbackColor: color.fallbackColor};
  return acc;
}, {});
