import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DatePicker, Modal, Tooltip } from "antd";
import moment from 'moment';
import React, { useEffect, useState, } from 'react';
import { SimpleSelect } from '../../../../../../common/components/DropDownMenu/SimpleSelect';
import { ModifiedTextArea } from '../../../../../../common/components/ModifiedTextArea/ModifiedTextArea';
import { colors, shadows } from '../../../../../../common/styles/theme.styles';
import { ActionButtonWrapper, DocumentsTableCell, DocumentsTableCellText, DocumentsTableRow, DocumentsTablesActionsWrapper, HoverActionsWrapper, SaveChangesButtonWrapper } from './DocumentTab.styles';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export function DocumentRow({ document, rowUpdated, rowDeleted, documentIndex, editedIndex, onEditedIndexChange, isRoleBlocked }) {
  const defaultValues = document;
  const [currentDocument, setCurrentDocument] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isHoverOn, setIsHoverOn] = useState(false);

  const { confirm } = Modal;

  useEffect(() => {
    if (editedIndex === documentIndex) {
      setCurrentDocument(document)
      setIsEdited(true);
    } else {
      setCurrentDocument(defaultValues)
      setIsEdited(false);
    }
    // return () => {}
  }, [editedIndex, document, documentIndex, defaultValues])


  const handleFieldChange = (field, value) => {
    if (isRoleBlocked) {
      return;
    }
    setCurrentDocument({
      ...currentDocument,
      [field]: value
    })
  }

  const cancelChanges = (e) => {
    e.stopPropagation();
    setCurrentDocument(defaultValues);
    onEditedIndexChange?.(null);
  }

  const saveChanges = (e) => {
    e.stopPropagation();
    onEditedIndexChange?.(null);
    rowUpdated?.(currentDocument);
  }

  const showDeleteDocumentConfirm = (e, documentId) => {
    if (isRoleBlocked) {
      return;
    }
    e.stopPropagation();
    confirm({
      title: `Are you sure you want to delete this document?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        rowDeleted?.(documentId);
      },
      onCancel() {
      },
    });
  }

  const downloadDocument = (e) => {
    e.stopPropagation();
    fetch(`${baseApiUrl}documents/${currentDocument?.id}`, {
      method: 'GET',
      credentials: 'include'
    })
      .then(response => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          }
        })
      })
      .then(stream => new Response(stream))
      .then(response => response.blob())
      .then(blob => {
        const objectUrl = URL.createObjectURL(blob);
        var link = window.document.createElement("a");
        link.href = objectUrl;
        link.download = currentDocument?.originalName;
        window.document.body.appendChild(link);
        link.click();
        window.document.body.removeChild(link);
      })
  }

  return (
    <DocumentsTableRow key={currentDocument?.id} isFirst={documentIndex === 0} isEdited={isEdited}
      onClick={() => { onEditedIndexChange?.(documentIndex) }}
      onMouseEnter={() => { setIsHoverOn(true) }} onMouseLeave={() => { setIsHoverOn(false) }} >

      <DocumentsTableCell>
        <DocumentsTableCellText
          disabled
          style={{ ...((isEdited || isHoverOn) ? { boxShadow: shadows.toggleShadow, color: colors.darkGray3 } : { borderColor: 'transparent', backgroundColor: 'transparent', color: colors.darkGray1 }), borderRadius: '6px' }} >
          {currentDocument?.originalName}
        </DocumentsTableCellText>
      </DocumentsTableCell>

      <DocumentsTableCell>
        <SimpleSelect value={currentDocument?.type}
          onChange={(val) => { handleFieldChange('type', val) }} style={{ width: '100%' }}
          options={[
            { value: 'General', label: 'General' },
            { value: 'K-1', label: 'K-1' },
            { value: 'Tax', label: 'Tax' },
            { value: 'Periodic reports', label: 'Periodic reports' },
          ]}
          buttonStyle={{ borderRadius: '6px', height: '32px', fontWeight: '450', color: (isHoverOn && isRoleBlocked) ? '#9097A3' : '#3F3F45', boxShadow: 'none', border: `1px solid ${colors.gray3}`, ...((isEdited || (isHoverOn && !isRoleBlocked)) ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }), ...(isRoleBlocked ? {cursor: 'not-allowed'} : {}) }}
          buttonHoverBorderColor={isRoleBlocked ? '' : colors.darkGray1}
          menuItemColor={'#3F3F45'}
          disabled={isRoleBlocked}
          {...((isEdited || isHoverOn) ? {} : { hideSuffixIcon: true })} />
      </DocumentsTableCell>

      <DocumentsTableCell>
        <DatePicker
          picker="year"
          dropdownClassName='documentRowDatePicker'
          inputReadOnly
          value={currentDocument?.year ? moment().year(currentDocument?.year) : null}
          onChange={(val) => { handleFieldChange('year', (val ? val.year() : currentDocument?.date)) }}
          style={{ ...((isEdited || isHoverOn) && !isRoleBlocked ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }), width: '100%', borderRadius: '6px', height: '32px' }}
          disabled={isHoverOn && isRoleBlocked}
          {...((isEdited || isHoverOn) ? {} : { suffixIcon: null })} />
      </DocumentsTableCell>

      <DocumentsTableCell>
        <ModifiedTextArea
          value={currentDocument?.remarks}
          onChange={(e) => { handleFieldChange('remarks', e.target.value) }}
          // customStyle={isHoverOn && isRoleBlocked ? { opacity: '0.3' } : {}}
          disabled={isHoverOn && isRoleBlocked}
          isEdited={isEdited} isHoverOn={isHoverOn} />
      </DocumentsTableCell>

      <DocumentsTablesActionsWrapper>
        {isEdited ? <>
          <Tooltip title="Save changes">
            <SaveChangesButtonWrapper onClick={saveChanges}>
              <CheckmarkSvg />
              Save changes
            </SaveChangesButtonWrapper>
          </Tooltip>
          <Tooltip title="Cancel changes">
            <ActionButtonWrapper onClick={cancelChanges}>
              <CloseSvg />
            </ActionButtonWrapper>
          </Tooltip>
        </> : <HoverActionsWrapper>
          {/* <Tooltip title="Preview document">
            <ActionButtonWrapper onClick={() => { window.open(`${baseApiUrl}documents/${currentDocument?.storageKey}`, '_blank') }}><PreviewSvg /></ActionButtonWrapper>
          </Tooltip> */}
          <Tooltip title="Download document">
            <ActionButtonWrapper onClick={downloadDocument}><DownloadSvg /></ActionButtonWrapper>
          </Tooltip>
          <Tooltip title="Delete document">
            <ActionButtonWrapper disabled={isRoleBlocked} onClick={(e) => { showDeleteDocumentConfirm(e, currentDocument?.id) }}><EraseSvg /></ActionButtonWrapper>
          </Tooltip>
          <Tooltip title="Edit document">
            <ActionButtonWrapper disabled={isRoleBlocked}><EditSvg /></ActionButtonWrapper>
          </Tooltip>
        </HoverActionsWrapper>}
      </DocumentsTablesActionsWrapper>
    </DocumentsTableRow>
  )
}

export const CheckmarkSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5105 8.60403L10.506 15.6125L6.99995 12.1076" stroke="white" strokeWidth="1.5" />
    </svg>
  )
}

export const CloseSvg = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="4.84283" y1="4.24265" x2="13.8584" y2="13.2583" stroke="black" strokeWidth="1.5" />
      <line x1="4.31244" y1="13.2582" x2="13.3281" y2="4.24263" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}

// const PreviewSvg = () => {
//   return (
//     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path fillRule="evenodd" clipRule="evenodd" d="M3 12.5C3 12.5 6.13401 19.5 12 19.5C17.866 19.5 21 12.5 21 12.5C21 12.5 17.866 5.5 12 5.5C6.13401 5.5 3 12.5 3 12.5ZM4.34279 12.5C4.4174 12.6391 4.50856 12.803 4.61605 12.986C4.97525 13.5975 5.5089 14.4085 6.20706 15.2141C7.62654 16.8518 9.57433 18.3 12 18.3C14.4257 18.3 16.3735 16.8518 17.7929 15.2141C18.4911 14.4085 19.0247 13.5975 19.3839 12.986C19.4914 12.803 19.5826 12.6391 19.6572 12.5C19.5826 12.3609 19.4914 12.197 19.3839 12.014C19.0247 11.4025 18.4911 10.5915 17.7929 9.78594C16.3735 8.14817 14.4257 6.7 12 6.7C9.57433 6.7 7.62654 8.14817 6.20706 9.78594C5.5089 10.5915 4.97525 11.4025 4.61605 12.014C4.50856 12.197 4.4174 12.3609 4.34279 12.5Z" fill="black" />
//       <path fillRule="evenodd" clipRule="evenodd" d="M15 12.5005C15 14.1573 13.6563 15.5 11.9995 15.5C10.3427 15.5 9 14.1573 9 12.5005C9 10.8427 10.3427 9.5 11.9995 9.5C13.6563 9.5 15 10.8427 15 12.5005Z" stroke="black" strokeWidth="1.2" />
//     </svg>
//   )
// }

const DownloadSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 15.25L12 4.75" stroke="black" strokeWidth="1.5" />
      <path d="M15.5 11.75L12 15.25L8.5 11.75" stroke="black" strokeWidth="1.5" />
      <path d="M5 13.3895V18.25C5 18.8023 5.44772 19.25 6 19.25L18 19.25C18.5523 19.25 19 18.8023 19 18.25L19 13.25" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}

const EraseSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.25 9.5C18.25 9.5 17.7262 15.9022 17.4194 18.9881C17.369 19.4947 16.9419 19.8757 16.4328 19.876L7.65189 19.8811C7.14227 19.8814 6.7143 19.5 6.66174 18.9931C6.34059 15.8953 5.75 9.5 5.75 9.5" stroke="#1A1B1D" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M19.5 6.55286H4.5" stroke="#1A1B1D" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M16.5 6.55288C15.4225 6.55288 15.0944 5.67353 15.042 4.75719C15.004 4.09153 14.5116 3.5 13.8448 3.5H10.1552C9.48842 3.5 8.99604 4.09153 8.95799 4.75719C8.90562 5.67353 8.57753 6.55288 7.5 6.55288" stroke="#1A1B1D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

const EditSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M17.3979 11.7995L12.0028 6.58966" stroke="black" strokeWidth="1.5" />
      <path d="M9.90656 19.5702L9.9056 20.3202C10.1098 20.3205 10.3053 20.2375 10.4469 20.0903L9.90656 19.5702ZM4.5028 14.3693L3.96242 13.8492C3.8208 13.9964 3.74531 14.1949 3.75338 14.399L4.5028 14.3693ZM4.70825 19.5636L3.95883 19.5932L3.98729 20.3127L4.70729 20.3136L4.70825 19.5636ZM14.6889 5.22794L18.6517 9.04193L19.6919 7.96118L15.7291 4.14719L14.6889 5.22794ZM18.6585 9.39542L9.36619 19.0501L10.4469 20.0903L19.7392 10.4356L18.6585 9.39542ZM5.04317 14.8894L14.3354 5.2347L13.2547 4.19452L3.96242 13.8492L5.04317 14.8894ZM9.90752 18.8202L4.70921 18.8136L4.70729 20.3136L9.9056 20.3202L9.90752 18.8202ZM5.45766 19.5339L5.25221 14.3397L3.75338 14.399L3.95883 19.5932L5.45766 19.5339ZM18.6517 9.04193C18.7512 9.13768 18.7542 9.29594 18.6585 9.39542L19.7392 10.4356C20.4094 9.73924 20.3882 8.6314 19.6919 7.96118L18.6517 9.04193ZM15.7291 4.14719C15.0328 3.47697 13.9249 3.49816 13.2547 4.19452L14.3354 5.2347C14.4312 5.13522 14.5895 5.13219 14.6889 5.22794L15.7291 4.14719Z" fill="black" />
      <path d="M20 19.6H13" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}