import { Tooltip } from "antd";
import { Observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { navigateToUrl } from "../../../../../../../common/utils/navigation.utils";
import { displayMoneyValue } from "../../../../../../../common/utils/number-display.utils";
import { useStore } from "../../../../../../app/data/root.store";
import { AvailableRemainingLoanAmountWrapper, CapitalMetricsValue, CapitalMetricsWrapper, LeveragedAssetsFilterButtonWrapper } from "../OverviewTab.styles";
import { AvailableLoanAmountEmptyState, DeathBenefitEmptyState, LeveragedAssetsEmptyState } from "../StatInfoBoxEmptyStates";
import { TooltipIcon } from "../../../../../../../assets/icons/common/common-icons";
import { FieldLabelToolTipTriggerWrapper } from "../../../../../../../common/components/form-displayer/FormDisplayer.styles";
import { ModifiedTooltip } from "../../../../../../../common/components/ModifiedTooltip/ModifiedTooltip";
import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { ActionLockedIconWithTooltip } from "../../../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip";
import { ROUTES } from "../../../../../../../common/constants/routes.const";

const OutstandingLoansLinkComp = ({ itemData, isHoldingView }) => {
  const { userStore, billingStore, uiStore } = useStore();

  const handleOutstandingLoanMenuItemClick = () => {
    if (userStore.isActionBlockedByRole('itemOverviewStatsEditOutstandingLoan')) {
      userStore.setShowLimitedSharedAccess(true);
    } else if (userStore.isDemoOrReadOnlyMode) {
      billingStore.setShowPricingTableModal(true);
    } else {
      if (itemData.collateralOf?.length) {
        uiStore.setShowConnectedCollateralsModal?.(true)
      } else {
        uiStore.setShowConnectCollateralModal?.(true);
      }
    }
  }

  const outstandingLoansAmount = itemData.outstanding_loans?.[0]?.actual || 0
  return (<Observer>{() => (
    <CapitalMetricsWrapper>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <ModifiedTooltip title={'Edit'}>
          <div onClick={handleOutstandingLoanMenuItemClick} style={{ display: 'flex', alignItems: "center", cursor: 'pointer', color:'#585D66' }}>
            Outstanding loans {displayMoneyValue(outstandingLoansAmount, itemData.currency, false, isHoldingView ? itemData.holdings : 100)}<PencilEditSvg />
          </div>
        </ModifiedTooltip>
        {userStore.isActionBlockedByRole('itemOverviewStatsEditOutstandingLoan') && <ActionLockedIconWithTooltip iconColor={'#9097A3'} isRoleBlocked={true} />}
      </div>
    </CapitalMetricsWrapper>
  )}</Observer>)
}

export const longTermSavingOverviewData = {
  value: { title: 'Current balance', lastUpdate: true },
  stats: [
    { title: 'Performance', subTitle: 'ROI', dataKey: 'roi', type: 'perc', multipleValues: true, hasMetricBars: true },
    {},
    { title: 'Total Contribution', subTitle: 'Including all contributions', dataKey: 'contributions', multipleValues: true },
    { title: 'Total Distribution', subTitle: 'Including all contributions', dataKey: 'distributions', multipleValues: true }
  ],
  visuals: []
}

const deathBenefitsEmptyStateProps = {
  showEmptyStateFn: (itemData) => { return false },//itemData?.deathBenefit === null },
  emptyStateComp: (itemData, isHoldingView) => { return <DeathBenefitEmptyState itemData={itemData} isHoldingView={isHoldingView} /> }
}

const leveragedAssetsEmptyStateProps = {
  showEmptyStateFn: (itemData) => { return !itemData.leverageOf?.length },
  emptyStateComp: (itemData, isHoldingView) => { return <LeveragedAssetsEmptyState itemData={itemData} isHoldingView={isHoldingView} />}
}

export const lifeInsuranceOverviewData = {
  value: { title: 'Current cash value', lastUpdate: true },
  stats: [
    {
      isArray: true, stats: [
        {
          title: 'Death benefits (Gross)', menuDescription: 'The full death benefits before any deductions', dataKey: 'death_benefit', multipleValues: true,
          ...deathBenefitsEmptyStateProps,
        },

        {
          title: 'Death benefits (Net)', menuDescription: 'Payout to beneficiaries after reducing outstanding loans', dataKey: 'death_benefit_net', multipleValues: true,
          ...deathBenefitsEmptyStateProps,
        }
      ],

    },
    // {},
    {
      title: 'Available loan amount', dataKey: 'availableCashValueBorrowPercentage', multipleValues: true,
      valueFn: (itemData, isIncognito, isHoldingView, selectedTimeFrameIndex) => {
        // console.log({...itemData});
        const availableAmount = itemData.available_loan_amount?.[selectedTimeFrameIndex]?.actual || 0
        return (
          <AvailableRemainingLoanAmountWrapper isNegative={availableAmount < 0}>
            {displayMoneyValue(availableAmount, itemData.currency, false, isHoldingView ? itemData.holdings : 100)}
            {availableAmount < 0 && <Tooltip title={<div>The available loan amount shouldn’t be negative. Check your <span style={{ fontWeight: 700 }}>outstanding loans</span> or review your <span style={{ fontWeight: 700 }}>cash value</span> amount to fix this issue</div>} placement="top" ><FieldLabelToolTipTriggerWrapper isNegative={availableAmount < 0}><TooltipIcon /></FieldLabelToolTipTriggerWrapper></Tooltip>}
          </AvailableRemainingLoanAmountWrapper>
        )
      },
      additionalDataFn: (itemData, selectedTimeFrameIndex, isHoldingView) => { return <OutstandingLoansLinkComp itemData={itemData} isHoldingView={isHoldingView} /> },
      showEmptyStateFn: () => false,
      emptyStateComp: (itemData, isHoldingView) => { return <AvailableLoanAmountEmptyState itemData={itemData} isHoldingView={isHoldingView} /> }
    },
    {
      title: 'Premium payments', dataKey: 'contributions', multipleValues: true,
      valueFn: (itemData, isIncognito, isHoldingView, selectedTimeFrameIndex) => {
        const premiumPayments = (itemData.base_payment?.[selectedTimeFrameIndex]?.actual || 0) + (itemData.paid_up_addition?.[selectedTimeFrameIndex]?.actual || 0);
        return (
          <div>
            {displayMoneyValue(premiumPayments, itemData.currency, false, isHoldingView ? itemData.holdings : 100)}
          </div>
        )
      },
      additionalDataFn: (itemData, selectedTimeFrameIndex, isHoldingView) => {
        return (<CapitalMetricsWrapper>
          <div>Base <CapitalMetricsValue>{displayMoneyValue(itemData.base_payment && itemData.base_payment[selectedTimeFrameIndex] ? itemData.base_payment[selectedTimeFrameIndex].actual : 0, itemData.currency, false, isHoldingView ? itemData.holdings : 100)}</CapitalMetricsValue></div>
          <div>Paid Up Addition <CapitalMetricsValue>{displayMoneyValue(itemData.paid_up_addition && itemData.paid_up_addition[selectedTimeFrameIndex] ? itemData.paid_up_addition[selectedTimeFrameIndex].actual : 0, itemData.currency, false, isHoldingView ? itemData.holdings : 100)}</CapitalMetricsValue></div>
        </CapitalMetricsWrapper>)
      }
    },
    {
      isArray: true, stats: [
        {
          title: 'Leveraged assets', menuDescription: 'The value of the assets that were funded by a loan against cash value', dataKey: 'leveraged_assets', multipleValues: true,
          ...leveragedAssetsEmptyStateProps,
          additionalDataFn: (itemData, selectedTimeFrameIndex, isHoldingView) => <LeveragedAssetsFilterButton itemData={itemData} />
        },
        { title: (item) => `Cash value growth (${CURRENCY_SYMBOL[item.currency] || '$'})`, menuDescription: 'The annual increase in value of your cash component', dataKey: 'cash_value_growth', multipleValues: true, defaultOpenIndex: 1 , hiddenIndexes : [0] },
        { title: 'Cash value IRR', menuDescription: 'The annual rate of return on your cash component', dataKey: 'irr', multipleValues: true, type: 'perc', },
      ],
    }
  ],
  visuals: []
}

const ArrowLinkSvg = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.87402 7.5C8.87402 7.5 12.2864 7.5 13.8322 7.5C13.8322 9.04575 13.8322 12.4581 13.8322 12.4581" stroke="#585D66" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 13.8359L13.6167 7.71926" stroke="#585D66" strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  )
}

const PencilEditSvg = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.6051 3.22014C13.4582 3.07415 13.2205 3.07462 13.0742 3.2212L7.68505 8.61988C7.64018 8.66483 7.60749 8.72042 7.59004 8.78143L7.0553 10.651C7.02291 10.7643 7.12811 10.8688 7.24149 10.8361L9.11315 10.295C9.17423 10.2774 9.22981 10.2445 9.27468 10.1996L13.2311 6.23618L11.6481 4.65314L12.1784 4.12281L13.761 5.70538L14.6638 4.80091C14.8102 4.65433 14.8097 4.41717 14.6628 4.27118L13.6051 3.22014Z" fill="#585D66" />
      <path d="M13.5 9V13.425C13.5 13.4664 13.4664 13.5 13.425 13.5H4.575C4.53358 13.5 4.5 13.4664 4.5 13.425L4.5 4.575C4.5 4.53358 4.53358 4.5 4.575 4.5H9" stroke="#585D66" strokeWidth="1.125" />
    </svg>
  )
}

const LeveragedAssetsFilterButton = ({ itemData }) => {
  const { filtersStore, wealthStore } = useStore();
  const history = useHistory();

  const handleApplyLeverageOfFilter = () => {
    if (!itemData.leverageOf?.length || !itemData.collateralOf?.length) return;
    filtersStore.handleClearFilterSelections();
    // filtersStore.handleSelectClass(3);
    itemData.collateralOf.forEach(collateral => {
      filtersStore.handleSelectLoanWithCollateral(collateral.liabilityId, true);
    })
    filtersStore.setAppliedFiltersFromSelection();
    wealthStore.refreshWealthData(false, history);
    navigateToUrl(history, ROUTES.wealthOverview.url);
  }

  return (
    <CapitalMetricsWrapper onClick={handleApplyLeverageOfFilter}>
      <ModifiedTooltip title={itemData.leverageOf?.length ? 'Go to filter page' : ''}>
        <LeveragedAssetsFilterButtonWrapper isClickable={itemData.leverageOf?.length}>{itemData.leverageOf?.length || 0} {`leveraged asset${itemData.leverageOf?.length === 1 ? '' : 's'}`}{itemData.leverageOf?.length ? <ArrowLinkSvg /> : null}</LeveragedAssetsFilterButtonWrapper>
      </ModifiedTooltip>
    </CapitalMetricsWrapper>
  )
}