import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import { colors } from "../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../common/styles/combos.styles";

export const SettingsMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  a {
    color: #fff;
  }
`;

export const SettingMenuHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
`;

export const HeaderUserIconWrapper = styled.div`
  ${flexCenter}
  min-width: 44px;
  width: 44px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid ${colors.darkGray2};
  margin-bottom: 12px;
`;

export const HeaderUserIconLetter = styled.div`
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  font-family: Domine;
  // margin-top: 2px
  color: #fff;
`;

export const UserNameText = styled.div`
font-size: 18px;
font-weight: 450;
color: #fff;
margin-bottom: 4px;
max-width: 100%;
word-break: break-word;
`;

export const UserEmailText = styled.div`
  font-size: 13px;
  font-weight: 450;
  color: ${colors.darkGray3};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SeparatorLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: #3F444F;
  // margin:8px 0;
`;

export const SectionTitle = styled.div`
  font-size: 12px;
  font-weight: 450;
  color: ${colors.gray2};
  letter-spacing: 0.5px;
`;



export const SettingsMenuIncognitoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #25272C;
  // padding-bottom: 8px;
  padding:6px 0;
  cursor: pointer;
  ${props=>props.disabled ? `opacity:0.4;cursor:not-allowed;` : ``}
`;

export const IncognitoTitle = styled.div``;

export const SettingsMenuLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 6px;
  border-radius: 6px;

  ${props => props.selected ? `background: ${colors.darkGray2}` : ``};
  &:hover {
    background: #2F3236;
  }
  ${props=>props.disabled ? `opacity:0.4;` : ``}
`;

export const SettingsMenuLogoutButton = styled.div`
  // border-top: 1px solid #25272C;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: #2F3236;
  }
`;