import { Player } from "@lottiefiles/react-lottie-player";
import { Observer } from 'mobx-react';
import React, { useRef } from 'react';
import { ActionLocked } from '../../../common/components/ActionLocked/ActionLocked';
import { ActionLockedIconWithTooltip } from '../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { useStore } from '../../app/data/root.store';
import { ActionsWrapper, AltTitle, LeftPanelWrapper, RightPanelWrapper, TitlesWrapper } from '../../onboarding/components/QuickStart/QuickStart.styles';
import heroAnimData from '../assets/hero-lottie.json';
import { AddAssetButton, HeroFlexWrapper, HeroTitle, HeroWrapper, LeftPanelContentWrapper } from './Reports.styles';

export function ReportsEmptyHero() {
  const { createItemStore, userStore, metadataStore, uiStore } = useStore();
  const lottieRef = useRef(null);
  const lottieDirRef = useRef(1);

  const manualItemOnClickHandler = () => {
    createItemStore.setChooseMethodStep();
    createItemStore.setShowModal(true);
  }

  const handleDirDone = () => {
    let nextDir;
    if (lottieDirRef.current) {
      nextDir = lottieDirRef.current === 1 ? -1 : 1;
      lottieDirRef.current = nextDir;
    }
    setTimeout(() => {
      if (lottieRef.current) {
        lottieRef.current.setPlayerDirection(nextDir);
        lottieRef.current.play();
      }
    }, 0)
  }

  return (<Observer>{() => (
    <HeroWrapper >
      <HeroFlexWrapper >
        <LeftPanelWrapper>
          <LeftPanelContentWrapper>
            <TitlesWrapper>
              <HeroTitle>Create, view, and export financial statements.</HeroTitle>
              {uiStore.isDesktopView && <AltTitle>{metadataStore.totalItemsCount > 0 || userStore.isFreeTier ? <>Create balance sheet and other custom<br />financial report in a click.</> : <>Once your portfolio is set up, you’ll be able to<br />create balance sheets and other financial reports.</>}</AltTitle>}
            </TitlesWrapper>
            {!userStore.isFreeTier && metadataStore.totalItemsCount === 0 &&
              <ActionsWrapper>
                <ActionLocked action={'quickStartHeroAddItem'}>
                  <AddAssetButton onClick={manualItemOnClickHandler}>Add asset{userStore.isReadOnlyMode && <ActionLockedIconWithTooltip />}</AddAssetButton>
                </ActionLocked>
              </ActionsWrapper>}

          </LeftPanelContentWrapper>
        </LeftPanelWrapper>
        <RightPanelWrapper>
          <Player
            ref={lottieRef}
            src={heroAnimData}
            //play={showLetsGoAnim}
            // onComplete={event => {
            //   console.log("A" , event);
            //   setPlayerDir(playerDir === 1 ? -1 : 1);
            // }}
            // loop={false}
            onEvent={event => {
              if (event === 'complete') {
                handleDirDone();
              }
            }}
            keepLastFrame
            autoplay={true}
            speed={0.5}
            // loop 
            style={{ width:  uiStore.isDesktopView ? '400px' : '100vw' }}
          />
        </RightPanelWrapper>
      </HeroFlexWrapper>

    </HeroWrapper>
  )}</Observer>)
}   