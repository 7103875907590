import styled from "@emotion/styled/macro";
import { colors } from "../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../common/styles/combos.styles";

export const DataBoxFormContentWrapper = styled.div`
    border: 1px solid ${colors.gray3};
    border-radius: 8px;
    margin-bottom: 12px;
    :hover {
        border: 1px solid ${colors.gray2};
    }
`;

export const FormHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding:24px;
    cursor: pointer;
    background: #fff;
    ${props => props.isOpen ? `
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: 1px solid ${colors.lightGray2};
        ` : `
        border-radius: 8px;
        `
    };
`;

export const HeaderTextWrapper = styled.div`
    
`;

export const HeaderIconWrapper = styled.div`
    ${flexCenter}
    svg{
        ${props => props.isOpen &&
        'transform: rotate(180deg);'}

        path{
            stroke: ${colors.darkGray1};
        }
    }
`;

export const FormTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.darkGray1};
`;