import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { InstitutionPlaceholderIcon, ModalCloseIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';

import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { useStore } from '../../../app/data/root.store';
import { ActionButton, CancelSurveyModalFooter, DescriptionText, InstitutionIconWrapper, LogoPlaceHolder, ModalCloseButtonWrapper, ModalContent, ModalHeader, TitleText } from './AfterReconnectModal.styles';

export const AfterReconnectModal = (props) => {
  const { onBackDropClick } = props;

  const { uiStore , userStore } = useStore();
  const { mixpanelTrack } = useAnalytics();

  useEffect(() => {
    mixpanelTrack('Popup Viewed', {
      destination : 'login required',
        //   location: 'Billing Page',
      title: `Wrong account synced`,
      cta_text: 'Login required',
      type: 'Wrong account synced modal',
      is_demo: userStore.isDemoMode
    })
  
    // return () => {}
  }, [mixpanelTrack, userStore.isDemoMode])



//   const handleTextareaOnChange = (e) => {
//     const updatedRemarks = e.target.value;
//     if (updatedRemarks === '') {
//       handleCheckboxOnClick(reasonsToLeave.length - 1);
//     } else {
//       const updatedReasonsToLeave = reasonsToLeave.map((reason, index) => index === reasonsToLeave.length - 1 ? { ...reason, checked: true } : reason);
//       setReasonsToLeave(updatedReasonsToLeave);
//     }
//     setRemarks(updatedRemarks);
//   }
   const { instName,  instLogoBase64 , instLogoUrl} = uiStore.reconnectErrorModal;
  return (<Observer>{() => (
    <BaseModal width={'500px'} height={'auto'} borderRadius={8} onBackDropClick={onBackDropClick}>

      <ModalHeader>
        <ModalCloseButtonWrapper onClick={onBackDropClick}>
          <ModalCloseIcon />
        </ModalCloseButtonWrapper>
        {/* Before you go, please help us improve Vyzer */}

        {/* <ModalAltText>
          We aim to make Vyzer the best product for investors.<br />
          Any feedback will help us improve our products and services.
        </ModalAltText> */}
      </ModalHeader>
      <ModalContent>
        <InstitutionIconWrapper>
                {instLogoBase64 ? <img alt={instName} src={'data:image/png;base64,' + instLogoBase64} /> :
                    instLogoUrl ? <img  alt={instName} src={instLogoUrl} /> :
                    <LogoPlaceHolder><InstitutionPlaceholderIcon /></LogoPlaceHolder>}
            </InstitutionIconWrapper>
        <TitleText>These login details belong to a different account.</TitleText>
        <DescriptionText>
          The username and password you entered are belong to a different account.<br/><br/>
          Please try again with the correct username and password.
        </DescriptionText>
        
      </ModalContent>

      <CancelSurveyModalFooter>
        {/* <ActionButton  >Sync again</ActionButton> */}
        <ActionButton isBack onClick={onBackDropClick}>Close</ActionButton>
        {/* <ActionButton  backgroundColor={'#BA4460'} whiteText>Cancel membership</ActionButton> */}
      </CancelSurveyModalFooter>

    </BaseModal>
  )}</Observer>)
}



    