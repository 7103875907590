import styled from '@emotion/styled/macro';
import { colors } from '../../../styles/theme.styles';

export const DistributionsDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 14px 16px;
`;

export const DistributionsDescriptionTitle = styled.div`
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
`;

export const ReturnCapitalRowWrapper = styled.div`
    display: flex;
    border-top: 1px solid rgba(88, 93, 102, 0.5);
    gap: 8px;
    padding: 8px 0;
`;

export const ReturnCapitalRowTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ReturnCapitalMainText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.gray2};
`;

export const ReturnCapitalAltText = styled(ReturnCapitalMainText)`
    font-weight: 450;
`;