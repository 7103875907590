import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../../common/styles/combos.styles";
import { colors, shadows } from "../../../../../common/styles/theme.styles";

export const ClassesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 16px 24px;
`;

export const ClassItemWrapper = styled.div``;

export const ClassRowWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
`;

export const ClassButton = styled.div`
  ${flexCenter}
  box-shadow: ${shadows.toggleShadow};
  border-radius: 26px;
  padding: 4px 8px;
  width: fit-content;
  min-width: 32px;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
  ${props=>props.isSelected ? `background:${props.color}; color:#fff;` : ``};
  border: 1px solid transparent;
  :hover{
    border: 1px solid #585D66;
  }
`;

export const ClassRowCategoriesButton = styled.div`
    ${flexCenter}
    cursor: pointer;
`;
export const ClassCategoriesButtonText = styled.span`
    font-size:12px;
    color:${colors.darkGray2};
    padding-right:4px;
`;

export const SelectedCategoriesCount = styled.div`
    margin-right:4px;
    ${flexCenter}
    background: ${colors.darkGray3};
    border-radius: 8px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    min-width: 16px;
    width: 16px;
    height:16px;
`;

export const ClassCategoriesCardWrapper = styled.div`
    border: 1px solid ${colors.gray3};
    border-radius: 8px;
    display:flex;
    flex-direction:column;
    margin-top:8px;
    .ant-checkbox-input, .ant-checkbox-inner {
            /* width: 16px;
            height: 16px; */
            :focus{
                border-color:${colors.darkGray3};
            }
        }
        .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color:${colors.darkGray3};
        }
        .ant-checkbox-checked{
            .ant-checkbox-inner {
                background-color:${colors.darkGray3};
                border-color:${colors.darkGray3};
                /* ::after{
                    transform: rotate(45deg) scale(1) translate(-56%, -58%);
                } */
            }
            ::after {
                border-color:${colors.darkGray3};
            }
        }
        :hover{
            .ant-checkbox-inner {
                border-color:${colors.darkGray3};
            }
        }
        &.ant-checkbox-wrapper-checked:hover{
            .ant-checkbox-inner {
                border-color:${colors.darkGray3};
            }
        }
`;

export const CategoriesCardHeader = styled.div`
    border-bottom:1px solid #DDE3EB;
    background-color:${colors.lightGray1};
    padding:8px;
    border-radius: 8px 8px 0px 0px;
`;

export const CategoriesCardContent = styled.div`
    display:flex;
    flex-direction:column;
    padding:8px;
    gap:8px;

    

`;

export const CategoriesCardList = styled.div``;

export const CategoryItem = styled.div``;