import { observer } from 'mobx-react';
import React from 'react';
import { CommonCustomClassIcon } from '../../../../../../../common/components/CommonCustomClassIcon/CommonCustomClassIcon';
import { startIntercomTourById } from '../../../../../../../common/utils/intercom.utils';
import { useStore } from '../../../../../../app/data/root.store';
import { CustomClassEmptyStateCTA, CustomClassEmptyStateLink, CustomClassEmptyStateSubTitle, CustomClassEmptyStateTitle, FiltersHeroEmptyStateWrapper } from '../FiltersHeroEmptyState/FiltersHeroEmptyState.styles';

export const FiltersHeroCustomClassEmptyState = observer(() => {
  const { filtersStore, uiStore } = useStore();
  const { id: customClassId, title: tagTitle , selectedSubClassesTitles, selectedCategoriesCount } = filtersStore.filterTags?.[0];
  return (
    <FiltersHeroEmptyStateWrapper>
      <CommonCustomClassIcon customClassId={customClassId} size={72} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 4, marginTop: 14 }}>
        <CustomClassEmptyStateTitle>No results for this filter</CustomClassEmptyStateTitle>
        <CustomClassEmptyStateSubTitle>How to edit items to “{selectedCategoriesCount ? selectedSubClassesTitles : tagTitle}” custom {selectedCategoriesCount ? 'sub-class' : 'class' }?</CustomClassEmptyStateSubTitle>
      </div>
      {uiStore.isDesktopView ? 
      <CustomClassEmptyStateCTA onClick={() => { startIntercomTourById('544685') }}>Learn about bulk edit</CustomClassEmptyStateCTA> :
      <CustomClassEmptyStateLink href="https://help.vyzer.co/en/articles/9614825-how-to-bulk-edit-items-in-vyzer-a-step-by-step-guide" target='_blank'>Learn about bulk edit</CustomClassEmptyStateLink>
      }
    </FiltersHeroEmptyStateWrapper>
  )
})