import React from 'react'
import { EmptyStateMessage, EmptyStateMessageWrapper, EmptyStateWrapper, NoTransactionsSkeletonWrapper } from './EmptyState.styles'
import { ReactComponent as NoTransactionsSkeleton } from './assets/no-transactions-skeleton.svg';
import { CactusIcon, EmptyStateStarsIcon } from '../../../../../assets/icons/common/common-icons';

export const NoCashflowThisMonthEmptyState = (props) => {
  const { hasActualTransactionsOnSelectedMonth } = props;

  return (
    <EmptyStateWrapper>
      {!hasActualTransactionsOnSelectedMonth ? <NoTransactionsSkeletonWrapper><NoTransactionsSkeleton /></NoTransactionsSkeletonWrapper> : null}
      <EmptyStateMessageWrapper isSmallGap={hasActualTransactionsOnSelectedMonth}>
        {!hasActualTransactionsOnSelectedMonth ? <CactusIcon /> : <EmptyStateStarsIcon />}
        <EmptyStateMessage>No expected cashflow event for<br />this month</EmptyStateMessage>
      </EmptyStateMessageWrapper>
    </EmptyStateWrapper>
  )
}
