import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../../common/styles/combos.styles";
import { colors, shadows } from "../../../../../common/styles/theme.styles";

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 16px 24px;
`;

export const DateParameterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DateButton = styled.div`
  ${flexCenter}
  box-shadow: ${shadows.toggleShadow};
  border-radius: 26px;
  padding: 4px 8px;
  width: fit-content;
  min-width: 32px;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  ${props => props.isSelected ? `background:${colors.darkGray3}; color:#fff;` : ``};
  user-select: none;
  border: 1px solid transparent;
  :hover{
    border: 1px solid #585D66;
  }
`;

export const WithCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const DatePickersWrapper = styled.div`
  ${flexCenter};
`;

export const LeftDatePickerWrapper = styled.div`
  .ant-picker {
    border-radius: 8px 0 0 8px;
    
    padding: 12px;
    transition: none;
  }

  .ant-picker-focused {
    box-shadow: none;
    border: 1px solid ${colors.darkGray1};
  }

  .ant-picker:hover {
    border: 1px solid ${colors.darkGray1};
  }
`;

export const RightDatePickerWrapper = styled(LeftDatePickerWrapper)`
  .ant-picker {
    border-radius: 0 8px 8px 0;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 8px;

  .ant-checkbox-input, .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    :focus{
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color:${colors.darkGray1};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
  }
  .ant-checkbox-checked{
    .ant-checkbox-inner {
        background-color:${colors.darkGray1};
        border-color:${colors.darkGray1};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
        border-radius: 4px;
        ::after{
          transform: rotate(45deg) scale(1) translate(-56%, -58%);
        }
    }
    ::after {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  :hover{
    .ant-checkbox-inner {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  &.ant-checkbox-wrapper-checked:hover{
    .ant-checkbox-inner {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
`