import React , { useState , useEffect } from 'react';
import moment from 'moment';
import { useStore } from '../../../../app/data/root.store';
import { Wrapper, 
    //FrequencyIcon, 
    TextWrapper, Title, DateText, 
    SwitchWrapper, ActionMenuWrapper, ValueWrapper, ThreeDotsIconWrapper, SimulationRowActionMenuWrapper, SimulationRowActionMenuContent, SimulationRowActionMenuItem, PeriodWrapper, Period, OverdueBadgeWrapper } from './CashFlowSimulationRow.styles';
import { displayMoneyValue } from '../../../../../common/utils/number-display.utils';
import { Switch, Dropdown, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { ThreeDots2Icon } from '../../../../../assets/icons/common/common-icons';
import { ModifiedTooltip } from '../../../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { ActionLockedIconWithTooltip } from '../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';

export function CashFlowSimulationRow(props) {
    const {cashFlowStore,userStore,billingStore} = useStore();
    const { confirm } = Modal;

    const {id, description, isIncognito, isCalculates} = props;
    const showHideSimulation = (simulationId,isChecked) => {
        setSwitchStatus(isChecked);
        cashFlowStore.toggleSimulation(simulationId,isChecked);
    }
    
    const [switchStatus,setSwitchStatus] = useState(false);
    useEffect(() => {
        setSwitchStatus(isCalculates)
        return () => {}
    }, [isCalculates,setSwitchStatus])
    

    const showDeleteConfirmation = () => {
        if (userStore?.isActionBlockedByRole('deleteScenario')) {
            userStore?.setShowLimitedSharedAccess(true);
        } else {
            confirm({
              title: `Do you want to delete ${description} scenario?`,
              icon: <ExclamationCircleOutlined />,
              onOk() {
                cashFlowStore.deleteSimulation(id);
              },
              onCancel() {},
            });
        }
    }

    const editSimulation = () => {
        if (userStore?.isActionBlockedByPaymentStatus('editScenario')) {
            billingStore.setShowPricingTableModal(true);
        } else {
            props.onEditSimulation?.();
        }
    }

    return (
        <Wrapper onClick={editSimulation}>
            {/* <FrequencyIcon></FrequencyIcon> */}
            <TextWrapper>
                <Title>
                    {props.description}
                    {props.isOverdue ? <ModifiedTooltip title='This scenario has passed'> <OverdueBadgeWrapper><OverdueCalendarSvg />Overdue</OverdueBadgeWrapper> </ModifiedTooltip> : null}
                </Title>
                <PeriodWrapper>
                    <Period>{props.frequency}:</Period>
                    <DateText>{moment(props.startDate).format('MMMM yyyy')}</DateText>
                    {props.frequency !== 'One time' ? <DateText>- {moment(props.endDate).format('MMMM yyyy')}</DateText> : null}
                </PeriodWrapper>
            </TextWrapper>
            <ValueWrapper cashIn={props.amount>0}>
                {/* <Amount> */}
                {displayMoneyValue(props.amount,props.currency,isIncognito)}
                {/* </Amount> */}
            </ValueWrapper>
            <SwitchWrapper onClick={(e)=>{e.stopPropagation()}}>
                <Switch 
                    checked={switchStatus}
                    size='small'
                    disabled={cashFlowStore.isLoadingSimulationsChanges || userStore?.isActionBlockedByRole('editScenario')}
                    // checkedChildren={<CheckOutlined />}
                    // unCheckedChildren={<CloseOutlined />}
                    onChange={(checked)=>{showHideSimulation(props.id,checked)}}
                ></Switch>
            </SwitchWrapper>
            <ActionMenuWrapper onClick={(e)=>{e.stopPropagation()}}>
                <Dropdown 
                overlay={SimulationRowActionMenu({showDeleteConfirmation,editSimulation,userStore})} trigger={['click']} placement="topRight" 
                overlayClassName="asset-action-menu-wrapper"
                getPopupContainer={() => document.getElementById('cashflowPageWrapper')}
                >
                        {/* <div className="action-button" onClick={e => e.preventDefault()}> */}
                            {/* <EllipsisOutlined rotate={90}/> */}
                        {/* </div> */}
                        <ThreeDotsIconWrapper>
                            <ThreeDots2Icon />
                        </ThreeDotsIconWrapper>

                </Dropdown>
            </ActionMenuWrapper>
        </Wrapper>
    )
}

function SimulationRowActionMenu(props){
    return (
         <SimulationRowActionMenuWrapper>
            <SimulationRowActionMenuContent>
                <SimulationRowActionMenuItem onClick={()=>{props.editSimulation?.()}}>
                     <EditOutlined />Edit
                </SimulationRowActionMenuItem>
                <SimulationRowActionMenuItem onClick={()=>{props.showDeleteConfirmation?.()}}>
                     <DeleteOutlined />Delete {props.userStore?.isActionBlockedByRole('deleteScenario') && <ActionLockedIconWithTooltip marginLeft={'auto'} isRoleBlocked={true} />}
                </SimulationRowActionMenuItem>
            </SimulationRowActionMenuContent>
      </SimulationRowActionMenuWrapper>
    )
}

const OverdueCalendarSvg = () => {
    const pathFill = '#E43A04';
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.74854 1.91844C1.74854 1.854 1.80077 1.80176 1.86521 1.80176H12.1328C12.1973 1.80176 12.2495 1.854 12.2495 1.91844V11.136C12.2495 11.2004 12.1973 11.2526 12.1328 11.2526H1.86521C1.80077 11.2526 1.74854 11.2004 1.74854 11.136L1.74854 1.91844ZM3.10062 3.14526C3.03618 3.14526 2.98394 3.1975 2.98394 3.26194V9.79245C2.98394 9.85689 3.03618 9.90913 3.10062 9.90913H10.8974C10.9619 9.90913 11.0141 9.85689 11.0141 9.79245V3.26194C11.0141 3.1975 10.9619 3.14526 10.8974 3.14526H3.10062Z" fill={pathFill} />
            <path d="M11.8005 4.80029L2.19965 4.80029L2.19965 2.40007L11.8005 2.40007L11.8005 4.80029Z" fill={pathFill} />
            <path d="M10.5676 0.000976562L10.5676 3.00126L9.1675 3.00126L9.1675 0.000976522L10.5676 0.000976562Z" fill={pathFill} />
            <path d="M4.90015 0.000976562L4.90015 3.00126L3.50002 3.00126L3.50002 0.000976522L4.90015 0.000976562Z" fill={pathFill} />
            <path d="M10.5676 0.000976562L10.5676 1.50112L9.1675 1.50112L9.1675 0.000976522L10.5676 0.000976562Z" fill={pathFill} />
            <path d="M4.90015 0.000976562L4.90015 1.50112L3.50002 1.50112L3.50002 0.000976522L4.90015 0.000976562Z" fill={pathFill} />
        </svg>
    )
}