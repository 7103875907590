import styled from '@emotion/styled/macro'
import { animateSpin } from '../../../../common/styles/animations.styles';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors, mobileVars } from '../../../../common/styles/theme.styles';

export const HeaderWrapper = styled.div`

    display: flex;
    // grid-area: header;
    align-items: center;
    padding: 8px;
    border-bottom:1px solid ${mobileVars.headerFooterBorderColor};
    background-color:${mobileVars.blackBackgroundColor};
    border-bottom: 1px solid #25272C;
    width: 100%;
    
    //justify-content: center;
    align-items: center;
    
    height:${mobileVars.headerHeight};
    min-height:${mobileVars.headerHeight};
    position: relative;
    
`
export const HeaderButtonWrapper = styled.div`
    height: 48px;
    width: 48px;
    ${flexCenter};
    cursor: pointer;
`;

export const MenuButtonWrapper = styled(HeaderButtonWrapper)`
    margin-right:6px;
    z-index: 3;
    
    line{
        transition:transform 0.2s;
        ${props=>props.isOpen ? `
            :nth-of-type(1){
                transform: translate(8px,0px) rotate(45deg) ;
            }
            :nth-of-type(2){
                transform: translateX(24px) scaleX(0);
            }
            :nth-of-type(3){
                transform: translate(-12px,10px) rotate(-45deg) ;
            }
        ` : `
        
        `}
        
    }
`;

export const LogoWrapper = styled.div`z-index: 99999;`;
export const FlexSpace = styled.div`flex:1`;

export const SignUpButton = styled.div`
    background-color:black;
    color:#fff;
    border:1px solid #000;
    padding: 8px 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height:auto;
    text-align: center;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition:all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 6px;
    &:hover{background-color:#211c52;}
    right: 24px;
    position: absolute;
`


export const SearchBoxWrapper = styled.div`
    display:flex;
    flex:1;
    justify-content:center;
    // padding-left:90px;
    padding-left:138px;
    @media (max-width: 1440px) {
        // padding-left:0;
        padding-left:calc(50vw - 585px);
    }
    @media (max-width: 1160px) {
        padding-left:0;
    }
`;


export const BackButtonWrapper = styled.div`
    width:24px;
    ${flexCenter}
    margin-right:16px;
    cursor: pointer;
`;

// export const LeftContentWrapper = styled.div``

export const SearchBox = styled.div`
    max-width: 380px;
    flex:1;
    /* display:flex; */
    /* align-items:center; */
`;

export const HeaderActions = styled.div`
    display:flex; 
    flex:1; 
    justify-content:flex-end; 
    align-items:center;
    gap:8px;
`;

export const HeaderButton = styled.div`
    ${flexCenter};
    height:32px;
    font-size:16px;
    font-weight:450;
    outline:none;
    border:none;
    border-radius:6px;
    cursor:pointer;
    gap: 4px;
    padding: 0 8px;
`;

export const FiltersButton = styled(HeaderButton)`
    // background-color:${colors.lightGray2};
    // color:${colors.darkGray1};
    // :hover{
    //     background-color:${colors.gray3};
    // }
    // border-radius: 26px;
    color:#FFFFFF;
    border-radius: 12px;
    height:36px;
    padding: 0 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    border: 1px solid #3B3F46;
`;

export const SearchButton = styled(FiltersButton)`
    padding: 0 6px;
    width:38px;
    min-width: 38px;
    margin-left:8px;
`

export const CreateButton = styled(HeaderButton)`
    height: 32px;
    ${props=>props.createBlack ? `
        background-color:${colors.darkGray1};
        color:#fff;
        svg path{stroke:#fff;}
        :hover{
            background-color:${colors.gray3};
            color:${colors.darkGray1};
            svg path{stroke:${colors.darkGray1};}
        }
    ` : `
        background-color:${colors.lightGray1};
        color:${colors.darkGray1};
        :hover{
            background-color:${colors.gray3};
        }
    `}
    padding: 10px 20px 10px 18px;  
`;

export const UpgradeButton = styled(HeaderButton)`
    height: 32px;
    background-color:${colors.upgradeOrange};
    color:#fff;
    :hover{
        background-color:${colors.upgradeOrangeHover};
    }
    padding: 10px 16px 10px 12px;
`;

export const NotificationsButtonWrapper = styled.div`
    svg path{stroke:#fff;}
    box-shadow: ${props=>props.isOpenState ? '0px 2px 4px rgb(0 0 0 / 4%), 0px 0px 2px rgb(0 0 0 / 16%)' : ''} ;
    border-radius: 6px;
    // padding: 12px;
    width:42px;
    height:32px;
    ${flexCenter};
    font-size:24px;
    font-weight:300;
    color:${colors.darkGray1};
    position: relative;
    cursor:pointer;
    :hover{
        box-shadow: 0px 2px 4px rgb(0 0 0 / 4%), 0px 0px 2px rgb(0 0 0 / 16%);
    }
    .userMenuArrowIcon{
        min-width:16px;
        margin-right:-3px;
    }
    ${props=>props.isOpenState ? `
        background-color:${colors.darkGray1};
        svg path{stroke:#fff;}
    ` : ``}
`;


export const NotificationsBellIconBadge = styled.div`
    position: absolute;
    top: 2px;
    right: ${props=>props.isInMenu ? 0 : 6}px;
    width: 12px;
    height: 12px;
    background-color: ${colors.redNotification};
    border: 1px solid ${props=>props.blackBorder ? '#000' : '#fff'};
    border-radius: 20px;
`;

export const UserMenuButton = styled.div`
    ${flexCenter};
    font-size:24px;
    font-weight:300;
    color:${colors.darkGray1};
`;

export const LinksStatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
    .header-status-link{
        padding:0px 16px;
        font-weight: 500;
        font-size: 14px;
        color: ${props=> props.white ? '#fff' : '#848C9A'};
        cursor: pointer;
        transition: all 0.2s;
        margin-top: 0px;
        display: flex;
        &:hover{
            color: ${props=> props.white ? '#e6eeff' : '#2B3549'};
        }
        &.selected{
            color: ${props=> props.white ? '#fff' : '#2B3549'};
            transform: scale(1.15) translateY(-0.5px);
        }
    }
`

export const ActionsWrapper = styled.div`
    width:200px;
    display: flex;
    padding-right:12px;
    justify-content: flex-end;
    align-items:center;
`;

export const ActionButton = styled.div`
    height: 32px;
    width: 32px;
    background-color: #4e5156;
    color: #fff;
    margin:0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    path{
        stroke: #fff;
    }
    .user-letter{
        font-size: 16px;
        text-transform: uppercase;
    }
`;

export const IncognitoSwitchWrapper = styled.div`
margin-right:8px;
.ant-switch{
    border: 1px solid #fff;
    height: 24px;
    background-color: #4e5156;
} 
.ant-switch-checked {
    background-color: #4e5156;
}
/* .ant-switch-handle::before{
    border-radius:0px;
} */
`;

export const MenuWrapper = styled.div`
    display: flex;
    flex-direction: row;
    .header-status-link{
        padding:0px 16px;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        opacity:0.4;
        // color: ${props=> props.white ? '#fff' : '#848C9A'};
        cursor: pointer;
        transition: all 0.2s;
        margin-top: 0px;
        display: flex;
        align-items:center;
        .link-text{
            padding-left:8px;
        }
        path{
            transition: stroke 0.2s;
            stroke: #848C9A;
        }
        &:hover{
            opacity:1;
            /* color: #2B3549;
            path{
                stroke: #2B3549;
            } */
            //color: ${props=> props.white ? '#e6eeff' : '#2B3549'};
        }
        &.selected{
            opacity:1;
            // color: #2B3549;
            // color: ${props=> props.white ? '#fff' : '#2B3549'};
            transform: scale(1.15) translateY(-0.5px);
            /* path{
                stroke: #2B3549;
            } */
        }
        
    }
`;

export const SyncRedIconWrapper = styled.div`
    svg{
        path{
            fill:#EB8787;
        }
    }
    animation-name: ${animateSpin};
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    ${flexCenter};
`;


export const NotificationsMenuWrapper = styled.div`
    /* width:400px; */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    /* margin-right:-112px; */
`;

export const CreateMenuItemWrapper = styled.div`
    ${flexCenter};
    padding:8px 0px;
`;
export const CreateMenuItemIcon = styled.div`
    width: 80px;
    ${flexCenter};
`;
export const CreateMenuItemTextContent = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
`;
export const CreateMenuItemBadge = styled.div`
    background-color: #ECEDA6;
    color: ${colors.darkGray1};
    box-shadow: 0px 1.23288px 2.46575px rgba(255, 255, 255, 0.04), 0px 0px 1.23288px rgba(255, 255, 255, 0.16);
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    padding:4px 8px;
    border-radius: 3px;
    margin-bottom:4px;
`;
export const RecommendedItemBadge = styled(CreateMenuItemBadge)`
    background-color: #A6EDCF;
`;
export const NewItemBadge = styled(CreateMenuItemBadge)`
    background-color: #F7C7E4;
`;
export const CreateMenuItemTitle = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    font-weight:500;
    line-height:19px;
    margin-bottom:4px;
`;
export const CreateMenuItemSubTitle = styled.div`
    color:${colors.darkGray3};
    font-size: 12px;
`;

export const CreateMenuAddManual = styled.div`
    ${flexCenter};
    border-top:1px solid ${colors.gray3};
    color:${colors.darkGray1};
    font-size:14px;
    width:100%;
    padding:16px;
    text-align:center;
`;




