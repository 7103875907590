export function getCashFlowEventData(formValues, is_demo) {
  const { 
    monthlyExpenses: monthly_cost,
    monthlyExpensesCurrency: monthly_cost_currency,
    monthlyIncome: salary,
    monthlyIncomeCurrency: salary_currency,
  } = formValues;
  
  return {
    monthly_cost,
    monthly_cost_currency,
    salary,
    salary_currency,
    is_demo
  }
}