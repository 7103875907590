import { Observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ChartComponent } from '../../../../../common/components/charts/ChartComponent';
import { displayMoneyFormatter, percentageDisplay } from '../../../../../common/utils/number-display.utils';
import { useStore } from '../../../../app/data/root.store';
import { PieTextContent, PieWrapper } from '../../../../wealth/components/charts/FiltersPieChart/FiltersPieChart.styles';
import { PieTextBigValue } from '../CommunityPieChart/CommunityPieChart.styles';
import { communityChartTooltip } from '../CommunityPieChart/CommunityPieChartTooltip';
import { ClassTitleText, PieTextDescription } from './CommunityFiltersPieChart.styles';

export function CommunityFiltersPieChart(props) {
  
  const { communityStore , metadataStore } = useStore();
  const [hoverData, setHoverData] = useState({ index: null });
  const [chartOptions, setChartOptions] = useState({ cutout: '90%' });
  const isPieWithLiabilities = false; // wealthStore?.pieFiltersData.datasets.length > 1;
  const containerRef = useRef();
  const textRef = useRef();
  const [textSize, setTextSize] = useState(32);

  const getTooltipItem = useCallback((datasetIndex, dataIndex) => {
    // console.log({...wealthStore.selectedClass});
    const relevantCategoryId = communityStore.chartClassCategories[dataIndex];
    const relevantCategory = metadataStore.categoriesObj[relevantCategoryId];
    const relevantClass = metadataStore.classesObj[relevantCategory.classId];
    const percentageFromClass = communityStore.selectedClass.categoriesAllAllocationsValues[relevantCategoryId] /  communityStore.selectedClass.totalValue * 100;

    return {
      classIcon: relevantClass.icon,
      classColor: relevantClass.color,
      percentage : percentageFromClass, // relevantItem.percentageOfAssets,
      title: relevantCategory.title,
      description: 'Of community`s ' + relevantClass.title + ' value',
      // isSingleClass: true,
      show: true
    }
  }, [ communityStore, metadataStore])

  const getChartConfig = useCallback(() => {
    return {
      maintainAspectRatio: true,
      cutout: (isPieWithLiabilities ? 80 : 90) + '%',
      onHover: (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        if (chartElement.length) {

        }
        setHoverData({
              show: false,
              isHoveredOnce: true
            })
      },
      plugins: {
        tooltip: communityChartTooltip(getTooltipItem),
      }
    }
  }, [ isPieWithLiabilities, getTooltipItem])

  useEffect(() => {
    setChartOptions(getChartConfig());
    // return () => {}
  }, [getChartConfig])

  const getBigPieTitle = () => {
    if (communityStore.selectedClass?.id === 10) {
      return 'Total balance'
    }
    return `Community’s total ${communityStore.selectedClass?.title} value`
  }

  useEffect(() => {
    const containerElement = containerRef.current;
    const textElement = textRef.current;

    if (containerElement && textElement) {
      const availableWidth = containerElement.clientWidth - 80;
      const textWidth = textElement.scrollWidth;
      
      setTextSize(textWidth > availableWidth ? (availableWidth / textWidth) * 32 : 32);
    }
  }, []);

  return (<Observer>{() => (
    <PieWrapper>
      <ChartComponent type='doughnut' data={communityStore.chartSingleClassData} options={chartOptions} />
      <PieTextContent contentTop={props.contentTop} ref={containerRef}>
        <ClassTitleText color={hoverData.show ? hoverData.color : null}>
          {hoverData.show ? hoverData.title : getBigPieTitle()}
        </ClassTitleText>
        <PieTextBigValue style={{fontSize: `${textSize}px`}} ref={textRef}>{displayMoneyFormatter(communityStore.selectedClass.totalValue,'USD')}</PieTextBigValue>
        <PieTextDescription color={communityStore.selectedClass?.color}>{percentageDisplay(communityStore.selectedClassFromList?.percentageOfAssets)} of community’s total value</PieTextDescription>
      </PieTextContent>
    </PieWrapper>
  )}</Observer>)
}