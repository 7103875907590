import React, { useRef, useEffect } from 'react';
import { CountUp } from 'countup.js';
import { CURRENCY_SYMBOL } from '../../constants/currency-symbol.const';


export function CountAnimation(props) {
    const countUpRef = useRef(null);
    const prevCount = useRef(0);
    const countUpInstanceRef = useRef(null);
    

    useEffect(() => {
        
        
        return () => {
            // cleanup
        }
    },[]);
    
    useEffect(() => {
        // console.log(props.value,countUpInstanceRef.current);
        const duration = props.noAnim ? 0 : props.duration ? props.duration :  ( props.value > prevCount.current ?  1.5 : 0.5 );
        countUpInstanceRef.current = new CountUp(countUpRef.current, props.value ,{
            duration,
            startVal: prevCount.current,
            ...(props.currency && CURRENCY_SYMBOL[props.currency] ? {prefix: CURRENCY_SYMBOL[props.currency]} : {})
        });
       
        // console.log(duration);
        prevCount.current = props.value;
        // countUpInstanceRef.current.duration = duration;
        if (!countUpInstanceRef.current.error) {
            countUpInstanceRef.current.start();
            // countUpInstanceRef.current.update(props.value);
          } else {
            console.log(countUpInstanceRef.current.error);
          }
        
        return () => {
            countUpInstanceRef.current.reset();
            // cleanup
        }
    },[
        props.value, props.currency, props.noAnim, props.duration
    ])

    return (
        props.width ? 
        <span style={{width: props.width, display: 'inline-block', ...props.style}} ref={countUpRef}></span> : 
        <span ref={countUpRef} style={props.style} ></span>
    )
}

