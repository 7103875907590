/**
 * Calculator Monthly payment by Spitzer
 * @param loan positive number
 * @param interest number between 0 to 1
 * @param months the loan's duration
 * @returns monthly payment (number)
 */
export const calcMonthlyPayment = (loanAmount, interest, months) => {
    let R = interest / 12
    let Mi = loanAmount * (interest === 0 ? 1 : R)
    let Rp = Math.pow((1 + R), -months)
    let denominator = 1 - Rp
    return Mi / (interest === 0 ? months : denominator)
}


/**
 * Calculator months left for complete the loan by Spitzer
 * @param loan  positive number
 * @param interest number between 0 to 1
 * @param monthlyPayment the monthly payment value
 * @returns the loan's duration in months (number)
 */
export const calcMonthLeft = (remainingLoanAmount, interest, monthlyPayment) => {
    if (12 * monthlyPayment - remainingLoanAmount * interest <= 0) {
        return null
    }
    let b = (12 * monthlyPayment) / (12 * monthlyPayment - remainingLoanAmount * interest)
    let a = (12 + interest) / 12
    return Math.log(b) / Math.log(a)
}