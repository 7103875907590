import moment from "moment";
import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { disabledMonthBefore, disabledMoreThanToday } from "../../../../../common/utils/dates.utils";
import { calcMonthLeft, calcMonthlyPayment } from "../../../../../common/utils/liabilities.utils";
import { ChangeLoanTermsModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/ChangeLoanTermsModal";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { isBetweenZeroAndHundred, isNotNegative, isRequired } from "../../input-validations";
import { containerRow } from "../../common-fields/container-field.const";
import { isNullOrUndefined } from "../../../../../common/utils/object.utils";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";

let start_date;

export const createAmortizationLoanAddItemSections = (isEditForm) => {
    return ({updateValue,updateField}) => {
        const { loans } = getTranslations().new_item_modal;
        const { asset_fields } = getTranslations().common;
        return [
            {
                title:'General info',
                rows:[
                    ...masterInstitutionInputRows(loans.NAME_LABEL,loans.NAME_DESCRIPTION, 1, false, updateValue),
                    {fields:[
                        {key:'title',label:'Loan name', description: 'This is the name you’ll use to search and find this loan on Vyzer.', validations:[isRequired]},
                    ]},
                    {fields:[
                        {key:'startDate', label: 'Date you received the loan',
                        description: 'The date you received the cash', 
                        validations:[isRequired],
                        // defaultValueFn: ()=>{return new Date()},
                        disabledDate: disabledMoreThanToday,
                        onChange:({value})=>{
                            start_date = value;
                            updateValue('principalFirstRepayment', moment(value).add(1, 'M'));
                            updateValue('interestFirstPayment', moment(value).add(1, 'M').toDate());
                            updateField('amortization', {startDate: moment(value)})
                        }}
                    ]},
                    {fields:[
                        {key:'value', type: 'numberWithHoldings', validations:[isRequired], label: loans.VALUE_LABEL,
                        onBlur:({value})=>{
                            updateValue('cashReceived',value);
                        },
                        description: loans.VALUE_DESCRIPTION,generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                        }},
                        {key:'currency',label:' ',size:0.3 ,onChange:({value})=>{
                            updateField('value',{prefix:CURRENCY_SYMBOL[value] || ''});
                            updateValue('cashInvestedCurrency',value);
                            updateField('cashInvested',{prefix:CURRENCY_SYMBOL[value] || ''});
                            updateValue('cashReceivedCurrency',value);
                            updateField('cashReceived',{prefix:CURRENCY_SYMBOL[value] || ''});
                            updateField('fixedMonthlyPayment',{prefix:CURRENCY_SYMBOL[value] || '',placeholder:CURRENCY_SYMBOL[value] || ''});
                            
                        }}
                    ]},
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : [
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden:true,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                    onBlur:({value})=>{
                                        updateField('value',{holdings:value || ''});
                                        updateField('cashInvested',{holdings:value || ''});
                                        updateField('cashReceived',{holdings:value || ''});
                                    },
                                    generatePropsFn : (values)=>{
                                        return {isHidden: values.holdings === 100}
                                    }
                                }
                            ]
                        },
                        {
                            fields:[
                                {type:'checkbox', key:'isPartialHolding',checkboxLabel: 'This loan is 100% owned by me', description: 'If you are co-owners of this loan, uncheck this box' ,
                                fieldCustomStyle: {marginTop:'-24px'},
                                defaultValue: true,
                                    onChange: ({value})=>{
                                        const newHoldingValue = value ? 100 : 50;
                                        updateValue('holdings',newHoldingValue);
                                        updateField('holdings',{isHidden:value});
                                        updateField('value',{holdings:newHoldingValue});
                                        updateField('cashInvested',{holdings:newHoldingValue});
                                        updateField('cashReceived',{holdings:newHoldingValue});
                                    }
                                },
                            ]
                        },
                        ]),
                ]
            },{
                title: 'Loan terms', 
                rows:[
                    {fields:[
                        {
                            key:'interest', 
                            label: isEditForm ? asset_fields.AMORTIZATION_INTEREST_LABEL : loans.AMORTIZATION_INTEREST_LABEL,
                            validations:[isRequired, isNotNegative],
                            specificDecimalScale: 3,
                            isLocked: isEditForm,
                            showHistory: false,
                            UnlockComponent: ChangeLoanTermsModal,
                            onUnlock: () => {
                                updateField('fixedMonthlyPayment', {isLocked: false})
                                updateField('amortization', {isLocked: false})
                            },
                            onBlur:({value,formValues}) => {
                                const { amount_borrowed, amortization } = formValues;
                                if (amount_borrowed && amount_borrowed[0].actual && amortization.amount) {
                                    const monthlyPayment = calcMonthlyPayment(amount_borrowed[0].actual , value/100 ,
                                    amortization.amount * (amortization.period === 'Annually' ? 12 : 1))
                                    updateValue('fixedMonthlyPayment',monthlyPayment);
                                } else if(!isNullOrUndefined(value) && formValues.amortization.amount){
                                    const monthlyPayment = calcMonthlyPayment(formValues.value , value/100 ,
                                    amortization.amount * (amortization.period === 'Annually' ? 12 : 1))
                                    updateValue('fixedMonthlyPayment',monthlyPayment);
                                }
                            }
                        }
                    ]},
                    {fields:[
                        {
                            key:'amortization', 
                            label: 'Amortization period', 
                            description: loans.AMORTIZATION_DESCRIPTION, 
                            type:'amortizationPeriod', 
                            UnlockComponent: ChangeLoanTermsModal,
                            isLocked: isEditForm,
                            hideEditLockIcon: true,
                            onUnlock: () => {
                                updateField('interest', {isLocked: false})
                                updateField('fixedMonthlyPayment', {isLocked: false})
                            },
                            onChange:({value, formValues}) => {
                                const { amount_borrowed, interest } = formValues;
                                if (!value.amount) return;
                                if ( amount_borrowed && amount_borrowed[0].actual && !isNullOrUndefined(interest) ) {
                                    const monthlyPayment = calcMonthlyPayment(amount_borrowed[0].actual , interest/100 , value.amount * (value.period === 'Annually' ? 12 : 1))
                                    updateValue('fixedMonthlyPayment',monthlyPayment);
                                } else if(!isNullOrUndefined(interest)) {
                                    const monthlyPayment = calcMonthlyPayment(formValues.value , interest/100 , value.amount * (value.period === 'Annually' ? 12 : 1))
                                    updateValue('fixedMonthlyPayment',monthlyPayment);
                                }
                                updateValue('expectedEndDate', moment(formValues.startDate).add(value.amount, value.period === 'Annually' ? 'Y' : 'M').toDate() , true )
                                updateValue('amortizationMonths', value.period === "Annually" ? value.amount * 12 : value.amount);
                            },
                            defaultValueFn: (values) => {
                                const months = values?.amortizationMonths;
                                if (!months) return {amount: null, period:'Annually'};
                                const isYears = months % 12 === 0 ? months / 12 : false;

                                return {
                                    amount: isYears ? isYears : months,
                                    period: isYears ? 'Annually' : 'Monthly',
                                };
                            },
                            generatePropsFn: (formValues) => {
                                return { startDate: moment(formValues.startDate) }
                            },
                            validations: [
                                {
                                    fn: (value) => {
                                        if (!Object.values(value).length) {
                                            return false;
                                        }
                                        const isValid = value.amount > 0;
                                        return isValid
                                    },
                                    message: `Required`
                                },
                            ]}
                    ]},
                    {fields:[
                        {
                            key:'expectedEndDate', 
                            label:'Expected payoff date', 
                            description: loans.EXPECTED_END_DATE_DESCRIPTION, 
                            validations:[isRequired],
                            disabledDate: (current) => disabledMonthBefore(current, moment(start_date).add(1, 'month')),
                        }
                    ]},
                    {fields:[{
                        key:'fixedMonthlyPayment',
                        isLocked: isEditForm,
                        onUnlock: () => {
                            updateField('interest', {isLocked: false})
                            updateField('amortization', {isLocked: false})
                        },
                        UnlockComponent: ChangeLoanTermsModal,
                        validations:[ 
                            // isRequired,
                        {
                            fn: (value, formValues) => {
                                const isValid = ((!!value || value === 0) && (''+value).trim() !== '') || (calcMonthLeft(formValues.value, formValues.interest / 100 , value) > 0) || (isEditForm && formValues.interest === 0);
                                return isValid;
                            },
                            message: `Required`,
                            bottomErrorMessage: (value, formValues) => {
                                return `Invalid loan terms conditions.\nThe monthly interest payment (as calculated) cannot be above the fixed monthly payment (of both principal + interest).\nPlease change the ‘Annual interest rate’ or the ‘Fixed monthly payment’`
                            },
                        }
                        ],
                        decimalScale: 2,
                        onBlur:({value, formValues}) => {
                            if(value && formValues.interest) {
                                let numberOfPeriodToReturn =  Math.ceil(calcMonthLeft(formValues.value,formValues.interest / 100 , value));
                                let period = 'Monthly';
                                if ( numberOfPeriodToReturn % 12 === 0){
                                    period = 'Annually';
                                    numberOfPeriodToReturn = numberOfPeriodToReturn / 12;
                                }
                                updateValue('amortization', {period, amount: numberOfPeriodToReturn  }, true);
                            }
                        },
                        generatePropsFn : (values)=>{
                            return {
                                prefix: CURRENCY_SYMBOL[values.currency] || '',
                                placeholder: CURRENCY_SYMBOL[values.currency] || '',
                            }
                        }}
                    ]},
                    // {fields:[
                    //     {key:'interestRepaymentPeriod'}
                    // ]},
                    // {fields:[
                    //     {key:'principalRepaymentPeriod'}
                    // ]},
                ]
            },
            // {
            //     title: 'Performance', 
            //     rows:[
            //         {fields:[
            //             {key:'startDate', label: loans.START_DATE_LABEL, description: loans.START_DATE_DESCRIPTION, defaultValueFn: ()=>{return new Date()},
            //             disabledDate: disabledMoreThanToday}
            //         ]},
            //         {fields:[
            //             {key:'cashReceived', type: 'numberWithHoldings', label: loans.CASH_RECEIVED_LABEL , description: loans.CASH_RECEIVED_DESCRIPTION,prefix:CURRENCY_SYMBOL['USD']},
            //             {key:'cashReceivedCurrency',size:0.3 , label: ' ', defaultValue: 'USD'}
            //         ]},
            //         {fields:[
            //             {key:'cashInvested', type: 'numberWithHoldings', label: loans.CASH_INVESTED_LABEL , description: loans.CASH_INVESTED_DESCRIPTION,prefix:CURRENCY_SYMBOL['USD']},
            //             {key:'cashInvestedCurrency',size:0.3 , label: ' ', defaultValue: 'USD'}
            //         ]},
            //     ]
            // },
            {
                title:`More info`,
                rows:[
                    {fields:[
                        {
                            key:'principalFirstRepayment', 
                            onChange:({value,formValues}) => {
                                // updateField('interestFirstPayment',{isHidden:false});
                                // updateValue('isInterestSameDate', false);
                                // const {isInterestSameDate, startDate, expectedEndDate, interestFirstPayment} = formValues;
                                // // if (isInterestSameDate) updateValue('interestFirstPayment', moment(value))
                                // if (!isInterestSameDate && !moment(startDate).isSame(value, 'month') && !moment(startDate).isSame(interestFirstPayment, 'month') ) {
                                //     const min = moment.min(moment(value), moment(interestFirstPayment));
                                //     const diff = Math.abs(moment(startDate).diff(min, 'month'));
                                //     updateValue('expectedEndDate', moment(expectedEndDate).add(diff, 'month'))
                                // }
                            },
                            disabledDate: (current) => disabledMonthBefore(current, moment(start_date).add(1, 'month')),
                           
                        }
                    ]},
                    {fields:[
                        {
                            type:'checkbox', 
                            key:'isInterestSameDate',
                            checkboxLabel: 'This is the same date interest will be paid the first time', 
                            description: 'To select another date for the interest first payment, uncheck the box' ,
                            fieldCustomStyle: {marginTop:'-24px'},
                            defaultValue: true,
                                onChange: ({value, formValues})=>{
                                    if (value === true) {
                                        updateValue('interestFirstPayment', moment(formValues.principalFirstRepayment));
                                    }
                                    
                                    updateField('interestFirstPayment',{isHidden:value});
                                }
                            },
                        ]},
                    {fields:[
                        {
                            key:'interestFirstPayment', 
                            isHidden:true,
                            onChange:({value,formValues}) => {
                                const { startDate, principalFirstRepayment, expectedEndDate } = formValues;
                                if (!moment(startDate).isSame(principalFirstRepayment, 'month') && !moment(startDate).isSame(value, 'month') ) {
                                    const min = moment.min(moment(principalFirstRepayment), moment(value));
                                    const diff = Math.abs(moment(startDate).diff(min, 'month'));
                                    updateValue('expectedEndDate', moment(expectedEndDate).add(diff, 'month'))
                                }
                            },
                            disabledDate: (current) => disabledMonthBefore(current, moment(start_date).add(1, 'month')),
                            
                        }
                        
                    ]},
                    {fields:[
                        {key:'loanType'}
                    ]},
                    ...(isEditForm ? [
                        // {
                        //     fields:[
                        //         {key:'defaultCashAccountId'}
                        //     ]
                        // },
                        {
                            fields:[
                                { key:'connectedCollateralId', label:'Collateral', description:`Select the asset that uses as collateral / security of this loan.`, type:'amortizationCollateralAutoComplete'}
                            ]
                        },
                        containerRow({isEditForm, updateField}),
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth',  isLocked: true,  UnlockComponent:HoldingsUnlockModal,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                // onBlur:({value})=>{
                                //     updateField('monthlyRent',{holdings:value || ''});
                                //     updateField('expectedMonthlyExpenses',{holdings:value || ''});
                                //     updateField('expectedSalePrice',{holdings:value || ''});
                                // },
                                }
                            ]
                        },
                    ] : []),
                    beneficiaryRow({}),
                    ...(isEditForm ? [
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {fields:[
                        {key:'remarks'}
                    ]}
                ]
            }
        ]
}
}