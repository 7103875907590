import styled from '@emotion/styled/macro';
import { flexCenter, textOverflow } from '../../styles/combos.styles';

export const LinkWrapper = styled.div`
    ${flexCenter};
    justify-content:flex-start;
    cursor: pointer;
    position:relative;
`;

export const ItemIconWrapper = styled.div`
    // margin-left:24px;
    height:24px;
    width:24px;
    min-width:24px;
    min-height:24px;
    border-radius: 50%;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:8px;
    svg{
        width:16px;
        height:16px;
    }
    path{
        // transition:all 0.2s;
        /* stroke:#4F699D; */
        fill:#fff;
    }
`;

export const ItemTitle = styled.div`
    position:relative;
    ::after{
        content:'';
        position:absolute;
        width:0%;
        height:1px;
        left:50%;
        bottom:0;
        background-color:black;
        transition:all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
    }
    ${LinkWrapper}:hover &{
        ::after{
            width:100%;
            left:0%;
        }
        // color:#2299ff;
    }
    ${props=>props.titleOverflow ? `
        ${textOverflow};
    ` : ``}
`;