import styled from '@emotion/styled/macro';
import { colors } from '../../../../common/styles/theme.styles';

export const HelpMessage = styled.div`
  font-size:14px;
  color:${colors.darkGray2};
  margin-bottom:24px;
  cursor: pointer;
  span{
    position: relative;
  ::after{
        content:'';
        position:absolute;
        width:0%;
        height:1px;
        left:50%;
        bottom:0;
        background-color:black;
        transition:all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
    }
    &:hover{
        ::after{
            width:100%;
            left:0%;
        }
        // color:#2299ff;
    }
  }
`;