import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../styles/combos.styles';
import { colors, shadows } from '../../../../styles/theme.styles';

export const Wrapper = styled.div`
    width: 100%;
    /* background: #fff;
    box-shadow: 0px 0.2px 2px rgba(0, 0, 0, 0.2), 0px 1px 4px #E5E9F2; */
    border-radius: 9px;
    display:flex;
    flex-direction:column;
    /* max-height:340px; */
    padding:16px 0px 0px;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    padding:0 16px 8px;
    color: ${colors.darkGray1}; // #FFFFFF;
`;

export const TableRowSizes = styled.div`
    display: grid;
    grid-template-columns: 130px ${props=>props.isWithHoldings ? '340' : '240'}px auto;
    // gap:8px;
`;

export const TableHeaderRow = styled(TableRowSizes)`
    // border-bottom:1px solid ${colors.gray3};
    /* grid-template-columns: 140px 90px 200px 128px; */
    /* padding:4px 0px 8px; */
`;

export const HeaderCell = styled.div`
    font-size:14px;
    color:#9D9D9D;
    ${flexCenter};
    justify-content: ${props=>props.isAmount ? 'space-between' : 'flex-start'};
`;

export const AmountPercentageWrapper = styled.div`
    ${flexCenter};
    padding-right:42px;
`

export const TooltipWrapper = styled.div`
    display: flex;
    margin-left: 4px;
`;

export const TableScrollContent = styled.div`
    /* overflow-y:auto; */
    .ant-picker , .ant-input{
        border-radius:4px;
        &:hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
            box-shadow: none;
        }
    }

    .ant-input{

        // border-color:'green';
        // border-color:${'#d9d9d9'};
       
    }
    .ant-input-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }
`;

export const TableBottomActions = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    padding-top:8px;
`;

export const RowWrapper = styled(TableRowSizes)`
    /* grid-template-columns: 140px 90px 200px 128px; */
    // padding:${props=>props.isEdit ? '4' : '8'}px 16px;
    padding: 8px 0px;
    position:relative;
    :not(:last-of-type){
        border-bottom:1px solid ${colors.gray3};
    }
    :last-of-type{
        padding-bottom:0px;
    }
    min-height:48px;
    /* &:hover{
        background-color:${colors.lightGray1}; // #2A2A2E;
    } */
`;

export const RowCell = styled.div`
    font-size:16px;
    line-height:24px;
    /* color: #FFFFFF; */
    color: ${colors.darkGray1};
    align-items: center;
    display:flex;
    /* padding-right:8px; */
    // margin: 0px 4px;
    /* border: 1px solid #fff; */
    /* padding: 0px 4px; */
    /* ${RowWrapper}:hover &{
        border-radius: 8px;
        border: 1px solid #e8eef3;
        background-color: ${colors.lightGray1};
    } */
`;

export const RowDCellDisplayedText = styled.div`
    padding:0 12px;
`;

export const RowActionsCell = styled.div`
    align-items: center;
    position:absolute;
    left:100%;
    top:10px;
    display:flex;
    /* right:0px; */
    
    /* display: block; */
    opacity:0;
    // visibility:hidden;
    ${RowWrapper}:hover &{
        opacity:1;
        // visibility:visible;
    }
    &:hover{
        opacity:1;
        // visibility:visible;
    }
`;

export const ActionButtonWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px;
    svg{
        height: 24px;
        path {
            stroke: #000;
        }
    }
`;

export const SaveButton = styled.div`
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:80px;
    color:#3F3F45;
    font-size:16px;
    height: 32px;
    margin-right: 16px;
    cursor: pointer;
`;


export const CreateButton = styled.div`
    ${flexCenter};
    cursor: pointer;
    color:${colors.darkGray1};
    border:1px solid ${colors.darkGray1};
    padding:2px 28px 2px 24px;
    border-radius:2px;
    font-size:16px;
    font-weight: 500;
    svg{
        margin-right:4px;
        stroke:${colors.darkGray1};
    }
    .button-text{
        margin-left:4px;
    }
`;

export const PrevComponentWrapper = styled.div`
    display:flex;
    flex-direction:column;
    margin-bottom:16px;
`;
export const PrevComponentDescription = styled.div`
    color:${colors.darkGray1};
    font-size:18px;
    margin-bottom:8px;
`;
export const PrevComponentButtons = styled.div`
    ${flexCenter};
    justify-content:flex-start;
    gap:16px;
`;
export const PrevComponentButton = styled.div`
    border-radius:6px; 
    padding:4px 16px;
    font-size:16px;
    font-weight:500;
    box-shadow:${shadows.toggleShadow};
    color: ${colors.darkGray1};
    background-color: #fff;
    border: 1px solid ${props=>props.isSelected ? colors.darkGray1 : '#fff'}; 
    cursor: pointer;
    ${props=>props.disabled ? `
        cursor: not-allowed;
        opacity: 0.4;
` : ``}
`;
export const CapitalCallsTitle = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    font-weight:500;
`;
export const CapitalCallsTitleAdditionalText = styled.div`
    font-size:14px;
    margin-left:4px;
    color:${colors.darkGray3};
`;
