import { Observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react'
import { useStore } from '../../../app/data/root.store';
import { FilterTag } from './FilterTag';
import { TagPlusMore, StickyWrapper, TagsListWrapper, TagsWrapper, } from './FilterTagsList.styles';

export const FilterTagsList = (props) => {
  const { isCommunity } = props;
  const { filtersStore, uiStore } = useStore();
  const filterParameterBreadcrumbsWrapperRef = useRef(null);
  const hiddenFilterParameterBreadcrumbsWrapperRef = useRef(null);

  useEffect(() => {
    const resizeListener = () => {
      if (filterParameterBreadcrumbsWrapperRef.current && hiddenFilterParameterBreadcrumbsWrapperRef.current) {
        const { clientWidth } = filterParameterBreadcrumbsWrapperRef.current;
        const wrapperWidth = clientWidth;
        const filterChildren = filterParameterBreadcrumbsWrapperRef.current.querySelectorAll('.filterParameterBreadcrumbChild')
        const hiddenChildren = hiddenFilterParameterBreadcrumbsWrapperRef.current.querySelectorAll('.filterParameterBreadcrumbChild');
        const combinedChildren = Array.prototype.slice.call(filterChildren).concat(Array.prototype.slice.call(hiddenChildren));
        // const filterChildren = filterParameterBreadcrumbsWrapperRef.current.querySelectorAll('.filterParameterBreadcrumbChild');
        let childrenWidth = 0;
        for (let index = 0; index < filtersStore.filterTags.length; index++) {
          childrenWidth += combinedChildren[index].clientWidth + 8;
          // console.log('childrenWidth', childrenWidth);
          // console.log('wrapperWidth', wrapperWidth);
          if (childrenWidth - 8 >= wrapperWidth) {
            filtersStore.setVisibleBreadcrumbsCount(index);
            return;
          }
          if(index === filtersStore.filterTags.length - 1) {
            filtersStore.setVisibleBreadcrumbsCount(filtersStore.filterTags.length);
          }
        }
      }
    }
    resizeListener();
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    }
  }, [filtersStore, filtersStore.filterTags, filterParameterBreadcrumbsWrapperRef, hiddenFilterParameterBreadcrumbsWrapperRef])

  return (<Observer>{() => (
    <StickyWrapper isSticky={uiStore.isBreadcrumbsWrapperSticky} isCommunity={isCommunity}>
      {/* <CenterContent isCashFlowView={wealthStore.isCashFlowView}> */}
        <TagsWrapper>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex' }}>
              {filtersStore.filterTags.length > 0 && <TagsListWrapper ref={filterParameterBreadcrumbsWrapperRef}>
                {filtersStore.filterTags.slice(0, filtersStore.visibleBreadcrumbsCount).map((tag, ind) => (
                  <FilterTag key={tag.title + `_${ind}`} tag={tag} />
                ))}
              </TagsListWrapper>}

              {filtersStore.filterTags.length - filtersStore.visibleBreadcrumbsCount > 0 && <TagPlusMore>+{filtersStore.filterTags.length - filtersStore.visibleBreadcrumbsCount} more </TagPlusMore>}

            </div>

            {filtersStore.filterTags.length > 0 && <TagsListWrapper isHidden ref={hiddenFilterParameterBreadcrumbsWrapperRef}>
              {filtersStore.filterTags.slice(filtersStore.visibleBreadcrumbsCount).map((tag, ind) => (
                <FilterTag key={tag.title + `_${ind}`} tag={tag} />
              ))}
            </TagsListWrapper>}

          </div>
        </TagsWrapper>
      {/* </CenterContent> */}
    </StickyWrapper>
  )}</Observer>)
}
