import { CURRENCY_SYMBOL } from "../../../common/constants/currency-symbol.const";
import { isRequired } from "../../add-item/data/input-validations";

export const costOfLivingFormSections = ({updateField}) => ([
    {
        rows:[
            {fields:[
                {type:'number',key:'monthlyIncome', label:'Household w2 income (monthly salaries)', validations:[isRequired],
                    generatePropsFn : (values)=>{
                        return {prefix: CURRENCY_SYMBOL[values.monthlyIncomeCurrency ? values.monthlyIncomeCurrency : values.currency] || '', disabled: values.isActionLocked}
                    },
                    setFnForSave: (isDistribution, value, formValues) => {
                        return Math.round(value);
                    }
                },
                {type:'select',key:'monthlyIncomeCurrency',showSearch:true,
                options: Object.entries(CURRENCY_SYMBOL).map(([key, value]) => ({value:key,label:key}))
                ,size: 0.3,onChange:({value})=>{
                    updateField('monthlyIncome',{prefix:CURRENCY_SYMBOL[value] || ''});
                },defaultValueFn:(values)=>values.monthlyIncomeCurrency ? values.monthlyIncomeCurrency : values.currency,
                generatePropsFn: (values) => {return {disabled: values.isActionLocked}}}
            ]},
            {fields:[
                {type:'number',key:'monthlyExpenses', label:'Household cost of living (monthly)', validations:[isRequired],
                    generatePropsFn : (values)=>{
                        return {prefix: CURRENCY_SYMBOL[values.monthlyExpensesCurrency ? values.monthlyExpensesCurrency : values.currency] || '', disabled: values.isActionLocked}
                    },
                    setFnForSave: (isDistribution, value, formValues) => {
                        return Math.round(value);
                    }
                },
                {type:'select',key:'monthlyExpensesCurrency',showSearch:true,
                options: Object.entries(CURRENCY_SYMBOL).map(([key, value]) => ({value:key,label:key}))
                ,size: 0.3,onChange:({value})=>{
                    updateField('monthlyExpenses',{prefix:CURRENCY_SYMBOL[value] || ''});
                },defaultValueFn:(values)=>values.monthlyExpensesCurrency ? values.monthlyExpensesCurrency : values.currency,
                generatePropsFn: (values) => {return {disabled: values.isActionLocked}}}
            ]},
        ]
    }
])