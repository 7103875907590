import moment from 'moment';

export const RefreshIcon = () => {
    return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.0303 17.8691C13.2886 19.8904 9.39632 19.6498 6.90105 17.1545C5.12928 15.3827 4.49426 12.9066 4.99854 10.6382" stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
<path d="M7.12683 11.1799L4.92702 8.98005L2.72722 11.18" stroke="black" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M7.96924 6.13123C10.7109 4.11001 14.6032 4.35061 17.0985 6.84588C18.8702 8.61765 19.5053 11.0938 19.001 13.3622" stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
<path d="M16.8727 12.8205L19.0725 15.0203L21.2723 12.8203" stroke="black" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
</svg>
    )
}


export const WebSiteIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2204_69047)">
            <path d="M12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12C3.75 7.44365 7.44365 3.75 12 3.75Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path d="M4.21973 14.75H19.78" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.22021 9.25H19.7803" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 3.97136C13.8985 3.97136 15.4375 7.56586 15.4375 11.9999C15.4375 16.4339 13.8985 20.0284 12 20.0284C10.1015 20.0284 8.5625 16.4339 8.5625 11.9999C8.5625 7.56586 10.1015 3.97136 12 3.97136Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10"/>
            </g>
            <defs>
            <clipPath id="clip0_2204_69047">
            <rect width="22" height="22" fill="white" transform="matrix(1 0 0 -1 1 23)"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export const ItemLinkArrowIcon = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.60693 4.35742C4.60693 4.35742 7.53183 4.35742 8.85676 4.35742C8.85676 5.68235 8.85676 8.60725 8.85676 8.60725" stroke="#9097A3" strokeWidth="1.02857" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.42871 9.78809L8.67158 4.54522" stroke="#9097A3" strokeWidth="1.02857" strokeLinecap="round"/>
        </svg>
)
}

export const instSyncTimeAgo = (date) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const oneMinute = 60 * 1000;
    const oneHour = 60 * oneMinute;
    const oneDay = 24 * oneHour;
    const oneWeek = 7 * oneDay;
    
    if (diff < oneMinute) {
      return 'Just now';
    } else if (diff < oneHour) {
      const minutes = Math.floor(diff / oneMinute);
      return `${minutes} min ago`;
    } else if (diff < oneDay) {
      const hours = Math.floor(diff / oneHour);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (diff < oneWeek) {
      const days = Math.floor(diff / oneDay);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else {
      return moment(date).format('MMM DD, yyyy');      
    } 
    
    // else if (diff < oneMonth) {
    //   const weeks = Math.floor(diff / oneWeek);
    //   return `${weeks} weeks ago`;
    // } else {
    //   const months = Math.floor(diff / oneMonth);
    //   return `${months} month ago`;
    // }
  }