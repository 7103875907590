import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from "react-router-dom";
import { BaseModal } from '../../../../../../common/components/BaseModal/BaseModal';
import { PrimaryButtonWithIcon, SecondaryButton } from '../../../../../../common/components/buttons/NewButtons.styles';
import { navigateToUrl } from '../../../../../../common/utils/navigation.utils';
import { getUrlDisplayName } from '../../../../../../common/utils/string.utils';
import { useStore } from '../../../../../app/data/root.store';
import { EditTargetEventDescription, EditTargetEventModalHeader, EditTargetEventTitle, FormStepFooter, ModalCloseButtonWrapper, StepWrapper } from './EditTargetEvent.styles';
import { ReactComponent as EditTargetEventCalendarSvg } from './assets/info-calendar-icon.svg';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent/useTrackEvent';
import { useAnalytics } from '../../../../../app/data/AnalyticsProvider';

export const EditTargetEvent = observer(({ onClose , field }) => {
  const { itemStore, userStore } = useStore();
  const history = useHistory();
  const { trackEvent } = useAnalytics()

  const handleEditTargetOnClick = (e) => {
    trackEvent('CTA Clicked', {
      cta_text: e.target.textContent,
      destination: 'Asset info',
      location: 'Asset page',
      type: 'Button',
    })
    navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(itemStore.item.categoryClass.title)}/${itemStore.item.id}/info${field ? '?field=' + field : ''}`);
    onClose();
  }

  useTrackEvent('Popup Viewed', {
    title: 'Edit Target Popup',
    tier: userStore.data?.profile?.tier,
    url: window.location.href
  })

  return (
    <BaseModal width={'600px'} height={'auto'} borderRadius={8}>
      <EditTargetEventModalHeader>
        <CheckMarkSvg />Event was successfully updated
        <ModalCloseButtonWrapper onClick={onClose}>
          <ModalCloseButton />
        </ModalCloseButtonWrapper>
      </EditTargetEventModalHeader>
      <StepWrapper>
        <EditTargetEventCalendarSvg />
        <EditTargetEventTitle>Edit your target events</EditTargetEventTitle>
        <EditTargetEventDescription>We recommend you update your target events to ensure this asset's expected cash flow is correct.</EditTargetEventDescription>
      </StepWrapper>
      <FormStepFooter>
        <PrimaryButtonWithIcon noStroke onClick={handleEditTargetOnClick} style={{ padding: '10px 20px', gap: '8px', height: '40px' }}><EditSvg />Edit target events</PrimaryButtonWithIcon>
        <SecondaryButton onClick={onClose} style={{ padding: '10px 20px 10px 16px', height: '40px' }}>Ignore</SecondaryButton>
      </FormStepFooter>
    </BaseModal>
  )
})

const EditSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.1399 0.292212C13.944 0.0975619 13.627 0.0981976 13.432 0.293632L6.24641 7.49187C6.18658 7.55181 6.14299 7.62593 6.11973 7.70727L5.40674 10.2001C5.36355 10.3511 5.50383 10.4905 5.655 10.4468L8.15055 9.72542C8.23198 9.70188 8.30609 9.65809 8.36592 9.59815L13.641 4.31374L11.5304 2.20313L12.2375 1.49602L14.3475 3.60601L15.5515 2.39991C15.7465 2.20448 15.7459 1.88825 15.55 1.6936L14.1399 0.292212Z" fill="white" />
      <path d="M14 8V13.9C14 13.9552 13.9552 14 13.9 14H2.1C2.04477 14 2 13.9552 2 13.9V2.1C2 2.04477 2.04477 2 2.1 2H8" stroke="white" strokeWidth="1.5" />
    </svg>
  )
}

const CheckMarkSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="13" fill="#72E1AC" />
      <path d="M18.5103 9.604L11.5059 16.6125L7.99983 13.1075" stroke="white" strokeWidth="2" />
    </svg>
  )
}

const ModalCloseButton = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="6.28033" y1="5.83362" x2="18.3011" y2="17.8544" stroke="#9097A3" strokeWidth="1.5" />
      <line x1="5.92682" y1="17.8544" x2="17.9476" y2="5.83362" stroke="#9097A3" strokeWidth="1.5" />
    </svg>
  )
}