import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors } from '../../../../common/styles/theme.styles';

export const DataBox = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.24), 0px 2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    width:100%;
    margin-bottom: 16px;
`;

export const DataBoxHeaderWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    padding: 24px;
`;

export const TitleDescriptionWrapper = styled.div`
`;

export const DataBoxTitle = styled.div`
    color: ${colors.darkGray1};
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
`;

export const FreeTrialBadge = styled.div`
    background-color: #000000;
    color:${colors.lightGray2};
    font-size:14px;
    padding:4px 8px;
    margin-block: 4px;
    display: inline-block;
`;

export const DataBoxTextDescription = styled.div`
    color: ${colors.darkGray3};
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 26px;
    gap: 10px;
    align-items: center;
`;

export const ActionsButton = styled.div`
    display: flex;
    align-self: flex-start;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.3);
    background: #FFFFFF;
    color: ${colors.darkGray1};
    border-radius: 4px;
    border:1px solid #00000000;
    padding:10px 8px 10px 12px;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    cursor: pointer;   
    gap: 6px;
    :hover{
        color:$ #1A1B1D;
        border: 1px solid #BDC6DA;
        background: #FFFFFF;

    }
`;

export const MembershipSettingsIconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const DataBoxBodyWrapper =styled.div`
    padding: 0 24px 0 24px;
    margin-bottom: 25px;
`;

export const DataBoxPlanWrapper =styled.div`
    border: 1px solid ${colors.gray3};
    border-radius: 6px;
    padding: 16px;
`;

export const DataBoxPlanType = styled.span`
    background-color: ${colors.gray3};
    border-radius: 20px;
    color: ${colors.darkGray1};
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 1px 12px;
    margin-bottom: 7px;
`;

export const PricingDiscountWrapper = styled.div`
    margin-bottom: 12px;
`;

export const PriceWrapper = styled.span`
    color: ${colors.darkGray1};
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding-right: 8px;
`;

export const DiscountWrapper = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #2565E7;
`;

export const RenewDateWrapper = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: ${colors.darkGray3};
`;

export const MembershipEmptyState = styled.div`
    ${flexCenter};
    flex-direction: column;
`;

export const NoMembershipText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.isTitle ? colors.darkGray1 : colors.darkGray3};
    padding-bottom: ${props=>props.isTitle ? '4px' : '16px'};
`;

export const NoMembershipActionButton = styled.div`
    background: #2565E7;
    border-radius: 42px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    padding: 8px 24px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
`;

export const BillingMoreActionMenuButton = styled.div`
${flexCenter};
height:38px;
width:38px;
font-size:14px;
font-weight:450;
outline:none;
border:none;
border-radius:6px;
cursor:pointer;
gap: 4px;

color:${colors.darkGray1};
// box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.3);
background-color: ${props=>props.isOpenState ? colors.lightGray2 : '#fff'} ;
:hover{
    background-color:${colors.lightGray2};
}
cursor: pointer;
`;
