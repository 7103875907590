import React from 'react'
import { SearchAltTextWrapper, SearchIconEmptyStateWrapper, SearchTextWrapper, StockParameterFilterEmptyStateWrapper } from './StockParameterFilter.styles'
import { ReactComponent as EmptySearchResultsIcon } from './icons/search-icon.svg';

export const StockParameterFilterEmptyState = () => {
  return (
    <StockParameterFilterEmptyStateWrapper>
      <SearchIconEmptyStateWrapper><EmptySearchResultsIcon /></SearchIconEmptyStateWrapper>
      <SearchTextWrapper>No results</SearchTextWrapper>
      <SearchAltTextWrapper>You don’t have any ticker/stock<br />at this name.</SearchAltTextWrapper>
    </StockParameterFilterEmptyStateWrapper>
  )
}
