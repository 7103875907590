import moment from 'moment';

export function disabledMonthBefore(current, startDate) {
    return current && current < moment(startDate);
}

export function disabledLessThanToday(current) {
    return current && current < moment().endOf('day');
}

export function disabledMoreThanToday(current) {
    return current && current > moment().endOf('day');
}

export function disabledLessThanDate(current,date) {
    return current && current < moment(date).endOf('day');
}

export function disabledMoreThanDate(current,date) {
    return current && current > moment(date).endOf('day');
}

export function enabledBetween(current, start, end) {
    return !(current && current > moment(start) && (current < moment(end) ) );
}

export function dayDiffs(d1,d2){
    const momentD1 = moment(d1);
    const momentD2 = moment(d2);
    return momentD2.diff(momentD1,'days');
    // const duration = moment.duration(momentD1.diff(momentD2));
    // return duration.asDays();
}

export function monthDiff(d1, d2, returnMinus = false) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return returnMinus ? months : (months <= 0 ? 0 : months);
}

export function addMonths(date, months) {
    const d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    return date;
}

export const endOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth()+1, 0);
}

export const getTimeAgo = (date) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const oneMinute = 60 * 1000;
    const oneHour = 60 * oneMinute;
    const oneDay = 24 * oneHour;
    const oneWeek = 7 * oneDay;
    const oneMonth = 4 * oneWeek;
    
    if (diff < oneMinute) {
      return 'Just now';
    } else if (diff < oneHour) {
      const minutes = Math.floor(diff / oneMinute);
      return `${minutes} min ago`;
    } else if (diff < oneDay) {
      const hours = Math.floor(diff / oneHour);
      return `${hours} hours ago`;
    } else if (diff < oneWeek) {
      const days = Math.floor(diff / oneDay);
      return `${days} days ago`;
    } else if (diff < oneMonth) {
      const weeks = Math.floor(diff / oneWeek);
      return `${weeks} weeks ago`;
    } else {
      const months = Math.floor(diff / oneMonth);
      return `${months} month ago`;
    }
  }