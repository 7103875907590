import React, { useState } from 'react';
import { Dropdown, Tooltip } from 'antd';
import { ArrowDownIcon, CheckmarkBlackIcon } from '../../../assets/icons/common/common-icons';
import { ButtonArrowWrapper, ButtonText, ButtonWrapper, MenuItemWrapper, MenuWrapper, ClearIcon, CheckmarkIconWrapper } from './SimpleSelect.styles';
import DetectableOverflow from 'react-detectable-overflow';
import { CloseCircleFilled } from '@ant-design/icons';
import { colors } from '../../styles/theme.styles';


export function SimpleSelect({value, onChange, onVisibleChange, width, options, placeHolder, placeHolderColor, placement, buttonStyle, className, allowClear, scrollContainerId , borderRadius, withCheckMark , withBorders, disabled, buttonHoverBorderColor = '#BDC6DA', menuItemColor = colors.darkGray1, hideSuffixIcon = false }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [isTooltipNeeded, setIsTooltipNeeded] = useState(false);

    const showClearButton = allowClear && value && isHover && !isMenuOpen;

    const handleVisibleChanged = (visible) => {
        setIsMenuOpen(visible);
        onVisibleChange && onVisibleChange(visible);
    }

    const onItemClick = (itemValue) => {
        if (value !== itemValue) {
            onChange(itemValue);
        }
        setIsMenuOpen(false);
        onVisibleChange && onVisibleChange(false);
    }

    const selectedOption = options.find(o => o.value === value);

    const handleOverflowChange = (isOverflowed) => {
        setIsTooltipNeeded(isOverflowed);
    }
    const handleClearSelection = (e) => {
        onItemClick(null);
        e.stopPropagation();
    }
    return (
        <Dropdown 
            overlay={SimpleSelectMenu({ onItemClick, options, width, value, borderRadius, withCheckMark , withBorders, menuItemColor })} 
            trigger={['click']} 
            placement={placement || "bottomLeft"}
            disabled={disabled}
            onVisibleChange={handleVisibleChanged} 
            getPopupContainer={() => scrollContainerId ? ( document.getElementById(scrollContainerId) || document.body) : document.body}
            >
            <ButtonWrapper 
                className={className} 
                customStyle={buttonStyle}
                buttonHoverBorderColor={buttonHoverBorderColor}
                width={width} 
                isPlaceHolder={!selectedOption} 
                placeHolderColor={placeHolderColor}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                >
                <ButtonText className='simple_select_button_text'>
                    <DetectableOverflow onChange={handleOverflowChange}>
                        <Tooltip title={isTooltipNeeded ? selectedOption ? selectedOption.label : placeHolder ? placeHolder : 'Select' : ''} placement={"topLeft"}>{selectedOption ? selectedOption.label : placeHolder ? placeHolder : 'Select'}</Tooltip>
                    </DetectableOverflow>
                    {/* {selectedOption ? selectedOption.label : placeHolder ? placeHolder : 'Select'} */}
                </ButtonText>
                {showClearButton && <ClearIcon onClick={(e) => handleClearSelection(e)}><CloseCircleFilled /></ClearIcon>}
                <ButtonArrowWrapper className="select-arrow" isOpen={isMenuOpen} >{!hideSuffixIcon ? <ArrowDownIcon /> : null}</ButtonArrowWrapper>
            </ButtonWrapper>
        </Dropdown>
    )
}

function SimpleSelectMenu({onItemClick, options, width , borderRadius, value, withCheckMark , withBorders, menuItemColor}) {

    return (
        <MenuWrapper width={width} borderRadius={borderRadius}>
            {options.map((option, index) => (
                <MenuItemWrapper key={index} menuItemColor={menuItemColor} onClick={() => { onItemClick(option.value) }} withBorders={withBorders} withCheckMark={withCheckMark}>
                    { option.label }
                    { withCheckMark && option.value === value && <CheckmarkIconWrapper><CheckmarkBlackIcon /></CheckmarkIconWrapper>}
                </MenuItemWrapper>))}
        </MenuWrapper>
    )
}