import moment from 'moment';
export const findValueAtDate = (allEvents, eventDate, eventId) => {
    // if there is event id, it's for edit of actual event - find the index and then the first index below with value update
    if (eventId){
        const relevantEventIndex = allEvents.findIndex(vu=> vu.eventId === eventId || vu.id === eventId);
        const previousEventWithValueUpdate = allEvents.slice(relevantEventIndex+1).find(ev=>ev.eventData?.valueUpdates.length > 0);
        if (previousEventWithValueUpdate){
            return previousEventWithValueUpdate.eventData?.valueUpdates[0]
        }
    } else {
        const firstEventAtMonthOrBeforeWithValueUpdate = allEvents.filter(ev=>ev.eventData?.valueUpdates.length > 0).find(vu => moment(vu.date).isSameOrBefore(moment(eventDate),'M'));
        if (firstEventAtMonthOrBeforeWithValueUpdate){
            return firstEventAtMonthOrBeforeWithValueUpdate.eventData?.valueUpdates[0]
        }
    }
    return null;
}

export const findAttributeValueAtDate = ( allEvents, atrKey, eventDate, eventId) => {
     // if there is event id, it's for edit of actual event - find the index and then the first index below with relevant attribute
     if (eventId){
        const relevantEventIndex = allEvents.findIndex(vu=> vu.eventId === eventId || vu.id === eventId);
        const previousEventWithAttributeUpdate = allEvents.slice(relevantEventIndex+1).find(ev=>ev.eventData?.attributes?.find(atr => atr.data.hasOwnProperty(atrKey)));
        if (previousEventWithAttributeUpdate){
            return previousEventWithAttributeUpdate.eventData?.attributes.find(atr => atr.data.hasOwnProperty(atrKey));
        }
    } else {
        
        const firstEventAtMonthOrBeforeWithAttributeUpdate = allEvents.filter(ev=>ev.eventData?.attributes?.find(atr => atr.data.hasOwnProperty(atrKey))).find(vu => moment(vu.date).isSameOrBefore(moment(eventDate),'M'));
        if (firstEventAtMonthOrBeforeWithAttributeUpdate){
            return firstEventAtMonthOrBeforeWithAttributeUpdate.eventData?.attributes.find(atr => atr.data.hasOwnProperty(atrKey));
        }
    }
    return null;
}


export const customValueAtDateText = {
    36: 'Balance at date:',
    39: 'Number of shares at date:',
    40: 'Cash value at date:',
    41: 'Balance at date:',
}

export const customValueAtDateType = {
    36: 'number',
    39: 'number'
}