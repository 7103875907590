import { Input, Tooltip } from "antd";
import React, { useEffect, useState } from 'react';
import { DeleteIcon, PlusIcon, TooltipIcon } from '../../../../../assets/icons/common/common-icons';
import { ActionButtonWrapper, AmountPercentageWrapper, CreateButton, HeaderCell, RowActionsCell, RowCell, RowWrapper, TableBottomActions, TableHeaderRow, TableScrollContent, TooltipWrapper, Wrapper } from './YearsPaymentsTable.styles';
// import { disabledMoreThanToday } from '../../utils/dates.utils';
import NumberFormat from 'react-number-format';
import { errorNotification } from '../../../../../modules/layout/components/Notifications/notifications';
import { colors } from '../../../../styles/theme.styles';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../../utils/object.utils';
import { addRow, changeYearsFrom, changeYearsTo, deleteRow, updateRowValues } from './yearsPaymentsTable.helpers';

// import { errorNotification } from '../../../modules/layout/components/Notifications/notifications';

// const getDisplayDate = (date) => {
//     //const d = new Date(date);
//     //return (("0"+(d.getMonth()+1)).slice(-2) + "/" +d.getFullYear());
//     return moment(date).format('MMM YYYY');
// }

const tableColumns = ['Policy years timeframe',<div><b>Total</b> annual premium</div>,'Base amount','PUA amount'];
const puaAmountToolTipText = () => {
    return (<div>
        The total annual premium can be divided into two main components: the obligatory <b>Base</b> premium and any <b>Paid Up Additions</b> (PUAs). Your insurance company provides this exact breakdown in the policy illustration, as you defined in the ‘Asset info’ tab.
    </div>)
};
export function YearsPaymentsTable(props){
    
    const {onChange, disabled, value , rowValuePreFix, holdings } = props;
    // const [editedRow,setEditedRow] = useState(0); // useState(value.length ? value[value.length -1].id || ('t'+value[value.length -1].tempId) : 0);

    const handleAddRowClick = () => {
        if (disabled){return}
        const { updatedSchedule } = addRow(value);
        // setEditedRow('t' + (newRowTempId));    
        onChange && onChange(updatedSchedule);
    }

    const handleAttributeRowUpdated = (updatedIndex,data,key) => {
        // let updatedRows = value.map((item,index)=>(index === updatedIndex ? data : item));
        // onChange && onChange(updatedRows);
        const updatedRow = updateRowValues(data,key);
        let updatedSortedSchedule = value.map((item,index)=>(index === updatedIndex ? updatedRow : item));
        
        // if (key === 'date'){
        //     updatedSortedSchedule = updatedSortedSchedule.sort((a,b)=>new Date(a.date) - new Date(b.date));
        //     // updatedSortedSchedule = updatedSortedSchedule.map((item,index)=>{
        //     //     if(index === 0) return item;
        //     //     return {...item, endDate: updatedSortedSchedule[index - 1].date };
        //     // });
        // }
        onChange && onChange(updatedSortedSchedule);
    }

    const handleStartDateChanged = (updatedIndex,newYear) => {
        const updatedSchedule = changeYearsFrom(value,updatedIndex,newYear); 
        onChange && onChange(updatedSchedule);
    }

    const handleEndDateChanged = (updatedIndex,newYear) => {
        const updatedSchedule = changeYearsTo(value,updatedIndex,newYear); 
        onChange && onChange(updatedSchedule);
    }
    
    const handleAttributeRowDeleted = (deletedIndex) => {
      const updatedSchedule = deleteRow(value,deletedIndex); 
      onChange && onChange(updatedSchedule);
    }
    
    return (
        <Wrapper>
            <TableHeaderRow onClick={(e)=>{e.stopPropagation()}} 
                isWithHoldings={holdings !== 100}>  
              {tableColumns.map((columnLabel,ind)=>(
                <HeaderCell key={'hc1'+ind} >
                    <span>{columnLabel}</span>
                    {columnLabel === 'PUA amount' ? <Tooltip title={puaAmountToolTipText} placement="top" overlayStyle={{maxWidth: '346px'}}>
                            <AmountPercentageWrapper><TooltipWrapper><TooltipIcon /></TooltipWrapper></AmountPercentageWrapper>
                        </Tooltip> : null}
                </HeaderCell>
              ))}
            </TableHeaderRow> 
            <TableScrollContent>
              {/* {value?.filter(row=>row.value!==0).map((row,index)=>( */}
              {value?.map((row,index)=>{
                const yearsFromMinValue = index > 0 ? (value[index-1]).fromYear + 1 : 0;
                const yearsFromMaxValue = index < value.length - 2 ?  value[index+2].fromYear - 2 : 9999;
                const nextRowYearValue = index === value.length - 1 ? null : value[index+1].fromYear;
                const isLastRow = index === value.length - 1;
         
                return isLastRow ? null :(
                <HistoryRow 
                key={(row.id || ('t' + row.tempId))+'i'+index} 
                isFirstRow={index === 0}
                rowValuePreFix={rowValuePreFix} index={index} 
                yearsFromMinValue={yearsFromMinValue}
                yearsFromMaxValue={yearsFromMaxValue}
                nextRowYearValue={nextRowYearValue}
                disabled={disabled}
                
                // disabledFrom={index !== 0 ? value[index-1].date : startDate}
                
                // allowClearEndDate={index === value.length - 1 || ( index === value.length - 2 && value[index+1].value === 0 )}
                // nextRowDate={index === value.length - 1 ? null : value[index+1].date} 
                // startDateDisabledFrom={startDateDisabledFrom}
                // endDateDisabledTo={endDateDisabledTo } 
                item={row}  atrKey={row.atrKey} 
                isEditMode={ true } // row.id ? (editedRow === row.id) : (editedRow === 't' + row.tempId)  }
                // setEditMode={()=> setEditedRow(row.id ? row.id : 't' + row.tempId)}
                holdings={holdings}
                rowUpdated={(data,key)=>{handleAttributeRowUpdated(index,data,key)}}
               
             
                // handleDateRangeChanged={(data)=>{handleDateRangeChanged(index,data)}}
                handleStartDateChanged={(data)=>{handleStartDateChanged(index,data)}}
                handleEndDateChanged={(data)=>{handleEndDateChanged(index,data)}}
                rowDeleted={()=>handleAttributeRowDeleted(index)} />
              )})}
              
            </TableScrollContent>
            <TableBottomActions>
            <CreateButton onClick={handleAddRowClick} disabled={disabled}>
                {/* <AddIcon /> */}
                <PlusIcon />
                <span className='button-text'>Add date range</span>
            </CreateButton>
            {/* <button style={{width:'80%', alignSelf:'center'}} onClick={handleAddRowClick}>Add row</button> */}
            </TableBottomActions>
            {/* <div style={{color:'#fff'}}>{JSON.stringify(scheduleHistory)}</div> */}
            {/* {eventTypes.map((event,index)=>(
                <ActualsMenuItem onClick={()=>{props.setItem(index)}} key={index} isHidden={ index === 0 && props.isValueDisabled} > 
                    <ActualsMenuItemTitle>{event.title}</ActualsMenuItemTitle>
                    <ActualsMenuItemDescription>{event.description}</ActualsMenuItemDescription>
                </ActualsMenuItem> 
            ))} */}
            {/* { isEditMode ? <>
                <button style={{width:'80%', alignSelf:'center'}} onClick={handleAddRowClick}>Add row</button>
                <button style={{width:'80%', alignSelf:'center'}} onClick={handleSaveChangesClick}>Save</button>
                <button style={{width:'80%', alignSelf:'center'}} onClick={handleCancelChangesClick}>Cancel changes</button>
            </> : <>
                
            </>} */}
            {/* <button style={{width:'80%', alignSelf:'center'}} onClick={()=>{setIsEditMode(true)}}>Edit</button> */}
           
        </Wrapper>
    )
}

const HistoryRow = (props) => {
    const {item, disabled, rowDeleted, isEditMode, yearsFromMinValue, yearsFromMaxValue, nextRowYearValue, rowValuePreFix, holdings, isFirstRow,
        handleStartDateChanged, handleEndDateChanged, } = props;
    const [yearsValue,setYearsValue] = useState('');
    const [yearsEndValue,setYearsEndValue] = useState('');
    const [totalValueNumber,setTotalValueNumber] = useState('');
    const [baseValueNumber,setBaseValueNumber] = useState('');
    const [puaValueNumber,setPuaValueNumber] = useState('');
    const [isHoverOn,setIsHoverOn] = useState(false);
    
    
    // console.log(prevRowDate);

    const handleFieldChange = (field,value) => {
   
        props.rowUpdated({
            ...item,
            [field]: isNullOrUndefinedOrEmptyString(value) ? '' : value
        },field)
    }

    useEffect(() => {
        setYearsValue(item.fromYear)
        return () => { }
    }, [item.fromYear])

    useEffect(()=>{
        setYearsEndValue(nextRowYearValue-1)        
    }, [nextRowYearValue])

    useEffect(() => {
        setBaseValueNumber(item.baseValue);
        setPuaValueNumber(item.puaValue);
        setTotalValueNumber(!item.baseValue && !item.puaValue ? '' : (item.baseValue || 0) + (item.puaValue||0))
        return () => { }
    }, [item.baseValue,item.puaValue])

    // useEffect(() => {
    //     setPuaValueNumber(item.puaValue)
    //     setTotalValueNumber(!item.baseValue && !item.puaValue ? '' : (item.baseValue || 0) + (item.puaValue||0))
    //     // setTotalValueNumber((item.baseValue || 0) + (item.puaValue || 0))
    //     return () => { }
    // }, [item.puaValue])

    
    return (
        <RowWrapper  isWithHoldings={holdings !== 100} isHidden={item.value===0} 
            onMouseEnter={()=>{setIsHoverOn(true)}} onMouseLeave={()=>{setIsHoverOn(false)}} >
            
            <RowCell style={{position:'relative'}}>
                <NumberFormat 
                    customInput={Input}
                    // prefix={'Year'}
                    // placeholder='Year'
                    disabled={disabled || isFirstRow}
                    decimalScale={0}
                    style={{width:'46%' , textAlign:'right' , borderRadius:'6px', ...(( isEditMode || isHoverOn ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'})}}
                    // value={ ( isNullOrUndefinedOrEmptyString( yearsValue) ) ? '' : yearsValue }
                    value={ ( isNullOrUndefinedOrEmptyString( yearsValue) ) ? '' : yearsValue }
                    autoComplete="off"
                    onValueChange={(values) => {
                        if ( !isNullOrUndefined(values.floatValue)){
                                setYearsValue(( values.floatValue || values.floatValue === 0 ) ? values.floatValue : null)
                        }
                    }}
                    onBlur={(e) => {
                        if (yearsFromMinValue > yearsValue || yearsFromMaxValue < yearsValue ){
                            setYearsValue(item.fromYear);
                            errorNotification("Overlapping years");
                        } else if(yearsValue) {
                            handleStartDateChanged(yearsValue)
                            // handleFieldChange('fromYear',yearsValue )
                        }
                    }}
                />
                <div style={{position:'absolute', top:4, left:8, pointerEvents:'none'}}>Year</div>   
            <YearsSeparatorSvg />
            {/* <div>--</div> */}
            
            <NumberFormat 
                    customInput={Input}
                    // prefix={'Year'}
                    // placeholder='Year'
                    disabled={disabled}
                    decimalScale={0}
                    style={{width:'46%', textAlign:'right' , borderRadius:'6px', ...(( isEditMode || isHoverOn ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'})}}
                    value={ yearsEndValue }
                    autoComplete="off"
                    onValueChange={(values) => {
                        setYearsEndValue( (values.floatValue || values.floatValue === 0 ) ? values.floatValue : yearsEndValue )
                    }}
                    onBlur={(e) => { 
                        if (yearsEndValue >= yearsValue && yearsEndValue < yearsFromMaxValue){
                            handleEndDateChanged(yearsEndValue);
                        } else {
                            setYearsEndValue( nextRowYearValue-1 )
                            errorNotification("Overlapping years");
                        }
                         
                    }}
            />   
            <div style={{position:'absolute', top:4, left:'calc(46% + 24px)', pointerEvents:'none'}}>Year</div>   
            
       </RowCell>


            <RowCell>

                     <NumberFormat 
                     thousandSeparator={true} 
                     customInput={Input}
                     prefix={rowValuePreFix}
                     placeholder={( isEditMode || isHoverOn ) ? (rowValuePreFix) : ''}
                     decimalScale={2}
                     style={{width:'100%',...(( isEditMode || isHoverOn ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'}),  ...( holdings !== 100 ? {paddingLeft:'30px', color:colors.holdingsValueColor, borderRadius:'0px 4px 4px 0px'} : {})}}
                    //  style={isIncognito && field.shouldBeHiddenIncognito ? {WebkitTextSecurity:'disc'}:{}} 
                     value={( isNullOrUndefinedOrEmptyString(totalValueNumber) ) ? '' : parseFloat( (+totalValueNumber).toFixed(2)) }
                     autoComplete="off"
                     disabled={disabled}
                     onValueChange={(values) => { setTotalValueNumber(values.floatValue)}}
                     allowNegative={false}
                     onBlur={(e) => {           
                        if (totalValueNumber === 0 && item.value === ''){ 
                            setTotalValueNumber('') 
                        } else {
                            handleFieldChange('totalValue',totalValueNumber);
                        }
                    }}
                    //  onValueChange={(values) => {
                    //     handleFieldChange('value', values.floatValue === 0 ? null : values.floatValue)
                    // }}
                    />
                    
            </RowCell> 

            <RowCell style={{marginRight:'-4px'}}>

                     <NumberFormat 
                     thousandSeparator={true} 
                     customInput={Input}
                     prefix={rowValuePreFix}
                     placeholder={( isEditMode || isHoverOn ) ? (rowValuePreFix) : ''}
                     style={{width:'100%',...(( isEditMode || isHoverOn ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'}),  ...( holdings !== 100 ? {paddingLeft:'30px', color:colors.holdingsValueColor, borderRadius:'0px 4px 4px 0px'} : {}), borderRadius: '6px 0 0 6px'}}
                    //  style={isIncognito && field.shouldBeHiddenIncognito ? {WebkitTextSecurity:'disc'}:{}} 
                     value={( isNullOrUndefinedOrEmptyString(baseValueNumber) ) ? '' : parseFloat( (+baseValueNumber).toFixed(2)) }
                     disabled={disabled}
                     autoComplete="off"
                     onValueChange={(values) => { setBaseValueNumber(values.floatValue)}}
                     allowNegative={false}
                     onBlur={(e) => {           
                        if (baseValueNumber === 0 && item.value === ''){ 
                            setBaseValueNumber('') 
                        } else {
                            handleFieldChange('baseValue',baseValueNumber );
                        }
                        // if (item.value)
                    }}
                    //  onValueChange={(values) => {
                    //     handleFieldChange('value', values.floatValue === 0 ? null : values.floatValue)
                    // }}
                    />
                    
            </RowCell> 

            <RowCell style={{marginInline:'-4px'}}>

                     <NumberFormat 
                     thousandSeparator={true} 
                     customInput={Input}
                     prefix={rowValuePreFix}
                     placeholder={'Optional'}
                     style={{width:'100%',...(( isEditMode || isHoverOn ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'}),  ...( holdings !== 100 ? {paddingLeft:'30px', color:colors.holdingsValueColor, borderRadius:'0px 4px 4px 0px'} : {}), borderRadius: '0 6px 6px 0'}}
                    //  style={isIncognito && field.shouldBeHiddenIncognito ? {WebkitTextSecurity:'disc'}:{}} 
                     value={( isNullOrUndefinedOrEmptyString(puaValueNumber) ) ? '' : parseFloat( (+puaValueNumber).toFixed(2)) }
                     disabled={disabled}
                     autoComplete="off"
                     onValueChange={(values) => { setPuaValueNumber(values.floatValue)}}
                     allowNegative={false}
                     onBlur={(e) => {           
                        if (puaValueNumber === 0 && item.value === ''){ 
                            setPuaValueNumber('') 
                        } else {
                            handleFieldChange('puaValue',puaValueNumber);
                        }
                        // if (item.value)
                    }}
                    //  onValueChange={(values) => {
                    //     handleFieldChange('value', values.floatValue === 0 ? null : values.floatValue)
                    // }}
                    />
                    
            </RowCell> 

          
           { !isFirstRow && !disabled && <RowActionsCell isRowHidden={item.value===0}>
            {/* { isEditMode &&      */}
            <ActionButtonWrapper 
                        //onClick={()=>{showDeleteUpdateConfirm()}}>
                        // onClick={()=>{setIsDelete(true)}} >
                            onClick={()=>{rowDeleted && rowDeleted()}} >
                             
                        <DeleteIcon />
                    </ActionButtonWrapper>
                    {/* } */}
            </RowActionsCell> }
            {/* {item.value===0 && <RowWRapperBlocker />} */}
            {/* <RowActionsCell show={isEdit || isDelete}>
                {isEdit ? <>
                    <SaveButton onClick={saveChanges}>Save</SaveButton>
                    <ActionButtonWrapper onClick={cancelChanges}>
                        <CloseXIcon />
                    </ActionButtonWrapper>
                </> : (
                isDelete ? <>
                    <SaveButton onClick={approveDelete}>Delete</SaveButton>
                    <ActionButtonWrapper onClick={()=>{setIsDelete(false)}}>
                        <CloseXIcon />
                    </ActionButtonWrapper>
                </>:
                <>
                    <ActionButtonWrapper style={{marginRight:'16px'}} onClick={()=>{setIsEdit(!isEdit)}} >
                        <EditIcon />
                    </ActionButtonWrapper>
                    <ActionButtonWrapper 
                        //onClick={()=>{showDeleteUpdateConfirm()}}>
                        onClick={()=>{setIsDelete(true)}} >
                        <DeleteIcon />
                    </ActionButtonWrapper>
                </>)}
            </RowActionsCell> */}
        </RowWrapper>
    )
}
  

const YearsSeparatorSvg = () => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 8L4 8M12 8L8.66667 4M12 8L8.66667 12" stroke="black"/>
    </svg>
    )
}