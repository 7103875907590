import { SecondaryButton } from "../../../../../../common/components/buttons/NewButtons.styles";
import { startIntercomTourById } from "../../../../../../common/utils/intercom.utils";
import { useStore } from "../../../../../app/data/root.store";
import { AvailableLoanAmountEmptyStateWrapper } from "./OverviewTab.styles";

export const AvailableLoanAmountEmptyState = ({ itemData, isHoldingView }) => {
  const { userStore, billingStore, uiStore } = useStore();

  const handleOutstandingLoanMenuItemClick = () => {
    if (userStore.isDemoOrReadOnlyMode) {
      billingStore.setShowPricingTableModal(true);
    } else {
      uiStore.setShowConnectCollateralModal?.(true)
    }
  }

  return <AvailableLoanAmountEmptyStateWrapper style={{ marginTop: '-8px' }}>
    <div>Track your loans for full visibility of your leverage strategy</div>
    <div><SecondaryButton onClick={handleOutstandingLoanMenuItemClick} style={{ minHeight: '32px', paddingInline: '12px', gap: '2px', justifyContent: 'normal' }}><PlusSvg /> Add outstanding loans</SecondaryButton></div>
  </AvailableLoanAmountEmptyStateWrapper>
}

export const DeathBenefitEmptyState = ({ itemData, isHoldingView }) => {

  return <AvailableLoanAmountEmptyStateWrapper>
    <div></div>
    <SecondaryButton onClick={()=>{alert('yo')}} style={{ minHeight: '32px', paddingInline: '12px', gap: '2px', justifyContent: 'normal' }}><PlusSvg /> Update death benefits</SecondaryButton>
  </AvailableLoanAmountEmptyStateWrapper>
}

export const LeveragedAssetsEmptyState = ({ itemData, isHoldingView }) => {

  return <AvailableLoanAmountEmptyStateWrapper>
    <div></div>
    <SecondaryButton id='link-leveraged-assets' onClick={() => { startIntercomTourById('473200') }} style={{ minHeight: '32px', paddingInline: '12px', fontSize:'14px', fontFamily:'Circular', fontWeight:450  }}>How to link leveraged assets?</SecondaryButton>
  </AvailableLoanAmountEmptyStateWrapper>
}

const PlusSvg = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 18L12.5 6" stroke="#1A1B1D" strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M18.5 12H6.5" stroke="#1A1B1D" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  )
}