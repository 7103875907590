import { Observer } from 'mobx-react';
import React from 'react';
import { useHistory } from "react-router-dom";
import { navigateToUrlWithQuery } from '../../../../common/utils/navigation.utils';
import { ReactComponent as BackArrowIcon } from '../../../../modules/onboarding/assets/back-arrow-icon.svg';
import { useStore } from '../../../app/data/root.store';
import { ActionButton, OnBoardingHeaderLink } from '../../../login/components/LoginPage.styles';
import { AUTH_STEPS } from '../../../user/data/user.store';
import { LogoWrapper } from '../MobileMessage/MobileMessage.styles';
import {  OnBoardingHeaderWrapper } from './Header.styles';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { ROUTES } from '../../../../common/constants/routes.const';

const signUpButtonSteps = [AUTH_STEPS.LOG_IN_STEP, AUTH_STEPS.FORGOT_PASSWORD_STEP]

export function OnBoardingHeader(props) {
  const { showBackButton = false, backButtonFn , whiteBg, isLoginLayout, hideVyzerLogo} = props;
  const { userStore } = useStore();
  const history = useHistory();
  const { trackEvent } = useAnalytics();

  const handleGoToLogin = () => {
    trackEvent('CTA Clicked', {
      cta_text: "Log In",
      location: "Main sign up page",
      destination: 'Main log in page',
      type: "Button",
      url: window.location.href
    })
    navigateToUrlWithQuery(history,'/',true);
    userStore.setAuthStep(AUTH_STEPS.LOG_IN_STEP);
  }


  const handleGoToRegister = () => {
    trackEvent('CTA Clicked', {
      location: "Main login page",
      destination: 'Main sign up page',
      cta_text: "Sign Up",
      type: "Button",
      url: window.location.href
    })
    navigateToUrlWithQuery(history, ROUTES.register.url,true);
    userStore.setAuthStep(AUTH_STEPS.SIGN_UP_STEP);
  }

  return (<Observer>{() => (
    <OnBoardingHeaderWrapper showBackButton={showBackButton} isLoginLayout={isLoginLayout} whiteBg={whiteBg}>

      {showBackButton && <span style={{display:'flex' , pointerEvents:'auto'}} onClick={backButtonFn}><BackArrowIcon /></span>}

      {!hideVyzerLogo ? <LogoWrapper style={{ width: 'auto', boxShadow: 'none', padding: '20px 4px' , display:'flex', pointerEvents:'auto' }} href="https://vyzer.co/" target="_blank">
        <VyzerDarkSymbol />
      </LogoWrapper> : null}

      {/* {showSignUpButton && !userStore?.data?.profile?.isOnBoarding && <SignUpButton onClick={handleSignUp}>Sign up</SignUpButton>} */}

      {isLoginLayout && userStore.authStep === AUTH_STEPS.SIGN_UP_STEP && 
      <>
        <OnBoardingHeaderLink>Already have an account?</OnBoardingHeaderLink>
        <ActionButton onClick={handleGoToLogin}>Log In</ActionButton>
      </>}

      {isLoginLayout && signUpButtonSteps.includes(userStore.authStep) &&
      <>
        <OnBoardingHeaderLink>New in Vyzer?</OnBoardingHeaderLink>
        <ActionButton onClick={handleGoToRegister}>Sign Up</ActionButton>
      </>}

    </OnBoardingHeaderWrapper>
  )}</Observer>)
}

const VyzerDarkSymbol = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1942 0H9.86411C4.41631 0 0 4.41631 0 9.86411V22.1942C0 27.642 4.41631 32.0584 9.86411 32.0584H22.1942C27.642 32.0584 32.0584 27.642 32.0584 22.1942V9.86411C32.0584 4.41631 27.642 0 22.1942 0Z" fill="#121D2A" />
      <path d="M10.1234 9.16623L17.7814 22.7358L15.9971 25.8974L6.55469 9.16623H10.1234ZM13.6921 9.16623L15.4588 12.297H20.1038L17.7814 16.4121L19.5658 19.5737V19.5743L25.4398 9.16623H13.6921Z" fill="white" />
    </svg>
  )
}