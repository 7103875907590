import { Observer } from 'mobx-react';
import React from 'react';
import { ModalCloseIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { LogoLoader } from '../../../../common/components/LogoLoader/LogoLoader';
import { PrimaryButton } from '../../../../common/components/buttons/NewButtons.styles';
import { useStore } from '../../../app/data/root.store';
import { ActionButton, BottomMessage, LogoDecorationsWrapper, ModalBgWrapper, ModalCloseButtonWrapper, ModalContent, ModalHeader, TitleText, TitleWrapper } from './UpgradePlanModal.styles';
import { ReactComponent as TitleLIlu } from './assets/title-l.svg';
import { ReactComponent as TitleRIlu } from './assets/title-r.svg';
import { ReactComponent as VyzerLogo } from './assets/vyzer-logo-with-glow.svg';
import { startIntercomNewMessage } from '../../../../common/utils/intercom.utils';

export const DowngradePlanModal = ({ onBackDropClick, noSidebarMargin = false }) => {
  const { userStore } = useStore();

  const TitleByTier = [
    ``, // shouldn't reach this
    `Thanks for selecting the\nVyzer Plus plan`,
    `Thanks for selecting the\nVyzer Premium plan`,
    `` // shouldn't reach this
  ]

  const BottomMessageByTier = [
    ``, // shouldn't reach this
    `Your plan was successfully updated.`,
    `Your plan was successfully updated.`,
    ``, // shouldn't reach this
  ]

  const handleStartClick = () => {
    onBackDropClick?.();
  }

  return (<Observer>{() => (
    <BaseModal width={'880px'} height={'auto'} borderRadius={8} onBackDropClick={onBackDropClick} noSidebarMargin={noSidebarMargin}>
      {userStore.isDemoMode || userStore.isFreeUser ?
        <div style={{ display: 'grid', placeItems: 'center', flex: 1, minHeight: '560px' }}>
          <LogoLoader />
        </div>
        : <>
          <ModalBgWrapper>
            <ModalCloseButtonWrapper onClick={onBackDropClick}>
              <ModalCloseIcon />
            </ModalCloseButtonWrapper>
            <ModalHeader>
              <LogoDecorationsWrapper>
                <TitleLIlu />
                <VyzerLogo />
                <TitleRIlu />
              </LogoDecorationsWrapper>
            </ModalHeader>
            <ModalContent isTier4={userStore.isTier4}>
              <TitleWrapper>
                <TitleText>
                  {TitleByTier[userStore.tierIndex]}
                </TitleText>
              </TitleWrapper>
              <BottomMessage>
                {BottomMessageByTier[userStore.tierIndex]}
              </BottomMessage>
            </ModalContent>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '78px', padding: '20px 24px' }}>
              <ActionButton style={{ gap: '4px', padding: '8px 20px' }} onClick={startIntercomNewMessage}><IntercomSvg /> Chat with us </ActionButton>
              <PrimaryButton style={{ minWidth: '144px' }} onClick={handleStartClick}>Got it</PrimaryButton>
            </div>
          </ModalBgWrapper>
        </>}
    </BaseModal>
  )}</Observer>)
}

const IntercomSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.3333 17.5C16.3333 17.5 14.0886 16.6305 12.2729 15.9344H4.63667C3.73333 15.9344 3 15.1684 3 14.2248V4.20812C3 3.26547 3.73333 2.5 4.63667 2.5H14.6962C15.6 2.5 16.3329 3.265 16.3329 4.20859V12.8355L16.3333 17.5ZM14.3624 11.8834C14.3267 11.8384 14.2819 11.8011 14.2309 11.774C14.1798 11.7468 14.1236 11.7304 14.0657 11.7258C14.0079 11.7212 13.9497 11.7284 13.8949 11.7471C13.84 11.7657 13.7897 11.7954 13.7471 11.8342C13.7329 11.8464 12.3114 13.0909 9.66619 13.0909C7.05381 13.0909 5.61048 11.8553 5.58476 11.8328C5.54213 11.7942 5.49183 11.7647 5.43705 11.7462C5.38226 11.7277 5.32417 11.7206 5.26645 11.7253C5.20873 11.7301 5.15262 11.7465 5.10168 11.7736C5.05074 11.8008 5.00606 11.838 4.97048 11.883C4.89425 11.9766 4.85698 12.0952 4.86619 12.2148C4.87424 12.3339 4.92845 12.4453 5.01762 12.5261C5.08524 12.5861 6.7019 14.0027 9.66619 14.0027C12.631 14.0027 14.2476 12.5861 14.3152 12.5261C14.4042 12.4452 14.4583 12.3338 14.4662 12.2148C14.4754 12.0954 14.4383 11.977 14.3624 11.8834Z" fill="#1A1B1D" />
    </svg>
  )
}