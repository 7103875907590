import { Drawer, Input } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { PrimaryButtonWithIcon, SecondaryButtonWithIcon } from '../../../../common/components/buttons/NewButtons.styles';
import { isNullOrUndefined } from '../../../../common/utils/object.utils';
import { useStore } from '../../../app/data/root.store';
import { CustomClassRowCollapsibleDetailsWrapper, CustomSubClassEmptyStateRowIconWrapper, CustomSubClassEmptyStateRowTextWrapper, CustomSubClassEmptyStateRowWrapper, CustomSubClassListMobileWrapper, CustomSubClassListTitle, CustomSubClassRowWrapper, CustomSubClassRowWrapperTextWrapper } from './CustomSubClassListMobile.styles';
import { DeleteCustomClass } from './DeleteCustomClass';
import { CUSTOM_CLASS_NAME_MAX_LENGTH } from './EditCustomClass';
import { DrawerHeader, DrawerWrapper, EditCustomClassInputRow, FloatingLabeInInput, ImageTile, InputWithLabelOnTopWrapper, ModalCloseButtonWrapper } from './EditCustomClass.styles';
import { ReactComponent as ModalCloseButtonSvg } from './svgs/modal-close-button.svg';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';

export const CustomSubClassListMobile = observer(({
  item,
  updateSubClasses,
  isEditingClass,
  relevantImage,
  relevantFallbackColor,
  RelevantSvg,
}) => {
  const { userStore } = useStore();
  const [isEditingSubClass, setIsEditingSubClass] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [formFields, setFormFields] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { customClassStore } = useStore();
  const subClassNameInputRef = useRef(null);
  const { customSubClasses } = item;
  const isBlockedByRoleOrPaymentStatus = userStore.isReadOnlyMode || userStore.isActionBlockedByRole('addCustomSubClass') || userStore.isActionBlockedByRole('editCustomSubClass');

  useEffect(() => {
    if (subClassNameInputRef.current) {
      setTimeout(() => {
        subClassNameInputRef.current.focus();
      }, 100);
    }
  }, [isEditingSubClass]);

  useEffect(() => {
  }, [customSubClasses])


  const updateField = (field, value) => {
    resetFormInputError(field);
    if (field === 'name' && value.length > CUSTOM_CLASS_NAME_MAX_LENGTH) {
      value = value.substring(0, CUSTOM_CLASS_NAME_MAX_LENGTH);
    }
    const newFormFields = { ...formFields, [field]: value };
    setFormFields(newFormFields);
  }

  const updateFormErrors = (data) => {
    const errors = {};
    const fields = [
      { name: 'name', message: 'Name is required' },
    ];

    fields.forEach(field => {
      const value = data[field.name];
      if (!value || !value?.trim()) {
        errors[field.name] = field.message;
      }
    });

    setFormErrors(errors);
    return errors;
  }

  const resetFormInputError = (input) => {
    delete formErrors[input];
  }

  const handleSave = async (data) => {
    if (isBlockedByRoleOrPaymentStatus) {
      userStore.setShowLimitedSharedAccess(true);
    } else {
      const errors = updateFormErrors(data);
      const isFormValid = Object.keys(errors).length === 0;
      if (isFormValid) {
        let updatedSubClasses = [];
        if (!isNullOrUndefined(data.id)) { // edit sub-class
          updatedSubClasses = customSubClasses.map(subClass => {
            if (subClass.id === data.id) {
              return { ...subClass, name: data.name }
            }
            return subClass;
          });
          if (isEditingClass) { // edit class
            await customClassStore.updateCustomSubClass({ ...data, customClassId: item.id });
          }
        } else { // create sub-class
          // Filter numeric IDs and find the max
          const numericIds = customSubClasses
            .map(subClass => parseInt(subClass.id))
            .filter(id => !isNaN(id)); // Ensure the ID is numeric
          const maxId = numericIds.length > 0 ? Math.max(...numericIds) : 0;
          // Set newId to be maxId + 1
          const newId = maxId + 1;
          updatedSubClasses = [...customSubClasses, { ...data, id: newId }];
          if (isEditingClass) { // edit class
            const newClass = await customClassStore.createCustomSubClass({ ...data, customClassId: item.id });
            const lastSubClass = newClass.customSubClasses.pop();
            updatedSubClasses = [...customSubClasses, { ...data, id: lastSubClass.id }];
          }
        }
        updateSubClasses?.(updatedSubClasses);
        setIsEditingSubClass(false);
        setFormFields({})
      }
    }
  }

  // const onKeyPress = (e) => {
  //   if (e.keyCode === 13) {
  //     setIsEditingSubClass(false);
  //     //handleSave({ id: formFields.id, customClassId: item.id, name: formFields.name });
  //   }
  // }

  const deleteCustomSubClass = (data) => {
    if (isEditingClass) {
      customClassStore.deleteCustomSubClass(data);
    }
    const updatedSubClasses = customSubClasses.filter(subClass => subClass.id !== data.id);
    updateSubClasses?.(updatedSubClasses);
  }

  return (
    <CustomSubClassListMobileWrapper>

      <CustomSubClassListTitle>Custom sub-class:</CustomSubClassListTitle>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2px', padding: '4px 8px' }}>

        {customSubClasses?.map((subClass, index) => {
          const subClassId = subClass.id || (index + 1);
          return (
            <CustomSubClassRowWrapper key={index} onClick={() => {
              setFormFields({ id: subClassId, name: subClass.name });
              setIsEditingSubClass(true);
            }}>
              <CustomClassRowCollapsibleDetailsWrapper>
                <ImageTile
                  image={relevantImage}
                  fallbackColor={relevantFallbackColor}
                  size={24}
                  borderRadius={6}
                >
                  <RelevantSvg />
                </ImageTile>
                <CustomSubClassRowWrapperTextWrapper>{subClass.name}</CustomSubClassRowWrapperTextWrapper>
                <EditPencilSvg />
              </CustomClassRowCollapsibleDetailsWrapper>
            </CustomSubClassRowWrapper>
          )
        })}

        <CustomSubClassEmptyStateRowWrapper onClick={() => { setIsEditingSubClass(true); setFormFields({}); }}>
          <CustomSubClassEmptyStateRowIconWrapper><EmptyStatePlusIcon /></CustomSubClassEmptyStateRowIconWrapper>
          <CustomSubClassEmptyStateRowTextWrapper>Add sub-class</CustomSubClassEmptyStateRowTextWrapper>
        </CustomSubClassEmptyStateRowWrapper>

      </div>
      <Drawer
        closable={false}
        visible={isEditingSubClass}
        width={'100%'}
        height={'auto'}
        className={'editCustomSubClassDrawerContainer_mobile'}
        placement={'bottom'}
        destroyOnClose={true}
        onClose={() => { setIsEditingSubClass(false) }} >
        <DrawerWrapper>
          <DrawerHeader style={{ padding: '14px 20px', boxShadow: 'none' }}>
            {formFields.id === undefined ? 'Create' : 'Edit'} custom sub-class
            <ModalCloseButtonWrapper onClick={() => { setIsEditingSubClass(false) }}><ModalCloseButtonSvg /></ModalCloseButtonWrapper>
          </DrawerHeader>
          <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
            <EditCustomClassInputRow>
              <InputWithLabelOnTopWrapper withError={formErrors.description}>
                <FloatingLabeInInput>Sub-class name</FloatingLabeInInput>
                <Input ref={subClassNameInputRef} style={{ height: '64px' }} value={formFields.name} onChange={(e) => { updateField('name', e.target.value) }} />
              </InputWithLabelOnTopWrapper>
            </EditCustomClassInputRow>
            <PrimaryButtonWithIcon mobileHeight={56} mobileBorderRadius={12} style={{ gap: '2px' }} onClick={(e) => { e.stopPropagation(); handleSave({ id: formFields.id, customClassId: item.id, name: formFields.name }); }}>
              {isBlockedByRoleOrPaymentStatus && <ActionLockedIconWithTooltip iconColor={'#fff'} marginLeft={'0px'} isRoleBlocked={userStore.isActionBlockedByRole('addCustomSubClass') || userStore.isActionBlockedByRole('editCustomSubClass')} />}
              {formFields.id === undefined ? 'Create' : 'Save'}
            </PrimaryButtonWithIcon>
            {formFields.id !== undefined && <SecondaryButtonWithIcon onClick={() => {
              if (userStore.isReadOnlyMode || userStore.isActionBlockedByRole('deleteCustomSubClass')) {
                userStore.setShowLimitedSharedAccess(true);
              } else {
                setIsDelete(true);
              }
            }} style={{ color: '#ff0000' }}  mobileHeight={56} mobileBorderRadius={12} >
            {userStore.isReadOnlyMode || userStore.isActionBlockedByRole('deleteCustomSubClass') ? <ActionLockedIconWithTooltip iconColor={'#ff0000'} marginLeft={'0px'} isRoleBlocked={userStore.isActionBlockedByRole('deleteCustomSubClass')} /> : <EraseCustomClassSvg />}
            Delete custom sub-class
            </SecondaryButtonWithIcon>}
          </div>
        </DrawerWrapper>
      </Drawer>

      {isDelete && <DeleteCustomClass isCustomSubClass item={{ customClassId: item.id, colorCode: item.colorCode, icon: item.icon, name: formFields.name, id: formFields.id }} onClose={() => { setIsDelete(false); }} onDelete={() => { deleteCustomSubClass({ id: formFields.id, customClassId: item.id }); setIsEditingSubClass(false) }}
        relevantImage={relevantImage}
        relevantFallbackColor={relevantFallbackColor}
        RelevantSvg={RelevantSvg}
      />}
    </CustomSubClassListMobileWrapper>
  )
})

const EmptyStatePlusIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 12.25L7 1.75" stroke="#9097A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.25 7H1.75" stroke="#9097A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

const EditPencilSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.1401 0.296118C13.9442 0.101468 13.6273 0.102104 13.4322 0.297538L6.24666 7.49578C6.18683 7.55572 6.14324 7.62983 6.11997 7.71118L5.40699 10.204C5.3638 10.355 5.50407 10.4944 5.65524 10.4507L8.15079 9.72933C8.23222 9.70579 8.30633 9.662 8.36616 9.60206L13.6412 4.31765L11.5306 2.20704L12.2377 1.49993L14.3477 3.60992L15.5517 2.40382C15.7468 2.20838 15.7462 1.89216 15.5503 1.69751L14.1401 0.296118Z" fill="#1A1B1D" />
      <path d="M14 8V13.9C14 13.9552 13.9552 14 13.9 14H2.1C2.04477 14 2 13.9552 2 13.9V2.1C2 2.04477 2.04477 2 2.1 2H8" stroke="#1A1B1D" strokeWidth="1.5" />
    </svg>
  )
}

const EraseCustomClassSvg = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.75 9.5C18.75 9.5 18.2262 15.9022 17.9194 18.9881C17.869 19.4947 17.4419 19.8757 16.9328 19.876L8.15189 19.8811C7.64227 19.8814 7.2143 19.5 7.16174 18.9931C6.84059 15.8953 6.25 9.5 6.25 9.5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M20 6.55469H5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M17 6.55288C15.9225 6.55288 15.5944 5.67353 15.542 4.75719C15.504 4.09153 15.0116 3.5 14.3448 3.5H10.6552C9.98842 3.5 9.49604 4.09153 9.45799 4.75719C9.40562 5.67353 9.07753 6.55288 8 6.55288" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}