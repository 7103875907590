import styled from "@emotion/styled/macro";
import { colors } from "../../../../styles/theme.styles";

export const InputWithBudgeWrapper = styled.div`
    position:relative;
    width:${props=>props.isWithHoldings ? '50' : '100'}%;
`

export const HoldingsBadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    position:absolute;
    left:8px;
    top: 50%;
    transform: translateY(-50%);
    
`;

export const HoldingsBadgeText = styled.div`
    background-color: ${colors.holdingsBadgeColor};
    padding: 0px 1px 0px 4px;
    border-radius: 2px 0px 0px 2px;
`;

export const HoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid ${colors.holdingsBadgeColor};
`;
