import { deleteData, postFormData, putData } from '../../../../../../../common/utils/network.utils';

export const uploadDocuments = async (itemId,formData) => {
    return (await postFormData(`assets/${itemId}/documents`,formData))
}

export const updateDocument = async (documentId, data) => {
    return (await putData(`documents/${documentId}`, data));
}

export const deleteDocument = async (documentId) => {
    return (await deleteData(`documents/${documentId}`));
}