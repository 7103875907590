import React from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../../app/data/root.store';
import { FilterOptionsWrapper, ListParameterOption } from './ListParameterFilter.styles';
import { CheckmarkIcon } from '../../../../../assets/icons/common/common-icons';


export const ListParameterFilter = ({param,paramIndex}) => {
    const { filtersStore } = useStore();

    const handleOptionClick = (optionIndex,isSelected) => {
        filtersStore.setListOptionSelected(paramIndex, optionIndex, !isSelected);
    }

    return (<Observer>{() => (
        <FilterOptionsWrapper>
            {param.options?.map((option,index)=>(
                <ListParameterOption key={index} onClick={()=>{handleOptionClick(index,option.isSelected)}} isSelected={option.isSelected}>{option.label} {option.isSelected ? <CheckmarkIcon /> : ''}</ListParameterOption>
            ))}
        </FilterOptionsWrapper>
    )}</Observer>)
}
