import { observer } from 'mobx-react';
import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { MobileBackButton } from '../../../assets/icons/common/common-icons';
import { ROUTES } from "../../../common/constants/routes.const";
import { useStore } from '../../app/data/root.store';
import { AppHeader } from "../../layout/components/Header/Header";
import { PageWrapper, RightSidePushMenuWrapper } from '../../layout/components/Layout.styles';
import { MobileBackButtonWrapper } from "../../layout/components/MobileLayout.styles";
import { FinancialSettingsContent } from './FinancialSettings/FinancialSettingsContent';
import { HeaderTitle, ProfileHeader, ProfileMobileHeader } from './Profile.styles';
import { MenuItemWrapper, MenuItemsWrapper, MenuWrapper } from '../../layout/components/Sidebar/MobileMoreMenu.styled';
import { PreferenceDataBox } from './PreferenceDataBox/PreferenceDataBox';
import { CustomClassListContent } from './CustomClassList/CustomClassListContent';
import { removeUrlParam } from '../../../common/utils/navigation.utils';
import { useHistory, useLocation } from "react-router-dom";
import { HoldingEntityListContent } from './HoldingEntityList/HoldingEntityListContent';

export const Preferences = observer(({ isWelcome }) => {
	const { userStore, uiStore } = useStore();
	const history = useHistory();
	const location = useLocation();
	const [params] = useState({
		spotlight: new URLSearchParams(location.search).get('spt'),
	});

	useEffect(() => {
		removeUrlParam(history, location, 'spt')
	}, [history, location])

	useEffect(() => {
		uiStore.setIsRightMenuOpen(false);
		uiStore.setIsUserMenuActive(true);
		return () => {
			uiStore.setIsUserMenuActive(false);
		}
	}, [userStore, uiStore]);


	return (
		<>
		{!isWelcome && <>
			{uiStore.isDesktopView ? <AppHeader /> : <ProfileMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
				<MobileBackButtonWrapper onClick={() => { uiStore.setIsAppMobileMenuOpen(true) }}><MobileBackButton /></MobileBackButtonWrapper>
				<HeaderTitle>Preferences</HeaderTitle>
			</ProfileMobileHeader>}
		</>}
		<RightSidePushMenuWrapper />
		<PageWrapper withHeader={!isWelcome} style={{ ...(!uiStore.isDesktopView ? { background: '#000', height: '100vh' } : {}) }} id='preferencesPage'>
		
			<div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
				{uiStore.isDesktopView ? <><ProfileHeader>Preferences</ProfileHeader>

					<PreferenceDataBox title={userStore.isViewingOwnPortfolio ? 'Financial settings' : `${userStore.firstNameCapitalize}'s financial settings`} defaultOpen={params?.spotlight !== 'customClass'}>
						<FinancialSettingsContent />
					</PreferenceDataBox>

					<PreferenceDataBox title={userStore.isViewingOwnPortfolio ? 'Entities management' : `${userStore.firstNameCapitalize}'s entities management`} defaultOpen={true || params?.spotlight === 'entitiesManagement'}>
						<HoldingEntityListContent />
					</PreferenceDataBox>

					<PreferenceDataBox title={userStore.isViewingOwnPortfolio ? 'Custom classes and sub-classes' : `${userStore.firstNameCapitalize}'s custom classes and sub-classes`} defaultOpen={true || params?.spotlight === 'customClass'}>
						<CustomClassListContent />
					</PreferenceDataBox>

				</> :
					<MenuWrapper style={{ padding: '12px 16px 0 16px' }}>
						<MenuItemsWrapper>
							<MenuItemWrapper>
								<NavLink to={ROUTES.financialSettings.url}><div className="link-title">{ROUTES.financialSettings.title}</div><RightArrowIcon /></NavLink>
							</MenuItemWrapper>
						</MenuItemsWrapper>
						<MenuItemsWrapper>
							<MenuItemWrapper>
								<NavLink to={ROUTES.entitiesManagement.url}><div className="link-title">{ROUTES.entitiesManagement.title}</div><RightArrowIcon /></NavLink>
							</MenuItemWrapper>
						</MenuItemsWrapper>
						<MenuItemsWrapper>
							<MenuItemWrapper>
								<NavLink to={ROUTES.customClassList.url}><div className="link-title">{ROUTES.customClassList.title}</div><RightArrowIcon /></NavLink>
							</MenuItemWrapper>
						</MenuItemsWrapper>
					</MenuWrapper>
				}
			</div>

		</PageWrapper>
	</>)
})

const RightArrowIcon = () => (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M10.3198 7.98683L13.6532 12.0005L10.3198 16.0142" stroke="white" strokeWidth="1.5" />
</svg>)