import { observer } from 'mobx-react';
import React from 'react';
import { CommonPopover } from '../../../../common/components/CommonPopover/CommonPopover';
import { useStore } from '../../../app/data/root.store';
import { ReactComponent as BackArrowIcon } from '../../assets/back-arrow-icon.svg';
import { QuestionnaireBackButtonWrapper, QuestionnaireMobileHeaderButtonWrapper, QuestionnaireTopHeaderWrapper, WhyWeAskWrapper, WhyWeAskWrapperHover } from './OnBoardingQuestionnaire.styles';
import { questionnaireIcons } from './assets/questionnaireIcons';
import { OnBoardingQuestionnaireMobileProfileMenu } from './OnBoardingQuestionnaireMobileProfileMenu';

const { 
  QuestionMarkSvg,
} = questionnaireIcons;



export const OnBoardingQuestionnaireHeader = observer(({showBackButton,whyDoWeAskContent}) => {
  const { onBoardingStore, uiStore } = useStore();

  return (
    
        <QuestionnaireTopHeaderWrapper >
          {showBackButton && <> { uiStore.isDesktopView ? 
            <QuestionnaireBackButtonWrapper onClick={() => { onBoardingStore.handleBackButton() }}><BackArrowIcon />Back</QuestionnaireBackButtonWrapper> :
            <QuestionnaireMobileHeaderButtonWrapper onClick={() => { onBoardingStore.handleBackButton() }}><MobileBackButtonIcon /></QuestionnaireMobileHeaderButtonWrapper>
            }</>
          }
          <div style={{ flex: 1 }} />
          {whyDoWeAskContent && <CommonPopover popoverTrigger={
             uiStore.isDesktopView ? <WhyWeAskWrapper><QuestionMarkSvg />Why do we ask?</WhyWeAskWrapper> : <QuestionnaireMobileHeaderButtonWrapper><MobileWhyIcon /></QuestionnaireMobileHeaderButtonWrapper>
            } popoverHoverStyle={{ width: '232px' }} popoverHover={<WhyWeAskWrapperHover>{whyDoWeAskContent}</WhyWeAskWrapperHover>} />}
          { !uiStore.isDesktopView && <OnBoardingQuestionnaireMobileProfileMenu />}
        </QuestionnaireTopHeaderWrapper>

    
  )
})

const MobileBackButtonIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 2.66699L4.66667 8.00033L10 13.3337" stroke="#585D66" strokeWidth="1.5"/>
</svg>
)

const MobileWhyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path d="M14.5416 16.64H12.9416C12.9256 16.432 12.9096 16.256 12.9096 16.032C12.9096 15.072 13.2776 14.288 14.2056 13.616L14.9416 13.088C15.4696 12.704 15.6936 12.24 15.6936 11.696C15.6936 10.864 15.1336 10.08 13.9656 10.08C12.7816 10.08 12.1576 10.992 12.1576 11.904C12.1576 12.144 12.1896 12.448 12.2536 12.624L10.4456 12.496C10.3816 12.272 10.3496 12.016 10.3496 11.76C10.3496 10.16 11.5496 8.41602 13.9656 8.41602C16.3336 8.41602 17.6136 9.98402 17.6136 11.616C17.6136 12.88 16.9416 13.776 16.0136 14.432L15.3256 14.912C14.7976 15.28 14.5256 15.808 14.5256 16.432C14.5256 16.464 14.5256 16.544 14.5416 16.64ZM12.5896 18.944C12.5896 18.304 13.1016 17.76 13.7576 17.76C14.3976 17.76 14.9256 18.304 14.9256 18.944C14.9256 19.584 14.3976 20.112 13.7576 20.112C13.1016 20.112 12.5896 19.584 12.5896 18.944Z" fill="#585D66"/>
  </svg>
)

