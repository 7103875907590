import { keyframes } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors, views } from "../../../../common/styles/theme.styles";

const typing = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const BlinkingCursorAnim = keyframes`
  from, to {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  `;

export const TextContainer = styled.div`
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  text-align: start;
  /* border-right: 0.15em solid ${colors.gray1}; */
  min-height: ${props=> props.minHeight ? props.minHeight : 46}px ;
  font-weight: 450;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 20px;
  background: rgba(225, 230, 240, 0.5);
  border-radius: 12px 12px 12px ${props => props.textOnly ? '12px' : '0px'};
  flex: 1;
  
  ${views.desktop}{
    animation: ${typing} 3s steps(40, end);
    ${props=>props.textOnly ? `margin-left: 62px` : ``};
  }
  ${views.tablet}{
    width:100%
  }
  
`;

const chatRowEaseIntoView = keyframes`
    0% {
       opacity: 0;
       transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
        
    }
`;

export const ChatRowWrapper = styled.div`
  ${flexCenter};
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding-bottom: 16px;
  animation-name: ${chatRowEaseIntoView};
  animation-duration: ${props => 'duration' in props ? (props.duration + 'ms') : '300ms'} ;
  animation-timing-function: linear;
  ${views.tablet}{
    flex-direction: column;
  }
`;

export const AdamChatIconWrapper = styled.div`
  ${flexCenter};
  ${props=>props.extraContent ? `align-self: flex-start` : ``};
  max-height: 48px;
  svg {
    width: 48px;
    height: 48px;
  }
  img {
    width: 48px;
    height: 48px;
  }
`;

export const BlinkingCursor = styled.span`
  color: ${colors.darkGray3};
  animation: ${BlinkingCursorAnim} 0.75s step-end infinite;
`;