import { Select } from "antd";
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { ArrowDownIcon, MobileBackButton } from "../../../../assets/icons/common/common-icons";
import { ActionLocked } from "../../../../common/components/ActionLocked/ActionLocked";
import { ActionLockedIconWithTooltip } from "../../../../common/components/ActionLocked/ActionLockedIconWithTooltip";
import { CURRENCY_SYMBOL } from "../../../../common/constants/currency-symbol.const";
import { ROUTES } from "../../../../common/constants/routes.const";
import { colors } from "../../../../common/styles/theme.styles";
import { navigateToUrl } from "../../../../common/utils/navigation.utils";
import { isNullOrUndefined } from "../../../../common/utils/object.utils";
import { useAnalytics } from "../../../app/data/AnalyticsProvider";
import { useStore } from "../../../app/data/root.store";
import { AppHeader } from "../../../layout/components/Header/Header";
import { PageWrapper, RightSidePushMenuWrapper } from "../../../layout/components/Layout.styles";
import { MobileBackButtonWrapper } from "../../../layout/components/MobileLayout.styles";
import { CardContentBoxWrapper } from "../../../wealth/components/wealth.commons.styles";
import { getProfileFinancialSettings } from "../../data/profile.mixpanel-events";
import {
  ActionsButton,
  ActionsStickyWrapper,
  ActionsWrapper,
  DataBoxFormContentWrapper,
  DataBoxFormWrapper,
  DataBoxInfoWrapper,
  DataBoxInputElement,
  DataBoxInputLabel,
  DataBoxInputWrapper,
  HeaderTitle,
  InfoBoxDescription,
  InfoBoxTitle,
  InfoBoxWrapper,
  ProfileMobileHeader,
  StyledInput,
  StyledMoneyWithCurrencyInput,
  StyledSelect
} from '../Profile.styles';
import { Conformation } from "../../../wealth/pages/asset/components/conformation/Conformation";

const { Option } = Select;

export const FinancialSettingsContent = observer(() => {
  const { userStore, metadataStore, uiStore } = useStore();
  const [haveChanges, setHaveChanges] = useState(false);
  const [editedChanges, setEditedChanges] = useState({});
  const { mixpanelTrack } = useAnalytics()
  const { isDemoMode: is_demo, isReadOnlyMode: isPaymentStatusBlocked } = userStore;
  const isRoleBlocked = userStore.isActionBlockedByRole('updateFinancialSettings');

  useEffect(() => {
    userStore.fetchProfile();
    uiStore.setIsRightMenuOpen(false);
    uiStore.setIsUserMenuActive(true);
    return () => {
      uiStore.setIsUserMenuActive(false);
    }
  }, [userStore, uiStore]);

  const handleFieldChange = (fieldName, value) => {
    if (isRoleBlocked) {
      return;
    };
    let updatedChanges = {};
    if (userStore.profileData[fieldName] !== value && (!isNullOrUndefined(userStore.profileData[fieldName]) || !isNullOrUndefined(value))) {
      updatedChanges = {
        ...editedChanges,
        [fieldName]: value
      }
    } else {
      updatedChanges = { ...editedChanges };
      delete updatedChanges[fieldName];
    }

    const updatedProfileData = {
      ...userStore.profileData,
      ...updatedChanges
    }
    setEditedChanges(updatedChanges);
    setHaveChanges(JSON.stringify(updatedProfileData) !== JSON.stringify(userStore.profileData));
    userStore.updateProfile({
      [fieldName]: value
    });
  }

  const handleCancelChanges = () => {
    userStore.cancelProfileUpdates(['monthlyIncome', 'monthlyIncomeCurrency', 'monthlyExpenses', 'monthlyExpensesCurrency', 'defaultCashAccountId', 'defaultCurrency']);
    setEditedChanges({});
    setHaveChanges(false);
  }

  const handleSaveChanges = async () => {
    const response = await userStore.saveProfileUpdates(['monthlyIncome', 'monthlyIncomeCurrency', 'monthlyExpenses', 'monthlyExpensesCurrency', 'defaultCashAccountId', 'defaultCurrency']);
    mixpanelTrack('Account Updated', getProfileFinancialSettings(response, is_demo, []));
    setEditedChanges({});
    setHaveChanges(false);
  }

  return (
    <>
      <CardContentBoxWrapper noBoxShadow borderless>
        <DataBoxFormContentWrapper>
          <DataBoxFormWrapper>
            <DataBoxInputWrapper>
              <DataBoxInputLabel>
                Household w2 income (monthly salaries)
              </DataBoxInputLabel>
              <ActionLocked action={'cashflowMonthlyIncome'}>
                <StyledMoneyWithCurrencyInput>
                  <StyledInput
                    type='number' name='monthlyIncome' className={`${isPaymentStatusBlocked || isRoleBlocked ? 'lockedMonthlyIncome' : ''}`} min='0'
                    disabled={isPaymentStatusBlocked || isRoleBlocked}
                    value={userStore.profileData.monthlyIncome}
                    suffix={isPaymentStatusBlocked && <ActionLockedIconWithTooltip iconColor={colors.darkGray3} />}
                    prefix={CURRENCY_SYMBOL[userStore.profileData.monthlyIncomeCurrency]}
                    onChange={(e) => { handleFieldChange('monthlyIncome', e.target.value) }} style={{ flex: 0.7, borderRadius: '4px 0 0 4px' }} />
                  <StyledSelect name='monthlyIncomeCurrency' style={{ flex: 0.3, borderRadius: '0 4px 4px 0' }}
                    value={userStore.profileData.monthlyIncomeCurrency} showSearch
                    disabled={isPaymentStatusBlocked || isRoleBlocked}
                    onChange={(val) => { handleFieldChange('monthlyIncomeCurrency', val || '') }}
                    suffixIcon={<ArrowDownIcon />} >
                    {Object.keys(CURRENCY_SYMBOL).map(value => (
                      <Option key={value} value={value} >{value}</Option>
                    ))}
                  </StyledSelect>
                </StyledMoneyWithCurrencyInput>
              </ActionLocked>
            </DataBoxInputWrapper>
            <DataBoxInputWrapper>
              <DataBoxInputLabel>
                Household cost of living (monthly)
              </DataBoxInputLabel>
              <ActionLocked action={'cashflowMonthlyExpenses'}>
                <StyledMoneyWithCurrencyInput>
                  <StyledInput type='number' name='monthlyExpenses' className={`${isPaymentStatusBlocked || isRoleBlocked ? 'lockedMonthlyIncome' : ''}`} min='0'
                    disabled={isPaymentStatusBlocked || isRoleBlocked}
                    value={userStore.profileData.monthlyExpenses}
                    suffix={isPaymentStatusBlocked && <ActionLockedIconWithTooltip iconColor={colors.darkGray3} />}
                    prefix={CURRENCY_SYMBOL[userStore.profileData.monthlyExpensesCurrency]}
                    onChange={(e) => { handleFieldChange('monthlyExpenses', e.target.value) }} style={{ flex: 0.7, borderRadius: '4px 0 0 4px' }} />
                  <StyledSelect name='monthlyExpensesCurrency' style={{ flex: 0.3, borderRadius: '0 4px 4px 0' }}
                    value={userStore.profileData.monthlyExpensesCurrency} showSearch
                    disabled={isPaymentStatusBlocked || isRoleBlocked}
                    onChange={(val) => { handleFieldChange('monthlyExpensesCurrency', val || '') }}
                    suffixIcon={<ArrowDownIcon />} >
                    {Object.keys(CURRENCY_SYMBOL).map(value => (
                      <Option key={value} value={value} >{value}</Option>
                    ))}
                  </StyledSelect>
                </StyledMoneyWithCurrencyInput>
              </ActionLocked>
            </DataBoxInputWrapper>
            <DataBoxInputWrapper>
              <DataBoxInputLabel>
                Cash account
              </DataBoxInputLabel>
              <DataBoxInputElement>
                <StyledSelect name='defaultCashAccountId'
                  disabled={isRoleBlocked}
                  value={userStore.profileData.defaultCashAccountId}
                  onChange={(val) => { handleFieldChange('defaultCashAccountId', val || null) }}
                  suffixIcon={<ArrowDownIcon />} >
                  <Option value={null} >None</Option>
                  {metadataStore.cashAccounts.map(ca => (
                    <Option key={ca.id} value={ca.id} >{ca.title}</Option>
                  ))}
                </StyledSelect>
              </DataBoxInputElement>
            </DataBoxInputWrapper>
            <DataBoxInputWrapper>
              <DataBoxInputLabel>
                Preferred currency
              </DataBoxInputLabel>
              <DataBoxInputElement>
                <StyledSelect name='defaultCurrency'
                  disabled={isRoleBlocked}
                  value={userStore.profileData.defaultCurrency} showSearch
                  onChange={(val) => { handleFieldChange('defaultCurrency', val || '') }}
                  suffixIcon={<ArrowDownIcon />} >
                  {Object.entries(CURRENCY_SYMBOL).map(([value, symbol]) => (
                    <Option key={value} value={value} >{value}</Option>
                  ))}
                </StyledSelect>
              </DataBoxInputElement>
            </DataBoxInputWrapper>
          </DataBoxFormWrapper>
          <DataBoxInfoWrapper>
            <InfoBoxWrapper>
              <InfoBoxTitle>
                Why do we ask this?
              </InfoBoxTitle>
              <InfoBoxDescription>
                This helps us reflect your monthly cost of living into your cash flow
              </InfoBoxDescription>
            </InfoBoxWrapper>
          </DataBoxInfoWrapper>
        </DataBoxFormContentWrapper>
        {haveChanges &&
          <ActionsWrapper>
            <ActionsStickyWrapper>
              <ActionsButton borderless onClick={handleCancelChanges}>Cancel changes</ActionsButton>
              <ActionsButton black onClick={handleSaveChanges} >Save</ActionsButton>
            </ActionsStickyWrapper>
          </ActionsWrapper>
        }
      </CardContentBoxWrapper>
      <Conformation
        when={haveChanges}
        onSave={handleSaveChanges}
      />
      </>
  );
});

export const FinancialSettingsMobilePage = observer(({isWelcome}) => {
  const { uiStore } = useStore();
  let history = useHistory();

  return (<>
    {!isWelcome && <>
      {uiStore.isDesktopView ? <AppHeader /> : <ProfileMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
        <MobileBackButtonWrapper onClick={() => { navigateToUrl(history, ROUTES.preferences.url) }}><MobileBackButton /></MobileBackButtonWrapper>
        <HeaderTitle>Financial Settings</HeaderTitle>
      </ProfileMobileHeader>}
    </>}
    <RightSidePushMenuWrapper />
    <PageWrapper withHeader>
      <FinancialSettingsContent />
    </PageWrapper>
  </>
  )
})