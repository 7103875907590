import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ArrowDownIcon } from '../../../assets/icons/common/common-icons';
import { CommonBadge } from '../../../common/components/CommonBadge/CommonBadge';
import { useStore } from '../../app/data/root.store';
import { DataBoxFormContentWrapper, FormHeaderWrapper, FormSubtitle, FormTitle, HeaderIconWrapper, HeaderTextWrapper } from './Reports.styles';

export const ReportsDataBox = observer(({ children, title, subtitle, headerSvg, isLimited = false, alwaysOpen = false, defaultOpen = false }) => {
  const { userStore, billingStore } = useStore()
  const [isOpen, setIsOpen] = useState(defaultOpen || alwaysOpen || false);

  useEffect(() => {
    setIsOpen(defaultOpen || alwaysOpen);
  }, [defaultOpen, alwaysOpen]);

  const handleOnOpenChange = () => {
    if (alwaysOpen) {
      if (isLimited) {
        billingStore.setShowPricingTableModal(true);
      }
      return;
    }
    setIsOpen(isOpen ? false : !isLimited);
    if (!isOpen && isLimited && userStore.isViewingOwnPortfolio) {
      billingStore.setShowPricingTableModal(true);
    }
  }

  return (
    <DataBoxFormContentWrapper isLimited={isLimited}>
      <FormHeaderWrapper isOpen={isOpen} onClick={handleOnOpenChange}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{headerSvg}</div>
          <HeaderTextWrapper>
            <FormTitle isLimited={isLimited}>{title} {isLimited ? <CommonBadge badgeType="limitedFeature" rounded noMargin color={!userStore.isFreeTier || userStore.isDemoOrReadOnlyMode ? '#2D72FF' : ''} title={'Upgrade'} /> : null}</FormTitle>
            <FormSubtitle>{subtitle}</FormSubtitle>
          </HeaderTextWrapper>
        </div>
        <HeaderIconWrapper isOpen={isOpen} isLimited={isLimited}><ArrowDownIcon /></HeaderIconWrapper>
      </FormHeaderWrapper>
      {isOpen && (<>
        {children}
      </>
      )}
    </DataBoxFormContentWrapper>
  );
});