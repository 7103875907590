import { Tooltip } from 'antd';
import { Observer } from 'mobx-react';
import moment from 'moment';
import currency from 'currency.js';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-cool-inview';
import DetectableOverflow from 'react-detectable-overflow';
import { ApproveIcon, TransactionSuggestionIcon } from '../../../../../../assets/icons/common/common-icons';
import { ActionLocked } from '../../../../../../common/components/ActionLocked/ActionLocked';
import { CURRENCY_SYMBOL } from '../../../../../../common/constants/currency-symbol.const';
import { addMonths } from '../../../../../../common/utils/dates.utils';
import { displayMoneyValue, displayMoneyValueWithDigits, numberWithCommas, percentageDisplay, quantityFormat, quantityFormatWithCommas } from '../../../../../../common/utils/number-display.utils';
import { useStore } from '../../../../../app/data/root.store';
import { EditEvent } from '../Actuals/EditEvent';
import {
  AcronymOptionWrapper, ActionButtonWrapper, ActualEventRowBadgeWrapper, BackToTodayButton, CellText,
  EventAmount, EventAmountAndLinkIconWWrapper, EventAtrSubText, EventHoverColumn, EventRemarks, EventRow, EventRowIndicationAndDateWrapper, EventRowLinkedTransactionIconWrapper, EventRowSection,
  HiddenDiv, HoverColumnActionsWrapper,
  HoverColumnRemarksWrapper, IndicationWrapper, MobileEventMoreDetails, MobileEventMoreDetailsWrapper, MobileEventRowDescriptionWrapper, MobileEventRowIconWrapper, MobileEventRowValueWrapper, MobileEventRowWrapper, MobileEventTitle, UnfixedMoneyCurrency, UnfixedMoneyWrapper
} from './AssetEventRow.styles';
import { HoveredRemarks } from './HoveredRemarks';
// import { PredictedAmountDisplay } from './PredictedAmountDisplay/PredictedAmountDisplay';
import { ArrowUpIcon, EditRowIcon } from './assets/icons';
import { categoriesEventTitle, categoriesEventsAfterAmountAttributes, categoriesEventsAttributes, categoriesTransactionAmountAdditionalDataFunction, categoriesValueDisplayFunction } from './categories-events-attributes.const';

export const AssetEventRow = (props) => {
  const { itemId, itemHoldings, itemCategoryId, itemCurrency, event, isMarked, eventIndex, isIncognito, openEditEventModal, onCloseEditEventModal, isEditDisabled } = props;
  const today = new Date();
  const pointData = addMonths(today, event.month);
  const eventDate = moment(pointData);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const { uiStore, eventsStore , itemStore, wealthStore } = useStore();
  const isToday = eventIndex === itemStore.eventsSliderDefaultIndex;
  const { observe, inView, entry } = useInView({});
  const wealthPageContentElement = document.querySelector('#wealthPageContent');
  const listElm = document.querySelector('#item-events-list-wrapper');
  const [isHoveredOn, setIsHoveredOn] = useState(false);
  const [isTooltipNeeded, setIsTooltipNeeded] = useState(false);
  const categoryAttributeColumns = categoriesEventsAttributes[itemCategoryId] || [];
  const categoriesCustomEventTitle = categoriesEventTitle?.[itemCategoryId]?.[event.title];
  const categoryAttributeAfterAmountColumns = categoriesEventsAfterAmountAttributes[itemCategoryId] || [];
  const categoryAmountAdditionalDataFn = categoriesTransactionAmountAdditionalDataFunction[itemCategoryId] || null;
  const categoryValueDisplayFn = categoriesValueDisplayFunction[itemCategoryId] || null;
  const hasTransactionsOnEvent = event.eventData?.transactions && event.eventData?.transactions.length > 0;

  useEffect(() => {
    if (openEditEventModal === event.eventType) {
      setShowEditEventModal(true);
    }
  
    // return () => {}
  }, [openEditEventModal, event.eventType])

  const handleCancelEventOk = async (eventType, formValues, eventDate, selectedTransaction) => {
    const eventBody = {
      date: eventDate,
      title: formValues.eventTitle,
      remarks: formValues.eventRemarks,
      eventType: event.eventType,
      id: event.id,
      eventData: {
        transactions:
          [...(selectedTransaction ? [
            {
              ...selectedTransaction,
            }
          ] : formValues.transactionValue ? [
            {
              date: eventDate,
              value: formValues.transactionValue * (
                eventType.isCustom ?
                  (event.eventData.transactions[0].value < 0 ? -1 : 1) :
                  (eventType.isDistribution ? 1 : -1)
              ),
              ...(hasTransactionsOnEvent && event.eventData.transactions[0].provider === 'manuall' ? {
                id: event.eventData.transactions[0].id
              } : {})

            }
          ] : []),
          ...(eventType.otherTrnsFn ? eventType.otherTrnsFn(eventDate, formValues, event.eventData && event.eventData.transactions ? event.eventData.transactions : []) : [])
          ],
        attributes: [
          // ...(event.eventData?.attributes.length ?
          //   event.eventData?.attributes.map(attr => ({
          //     date: eventDate,
          //     data: attr.data,
          //     id: attr.id
          //   })) : []),
          ...(eventType.attributesToSend ?
            eventType.attributesToSend.map(attr => {
                const attrValue = attr.setFnForSave ? attr.setFnForSave(eventType.isDistribution, formValues[attr.fieldKey], formValues) : formValues[attr.fieldKey];
                if (attrValue === null){return null}
                return {
                  date: eventDate,
                  data: {
                    [attr.dataKey]: attrValue
                  },
                  id: event.eventData.attributes.find(eventAttr => eventAttr.data.hasOwnProperty(attr.dataKey))?.id,
                }
            }).filter(attr=>attr) : [])
        ],
        valueUpdates: (formValues.updatedValue || formValues.updatedValue === 0) ? [
          {
            date: eventDate,
            value: formValues.updatedValue,
            ...(event.eventData?.valueUpdates && event.eventData?.valueUpdates.length ? { id: event.eventData.valueUpdates[0].id } : {})
            // id: event.eventData.valueUpdates[0].id,
          }
        ] : []
      }
    }
    
    await eventsStore.updateEvent(itemId, eventBody, itemStore);
    wealthStore.setGetLastChangesDataAfterExitPage();
    if ( eventType?.hasOwnProperty('showTargetModalAfterSaveFn')){
      const haveTargets = itemStore.itemEvents.slice().reverse().find((eve)=> !eve.id && !eve.eventId);
      if (haveTargets && eventType.showTargetModalAfterSaveFn({formValues})){
        itemStore.setShowEditTargetModal(true,eventType.targetFieldLink);
      }
    } 
  }

  const handleBackToToday = () => {
    const element = entry.target;
    const todayOffSetFromListTop = element.getBoundingClientRect().y - listElm.getBoundingClientRect().y;
    wealthPageContentElement.scrollTo({ top: 280 + todayOffSetFromListTop, behavior: 'smooth' })
  }

  const handleOverflowChange = (isOverflowed) => {
    setIsTooltipNeeded(isOverflowed);
  }

  return (<Observer>{() => (
    <>
      {isToday && <HiddenDiv ref={observe} />}
      {uiStore.isDesktopView ? 
      <ActionLocked action={'editEventRow'}>
        <EventRow categoryAttributeCount={categoryAttributeColumns.length + categoryAttributeAfterAmountColumns.length} hasHappened={true} isMarked={isMarked} onClick={() => setShowEditEventModal(true)}
          onMouseEnter={() => { setIsHoveredOn(true) }} onMouseLeave={() => { setIsHoveredOn(false) }}>
          <EventRowSection  >
            <EventRowIndicationAndDateWrapper>
              <IndicationWrapper></IndicationWrapper>
              <CellText>{eventDate.format('MM-YYYY')}</CellText>
            </EventRowIndicationAndDateWrapper>
          </EventRowSection>
          {/* <EventRowSection>
          <EventRowDateWrapper isHidden={!event.isFirstInMonth && !isFirstRow} isCanceled={event.isCanceled}>
            <EventRowShortnameMonth>{eventDate.format('MMM')}</EventRowShortnameMonth>
            <EventRowFullYear>{eventDate.format('YYYY')}</EventRowFullYear>
          </EventRowDateWrapper>
        </EventRowSection> */}
          <EventRowSection >
            <CellText>{categoriesCustomEventTitle ? categoriesCustomEventTitle : event.title ? event.title : event.eventType}</CellText>
          </EventRowSection>
          {/* <EventRowSection flex={event.eventData.transactions.length && event.eventData.valueUpdates.length ? 0.4 : 1}>
          <EventRowSectionMainTxt>
            {event.eventData.transactions.length ?
              displayMoneyValue(
                Math.abs(event.eventData.transactions[0].value), event.eventData.transactions[0].currency || itemCurrency, isIncognito, (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100) : null}
            {event.eventData.transactions.length === 0 && event.eventData.valueUpdates.length ?
              displayMoneyValue(
                Math.abs(event.eventData.valueUpdates[0].value), itemCurrency, isIncognito, (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100) : null}

            {!itemStore.isHoldingsView && <>
              <HoldingsBadgeSquare /><HoldingsBadgeTriangle />
            </>}

            {event.eventData?.transactions && event.eventData.transactions.length > 0 && event.eventData.transactions[0].provider === 'api' && <EventRowLinkedTransactionIconWrapper >
              <Tooltip title={'Linked transaction'} placement="top">
                <TransactionSuggestionIcon />
              </Tooltip>
            </EventRowLinkedTransactionIconWrapper>}
          </EventRowSectionMainTxt>
          <EventRowSectionAltTxt>{event.title ? event.title : event.eventType}</EventRowSectionAltTxt>
        </EventRowSection> */}

          {categoryAttributeColumns.map((column) => {

            const relevantAttribute = event.eventData.attributes.find(atr => atr.data.hasOwnProperty(column.atrKey));
            const relevantSubAttribute = column.subKey ? event.eventData.attributes.find(atr => atr.data.hasOwnProperty(column.subKey)) : null;
            return (
              <EventRowSection key={column.atrKey}>
                {relevantAttribute ? <EventAmount>
                  {column.type === 'money' ?
                  <OptionalToolTip text={
                   (column.digits ? 
                    displayMoneyValueWithDigits(relevantAttribute.data[column.atrKey], column.customCurrency || itemCurrency, false, 100 , column.digits) :
                    displayMoneyValue(relevantAttribute.data[column.atrKey], column.customCurrency || itemCurrency ) )} /> : 
                  column.type === 'unfixedMoney' ?
                  <CellValueWithOptionalToolTip column={column} relevantAttribute={relevantAttribute} itemCurrency={itemCurrency} holdings={column.withHoldings && itemStore.isHoldingsView && itemHoldings ? (itemHoldings / 100) : 1} /> :
                  column.type === 'cryptoQuantity' && itemStore.item.cryptoCoin ?
                  <CellValueWithOptionalToolTip column={column} relevantAttribute={relevantAttribute} holdings={column.withHoldings && itemStore.isHoldingsView && itemHoldings ? (itemHoldings / 100) : 1} />
                  
                  // <UnfixedMoneyWrapper>
                  // <UnfixedMoneyCurrency>{CURRENCY_SYMBOL[column.customCurrency || itemCurrency] || itemCurrency}</UnfixedMoneyCurrency>
                  // <DetectableOverflow onChange={handleOverflowChange}>
                  //     <Tooltip title={isTooltipNeeded ? ((CURRENCY_SYMBOL[column.customCurrency || itemCurrency] || itemCurrency) + numberWithCommas(relevantAttribute.data[column.atrKey])) : ''} placement={"topLeft"}>{numberWithCommas(relevantAttribute.data[column.atrKey])}</Tooltip>
                  //   </DetectableOverflow>
                  // {/* <span>{relevantAttribute.data[column.atrKey]}</span> */}
                  // </UnfixedMoneyWrapper>
                  :
                  column.type === 'quantityWithCommas' ?
                  quantityFormatWithCommas(relevantAttribute.data[column.atrKey] * (column.withHoldings && itemStore.isHoldingsView && itemHoldings ? (itemHoldings / 100) : 1))
                  :
                  quantityFormat(relevantAttribute.data[column.atrKey] * (column.withHoldings && itemStore.isHoldingsView && itemHoldings ? (itemHoldings / 100) : 1))
                }</EventAmount> : ''}
                {relevantSubAttribute ?
                  <EventAtrSubText>
                    <DetectableOverflow onChange={handleOverflowChange}>
                      <Tooltip title={isTooltipNeeded ? relevantSubAttribute.data[column.subKey] : ''} placement={"topLeft"}>{relevantSubAttribute.data[column.subKey]}</Tooltip>
                    </DetectableOverflow>
                  </EventAtrSubText>
                  : null}
                {/* <EventRemarks>{event.remarks}</EventRemarks> */}
              </EventRowSection>
            )
          })}

          <EventRowSection>
            <EventAmountAndLinkIconWWrapper>
              {!hasTransactionsOnEvent && 
              <EventAmount>
                { event.eventType === 'Annual interest rate' ? percentageDisplay(event.eventData.attributes[0].data.interest * 100,100,3) : 
                  // event.eventType === 'Death benefit update' ? displayMoneyValue(event.eventData.attributes[0].data.deathBenefit, itemCurrency, isIncognito,  (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100) : 
                  displayMoneyValue(0, itemCurrency, isIncognito,  (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100)}  
                </EventAmount>}
              {hasTransactionsOnEvent && 
              <EventAmount isDistribution={event.eventData.transactions[0].value * (event.eventData.transactions[0].provider === "api" ? -1 : 1) > 0}>
                {displayMoneyValue(
              Math.abs(event.eventData.transactions[0].value), itemCategoryId === 36 ? event.eventData.transactions[0].currency : itemCurrency, isIncognito,  (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100)}  
              {/* TODO: Think about this, make general solution ( not categoryId === ) */}
              </EventAmount>} 
              {hasTransactionsOnEvent && event.eventData.transactions[0].provider === "api" && <EventRowLinkedTransactionIconWrapper isDistribution={event.eventData.transactions[0].value * (event.eventData.transactions[0].provider === "api" ? -1 : 1) > 0} >
                <Tooltip title={'Linked transaction'} placement="top">
                  <TransactionSuggestionIcon />
                </Tooltip>
              </EventRowLinkedTransactionIconWrapper>}
            </EventAmountAndLinkIconWWrapper>
            {categoryAmountAdditionalDataFn ? categoryAmountAdditionalDataFn(event, itemCurrency, isIncognito,  (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100) : null }
          </EventRowSection>
          
          {categoryAttributeAfterAmountColumns.map((column) => {
            const relevantAttribute = event.eventData?.attributes.find(atr => column.atrKey in atr.data);
            return (

              <EventRowSection key={column.atrKey}>
                {
                  relevantAttribute ?  displayMoneyValue(
                    relevantAttribute.data[column.atrKey],
                    itemCurrency, isIncognito, (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100) : null
                  }
                {event.eventData.attributes[column.atrKey] && displayMoneyValue(event.eventData.attributes[column.atrKey],itemCurrency)}
              </EventRowSection>
            )
          })}        

          <EventRowSection isCurrencySymbolNotSupported={!CURRENCY_SYMBOL[itemCurrency]}>
          {(event.eventData?.valueUpdates && event.eventData?.valueUpdates.length > 0) && 
            <EventAmount>
               {categoryValueDisplayFn ? categoryValueDisplayFn({
                  value : event.eventData.valueUpdates[0].value * (itemStore.isHoldingsView && itemHoldings ? (itemHoldings / 100) : 1),
                  cryptoCoin: itemStore.item.cryptoCoin,
                  stock: itemStore.item.stock,
                }) 
                : 
                <>
                  {displayMoneyValue(event.eventData.valueUpdates[0].value, itemCurrency, isIncognito,  (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100)}
                  {!CURRENCY_SYMBOL[itemCurrency] && <AcronymOptionWrapper>{itemCurrency}</AcronymOptionWrapper>}
                </>} 
            </EventAmount>}
        </EventRowSection> 

          <EventRowSection hiddenOverflow className="hideOnHover">
            <EventRemarks>{event.remarks}</EventRemarks>
          </EventRowSection>
          {/* <EventRowSection>
          <ActualEventRowBadgeWrapper><ApproveIcon /> Actual</ActualEventRowBadgeWrapper>
        </EventRowSection> */}
          <EventRowSection className="hideOnHover">
            <ActualEventRowBadgeWrapper><ApproveIcon /> Actual</ActualEventRowBadgeWrapper>
          </EventRowSection>

          <EventRowSection hiddenOverflow className="showOnHover">
            <EventHoverColumn>
              <HoverColumnRemarksWrapper>
                {(isHoveredOn) && <HoveredRemarks remarks={event.remarks}></HoveredRemarks>}
              </HoverColumnRemarksWrapper>
              <HoverColumnActionsWrapper>
                <ActionButtonWrapper isOnlyIcon >
                  <EditRowIcon />
                </ActionButtonWrapper>
              </HoverColumnActionsWrapper>
            </EventHoverColumn>
          </EventRowSection>

        </EventRow>
      </ActionLocked> : 
       <ActionLocked action={'editEventRow'}>
            <MobileEventRowWrapper hasHappened={true} isMarked={isMarked} onClick={() => setShowEditEventModal(true)}>
              <MobileEventRowIconWrapper>
                <ActualEventRowBadgeWrapper mobileBox><ApproveIcon /></ActualEventRowBadgeWrapper>
              </MobileEventRowIconWrapper>
              <MobileEventRowDescriptionWrapper>
              <MobileEventTitle>
                {event.title ? event.title : event.eventType}
              </MobileEventTitle>
              <MobileEventRowDescriptionWrapper>
                <MobileEventMoreDetailsWrapper>
                <MobileEventMoreDetails>{eventDate.format('MM-YYYY')}</MobileEventMoreDetails>
                </MobileEventMoreDetailsWrapper>
              </MobileEventRowDescriptionWrapper>
              </MobileEventRowDescriptionWrapper>
              <MobileEventRowValueWrapper>
              <EventAmountAndLinkIconWWrapper>
              {!hasTransactionsOnEvent && 
              <EventAmount>
                { event.eventType === 'Annual interest rate' ? percentageDisplay(event.eventData.attributes[0].data.interest * 100,100,3) : 
                  // event.eventType === 'Death benefit update' ? displayMoneyValue(event.eventData.attributes[0].data.deathBenefit, itemCurrency, isIncognito,  (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100) : 
                  displayMoneyValue(0, itemCurrency, isIncognito,  (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100)}  
                </EventAmount>}
              {hasTransactionsOnEvent && 
              <EventAmount isDistribution={event.eventData.transactions[0].value * (event.eventData.transactions[0].provider === "api" ? -1 : 1) > 0}>
                {displayMoneyValue(
              Math.abs(event.eventData.transactions[0].value), itemCategoryId === 36 ? event.eventData.transactions[0].currency : itemCurrency, isIncognito,  (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100)}  
              {/* TODO: Think about this, make general solution ( not categoryId === ) */}
              </EventAmount>} 
              {hasTransactionsOnEvent && event.eventData.transactions[0].provider === "api" && <EventRowLinkedTransactionIconWrapper isDistribution={event.eventData.transactions[0].value * (event.eventData.transactions[0].provider === "api" ? -1 : 1) > 0} >
                <Tooltip title={'Linked transaction'} placement="top">
                  <TransactionSuggestionIcon />
                </Tooltip>
              </EventRowLinkedTransactionIconWrapper>}
            </EventAmountAndLinkIconWWrapper>
            {categoryAmountAdditionalDataFn ? categoryAmountAdditionalDataFn(event, itemCurrency, isIncognito,  (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100) : null }
              </MobileEventRowValueWrapper>
            </MobileEventRowWrapper>
       </ActionLocked>}

      { uiStore.isDesktopView && isToday && !inView && <BackToTodayButton isJumpDown={entry && entry.target.getBoundingClientRect().y - listElm.getBoundingClientRect().y > wealthPageContentElement.scrollTop} onClick={handleBackToToday} ><ArrowUpIcon />Back to today</BackToTodayButton>}
      {showEditEventModal && <EditEvent event={event} itemCategoryId={itemCategoryId}
        isFormDisabled={isEditDisabled}
        itemId={itemId} itemCurrency={itemCurrency} itemHoldings={itemHoldings}
        onSave={async ({ eventType, formValues, eventDate, selectedTransaction }) => { await handleCancelEventOk(eventType, formValues, eventDate, selectedTransaction) }}
        onClose={() => { setShowEditEventModal(false); onCloseEditEventModal?.() }} />}
    </>
  )}</Observer>)
}


const CellValueWithOptionalToolTip = ({column,relevantAttribute,itemCurrency, holdings}) => {

  const [isTooltipNeeded, setIsTooltipNeeded] = useState(false);
  const [supportedCurrencySymbol] = useState(CURRENCY_SYMBOL[column.customCurrency || itemCurrency])

  const handleOverflowChange = (isOverflowed) => {
    setIsTooltipNeeded(isOverflowed);
  }

  return (
    <UnfixedMoneyWrapper>
    {supportedCurrencySymbol ? <UnfixedMoneyCurrency>{supportedCurrencySymbol}</UnfixedMoneyCurrency> : null}
      <DetectableOverflow onChange={handleOverflowChange}>
        <Tooltip title={isTooltipNeeded ? ( (supportedCurrencySymbol ? supportedCurrencySymbol : '') + numberWithCommas(currency(relevantAttribute.data[column.atrKey],{precision:8}).multiply(holdings).value)) : ''} placement={"topLeft"}>{numberWithCommas(currency(relevantAttribute.data[column.atrKey],{precision:8}).multiply(holdings).value)}</Tooltip>
      </DetectableOverflow>
    {/* <span>{relevantAttribute.data[column.atrKey]}</span> */}
    </UnfixedMoneyWrapper>
  )
}

const OptionalToolTip = ({text}) => {

  const [isTooltipNeeded, setIsTooltipNeeded] = useState(false);
  const handleOverflowChange = (isOverflowed) => {
    setIsTooltipNeeded(isOverflowed);
  }

  return (
      <DetectableOverflow onChange={handleOverflowChange}>
        <Tooltip title={isTooltipNeeded ? text : ''} placement={"topLeft"}>{text}</Tooltip>
      </DetectableOverflow>
  )
}