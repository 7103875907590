import styled from "@emotion/styled/macro";
import { colors } from "../../styles/theme.styles";
import { flexCenter } from "../../styles/combos.styles";

export const AccountSelectionModalWrapper = styled.div`
  color: ${colors.darkGray1};
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height:100%;
  flex:1;
  ${props=>props.withBorderRadius ? `
    border-radius: 8px;
  ` : ``}
`;

export const AccountSelectionModalHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 8px 10px;
`;

export const AccountSelectionModalFooter = styled.div`
    border-top: 1px solid ${colors.gray3};
    padding: 20px 32px;
    ${flexCenter};
    justify-content: space-between;
    @media (max-width: 420px) {
        flex-direction: column-reverse;
        align-items: stretch;
        gap: 8px;
    }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 8px;

  .ant-checkbox-input, .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    :focus{
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color:${colors.darkGray1};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
  }
  .ant-checkbox-checked{
    .ant-checkbox-inner {
        background-color:${colors.darkGray1};
        border-color:${colors.darkGray1};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
        border-radius: 4px;
        ::after{
          transform: rotate(45deg) scale(1) translate(-56%, -58%);
        }
    }
    ::after {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  :hover{
    .ant-checkbox-inner {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  &.ant-checkbox-wrapper-checked:hover{
    .ant-checkbox-inner {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
`

export const AccountSelectionModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  padding: 0 32px 32px;
`;

export const AccountSelectionTitle = styled.div`
  font-family: Circular;
  font-size: 22px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
`;

export const ConfirmationViewRow = styled.div`
    display: inline-flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid ${colors.gray3};
    margin-bottom: 32px;
`;

export const RolesNameIconWrapper = styled.div`
    ${flexCenter}
    width: 30px;
    min-width: 30px;
    height:30px;
    border-radius: 10px;
    color:${props=>(props.isDemoRole || props.isOwner) ? '#fff' : colors.darkGray1 };
    background-color:${props=>props.isDemoRole ? '#2565E7' : props.isOwner ? '#111213' : props.color};
`;

export const ConfirmationViewRowNameAndMail = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const ConfirmationViewRowNameText = styled.div`
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const ConfirmationViewRowEmail = styled.div`
    color: ${colors.darkGray2};
    font-family: Circular;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;