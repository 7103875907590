import { Popover } from 'antd';
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import { useStore } from '../../../app/data/root.store';
import { IconLetter, IconWrapper, MenuSubTitle, MenuTitle, PortfolioItemWrapper, RoleIconLetter, RoleName, RolesNameIconWrapper, SeparatorLine, SharedPortfolioBannerWrapper, SharedPortfolioName, SharedPortfoliosMenuWrapper, SwitchPortfolioText } from './SharedPortfolioBanner.styles';
import { AccountSelectionConfirmation } from '../../../../common/components/AccountSelectionConfirmation/AccountSelectionConfirmation';

export const DesktopSharedPortfolioBanner = () => {
   const { userStore, uiStore } = useStore();

  return (<Observer>{() => (
    <Popover trigger={"click"}  content={SharedPortfoliosMenu({userStore})} overlayClassName='shared_portfolios_menu_wrapper' >
      <SharedPortfolioBannerWrapper isOwner={userStore.isViewingOwnPortfolio} color={userStore.currentPortfolioDetails.color} >
        <IconWrapper isOwner={userStore.isViewingOwnPortfolio}>
          <IconLetter isOwner={userStore.isViewingOwnPortfolio}>
            {userStore.currentPortfolioDetails.name.charAt(0).toUpperCase()}
          </IconLetter>
          </IconWrapper>
          {! uiStore.isSideMenuMinimized && 
          <SharedPortfolioName isOwner={userStore.isViewingOwnPortfolio}>
            {userStore.currentPortfolioDetails.name}
          </SharedPortfolioName>
          }
          <ArrowDownIcon isOwner={userStore.isViewingOwnPortfolio} />
          
      </SharedPortfolioBannerWrapper>
      </Popover>
  )}</Observer>);
}

const SharedPortfoliosMenu = ({userStore}) => {
  const [accountSelectionConfirmation, setAccountSelectionConfirmation] = useState(null);

    const handleSwitchAccountToOwner = () => {
      userStore.switchAccount(userStore.granteeId);
    }

    const handleSwitchAccountToGrantee = (portfolio) => {
      if (!userStore.isDismissedSwitchUser) {
        setAccountSelectionConfirmation(portfolio);
      } else {
        userStore.switchAccount(portfolio.ownerId);
      }
    }

    return (<SharedPortfoliosMenuWrapper>
      <MenuTitle>Hi {userStore.granteeFirstNameCapitalize}</MenuTitle>
      <MenuSubTitle>You are {userStore.isViewingOwnPortfolio ? 'the' : 'a'} {userStore.currentPortfolioDetails.roleDescription} for this<br/>portfolio with {userStore.currentPortfolioDetails.permission} permissions.</MenuSubTitle>
      <PortfolioItemWrapper isSelected={true} >
          <RolesNameIconWrapper color={userStore.currentPortfolioDetails.color} isOwner={userStore.isViewingOwnPortfolio}><RoleIconLetter>
            {userStore.currentPortfolioDetails.name.charAt(0).toUpperCase()}
            </RoleIconLetter></RolesNameIconWrapper>
          <RoleName>{userStore.currentPortfolioDetails.name}</RoleName>
          <SelectedIcon/>
      </PortfolioItemWrapper>
      
      {(userStore.isGranteeHavePortfolio || userStore.sharedPortfolios.length > 1) && <>
        <SeparatorLine />
        <SwitchPortfolioText>Switch portfolio</SwitchPortfolioText>
      </>}
      {userStore.isGranteeHavePortfolio && !userStore.isViewingOwnPortfolio && 
      <PortfolioItemWrapper onClick={handleSwitchAccountToOwner}>
          <RolesNameIconWrapper isOwner={true}><RoleIconLetter>{userStore.granteeFullName.charAt(0)}</RoleIconLetter></RolesNameIconWrapper>
          <RoleName>{userStore.granteeFullName} (Personal)</RoleName> 
          
      </PortfolioItemWrapper> }
      {userStore.sharedPortfolios.map((portfolio, index) => (
        portfolio.ownerId !== userStore.currentPortfolioDetails.ownerId ? <PortfolioItemWrapper key={index} onClick={()=>{handleSwitchAccountToGrantee(portfolio, index)}} >
          <RolesNameIconWrapper color={portfolio.color}><RoleIconLetter>{portfolio.name.charAt(0).toUpperCase()}</RoleIconLetter></RolesNameIconWrapper>
          <RoleName>{portfolio.name}</RoleName>
        </PortfolioItemWrapper> : null
      )) }
      {accountSelectionConfirmation && <AccountSelectionConfirmation userStore={userStore} portfolio={accountSelectionConfirmation} onClose={()=>{setAccountSelectionConfirmation(null)}} onSwitchConfirm={() => {userStore.switchAccount(accountSelectionConfirmation.ownerId)}}  />}
    </SharedPortfoliosMenuWrapper>)
}

const ArrowDownIcon = ({isOwner}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 11L4 6H12L8 11Z" fill={isOwner ? '#fff' : '#000'} />
  </svg>
)

const SelectedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5105 8.604L10.506 15.6125L6.99995 12.1075" stroke="white" strokeWidth="2"/>
  </svg>
)
