import React, { useState } from 'react';
import { PrimaryButtonWithIcon } from '../../../buttons/NewButtons.styles';
import { HighlightedSearchText, InstitutionAutoCompleteResultRowWrapper, ItemContent, ItemLogoWrapper, ItemMoreInfos, ItemText, ItemTitle, ItemTitleAndBadge, LogoPlaceHolder, MoreInfosItem, ReviewMySyncedAccountsButton, Separator } from './InstitutionAutoComplete.styles';
import { ReactComponent as InstitutionPlaceholderIcon } from './icons/institution-placeholder-icon.svg';
import { CommonBadge } from '../../../CommonBadge/CommonBadge';
import DetectableOverflow from 'react-detectable-overflow';
import { Tooltip } from 'antd';
import { cleanURL } from '../../../../utils/string.utils';

export const InstitutionAutoCompleteResultRow = (props) => {
  const { inst, handleItemClick, searchStr, isSeparator, isAutoSync, isAlreadyConnected ,isDesktopView } = props;
  const [isTooltipNeeded, setIsTooltipNeeded] = useState(false);

  const highlightSearchTerm = (title) => {
    if (searchStr) {
      const index = title.toLowerCase().indexOf(searchStr.toLowerCase());
      if (index !== -1) {
        const length = searchStr.length;
        const prefix = title.substring(0, index);
        const suffix = title.substring(index + length);
        const match = title.substring(index, index + length);
        return (
          <span>{prefix}<HighlightedSearchText>{match}</HighlightedSearchText>{suffix}</span>
        )
      }
    }
    return title;
  }

  const handleOverflowChange = (isOverflowed) => {
    setIsTooltipNeeded(isOverflowed);
  }

  return (
    !isSeparator ? <InstitutionAutoCompleteResultRowWrapper onMouseDown={() => { handleItemClick?.(inst) }} isAutoSync={isAutoSync || isAlreadyConnected}>
      {inst.logoBase64 ? <ItemLogoWrapper><img alt={inst.name} src={'data:image/png;base64,' + inst.logoBase64} /></ItemLogoWrapper> :
        (inst.providerName === 'vip' || inst.providerName === 'akoya') && inst.logoURL ? <ItemLogoWrapper><img alt={inst.name} src={inst.logoURL} /></ItemLogoWrapper> :
          inst.logoUrl ? <ItemLogoWrapper><img alt={inst.name} src={inst.logoUrl} /></ItemLogoWrapper> : <LogoPlaceHolder ><InstitutionPlaceholderIcon /></LogoPlaceHolder>
      }
      <ItemContent>
        <ItemText>
          <ItemTitleAndBadge>
            <ItemTitle>
              <DetectableOverflow onChange={handleOverflowChange}>
                <Tooltip title={isTooltipNeeded ? highlightSearchTerm(inst.name) : ''} placement={"topLeft"}>{inst.name}</Tooltip>
              </DetectableOverflow>
              {/* {highlightSearchTerm(inst.name)} */}
            </ItemTitle>
            {inst.providerName === 'vip' ? <CommonBadge isCTA badgeType={'vipService'} 
            title={isDesktopView ? '' : 'VIP'}
            tooltipContent={'Sync this account automatically by providing Vyzer a Read-Only access directly to your account.\nThis process can take time, but ut will fully serve your needs'} /> : null}
          </ItemTitleAndBadge>
          <ItemMoreInfos>
            <MoreInfosItem>
              {cleanURL(inst.homeURL)}
            </MoreInfosItem>
          </ItemMoreInfos>
        </ItemText>
      </ItemContent>
      {isAutoSync ? <PrimaryButtonWithIcon style={{ padding: '6px 16px' }}><SyncLightningSvg />Sync</PrimaryButtonWithIcon> : null}
      {isAlreadyConnected ? <ReviewMySyncedAccountsButton>Review my synced accounts <DiagonalArrowSvg /></ReviewMySyncedAccountsButton> : null}
    </InstitutionAutoCompleteResultRowWrapper> : <Separator />
  )
}

const SyncLightningSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.83333 7.1875L8 1.5L3 8.8125H7.58333V14.5L13 7.1875H8.83333Z" fill="white" />
    </svg>
  )
}

const DiagonalArrowSvg = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.57727 6.8125C7.57727 6.8125 10.5631 6.8125 11.9156 6.8125C11.9156 8.16503 11.9156 11.1509 11.9156 11.1509" stroke="white" strokeWidth="1.24681" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.375 12.3564L11.7271 7.00435" stroke="white" strokeWidth="1.24681" strokeLinecap="round"/>
    </svg>
  )
}