import { getValueByPath } from "../../../../../common/utils/object.utils";

const darkHeroBackground = '#111213';

export const communityOverviewPieChartData = (classesData,hoverAllocationId) => {
  
  const relatedPath = hoverAllocationId ? `allocations.${hoverAllocationId}.totalWorth` : 'totalValue';
  const totalNetWorthValue = classesData.reduce(
    (a, c) => (a + getValueByPath(c,relatedPath) * (c.isLiability ? -1 : 1)),0
  );

  
  return {
    datasets: [
      {
        data: classesData.filter(g => !g.isLiability).map(group =>  getValueByPath(group,relatedPath)),
        backgroundColor: classesData.filter(g => !g.isLiability).map(group => group.color),
        borderColor: darkHeroBackground,
        borderWidth: 6,
        hoverBorderColor: darkHeroBackground,
        label: 'Assets',
      },
      {
        data: [...classesData.filter(g => g.isLiability).map(group => getValueByPath(group,relatedPath)), totalNetWorthValue],
        backgroundColor: [...classesData.filter(g => g.isLiability).map(group => group.color), darkHeroBackground],
        borderColor: darkHeroBackground,
        borderWidth: 6,
        hoverBorderColor: darkHeroBackground,
        label: 'Liabilities',
        hoverBackgroundColor: [...classesData.filter(g => g.isLiability).map(group => group.color), darkHeroBackground]
      }
    ]
  };
}

export const communitySingleClassPieChartData = (classData,hoverAllocationId) => {
  if (!classData){
    return {datasets:[]}
  }

  const relatedPath = hoverAllocationId ? `allocations.${hoverAllocationId}.categories` : 'categoriesAllAllocationsValues';
  const categoriesObj = getValueByPath(classData,relatedPath);
  
  return {
    datasets: [
      {
        data: Object.keys(categoriesObj).map(catId => hoverAllocationId ? categoriesObj[catId].totalWorth : categoriesObj[catId]),
        backgroundColor: classData.color,
        borderColor: darkHeroBackground,
        label: 'Assets',
        borderWidth: 6,
      }
    ] 
  };


}