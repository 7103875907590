import React from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import {
    ReadOnlyAndDemoModeIconTextWrapper, ReadOnlyAndDemoModeIconWrapper, ReadOnlyAndDemoModeTextWrapper,
    ReadOnlyAndDemoModeActionButtonWrapper, ActionButtonText, ActionButtonIcon
} from './Banner.styles'
import { EyesIcon } from './icons/banner-icons'
import { Banner } from './Banner';
import { LinkArrowIcon } from '../../../../assets/icons/common/common-icons';
import { LimitationModal } from '../../../../common/components/LimitationModal/LimitationModal';

export function ReadOnlyBanner() {
    const { billingStore , uiStore } = useStore();

    const handleBannerClick = async () => {
        // const paymentLink = await billingStore.fetchBillingUrl();
        billingStore.setShowPricingTableModal(true);
        // console.log('plans popup');
    }

    return <Observer>{() => (<>
        <Banner bannerBgc={'#DAE2F5'} bannerTxtColor={'#2565E7'}
            titleComponent={
                <>
                    <ReadOnlyAndDemoModeIconTextWrapper>
                        <ReadOnlyAndDemoModeTextWrapper>You are in Read-only mode</ReadOnlyAndDemoModeTextWrapper>
                        <ReadOnlyAndDemoModeIconWrapper bannerTxtColor={'#2565E7'}><EyesIcon /></ReadOnlyAndDemoModeIconWrapper>
                    </ReadOnlyAndDemoModeIconTextWrapper>
                </>}
            buttonComponent={<>
     
                <ReadOnlyAndDemoModeActionButtonWrapper  bannerButtonBgc={'#2565E7'} bannerButtonTxtColor={'#FFFFFF'}onClick={() => handleBannerClick()}>
                    <ActionButtonText>Reactivate my account</ActionButtonText>
                    <ActionButtonIcon bannerButtonTxtColor={'#FFFFFF'}><LinkArrowIcon /></ActionButtonIcon>
                </ReadOnlyAndDemoModeActionButtonWrapper>
            </>}
        />
        {uiStore.isShowDemoLimitationModal &&  <LimitationModal modalType={'readOnlyPortfolio'} onStartMyPortfolio={()=>handleBannerClick()} onClose={()=>{uiStore.setShowDemoLimitationModal(false)}} />}</>
    )}
    </Observer>
}


