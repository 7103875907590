import styled from "@emotion/styled/macro";
import { flexCenter, textOverflow } from "../../../../common/styles/combos.styles";
import { colors } from "../../../../common/styles/theme.styles";

export const StickyWrapper = styled.div`
    position: sticky;
    top: 0px;
    width: 100%;
    padding-top: 16px;
    background-color: ${colors.lightGray2};
    
    ${props => props.isSticky ? `
        border-bottom: 1px solid ${colors.gray3};
        z-index: 5;
    ` : null}
`;

export const BreadcrumbsWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    font-weight: 500;
    height: 20px;
`;

export const BreadCrumbsHomeIconWrapper = styled.div`
    /* margin-left: 12px; */
    /* height:24px;
    width:64px; */
    gap: 4px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-size: 14px;
    line-height: 17px;
    color: #1A1B1D;
    cursor: pointer;
    svg{
        width:18px;
        height:18px;
    }
    path{
        // transition:all 0.2s;
        /* stroke:#4F699D; */
        fill:#fff;
    }
`;

export const DiagonalSeparatorWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 4px;
    svg{
        transform:rotate(-90deg);
        path{
            stroke:${colors.darkGray3};
        }
    }
`;

export const BreadCrumbsIconWrapper = styled.div`
    margin-left:8px;
    height:24px;
    width:24px;
    border-radius: 2px;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:6px;
    svg{
        width:16px;
        height:16px;
    }
    path{
        // transition:all 0.2s;
        /* stroke:#4F699D; */
        fill:#fff;
    }
`;

export const BreadCrumbsClassText = styled.div`
    ${flexCenter};
    color: ${colors.darkGray1};
    font-size: 14px;
    line-height: 17px;
    /* font-weight: 500px; */
    cursor: pointer;
    ${props => props.isClassView ? `
        color: ${colors.darkGray3};
        cursor: auto;
    ` : null}
    flex-shrink:0;
`;

export const BreadCrumbsItemText = styled.div`
    ${flexCenter};
    font-size: 14px;
    line-height: 17px;
    /* font-weight: 500px; */
    color: ${colors.darkGray3};
    overflow:hidden;
`;

export const BreadCrumbsOverflowText = styled.div`
    ${textOverflow}
`;

export const BreadCrumbsCategoryBadge = styled.div`
    background-color:${colors.gray3};
    border-radius:2px;
    padding:2px 4px;
    color:${colors.darkGray2};
    /* font-weight: 500; */
    font-size: 10px;
    line-height: 12px;
    margin-left:8px;
    margin-right:8px;
`;

export const BreadcrumbsPlaceholder = styled.div`
    min-height: 49px;
`;

export const HoldingsBadgeWrapper = styled.div`
    ${flexCenter};
`;

export const HoldingsBadgeSquare = styled.div`
    color: ${colors.assetHoldingsBadgeTextColor};
    margin-left: 4px;
    padding: 0px 1px 0px 4px;
    background-color: ${colors.assetHoldingsBadgeBgColor};
    border-radius: 2px 0px 0px 2px;
    font-weight: 500;
    font-size: 12px;
`;

export const HoldingsBadgeTriangle = styled.div`
    color: ${props=>props.isHoldingsView ? colors.darkGray1 : '#fff'};
    /* width: 0;
    height: 0; */
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 8px solid ${colors.assetHoldingsBadgeBgColor};
`;