import { Observer } from 'mobx-react';
import React from 'react';
import { ROUTES } from '../../../../common/constants/routes.const';
import { useStore } from '../../../app/data/root.store';
import { IconLetter, IconWrapper, MobileSharedPortfolioBannerWrapper, MobileSwitchPortfolioText, SharedPortfolioName, SwitchLink } from './SharedPortfolioBanner.styles';

export const MobileSharedPortfolioBanner = ({ rounded }) => {
  const { userStore, } = useStore();

  return (<Observer>{() => (
    <MobileSharedPortfolioBannerWrapper isOwner={userStore.isViewingOwnPortfolio} color={userStore.currentPortfolioDetails.color} rounded={rounded}>
      <IconWrapper isOwner={userStore.isViewingOwnPortfolio}>
        <IconLetter isOwner={userStore.isViewingOwnPortfolio}>
          {userStore.currentPortfolioDetails.name.charAt(0).toUpperCase()}
        </IconLetter>
      </IconWrapper>
      <SharedPortfolioName isOwner={userStore.isViewingOwnPortfolio}>
        {userStore.currentPortfolioDetails.name}
      </SharedPortfolioName>
      <SwitchLink to={ROUTES.portfolios.url} >
        <MobileSwitchPortfolioText isOwner={userStore.isViewingOwnPortfolio}>Switch</MobileSwitchPortfolioText>
        <SwitchIcon isOwner={userStore.isViewingOwnPortfolio} />
      </SwitchLink>
    </MobileSharedPortfolioBannerWrapper>
  )}</Observer>);
}

const SwitchIcon = ({ isOwner }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.375 7.5C8.375 7.5 11.7874 7.5 13.3331 7.5C13.3331 9.04575 13.3331 12.4581 13.3331 12.4581" stroke={isOwner ? '#fff' : '#1A1B1D'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 13.8359L13.1167 7.71926" stroke={isOwner ? '#fff' : '#1A1B1D'} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
)