import styled from '@emotion/styled/macro';
import bgImg from './assets/vyzerBgImg.png';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors, shadows } from '../../../../common/styles/theme.styles';
import { keyframes } from '@emotion/react';

const colorChange = keyframes`
    0% {
      fill:#fff;
    }
    40% {
      fill:#fff;
    }
    50%{
      fill:aqua;
    }
    60%{
      fill:#fff;
    }
    100% {
      fill:#fff;
    }
`;

export const ModalBgWrapper = styled.div`
     background: url(${bgImg}) no-repeat;
     background-size: 100% 344px;
     border-radius:8px;
     position: relative;
     display:flex;
     flex-direction:column;
     align-items:center;
`;

export const ModalCloseButtonWrapper = styled.div`
    position:absolute;
    right:4px;
    top:4px;
    cursor: pointer;
    path{
      stroke: ${colors.darkGray3};
    }
`;

export const ModalHeader = styled.div`
 display: flex;
 justify-content: flex-start;
 align-items: center;
 flex-direction: column;
  height:344px;
  svg {
    path {
      width: 98px;
      height: 98px;

    }
  }
  /* svg{
    rect:nth-child(1){
      animation: ${colorChange} 0.8s infinite linear;
    }
    rect:nth-child(3){
      animation: ${colorChange} 0.8s 0.2s infinite linear;
    }
    rect:nth-child(2){
      animation: ${colorChange} 0.8s 0.4s infinite linear;
    }
    rect:nth-child(4){
      animation: ${colorChange} 0.8s 0.6s infinite linear;
    }
  } */
`;

export const ModalLogoWrapper = styled.div`
  padding:70px 0 36px;
  // position:relative; 
  // > svg {
  //   position: absolute;
  //   top:0;
  //   right:0px;
  // }
`;

export const ModalContent = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  padding: 48px 24px 32px 24px;
  position: relative;
  z-index: 1003;
`;

export const TitleWrapper = styled.div`
  ${flexCenter};
  /* padding-top:40px; */
  padding-bottom:24px;
  > svg {
    path{
      fill:#fff;
    }
  }
`;

export const LogoDecorationsWrapper = styled.div`
  display: flex;
  align-items: center;
  svg:first-of-type, svg:last-of-type{
    width: 60px;
    height: 102px;
    path {
      stroke: #fff;
      fill: #fff;
    }
  }
`;

export const TitleText = styled.div`
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 103%;
  color: #fff;
  text-align: center;
  // padding-top: 45px;
  white-space: pre-line;
`;

export const StartButton = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  background: ${colors.darkGray1};

  color: #fff;
  text-align: center;
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const MiddleMessage = styled.div`
  text-align: center;
  font-family: Circular;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: ${colors.darkGray1};
  padding-bottom: 4px;
`;

export const BottomMessage = styled.div`
  color:${colors.darkGray1};
  text-align: center;
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  max-width: 234px;
  padding-bottom: 64px;
`;

export const ClickToDownload = styled.div`
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: ${colors.darkGray2};
  padding-top: 4px;
`;

export const MobileStoresWrapper = styled.div`
  ${flexCenter};
  gap: 4px;
  padding-top: 16px;
`;

export const ActionButton = styled.div`
  ${flexCenter};
  padding: 12px 24px;
  height:38px;
  cursor: pointer;
  min-width: 144px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  box-shadow: ${shadows.toggleShadow};
  border-radius: 6px;
  ${props => props.black ? `
  background-color: ${colors.darkGray1};
  color: #fff;
  ` : `
  background-color: #fff;
  color: ${colors.darkGray1};
  `};
  ${props => props.isBack ? `margin-right: auto` : ``};
`;

export const VipSyncWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #DEB482;
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  margin-top: 10px;
  border-radius: 6px;
  background: #FFF;
  padding-left: 12px;
  padding-right: 20px;
  box-shadow: ${shadows.toggleShadow};
  cursor: pointer;
  min-height: 40px;
`;

export const LogoWrapper = styled.div`
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 60px;
  background: ${colors.darkGray1};
  background: url(${bgImg}) no-repeat;
  background-size: 100% 100px;
  margin-bottom: 16px;
`;