import React  from 'react';
import { Observer } from 'mobx-react';
import moment from 'moment';


import { useStore } from '../../../../app/data/root.store';
import { AssetClosedBoxWrapper, AssetClosedTitle, AssetClosedDates } from './AssetPage.styles';
import { ItemLogo } from '../../../../../common/components/ItemLogo/ItemLogo';
import { monthDiff } from '../../../../../common/utils/dates.utils';
import { HeroConnectedAssetBtn, HeroConnectedAssetTitle, HeroConnectedAssetTitleBtnWrapper, HeroConnectedAssetWrapper } from './AssetOverviewHeroContent.styles';
import { ArrowRightIcon } from '../../../../../assets/icons/common/common-icons';
import { AssetClosedConnectedLiabilities } from './AssetClosedConnectedLiabilities';

// import { AssetConnectedLiability } from '../../../pages/asset/components/AssetConnectedLiability';
// import { displayMoneyFormatter, displayMoneyValue } from '../../../../../common/utils/number-display.utils';
// import { customTooltip } from './chartTooltips';

export function ItemClosedMainBox(props) {
  const { itemStore } = useStore();
  // let history = useHistory();
  // const hasConnectedLiability = itemStore.item?.connectedLiabilities?.length > 0;
  // const hasTickers = itemStore.isShowingTickers;
  // const [chartOptions, setChartOptions] = useState({cutout:'95%'});
  // const liabilitiesLength = itemStore.item.connectedLiabilities.length
  // let liabilityId


  return (<Observer>{() => (
    <AssetClosedBoxWrapper>
        <ItemLogo item={itemStore.item} iconSize={36} logoImgSize={'60px'} customStyles={{width: '60px' , height: '60px' , borderRadius: '50%' , marginRight:0}} />
        <AssetClosedTitle>{itemStore.item.title}{itemStore.item.title !== itemStore.item.name && itemStore.item.name ? `, ${itemStore.item.name}` : ``}</AssetClosedTitle>
        {itemStore.itemEvents.length && 
        <AssetClosedDates>
          {moment(itemStore.itemEvents[itemStore.itemEvents.length-1].date).format('MMMM yyyy')} - {moment(itemStore.itemEvents[0].date).format('MMMM yyyy')} ({monthDiff(moment(itemStore.itemEvents[itemStore.itemEvents.length-1].date).toDate(), moment(itemStore.itemEvents[0].date).toDate())} month)
        </AssetClosedDates>
        }

      {itemStore.item.connectedLiabilities?.length > 0 &&
                <HeroConnectedAssetWrapper isRelative withHoldings={itemStore.item.holdings !== 100}>
                  <HeroConnectedAssetTitleBtnWrapper isClosedAsset>
                    <HeroConnectedAssetTitle>Linked Liabilit{itemStore.item.connectedLiabilities?.length > 1 ? 'ies' : 'y' }</HeroConnectedAssetTitle>
                    <HeroConnectedAssetBtn isClosedAsset><ArrowRightIcon /></HeroConnectedAssetBtn>
                  </HeroConnectedAssetTitleBtnWrapper>
                  <AssetClosedConnectedLiabilities item={itemStore.item} />
                </HeroConnectedAssetWrapper>}
        
    </AssetClosedBoxWrapper>
  )}</Observer>)
}   