import React, { useState,  useRef, useEffect } from 'react';
import { EventRemarks } from './AssetEventRow.styles';
import { Tooltip } from "antd";


export const HoveredRemarks = (props) => {
  const {remarks  } = props;
  const remarksTextRef = useRef(null);
  const [isRemarksTooltipNeeded, setIsRemarksTooltipNeeded] = useState(false);

  useEffect(() => {
    const needToolTip = remarksTextRef.current && remarksTextRef.current.scrollWidth > remarksTextRef.current.clientWidth;
    setIsRemarksTooltipNeeded(needToolTip);
    return () => {}
  }, []);
  
  return (
    <Tooltip title={isRemarksTooltipNeeded ? remarks : ''} placement="topLeft">
        <EventRemarks ref={remarksTextRef} hovered>{remarks}</EventRemarks>
            {/* <EventRemarks ref={remarksTextRef}>{remarks}</EventRemarks> */}
    </Tooltip>
  )
}
