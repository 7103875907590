import styled from '@emotion/styled/macro';
import { colors, shadows, views } from '../../styles/theme.styles';


export const HeaderWrapper = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:16px 16px 13px 24px;
    box-shadow: inset 0px -1px 0px #DDE3EB;
    /* border-bottom: 1px solid #E7E5E5; */
    color:#000;
    border-radius: 4px 4px 0 0;
`;

export const CloseBtnWrapper = styled.div`
    cursor: pointer;
    height: 16px;
    width: 16px;

    svg{
        path{
            height: 12px;
            width: 12px;
        }
    }
`;

export const HeaderTitle = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: ${colors.darkGray1};
`;



export const ModalWrapper = styled.div`
    padding: 32px 55px;
    height: 375px;
    ${views.tablet} {
        padding: 16px;
    }
`;

export const ErrorMessage = styled.div`
    color: red;
`;

const inputPaddingLeft = 24;

export const SearchBoxWrapper = styled.div`
    width: 100%;
    position: relative;
    
    .ant-select-focus-visible {
        outline: none;
    }
    .ant-select .ant-select-selector {
        border: 1px solid ${colors.gray3};
        box-shadow: none;
        border-radius: 34px;
        height: 40px;
        /* padding: 2px 36px ; */
        padding: 0 36px ;
        /* overflow: hidden;
        text-overflow: ellipsis; */
    }

    .ant-select:hover .ant-select-selector, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border: 1px solid ${colors.gray3};
        box-shadow: none;
        height: 40px;
        padding-left: 36px;
        /* padding-left: ${inputPaddingLeft}px; */
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
        height: 40px;
        padding-left: ${inputPaddingLeft}px;
    } 
`;

export const SearchPlaceholderWrapper = styled.span`
    padding-left: ${inputPaddingLeft}px;
`;

export const SearchIconWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 12px;
    left: 12px;
    align-items: center;
    svg{
        width: 16px;
        height: 16px;
        circle, line {
            stroke: ${props => props.hasSearchInput ? colors.darkGray1 : '#9097A3' }
        }
    }
`;

export const ModalFooterWrapper = styled.div`
    box-shadow: inset 0px 1px 0px #E7E8EA;
    /* border-top: 1px solid pink; */
    /* background-color: lightgreen; */
    display: flex;
    justify-content: flex-end;
    padding: 14px 24px;
    border-radius: 0 0 4px 4px;
`

export const CreateNewLoanButtonWrapper = styled.div`
    display: flex;
    gap: 8px;
    // color: #000;
`;

export const PlusSvgWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    box-shadow: ${shadows.toggleShadow};
`;