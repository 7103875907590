import styled from "@emotion/styled";
import { colors, views } from "../../../../../../common/styles/theme.styles";


export const ReOpenItemModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items:center; 
  position:relative;
  padding:20px 100px 42px; 
  gap:4px;
  ${views.tablet}{
    padding:20px 24px; 
  }
`;


export const OpenItemModalTitle = styled.div`
  color: ${colors.darkGray1};
  text-align: center;
  font-family: Domine;
  font-size: 22px;
  font-weight: 400;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 16px;
  cursor: pointer;
  display: flex;
  alignItems: center;
  justifyContent: center;
`;

export const OpenItemModalMessage = styled.div`
  text-align: center; 
  padding-top: 4px;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
`;
