import styled from "@emotion/styled/macro";
import { colors, views } from "../../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../../common/styles/combos.styles";

export const Overlay = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    border-radius: 12px;
    border: 1px solid ${colors.darkGray2};
    background: #000;
    overflow: hidden;
    ${views.tablet} {
      flex-direction: column-reverse;
    }
`;

export const BackgroundImage = styled.div`
    position: absolute;
    inset: 0;
    background: url(${props => props.imageUrl}) no-repeat center right;
    pointer-events: none;
    ${views.tablet} {
      background: url(${props => props.imageUrl}) no-repeat;
      background-position-x: right;
      top: 60px;
    }
`;

export const LeftPane = styled.div`
  background-color: #fff;
  display: flex;
  flex:1;
  justify-content: center;
  flex-direction: column;
  ${views.tablet} {
    flex: 2;
    text-align: center;
  }
`;

export const RightPane = styled.div`
    display: flex;
    position: relative;
    flex:1;
`;

export const Title = styled.div`
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    ${views.tablet} {
      font-size: 28px;
      font-weight: 700;
      line-height: 32px;
    }
`;

export const SubTitle = styled.div`
    color: ${colors.darkGray2};
    font-family: Circular;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-top: 12px;
    ${views.tablet} {
      font-size: 18px;
    }
`;

export const CloseButton = styled.div`
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  ${views.tablet} {
    top: 4px;
    right: 4px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 64px 0 40px;
  ${views.tablet} {
    padding: 0 48px;
    align-items: center;
  }
`;

export const AssetClassCard = styled.div`
  width: 120px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
`;

export const AssetClassImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

export const CreateButton = styled.div`
    ${flexCenter};
    gap: 8px;
    background-color: #111213;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 24px;
    font-family: Circular;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    ${views.desktop} {
      align-self: flex-start;
    }
    ${views.tablet} {
    padding: 15px 20px;
    }
`;
