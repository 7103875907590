import React from 'react'
import { useHistory } from 'react-router-dom';
import { ClockIcon, TransactionSuggestionIcon } from '../../../../assets/icons/common/common-icons';
import { ModifiedTooltip } from '../../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { navigateToUrl } from '../../../../common/utils/navigation.utils';
import { displayMoneyValue, floatFixed } from '../../../../common/utils/number-display.utils';
import { isNullOrUndefinedOrZeroOrEmptyString } from '../../../../common/utils/object.utils';
import { getUrlDisplayName } from '../../../../common/utils/string.utils';
import { useStore } from '../../../app/data/root.store';
import { AssetClassIcon } from '../../../assets/components/AssetClassIcon';
import { ActualTransactionItemRowWrapper, ClockIconWrapper, EventRowLinkedTransactionIconWrapper, HoldingsBadgeText, HoldingsBadgeTriangle, HoldingsBadgeWrapper, ItemDescription, ItemIconWrapper, ItemTitleLink, ItemTitleWrapper, TransactionAndAssetDetails, TransactionItemCashBox, TransactionItemPredictedAmount, TransactionItemPredictedAmountWrapper, TransactionsDetails, TransactionsStatusIconWrapper } from './TransactionsPanel.styles';
import { TransactionsStatusIcon } from './TransactionsStatusIcon';

export const ActualTransactionItemRow = (props) => {
  const { trn, trnRelatedClass, hasTransactionsOnEvent, isIncognito } = props;
  const { metadataStore } = useStore();
  let history = useHistory();
  const handleItemClick = (trn) => {
    const astClass = metadataStore.classesObj[metadataStore.categoriesObj[trn.itemCategoryId].classId];
    navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(astClass.title)}/${trn.itemId}/events`);
  }

  return (
    <ActualTransactionItemRowWrapper>
      <TransactionsDetails>
        {trnRelatedClass && <>
          <ItemIconWrapper color={trnRelatedClass.color} >
            <AssetClassIcon name={trnRelatedClass.icon} />
          </ItemIconWrapper>
          <TransactionAndAssetDetails>
            <ItemTitleWrapper>
              <ItemTitleLink onClick={() => { handleItemClick(trn) }}>{trn.itemTitle}</ItemTitleLink>
              {!isNullOrUndefinedOrZeroOrEmptyString(trn.itemHoldings) && trn.itemHoldings !== 1 ?
                <HoldingsBadgeWrapper><HoldingsBadgeText>{floatFixed(trn.itemHoldings * 100, 2)}%</HoldingsBadgeText><HoldingsBadgeTriangle /></HoldingsBadgeWrapper>
                : null}
            </ItemTitleWrapper>
            <ItemDescription>{trn.description}</ItemDescription>
            {hasTransactionsOnEvent && <TransactionItemPredictedAmountWrapper>
              <TransactionItemPredictedAmount>{displayMoneyValue(Math.abs(trn.predictedAmountHoldingUsdValue ? trn.predictedAmountHoldingUsdValue :  trn.usdHoldingValue), 'USD')}</TransactionItemPredictedAmount>
              <ClockIconWrapper><ClockIcon /></ClockIconWrapper>
            </TransactionItemPredictedAmountWrapper>}
          </TransactionAndAssetDetails>
        </>
        }
      </TransactionsDetails>
      <TransactionItemCashBox cashIn={ trn.usdHoldingValue * (trn.eventData.transactions[0].provider === "api" ? -1 : 1) > 0}>
        {displayMoneyValue(trn.usdHoldingValue * (trn.eventData.transactions[0].provider === "api" ? -1 : 1), 'USD', isIncognito)}
      </TransactionItemCashBox>

      {hasTransactionsOnEvent && trn.eventData.transactions[0].provider === "api" && <ModifiedTooltip title={'Linked transaction'} placement="top">
        <EventRowLinkedTransactionIconWrapper isDistribution={trn.eventData.transactions[0].value * (trn.eventData.transactions[0].provider === "api" ? -1 : 1) > 0}>
          <TransactionSuggestionIcon />
        </EventRowLinkedTransactionIconWrapper>
      </ModifiedTooltip>}

      <ModifiedTooltip title={'This is an actual cash flow event. The gray number represent the expectation amount.'} isLongText={true}>
        <TransactionsStatusIconWrapper hasHappened={true} bgColor={'#D6EDFD'}>
          <TransactionsStatusIcon iconName={'happened'} />
        </TransactionsStatusIconWrapper>
      </ModifiedTooltip>
    </ActualTransactionItemRowWrapper>
  )
}
