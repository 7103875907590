import  liabilityDefaultLogo  from '../../../../../assets/icons/liability-class/lia-loans.svg'
import { displayMoneyValue } from '../../../../../common/utils/number-display.utils'; 

export const customTooltip = (getTooltipData) => ({

    // Disable the on-canvas tooltip
    enabled: false,
    // mode: 'dataset',
    // mode: 'index',
    // intersect: false,
    // position: 'cursor',
    external: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl = document.getElementById('itempage-chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'itempage-chartjs-tooltip';
            tooltipEl.className = 'itempage-chart-tooltip-wrapper';
            tooltipEl.innerHTML = `<div class="tooltip-content-wrapper">
                                        <div class="itempage-tooltip-top"></div>
                                        <div class="itempage-tooltip-bottom"></div>
                                    </div>`;
            document.body.appendChild(tooltipEl);


            // if(asset && asset.connectedLiabilities && asset.connectedLiabilities.length > 0) {
            // console.log('YO');
            // }
            // tooltipEl.addEventListener('mouseout', () => {
            //     tooltipEl.style.opacity = 0
            //     document.body.removeChild(tooltipEl);
            // })

            // tooltipEl.addEventListener('mouseover', () => {
            //     tooltipEl.style.opacity = 1;
            // })
        }
        let showToolTip = false;
        if (tooltipModel.tooltip.dataPoints && tooltipModel.tooltip.dataPoints.length) {

            const currentDataPoint = tooltipModel.tooltip.dataPoints[0];

            if (currentDataPoint.datasetIndex === 2) {
                const tooltipItem = getTooltipData(currentDataPoint.datasetIndex, currentDataPoint.dataIndex);

                if (tooltipItem?.liability) {
                    showToolTip = true;
                    const logo = (
                        tooltipItem.liability.logoBase64 ?
                            '<img class="icon-image" alt="'+ tooltipItem.liability.connectedInstitutionName+'" src="data:image/png;base64,' +  tooltipItem.liability.logoBase64 + '"/>'
                            :
                            tooltipItem.liability.logoUrl ?
                                '<img class="icon-image" alt="'+ tooltipItem.liability.connectedInstitutionName +'" src="'+ tooltipItem.liability.logoUrl +'" />'
                                :
                                '<img class="icon-image-default" src="'+liabilityDefaultLogo+'" alt="wtf"/>'
                                )
                                  
                    const isImageLogo = tooltipItem.liability.logoBase64 ||  tooltipItem.liability.logoUrl;
                    // const topValue = '<div class="icon-wrapper">' + logo + '</div><div class="liability-title">' + tooltipItem.liability.title + '</div>'  +  (tooltipItem.liability.name.length ? '<div class="liability-organization">, '   + tooltipItem.liability.name + '</div>' : '');
                    const topValue = `
                        <div class="icon-wrapper${isImageLogo ? '-with-logo' : ''}">${logo}</div>
                        <div class="liability-title">${tooltipItem.liability.title}</div>
                        ${tooltipItem.liability.name.length ? `<div class="liability-organization">, ${tooltipItem.liability.name}</div>` : ''}
                    `; 
                    const bottomValue = '<div class="bottom-text-wrapper">Remaining balance</div><div class="balance-wrapper">' + displayMoneyValue(tooltipItem.liability.value, tooltipItem.liability.currency || 'USD') + '</div>' 
                    
                    var tooltipBottom = tooltipEl.querySelector('.itempage-tooltip-bottom');
                    var tooltipTop = tooltipEl.querySelector('.itempage-tooltip-top');
                    tooltipTop.innerHTML = topValue;
                    tooltipBottom.innerHTML = bottomValue;
                }
            }





            // let bottomValue = '';

            // if (currentDataPoint.datasetIndex === 0) {
            //     bottomValue = `<div class="cashFlow-tooltipItemRow">
            //                         <div class="item-label">Expected remaining Income</div>
            //                         <div class="item-value green">${displayMoneyValue(tooltipItems.remainingCashIn, 'USD', isIncognito)}</div>
            //                     </div>
            //                 <div class="cashFlow-tooltipItemRow">
            //                     <div class="item-label">Expected remaining Outcome</div>
            //                     <div class="item-value">${displayMoneyValue(tooltipItems.remainingCashOut, 'USD', isIncognito)}</div>
            //                 </div>`

        }

        // const tooltipData = getTooltipData();
        // const {liabilityTitle, liabilityClassTitle} = tooltipData;
        // // console.log(tooltipData.label);
        // const liabilityTooltipRow = `
        // <div class="itempage-connected-liability-row">
        //     <div class="itempage-connected-liability-row-info">
        //         <div class="itempage-connected-liability-row-info-icon"></div>
        //         <div class="itempage-connected-liability-row-info-titles-wrapper">
        //             <div class="itempage-connected-liability-row-info-main-title">${liabilityTitle}</div>
        //             <div class="itempage-connected-liability-row-info-alt-title">${liabilityClassTitle}</div>
        //         </div>
        //     </div>
        //     <div class="itempage-connected-liability-row-action">@</div>
        // </div>
        // `;

        // const liabilityTooltipHeader = '<div class="itempage-connected-liability-tooltip-header">Linked liability<div class="itempage-connected-liability-tooltip-header-content"><span class="itempage-connected-liability-gray3-text">Remainig</span>' + displayMoneyFormatter(tooltipData.connectedLiabilities[0].value, tooltipData.connectedLiabilities[0].currency) + '</div></div>';
        // tooltipEl.querySelector('.itempage-tooltip-top').innerHTML = liabilityTooltipHeader + liabilityTooltipRow;
        // tooltipEl.onclick = () => {alert('YO')};
        // .querySelector('.itempage-tooltip-top')
        // .addEventListener('click', () => alert('abc'));

        // let shouldShowToolTip = false;
        // if (tooltipModel.dataPoints && tooltipModel.dataPoints.length) {

        //     const currentDataPoint = tooltipModel.dataPoints[0];
        //     const tooltipData =  getTooltipItem();
        //     if (item.show) {
        //         const topValue = '<span class="tooltip-date">'+item.label+'</span><span class="tooltip-value">' + displayMoneyValue(item.value,'USD',item.isIncognito) + '</sapn>';
        //         var tooltipTop = tooltipEl.querySelector('.tooltip-top');
        //         tooltipTop.innerHTML = topValue;
        //         var tooltipBottom = tooltipEl.querySelector('.tooltip-bottom');
        //         tooltipBottom.innerHTML = '';
        //         shouldShowToolTip = true;
        //     } else {
        //         shouldShowToolTip = false;
        //     }
        // }

        // Hide if no tooltip
        // Hide if no tooltip
        if (!showToolTip || tooltipModel.tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            // tooltipEl.style.display = 'none';
            return;
        }

        // `this` will be the overall tooltip
        const position = tooltipModel.chart.canvas.getBoundingClientRect();
        
        // var tooltip
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        // tooltipEl.style.display = 'block';

        const tooltipPositions = tooltipEl.getBoundingClientRect();

        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.tooltip._eventPosition.y - 10 - tooltipPositions.height  + 'px'
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.tooltip._eventPosition.x - tooltipPositions.width/2 + 'px'
        // tooltipEl.style.pointerEvents = 'none';
    }
})