import styled from "@emotion/styled";
import {  flexCenter, flexVerticalCenter } from "../../../../common/styles/combos.styles";
import { FiltersButton, HeaderButton } from "../Header/MobileHeader.styles";
import { colors } from "../../../../common/styles/theme.styles";
// import { colors, shadows, views } from "../../../../common/styles/theme.styles";

export const HeaderWrapper = styled.div`
  
  height: 64px;
  ${flexVerticalCenter};
  justify-content: space-between;
  padding: 0 14px 0 16px;
  ${props=>props.isHavingSharedPortfolios ? ``:`
    border-bottom: 1px solid #3B3F46;
  `}
`;


export const LogoAndBadgeWrapper = styled.div`
  ${flexCenter}
  marginLeft: 20px; 
  gap:16px;
`;

export const RightActionsWrapper = styled.div`
  ${flexCenter};
`;

export const NotificationButtonWrapper = styled(HeaderButton)`
    color:#FFFFFF;
    border-radius: 12px;
    height:36px;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    
    ${props=>props.isInMenu ? `
      padding: 0;
    `: `
      padding: 0 5px;
      border: 1px solid #3B3F46;
    `}
    position: relative;
`;

export const PortfolioBannerMenuWrapper = styled.div`
    margin:0 16px 16px;
    border-radius: 12px;
`;

export const UserDetailsWrapper = styled.div`
    ${flexCenter};
    flex-direction: column;
    padding:24px;
    color:#fff;
`;

export const UserAvatarWrapper = styled.div`
  border-radius: 18px;
  border: 1px solid ${colors.darkGray2};
  background: #26292C;
  font-size: 30px;
  font-weight: 400;
  width: 56px;
  height: 56px;
  ${flexCenter};
`;

export const UserNameWrapper = styled.div`
  font-size: 18px;
  font-weight: 450;
  line-height: 24px; 
`;

export const UserEmailWrapper = styled.div`
  color: ${colors.darkGray3};
  font-size: 13px;
  font-weight: 450;
`;

export const MenusWrapper = styled.div`
    padding:0 16px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const MenuWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
`;

export const MenuTitle = styled.div`
  color: ${colors.darkGray3};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const MenuItemsWrapper = styled.div`
    border-radius: 12px;
    background: ${props=>props.bgc || 'rgba(59, 63, 70, 0.50)'} ;
`;

export const MenuItemWrapper = styled.div`
    height: 56px;
    :not(:last-of-type){
      border-bottom: 1px solid rgba(88, 93, 102, 0.50);
    }
    display: flex;
    flex: 1;
    ${flexVerticalCenter};
    a {
      display: flex;
      flex: 1;
      height: 100%;
      align-items: center;
      padding:0 16px;
      font-size: 16px;
      color:#fff;
      font-weight: 450;
      .link-title{
        gap:8px;  
        flex:1;
        display: flex;
        align-items: center;
      }
      .arrow-icon{
        transform:rotate(90 deg);
      }
    }
`;

export const MenuActionItemWrapper = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  flex: 1;
  padding:0 16px;
  font-size: 16px;
  font-weight: 450;
  color:#fff;
  .link-title{
    gap:8px;  
    flex:1;
    display: flex;
    align-items: center;
  }
`;


export const UserNotificationsHeader = styled.div`
    position: fixed;
    top: 0;
    background-color: #111213;
    z-index: 2;
    width: 100%;
    min-height: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 24px 0 0;
`;

export const UserNotificationsTitle = styled.div`
    font-weight: 450;
    font-size: 18px;
    color: #fff;
    flex:1;
`;

export const DeleteButton = styled(FiltersButton)`
    padding: 0 6px;
    width:38px;
    min-width: 38px;
    margin-left:8px;
`

export const SideBarMenuItemRightIconWrapper = styled.div`
  width: 24px;
  min-width: 24px;
  ${flexCenter};
`;




