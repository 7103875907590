import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { MobileBackButton, MobileLeftCornerMask, MobileRightCornerMask, PlusIcon } from '../../../../assets/icons/common/common-icons';
import { ActivationChecklistPane } from '../../../activation/components/checklist-pane/ChecklistPane';
import { useStore } from '../../../app/data/root.store';
import { AppHeader } from '../../../layout/components/Header/Header';
import { PageWrapper, RightSidePushMenuWrapper } from '../../../layout/components/Layout.styles';

import {  RolesWrapper, ContentHeaderWrapper,  HeaderTitle,  RolesContentBox, RolesMobileHeader, RolesTableHeaderCell, ContentHeaderTitle, ContentHeaderActionsWrapper, HeaderButton, HeaderInvisibleButton, RolesTableHeaderRow, InviteRowButton, InvitePlusButtonWrapper, RolesListWrapper } from './Roles.styles';
// import { ReportsDataBox } from './ReportsDataBox';
// import { ReportsEmptyHero } from './ReportsEmptyHero';
import { AppLoader } from '../../../../common/components/Loaders/AppLoader';
import { RolesHero } from './RolesHero';
import { DataBoxSubTitle, DataBoxTitle } from '../Profile.styles';
import { MobileBackButtonWrapper } from '../../../layout/components/MobileLayout.styles';
import { RoleRow } from './RoleRow';
import { EditRole } from './EditRole';
import moment from 'moment';

const demoRoleItem = {
  granteeName: "Demo guest",
  granteeEmail: "example@mail.com",
  roleDescription: "Tax advisor",
  role: "GUEST",
  color: '#2565E7',
  isOwner: false,
  isActive: true,
  lastActivity: moment().subtract(1, 'days').hour(17).minute(23).second(0).format('MMM Do, h:mmA'), // 'Yesterday, 5:23pm'
}

export const RolesPage = observer((props) => {

const rolesTableColumns = [
    'Name', 'Role', 'Permissions', 'Last activity', 'Actions'
]
const { metadataStore, userStore, uiStore, billingStore } = useStore();
const [showCreateModal,setShowCreateModal] = useState(false);

useEffect(() => {
  userStore.fetchRolesAndPermissions();
  // uiStore.setIsRightMenuOpen(false);
  // uiStore.setIsUserMenuActive(true);
  return () => { 
    // uiStore.setIsUserMenuActive(false);
  }
}, [userStore, uiStore]);

const createRole = async (data, cb) => {
  const newRole = await userStore.createRole(data);
  cb(newRole);
  // setShowCreateModal(false);
}

const showBillingModal = () => {
  billingStore.setShowPricingTableModal(true);
}

return (
  <>
  {uiStore.isDesktopView ? <AppHeader /> : 
      <RolesMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
        <MobileBackButtonWrapper onClick={()=>{uiStore.setIsAppMobileMenuOpen(true)}}><MobileBackButton /></MobileBackButtonWrapper>
        <HeaderTitle>Roles</HeaderTitle>
      </RolesMobileHeader> } 
    <RightSidePushMenuWrapper>
      {userStore.isViewingOwnPortfolio && userStore.isGetStarted && <ActivationChecklistPane />}
    </RightSidePushMenuWrapper>
    {!metadataStore.isLoaded  ?  <AppLoader backdrop /> :
      <><PageWrapper withHeader>
        <RolesWrapper>
          {/* <ReportsEmptyHero /> */}
          <RolesHero />
          
            
              {/* <>
              {!uiStore.isDesktopView &&<MobileHeaderWrapper>
                  Select report
                  <MobileLeftCornerMask style={{position:'absolute', top:'0' , left:'0'}}/>
                  <MobileRightCornerMask style={{position:'absolute', top:'0' , right:'0'}}/>
                </MobileHeaderWrapper>}
              </> */}
            
              <RolesContentBox>
              
                <ContentHeaderWrapper>
                  <ContentHeaderTitle>
                    <DataBoxTitle>{userStore.isViewingOwnPortfolio ? 'R' : `${userStore.firstNameCapitalize}'s r`}oles and permissions</DataBoxTitle>
                    <DataBoxSubTitle>{userStore.isTier1or2 || userStore.isDemoMode ? 'Upgrade to Premium or Elite to access Roles' : 'Manage portfolio access for advisors, assistants, and family members'}</DataBoxSubTitle>
                  </ContentHeaderTitle>
                  {uiStore.isDesktopView && 
                  <ContentHeaderActionsWrapper>
                    <a href='https://help.vyzer.co/en/articles/9287056-how-do-roles-and-permissions-work' target='_blank' rel='noreferrer'>
                      <HeaderInvisibleButton noStroke ><LearnIcon/>Learn</HeaderInvisibleButton>
                    </a>
                    <HeaderButton onClick={()=>{setShowCreateModal(true)}}><InviteIcon/>Invite</HeaderButton>
                  </ContentHeaderActionsWrapper> }
                   
                  {!uiStore.isDesktopView &&<>
                  <MobileLeftCornerMask style={{position:'absolute', top:'0' , left:'0'}}/>
                  <MobileRightCornerMask style={{position:'absolute', top:'0' , right:'0'}}/>
                  </>}
                 
                </ContentHeaderWrapper> 

                {uiStore.isDesktopView && <RolesTableHeaderRow>
                        {rolesTableColumns.map((columnLabel, ind) => (
                    <RolesTableHeaderCell key={'hc1' + ind}>
                      <span>{columnLabel}</span>
                    </RolesTableHeaderCell>
                  ))}
                  </RolesTableHeaderRow>}
                  <RolesListWrapper>
                  {
                    userStore.roles.map((permission, index) => (
                      <RoleRow key={index} item={permission} itemIndex={index-1} />
                    ))
                  }
                  {
                    userStore.isDemoMode ?
                      <RoleRow
                        key={1}
                        item={demoRoleItem}
                        isDemoRole
                        itemIndex={-1}
                      />
                    : null
                  }
                  {/* {userStore.roles.map((permission, index)=>(
                    <RoleRow key={index} item={permission} itemIndex={index} />
                  ))} */}
                  </RolesListWrapper>

                  <InviteRowButton onClick={()=>{setShowCreateModal(true)}}><InvitePlusButtonWrapper><PlusIcon /></InvitePlusButtonWrapper>Invite</InviteRowButton>
                  
                  {!uiStore.isDesktopView && 
                  <InviteRowButton white onClick={() => window.open('https://help.vyzer.co/en/articles/9287056-how-do-roles-and-permissions-work', '_blank')}                  >
                    <LearnIcon />Learn about Roles
                  </InviteRowButton>}
              </RolesContentBox>

        </RolesWrapper>
        {(showCreateModal || !uiStore.isDesktopView) && <EditRole isNew item={{}} isDemoRole={userStore.isDemoMode} itemIndex={userStore.roles.length - 1} visible={showCreateModal} onClose={()=>{setShowCreateModal(false);}} onSave={(data,cb)=>{createRole(data,cb)}} userTier={userStore.data?.profile?.tier} isTier1or2={userStore.isTier1or2} onStartPortfolioClick={showBillingModal} />}
      </PageWrapper>
        {/* {userStore.data?.profile?.showFreeTierReportsPopup && <LimitationModal modalType={'reports'} onUpgrade={() => { handleUpgradeTierClick() }} onClose={() => { userStore.closeFreeTierReportsModal() }} />}
        {uiStore.showRequestPfsModal ? <RequestPfsModal onClose={() => { uiStore.setShowRequestPfsModal(false); ls.set('pfsRequestSent', true); setPfsRequestSent(true); }} /> : null} */}
      </>
    }</>
)
})

const LearnIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="12" height="12" rx="6" stroke="#585D66" strokeWidth="1.28571"/>
    <path d="M7.36413 8.484H6.35613C6.33747 8.36267 6.3188 8.24133 6.3188 8.10133C6.3188 7.60667 6.52413 7.14933 7.05613 6.748L7.50413 6.412C7.78413 6.20667 7.90547 5.95467 7.90547 5.67467C7.90547 5.24533 7.59747 4.83467 6.97213 4.83467C6.30947 4.83467 6.02013 5.36667 6.02013 5.88933C6.02013 6.00133 6.02947 6.104 6.05747 6.188L4.88147 6.14133C4.84413 6.01067 4.8348 5.86133 4.8348 5.72133C4.8348 4.71333 5.5908 3.74267 6.97213 3.74267C8.4188 3.74267 9.1748 4.65733 9.1748 5.6C9.1748 6.33733 8.80147 6.86 8.2508 7.252L7.86813 7.52267C7.54147 7.756 7.36413 8.04533 7.36413 8.428V8.484ZM6.14147 9.83733C6.14147 9.42667 6.46813 9.09067 6.8788 9.09067C7.28947 9.09067 7.62547 9.42667 7.62547 9.83733C7.62547 10.248 7.28947 10.5747 6.8788 10.5747C6.46813 10.5747 6.14147 10.248 6.14147 9.83733Z" fill="#585D66"/>
    </svg>
  )
}

const InviteIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5 11.001C8.5575 11.001 7 9.44367 7 7.50056C7 5.55829 8.5575 4.00098 10.5 4.00098C12.4425 4.00098 14 5.55829 14 7.50056C14 9.44367 12.4425 11.001 10.5 11.001Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17 19V18.3618C17 15.9528 15.0472 14 12.6382 14H8.36177C5.95283 14 4 15.9528 4 18.3618V19" stroke="white" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
      <path d="M16.4004 10.8788L22.4004 10.8788" stroke="white" strokeWidth="1.5" strokeLinejoin="round"/>
      <path d="M19.4003 13.8789L19.4003 7.87891" stroke="white" strokeWidth="1.5" strokeLinejoin="round"/>
    </svg>
  )
}

