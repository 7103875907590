import { deleteData, getData, postData, putData } from '../../../common/utils/network.utils';

export const fetchCashFlowData = async (withSimulationsData) => {
    return (await getData('cash-flow' + (withSimulationsData ? '' : '?wos=1'))).data;
}

export const updateCostOfLivingData = async (data) => {
    return (await putData('cash-flow/cost-of-living',data));
}

export const toggleSimulation = async (data) => {
    return (await putData('cash-flow/toggle-simulation',data));
}

export const deleteSimulation = async (simulationId) => {
    return (await deleteData(`cash-flow/simulations/${simulationId}`));
}

export const addSimulation = async (data) => {
    return (await postData('cash-flow/add-simulation',data))
}

export const updateSimulation = async (simulationId,data) => {
    return (await putData(`cash-flow/simulations/${simulationId}`,data));
}