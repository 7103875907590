// import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import { HeaderWrapper } from './UserActivationMobileHeader.styles';
// import { LimitationModal } from '../../../common/components/LimitationModal/LimitationModal';



export const UserActivationMobileHeader = observer(() => {
  const { uiStore } = useStore();
  
  // const NextActivationFlowStep = activationFlowSteps[uiStore.nextActivationStep];
  const handleBackButton = () => {
    uiStore.setActivationStepIndex(uiStore.activationStepIndex -1);
  }

  return (
    <HeaderWrapper>
       {uiStore.activationStepIndex !== 0 && <div onClick={handleBackButton} style={{marginRight:'8px'}}>{'<'}</div>}Activate your vyzer portfolio
    </HeaderWrapper>
  );
});