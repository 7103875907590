import React from 'react';
import { Observer} from 'mobx-react';
import { useStore } from '../../../../../../app/data/root.store';

import { AssetBalanceUpdateRow } from './BalanceUpdateRow';
// import { ActualAttributeRow } from './ActualAttributeRow';
import { AssetTransactionRow } from './TransactionRow';



export function OldEventRow(props) {
  const { isFirstRow, id, isIncognito, item, event, itemCategoryId, isMarked} = props;
  const { itemStore} = useStore();

  const balanceUpdateRowUpdated = (valueUpdate) => {
    itemStore.updateAssetValueUpdate(id,valueUpdate);
  }

  const balanceUpdateRowDeleted = (balanceUpdateId) => {
    itemStore.deleteAssetValueUpdate(id,balanceUpdateId);
  }

  const transactionRowUpdated = (transaction) => {
    itemStore.updateTransaction(id,transaction);
  }

  const transactionRowDeleted = (transactionId) => {
    itemStore.deleteTransaction(id,transactionId);
  }

    return (
      <Observer>{()=>(<>
              {event.isValueUpdate ? 
              <AssetBalanceUpdateRow isLiability={itemStore.item.categoryClass.isLiability} balanceUpdate={event} currency={itemStore.itemCurrency}
              itemCategoryId={itemCategoryId}
               isIncognito={isIncognito} parentId={id} key={'vu'+event.id} 
               isFirstRow={isFirstRow}
               isMarked={isMarked}
               isItemConnected={item.plaidConnectedAccountId && !item.isLoginRequired}
               isHoldingsView={itemStore.isHoldingsView} holdings={item.holdings || 100}
                rowUpdated={balanceUpdateRowUpdated}
                rowDeleted={balanceUpdateRowDeleted}/> : 
                event.isAttributeUpdate ? null :   
              <AssetTransactionRow itemCurrency={event.currency} isIncognito={isIncognito} transaction={event} 
              itemCategoryId={itemCategoryId}
                isFirstRow={isFirstRow}
                isMarked={isMarked}
                isFirstContribution={itemStore.firstContributionId === event.id}
                isFirstDistribution={itemStore.firstDistributionId === event.id}
                parentId={id} key={'tr'+event.id} isHoldingsView={itemStore.isHoldingsView} holdings={item.holdings || 100}
                rowUpdated={transactionRowUpdated}
                rowDeleted={transactionRowDeleted} 
              /> 
              }</>
    )}</Observer>)
}


