import React , {useRef , useEffect} from 'react'
import { Player } from "@lottiefiles/react-lottie-player";

export const LottieHoverIcon = (props) => {

    const {hoverParentRef,lottieFile,style,speed} = props;
    const lottieRef = useRef(null);


    const handleParentMouseEnter = () => {
        lottieRef.current.setPlayerDirection(1);
        lottieRef.current.play();
    }

    const handleParentMouseLeave = () => {
        lottieRef.current.setPlayerDirection(-1);
        lottieRef.current.play();
    }

    useEffect(() => {
        const currentRef = hoverParentRef.current;
        hoverParentRef.current.addEventListener('mouseenter',handleParentMouseEnter);
        hoverParentRef.current.addEventListener('mouseleave',handleParentMouseLeave);
    
      return () => {
        if (currentRef){
            currentRef.removeEventListener('mouseenter',handleParentMouseEnter);
            currentRef.removeEventListener('mouseleave',handleParentMouseLeave);
        }
      }
    }, [hoverParentRef])
    

  return (
    <Player
    ref={lottieRef}
    autoplay={false}
    loop={false}
    keepLastFrame={true}
    src={lottieFile}
    speed={speed || 1}
    style={style}
    ></Player>
  )
}
