import React from 'react';

import {ReactComponent as HoldingEntityIcon} from '../assets/icons/holding-entity.svg';
import {ReactComponent as AssetClassesIcon} from '../assets/icons/asset-classes.svg';
import {ReactComponent as AssetCustomClassesIcon} from '../assets/icons/asset-custom-classes.svg';
import {ReactComponent as InstitutioHeroIcon} from '../assets/icons/institution-hero-icon.svg';
import {ReactComponent as InstitutionIcon} from '../assets/icons/institution-icon.svg';
import {ReactComponent as OwnershipIcon} from '../assets/icons/ownership.svg';
import {ReactComponent as AssetValueIcon} from '../assets/icons/asset-value.svg';
import {ReactComponent as DatePickerIcon} from '../assets/icons/date-picker.svg';
import {ReactComponent as CurrencyIcon} from '../assets/icons/currency.svg';
import {ReactComponent as ContainersIcon} from '../assets/icons/containers.svg';
import {ReactComponent as ItemStatusIcon} from '../assets/icons/item-status.svg';
import {ReactComponent as HoldingEntityHeroIcon} from '../assets/icons/hero-holding-entity.svg';
import {ReactComponent as OwnershipHeroIcon} from '../assets/icons/hero-ownership.svg';
import {ReactComponent as AssetValueHeroIcon} from '../assets/icons/hero-asset-value.svg';
import {ReactComponent as DatePickerHeroIcon} from '../assets/icons/hero-date-picker.svg';
import {ReactComponent as CurrencyHeroIcon} from '../assets/icons/hero-currency.svg';
import {ReactComponent as ContainersHeroIcon} from '../assets/icons/hero-containers.svg';
import {ReactComponent as ItemStatusHeroIcon} from '../assets/icons/hero-item-status.svg';
import {ReactComponent as StocksIcon} from '../assets/icons/stocks.svg';
import {ReactComponent as LeveragedAssets} from '../assets/icons/leveraged-assets.svg';
import {ReactComponent as HeroLeveragedAssets} from '../assets/icons/hero-leveraged-assets.svg';
import {ReactComponent as RiskIcon} from '../assets/icons/risk-level.svg';
import {ReactComponent as HeroRiskIcon} from '../assets/icons/hero-risk-level.svg';
import {ReactComponent as LiquidityIcon} from '../assets/icons/liquidity.svg';
import {ReactComponent as HeroLiquidityIcon} from '../assets/icons/hero-liquidity.svg';
import {ReactComponent as PropertyTypeIcon} from '../assets/icons/property-type.svg';
import {ReactComponent as HeroPropertyTypeIcon} from '../assets/icons/hero-property-type.svg';
import {ReactComponent as CompanySectorIcon} from '../assets/icons/company-sector.svg';
import {ReactComponent as HeroCompanySectorIcon} from '../assets/icons/hero-company-sector.svg';



export const FilterParameterIcon = ({name}) => {
    switch (name) {
        case 'holdingEntity':
          return <HoldingEntityIcon />;
        case 'hero-holdingEntity':
            return <HoldingEntityHeroIcon />;
        case 'classes':
            return <AssetClassesIcon />;
        case 'customClasses':
            return <AssetCustomClassesIcon />;
        case 'institutions':
            return <InstitutionIcon />;
        case 'institutions-hero':
            return <InstitutioHeroIcon />
        case 'ownerships':
            return <OwnershipIcon />;
        case 'hero-ownerships':
            return <OwnershipHeroIcon />;
        case 'itemStatus':
            return <ItemStatusIcon />;
        case 'hero-itemStatus':
            return <ItemStatusHeroIcon />;
        case 'assetsValue':
            return <AssetValueIcon />;
        case 'hero-assetsValue':
            return <AssetValueHeroIcon />;
        case 'startEndDate':
            return <DatePickerIcon />;
        case 'hero-startEndDate':
            return <DatePickerHeroIcon />;
        case 'currencies':
            return <CurrencyIcon />;
        case 'hero-currencies':
            return <CurrencyHeroIcon />;
        case 'containers':
            return <ContainersIcon />;
        case 'hero-containers':
            return <ContainersHeroIcon />;
        case 'leveragedAssets':
            return <LeveragedAssets />;
        case 'hero-leveragedAssetsId':
            return <HeroLeveragedAssets />;
        case 'hero-notLeveragedAssetsId':
            return <HeroLeveragedAssets />;
        case 'stocks':
            return <StocksIcon />;
        case 'riskLevel' :
            return <RiskIcon />
        case 'hero-riskLevel' :
            return <HeroRiskIcon />
        case 'liquidityLevel' :
            return <LiquidityIcon />
        case 'hero-liquidityLevel' :
            return <HeroLiquidityIcon />
        case 'propertyType' :
            return <PropertyTypeIcon />
        case 'hero-propertyType' :
            return <HeroPropertyTypeIcon />
        case 'companySector' :
            return <CompanySectorIcon />
        case 'hero-companySector' :
            return <HeroCompanySectorIcon />
        default:
          return null;
      }
};