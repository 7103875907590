import React, { useState} from 'react';
import moment from 'moment';
import { Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { displayMoneyValue } from '../../../../../../../common/utils/number-display.utils';
import { EventRow, ActualEventRowBadgeWrapper, EventRowSection, EventRowIndicationAndDateWrapper, IndicationWrapper, CellText, EventRemarks, EventHoverColumn, HoverColumnRemarksWrapper, HoverColumnActionsWrapper } from '../AssetEventRow.styles';
import { ApproveIcon } from '../../../../../../../assets/icons/common/common-icons';
import { EditEvent } from '../../Actuals/EditEvent';
// import { valueUpdateOnly } from '../../Actuals/constants/createEvent.events.const';
import { addMonths } from '../../../../../../../common/utils/dates.utils';
import { HoveredRemarks } from '../HoveredRemarks';
import { EditRowIcon } from '../assets/icons';
import { eventTypeGenerator } from '../../Actuals/constants/createEventUtils';

const valueUpdateOnly = eventTypeGenerator(false, false, true);

export function AssetBalanceUpdateRow(props) {
  const { balanceUpdate, isLiability, isIncognito, isHoldingsView, holdings, currency, itemCategoryId, isMarked} = props;
  const today = new Date();
  const pointData = addMonths(today,balanceUpdate.month); 
  const eventDate = moment(pointData);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const { confirm } = Modal;
  const [isHoveredOn, setIsHoveredOn] = useState(false);


  const saveChanges = (formValues, eventDate) => {
    setShowEditEventModal(false);

    const updatedBalance = {
      value: formValues.updatedValue,
      date: eventDate,
      remarks: formValues.eventRemarks,
      id: balanceUpdate.id
    }

    props.rowUpdated && props.rowUpdated(updatedBalance);
  }

  const showDeleteBalanceUpdateConfirm = () => {
    confirm({
      title: `Do you want to delete this update?`,
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        props.rowDeleted && props.rowDeleted(balanceUpdate.id);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  const oldEventType = `${isLiability ? 'Liability' : 'Asset'} Value`;

  const formSections = valueUpdateOnly;
  const formData = {
    eventTitle: oldEventType,
    updatedValue: balanceUpdate?.value,
    eventRemarks: balanceUpdate.remarks ? balanceUpdate.remarks : '',
    currency
  }

  return (
    <><EventRow key={balanceUpdate.id} hasHappened={true} isMarked={isMarked}  onClick={() => setShowEditEventModal(true)}
        onMouseEnter={()=>{setIsHoveredOn(true)}} onMouseLeave={()=>{setIsHoveredOn(false)}}>
      <EventRowSection>
        <EventRowIndicationAndDateWrapper>
          <IndicationWrapper></IndicationWrapper>
          <CellText>{eventDate.format('MM-YYYY')}</CellText>
        </EventRowIndicationAndDateWrapper>
      </EventRowSection>

      {/* <EventRowSection>
        <EventRowDateWrapper isHidden={!balanceUpdate.isFirstInMonth && !isFirstRow} isCanceled={balanceUpdate.isCanceled}>
          <EventRowShortnameMonth>{eventDate.format('MMM')}</EventRowShortnameMonth>
          <EventRowFullYear>{eventDate.format('YYYY')}</EventRowFullYear>
        </EventRowDateWrapper>
      </EventRowSection> */}

      <EventRowSection >
        <CellText>Update Value</CellText>
      </EventRowSection>
      {/* <EventRowSection flex={1}>
        <EventRowSectionMainTxt>
          {displayMoneyValue(balanceUpdate.value, currency, isIncognito, (isHoldingsView && holdings) ? holdings : 100)}
        </EventRowSectionMainTxt>
        <EventRowSectionAltTxt>
          Update Value
        </EventRowSectionAltTxt>
      </EventRowSection> */}

      <EventRowSection></EventRowSection>

      <EventRowSection>{displayMoneyValue(balanceUpdate.value, currency, isIncognito, (isHoldingsView && holdings) ? holdings : 100)}</EventRowSection>

      <EventRowSection hiddenOverflow className="hideOnHover">
        <EventRemarks >{balanceUpdate.remarks}</EventRemarks>
      </EventRowSection>

      <EventRowSection className="hideOnHover">
        <ActualEventRowBadgeWrapper><ApproveIcon /> Actual</ActualEventRowBadgeWrapper>
      </EventRowSection>

      <EventRowSection hiddenOverflow className="showOnHover">
          <EventHoverColumn>
            <HoverColumnRemarksWrapper>
              {  ( isHoveredOn   )  && <HoveredRemarks remarks={balanceUpdate.remarks}></HoveredRemarks> }
            </HoverColumnRemarksWrapper>
            <HoverColumnActionsWrapper>
                <EditRowIcon />
            </HoverColumnActionsWrapper>
          </EventHoverColumn>
        </EventRowSection> 
      {/* <EventRowSection>
        <ThreeDotsIconWrapper onClick={() => setShowEditEventModal(true)}>
          <ThreeDotsIcon />
        </ThreeDotsIconWrapper>
      </EventRowSection> */}
    </EventRow >
      {showEditEventModal && <EditEvent event={balanceUpdate} itemCategoryId={itemCategoryId} 
        oldEventType={oldEventType}
        formSections={formSections}
        formData={formData}
        onSave={({ formValues, eventDate }) => { saveChanges(formValues, eventDate) }}
        onDelete={showDeleteBalanceUpdateConfirm}
        onClose={() => { setShowEditEventModal(false) }} />
      }
    </>
  )

}