import { isNullOrUndefinedOrEmptyString } from "./object.utils";

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getUrlDisplayName = (str) => {
  return str.split(' ').join('-').toLocaleLowerCase();
}

export const getBreadcrumbsDisplayName = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).split('-').join(' ');
}

export const getDistance = (a,b) => {
    if(a.length === 0) return b.length; 
    if(b.length === 0) return a.length; 
  
    var matrix = [];
  
    // increment along the first column of each row
    var i;
    for(i = 0; i <= b.length; i++){
      matrix[i] = [i];
    }
  
    // increment each column in the first row
    var j;
    for(j = 0; j <= a.length; j++){
      matrix[0][j] = j;
    }
  
    // Fill in the rest of the matrix
    for(i = 1; i <= b.length; i++){
      for(j = 1; j <= a.length; j++){
        if(b.charAt(i-1) === a.charAt(j-1)){
          matrix[i][j] = matrix[i-1][j-1];
        } else {
          matrix[i][j] = Math.min(matrix[i-1][j-1] + 1, // substitution
                                  Math.min(matrix[i][j-1] + 1, // insertion
                                           matrix[i-1][j] + 1)); // deletion
        }
      }
    }
  
    return matrix[b.length][a.length];
  }

  export const cleanURL = (url) => {
    if (isNullOrUndefinedOrEmptyString(url)) return '';
    
    // Remove "http://" or "https://"
    let cleanedURL = url.replace(/^(https?:\/\/)/i, '');
  
    // Remove "www."
    cleanedURL = cleanedURL.replace(/^www\./i, '');
    
    // Remove trailing "/"
    if (cleanedURL.charAt(cleanedURL.length - 1) === '/') {
        cleanedURL = cleanedURL.slice(0, -1);
    }
    return cleanedURL;
}

export const addHttpPrefixIfMissing = (url) => {
  // if url doesn't start with http:// or https://, prefix it with http://
  if (!url.match(/^(https?:\/\/)/i)) {
    return 'http://' + url;
  } else {
    return url;
  }
}

export const isValidPassword = (password) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/.test(password);
};

export function transformEmailForMixpanel(email) {
  email = email.toLowerCase();
  // Encode the email to Base64
  const base64Email = btoa(email);
  
  // Get the last 5 characters of the Base64 string
  const last5Chars = base64Email.slice(-5);
  
  // Remove the last 5 characters from the Base64 string
  const base64WithoutLast5 = base64Email.slice(0, -5);
  
  // Create the new string with the last 5 characters at the start
  const transformedString = last5Chars + base64WithoutLast5;
  
  return transformedString;
}