import React , { useState, useEffect } from 'react';
import { Observer} from 'mobx-react';

import { useStore } from '../../../../../app/data/root.store';

import {  FormWrapper, TabWrapper } from '../Info/AssetInfo.styles';
import { FormWithStore } from '../../../../../form-displayer-connector/components/FormWithStore';
import { connectedStockInfoSections } from './connectedStockInfoSections.const';

export function ConnectedStockForm(props) {

  const { isIncognito } = props;
  const formName = 'connectedStockInfoForm'
  const [stock, setStock] = useState({});
  
  const { userStore} = useStore();


  useEffect(() => {
    setStock(props.stock);
  }, [props.stock]);


  return (
    <Observer>{()=>(
      <TabWrapper>
        <FormWrapper>
       { stock.category && <>
       <FormWithStore 
        fullWidth
        scrollContainerId={"wealthPageContent"}
        sections={connectedStockInfoSections} 
        formName={formName} defaultValues={stock} 
        isIncognito={isIncognito}
        // attributeHistoryUpdated={handleAttributeRowUpdated} 
        // attributeHistoryDeleted={handleAttributeRowDeleted}
        isActionLocked={userStore?.isReadOnlyMode} />
       </> }
       </FormWrapper>
     
     
      </TabWrapper>
   )}</Observer>)
}
