import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors, shadows } from "../../../../common/styles/theme.styles";

export const ActivationFlowStepsMenuWrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  gap: 8px;
  padding: 24px 20px;
`;

export const ActivationFlowStepRowWrapper = styled.div`
  ${flexCenter};
  width: 100%;
  gap: 8px;
  padding: 12px 12px 12px 8px;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: ${shadows.toggleShadow};

  :hover {
    background-color: #2F3236;
    /* border-image-source: ; */
  }

  ${props=>props.isActive ? `
    border: 1px solid #A6EDCF; background-color: #2F3236;
    ${ActivationStepTitleWrapper} {
      color: #fff;
    }
    ` : ``};
`;

export const ActivationStepStatusIconWrapper = styled.div`
  ${flexCenter};
`;

export const ActivationStepTitleWrapper = styled.div`
  flex: 1;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.darkGray3};
`;

export const JumpToPortfolioButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #A6EDCF;
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  border-radius: 39px;
  border: 1px solid #A6EDCF;
  background: #2F3236;
  padding: 12px 10px 12px 16px;
  box-shadow: ${shadows.toggleShadow};
  ${props=>props.isLastStep ? `
    background: #A6EDCF;
    color: ${colors.darkGray1};
    svg {
      path {
        stroke: ${colors.darkGray1};
      }
    }
    :hover {
      color: #17181A;
      background: #C68F7D;
    }
  ` : `
    :hover {
      color: ${colors.darkGray1};
      background: #A6EDCF;
      svg {
        path {
          stroke: ${colors.darkGray1};
        }
      }
    }
  `}
`;

