import styled from '@emotion/styled/macro';
import { keyframes } from "@emotion/react";
import { colors, views } from '../../../../common/styles/theme.styles';
import { flexCenter } from '../../../../common/styles/combos.styles';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const InstitutionsListWrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    gap:16px;
    ${views.tablet}{
        gap:0px;
    }
`;


export const InstitutionItemWrapper = styled.div`
    border: 1px solid ${colors.gray3};
    border-radius: 6px;
    .ReactCollapse--collapse {
        transition: height 200ms;
    }
    ${views.tablet}{
        border-radius: 0;
        border:none;
        border-bottom: 1px solid ${colors.lightGray2};
    }
`;

export const InstitutionItemHeaderWrapper = styled.div`
    ${InstitutionItemWrapper}:hover &{
        background-color:#F1F4F8
    }
    cursor: pointer;
    border-radius: ${props => props.isOpen ? '6px 6px 0 0' : '6px'};
    border-bottom: ${props => props.isOpen ? `1px solid ${colors.gray3}` : ''};
`;

export const InstitutionItemContentWrapper = styled.div`
    padding: 12px 0px;
`;

export const FlexSpace = styled.div`
    flex:1;
`;

export const PanelHeaderStatusWrapper = styled.div`
    // border-left: 1px solid ${colors.gray3};
    display: flex;
    flex-direction: column;
    // padding-left:16px;
    // padding-right: 16px;
    align-self: stretch;
    justify-content: center;
    align-items: flex-end;
    gap: 2px;
`;

export const PanelHeaderRightButtonsWrapper = styled.div`
    border-left: 1px solid ${colors.gray3};
    display: flex;
    padding-left:24px;
    padding-right: 8px;
    align-self: stretch;
    align-items: center;
    ${views.tablet}{
        border-left:none;
        padding-left:8px;
    }
`;

export const PanelHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    height: 66px;
    padding:0 16px;
    border-bottom: ${props => props.isOpen ? '1px solid gray' : 'none'};
    ${views.tablet}{
        gap:8px;
        padding-right: 8px;
    }
`;

export const InstitutionHeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 2px;
`;

export const AccountItemHeader = styled.div`
    display:flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1A1B1D;
    overflow-wrap: anywhere;
`;

export const InstitutionHeader = styled.div`
    display:flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1A1B1D;
`;

export const VipPendingText = styled.div`
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    color: #2565E7;
    display:flex;
    align-items:center;
    gap:2px;
    margin-bottom:2px;
    svg{
        width:16px;
        height:16px;
        path{
            stroke: #2565E7;
            stroke-width: 2px;
        }
        animation: ${spin} 2s infinite linear;
    }
`;



export const LastSyncPrompt = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: #979797;
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const SyncIconWrapper = styled.div`
    width: 12px;
    height: 12px;
    animation: ${spin} 2s linear infinite;
    svg{
        width:12px;
        height:12px;
        path{
            stroke: #979797;
        }
    }
`;

export const AccountItemAltHeader = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: #979797;
    display: flex;
    align-items: center;
`;

export const AccountAltSeparator = styled.span`
    width:2px;
    height: 2px;
    margin:0 4px;
    background-color: #979797;
`;

export const AccountAltItemTitleLink = styled.div`
    font-size:12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position:relative;
    svg{
        margin-top:-1px;
        margin-left: 2px;
    }
    ::after{
        content:'';
        position:absolute;
        width:0%;
        height:1px;
        left:50%;
        bottom:0;
        background-color:#979797;
        transition:all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
    }
    &:hover{
        ::after{
            width:100%;
            left:0%;
        }
    }
`;


export const InstitutionAccountsPanelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 6px 6px;
`;

export const InstitutionAccountsPanelContentWrapper = styled.div`
    flex:1;
  
`;

export const InstitutionAccountsListWrapper = styled.div`
    flex:1;
`;

export const AccountItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    opacity: ${props => props.isLoginRequired ? 0.4 : 1};
    .ant-switch-checked{
        background-color: #65A87F;
    }
    ${views.tablet}{
        gap:8px;
    }
`;

export const AccountTitleAndBalance = styled.div`
    display:flex;
    justify-content:space-between;
    font-size:14px;
    font-weight:500;
    ${props=>props.isConnected ? `` : `
        padding-bottom:8px;
        border-bottom: 1px solid #d9d9d9;
    `}
`;

export const AccountActions = styled.div`
    display:flex;
    flex-direction:row;
    padding-top:8px;
`;

export const AccountActionBox = styled.div`
    flex:1;
    &:not(:first-of-type){
        margin-left:8px;
    }
    &:not(:last-of-type){
        margin-right:8px;
    }
`;

export const AccountItemHeaderWrapper = styled.div`
    display: flex;
    flex:1;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    padding: 0px 8px;
    gap: 2px;
    ${views.tablet}{
        padding:0;
    }

`;

export const AccountItemIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InstitutionIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-left: 12px;
`;

export const LogoPlaceHolder = styled.div`
    height:28px;
    width:28px;
    background-color:${colors.gray3};
    border-radius:4px;
    ${flexCenter};
    svg {
        width:20px;
        height:20px;
    }
`;


export const PlaidLinkWrapper = styled.div`
    display: flex;
`;

export const SaltedgeLinkWrapper = styled(PlaidLinkWrapper)``;

export const ThreeDotsIconWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: ${props=>props.isOpen ? '16' : '0'}px;
    width: ${props=>props.isOpen ? '48' : '0'}px;
    margin-right: ${props=>props.isOpen ? '-16' : '0'}px;
    overflow: hidden;
    transition: all 0.2s;

`;

export const InstitutionActionWrapper = styled.div`
    width: 135px;
    background: #FFFFFF;
    box-shadow: 0px 0.2px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px #E5E9F2;
`;

export const InstitutionActionItem = styled.div`
    padding: 8px 12px;
    cursor: pointer;
    width: 100%;
    text-align:left;
    &:hover{
        background-color:#ECF0F4;
    }
`;

export const InstitutionActionItemTitle = styled.div`
    color:#EB8787;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
`;

export const SyncOnlinePlatformButtonWrapper = styled.div`
    padding: 28px;
`;

export const InstitutionsListEmptyStateWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 24px;
`;

export const AddNewPanelWrapper = styled.div`
    width: 100%;
    padding:0 16px;
    height: 66px;
    border: 1px dashed #DDE3EB;
    border-radius: 6px;
    ${flexCenter};
    justify-content: flex-start;
    cursor: pointer;
    gap:16px;
    :hover{
        background-color:#F1F4F8
    }
`;

export const AddButtonWrapper = styled.div`
    width: 32px;
    min-width: 32px;
    height: 32px;
    ${flexCenter};
    background-color: ${colors.lightGray1};
    border:1px solid ${colors.lightGray2};
    border-radius: 50%;
`;

export const AddPanelText = styled.div`
    font-weight: 500;
    font-size: 14px;
    color:${colors.darkGray2};
`;

export const EditModeRow = styled.div`
    display:flex;
    align-items:center;
    padding:0 36px;
    gap: 8px;
    :not(:last-of-type){
        margin-bottom:8px;
    }
`
