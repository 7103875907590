import React, { useState } from 'react';
import { ModalCloseIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { Radio } from '../../../../common/components/form-displayer/input-types/Radio/Radio';
// import { useStore } from '../../../app/data/root.store';
import { ModalButton, ModalClose, ModalContent, ModalFooter, ModalHeader, ModalTitle } from '../../../wealth/pages/asset/components/Events/AssetEventModal.styles';
import { ConnectedAccountActionPrefixIcon, ConnectedAccountActionPrefixSelectedIcon } from '../icons/connected-institutions-icons';
import { ModalPromptWrapper, PromptQuestion, RadioSelectedOptionWrapper } from './ConnectedInstitutionModal.styles';
import { SecondaryButton } from '../../../../common/components/buttons/NewButtons.styles';

export const ConnectedInstitutionAccountDeleteNotFoundModal = ({ handleOk, title, onClose, isDesktopView }) => {
    // const { billingStore } = useStore();

    const radioOptions = [
        {
            prefixIcon: <RadioSelectedOptionWrapper><ConnectedAccountActionPrefixIcon /></RadioSelectedOptionWrapper>,
            prefixSelectedIcon: <ConnectedAccountActionPrefixSelectedIcon style={{ marginRight: '2px' }} />,
            label: 'Manage this asset manually',
            value: 'manual',
            customOptionStyle: { display: 'flex', alignItems: 'center', gap: '10px' },
        },
        {
            prefixIcon: <RadioSelectedOptionWrapper><ConnectedAccountActionPrefixIcon /></RadioSelectedOptionWrapper>,
            prefixSelectedIcon: <ConnectedAccountActionPrefixSelectedIcon style={{ marginRight: '2px' }} />,
            label: 'Close this asset (history will be saved)',
            value: 'archive',
            customOptionStyle: { display: 'flex', alignItems: 'center', gap: '10px' },
        },
        {
            prefixIcon: <RadioSelectedOptionWrapper><ConnectedAccountActionPrefixIcon /></RadioSelectedOptionWrapper>,
            prefixSelectedIcon: <ConnectedAccountActionPrefixSelectedIcon style={{ marginRight: '2px' }} />,
            label: 'Delete this asset and all its history',
            value: 'delete',
            customOptionStyle: { color: '#FF0000', display: 'flex', alignItems: 'center', gap: '10px' },
        },
    ]
    const [value, setValue] = useState('');

    // const handleVyzerMembershipOnClick = () => {
    //     billingStore.setShowPricingTableModal(true);
    // }

    return (
        <BaseModal width={'600px'} height={'auto'}>
            <ModalHeader style={{ boxShadow: 'inset 0px -1px 0px #DDE3EB' }}>
                <ModalTitle>
                    Un-sync {title}
                </ModalTitle>
                <ModalClose onClick={onClose}>
                    <ModalCloseIcon />
                </ModalClose>
            </ModalHeader>
            <ModalContent style={{ gap: '24px', padding: isDesktopView ? '24px 68px 32px 68px' : '16px' }}>
                <ModalPromptWrapper>
                    <PromptQuestion>Are you sure you want to un-sync this account?</PromptQuestion>
                    This un-sync will stop automatic updates for this asset:

                    What do you want to do with the asset above?
                    <Radio
                        value={value}
                        options={radioOptions}
                        onChange={(val) => { setValue(val) }}
                        customWrapperStyle={{ flexDirection: 'column', gap: '8px' }}
                    />
                    {/* Are you sure that you want to un-sync <span style={{ color: '#979797' }}>{props.title}</span>?
                    {`\n\n${props.isActionLocked ? `This asset will be removed from your assets list and moved to Archived.
                    You will be able to re-enable it only with ` :
                        `Vyzer will no longer sync your account (asset) and your data will not be updated.
                    \nYou can always re-sync your account from the "Synced accounts" tab under "My account", this will automatically create a new connected account (asset) and you will be able to delete the inactive one.`}`}
                    {props.isActionLocked && <VyzerMembershipLink onClick={handleVyzerMembershipOnClick}>Vyzer Membership</VyzerMembershipLink>} */}
                </ModalPromptWrapper>
            </ModalContent>
            <ModalFooter style={{ boxShadow: 'inset 0px -1px 0px #DDE3EB', display: 'flex', justifyContent: 'space-between' }}>
                <SecondaryButton onClick={onClose} style={{ padding: '5px 16px' }}>Cancel</SecondaryButton>
                <ModalButton onClick={() => { handleOk?.(value) }} disabled={value === ''}>
                    Un-sync {title}
                </ModalButton>
            </ModalFooter>
        </BaseModal>
    )
}