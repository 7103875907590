import React from 'react'
import { observer } from 'mobx-react'
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal'
import { PrimaryButton } from '../../../../common/components/buttons/NewButtons.styles'
import DeepAnalysisCoverImg from './assets/deep-analysis-cover.png'
import { ContinueDeepAnalysisResultFooter, ContinueDeepAnalysisRowWrapper, ContinueDeepAnalysisSubtitle, ContinueDeepAnalysisTextWrapper, ContinueDeepAnalysisTitle, GraySpacer } from './ContinueDeepAnalysisResultModal.styles'
import { MagicBoxAnimatedIconGrey } from '../MagicBoxAnimatedIconGrey'
import { useStore } from '../../../app/data/root.store'
import { useTrackEvent } from '../../../../hooks/useTrackEvent/useTrackEvent'
import { useAnalytics } from '../../../app/data/AnalyticsProvider'

export const ContinueDeepAnalysisResultModal = observer(({onClose}) => {

  const { magicBoxAiStore, userStore } = useStore();
  const { trackEvent } = useAnalytics();
  const numOfItems = (magicBoxAiStore.llmResult?.data?.length || 0);

  const noMargin = userStore.isWelcome;
  

  useTrackEvent('Popup Viewed', {
    title: 'AI Items Were Created',
    url: window.location.href
  })

  const onDone = () => {
    trackEvent('CTA Clicked', {
      cta_text: 'Done',
      location: 'Magic box ai deep analysis results',
      destination: 'done',
      type: "Button",
      url: window.location.href
    });
    onClose();
  }

  return (
    <BaseModal borderRadius={8} height={'600px'} width={'550px'} noSidebarMargin={noMargin}>
      <img src={DeepAnalysisCoverImg} alt="Magic Box AI" style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8, maxWidth: '100%' }} />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '12px 110px' }}>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <ContinueDeepAnalysisRowWrapper>
            <CheckMarkSvg />
            <ContinueDeepAnalysisTextWrapper>
              <ContinueDeepAnalysisTitle>1. Import Files</ContinueDeepAnalysisTitle>
              <ContinueDeepAnalysisSubtitle>Successfully imported a personal balance sheet / spreadsheet tacker</ContinueDeepAnalysisSubtitle>
            </ContinueDeepAnalysisTextWrapper>
          </ContinueDeepAnalysisRowWrapper>
          <GraySpacer />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <ContinueDeepAnalysisRowWrapper>
            <CheckMarkSvg />
            <ContinueDeepAnalysisTextWrapper>
              <ContinueDeepAnalysisTitle>2. Magic Box AI</ContinueDeepAnalysisTitle>
              <ContinueDeepAnalysisSubtitle>The initial analysis identified {numOfItems} assets and liabilities</ContinueDeepAnalysisSubtitle>
            </ContinueDeepAnalysisTextWrapper>
          </ContinueDeepAnalysisRowWrapper>
          <GraySpacer />
        </div>

        <ContinueDeepAnalysisRowWrapper>
          <MagicBoxAnimatedIconGrey />
          <ContinueDeepAnalysisTextWrapper>
            <ContinueDeepAnalysisTitle>3. Deep Analysis</ContinueDeepAnalysisTitle>
            <ContinueDeepAnalysisSubtitle>Your files are in our deep analysis process to accurately extract all relevant financial information. Deep analysis can take up to 24 hours.</ContinueDeepAnalysisSubtitle>
          </ContinueDeepAnalysisTextWrapper>
        </ContinueDeepAnalysisRowWrapper>

      </div>
      <ContinueDeepAnalysisResultFooter>
        <PrimaryButton style={{ borderRadius: '30px', width: '174px', height: '44px' }} onClick={onDone}>Done 🎉 </PrimaryButton>
      </ContinueDeepAnalysisResultFooter>
    </BaseModal>
  )
})

const CheckMarkSvg = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="1" width="16" height="16" rx="8" fill="#6EBA7F" />
      <path d="M11.6736 6.73665L7.0039 11.409L4.66655 9.07235" stroke="white" strokeWidth="2" />
    </svg>
  )
}