import { Tooltip } from 'antd';
import { Observer } from 'mobx-react';
import React , {useRef, useEffect , useState } from 'react';
import { useStore } from '../../../app/data/root.store';
import { TableHeaderWrapper } from '../../../wealth/pages/class/components/ClassPage.styles';
import { SortHoverIconWrapper, TableHeaderCell, TableHeaderSortIconWrapper, TableHeaderTextWrapper } from '../../../wealth/pages/overview/components/WealthOverviewPage.styles';
import { ReactComponent as SortHoverIcon } from '../../assets/sort-hover-icon.svg';
import { ReactComponent as SortIcon } from '../../assets/sort-icon.svg';
import { CommunityItemsListHeaderTabs } from './CommunityItemsListHeaderTabs';
import { MobileLeftCornerMask, MobileRightCornerMask } from '../../../../assets/icons/common/common-icons';

export function CommunityListHeader(props) {
  const { communityStore , uiStore } = useStore();
  const [isTableHeaderFixed,setIsTableHeaderFixed] = useState(false);
  const tableHeaderRef = useRef(null)

  const handleColumHeaderClick = (dl) => {
    if (!communityStore.isShowingInstitutions){return}
    if (communityStore.itemsSortBy === dl.dataKey) {
      if (communityStore.itemsSortDirection === 'asc') {
        communityStore.setSortBy('instName');
        communityStore.setSortDirection('desc');
        
        // communityStore.setSortByColumn({ dataKey: 'assetName', type: 'assetName' });
      }
      communityStore.setSortDirection(communityStore.itemsSortDirection === 'desc' ? 'asc' : 'desc');
    } else {
      communityStore.setSortBy(dl.dataKey);
      // wealthStore.setSortByColumn(dl);
      communityStore.setSortDirection('desc');
    }
  }

  const checkScroll = () => {
    
    if (tableHeaderRef.current) {
      const { y } = tableHeaderRef.current.getBoundingClientRect();
      // console.log(y);
      setIsTableHeaderFixed(y < 64);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    
    // Initial check to set the state correctly on mount
    checkScroll();

    return () => window.removeEventListener('scroll', checkScroll);
  }, []);


  return (<Observer>{() => (
    <>
      <div ref={tableHeaderRef} style={{width:'100%' , height: isTableHeaderFixed ? '50px' : 0}}></div>
    <TableHeaderWrapper matricesColumns={1} style={uiStore.isDesktopView ? {top:'-45px'}: {}} isFixed={!uiStore.isDesktopView && isTableHeaderFixed}>

      <CommunityItemsListHeaderTabs />
      
      { (uiStore.isDesktopView ? [
        { dataKey: 'numberOfInvestments', title: '# Holdings', subAttributes: [], type: 'number', path: 'totalInvestments', description: '',
        //htmlTitle: 'Number of<br/>Investments' 
      },
        // { dataKey: 'communityValueChangeOverWeek', title: 'Value change', subAttributes: [], type: 'percentage', path: 'valueChangeOverWeek', },
      ] : []).map(dl => (
        <TableHeaderCell key={dl.dataKey} clickable={communityStore.isShowingInstitutions} onClick={() => { handleColumHeaderClick(dl) }}>
          <Tooltip placement='topRight' title={dl.description ? dl.description : ''} >
          {dl.htmlTitle ? <TableHeaderTextWrapper dangerouslySetInnerHTML={{ __html: dl?.htmlTitle }} /> :
            <TableHeaderTextWrapper>
              {dl.title}
              {dl.subTitle ? <><br />{dl.subTitle}</> : null}
            </TableHeaderTextWrapper>}
            </Tooltip>
          {communityStore.isShowingInstitutions && communityStore.itemsSortBy === dl.dataKey ? <TableHeaderSortIconWrapper isAsc={communityStore.itemsSortDirection === 'asc'}>
            {/* V */}
            <SortIcon />
          </TableHeaderSortIconWrapper> : null}
          {communityStore.isShowingInstitutions && communityStore.itemsSortBy !== dl.dataKey ? <SortHoverIconWrapper><SortHoverIcon /></SortHoverIconWrapper> : null}
        </TableHeaderCell>
      ))}
      <TableHeaderCell isValue clickable={communityStore.isShowingInstitutions} onClick={() => { handleColumHeaderClick({ dataKey: 'totalValue' }) }}>
        {communityStore.isShowingInstitutions && communityStore.itemsSortBy === 'totalValue'  ? <TableHeaderSortIconWrapper isValue isAsc={communityStore.itemsSortDirection === 'asc'}>
          {/* V */}
          <SortIcon />
        </TableHeaderSortIconWrapper> : null}
        { communityStore.isShowingInstitutions && communityStore.itemsSortBy !== 'totalValue' ? <SortHoverIconWrapper><SortHoverIcon /></SortHoverIconWrapper> : null}
        <TableHeaderTextWrapper>{'Total value'}</TableHeaderTextWrapper>
      </TableHeaderCell>
      {!uiStore.isDesktopView && 
        <>
          
            <MobileLeftCornerMask style={{position:'absolute', top:'0' , left:'0'}}/>
            <MobileRightCornerMask style={{position:'absolute', top:'0' , right:'0'}}/>
        </>
      }
    </TableHeaderWrapper></>
  )}</Observer>)
}