import React from 'react'
import {ReactComponent as EmptySearchResultsIcon} from './icons/search-icon.svg';
import { HeaderSearchBoxEmptyStateWrapper, SearchAltTextWrapper, SearchIconEmptyStateWrapper, SearchTextWrapper } from './HeaderSearchBox.styles'

export const HeaderSearchBoxEmptyState = () => {
  return (
    <HeaderSearchBoxEmptyStateWrapper>
        <SearchIconEmptyStateWrapper><EmptySearchResultsIcon /></SearchIconEmptyStateWrapper>
        <SearchTextWrapper>No results</SearchTextWrapper>
        <SearchAltTextWrapper>Did you type your asset name correctly?</SearchAltTextWrapper>
    </HeaderSearchBoxEmptyStateWrapper>
  )
}
