import { getTranslations } from "../../../../common/translations/translations";
import { createAmortizationLoanAddItemSections } from "./assets/amortization-loans-forms";
import { createCashAccountsPhysicalCashAddItemSections, createCashAccountsAddItemSections } from "./assets/cash-accounts-forms";
import { creditCardAddItemSections } from "./assets/credit-cards-forms";
import { createCryptoAddItemSections } from "./assets/crypto-forms";
import { createDebtFundAddItemSections, createHedgeFundAddItemSections, createPrivateEquityAddItemSections, createRealEstateFundAddItemSections, createVentureCapitalAddItemSections } from "./assets/funds-forms";
import { createLoanAddItemSections } from "./assets/loans-forms";
import { createLongTermAddItemSections, createLongTermLifeInsuranceAddItemSections } from "./assets/long-term-saving-forms";
import { createOtherAddItemSections } from "./assets/other-forms";
import { createEquityAddItemSections, createOptionsAddItemSections, createTraditionalBusinessAddItemSections } from "./assets/private-businesses-forms";
import { createPrivateLoanAmortizedAddItemSections } from "./assets/private-loan-amortized-forms";
import { createPrivateLoanNonAmortizedAddItemSections } from "./assets/private-loan-non-amortized-forms";
import { createP2pLendingPlatformAddItemSections, createPrivateLoanAddItemSections } from "./assets/private-loans-OLD-forms";
import { createGeneralBrokerageAddItemSections, createPublicMarketsTickersAddItemSections } from "./assets/public-markets-forms";
import { createRealEstateDevelopmentSoleOwnershipAddItemSections, createRealEstateDevelopmentWithPartnersAddItemSections, createRealEstateRentalPropertySoleOwnershipAddItemSections, createRealEstateRentalPropertyWithPartnersAddItemSections, createRealEstateResidenceAddItemSections } from "./assets/real-estate-forms";
import { createCryptoItemsSections } from "./assets/synced-crypto-forms";
import { expendFormFieldsFnByKeys } from "./form-utils";

const { RETIREMENT_TITLE_DESCRIPTION, CHILDRENS_SAVINGS_TITLE_DESCRIPTION, OTHER_TITLE_DESCRIPTION } = getTranslations().new_item_modal.long_term_savings;

export const ASSET_CATEGORIES_ADD_ITEM_FORMS = {
    1: expendFormFieldsFnByKeys(createCashAccountsAddItemSections(false)),
    2: expendFormFieldsFnByKeys(createCashAccountsAddItemSections(false)),
    3: expendFormFieldsFnByKeys(createCashAccountsAddItemSections(false)),
    4: expendFormFieldsFnByKeys(createCashAccountsAddItemSections(false)),
    5: expendFormFieldsFnByKeys(createCashAccountsPhysicalCashAddItemSections(false)),
    6: expendFormFieldsFnByKeys(createLongTermAddItemSections(RETIREMENT_TITLE_DESCRIPTION, false)),
    10: expendFormFieldsFnByKeys(createLongTermAddItemSections(CHILDRENS_SAVINGS_TITLE_DESCRIPTION, false)),
    11: expendFormFieldsFnByKeys(createLongTermAddItemSections(OTHER_TITLE_DESCRIPTION, false)),
    12: expendFormFieldsFnByKeys(createCryptoAddItemSections(false)),
    36: expendFormFieldsFnByKeys(createCryptoItemsSections(false)),
    13: expendFormFieldsFnByKeys(createGeneralBrokerageAddItemSections(false)),
    15: expendFormFieldsFnByKeys(createRealEstateRentalPropertySoleOwnershipAddItemSections(false)),
    16: expendFormFieldsFnByKeys(createRealEstateRentalPropertyWithPartnersAddItemSections(false)),
    17: expendFormFieldsFnByKeys(createRealEstateDevelopmentSoleOwnershipAddItemSections(false)),
    18: expendFormFieldsFnByKeys(createRealEstateDevelopmentWithPartnersAddItemSections(false)),
    // 20: REIT_FORM_SECTIONS,
    21: expendFormFieldsFnByKeys(createRealEstateResidenceAddItemSections(false)),
    22: expendFormFieldsFnByKeys(createPrivateLoanAddItemSections(false)),
    23: expendFormFieldsFnByKeys(createP2pLendingPlatformAddItemSections(false)),
    24: expendFormFieldsFnByKeys(createEquityAddItemSections(false)),
    25: expendFormFieldsFnByKeys(createOptionsAddItemSections(false)),
    31: expendFormFieldsFnByKeys(createTraditionalBusinessAddItemSections(false)),
    14: expendFormFieldsFnByKeys(createHedgeFundAddItemSections(false)),
    19: expendFormFieldsFnByKeys(createRealEstateFundAddItemSections(false)),
    26: expendFormFieldsFnByKeys(createPrivateEquityAddItemSections(false)),
    27: expendFormFieldsFnByKeys(createVentureCapitalAddItemSections(false)),
    32: expendFormFieldsFnByKeys(createDebtFundAddItemSections(false)),
    28: expendFormFieldsFnByKeys(createOtherAddItemSections(false)),
    29: expendFormFieldsFnByKeys(createOtherAddItemSections(false)),
    30: expendFormFieldsFnByKeys(createOtherAddItemSections(false)),
    33: expendFormFieldsFnByKeys(createLoanAddItemSections(false)),
    34: expendFormFieldsFnByKeys(creditCardAddItemSections(false)),
    35: expendFormFieldsFnByKeys(createEquityAddItemSections(false)),
    37: expendFormFieldsFnByKeys(createAmortizationLoanAddItemSections(false)),
    38: expendFormFieldsFnByKeys(createPrivateLoanAmortizedAddItemSections(false)),
    39: expendFormFieldsFnByKeys(createPublicMarketsTickersAddItemSections(false)),
    40: expendFormFieldsFnByKeys(createLongTermLifeInsuranceAddItemSections()),
    41: expendFormFieldsFnByKeys(createPrivateLoanNonAmortizedAddItemSections()),
}