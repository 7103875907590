import { Observer } from 'mobx-react';
import React from 'react';
import Lottie from 'react-lottie-player';
import completeAnimData from '../../../assets/onboarding/fireworks.json';
import { useStore } from '../../app/data/root.store';
import { OnboardingLayoutWrapper, PageContent, PageWrapper } from './OnBoarding.styles';
import { OnBoardingQuestionnaire } from './Questionnaire/OnBoardingQuestionnaire';
import { OnboardingSidebar } from './OnboardingSidebar/OnboardingSidebar';

export function OnBoardingLayout(props) {

    const { onBoardingStore, uiStore } = useStore();

    return (<Observer>{() => (

        <OnboardingLayoutWrapper>
            {uiStore.isDesktopView && <OnboardingSidebar />}
        <PageWrapper>

            {/* <MenuAndContentWrapper>
         <OnBoardingSideMenu /> */}
         
            <PageContent noPaddingBottom={[0,6].includes(onBoardingStore.questionnaireStep)} alignCenter={[0,1,6].includes(onBoardingStore.questionnaireStep)} >
                <OnBoardingQuestionnaire />
            </PageContent>

            {onBoardingStore.completeAnimationStage > 0 && <>
                <Lottie
                    loop={true}
                    animationData={completeAnimData}
                    //play={showLetsGoAnim}
                    play
                    style={{ position: 'absolute', top: '200px', left: '50%', zIndex: '2' }}
                />
                <Lottie
                    loop={true}
                    animationData={completeAnimData}
                    //play={showLetsGoAnim}
                    speed={2}
                    play
                    style={{ position: 'absolute', top: '80px', left: '20%', zIndex: '2', width: '300px' }}
                />

            </>}
            {onBoardingStore.completeAnimationStage > 1 && <>
                <Lottie
                    loop={true}
                    animationData={completeAnimData}
                    //play={showLetsGoAnim}
                    speed={2}
                    play
                    style={{ position: 'absolute', top: '480px', left: '30%', zIndex: '2', width: '300px' }}
                />
            </>}
            {onBoardingStore.completeAnimationStage > 2 && <>
                <Lottie
                    loop={true}
                    animationData={completeAnimData}
                    //play={showLetsGoAnim}
                    // speed={}
                    play
                    style={{ position: 'absolute', top: '20px', left: '55%', zIndex: '2', width: '250px' }}
                />
            </>}

            {/* </MenuAndContentWrapper> */}
        </PageWrapper>
        </OnboardingLayoutWrapper>
    )}</Observer>)
}