import React from 'react';
import { useStore } from '../../app/data/root.store';
import { ActionButton, ActionButtonsWrapper, NotificationsCashRelatedEmptyStateHeader, NotificationsCashRelatedEmptyStateTitle, NotificationsCashRelatedEmptyStateWrapper, NotificationsEmptyStateIconWrapper } from './Notifications.styles';
import EmptyNotificationsPng from './icons/EmptyNotifications.png';
import { ActionLockedIconWithTooltip } from '../../../common/components/ActionLocked/ActionLockedIconWithTooltip';

export const NotificationsCashRelatedEmptyState = (props) => {
  const { hasNoSyncedCashAccounts, onItemClick } = props;
  const { createItemStore, billingStore, userStore, uiStore } = useStore();

  const 
  handleMainActionClick = () => {
    if(userStore.isDemoOrReadOnlyMode) {
      billingStore.setShowPricingTableModal(true);
    } else {
      if (hasNoSyncedCashAccounts) {
        if (!userStore.isActionBlockedByRole('classPageEmptyStateSyncNew')){
        createItemStore.setInstitutionStep('bank');
        createItemStore.setShowModal(true);
        }
      } else {
        createItemStore.setChooseMethodStep();
        createItemStore.setShowModal(true);
      }
    } 
    onItemClick && onItemClick();
  }

  const handleLearnMoreOnClick = () => {
    window.open('https://help.vyzer.co/en/articles/7033045-notifications', '_blank');
    onItemClick && onItemClick();
  }

  return (
    <NotificationsCashRelatedEmptyStateWrapper fullWidth={uiStore.isMobileAppView}>
      <NotificationsEmptyStateIconWrapper>
        <img src={EmptyNotificationsPng} alt='no notifications' />
      </NotificationsEmptyStateIconWrapper>
      <NotificationsCashRelatedEmptyStateHeader>Notifications</NotificationsCashRelatedEmptyStateHeader>
      <NotificationsCashRelatedEmptyStateTitle>
        Keeping track of the inflows and outflows of your assets has never been easier.
      </NotificationsCashRelatedEmptyStateTitle>
      <ActionButtonsWrapper>
        <ActionButton onClick={handleMainActionClick} black>
          {hasNoSyncedCashAccounts ?
            'Sync your cash account' :
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center' }}><PlusSvg /> Add a cash generating asset</div>}
            {userStore.isDemoOrReadOnlyMode || (hasNoSyncedCashAccounts && userStore.isActionBlockedByRole('classPageEmptyStateSyncNew')) ? <ActionLockedIconWithTooltip iconColor={'#fff'} marginTop={'-2px'} isRoleBlocked={userStore.isActionBlockedByRole('classPageEmptyStateSyncNew')}/> : null}
        </ActionButton>
        <ActionButton onClick={handleLearnMoreOnClick} >Learn more</ActionButton>
      </ActionButtonsWrapper>
    </NotificationsCashRelatedEmptyStateWrapper>
  )
}

const PlusSvg = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6H11" stroke="#fff" strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M6.00024 11L6.00024 0.999999" stroke="#fff" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  )
}