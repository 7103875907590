import styled from "@emotion/styled/macro";
import { colors } from "../../../../../common/styles/theme.styles";

const center = `
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);`

export const FilterOptionsWrapper = styled.div`
  padding: 16px 24px;
  .ant-slider-handle {
    &-dragging, &:focus, &:hover {
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.09), 0px 0px 3px rgba(0, 0, 0, 0.26) !important
    }
      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 9px;
        border-inline: 1px solid ${colors.darkGray3};
        ${center}
      }
      &::before {
        content: '';
        position: absolute;
        background-color: ${colors.darkGray3};
        width: 1px;
        height: 9px;
        ${center}
      }
  }
`;

export const RangeParameterFilterWrapper = styled.div`
  display: flex;
  gap: 14px;
  /* margin: 12px 0; */
  margin-bottom: 30px;
  align-items: center;
  /* & > div {
    flex-grow: 1;
  }   */
  .range-number-input{
    width: 100%;
    border: 1px solid ${colors.gray3};
    border-radius: 8px;
    padding: 6px 12px;
    &:focus-visible {
      outline: 1px solid ${colors.darkGray1};
    }
  }
`;