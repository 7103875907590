import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { isNullOrUndefinedOrEmptyString } from "../../../../../common/utils/object.utils";
// import { disabledMoreThanToday } from "../../../../../common/utils/dates.utils";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";
import { containerRow } from "../../common-fields/container-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { masterCryptoInputRows } from "../../common-fields/master-crypto.const";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { isBetweenZeroAndHundred } from "../../input-validations";
// import { isRequired } from "../../input-validations"

export const createSyncedCryptoAddItemSections = (isEditForm) => {
    return ({updateField,updateValue}) => {
        const { crypto } = getTranslations().new_item_modal;
        // const {CASH_INVESTED_LABEL} = getTranslations().common.asset_fields;
        return ([
            {
                title:'General info',
                rows:[
                    ...masterInstitutionInputRows(crypto.NAME_LABEL,crypto.NAME_DESCRIPTION, 9, false, updateValue),
                    beneficiaryRow({}),
                     ...masterCryptoInputRows('Coin', '', 12, false, updateValue, updateField),
                    {fields:[
                        {key: 'title', isHidden:true,defaultValue:''}
                    ]},
                    {fields:[
                        {key: 'value', isHidden:true,defaultValue:0}
                    ]},
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : []),   
                ]
            },{
                title: 'BUY & SELL HISTORY',
                fullWidth: true,
                margin: '0 -24px',
                rows: [
                    {
                        fields: [
                            {
                                key: 'cryptoDistributions', label: '', description: '', type: 'cryptoTradingTable',
                                showHistory: false,
                                generatePropsFn: (values) => {
                                    return { prefix: CURRENCY_SYMBOL[values.currency] || 'USD', placeholder: CURRENCY_SYMBOL[values.currency], holdings: values.holdings}
                                },
                                onChange:({value})=>{
                                    const coinsValue = value?.reduce((acc, row) => acc + (isNullOrUndefinedOrEmptyString(row.quantity) ? 0 : row.quantity * ( ['Sell','Transfer'].includes(row.eventType) ? -1 : 1)), 0)
                                    updateValue('value',coinsValue);
                                },
                                validations: [
                                    {
                                        fn: (value) => {
                                            if (!value.length) {
                                                return false;
                                            }

                                            const isValid = value.map(row=>{
                                                // console.log({...row});
                                                if(row.quantity === '' || row.cost === '' || row.pricePerCoin === undefined) {
                                                    return false;
                                                }
                                                return true;
                                            })
                                            // console.log(isValid);
                                            if(isValid.includes(false)){
                                                return false;
                                            }
                                            // console.log(value[0]);
                                            return true //false;
                                        },
                                        message: `Required`
                                    },
                                ],
                            },
                        ]
                    },
                ]
            },
            {
                title:`More Info`,
                rows:[
                    ...(isEditForm ? [
                        containerRow({isEditForm, updateField}),
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isLocked: true,  UnlockComponent:HoldingsUnlockModal,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                }
                            ]
                        },
                        {
                            fields:[
                                {key:'defaultCashAccountId'}
                            ]
                        },
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {fields:[
                        {key:'remarks'}
                    ]}
                ]
            }
        ])
    }
}


export const createCryptoItemsSections = (isEditForm) => {
    return ({updateValue,updateField}) => {
        const { brokerage_accounts , crypto } = getTranslations().new_item_modal;
        return ([
            {
                title:'General info',
                rows:[
                    ...masterInstitutionInputRows(crypto.NAME_LABEL,crypto.NAME_DESCRIPTION, 3, false, updateValue, !isEditForm, 
                        // [{
                        //     fn: (value, formValues) => {
                        //         if (!value || !formValues.name) {
                        //             return false;
                        //         }
                        //         return true
                        //     },
                        //     message: `Required`
                        // },]
                    ),
                    containerRow({isEditForm, updateField}),
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : [
                        beneficiaryRow({label: 'Holding entity'}),
                        {fields:[
                            {
                                key:'currency', label: 'Transaction currency',
                                description: 'The currency used for buying and selling your crypto coins',
                                onChange:({value,formValues})=>{
                                    updateField('coins',{currency: value})
                                }
                            }
                        ]},
                    ]),
                ]
            },{
                title: isEditForm ? 'Ticker info' : () => (<div style={{display:'flex', flexDirection:'column', gap:'6px'}}>
                    <div>Add Coins</div>
                    <div style={{color:'#9097A3', fontFamily:'Circular', textTransform:'none', fontSize:'14px', fontWeight:450}}>Add your current holdings here. Historical information, such as purchase, sales and cost basis, can be added later.</div>
                    </div>),
                fullWidth: true,
                // margin: '0 -24px',
                rows:[
                    ...(isEditForm ? [
                        {fields:[
                            {key:'title', label:'Ticker name', disabled: true,
                                defaultValue: '',
                            }
                        ]},
                        {fields:[
                            {key:'stockType', label:'Type', disabled: true, type:'text', defaultValueFn: (values) => values.stock.type }
                        ]},
                        {fields:[
                            {key:'exchangePlatform', label: 'Exchange', type:'text', disabled: true, defaultValueFn: (values) => values.stock.exchange}
                        ]},
                        // {fields:[
                        //     { key: 'companySector', type: 'select', label: 'Company sector', description: '', options: COMPANY_SECTOR_OPTIONS, allowClear: true }
                        // ]},
                        {fields:[
                            {key:'marketCap', type:'text', label:'Market Capitalization', disabled: true, defaultValue: 'Loading...'}
                        ]},

                    ] : [
                        {fields:[
                            {key: 'coins', type:'cryptoHoldingsTable',
                            currency: 'USD',
                            defaultValue: [{ quantity: '', searchStr: '', selectedObject: null, pricePerShare: '', openOnLoad: true }],
                            validations: [
                                {
                                    fn: (value) => {
                                        if (!value.length) {
                                            return false;
                                        }
    
                                        const isValid = value.map(row=>{
                                            if(!row.quantity || !row.selectedObject) {
                                                return false;
                                            }
                                            return true;
                                        })
                                        // console.log(isValid);
                                        if(isValid.includes(false)){
                                            return false;
                                        }
                                        // console.log(value[0]);
                                        return true //false;
                                    },
                                    message: `Required`
                                },
                            ],
                            setFnForSave: (isDistribution, value, formValues) => {
                                const normalizedStocks = value.slice().map(stock => {
                                    const { symbol, coinName, name , imageUrl } = stock.selectedObject;
                                    const { pricePerShare, quantity, usdPrice } = stock;
                                    return {
                                        symbol,
                                        coinName,
                                        name,
                                        imageUrl,
                                        usdPrice,
                                        pricePerCoin: +pricePerShare,
                                        quantity: +quantity
                                    }
                                })
                                return normalizedStocks;
                            }
                            }
                        ]},
                        {fields:[
                            {key:'title', isHidden:true, disabled: true,
                                defaultValue: '',
                            }
                        ]}
                    ])
                ]
            },
            ...(isEditForm ? [{
                title:`More Info`,
                rows:[
                    beneficiaryRow({label: 'Holding entity'}),
                    {
                        fields:[
                            {key:'defaultCashAccountId', description: brokerage_accounts.DEFAULT_CASH_ACCOUNT_DESCRIPTION}
                        ]
                    },
                    // containerRow({isEditForm, updateField}),
                    {
                        fields:[
                            {key:'holdings',  label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isLocked: true, UnlockComponent:HoldingsUnlockModal,
                            validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                            validations:[isBetweenZeroAndHundred],
                            }
                        ]
                    },
                    ...(isEditForm ? [
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {fields:[
                        {key:'remarks', label: 'Remarks' }
                    ]}
                ]
            }] : [])
        ])
    }
} 