import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { removeUrlParam } from '../../../common/utils/navigation.utils';
import { useStore } from '../../app/data/root.store';
import { OnBoardingHeader } from '../../layout/components/Header/OnBoardingHeader';
// import { ReactComponent as BackArrowIcon } from '../../onboarding/assets/back-arrow-icon.svg';
// import { QuestionnaireBackButtonWrapper } from '../../onboarding/components/Questionnaire/OnBoardingQuestionnaire.styles';
import { AUTH_STEPS } from "../../user/data/user.store";
import { ConciergeRightPane } from './ConciergeRightPane';
import { LoginCenterContent, LoginWrapper } from './LoginPage.styles';
import { EmailVerificationStep } from './steps/EmailVerificationStep';
import { ForgotPasswordStep } from './steps/ForgotPasswordStep';
import { LogInStep } from './steps/LogInStep';
import { ResetPasswordStep } from './steps/ResetPasswordStep';
import { SetPasswordStep } from './steps/SetPasswordStep';
import { SignUpStep } from './steps/SignUpStep';
import { ls } from "../../../common/utils/localstorage.util";
import { useAnalytics } from '../../app/data/AnalyticsProvider';
import { InvitationStep } from './steps/InvitationStep';


const relevantLandingPagePlans = ['starter', 'plus', 'premium', 'familyoffice'];

function LoginPage(props) {

  const { userStore } = useStore();
  
  const history = useHistory();
  const location = useLocation();

  const { mixpanel } = useAnalytics();

  useEffect(() => {
    const distinct_id = new URLSearchParams(location.search).get('distinct_id');
    const plan = new URLSearchParams(location.search).get('plan');
    ls.set('isAfterLogin', 1);

    const alias = mixpanel.get_distinct_id()

    if (alias !== distinct_id) {
      mixpanel.alias(alias, distinct_id)
      // removeUrlParam(history, location, 'distinct_id'); 
    }
    if (plan && plan !== 'starter') {
      const score = relevantLandingPagePlans.indexOf(plan) + 1;
      ls.set('questionnaireScore', score);
      removeUrlParam(history, location, 'plan');
    }
    return () => { }
  }, [mixpanel, history, location, userStore]);

  return (<Observer>{() => (
    <div style={{ display: 'flex', height: '100%' }}>
      <LoginWrapper>
        <OnBoardingHeader isLoginLayout />

        <LoginCenterContent>
          {userStore.authStep === AUTH_STEPS.LOG_IN_STEP && <LogInStep />}
          {userStore.authStep === AUTH_STEPS.SIGN_UP_STEP && <SignUpStep />}
          {userStore.authStep === AUTH_STEPS.EMAIL_VERIFICATION_STEP && <EmailVerificationStep />}
          {userStore.authStep === AUTH_STEPS.SET_PASSWORD_STEP && <SetPasswordStep />}
          {userStore.authStep === AUTH_STEPS.RESET_PASSWORD_STEP && <ResetPasswordStep />}
          {userStore.authStep === AUTH_STEPS.FORGOT_PASSWORD_STEP && <ForgotPasswordStep />}
          {userStore.authStep === AUTH_STEPS.INVITATION && <InvitationStep />}
          {/* <CurrentAuthStep login={login} /> */}

          {/* <Title>{showCodeInput ? 'Verify your email' : (isRegister ? 'Create your vyzer account' : 'Welcome to vyzer')}</Title>
          {(!isRegister && !showCodeInput) ? null : <SubTitle>{showCodeInput ? `Enter the code we sent to ${loginRegEmail}` : 'Manage and grow your finances with full visibility'}</SubTitle>} */}

        </LoginCenterContent>
      </LoginWrapper>
      <ConciergeRightPane />
    </div>
  )}</Observer>)
}


export { LoginPage };
