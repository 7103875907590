import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../../../../common/styles/combos.styles";
import { colors, shadows } from "../../../../../../../common/styles/theme.styles";

export const HoldingsUnlockModalWrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  padding: 24px 50px;
`;

export const HoldingsUnlockModalContent = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    text-align: center;
    gap: 12px;
`;

export const ActionsWrapper = styled.div`
    ${flexCenter};
    width: 100%;
    gap: 16px;
    padding: ${props => props.padding ? props.padding : '40px 0 0'};
`;

export const ActionButton = styled.div`
    background-color: ${props=>props.black ? colors.darkGray1 : '#fff'} ;
    box-shadow: ${shadows.toggleShadow};
    border-radius: 4px;
    padding:8px 16px;
    font-size:14px;
    color: ${props=> props.black ? '#fff' : '#000' };
    cursor: pointer;
    text-align:center;
    transition:all 0.2s;
    flex: 1;
    ${props => props.flexGrow ? `flex-grow: ${props.flexGrow}` : null};
    ${props => props.nowrap ? `white-space: nowrap` : null};
    ${props=>props.disabled ? `
      cursor: not-allowed;
      opacity: 0.3;
    ` : ``}
`;

export const HoldingsUnlockModalMainText = styled.div`
  font-weight: 500;
  line-height: 130%;
  color: ${colors.darkGray1};
  margin: ${props=>props.margin ? `${props.margin}` : '0'};
  ${props=>props.fontSize ? `font-size: ${props.fontSize}px` : `font-size: 16px`};
`;

export const HoldingsUnlockModalAltText = styled(HoldingsUnlockModalMainText)`
  font-weight: 400;
  ${props=>props.fontSize ? `font-size: ${props.fontSize}px` : `font-size: 14px`};
  ${props=>props.center ? "text-align: center;" : null};
`;

export const HoldingsUnlockModalSmallText = styled(HoldingsUnlockModalMainText)`
  font-weight: 400;
  color: ${colors.darkGray2};
  margin: ${props=>props.margin ? `${props.margin}` : '20px 0'};
  ${props=>props.fontSize ? `font=size: ${props.fontSize}` : 'font-size: 14px'};
  ${props=>props.center ? "text-align: center;" : null};
`;

export const EditButtonWrapper = styled.div`
  ${flexCenter};
  padding: 8px 24px;
  border-radius: 6px;
  border: 1px solid red;
  color: red;
  font-family: Circular;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  min-width: 174px;
`;

export const CancelButtonWrapper = styled(EditButtonWrapper)`
  color: ${colors.darkGray1};
  border: none;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16), 0px 1px 6px 0px rgba(0, 0, 0, 0.04);
`;

export const ModalCloseButtonSvgWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;