import styled from '@emotion/styled/macro'
import { colors } from '../../../../common/styles/theme.styles';

export const MessageWrapper = styled.div`
  position:fixed;
  top:0;
  left:0;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  background-color: #FFFFFF;
  z-index: 99999991;
  width: 100vw;
  height: 100vh;
  /* font-family: -apple-system, BlinkMacSystemFont, sans-serif; */
`

export const LogoWrapper = styled.a`
  padding:24px;
  // width:100%;
`;

export const IllustrationWrapper = styled.a`
  padding-bottom:12px;
  // width:100%;
`;
export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:0 24px 24px;
  max-width: 400px
`;

export const MessageTitle = styled.div`
  font-family: 'Domine';
  font-size:28px;
  font-weight:400;
  line-height: 36px;
  color: ${colors.darkGray1};
  text-align: center;
`;

export const MessageSubtitle = styled.div`
  max-width: 305px;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.darkGray1};
  margin-top: 24px;
  margin-bottom: 36px;
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap:12px;
`;

export const MobileAppBtn = styled.a`
  width: 176px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:8px;
  font-size: 16px;
  font-weight: 450px;
  color: ${colors.darkGray1};
  border-radius: 36px;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16), 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  :hover , :active {
    color: ${colors.darkGray1};
  }
`;

export const UnderstandButton = styled(MobileAppBtn)`
  border: 1px solid ${colors.darkGray1};
  border-radius: 28px;
  background-color: ${colors.darkGray1};
  color:#fff;
  :hover , :active {
    color: #fff;
  }
`;

export const MessageButton = styled.a`
  background: ${colors.darkGray1};
  border-radius: 6px;
  color:#fff;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  padding: 10px 28px;
  display:inline-flex;
  font-weight:500;
`;

export const BottomPanel = styled.div`
  padding:16px;
  border-top:1px solid gray;
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
`;

export const IconWrapper = styled.div`
  height: 34px;
  margin-bottom: 24px;
`;

export const MessageTextSecond = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.darkGray1};
  margin-bottom: 20px;

  span{
    font-weight: 700;
  }
`;

export const MessageTextThird = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.darkGray1};
  margin-bottom: 20px;
`;