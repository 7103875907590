import { Observer } from 'mobx-react'
import React, { useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal'
import { BlueButton, SecondaryButton } from '../../../../common/components/buttons/NewButtons.styles'
import { useStore } from '../../../app/data/root.store'
import { CloseButtonSvgWrapper, ModalActionsWrapper, ModalContent, ModalContentPerk, ModalContentPerksWrapper, ModalContentSubtitle, ModalContentTitle, ModalHeader, ModalHeaderWrapper, NoCreditRequiredText } from './StarterPlanModal.styles'
import { ROUTES } from '../../../../common/constants/routes.const';

export const StarterPlanModal = ({onClose}) => {
  const { billingStore, uiStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleContinueClick = async () => {
    setIsLoading(true);
    await billingStore.setStarterPlan();
    window.location.href = `${ROUTES.wealthOverview.url}?subscribed_success&sw`;
  }

  return (<Observer>{() => (
    <BaseModal width={'460px'} height={uiStore.isDesktopView ? '560px' : 'auto'} borderRadius={12} customBackDropStyle={{ zIndex: '99999999'}} customModalWrapperStyles={{ ...(uiStore.isMobileScreenSize ? { marginLeft: 0 } : {}) }}>
      <ModalHeaderWrapper>
        <ModalHeader>SELECTED PLAN</ModalHeader>
        <CloseButtonSvgWrapper onClick={onClose}><CloseButtonSvg /></CloseButtonSvgWrapper>
      </ModalHeaderWrapper>
      <ModalContent>
        <ModalContentTitle>Starter / Free</ModalContentTitle>
        <ModalContentSubtitle>Explore Vyzer free for an unlimited time</ModalContentSubtitle>
        <ModalContentPerksWrapper>
          <ModalContentPerk><CheckMarkSvg />Sync unlimited banks and brokerage platforms</ModalContentPerk>
          <ModalContentPerk><CheckMarkSvg />Unlimited holdings</ModalContentPerk>
          <ModalContentPerk><CheckMarkSvg />Create up to 2 cash flow planning scenarios</ModalContentPerk>
          <ModalContentPerk><CheckMarkSvg />Manage 2 entities</ModalContentPerk>
          <ModalContentPerk><CheckMarkSvg />Unlimited uploads for setup, then 1 doc per month</ModalContentPerk>
        </ModalContentPerksWrapper>

        <ModalActionsWrapper>
          <BlueButton onClick={handleContinueClick} style={{ height: '48px' }}>{isLoading ? <LoadingOutlined /> : 'Continue'}</BlueButton>
          <SecondaryButton onClick={() => { billingStore.setShowStarterModal(false) }} style={{ height: '48px', borderRadius: '30px', boxShadow: '0px 1px 10px rgba(0, 71, 255, 0.15)' }}>Select another plan</SecondaryButton>
        </ModalActionsWrapper>
        <NoCreditRequiredText>No credit card required. You can upgrade anytime.</NoCreditRequiredText>
      </ModalContent>
    </BaseModal>
  )}</Observer>)
}


const CheckMarkSvg = () => {
  return (
    
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" style={{minWidth:'14px'}} xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2145 5.01884L6.12854 9.10713L4.08336 7.06257" stroke="#1A1B1D" strokeWidth="2" />
    </svg>
  )
}

const CloseButtonSvg = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="8" width="28" height="28" rx="14" fill="black" fillOpacity="0.05" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.4194 27.7302L16.5199 17.8307L17.5806 16.77L27.4801 26.6695L26.4194 27.7302Z" fill="#585D66" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.4802 17.8306L17.5807 27.7301L16.52 26.6694L26.4195 16.7699L27.4802 17.8306Z" fill="#585D66" />
    </svg>
  )
}