export const startIntercomTourById = (tourId) => {
  window.Intercom && window.Intercom('startTour', ''+tourId);
}

export const startIntercomNewMessage = () => {
    window.Intercom && window.Intercom('showNewMessage');
}

export const showIntercomLuncher = () => {
  window.Intercom && window.Intercom('showNewMessage');
}