import styled from "@emotion/styled/macro";
import { colors, shadows } from "../../../../styles/theme.styles";

export const RadioWrapper = styled.div`
    margin-top: 16px;
    display: flex;
    gap: 16px;
`;

export const RadioOptionWrapper = styled.div`
  padding: 10px 18px;
  border-radius:8px; 
  font-size:16px;
  font-weight:500;
  box-shadow:${shadows.toggleShadow};
  color: ${colors.darkGray1};
  border: 1px solid ${props=> props.selected ? colors.darkGray1 : '#fff'};
  cursor: pointer;
  transition: all 0.2s;
  background-color: #fff;
  ${props=> props.disabled ? `
    opacity: 0.4;
    cursor: not-allowed;
  ` : ``}
`;

export const RadioLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioSubLabelWrapper = styled.div`
  color: ${colors.darkGray3};
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;