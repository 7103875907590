import { observer } from 'mobx-react'
import React from 'react'
import { useStore } from '../app/data/root.store'
import { PricingModal } from '../onboarding/components/PricingModal/PricingModal'
import { MobilePricingModal } from '../onboarding/components/PricingModal/MobilePricingModal'
import { MobileUserMenuWrapper } from '../user/components/Portfolios/portfolios.styles'
import { UserProfileMenu } from '../layout/components/Sidebar/UserProfileMenu'

export const PricingPage = observer(() => {
  const { billingStore, uiStore } = useStore();

  return (
      uiStore.isDesktopView ?
      <>
      <PricingModal isFullPage onClose={() => { billingStore.setShowPricingTableModal(false) }} onBackDropClick={() => { billingStore.setShowPricingTableModal(false) }} />
      <MobileUserMenuWrapper style={{zIndex:'1002', color:'#fff', width:'22%'}} isFullPage><UserProfileMenu /></MobileUserMenuWrapper>
      </>
      :
      <MobilePricingModal onClose={()=>{ billingStore.setShowPricingTableModal(false); }} />
  )
})