import React from 'react';

import {ReactComponent as CashIcon} from '../../../assets/icons/asset-class/cash.svg';
import {ReactComponent as LongIcon} from '../../../assets/icons/asset-class/long.svg';
import {ReactComponent as BrokerageIcon} from '../../../assets/icons/asset-class/brokerage.svg';
import {ReactComponent as RealIcon} from '../../../assets/icons/asset-class/real.svg';
import {ReactComponent as FundsIcon} from '../../../assets/icons/asset-class/funds.svg';
import {ReactComponent as LoansIcon} from '../../../assets/icons/asset-class/loans.svg';
import {ReactComponent as BusinessesIcon} from '../../../assets/icons/asset-class/businesses.svg';
import {ReactComponent as CryptoIcon} from '../../../assets/icons/asset-class/crypto.svg';
import {ReactComponent as OtherIcon} from '../../../assets/icons/asset-class/other.svg';
import {ReactComponent as LiaLoansIcon} from '../../../assets/icons/liability-class/lia-loans.svg';
import {ReactComponent as CreditIcon} from '../../../assets/icons/liability-class/lia-credit.svg';
import {ReactComponent as TaxIcon} from '../../../assets/icons/liability-class/lia-tax.svg';
import {ReactComponent as FilterIcon} from '../../../assets/icons/common/filter.svg';
import {ReactComponent as HomeIcon} from '../../../assets/icons/common/home.svg';

export const AssetClassIcon = ({name}) => {
    switch (name) {
        case 'cash':
          return <CashIcon />;
        case 'long':
            return <LongIcon />;
        case 'brokerage':
          return <BrokerageIcon />;
        case 'real':
            return <RealIcon />;
        case 'funds':
            return <FundsIcon />;
        case 'loans':
          return <LoansIcon />;
        case 'businesses':
          return <BusinessesIcon />;
        case 'crypto':
          return <CryptoIcon />;
        case 'other':
            return <OtherIcon />;
        case 'lia-loans':
            return <LiaLoansIcon />;
        case 'lia-credit':
            return <CreditIcon />;
        case 'lia-tax':
            return <TaxIcon />;
        case 'filtered':
          return <FilterIcon />
        case 'home':
          return <HomeIcon />
        default:
          return null;
      }
};