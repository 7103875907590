import { Observer } from 'mobx-react'
import React from 'react'
import { ThunderboltOutlined } from '@ant-design/icons';
import { ActionLocked } from '../../../../common/components/ActionLocked/ActionLocked'
import { useStore } from '../../../app/data/root.store'
import { ConnectionBigIcon } from '../icons/connected-institutions-icons'
import { InstitutionsListEmptyStateWrapper, SyncOnlinePlatformButtonWrapper } from './ConnectedInstitutionsList.styles'
import { ConnectedInstitutionsAltTitle, ConnectedInstitutionsTitle } from './InstitutionsPanel.styles'
import { PrimaryButtonWithIcon } from '../../../../common/components/buttons/NewButtons.styles'
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';

export const ConnectedInstitutionsListEmptyState = (props) => {
  const { handleConnect } = props;
  const { userStore } = useStore();

  return (<Observer>{() => (
    <InstitutionsListEmptyStateWrapper>
      <ConnectionBigIcon />
      <ConnectedInstitutionsTitle style={{ padding: '12px 0px 8px' }}>No synced accounts yet</ConnectedInstitutionsTitle>
      <ConnectedInstitutionsAltTitle style={{ color: '#9097A3' }}>
        Unleash the magic - Sync your checking accounts
      </ConnectedInstitutionsAltTitle>
      <SyncOnlinePlatformButtonWrapper>
        <ActionLocked action={'institutionSyncEmptyState'}>
        <PrimaryButtonWithIcon onClick={handleConnect} style={{margin:'0 8px'}}><ThunderboltOutlined />Sync an account {userStore.isReadOnlyMode && <ActionLockedIconWithTooltip iconColor={'#fff'} />}</PrimaryButtonWithIcon>
          {/* <SyncOnlinePlatformButton promptText='Sync an account' isActionLocked={userStore.isReadOnlyMode} afterConnect={(provider, accounts, inst) => { afterConnect && afterConnect(provider, accounts, inst) }} /> */}
        </ActionLocked>
      </SyncOnlinePlatformButtonWrapper>
    </InstitutionsListEmptyStateWrapper>
  )}</Observer>)
}
