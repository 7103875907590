import { Input } from 'antd';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { isNullOrUndefined } from '../../../../utils/object.utils';
import { PercentageInputWrapper } from '../../FormDisplayer.styles';

export const PercentageInput = ({ field, value, inputKey, handleChange, isItemConnected, handleBlur, disabled }) => {
  const [numberValue, setNumberValue] = useState(value*field.amount / 100);

  const numberToPercentage = (number) => {
    return number / field.amount * 100;
  }

  const percentageToNumber = (percentage) => {
    return percentage * field.amount / 100;
  }



  return (
    <PercentageInputWrapper>
      <NumberFormat
        thousandSeparator={true}
        suffix={'%'} decimalScale={field.specificDecimalScale || 2}
        max={100}
        amount={field.amount}
        placeholder={'%'}
        customInput={Input}
        style={{...field.isLocked && !disabled ? ({pointerEvents : 'none'}) : ({}) }}
        value={(isNullOrUndefined(value)) ? '' : value}
        disabled={ disabled || field.isLocked || field.disabled || (isItemConnected && field.disableIfConnected)}
        autoComplete="off"
        {...(field.validValues ? { isAllowed: field.validValues } : {})}
        // onValueChange={(values) => {handleChange(inputKey,values.floatValue ? values.floatValue : 0)}}
        onValueChange={(values) => {
          // console.log(value,values);
          if ('' + value !== values.value &&
            (!isNullOrUndefined(value) || !isNullOrUndefined(values.floatValue))) {
            handleChange(inputKey, (values.floatValue || values.floatValue === 0) ? values.floatValue : null)
          }
        }}
        onBlur={(e) => { handleBlur?.(inputKey); setNumberValue(percentageToNumber(value)) }}
      />
      {field.withNumber ? <NumberFormat
        thousandSeparator={true}
        customInput={Input}
        suffix={field.suffix}
        prefix={field.prefix}
        // style={isIncognito && field.shouldBeHiddenIncognito ? { WebkitTextSecurity: 'disc' } : {}}
        value={(isNullOrUndefined(numberValue)) ? '' : numberValue}
        disabled={ disabled || field.isLocked || field.disabled || (isItemConnected && field.disableIfConnected)}
        // disabled={field.disabled || field.isLocked || (isItemConnected && field.disableIfConnected)}
        autoComplete="off"
        // onValueChange={(values) => {
        //   if (numberValue !== values.floatValue &&
        //     (!isNullOrUndefined(numberValue) || !isNullOrUndefined(values.floatValue))) {
        //     handleChange(inputKey, (values.floatValue || values.floatValue === 0) ? values.floatValue : null)
        //   }
        // }}
        onValueChange={(values) => { setNumberValue(values.floatValue) }}
        onBlur={(e) => { 
          
          if (field.amount){
            handleChange?.(inputKey, Number(numberToPercentage(numberValue)));
            handleBlur?.(inputKey);
          } else {
            setNumberValue(0);
          }
        }}
        placeholder={field.placeholder}
        decimalScale={field.decimalScale || 2}
      // allowNegative={field.allowNegative || false}
      /> : null}
    </PercentageInputWrapper>
  )
}
