import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors, views } from "../../../../common/styles/theme.styles";
import { PrimaryButtonWithIcon } from "../../../../common/components/buttons/NewButtons.styles";

export const HoldingEntityRowCollapsible = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${colors.gray3};
  ${views.tablet} {
    padding: 6px 0px;
  }
`;

export const ThreeDotsCircleWrapper = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  :hover {
    background: #E8E9ED;
  }
`;

export const HoldingEntityRowCollapsibleHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 4px 0px 8px;
  gap: 8px;
  cursor: pointer;
  :hover {
    ${views.desktop} {
      background: ${colors.lightGray1};
    }
  }
    ${views.desktop} {
      ${props=>props.isOpen ? `
        border-bottom: 1px solid ${colors.gray3};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        ` : `
        border-radius: 8px;
      `}  
    }
  
`;

export const HoldingEntityRowCollapsibleDraggableIcon = styled.div`
  ${flexCenter}
`;

export const HoverActionWrapper = styled.div`
  ${flexCenter}
  width: 28px;
  height: 28px;
  padding: 4px;
  border-radius: 17px;
  opacity: 0;
  transition: opacity 0.3s ease;
  :hover {
    background: rgba(221, 227, 235, 0.50);
  }
`;

export const HoldingEntityRowCollapsibleDetailsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    :hover {
      ${views.desktop} {
        ${HoverActionWrapper} {
          opacity: 1;
        }
      }
    }
`;

export const HoldingEntityRowCollapsibleTitle = styled.div`
  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const HoldingEntityRowCollapsibleActionsWrapper = styled.div`
  display: flex;
  padding: 0 4px;
  align-items: center;
  // ${views.desktop} {
  //   border-left: 1px solid ${colors.gray3};
  // }
`;

export const HoldingEntityRowCollapsibleDefaultBadge = styled.div`
  ${flexCenter};
  padding: 2px 4px 2px 8px;
  border-radius: 4px;
  background: ${colors.lightGray2};
  color: ${colors.darkGray2};
  font-family: Circular;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const HoldingEntityRowCollapsibleThreeDots = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  padding: 8px;
  justify-content: center;
  align-items: center;
`;

export const HoldingEntityRowCollapsibleArrow = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: transform 0.2s;
  ${views.desktop} {
    ${props=> !props.isOpen ? `
      transform: rotate(180deg);
      ` : ``}
  }
  ${views.tablet} {
    transform: rotate(90deg);
  }
`;

export const CollapsibleContent = styled.div`
  display: flex;
  padding: 0 8px 6px 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const CustomSubClassListTitle = styled.div`
  display: flex;
  padding: 8px 0 8px 12px;
  align-items: flex-end;
  gap: 4px;
  align-self: stretch;
  color: ${colors.darkGray3};
  font-family: Circular;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const CustomSubClassRowWrapper = styled.div`
  display: flex;
  padding: ${props=>props.isEdited ? `0 12px 0 4px` : `6px 12px`};
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1;
  cursor: pointer;
  border-radius: 8px;
  :hover {
    ${props=>props.isEdited ? `` : `
      background: ${colors.lightGray1};
    `}
  }
`;

export const CustomSubClassRowWrapperTextWrapper = styled.div`
  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  flex:1;
`;

export const ActionsOnHoverWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CustomSubClassEmptyStateRowIconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${colors.lightGray2};
`;

export const CustomSubClassEmptyStateRowWrapper = styled.div`
  display: flex;
  padding: ${props=>props.isEditingSubClass ? `0 0 0 6px` : `8px 0 8px 12px`};
  align-items: center;
  gap: 8px;
  flex: 1;
  cursor: pointer;
  border-radius: 8px;
  :hover {
    ${props=>props.isEditingSubClass ? `` : `
      ${props.views ? `` : `background: ${colors.lightGray1};` }
      ${CustomSubClassEmptyStateRowIconWrapper} {
        background: ${colors.gray3};
      }
    `
  }

`;

export const CustomSubClassInputWrapper = styled.div`
  width: 100%;
  
  .ant-input{
      padding-left: 39px;
      height: 40px;
      font-size: 14px;
      font-weight: 450;
      outline: none;
      border-radius:8px;
      :hover, :active, :focus {
          box-shadow: none;
          border: 1px solid ${colors.gray3};
      }
      ${props => props.withError ? `
        border: 1px solid red;
    ` : ``}
  }

  .ant-input-borderless {
      background-color: #fff;
  }
`;

export const EraseSubClassSvgWrapper = styled.div`
  ${flexCenter }
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 17px;
  :hover {
    background: rgba(221, 227, 235, 0.50);
  }
`;

export const CustomSubClassEmptyStateRowTextWrapper = styled.div`
  color: ${colors.darkGray3};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const HoldingEntityActionMenuWrapper = styled.div`
  position: relative;
`;

export const HoldingEntityActionMenuContent = styled.div`
  ${views.desktop}{
    position: absolute;
  }
  width: 260px;
  border: 1px solid #DDE3EB;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  top: -20px;
  left: 100%;
`;

export const DeleteHoldingEntityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex:1;
`;

export const DeleteHoldingEntityHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  width:100%;
`;

export const DeleteHoldingEntityWrapper = styled.div`
  display: flex;
  padding: 0px 40px 48px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex:1;
`;

export const DeleteHoldingEntityMainText = styled.div`
  color: ${colors.darkGray1};
  text-align: center;
  font-family: Circular;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DeleteHoldingEntityAltText = styled.div`
  color: ${colors.darkGray2};
  text-align: center;
  font-family: Circular;
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const DeleteHoldingEntityFooter = styled.div`
  display: flex;
  padding: 18px 32px;
  justify-content: flex-end;
  align-items: center;
  width:100%;
  border-top: 1px solid ${colors.lightGray2};
`;

export const DeleteHoldingEntityActionButton = styled(PrimaryButtonWithIcon)`
  background: #FF0000;
  :hover {
    background: #FF0000;
  }
  ${views.tablet} {
    flex:1;
  }
`;