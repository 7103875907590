import React from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../../app/data/root.store';
import { CheckboxWrapper, DateButton, DateParameterWrapper, DatePickersWrapper, FilterWrapper, LeftDatePickerWrapper, RightDatePickerWrapper, WithCheckboxWrapper } from './StartEndDateParameterFilter.styles';
import { CheckmarkIcon } from '../../../../../assets/icons/common/common-icons';
import { Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import { enabledBetween } from '../../../../../common/utils/dates.utils';
import { EmptyParam } from '../FilterParameterTypeSwitcher';

const dateKeys = ['startDate', 'endDate'];

export const StartEndDateParameterFilter = ({ param }) => {
  const { filtersStore } = useStore();

  const handleOptionClick = (optionKey, isSelected) => {
    filtersStore.setStartEndDateSelected(optionKey, !isSelected);
  }

  const handleDateChange = (optionKey, dateKey, date) => {
    filtersStore.setStartEndDate(optionKey, dateKey, date)
  }

  const disabledDate = (current, from, to) => {
    return enabledBetween(current, from, to)
  }

  const handleIncludeNoEndDateChange = (checked) => {
    filtersStore.setStartEndDateIncludeNoEndDate(checked);
  }

  return (<Observer>{() => (
    <FilterWrapper>
      {dateKeys.map(dateKey => (<DateParameterWrapper key={dateKey}>
        <DateButton
          onClick={() => { handleOptionClick(dateKey, param[dateKey].isSelected) }}
          isSelected={param[dateKey].isSelected}>
          {dateKey === 'startDate' ? 'Start ' : 'End '}date {param[dateKey].isSelected ? <CheckmarkIcon /> : ''}
        </DateButton>
        {param[dateKey].isSelected && param[dateKey].isHidden ? <EmptyParam parameterTitle={dateKey === 'startDate' ? 'Start date' : 'End date'} /> : <>
        {param[dateKey].isSelected ? dateKey === 'startDate' ? 'Item created within:' : 'Expected end date within:' : ''}
        {param[dateKey].isSelected &&
          <WithCheckboxWrapper>
            <DatePickersWrapper>
              <LeftDatePickerWrapper>
                <DatePicker format={'MMM YYYY'} picker={'month'}
                  onChange={(date, dateString) => { handleDateChange(dateKey, 'from', date) }}
                  value={moment(param[dateKey].from, 'YYYY-MM')}
                  disabledDate={(current) => disabledDate(current, param[dateKey].defaultFrom, param[dateKey].to)}
                  allowClear={param[dateKey].from !== param[dateKey].defaultFrom}
                />
              </LeftDatePickerWrapper>
              <RightDatePickerWrapper>
                <DatePicker format={'MMM YYYY'} picker={'month'}
                  onChange={(date, dateString) => { handleDateChange(dateKey, 'to', date) }}
                  value={moment(param[dateKey].to, 'YYYY-MM')}
                  disabledDate={(current) => disabledDate(current, param[dateKey].from, param[dateKey].defaultTo)}
                  allowClear={param[dateKey].to !== param[dateKey].defaultTo}
                />
              </RightDatePickerWrapper>
            </DatePickersWrapper>
            {dateKey === 'endDate' && <CheckboxWrapper><Checkbox checked={param[dateKey].isNoEndDateIncluded} onChange={(e)=>{handleIncludeNoEndDateChange(e.target.checked)}}></Checkbox>Including assets with no end date</CheckboxWrapper>}
          </WithCheckboxWrapper>}
          </>}
      </DateParameterWrapper>))}
    </FilterWrapper>
  )}</Observer>)
}
