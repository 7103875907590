import { Tooltip } from 'antd'
import React from 'react'

export const ModifiedTooltip = ({ children, isLongText, overlayStyle, overlayInnerStyle, ...rest }) => {
  
  return (
    <Tooltip
      // visible
      overlayStyle={{ ...(isLongText ? { minWidth: '350px' } : {}) , ...(overlayStyle ? overlayStyle : {}) }}
      overlayInnerStyle={{ fontSize: '12px', background: 'rgba(0, 0, 0, 0.9)', borderRadius: '4px' , ...(overlayInnerStyle ? overlayInnerStyle : {})  }}
      {...rest}
    > {children} </Tooltip>
  )
}