import styled from '@emotion/styled/macro';
import { PrimaryButtonWithIcon } from '../../../../common/components/buttons/NewButtons.styles';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../../common/styles/theme.styles';

export const SimulationsPanelWrapper = styled.div`
    // position: fixed;
    display: flex;
    // top: ${props => props.isWithBanner ? '178' : '125'}px;
    /* width: 340px; */
    // max-width: 400px;
    // width: calc((100% - 864px) /2);
    // margin-right: 16px;
    /* height: 100%; */
    max-height: 100%;
    // bottom:40px;
    // right: 0;
    // left: calc(50% + 416px);
    // @media (max-width: 1519px) {
    //     width:0px;
    //  }
    // border-left:1px solid gray;
    //box-shadow: inset 1px 0px 0px #DDE3EB;
    @media (max-height: 979px) {
        height:100%;
    }
`

export const SimulationsDrawerOpenerPanel = styled.div`
    position: fixed;
    top: 72px;
    width: 0px;
    height: 100%;
    right: 64px;
    //box-shadow: inset 1px 0px 0px #DDE3EB;
    ::after{
      content:'';
      display:block;
      height: 100%;
      width:1px;
      box-shadow: inset 1px 0px 0px #DDE3EB;
    }
`

export const SimulationsDrawerIconWrapper = styled.div`
    position: absolute;
    width: 72px;
    height: 72px;
    left: -36px;
    top: ${props => props.isWithBanner ? '132' : '76'}px;
    cursor: pointer;
`

export const SimulationItemsList = styled.div`
    /* border-top:1px solid #DDE3EB; */
    /* border-bottom:2px solid #DDE3EB; */
    /* padding:24px 16px 24px 24px; */
    background: #FDFDFD;
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    overflow-y: auto;
`;

export const SimulationsContentWrapper = styled.div`
    /* background-color:${colors.lightGray1}; */
    /* box-shadow:${shadows.cardShadow}; */
    border-radius: 8px;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    text-align:left;
    /* max-height:calc(100% - 72px); */
    gap: 12px;
    max-height: 100%;
    @media (max-height: 979px) {
        height:100%;
    }
    
`;
export const SimulationsHeaderAndItemsListWrapper = styled.div`
    ${flexCenter};
    flex-direction: column;
    background: #FDFDFD;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.04); //${shadows.cardShadow};
    border-radius: 8px;
    width:100%;
    max-height: 100%;
    justify-content:flex-start;
    @media (max-height: 979px) {
        height:100%;
    }
    
    // overflow-y: scroll;
`;

export const SimulationHeaderWrapper = styled.div`
  ${flexCenter};
  width:100%;
  padding:16px 24px;
  border-radius: 8px 8px 0 0;    
  justify-content: space-between;
    ${props => props.hasSimulations ? `border-bottom: 1px solid ${colors.gray3}` : ``};
    ${views.tablet}{
        gap:8px;
    }
`;


export const SimulationHeaderTitleWrapper = styled.div`
    //flex:1
    display: flex;
    flex-direction: column;
    
`;

export const SimulationHeaderActionsWrapper = styled.div`
    ${flexCenter};
    justify-content: flex-end;
`;




export const SimulationsTitle = styled.div`
    color: ${colors.scenario};
    font-size:20px;
    font-weight:500;
    display: flex;
    align-items: center;
    > svg{
        margin-right:8px;
    }
    
`;
export const SimulationsSubTitle = styled.div`
    color:${colors.darkGray3};
    line-height: 17px;
    font-size:14px;
    /* border-bottom:2px solid #DDE3EB; */
    /* padding-bottom:16px; */
    /* margin-bottom:8px; */
`;

export const CreateSimulationsButton = styled(PrimaryButtonWithIcon)`
    > svg {
        path {
            stroke: #fff;
        }
    }
    min-width: 154px;
`;

export const EmptyScenariosWrapper = styled.div`
    ${flexCenter};
    border-top: 1px solid ${colors.gray3};
    padding: 16px 0;
    ${views.tablet}{
        padding:16px 24px;
    }
    svg {
        width:100%;
    }
`;