import { Input } from 'antd';
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import { CheckmarkIcon } from '../../../../../assets/icons/common/common-icons';
import { useStore } from '../../../../app/data/root.store';
import { FilterOptionsWrapper, ListParameterOption, OptionLogoWrapper, SearchBoxWrapper, SearchIconWrapper, TickerIconPlaceholder } from './StockParameterFilter.styles';
import { StockParameterFilterEmptyState } from './StockParameterFilterEmptyState';
import { ReactComponent as SearchResultsIcon } from './icons/search-icon.svg';

export const StocksParameterFilter = ({ param, paramIndex }) => {
    const { filtersStore } = useStore();

    const handleOptionClick = (optionIndex, isSelected) => {
        filtersStore.setListOptionSelected(paramIndex, optionIndex, !isSelected);
    }

    return (<Observer>{() => (
        <FilterOptionsWrapper>
            <SearchBoxWrapper>
                <Input placeholder={'Search'}
                    suffix={null}
                    value={filtersStore.stockStr}
                    onChange={(e) => filtersStore.setStockStr(e.target.value || '')}
                />
                <SearchIconWrapper hasSearchInput={filtersStore.stockStr !== ''} ><SearchResultsIcon /></SearchIconWrapper>
            </SearchBoxWrapper>
            {!filtersStore.stockSearchResults?.length && filtersStore.stockStr !== '' ?
                <StockParameterFilterEmptyState /> :
                filtersStore.stockSearchResults?.map((option, index) => (
                    <ListParameterOption key={index} onClick={() => { handleOptionClick(option.index, option.isSelected) }} isSelected={option.isSelected}>
                        <ItemLogo option={option} />
                        {option.name}
                        {option.isSelected ? <CheckmarkIcon /> : ''}
                    </ListParameterOption>
                ))}
        </FilterOptionsWrapper>
    )}</Observer>)
}

const ItemLogo = ({ option }) => {
    const [isCorruptedImage, setIsCorruptedImage] = useState(false);

    return (
        <>
            {option.logoBase64 ? <OptionLogoWrapper><img alt={option.connectedInstitutionName} src={'data:image/png;base64,' + option.logoBase64} /></OptionLogoWrapper> :
                option.logoURL && !isCorruptedImage ? <OptionLogoWrapper><img onError={() => {setIsCorruptedImage(true)}} alt={option.connectedInstitutionName} src={option.logoURL} /></OptionLogoWrapper> :
                    <TickerIconPlaceholder>{(option.name && option.name !== '') ? option.name.charAt(0).toUpperCase() : (option.symbol && option.symbol !== '') ? option.symbol.charAt(0).toUpperCase() : (option.type && option.type !== '') ? option.type.charAt(0).toUpperCase() : ''}</TickerIconPlaceholder>}
        </>
    )
}