import styled from "@emotion/styled/macro";
import { colors, shadows } from "../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../common/styles/combos.styles";

export const Wrapper = styled.div`
    position:fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left:0 ;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    background-color: #FFFFFF;
    z-index: 99999991;
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  flex: 1;
  position: relative;
  text-align: center;
  height: 100%;
`;

export const MobilePricingModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 12px;
  // flex-shrink: 0;
`;

export const HeaderLogoAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #121D2A;
  font-family: Domine;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;  
  padding: 2px 10px;
`;

export const LogoWrapper = styled.a`
  ${flexCenter};
`;

export const MobileMenuWrapper = styled.div`
  ${flexCenter};
  cursor: pointer;
  margin-right: 24px;
`;

export const MobileMenuHoverWrapper = styled.div`
  display: flex;
  width: 266px;
  padding: 4px 0;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25), 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
`;

export const MobileMenuHoverRow = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
  padding: 12px 16px;
  margin: 0 -8px;

  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;

  :not(:last-of-type) {
    border-bottom: 1px solid ${colors.gray3};
  }
  cursor: pointer;
`;

export const LandingPageSvgWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ModalRightPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow-y: auto;
  padding: 10px 0;
  ${props=>props.isFooterSticky ? `` : `
  justify-content: center;
  `}
  // padding-top: ${props => props.isHeaderSticky ? '96px' : '0'};
  // padding-bottom: ${props => props.isFooterSticky ? '116px' : '0'};
`;

export const PlanSwitchWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: center;
  border-bottom: 1px solid ${colors.lightGray2};
  font-weight: 450;
  font-size: 15px;
  line-height: 19px;
  width: 364px;
`;

export const TooltipBoldText = styled.div`
  font-weight:600;
  display:inline-flex;
`;

export const PlanSwitchOption = styled.div`
  position: relative;
  font-size:14px;
  font-weight:500;
  cursor: pointer;
  padding: 10px 18px;
  display: flex;
  gap: 4px;
  ${props => props.isSelected ? `
    color: ${colors.darkGray1};
    ::after{
      content:' ';
      position: absolute;
      left:0;
      bottom:0;
      height:1px;
      width:100%;
      background-color: #000;
    }
    ` : `
    color: ${colors.darkGray2};
  `}
`;

export const SavePercentage = styled.div`
  display: flex;
  padding: 1.3px 5.5px 0.7px 6.5px;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 0px;
  background: ${props => props.isSelected ? colors.darkGray1 : colors.gray2};
  color: #fff;
  font-family: Circular;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 16px;
`;

export const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-inline: 20px;
`;

export const PlanRowInvisibleBorder = styled.div`
  cursor:  ${props => props.disableRow ? '' : 'pointer'};
  border-radius: 8px;
  border: 1px solid transparent;
  position: relative;
  ${props => props.isSelected ? `
  ::after {
    content: '';
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    border: 2px solid #2565E7;
    border-radius: 8px;
  }
  ` : ``};
`;

export const RecommendedPlanBanner = styled.div`
  padding: 6px 16px;
  font-weight: 450;
  font-size: 12px;
  line-height: 110.5%;
  
  background-color: ${props => props.isSelected ? colors.blueAzul : 'rgba(37, 101, 231, 0.20)'};
  color: ${props => props.isSelected ? '#fff' : colors.darkGray1};
  
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const PlanRowWrapper = styled.div`
  position: relative;
  display: flex;
  width:  100%;
  border-radius: ${props => props.isRecommended ? `0 0 8px 8px` : `8px`};
  padding: 18px 16px;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray2}; 
  background-color: ${props => props.isSelected ? `rgba(239, 244, 255, 0.30)` : `#F6F8FB`};
  ${props => props.displayRowInGray ? `border: 1px solid var(--Gray-3, #DDE3EB);opacity: 0.4;` : ``};
  ${props => props.isFirst ? `max-height:80px` : ``}
`;

export const PlanDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
`;

export const PlanSelectionCircle = styled.div`
  display: grid;
  place-items: center;
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid ${props => props.isSelected ? colors.blueAzul : '#DDE3EB'};
  box-shadow: ${shadows.toggleShadow};
`;

export const PlanSelectionCircleFill = styled.div`
  width: 8px;
  height: 8px;
  background-color: #2565E7;
  border-radius: 50%;
`;

export const PlanTitleWrapper = styled.div`
  font-family: 'Domine';
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray1};
`;

export const PlanPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray2};
`;

export const PlanPriceValue = styled.div`
  font-family: 'Domine';
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray1};
`;

export const PlanDiscountValue = styled.div`
  font-family: 'Domine';
  font-weight: 450;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-decoration: line-through;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray1};
`;

export const ModalRightPaneFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 10px;
  margin-top: auto;
  padding: 8px 20px 18px 20px;
  ${props => props.isSticky ? `
    background-color: #fff;
    border-top: 1px solid ${colors.gray3};
    z-index: 5;
` : null}
`;

export const ComparePlansButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  height: 48px;
  cursor:pointer;
  border-radius: 30px;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  background: #fff;
  color: #2565E7;
`;

export const ContinueButton = styled(ComparePlansButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 28px 0;
  background-color: #2565E7;
  color: #fff;
`;

export const DiscountLabelWrapper = styled.div`
  position:relative;
`;

export const DiscountLabelText = styled.div`
  position: absolute;
  height: 18px;
  background-color: #f0ba7a;
  top: 2px;
  right: 0px;
  padding: 0px 7px 0 9px;
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  border-radius: 6px 0;
  white-space: nowrap;
`;
