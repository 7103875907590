import { Select } from 'antd';
import React, { useState } from 'react';
import { CloseSymmetricIcon } from '../../../assets/icons/common/common-icons';
import { HeaderSearchBoxEmptyState } from '../../../modules/layout/components/HeaderSearchBox/HeaderSearchBoxEmptyState';
import { HeaderSearchBoxResultRow } from '../../../modules/layout/components/HeaderSearchBox/HeaderSearchBoxResultRow';
import { ReactComponent as SearchResultsIcon } from '../../../modules/layout/components/HeaderSearchBox/icons/search-icon.svg';
import { BaseModal } from '../BaseModal/BaseModal';
import { PrimaryButton } from '../buttons/NewButtons.styles';
import { InputWrapper } from '../form-displayer/FormDisplayer.styles';
import { CloseBtnWrapper, HeaderTitle, HeaderWrapper, ModalFooterWrapper, ModalWrapper, SearchBoxWrapper, SearchIconWrapper } from './ConnectAssetModal.styles';
import { useStore } from '../../../modules/app/data/root.store';

export function ConnectAssetModal({ onClose, onSelectAsset, connectAssetSearchItems }) {
    const { Option } = Select;
    const { uiStore } = useStore()
    const [inputVal, setInputVal] = useState('');
    const [searchVal, setSearchVal] = useState('');

    return (
        <BaseModal onBackDropClick={onClose} width={'538px'} height={'auto'} borderRadius={4} >
            <HeaderWrapper>
                <HeaderTitle>
                    Link liability to asset
                </HeaderTitle>
                <CloseBtnWrapper onClick={onClose}>
                    <CloseSymmetricIcon />
                </CloseBtnWrapper>
            </HeaderWrapper>
            <ModalWrapper>
                <InputWrapper>
                    <SearchBoxWrapper>
                        <Select
                            autoFocus
                            defaultOpen
                            allowClear
                            onClear={() => setInputVal('')}
                            showSearch
                            placeholder='Search asset to link'
                            optionFilterProp='label'
                            suffixIcon={null}
                            style={{ width: '100%', display: 'flex' }}
                            dropdownMatchSelectWidth={428}
                            onSearch={(val) => setSearchVal(val)}
                            onSelect={(selected, option) => { setInputVal(option.children.props.item) }}
                            notFoundContent={<HeaderSearchBoxEmptyState />}
                            dropdownClassName={`${!uiStore.isDesktopView ? 'connectLiabilitySearchBoxDropdown_mobile ': ''}headerSearchBoxDropdown`}
                            optionLabelProp="label"

                        >
                            {connectAssetSearchItems.reverse().map((item, index) => (
                                <Option key={index} value={item.id} label={item.title}>
                                    <HeaderSearchBoxResultRow item={item} inputValue={searchVal} isLightTheme />
                                </Option>
                            ))}
                        </Select>
                        <SearchIconWrapper hasSearchInput={inputVal !== '' || searchVal !== ''} ><SearchResultsIcon /></SearchIconWrapper>
                    </SearchBoxWrapper>
                </InputWrapper>
            </ModalWrapper>
            <ModalFooterWrapper>
                <PrimaryButton disabled={!inputVal} onClick={() => inputVal && onSelectAsset(inputVal.id)} >
                    Link asset to loan
                </PrimaryButton>
            </ModalFooterWrapper>
        </BaseModal>
    )

}






