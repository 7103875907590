import React from 'react'
import { AssetClassesParameterFilter } from './AssetClassesParameterFilter/AssetClassesParameterFilter';
import { ListParameterFilter } from './ListParameterFilter/ListParameterFilter';
import { RangeParameterFilter } from './RangeParameterFilter/RangeParameterFilter';
import { SponsorParameterFilter } from './SponsorParameterFilter/SponsorParameterFilter';
import { StartEndDateParameterFilter } from './StartEndDateParameterFilter/StartEndDateParameterFilter';
import { HashtagIcon } from '../../../../assets/icons/common/common-icons';
import { EmptyParamWrapper, HashtagIconWrapper, EmptyParamTitle, EmptyParamText } from '../FiltersPanel.styles';
import { StocksParameterFilter } from './StockParameterFilter/StockParameterFilter';
import { LeveragedAssetsParameterFilter } from './LeveragedAssetsParameterFilter/LeveragedAssetsParameterFilter';
import { AssetCustomClassesParameterFilter } from './AssetClassesParameterFilter/AssetCustomClassesParameterFilter';

export const ParamTypeSwitcher = (props) => {
  const { type, title, isHidden } = props.param;
  return (
    isHidden ? <EmptyParam parameterTitle={title} /> :
      <>
        {type === 'list' && <ListParameterFilter {...props} />}
        {type === 'range' && <RangeParameterFilter {...props} />}
        {type === 'classes' && <AssetClassesParameterFilter {...props} />}
        {type === 'customClasses' && <AssetCustomClassesParameterFilter {...props} />}
        {type === 'sponsor' && <SponsorParameterFilter {...props} />}
        {type === 'stocks' && <StocksParameterFilter {...props} />}
        {type === 'startEndDate' && <StartEndDateParameterFilter {...props} />}
        {type === 'leveragedAssets' && <LeveragedAssetsParameterFilter {...props} />}
      </>
  )
}


export function EmptyParam({ parameterTitle }) {
  return (
    <EmptyParamWrapper>
      <HashtagIconWrapper><HashtagIcon /></HashtagIconWrapper>
      <EmptyParamTitle>Filter is not available</EmptyParamTitle>
      <EmptyParamText>You don't have any asset / liability with '{parameterTitle}' yet.</EmptyParamText>
    </EmptyParamWrapper>
  )
}
