import { observer } from "mobx-react";
import React, { useState } from "react";
import { PopupModal, useCalendlyEventListener } from "react-calendly";

export const CalendlyPopupWidget = observer(({ onScheduleSuccess, triggerComponent, triggerWrapperStyles, onClose }) => {
  const [show, setShow] = useState(false);
  const calendlyUrl = process.env.REACT_APP_CALENDLY_URL;

  useCalendlyEventListener({
    // onProfilePageViewed: () => console.log("onProfilePageViewed"),
    // onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    // onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => { onScheduleSuccess?.() } //console.log(e.data.payload),
  });

  return (
    <>
      <div onClick={() => { setShow(true) }} style={{ cursor: 'pointer', ...(triggerWrapperStyles ? triggerWrapperStyles : {}) }}>
        {triggerComponent}
      </div>
      <PopupModal
        url={calendlyUrl}
        rootElement={document.getElementById("root")}
        onModalClose={() => { setShow(false); onClose?.() }}
        open={show}
      />
    </>)
})