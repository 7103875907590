import styled from '@emotion/styled/macro';

export const ModalButton = styled.button`
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 16px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.32), 0px 2px 8px rgba(0, 0, 0, 0.08);
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    background-color: ${props => props.disabled ? 'grey' : 'black'};
`;

export const ModalCancelButton = styled(ModalButton)`
    margin-right: 10px;
    background-color: white;
    color: black;
`;

export const ModalHeader = styled.div`
    display: flex;
    width: 100%;
    padding: 16px;
    color: #000000d9;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
`;

export const ModalTitle = styled.div`
    margin: 0;
    color: #000000d9;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
    overflow-wrap: anywhere;
`;

export const ModalContent = styled.div`
    padding: 16px;
    background-color: #F7F8F9;
`;

export const ModalEventPrompt = styled.div`
    padding: 16px;
    display: grid;
    place-items: center;
`;

export const ModalFooter = styled.div`
    padding: 16px 24px;
    color: #000000d9;
    background: #fff;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    text-align: right;
`;

export const ModalClose = styled.div`
    display: grid;
    margin-left: auto;
    order: 2;
    place-items: center;
    width: 32px;
    cursor: pointer;
`;

export const ModalEventPreview = styled.div`
    position: relative;
    display: grid;
    place-items: center;
`;