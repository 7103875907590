import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../../../common/styles/combos.styles';
import { colors, shadows, sizes } from '../../../../../../common/styles/theme.styles';
import { animateSpin } from '../../../../../../common/styles/animations.styles';

export const HeaderWrapper = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content: space-between;
    padding-top: 14px;
    margin-bottom: 24px;
    .ant-picker{
        width: 128px;
        padding:5px 6px 6px 8px;
        background-color: #000;
        border: 1px solid #000;
        border-radius: 4px;
    }
    .ant-picker:hover, .ant-picker-focused {
        border: 1px solid #000;
        box-shadow: none;
    }
    .ant-picker-input > input {
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }
`;

export const TotalTransactionsWrapper = styled.div`
    display: flex;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.darkGray1};
    gap: 8px;
    padding: 4px 12px;
`;

export const TotalInflowValue = styled.div`
    color: #6EBA7F;
`;

export const TotalOutflowValue = styled.div`
    color: ${colors.darkGray1};
`;

export const CenterContent = styled.div`
    width: 100%;
    // max-width: 816px;
    max-width: ${sizes.wealthContentWidth + 32}px;
    margin: 0 auto;
    min-height:100%;
`;

export const TableRowSizes = styled.div`
    display: grid;
    grid-template-columns: 13fr 18fr minmax(0px, 30fr) minmax(0px, 24fr);
    gap:8px;
`;

export const TableHeaderRow = styled(TableRowSizes)`
    border-bottom: 1px solid ${colors.lightGray2};
`;

export const TableContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HeaderCell = styled.div`
    font-size:14px;
    color:#9D9D9D;
    ${props=>props.isValueCell ? `
        text-align:right;
        padding-right:40px;
    ` : ``}
`;

export const RowWrapper = styled(TableRowSizes)`
    padding: 12px 0px;
    &:not(:last-child){
        border-bottom: 1px solid ${colors.lightGray2};
    }
    // ${props=>props.isOpen ? `
    // background-color: ${colors.lightGray1};
    // ` : ``};
`;

export const RemarksCellWrapper = styled.div`
    max-width: 100%;
    padding-right:24px;
    position:relative;
`;

export const CollapsibleWrapper = styled.div`
    position: relative;
`;

export const CollapsibleTitle = styled.div`
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray1};
`;

export const CollapsibleArrowWrapper = styled.div`
    position: absolute;
    top: -2px;
    right: -16px;
    transition: all 0.2s;
    ${props => props.isOpen ? `transform:rotate(180deg)` : ``};    
`;

export const CollapsingContentWrapper = styled.div`
  ${props => !props.isOpen && `display:none`};
  word-break: break-word;
`;


export const ArrowDownWrapper = styled.div`
    ${flexCenter};
    width: 12px;
    height: 12px;
    background: ${colors.lightGray2};
    border-radius: 24px;
    margin: 0px 4px;
    position:absolute;
    right:0px;
    top:6px;
`;

export const RowCell = styled.div`
    
    font-size: 14px;
    line-height:24px;
    
`;

export const ValueCellWrapper = styled(RowCell)`
    font-weight: 500;
    color: ${props => props.isPositive ? '#6EBA7F' : colors.darkGray1};
    width: 100%;
    text-align: right;
    padding-right: 40px;
`;

export const LinkedItemColumnHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const TransactionsTabEmptyStateWrapper = styled.div`
    ${flexCenter}
    flex-direction: column;
    min-height: 240px;
    gap: 16px;
`;

export const TransactionsTabEmptyStateMessage = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color:${colors.gray2};
    text-align:center;
`;

export const ArrowDownSmallTriangleIconWrapper = styled.div`
    ${flexCenter}
    svg {
        path {
            fill: #fff;
        }
    }
`;

export const TransactionsTabManualAccountStateWrapper = styled.div`
    ${flexCenter};
    flex-direction: column;
`;

export const SyncIconWrapper = styled.div`
    svg{
        width:24px;
        height:24px;
        // path{
        //     fill:${colors.darkGray1};
        // }
    }
    animation-name: ${animateSpin};
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    ${flexCenter};
    margin: 8px 0px;
`;

export const TransactionsTabManualAccountStateTextWrapper = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    margin-bottom: 56px;
    color: ${colors.darkGray1};
`;

export const SyncingTransactionsHistoryTextWrapper = styled.div`
    font-weight: 450;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: ${props=>props.isBolded ? colors.darkGray1 : colors.darkGray3};
`;

export const WizardButton = styled.div`
    // border : 1px solid #222222;
    color: ${props=> props.black ? '#fff' : '#000'};
    background-color: ${props=> props.black ? '#222222' : '#fff'};
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 8px 16px;
    text-align:center;
    cursor: pointer;
    // &:not(:last-child){
        // margin: ${props => props.margin || '0px 0px 8px 0px'};
        margin: ${props => props.margin || '0px'};
    //}
    box-shadow:${shadows.toggleShadow};
    // min-width: 144px;
    transition:all 0.2s;
    border-radius:4px;
    display: flex;
    align-items: center;
    gap: 4px;
    &:hover{
        background-color: ${props=> props.black ? '#000' : '#f1f4f8'};
        // border : 1px solid ${props=> props.black ? '#000' : '#222222'};
    }
    /* display: ${props=> props.inline ? 'inline-block' : 'block'}; */
`;