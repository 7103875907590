import { Observer } from 'mobx-react';
import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { useStore } from '../../../app/data/root.store';
import { LogoAndBadgeWrapper, LogoAreaWrapper } from '../Layout.styles';
import { TierBadge } from '../TierBadge/TierBadge';
// import { ls } from '../../../common/utils/localstorage.util';
// import { removeUrlParams } from '../../../common/utils/navigation.utils';

export const DesktopLogo = ({keepUrl}) => {
  const { userStore, uiStore, billingStore } = useStore();
   
  const handleBadgeClick = () => {
    if (!userStore.isTier4){
      billingStore.setShowPricingTableModal(true);
    }   
  }

  return (<Observer>{() => (
      <LogoAreaWrapper> <LogoAndBadgeWrapper>
          <DesktopSVGLogo keepUrl={keepUrl}/>
            {  ( (!uiStore.isRouteWithEmptySideBarLayout ) && (!uiStore.isSideMenuMinimized || userStore.isWelcome)) && !userStore.isEmptySharedSideMenu && <TierBadge tier={userStore.getTierType} showToolTip onClick={handleBadgeClick}  isClickable={!userStore.isTier4}/>}
            </LogoAndBadgeWrapper>  </LogoAreaWrapper> 
              // {uiStore.isShowMinimizedButton &&
              //  <MinimizeButtonWrapper onClick={() => { uiStore.setIsSideMenuMinimized(!uiStore.isSideMenuMinimized) }} isOpen={!uiStore.isSideMenuMinimized}>
              //    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              //      <path d="M0.294958 4.74219H2.57864L5.81765 12.1991L9.05666 4.74219H11.3404L6.58645 15.6946H5.0484L0.294495 4.74219H0.294958Z" fill="white" />
              //    </svg>
              //  </MinimizeButtonWrapper>} 
  )}</Observer>);
}


export const DesktopSVGLogo = ({keepUrl}) => {
  const { userStore, uiStore, filtersStore, wealthStore } = useStore();
  
  const history = useHistory();  

  const handleLogoClick = (e) => {
    if (!keepUrl &&  filtersStore.countActiveFilters) {
      filtersStore.handleClearFilterSelections();
      wealthStore.refreshWealthData(false, history);
    }
  }
  
  return (<Observer>{() => (
            <Link to={ keepUrl ? keepUrl : "/wealth/overview"} onClick={handleLogoClick}>
              { userStore.isEmptySharedSideMenu || (uiStore.isSideMenuMinimized && !userStore.isWelcome ) ? 
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.9898 4.92L13.8219 18.798L11.997 22.0314L2.34 4.92H5.9898ZM9.6396 4.92L11.4465 8.1219H16.197L13.8219 12.3306L15.6468 15.564V15.5646L21.6543 4.92H9.6396Z" fill="white"/>
</svg> :
              <svg width='53' height="20" viewBox={`0 0 53 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.294958 4.74219H2.57864L5.81765 12.1991L9.05666 4.74219H11.3404L6.58645 15.6946H5.0484L0.294495 4.74219H0.294958Z" fill="white" />
                <path d="M29.5104 6.72318H23.835V4.74219H33.4432L27.3628 13.7136H33.0396V15.6941H23.449L29.5109 6.72271L29.5104 6.72318Z" fill="white" />
                  <path d="M52.0103 4.74219H47.6813H45.7943H45.4675V15.6946H47.6813V6.72318H51.1485L52.0103 4.74219Z" fill="white" />
                  <path d="M14.3142 19.9926H16.6622L23.2994 4.74219H20.969L17.8235 11.9663L14.3975 4.74219H12.0208L16.7048 14.5527L14.3142 19.9926Z" fill="white" />
                  <path d="M41.9244 12.0494L41.8948 12.1132C41.3366 13.3097 40.3164 13.942 38.945 13.942C36.99 13.942 35.7722 12.4146 35.5283 10.8432H44.3108L44.3118 10.6645C44.3215 8.85526 43.7054 7.2339 42.5765 6.099C41.5661 5.08305 40.2405 4.52393 38.8432 4.52393C35.8389 4.52393 33.395 7.07746 33.395 10.216C33.395 13.3546 35.8328 15.8679 38.945 15.8679C41.1389 15.8679 42.8441 14.8477 43.7369 13.038L41.9244 12.0498V12.0494ZM38.8437 6.44937C40.2595 6.44937 41.5222 7.46162 41.9832 8.93811H35.6644C36.074 7.66713 37.2524 6.44937 38.8437 6.44937Z" fill="white" />
                
              </svg>}
            </Link>
  )}</Observer>);
}
