
import React from 'react';
import {  Select } from "antd";
import { Observer} from 'mobx-react';
import { useStore } from '../../../../modules/app/data/root.store';
import { ArrowDownIcon } from '../../../../assets/icons/common/common-icons';

export function CashAccountSelect(props) {
    const { inputKey, handleChange, disabled, value, scrollContainerId } = props;

    const {metadataStore} = useStore();
    const { Option } = Select;
    return (<Observer>{()=>(
        <Select value={value} disabled={disabled} onChange={(val)=>{handleChange(inputKey,val)}}
        getPopupContainer={() => scrollContainerId ? ( document.getElementById(scrollContainerId) || document.body) : document.body} 
        suffixIcon={<ArrowDownIcon/>} >
        <Option value={null} >None</Option>
            {metadataStore.cashAccounts.map(ca=>(
                <Option key={ca.id} value={ca.id} >{ca.title}</Option>
            ))}
        </Select>   
    )}</Observer>)
}