import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { PasswordDisplayer } from '../../../../common/components/PasswordDisplayer/PasswordDisplayer';
import { postData } from '../../../../common/utils/network.utils';
import { useStore } from '../../../app/data/root.store';
import { errorNotification, successNotification } from '../../../layout/components/Notifications/notifications';
import { AUTH_STEPS } from '../../../user/data/user.store';
import { AntPopoverButton, BackButtonWrapper, ForgotPasswordStepWrapper, InputLabel, InputWrapper, LinkSpan, LoginForm, LoginFormButton, PopoverWrapper, RestSuccessfulSvgWrapper, SetPasswordStepHeader, SignUpStepSubHeader, VerCodeInput, WrongCodeMsg } from '../LoginPage.styles';
import { useRecaptchaHook } from '../useRecaptchaHook';
import { isValidPassword } from '../../../../common/utils/string.utils';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';

export const ResetPasswordStep = observer(() => {
  const { userStore } = useStore();
  // eslint-disable-next-line
  const [message, setMessage] = useState('');
  const [showWrongCodeMsg, setWrongCodeMsg] = useState(false);
  const verificationInputRef = useRef();
  const passwordInputRef = useRef();
  const { trackEvent } = useAnalytics()
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);
  const [createRecapcthaToken, onRecaptchaReset, MemoizedRecaptchaComponent] = useRecaptchaHook();
  const { email, userHasPassword } = userStore.authFields;

  const handlePasswordInputChange = (value) => {
    userStore.setAuthFields('password', value);
  }

  const handleResetPasswordClick = async () => {
    trackEvent('CTA Clicked', {
      cta_text: `${userHasPassword ? 'Reset' : 'Set'} password`,
      location: "Reset password",
      destination: 'Password reset success page',
      type: "Button",
      url: window.location.href
    })
    const isPasswordValid = isValidPassword(passwordInputRef.current.state.value);
    if (!isPasswordValid) {
      return errorNotification('Password must be at least 8 characters and contain 1 number, 1 lowercase letter, 1 uppercase letter, and 1 special character');      
    }
    const token = await createRecapcthaToken();
    const formData = {
      email: email,
      code: verificationInputRef.current.value,
      password: passwordInputRef.current.state.value,
      recaptchaToken: token
    }
    if (formData.email && formData.password && formData.code) {
      postData("user/reset-password", formData)
        .then(data => {
          if (!data.success) {
            setWrongCodeMsg(true);
          } else {
            setIsResetSuccessful(true);
          }
        }, error => {
          if (error.message) {
            setMessage("Something went wrong");
          }
        }).finally(() => {
          onRecaptchaReset && onRecaptchaReset();
        });
    } else {
      setMessage("Please fill all inputs");
    }
  }

  const handleDoneClick = () => {
    trackEvent('CTA Clicked', {
      cta_text: `Done`,
      location: "Password reset success page",
      destination: 'Welcome page',
      type: "Button",
      url: window.location.href
    })
    window.location.reload();
  }

  const handleSendCodeClick = async () => {
    const recaptchaToken = await createRecapcthaToken();
    postData("user/forgot-password", { email, recaptchaToken })
      .then(data => {
        if (data.success) {
          successNotification('Email sent');
        }
      }, error => {
        if (error.message) {
          setMessage("Something went wrong");
        }
      }).finally(() => {
        onRecaptchaReset && onRecaptchaReset();
      });
  }

  return (
    <>
      <BackButtonWrapper onClick={() => { userStore.setAuthStep(AUTH_STEPS.LOG_IN_STEP) }}><BackArrowSvg /> Back</BackButtonWrapper>
      <ForgotPasswordStepWrapper isResetSuccessful={isResetSuccessful}>
        {isResetSuccessful ? <RestSuccessfulSvgWrapper><RestSuccessfulSvg /></RestSuccessfulSvgWrapper> : null}
        <SetPasswordStepHeader isResetSuccessful={isResetSuccessful}>{!isResetSuccessful ? `${userHasPassword ? 'Reset' : 'Set'} Your Password` : userHasPassword ? 'Your password was\nupdated successfully' : 'You’ve successfully updated\nyour password'}</SetPasswordStepHeader>
        <SignUpStepSubHeader isResetSuccessful={isResetSuccessful}>{!isResetSuccessful ? `Please enter the verification code sent to ${email}` : `You can now log in to your account.`}</SignUpStepSubHeader>
        <LoginForm>
          {!isResetSuccessful ? <>
            <InputWrapper>
              <InputLabel>Verification code</InputLabel>
              <VerCodeInput style={{ height: '44px' }}
                autoFocus
                placeholder="••••••"
                onBlur={() => { setWrongCodeMsg(false) }}
                id="codeInput" type="text" name="code" ref={verificationInputRef} autoComplete="off" />
            </InputWrapper>
            {showWrongCodeMsg &&
              <WrongCodeMsg>This verification code is expired or incorrect.</WrongCodeMsg>
            }
            <InputWrapper style={{ marginBottom: '40px' }}>
              <InputLabel>Create new password</InputLabel>
              <PasswordDisplayer showHints ref={passwordInputRef} onChange={handlePasswordInputChange} placeholder='Create a secure password' />
            </InputWrapper>
          </> : null}
          {!isResetSuccessful ? <LoginFormButton onClick={handleResetPasswordClick}>{`${userHasPassword ? 'Reset' : 'Set'} password`}</LoginFormButton> :
            <LoginFormButton onClick={handleDoneClick}>Done</LoginFormButton>}
        </LoginForm>
        {!isResetSuccessful ?
          <PopoverWrapper>
            <AntPopoverButton style={{ cursor: 'default' }}>Didn’t receive a code? <LinkSpan onClick={handleSendCodeClick}>Resend email</LinkSpan></AntPopoverButton>
          </PopoverWrapper> : null}
        <MemoizedRecaptchaComponent />
      </ForgotPasswordStepWrapper>
    </>
  )
})

const BackArrowSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6803 16.0137L10.347 12L13.6803 7.98633" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}

const RestSuccessfulSvg = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5103 11.604L13.5059 18.6125L9.99983 15.1075" stroke="black" strokeWidth="2" />
      <rect x="0.75" y="0.75" width="28.5" height="28.5" rx="14.25" stroke="#1A1B1D" strokeWidth="1.5" />
    </svg>
  )
}