import { Observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../../app/data/root.store';
// import { ClassPageEmptyState } from '../../../class/components/ClassPageEmptyState';
import { CommunityOverviewClassesListView } from './CommunityClassesListView';
import { CommunityInstitutionsListView } from './CommunityItemsListView';

export const CommunityItemsListViewSwitcher = (props) => {
  const { communityStore } = useStore();

  return (<Observer>{() => (
    <>
          {communityStore.isOverviewView && communityStore.selectedTab !== 'institutions' ?
            <CommunityOverviewClassesListView  />
            :
            <CommunityInstitutionsListView ab={props.ab} />}
    </>
  )}</Observer>)
}

