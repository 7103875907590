const ADD_ITEM_TYPE = {
    ASSET : 'asset',
    LIABILITY : 'liability'
}

export const plaidTypeToVyzerTypeMapping = {
    'depository' : ADD_ITEM_TYPE.ASSET,
    'investment' : ADD_ITEM_TYPE.ASSET,
    'credit' : ADD_ITEM_TYPE.LIABILITY,
    'loan' : ADD_ITEM_TYPE.LIABILITY,
    'other' : ADD_ITEM_TYPE.ASSET,
    'mortgage' : ADD_ITEM_TYPE.LIABILITY
}
    
export const plaidTypeToVyzerClassMapping = {
    'depository' : 1,
    'credit' : 11,
    'loan' : 10,
    'mortgage' : 10,
}

export const plaidTypeAndSubTypeToVyzerClassMapping = {
    'investment_brokerage' : 3,
    'investment_stock plan' : 3,
    'investment_ira' : 2,
    'investment_401k' : 3,
    'credit_credit card' : 11,
    'loan_student' : 10,
    'loan_mortgage' : 10,
}

export const plaidSubTypeToVyzerCategoryMapping = {
    'checking' : 1,
    'savings' : 2,
    'cd': 4,
    'money market': 3,
    'stock plan': 13,
    'mortgage': 37,
}

export const saltedgeCheckingAccountsNatures = ['account','checking'];

export const saltedgeTypeToVyzerTypeMapping = {
    'card' : ADD_ITEM_TYPE.LIABILITY,
    'debit_card' : ADD_ITEM_TYPE.LIABILITY,
    'credit_card' : ADD_ITEM_TYPE.LIABILITY,
    'credit' : ADD_ITEM_TYPE.LIABILITY,
    'loan' : ADD_ITEM_TYPE.LIABILITY,
    'mortgage' : ADD_ITEM_TYPE.LIABILITY
}
    
export const saltedgeTypeToVyzerClassMapping = {
    'account' : 1,
    'checking' : 1,
    'savings' : 1,
    'credit' : 11,
    'card' : 11,
    'credit_card' : 11,
    'debit_card' : 11,
    'loan' : 10,
    'mortgage' : 10,
    'investment' : 3
}



export const akoyaTypeToVyzerTypeMapping = {
    'CHECKING' : ADD_ITEM_TYPE.ASSET,
    'SAVINGS' : ADD_ITEM_TYPE.ASSET,
    '401(k) PLAN' :ADD_ITEM_TYPE.ASSET,
    'COMMERCIALLOAN' : ADD_ITEM_TYPE.LIABILITY,
    'Fidelity Credit Card' : ADD_ITEM_TYPE.LIABILITY,
}

export const akoyaTypeToVyzerClassMapping = {
    'CHECKING' : 1,
    'SAVINGS' : 1,
    'CD': 1,
    'CHARGE': 1, 
    'MONEYMARKET' :1, 
    '401(k) PLAN' : 3,
    'AUTOLOAN' : 10,
    'COMMERCIALLOAN' : 10,
    'HOMEEQUITYLOAN' : 10,
    'INSTALLMENT' : 10,
    'LOAN' : 10,
    'MILITARYLOAN' : 10,
    'MORTGAGE' : 10,
    'PERSONALLOAN' : 10,
    'SMBLOAN' : 10,
    'STUDENTLOAN': 10,
    'insuranceAccount': 2,
    "College Savings": 2,
    "BROKERAGE": 3,
    "ROTH": 3,
    "IRA": 3,
    "401K": 3,
    "HSA": 3,
    "I": 3,
    "CREDITCARD": 11,
    "Fidelity Credit Card": 11,
}

export const akoyaSubTypeToVyzerCategoryMapping = {
    'insuranceAccount': 11,
    "College Savings": 10,
    "BROKERAGE": 13,
    "ROTH": 13,
    "IRA": 13,
    "401K": 13,
    "HSA": 13,
    "I": 13,
    "CREDITCARD": 34,
    "Fidelity Credit Card": 34,
}