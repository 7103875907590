import { deleteData, getData, postData, putData } from '../../../common/utils/network.utils'

// export const fetchAssetEvents = async () => {
//     return (await getData('events')).data;
// }

// export const fetchLiabilityEvents = async () => {
//     return (await getData('events')).data;
// }

export const createEvent = async (itemId, eventData) => {
    return (await postData(`events/asset/${itemId}`, eventData));
}

export const updateEvent = async (itemId, eventData) => {
    return (await putData(`events/asset/${itemId}`, eventData));
}

export const deleteEvent = async (itemEventId) => {
    return (await deleteData(`events/${itemEventId}`));
}

export const getTransactionsForEvent = async () => {
    return (await getData('transactions/for-event')).data;
}

export const searchTransactions = async (str, callingNext,date, amount,currency) => {
    if(callingNext) {
        const regex = /transactions(.*)/;
        const match = callingNext.match(regex);
        const nextLinkParams = '&' + match[1].slice(1);
        return (await getData('transactions/search-for-event?sch='+str+'&date='+date+ '&amount=' + amount + '&currency=' + currency +nextLinkParams)).data;
    }
    return (await getData('transactions/search-for-event?sch='+str+'&date='+date+ '&amount=' + amount + '&currency=' + currency)).data;
}
