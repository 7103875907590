import React from 'react';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../../common/utils/object.utils';
import { questionnaireIcons } from './assets/questionnaireIcons';
import { NetWorthBar, QuestionnaireContinueButton, QuestionnaireStepTitle, QuestionnaireTitleWrapper, QuestionnaireTopSubTitle } from './OnBoardingQuestionnaire.styles';
import { CountAnimation } from '../../../../common/components/count-animation/CountAnimation';
import { useStore } from '../../../app/data/root.store';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { observer } from 'mobx-react';

const {
  StrategyAndPlanningSvg,
  ToolsToManageWealthSvg,
  WalletSvg,
  WhereAndHowToInvestSvg,
  MagnifierSvg,
  ThreeDBox,
  VillageSvg,
  MarshmallowsSvg,
} = questionnaireIcons;


export const newQuestionnaireStepsDataAdvisor = {
  advisor_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    isPathStep: false,
    isInsightsStep: true,
    stepHeader: () => <AdvisorInsightHeader />,
    showContent: false,
    nextStep: 'advisor_type',
    nextButtonText: `Wow! Let's continue`,
  },
  advisor_type: ({ firstNameCapitalize }) => ({
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    title: `Great! Which of these best describes you, ${firstNameCapitalize}?`,
    answerType: 'singleChoiceWithOther',
    stepFooter: () => <AdvisorTypeFooter />,
    answerOptions: [
      { label: 'I run a single family office', value: 'single_family_office', goToStep: 'advisor_net_worth_step'},
      { label: 'I run a multi-family office\n/RIA or similar', value: 'multi_family_office', goToStep: 'advisor_net_worth_step'  },
      { label: `I'm a financial assistant`, value: 'financial_assistant', goToStep: 'advisor_net_worth_step'},
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' },
    isValidInput: (data) => { return !isNullOrUndefinedOrEmptyString(data?.value) },
    nextStep: 'advisor_net_worth_step',
    withRandomize: true,
  }),
  advisor_net_worth_step: ({isMobileView}) => ({
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: 'To tailor our service,',
    title: `what's the average size of portfolios you manage?`,
    answerType: 'goToStep',
    answerOptions: [
      { label: 'Less than $10M', icon: <NetWorthBar />, value: 'less_than_10m', goToStep: 'advisor_investment_step' },
      { label: '$10M  - $50M', icon: <NetWorthBar height={56} />, value: '10m_to_50m', goToStep: 'advisor_investment_step' },
      { label: '$50M - $100M', icon: <NetWorthBar height={80} />, value: '50m_to_100m', goToStep: 'advisor_investment_step' },
      { label: 'Over $100M', icon: <NetWorthBar height={104} />, value: 'over_100m', goToStep: 'advisor_investment_step' },
    ],
    answerWrapperCustomStyles: { maxWidth: 'none', },
    answerCustomStyles: { 
      ...(isMobileView ?
        { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap:16, maxHeight: 72, paddingLeft: 12 }
        :
        { display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', gap:8, padding:'32px 0 24px 0', maxHeight: 'none', minHeight: 190, minWidth:'156px', maxWidth:'156px' }
      )
    },
    isValidInput: (data) => { return !isNullOrUndefinedOrEmptyString(data?.value) },
    showFooter: false,
  }),
  advisor_investment_step: ({ firstNameCapitalize }) => ({
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: `Thanks ${firstNameCapitalize}.`,
    title: 'What types of assets do you typically manage?',
    subTitle: 'Select all that apply:',
    answerType: 'multiChoice',
    answerOptions: [
      { label: 'Syndications', value: 'syndications' },
      { label: 'Real estate', value: 'real_estate' },
      { label: 'Private equity', value: 'private_equity' },
      { label: 'Public markets', value: 'public_markets' },
      { label: 'Startups', value: 'startups' },
      { label: 'Crypto', value: 'crypto' },
      { label: 'Hedge funds', value: 'hedge_funds' },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    nextStep: 'advisor_looking_for_step',
    withRandomize: true,
  }),
  advisor_looking_for_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    title: 'Great! What are you looking for?',
    answerType: 'goToStep',
    answerOptions: [
      { label: 'Tools to manage wealth', icon: <ToolsToManageWealthSvg />, value: 'tools_to_manage_wealth_step', goToStep: 'advisor_tools_to_manage_wealth_step' },
      { label: 'Where and how to invest', icon: <WhereAndHowToInvestSvg />, value: 'where_and_how_to_invest_step', goToStep: 'advisor_where_and_how_to_invest_step' },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none' },
    showFooter: false, 
    withRandomize: true,   
  },
  advisor_tools_to_manage_wealth_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    isPathStep: false,
    isInsightsStep: true,
    stepHeader: () => <AdvisorToolsInsightHeader />,
    showContent: false,
    nextButtonText: 'Onward',
    nextStep: 'advisor_type_of_tool_step'
  },
  advisor_where_and_how_to_invest_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    isPathStep: false,
    isInsightsStep: true,
    stepHeader: () => <AdvisorWhereAndHowInsightHeader />,
    showContent: false,
    nextButtonText: 'Wow!',
    nextStep: 'advisor_where_and_how_to_invest_type_of_tool_step'
  },
  advisor_type_of_tool_step: ({ firstNameCapitalize }) => ({
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    title: `Ok, ${firstNameCapitalize}, so what type of tool do you need most?`,
    subTitle: 'Select one:',
    answerType: 'goToStep',
    answerOptions: [
      { label: 'Strategy and planning', icon: <StrategyAndPlanningSvg />, value: 'strategy_and_planning_step', goToStep: 'advisor_strategy_and_planning_step' },
      { label: 'Investments tracking and reporting', icon: <ToolsToManageWealthSvg />, value: 'investments_tracking_and_reporting', goToStep: 'advisor_investments_tracking_and_reporting_step' },
      { label: 'Budgeting and expense management', icon: <WalletSvg />, value: 'budgeting_and_expense_management', goToStep: 'advisor_budgeting_and_expense_management_step' },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none' },
    showFooter: false,
    withRandomize: true,
  }),
  advisor_strategy_and_planning_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: 'Excellent, we can help with strategy and planning! Last question,',
    title: 'what are your two biggest challenges?',
    subTitle: 'Select up to two:',
    answerType: 'multiChoice',
    answerSelectionLimit: 2,
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerOptions: [
      { label: 'Building an estate plan', value: 'building_an_estate_plan' },
      { label: 'Planning future cash flow', value: 'planning_future_cashflow' },
      { label: 'Refining tax strategy', value: 'refining_tax_strategy' },
      { label: 'Understanding my net worth over time', value: 'understanding_my_net_worth_over_time' },
      { label: 'Aligning my asset allocation strategy and goals', value: 'aligning_my_asset_allocation_strategy_and_goals', customStyle: {} },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    nextStep: 'anything_else_step',
    withRandomize: true,
  },
  advisor_investments_tracking_and_reporting_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: 'Excellent, we can help with investment tracking and reporting! Last question,',
    title: 'what are your two biggest challenges?',
    subTitle: 'Select up to two:',
    answerType: 'multiChoice',
    answerSelectionLimit: 2,
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerOptions: [
      { label: 'Tracking diverse assets’ performance', value: 'tracking_diverse_assets_performance' },
      { label: 'Gathering information for tax returns', value: 'gathering_information_for_tax_returns'},
      { label: 'Gathering investment data from many sources', value: 'gathering_investment_data_from_many_sources' },
      { label: 'Customizing and generating reports', value: 'customizing_and_generating_reports' },
      { label: 'Visualising net worth', value: 'visualising_net_worth' },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    nextStep: 'anything_else_step',
    withRandomize: true,
  },
  advisor_budgeting_and_expense_management_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: 'Excellent! Last question,',
    title: 'what are your two biggest challenges?',
    subTitle: 'Select up to two:',
    answerType: 'multiChoice',
    answerSelectionLimit: 2,
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerOptions: [
      { label: 'Breakdown of expenses', value: 'breakdown_of_expenses' },
      { label: 'Gathering data from many bank accounts and credit cards', value: 'gathering_data_from_many_bank_accounts_and_credit_cards' },
      { label: 'Building and enforcing a budget', value: 'building_and_enforcing_a_budget' },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column', alignItems:'stretch' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none' },
    nextStep: 'anything_else_step',
    withRandomize: true,
  },
  advisor_where_and_how_to_invest_type_of_tool_step: ({ firstNameCapitalize }) => ({
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    title: `Great! Which of the following best describe\nwhat you're looking for?`,
    answerType: 'goToStep',
    answerOptions: [
      { label: 'Finding new investment opportunities', icon: <MagnifierSvg />, value: 'finding_new_investment_opportunities', goToStep: 'advisor_finding_new_investment_opportunities_and_planning_step' },
      { label: 'Refining my investment strategy', icon: <StrategyAndPlanningSvg />, value: 'refining_my_investment_strategy', goToStep: 'advisor_refining_my_investment_strategy' },
      { label: 'Solving financial complexity', icon: <ThreeDBox />, value: 'solving_financial_complexity', goToStep: 'advisor_type_of_tool_step' },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none', maxWidth: '280px' },
    showFooter: false,
    withRandomize: true,
  }),
  advisor_finding_new_investment_opportunities_and_planning_step: ({ firstNameCapitalize }) => ({
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    title: `What type of investments are you looking for, ${firstNameCapitalize}?`,
    answerType: 'goToStep',
    answerOptions: [
      { label: 'Private investments', icon: <VillageSvg />, value: 'private_investments', goToStep: 'advisor_private_investments_strategy_and_planning_step' },
      { label: 'Public investments', icon: <MarshmallowsSvg />, value: 'public_investments', goToStep: 'advisor_public_investments_strategy_and_planning_step' },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none' },
    showFooter: false,
    withRandomize: true,
  }),
  advisor_private_investments_strategy_and_planning_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: `Got it, within the private markets,`,
    title: 'What are your two biggest challenges?',
    subTitle: 'Select up to two:',
    answerType: 'multiChoice',
    answerSelectionLimit: 2,
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerOptions: [
      { label: 'Accessing investment opportunities', value: 'accessing_investment_opportunities' },
      { label: 'Vetting investment opportunities', value: 'vetting_investment_opportunities' },
      { label: 'Lowering fees for investments opportunities', value: 'lowering_fees_for_investments_opportunities' },
      { label: 'Lowering the minimum ticket for investments opportunities', value: 'lowering_the_minimum_ticket_for_investments_opportunities' },
      { label: 'Finding tax efficient investments opportunities', value: 'finding_tax_efficient_investments_opportunities' },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    nextStep: 'anything_else_step',
    withRandomize: true,
  },
  advisor_public_investments_strategy_and_planning_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: 'Excellent! Last question, within the public markets,',
    title: 'What are your two biggest challenges?',
    subTitle: 'Select up to two:',
    answerType: 'multiChoice',
    answerSelectionLimit: 2,
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerOptions: [
      { label: 'Deciding which stocks / bonds / ETFs etc. to buy', value: 'deciding_which_stocks_bonds_et_fs_etc_to_buy' },
      { label: 'Accessing structured products', value: 'accessing_structured_products' },
      { label: 'Accessing top-performing hedge funds', value: 'accessing_top-performing_hedge_funds' },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column', alignItems:'stretch' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none' },
    nextStep: 'anything_else_step',
    withRandomize: true,
  },
  advisor_refining_my_investment_strategy: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: 'Excellent, we can help with strategy and planning! Last question,',
    title: 'what are your two biggest challenges?',
    subTitle: 'Select up to two:',
    answerType: 'multiChoice',
    answerSelectionLimit: 2,
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerOptions: [
      { label: 'Building an estate plan', value: 'building_an_estate_plan' },
      { label: 'Retirement planning ', value: 'retirement_planning' },
      { label: 'Aligning asset allocation strategy and goals', value: 'aligning_asset_allocation_strategy_and_goals', customStyle: {} },
      { label: 'Refining tax strategy', value: 'refining_tax_strategy' },
      { label: 'Planning future cash flow', value: 'planning_future_cashflow' },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    nextStep: 'anything_else_step',
    withRandomize: true,
  },
};

const AdvisorToolsInsightHeader = () => (
  <QuestionnaireTitleWrapper >
      <QuestionnaireTopSubTitle >Perfect choice! Time is golden.</QuestionnaireTopSubTitle>
      <QuestionnaireStepTitle >Our clients save over <CountAnimation width={34} value={20} duration={2}/> hours a month with us.<br/>Welcome aboard!</QuestionnaireStepTitle>
  </QuestionnaireTitleWrapper>
)

const AdvisorWhereAndHowInsightHeader = () => (
  <QuestionnaireTitleWrapper >
      <QuestionnaireTopSubTitle >You're in good hands.</QuestionnaireTopSubTitle>
      <QuestionnaireStepTitle >With <CountAnimation width={180} value={6383101445} currency={'USD'} duration={2}/> tracked, our unique investment insights<br/>have helped thousands make better decisions.</QuestionnaireStepTitle>
  </QuestionnaireTitleWrapper>
)

const AdvisorInsightHeader = () => (
  <QuestionnaireTitleWrapper>
    <QuestionnaireTopSubTitle>You're in good hands!</QuestionnaireTopSubTitle>
    <QuestionnaireStepTitle>Vyzer helps <CountAnimation width={44} value={274} duration={2} /> individuals like you<br/>effectively manage others.</QuestionnaireStepTitle>
  </QuestionnaireTitleWrapper>
)

const AdvisorTypeFooter = observer(() => {
  const { onBoardingStore } = useStore();
  const {mixpanelTrack} = useAnalytics();

  const handleContinueOnClick = () => {
    if (!isNullOrUndefinedOrEmptyString(onBoardingStore.currentStepData) && isValidInput(onBoardingStore.currentStepData?.other)) {
      mixpanelTrack('Questionnaire Step Completed', {step_name: onBoardingStore.questionnaireStep, step_selections: onBoardingStore.currentStepData});
      onBoardingStore.handleNext('advisor_net_worth_step');
    }
  }

  const isValidInput = (data) => !isNullOrUndefinedOrEmptyString(data)
  const isHiddenInput = (data) => isNullOrUndefinedOrEmptyString(data)

  return (
    isHiddenInput(onBoardingStore.currentStepData?.value) ? null :
    <QuestionnaireContinueButton disabled={!isValidInput(onBoardingStore.currentStepData?.other)} onClick={handleContinueOnClick}>Continue</QuestionnaireContinueButton>
  )
})