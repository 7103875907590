import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../../common/styles/theme.styles';

export const Wrapper = styled.div`
    width: ${props=>props.width ? props.width : '100%'};
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    margin:16px 0 16px 0;
    /* margin:0 auto;
    margin-top:${props=>props.marginTop || '0px'}; */
`

export const LeftPanelWrapper = styled.div`
    ${flexCenter};
    flex-direction: column;
    flex: 1;
`;

export const LeftPanelContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 24px 0 56px;
    ${views.tablet}{
        padding: 0 24px;
    }
`;

export const TitlesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const Title = styled.div`
    font-size: 14px;
    line-height:17px;
    font-weight:500;
    color:#000;
`;

export const AltTitle = styled.div`
    font-size: 14px;
    line-height: 17px;
    font-weight:450;
    color:#fff;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    gap: 8px;
    /* min-height: 33px; */
`;

export const TourButtonWrapper = styled.div`
    border: 1px solid #C0D4FC;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.32)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04));
    border-radius: 6px;
    color: #C0D4FC;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;

`;

export const TourButton = styled.div`
    ${flexCenter};
    gap: 6px;
    padding: 8px 16px;
    svg{
        width: 15px;
        height: 15px;
    }
`;

export const AddAssetButtonWrapper = styled(TourButtonWrapper)`
    color: ${colors.darkGray1};
    background-color: #C0D4FC;
`;





export const AddAssetButton = styled.div`
    ${flexCenter};
    /* gap: 8px; */
    padding: 8px 20px;
    svg :first-of-type {
        width: 10px;
        height: 10px;
        path{
            stroke: ${colors.darkGray1}
        }
    }
`;

export const AddAssetButtonText = styled.div`
    /* padding-left: 8px; */
`;

export const RightPanelWrapper = styled.div`
    ${flexCenter};
    flex: 1;
    ${views.tablet}{
        padding: 0 12px;
    }
`;

export const EmptyGraphWrapper = styled.div`
    ${flexCenter};
    padding: 48px;
    position: relative;
    flex: 1;
    height: 100%;
`;

export const GraphPlaceholder = styled.div`
    display: flex;
    border: 4px solid #C0D4FC;
    border-radius: 50%;
    flex: 1;
    height: 100%;
    height: 312px;
    width: 312px;
    max-width:312px;
`;

export const ChartDataboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom:0;
    right:0;
`;

export const PieTextLabel = styled.div`
    color:#fff;
    font-size:16px;
    line-height:12px;
    margin-bottom: 4px;
`;

export const PieTextBigValue = styled.div`
    color:#FFFFFF;
    font-size:${props=>props.isIncognito ? '22px' : props.fontSize ? props.fontSize : '32px'};
    font-weight:  32px;
`;

export const ValueChangesButton = styled.div`
    margin-top: 0px;
    padding: 0px 8px;
    color: #9097A3;
    font-size:12px;
    ${flexCenter};
    pointer-events:auto;
`;

export const ValueChangesButtonWrapper = styled.div`
    ${flexCenter};
    background: ${colors.darkGray1};
    border: 1px solid #2E2F32;
    box-shadow: ${shadows.toggleShadow};
    border-radius: 4px;
`;

export const BoxesWrapper = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    gap:16px;
`;

export const BoxWrapper = styled.div`
    ${flexCenter};
    background:${props=>props.isCompleted ? '#e0ffe0' : '#FFFFFF'};
    border: 1px solid ${colors.gray3};
    box-sizing: border-box;
    border-radius: 8px;
    flex:1;
    color:${props=>props.isCompleted ? 'green' : 'black'};
    height:108px;
    padding: 0 12px;
    cursor: pointer;
    position: relative;
`;

export const BoxIconWrapper = styled.div`
    ${flexCenter};
`;

export const BoxTextWrapper = styled.div`
    margin-left: 12px;
    display: flex;
    flex-direction: column;
`;

export const BoxTitle = styled.div`
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${colors.darkGray1};
`;

export const BoxTitleDescription = styled(BoxTitle)`
    font-size: 12px;
    line-height: 130%;
    font-weight: 400;
`;

export const BoxCompletedWrapper = styled.div`
    ${flexCenter};
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background-color: #6EBA7F;
    position: absolute;
    top: 12px;
    right: 12px;
    svg{
        width: 18px;
        height: 18px;
    }
`;