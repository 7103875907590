import React from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../../app/data/root.store';
import { FilterOptionsWrapper, ListParameterOption, OptionLogoPlaceholder, OptionLogoWrapper, SearchBoxWrapper, SearchIconWrapper } from './SponsorParameterFilter.styles';
import { CheckmarkIcon } from '../../../../../assets/icons/common/common-icons';
import { Input } from 'antd';
import { SponsorParameterFilterEmptyState } from './SponsorParameterFilterEmptyState';
import { ReactComponent as SearchResultsIcon } from './icons/search-icon.svg';

export const SponsorParameterFilter = ({ param, paramIndex }) => {
    const { filtersStore } = useStore();

    const handleOptionClick = (optionIndex, isSelected) => {
        filtersStore.setListOptionSelected(paramIndex, optionIndex, !isSelected);
    }

    return (<Observer>{() => (
        <FilterOptionsWrapper>
            <SearchBoxWrapper>
                <Input placeholder={'Search'}
                    suffix={null}
                    value={filtersStore.institutionStr}
                    onChange={(e) => filtersStore.setInstitutionStr(e.target.value || '')}
                />
                <SearchIconWrapper hasSearchInput={filtersStore.institutionStr !== ''} ><SearchResultsIcon /></SearchIconWrapper>
            </SearchBoxWrapper>
            {!filtersStore.institutionSearchResults.length && filtersStore.institutionStr !== '' ?
                <SponsorParameterFilterEmptyState /> :
                filtersStore.institutionSearchResults?.map((option, index) => (
                    <ListParameterOption key={index} onClick={() => { handleOptionClick(option.index, option.isSelected) }} isSelected={option.isSelected}>
                        {option.logoBase64 ? <OptionLogoWrapper><img alt={option.connectedInstitutionName} src={'data:image/png;base64,' + option.logoBase64} /></OptionLogoWrapper> :
                            option.logoURL ? <OptionLogoWrapper><img alt={option.connectedInstitutionName} src={option.logoURL} /></OptionLogoWrapper> :
                                <OptionLogoPlaceholder />}
                        {option.name}
                        {option.isSelected ? <CheckmarkIcon /> : ''}
                    </ListParameterOption>
                ))}
        </FilterOptionsWrapper>
    )}</Observer>)
}
