import { Player } from '@lottiefiles/react-lottie-player';
import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { ArrowRightIcon, ModalCloseIcon } from '../../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../../common/components/BaseModal/BaseModal';
import { ModalFooter } from '../../../../../common/components/BaseModal/BaseModal.styles';
import { ROUTES } from '../../../../../common/constants/routes.const';
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import { useAnalytics } from '../../../../app/data/AnalyticsProvider';
import { useStore } from '../../../../app/data/root.store';
import magicBoxAnimData from '../Icons/magic-box-animation-data.json';
import { ActionButton, ContentPrompt, ContentTextWrapper, ContentTitle, ModalContent, ModalHeader, UnlockMagicBoxModalSubtitle, UnlockMagicBoxModalTitle, UnlockMagicBoxModalWrapper } from './UnlockMagicBoxModal.styles';

export const UnlockMagicBoxModal = (props) => {
  const { onClose } = props;
  const history = useHistory();
  const {userStore} = useStore();
  const { mixpanelTrack } = useAnalytics()

  useEffect(() => {
    mixpanelTrack('Popup Viewed', {
      destination : ROUTES.magicBox.url,
      location: 'Asset page',
      title: `Great job, You've successfully added your first asset.`,
      cta_text: 'Upload now',
      type: 'Unlock MagicBox Modal',
      is_demo: userStore.isDemoMode
    })
  
    // return () => {}
  }, [mixpanelTrack, userStore.isDemoMode])

  const handleUploadClick = (action) => {
    mixpanelTrack('Popup Clicked', {
      click_type: 'CTA click',
      destination : `${action === 'upload' ? ROUTES.magicBox.url : 'help.vyzer.co/en/articles/6514782-the-magic-box'}`,
      location: 'Asset page',
      title: `Great job, You've successfully added your first asset.`,
      cta_text: `${action === 'upload' ? 'Upload now' : 'Learn more'}`,
      type: 'Unlock MagicBox Modal',
      is_demo: userStore.isDemoMode
    })
    if (action === 'upload') {
      navigateToUrl(history, ROUTES.magicBox.url);
    } else {
      window.open('https://help.vyzer.co/en/articles/6514782-the-magic-box', '_blank');
    }
    onClose && onClose();
  }

  return (<Observer>{() => (
    <BaseModal borderRadius={12} width={'690px'} height={'auto'} onClick={(e) => { e.stopPropagation() }}>
      <UnlockMagicBoxModalWrapper>
        <div onClick={()=>{onClose && onClose()}} style={{position:'absolute', right:'8px', top:'8px', cursor:'pointer'}}>
          <ModalCloseIcon />
        </div>
        <ModalHeader>
          <UnlockMagicBoxModalTitle>Great job 🎉</UnlockMagicBoxModalTitle>
          <UnlockMagicBoxModalSubtitle>{`You’ve successfully added your first asset.`}</UnlockMagicBoxModalSubtitle>
        </ModalHeader>
        <ModalContent>
          <ContentTextWrapper>
            <ContentTitle><span style={{color:'#F33DAA'}}>Do you have more?</span> Set up your portfolio effortlessly</ContentTitle>
            <ContentPrompt>{`Upload your investment trackers, financial statements, and Vyzer\nwill create your assets and liabilities for you.`}</ContentPrompt>
          </ContentTextWrapper>
          <Player
            loop={true}
            src={magicBoxAnimData}
            //play={showLetsGoAnim}
            speed={1}
            autoplay
            style={{ width: '538px', margin: '0', marginLeft: 'auto' }}
          />
        </ModalContent>
        <ModalFooter padding={'20px 0 0'} style={{ width: '100%' }}>
          <ActionButton onClick={handleUploadClick} isBack>Learn more</ActionButton>
          <ActionButton onClick={()=>{handleUploadClick('upload')}} black withIcon>Upload now <ArrowRightIcon /></ActionButton>
        </ModalFooter>
      </UnlockMagicBoxModalWrapper>
    </BaseModal>
  )}</Observer>)
}
