import { Input } from 'antd';
import React from 'react';
import NumberFormat from 'react-number-format';
import { DeleteIcon, PlusIcon } from '../../../../../assets/icons/common/common-icons';
import { isNullOrUndefinedOrEmptyString } from '../../../../utils/object.utils';
import { ActionButtonWrapper, CreateButton, HeaderCell, RowActionsCell, RowCell, RowWrapper, TableBottomActions, TableHeaderRow, TableScrollContent, Wrapper } from './LifeInsuranceRidersTable.styles';

const tableColumns = ['Rider benefits amount', 'Policy description'];

export const LifeInsuranceRidersTable = (props) => {
  const { onChange, disabled, value, rowValuePreFix, withError } = props;
  // console.log({...value});

  const handleAddRowClick = (rows) => {
    if (disabled){return}
    const maxValueOfTempId = Math.max(...value.map(u => u.tempId || 0), 0);
    const newRow = {
      amount: null,
      description: '',
      tempId: maxValueOfTempId + 1,
    }
    onChange?.([...value, newRow]);
  }

  const handleAttributeRowUpdated = (updatedIndex, data, key) => {
    const updatedSortedSchedule = value.map((item, index) => (index === updatedIndex ? data : item));
    onChange?.(updatedSortedSchedule);
  }

  const handleAttributeRowDeleted = (deletedIndex) => {
    const updatedSchedule = value.filter((item, index) => index !== deletedIndex);
    onChange?.(updatedSchedule);
  }

  return (
    <Wrapper>
      <TableHeaderRow onClick={(e) => { e.stopPropagation() }}>
        {tableColumns.map((columnLabel, ind) => (
          <HeaderCell key={'hc1' + ind} >
            <span>{columnLabel}</span>
          </HeaderCell>
        ))}
      </TableHeaderRow>
      <TableScrollContent>
        {value?.map((row, index) => (
          <HistoryRow key={row.id || ('t' + row.tempId)}
            rowValuePreFix={rowValuePreFix} index={index}
            item={row} atrKey={row.atrKey}
            disabled={disabled}
            withError={withError}
            rowUpdated={(data, key) => { handleAttributeRowUpdated(index, data, key) }}
            rowDeleted={() => handleAttributeRowDeleted(index)} />
        ))}
      </TableScrollContent>
      <TableBottomActions>
        <CreateButton disabled={disabled} onClick={() => { handleAddRowClick(value) }}>
          <PlusIcon />
          <span className='button-text'>Add rider</span>
        </CreateButton>
      </TableBottomActions>
    </Wrapper>
  )
}

const HistoryRow = (props) => {
  const { item, disabled, rowDeleted, withError, rowValuePreFix } = props;

  const handleFieldChange = (field, value) => {
    props.rowUpdated({
      ...item,
      [field]: value
    }, field)
  }

  return (
    <RowWrapper onClick={(e) => { e.stopPropagation(); }}>
      <RowCell>
        <NumberFormat
          thousandSeparator={true}
          customInput={Input}
          className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.amount) ? ' inputEntryError' : ''}`}
          allowNegative={false}
          disabled={disabled}
          value={(isNullOrUndefinedOrEmptyString(item.amount)) ? '' : parseFloat((+item?.amount).toFixed(2))}
          onValueChange={(values) => { handleFieldChange('amount', values.floatValue) }}
          prefix={rowValuePreFix}
          placeholder={rowValuePreFix}
        />
      </RowCell>
      <RowCell>
        <Input
          type='text'
          className={`innerTable`}
          value={item.description}
          disabled={disabled}
          onChange={(e) => { handleFieldChange('description', e.target.value) }}
        />
      </RowCell>
      { !disabled && <RowActionsCell>
        <ActionButtonWrapper
          onClick={() => { rowDeleted?.() }} >
          <DeleteIcon />
        </ActionButtonWrapper>
      </RowActionsCell> }
    </RowWrapper>
  )
}