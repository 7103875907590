import { observer } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { DeleteCustomClassActionButton, DeleteCustomClassAltText, DeleteCustomClassContainer, DeleteCustomClassFooter, DeleteCustomClassHeader, DeleteCustomClassMainText, DeleteCustomClassWrapper } from './CustomClassRow.styles';
import { ImageTile } from './EditCustomClass.styles';
import { ReactComponent as ModalCloseSvg } from './svgs/modal-close-button.svg';
import { useStore } from '../../../app/data/root.store';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';

export const DeleteCustomClass = observer((props) => {

  return (
    ReactDOM.createPortal(<BaseModal width={'512px'} height={'auto'} borderRadius={8}>
      <DeleteCustomClassContent {...props} />
    </BaseModal>, document.body)
  )
})

const DeleteCustomClassContent = observer(({ item, onDelete, onClose, isCustomSubClass, relevantImage, relevantFallbackColor, RelevantSvg }) => {
  const { userStore } = useStore();
  const classType = isCustomSubClass ? 'sub-class' : 'class';
  const isBlockedByRoleOrPaymentStatus = userStore.isReadOnlyMode || userStore.isActionBlockedByRole(isCustomSubClass ? 'deleteCustomSubClass' : 'deleteCustomClass');

  const handleDelete = () => {
    if (isBlockedByRoleOrPaymentStatus) {
      userStore.setShowLimitedSharedAccess(true);
    } else {
      onDelete();
      onClose();
    }
  }

  return (
    <DeleteCustomClassContainer>
      <DeleteCustomClassHeader>
        <ModalCloseSvg onClick={onClose} style={{ cursor: 'pointer' }} />
      </DeleteCustomClassHeader>
      <DeleteCustomClassWrapper>
        <ImageTile image={relevantImage} fallbackColor={relevantFallbackColor} size={32} borderRadius={6}>
          {RelevantSvg && <RelevantSvg />}
        </ImageTile>
        <DeleteCustomClassMainText>
          Are you sure you want to delete the<br /> "{item.name}" custom {classType}?
        </DeleteCustomClassMainText>
        <DeleteCustomClassAltText>
          Any asset listed under this {classType} will appear<br /> as 'Unspecified' after deletion
        </DeleteCustomClassAltText>
      </DeleteCustomClassWrapper>
      <DeleteCustomClassFooter>
        <DeleteCustomClassActionButton onClick={handleDelete}>
          {isBlockedByRoleOrPaymentStatus && <ActionLockedIconWithTooltip iconColor={'#fff'} marginLeft={'0px'} isRoleBlocked={userStore.isActionBlockedByRole(isCustomSubClass ? 'deleteCustomSubClass' : 'deleteCustomClass')} />}
          Delete custom {classType}
        </DeleteCustomClassActionButton>
      </DeleteCustomClassFooter>
    </DeleteCustomClassContainer>
  );
});