import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './modules/app/components/App';
// import reportWebVitals from './reportWebVitals';

import { datadogRum } from '@datadog/browser-rum';

if (process.env.REACT_APP_ENV_KEY !== 'dev') {
  datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: `vyzer-${process.env.REACT_APP_ENV_KEY}`,
      env: process.env.REACT_APP_ENV_KEY,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0', 
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [process.env.REACT_APP_BASE_API_URL, ],
  });
}

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
