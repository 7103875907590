import { makeAutoObservable } from "mobx";
import { NotificationErrorIcon, NotificationMegaphoneIcon, NotificationTransactionsIcon } from '../components/icons/notification-icons';
import { getNotificationsData, postNotificationMarkAsRead, postNotificationMarkAsSeen } from './notifications.service';

const NotificationTypes = {
  'EVENT_PASSED': 'event passed',
  'END_OF_EVENT_MONEY_IN': 'end of event money in',
  'END_OF_EVENT_MONEY_OUT': 'end of event money out',
  'TRANSACTION_TO_EVENT': "transaction to specific event",
  'TRANSACTION_TO_ITEM': "transaction to specific item",
  'UNLINKED_TRANSACTION': "unlinked transaction",
  'CONNECTIVITY': 'connectivity',
  'ENGAGEMENT': 'engagement',
  'ONBOARDING': 'onboarding',
  'NET_WORTH_CHANGES': 'net worth changes',
  'INVITATION_ACCEPTED' : 'invitation accepted'
}

export class NotificationsStore {
  notifications = [];
  isFetchingNotifications = true;
  isUpdating = true;

  constructor(userStore) {
    makeAutoObservable(this);
    this.userStore = userStore;
  }

  setNotifications(data) {
    this.notifications = data;
  }

  setNotificationIconAndAction(notificationType) {
    switch (notificationType) {
      case NotificationTypes.EVENT_PASSED: // priority 1
        return { icon: <NotificationMegaphoneIcon />, action: { text: 'Review' } }
      case NotificationTypes.END_OF_EVENT_MONEY_IN: // priority 2.1
      case NotificationTypes.END_OF_EVENT_MONEY_OUT: // priority 2.2
        return { icon: <NotificationErrorIcon />, action: { text: 'Got it' } }
      case NotificationTypes.TRANSACTION_TO_EVENT: // priority 3
      case NotificationTypes.TRANSACTION_TO_ITEM: // priority 4
      case NotificationTypes.UNLINKED_TRANSACTION: // priority 5
        return { icon: <NotificationTransactionsIcon />, action: { text: 'Review' } }
      case NotificationTypes.CONNECTIVITY: // priority 6
        return { icon: <NotificationErrorIcon />, action: { text: 'Reconnect' } }
      default:
        return <NotificationMegaphoneIcon />
    }
  }

  setIsFetchingNotifications(isFetching) {
    this.isFetchingNotifications = isFetching;
  }

  setIsUpdating(isUpdating) {
    this.isUpdating = isUpdating;
  }

  setNotificationMarkedAsRead = async (notificationsIds) => {
    this.setIsUpdating(true);
    try {
      await postNotificationMarkAsRead(notificationsIds);
      this.setNotifications(this.notifications.filter(notification => notificationsIds.indexOf(notification.id) === -1))
    }
    //catch (e) {} 
    finally {
      this.setIsUpdating(false);
    }
  }

  setNotificationMarkedAsSeen = async (itemId) => {
    this.setIsUpdating(true);
    try {
      await postNotificationMarkAsSeen(this.notifications.filter(notif => notif.itemId === itemId).map(notif => notif.id));
    }
    //catch (e) {} 
    finally {
      this.setIsUpdating(false);
    }
  }

  getNotifications = async (cb) => {
    this.setIsFetchingNotifications(true);
    try {
      const data = await getNotificationsData();
      if (data.length > 0) {
        this.setNotifications(data);
      }
      const filteredDataLength = data.filter(notif => 
          notif.notificationType !== NotificationTypes.INVITATION_ACCEPTED || ['OWNER','ADMIN'].includes(this.userStore.currentPortfolioDetails.role)).length
      cb && cb(filteredDataLength);
    }
    //catch (e) {} 
    finally {
      this.setIsFetchingNotifications(false);
    }
  }

  removeNotification = (itemId, eventType, months) => {
    this.setNotifications(this.notifications.filter(ntf => ntf.itemId !== itemId || ntf.eventType !== eventType || ntf.months !== months));
  }

  get displayedNotifications() {
    return this.notifications.filter(
      notif => 
        notif.notificationType !== NotificationTypes.INVITATION_ACCEPTED || ['OWNER','ADMIN'].includes(this.userStore.currentPortfolioDetails.role))
  }

  get sortedNotifications() {
    const sortedByAmount = this.displayedNotifications
    .slice().sort((a, b) => {
      return a.months < b.months ? 1 : a.months > b.months ? -1 :
        (a.sortPriority > b.sortPriority ? 1 : a.sortPriority < b.sortPriority ? -1 : (a.amount < b.amount ? 1 : a.amount > b.amount ? -1 : 0));
    })

    const groupedByItemId = [];
    sortedByAmount.forEach((notif) => {
      let relevantGroup = groupedByItemId.find(obj => obj.itemId === notif.itemId);
      if (relevantGroup) {
        relevantGroup.items.push(notif);
      } else {
        groupedByItemId.push({
          itemId: notif.itemId,
          items: [notif]
        })
      }
    })

    return groupedByItemId.slice(0, 4).map(itemGroup => itemGroup.items[0]);
  }

}