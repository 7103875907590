import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors } from '../../../../common/styles/theme.styles';

export const ModalButton = styled.div`
  ${flexCenter};
  cursor: pointer;
  gap: 12px;
  background-color: #2565E7;
  color: #fff;
  padding: 14px 48px;
  font-size: 16px;
  line-height: 19px;
  border-radius: 29px;
  svg {
    width: 12px;
    height: 12px;
  }
`;

export const ModalCancelButton = styled(ModalButton)`
  margin-right: 10px;
  background-color: white;
  color: black;
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  color: #000000d9;
  background: #fff;
  /* border-bottom: 1px solid #f0f0f0; */
  /* border-radius: 2px 2px 0 0; */
  border-radius: 8px;
`;

export const ModalTitle = styled.div`
  color: ${colors.darkGray1};
  padding-top: 22px;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
`;

export const ModalContent = styled.div`
  ${flexCenter};
  flex-direction: column;
  padding: 0 86px;
  /* background-color: #F7F8F9; */
`;

export const ModalFooter = styled.div`
  ${flexCenter};
  flex-direction: column;
  padding-top: 60px;
  gap: 12px;
`;

export const ModalClose = styled.div`
  display: grid;
  margin-left: auto;
  order: 2;
  place-items: center;
  width: 32px;
  cursor: pointer;
  svg {
    path {
      stroke: ${colors.darkGray3};
      stroke-width: 1.5px;
    }
  }
`;

export const ModalEventPreview = styled.div`
  position: relative;
  display: grid;
  place-items: center;
`;

export const ModalPromptWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  white-space: pre-line;
`;

export const ModalBannerWrapper = styled.div`
  ${flexCenter};
  align-self: center;
  padding: 2px 6px;
  width: fit-content;
  gap: 4px;
  border: 1px solid #1A1B1D;
  border-radius: 31px;
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: #000;
    }
  }
`;

export const ModalBannerText = styled.div`
  font-size: 13px;
  line-height: 135%;
  color: ${colors.darkGray1};
`;