import styled from '@emotion/styled/macro';
import { colors } from '../../../../styles/theme.styles';
import { flexCenter } from '../../../../styles/combos.styles';

export const NumberFormatWithArrowsWrapper = styled.div`
    display: flex;
    position: relative;
`;

export const NumberArrows = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    height: 100%;
    border-left: 1px solid ${colors.gray3};
`;

export const ArrowWrapper = styled.div`
    ${flexCenter};
    flex:1;
`;

export const HorizontalLine = styled.div`
    height: 1px;
    background-color: ${colors.gray3};
    width: 100%;
`;

export const SuffixWrapper = styled.div`
    position: absolute;
    right: 8px;
    top:50%;
    transform: translateY(-50%);
    color:${colors.darkGray3};
    font-size: 16px;
`;