import { observer } from 'mobx-react'
import React, { useEffect, useRef } from 'react'
import { BaseModal } from '../../../../../../common/components/BaseModal/BaseModal'
import { ItemLogo } from '../../../../../../common/components/ItemLogo/ItemLogo'
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent/useTrackEvent'
import { useAnalytics } from '../../../../../app/data/AnalyticsProvider'
import { useStore } from '../../../../../app/data/root.store'
import { itemTitleByClassId } from '../AssetActionsMenu'
import { VerticalSeparator } from '../AssetOverviewHeroContent.styles'
import { ActionButton, CloseItemContentPromptWrapper, CloseItemContentWrapper, CloseItemFooterWrapper, CloseItemModalHeader, CloseItemModalTitle, ItemTitleWrapper, ModalCloseButtonWrapper, PromptTitle, StyledLink } from './CloseAccountNotFoundItemModal.styles'
import { PrimaryButton } from '../../../../../../common/components/buttons/NewButtons.styles'

export const CloseAccountNotFoundItemModal = observer(({ onClose, onItemClose }) => {
  const { itemStore, userStore } = useStore();
  const { closedAt } = itemStore.item;
  const { mixpanelTrack } = useAnalytics();
  const previousClosedAtRef = useRef(closedAt);

  const itemTitle = itemTitleByClassId(itemStore.item?.categoryClass.id);

  const handleDoneOnClick = (e) => {
    mixpanelTrack('Popup Clicked', {
      cta_text: e.target.textContent,
      destination: 'Asset page',
      location: 'Asset page',
      type: 'Button',
    })
    onClose?.()
  }

  const handleCloseItemOnClick = (e) => {
    mixpanelTrack('Popup Clicked', {
      cta_text: e.target.textContent,
      destination: 'Asset page',
      location: 'Asset page',
      type: 'Button',
    })
    onItemClose?.()
  }

  useTrackEvent('Popup Viewed', {
    title: 'Closure Eligibility',
    tier: userStore.data?.profile?.tier,
    url: window.location.href
  })

  useEffect(() => {
    if (closedAt && !previousClosedAtRef.current) {
      mixpanelTrack('Popup Viewed', {
        title: 'Close Confirmation',
        tier: userStore.data?.profile?.tier,
        url: window.location.href
      });
    }
    previousClosedAtRef.current = closedAt;
  }, [closedAt, userStore.data?.profile?.tier, mixpanelTrack]);

  return (
    <BaseModal width={'auto'} height={'auto'} borderRadius={8}>
      <CloseItemModalHeader>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', maxWidth: '100%', overflow: 'hidden' }}>
          <CloseItemModalTitle>{!closedAt ? 'Item closure confirmation' : 'Close out'}</CloseItemModalTitle>
          <VerticalSeparator style={{ height: '32px' }} />
          <ItemTitleWrapper>
            <ItemLogo item={itemStore.item} customStyles={{ marginRight: '6px' }} />
            {itemStore.item.title}{itemStore.item.title !== itemStore.item.name && itemStore.item.name ? `, ${itemStore.item.name}` : ``}
          </ItemTitleWrapper>
        </div>
        {!closedAt ? <ModalCloseButtonWrapper onClick={onClose}><ModalCloseButtonSvg /></ModalCloseButtonWrapper> : null}
      </CloseItemModalHeader>

      <CloseItemContentWrapper closedAt={closedAt}>

        <CloseItemContentPromptWrapper>
          {closedAt ? <ClosedSignSvg /> : null}
          {!closedAt ? <><PromptTitle>Are you sure you want to close out this asset? </PromptTitle>
          This will turn off the sync and turn this asset into a manual asset. Historical information will be saved. </> :
          <>
          <PromptTitle>You have closed out this asset.</PromptTitle>
          <div>
                Closed items still affect all historical metrics but do not contribute to net worth or have expected cash flow events. <StyledLink onClick={() => {window.open('https://help.vyzer.co/en/articles/9009152-how-does-closing-an-asset-affect-my-net-worth', '_blank')}}>Learn more</StyledLink>
          </div>
          </>}
        </CloseItemContentPromptWrapper>

      </CloseItemContentWrapper>

      <CloseItemFooterWrapper>
        {!closedAt ?
          <ActionButton onClick={handleCloseItemOnClick}>Close out this {itemTitle}</ActionButton> :
          <PrimaryButton style={{ padding: '8px 32px' }} onClick={handleDoneOnClick}>Done</PrimaryButton>
        }
      </CloseItemFooterWrapper>
    </BaseModal>
  )
})

const ModalCloseButtonSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="6.28033" y1="5.83344" x2="18.3011" y2="17.8543" stroke="#9097A3" strokeWidth="1.5" />
      <line x1="5.9267" y1="17.8544" x2="17.9475" y2="5.83362" stroke="#9097A3" strokeWidth="1.5" />
    </svg>
  )
}

const ClosedSignSvg = () => {
  return (
    <svg width="108" height="69" viewBox="0 0 108 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="52" cy="4" r="4" fill="#1A1B1D" />
      <rect x="0.5" y="33.5" width="107" height="35" rx="8.5" stroke="#1A1B1D" />
      <rect x="2" y="35" width="104" height="32" rx="8" fill="#1A1B1D" />
      <path d="M18.3616 59.33C13.8736 59.33 10.2656 56.096 10.2656 51.234C10.2656 46.372 13.9616 43.072 18.3176 43.072C23.0036 43.072 25.0716 46.086 25.5776 48.33L22.2996 49.364C22.0576 48.286 21.0676 46.46 18.3176 46.46C16.2276 46.46 13.8736 47.956 13.8736 51.234C13.8736 54.16 16.0296 55.854 18.3616 55.854C21.0676 55.854 22.1456 54.05 22.4536 52.928L25.7756 53.874C25.2696 56.03 23.2016 59.33 18.3616 59.33ZM38.3698 59H28.1618V43.402H31.6378V55.678H38.3698V59ZM42.9635 51.19C42.9635 54.446 45.2955 55.898 47.4735 55.898C49.6295 55.898 51.9615 54.446 51.9615 51.19C51.9615 47.934 49.6295 46.46 47.4735 46.46C45.2955 46.46 42.9635 47.934 42.9635 51.19ZM39.3555 51.212C39.3555 46.262 43.0735 43.072 47.4735 43.072C51.8515 43.072 55.5695 46.262 55.5695 51.212C55.5695 56.14 51.8515 59.33 47.4735 59.33C43.0735 59.33 39.3555 56.14 39.3555 51.212ZM68.7611 47.23L65.6371 48.11C65.5271 47.384 64.9331 46.152 63.0411 46.152C61.7431 46.152 60.9071 46.966 60.9071 47.824C60.9071 48.572 61.3691 49.122 62.4471 49.32L64.5371 49.716C67.5071 50.266 69.0471 52.18 69.0471 54.402C69.0471 56.844 67.0011 59.33 63.2831 59.33C58.9711 59.33 57.1231 56.536 56.9031 54.336L60.0931 53.566C60.2251 54.996 61.1931 56.25 63.3271 56.25C64.6911 56.25 65.5271 55.59 65.5271 54.622C65.5271 53.83 64.8891 53.258 63.8551 53.06L61.7211 52.664C59.0811 52.158 57.4751 50.398 57.4751 48.088C57.4751 45.14 60.0711 43.072 63.0631 43.072C66.9571 43.072 68.4311 45.404 68.7611 47.23ZM81.3313 59H71.4313V43.402H81.3093V46.68H74.9073V49.65H80.7153V52.708H74.9073V55.7H81.3313V59ZM87.7335 55.722H89.7575C92.1555 55.722 94.1135 54.336 94.1135 51.212C94.1135 48.088 92.1555 46.68 89.7575 46.68H87.7335V55.722ZM89.8895 59H84.2575V43.402H89.9115C94.5975 43.402 97.7435 46.394 97.7435 51.212C97.7435 56.03 94.5975 59 89.8895 59Z" fill="white" />
      <path d="M32 33L51.9979 4.00327L72 33.0032" stroke="#1A1B1D" strokeLinecap="round" />
    </svg>
  )
}