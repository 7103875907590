import React from 'react'
import { percentageCalc, percentageDisplay } from '../../../../../../common/utils/number-display.utils';
import { ItemTargetActualPerformanceWrapper } from './OverviewTab.styles';

export const ItemTargetActualPerformance = (props) => {
    const { stat, itemData, selectedTimeFrameIndex } = props;
    const timeFrameData = itemData[stat.dataKey]?.[selectedTimeFrameIndex] ?? { actual: 0, target: 0 };
    const actualValue = timeFrameData.actual;
    const targetValue = timeFrameData.target;

    const isPositiveHandler = (value) => {
        return stat.isActualAboveTargetPositive ?
            ( actualValue >= targetValue || percentageCalc(actualValue - targetValue, actualValue) === 0 )
            :
            actualValue < targetValue
    }

    return (
        (actualValue !== 0 && targetValue !== 0) ? <ItemTargetActualPerformanceWrapper isPositive={isPositiveHandler()}>
            {
                // stat.isActualAboveTargetPositive ? 
                // (

                    
                    percentageCalc(targetValue - actualValue, targetValue) > 0 ? `-${actualValue === 0 ? '100%' : percentageDisplay(targetValue - actualValue, targetValue, 0)} below target`
                    :
                    percentageCalc(actualValue - targetValue, actualValue) > 0 ? `+${targetValue === 0 ? '100%' : percentageDisplay(actualValue - targetValue, actualValue, 0)} above target`
                        : 'On target'
                // ) : 
                // (
                //     targetValue > actualValue ? `+${targetValue === 0 ? '100%' : percentageDisplay(targetValue - actualValue, actualValue)} above target`
                // :
                //     targetValue < actualValue ? `-${actualValue === 0 ? '100%' : percentageDisplay(actualValue - targetValue, targetValue)} below target`
                // : 'On target')
            }

        </ItemTargetActualPerformanceWrapper> : null
    )
}


// {targetValue > actualValue ? `-${actualValue === 0 ? '100%' : percentageDisplay(targetValue - actualValue, actualValue)} below target`
// :
// targetValue < actualValue ? `+${targetValue === 0 ? '100%' : percentageDisplay(actualValue - targetValue, targetValue)} above target`
// :
// 'On target'}