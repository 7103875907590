
import React from 'react';
import { Observer} from 'mobx-react';
import { useStore } from '../../../../modules/app/data/root.store';
import { Select } from 'antd';
import { ArrowDownIcon } from '../../../../assets/icons/common/common-icons';
import { isNullOrUndefinedOrEmptyString } from '../../../utils/object.utils';
// import { CheckMarkSvgWrapper, SelectWithDescriptionInOptionItemSubTitle, SelectWithDescriptionInOptionItemTitle, SelectWithDescriptionInOptionItemTitlesWrapper } from './SelectWithDescriptionInOption/SelectWithDescriptionInOption.styles';
import { ModifiedSelectClearIcon } from '../../ModifiedSelectClearIcon/ModifiedSelectClearIcon';
import { customClassColorsObj } from '../../../../modules/user/components/CustomClassList/icons/custom-colors';
import { customClassSvgObj } from '../../../../modules/user/components/CustomClassList/new-svgs/new-custom-svgs';
import { ImageTile, SelectEmptyStateMessage, SelectEmptyStateWrapper } from '../../../../modules/user/components/CustomClassList/EditCustomClass.styles';
import { colors } from '../../../styles/theme.styles';

export function CustomClassSelect(props) {
    const { inputKey, handleChange, disabled, value, scrollContainerId, defaultValue, allowClear} = props;

    const {metadataStore , uiStore} = useStore();

    const { Option } = Select;

    const { colorImage: emptyStateRelevantImage, fallbackColor: emptyStateRelevantFallbackColor } = customClassColorsObj['customColor13'] || {};

    return (<Observer>{()=>(
      <Select
        value={value}
        name={inputKey}
        disabled={disabled}
        allowClear={allowClear && !isNullOrUndefinedOrEmptyString(value)}
        clearIcon={allowClear ? <ModifiedSelectClearIcon /> : null}
        optionLabelProp='label'
        defaultValue={defaultValue}
        onChange={(val) => { handleChange(inputKey,val)} }
        dropdownClassName='formDisplayerDefaultSelect'
        getPopupContainer={() => scrollContainerId ? (document.getElementById(scrollContainerId) || document.body) : document.body}
        suffixIcon={<ArrowDownIcon />}
        notFoundContent={<SelectEmptyStateWrapper>
            <ImageTile
                image={emptyStateRelevantImage}
                fallbackColor={emptyStateRelevantFallbackColor}
                size={24}
                borderRadius={8} >
                <EmptyStateIcon />
            </ImageTile>
            <SelectEmptyStateMessage>No custom classes yet</SelectEmptyStateMessage>
            
        </SelectEmptyStateWrapper>}
        dropdownRender={menu => (<>
            {menu}
            <div style={{ display: 'flex', flex: 1, alignItems:'center', justifyContent:'center'  , borderTop : `1px solid ${colors.gray3}` }}>
                <a style={{ display: 'flex', flex: 1, alignItems:'center', gap:'8px' , justifyContent:'center' , padding:'8px' , color:'#2565E7'  }} href={'/profile/preferences' +  (uiStore.isDesktopView ? '?spt=customClass' : `/custom-class-list`)} >Edit custom class and sub-class list<ArrowLinkSvg /></a>
            </div>
        </>)}
      >
        {metadataStore.customClasses.map(customClass => {
            const { colorImage: relevantImage, fallbackColor: relevantFallbackColor } = customClassColorsObj[customClass.colorCode] || {};
            const  RelevantSvg  = customClassSvgObj[customClass.icon] || '';
            return (
                <Option key={customClass.id} value={customClass.id} className='formDisplayerSelectWithDescriptionOption' label={
                    <div style={{ display: 'flex', flex: 1, alignItems:'center', gap:'8px', marginTop:'-1px' }}>
                       <ImageTile
                            image={relevantImage}
                            fallbackColor={relevantFallbackColor}
                            size={28}
                            borderRadius={8}
                        >
                       {RelevantSvg && <RelevantSvg />}
                     </ImageTile>
                        {customClass.name}</div>}>
            <div style={{ display: 'flex', flex: 1, alignItems:'center', gap:'8px' }}>
                       <ImageTile
                            image={relevantImage}
                            fallbackColor={relevantFallbackColor}
                            size={32}
                        >
                       {RelevantSvg && <RelevantSvg />}
                     </ImageTile>
                        {customClass.name}</div>
            </Option>
            )
        }    
        )}
      </Select>
)}</Observer>)
    }
    

const ArrowLinkSvg = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9675 8.875C10.9675 8.875 15.233 8.875 17.1652 8.875C17.1652 10.8072 17.1652 15.0727 17.1652 15.0727" stroke="#2565E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.25 16.7969L16.8958 9.15103" stroke="#2565E7" strokeWidth="1.5" strokeLinecap="round"/>
</svg>
)

const EmptyStateIcon = () => (
<svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.63184 5.36575C1.04507 5.70186 1.04507 6.54814 1.63184 6.88425L8.06494 10.5693C8.33435 10.7237 8.66537 10.7237 8.93479 10.5693L15.3679 6.88425C15.9547 6.54814 15.9547 5.70187 15.3679 5.36575L8.93479 1.68067C8.66537 1.52634 8.33435 1.52634 8.06494 1.68067L1.63184 5.36575Z" fill="white" stroke="white" strokeWidth="1.3125"/>
<path d="M13.6612 7.88808L15.4784 8.92906C16.0395 9.25048 16.0702 10.0485 15.5353 10.412L8.9917 14.8592C8.69484 15.061 8.30488 15.061 8.00803 14.8592L1.4644 10.412C0.929569 10.0485 0.960196 9.25048 1.52131 8.92906L3.33856 7.88808" stroke="white" strokeWidth="1.3125"/>
<path d="M13.6612 11.9447L15.4784 12.9857C16.0395 13.3071 16.0702 14.1052 15.5353 14.4686L8.9917 18.9158C8.69484 19.1176 8.30488 19.1176 8.00803 18.9158L1.4644 14.4686C0.929569 14.1052 0.960196 13.3071 1.52131 12.9857L3.33856 11.9447" stroke="white" strokeWidth="1.3125"/>
</svg>
)