import { LoadingOutlined } from '@ant-design/icons'
import { Observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../../../../common/components/buttons/NewButtons.styles'
import { navigateToUrl } from '../../../../../common/utils/navigation.utils'
import { useStore } from '../../../../app/data/root.store'
import { VipSyncStepFooter, VipSyncStepWrapper } from '../../AddItem.styles'
import { VipSyncConsent } from './steps-vip-sync/VipSyncConsent'
import { VipSyncEmailSent } from './steps-vip-sync/VipSyncEmailSent'
import { VipSyncIntro } from './steps-vip-sync/VipSyncIntro'
import { useAnalytics } from '../../../../app/data/AnalyticsProvider'
import { ROUTES } from '../../../../../common/constants/routes.const'

const vipSyncSteps = [VipSyncIntro, VipSyncConsent, VipSyncEmailSent]
const vipSyncActionButtonTextByIndex = {
  0: 'Next',
  1: 'Submit',
  2: 'Done',
}
const vipMixpanelByIndex = {
  0: {
    cta_text: 'Next',
    destination: 'Service Agreement',
  },
  1: {
    cta_text: 'Submit',
    destination: 'Success Screen',
  },
  2: {
    cta_text: 'Done',
    destination: 'Synced Account Page',
  },
}

export const VipSyncFlow = ({ isActivationFlow, item, stepIndex }) => {
  const { userStore, createItemStore, connectedInstitutionsStore, uiStore } = useStore();
  const history = useHistory();
  const CurrentVipSyncStep = vipSyncSteps[stepIndex];
  const [isConsentApproved, setIsConsentApproved] = useState(false);
  const { trackEvent } = useAnalytics();

  const handleBackClick = () => {
    if (stepIndex === 0) {
      createItemStore.setInstitutionStep(createItemStore.syncMode);
    }
    const updatedIndex = stepIndex > 0 ? stepIndex - 1 : stepIndex;
    createItemStore.setVipSyncStepIndex(updatedIndex);
  }

  const handleNextClick = () => {
    const isDisabled = stepIndex === 1 && !isConsentApproved;
    if (isDisabled) return;
    trackEvent('CTA Clicked', {
      cta_text: vipMixpanelByIndex[stepIndex].cta_text,
      destination: vipMixpanelByIndex[stepIndex].destination,
      location: 'VIP Sync Service',
      type: 'Button',
      institution: item.name,
    })

    if (stepIndex === 1) {
      createItemStore.createVipAsset();
    }
    if (stepIndex === 2) {
      createItemStore.setShowModal(false);
      if (window.location.pathname.includes(ROUTES.accounts.url)) {
        connectedInstitutionsStore.fetchData();
      } else {
        navigateToUrl(history, ROUTES.accounts.url);
      }
    } else {
      createItemStore.setVipSyncStepIndex(stepIndex + 1);
    }
  }

  const handlePreferManuallyClick = () => {
    createItemStore.setCategoryStep(true);
    trackEvent('CTA Clicked', {
      cta_text: 'I prefer to add this item manually',
      location: 'VIP Sync Service',
      type: 'Button',
      destination: 'add manual item',
      institution: item.name,
    })
  }

  useEffect(() => {
    return () => {
      createItemStore.setIsFromVipVipSyncingTab(false);
    }
  }, [createItemStore])
  

  return (<Observer>{() => (
    <VipSyncStepWrapper>
      <CurrentVipSyncStep item={item} isConsentApproved={isConsentApproved} setIsConsentApproved={setIsConsentApproved} userStore={userStore} />
      {!isActivationFlow ? <VipSyncStepFooter>
        { stepIndex === 1 || (stepIndex !== 2 && !createItemStore.isFromVipVipSyncingTab) ? <SecondaryButton onClick={handleBackClick} style={{ padding: '10px 20px', maxHeight: '38px' }}>Back</SecondaryButton> : null}
        {stepIndex === 0 && !createItemStore.isFromVipVipSyncingTab ? <SecondaryButton onClick={handlePreferManuallyClick} style={{ padding: '10px 20px', maxHeight: '38px' }}>I prefer to add this item manually</SecondaryButton> : null}
        <PrimaryButton disabled={stepIndex === 1 && !isConsentApproved && !createItemStore.isSavingItem} onClick={handleNextClick} style={{ maxHeight: '38px', marginLeft: 'auto', ...(uiStore.isDesktopView ? {} : {width:'100%'} ) }}>{createItemStore.isSavingItem ? <LoadingOutlined /> : vipSyncActionButtonTextByIndex[stepIndex]}</PrimaryButton>
      </VipSyncStepFooter> : null}
    </VipSyncStepWrapper>
  )}</Observer>)
}
