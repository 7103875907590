import { Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { HeaderSearchBoxEmptyState } from '../../../../../modules/layout/components/HeaderSearchBox/HeaderSearchBoxEmptyState';
import { HeaderSearchBoxResultRow } from '../../../../../modules/layout/components/HeaderSearchBox/HeaderSearchBoxResultRow';
import { ReactComponent as SearchResultsIcon } from '../../../../../modules/layout/components/HeaderSearchBox/icons/search-icon.svg';
import { AmortizationCollateralAutoCompleteWrapper, InputWrapper, SearchBoxWrapper, SearchIconWrapper, SelectedItemWrapper } from './AmortizationCollateralAutoComplete.styles';
import { useStore } from '../../../../../modules/app/data/root.store';
import { isNullOrUndefinedOrEmptyString } from '../../../../utils/object.utils';
import { Observer } from 'mobx-react';

export const AmortizationCollateralAutoComplete = ({ value, disabled, onChange, scrollContainerId, onBlur }) => {
  const { metadataStore, uiStore } = useStore();
  const { Option } = Select;
  const [searchVal, setSearchVal] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSearchWithFocus, setIsSearchWithFocus] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    setSelectedOption(metadataStore.headerSearchItems.find(i=>i.id === value));
  }, [value,metadataStore])

  const handleSelectedItemClick = () => {
    const selectRefElement = selectRef.current;
    if (selectRefElement) {
      selectRefElement.focus();
    }
    setIsSearchWithFocus(true);
  }
  
  const handleSelect = (selected, option) => {
    onChange?.(option.children.props.item.id);
    setIsSearchWithFocus(false);
    setSearchVal('');
  }

  return (<Observer>{() => (
    <AmortizationCollateralAutoCompleteWrapper>
      <InputWrapper>
        <SearchBoxWrapper>
          <Select
            ref={selectRef}
            onFocus={() => { setIsSearchWithFocus(true) }}
            onBlur={() => { setIsSearchWithFocus(false); onBlur?.() }}
            open={isSearchWithFocus}
            allowClear
            disabled={disabled}
            onClear={() => { onChange?.(null) }}
            getPopupContainer={() => scrollContainerId ? (document.getElementById(scrollContainerId) || document.body) : document.body}
            showSearch
            value={value}
            placeholder='Search collateral to link'
            optionFilterProp='label'
            suffixIcon={null}
            style={{ width: '100%', display: 'flex' }}
            dropdownMatchSelectWidth={428}
            onSearch={(val) => setSearchVal(val)}
            onSelect={handleSelect}
            notFoundContent={<HeaderSearchBoxEmptyState />}
            dropdownClassName={`${!uiStore.isDesktopView ? 'connectLiabilitySearchBoxDropdown_mobile ': ''}headerSearchBoxDropdown`}
            optionLabelProp="label" >
            {metadataStore.headerSearchItems.filter(item=>item.categoryId === 40)?.map((item, index) => (
              <Option key={index} value={item.id} label={item.title}>
                <HeaderSearchBoxResultRow item={item} inputValue={searchVal} isLightTheme />
              </Option>
            ))}
          </Select>
          <SearchIconWrapper hasSearchInput={value !== '' || searchVal !== ''} ><SearchResultsIcon /></SearchIconWrapper>
        </SearchBoxWrapper>
        {!isNullOrUndefinedOrEmptyString(selectedOption) && !isSearchWithFocus ?
          <SelectedItemWrapper onClick={handleSelectedItemClick}>
            <HeaderSearchBoxResultRow item={selectedOption} inputValue={''} isLightTheme />
          </SelectedItemWrapper>
          :
          null}
      </InputWrapper>
    </AmortizationCollateralAutoCompleteWrapper>
  )}</Observer>)
}
