import { Checkbox, Drawer } from 'antd'
import React, { useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { BaseModal } from '../BaseModal/BaseModal'
import { PrimaryButton } from '../buttons/NewButtons.styles'
import { AccountSelectionModalBody, AccountSelectionModalFooter, AccountSelectionModalHeader, AccountSelectionModalWrapper, AccountSelectionTitle, CheckboxWrapper, ConfirmationViewRow, ConfirmationViewRowEmail, ConfirmationViewRowNameAndMail, ConfirmationViewRowNameText, RolesNameIconWrapper } from './AccountSelectionConfirmation.styles'
import { ReactComponent as ModalCloseButton } from './assets/modal-close-button.svg'
import { capitalize } from '../../utils/string.utils'

export const AccountSelectionConfirmation = ({ variant = 'modal', onClose, visible, ...props }) => {
  return (
    variant === 'modal' ?
      <BaseModal width={'550px'} height={'auto'} borderRadius={8} >
        <AccountSelectionConfirmationContent onClose={onClose} withBorderRadius {...props} />
      </BaseModal>
      :
      <Drawer
        closable={false}
        visible={visible}
        // width={340}
        // height={'calc(100% - 32px)'}
        height={'fit-content'}
        className={'limitedAccessDrawerContainer_mobile'}
        placement={'bottom'}
        destroyOnClose={true}
        onClose={onClose} >
        <AccountSelectionConfirmationContent onClose={onClose} isMobileDrawer {...props} />
      </Drawer>
  )
}

const AccountSelectionConfirmationContent = ({ isMobileDrawer, onClose, withBorderRadius = false, portfolio, onSwitchConfirm, userStore }) => {
  const [isDismissChecked, setIsDismissChecked] = useState(false)

  const swipeHandlers = useSwipeable(isMobileDrawer ? {
    onSwipedDown: (eventData) => {
      onClose?.()
    }
  } : {});

  const handleSwitch = () => {
    onSwitchConfirm?.();
    if(isDismissChecked) {
      userStore.updateGranteeSettings('promptDismissed', { switchUser: true });
    }
    onClose?.();
  }

  return (
    <AccountSelectionModalWrapper withBorderRadius={withBorderRadius} {...swipeHandlers}>
      <AccountSelectionModalHeader><ModalCloseButton onClick={onClose} style={{ cursor: 'pointer' }} /></AccountSelectionModalHeader>
      <AccountSelectionModalBody>
        <AccountSelectionTitle>
          You are about to switch to:
        </AccountSelectionTitle>

        <ConfirmationViewRow style={{ border: 'none', justifyContent: 'normal', marginBottom: 0, paddingLeft:0 }}>
          <RolesNameIconWrapper isOwner={portfolio.isOwner} color={portfolio.color}>
            {portfolio.name.charAt(0).toUpperCase()}
          </RolesNameIconWrapper>
          <ConfirmationViewRowNameAndMail>
            <ConfirmationViewRowNameText>{capitalize(portfolio.name)}’s Portfolio</ConfirmationViewRowNameText>
            <ConfirmationViewRowEmail>My role: {portfolio.roleDescription} / {capitalize(portfolio.role.toLowerCase())}</ConfirmationViewRowEmail>
          </ConfirmationViewRowNameAndMail>
        </ConfirmationViewRow>

      </AccountSelectionModalBody>
      <AccountSelectionModalFooter>
        {/* <DoNotShowAgainContainer > */}
        <CheckboxWrapper><Checkbox onChange={(checked)=>{setIsDismissChecked(checked)}}>Don’t show again</Checkbox></CheckboxWrapper>
        {/* </DoNotShowAgainContainer> */}
        <PrimaryButton onClick={handleSwitch}>Switch portfolio</PrimaryButton>
      </AccountSelectionModalFooter>
    </AccountSelectionModalWrapper>
  )
}