import styled from "@emotion/styled/macro";
import { colors } from "../../../../../../common/styles/theme.styles";

export const AllocatedByMenuItemList = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 12px;
`;

export const AllocatedByMenuItem = styled.div`
    display: flex;
    align-items: center;
    padding: 11px 8px;
    border: 1px solid ${props => props.isSelected ? colors.darkGray1 : 'transparent'};
    border-radius: 9px;
`;

export const AllocatedByMenuItemText = styled.div`
    flex:1;
    padding-left: 8px;
`;

export const DrawerHeader = styled.div`
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px 2px 24px;
  color: ${colors.darkGray1};
  font-size: 16px;
  font-weight: 450;
`;

export const DrawerHeaderTitle = styled.div`
  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const AllocateByTitle = styled.div`
  color: ${colors.darkGray2};
  font-family: Circular;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 12px 20px;
`;

export const AllocationDrawerCloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;