import styled from "@emotion/styled/macro";

export const SelectWithCustomOptionWrapper = styled.div`
  /* .eventModal { */
  .ant-dropdown-trigger {
    display: flex;
    border-radius: 4px!important;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 24%), 0px 2px 4px rgb(0 0 0 / 4%)!important;
  }
  /* } */
  .ant-input-affix-wrapper{
    height:40px !important;
    .ant-input {
      margin-top: -2px;
      height: 34px !important;
    }
  }
`;