export const weightedAverage = (data) => {
  const sum = data.reduce((acc, item) => {
    return acc + item.value * item.weight;
  }, 0);
  const weightSum = data.reduce((acc, item) => {
    return acc + item.weight;
  }, 0);
  return sum / weightSum;
}

export const getDeltaByEventTypes = (events, selectedEventTypes, inOrOutFlow = 'in') => {
  const inflowKeys = ['roc','rfc', 'ca'];
  const outflowKeys = ['ctr','oex', 'ca'];
  return events ? 
      Object.entries(events)
      .filter(([key,value]) => inOrOutFlow === 'in' ? inflowKeys.includes(key) : outflowKeys.includes(key))
      .reduce((accumulator, [key, value]) => {
          if (!selectedEventTypes.includes(key)) {
              accumulator += value;
          }
          return accumulator;
      }, 0) : 0;
};

export const getRelevantValueByEventTypes = (events, selectedEventTypes, inOrOutFlow = 'in') => {
  const inflowKeys = ['roc','rfc', 'ca'];
  const outflowKeys = ['ctr','oex', 'ca'];
  return events ? 
      Object.entries(events)
      .filter(([key,value]) => inOrOutFlow === 'in' ? inflowKeys.includes(key) : outflowKeys.includes(key))
      .reduce((accumulator, [key, value]) => {
          if (selectedEventTypes.includes(key)) {
              accumulator += value;
          }
          return accumulator;
      }, 0) * (inOrOutFlow === 'out' ? -1 : 1) : 0;
};