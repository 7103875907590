import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { floatFixed } from "../../../../../../../common/utils/number-display.utils";
import { isNullOrUndefinedOrEmptyString } from "../../../../../../../common/utils/object.utils";
import { isRequired, isRequiredAndNotZero } from "../../../../../../add-item/data/input-validations";
import { eventTypeGenerator } from "./createEventUtils";

const valueUpdateOnly = eventTypeGenerator(false, false, true);

export const cryptoEventTypeGenerator = (customRows = []) => {
  return ({ updateValue }) => ([
    {
      title: '',
      rows: [
        ...customRows.map(cr => typeof cr === 'function' ? cr({ updateValue }) : cr),
        { fields: [], type: 'separator' },
        { fields: [{ key: 'eventRemarks', type: 'textarea', label: 'Remarks', rows: 2, placeholder: 'Add notes to remember' }] }
      ]
    }
  ])
}

const pricePerCoinAttr = { fieldKey: 'newPricePerCoin', dataKey: 'pricePerCoin' };
const quantityAttr = { fieldKey: 'newQuantity', dataKey: 'quantity', setFnForSave: (isDistribution, value) => value * (isDistribution ? -1 : 1), getEditValueFn: ({ value }) => Math.abs(value) };

const pricePerCoinFieldFn = () => ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'newPricePerCoin', type: 'number', label: 'Price per coin',
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
          }
        },
        onBlur: ({ value, formValues }) => {
          // if (!isNullOrUndefinedOrZeroOrEmptyString(value) && formValues.transactionValue && isNullOrUndefinedOrZeroOrEmptyString(formValues.newQuantity)) {
          //   updateValue('newQuantity', formValues.transactionValue / value);
          // }
          if(formValues.hasOwnProperty('newQuantity') && !isNullOrUndefinedOrEmptyString(formValues.newQuantity) && !formValues.doNotUpdateTransactionValue){
            const trnVal =  floatFixed( value * formValues.newQuantity , 2);
            updateValue('transactionValue', trnVal > 0 ? trnVal : 0 );
          }
          // else if (!isNullOrUndefinedOrZeroOrEmptyString(value) && formValues.newQuantity && isNullOrUndefinedOrZeroOrEmptyString(formValues.transactionValue)) {
          //   updateValue('transactionValue', formValues.newQuantity * value);
          // }
        }
      }
    ]
  };
}

const costFieldFn = (label) => ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'transactionValue', type: 'number', label,
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.trnCurrency || values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.trnCurrency || values.currency] || '',
          }
        },
        onBlur: ({ value, formValues }) => {
          // if (!isNullOrUndefinedOrZeroOrEmptyString(value) && formValues.newPricePerCoin && isNullOrUndefinedOrZeroOrEmptyString(formValues.newQuantity)) {
          //   updateValue('newQuantity', value / formValues.newPricePerCoin);
          // }
          if (!isNullOrUndefinedOrEmptyString(value) && formValues.newQuantity && !isNullOrUndefinedOrEmptyString(formValues.newQuantity)){
              updateValue('newPricePerCoin', floatFixed( value / formValues.newQuantity , 2 ));
          }
        }
      }
    ]
  }
}



const quantityFieldFn = ({ updateValue, isDistribution, label }) => {
  return {
    fields: [
      {
        key: 'newQuantity', type: 'number', label,
        validations: [isRequiredAndNotZero],
        onBlur: ({ value, formValues }) => {

          if (!isNullOrUndefinedOrEmptyString(value)){
            //if(formValues.hasOwnProperty('transactionValue') && isNullOrUndefinedOrEmptyString(formValues.transactionValue)){
            if(!formValues.doNotUpdateTransactionValue && !isNullOrUndefinedOrEmptyString(formValues.newPricePerCoin) ){
              const trnVal =  floatFixed( value * formValues.newPricePerCoin , 2);
              updateValue('transactionValue', trnVal > 0 ? trnVal : 0 );
            }
            
            // if(formValues.hasOwnProperty('valueAtDate') ){ // && isNullOrUndefinedOrEmptyString(formValues.updatedValue)){
              const newVal = floatFixed( +(formValues.valueAtDate || 0) + ( value * (isDistribution ? -1 : 1) ) , 2)
              updateValue('updatedValue', newVal > 0 ? newVal : 0 );
            // }
          }


          // if (!isNullOrUndefinedOrZeroOrEmptyString(value) && formValues.transactionValue && isNullOrUndefinedOrZeroOrEmptyString(formValues.newPricePerCoin)) {
          //   updateValue('newPricePerCoin', formValues.transactionValue / value);
          // }
          // else if (!isNullOrUndefinedOrZeroOrEmptyString(value) && formValues.newPricePerCoin && isNullOrUndefinedOrZeroOrEmptyString(formValues.transactionValue)) {
          //   updateValue('transactionValue', value * formValues.newPricePerCoin);
          // }

          // if (!isNullOrUndefinedOrEmptyString(value) && !isNullOrUndefinedOrEmptyString(formValues.newPricePerCoin)) {
          //   if (formValues.hasOwnProperty('transactionValue') && isNullOrUndefinedOrEmptyString(formValues.transactionValue)) {
          //     const trnVal = (value * formValues.pricePerCoin);
          //     updateValue('transactionValue', trnVal * (isDistribution ? -1 : 1));
          //   }
          //   if (formValues.hasOwnProperty('updatedValue')) { // && isNullOrUndefinedOrEmptyString(formValues.updatedValue)){
          //     // console.log(formValues);
          //     let newVal = 0;
          //     if (isDistribution) { // e.g. Sell or Transfer
          //       if (isNullOrUndefinedOrEmptyString(formValues?.valueAtDate)) { // Edit event
          //         // newVal = (+formValues.valueAtDate || +formValues.value) + value * formValues.pricePerCoin;
          //         newVal = currency((formValues.valueAtDate || +formValues.value), { precision: 4 }).add(value);
          //       }
          //       else { // Create event
          //         // newVal = ((formValues?.totalQuantity - value) * formValues.pricePerCoin);
          //         newVal = currency(formValues?.valueAtDate, { precision: 4 }).subtract(value); // +formValues?.valueAtDate - value;
          //       }
          //     }
          //     else { // e.g. Buy
               
          //       if (isNullOrUndefinedOrEmptyString(formValues?.valueAtDate)) { // Edit event
          //         // newVal = value + (+formValues.valueAtDate || +formValues.value);
          //         newVal = currency((formValues.valueAtDate || +formValues.value), { precision: 4 }).add(value);
          //       }
          //       else { // Create event
          //         // newVal = +formValues?.valueAtDate + value;
          //         newVal = currency(formValues?.valueAtDate, { precision: 4 }).add(value);
          //       }
          //     }
          //     // const newVal = ( +formValues.valueAtDate || +formValues.value ) + ( (value + formValues?.totalNumberOfShares) * formValues.pricePerShare * (isDistribution ? -1 : 1));
          //     updateValue('updatedValue', newVal > 0 ? newVal : 0);
          //   }
          // }
        },
        setFnForSave: (isDistribution, value) => {
          // console.log(value,isDistribution);
          return value * (isDistribution ? -1 : 1)
        }
      },
      {
        key: 'coreCurrency', type: 'text', size: 0.2, disabled: true,
        defaultValueFn: (values)=>( values.cryptoDetails?.symbol),
      }
    ]
  }
}

const purchasedCryptoFnRow = (label, isContribution) => (props) => {
  return {
    fields: [
      ...quantityFieldFn({ ...props, label, isDistribution: !isContribution }).fields
    ]
  }
}

const visibleValueUpdateFn = ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated asset value',
        validations: [isRequired],
        showPrevComp: false,
        defaultValue: null,
        disabled:true,
        generatePropsFn: (values) => {
          return {
            holdings: values.holdings,
            decimalScale: 2,
          }
        },
      },
      {
        key: 'coreCurrency', type: 'text', size: 0.2, disabled: true,
        defaultValueFn: (values)=>( values.cryptoDetails?.symbol),
      }
    ]
  }
}

const pricePerCoinValueForEdit = {dataKey: 'newPricePerCoin' , itemDataKey:'cryptoLastPrice' , getEditValueFn: ({ value }) => parseFloat(value).toFixed(2) };
const cryptoDetails = {dataKey:'cryptoDetails', itemDataKey:'cryptoCoin'}

const noTransactionValue = () => {
  return {
  fields: [{
      key:'doNotUpdateTransactionValue', 
      isHidden:true,
      defaultValue:true
            // defaultValue:1,
  }]
  }
}

export const cryptoEvents = [
  { title: 'Buy (Purchase of coins)', eventType: 'Purchase of crypto coins',
    sections: cryptoEventTypeGenerator([pricePerCoinFieldFn(false), purchasedCryptoFnRow('Quantity', true), costFieldFn('Cost'), visibleValueUpdateFn]), isDistribution: false, 
    attributesToSend: [pricePerCoinAttr, quantityAttr],
    valuesForCreateEventFromItem:[pricePerCoinValueForEdit,cryptoDetails],
    valuesForEditFromItem:[cryptoDetails] },
  { title: 'Sell', eventType: 'Sale of crypto coins', 
    sections: cryptoEventTypeGenerator([pricePerCoinFieldFn(false), purchasedCryptoFnRow('Quantity', false), costFieldFn('Sell Price'), visibleValueUpdateFn]), isDistribution: true, 
    attributesToSend: [pricePerCoinAttr, quantityAttr] ,
    valuesForCreateEventFromItem:[pricePerCoinValueForEdit, cryptoDetails],
    valuesForEditFromItem:[cryptoDetails] },
  { 
    title: 'Deposit', eventType: 'Deposit of crypto coins', 
    sections: cryptoEventTypeGenerator([purchasedCryptoFnRow('Quantity', true), visibleValueUpdateFn,noTransactionValue]), isDistribution: false, 
    attributesToSend: [quantityAttr],
    valuesForCreateEventFromItem:[cryptoDetails],
    valuesForEditFromItem:[cryptoDetails] 
  },
  { title: 'Transfer', eventType: 'Transfer of crypto coins', 
    sections: cryptoEventTypeGenerator([purchasedCryptoFnRow('Quantity', false), visibleValueUpdateFn,noTransactionValue]), isDistribution: true, 
    attributesToSend: [quantityAttr],
    valuesForCreateEventFromItem:[cryptoDetails],
    valuesForEditFromItem:[cryptoDetails] 
  },
  { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: cryptoEventTypeGenerator([pricePerCoinFieldFn(false), purchasedCryptoFnRow('Quantity', true), costFieldFn(false)]), isDistribution: false, attributesToSend: [pricePerCoinAttr, quantityAttr], deleteEnable: false,  valuesForEditFromItem: [{dataKey:'cryptoDetails', itemDataKey:'cryptoCoin'}] },
]