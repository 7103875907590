import React from 'react';
import { ToastContainer , Slide } from 'react-toastify';
import { NotificationsContainerWrapper } from './notifications.styles';


export function NotificationsWrapper({isAuthenticated}) {

  return (
    <NotificationsContainerWrapper>
        <ToastContainer 
            position="bottom-center" 
            transition={Slide}
            className={isAuthenticated ? 'app-notifications-wrapper' : 'app-notifications-wrapper-login-layout'}
            toastClassName="app-notification"
            hideProgressBar={true} 
            closeButton={false}
            autoClose={3000}
            theme="dark"
        />
    </NotificationsContainerWrapper>
  );
}
