import { getTranslations } from "../../../../common/translations/translations";
import { isNullOrUndefined } from "../../../../common/utils/object.utils";

const { asset_fields } = getTranslations().common;



export const customClassAndSubClassRows = ({ updateField, updateValue,  validations = []  }) => (
  [{
  fields: [
    {
      key: 'customClassId', type: 'custom-class',
      label: asset_fields.CUSTOM_CLASS_LABEL,
      description:asset_fields.CUSTOM_CLASS_DESCRIPTION,
      allowClear: true,
      withSuffixIcon: true,
      
      setFnForSave: (isDistribution, value, formValues) => (!value ) ? null : value,
      ...(validations.length > 0 ? {
        validations
      } : {}),
      onChange: ({value, formValues}) => {
        updateValue('customSubClassId', '');
        updateField('customSubClassId', {customClassId: value , disabled: value ? false : true});
        
      }
    }
  ]
  },
  {
    fields: [
      {
        key: 'customSubClassId', type: 'custom-sub-class',
        label: asset_fields.CUSTOM_SUB_CLASS_LABEL,
        description:asset_fields.CUSTOM_SUB_CLASS_DESCRIPTION,
        allowClear: true,
        withSuffixIcon: true,    
        setFnForSave: (isDistribution, value, formValues) => (value === '') ? null : value,
        ...(validations.length > 0 ? {
          validations
        } : {}),
        generatePropsFn: (formValues) => {
          return { disabled: isNullOrUndefined(formValues?.customClassId) , customClassId: formValues?.customClassId}
        },
        onChange: ({value, formValues}) => {
        }
      }
    ]
  }
])