import styled from '@emotion/styled/macro';
import { colors, shadows, views } from '../../../../common/styles/theme.styles';

export const Wrapper = styled.div`
    
    background-color: ${colors.darkHeroBackground};
    /* Card Shadow */
    box-shadow: ${shadows.cardShadow};
    border: 1px solid #DDE3EB;
    border-radius: 8px 8px 0px 0px;
    // padding:62px;
    display:flex;
    flex-direction:column;
    // margin-bottom:16px;
    ${views.desktop}{
        height:400px;
    }
    ${views.tablet}{
        border:none;
        border-radius: 0px;
    }
`

export const Title = styled.div`
    font-size: 24px;
    line-height:29px;
    font-weight:500;
    color:#FFFFFF;
    margin:0 0 8px 0;
`;



