import styled from '@emotion/styled/macro'
import { colors, views } from '../../../../common/styles/theme.styles';
import { flexCenter } from '../../../../common/styles/combos.styles';

const pageBlackColor = '#26282C';

export const PortfoliosWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 3;
    overflow-y: auto;  
    height:100%;
    background-color: #fff;
    grid-area: content;
    ${views.tablet} {
        min-height: 100vh;
    }
`

export const PortfoliosCenterContent = styled.div`
    width: 100%;
    max-width: 424px;
    align-items: center;
    justify-content: center;
    padding: 36px 16px;
    margin: 0 auto;
    flex: 1;
    display: flex;
    flex-direction: column;  

    ${views.tablet} {
        padding: 36px 24px;
        justify-content: center;
    }
  
`;


export const PortfoliosTitle = styled.div`
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    font-weight:700;
    color: ${pageBlackColor};
`;

export const PortfoliosSubTitle = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: ${pageBlackColor};
    margin-bottom: 32px;
`;

export const PortfoliosListWrapper = styled.div`
    display: flex;  
    flex-direction: column;
    gap:8px;
    width: 100%;
    margin-bottom: 24px;
`;

export const PortfolioItemWrapper = styled.div`
    ${flexCenter}
    cursor: pointer;
    justify-content: flex-start;
    border-radius: 8px;
    padding:16px;
    border: 1px solid ${props=>props.isSelected ? colors.darkGray1 : '#fff'};
    background-color: #fff;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.25), 0px 1px 6px 0px rgba(0, 0, 0, 0.04);
`;

export const PortfolioItemSelectedCircle = styled.div`
    border:1px solid ${props=>props.isSelected ? '#1A1B1D' : colors.gray3 };
    ${flexCenter};
    height: 12px;
    width: 12px;
    min-width: 12px;
    border-radius: 50%;
    ${props => props.isSelected ? `
        :after{
            content: '';
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: #1A1B1D;
        }
    ` : ``}
`;

export const RolesNameIconWrapper = styled.div`
    ${flexCenter}
    width: 30px;
    min-width: 30px;
    height:30px;
    border-radius: 10px;
    margin:0 12px 0 16px;
    color:${props=>props.isOwner ? '#fff' : colors.darkGray1 };
    background-color:${props=>props.isOwner ? '#111213' : props.color};
`

export const NameAndInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:2px;
    overflow: hidden;
`;

export const NameText = styled.div`
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color:${pageBlackColor};
    word-wrap: break-word;
    overflow: hidden;
    max-width: 100%;
`;
export const MoreInfo = styled.div`
    font-size: 13px;
    line-height:13px;
    font-weight: 450;
    color:${colors.darkGray2};
`;

export const CreatePortfolioMessage = styled.div`
    font-size: 13px;
    font-weight: 450;
    color: ${colors.darkGray1};
    padding-bottom: 32px;
    margin-top: 48px;
`;

export const LinkSpan = styled.span`
    cursor: pointer;
    text-decoration: underline;
    color: #2565E7;
`;

export const EmptyStateWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color: ${colors.lightGray1};
`;

export const EmptyStateText = styled.div`
        color: ${colors.darkGray2};
        font-size: 13px;
        line-height: 14px;
`;

export const MobileUserMenuWrapper = styled.div`
    position: fixed;
    bottom: 24px;
    left: 18px;
    .userMenuUserButtonWrapper{
        background-color: #000;
    }
    ${props=>props.isFullPage ? `
    .profile-image {
        background-color: ${colors.darkGray2};
    }
    `: ``}
`;