import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import './LogoLoader.css';
import loaderFile from '../Loaders/app-loader-lottie.json';

const Logo = ({logoWidth}) => {
	return (
		// <svg className="vyzer-logo-loader" width={logoWidth} viewBox="0 0 145 130" fill="none" xmlns="http://www.w3.org/2000/svg">
		// 	<rect width="145" height="130" fill="transparent" />
		// 	<path className="path1" d="M12 12L72 119L83.3422 98.7705L34.6845 12H12Z" fill="currentColor" />
		// 	<path className="path2" d="M83.3422 58.3115L94.6845 78.541L132 12H57.3689L68.5847 32.0174H98.0998L83.3422 58.3115Z" fill="currentColor" />
		// </svg>
		<Player
    // ref={lottieRef}
    autoplay={true}
    loop={true}
    keepLastFrame={false}
    src={loaderFile}
    speed={1}
    style={{width:'72px' , height: '72[x'}}
    ></Player>
	)
}

export function LogoLoader({ logoWidth = 100, wbg, backdrop = false , absolute = false }) {
	return (
		<>
			{backdrop === true ? <div className={"loader-back-drop" + ( absolute ? ' absolute-pos' : '' ) } ><Logo logoWidth={logoWidth} /></div> : <Logo />}
		</>
	)

}