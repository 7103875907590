import { Observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { CommonBadge } from '../../../common/components/CommonBadge/CommonBadge'
import { LimitationModal } from '../../../common/components/LimitationModal/LimitationModal'
import { CommunityLimitation } from '../../../common/components/LimitationModal/icons/limitation-icons'
import { PrimaryButtonWithIcon } from '../../../common/components/buttons/NewButtons.styles'
import { useStore } from '../../app/data/root.store'
import { AppHeader } from '../../layout/components/Header/Header'
import { PageWrapper, RightSidePushMenuWrapper } from '../../layout/components/Layout.styles'
import { BoxArrowIcon, CommunityIcon, CompareAssetAllocationIcon, LeveragePortfoliosIcon, TopInstitutionsIcon } from '../icons/community-page-icons'
import CommunityBodyImage from '../images/community-body.jpg'
import CompareAssetAllocationImage from '../images/compare-asset-allocation.jpg'
import LeverageImage from '../images/leverage-hover.jpg'
import TopInstitutionsImage from '../images/top-institutions.jpg'
import { ActionButtonText, ActionButtonWrapper, CommunityPageBody, CommunityPageBox, CommunityPageBoxArrow, CommunityPageBoxHoverEffect, CommunityPageBoxText, CommunityPageBoxTextAndIconWrapper, CommunityPageBoxesWrapper, CommunityPageHeader, HeaderAltText, HeaderTitle, HoverEffectTitle, LimitedTierContentText, LimitedTierContentTitle, LimitedTierContentWrapper, MobileHeaderTitle } from './LimitedCommunityPage.styles'
import { MobileHeader } from '../../magic-box/components/MagicBox.styles'
import { MobileBackButtonWrapper } from '../../layout/components/MobileLayout.styles'
import { MobileBackButton } from '../../../assets/icons/common/common-icons'

export const LimitedCommunityPage = () => {
    const { billingStore , userStore , uiStore } = useStore();

    useEffect(() => {
        uiStore.setIsRightMenuOpen(false);
        // return () => {}
      }, [uiStore])
      
    const handleUpgradeTierClick = async () => {
        // const paymentLink = await billingStore.fetchBillingUrl()
        billingStore.setShowPricingTableModal(true);   
    }

    return (<Observer>{() => (
        <>
        {uiStore.isDesktopView ? <>
        <AppHeader />
        <RightSidePushMenuWrapper /></>:
        <MobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}> 
            <MobileBackButtonWrapper onClick={()=>{uiStore.setIsAppMobileMenuOpen(true)}}><MobileBackButton /></MobileBackButtonWrapper>
            <MobileHeaderTitle>Community</MobileHeaderTitle>
        </MobileHeader> 
        }
        <PageWrapper withHeader style={{ flexDirection: 'column', justifyContent: 'normal', alignItems: 'center' }}>
        { userStore.isDemoMode && <CommunityPageHeader>
                <CommunityIcon />
                <HeaderTitle>Learn from Vyzer’s Investor Community</HeaderTitle>
                <HeaderAltText>Strategize your investments with aggregated insights from other experts. </HeaderAltText>
            </CommunityPageHeader>}
            <CommunityPageBoxesWrapper style={{marginBottom:0}}>
                <CommunityPageBox>
                    <CommunityPageBoxTextAndIconWrapper>
                        <LeveragePortfoliosIcon />
                        <CommunityPageBoxText>Leverage high net<br />worth portfolios</CommunityPageBoxText>
                    </CommunityPageBoxTextAndIconWrapper>
                    <CommunityPageBoxArrow><BoxArrowIcon /></CommunityPageBoxArrow>
                    <CommunityPageBoxHoverEffect>
                        <HoverEffectTitle>Leverage successful portfolios so you can make smarter investments.</HoverEffectTitle>
                        <img src={LeverageImage} alt='leverage' />
                    </CommunityPageBoxHoverEffect>
                </CommunityPageBox>
                <CommunityPageBox>
                    <CommunityPageBoxTextAndIconWrapper>
                    <TopInstitutionsIcon />
                    <CommunityPageBoxText>Top institutions<br />invested in 2022</CommunityPageBoxText>
                    </CommunityPageBoxTextAndIconWrapper>
                    <CommunityPageBoxArrow><BoxArrowIcon /></CommunityPageBoxArrow>
                    <CommunityPageBoxHoverEffect>
                        <HoverEffectTitle>See trustworthy institutions in specific asset classes.</HoverEffectTitle>
                        <img src={TopInstitutionsImage} alt='top-institutions' />
                    </CommunityPageBoxHoverEffect>
                </CommunityPageBox>
                <CommunityPageBox>
                <CommunityPageBoxTextAndIconWrapper>
                    <CompareAssetAllocationIcon />
                    <CommunityPageBoxText>Compare<br />asset allocation</CommunityPageBoxText>
                    </CommunityPageBoxTextAndIconWrapper>
                    <CommunityPageBoxArrow><BoxArrowIcon /></CommunityPageBoxArrow>
                    <CommunityPageBoxHoverEffect>
                        <HoverEffectTitle>Compare your assets with investors who have higher net worth portfolios.</HoverEffectTitle>
                        <img src={CompareAssetAllocationImage} alt='compare-asset-allocation' />
                    </CommunityPageBoxHoverEffect>
                </CommunityPageBox>
            </CommunityPageBoxesWrapper>
            <CommunityPageBody>
                <img src={CommunityBodyImage} alt='community-body' />
                { userStore.isFreeTier && uiStore.isDesktopView && <div style={{position:'absolute' , top: '14px' , left: '138px'}}><CommonBadge noMargin badgeType="limitedFeature" title={`Upgrade`} rounded  clickable onClick={()=>{ billingStore.setShowPricingTableModal(true)}}  /></div>}
                { userStore.isDemoMode ? 
                <ActionButtonWrapper onClick={() => { billingStore.setShowPricingTableModal(true) }}>
                    <ActionButtonText>Explore Vyzer’s Community</ActionButtonText>
                </ActionButtonWrapper> : <LimitedTierContentWrapper>
                    {uiStore.isDesktopView && <>
                    <CommunityLimitation />
                    <LimitedTierContentTitle>Community Benchmarking</LimitedTierContentTitle>
                    <LimitedTierContentText>Learn from Vyzer’s Investor Community. Strategize your investments with aggregated insights from other experts. </LimitedTierContentText>
                    </>}
                    <PrimaryButtonWithIcon style={{minWidth:'180px'}} onClick={() => { billingStore.setShowPricingTableModal(true)}} noStroke><MembershipSvg />{userStore.isReadOnlyMode ? 'Unlock with membership' : 'Upgrade'}</PrimaryButtonWithIcon>
                </LimitedTierContentWrapper>}
            </CommunityPageBody>
        </PageWrapper>
        {userStore.data?.profile?.showFreeTierCommunityPopup && <LimitationModal modalType={'community'} onUpgrade={()=>{handleUpgradeTierClick()}} onClose={() => { userStore.closeFreeTierCommunityModal() }} />}
        </>
    )}</Observer>)
}

const MembershipSvg = () => {
    return(
      <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.96577 11.5653L4.6875 7.38002V16.3086H12.5H20.3125V7.38002L16.0342 11.5653L12.5 4.58984L8.96577 11.5653Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.5312 19.8438H5.46875V17.9688H19.5312V19.8438Z" fill="white"/>
      </svg>
    )
  }