import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { isRequired, isRequiredAndNotZero } from "../../../../../../add-item/data/input-validations";
import { eventTypeGenerator } from "./createEventUtils";
import { PlusOutlined } from '@ant-design/icons';
import { AddValueUpdatePrevButton } from "../CreateEvent.styles";
import { OnOfCapitalTooltip } from "../../../../../../../common/components/Tooltips/OnOfCapitalTooltip/OnOfCapitalTooltip";
import { isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrZeroOrEmptyString } from "../../../../../../../common/utils/object.utils";
import { createLabelFnWithPredictedValue } from "../../Events/PredictedAmountDisplay/PredictedAmountDisplay";

const valueUpdateOnly = eventTypeGenerator(false, false, true);
const contributionOnly = eventTypeGenerator(true);
const distributionOnly = eventTypeGenerator(false, true);
const contributionAndValueUpdate = eventTypeGenerator(true, false, true);
const distributionAndValueUpdate = eventTypeGenerator(false, true, true);
const itemCreatedValueUpdate = eventTypeGenerator(true, false, true);

export const capitalCallsSections = (fieldRows = []) => {
  return ({updateValue}) => ([
      {
          title: '',
          rows: [
              ...fieldRows.map(fr=> typeof fr === 'function' ? fr({updateValue}) : fr),
              {fields:[],type:'separator'},
              {fields: [{key:'eventRemarks', type: 'textarea', label: 'Remarks' , rows:2 , placeholder:'Add notes to remember'}]}
          ]
      }
  ])
}

const contributionValueFn = ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'transactionValue', type: 'numberWithHoldings', label: 'Contribution value',
        validations: [isRequiredAndNotZero],
        generatePropsFn: (values) => {
          const labelFn = createLabelFnWithPredictedValue('Contribution value',values.eventPredictedAmount,`The expected contribution amount`)
          return {
            label: labelFn,
            prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
            holdings: values.holdings,
            decimalScale: 2,
          }
        },
        onBlur: ({ value, formValues }) => {
          // if(formValues.hasOwnProperty('updatedValue')){
          const newVal = (+formValues.valueAtDate || +formValues.value) + value;
          updateValue('updatedValue', newVal > 0 ? newVal : 0);
          // }
        }
      }
    ]
  }
}

const realEstateDividendSections = ( isManualReInvest , isValueUpdateOpenByDefault , hideOnOfCapital ) => ({updateValue, updateField}) => {
  return [
    {
      title: '',
      rows: [
        {fields:[
          {   
              key:'transactionValue', type: 'numberWithHoldings', label: 'Distribution value', 
              validations:[isRequired],
              defaultValue:null,
              generatePropsFn : (values)=>{
                const labelFn = createLabelFnWithPredictedValue('Distribution value',values.eventPredictedAmount,`The expected distribution amount`)
                  return {
                      label:labelFn,
                      holdings: values.holdings,
                      prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      decimalScale : 2,
                      disabled: (values.onCapital || values.ofCapital),
                      isHidden: values.isTransactionLinked,
                  }
              },
              onChange: ({value}) => {
                updateField('isDistributionOfCapital', {disabled: isNullOrUndefinedOrZeroOrEmptyString(value)});
              },
          },  
          ...( isManualReInvest ?  [{
            key:'isDistributionReinvested', 
            isHidden:true,
            // defaultValue:1,
            defaultValueFn:(values)=>1
          }] : [  
          ] )  
        ]},
        {fields:[
          {   
              key:'contributionTransactionValue', type: 'numberWithHoldings', label:  'Contribution value (reinvested)', 
              validations:[],
              defaultValueFn: (values) => values.eventId ? ( values.allTransactions?.length ? Math.abs(values.allTransactions[1].value) : null ) : values.transactionValue,
              generatePropsFn : (values)=>{
                  return {
                      isHidden:  !(isManualReInvest || (values.distributionReinvested && !values.eventId) || values.allTransactions?.length),
                      holdings: values.holdings,
                      prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      decimalScale : 2, 
                  }
              },
            }
        ]},

        ...(!hideOnOfCapital ? [

        {fields:
          [{type:'checkbox', key:'isDistributionOfCapital',checkboxLabel: 'This distribution is a return OF capital ', description: 'The distribution displays Return ON Capital (profit) by default. Check this box to view it as Return OF Capital.' ,
          defaultValueFn: (values) => {return ( values.onCapital || values.ofCapital ) ? true : false},
          generatePropsFn : (values)=>{
            return {
                isHidden: values.allTransactions?.length || isManualReInvest || ( values.isEditEvent && values.contributionTransactionValue),
                disabled: isNullOrUndefinedOrZeroOrEmptyString(values.transactionValue),
                fieldCustomStyle: {marginTop: values.isTransactionLinked ? '' : '-24px'},
            }
          },
          onChange: ({value,formValues})=>{
              let distributionFieldTitle = 'Distribution amount';
              if (value){ 
                // show on / of capital fields
                distributionFieldTitle = 'Total distribution amount';
                updateValue('ofCapital',formValues.transactionValue || 0);
                updateValue('updatedValue', +formValues.valueAtDate - ( formValues.transactionValue || 0 ));
                
              } else {
                // hide on / of capital fields
                updateValue('onCapital',null);
                updateValue('ofCapital',null);
                if ( !isValueUpdateOpenByDefault ){
                  updateValue('updatedValue',null);
                }
                
              }
              const isTransactionValueShouldBeDisabled = value || formValues.isTransactionLinked;
              updateField('transactionValue',{disabled:isTransactionValueShouldBeDisabled , label: distributionFieldTitle })
              updateField('onCapital',{isHidden:!value});
              updateField('ofCapital',{isHidden:!value});
              if ( !isValueUpdateOpenByDefault ){
                updateField('updatedValue',{isHidden:!value});
              }
          }
        }]},
      
      {
        fields: [
          {
            key: 'onCapital', type: 'numberWithHoldings', description: <OnOfCapitalTooltip />, 
            tooltipOverlayCustomInnerStyle: {minWidth:'370px', backgroundColor: 'rgba(0, 0, 0, 0.90)'},
            size: 0.5,
            decimalScale: 2,
            label: `Return ON capital`,
            generatePropsFn: (values) => {
              return {
                isHidden: !(values.isEditEvent && values.ofCapital),
                prefix: CURRENCY_SYMBOL[values.currency] || '',
                placeholder: CURRENCY_SYMBOL[values.currency] || '',
                holdings: values.holdings,
              }
            },
            onBlur: ({ value, formValues }) => {
              const onCapitalVal = isNullOrUndefinedOrEmptyString(value) ? 0 : value;
              const ofCapitalVal = isNullOrUndefinedOrEmptyString(formValues.ofCapital) ? 0 : +formValues.ofCapital;
              if (onCapitalVal <= formValues.transactionValue && ofCapitalVal <= formValues.transactionValue){
                const newOfCapitalVal  = formValues.transactionValue - onCapitalVal;
                updateValue('ofCapital', newOfCapitalVal);
                updateValue('updatedValue', +formValues.valueAtDate - newOfCapitalVal);
              } 
            },
            validations: [{
              fn: (value, formValues) => { return validateTransactionValueWithOnOfCapital(formValues) },
              message:' ',
            }],
            validateFieldsOnChange: ['validationOfOnCapital','ofCapital'],
            showValidationErrorOnBlur: true,
            validateFieldsOnBlur: ['validationOfOnCapital','ofCapital'],
          },
          {
            key: 'ofCapital', type: 'numberWithHoldings', label: 'Return OF capital',
            size: 0.5,
            decimalScale: 2,
            generatePropsFn: (values) => {
              return {
                isHidden:  !(values.isEditEvent && values.ofCapital),
                prefix: CURRENCY_SYMBOL[values.currency] || '',
                placeholder: CURRENCY_SYMBOL[values.currency] || '',
                holdings: values.holdings,
              }
            },
            onBlur: ({ value, formValues }) => {
              const ofCapitalVal = isNullOrUndefinedOrEmptyString(value) ? 0 : value;
              const onCapitalVal = isNullOrUndefinedOrEmptyString(formValues.onCapital) ? 0 : +formValues.onCapital;
              if (ofCapitalVal <= formValues.transactionValue && onCapitalVal <= formValues.transactionValue){
                const newOnCapitalVal  = formValues.transactionValue - ofCapitalVal;
                updateValue('onCapital', newOnCapitalVal);
                updateValue('updatedValue', +formValues.valueAtDate - ofCapitalVal);
              } 
            },
            validateFieldsOnChange: ['validationOfOnCapital','onCapital'],
            showValidationErrorOnBlur: true,
            validateFieldsOnBlur: ['validationOfOnCapital','onCapital'],
            
            validations: [{
              fn: (value, formValues) => {return validateTransactionValueWithOnOfCapital(formValues)},
              message:' ',
            },],
          }
        ],
       
      },
      {
        fields:[
          {key : 'validationOfOnCapital' ,  type : 'validation' , fieldCustomStyle: {marginTop:'-24px'},
          validations: [
            {
              fn: (value, formValues) => {
                return validateTransactionValueWithOnOfCapital(formValues);
              },
              bottomErrorMessage: (value, formValues) => {
                return `Invalid amount. The sum of 'return On capital' and 'Of capital' must be equal to the ${formValues.isTransactionLinked ? 'Linked transaction' : 'Total distribution'} amount.`;
              },
            },
          ], } , 
          {key: 'isTransactionLinked' , isHidden:true,
            onChange: ({value,formValues})=>{
              updateField('transactionValue', {isHidden: value})
              updateField('isDistributionOfCapital', {fieldCustomStyle: {marginTop: value ? '' : '-24px'},})
              if (formValues.isDistributionOfCapital){
                updateField('transactionValue',{disabled:true })
                if (value) {
                  updateValue('ofCapital', formValues.transactionValue)
                  updateValue('onCapital', null)
                  updateValue('updatedValue', +formValues.valueAtDate - formValues.transactionValue)
                }
              }
            },
            validateFieldsOnChange: ['validationOfOnCapital'],
          }
        ]
      },
    ] : []),
        {fields: [
            {
              key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated asset value',
              showPrevComp: false,
              defaultValueFn: (values) => { return (isManualReInvest || (values.distributionReinvested && !values.eventId)) ? ( values.eventId ? values.updatedValue : +values.valueAtDate + values.transactionValue) : null},
              // defaultValueFn: (values) => 10,
              generatePropsFn: (values) => {
                return {
                  isHidden: !(isManualReInvest || (values.distributionReinvested && !values.eventId) || values.ofCapital || values.updatedValue  || isValueUpdateOpenByDefault),
                  prefix: CURRENCY_SYMBOL[values.currency] || '',
                  placeholder: CURRENCY_SYMBOL[values.currency] || '',
                  holdings: values.holdings,
                  decimalScale: 2,
                }
              },
            }
          ]
        },
        // ...customRows.map(cr => typeof cr === 'function' ? cr({ updateValue }) : cr),
        {fields:[],type:'separator'},
        {fields: [{key:'eventRemarks', type: 'textarea', label: 'Remarks' , rows:2 , placeholder:'Add notes to remember'}]},
        
      ]
    }
]};

const exitEventSections = (fieldRows = []) => ({updateValue, updateField}) => {
  return [
    {
      title: '',
      rows: [
        {fields:[
          {   
              key:'transactionValue', type: 'numberWithHoldings', label: 'Total distribution amount', 
              validations:[isRequired],
              defaultValue:null,
              generatePropsFn : (values)=>{
                const labelFn = createLabelFnWithPredictedValue('Total distribution amount',values.eventPredictedAmount,`The expected distribution amount`)
                  return {
                      label:labelFn,
                      holdings: values.holdings,
                      prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                      decimalScale : 2,
                      // disabled: (values.onCapital || values.ofCapital),
                      isHidden: values.isTransactionLinked,
                  }
              },
              onChange: ({value}) => {
                updateField('isDistributionOfCapital', {disabled: isNullOrUndefinedOrZeroOrEmptyString(value)});
              },
          },    
        ]},
        {fields:
          [{type:'checkbox', key:'isDistributionOfCapital',checkboxLabel: 'This distribution is a return OF capital ', description: 'The distribution displays Return ON Capital (profit) by default. Check this box to view it as Return OF Capital.' ,
          defaultValue: true,
          generatePropsFn : (values)=>{
            return {
                isHidden: true,
            }
          },
      }]},
      {
        fields: [
          {
            key: 'onCapital', type: 'numberWithHoldings', description: <OnOfCapitalTooltip />, 
            tooltipOverlayCustomInnerStyle: {minWidth:'370px', backgroundColor: 'rgba(0, 0, 0, 0.90)'},
            size: 0.5,
            decimalScale: 2,
            label: `Return ON capital`,
            generatePropsFn: (values) => {
              return {
                // isHidden: !values.isEditEvent,
                prefix: CURRENCY_SYMBOL[values.currency] || '',
                placeholder: CURRENCY_SYMBOL[values.currency] || '',
                holdings: values.holdings,
              }
            },
            onBlur: ({ value, formValues }) => {
              const onCapitalVal = isNullOrUndefinedOrEmptyString(value) ? 0 : value;
              const ofCapitalVal = isNullOrUndefinedOrEmptyString(formValues.ofCapital) ? 0 : +formValues.ofCapital;
              if (onCapitalVal <= formValues.transactionValue && ofCapitalVal <= formValues.transactionValue){
                const newOfCapitalVal  = formValues.transactionValue - onCapitalVal;
                updateValue('ofCapital', newOfCapitalVal);
                updateValue('updatedValue', +formValues.valueAtDate - newOfCapitalVal);
              } 
            },
            validations: [{
              fn: (value, formValues) => { return validateTransactionValueWithOnOfCapital(formValues) },
              message:' ',
            }],
            validateFieldsOnChange: ['validationOfOnCapital','ofCapital'],
            showValidationErrorOnBlur: true,
            validateFieldsOnBlur: ['validationOfOnCapital','ofCapital'],
          },
          {
            key: 'ofCapital', type: 'numberWithHoldings', label: 'Return OF capital',
            size: 0.5,
            decimalScale: 2,
            defaultValueFn: (values) => { return values.transactionValue || null },
            generatePropsFn: (values) => {
              return {
                // isHidden: !values.isEditEvent,
                prefix: CURRENCY_SYMBOL[values.currency] || '',
                placeholder: CURRENCY_SYMBOL[values.currency] || '',
                holdings: values.holdings,
              }
            },
            onBlur: ({ value, formValues }) => {
              const ofCapitalVal = isNullOrUndefinedOrEmptyString(value) ? 0 : value;
              const onCapitalVal = isNullOrUndefinedOrEmptyString(formValues.onCapital) ? 0 : +formValues.onCapital;
              if (ofCapitalVal <= formValues.transactionValue && onCapitalVal <= formValues.transactionValue){
                const newOnCapitalVal  = formValues.transactionValue - ofCapitalVal;
                updateValue('onCapital', newOnCapitalVal);
                const newUpdatedValue = +formValues.valueAtDate - ofCapitalVal > 0 ? +formValues.valueAtDate - ofCapitalVal : 0;
                updateValue('updatedValue', newUpdatedValue);
              } 
            },
            validateFieldsOnChange: ['validationOfOnCapital','onCapital'],
            showValidationErrorOnBlur: true,
            validateFieldsOnBlur: ['validationOfOnCapital','onCapital'],
            
            validations: [{
              fn: (value, formValues) => {return validateTransactionValueWithOnOfCapital(formValues)},
              message:' ',
            },],
          }
        ],
       
      },
      {
        fields:[
          {key : 'validationOfOnCapital' ,  type : 'validation' , fieldCustomStyle: {marginTop:'-24px'},
          validations: [
            {
              fn: (value, formValues) => {
                return validateTransactionValueWithOnOfCapital(formValues);
              },
              bottomErrorMessage: (value, formValues) => {
                return `Invalid amount. The sum of 'return On capital' and 'Of capital' must be equal to the ${formValues.isTransactionLinked ? 'Linked transaction' : 'Total distribution'} amount.`;
              },
            },
          ], } , 
          {key: 'isTransactionLinked' , isHidden:true,
            onChange: ({value,formValues})=>{
              updateField('transactionValue', {isHidden: value})
              // if (formValues.isDistributionOfCapital){
                // updateField('transactionValue',{disabled:true })
                if (value) {
                  updateValue('ofCapital', formValues.transactionValue)
                  updateValue('onCapital', null)
                  const newUpdatedValue = +formValues.valueAtDate - formValues.transactionValue > 0 ? +formValues.valueAtDate - formValues.transactionValue : 0;
                  updateValue('updatedValue', newUpdatedValue);
                }
              // }
            },
            validateFieldsOnChange: ['validationOfOnCapital'],
          }
        ]
      },

        ...fieldRows.map(fr=> typeof fr === 'function' ? fr({updateValue}) : fr),
        {fields:[],type:'separator'},
        {fields: [{key:'eventRemarks', type: 'textarea', label: 'Remarks' , rows:2 , placeholder:'Add notes to remember'}]},
        
      ]
    }
]};

const valueUpdateFn = (isPredicted) => ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated asset value',
        validations: [],
        showPrevComp: true,
        ClickToShowComponent: ({ show }) => (<AddValueUpdatePrevButton onClick={show}><PlusOutlined /> Add Change in value</AddValueUpdatePrevButton>),
        defaultValueFn:(values) => (+values.valueAtDate + values.transactionValue),
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            holdings: values.holdings,
            decimalScale: 2,
            showPrevComp: values.eventId || !isPredicted ? true : false
          }
        }
      }
    ]
  }
}

const onCapitalAttr = { fieldKey: 'onCapital', dataKey: 'onCapital' };
const ofCapitalAttr = { fieldKey: 'ofCapital', dataKey: 'ofCapital' };

const saveDividendTrns = (eventDate, formValues, eventTransactions) => {
  return (formValues.distributionReinvested || formValues.isDistributionReinvested) && formValues.contributionTransactionValue ? [
    {
      date: eventDate,
      value:  Math.abs(formValues.contributionTransactionValue) * -1,
      ...(eventTransactions && eventTransactions.length > 1 ? {
        id: eventTransactions[1].id
      } : {})
    }
  ] : [];
}

export const realEstateRentalSoleOwnershipEvents = [
  { title: 'Rent received', sections: distributionOnly, isDistribution: true },
  { title: 'Maintenance', sections: contributionOnly },
  { title: 'General expenses', sections: contributionOnly },
  { title: 'Property value update', sections: valueUpdateOnly },
  { title:'Expenses', eventType:'Contribution', isHidden: true,sections:contributionOnly },
  { title: 'Annual revenue',  eventType: 'Annual revenue', isPredicted: true, sections: realEstateDividendSections(false,false,true), isDistribution: true },
  { title: 'Monthly rent', isPredicted: true, sections: distributionOnly, isDistribution: true },
  { title: 'Monthly expenses', isPredicted: true, sections: contributionOnly },
  { title: 'Sale of property', sections: distributionAndValueUpdate, isDistribution: true },
  { title: 'End date', isPredicted: true, sections: distributionAndValueUpdate, isDistribution: true },
  { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false },
]

export const realEstateRentalWithPartnersEvents = [
  { title: 'Distribution received', sections: realEstateDividendSections(false), attributesToSend: [onCapitalAttr, ofCapitalAttr], isDistribution: true },
  { title:'Distribution reinvested',  isHidden: true, eventType: 'Annual dividend', sections: realEstateDividendSections(true), valuesForEditFromItem: [{dataKey:'distributionReinvested', itemDataKey:'distributionReinvested'}], isDistribution: true , otherTrnsFn: saveDividendTrns},
  { title: 'More money invested', sections: contributionAndValueUpdate },
  { title: 'Property value update', sections: valueUpdateOnly },
  { title: 'Refinance', sections: realEstateDividendSections(false,true), attributesToSend: [onCapitalAttr, ofCapitalAttr], isDistribution: true },
  { title: 'Sale of property', sections: distributionAndValueUpdate, isDistribution: true , isHidden: true },  
  { title: 'Exit event', sections: exitEventSections([valueUpdateFn(false)]), attributesToSend: [onCapitalAttr, ofCapitalAttr], isDistribution: true , assetInfoLabelId: 'expected-exit-event'},
  { title: 'Annual revenue',  eventType: 'Annual revenue', isPredicted: true, sections: realEstateDividendSections(false), 
    valuesForEditFromItem: [{dataKey:'distributionReinvested', itemDataKey:'distributionReinvested'}],
    attributesToSend: [onCapitalAttr, ofCapitalAttr], 
    isDistribution: true 
  },
  { title: 'End date', isPredicted: true, sections: distributionAndValueUpdate, isDistribution: true },
  { title: 'Capital call contribution', isPredicted: true, sections: capitalCallsSections([contributionValueFn, valueUpdateFn(true)])},
  { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false },
]

export const realEstateDevelopmentSoleOwnershipEvents = [
  { title: 'Renovation', sections: contributionAndValueUpdate },
  { title: 'Maintenance', sections: contributionOnly },
  { title: 'General expenses', sections: contributionOnly },
  { title: 'Property value update', sections: valueUpdateOnly },
  { title: 'Sale of property', sections: distributionAndValueUpdate, isDistribution: true },
  { title: 'End date', isPredicted: true, sections: distributionAndValueUpdate, isDistribution: true },
  { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false },
]

export const realEstateDevelopmentWithPartnersEvents = [
  { title: 'Distribution received', sections: realEstateDividendSections(false), attributesToSend: [onCapitalAttr, ofCapitalAttr], isDistribution: true },
  { title:'Distribution reinvested', isHidden: true, eventType: 'Annual dividend', sections: realEstateDividendSections(true), valuesForEditFromItem: [{dataKey:'distributionReinvested', itemDataKey:'distributionReinvested'}], isDistribution: true , otherTrnsFn: saveDividendTrns},
  { title: 'More money invested', sections: contributionAndValueUpdate },
  { title: 'Refinance', sections: realEstateDividendSections(false,true), attributesToSend: [onCapitalAttr, ofCapitalAttr], isDistribution: true },
  { title: 'Property value update', sections: valueUpdateOnly },
  { title: 'Sale of property', sections: distributionAndValueUpdate, isDistribution: true , isHidden: true  },
  { title: 'Exit event', sections: exitEventSections([valueUpdateFn(false)]), attributesToSend: [onCapitalAttr, ofCapitalAttr], isDistribution: true, assetInfoLabelId: 'expected-exit-event' },
  { title: 'Annual revenue',  eventType: 'Annual revenue', isPredicted: true, sections: realEstateDividendSections(false), 
    valuesForEditFromItem: [{dataKey:'distributionReinvested', itemDataKey:'distributionReinvested'}],
    attributesToSend: [onCapitalAttr, ofCapitalAttr], 
    isDistribution: true },
  { title: 'End date', isPredicted: true, sections: distributionAndValueUpdate, isDistribution: true },
  { title: 'Capital call contribution', isPredicted: true, sections: capitalCallsSections([contributionValueFn, valueUpdateFn(true)])},
  { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false },
]

export const realEstateResidenceEvents = [
  { title: 'Sale of property', sections: distributionAndValueUpdate, isDistribution: true },
  { title: 'Renovation', sections: contributionAndValueUpdate },
  { title: 'Maintenance', sections: contributionOnly },
  { title: 'General expenses', sections: contributionOnly },
  { title: 'Property value update', sections: valueUpdateOnly },
  { title: 'Monthly expenses', isPredicted: true, sections: contributionOnly },
  { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false },
]

const validateTransactionValueWithOnOfCapital = (formValues) => { return formValues.isDistributionOfCapital ? Math.trunc((formValues.onCapital || 0) + (formValues.ofCapital||0)) === Math.trunc(formValues.transactionValue) : true };