import styled from '@emotion/styled/macro'
import { flexCenter, flexVerticalCenter } from '../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../common/styles/theme.styles';
import { keyframes } from '@emotion/react';

export const MagicBoxCardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    ${views.tablet}{
        gap:0;
    }
`;

export const MagicBoxHeroWrapper = styled.div`
    display: flex;
    align-items: center;
    position:relative;
    background-color: #060a0c;     
    border-radius: 8px;
    ${views.tablet}{
        flex-direction: column-reverse;
        border-radius: 0px;
        padding-bottom:24px;
    }
`;

export const MagicBoxHeroTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 56px;
    ${views.tablet}{
        padding:0px 24px;
        align-items: center;
    }
    z-index: 1;
`;

export const MagicBoxHeroRightPaneWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-left: auto;
    ${views.tablet}{
        margin-left:0px;
        padding: 16px 0 24px 24px;
    }
`;

export const MagicBoxHeroTitle = styled.div`
    font-weight: 700;
    font-size: ${props=>props.demoTitle ? 40 : 24}px;
    line-height: 120.5%;
    color: #fff;
    ${views.tablet}{
        text-align: center;
    }
`;

export const MagicBoxHeroSubtitle = styled.div`
    font-weight: 450;
    font-size: 14px;
    line-height: 140.34%;
    color: #fff;
    white-space: pre-line;
    ${views.tablet}{
        text-align: center;
        font-size: 16px;
    }
`;

export const LearnMoreButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    color: #C0D4FC;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    padding: 4px 12px 4px 16px;
    width: fit-content;
    height: 32px;
    border: 1px solid #C0D4FC;
    border-radius: 6px;
    margin-top: 20px;
    cursor: pointer;
    svg {
        path {
            stroke: #C0D4FC;
        }
    }
`;

export const MagicBoxModalHeader = styled.div`
    display: flex;
    width: 100%;
    padding: 20px;
    color: #000000d9;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
`;

export const MagicBoxModalTitle = styled.div`
    display:flex;
    gap: 8px;
    margin: 0;
    color: #000000d9;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
`;

export const MagicBoxModalClose = styled.div`
    display: grid;
    margin-left: auto;
    order: 2;
    place-items: center;
    width: 32px;
    cursor: pointer;
`;

export const MagicBoxModalContent = styled.div`
    /* background-color: #FFFFFF; */
    display:flex;
    flex-direction:column;
    flex:1;
    overflow-y: auto;
    border-radius: 0 0 8px 8px;
`;

export const MagicBoxModalFooter = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 16px 24px;
    color: #000000d9;
    background: #fff;
    border-top: 1px solid #f0f0f0;
    border-radius: 0px 0px 8px 8px;
    text-align: right;
`;

export const MagicBoxModalButton = styled.button`
    background-color: ${props => props.disabled ? 'grey' : 'black'};
    line-height: 0;
    color: white;
    font-weight:500;
    border: none;
    height: 33px;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.32), 0px 2px 8px rgba(0, 0, 0, 0.08);
`;

export const PromptWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 24px;
`;

export const IllustrationIconWrapper = styled.div`
    // padding-bottom:8px;
`;

export const IllustrationIconTextTopRow = styled.div`
    color: ${colors.darkGray1};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    ${views.tablet}{
        font-weight: 450;
        text-align:center;
    }
`;

export const IllustrationIconTextSecondRow = styled.div`
    color: ${colors.darkGray1};
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    ${views.tablet}{
        color:${colors.darkGray2};
        text-align: center;
    }
`;



export const AltTextPrompt = styled.div`
    color: ${colors.darkGray3};
    font-size: ${props => props.textSize ? props.textSize : '14'}px;
    font-weight: 500;
    line-height: 130%;
    text-align: center;
`;

export const MainTextPrompt = styled(AltTextPrompt)`
    color: ${colors.darkGray1};
    font-size: 16px;
`;

export const LighterMainTextPrompt = styled(MainTextPrompt)`
    font-weight: 400;
    font-size: 14px;
`;

export const FileDropAreaWrapper = styled.div`
    position: relative;
    ${flexCenter};
    flex-direction: column;
    width: 100%;
    height: ${props => props.height ? props.height : '280px'};
    padding: ${props => props.isWithAiImage ? '0 0 24px 0' : '6px 8px 24px 8px'};
    background-color: ${props => props.isHoverActive ? colors.lightGray1 : 'transparent'};
    border: 1px dashed ${colors.darkGray3};
    border-radius: ${props => props.isWithAiImage ? '12' : '8'}px;
    cursor:pointer;
    ${views.tablet}{
        border-radius: 0px;
        border:none;
        height: auto;
        padding:${props => !props.isActivationFlow ? '24px 0' : '40px 0 24px'};
        background-color: #fff;
    }
`;

export const FileDropAreaFailedWrapper = styled(FileDropAreaWrapper)`
    border: 1px solid ${colors.gray3};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
`;

export const FileDropArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    gap: 4px;
    ${views.tablet}{
        width: 80%;
        gap: 12px;
    }
`;

export const ForwardEmailsTipWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 8px;
    padding: 12px 16px;
    margin-top: 24px;
    background: #FEF8EF;
    box-shadow: ${shadows.buttonShadow};
    border-radius: 8px;
    svg {
        width: 24px;
        height: 24px;
    }
`;

export const ForwardEmailsTipText = styled.div`
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.darkGray1};
`;

export const DocumentListHeader = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.gray2};
    border-bottom: 1px solid ${colors.gray3};
    padding-bottom: 8px;

    height: 24px;
    padding: 0px 38px 8px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

export const SecuredPromptWrapper = styled.div`
    ${flexCenter};
    padding: 12px 0;
    background-color: #fff;
`;

export const TooltipAreaWrapper = styled.div`
    display: flex;
    gap: 6px;
    padding: 8px;
`;

export const CreateMenuItemBadge = styled.div`
    background-color: #ECEDA6;
    color: ${colors.darkGray1};
    box-shadow: 0px 1.23288px 2.46575px rgba(255, 255, 255, 0.04), 0px 0px 1.23288px rgba(255, 255, 255, 0.16);
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    padding:4px 8px;
    border-radius: 3px;
    margin-bottom:4px;
`;
export const NewItemBadge = styled(CreateMenuItemBadge)`
    background-color: #F7C7E4;
`;

export const ButtonWrapper = styled.div`
    ${flexCenter};
    color: #fff;
    background-color: ${colors.darkGray1};
    gap: 8px;
    min-width: 200px;
    min-height: 32px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 17px;
    svg{
        width: 24px;
        height: 24px;
    }
`;

export const SectionWrapper = styled.div`
    display:flex;
    // padding:24px 240px 16px 240px;
    // padding:24px 16px;
    /* padding:${props => props.padding ? props.padding : '24px'}; */
    padding: 24px 0;
    flex-direction:column;
    width:100%;
    /* max-width: ${props => props.fullWidth ? '100%' : '512px'}; */
    /* margin: 0 auto; */
    width: 100%;
    // background-color:${props => props.index % 2 === 1 ? '#fbfcfc' : '#fff'};
    /* ${props => props.horizntalSections ? `
        flex-direction:row;
        &:not(:last-child){
            border-bottom: 1px solid #E5E5E5;
        }
    ` : `
        flex-direction:column;
    `} */
        
    ${views.tablet}{
        ${props => props.withTopBorder ? `border-top: 1px solid ${colors.gray3};` : ``}
    }

`;

export const ActionsWrapper = styled.div`
    ${flexCenter};
    width: 100%;
`;

export const ActionButton = styled.div`
    ${flexCenter};
    gap: 8px;
    background: #2A2D33;
    width: ${props => props.width ? props.width : 'auto'};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    margin: 4px;
    padding:${props => props.withIcon ? '8px 40px 8px 32px' : '8px 40px'};
    // padding: 8px 32px;
    font-size:14px;
    color:#fff;
    cursor: pointer;
    .anticon{
        padding:0 8px 0 0;
    }
    ${props => props.disabled ? `opacity:0.6;cursor:default` : ``}
    svg{
        width: 24px;
        height: 24px;
    }
`;

export const ActionLinkButton = styled.a`
      ${flexCenter};
    gap: 8px;
    background: #2A2D33;
    width: ${props => props.width ? props.width : 'auto'};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    margin: 4px;
    padding:${props => props.withIcon ? '8px 40px 8px 32px' : '8px 40px'};
    // padding: 8px 32px;
    font-size:14px;
    color:#fff;
    cursor: pointer;
    .anticon{
        padding:0 8px 0 0;
    }
    ${props => props.disabled ? `opacity:0.6;cursor:default` : ``}
    svg{
        width: 24px;
        height: 24px;
    }
`

export const PlusIconWrapper = styled.div`
    width: 16px;
    height: 16px;
    svg{
        width: 16px;
        height: 16px;
        path{
            stroke: #fff;
        }
    }
`;

export const ActivationFlowFilesWrapper = styled.div`
    padding-top: ${props => props.withPadding ? '8' : '0'}px;
`;

export const DocumentRow = styled.div`
    ${flexCenter};
    justify-content: ${props => props.spaceBetween ? 'space-between' : 'flex-start'};
    width: 100%;

    ${props => props.isActivation ? `
        justify-content: space-between;
    ` : ``}

    ${props => props.withModal ? `
        :first-of-type {
            border-top: 1px solid ${colors.gray3};
        }
        border-bottom: 1px solid ${colors.gray3};
        padding: 16px;
        width: 380px;
    ` : `
        padding: 8px 0;
    `};
    
    ${props => props.withCollpase ? `
        display: flex;
        height: 48px;
        padding: 12px 0;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        
        ${props.open ? `background-color: var(--Light-Gray-1, #F7F8F9);` : ``}

    ` : ``}

    ${props => props.gap ? `gap: ${props.gap}px`: ``};

    ${props => props.pointer ? 'cursor:pointer' : ''};
`;

export const DeleteIconWrapper = styled.div`
    cursor: pointer;
    padding: 4px 4px 4px 0px;
    svg {
        path {
            stroke: #000 //#FF947C;
        }
    }
    ${flexCenter};
`;

export const DocumentStatusBadgeWrapper = styled.div`
    display: flex;
    width: 96px;
    padding: 0px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
`;

export const DocumentStatusBadge = styled.div`
    display: flex;
    justify-content: flex-end;
    background-color: ${props => props.status ? props.status === 'Done' ? '#72E1AC' : props.status === 'Rejected' ? '#FF947C' : null : null};
    color: ${props => props.status && (props.status === 'Pending' || props.status === 'Processing') ? null : '#fff'};
    box-shadow: 0px 1.23288px 2.46575px rgba(255, 255, 255, 0.04), 0px 0px 1.23288px rgba(255, 255, 255, 0.16);
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    padding: ${props => props.status && (props.status === 'Pending' || props.status === 'Processing') ? '0' : '4px 8px'};
    border-radius: 4px;
    margin-bottom:4px;
    gap: 4px;
    flex-shrink: 0;
`;

export const DocumentStatusIconWrapper = styled.div`
    ${flexCenter};
    svg{
        width: 16px;
        height: 16px;
    }
`;

export const UploadAnimatedBarWrapper = styled.div`
    background-color: ${colors.gray3};
    height: 2px;
    position: relative;
    width: 100%;
`;

const progressBar = keyframes`
    0% { left:0; width: 0; }
    50% { left:0; width: 100% }
    100% { left:100%; width: 0 }
`;

export const UploadAnimatedBar = styled.div`
    background-color: ${colors.darkGray1};
    height: 2px;
    width: 100%;
    position: absolute;
    /* transition : width 5s ease; */
    animation: ${progressBar} 5s ease-in-out infinite;
`;

export const SuccessMessageWrapper = styled.div`
    padding: ${props => props.noPadding ? '0 0' : '48px 0'};
    display:flex;
    flex-direction:column;
    align-items:center;
`

export const StepWrapper = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    padding:0 120px;
    overflow-y: auto;
    background:#fff;
    // padding:${props => props.widthPadding ? '16px' : '0'};
    ${views.tablet}{
        padding:0 32px;
    }
`
export const MagicBoxPromptHoverEffectWrapper = styled.div`
    pointer-events:none;
    position: absolute;
    z-index: 1;
    // margin-top:4px;
    transition: all 0.2s;
    opacity:0;
    width: 250px;
    display: flex;
    top: 100%;
    padding-top:4px;
    ${props => props.rightPos ? `right: 0;` : `left: 0;`}
    ${props => props.isVisible ? `
        z-index: 1;
        opacity:1;
        pointer-events:auto;
    ` : ``}
`

export const MagicBoxPromptHoverEffect = styled.div`
  
  border-radius: 8px;
  background-color: #232628;
  color: #fff;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  width: 250px;
  padding: 16px;
  box-shadow: ${shadows.popupShadow};
  
  
  
  
`;

export const MagicBoxHeroRightPaneHeaderWrapper = styled.div`
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding-right: 12px;
`;

export const MagicBoxPromptWrapper = styled.div`
  ${flexCenter};
  gap: 4px;
  border-radius: 6px;
  box-shadow: ${shadows.cardShadow};
  border: 1px solid #232628;
  background-color: #232628;
  color: #fff;
  padding: 6px 8px;
  position: relative;
  /* border: 1px solid #fff; */
  transition: all 0.2s;
  :hover {
  }
  ${props => props.isVisible ? `
      border: 1px solid #585D66;
  ` : ``};
`;

export const CloseXIconWrapper = styled.div`
    ${flexCenter};
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 14px;
    height: 14px;
    svg {
        path {
            stroke: ${colors.gray2};
        }
    }
`;

export const MobileHeader = styled.div`
    position: ${props => props.isHeaderFixed ? `fixed` : `relative`};
    top: 0;
    background-color: #111213;
    z-index: 2;
    width: 100%;
    min-height: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 24px 0 0;
`;

export const HeaderTitle = styled.div`
    font-weight: 450;
    font-size: 18px;
    color: #fff;
`;

export const MobileBottomContentWrapper = styled.div`
    background-color: #fff;
    padding-bottom: 32px;
`;

export const CountersWrapper = styled.div`
    padding:0 20px 24px;
    display: flex;
    flex-direction: column;
    gap:8px;
`;

export const CounterRowWrapper = styled.div`
    ${flexVerticalCenter};
    gap:8px;
    
`;

export const MobileListTitle = styled.div`
    color:${colors.darkGray3};
    font-size: 13px;
    font-weight: 500;
    padding:0 20px;
`;

export const MobileFilesListWrapper = styled.div``;

export const MobileFileRow = styled.div`
    ${flexCenter};
    border-top:1px solid ${colors.lightGray2};
    padding:8px 20px;
    gap:8px;
`;

export const FileRowIconColumn = styled.div`
    width:26px;
    min-width:26px;
    display: flex;
`;
export const FileRowTextColumn = styled.div`
    flex:1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const FileRowTitle = styled.div`
    font-size: 16px;
    font-weight: 450;
    color: ${colors.darkGray1};
    overflow-wrap: break-word;
`;

export const FileRowMoreInfo = styled.div`
    color:${colors.darkGray3};
    font-size: 13px;
    font-weight: 500;
`;

export const CollapseIconWrapper = styled.div`
    ${flexCenter};
    width: 24px;
    height: 24px;
    padding: 7.987px 0px 7.986px 0px;
    border-radius: 14px;
    flex-shrink: 0;

    ${props => props.open ? `
        border-radius: 14px;
        background: rgba(221, 227, 235, 0.50);
        transform: rotate(180deg);
        ` :
        ``
    }
`;

export const TableHeaderRightItems = styled.div`
    display: flex;
    width: 267px;
    align-items: flex-start;
    gap: 8px;
`;

export const TableContentRightItems = styled.div`
    display: flex;
    width: 304px;
    align-items: center;
    gap: 8px;
`;

export const TableDateNameCell = styled.div`
    display: flex;
    width: 168px;
    padding: 10px 0;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
`;

export const TableDateNameCellDateText = styled.div`
    color: var(--Dark-Gray-1, #1A1B1D);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 130%; /* 18.2px */
`;

export const TableDateNameCellNameText = styled.div`
    color: var(--Dark-Gray-3, #9097A3);
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 130%; /* 15.6px */
`;

export const MagicBoxFileNameWrapper = styled.div`
    display: flex;
    height: 33px;
    align-items: center;
    gap: 4px;

    overflow: hidden;
    width: calc(100% - 308px);

    span{
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const MagicBoxFileDetailsWrapper = styled.div`
    min-height: ${props => props.isCollapsed ? '0' : '125px'};
    height: ${props => props.isCollapsed ? '0' : 'auto'};
    padding: ${props => props.isCollapsed ? '0' : '0px 32px 12px 44px'};
    overflow: hidden;
    
    border-radius: 0px 0px 8px 8px;
    background: var(--Light-Gray-1, #F7F8F9);
`;

export const MagicBoxFileDetails = styled.div`
    width: calc(100% - 275px);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
`;

export const MagicBoxFileSizeWrapper = styled.div`
    color: var(--Dark-Gray-3, #9097A3);
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const MagicBoxFileNotesWrapper = styled.div`
    display: flex;
    padding: 8px 0px;
    align-items: center;
    gap: 6px;
    width: 100%;

    input {
        background: transparent;
        align-self: stretch;
        border-radius: 8px;
        width: calc(100% - 272px);
        border: 1px solid var(--Gray-3, #DDE3EB);
        padding: 8px;
        box-shadow: none !important;
        
            :hover,
            :focus,
            :active {
                border: 1px solid #1A1B1D;
            }
        };
`;

export const MagicBoxFileDescription = styled.div`
    color: var(--Dark-Gray-1, #1A1B1D);
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 19px; /* 135.714% */
`;

export const InputWithIconWrapper = styled.div`
    display: flex;
    gap: 6px;
    border-radius: 8px;
    align-items: center;
    padding: 8px;

    width: 100%;
    border: 1px solid var(--Gray-3, #DDE3EB);
    :hover,
    :focus,
    :active {
        border: 1px solid #1A1B1D;
    }

    input {
        background: transparent;
        align-self: stretch;
        border-radius: 0 8px;
        width: ${props => props.isEdited ? 'calc(100% - 32px)' : '100%'};
        box-shadow: none !important;
        border: none !important;
        padding: 0;
        };
`;

export const InputWithLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: calc(100% - 272px);

    span {
        color: var(--Dark-Gray-3, #9097A3);
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }
`;