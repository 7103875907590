
export const connectedStockInfoSections = [
    {
        title:'ACCOUNT INFO',
        rows:[
            {fields:[{type:'text', key:'name' , label: 'Institution', disabled:true}]},
            {fields:[{type:'text', key:'container' , label: 'Investment account', disabled:true}]},
            {fields:[{type:'text', key:'beneficiary' , label: 'Holding entity', disabled:true}]}
            
        ]
    },
    {
        title:'TICKER INFO',
        rows:[
            {fields:[{type:'text', key:'titleWithSymbol' , label: 'Name', disabled:true , defaultValueFn: (values)=>{return `${values.symbol}, ${values.title}`}}]},
            {fields:[{type:'text', key:'symbolType' , label: 'Type', disabled:true}]}
        ]
    }
]