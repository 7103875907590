import { Switch } from "antd";
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import { displayMoneyValue } from '../../../../common/utils/number-display.utils';
import { useStore } from '../../../app/data/root.store';
import { ConnectedInstitutionAccountDisableModal } from './ConnectedInstitutionAccountDisableModal';
import { AccountAltItemTitleLink, AccountAltSeparator, AccountItemAltHeader, AccountItemHeader, AccountItemHeaderWrapper, AccountItemWrapper } from './ConnectedInstitutionsList.styles';
import { useHistory } from "react-router-dom";
import { ItemLinkArrowIcon } from "./institutionPanel.commons";
import { navigateToUrl } from "../../../../common/utils/navigation.utils";
import { getUrlDisplayName } from "../../../../common/utils/string.utils";

import { VipConnectionOnModal } from "./VipConnectionOnModal";
import { ModifiedTooltip } from "../../../../common/components/ModifiedTooltip/ModifiedTooltip";


export const VipInstitutionAccountRow = (props) => {
  const { account, inst, isIncognito, isLoginRequired } = props;
  const { connectedInstitutionsStore, userStore, billingStore, metadataStore} = useStore();
  const [showConnectedInstitutionModal, setShowConnectedInstitutionModal] = useState(false);
  const [showConnectedInstitutionAccountDisableModal, setShowConnectedInstitutionAccountDisableModal] = useState(false);
  const isEditMode = new URLSearchParams(window.location.search).get('edit') === '1';
  let history = useHistory();

  const handleSwitchOnClick = (isChecked, e) => {
    if (isChecked) {
      if (userStore.isDemoOrReadOnlyMode) {
        e.stopPropagation();
        billingStore.setShowPricingTableModal(true);
      }
      else {
        
        setShowConnectedInstitutionModal(true);
      }
    } else {
      setShowConnectedInstitutionAccountDisableModal(true);
    }
  }

  const handleCloseConnectionModal = () => {
    setShowConnectedInstitutionModal(false);
    connectedInstitutionsStore.connectAssetToVipService(inst.id,account.astId,false,false);
  }

  const handleItemAssetLinkClick = (categoryId, itemId) => {
    const astClass = metadataStore.classesObj[metadataStore.categoriesObj[categoryId].classId];
    navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(astClass.title)}/${itemId}`);
  }

  

  const handleDisableItem = (action) => {
    connectedInstitutionsStore.connectAssetToVipService(inst.id,account.astId,false,(action === 'archive'));
  }

  const handleRemoveConnection = () => {
    connectedInstitutionsStore.connectAssetToVipService(inst.id,account.astId,true,false);
  }

  const renderTitle = (title) => {
    const sliceMapByScreen = {
      'desktop': 60,
      'tablet': 60,
      'mobile': 60,
    };
  
    const screenSize = window.outerWidth 
    let screenType = 'desktop';
    if (screenSize <= 455) {
      screenType = 'mobile'
    } else if (screenSize >= 456 && screenSize <= 784) {
      screenType = 'tablet'
    }
  
    if (title?.length > sliceMapByScreen[screenType]) {
      return title.slice(0, sliceMapByScreen[screenType]) + '...';
    } else {
      return title;
    }
  };

  return (
    <Observer>{() => (
      <AccountItemWrapper key={account.astId} isLoginRequired={isLoginRequired}>
        {/* <AccountItemIconWrapper>
          <div style={{ backgroundColor: 'lightgrey', borderRadius: '50%', width: '24px', height: '24px' }} />
        </AccountItemIconWrapper> */}
        <AccountItemHeaderWrapper>
          <AccountItemHeaderWrapper>
            <AccountItemHeader>
              {renderTitle(account.astTitle)}
            </AccountItemHeader>
            <AccountItemAltHeader>
                <span style={{flexShrink:0}}>{metadataStore.categoriesObj[account.astCategoryId].title}</span>
                <AccountAltSeparator />
                <AccountAltItemTitleLink onClick={() => { handleItemAssetLinkClick(account.astCategoryId, account.astId) }} >{renderTitle(account.astTitle)}<ItemLinkArrowIcon /></AccountAltItemTitleLink>
              
            </AccountItemAltHeader>
          </AccountItemHeaderWrapper>
        </AccountItemHeaderWrapper>
        <div style={{ paddingRight: '16px' }}>{displayMoneyValue(account.balance * (account.astCategoryId === 39 ? account.stock.stockPrice.price : 1), account.currency, isIncognito, 100)}</div>
        <ModifiedTooltip placement={'left'} title={userStore.isActionBlockedByRole('institutionsAccountToggle') ? userStore.actionBlockedByRolePrompt : ''}>
          <Switch disabled={isLoginRequired || userStore.isActionBlockedByRole('institutionsAccountToggle')} checked={account.connectionStatus === 'connected'} onChange={(isChecked, e) => handleSwitchOnClick(isChecked, e)}></Switch>
        </ModifiedTooltip>
        {isEditMode && <button onClick={()=>{handleRemoveConnection()}}>X</button>}
        {showConnectedInstitutionModal && <VipConnectionOnModal
          
          astTitle={account.astTitle}
          astCategoryId={account.astCategoryId}
          title={account.astTitle}
          institution={inst}
          amount={account.balance}
          amountCurrency={account.currency}
          isIncognito={isIncognito}
          provider={'vip'}
          onClose={handleCloseConnectionModal} 
          handleOk={handleCloseConnectionModal}

        />}
        {showConnectedInstitutionAccountDisableModal && <ConnectedInstitutionAccountDisableModal
          onClose={() => setShowConnectedInstitutionAccountDisableModal(false)}
          astTitle={account.astTitle}
          astCategoryId={account.astCategoryId}
          title={account.astTitle}
          institution={inst}
          amount={account.balance}
          amountCurrency={account.currency}
          isIncognito={isIncognito}
          provider={'saltEdge'}
          handleOk={handleDisableItem}
          hideDeleteOption={true}
          isActionLocked={userStore.isDemoOrReadOnlyMode} />}
      </AccountItemWrapper>
    )}</Observer>
  )
}
