import styled from "@emotion/styled/macro";
import { colors } from "../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../common/styles/combos.styles";

export const PageWrapper = styled.div`
  position:fixed;
  inset:0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  background-color: #FFFFFF;
  z-index: 99999991;
`;

export const MobileMenuWrapper = styled.div`
  ${flexCenter};
  cursor: pointer;
`;

export const MobileMenuHoverWrapper = styled.div`
  display: flex;
  width: 266px;
  padding: 4px 0;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25), 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
`;

export const MobileMenuHoverRow = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
  padding: 12px 16px;
  margin: 0 -8px;

  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;

  :not(:last-of-type) {
    border-bottom: 1px solid ${colors.gray3};
  }
  cursor: pointer;
`;

export const LandingPageSvgWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const MobilePricingModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 12px;
  flex-shrink: 0;
`;

export const HeaderLogoAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #121D2A;
  font-family: Domine;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;  
  padding: 2px 10px;
`;

export const LogoWrapper = styled.a`
  ${flexCenter};
`;

export const LaptopImage = styled.div`
  display: flex;
  width:100%;
  min-height: 244px;
   flex:1;
   display: flex;
   align-items: center;
  padding-top: 8px;
  img{
    object-fit: cover;
    max-width: 100%;
    max-height: 320px;
  }
  @media (max-height: 500px) {
    min-height: 200px;
  }
`;

export const ContentWrapper = styled.div`
  overflow-y:scroll; 
  flex:1;
  display: flex;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  display: flex; 
  flex-direction: column;
   gap: 12px;
   padding-top: 32px;
   padding-bottom: 20px;
  //  flex:1;
   justify-content:flex-end;
   @media (min-height: 640px) {
    padding-bottom: 32px;
  }
`;

export const CompleteSetupTitle = styled.div`
  color: ${colors.darkGray1};
  font-family: Domine;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CompleteSetupDescription = styled.div`
  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 22px;
`;