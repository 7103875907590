import { makeAutoObservable } from "mobx";
import { errorNotification } from "../../layout/components/Notifications/notifications";
import { fetchUserPlanData, fetchBillingUrlData, fetchPricingTable, fetchSubscriptionUrl, setStarterPlan, createDeepLink } from './billing.service';

export class BillingStore{
    userPlan = {};
    isUserPlanLoaded = false;
    isLoading = false;
    
    isShowPricingTablePopup = false;
    selectedPlan = 'year';

    pricing = {};
    isPricingTableLoaded = false;
    showCancelPlanModal = false
    showCancelSurveyModal = false

    showComparePlansModal = false;
    showStarterModal = false;
    
    constructor(){
        makeAutoObservable(this);
    }
    
    fetchUserPlan = async () =>{
        if (!this.isUserPlanLoaded) {
            this.setUserPlanLoading(true);
            try {
                const data = await fetchUserPlanData();
                // console.log(data);
                this.setUserPlanData(data)
            } 
            catch (e) {} 
            finally {
                this.setUserPlanLoading(false);
            }
        }
    }

    fetchPricingTable = async () =>{
        if (!this.isPricingTableLoaded) {
            this.setPricingTableLoading(true);
            try {
                const data = await fetchPricingTable();
                this.setPricingTableData(data)
            } 
            catch (e) {} 
            finally {
                this.setPricingTableLoading(false);
            }
        }
    }

    fetchSubscriptionUrl = async(selectedPlanIndex, backUrl) => {
        this.setLoading(true);
        try {
            const data = await fetchSubscriptionUrl(this.selectedPlanObj[selectedPlanIndex].id , backUrl);
            return data;
        } 
        catch (e) {
            errorNotification('Something went wrong');
        } 
        finally {
            setTimeout(()=>{
                this.setLoading(false);
            },1000)
        }
    }

    setStarterPlan = async() => {
        this.setLoading(true);
        try {
            await setStarterPlan();
        } 
        catch (e) {
            errorNotification('Something went wrong');
        } 
        finally {
            setTimeout(()=>{
                this.setLoading(false);
            },1000)
        }
    }

    createDeepLink = async(selectedPlanIndex , flow = 'subscription_update_confirm' ) => {
        this.setLoading(true);
        const price_id = (selectedPlanIndex || selectedPlanIndex === 0) ? this.selectedPlanObj[selectedPlanIndex].id : null;
        try {
            const data = await createDeepLink(flow, price_id);
            return data;
        } 
        catch (e) {
            errorNotification('Something went wrong');
        } 
        finally {
            setTimeout(()=>{
                this.setLoading(false);
            },1000)
        }
    }

    setShowPricingTableModal(show){
        this.isShowPricingTablePopup = show;
    }

    setUserPlanData(data) {
        this.userPlan = data
    }

    setPricingTableData(data){
        this.pricing = data;
        // this.pricing.plans.year.discountPercentage = 14.7;
        // this.pricing.plans.year.originalPriceAfterDiscount = 85000;
    }

    fetchBillingUrl = async () =>{
        try {
            this.setLoading(true);
           return await fetchBillingUrlData();
        } 
        catch (e) {} 
        finally{
            setTimeout(()=>{
                this.setLoading(false);
            },1000)
        }
    }

    setPricingTableLoading(isLoading){
        this.isLoading = isLoading;
        if (!isLoading){
            this.isPricingTableLoaded = true;
        }
    }

    setUserPlanLoading(isLoading){
        this.isLoading = isLoading;
        if (!isLoading){
            this.isUserPlanLoaded = true;
        }
    }

    setLoading(isLoading){
        this.isLoading = isLoading;
    }

    setSelectedPlan(plan) {
        this.selectedPlan = plan;
    }

    setShowCancelPlanModal = (show) => {
        this.showCancelPlanModal = show;
    }
    
    setShowCancelSurveyModal = (show) => {
        this.showCancelSurveyModal = show;
    }

    setShowStarterModal = (show) => {
        this.showStarterModal = show;
    }

    setShowComparePlansModal = (show) => {
        this.showComparePlansModal = show;
    }

    get selectedPlanObj(){
        return this.pricing.plans[this.selectedPlan];
    }

}