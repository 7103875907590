import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors } from "../../../../common/styles/theme.styles";

export const OnboardingSidebarWrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  height: 100%;
  background: ${colors.darkGray1};
  width: 80px;
  padding: 12px;
`;

export const UserProfileMenuWrapper = styled.div`
    .profile-image {
        color: #fff;
    }
`;