import moment from "moment";
import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { disabledMoreThanToday } from "../../../../../common/utils/dates.utils";
import { containerRow } from "../../common-fields/container-field.const";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { isRequired } from "../../input-validations";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";

export const createLongTermAddItemSections = (accountNameDescription, isEditForm) => {
   return ({updateValue,updateField}) => {
        const { long_term_savings } = getTranslations().new_item_modal;
        return ([
        {        title:'General info',
            rows:[
                ...masterInstitutionInputRows(long_term_savings.NAME_LABEL,long_term_savings.NAME_DESCRIPTION, 2,false,  updateValue),
                {fields:[
                    {key:'title', description: accountNameDescription, validations:[isRequired]}
                ]},
                beneficiaryRow({label: 'Account owner'}),
                { fields:[
                    {key:'value',label: long_term_savings.VALUE_LABEL, description: long_term_savings.VALUE_DESCRIPTION, validations:[isRequired],
                    // suffix:'$',
                    onBlur:({value})=>{
                        updateValue('cashInvested',value);
                    },generatePropsFn : (values)=>{
                        return {prefix: CURRENCY_SYMBOL[values.currency] || '',placeholder: CURRENCY_SYMBOL[values.currency] || ''}
                    }},
                    {key:'currency',size:0.3,onChange:({value})=>{
                        updateValue('cashInvestedCurrency',value);
                        updateField('value',{prefix:CURRENCY_SYMBOL[value] || '',placeholder: CURRENCY_SYMBOL[value] || ''});
                        updateField('cashInvested',{prefix:CURRENCY_SYMBOL[value] || '',placeholder: CURRENCY_SYMBOL[value] || ''});
                        updateField('expectedMonthlyContribution',{prefix:CURRENCY_SYMBOL[value] || '',placeholder: CURRENCY_SYMBOL[value] || ''});
                    }},
                    {
                        key:'cashInvested', 
                        isHidden:true,
                        defaultValueFn:(values)=>values.value
                    },
                ]},
                ...( isEditForm ? [
                    ...customClassAndSubClassRows({updateValue,updateField}),
                ] : [])
            ]
        },{
            title: 'Projection', 
            rows:[
                {fields:[
                    {key:'expectedIRR', label:'Expected annual performance %', description: long_term_savings.EXPECTED_ANNUAL_PERFORMANCE_DESCRIPTION, defaultValue: 4}
                ]},
                {fields:[
                        {key:'expectedMonthlyContribution', description: long_term_savings.EXPECTED_MONTHLY_CONTRIBUTION_DESCRIPTION,
                        generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || 'USD',placeholder: CURRENCY_SYMBOL[values.currency] || ''}
                        },
                    }
                ]},
            
            ]
        },{
            title: 'Performance', 
            rows:[
                {fields:[
                    {key:'startDate', label: 'Account opening date', description: long_term_savings.START_DATE_DESCRIPTION, 
                    validations:[isRequired],
                    //defaultValueFn: ()=>{return new Date()},
                    disabledDate: disabledMoreThanToday }
                ]},
            ]
        },{
            title:`More info`,
            rows:[
                ...(isEditForm ? [
                    containerRow({isEditForm, updateField}),
                    {
                        fields:[
                            {key:'defaultCashAccountId'}
                        ]
                    },
                    riskLevelRow({updateField}),
                    liquidityRow({updateField}),
                ] : []),
                {fields:[
                    {key:'remarks'}
                ]}
            ]
        }
        ])
    }
}

export const createLongTermLifeInsuranceAddItemSections = (accountNameDescription, isEditForm) => {
    return ({updateValue,updateField}) => {
        const nameFieldLabel = 'Institution / insurance company'
        const nameFieldDescription = ''
        return [
            {
                title:'General info',

                rows:[
                    ...masterInstitutionInputRows(nameFieldLabel,nameFieldDescription, 2,false,  updateValue, true, true),
                    titleRow(),
                    startDateRow(),
                ]
            },
            {
                title:'Current statement',
                rows:[
                    {fields:[
                        valueRow(),
                        currencyRow(updateField),
                    ]},
                    {fields:[
                        {key:'deathBenefit', type:'number', label:() => <div>Current <span style={{ fontWeight: 700 }}>death benefits</span></div>, description: `Your death benefits component amount as of today`,
                            generatePropsFn : (values)=>{
                                return {prefix: CURRENCY_SYMBOL[values.currency] || '',placeholder: CURRENCY_SYMBOL[values.currency] || ''}
                            }
                        }
                    ]},
                    {fields:[
                        {key:'outstandingLoanToggle', type: 'radio', label:() => <div>Do you have an <span style={{ fontWeight: 700 }}>outstanding loan?</span></div>, description: ``,
                        defaultValue: false,
                        onChange: ({value, formValues}) => {
                            updateField('outstandingLoanPrompt', {isHidden:!value})
                        },
                        optionType: 'button',
                        options:[
                            {label: 'Yes', value: true},
                            {label: 'No', value: false}
                        ],
                        }
                    ]},
                    {fields:[
                        {key:'outstandingLoanPrompt', label: 'Awesome! You can add your loan information later.',
                        generatePropsFn: (values) => {
                            return {
                                isHidden: !values.outstandingLoanToggle
                            }
                        }
                        }
                    ]},
                ]
            },
            {
                fullWidth:true,
                title: 'Premium payment schedule',
                rows:[
                    expectedContributionsTableRow(),
                    premiumPaymentPeriodRow(updateField),
                    premiumPaymentCustomRow(updateField),
                ]
            },
            {
                title: 'More info',
                rows:[
                    beneficiaryRow({label: 'Holding entity / Policy owner', description:'The person or company listed as the owner of this policy'}),
                    remarksRow(),
                ]
            },
        ]
    
    }
}

export const createLongTermLifeInsuranceEditItemSections = (isEditForm) => {
    return ({updateValue,updateField}) => {
        const nameFieldLabel = 'Institution / insurance company'
        const nameFieldDescription = ''
        return [
            {
                title:'Policy info',
                rows:[
                    ...masterInstitutionInputRows(nameFieldLabel, nameFieldDescription, 2, false, updateValue),
                    titleRow(),
                    lifeInsuranceTypeRow(),
                    policyNumberRow(),
                    // startDateRow() - as discussed - this can only be done via Item created event
                    beneficiaryRow({label: 'Holding entity / Policy owner', description:'The person or company listed as the owner of this policy'}),
                    insuredNameRow(),
                    ...customClassAndSubClassRows({updateValue,updateField}),
                ]
            },
            {
                title:'Death benefits',
                rows:[
                    
                    recipientsRow(),
                    {fields:[
                        {key:'initialBaseFaceAmount', label: 'Initial base face amount', type:'number',generatePropsFn:(values)=>{
                            return {
                                prefix: CURRENCY_SYMBOL[values.currency],
                                placeholder: CURRENCY_SYMBOL[values.currency],
                            }
                        }}
                    ]},
                    {fields:[
                        {key:'ridersTable', type:'lifeInsuranceRidersTable',
                            generatePropsFn: (values) => {
                                return {
                                    prefix: CURRENCY_SYMBOL[values.currency],
                                }
                            },
                            defaultValue: [
                                {amount: null, description: '',}
                            ],
                            overrideToDefaultIfNull: true,
                        }
                    ]}
                ]
            },
            {
                title:'Premium payment schedule',
                rows:[
                    {fields:[
                        {key:'cashValueExpectedAnnualYield', label:'Cash value’s expected annual yield', type:'percentage'}
                    ]},
                    expectedContributionsTableRow(),
                    premiumPaymentPeriodRow(updateField),
                    premiumPaymentCustomRow(updateField),
                ]
            },
            {
                title:'Loan against cash value',
                rows:[
                    {fields:[
                        {key:'availableCashValueBorrowPercentage', label:'Available loan amount', description:'Percentage of cash value available to borrow from the insurance company', type:'percentage', withNumber: true,
                        generatePropsFn: (values) => {
                            return {
                                prefix: CURRENCY_SYMBOL[values.currency],
                                placeholder: CURRENCY_SYMBOL[values.currency],
                                amount: values.value
                            }
                        }
                        }
                    ]},
                    {fields:[
                        {key:'loanInterestRate', label:'Loan interest rate', type:'percentage',}
                    ]}
                ]
            },
            {
                title:'More info',
                rows:[
                    containerRow({isEditForm: true, updateField}),
                    ...(isEditForm ? [
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    remarksRow(),
                ]
            },
        ]    
    }
}

const titleRow = () => ({fields:[
    {key:'title', label:'Policy name', description: `The name you want this policy to appear under`, validations:[isRequired]}
]})

const startDateRow = () => ({fields:[
    {key:'startDate', label: 'Policy start date', description: `The month this policy started. This date influences the policy’s annual renewal date.`,
    validations:[isRequired],
    disabledDate: disabledMoreThanToday }
]})

const lifeInsuranceTypeRow = () => ({fields:[
    {key:'lifeInsuranceType', label:'Life insurance type', type:'select', options: [
        {value:'permanentWholeLifeInsurance',label:'Permanent - Whole life insurance'},
        {value:'permanentUniversalLifeInsurance',label:'Permanent - Universal Life Insurance'},
        {value:'permanentVariableLifeInsurance',label:'Permanent - Variable Life Insurance'},
        {value:'termLifeInsurance',label:'Term Life Insurance '},
    ]}
]})

const policyNumberRow = () => ({fields:[
    {key:'policyNumber',label:'Policy number', type:'text'}
]})

const insuredNameRow = () => ({fields:[
    {key:'insuredName',label:'Insured name', type:'text', description:'The individual who this life insurance policy covers.' }
]})

const valueRow = (customLabel='') => ({key:'value', label:() => customLabel ? customLabel : <div>Current <span style={{ fontWeight: 700 }}>cash value</span></div>, description: `Your cash value component amount as of today`, validations:[isRequired],
    generatePropsFn : (values)=>{
        return {prefix: CURRENCY_SYMBOL[values.currency] || '',placeholder: CURRENCY_SYMBOL[values.currency] || ''}
    }
})

const currencyRow = (updateField) => ({key:'currency',size:0.3,onChange:({value})=>{
    updateField('value',{prefix:CURRENCY_SYMBOL[value] || '',placeholder: CURRENCY_SYMBOL[value] || ''});
    updateField('deathBenefit',{prefix:CURRENCY_SYMBOL[value] || '',placeholder: CURRENCY_SYMBOL[value] || ''});
    updateField('lifeInsuranceExpectedContributions',{prefix:CURRENCY_SYMBOL[value] || '',placeholder: CURRENCY_SYMBOL[value] || ''});
}})

const recipientsRow = () => ({fields:[
    {key:'recipients', type:'lifeInsuranceBeneficiary',
        defaultValue: {
            mode: 'simple',
            beneficiary: '',
            primaryArray: [
                {beneficiary: '', holdings: 100}
            ],
            secondaryArray: [
                {beneficiary: '', holdings: 100}
            ],
        },
        overrideToDefaultIfNull: true,
        validations:[
            {
                fn: (value) => {
                    const isPrimaryArrayValid = value.primaryArray.reduce((acc,row) => acc+row.holdings, 0) <= 100;
                    return isPrimaryArrayValid
                },
                message: ' ',
                bottomErrorMessage: (value, formValues) => {    
                    return `Max Total Primary Ownerships is 100%`
                },
            },
            {
                fn: (value) => {
                    const isSecondaryArrayValid = value.secondaryArray.reduce((acc,row) => acc+row.holdings, 0) <= 100;
                    return isSecondaryArrayValid
                },
                message: ' ',
                bottomErrorMessage: (value, formValues) => {    
                    return `Max Total Secondary Ownerships is 100%`
                },
            },
        ]
    }
]})

const remarksRow = () => ({fields:[
    {key:'remarks', label: 'Remarks'}
]})

const expectedContributionsTableRow = () => ({fields:[
    {
        key: 'lifeInsuranceExpectedContributions',
        type:'yearsPaymentTable',
        label: 'Annual premium payment amount',
        defaultValue: [{
                tempId: 0,
                fromYear : 1,
            },
            {
                tempId: 1,
                fromYear : 81,
                baseValue: 0,
                puaValue: 0,
            }
        ],
        generatePropsFn : (values)=> {
            return {
                prefix: CURRENCY_SYMBOL[values.currency] || 'USD', 
            }
        },
        setFnForSave: (isDistribution, value, formValues) => {
            return value.map((item)=>{
               
                return {
                    ...(item.id ? {id:item.id} : {}),
                    value:{
                        baseValue :  ( item.totalValue && !item.baseValue && !item.puaValue ) ? item.totalValue : item.baseValue ,
                        puaValue : item.puaValue
                    },
                    date: moment(formValues.startDate).add(item.fromYear - 1, 'year').toDate(),
                    remarks: ''
                }
            })
        },
        overrideValueFn: (formValues) => {
            const startDateYear = moment(formValues.startDate).year();
            return formValues.lifeInsuranceExpectedContributions.map(dataRow=>({
                id: dataRow.id,
                fromYear : moment(dataRow.date).year() - startDateYear + 1,
                baseValue: dataRow.value.baseValue,
                puaValue: dataRow.value.puaValue,
            }));
        }        
    }

]})

const premiumPaymentPeriodRow = (updateField) => ({fields:[
    {key:'expectedContributionPeriod', label: 'Premium payments frequency', description: '',
        type:'select',
        withDescriptionInOption: true,
        defaultValue: 'Annually',
        withSuffixIcon: true,
        options: [
            {value:'Monthly',label:'Monthly'},
            {value:'Quarterly',label:'Quarterly', description:'Transactions date: Apr, Jul, Oct, Jan (next year).'},
            {value:'Semi-annually',label:'Semi-annually', description:'Transactions date: Jul and Jan (next year).'},
            {value:'Annually',label:'Annually', description:'Transactions date: Jan (next year).'},
            {value:'Custom',label:'Custom...', },
        ],
        onChange: ({value, formValues}) => {
            updateField('expectedContributionCustomInterval', { isHidden: value !== 'Custom' });
            updateField('expectedContributionCustomPeriod', { isHidden: value !== 'Custom' });
        },
    }
]})

const premiumPaymentCustomRow = (updateField) => ({fields:[
    {key:'expectedContributionCustomInterval', label:'Repeat every:', type:'number', withArrows:true,
    maxValue: 15, minValue: 0, defaultValue:2, decimalScale: 0,

    generatePropsFn: (formValues) => {
        return { isHidden: formValues.expectedContributionPeriod !== 'Custom' }
    },
    validations:[
        {
            fn: (value) => {
                return value < 16;
            },
            message:'Maximum period allowed: 15'
        }
    ]},
    {key:'expectedContributionCustomPeriod', type:'select', defaultValue:'Months',
    withDescriptionInOption: true,
    withSuffixIcon: true,
    generatePropsFn: (formValues) => {
        return { isHidden: formValues.expectedContributionPeriod !== 'Custom' }
    },
    options:[
        {value: 'Months', label:'Months'},
        {value: 'Years', label:'Years'},
    ]},
]})