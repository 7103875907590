import { Input } from 'antd';
import React from 'react';
import NumberFormat from 'react-number-format';
import { isNullOrUndefined } from '../../../../utils/object.utils';
import { ArrowWrapper, HorizontalLine, NumberArrows, NumberFormatWithArrowsWrapper, SuffixWrapper } from './NumberInput.styles';

export const NumberInput = ({ value, field, disabled, inputKey, isIncognito, handleChange, handleBlur, isItemConnected }) => {
  const { suffix, prefix, shouldBeHiddenIncognito,  disableIfConnected, isLocked, placeholder, decimalScale, allowNegative, withArrows, minValue, maxValue } = field;

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) { // arrow up
      if (maxValue && value >= maxValue) return;
      handleChange(inputKey, value + 1);
    }
    else if (e.keyCode === 40) { // arrow down
      if (!isNullOrUndefined(minValue) && value <= minValue) return;
      handleChange(inputKey, value - 1);
    }
  }

  const isDisabled = disabled || field.disabled || isLocked || (isItemConnected && disableIfConnected);

  const handleIncrement = () => {
    if (!isDisabled && !(maxValue && value >= maxValue)) {
      handleChange(inputKey, value + 1);
    }
  };

  const handleDecrement = () => {
    if (!isDisabled && !(minValue && value <= minValue)) {
      handleChange(inputKey, value - 1);
    }
  };

  return (
    <NumberFormatWithArrowsWrapper style={{ cursor: field.isLocked ? 'pointer' : 'default' }}>
      <NumberFormat
        thousandSeparator={true}
        customInput={Input}
        // suffix={suffix}
        prefix={prefix}
        style={{...isIncognito && shouldBeHiddenIncognito ? { WebkitTextSecurity: 'disc' } : {} , ...field.isLocked? ({pointerEvents : 'none'}) : ({}) }}
        // value={( isNullOrUndefined(value) || value === 0) ? '' : value}
        value={(isNullOrUndefined(value)) ? '' : value}
        //  value={value == null ? '' : value} /// == and not === because it will work with undifined as well , think about change to function to check null or undifined.
        disabled={isDisabled}
        autoComplete="off"
        // onValueChange={(values) => {handleChange(inputKey,values.floatValue ? values.floatValue : 0)}}
        onValueChange={(values) => {
          // console.log(value,values);
          if (value !== values.floatValue &&
            (!isNullOrUndefined(value) || !isNullOrUndefined(values.floatValue))) {
            handleChange(inputKey, (values.floatValue || values.floatValue === 0) ? values.floatValue : null)
          }
        }}
        onBlur={(e) => { handleBlur?.(inputKey) }}
        placeholder={placeholder}
        decimalScale={decimalScale}
        allowNegative={allowNegative || false}
        onKeyDown={handleKeyDown}
      // ref={inputRef} 
      // onFocus={()=>{handleNumberTextFocus(field.type)}} 
      // onBlur={()=>{handleNumberBlur(field.type)}} 
      />
      {suffix ? <SuffixWrapper>{suffix}</SuffixWrapper> : null }
      {withArrows ? <NumberArrows>
        <ArrowWrapper onClick={handleIncrement}><ArrowUpSvg /></ArrowWrapper>
        <HorizontalLine />
        <ArrowWrapper onClick={handleDecrement}><ArrowDownSvg /></ArrowWrapper>
      </NumberArrows> : null}
    </NumberFormatWithArrowsWrapper>
  )
}

const ArrowDownSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 11L4 6H12L8 11Z" fill="black" />
    </svg>
  )
}
const ArrowUpSvg = () => {
  return (
    <svg style={{ transform: `rotate(180deg)` }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 11L4 6H12L8 11Z" fill="black" />
    </svg>
  )
}