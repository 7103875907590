import styled from "@emotion/styled/macro";
import { flexCenter } from "../../styles/combos.styles";

export const CommonBadgeWrapper = styled.div`
    ${flexCenter};
    gap: 4px;
    padding: 2px 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-left: ${props=> props.noMargin ? '0px' : '8px'};
`;