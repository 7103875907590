import styled from '@emotion/styled/macro';
import bgImg from './assets/bg.png';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../../common/styles/theme.styles';

export const ModalBgWrapper = styled.div`
     background: url(${bgImg}) no-repeat;
     background-size: 100% 330px;
     border-radius:8px;
     position: relative;
     display:flex;
     flex-direction:column;
     align-items:center;
`;

export const ModalCloseButtonWrapper = styled.div`
    position:absolute;
    right:16px;
    top:16px;
    width:24px;
    height:24px;
    cursor: pointer;
    path{
      stroke: rgba(255,255,255, 0.8);
    }
`;

export const ModalHeader = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height:330px;
svg {
  ${views.tablet} {
    width: 150px;
    height: 140px;
  }
  path {
    width: 98px;
    height: 98px;
  }
}
`;

export const HeaderTitle = styled.div`
  white-space: pre-line;
  text-align: center;
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 103%;
  color: #fff;
  ${views.tablet} {
    padding-inline: 20px;
  }
`;

export const ModalContent = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-bottom:48px;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.darkGray1};
  padding-top: 20px;
  text-align: center;
`;

export const ContentSubtitle = styled.span`
  align-self: center;
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.darkGray2};
`;

export const MobileStoresWrapper = styled.div`
  ${flexCenter};
  gap: 4px;
  padding-top: 16px;
`;

export const TitleWrapper = styled.div`
  ${flexCenter};
  padding-top:40px;
  padding-bottom:24px;
  svg{
    width: 48px;
    height: 80px;
  }
`;

export const TitleText = styled.div`
  color:${colors.darkGray1};
  /* max-width: 710px; */
  font-size:40px;
  line-height:46px;
  font-weight:400;
  font-family: 'Domine';
  text-align: center;
  padding: 0 8px;
`;

export const ActionButton = styled.div`
  ${flexCenter};
  padding: 12px 24px;
  height:38px;
  cursor: pointer;
  min-width: 144px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  gap:8px;
  box-shadow: ${shadows.toggleShadow};
  border-radius: 6px;
  ${props=>props.black ? `
  background-color: ${colors.darkGray1};
  color: #fff;
  ` : `
  background-color: #fff;
  color: ${colors.darkGray1};
  `};
  ${props=>props.isBack ? `margin-right: auto` : ``};
`;

export const ModalFooter = styled.div`
  ${flexCenter};
  padding: 20px 24px;
  width: 100%;
  gap:8px;
`;


export const FiltersBadgesWrapper = styled.div`
   margin-top: 24px;
   display: flex;
   gap: 4px;
`;

const filtersBadgesProps = [
  ['#73D0DD',52],
  ['#F6BB49',82],
  ['#585D66',55],
  ['#AC8FEB',33],
]
export const FiltersBadge = styled.div`
    height:15px;
    border-radius: 15px;
    width:${props=> filtersBadgesProps[props.badge || 0][1]}px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: ${props=> filtersBadgesProps[props.badge || 0][0]};
`;

export const FilterBadgeCloseBtn = styled.div`
    ${flexCenter}
    background-color: rgba(255, 255, 255, 0.20);
    width: 11px;
    height: 11px;
    border-radius: 20px;
    color:#fff;
    font-size: 8px;
    margin-right: 2px;
    line-height: 11px;
    font-weight: 600;
`;