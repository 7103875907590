import { makeAutoObservable } from "mobx";
import { fetchUserData, fetchProfileData, saveProfileData, changePassword, updateProfileSettings, sendCancelSurvey, logOut, switchAccount, getRoles, grantPermission, updatePermission, deletePermission, resendInvitationMail, startMyPortfolio, updateGranteeProfileSettings, saveGranteeProfileData, updatePhoneNumber } from './user.service';
import { errorNotification, successNotification } from "../../layout/components/Notifications/notifications";
import { capitalize } from "../../../common/utils/string.utils";
import { ls } from "../../../common/utils/localstorage.util";
import moment from "moment";
import { emailToColor, rolesColorPlates } from "../../../common/styles/theme.styles";
import { ROUTES } from "../../../common/constants/routes.const";
import { countryListPhone } from "../../../common/constants/countries.const";
import { datadogRum } from '@datadog/browser-rum';

export const AUTH_STEPS = {
    LOG_IN_STEP: 'LogInStep',
    SIGN_UP_STEP: 'SignUpStep',
    EMAIL_VERIFICATION_STEP: 'EmailVerificationStep',
    INVITATION: 'InvitationStep',
    SET_PASSWORD_STEP: 'SetPasswordStep',
    RESET_PASSWORD_STEP: 'ResetPasswordStep',
    FORGOT_PASSWORD_STEP: 'ForgotPasswordStep',
};

const blockedActionsByRole = {
    'ADMIN': [],
    'EDITOR': ['viewBillingPageFromSidebar', 'viewRolesPageFromSidebar'],
    'GUEST': [
        'viewBillingPageFromSidebar',
        'viewMagicBoxPageFromSidebar',
        'viewCommunityPageFromSidebar',
        'viewRolesPageFromSidebar',
        'viewRolesPage',
        'createItemInvestor',
        'createItemBank',
        'createItemMagicBox',
        'createItemManually',
        'hideNotification',
        'createEvent',
        'editEvent',
        'assetActionMenuLinkLiability',
        'assetActionMenuLinkAsset',
        'assetActionMenuOutstandingLoan',
        'assetActionMenuCloseReopen',
        'assetActionMenuDeleteAsset',
        'editItemInfo',
        'syncFromItemPageCashAccountEmptyState',
        'cashflowNoAssetEmptyStateCreateItem',
        'cashflowNoDataEventsOrCostOfLivingAddItem',
        'vipSyncFromItemPageTab',
        'vipSyncFromUpgradePlanModal',
        'itemUploadDocument',
        'itemOverviewStatsEditOutstandingLoan',
        'updateFinancialSettings',
        'institutionsPanelSyncNew',
        'institutionsAccountToggle',
        'deleteConnectedInstitution',
        'authorizeConnectedInstitution',
        'classPageEmptyStateAddManually',
        'classPageEmptyStateUploadMagicBox',
        'classPageEmptyStateSyncNew',
        'addScenario',
        'editScenario',
        'deleteScenario',
        'editTargetEvent',
        'classPageQuickAdd',
        'bulkEdit',
        'addCustomClass',
        'editCustomClass',
        'deleteCustomClass',
        'editCustomClassOrder',
        'addCustomSubClass',
        'editCustomSubClass',
        'deleteCustomSubClass',
        'addHoldingEntity',
        'editHoldingEntity',
        'deleteHoldingEntity',
    ],
}

const blockedActionsByPaymentStatus = {
    'demo': ['viewBillingPageFromSidebar'],
    'readonly': ['viewWealthOverviewProjectionTab', 'viewCostOfLiving', 'addScenario', 'editScenario', 'viewClassProjectionTab', 'editHistoryDropdown', 'uploadItemDocument', 'editEventRow', 'editPredictedEventRow', 'createEvent', 'editItemInfo', 'quickStartCreateItem', 'quickStartMagicBox', 'quickStartSyncBank', 'institutionSyncEmptyState', 'quickStartHeroAddItem', 'cashflowSyncBankEmptyState', 'cashflowNoAssetEmptyStateCreateItem', 'cashflowMonthlyIncome', 'cashflowMonthlyExpenses', 'viewClassPageLoginRequired', 'viewItemPageLoginRequired', 'bulkEdit'],
}

const permissionMapping = {
    'EDITOR': 'Editor',
    'ADMIN': 'Full-access',
    'GUEST': 'View-only'
}
export class UserStore {
    isAuthenticated = false;
    data = {};
    profileData = {}
    isLoaded = false;
    isLoading = false;
    isLoadingProfileData = false;
    isLoadingRolesData = false;
    isSavingRole = false;
    defaultProfileValues = {};
    isAfterSuccessPayment = false;
    isFirstPayment = false;
    getStartedActiveStepId = 0;
    authStep = window.location.pathname.includes(ROUTES.register.url) ? AUTH_STEPS.SIGN_UP_STEP : window.location.pathname.includes(ROUTES.invitation.url) ? AUTH_STEPS.INVITATION : AUTH_STEPS.LOG_IN_STEP;
    prevAuthStep = window.location.pathname.includes(ROUTES.register.url) ? AUTH_STEPS.SIGN_UP_STEP : window.location.pathname.includes(ROUTES.invitation.url) ? AUTH_STEPS.INVITATION : AUTH_STEPS.LOG_IN_STEP;
    authFields = {
        email: '',
        password: '',
        verifyCode: '',
        isInvitation: false,
        userHasPassword: false,
    };
    skipGetStartedGuid = false;
    showTier4FirstTimeExperienceModal = false;
    showAfterPaymentMobileModal = false;
    showLimitedSharedAccess = false;
    roles = [];
    abTestVariant = 0;

    constructor(metadataStore, uiStore) {
        makeAutoObservable(this);
        this.metadataStore = metadataStore;
        this.uiStore = uiStore;
    }

    getData = async () => {
        try {
            const data = await fetchUserData();
            return data;
        }
        catch (e) {
            return {}
        }
        finally {
        }
    }


    fetchData = async (metadataStore, uiStore, wealthStore, filtersStore, dataLayersStore) => {
        this.setLoading(true);
        try {
            const data = await fetchUserData();
            this.setData(data);
            filtersStore.setLSKey(data.id);
            dataLayersStore.setLSKey(data.id);
            wealthStore.setLSKey(data.id);
            metadataStore.fetchData();
            if (data.profile?.classListIds) {
                metadataStore.setDefaultClassesListIds(data.profile?.classListIds);
            }
            if (data.profile.onBoardingStep === 'get_started') {
                uiStore.setIsRightMenuOpen(true);
            }
            // if (window.Intercom){
            //     window.Intercom('boot', {
            //         app_id: 'qtcqw6wj',
            //         name: (data.profile.firstName + ' ' + data.profile.lastName).trim(),
            //         email: data.email,
            //         user_id: process.env.REACT_APP_ENV_KEY + '-' + data.id,
            //         created_at: Date.now(),
            //     });
            //     window.Intercom('onShow', function() { 
            //         window.Intercom("update", {last_request_at: parseInt((new Date()).getTime()/1000)})
            //     });
            // }
            // if (window.analytics){
            //     window.analytics.identify(process.env.REACT_APP_ENV_KEY + '-' + data.id, {
            //         name: (data.profile.firstName + ' ' + data.profile.lastName).trim(),
            //         email: data.email
            //     });
            // }

            // for not changing google tag manager variables - 
            // changing the data to keep user current profile at profile prop, move Profile owner data from profile prop to owner prop
            // we can use this instead of data also for sentry and smartlook
            const profileTier = data.profile?.tier === 'NONE' ? 'no-portfolio' : data.profile.tier
            let gTagManagerUserData = { ...data, numberOfSharedPortfolios: data.sharedAccess.length, role: 'Owner', roleDescription: 'Owner', profile: { ...data.profile, tier: profileTier }, }

            if (data.hasOwnProperty('granteeProfile')) {
                const currentPortfolioIndex = this.data?.sharedAccess?.findIndex(access => access.ownerId === this.data?.id);
                const currentPortfolio = this.data?.sharedAccess?.[currentPortfolioIndex];
                gTagManagerUserData = {
                    id: data.granteeProfile.id,
                    email: data.granteeProfile.email,
                    profile: {
                        ...data.granteeProfile,
                        tier: data.granteeProfile?.tier === 'NONE' ? 'no-portfolio' : data.granteeProfile.tier
                    },
                    role: currentPortfolio?.role,
                    roleDescription: currentPortfolio?.roleDescription,
                    portfolioUserProfile: { ...data.profile, id: data.id, email: data.email },
                    numberOfSharedPortfolios: data.sharedAccess.length
                }
            }

            if (window.dataLayer) {
                window.dataLayer.push({
                    event: "UserIdentification",
                    userData: gTagManagerUserData,
                    userID: process.env.REACT_APP_ENV_KEY + '-' + gTagManagerUserData.id,
                })
            }

            // if (window.hj){
            //     window.hj('identify', process.env.REACT_APP_ENV_KEY + '-' + data.id, {
            //         name:(data.profile.firstName + ' ' + data.profile.lastName).trim(),
            //         email: data.email
            //     });
            // }
            return data;
        }
        catch (e) { }
        finally {
            this.setLoading(false);
        }
    }

    updateData = async () => {
        this.setLoading(true);
        try {
            const data = await fetchUserData();
            this.setData(data);
        }
        catch (e) { }
        finally {
            this.setLoading(false);
        }
    }

    logOut = async () => {
        await logOut();
        window.localStorage.removeItem('pfsRequestSent')
        const hasDuCookie = document.cookie.split(';').find(i => i.trim().startsWith('__mp_du_session'))?.split('=')?.[0];
        if (hasDuCookie) {
            document.cookie = hasDuCookie + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'logout', data: { a: 'cool', b: Date.now() }, response: 'Message received!' }));
        }
        datadogRum.clearUser();
        window.location.href = '/';
    }

    setData(data) {
        this.data = data;
        this.isAuthenticated = true;
        this.skipGetStartedGuid = ls.get('skipGetStartedGuid_' + data.id);
        if (this.data.profile?.onBoardingStep === 'welcome') {
            this.updateIntercomDemoDoneKey();
        }
        if (this.data.profile?.paymentStatus.includes('readonly') && this.data.profile?.paymentStatus.includes('isBetaUser')) {
            this.updateIntercomBetaPopupWelcomeKey();
        }
        this.abTestVariant = this.data.profile?.variantNumber;
    }

    setUserShowGetStartedGuid() {
        ls.set('skipGetStartedGuid_' + this.data.id, true);
        this.skipGetStartedGuid = true;
    }

    setUserTierAndPaymentStatus(tier, status, tierLimitations) {
        this.data.profile.tier = tier;
        this.data.profile.paymentStatus = status;
        this.data.tierLimitations = tierLimitations;
    }

    updateIntercomDemoDoneKey() {
        setTimeout(() => {
            if (window.Intercom) {
                window.Intercom("update", { demo_done: true })
            }
        }, 1000);
    }

    updateIntercomBetaPopupWelcomeKey() {
        setTimeout(() => {
            if (window.Intercom) {
                window.Intercom("update", { show_betauser_welcome_popup: true })
            }
        }, 1000);
    }

    setLoading(isLoading) {
        if (isLoading) {
            this.isLoading = true;
        } else {
            this.isLoaded = true;
            this.isLoading = false;
        }
    }

    setLoadingProfile(isLoading) {
        this.isLoadingProfileData = isLoading;
    }

    setLoadingRoles(isLoading) {
        this.isLoadingRolesData = isLoading;
    }

    setIsSavingRole(isSaving) {
        this.isSavingRole = isSaving;
    }


    setShowAfterPaymentMobileModal(show) {
        this.showAfterPaymentMobileModal = show
    }

    async fetchProfile() {
        this.setLoadingProfile(true);
        try {
            const data = await fetchProfileData();
            this.setProfile(data);
        }
        catch (e) { }
        finally {
            this.setLoadingProfile(false);
        }
    }

    setProfile(profile) {
        this.profileData = { ...profile, fullName: profile.firstName + ' ' + profile.lastName, monthlyIncomeCurrency: profile.monthlyIncomeCurrency || profile.defaultCurrency, monthlyExpensesCurrency: profile.monthlyExpensesCurrency || profile.defaultCurrency };
        this.defaultProfileValues = { ...profile, fullName: profile.firstName + ' ' + profile.lastName };
    }

    cancelProfileUpdates(fields) {
        const filteredDefaultValues = {};
        for (let i = 0; i < fields.length; i++) {
            filteredDefaultValues[fields[i]] = this.defaultProfileValues[fields[i]];
        }
        this.profileData = { ...this.profileData, ...filteredDefaultValues };
    }

    updateProfile(updates) {
        this.profileData = { ...this.profileData, ...updates };
    }

    updateGranteeProfile(updates) {
        if (this.isViewingOwnPortfolio) {
            this.profileData = { ...this.profileData, ...updates };
        } else {
            this.data.granteeProfile = { ...this.data.granteeProfile, ...updates };
        }
    }

    updateFullName(fullName) {
        const firstName = fullName.split(' ')[0];
        const lastName = fullName.split(' ').slice(1).join(' ');
        this.data.profile.firstName = firstName;
        this.data.profile.lastName = lastName;
        this.updateProfile({ fullName, firstName, lastName });
    }

    updateGranteeFullName(fullName) {
        const firstName = fullName.split(' ')[0];
        const lastName = fullName.split(' ').slice(1).join(' ');
        this.data.granteeProfile.firstName = firstName;
        this.data.granteeProfile.lastName = lastName;
        this.updateGranteeProfile({ fullName, firstName, lastName });
    }

    async updatePhoneNumber(phoneNumber, alpha3) {
        // this.updateProfile({phoneNumber});
        try {
            const isValid = await updatePhoneNumber({ phoneNumber, alpha2Code: countryListPhone[alpha3].alpha2, alpha3Code: alpha3 });
            if (!isValid) {
                // errorNotification('Invalid phone number. Please check and try again.');
                return false;
            }
            if (this.isViewingOwnPortfolio) {
                this.data.profile.phoneNumber = phoneNumber;
            } else {
                this.data.profile.granteeProfile = phoneNumber;
            }
            return true;
        } catch (error) {
            // errorNotification('Invalid phone number');
        }
    }

    async updateSettings(section, updates) {
        await updateProfileSettings(section, updates);
    }

    async updateGranteeSettings(section, updates) {
        await updateGranteeProfileSettings(section, updates);
    }

    updateDefaultProfileValues(updates) {
        this.defaultProfileValues = { ...this.defaultProfileValues, ...updates };
    }

    async saveProfileUpdates(fields, passNotification = false) {
        const filteredProfileDataValues = {};
        for (let i = 0; i < fields.length; i++) {
            if (fields[i] === 'fullName') {
                filteredProfileDataValues['firstName'] = this.profileData.fullName.split(' ')[0];
                filteredProfileDataValues['lastName'] = this.profileData.fullName.split(' ').slice(1).join(' ');
            }
            else if (fields[i] === 'monthlyExpenses' && this.profileData.monthlyExpenses === '') {
                filteredProfileDataValues['monthlyExpenses'] = null;
            }
            else if (fields[i] === 'monthlyExpenses' && this.profileData.monthlyExpenses !== '' && this.profileData.monthlyExpenses !== null) {
                filteredProfileDataValues['monthlyExpenses'] = Math.round(this.profileData.monthlyExpenses);
            }
            else if (fields[i] === 'monthlyIncome' && this.profileData.monthlyIncome === '') {
                filteredProfileDataValues['monthlyIncome'] = null;
            }
            else if (fields[i] === 'monthlyIncome' && this.profileData.monthlyIncome !== '' && this.profileData.monthlyIncome !== null) {
                filteredProfileDataValues['monthlyIncome'] = Math.round(this.profileData.monthlyIncome);;
            }
            else {
                filteredProfileDataValues[fields[i]] = this.profileData[fields[i]];
            }
        }
        const data = await saveProfileData(filteredProfileDataValues);
        if (data.statusCode) {
            errorNotification();
        }
        else {
            if (!passNotification) {
                successNotification('Profile updated', { autoClose: 3000 });
            }
            this.updateProfile(data);
            this.updateDefaultProfileValues(data)
            return data;
            // assetUpdated && assetUpdated(data);
            // setAssetsWithCurrentValueAndUpdatePropertyValues(data);
        }
    }

    async changeGranteeFullName(value) {
        try {
            const firstName = value.split(' ')[0];
            const lastName = value.split(' ').slice(1).join(' ');
            await saveGranteeProfileData({ firstName, lastName });
            if (this.isViewingOwnPortfolio) {
                this.updateFullName(value);
            } else {
                this.updateGranteeFullName(value);
            }
            successNotification('Profile updated', { autoClose: 3000 });
        } catch (e) {

        }
    }


    async changePassword(passwordsData) {
        try {
            const data = await changePassword(passwordsData);
            successNotification('Password changed', { autoClose: 3000 });
            return data;
        } catch (error) {
            errorNotification(error.errorCode === 0 ? error.message[0] : 'Wrong credentials provided', { autoClose: false })
        }
    }

    async fetchRolesAndPermissions() {
        this.setLoadingRoles(true);
        try {
            const data = await getRoles();
            this.setRoles(data);
        }
        catch (e) { }
        finally {
            this.setLoadingRoles(false);
        }
    }

    setRoles(roles) {
        const userOwnerRole = {
            granteeName: this.fullName,
            granteeEmail: this.data.email,
            roleDescription: 'Owner',
            role: 'OWNER',
            isOwner: true,
            lastActivity: this.isViewingOwnPortfolio ? 'Just Now' : moment(roles.ownerLastLogin).format('MMM Do, h:mmA'),
        }
        const mappedRoles = roles.sharedAccess.map(role => {
            return {
                ...role,
                lastActivity: role.lastActivityTime ? moment(role.lastActivityTime).format('MMM Do, h:mmA') : 'Invitation sent',
                isActive: !!role.lastActivityTime,
                color: emailToColor(role.granteeEmail),
            }
        })
        this.roles = [userOwnerRole, ...mappedRoles];
    }

    addRole(role) {
        this.roles = [...this.roles, {
            ...role,
            lastActivity: role.lastActivityTime ? moment(role.lastActivityTime).format('MMM Do, h:mmA') : 'Invitation sent',
            isActive: false,
            color: emailToColor(role.granteeEmail),
        }];
    }

    updateRoleInArray(role) {
        const roleIndex = this.roles.findIndex(r => r.id === role.id);
        this.roles[roleIndex] = {
            ...role,
            lastActivity: role.lastActivityTime ? moment(role.lastActivityTime).format('MMM Do, h:mmA') : 'Invitation sent',
        }
    }

    async createRole(data) {
        this.setIsSavingRole(true);
        try {
            const createPermissionData = await grantPermission(data);
            createPermissionData.canReSend = true;
            this.addRole(createPermissionData);
            // this.addRole(data);
            successNotification('Role created', { autoClose: 3000 });
            return data;
        } catch (error) {
            errorNotification('Something went wrong')
            return { error: true }
        } finally {
            this.setIsSavingRole(false);
        }
    }

    async resendInvitation(rowId) {
        const relevantRole = this.roles.find(role => role.id === rowId);
        if (!relevantRole.canReSend) {
            return false;
        }
        try {
            relevantRole.canReSend = false;
            this.updateRoleInArray(relevantRole);
            await resendInvitationMail(rowId);
            successNotification('Invitation resent', { autoClose: 3000 });
        } catch (error) {
            relevantRole.canReSend = true;
            this.updateRoleInArray(relevantRole);
            errorNotification('Something went wrong')
        }
    }

    async updateRole(data) {
        this.setIsSavingRole(true);
        try {
            const { id, granteeName, role, roleDescription } = data;
            const updatedPermission = await updatePermission({ id, granteeName, role, roleDescription });
            this.updateRoleInArray(updatedPermission);
            successNotification('Role updated', { autoClose: 3000 });
            return data;
        } catch (error) {
            errorNotification('Something went wrong')
        } finally {
            this.setIsSavingRole(false);
        }
    }

    async deleteRole(rowId) {
        try {
            await deletePermission(rowId);
            this.roles = this.roles.filter(role => role.id !== rowId);
            successNotification('Role deleted', { autoClose: 3000 });
        } catch (error) {
            errorNotification('Something went wrong')
        }
    }

    async switchAccount(accountId) {
        try {
            const data = await switchAccount(accountId);
            // successNotification('Password changed', { autoClose: 3000 });
            window.location.href = ROUTES.wealth.url;
            // reload();
            return data;
        } catch (error) {
            errorNotification('Something went wrong', { autoClose: false });
        }
    }

    async startMyPortfolio() {
        try {
            const data = await startMyPortfolio();
            // successNotification('Password changed', { autoClose: 3000 });
            window.location.href = '/';
            // reload();
            return data;
        } catch (error) {
            errorNotification('Something went wrong', { autoClose: false })
        }
    }


    setIsOnBoardingDone() {
        this.data.profile.isOnBoarding = false;
    }

    setIsFirstPayment() {
        this.isFirstPayment = true;
    }

    isSuccessPayment() {
        this.isAfterSuccessPayment = true;
    }

    // isActionBlocked(action){
    //     return (this.isDemoMode && blockedActionsForDemo.includes(action)) ||
    //             (this.isReadOnlyMode && blockedActionsForReadOnly.includes(action))
    // }
    isActionBlockedByRole(action) {
        return !this.isViewingOwnPortfolio && blockedActionsByRole[this.currentRole]?.includes(action); // bring back up when finished development
        // return blockedActionsByRole[this.myRole].includes(action);
    }

    isActionBlockedByPaymentStatus(action) {
        const paymentStatuses = this.data?.profile?.paymentStatus;
        return paymentStatuses.some(paymentStatus => blockedActionsByPaymentStatus[paymentStatus]?.includes(action));
    }

    get isRelevantVariant() {
        return (this.isNoneTier || this.isDemoTier) ? this.abTestVariant : false;
    }

    closeGoodBeyModal() {
        this.data.profile.showCancelSubscriptionPopup = false;
        this.updateSettings('modals', { showCancelSubscriptionPopup: false });
    }

    closePaymentRejectedModal() {
        if (this.data.profile.showPaymentRejectedPopup?.show) {
            this.data.profile.showPaymentRejectedPopup.show = false;
            this.updateSettings('modals', { showPaymentRejectedPopup: { ...this.data.profile.showPaymentRejectedPopup, show: false } });
        }
    }

    closeSuccessPaymentModal() {
        this.data.profile.showSuccessPaymentPopup = false;
        this.updateSettings('modals', { showSuccessPaymentPopup: false });
    }

    closeUpdatePlanModal() {
        this.data.profile.showSuccessUpdatePlanPopup.show = false;
        this.updateSettings('modals', { showSuccessUpdatePlanPopup: { show: false } });
    }

    closePortfolioUnlockModal() {
        this.data.profile.showPortfolioUnlockPopup = false;
        this.updateSettings('modals', { showPortfolioUnlockPopup: false });
    }

    closeCommunityWelcomeModal() {
        if (this.data.profile.showCommunityWelcomePopup) {
            this.data.profile.showCommunityWelcomePopup = false;
            this.updateSettings('modals', { showCommunityWelcomePopup: false });
        }
    }

    closeFreeTierReportsModal() {
        if (this.data.profile.showFreeTierReportsPopup) {
            this.data.profile.showFreeTierReportsPopup = false;
            this.updateSettings('modals', { showFreeTierReportsPopup: false });
        }
    }

    closeFreeTierCommunityModal() {
        if (this.data.profile.showFreeTierCommunityPopup) {
            this.data.profile.showFreeTierCommunityPopup = false;
            this.updateSettings('modals', { showFreeTierCommunityPopup: false });
        }
    }

    closeTier4FirstTimeExperienceModal() {
        if (this.data.profile.showTier4FirstTimeExperiencePopup) {
            this.data.profile.showTier4FirstTimeExperiencePopup = false;
            this.updateSettings('modals', { showTier4FirstTimeExperiencePopup: false });
        }
    }

    setOnBoardingToGetStarted() {
        this.data.profile.onBoardingStep = 'get_started';
    }

    setGetStartedStepAsComplete(step) {
        if (this.data.profile.getStartedPane && this.data.profile.getStartedPane[step] !== true) {
            this.data.profile.getStartedPane[step] = true;
        }
    }

    setDemoStepAsComplete(step) {
        if (this.data.profile.demoStartedPane && this.data.profile.demoStartedPane[step] !== true) {
            this.data.profile.demoStartedPane[step] = true;
        }
    }

    setGetStartedActiveStepId(id) {
        this.getStartedActiveStepId = id;
    }

    setAuthStep(step) {
        this.prevAuthStep = this.authStep;
        this.authStep = step;
    }

    setAuthFields(field, value) {
        this.authFields[field] = value;
    }

    sendCancelSurveyAnswer(answer) {
        sendCancelSurvey(answer);
    }

    setShowTier4FirstTimeExperienceModal = (show) => {
        this.showTier4FirstTimeExperienceModal = show;
    }

    setShowLimitedSharedAccess = (show) => {
        this.showLimitedSharedAccess = show;
    }

    get fullName() {
        return capitalize(this.data.profile?.firstName) + (this.data.profile?.lastName ? ' ' + this.data.profile?.lastName : '');
    }

    get granteeFullName() {
        return this.isViewingOwnPortfolio ? this.fullName : capitalize(this.data.granteeProfile?.firstName) + (this.data.granteeProfile?.lastName ? ' ' + this.data.granteeProfile?.lastName : '');
    }

    get granteeEmail() {
        return this.isViewingOwnPortfolio ? this.data.email : this.data.granteeProfile?.email;
    }

    get granteeId() {
        return this.isViewingOwnPortfolio ? this.data.id : this.data.granteeProfile?.id;
    }

    get defaultBeneficiary() {
        return this.metadataStore.beneficiaries.find(b => b.isDefault)?.name || (this.data.profile?.firstName + ' ' + this.data.profile?.lastName);
    }

    get isDismissedSwitchUser() {
        return this.isViewingOwnPortfolio ? this.data.profile?.dismissedSwitchUser : this.data.granteeProfile?.dismissedSwitchUser;
    }


    get isOnBoarding() {
        return this.data?.profile?.isOnBoarding
    }

    get isGetStarted() {
        return this.data.profile?.onBoardingStep === 'get_started';
    }

    get getStartedDoneSteps() {
        return this.isGetStarted && this.data.profile.getStartedPane ? Object.keys(this.data.profile.getStartedPane).filter(key => this.data.profile.getStartedPane[key]) : [];
    }

    get completePercentages() {
        const isBookACallExists = this.getStartedDoneSteps.includes('bookACall');
        return (this.getStartedDoneSteps.length + (isBookACallExists ? 0 : 1)) * 20;
    }

    get getDemoDoneSteps() {
        return this.isDemoMode && this.data.profile.demoStartedPane ? Object.keys(this.data.profile.demoStartedPane).filter(key => this.data.profile.demoStartedPane[key]) : [];
    }

    get completeDemoPercentages() {
        return (this.getDemoDoneSteps.length + 1) * 25;
    }

    get isWelcome() {
        return this.data.profile?.onBoardingStep === 'welcome';
    }

    get isQuestionnaire() {
        return this.data.profile?.onBoardingStep === 'questionnaire';
    }

    get isDemoMode() {
        return this.data?.profile?.paymentStatus?.includes('demo');
        // return this.data?.profile?.onBoardingStep === 'demo';
    }

    get isReadOnlyMode() {
        return !this.isAfterSuccessPayment && this.data?.profile?.paymentStatus?.includes('readonly');
    }

    get isDemoOrReadOnlyMode() {
        return this.isDemoMode || this.isReadOnlyMode;
    }

    get isRedeemed() {
        return this.data?.profile?.paymentStatus?.includes('redeemed');
    }

    get isBetaUser() {
        return this.data?.profile?.paymentStatus?.includes('isBetaUser');
    }

    get isFreeUser() {
        return this.data?.profile?.paymentStatus?.includes('free');
    }

    get isNoneTier() {
        return this.data?.profile?.tier === 'NONE';
    }

    get isPaymentFailedMode() {
        return this.data?.profile?.paymentStatus?.includes('payment-failed');
    }

    get firstNameCapitalize() {
        return capitalize(this.data?.profile?.firstName);
    }

    get firstNameFirstLetterCapitalize() {
        return this.data?.profile?.firstName ? this.data?.profile?.firstName.charAt(0).toUpperCase() : '';
    }

    get phoneAlpha3() {
        return this.data?.profile?.phoneAlpha3 || 'USA';
        // return alpha3ByCountryCode[this.data?.profile?.phoneNumber?.split('-')?.[0]] || 'USA';
    }

    get phoneNumber() {
        return this.data?.profile?.phoneNumber?.split('-')?.[1] || '';
    }

    get granteeFirstNameCapitalize() {
        return this.isViewingOwnPortfolio ? this.firstNameCapitalize : capitalize(this.data?.granteeProfile?.firstName);
    }

    get granteeLastName() {
        return this.isViewingOwnPortfolio ? this.data?.profile?.lastName : this.data?.granteeProfile?.lastName;
    }

    get granteeFirstNameFirstLetterCapitalize() {
        return this.isViewingOwnPortfolio ? this.firstNameFirstLetterCapitalize : this.data?.granteeProfile?.firstName.charAt(0).toUpperCase();
    }

    get granteePhoneNumber() {
        return this.isViewingOwnPortfolio ? this.phoneNumber : this.data?.granteeProfile?.phoneNumber?.split('-')?.[1] || '';
    }

    get granteePhoneAlpha3() {
        return this.isViewingOwnPortfolio ? this.phoneAlpha3 : this.data?.granteeProfile?.phoneAlpha3 || 'USA';
    }

    get isDemoTier() {
        return this.data?.profile?.tier === 'DEMO';
    }

    get isFreeTier() {
        return this.data?.profile?.tier === 'TIER_1';
    }

    get isTier2() {
        return this.data?.profile?.tier === 'TIER_2';
    }

    get isTier3() {
        return this.data?.profile?.tier === 'TIER_3';
    }

    get isTier4() {
        return this.data?.profile?.tier === 'TIER_4';
    }

    get isTier1or2() {
        return ['TIER_1', 'TIER_2'].includes(this.data?.profile?.tier);
    }

    get isTier2or3() {
        return ['TIER_2', 'TIER_3'].includes(this.data?.profile?.tier);
    }

    get isPayedTier() {
        return ['TIER_2', 'TIER_3', 'TIER_4'].includes(this.data?.profile?.tier);
    }

    get tierIndex() {
        return ['TIER_1', 'TIER_2', 'TIER_3', 'TIER_4'].indexOf(this.data?.profile?.tier);
    }

    get isScoreTier4() {
        return this.data?.profile?.scoreTier === 4;
    }

    get isGranteeHavePortfolio() {
        return this.isViewingOwnPortfolio ? (this.data?.profile?.tier !== 'NONE') : (this.data?.granteeProfile?.tier !== 'NONE');
    }

    get isManuallyFreeTier() {
        return this.isTier2or3 && !this.isRedeemed;
    }

    get userLimitations() {
        return this.data?.tierLimitations || {};
    }

    get isCreateItemManuallyLimited() {
        return this.userLimitations.manualActiveItems.manualItems !== null && this.userLimitations.manualActiveItems.manualItems <= this.metadataStore.manualItemsCount
    }

    get isAddSyncInstitutionLimited() {
        const connectedInstitutionsCount = this.metadataStore.connectedInstitutions?.filter(inst => inst.connectedProvider !== 'vip').length;

        return this.userLimitations?.financialInstitutionSync?.syncedInstitutions !== null && this.userLimitations?.financialInstitutionSync?.syncedInstitutions <= connectedInstitutionsCount
    }

    get isVipSyncInstitutionLimited() {
        const hasVipSync = this.userLimitations?.vipSync;
        const vipSyncedInstitutions = this.userLimitations?.financialInstitutionSync?.vipSyncedInstitutions;
        const connectedVipInstitutionsCount = this.metadataStore.connectedInstitutions?.filter(inst => inst.connectedProvider === 'vip').length;
        return !hasVipSync || (vipSyncedInstitutions !== null && vipSyncedInstitutions <= connectedVipInstitutionsCount);
    }

    get getTierType() {
        if (this.data?.profile?.paymentStatus.includes('readonly')) {
            return 'READ_ONLY'
        }
        return this.data?.profile?.tier
    }

    get isViewingOwnPortfolio() {
        return !this.data?.hasOwnProperty('granteeProfile');
    }

    get isHavingSharedPortfolios() {
        return this.data?.sharedAccess?.length > 0;
    }

    get currentPortfolioDetails() {
        if (this.isViewingOwnPortfolio) {
            return {
                name: this.fullName,
                permission: 'full-access',
                role: 'OWNER',
                roleDescription: 'Owner'
            }
        } else {
            const currentPortfolioIndex = this.sharedPortfolios?.findIndex(access => access.ownerId === this.data?.id);
            const currentPortfolio = this.sharedPortfolios?.[currentPortfolioIndex];
            return {
                ...currentPortfolio,
                permission: permissionMapping[currentPortfolio.role],
            };
        }
    }

    get currentRole() {
        return this.currentPortfolioDetails?.role || null;
    }

    get sharedPortfolios() {
        return this.data?.sharedAccess?.map((access, index) => {
            return {
                index,
                ownerId: access.ownerId,
                role: access.role,
                roleDescription: access.roleDescription,
                name: capitalize(access.ownerDetails.firstName) + (access.ownerDetails.lastName ? ' ' + access.ownerDetails.lastName : ''),
                color: rolesColorPlates[access.ownerId % rolesColorPlates.length],
            }
        })
    }
    get isEmptySharedSideMenu() {
        return [ROUTES.portfolios.url].includes(this.uiStore.currentRoute) || ([ROUTES.profile.url].includes(this.uiStore.currentRoute) && (!this.isViewingOwnPortfolio || this.isNoPortfolioSelected));
    }

    get isNoPortfolioSelected() {
        return this.isViewingOwnPortfolio && this.data?.profile?.tier === 'NONE';
    }

    get actionBlockedByRolePrompt() {
        return `You are ${this.currentPortfolioDetails.roleDescription} for this portfolio with ${this.currentPortfolioDetails.permission.toLowerCase()} permissions.\nTo extend permissions, contact the account owner/admin.`
    }
}