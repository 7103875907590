import React from 'react'
import { useHistory } from 'react-router-dom';
import { CashIcon, SimulationsIcon } from '../../../../assets/icons/common/common-icons';
import { ModifiedTooltip } from '../../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { navigateToUrl } from '../../../../common/utils/navigation.utils';
import { displayMoneyValue, floatFixed } from '../../../../common/utils/number-display.utils';
import { isNullOrUndefinedOrZeroOrEmptyString } from '../../../../common/utils/object.utils';
import { getUrlDisplayName } from '../../../../common/utils/string.utils';
import { useStore } from '../../../app/data/root.store';
import { AssetClassIcon } from '../../../assets/components/AssetClassIcon';
import { ExpectedTransactionItemRowWrapper, HoldingsBadgeText, HoldingsBadgeTriangle, HoldingsBadgeWrapper, ItemDescription, ItemIconWrapper, ItemTitle, ItemTitleLink, ItemTitleWrapper, TransactionAndAssetDetails, TransactionItemCashBox, TransactionsDetails, TransactionsStatusIconWrapper } from './TransactionsPanel.styles';
import { TransactionsStatusIcon } from './TransactionsStatusIcon';

export const ExpectedTransactionItemRow = (props) => {
  const { trn, trnRelatedClass, isSimulation, isProfileEvent, isIncognito, setShowCostOfLivingModal } = props;
  const { metadataStore } = useStore();
  let history = useHistory();

  const handleItemClick = (trn) => {
    const astClass = metadataStore.classesObj[metadataStore.categoriesObj[trn.itemCategoryId].classId];
    navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(astClass.title)}/${trn.itemId}/events`);
  }

  const handleProfileItemClick = () => {
    setShowCostOfLivingModal && setShowCostOfLivingModal(true);
    // navigateToUrl(history, `/profile/financial`);
  }

  return (
    <ExpectedTransactionItemRowWrapper>
      <TransactionsDetails>
        {trnRelatedClass && <>
          <ItemIconWrapper color={trnRelatedClass.color} >
            <AssetClassIcon name={trnRelatedClass.icon} />
          </ItemIconWrapper>
          <TransactionAndAssetDetails>
            <ItemTitleWrapper>
              <ItemTitleLink onClick={() => { handleItemClick(trn) }}>{trn.itemTitle}</ItemTitleLink>
              {!isNullOrUndefinedOrZeroOrEmptyString(trn.itemHoldings) && trn.itemHoldings !== 1 ?
                <HoldingsBadgeWrapper><HoldingsBadgeText>{floatFixed(trn.itemHoldings * 100, 2)}%</HoldingsBadgeText><HoldingsBadgeTriangle /></HoldingsBadgeWrapper>
                : null}
            </ItemTitleWrapper>
            <ItemDescription>{trn.description}</ItemDescription>
          </TransactionAndAssetDetails>
        </>
        }
        {
          isSimulation &&
          <>
            <ItemIconWrapper color={'#F6E4FA'} isSimulation >
              <SimulationsIcon />
            </ItemIconWrapper>
            <TransactionAndAssetDetails>
              <ItemTitle>{trn.description}
              </ItemTitle>
              <ItemDescription>{trn.frequency}</ItemDescription>
            </TransactionAndAssetDetails>
          </>
        }
        {
          isProfileEvent && <>
            <ItemIconWrapper color={'#9097A3'} >
              <CashIcon />
            </ItemIconWrapper>
            <TransactionAndAssetDetails>
              <ItemTitleLink onClick={handleProfileItemClick}>{trn.usdValue > 0 ? 'W-2 income' : 'Household'}</ItemTitleLink>
              <ItemDescription>Monthly {trn.usdValue > 0 ? 'income' : 'expenses'}</ItemDescription>
            </TransactionAndAssetDetails>
          </>
        }
      </TransactionsDetails>
      <TransactionItemCashBox cashIn={trn.usdValue > 0}>
        {displayMoneyValue(trn.usdHoldingValue, 'USD', isIncognito)}
      </TransactionItemCashBox>
      <ModifiedTooltip title={'This is an expected cashflow event'}>
        <TransactionsStatusIconWrapper isSimulation={isSimulation} bgColor={isSimulation ? '#F6E4FA' : '#FAF1E4'}>
          <TransactionsStatusIcon iconName={isSimulation ? 'simulation' : 'default'} />
        </TransactionsStatusIconWrapper>
      </ModifiedTooltip>
    </ExpectedTransactionItemRowWrapper>
  )
}
