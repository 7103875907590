import { Observer } from 'mobx-react';
import React from 'react';
import { useTrackEvent, useTrackGTMEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { useStore } from '../../../app/data/root.store';


import bgImg from './assets/mobile-app-pricing-modal-bg.jpg';

import { ActionButton, MobileAppModalWrapper, ModalCloseButtonWrapper, ModalTitle, PlanIconWrapper, PlanItemDescription, PlanItemTexts, PlanItemTitle, PlanItemWrapper, PlansListWrapper, PlansTitle } from './MobileAppPricingModal.styles';
import { postData } from '../../../../common/utils/network.utils';

const PlusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <path d="M27.6923 0.5H12.3077C5.51034 0.5 0 6.01034 0 12.8077V28.1923C0 34.9897 5.51034 40.5 12.3077 40.5H27.6923C34.4897 40.5 40 34.9897 40 28.1923V12.8077C40 6.01034 34.4897 0.5 27.6923 0.5Z" fill="#CFCFCF" fill-opacity="0.56"/>
    <path d="M12.3077 1H27.6923C34.2135 1 39.5 6.28648 39.5 12.8077V28.1923C39.5 34.7135 34.2135 40 27.6923 40H12.3077C5.78648 40 0.5 34.7135 0.5 28.1923V12.8077C0.5 6.28648 5.78648 1 12.3077 1Z" stroke="white" stroke-opacity="0.18"/>
    <g transform="translate(8, 9)"><path d="M6.38998 4.2168L14.048 17.7864L12.2637 20.9479L2.82129 4.2168H6.38998ZM9.95868 4.2168L11.7254 7.34754H16.3704L14.048 11.4627L15.8324 14.6243V14.6249L21.7064 4.2168H9.95868Z" fill="white"/></g>
  </svg>
)

const PremiumIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <path d="M27.6923 0.5H12.3077C5.51034 0.5 0 6.01034 0 12.8077V28.1923C0 34.9897 5.51034 40.5 12.3077 40.5H27.6923C34.4897 40.5 40 34.9897 40 28.1923V12.8077C40 6.01034 34.4897 0.5 27.6923 0.5Z" fill="#6F6F6F" fill-opacity="0.56"/>
    <path d="M12.3077 1H27.6923C34.2135 1 39.5 6.28648 39.5 12.8077V28.1923C39.5 34.7135 34.2135 40 27.6923 40H12.3077C5.78648 40 0.5 34.7135 0.5 28.1923V12.8077C0.5 6.28648 5.78648 1 12.3077 1Z" stroke="white" stroke-opacity="0.18"/>
    <g transform="translate(8, 9)"><path d="M6.38998 4.2168L14.048 17.7864L12.2637 20.9479L2.82129 4.2168H6.38998ZM9.95868 4.2168L11.7254 7.34754H16.3704L14.048 11.4627L15.8324 14.6243V14.6249L21.7064 4.2168H9.95868Z" fill="white"/></g>
  </svg>
)

const EliteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <path d="M27.6923 0.5H12.3077C5.51034 0.5 0 6.01034 0 12.8077V28.1923C0 34.9897 5.51034 40.5 12.3077 40.5H27.6923C34.4897 40.5 40 34.9897 40 28.1923V12.8077C40 6.01034 34.4897 0.5 27.6923 0.5Z" fill="black" fill-opacity="0.56"/>
    <path d="M12.3077 1H27.6923C34.2135 1 39.5 6.28648 39.5 12.8077V28.1923C39.5 34.7135 34.2135 40 27.6923 40H12.3077C5.78648 40 0.5 34.7135 0.5 28.1923V12.8077C0.5 6.28648 5.78648 1 12.3077 1Z" stroke="white" stroke-opacity="0.18"/>
    <g transform="translate(8, 9)"><path d="M6.38998 4.2168L14.048 17.7864L12.2637 20.9479L2.82129 4.2168H6.38998ZM9.95868 4.2168L11.7254 7.34754H16.3704L14.048 11.4627L15.8324 14.6243V14.6249L21.7064 4.2168H9.95868Z" fill="white"/></g>
  </svg>
)

const ElitePrefixIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99991 3.32126L9.9998 3.32104V3.32148L6.26786 10.6872L1.75 6.26752V15.6961H10V15.696H18.2498V6.26747L13.7319 10.6871L10 3.32143V3.32109L9.99991 3.32126Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4252 19.4285H2.5752V17.4485H17.4252V19.4285Z" fill="white"/>
  </svg>
)

const plans = [
  {title: 'Plus', icon: <PlusIcon />, description: 'Tailored for passive investors with growing portfolios.'},
  {title: 'Premium ' , icon: <PremiumIcon /> , description: 'Designed for experienced investors with diverse portfolios.'},
  {title: 'Elite', titlePrefixIcon: <ElitePrefixIcon/>, icon: <EliteIcon /> , description: 'Ultimate wealth management for sophisticated investors.'}
]

export const MobileAppPricingModal = ({ onClose }) => {
  const { userStore } = useStore();

  const [isLinkSent, setIsLinkSent] = React.useState(false);

  useTrackEvent('Mobile app Pricing Table Viewed', {
    tier: userStore.data?.profile?.tier,
    url: window.location.href,
    recommended_tier: userStore.data?.profile?.scoreTier,
    onboarding_step: userStore.data?.profile?.onBoardingStep,
    payment_status: userStore.data?.profile?.paymentStatus?.join(),
  });
  useTrackGTMEvent('Mobile app Pricing Table Viewed', {
    tier: userStore.data?.profile?.tier,
    url: window.location.href,
    recommended_tier: userStore.data?.profile?.scoreTier,
    onboarding_step: userStore.data?.profile?.onBoardingStep,
    payment_status: userStore.data?.profile?.paymentStatus?.join(),
  });

  const handleSendLink = () => {
    if (isLinkSent){
      return;
    } 
    postData('payments/send-app-link', {  }).then((res) => {
      setIsLinkSent(true);
    });
    
  };

  return (<Observer>{() => (
    // <PageWrapper style={{ width: '100%'}}> 
    <MobileAppModalWrapper bgImage={bgImg} >
       <ModalCloseButtonWrapper onClick={onClose} style={{ top: '16px' }}><ModalCloseButton /></ModalCloseButtonWrapper>
      <ModalTitle>Explore Vyzer’s<br/>plans on desktop</ModalTitle>
      <PlansTitle>Available plans</PlansTitle>
      <PlansListWrapper>
        {plans.map((plan,index)=>(
          <PlanItemWrapper key={index}>
            <PlanIconWrapper>{plan.icon}</PlanIconWrapper>
            <PlanItemTexts>
              <PlanItemTitle>{plan.title} {plan.titlePrefixIcon}</PlanItemTitle>
              <PlanItemDescription>{plan.description}</PlanItemDescription>
            </PlanItemTexts>
          </PlanItemWrapper>
        ))}
      </PlansListWrapper>
      <PlansTitle style={{textAlign:'center',marginTop:'16px' , padding:'0 32px'}}>You can’t explore or change plans here in the app, we know it’s not ideal...</PlansTitle>
       <ActionButton  style={{marginTop:'24px'}} onClick={onClose}>Got it</ActionButton>
       <ActionButton disabled={isLinkSent} black style={{marginTop:'24px' , marginBottom:'40px'}} onClick={()=>{handleSendLink()}}>{isLinkSent ? 'Link sent to your email' : <>Send me link to my email <GetLinkSvg/></>}</ActionButton>
       </MobileAppModalWrapper>
    //  </PageWrapper> 
  )}</Observer>)
}


const GetLinkSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
<path d="M7.35607 12L4.47674 9.12069L5.34252 6.65749L2.87932 7.52327L1.60771e-07 4.64395L12 0L7.35607 12Z" fill="white"/>
</svg>
)


const ModalCloseButton = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="6.28033" y1="5.83393" x2="18.3011" y2="17.8547" stroke="white" strokeWidth="1.5" />
      <line x1="5.92676" y1="17.8544" x2="17.9476" y2="5.83362" stroke="white" strokeWidth="1.5" />
    </svg>
  )
}


