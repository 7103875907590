import { makeAutoObservable  } from "mobx";
import { addSimulation, deleteSimulation, fetchCashFlowData, toggleSimulation, updateCostOfLivingData, updateSimulation } from './cash-flow.service';
import moment from 'moment';
import { errorNotification, successNotification } from "../../layout/components/Notifications/notifications";
import { addMonths } from "../../../common/utils/dates.utils";
import bgImg from '../components/assets/img/selected_month.svg'; //' ./assets/bg.jpg';
//import mobileSelectedImg from '../components/assets/img/mobile_selected_month.svg'; //' ./assets/bg.jpg';

const monthsOfHistory = 0;
const colors = ['#77b99b', '#800080', '#ffa500', '#49dca7', '#716dea', '#e4a4c1'];

var icon = new Image();
icon.style.zIndex = 9999;
icon.src = bgImg;  //'https://i.stack.imgur.com/YvlWY.png';


const cnvsCtx = document.createElement('canvas').getContext('2d');
var gradient = cnvsCtx.createLinearGradient(0, 0, 0, 400);
gradient.addColorStop(0, '#FFD2D280');
gradient.addColorStop(1, '#FF585880');

// #FFD2D280 , #FF585880
export class CashFlowStore {
    data = {};
    singleAccountData = {};
    isLoading = false;
    isLoadingSimulationsChanges = false;
    displayedAccounts = [];
    displayMonths = 12;
    displayTransactionsMonth = 0;
    displayTransactionsDataSet = 0;
    isSingleAccountMode = false;
    isAnimateDateChange = false;
    showTransactionsPanel = false;
    showScenariosPanel = false;
    editedScenario = {};
    showScenariosLimitationPopup = false;
    shouldStartLearnTour = false;
    hoverDataIndex = 0;
    hoverMonthIndex = 0;

    constructor(uiStore) {
        makeAutoObservable(this);
        this.uiStore = uiStore;
    }

    getData = async (withSimulationsData) => {
        this.setLoading(true);

        try {
            const data = await fetchCashFlowData(withSimulationsData);
            this.setData(data);
        }
        //catch (e) {} 
        finally {
            this.setLoading(false);
        }
    }

    setLoading(isLoading) {
        this.isLoading = isLoading;
    }

    setLoadingSimulationsChanges(isLoading) {
        this.isLoadingSimulationsChanges = isLoading;
    }

    setDisplayAccounts(accounts) {
        this.displayedAccounts = accounts;
    }

    setDisplayMonths(months) {
        this.displayMonths = months;
    }

    setShowTransactionsPanel(show) {
        this.showTransactionsPanel = show;
    }

    setShowScenariosPanel(show) {
        this.showScenariosPanel = show;
    }

    setShowScenariosLimitationPopup(show) {
        this.showScenariosLimitationPopup = show;
    }

    setShouldStartLearnTour(start) {
        this.shouldStartLearnTour = start;
    }
    
    setEditedScenario(scenario) {
        this.editedScenario = scenario;
    }

    resetData(){
        this.displayTransactionsDataSet = 0;
        this.displayTransactionsMonth = 0;
    }

    setDisplayTransactions(datasetIndex, monthIndex) {
        if (this.displayTransactionsMonth !== monthIndex || this.displayTransactionsDataSet !== datasetIndex){
            this.displayTransactionsMonth = monthIndex;
            this.displayTransactionsDataSet = datasetIndex;
            this.setIsAnimateDateChange(true);
        }
    }

    setIsAnimateDateChange(isAnimate) {
        this.isAnimateDateChange = isAnimate;
        if (isAnimate === true){
            setTimeout(()=>{
                this.setIsAnimateDateChange(false);
            },200)
        }
    }

    setHoveredState(datasetIndex, monthIndex){
        this.hoverDataIndex = datasetIndex;
        this.hoverMonthIndex = monthIndex;
    }
    
    setData(data) {
        const cashAccountsObj = data.cashAccounts.reduce(
            (acc, curr) => ((acc[curr.id] = curr.title + (curr.title && curr.name ? ', ' : '') + curr.name), acc), {} /* eslint-disable-line no-sequences*/
        );
        this.isSingleAccountMode = false;
        this.data = { ...data, cashAccountsObj };
        if (!this.data.costOfLiving.showModal) {
            if (window.Intercom) {
                window.Intercom("update", { cost_of_living_popup_is_up: true })
            }
        }
    }

    updateIntercomCostOfLivingKey() {
        if (window.Intercom) {
            window.Intercom("update", { cost_of_living_popup_is_up: true })
        }
    }

    setSingleAccountData(data) {
        this.isSingleAccountMode = true;
        this.singleAccountData = { ...data }
    }

    updateCostOfLiving = async (costOfLivingData) => {
        this.setLoading(true);
        try {
            const data = await updateCostOfLivingData(costOfLivingData);
            this.setData(data);
        }
        //catch (e) {} 
        finally {
            this.setLoading(false);
        }
    }

    saveSimulation = async (simulation, cb) => {
        this.setLoadingSimulationsChanges(true);
        const { inEx, ...simulationProps } = simulation;
        if (inEx === 'expense') {
            simulationProps.amount = simulationProps.amount * -1;
        }
        if (simulation.id) {
            try {
                const data = await updateSimulation(simulation.id, simulationProps);
                if (data.statusCode) {
                    errorNotification('Something went wrong')
                }
                else {
                    successNotification('Scenario saved');
                    cb && cb();
                    this.setData(data);
                }
            }
            catch (e) { errorNotification('Something went wrong'); }
            finally {
                this.setLoadingSimulationsChanges(false);
            }
        } else {
            try {
                const data = await addSimulation(simulationProps);
                if (data.statusCode) {
                    errorNotification('Something went wrong')
                }
                else {
                    successNotification('Scenario added');
                    cb && cb();
                    this.setData(data);
                }
            }
            catch (e) { errorNotification('Something went wrong'); }
            finally {
                this.setLoadingSimulationsChanges(false);
            }
        }
    }

    toggleSimulation = async (simulationId, isChecked) => {
        this.setLoadingSimulationsChanges(true);
        try {
            const data = await toggleSimulation({ simulationId, isChecked });
            if (data.statusCode) {
                errorNotification('Something went wrong')
            }
            else {
                this.setData(data);
            }
        }
        catch (e) { errorNotification('Something went wrong'); }
        finally {
            this.setLoadingSimulationsChanges(false);
        }
    }

    deleteSimulation = async (simulationId) => {
        this.setLoadingSimulationsChanges(true);
        try {
            const data = await deleteSimulation(simulationId);
            if (data.statusCode) {
                errorNotification('Something went wrong');
            }
            else {
                successNotification('Scenario deleted');
                this.setData(data);
            }
        }
        catch (e) { errorNotification('Something went wrong'); }
        finally {
            this.setLoadingSimulationsChanges(false);
        }
    }

    get sortedSimulations (){
        return this.data.simulations && this.data.simulations?.length > 0 ? this.data.simulations.slice().sort(({amount:a},{amount:b})=>{
            if (parseFloat(a) < 0 && parseFloat(b) < 0){
               return parseFloat(a) < parseFloat(b) ? -1 : 1
            } 
            return parseFloat(a) > parseFloat(b) ? -1 : 1
        }) : [];
    }

    get transactionsMonthPickerDate() {
        return moment(addMonths(new Date(), this.displayTransactionsMonth - monthsOfHistory));
    }

    get currentPosition() {
        return this.data.currentCashUsdValue; // this.data.cashData ? this.data.cashData[0 + monthsOfHistory] : 0;
    }

    get currentPositionSingleAccount() {
        return this.singleAccountData.cashData ? this.singleAccountData.cashData[0 + monthsOfHistory] : 0;
    }

    get headerBoxes() {
        const orderedAccounts = this.data.cashAccounts ? [...this.data.cashAccounts].sort((a, b) => {
            return parseFloat(a.usdValue) < parseFloat(b.usdValue) ? 1 : -1;
        }) : [];
        const others = {
            holdingsValue: 0,
            currency: 'USD',
            title: '',
            accounts: []
        }
        if (orderedAccounts.length > 3) {
            const otherCounts = orderedAccounts.slice(3).reduce(
                (a, b) => a + parseFloat(b.usdValue), 0
            );
            others.holdingsValue = otherCounts;
            others.title = `+${this.data.cashAccounts.length - 3} Others`;
            others.accounts = orderedAccounts.slice(3)

        }
        return { accounts: orderedAccounts.slice(0, 3), others };
    }

    get chartData() {
        // const currentMonth = new Date(); //.getMonth();
        // const date = new Date();
        const iconSize = this.uiStore.isDesktopView ? 23 : 20;
        icon.width = iconSize;
        icon.height = iconSize;

        const pointsStyle = this.uiStore.isDesktopView ? 'circle' : false;
        
        return {
            //labels: new Array(37).fill(1).map((item,ind)=>("0"+(((currentMonth+ind)%12)+1)).slice(-2)),
            labels: new Array(this.displayMonths + 1 + monthsOfHistory).fill(1).map((item, ind) => {
                const today = new Date();
                const pointData = addMonths(today, ind - monthsOfHistory);
                return moment(pointData).format('MMM YY');
                // new Date(date.setMonth(date.getMonth()+1)) ),
            }),
            datasets: [
                {
                    label: 'All',
                    data: this.data.cashData ? [...this.data.cashData.slice(0, this.displayMonths + 1 + monthsOfHistory)] : [],
                    fill: {
                        target:'origin',
                        below: gradient,
                        above: 'transparent'
                    },
                    borderColor: this.uiStore.isDesktopView ? '#545E64':'#fff' ,
                    lineTension: this.uiStore.isDesktopView ? 0.1 : 0.5,
                    borderWidth: 2,
                    // pointStyle: this.displayTransactionsDataSet === 0 && this.data.cashData ? [...this.data.cashData.slice(0, this.displayMonths + 1 + monthsOfHistory)].map((e,i)=>i === this.displayTransactionsMonth ? icon : 'circle') : 'circle',
                    pointStyle: this.data.cashData ? [...this.data.cashData.slice(0, this.displayMonths + 1 + monthsOfHistory)].map((e,i)=>i === this.displayTransactionsMonth && this.displayTransactionsDataSet === 0 ? icon : pointsStyle) : pointsStyle,
                    pointBorderColor: this.uiStore.isDesktopView ?  '#545E64' : '#fff',
                    pointRadius:  3,
                    pointHoverRadius: this.uiStore.isDesktopView ? 7 : 3,
                    pointBackgroundColor : '#545E64',
                    pointHoverBackgroundColor : this.uiStore.isDesktopView ? '#fff' : '#545E64',
                    order: 4

                },
                ...(this.data.cashFlowChartData ? this.data.cashFlowChartData.map((accountDataSet, index) => ({
                    label: accountDataSet.label,
                    data: [...accountDataSet.data.slice(0, this.displayMonths + 1 + monthsOfHistory)],
                    order: 1,
                    fill: {
                        target:'origin',
                        below: gradient,
                        above: 'transparent'
                    },
                    hidden: !this.displayedAccounts.includes(accountDataSet.id),
                    lineTension: 0.1,
                    pointStyle: this.data.cashData ? [...this.data.cashData.slice(0, this.displayMonths + 1 + monthsOfHistory)].map((e,i)=>i === this.displayTransactionsMonth && this.displayTransactionsDataSet === index+1 ? icon : 'circle') : 'circle',
                    backgroundColor: accountDataSet.label === 'Other' ? 'gray' : colors[index % colors.length],
                    borderColor: accountDataSet.label === 'Other' ? 'gray' : colors[index % colors.length],
                    pointBorderColor: accountDataSet.label === 'Other' ? 'gray' : colors[index % colors.length],
                    pointBackgroundColor : accountDataSet.label === 'Other' ? 'gray' : colors[index % colors.length],
                    borderWidth: 2,
                    pointRadius: 3,
                    pointHoverRadius: 7,
                    pointHoverBackgroundColor : '#fff'
                })) : [])
                // {
                //     label: 'Other',
                //     data: [100015,200017,100018,200020],
                //     fill: false,
                // }
            ]
        }
    }

    get cashFlowChartDataSingleAsset() {
        // const currentMonth = new Date(); //.getMonth();
        // const date = new Date();
        return {
            //labels: new Array(37).fill(1).map((item,ind)=>("0"+(((currentMonth+ind)%12)+1)).slice(-2)),
            labels: new Array(this.displayMonths + 1 + monthsOfHistory).fill(1).map((item, ind) => {
                const today = new Date();
                // const pointData = today.setMonth(today.getMonth()+ind);
                const pointData = addMonths(today, ind - monthsOfHistory);
                return moment(pointData).format('MMM YY')
                // new Date(date.setMonth(date.getMonth()+1)) ),
            }),
            datasets: [
                {
                    label: 'All',
                    data: this.singleAccountData.cashData ? [...this.singleAccountData.cashData.slice(0, this.displayMonths + 1 + monthsOfHistory)] : [],
                    fill: 'origin',
                    backgroundColor: '#545E64',
                    borderColor: '#545E64',
                    lineTension: 0,
                }

                // {
                //     label: 'Other',
                //     data: [100015,200017,100018,200020],
                //     fill: false,
                // }
            ]
        }
    }


    get transactionDrawerData() {
        if (this.displayTransactionsMonth === -1) { // All transactions of account
            return {
                transactions: []
            }
            // const checkAccountId = this.displayTransactionsDataSet === this.data.cashFlowChartData.length ? null : this.data.cashFlowChartData[this.displayTransactionsDataSet - 1].id;
            // return {
            //     account: this.data.cashFlowChartData[this.displayTransactionsDataSet - 1].label,
            //     transactions: this.data.groupByMonths.map(tranGroup => tranGroup.filter(trn => trn.cashAccount === checkAccountId)).slice(1, this.displayMonths + 1)
            // };
        } else {
            const today = new Date();
            const pointDate = addMonths(today, this.displayTransactionsMonth - monthsOfHistory);
            let datasetTransactions = this.data.groupByMonths ? this.data.groupByMonths[this.displayTransactionsMonth] : [];
            let displayAccount = 'All accounts';
            if (this.displayTransactionsDataSet > 0) {
                const checkAccountId = this.displayTransactionsDataSet === this.data.cashFlowChartData.length ? null : this.data.cashFlowChartData[this.displayTransactionsDataSet - 1].id;
                datasetTransactions = datasetTransactions.filter(trn => trn.cashAccount === checkAccountId);
                displayAccount = this.data.cashFlowChartData[this.displayTransactionsDataSet - 1].label;
            }
            if (this.isSingleAccountMode) {
                displayAccount = this.singleAccountData.accountName;
                datasetTransactions = this.singleAccountData.futureTransactions && this.displayTransactionsMonth > 0 ? this.singleAccountData.futureTransactions[this.displayTransactionsMonth - 1] : [];
            }
            const sortedCashInTransactions = datasetTransactions.filter(trn => trn.usdHoldingValue > 0).sort((a, b) => {
                return a.usdHoldingValue < b.usdHoldingValue ? 1 : a.usdHoldingValue > b.usdHoldingValue ? -1 : 0;
            });
            const sortedCashOutTransactions = datasetTransactions.filter(trn => trn.usdHoldingValue < 0).sort((a, b) => {
                return a.usdHoldingValue < b.usdHoldingValue ? -1 : a.usdHoldingValue > b.usdHoldingValue ? 1 : 0;
            });
            return {
                account: displayAccount,
                isAllAccounts: this.displayTransactionsDataSet === 0,
                date: moment(pointDate).format('MMM YYYY'),
                transactions: [...sortedCashInTransactions, ...sortedCashOutTransactions],
            }
        }
    }

    get expectedTransactionsOnSelectedMonth() {
        return this.transactionDrawerData.transactions.filter(trn => !trn.hasHappened);
    }

    get expectedTransactionCashIn() {
        return this.expectedTransactionsOnSelectedMonth.filter(trn => trn.usdHoldingValue > 0).reduce((partialSum, a) => partialSum + a.usdHoldingValue, 0);
    }

    get expectedTransactionCashOut() {
        return this.expectedTransactionsOnSelectedMonth.filter(trn => trn.usdHoldingValue < 0).reduce((partialSum, a) => partialSum + a.usdHoldingValue, 0);
    }

    get actualTransactionsOnSelectedMonth() {
        return this.transactionDrawerData.transactions.filter(trn => trn.hasHappened);
    }

    get hasAnyCashDataEvents() {
        return this.data?.cashData?.some(amount => amount !== 0);
    }

    get hasCostOfLiving() {
        return !this.data?.costOfLiving?.showModal;
    }

    get monthsList() {
        return this.data?.groupByMonths ? this.data?.groupByMonths.map((group, index) => {
            const cashIn = group.filter(trn => trn.usdHoldingValue > 0).reduce((partialSum, a) => partialSum + a.usdHoldingValue, 0);
            const cashOut = group.filter(trn => trn.usdHoldingValue < 0).reduce((partialSum, a) => partialSum + a.usdHoldingValue, 0);
            const remainingCashIn = group.filter(trn => trn.usdHoldingValue > 0 && !trn.hasHappened).reduce((partialSum, a) => partialSum + a.usdHoldingValue, 0);
            const remainingCashOut = group.filter(trn => trn.usdHoldingValue < 0 && !trn.hasHappened).reduce((partialSum, a) => partialSum + a.usdHoldingValue, 0);
            return { count: group.length, monthIndex: index - monthsOfHistory, cashIn, cashOut , remainingCashIn  , remainingCashOut};
        }).reverse() : [];
    }
}

