
import React from 'react';
import { Observer} from 'mobx-react';
import { useStore } from '../../../modules/app/data/root.store';
import { useHistory } from "react-router-dom";
import { ItemIconWrapper, ItemTitle, LinkWrapper } from './ItemLink.styles';
import { AssetClassIcon } from '../../../modules/assets/components/AssetClassIcon';
import { navigateToUrl } from '../../utils/navigation.utils';
import { getUrlDisplayName } from '../../utils/string.utils';

export function ItemLink(props) {
    const { id, onItemClick, titleOverflow } = props;

    const {metadataStore} = useStore();
    let history = useHistory();

    const itemTitle = metadataStore.itemsMeta[id].title
    const itemClassId = metadataStore.itemsMeta[id].classId;
    const itemClass = metadataStore.classesObj[itemClassId];

    const handleItemClick = () => {
        if (onItemClick){
            onItemClick();
        } else {
            navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(itemClass.title)}/${id}/events`);        
        }
    }

    return (<Observer>{()=>(
        <LinkWrapper onClick={handleItemClick} >
             <ItemIconWrapper color={itemClass.color} >
                <AssetClassIcon name={itemClass.icon} />  
             </ItemIconWrapper>
             <ItemTitle titleOverflow={titleOverflow}>{itemTitle}</ItemTitle>
        </LinkWrapper>
        // <AutoComplete value={value}  options={metadataStore.sortedBeneficiaries} 
        // disabled={disabled}  filterOption={true}
        //  onChange={(val)=>{handleChange(inputKey,val)}} 
        //  getPopupContainer={() => scrollContainerId ? ( document.getElementById(scrollContainerId) || document.body) : document.body} />
    )}</Observer>)

}