import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { disabledMoreThanToday } from "../../../../../common/utils/dates.utils";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";
import { containerRow } from "../../common-fields/container-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { isBetweenZeroAndHundred, isRequired } from "../../input-validations";

export const createOtherAddItemSections = (isEditForm) => {
    return ({updateValue,updateField}) => {
        const { other } = getTranslations().new_item_modal;
            return ([
            {
                title:'General info',
                rows:[
                    {fields:[
                        {key:'title',validations:[isRequired], description:other.TITLE_DESCRIPTION,}
                    ]},
                    beneficiaryRow({}),
                    {fields:[
                        {key:'value' , type: 'numberWithHoldings' , label: other.VALUE_LABEL , validations:[isRequired],
                        description: other.VALUE_DESCRIPTION ,generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || '',placeholder: CURRENCY_SYMBOL[values.currency] || ''}
                        }},
                        {key:'currency',size:0.3,onChange:({value})=>{
                            updateField('value',{prefix:CURRENCY_SYMBOL[value] || '',placeholder: CURRENCY_SYMBOL[value] || ''});
                            updateField('cashInvested',{prefix:CURRENCY_SYMBOL[value] || '',placeholder: CURRENCY_SYMBOL[value] || ''});
                            updateValue('cashInvestedCurrency', value || '');
                        }},
                    ]},
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : [
                        {fields:[
                            {key:'cashInvested' , type: 'numberWithHoldings' , label:'Purchase price' ,  description: other.PURCHASE_PRICE_DESCRIPTION ,generatePropsFn : (values)=>{
                                return {prefix: CURRENCY_SYMBOL[values.currency] || '',placeholder: CURRENCY_SYMBOL[values.currency] || ''}
                            }},
                            {key:'cashInvestedCurrency',size:0.3, defaultValue: 'USD',onChange:({value})=>{}}
                        ]},
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden:true,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                    onBlur:({value})=>{
                                        updateField('value',{holdings:value || ''});
                                        updateField('cashInvested',{holdings:value || ''});
                                    },
                                    generatePropsFn : (values)=>{
                                        return {isHidden: values.holdings === 100}
                                    }
                                }
                            ]
                        },
                        {
                            fields:[
                                {type:'checkbox', key:'isPartialHolding',checkboxLabel: 'This asset is 100% owned by me', description: 'If you are a co-owner of this asset, uncheck this box.' ,
                                defaultValue: true,
                                fieldCustomStyle: {marginTop:'-24px'},
                                    onChange: ({value})=>{
                                        const newHoldingValue = value ? 100 : 50;
                                        updateValue('holdings',newHoldingValue);
                                        updateField('holdings',{isHidden:value});
                                        updateField('value',{holdings:newHoldingValue});
                                        updateField('cashInvested',{holdings:newHoldingValue});
                                        
                                    }
                                },
                            ]
                        },
                        ]),
                ]
            },{
                title:'Projection',
                rows:[
                    {fields:[
                        {key:'annualIncreaseInValue' , label: other.ANNUAL_INCREASE_IN_VALUE_LABEL, description: other.ANNUAL_INCREASE_IN_VALUE_DESCRIPTION}
                    ]},
                    
                    // {fields:[
                    //     {key:'expectedEndDate', label : 'Expected sale date'}
                    // ]},
                    // {fields:[
                    //     {key:'expectedSalePrice'}
                    // ]},
                ]
            },{
                title: 'Performance', 
                rows:[
                    {fields:[
                        {key:'startDate', label:'Purchase date', description: other.START_DATE_DESCRIPTION, defaultValueFn: ()=>{return new Date()},
                        disabledDate: disabledMoreThanToday }
                    ]},
                ]
            },{
                title:`More info`,
                rows:[
                    ...(isEditForm ? [
                        containerRow({isEditForm, updateField}),
                        {
                            fields:[
                                {key:'holdings',  label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth',  isLocked: true,  UnlockComponent:HoldingsUnlockModal,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                // onBlur:({value})=>{
                                //     updateField('monthlyRent',{holdings:value || ''});
                                //     updateField('expectedMonthlyExpenses',{holdings:value || ''});
                                //     updateField('expectedSalePrice',{holdings:value || ''});
                                // },
                                }
                            ]
                        },
                        {
                            fields:[
                                {key:'defaultCashAccountId'}
                            ]
                        },
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {fields:[
                        {key:'remarks'}
                    ]}
                ]
            }
        ])
    }
}
