import { DistributionsDescriptionTitle, DistributionsDescriptionWrapper, ReturnCapitalAltText, ReturnCapitalMainText, ReturnCapitalRowTextWrapper, ReturnCapitalRowWrapper } from './OnOfCapitalTooltip.styles';

export const OnOfCapitalTooltip = () => {
    return (
        <DistributionsDescriptionWrapper>

            <DistributionsDescriptionTitle>The total distributions received from this investment, including return ON capital and return OF capital:</DistributionsDescriptionTitle>

            <ReturnCapitalRowWrapper>
                <OfCapitalSvg />
                <ReturnCapitalRowTextWrapper>
                    <ReturnCapitalMainText>Return OF capital</ReturnCapitalMainText>
                    <ReturnCapitalAltText>Returns from your original investment. Return OF Capital reduces the asset value, but you can adjust it manually.</ReturnCapitalAltText>
                </ReturnCapitalRowTextWrapper>
            </ReturnCapitalRowWrapper>
            
            <ReturnCapitalRowWrapper>
                <OnCapitalSvg />
                <ReturnCapitalRowTextWrapper>
                    <ReturnCapitalMainText>Return ON capital</ReturnCapitalMainText>
                    <ReturnCapitalAltText>Income or profit.</ReturnCapitalAltText>
                </ReturnCapitalRowTextWrapper>
            </ReturnCapitalRowWrapper>

        </DistributionsDescriptionWrapper>
    )
}

const OnCapitalSvg = () => {
    return (
        <svg style={{flexShrink:0}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.75" y="7.75" width="14.5" height="14.5" rx="7.25" stroke="#ACB1BB" strokeWidth="1.5"/>
            <rect x="15.6" y="2.6" width="5.8" height="5.8" rx="2.9" fill="#ACB1BB" stroke="#ACB1BB" strokeWidth="1.2"/>
        </svg>
    )
}
const OfCapitalSvg = () => {
    return (
        <svg style={{flexShrink:0}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_1044_71350" fill="white">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.3262 2.5C5.89808 2.50099 1.49805 6.90164 1.49805 12.33C1.49805 17.7589 5.89907 22.1599 11.328 22.1599C16.2846 22.1599 20.3843 18.4914 21.0602 13.7215H19.5423C18.88 17.6596 15.4544 20.6599 11.328 20.6599C6.7275 20.6599 2.99805 16.9305 2.99805 12.33C2.99805 7.73006 6.72651 4.00099 11.3262 4V2.5Z"/>
            </mask>
            <path d="M11.3262 2.5H12.5262V1.29978L11.326 1.3L11.3262 2.5ZM21.0602 13.7215L22.2484 13.8899L22.4423 12.5215H21.0602V13.7215ZM19.5423 13.7215V12.5215H18.5272L18.3589 13.5225L19.5423 13.7215ZM11.3262 4L11.3264 5.2L12.5262 5.19974V4H11.3262ZM2.69805 12.33C2.69805 7.56431 6.56094 3.70087 11.3264 3.7L11.326 1.3C5.23522 1.30111 0.298047 6.23897 0.298047 12.33H2.69805ZM11.328 20.9599C6.56181 20.9599 2.69805 17.0962 2.69805 12.33H0.298047C0.298047 18.4216 5.23633 23.3599 11.328 23.3599V20.9599ZM19.8721 13.5531C19.2789 17.7397 15.6785 20.9599 11.328 20.9599V23.3599C16.8907 23.3599 21.4898 19.2432 22.2484 13.8899L19.8721 13.5531ZM21.0602 12.5215H19.5423V14.9215H21.0602V12.5215ZM11.328 21.8599C16.05 21.8599 19.9678 18.4268 20.7256 13.9205L18.3589 13.5225C17.7922 16.8924 14.8587 19.4599 11.328 19.4599V21.8599ZM1.79805 12.33C1.79805 17.5932 6.06476 21.8599 11.328 21.8599V19.4599C7.39024 19.4599 4.19805 16.2677 4.19805 12.33H1.79805ZM11.3259 2.8C6.06362 2.80114 1.79805 7.06741 1.79805 12.33H4.19805C4.19805 8.39272 7.38939 5.20085 11.3264 5.2L11.3259 2.8ZM12.5262 4V2.5H10.1262V4H12.5262Z" fill="#ACB1BB" mask="url(#path-1-inside-1_1044_71350)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.6334 11.9734H13V3C17.2872 3.34123 20.6599 6.92845 20.6599 11.3034C20.6599 11.529 20.651 11.7524 20.6334 11.9734Z" fill="#ACB1BB"/>
        </svg>
    )
}