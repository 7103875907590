import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { DataBoxFormContentWrapper, FormHeaderWrapper, FormTitle, HeaderIconWrapper, HeaderTextWrapper } from './PreferenceDataBox.styles';

export const PreferenceDataBox = observer(({ children, title, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen || false);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const handleOnOpenChange = () => {
    setIsOpen(!isOpen);
  }

  return (
    <DataBoxFormContentWrapper>
      <FormHeaderWrapper isOpen={isOpen} onClick={handleOnOpenChange}>
        <HeaderTextWrapper>
          <FormTitle>{title}</FormTitle>
        </HeaderTextWrapper>
        <HeaderIconWrapper isOpen={isOpen}><ArrowDownIcon /></HeaderIconWrapper>
      </FormHeaderWrapper>
      {isOpen && (<>
        {children}
      </>
      )}
    </DataBoxFormContentWrapper>
  )
})

const ArrowDownIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3333 5.33301L7.99992 10.6663L2.66659 5.33301" stroke="#1A1B1D" strokeWidth="1.5" />
    </svg>
  )
}