import React, { useState } from 'react';
import { Observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom'
import { Drawer } from "antd";
import { useStore } from "../../../app/data/root.store";
import { AddButtonWrapper, FooterWrapper, MenuButtonText, MenuButtonWrapper, MenuLinkButtonWrapper } from "./MobileFooter.styles";
import { CreateMenu } from "../Header/Header";
import { navigateToUrl } from '../../../../common/utils/navigation.utils';
import { NewCreateItemModal } from '../../../add-item/components/NewCreateItemModal';
import { LimitationModal } from '../../../../common/components/LimitationModal/LimitationModal';
import { ROUTES } from '../../../../common/constants/routes.const';

export function MobileFooter(props) {
    const {isHidden} = props;
    const history = useHistory();
    const location = useLocation();
    const { uiStore , createItemStore , userStore,filtersStore, billingStore, wealthStore } = useStore();

    const [isCreateMenuOpen, setIsCreateMenuOpen] = useState(false);

    const handlePortfolioClick = () => {
        filtersStore.handleClearFilterSelections();
        filtersStore.closeAllParameters();
        if (location.pathname.includes(ROUTES.wealth.url)) {
          wealthStore.refreshWealthData();
        }
        // uiStore.setIsRightMenuOpen(!uiStore.isRightMenuOpen)
      }

    const onCreateSyncClick = (syncMode) => {
        createItemStore.setInstitutionStep(syncMode);
        createItemStore.setShowModal(true);
        setIsCreateMenuOpen(false);
      }
    
      const onCreateManuallyClick = () => {
        createItemStore.setCategoryStep(true);
        createItemStore.setShowModal(true);
        setIsCreateMenuOpen(false);
      }
    
      const onMagicBoxClick = () => {
        navigateToUrl(history, ROUTES.magicBox.url);
        setIsCreateMenuOpen(false);
      }
    
      const handleItemAdded = () => {
        createItemStore.setShowModal(false);
        userStore.updateData();
      }
    
      const handleCreateItemModalClose = () => {
        createItemStore.setIsPlaidInProcess(false);
        createItemStore.setIsSaltedgeInProcess(false);
        createItemStore.setShowModal(false);
      }

      return <Observer>{() => (
        <FooterWrapper isHidden={isHidden} id="mobile_footer">
            <MenuLinkButtonWrapper to={ROUTES.wealthOverview.url} activeStyle={{ opacity : uiStore.isAppMobileMenuOpen ? 0.5 : 1 }} onClick={()=>{uiStore.setIsAppMobileMenuOpen(false);handlePortfolioClick()}} >
                {/* <HomeIcon className={'normalIcon'} isActive={false}/>
                <HomeIcon className={'selectedIcon'} isActive={true}/> */}
                <HomeIcon />
                <MenuButtonText>Portfolio</MenuButtonText>
            </MenuLinkButtonWrapper>
            <MenuLinkButtonWrapper to={ROUTES.cashflow.url}  activeStyle={{ opacity : uiStore.isAppMobileMenuOpen ? 0.5 : 1 }} onClick={()=>{uiStore.setIsAppMobileMenuOpen(false)}}>
                    <PlanningIcon />
                    <MenuButtonText>Planning</MenuButtonText>
            </MenuLinkButtonWrapper>
            <AddButtonWrapper onClick={()=>{setIsCreateMenuOpen(true)}}>
                    <AddIcon />
                    {/* <MenuButtonText>Planning</MenuButtonText> */}
            </AddButtonWrapper>
            <MenuLinkButtonWrapper to={ROUTES.reports.url}  activeStyle={{ opacity : uiStore.isAppMobileMenuOpen ? 0.5 : 1 }}>
                    <ReportsIcon />
                    <MenuButtonText>Reports</MenuButtonText>
            </MenuLinkButtonWrapper>
            <MenuButtonWrapper isSelected={uiStore.isAppMobileMenuOpen} onClick={()=>{uiStore.setIsAppMobileMenuOpen(!uiStore.isAppMobileMenuOpen)}}>
                    <MoreIcon />
                    <MenuButtonText>More</MenuButtonText>
            </MenuButtonWrapper>

            <Drawer
                closable={false}
                visible={isCreateMenuOpen }
            width={ '100%' }
            height={'auto'}
            className={'addItemDrawerContainer_mobile'}
            placement={'bottom'}
            onClose={()=>{setIsCreateMenuOpen(false)}}>
                <CreateMenu fullWidth 
                onSyncClick={onCreateSyncClick}
                onManuallyClick={onCreateManuallyClick}
                onMagicClick={onMagicBoxClick}
                userStore={userStore}
                isMobileMenu
                onClose={()=>{setIsCreateMenuOpen(false)}}
                setMenuVisibility={setIsCreateMenuOpen} />
            </Drawer>

            {createItemStore.showModal &&
                <NewCreateItemModal
                    onAddItem={() => { handleItemAdded() }}
                    onClose={handleCreateItemModalClose}
                />}

{ createItemStore.isShowAddManualItemLimitModal && <LimitationModal modalType="addItems" 
        paramsForText={[userStore.userLimitations.manualActiveItems.manualItems]}
        onUpgrade={()=>{billingStore.setShowPricingTableModal(true)}}
        onClose={()=>createItemStore.setShowAddManualItemLimitModal(false) } />}

        { createItemStore.isShowAddSyncLimitModal && <LimitationModal modalType="instSync" 
        paramsForText={[userStore.userLimitations.financialInstitutionSync.syncedInstitutions]}
        onUpgrade={()=>{billingStore.setShowPricingTableModal(true)}}
        onClose={()=>createItemStore.setShowAddSyncLimitModal(false) } />}

        { createItemStore.isShowAddVipSyncLimitModal && <LimitationModal modalType="vipSync" 
        paramsForText={[userStore.userLimitations.financialInstitutionSync.syncedInstitutions]}
        onUpgrade={()=>{billingStore.setShowPricingTableModal(true)}}
        onClose={()=>createItemStore.setShowAddVipSyncLimitModal(false) } />}


        </FooterWrapper>
    )}</Observer>
}


const HomeIcon = ({isActive,className}) => {
    return (<svg className={className} width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.9 20.5V10.2562C20.9 9.76069 20.6553 9.29711 20.2461 9.0176L14.0281 4.7705C13.3478 4.30585 12.4522 4.30585 11.772 4.7705L5.55399 9.0176C5.14478 9.29711 4.90002 9.76069 4.90002 10.2562V20.5C4.90002 20.7761 5.12388 21 5.40002 21H10C10.2762 21 10.5 20.7761 10.5 20.5V17.1676C10.5 16.6153 10.9477 16.1676 11.5 16.1676H14.3C14.8523 16.1676 15.3 16.6153 15.3 17.1676V20.5C15.3 20.7761 15.5239 21 15.8 21H20.4C20.6762 21 20.9 20.7761 20.9 20.5Z" stroke="#EAF0F1" strokeWidth="1.5" fill={isActive ? '#EAF0F1' : 'none'}/>
    </svg>
    )
}

const PlanningIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-outside-1_191_3652" maskUnits="userSpaceOnUse" x="1.70007" y="3.73535" width="22" height="17" fill="black">
        <rect fill="white" x="1.70007" y="3.73535" width="22" height="17"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.70007 11.5001H7.20007L11.2001 8.50006L15.7001 13.5001L21.7001 5.73535V18.5001H3.70007V11.5001Z"/>
        </mask>
        <path d="M7.20007 11.5001V13.0001C7.52463 13.0001 7.84043 12.8948 8.10007 12.7001L7.20007 11.5001ZM3.70007 11.5001V10.0001C2.87165 10.0001 2.20007 10.6716 2.20007 11.5001H3.70007ZM11.2001 8.50006L12.315 7.49661C11.7955 6.91938 10.9213 6.83411 10.3001 7.30006L11.2001 8.50006ZM15.7001 13.5001L14.5851 14.5035C14.8827 14.8342 15.3117 15.0157 15.7563 14.999C16.2008 14.9823 16.615 14.7692 16.887 14.4172L15.7001 13.5001ZM21.7001 5.73535H23.2001C23.2001 5.09368 22.7919 4.52307 22.1846 4.31578C21.5774 4.10849 20.9055 4.31043 20.5131 4.81818L21.7001 5.73535ZM21.7001 18.5001V20.0001C22.5285 20.0001 23.2001 19.3285 23.2001 18.5001H21.7001ZM3.70007 18.5001H2.20007C2.20007 19.3285 2.87165 20.0001 3.70007 20.0001L3.70007 18.5001ZM7.20007 10.0001H3.70007V13.0001H7.20007V10.0001ZM10.3001 7.30006L6.30007 10.3001L8.10007 12.7001L12.1001 9.70006L10.3001 7.30006ZM16.815 12.4966L12.315 7.49661L10.0851 9.5035L14.5851 14.5035L16.815 12.4966ZM20.5131 4.81818L14.5131 12.5829L16.887 14.4172L22.887 6.65252L20.5131 4.81818ZM20.2001 5.73535V18.5001H23.2001V5.73535H20.2001ZM21.7001 17.0001H3.70007V20.0001H21.7001V17.0001ZM5.20007 18.5001V11.5001H2.20007V18.5001H5.20007Z" fill="#EAF0F1" mask="url(#path-1-outside-1_191_3652)"/>
        </svg>                
    )
}

const AddIcon = () => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.59998 14L8.59998 2" stroke="#EAF0F1" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M14.6 8H2.59998" stroke="#EAF0F1" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
</svg>

)

const ReportsIcon = () => {
    return (<svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.9668 6.5C2.9668 5.94771 3.41451 5.5 3.9668 5.5H15.5098C16.3335 5.5 17.121 5.83869 17.6875 6.43664L21.9779 10.9648C22.5059 11.5221 22.8001 12.2605 22.8001 13.0282V18.9993C22.8001 19.5516 22.3524 19.9993 21.8001 19.9993H3.9668C3.41451 19.9993 2.9668 19.5516 2.9668 18.9993V6.5Z" stroke="#EAF0F1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.3 12.5L20.3779 12.3056H15.9334V7.5C15.9334 6.39543 15.038 5.5 13.9334 5.5H12.6001" stroke="#EAF0F1" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
    </svg>
    )
}

const MoreIcon = () => {
    return (<svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="4.06531" y1="7.25" x2="20.7459" y2="7.25" stroke="#EAF0F1" strokeWidth="1.5" strokeLinecap="round"/>
    <line x1="4.06531" y1="13.75" x2="20.7459" y2="13.75" stroke="#EAF0F1" strokeWidth="1.5" strokeLinecap="round"/>
    <line x1="4.06531" y1="20.25" x2="20.7459" y2="20.25" stroke="#EAF0F1" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
    )
}
