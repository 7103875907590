import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import { ConnectedInstitutionsList } from './ConnectedInstitutionsList';
import { LogoLoader } from '../../../../common/components/LogoLoader/LogoLoader';
import { ConnectedInstitutionsAltTitle, ConnectedInstitutionsTitle, MobileContentHeader, SyncAllIconWrapper, SyncedInstitutionsMobileHeader, SyncedInstitutionsMobileHeaderTitle, SyncedInstitutionsWrapper } from './InstitutionsPanel.styles';
import { ConnectedAccountsIcon, MobileBackButton, PlusIcon } from '../../../../assets/icons/common/common-icons';
import { ConnectedInstitutionsListEmptyState } from './ConnectedInstitutionsListEmptyState';
import { PageWrapper, RightSidePushMenuWrapper } from '../../../layout/components/Layout.styles';
import { CardContentBoxWrapper } from '../../../wealth/components/wealth.commons.styles';
import { AppHeader } from '../../../layout/components/Header/Header';
import { PrimaryButtonWithIcon, SecondaryButton } from '../../../../common/components/buttons/NewButtons.styles';
import { AddButtonWrapper, AddNewPanelWrapper, AddPanelText } from './ConnectedInstitutionsList.styles';
import { removeUrlParam } from '../../../../common/utils/navigation.utils';
import { useHistory, useLocation } from "react-router-dom";
import { CommonBadge } from '../../../../common/components/CommonBadge/CommonBadge';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { AfterReconnectModal } from '../after-reconnect-modal/AfterReconnectModal';
import { MobileBackButtonWrapper } from '../../../layout/components/MobileLayout.styles';
import { colors } from '../../../../common/styles/theme.styles';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';

export function InstitutionsPanel(props) {
  const { connectedInstitutionsStore, createItemStore, uiStore, userStore , billingStore , itemStore } = useStore();
  const [params] = useState({
    instId:  new URLSearchParams(window.location.search).get('inst'),
    provider:  new URLSearchParams(window.location.search).get('p')
  });
  const history = useHistory();
  const location = useLocation();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    removeUrlParam(history,location,'inst')
    removeUrlParam(history,location,'p')
  }, [history,location])
  

  useEffect(() => {
    connectedInstitutionsStore.fetchData();
    uiStore.setIsRightMenuOpen(false);
    uiStore.setIsUserMenuActive(true);
    return () => { 
			uiStore.setIsUserMenuActive(false);
		}
  }, [connectedInstitutionsStore,uiStore]);

  const handleSyncAllAccounts = () => {
    trackEvent('CTA Clicked', {
      location: "Synced Accounts Page",
      cta_text: "Sync all now",
      type: "Button",
      url: window.location.href,
      destination: 'N/A'
    })
    // connectedInstitutionsStore.fetchData();
    connectedInstitutionsStore.syncAllData();
    itemStore.setItemUpdated(true);
  }

  const handleSyncNew = () => {
    if (userStore.isActionBlockedByRole('institutionsPanelSyncNew')) {
      userStore.setShowLimitedSharedAccess(true);
    } else {
      createItemStore.setInstitutionStep('bank');
      createItemStore.setShowModal(true);
    }
  }  

  return (
    <>
    {uiStore.isDesktopView ? <AppHeader /> : <SyncedInstitutionsMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}> 
        <MobileBackButtonWrapper onClick={()=>{uiStore.setIsAppMobileMenuOpen(true)}} fontColor={colors.darkGray1}><MobileBackButton /></MobileBackButtonWrapper>
        <SyncedInstitutionsMobileHeaderTitle>Synced Institution</SyncedInstitutionsMobileHeaderTitle>
    </SyncedInstitutionsMobileHeader> } 
    <RightSidePushMenuWrapper />
    <Observer>{() => (
    <>
    <PageWrapper withHeader id="syncedAccountsPage">
      {connectedInstitutionsStore.isLoading ?
        <div><LogoLoader backdrop /></div> :
        <CardContentBoxWrapper>
          <SyncedInstitutionsWrapper>
            {connectedInstitutionsStore.connectedInstitutionsCount > 0 ?
              <>
                {uiStore.isDesktopView &&
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' , marginBottom: '16px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' , alignItems:'flex-start' }}>
                    <ConnectedInstitutionsTitle>{userStore.isViewingOwnPortfolio ? 'S' : `${userStore.firstNameCapitalize}'s s`}ynced financial institutions ({connectedInstitutionsStore.connectedInstitutionsCount})</ConnectedInstitutionsTitle>
                    <ConnectedInstitutionsAltTitle>See and manage all accounts you've connected</ConnectedInstitutionsAltTitle>
                    {userStore.isFreeTier && userStore.isAddSyncInstitutionLimited && <CommonBadge noMargin badgeType="limitedFeature"
                      badgeWrapperStyle={{marginTop:'8px'}}
                       title={`Up to ${userStore.userLimitations.financialInstitutionSync.syncedInstitutions} institutions`} rounded  clickable onClick={()=>{ billingStore.setShowPricingTableModal(true)}}  />}
                    {userStore.isPayedTier && userStore.isAddSyncInstitutionLimited && <CommonBadge noMargin badgeType="limitedFeature"
                      badgeWrapperStyle={{marginTop:'8px'}} color={'#2D72FF'} 
                       title={`Up to ${userStore.userLimitations.financialInstitutionSync.syncedInstitutions} institutions`} rounded  clickable onClick={()=>{ billingStore.setShowPricingTableModal(true)}}  />}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                    {/* <SyncAllNowButton onClick={handleSyncAllAccounts}><ConnectedAccountsIcon /> Sync all now</SyncAllNowButton> */}
                    <SecondaryButton onClick={handleSyncAllAccounts} disabled={connectedInstitutionsStore.isSyncAllLoading}><SyncAllIconWrapper isLoading={connectedInstitutionsStore.isSyncAllLoading}><ConnectedAccountsIcon /></SyncAllIconWrapper>{connectedInstitutionsStore.isSyncAllLoading ? 'Syncing...' : 'Sync all now'}</SecondaryButton>
                    <PrimaryButtonWithIcon onClick={handleSyncNew}><PlusIcon />Sync new account {userStore.isActionBlockedByRole('institutionsPanelSyncNew') && <ActionLockedIconWithTooltip marginLeft={'0'} iconColor={colors.gray3} isRoleBlocked={true} />}</PrimaryButtonWithIcon>
                  </div>
                </div> }
                {!uiStore.isDesktopView && <MobileContentHeader>See and manage all accounts you've connected</MobileContentHeader>}
                <ConnectedInstitutionsList
                  isIncognito={uiStore.isIncognitoMode}
                  plaidConnectedInstitutions={connectedInstitutionsStore.plaidInstitutions}
                  saltEdgeInstitutions={connectedInstitutionsStore.saltEdgeInstitutions}
                  akoyaInstitutions={connectedInstitutionsStore.akoyaInstitutions}
                  vipInstitutions={connectedInstitutionsStore.vipInstitutions}
                  params={params}
                  />
                <AddNewPanelWrapper  onClick={handleSyncNew}>
                    <AddButtonWrapper><PlusIcon /></AddButtonWrapper>
                    <AddPanelText>Add your Bank / Brokerage account / Investor portals</AddPanelText>
                </AddNewPanelWrapper>
              </>
              
              : <ConnectedInstitutionsListEmptyState handleConnect={handleSyncNew} />}
          </SyncedInstitutionsWrapper>
        </CardContentBoxWrapper>}
        
    </PageWrapper>
    {uiStore.reconnectErrorModal.show  && <AfterReconnectModal onBackDropClick={()=>{uiStore.setShowReconnectErrorModal(false)}} /> }
    </>
  )}</Observer></>


              



  )
}

