import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../common/styles/combos.styles";
import { colors, views } from "../../../common/styles/theme.styles";

export const CommunityPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  grid-area:content;
  ${views.desktop}{
    height: 100vh;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 45px;
  background-color: #f2f3f5;
  ${props=>props.withHeader ? `` : `
    grid-area:content;
  `}
  overflow:auto;
  min-height:100%;
  & > div {
    width: 100%;
    max-width: 880px;
    margin-bottom:45px;
  }
  ${views.tablet}{
    background-color: ${colors.darkHeroBackground};
    padding:0px; //64px 0 0 0;
    & > div {
      // padding-bottom:64px;
      padding-bottom: calc( env(safe-area-inset-bottom) + 64px );
      margin-bottom:0px;
    }
  }
`

export const StickyWrapper = styled.div`
  position: sticky;
  top: 0px;
  width: 100%;
  
  ${props => props.isSticky ? `
      border-bottom: 1px solid ${colors.gray3};
      z-index: 5;
  ` : null}
`;

export const CommunityPageHeader = styled.div`
  display: flex;
  align-items: center;
  padding-inline: 20px;
  min-height: 52px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CommunityPageHeaderTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${colors.darkGray1};
`

export const HeaderAltText = styled.div`
  font-size: 13px;
  line-height: 138%;
  color: ${colors.darkGray3};
`;

export const HeaderActions = styled.div`
  display:flex; 
  flex:1; 
  justify-content:flex-end; 
  align-items:center;
  gap:8px;
`;

export const HeaderButton = styled.div`
  ${flexCenter};
  height:32px;
  font-size:16px;
  font-weight:450;
  outline:none;
  border:none;
  border-radius:6px;
  cursor:pointer;
  gap: 4px;
  padding: 0 8px;
`;

export const LearnButton = styled(HeaderButton)`
  background-color:${colors.lightGray1};
  color:${colors.darkGray1};
  :hover{
    background-color:${colors.darkGray1};
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
  }
`

export const WhatYouGetButton = styled(HeaderButton)`
  background-color: ${props => props.isOpen ? colors.darkGray1 : colors.lightGray1};
  color: ${props => props.isOpen ? '#fff' : colors.darkGray1};
  :hover{
    background-color:${colors.darkGray1};
    color: #fff;
  }
`;

export const MenuWrapper = styled.div`
    width:${props => props.width || '200px'};
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    padding: 8px 28px;
    border-radius: 8px 0px 8px 8px;
    border: 1px solid ${colors.gray3};
`;

export const MenuTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.gray2};
  padding: 8px 0 16px;
`;

export const MenuItemWrapper = styled.div`
    padding:8px 0;
    ${flexCenter};
    color:${colors.darkGray1};
    border-top: 1px solid ${colors.gray3};
    /* ${props => !props.disabled ? `
    :hover{
        background-color: ${colors.lightGray1};
    }
    cursor: pointer;
    ` : null} */
`;

export const WhatYouGetMenuItemWrapper = styled.div`
    ${flexCenter};
    padding:8px 0px;
    gap: 20px;
`;

export const WhatYouGetMenuItemIcon = styled.div`
    /* width: 80px; */
    display: flex;
    align-items: center;
`;

export const WhatYouGetMenuItemTextContent = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
`;

export const WhatYouGetMenuItemTitle = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    font-weight:500;
    line-height:19px;
    margin-bottom:4px;
`;

export const WhatYouGetMenuItemSubTitle = styled.div`
    color:${colors.darkGray3};
    font-size: 12px;
`;

export const ListItemWrapper = styled.div`
    ${flexCenter};
    padding:12px 0;
    /* :not(:last-child){
        margin-bottom: 8px;
    }  */
    cursor: pointer;
    transition:background-color 0.2s;
    :hover{
        background-color:${colors.lightGray1};
    }
    ${props=>props.customStyle? props.customStyle : `` }
`;

export const ItemIconWrapper = styled.div`
    margin-left:24px;
    height:32px;
    width:32px;
    border-radius: 2px;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    svg{
        width:24px;
        height:24px;
        path{
            /* transition:all 1s; */
            fill:#fff;
        }
        rect{
            /* transition:all 1s; */
            fill:#fff;
        }
    }
    
    /* ${ListItemWrapper}:hover &{
        path{
            fill:#3e3e3e;
        }
    } */
`;

export const ItemContent = styled.div`
    flex:1;
    display:flex;
    align-items:center;
`;

export const ItemTitle = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    line-height:19px;
    font-weight:500;
`;

export const LightColorText = styled.span`
    color:${colors.gray2};
`;

export const ItemValue = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    line-height:19px;
    padding-right:24px;
    ${props => props.width ? `width:${props.width}` : ``}
`;

export const ClassValueWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right:24px;
`;
export const ClassItemValue = styled.div`
  color:${colors.darkGray1};
  font-size:16px;
  line-height:19px;
  width:120px;
  text-align:right;
`;

export const IndicationsWrapper = styled.div`
    ${flexCenter};
    display:inline-block;
    margin-left:8px;
`;

export const IndicationCircle = styled.div`
    width:8px;
    height:8px;
    background-color: ${props=>props.isEventPass ? colors.redNotification : '#82AAFA'} ;
    border-radius: 20px;
    margin-right:${props=>props.isClassView ? 0 : 8}px;
`;

export const InstitutionLogoPlaceHolder = styled.div`
    ${flexCenter}
    height:26px;
    width:26px;
    background-color:${colors.gray3};
    border-radius:50%;
    svg{
        height:18px;
        width:18px;
    }
    margin-right:12px;
`;

export const SelectedInstitutionBreakdownMobileWrapper = styled.div`
    padding:8px 24px;
    background-color: #f7f8f9;
`;

export const MobileSelectedInstitutionRowWrapper = styled.div`
    ${flexCenter};
    width: 100%;
    padding: 8px 0;
    :not(:last-of-type) {
        border-bottom: 1px solid rgba(47, 69, 85, 0.15);
    }
`;