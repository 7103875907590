import moment from "moment";
import React, { useState } from "react";
import DetectableOverflow from 'react-detectable-overflow';
import { ItemLink } from "../../../../../../common/components/ItemLink/ItemLink";
import { displayMoneyValue } from "../../../../../../common/utils/number-display.utils";
import { CollapsibleArrowWrapper, CollapsibleTitle, CollapsibleWrapper, CollapsingContentWrapper, RemarksCellWrapper, RowCell, RowWrapper, ValueCellWrapper } from "./AssetTransactionsTab.styles";

export const TrnRow = (props) => {
  const { isIncognito, trn } = props;
  const [isTooltipNeeded, setIsTooltipNeeded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleOverflowChange = (isOverflowed) => {
    setIsTooltipNeeded(isOverflowed);
  }

  return (<RowWrapper isOpen={isOpen}>
    <RowCell>{moment(trn.date, 'YYYY-MM-DD').format('MMM DD')}</RowCell>
    <ValueCellWrapper isPositive={trn.value < 0}>{displayMoneyValue(trn.value * -1, trn.currency, isIncognito)}</ValueCellWrapper>
    <RowCell>
      <RemarksCellWrapper>
        <CollapsibleWrapper onClick={() => { setIsOpen(!isOpen) }}>
          <CollapsibleTitle>
            <DetectableOverflow onChange={handleOverflowChange}>
              {trn.merchantName ? trn.merchantName : trn.remarks}
            </DetectableOverflow>
          </CollapsibleTitle>
          {isTooltipNeeded || trn.merchantName ? <> <CollapsibleArrowWrapper isOpen={isOpen}><CollapseArrowSvg /></CollapsibleArrowWrapper>
            <CollapsingContentWrapper isOpen={isOpen}>{trn.remarks}{isTooltipNeeded && trn.merchantName ? trn.merchantName : null}</CollapsingContentWrapper></> : null}
        </CollapsibleWrapper>
      </RemarksCellWrapper>
    </RowCell>
    <RowCell>{trn.connectedToId && <ItemLink titleOverflow id={trn.connectedToId} />}</RowCell>
  </RowWrapper>)
}

const CollapseArrowSvg = () => {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 9L11.5 13L15.5 9" stroke="#585D66" strokeWidth="1.71429" />
    </svg>
  )
}
