import styled from "@emotion/styled/macro";
import { Input } from "antd";
import { flexCenter, ScrollPanelWithShadows } from "../../../../../../common/styles/combos.styles";
import { colors } from "../../../../../../common/styles/theme.styles";


export const LinkEventTransactionMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 285px;
    background: #FFFFFF;
    /* box-shadow: 0px 0.2px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px #E5E9F2; */
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 0px 16px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    margin-top: 6px;
`;

export const LoaderWrapper = styled.div`
    display:flex;
    justify-content: center;
    padding:80px;
`;

export const LinkEventTransactionMenuItemWrapper = styled.div`
    display: flex;
    gap: 16px;
    align-items: flex-start;
`;



export const SearchInput = styled(Input)`
    /* , borderLeft: 'none', borderRight: 'none', borderRadius: '4px 4px 0px 0px' */
    /* border-bottom: 1px solid #DDE3EB; */
    border: 1px solid ${colors.gray3};
    /* border-width:0 0 1px 0; */
    border-radius:80px; //4px 0px 0px;
    margin: 12px 16px;
    &:hover{
        border-color:#7d7d7d!important;
        box-shadow: none;
        border-right:none!important;
    }
    &:focus{
        border-color:#7d7d7d!important;
        box-shadow: none;
        border-right:none!important;
    }
    &.ant-input-affix-wrapper{
        width: auto;
    }
    &.ant-input-affix-wrapper .ant-input{
        margin-left: 24px;
    }
    &.ant-input-affix-wrapper-focused{
        border-color:#7d7d7d;
        box-shadow: none;
        border-right:none!important;
    }
`

export const LinkEventTransactionScrollPanel = styled(ScrollPanelWithShadows)`
    background: #fff;
    border-top: 1px solid ${colors.gray3};
    border-radius: 0 0 8px 8px;
    max-height:300px;
`;

export const LinkEventTransactionMenuItem = styled.div`
    padding: 8px 16px;
    cursor: pointer;
    width: 100%;
    display: ${props=>props.isHidden ? 'none' : 'block'};
    text-align:left;
    border-bottom: 1px solid #DDE3EB;
    &:hover{
        background-color:#ECF0F4;
    }
`;

export const ValueAndDateWrapper = styled.div`
    display:flex;
    flex-direction:column;
    min-width:90px;
    width:90px;
    /* margin-top:2px; */
`;

export const LinkEventTransactionMenuItemDescription = styled.div`
    color: ${colors.darkGray1};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Roboto Mono';
`;

export const LinkEventTransactionMenuItemMainTxt = styled.div`
    display: flex;
    /* justify-content: space-between; */
`;

export const LinkEventTransactionMenuItemAmount = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #2A2D33;
`;

export const LinkEventTransactionMenuItemDate = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.darkGray2};
`;

export const HoldingsBadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
`;

export const HoldingsBadgeText = styled.div`
    background-color: ${colors.assetHoldingsBadgeBgColor};
    color:${colors.assetHoldingsBadgeTextColor};
    padding: 0px 1px 0px 4px;
    border-radius: 2px 0px 0px 2px;
    white-space: nowrap;
`;

export const HoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid ${colors.assetHoldingsBadgeBgColor};
`;

export const LinkEventTransactionMenuIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 16px 16px 8px;
    background-image: url("${props => props.img}");
`;

export const ItemLogoWrapper = styled.div`
    height:24px;
    width:24px;
    min-width:24px;
    border-radius: 40px;
    /* margin-right:12px; */
    img{
        height:24px;
        width:24px;
    }
    margin-top:4px;
`;

export const InstitutionPlaceholderIconWrapper = styled.div`
    ${flexCenter};
    width: 24px;
    min-width:24px;
    height: 24px;
    background-color: ${colors.darkGray3};
    border-radius: 40px;
    margin-top:4px;
`;