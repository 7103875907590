import moment from "moment";
// import NumberFormat from 'react-number-format';
// import { displayMoneyValue } from "../../../../../../../common/utils/number-display.utils";

export const loansOverviewData = {
    value: {title:'Current Balance', subTitle: 'Remaining loan amount' , lastUpdate: true },
    stats : [
        {title:'APR', subTitle : 'Annual interest rate' , dataKey: 'interest' , type : 'perc' , specificDecimalScale: 3,
        childs:[
            {title:'Original Loan Amount', subTitle : 'Cash in risk' , dataKey: 'originalLoanAmount' },
        ]},
        {title:'Total Repayment', subTitle : '' , dataKey: 'contributions', multipleValues: true,
        childs:[
            {title:'Interest Payment',
            valueFn: (itemData) => { return itemData.nextReceivableMonths ? ( itemData.nextReceivableMonths + (itemData.nextReceivableMonths === 1 ? ' Month' : ' Months')) : '--'},
            subTitleFn : (itemData) => {
                return itemData.nextReceivable ? 'Next Expected date: ' + moment(itemData.nextReceivable).format('MMM, yyyy') : 'Missing repayment info'
            },
            },
            {title:'Principal Repayment',
            valueFn: (itemData) => { return itemData.nextReceivableMonths ? ( itemData.nextReceivableMonths + (itemData.nextReceivableMonths === 1 ? ' Month' : ' Months')) : '--'},
            subTitleFn : (itemData) => {
                return itemData.nextReceivable ? 'Next Expected date: ' + moment(itemData.nextReceivable).format('MMM, yyyy') : 'Missing repayment info'
            },
            }
        ]},
        {
            title:'Remaining Time', dataKey: 'remainingMonths', type: 'date',
            valueFn: (itemData) => { return itemData.remainingMonths ? ( itemData.remainingMonths + (itemData.remainingMonths === 1 ? ' Month' : ' Months')) : '--'},
            subTitleFn : (itemData) => {
                return itemData.expectedEndDate ? 'End Date: ' + moment(itemData.expectedEndDate).format('MMM, yyyy') : 'Missing end date'
            },  
        },
        {},
    ],
    visuals : []
}
