import React from 'react';
import {
  HighlightedSearchText, ItemContent,
  ItemLogoWrapper,
  ItemMoreInfos,
  ItemText, ItemTitle,
  LogoPlaceHolder,
  MoreInfosItem,
  SelectedCheckMarkSvgWrapper,
  Separator, TickerAutoCompleteResultRowWrapper
} from './TickerTradingTable.styles';

export const TickerAutoCompleteResultRow = ({ ticker, handleItemClick, searchStr, isSeparator, isTickerSelected }) => {

  const hightlightSearchTerm = (title) => {
    if (searchStr) {
      const index = title?.toLowerCase().indexOf(searchStr.toLowerCase());
      if (index !== -1) {
        const length = searchStr.length;
        const prefix = title?.substring(0, index);
        const suffix = title?.substring(index + length);
        const match = title?.substring(index, index + length);
        return (
          <span>{prefix}<HighlightedSearchText>{match}</HighlightedSearchText>{suffix}</span>
        )
      }
    }
    return title;
  }

  const handleResultRowClick = () => {
    if (isTickerSelected) {
      return;
    }
    handleItemClick?.(ticker)
  }

  return (
    !isSeparator ? <TickerAutoCompleteResultRowWrapper
      isTickerSelected={isTickerSelected}
      onMouseDown={handleResultRowClick}
    >
      {ticker.imageUrl ? <ItemLogoWrapper><img alt={ticker.symbol} src={'data:image/png;base64,' + ticker.imageUrl} /></ItemLogoWrapper> :
        ticker.logoUrl ? <ItemLogoWrapper><img alt={ticker.symbol} src={ticker.logoUrl} /></ItemLogoWrapper> :
          <LogoPlaceHolder >
            {(ticker.name && ticker.name !== '') ? ticker.name.charAt(0).toUpperCase() : (ticker.symbol && ticker.symbol !== '') ? ticker.symbol.charAt(0).toUpperCase() : (ticker.type && ticker.type !== '') ? ticker.type.charAt(0).toUpperCase() : ''}
          </LogoPlaceHolder>
      }
      <ItemContent>
        <ItemText>
          <ItemTitle>{hightlightSearchTerm(ticker?.symbol)}, {ticker?.name}</ItemTitle>
          <ItemMoreInfos>
            <MoreInfosItem>
              {ticker.instrument_type} , {ticker.exchange}
            </MoreInfosItem>
          </ItemMoreInfos>
        </ItemText>
      </ItemContent>
      {isTickerSelected ? <SelectedCheckMarkSvgWrapper><SelectedCheckMarkSvg /></SelectedCheckMarkSvgWrapper> : null}
    </TickerAutoCompleteResultRowWrapper> : <Separator />
  )
}

const SelectedCheckMarkSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5103 8.604L10.5059 15.6125L6.99983 12.1075" stroke="black" strokeWidth="2" />
    </svg>
  )
}