import React, { useState, useEffect } from 'react';
import { useStore } from '../../../app/data/root.store';
import { Observer } from 'mobx-react';
import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import {
  DataBoxHeaderWrapper, TitleDescriptionWrapper, DataBoxTitle, DataBoxTextDescription,
  DataBoxBodyWrapper, DataBoxPlanWrapper, PricingDiscountWrapper, PriceWrapper, DiscountWrapper, RenewDateWrapper, FreeTrialBadge, NoMembershipText, MembershipEmptyState, NoMembershipActionButton, ActionsWrapper, BillingMoreActionMenuButton
} from './Billing.style'
import { LogoLoader } from '../../../../common/components/LogoLoader/LogoLoader';
import { displayMoneyValue } from '../../../../common/utils/number-display.utils';
import { PageWrapper, RightSidePushMenuWrapper } from '../../../layout/components/Layout.styles';
import { CardContentBoxWrapper } from '../../../wealth/components/wealth.commons.styles';
import { CancelPlanModal } from './CancelPlanModal';
import { CancelSurveyModal } from './CancelSurveyModal';
import { UpgradePortfolioButton, WhiteButton } from '../../../../common/components/buttons/NewButtons.styles';
import { AppHeader } from '../../../layout/components/Header/Header';
import { TierBadge } from '../../../layout/components/TierBadge/TierBadge';
import { MobileBackButton, ModalCloseIcon, UpgradeCrownIcon } from '../../../../assets/icons/common/common-icons';
import { MenuWrapper, MenuItemWrapperBlackBorder } from '../../../../common/components/DropDownMenu/DropDownMenu.styles';
import { HeaderTitle, ProfileMobileHeader } from '../Profile.styles';
import { MobileBackButtonWrapper } from '../../../layout/components/MobileLayout.styles';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';


export function BillingPage({isPopup , onClosePopup}) {
  const { billingStore, userStore,uiStore } = useStore();
  const [isMoreActionsMenuOpen, setIsMoreActionsMenuOpen] = useState(false);
  // const [isMoreActionsMenuOpen, setIsMoreActionsMenuOpen] = useState(false);

  const { trackEvent } = useAnalytics()

  const handleGoToSetting = async() => {
  const {url} = await billingStore.fetchBillingUrl();
  trackEvent('CTA Clicked', {
      destination: url,
      location: 'Billing Page',
      cta_text: 'Membership settings',
      type: 'Button',
      url: window.location.href
    }, {send_immediately: true})
    window.location.href = url;
  }

  useEffect(() => {
    billingStore.fetchUserPlan()
    uiStore.setIsRightMenuOpen(false);
    uiStore.setIsUserMenuActive(true);
    return () => { 
			uiStore.setIsUserMenuActive(false);
		}
  }, [billingStore,uiStore])

  const handleCancelPlanOnClick = () => {
    billingStore.setShowCancelPlanModal(true);
    setIsMoreActionsMenuOpen(false);
  }

  const handleUpdatePlan = async(flow) => {
    const { url } = await billingStore.createDeepLink(null, flow);
    window.location.href = url;
  }

  return <Observer>{() => (
    <>
    { !isPopup && <>
      {uiStore.isDesktopView ? <AppHeader /> : 
      <ProfileMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
        <MobileBackButtonWrapper onClick={()=>{uiStore.setIsAppMobileMenuOpen(true)}}><MobileBackButton /></MobileBackButtonWrapper>
        <HeaderTitle>{userStore.isViewingOwnPortfolio ? 'B' : `${userStore.firstNameCapitalize}'s b`}illing</HeaderTitle>
      </ProfileMobileHeader>}
    </> }
    {!billingStore.isUserPlanLoaded ? 
      // <LogoLoaderWrapper style={isPopup ? {gridArea:"content"}: {}} >
        <LogoLoader backdrop absolute={!uiStore.isDesktopView || isPopup} />
      // </LogoLoaderWrapper> 
      :
      <>
    <RightSidePushMenuWrapper />
    <PageWrapper withHeader={!isPopup}  isPopup={isPopup}>
        {/* { uiStore.isMobileAppView ? 
          <>
          <DataBoxHeaderWrapper>
            <DataBoxTitle>
                Mobile app billing page content
            </DataBoxTitle>
            </DataBoxHeaderWrapper>
          </> :      */}
         <CardContentBoxWrapper borderLess={isPopup}>
          {isPopup && <div style={{position: 'absolute' , width : '20px' , height: '20px' , right: 20 , cursor:'pointer'}} onClick={()=>{onClosePopup && onClosePopup()}}><ModalCloseIcon /></div>}
          <DataBoxHeaderWrapper>
            <TitleDescriptionWrapper>
              <DataBoxTitle >{userStore.isViewingOwnPortfolio ? 'B' : `${userStore.firstNameCapitalize}'s b`}illing{billingStore.userPlan.inTrial && <><br /><FreeTrialBadge>30 days free trial (ACTIVE)</FreeTrialBadge></>}</DataBoxTitle>
              <DataBoxTextDescription>Current Vyzer membership plan</DataBoxTextDescription>
            </TitleDescriptionWrapper>
          </DataBoxHeaderWrapper>
          <DataBoxBodyWrapper>
            <DataBoxPlanWrapper>
              {userStore.isReadOnlyMode ?
              <MembershipEmptyState>
                <NoMembershipText isTitle >No active membership plan</NoMembershipText>
                <NoMembershipText>For your billing information and historical invoices go to your billing settings</NoMembershipText>
                <NoMembershipActionButton onClick={()=>{billingStore.setShowPricingTableModal(true)}}>Select a plan</NoMembershipActionButton>
              </MembershipEmptyState> :
              <>
                {/* <DataBoxPlanType>{billingStore.userPlan.interval === 'year' ? 'Yearly plan' : 'Monthly plan'}</DataBoxPlanType> */}
                <div style={{display:'flex', alignItems:'center', gap:'8px'}} >
                  <svg width={uiStore.isSideMenuMinimized ? 12 : 53} height="20" viewBox={`0 0 ${uiStore.isSideMenuMinimized ? 12 : 53} 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.294958 4.74219H2.57864L5.81765 12.1991L9.05666 4.74219H11.3404L6.58645 15.6946H5.0484L0.294495 4.74219H0.294958Z" fill="#111213" />
                    <path d="M29.5104 6.72318H23.835V4.74219H33.4432L27.3628 13.7136H33.0396V15.6941H23.449L29.5109 6.72271L29.5104 6.72318Z" fill="#111213" />
                    <path d="M52.0103 4.74219H47.6813H45.7943H45.4675V15.6946H47.6813V6.72318H51.1485L52.0103 4.74219Z" fill="#111213" />
                    <path d="M14.3142 19.9926H16.6622L23.2994 4.74219H20.969L17.8235 11.9663L14.3975 4.74219H12.0208L16.7048 14.5527L14.3142 19.9926Z" fill="#111213" />
                    <path d="M41.9244 12.0494L41.8948 12.1132C41.3366 13.3097 40.3164 13.942 38.945 13.942C36.99 13.942 35.7722 12.4146 35.5283 10.8432H44.3108L44.3118 10.6645C44.3215 8.85526 43.7054 7.2339 42.5765 6.099C41.5661 5.08305 40.2405 4.52393 38.8432 4.52393C35.8389 4.52393 33.395 7.07746 33.395 10.216C33.395 13.3546 35.8328 15.8679 38.945 15.8679C41.1389 15.8679 42.8441 14.8477 43.7369 13.038L41.9244 12.0498V12.0494ZM38.8437 6.44937C40.2595 6.44937 41.5222 7.46162 41.9832 8.93811H35.6644C36.074 7.66713 37.2524 6.44937 38.8437 6.44937Z" fill="#111213" />
                  </svg>
                  <TierBadge tier={userStore.data?.profile?.tier} noBg={userStore.isFreeTier} />
                  </div>
                <PricingDiscountWrapper>
                  {billingStore.userPlan && 
                    <PriceWrapper>{displayMoneyValue((billingStore.userPlan.originalPriceAfterDiscount ? billingStore.userPlan.originalPriceAfterDiscount : billingStore.userPlan.originalPrice) / 100, 'USD')} / {billingStore.userPlan.interval === 'year' ? 'Annual billing' : 'Monthly billing'}</PriceWrapper>
                  }
                  
                  <DiscountWrapper>
                    {/* {billingStore.userPlan.interval === 'year' && billingStore.userPlan.discountPercentage && '(You saved 15% +' + billingStore.userPlan.discountPercentage + '% discount for the first year)'}
                    {billingStore.userPlan.interval === 'year' && !billingStore.userPlan.discountPercentage && '(You saved 15%)'} */}
                    {/* {billingStore.userPlan.interval === 'month' && billingStore.userPlan.discountPercentage && '(You saved ' + billingStore.userPlan.discountPercentage + '% + you can save extra 15% on Yearly plan)'} */}
                    {/* {billingStore.userPlan.interval === 'month' && '(you can save an extra 20% on the annual plan)'} */}
                  </DiscountWrapper>
                </PricingDiscountWrapper>
                <RenewDateWrapper >{!billingStore.userPlan ? 'Free membership' : billingStore.userPlan.inTrial ? `After your trial ends, you will be charged ${displayMoneyValue((billingStore.userPlan.originalPriceAfterDiscount ? billingStore.userPlan.originalPriceAfterDiscount : billingStore.userPlan.originalPrice) / 100, 'USD')} per ${billingStore.userPlan.interval === 'year' ? 'year' : 'month'} starting ${billingStore.userPlan.expiresAtDate}. You can always cancel before then.` : `Your plan renews on ${billingStore.userPlan.expiresAtDate}`}</RenewDateWrapper>
              </>}
            </DataBoxPlanWrapper>
              { uiStore.isMobileAppView ? 
              <DataBoxTextDescription style={{padding:'8px', marginTop:'8px'}}>To manage your billing settings, please access Vyzer on a browser. Billing management is currently unavailable in the mobile app.</DataBoxTextDescription> :
            <ActionsWrapper>
              {/* {!userStore.isReadOnlyMode && <SecondaryButtonBorderless style={{boxShadow:'none'}} onClick={handleCancelPlanOnClick}>Cancel plan</SecondaryButtonBorderless> } */}
              <WhiteButton height={38} onClick={()=>{onClosePopup && onClosePopup(); billingStore.setShowComparePlansModal(true)}}>Explore plans</WhiteButton>
              {(userStore.isFreeTier || userStore.isTier2or3) && <UpgradePortfolioButton blueBg={userStore.isTier2or3} height={38} onClick={()=>{onClosePopup && onClosePopup(); billingStore.setShowPricingTableModal(true)}}><UpgradeCrownIcon />Upgrade</UpgradePortfolioButton>}
              { !userStore.isFreeTier && !userStore.isReadOnlyMode && !(!userStore.isRedeemed && userStore.isTier2or3) &&
              <Dropdown
                overlay={BillingMoreActionMenu({
                  isFree: userStore.isFreeTier,
                  ShowPricingTable:  ()=>{onClosePopup && onClosePopup();billingStore.setShowPricingTableModal(true)},
                  cancelMembership : handleCancelPlanOnClick,
                  updatePlan: (flow) => handleUpdatePlan(flow),
                  goToSettings: handleGoToSetting
                })}
                visible={isMoreActionsMenuOpen}
                onVisibleChange={(visible) => { setIsMoreActionsMenuOpen(visible) }}
                trigger={['click']} placement="bottomRight" >
                  <BillingMoreActionMenuButton isOpenState={isMoreActionsMenuOpen}>{billingStore.isLoading ? <LoadingOutlined /> : <Billing3Dots />} </BillingMoreActionMenuButton>
                </Dropdown> }

                {userStore.isReadOnlyMode && <WhiteButton height={38} onClick={handleGoToSetting}>{billingStore.isLoading ? <LoadingOutlined /> : 'Billing settings'}</WhiteButton> }

              {/* <ActionsButton onClick={handleGoToSetting}><MembershipSettingsIconWrapper>{billingStore.isLoading ? <LoadingOutlined /> : <MembershipSettingsIcon />}</MembershipSettingsIconWrapper>Membership settings</ActionsButton> */}
            </ActionsWrapper> }
          </DataBoxBodyWrapper>
    </CardContentBoxWrapper>
    {/* } */}
    </PageWrapper></>
      }
      {billingStore.showCancelPlanModal ? <CancelPlanModal onBackDropClick={()=>{billingStore.setShowCancelPlanModal(false)}} /> : null}
      {billingStore.showCancelSurveyModal ? <CancelSurveyModal onBackDropClick={()=>{billingStore.setShowCancelSurveyModal(false)}} /> : null}
      </>
  )}
  </Observer>
}

function BillingMoreActionMenu({ isFree , ShowPricingTable, cancelMembership, updatePlan, goToSettings }) {
  return (
    <MenuWrapper>
      <MenuItemWrapperBlackBorder onClick={()=>{isFree ? ShowPricingTable() : updatePlan('subscription_update')}}>Change / Update plan</MenuItemWrapperBlackBorder>
      { !isFree && <>
        <MenuItemWrapperBlackBorder onClick={()=>{updatePlan('subscription_update')}}>Change billing period</MenuItemWrapperBlackBorder>
        <MenuItemWrapperBlackBorder onClick={()=>{updatePlan('payment_method_update')}}>Update payment method</MenuItemWrapperBlackBorder>
        <MenuItemWrapperBlackBorder onClick={goToSettings}>Purchase history</MenuItemWrapperBlackBorder>
      </>}
      <MenuItemWrapperBlackBorder onClick={cancelMembership}>Cancel membership</MenuItemWrapperBlackBorder>
    </MenuWrapper>
    // <EventTypeActionMenuWrapper>
    //   <EventTypeActionMenuContent>
    //     {createEventTypes.map((eventType, index) => (
    //       eventType.isPredicted || eventType.isHidden ? null :
    //         <EventTypeActionMenuItem onClick={() => { setItem(index) }} key={index} isHidden={index === 0 && isValueDisabled && !eventType.isAlwaysVisible} >
    //           <EventTypeActionMenuItemContentWrapper>
    //             <EventTypeActionMenuItemTitleWrapper>{eventType.title}</EventTypeActionMenuItemTitleWrapper>
    //             <ArrowRightSvgWrapper><ArrowRightSvg /></ArrowRightSvgWrapper>
    //           </EventTypeActionMenuItemContentWrapper>
    //         </EventTypeActionMenuItem>
    //     ))}
    //   </EventTypeActionMenuContent>
    // </EventTypeActionMenuWrapper>
  )
}


const Billing3Dots = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3.33329" cy="9.9987" r="1.66667" fill="black"/>
    <circle cx="9.99992" cy="9.9987" r="1.66667" fill="black"/>
    <circle cx="16.6667" cy="9.9987" r="1.66667" fill="black"/>
  </svg>
)