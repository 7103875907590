import { observer } from 'mobx-react'
import React from 'react'
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal'
import { PrimaryButtonWithIcon } from '../../../../common/components/buttons/NewButtons.styles'
import { useStore } from '../../../app/data/root.store'
import { MailOutlinedIcon, UploadFailIcon } from '../../assets/icons'
import { DeepAnalysisSuccessModalContent, DeepAnalysisSuccessModalFooter } from '../DeepAnalysisSuccessModal/DeepAnalysisSuccessModal.styles'
import { ActionLinkButton, AltTextPrompt, FileDropArea, WrongFileContent, LighterMainTextPrompt, MainTextPrompt, PromptWrapper } from '../MagicBoxAiActivationFlow.styles'
import { MagicboxAIModalHeader } from '../MagicboxAIModalHeader'
import { SUPPORTED_FILE_FORMATS, MAX_MAGIC_BOX_FILE_SIZE_MB } from '../../data/magic-box-ai-store'

export const DeepAnalysisFailModal = observer(() => {
  const { magicBoxAiStore, userStore } = useStore();
  const onClose = () => magicBoxAiStore.setFileForUpload(null);


  return (
    <BaseModal borderRadius={8} height='508px' noSidebarMargin={userStore.isWelcome}>
      <MagicboxAIModalHeader onClose={onClose} />
      <DeepAnalysisSuccessModalContent>
        <WrongFileContent>
          <FileDropArea>
            <UploadFailIcon />
          </FileDropArea>
          <PromptWrapper>
            <MainTextPrompt>Oh no! Upload failed</MainTextPrompt>
            {
              magicBoxAiStore.fileForUpload && magicBoxAiStore.fileForUpload.errors.map((error) => {
                switch (error.code) {
                  case 'file-too-large':
                    return <InvalidFileSizePrompt />;
                  case 'file-invalid-type':
                    return <InvalidFileTypePrompt />;
                  default:
                    return null;
                }
              })
            }
          </PromptWrapper>
        </WrongFileContent>
      </DeepAnalysisSuccessModalContent>

      <DeepAnalysisSuccessModalFooter>
        <ActionLinkButton withIcon href="mailto:support@vyzer.co"><MailOutlinedIcon />Vyzer support team</ActionLinkButton>
        <PrimaryButtonWithIcon withIcon onClick={onClose}><UploadIconSvg isWhite />Upload another file</PrimaryButtonWithIcon>
      </DeepAnalysisSuccessModalFooter>
    </BaseModal>
  )
})

const UploadIconSvg = ({ isWhite = false }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 4.75L12.5 15.25" stroke={isWhite ? 'white' : 'black'} strokeWidth="1.5" />
      <path d="M16 8.25L12.5 4.75L9 8.25" stroke={isWhite ? 'white' : 'black'} strokeWidth="1.5" />
      <path d="M5.5 13.3895V18.25C5.5 18.8023 5.94772 19.25 6.5 19.25L18.5 19.25C19.0523 19.25 19.5 18.8023 19.5 18.25L19.5 13.25" stroke={isWhite ? 'white' : 'black'} strokeWidth="1.5" />
    </svg>
  )
}

const InvalidFileTypePrompt = () => {
  return (
    <>
      <AltTextPrompt style={{ paddingTop: '16px' }}>Supported formats:</AltTextPrompt>
      <LighterMainTextPrompt>{SUPPORTED_FILE_FORMATS.map((file)=> file.slice(1).toUpperCase()).join(', ')}</LighterMainTextPrompt>
    </>
  );
};

const InvalidFileSizePrompt = () => {
  return (<LighterMainTextPrompt>File max size: {MAX_MAGIC_BOX_FILE_SIZE_MB}MB</LighterMainTextPrompt>);
};
