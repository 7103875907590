import { AddValueUpdatePrevButton} from "../CreateEvent.styles";
import { PlusOutlined } from '@ant-design/icons';
import { isRequired } from "../../../../../../add-item/data/input-validations";
import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { createLabelFnWithPredictedValue } from "../../Events/PredictedAmountDisplay/PredictedAmountDisplay";


const categoriesWithAllowNegativeValueFotValueUpdate = [1,2,3,4];
export const eventTypeGenerator = (withContribution = false, withDistribution = false, withValueUpdate = false, customRows = []) => {
  return ({updateValue}) => ([
      {
          title: '',
          rows: [
              // ...commonRows,
              ...(withContribution || withDistribution ? [{fields:[
                  {   
                      key:'transactionValue', type: 'numberWithHoldings', 
                      // label: withContribution ? 'Contribution value' : 'Distribution value', 
                      validations:[isRequired],
                      generatePropsFn : (values)=>{
                          const labelFn = createLabelFnWithPredictedValue(
                            withContribution ? 'Contribution value' : 'Distribution value',
                            values.eventPredictedAmount,
                            `The expected ${withContribution ? 'contribution' : 'distribution'} amount`)
                          return {
                              holdings: values.holdings,
                              label:labelFn,
                              prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                              placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                              decimalScale : 2, 
                          }
                      },
                      // onBlur:({value,formValues})=>{
                      //     if(formValues.hasOwnProperty('updatedValue')){
                      //         const newVal = ( +formValues.valueAtDate || +formValues.value ) + ( value * (withContribution ? 1 : -1));
                      //         updateValue('updatedValue', newVal > 0 ? newVal : 0 );
                      //     }
                      // }
                  }   
              ]}] : []),
              ...customRows.map(cr=> typeof cr === 'function' ? cr({updateValue,isDistribution : withDistribution}) : cr),
              ...(withValueUpdate ? [{fields:[
                  // {key:'valueAtDate', type: 'number', label: 'Value at date', disabled:true, 
                  //     // defaultValueFn: (values)=>{
                  //     //     return values.valueAtDate ? values.valueAtDate : values.value ? values.value : '';
                  //     // },
                  // generatePropsFn : (values)=>{
                  //     return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                  // }},
                  {key:'updatedValue', type: 'numberWithHoldings', label: 'Updated asset value' ,
                  validations:withContribution || withDistribution ? [] : [isRequired],
                  // showPrevComp:withContribution || withDistribution,
                  ClickToShowComponent:({show})=>(<AddValueUpdatePrevButton onClick={show}><PlusOutlined /> Add Change in value</AddValueUpdatePrevButton>),
                  // defaultValueFn: (values)=>{
                  //     return values.value ? values.value : ''
                  // },
                  generatePropsFn : (values)=>{
                      return {
                          prefix: CURRENCY_SYMBOL[values.currency] || '',
                          placeholder:CURRENCY_SYMBOL[values.currency] || '',
                          showPrevComp:( withContribution || withDistribution ) && !values.updatedValue ,
                          holdings: values.holdings,
                          decimalScale : 2, 
                          allowNegative: categoriesWithAllowNegativeValueFotValueUpdate.includes(values.categoryId || 0)
                      }
                  }
                  }
              ]}] : []),
              {fields:[],type:'separator'},
              {fields: [{key:'eventRemarks', type: 'textarea', label: 'Remarks' , rows:2 , placeholder:'Add notes to remember'}]}
          ]
      }
  ])
}

// export const valueUpdateOnly = eventTypeGenerator(false, false, true);
// export const contributionOnly = eventTypeGenerator(true);
// export const distributionOnly = eventTypeGenerator(false, true);
// // const createNewLoanOnly = eventTypeGenerator(false, false, true);
// export const contributionAndValueUpdate = eventTypeGenerator(true, false, true);
// export const distributionAndValueUpdate = eventTypeGenerator(false, true, true);
// // const newLoanAndValueUpdate = eventTypeGenerator(false, false, true, [newLoanField]);
// export const itemCreatedValueUpdate = eventTypeGenerator(true, false, true);