import React from 'react';
import { CollapseIconWrapper, DocumentRow, DocumentStatusBadge, DocumentStatusBadgeWrapper, DocumentStatusIconWrapper, MagicBoxFileDescription, MagicBoxFileDetails, MagicBoxFileDetailsWrapper, MagicBoxFileNameWrapper, MagicBoxFileSizeWrapper, TableContentRightItems, TableDateNameCell, TableDateNameCellDateText, TableDateNameCellNameText } from './MagicBox.styles';
import { CommonTextOverflow } from '../../../common/components/CommonTextOverflow/CommonTextOverflow'
import { DocumentIconWrapper } from './DeepAnalysisSuccessModal/DeepAnalysisSuccessModal.styles'
import { ArrowCollapsedIcon } from '../assets/icons';
import { docTypes } from './DeepAnalysisSuccessModal/assets/docTypes';
import moment from 'moment'
import { DocumentStatusIcon } from './MagicBoxPage';
import { DeleteIconWrapper } from './MagicBoxAiActivationFlow.styles';
import { DeleteIcon } from '../../../assets/icons/common/common-icons';

export const MagicBoxFileRow = (props) => {
    const { originalName, createdAt, status, size, llmDescription, fullName, open, onClick, onDelete } = props;
    const fileType = originalName.split('.').pop()?.toLowerCase();
    const hasDescription = !!llmDescription;
    const onClickRow = () => {
        if (hasDescription) {
            onClick?.();
        }
    };

    return (
        <>
            <DocumentRow
                withCollpase
                open={open}
                onClick={onClickRow}
                pointer={hasDescription}
                gap={8}
            >
                <MagicBoxFileNameWrapper>
                    <DocumentIconWrapper>{docTypes[fileType]}</DocumentIconWrapper>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 36px)' }}>
                        <CommonTextOverflow text={originalName} />
                        <MagicBoxFileSizeWrapper>File size: {(size / 1024).toFixed(0)}kb</MagicBoxFileSizeWrapper>
                    </div>
                </MagicBoxFileNameWrapper>
                <TableContentRightItems>
                    <TableDateNameCell>
                        <TableDateNameCellDateText>{moment(createdAt).format('MMM D, YYYY')}</TableDateNameCellDateText>
                        <TableDateNameCellNameText>by {fullName}</TableDateNameCellNameText>
                    </TableDateNameCell>
                    <DocumentStatusBadgeWrapper>
                        <DocumentStatusBadge status={status} style={{ width: 'fit-content', margin: '0' }}>
                            <DocumentStatusIconWrapper><DocumentStatusIcon status={status} /></DocumentStatusIconWrapper>
                            {status === 'Processing' || status === 'Pending' ? 'In process' : status}
                        </DocumentStatusBadge>
                    </DocumentStatusBadgeWrapper>
                    {
                        !onDelete ?
                            hasDescription &&
                            <CollapseIconWrapper open={open}>
                                <ArrowCollapsedIcon />
                            </CollapseIconWrapper> :
                            <DeleteIconWrapper>
                                <DeleteIcon onClick={onDelete} />
                            </DeleteIconWrapper>
                    }
                </TableContentRightItems>
            </DocumentRow>
            {
                hasDescription &&
                <MagicBoxFileDetailsWrapper isCollapsed={!open}                >

                    <MagicBoxFileDetails>
                        <MagicBoxFileDescription>{llmDescription}</MagicBoxFileDescription>
                    </MagicBoxFileDetails>

                </MagicBoxFileDetailsWrapper>
            }
        </>
    );
};