import styled from '@emotion/styled/macro';
import { views } from '../../styles/theme.styles';

export const Wrapper = styled.div`
    width: 590px;
    background: #3F3F45;
    box-shadow: 0px 0.2px 2px rgba(0, 0, 0, 0.2), 0px 1px 4px #E5E9F2;
    border-radius: 9px;
    display:flex;
    flex-direction:column;
    max-height:340px;
    padding:16px 0px;
    ${views.tablet}{
        width: 100%;
        max-width:100%;
    }
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    padding:0 16px 8px;
    color: #FFFFFF;
`;

export const TableHeaderRow = styled.div`
    display: grid;
    grid-template-columns: 140px 90px 200px 128px;
    ${views.tablet}{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    padding:4px 16px 8px;
`;

export const HeaderCell = styled.div`
    font-size:14px;
    color:#9D9D9D;
`;

export const TableScrollContent = styled.div`
    overflow-y:auto;
`;

export const RowWrapper = styled.div`
    display: grid;
    grid-template-columns: 140px 90px 200px 128px;
    padding:${props=>props.isEdit ? '4' : '8'}px 16px;
    &:hover{
        background-color:#2A2A2E;
    }
    ${views.tablet}{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`;

export const RowCell = styled.div`
    font-size:16px;
    line-height:24px;
    color: #FFFFFF;
    align-items: center;
    display:flex;
    padding-right:8px;
`;

export const RowActionsCell = styled.div`
    /* position:absolute; */
    align-items: center;
    display:${props=>props.show? 'flex' : 'none'};
    /* right:0px;
    top:4px; */
    ${RowWrapper}:hover &{
        display:flex;
    }
`;

export const ActionButtonWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    svg{
        height: 24px;
    }
`;

export const SaveButton = styled.div`
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:80px;
    color:#3F3F45;
    font-size:16px;
    height: 32px;
    margin-right: 16px;
    cursor: pointer;
`;
