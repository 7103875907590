import React , { useState, useEffect } from 'react';

import { getData } from '../../../../common/utils/network.utils';
import { LogoLoader } from '../../../../common/components/LogoLoader/LogoLoader';
import { PageWrapper } from '../../../layout/components/Layout.styles';




export function AkoyaRedirectComponent(props) {
  const [isSendingRequest,setIsSendingRequest] = useState(true);

    useEffect(() => {  
        getData('akoya/token'+window.location.search)
        .then(res=>{
            window.close();
            setIsSendingRequest(false);
        }).finally(()=>{
          window.close();
        })
      
       
       return () => {
         //cleanup
       }
  }, []);


  

  return (<PageWrapper style={{alignItems:'center'}}>
    

  
    { isSendingRequest ? 
              <LogoLoader wbg/>
               : <div onClick={()=>{window.close()}}>Done</div>
      }
   </PageWrapper>)
}

