import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../styles/combos.styles";

export const DatePickerWithCustomOptionWrapper = styled.div`
  position: relative;
`;

export const ExtraFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  align-items: center;
  // background-color: red;
  gap:8px;
  padding:8px;
`;

export const ExtraFooterButton = styled.div`
  border-radius: 28px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16), 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  height: 36px;
  padding: 0 30px;
  cursor: pointer;
  ${flexCenter};
`;

export const CustomValueLabel = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  right: 42px;
  font-size: 18px;
  line-height: 18px;
  padding: 9px 12px;
  background-color: #fff;
  pointer-events: none;
  ${props=>props.disabled ? `
    background: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
  ` : ``}
`;