import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { MobileBackButton } from "../../../../assets/icons/common/common-icons";
import { ROUTES } from "../../../../common/constants/routes.const";
import { navigateToUrl } from "../../../../common/utils/navigation.utils";
import { useStore } from "../../../app/data/root.store";
import { AppHeader } from "../../../layout/components/Header/Header";
import { PageWrapper, RightSidePushMenuWrapper } from "../../../layout/components/Layout.styles";
import { MobileBackButtonWrapper } from "../../../layout/components/MobileLayout.styles";
import { CardContentBoxWrapper } from '../../../wealth/components/wealth.commons.styles';
import { DataBoxFormContentWrapper, DataBoxFormWrapper, DataBoxInfoWrapper, DataBoxInputWrapper, HeaderTitle, InfoBoxDescription, InfoBoxTitle, InfoBoxWrapper, ProfileMobileHeader } from '../Profile.styles';
import { CustomClassEmptyStateRowIconWrapper, CustomClassEmptyStateRowTextWrapper, CustomClassEmptyStateRowWrapper } from './CustomClassListContent.styles';
import { CustomClassRow } from './CustomClassRow';
import { EditCustomClass } from './EditCustomClass';

import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';


import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';


export const CustomClassListContent = observer(() => {
  const { uiStore, customClassStore } = useStore();
  // eslint-disable-next-line
  
  const [isNewCustomClassCreated, setIsNewCustomClassCreated] = useState(false);
  const [forceItemsClose, setForceItemsClose ] = useState(false);

  const handleCustomClassEmptyRowClick = () => {
    customClassStore.setShowModal(true);
  }
  
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const {active, over } = event;
    
    if (active.id !== over.id) {
      const itemsIds = customClassStore.customClasses.map(item=> item.id);
      const oldIndex = itemsIds.indexOf(active.id);
      const newIndex = itemsIds.indexOf(over.id);
      
      const updatedData = arrayMove(itemsIds, oldIndex, newIndex);
      customClassStore.updateCustomClassOrder(updatedData);
    }
    setForceItemsClose(false);
  }


  const handleDragStart = (event) => {
    setForceItemsClose(true);
  }

  return (
    <>
      <CardContentBoxWrapper noBoxShadow borderless style={{ paddingBottom: '16px' }}>
        <DataBoxFormContentWrapper>

          <DataBoxFormWrapper>
            <DataBoxInputWrapper style={{ gap: '8px' }}>



            <DndContext 
            modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext 
        items={customClassStore.customClasses.map(item => item.id)}
        strategy={verticalListSortingStrategy}
        
      >
          {customClassStore.customClasses.map((item, index) => {
                return <CustomClassRow key={index} id={item.id} item={item} isNewCustomClassCreated={isNewCustomClassCreated} forceClose={forceItemsClose} />
              })}
      </SortableContext>
    </DndContext>

            
              <CustomClassEmptyStateRowWrapper onClick={handleCustomClassEmptyRowClick}>
                <CustomClassEmptyStateRowIconWrapper><EmptyStatePlusIcon /></CustomClassEmptyStateRowIconWrapper>
                <CustomClassEmptyStateRowTextWrapper>Create new{uiStore.isDesktopView ? ' custom class' : ''}... </CustomClassEmptyStateRowTextWrapper>
              </CustomClassEmptyStateRowWrapper>
            </DataBoxInputWrapper>
          </DataBoxFormWrapper>

          <DataBoxInfoWrapper>
            <InfoBoxWrapper>
              <InfoBoxTitle>
                What are custom classes?
              </InfoBoxTitle>
              <InfoBoxDescription>
                With custom classes and sub-classes, you can organize your portfolio for tailored wealth management. <a target='_blank' rel='noreferrer' href='https://help.vyzer.co/en/articles/9607213-how-to-set-custom-classes-in-vyzer'>Learn more</a>
              </InfoBoxDescription>
            </InfoBoxWrapper>
          </DataBoxInfoWrapper>

        </DataBoxFormContentWrapper>
      </CardContentBoxWrapper>
   
      {(customClassStore.showModal || !uiStore.isDesktopView) &&
        <EditCustomClass
          onClose={() => { customClassStore.setShowModal(false); }}
          drawerVisible={customClassStore.showModal}
          onSave={(data) => { customClassStore.createCustomClass(data); setIsNewCustomClassCreated(true); customClassStore.setShowModal(false); }}
        />}
    </>
  );
});

export const CustomClassListMobilePage = observer(({ isWelcome }) => {
  const { uiStore } = useStore();
  let history = useHistory();

  return (<>
    {!isWelcome && <>
      {uiStore.isDesktopView ? <AppHeader /> : <ProfileMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
        <MobileBackButtonWrapper onClick={() => { navigateToUrl(history, ROUTES.preferences.url) }}><MobileBackButton /></MobileBackButtonWrapper>
        <HeaderTitle>{ROUTES.customClassList.title}</HeaderTitle>
      </ProfileMobileHeader>}
    </>}
    <RightSidePushMenuWrapper />
    <PageWrapper withHeader>
      <CustomClassListContent />
    </PageWrapper>
  </>
  )
})

const EmptyStatePlusIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 12.25L7 1.75" stroke="#9097A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.25 7H1.75" stroke="#9097A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}