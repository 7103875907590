import styled from "@emotion/styled/macro";

export const ContainerAutocompleteWrapper = styled.div`
  position: relative;
  ${props => props.withHoverAction ? `
  :hover {
    ${HoverActionSvgWrapper} {
      opacity: 1;
    }
  }
  ` : ``}
`;

export const HoverActionSvgWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(120%,-50%);
  opacity: 0;
  cursor: pointer;
`;