import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../common/styles/combos.styles';
import { MenuItemWrapperWealthPie, MenuWrapperWealthPie } from '../../../common/components/DropDownMenu/DropDownMenu.styles';
// import { colors, shadows, sizes, views } from '../../../common/styles/theme.styles';

export const AllocateByMenuButton = styled.div`
    color: #9097A3;
    font-size:14px;
    font-weight: normal;
    cursor: pointer;
    ${flexCenter};
    pointer-events:auto;
    svg {
        path {
            stroke:#9097A3;
        }
    }
    ${props=>props.isActive ? `
        background-color: #80808045;
    ` : `
    
    `}
`;

export const ArrowIconWrapper = styled.div`
    ${flexCenter};
    margin-left:8px;
    svg {
        transition: transform 0.3s;
        transform: rotate(${props=>props.isOpen ? '180' : '0'}deg);
    }
`;


export const AllocatedByMenuWrapper = styled(MenuWrapperWealthPie)`
    width: 260px;
`;

export const AllocatedByMenuItemWrapper = styled(MenuItemWrapperWealthPie)`
    justify-content: flex-start;
`;

export const AllocatedByMenuItemText = styled.div`
    flex:1;
    padding-left: 8px;
`;

export const InstitutionLogoWrapper = styled.div`
  ${flexCenter}
  height:32px;
  width:32px;
  border-radius: 23px;
  img{
      height:32px;
      width:32px;
  }
`;