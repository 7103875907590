import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../../../../common/styles/combos.styles";
import { colors } from "../../../../../../../common/styles/theme.styles";
import { animateSpin } from "../../../../../../../common/styles/animations.styles";

export const CenterContentWrapper = styled.div`
  width: 100%;
`;

export const LogoLoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 200px;
`;

export const TableRowSizes = styled.div`
  display: grid;
  grid-template-columns:
  ${props => props.categoryAttributeCount ? `repeat(${props.categoryAttributeCount}, 116px)` : ``};
`;

export const HeaderTableColumns = styled(TableRowSizes)``;

export const HeaderWrapper = styled.div`
  display:flex;
  flex-direction:column;
`;

export const HeaderTableLeftColumn = styled.div`
  display:flex;
  width: 240px;
`;

export const HeaderTableRightColumn = styled(HeaderTableLeftColumn)`
  width: 134px;
  justify-content:${props => props.isValue ? 'flex-end' : 'flex-start'};
  box-shadow: none;
`;

export const HeaderTableCell = styled.div`
  font-size:12px;
  line-height: 14px;
  margin:8px 0px;
  padding:0 8px;
  color:${colors.darkGray3};
  border-left:1px solid ${colors.gray3};
  display:flex;
  justify-content: space-between;
  :first-of-type{
    border-left:none;
  }
`;

export const StaticLeftHeaderTableCell = styled(HeaderTableCell)`
  padding: 0 0 0 24px;
`;

export const StaticRightHeaderTableCell = styled(StaticLeftHeaderTableCell)`
  padding: 0 24px 0 0;
  margin:7px 0px;
`;

export const TableCell = styled.div`
  ${flexCenter};
  justify-content:${props => props.isValue ? 'flex-end' : 'flex-start'};
  font-size:14px;
  line-height:19px;
  padding:0px 8px;
  ${props=>props.withColors ? props.isPositive ? `color: ${colors.inflow2}` : `color: ${colors.outflow}` : ``};
`;

export const StaticRightColumnTableCell = styled(TableCell)`
  padding:12px 0;
  padding-left: 8px;
  padding-right: 24px;
  border: none;
  :not(:last-of-type){
    border-bottom:1px solid ${colors.lightGray2};
  }
  min-height: 54px;
  width: 100%;
  ${props=>props.isClassView ? `cursor: pointer` : `cursor: default`};
  ${props=>props.hoveredIndex === props.tickerId ? `
    background-color:${colors.lightGray1};
  ` : null}
`;

export const StaticLeftColumnTableCell = styled(StaticRightColumnTableCell)`
  padding:2.5px 0;
  border: none;
  padding-left: 24px;
  padding-right: 16px;
`;

export const TableRowWrapper = styled.div`
  display:grid;
  grid-template-columns:
  ${props => props.categoryAttributeCount ? `repeat(${props.categoryAttributeCount}, 116px)` : ``};
  min-height: 54px;
  width: ${props => props.categoryAttributeCount * 116}px;
`;

export const ListItemWrapper = styled(TableRowWrapper)`
  :not(:last-of-type){
    border-bottom:1px solid ${colors.lightGray2};
  }
  padding:12px 0;
  ${props=>props.isClassView ? `cursor: pointer` : `cursor: default`};
  /* transition:background-color 0.2s; */
  :hover{
    background-color:${colors.lightGray1};
  }
  ${props=>props.tickerId === props.hoveredIndex ? `
    background-color:${colors.lightGray1};
  ` : null}
`;

export const TickersListLeftColumn = styled.div`
  display:flex;
  flex-direction:column;
  width: 240px;
`;

export const TickersListRightColumn = styled(TickersListLeftColumn)`
  width: 134px;
  box-shadow: none;
`;

export const ItemContent = styled.div`
  flex:1;
  overflow: hidden;
`;

export const ItemLogoWrapper = styled.div`
  ${flexCenter};
  height:26px;
  width:26px;
  border-radius: 23px;
  margin-right:12px;
  img{
    height:26px;
    width:26px;
  }
`;

export const ItemText = styled.div`
  display:flex;
  flex-direction:column;
`;

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  font-size:14px;
  font-weight:500;
`;

export const ItemMoreInfos = styled.div`
  font-size:12px;
  color: #9097A3;
  display:flex;
  flex-direction:row;
  align-items:center;
`;

export const MoreInfosItem = styled.div`
  font-size:12px;
  color: #9097A3;
  &:not(:last-child){padding-right:8px;}
  display: flex;
  align-items: center;
`;

export const TickersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftColumnWrapper = styled.div`
  display:flex;
  flex-direction: column;
  ${props => props.isOverflowing !== -1 ? `box-shadow: 7px 0px 6px -5px #00000014` : ``};
  z-index: 1;
`;

export const MiddleColumnScrollableArea = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

export const RightColumnWrapper = styled(LeftColumnWrapper)`
  padding-right: 0;
  box-shadow: none;
  ${props => props.isOverflowing !== 1 ? `box-shadow: -7px 0px 6px -5px #00000014` : ``};
`;

export const TickersTabEmptyStateWrapper = styled.div`
  ${flexCenter}
  flex-direction: column;
  min-height: 240px;
  gap: 8px;
`;

export const TickersTabEmptyStateMessage = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #000;
  /* color:${colors.gray2}; */
  text-align:center;
  max-width: 260px;
`;

export const TickersTabEmptyStateMessageTitle = styled(TickersTabEmptyStateMessage)`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.darkGray1};
`;

export const TickerIconPlaceholder = styled.div`
  ${flexCenter};
  background-color: #B3E8CF;
  color: ${colors.darkGray1};
  font-weight: 500;
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;

export const TickerIconWrapper = styled.div`
  ${flexCenter};
  img {
    border-radius: 23px;
    object-fit: contain;
    background-color: #fff;
  }
`;

export const TickersListEmptyStateWrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  min-height: 300px;
`;

export const TickersListEmptyStateTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-top: 16px;
  padding-bottom: 8px;
`;

export const TickersListEEmptyStateText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.darkGray1};
  text-align: center;
  max-width: 270px;
`;

export const TooltipTriggerWrapper = styled.div`
    margin-left: 8px;
    width: 16px;
    height: 16px;
    svg {
      width: 16px;
      height: 16px;
    }
`;

export const HeaderCellWithTooltipWrapper = styled.div`
  display: flex;
`;

export const SyncIconWrapper = styled.div`
    svg{
        width:24px;
        height:24px;
        // path{
        //     fill:${colors.darkGray1};
        // }
    }
    animation-name: ${animateSpin};
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    ${flexCenter};
    margin: 8px 0px;
`;

export const SyncingTransactionsHistoryTextWrapper = styled.div`
    font-weight: 450;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: ${props=>props.isBolded ? colors.darkGray1 : colors.darkGray3};
`;