import { Button, DatePicker } from 'antd';
import { Observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { ArrowDownSmallTriangleIcon, CactusIcon, LinkedItemIcon, SyncedLightningIcon } from '../../../../../../assets/icons/common/common-icons';
import { displayMoneyValue } from '../../../../../../common/utils/number-display.utils';
import { TabWrapper } from '../Info/AssetInfo.styles';
import { ArrowDownSmallTriangleIconWrapper, CenterContent, HeaderCell, HeaderWrapper, LinkedItemColumnHeader, SyncIconWrapper, SyncingTransactionsHistoryTextWrapper, TableContentWrapper, TableHeaderRow, TotalInflowValue, TotalOutflowValue, TotalTransactionsWrapper, TransactionsTabEmptyStateMessage, TransactionsTabEmptyStateWrapper, TransactionsTabManualAccountStateTextWrapper, TransactionsTabManualAccountStateWrapper, WizardButton } from './AssetTransactionsTab.styles';
import { TrnRow } from './TrnRow';
import { ActionLockedIconWithTooltip } from '../../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { CommonBadge } from '../../../../../../common/components/CommonBadge/CommonBadge';
import { useStore } from '../../../../../app/data/root.store';
import { PlaidLink } from '../../../../../connected-institutions/components/plaid-link/PlaidLink';
import { SaltedgeLink } from '../../../../../connected-institutions/components/saltedge-link/SaltedgeLink';
import { ProviderLinkWrapper } from '../AssetPageHeader.styles';
import { ReactComponent as NoTransactionsSkeletonImage } from './assets/transactions-skeleton.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { AkoyaLink } from '../../../../../connected-institutions/components/akoya-link/AkoyaLink';

const tableColumns = ['Date', 'Value', 'Description', (<LinkedItemColumnHeader><LinkedItemIcon />Linked item</LinkedItemColumnHeader>)];

export const AssetTransactionsTab = (props) => {
  const { item, isIncognito } = props;
  const { cashAccountTransactions, createItemStore, userStore, billingStore , itemStore, uiStore } = useStore()
  const today = moment();

  useEffect(() => {
    cashAccountTransactions.setData(item.transactions);
    return () => { }
  }, [item.transactions, cashAccountTransactions])

  useEffect(() => {
    cashAccountTransactions.setTimeData(moment());
    return () => { }
  }, [cashAccountTransactions])

  const disabledDate = (current) => {
    return current && cashAccountTransactions.transactions.length > 0 ?
      (current < moment('' + cashAccountTransactions.transactions[0].year).startOf('year')
        || current > moment().endOf('month'))
      : true
  }

  const handleTimeframeChange = (date, dateString) => {
    cashAccountTransactions.setTime(date.year(), date.month())
  }

  const handleSyncAccountClick = (e) => {
    if (userStore.isActionBlockedByRole('syncFromItemPageCashAccountEmptyState')) {
      userStore.setShowLimitedSharedAccess(true);
    } else if (userStore.isDemoOrReadOnlyMode) {
      billingStore.setShowPricingTableModal(true);
      e.stopPropagation();
    } else {
      createItemStore.setInstitutionStep('bank');
      createItemStore.setShowModal(true);
    }
  }

  const handleInstReconnect = () => {
    window.location.reload();
  }

  const handleReconnectError = () => {
    uiStore.setShowReconnectErrorModal(true, itemStore.item.connectedInstitutionName , itemStore.item.logoBase64, itemStore.item.logoUrl );
  }

  return (
    <Observer>{() => (
      <TabWrapper style={{ padding: '0 8px' }}>
        <CenterContent>
          {!item.isConnected && !cashAccountTransactions.transactions.length ?
            <TransactionsTabManualAccountStateWrapper>
              <NoTransactionsSkeletonImage style={{ width: '100%', height: '158px' }} />
              <WizardButton onClick={handleSyncAccountClick} margin='32px 0 12px 0' black inline style={{ flex: 0.4 }}><SyncedLightningIcon />Sync account {(userStore.isDemoOrReadOnlyMode || userStore.isActionBlockedByRole('syncFromItemPageCashAccountEmptyState')) && <ActionLockedIconWithTooltip iconColor={'#fff'} isRoleBlocked={userStore.isActionBlockedByRole('syncFromItemPageCashAccountEmptyState')} />}</WizardButton>
              <TransactionsTabManualAccountStateTextWrapper>
                Syncing your bank accounts allows Vyzer to track your transactions and<br />
                <b>keep your assets performance up to date</b>
              </TransactionsTabManualAccountStateTextWrapper>
            </TransactionsTabManualAccountStateWrapper>
            : item.lastSync === null ?
            <TransactionsTabManualAccountStateWrapper style={{marginBottom:'56px'}}>
              <NoTransactionsSkeletonImage style={{ width: '100%', height: '158px' }} />
              <SyncIconWrapper><SyncingTransactionIcon /></SyncIconWrapper>
              <SyncingTransactionsHistoryTextWrapper isBolded>Importing transaction history... </SyncingTransactionsHistoryTextWrapper>
              <SyncingTransactionsHistoryTextWrapper>This process can take some time</SyncingTransactionsHistoryTextWrapper>
            </TransactionsTabManualAccountStateWrapper> :
            <>
              <HeaderWrapper>
                <DatePicker dropdownClassName='cashAccountDatePicker' allowClear={false} format={'MMM YYYY'} inputReadOnly suffixIcon={<ArrowDownSmallTriangleIconWrapper><ArrowDownSmallTriangleIcon /></ArrowDownSmallTriangleIconWrapper>} defaultValue={today} onChange={handleTimeframeChange} picker='month' disabledDate={disabledDate} />
                {cashAccountTransactions.filteredTransactions.length !== 0 && <TotalTransactionsWrapper>
                  Total transactions
                  <TotalInflowValue>+{displayMoneyValue(cashAccountTransactions.totalInflowValue, item.currency, isIncognito)}</TotalInflowValue>
                  <TotalOutflowValue>-{displayMoneyValue(cashAccountTransactions.totalOutflowValue, item.currency, isIncognito)}</TotalOutflowValue>
                </TotalTransactionsWrapper>}
              </HeaderWrapper>
              {cashAccountTransactions.filteredTransactions.length === 0 ?

                <TransactionsTabEmptyStateWrapper>
                  <CactusIcon />
                  <TransactionsTabEmptyStateMessage>No transactions this month</TransactionsTabEmptyStateMessage>
                  {item.isLoginRequired ? (item.connectedProvider === 'plaid' ?
                    <ProviderLinkWrapper>
                      <PlaidLink reconnectId={item.connectedInstitutionId} onSuccess={() => handleInstReconnect()} >
                        <CommonBadge isCTA badgeType={'loginRequired'} lastSync={item.lastSync} />
                      </PlaidLink>
                    </ProviderLinkWrapper>
                     : item.connectedProvider === 'saltEdge' ?
                    <ProviderLinkWrapper>
                      <SaltedgeLink reconnectId={item.connectedInstitutionId} onSuccess={(data) => { handleInstReconnect() }} loadingComp={<Button><LoadingOutlined /></Button>}>
                        <CommonBadge isCTA badgeType={'loginRequired'} lastSync={item.lastSync} />
                      </SaltedgeLink>
                    </ProviderLinkWrapper>
                    :
                    <ProviderLinkWrapper>
                      <AkoyaLink reconnectId={item.connectedInstitutionId} onError={(data)=>{ handleReconnectError(data) }}  onSuccess={(data) => { handleInstReconnect() }} loadingComp={<Button><LoadingOutlined /></Button>}>
                        <CommonBadge isCTA badgeType={'loginRequired'} lastSync={item.lastSync} />
                      </AkoyaLink>
                    </ProviderLinkWrapper>
                  ) : null}
                </TransactionsTabEmptyStateWrapper>
                :
                <>
                  <TableHeaderRow onClick={(e) => { e.stopPropagation() }}>
                    {tableColumns.map((columnLabel, ind) => (
                      <HeaderCell key={'hc1' + ind} isValueCell={columnLabel === 'Value'}>
                        <span>{columnLabel}</span>
                      </HeaderCell>
                    ))}
                  </TableHeaderRow>
                  <TableContentWrapper>
                    {cashAccountTransactions.filteredTransactions.map((trn, index) => (
                      <TrnRow key={index} trn={trn} isIncognito={isIncognito} />
                    ))}
                  </TableContentWrapper>
                </>}
            </>
          }
        </CenterContent>
      </TabWrapper>
    )}</Observer>)
}


const SyncingTransactionIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3 5C16.6679 5.50945 19.25 8.43182 19.25 11.9607C19.25 14.4663 17.9482 16.6662 15.9876 17.9137" stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
      <path d="M14.8658 16.0256L14.8658 19.1366L17.9769 19.1365" stroke="black" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
      <path d="M10.7 19C7.33213 18.4905 4.75001 15.5682 4.75001 12.0393C4.75001 9.53367 6.05185 7.33377 8.01245 6.08633" stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
      <path d="M9.13422 7.97444L9.13425 4.86341L6.02314 4.86353" stroke="black" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
    </svg>
  )
}