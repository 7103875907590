import styled from "@emotion/styled/macro";
import { colors, views } from "../../../../common/styles/theme.styles";
import bgImg from './Icons/portfolio-illustration.png';

export const ModalCloseButtonWrapper = styled.div`
  position:absolute;
  right:16px;
  top:12px;
  width:24px;
  height:24px;
  cursor: pointer;
  path{
    stroke:${colors.darkGray3};
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0 0 32px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: ${colors.darkGray1};
  position: relative;
  ${views.tablet} {
    padding: 24px 24px 0 24px;
  }
`;

export const ModalAltText = styled.div`
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #121D2A;
  ${views.tablet} {
    padding-top: 8px;
    padding-bottom: 24px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  flex: 1;
  padding: 32px;

  .ant-input {
    border: 1px solid #9097A3;
    border-radius: 6px;
  }

  .ant-input:focus, .ant-input-focused {
    border-color: ${colors.darkGray1};
    box-shadow: none;
  }

  overflow-y: auto;
  ${views.tablet} {
    padding: 0 24px 24px 24px;
  }
`;

export const ModalBgWrapper = styled.div`
  background: url(${bgImg}) no-repeat;
  background-size: 100% 366px;
  height: 366px;
`;

export const CancelSurveyModalFooter = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  padding: 20px 32px;
  border-top: 1px solid ${colors.gray3};
  ${views.tablet} {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const ActionButton = styled.div`
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#fff'} ;
    color: ${props => props.whiteText ? '#fff' : colors.darkGray1};
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.3);;
    border-radius: 6px;
    padding: ${props => props.isBack ? '10px 20px' : '8px 16px'};
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 1s;
    ${props => props.withIcon ? `gap: 6px` : ``};
    ${props => props.isBack ? `margin-right: auto` : ``};
    ${props => props.disabled ? `opacity: 0.2; cursor: default;` : ``}
    ${views.tablet} {
      width: 100%;
      justify-content: center;
      height: 48px;
    }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #121D2A;
  cursor: pointer;

  .ant-checkbox-input, .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    border: 1px solid ${colors.darkGray1};
    :focus{
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color:${colors.darkGray1};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
  }
  .ant-checkbox-checked{
    .ant-checkbox-inner {
        background-color:${colors.darkGray1};
        border-color:${colors.darkGray1};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
        border-radius: 4px;
        ::after{
          transform: rotate(45deg) scale(1) translate(-56%, -58%);
        }
    }
    ::after {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  .ant-checkbox-disabled {
    .ant-checkbox-inner {
        background: #D5D8DC;
        box-shadow: none;
    }
  }
  :hover{
    .ant-checkbox-inner {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
  &.ant-checkbox-wrapper-checked:hover{
    .ant-checkbox-inner {
      border-color:${colors.darkGray1};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
    }
  }
`