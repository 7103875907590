import styled from '@emotion/styled/macro';
import { colors } from '../../styles/theme.styles';

export const LinkedLiabilitiesHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 11px 24px;
    box-shadow: inset 0px -1px 0px #DDE3EB;
`;

export const LinkedLiabilitiesHeaderTextWrapper = styled.div`
`;

export const LinkedLiabilitiesHeaderTitle = styled.div`
    margin-bottom: 2px;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: ${colors.darkGray1};
`;

export const LinkedLiabilitiesHeaderSubTitle = styled.div`
    color: ${colors.darkGray3};
    font-family: Circular;
    font-size: 14px;
    font-weight: 500;
`;

export const LinkedLiabilitiesBalanceTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray3};
`;

export const LinkedLiabilitiesCloseBtnWrapper = styled.div`
    height: 16px;
    width: 16px;
    cursor: pointer;
`;

export const LinkedLiabilitiesModalFooterWrapper = styled.div`
    display: flex;
    padding:8px 0 12px 0;
   
`

export const LinkedLiabilitiesModalWrapper = styled.div`
    padding-top: 4px;
`;

export const LinkedLiabilitiesAssetWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px 8px 20px;
    :hover{
        background: #F7F8F9;
        box-shadow: 2px 0 0 0 black inset;
    }
`;

export const LiabilityLeftSideWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const LiabilityIconWrapper = styled.div`
    height:24px;
    width:24px;
    min-width:24px;
    min-height:24px;
    border-radius: 50%;
    background-color:${props => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:8px;
    svg{
        width:16px;
        height:16px;
    }
    path{
        fill:#fff;
    }        
`;

export const ItemLogoWrapper = styled.div`
    height:24px;
    width:24px;
    min-width:24px;
    border-radius: 40px;
    /* margin-right:12px; */
    img{
        height:24px;
        width:24px;
    }
    margin-top:4px;
`;

export const AssetTextWrapper = styled.div`
`;

export const AssetTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray1};
    margin-bottom: 1px;
`;

export const AssetClassSubtitle = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${colors.darkGray3};
`;

export const LiabilityRightSideWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const LiabilityValueWrapper = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray1};
`

export const ArrowIconWRapper = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12px 0 4px;
    cursor: pointer;
    svg{
        transform: rotate(-90deg);
        path{
            stroke: black;
        }
    }  
`;

export const DeleteLiabilityIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.gray3};
    border-radius: 50%;
    height: 18px;
    width: 18px;
    cursor: pointer;
    svg{
        height: 10px;
        width: 10px;
        path{
            stroke: ${colors.darkGray2} ;

        }
    }

`

export const ButtonIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    border-radius: 24px;
    height: 28px;
    width: 28px;

    svg{
        path{
            stroke: black;
        }
    }
`;

export const LinkLiabilityButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    gap: 8px;
    background: #FFFFFF;
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: ${colors.darkGray1};
    cursor: pointer;

    &:hover ${ButtonIconWrapper}{
        svg{
            path{
            stroke: ${colors.darkGray2};
            }
        }
    }

    &:active ${ButtonIconWrapper}{
        svg{
            path{
            stroke: ${colors.darkGray2};
            opacity: 0.8;
            }
        }
    }

    :hover{
        color: ${colors.darkGray2};
    };

    :active{
        color: ${colors.darkGray2};
        opacity: 0.8;
    }
`;

export const SelectedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding: 0 12px;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  border-radius: 34px;
  border: 1px solid ${colors.gray3};

  :hover {
    border: 1px solid ${colors.darkGray1};
  }
`;