import { classIconsBase64 } from "../../../../../assets/icons/asset-class/classIconsBase64";
import { percentageDisplay } from "../../../../../common/utils/number-display.utils";
import './communityChartTooltip.css';

export const communityChartTooltip = (getTooltipItem) => ({
  enabled: false,
  // mode: 'index',
  // intersect: false,
  external: function (tooltipModel) {
    var tooltipEl = document.getElementById('community-chart-tooltip');
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'community-chart-tooltip';
      tooltipEl.className = 'community-tooltip-wrapper'

      const tooltipElLiteral = `
            <div class="community-tooltip-content-wrapper">
                <div class="community-tooltip-top"></div>
                <div class="community-arrow-div"></div>
            </div>
            `;

      tooltipEl.innerHTML = tooltipElLiteral;
      document.body.appendChild(tooltipEl);
    }
    let shouldShowToolTip = false;
    if (tooltipModel.tooltip.dataPoints && tooltipModel.tooltip.dataPoints.length) {
      const currentDataPoint = tooltipModel.tooltip.dataPoints[0];
      const item = getTooltipItem(currentDataPoint.datasetIndex, currentDataPoint.dataIndex);
      // console.log(item);
      if (item.show) {
        const topValue = `<div class="community-class-wrapper">
                            <div class="community-class-icon">
                              <img src="data:image/svg+xml;base64,${classIconsBase64[item.classIcon]}"/>
                            </div>
                            <div class="community-class-title">${item.title}</div>
                          </div>
                          <span class="community-tooltip-value">${percentageDisplay(item.percentage)}</span>
                          <span class="community-tooltip-value-subtitle">${item.description}</span>
                          `;
        var tooltipTop = tooltipEl.querySelector('.community-tooltip-top');
        tooltipTop.innerHTML = topValue;
        var tooltipClassWrapper = tooltipEl.querySelector('.community-class-wrapper');
        tooltipClassWrapper.style.backgroundColor = item.classColor;
        shouldShowToolTip = true;
      } else {
        shouldShowToolTip = false;
      }
    }
    if (tooltipModel.tooltip.opacity === 0 || !shouldShowToolTip) {
      tooltipEl.style.opacity = 0;
      tooltipEl.style.visibility = 'hidden';
      return;
    }
    var position = tooltipModel.chart.canvas.getBoundingClientRect();

    tooltipEl.style.opacity = 1;
    tooltipEl.style.visibility = 'visible';
    tooltipEl.style.position = 'absolute';

    const tooltipPositions = tooltipEl.getBoundingClientRect();

    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.tooltip._eventPosition.y - 10 - tooltipPositions.height + 'px'
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.tooltip._eventPosition.x - tooltipPositions.width / 2 + 'px'
    // tooltipEl.style.pointerEvents = 'none';
  }
})