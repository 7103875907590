import { Checkbox, Input } from 'antd';
import { Observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalCloseIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { navigateToUrl } from '../../../../common/utils/navigation.utils';
import { useStore } from '../../../app/data/root.store';
import { ActionButton, CancelSurveyModalFooter, CheckboxWrapper, ModalAltText, ModalCloseButtonWrapper, ModalContent, ModalHeader } from './CancelSurveyModal.styles';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { ROUTES } from '../../../../common/constants/routes.const';

export const CancelSurveyModal = (props) => {
  const { onBackDropClick } = props;
  const RemarksTextRef = useRef();
  const [reasonsToLeave, setReasonsToLeave] = useState([
    ...[
      { key: 'dont_understand_value', checked: false, label: 'I don’t understand the value' },
      { key: 'too_expensive', checked: false, label: 'It’s too expensive' },
      { key: 'need_more_features', checked: false, label: 'I need more features' },
      { key: 'cant_sync_bank', checked: false, label: 'I can’t sync my bank' },
      { key: 'platform_too_structured', checked: false, label: 'The platform is too structured for my needs' },
      { key: 'hard_to_set_up', checked: false, label: 'It was too hard to set up' },
      { key: 'hard_to_use', checked: false, label: 'I found it hard to use' },
      { key: 'too_much_effort_to_maintain', checked: false, label: 'Takes too much effort to maintain the data' },
    ].sort((a, b) => (Math.random() - 0.5)),
    { key: 'prefer_not_answering', checked: false, label: 'I prefer not answering' },
    { key: 'other', checked: false, label: 'Other' },
  ]);
  const { TextArea } = Input;
  const [remarks, setRemarks] = useState('');
  const history = useHistory();
  const { billingStore, userStore, uiStore } = useStore();
  const { mixpanelTrack } = useAnalytics()

  useEffect(() => {
    mixpanelTrack('Popup Viewed', {
      destination: 'stripe',
      location: 'Billing Page',
      title: `Before you go, please help us improve Vyzer`,
      cta_text: 'Cancel membership',
      type: 'Cancel Plan Survey Modal',
      is_demo: userStore.isDemoMode
    })

    // return () => {}
  }, [mixpanelTrack, userStore.isDemoMode])

  const handleCheckboxOnClick = (reasonIndex) => {
    const updatedReasonsToLeave = reasonsToLeave.map((reason, index) => index === reasonIndex ? { ...reason, checked: !reason.checked } : reason);
    setReasonsToLeave(updatedReasonsToLeave);
  }

  const handleGoToSetting = async () => {
    if (!reasonsToLeave.some(r => r.checked) || (reasonsToLeave[reasonsToLeave.length - 1].checked && !remarks.length)) return;
    const answer = {
      comments: remarks
    }
    reasonsToLeave.forEach(({ key, checked }) => {
      answer[key] = checked;
    });
    userStore.sendCancelSurveyAnswer(answer);
    const { url } = await billingStore.fetchBillingUrl();
    mixpanelTrack('Popup Clicked', {
      click_type: 'CTA click',
      destination: 'stripe',
      location: 'Billing Page',
      title: `Before you go, please help us improve Vyzer`,
      cta_text: 'Cancel membership',
      type: 'Cancel Plan Survey Modal',
      is_demo: userStore.isDemoMode
    })
    window.location.href = url;
  }

  const handleBackToPortfolioOnClick = () => {
    mixpanelTrack('Popup Clicked', {
      click_type: 'CTA click',
      destination: ROUTES.wealthOverview.url,
      location: 'Billing Page',
      title: `Before you go, please help us improve Vyzer`,
      cta_text: 'Back to my portfolio',
      type: 'Cancel Plan Survey Modal',
      is_demo: userStore.isDemoMode
    })
    onBackDropClick && onBackDropClick();
    navigateToUrl(history, ROUTES.wealthOverview.url);
  }

  const handleTextareaOnClick = () => {
    if (RemarksTextRef.current) {
      RemarksTextRef.current.focus();
    }
    const reasonIndex = reasonsToLeave.length - 1;
    if (reasonsToLeave[reasonIndex].checked) {
      setRemarks('');
    }
    handleCheckboxOnClick(reasonsToLeave.length - 1);
  }

  const handleTextareaOnChange = (e) => {
    const updatedRemarks = e.target.value;
    if (updatedRemarks === '') {
      handleCheckboxOnClick(reasonsToLeave.length - 1);
    } else {
      const updatedReasonsToLeave = reasonsToLeave.map((reason, index) => index === reasonsToLeave.length - 1 ? { ...reason, checked: true } : reason);
      setReasonsToLeave(updatedReasonsToLeave);
    }
    setRemarks(updatedRemarks);
  }

  return (<Observer>{() => (
    <BaseModal width={'500px'} height={'auto'} borderRadius={8} onBackDropClick={onBackDropClick}>

      <ModalHeader>
        <ModalCloseButtonWrapper onClick={onBackDropClick}>
          <ModalCloseIcon />
        </ModalCloseButtonWrapper>
        Before you go, please help us improve Vyzer

        {uiStore.isDesktopView ? <ModalAltText>
          We aim to make Vyzer the best product for investors.<br />
          Any feedback will help us improve our products and services.
        </ModalAltText> : null}
      </ModalHeader>
      <ModalContent>

        {!uiStore.isDesktopView ? <ModalAltText>
          We aim to make Vyzer the best product for investors.<br />
          Any feedback will help us improve our products and services.
        </ModalAltText> : null}

        {reasonsToLeave.slice(0, reasonsToLeave.length - 1).map((reason, index) => (
          <CheckboxWrapper key={index} onClick={() => { handleCheckboxOnClick(index) }}>
            <Checkbox checked={reason.checked}></Checkbox>
            {reason.label}
          </CheckboxWrapper>
        ))}

        <CheckboxWrapper onClick={handleTextareaOnClick}>
          <Checkbox checked={remarks.length > 0 || reasonsToLeave[reasonsToLeave.length - 1].checked}></Checkbox>
          Other
        </CheckboxWrapper>
        <TextArea autoSize={{minRows: 4}} ref={RemarksTextRef} value={remarks} onChange={handleTextareaOnChange} placeholder='Add your comment here'></TextArea>
      </ModalContent>

      <CancelSurveyModalFooter>
        <ActionButton isBack onClick={handleBackToPortfolioOnClick}>Back to my portfolio</ActionButton>
        <ActionButton onClick={handleGoToSetting} disabled={!reasonsToLeave.some(r => r.checked) || (reasonsToLeave[reasonsToLeave.length - 1].checked && !remarks.length)} backgroundColor={'#BA4460'} whiteText>Cancel membership</ActionButton>
      </CancelSurveyModalFooter>

    </BaseModal>
  )}</Observer>)
}
