import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
// import { AddSimulationButton } from '../CashFlowPage.styles';
import { CURRENCY_SYMBOL } from '../../../../common/constants/currency-symbol.const';
import NumberFormat from 'react-number-format';
import { Drawer, Select, Input, DatePicker } from "antd";
import { ArrowRightOutlined } from '@ant-design/icons';
import { DrawerTitle } from '../../../../common/components/drawers/DrawerTitle/DrawerTitle';
import { FormWrapper, IncomeExpenseButtonsWrapper, IncomeExpenseButton, SimulationRowWrapper, ActionsWrapper, AddSaveButton, OptionalText, ValidationError } from './NewSimulationDrawer.styles';
import { ReactComponent as IncomeButtonIcon } from '../../../../assets/icons/simulations/income.svg'
import { ReactComponent as ExpenseButtonIcon } from '../../../../assets/icons/simulations/expense.svg'
import { FieldWrapper, FieldLabel, InputWrapper, FieldLabelAndErrorWrapper } from '../../../../common/components/form-displayer/FormDisplayer.styles';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';



const frequencyOptions = ['Monthly', 'Quarterly', 'Annually', 'One time']

export function NewSimulationDrawer(props) {
    const { Option } = Select;
    const [simulation, setSimulation] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [savedOnce, setSavedOnce] = useState(false);
    const { cashFlowStore, userStore } = useStore();
    const { isIncognito } = props;

    const handleFiledChange = (fieldName, value) => {
        setSimulation({
            ...simulation,
            [fieldName]: value
        });
        setValidationErrors({ ...validationErrors, [fieldName]: !value });
    }

    const changeIncomeExpense = (isIncome) => {
        if (userStore.isActionBlockedByRole('editScenario')) {
            userStore.setShowLimitedSharedAccess(true);
        } else {
            setSimulation({
                ...simulation,
                inEx: isIncome ? 'income' : 'expense'
            });
        }
    }
    useEffect(() => {
        // console.log('hello', props.editedSimulation)
        if (props.editedSimulation.id) {
            const inEx = props.editedSimulation.amount > 0 ? 'income' : 'expense';
            const amount = Math.abs(props.editedSimulation.amount);
            setSimulation({ ...props.editedSimulation, inEx, amount });
            // setIs
        } else {
            setSimulation({ currency: 'USD', inEx: 'income', amount: '', frequency: 'One time' })
        }
        return () => {
            setValidationErrors({})
            setSavedOnce(false);
        }
    }, [props.editedSimulation]);

    const saveSimulation = () => {
        if (userStore.isActionBlockedByRole('editScenario')) {
            userStore.setShowLimitedSharedAccess(true);
        } else {
            setSavedOnce(true);
            const errors = {};
            if (!simulation.description) {
                errors.description = true;
            }
            if (!simulation.amount) {
                errors.amount = true;
            }
            if (!simulation.frequency) {
                errors.frequency = true;
            }
            if (!simulation.startDate) {
                errors.startDate = true;
            }
            if (!simulation.endDate && simulation.frequency !== 'One time') {
                errors.endDate = true;
            }
            if (Object.keys(errors).length) {
                setValidationErrors(errors);
                return;
            }
            if (!simulation.cashAccountId) {
                simulation.cashAccountId = 0;
            }
            if (simulation.frequency === 'One time') {
                simulation.endDate = null;
            }
            cashFlowStore.saveSimulation(simulation, () => {
                cashFlowStore.setShowScenariosPanel(false)
            })
        }
    }

    function disabledLessThanToday(current) {
        return current && current < moment().endOf('day');
    }

    function disabledLessThenStartDate(current) {
        return current && current < (simulation?.startDate ? moment(simulation.startDate) : moment()).endOf('day')
    }

    return (<Observer>{() => (
        <Drawer
            closable={false}
            visible={cashFlowStore.showScenariosPanel}
            className={'addSimulationDrawerContainer'}
            width={384}
            zIndex={1001}
            onClose={() => { cashFlowStore.setShowScenariosPanel(false) }}
        >
            <DrawerTitle
                text={`${simulation.id ? 'Edit' : 'Add a'} scenario`}
                onClose={() => { cashFlowStore.setShowScenariosPanel(false) }} />
            <FormWrapper>
                <SimulationRowWrapper>
                    <FieldWrapper>
                        <FieldLabelAndErrorWrapper>
                            <FieldLabel>Name</FieldLabel>
                            {validationErrors.description && savedOnce && (
                                <ValidationError>Required</ValidationError>
                            )}
                        </FieldLabelAndErrorWrapper>
                        <InputWrapper withError={validationErrors.description && savedOnce}>
                            <Input
                                value={simulation.description}
                                disabled={userStore.isActionBlockedByRole('editScenario')}
                                onChange={(e) => { handleFiledChange('description', e.target.value) }}
                            />
                        </InputWrapper>
                    </FieldWrapper>
                </SimulationRowWrapper>


                <SimulationRowWrapper>
                    <FieldWrapper>
                        <FieldLabelAndErrorWrapper>
                            <FieldLabel>Amount</FieldLabel>
                            {validationErrors.amount && savedOnce && (
                                <ValidationError>Required</ValidationError>
                            )}
                        </FieldLabelAndErrorWrapper>
                        <InputWrapper withError={validationErrors.amount && savedOnce}>
                            <NumberFormat
                                thousandSeparator={true}
                                customInput={Input}
                                autoComplete="off"
                                value={simulation.amount} min="0"
                                disabled={userStore.isActionBlockedByRole('editScenario')}
                                style={isIncognito ? { WebkitTextSecurity: 'disc' } : {}}
                                onValueChange={(values) => { handleFiledChange('amount', values.floatValue) }} />
                        </InputWrapper>
                    </FieldWrapper>
                    <FieldWrapper size={0.4}>
                        <FieldLabel></FieldLabel>
                        <InputWrapper>
                            <Select name='currency' value={simulation.currency} showSearch onChange={(val) => { handleFiledChange('currency', val || '') }} disabled={userStore.isActionBlockedByRole('editScenario')} >
                                {Object.entries(CURRENCY_SYMBOL).map(([value, symbol]) => (
                                    <Option key={value} value={value} >{value}</Option>
                                ))}
                            </Select>
                        </InputWrapper>
                    </FieldWrapper>
                </SimulationRowWrapper>
                {/* <InputWrapper>
                        <InputLabel>Name</InputLabel>
                        <Input name='name' value={simulation.description} 
                            onChange={(e)=>{handleFiledChange('description',e.target.value)}} />
                    </InputWrapper>
                    <InputWrapper>
                        <InputLabel>Amount</InputLabel>
                        <InputsWrapper>
                            <Input type='number' name='amount' value={simulation.amount} min="0"
                                style={isIncognito ? {WebkitTextSecurity:'disc'}:{}}
                                onChange={(e)=>{handleFiledChange('amount',e.target.value)}}/> 
                            
                        </InputsWrapper>
                    </InputWrapper> */}
                <InputWrapper>
                    <FieldLabel>What is it?</FieldLabel>
                    <IncomeExpenseButtonsWrapper>
                        <IncomeExpenseButton isSelected={simulation.inEx === 'income'} disabled={userStore.isActionBlockedByRole('editScenario')} onClick={() => { changeIncomeExpense(true) }}>
                            <IncomeButtonIcon />
                            <span className='button-text'>Income</span>
                        </IncomeExpenseButton>
                        <IncomeExpenseButton isSelected={simulation.inEx === 'expense'} disabled={userStore.isActionBlockedByRole('editScenario')} onClick={() => { changeIncomeExpense(false) }}>
                            <ExpenseButtonIcon />
                            <span className='button-text'>Expense</span>
                        </IncomeExpenseButton>
                    </IncomeExpenseButtonsWrapper>
                </InputWrapper>


                <SimulationRowWrapper>
                    <FieldWrapper>
                        <FieldLabel>Cash account <OptionalText>(Optional)</OptionalText></FieldLabel>
                        <InputWrapper>
                            <Select name='cashAccountId' value={simulation.cashAccountId} allowClear onChange={(val) => { handleFiledChange('cashAccountId', val || '') }} disabled={userStore.isActionBlockedByRole('editScenario')} >
                                {/* <Option value={null} >None</Option> */}
                                {cashFlowStore.data.cashAccounts?.map(ca => (
                                    <Option key={ca.id} value={ca.id} >{ca.title}</Option>
                                ))}
                            </Select>
                        </InputWrapper>
                    </FieldWrapper>
                </SimulationRowWrapper>


                <SimulationRowWrapper>
                    <FieldWrapper>
                        <FieldLabelAndErrorWrapper>
                            <FieldLabel>Frequency</FieldLabel>
                            {validationErrors.frequency && savedOnce && (
                                <ValidationError>Required</ValidationError>
                            )}
                        </FieldLabelAndErrorWrapper>
                        <InputWrapper withError={validationErrors.frequency && savedOnce}>
                            <Select name="frequency" value={simulation.frequency} showSearch allowClear onChange={(val) => { handleFiledChange('frequency', val || '') }} disabled={userStore.isActionBlockedByRole('editScenario')} >
                                {frequencyOptions.map(fo => (
                                    <Option key={fo} value={fo} >{fo}</Option>
                                ))}
                            </Select>
                        </InputWrapper>
                    </FieldWrapper>
                </SimulationRowWrapper>


                <SimulationRowWrapper>
                    <FieldWrapper>
                        <FieldLabelAndErrorWrapper>
                            <FieldLabel>Time frame</FieldLabel>
                            {validationErrors.startDate && savedOnce && (
                                <ValidationError>Required</ValidationError>
                            )}
                        </FieldLabelAndErrorWrapper>
                        <InputWrapper withError={validationErrors.startDate && savedOnce}>
                            <DatePicker picker="month" inputReadOnly disabled={userStore.isActionBlockedByRole('editScenario')} format={'MM-YYYY'} disabledDate={disabledLessThanToday} placeholder="Start date" style={{ flex: 1 }} value={simulation?.startDate ? moment(simulation?.startDate) : ''} name='startDate' onChange={(val) => { handleFiledChange('startDate', (val ? val.format() : null)) }} />
                        </InputWrapper>
                    </FieldWrapper>
                    {simulation.frequency !== 'One time' ? <><FieldWrapper size={0.1}>
                        <ArrowRightOutlined style={{ padding: '0 0 12px' }} />
                    </FieldWrapper>
                    <FieldWrapper >
                        <FieldLabelAndErrorWrapper>
                            <FieldLabel></FieldLabel>
                            {validationErrors.endDate && savedOnce && (
                                <ValidationError>Required</ValidationError>
                            )}
                        </FieldLabelAndErrorWrapper>
                        <InputWrapper withError={validationErrors.endDate && savedOnce}>
                            <DatePicker picker="month" inputReadOnly disabled={userStore.isActionBlockedByRole('editScenario')} format={'MM-YYYY'} disabledDate={disabledLessThenStartDate} placeholder="End date" style={{ flex: 1 }} value={simulation?.endDate ? moment(simulation?.endDate) : ''} name='endDate' onChange={(val) => { handleFiledChange('endDate', (val ? val.format() : null)) }} />
                        </InputWrapper>
                    </FieldWrapper></> : <div style={{flex:1}}></div>}
                </SimulationRowWrapper>

                {/* <InputWrapper>
                        <InputLabel>Time frame</InputLabel>
                        <InputsWrapper>
                            <DatePicker picker="month" disabledDate={disabledLessThanToday} placeholder="Start date" style={{flex:1}} value={ simulation?.startDate ? moment(simulation?.startDate) : ''} name='startDate' onChange={(val)=>{handleFiledChange('startDate', (val ? val.format(): null))}}/>
                            <ArrowRightOutlined style={{padding:'0 16px'}}/>
                            <DatePicker picker="month" disabledDate={disabledLessThenStartDate} placeholder="End date" style={{flex:1}} value={ simulation?.endDate ? moment(simulation?.endDate) : ''} name='endDate' onChange={(val)=>{handleFiledChange('endDate', (val ? val.format(): null))}}/>
                        </InputsWrapper>
                    </InputWrapper> */}

            </FormWrapper>
            <ActionsWrapper>
                <AddSaveButton onClick={saveSimulation}>
                    {simulation.id ? 'Save' : 'Add'} {userStore.isActionBlockedByRole('editScenario') && <ActionLockedIconWithTooltip iconColor={'#DDE3EB'} isRoleBlocked={true} />}
                </AddSaveButton>
            </ActionsWrapper>
            {/* <AddSimulationButton onClick={saveSimulation}>{simulation.id ? 'Save' : 'Add'}</AddSimulationButton> */}
        </Drawer>
    )}</Observer>)
}