import styled from '@emotion/styled/macro';
import { CloseOutlined } from '@ant-design/icons';

const TitleWrapper = styled.div`
    width:100%;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    // padding:24px;
    flex:1;
`  

const TitleText = styled.div`
    color:#343739;
    font-size:24px;
    padding-right:24px;
    line-height:28px;
`;

const CloseIcon = styled(CloseOutlined)`
    color:#75818D;
    font-size:18px;
` 
export function DrawerTitle(props) {
    return (
        <TitleWrapper>
            <TitleText>{props.text}</TitleText>
            <CloseIcon onClick={()=>{props.onClose()}} />
        </TitleWrapper>
    )
}