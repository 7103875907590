import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { VyzerTriangleLogo } from '../../../../assets/icons/common/common-icons';
import { ModalBackDrop, ModalWrapper } from '../../../../common/components/BaseModal/BaseModal.styles';
import { useStore } from '../../../app/data/root.store';
import { LoginButtonText, LoginButtonWrapper, SessionExpirationModalTitleAlt, SessionExpirationModalTitleMain, SessionExpirationModalTitleWrapper, SessionExpirationModalWrapper } from './SessionExpirationModal.styles';
import { datadogRum } from '@datadog/browser-rum';

export const SessionExpirationModal = (props) => {
  const { filtersStore } = useStore();

  useEffect(() => {
    if (filtersStore.countActiveFilters) {
      filtersStore.handleClearFilterSelections();
    }
    // return () => {}
  }, [filtersStore])

  const handleLogInClick = () => {
    datadogRum.clearUser();
    window.location.reload();
  }

  return (<Observer>{() => (
    <ModalBackDrop backgroundColor={'rgba(26, 27, 29, 0.5)'} style={{ backdropFilter: 'blur(10px)', zIndex:99999999999 }}>
      <ModalWrapper style={{ borderRadius: '8px' }} width={'auto'} height={'auto'} onClick={(e) => { e.stopPropagation() }}>
        <SessionExpirationModalWrapper>
          <SessionExpirationModalTitleWrapper>
            <VyzerTriangleLogo />
            <SessionExpirationModalTitleMain>Your session expired.</SessionExpirationModalTitleMain>
            <SessionExpirationModalTitleAlt>You have been logged out. Please login again</SessionExpirationModalTitleAlt>
          </SessionExpirationModalTitleWrapper>
          <LoginButtonWrapper onClick={handleLogInClick}><LoginButtonText>Log in Vyzer</LoginButtonText></LoginButtonWrapper>
        </SessionExpirationModalWrapper>
      </ModalWrapper>
    </ModalBackDrop>
  )}</Observer>)
}
