const validateKey = (key) => {
  if (!key) throw Error('No key provided');
  if (!Object.keys(window.localStorage).includes(key)) console.warn(`Warning: Local storage key '${key}' does not exist`);
}

export const ls = {
  get(key) {
    try {
      validateKey(key);
      return window.localStorage.getItem(key);
    } catch(e) {
      console.error(e)
    }
  },
  set(key, value) {
    try {
      validateKey(key);
      return window.localStorage.setItem(key, value);
    } catch(e) {
      console.error(e)
    }
  },
  clear(key) {
    try {
      validateKey(key);
      return window.localStorage.setItem(key, '')
    } catch(e) {
      console.error(e)
    }
  },
  remove(key) {
    try {
      validateKey(key);
      return window.localStorage.removeItem(key)
    } catch(e) {
      console.error(e)
    }
  }
}