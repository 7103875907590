
import { Observer } from 'mobx-react';
import { NavLink } from "react-router-dom";
import { ActionLockedIconWithTooltip } from "../../../../common/components/ActionLocked/ActionLockedIconWithTooltip";
import { useStore } from "../../../app/data/root.store";
import { CommunityIcon } from './icons/sidebar-icons';
import { ROUTES } from '../../../../common/constants/routes.const';

export const CommunityMenu = (props) => {
  const { handleCommunityClick } = props;
  const { userStore, uiStore } = useStore();

  return <Observer>{() => (
    <ul>
      <li>
        <NavLink to={ROUTES.community.url} onClick={handleCommunityClick} className={'small-text'}><CommunityIcon />{ROUTES.community.title}
          {userStore.isActionBlockedByRole('viewCommunityPageFromSidebar') && !uiStore.isSideMenuMinimized && <ActionLockedIconWithTooltip iconColor={'#ACB1BB'} marginTop={'-2px'} isRoleBlocked={true} />}
        </NavLink>
      </li>
    </ul>
  )}</Observer>
}