import React from 'react'
import { Observer } from 'mobx-react';
import { TabWrapper } from '../../Info/AssetInfo.styles';
import { TickersList } from '../TickersList';
import { CenterContentWrapper, LogoLoaderWrapper, SyncIconWrapper, SyncingTransactionsHistoryTextWrapper, TickersTabEmptyStateMessage, TickersTabEmptyStateMessageTitle, TickersTabEmptyStateWrapper } from './AssetsTickersTab.styles';
import { ReactComponent as OutOfSyncIcon } from '../icons/out_of_sync_icon.svg'
import { ActionButton } from '../../../../../../../common/components/buttons/Buttons.styles';
import { PlaidLink } from '../../../../../../connected-institutions/components/plaid-link/PlaidLink';
import { useStore } from '../../../../../../app/data/root.store';
import { LogoLoader } from '../../../../../../../common/components/LogoLoader/LogoLoader';
import { TickersListEmptyState } from '../TickersListEmptyState';
import { ReactComponent as NoTransactionsSkeletonImage } from '../../Transactions/assets/transactions-skeleton.svg';

export const AssetsTickersTab = (props) => {
  const { item, tickers } = props;
  const { itemStore, tickersStore } = useStore();

  const handleInstReconnect = () => {
    window.location.reload();
  }

  return (
    <Observer>{() => (
      <TabWrapper>
        <CenterContentWrapper>
          {/* <CenterContent style={{ paddingTop: '16px' }}> */}
            {tickersStore.isLoadingTickersData ?
              <LogoLoaderWrapper>
                <LogoLoader wbg />
              </LogoLoaderWrapper>
              :
              item?.isLoginRequired ?
                <TickersTabEmptyStateWrapper>
                  <OutOfSyncIcon />
                  <TickersTabEmptyStateMessageTitle>Asset is out of sync</TickersTabEmptyStateMessageTitle>
                  <TickersTabEmptyStateMessage>Vyzer lost the connection for this asset.
                    Login again to retrieve updated data.</TickersTabEmptyStateMessage>
                  <PlaidLink reconnectId={itemStore.item.connectedInstitutionId} onSuccess={() => handleInstReconnect()}>
                    <ActionButton style={{ padding: '8px 16px', fontWeight: '500', marginTop: '8px' }}>Login</ActionButton>
                  </PlaidLink>
                </TickersTabEmptyStateWrapper>
                :
                item?.isConnected && item.lastSync === null ?
                <TickersTabEmptyStateWrapper style={{paddingBottom:'56px'}}>
                  <NoTransactionsSkeletonImage style={{ width: '100%', height: '158px' }} />
                  <SyncIconWrapper><SyncingHoldingsIcon /></SyncIconWrapper>
                  <SyncingTransactionsHistoryTextWrapper isBolded>Importing holdings information...</SyncingTransactionsHistoryTextWrapper>
                  <SyncingTransactionsHistoryTextWrapper>This process can take some time</SyncingTransactionsHistoryTextWrapper>

                </TickersTabEmptyStateWrapper> 
                :
                !tickers?.length ?
                  <TickersListEmptyState />
                  :
                  <TickersList tickers={tickers} closedHoldingsInDisplayCount={tickersStore.closedHoldingsInDisplayCount} />}
          {/* </CenterContent> */}
        </CenterContentWrapper>
      </TabWrapper>
    )}</Observer>)
}

const SyncingHoldingsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3 5C16.6679 5.50945 19.25 8.43182 19.25 11.9607C19.25 14.4663 17.9482 16.6662 15.9876 17.9137" stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
      <path d="M14.8658 16.0256L14.8658 19.1366L17.9769 19.1365" stroke="black" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
      <path d="M10.7 19C7.33213 18.4905 4.75001 15.5682 4.75001 12.0393C4.75001 9.53367 6.05185 7.33377 8.01245 6.08633" stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
      <path d="M9.13422 7.97444L9.13425 4.86341L6.02314 4.86353" stroke="black" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
    </svg>
  )
}