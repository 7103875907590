const cashTypes = ["CD", "CHARGE", "CHECKING", "MONEYMARKET", "SAVINGS"]
const loanTypes = ["AUTOLOAN", "COMMERCIALLOAN", "HOMEEQUITYLOAN", "INSTALLMENT", "LOAN", "MILITARYLOAN", "MORTGAGE", "PERSONALLOAN", "SMBLOAN", "STUDENTLOAN",];
const creditTypes = ['CREDITCARD', "Fidelity Credit Card"];

export const akoyaAccountTypes = {
  isCashAccount: (accountType) => cashTypes.includes(accountType),
  isCreditCardAccount: (accountType) =>
    creditTypes.includes(accountType),
  isLoanAccount: (accountType) =>
    loanTypes.includes(accountType),
  isCreditOrLoanAccounts: (accountType) =>
    akoyaAccountTypes.isCreditCardAccount(accountType) ||
    akoyaAccountTypes.isLoanAccount(accountType),
};

export const isCashAccount = (account) => akoyaAccountTypes.isCashAccount(account.accountType);
export const isCreditCard = (account) => akoyaAccountTypes.isCreditCardAccount(account.accountType);
export const isLiability = (account) => akoyaAccountTypes.isCreditOrLoanAccounts(account.accountType);