import React from 'react';
import { Observer } from 'mobx-react';
import { BannerWrapper, BannerContentWrapper } from './Banner.styles'

export function Banner(props) {
    const { bannerBgc, titleComponent, buttonComponent, bannerTxtColor } = props

    return <Observer>{() => (
        <BannerWrapper bannerBgc={bannerBgc} bannerTxtColor={bannerTxtColor} > 
            <BannerContentWrapper>

                {titleComponent ? titleComponent : <></>}

                {buttonComponent ? buttonComponent : <></>}
            </BannerContentWrapper>
        </BannerWrapper>
    )}
    </Observer>
}