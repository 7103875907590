import moment from 'moment';

export const addRow = (currentValue, startDate, newRowValue) => {

    let updatedSchedule = [...currentValue]; 
    const mostRecentDate = currentValue.length > 0 ? currentValue[currentValue.length - 1].date : startDate ? moment(startDate).subtract(1,'y') :  moment().subtract(1,'y');
    // const recentDatePlusYear = 
    // console.log(value.map(u=>u.tempId));
    const isLastValueZero = currentValue.length > 0 && currentValue[currentValue.length - 1].value === 0; 

    const maxValueOfTempId = Math.max(...currentValue.map(u =>  u.tempId || 0), 0);
    const newRowTempId = maxValueOfTempId + 1;

    if (newRowValue === '' && currentValue.length > (isLastValueZero ? 1 : 0) ){
        newRowValue = currentValue[currentValue.length - (isLastValueZero ? 2 : 1)].value;
    }

    // last visible row is having end date.
    if (isLastValueZero){
        updatedSchedule[currentValue.length-1].date = moment(mostRecentDate).add(1,'y'); // .add(1,'M');
        const newRow = {
            value: newRowValue,
            date: moment(mostRecentDate).utc().set("D",15).startOf("D") || moment().utc().set("D",15).startOf("D"),
            remarks: "",
            tempId: newRowTempId
        }
        updatedSchedule = [...updatedSchedule, newRow].sort((a,b)=>new Date(a.date) - new Date(b.date));
    } else {
        const newRow = {
            value: newRowValue,
            date: moment(mostRecentDate).add(1,'y').utc().set("D",15).startOf("D") || moment().utc().set("D",15).startOf("D"),
            remarks: "",
            tempId: newRowTempId
        }
        updatedSchedule = [...updatedSchedule, newRow];
    }

    return  { updatedSchedule , newRowTempId }
   
}

export const changeStartDate = (currentValue,updatedIndex,newDate) => {
    // console.log(updatedIndex,newDate);
    const prevDate =  currentValue[updatedIndex].date;
    let updatedSchedule = currentValue.map((item,index)=>(index === updatedIndex ? {...item,date:newDate.format()} : item));
    const isNewDateBiggerThenLastDate = moment(prevDate).isBefore(newDate,"M");
    const isPrevRowZero = updatedIndex !== 0  ? currentValue[updatedIndex-1].value === 0 : false;
    const isNewDateSmallerOrEqualThenPrevRaw = updatedIndex > 0 ? moment(currentValue[updatedIndex-1].date).isSameOrAfter(newDate) : null;
    // const isSameOrAsLastDate = moment(prevDate).isBefore(newDate,"M");
    // console.log(isNewDateBiggerThenLastDate, isNewDateSmallerOrEqualThenPrevRaw)
    // console.log(updatedIndex > 1 ? moment(currentValue[updatedIndex-2].date) : null);
    
    if (isNewDateBiggerThenLastDate && !isPrevRowZero && updatedIndex !== 0){
        // console.log("ADD gap");
        // check if previous row is o
        
        const maxValueOfTempId = Math.max(...currentValue.map(u =>  u.tempId || 0), 0);

        const newRow = {
            value: 0,
            date: prevDate, // moment(prevDate).add(1,'y').set("D",15).startOf("D") || moment().set("D",15).startOf("D"),
            remarks: "",
            tempId: maxValueOfTempId+1
        }
        updatedSchedule = [...updatedSchedule, newRow].sort((a,b)=>new Date(a.date) - new Date(b.date));
        
    } else if (!isNewDateBiggerThenLastDate && isPrevRowZero && isNewDateSmallerOrEqualThenPrevRaw){
        updatedSchedule.splice(updatedIndex-1,1);
    }
    

    return updatedSchedule;
}

export const changeEndDate = (currentValue, updatedIndex, newDate) => {
    // let updatedSchedule = [...currentValue]; 
    let updatedSchedule = currentValue.map((item,index)=> ({...item}));
    if ( newDate ){
        // if is last row
        if (updatedIndex === currentValue.length - 1){
            // add gap to end
            // check if previous row is o
            
            const maxValueOfTempId = Math.max(...currentValue.map(u =>  u.tempId || 0), 0);
            const nextDateForGap = newDate.add(1,"M");

            const newRow = {
                value: 0,
                date: nextDateForGap.format(), // moment(prevDate).add(1,'y').set("D",15).startOf("D") || moment().set("D",15).startOf("D"),
                remarks: "",
                tempId: maxValueOfTempId+1
            }
            updatedSchedule = [...updatedSchedule, newRow]; //.sort((a,b)=>new Date(a.date) - new Date(b.date));
        } else {
            const isNextRowZero = updatedIndex+1 !== currentValue.length ? currentValue[updatedIndex+1].value === 0 : false;

            // const prevDate =  currentValue[updatedIndex].date;

            // const isBiggerThenLastDate = moment(prevDate).isBefore(newDate,"M");
            // const nextSecondRowDate= updatedIndex < value.length - (isNextRowZero ? 3 :2) ? value[index+(isNextRowZero ? 3 :2)].date : null } 

            const isDateBiggerThenNextNotZeroRaw = updatedIndex+2 < currentValue.length ? moment(currentValue[updatedIndex+2].date).isSameOrBefore(newDate) : null;
            
            if (isNextRowZero && isDateBiggerThenNextNotZeroRaw){
                updatedSchedule[updatedIndex+2].date = newDate.add(1,"M").format();
                updatedSchedule.splice(updatedIndex+1,1);
            } else {
                updatedSchedule[updatedIndex+1].date = newDate.add(1,"M").format();
            }
            
            
        }
    } else {
        // new date is null it's mean clear - which is possible only for the last row that is not zero ( which have another gap ( hidden row ) after )
        updatedSchedule.pop();
    }
    return updatedSchedule;
}

export const deleteRow = (currentValue, deletedIndex) => {
    let updatedSchedule = [...currentValue];
    // console.log(currentValue, deletedIndex)
    
    // is last row ( without gap )
    if (deletedIndex === 0 && currentValue.length === 1) {
        return [];
    } else {
        
        const isNextRowZero = deletedIndex+1 !== currentValue.length ? currentValue[deletedIndex+1].value === 0 : false;
        const isPrevRowZero = deletedIndex > 0 ? currentValue[deletedIndex-1].value === 0 : false;
        if (isNextRowZero){

            updatedSchedule = updatedSchedule.filter((item,index)=>index !== deletedIndex);
            if (deletedIndex === 0){
              updatedSchedule.shift();
            } else if (isPrevRowZero){
               updatedSchedule.splice(deletedIndex,1);
            } else {
               // - after filter - the next row is the deleted index
               updatedSchedule[deletedIndex].date = currentValue[deletedIndex].date;
            }
        } else if (!isPrevRowZero){
            if (deletedIndex !== 0){
                updatedSchedule[deletedIndex].value = 0;
            } else {
                updatedSchedule.splice(deletedIndex,1);    
            }
            
        } else {
            updatedSchedule.splice(deletedIndex,1);
        }


        // if (isPrevRowZero){
        //     updatedSchedule.splice(deletedIndex,1);
        // }
    }

    
    
    // const prevRawDate = deletedIndex > 0 ? currentValue[deletedIndex-1].date : null;
     
    // if (deletedIndex === 0){
        
    // } else {
    //     // const 
    // }
    return updatedSchedule;
    // setScheduleHistory(updatedSchedule);
    
  }

