import styled from "@emotion/styled/macro";
import { colors } from "../../../../styles/theme.styles";
import { flexCenter } from "../../../../styles/combos.styles";

// export const SelectWithDescriptionInOptionWrapper = styled.div`
//     width: ${props => props.width || '562px'};
//     border: 1px solid #DDE3EB;
//     box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
//     border-radius: 8px;
// `;

// export const SelectWithDescriptionInOptionItemWrapper = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 8px;
//     padding: 8px 8px 8px 12px;
//     min-height: 40px;
//     cursor: pointer;
//     background: ${props => props.selectedItem ? 'linear-gradient( 90deg, #000  2px, #F7F8F9 2px);' : '#fff'};
//     animation: all 0.2s;

//     :first-of-type {
//         border-top-left-radius: 8px;
//         border-top-right-radius: 8px;
//     }

//     :last-of-type {
//         border-bottom-left-radius: 8px;
//         border-bottom-right-radius: 8px;
//     }

//     :hover {
//         background: linear-gradient( 90deg, #000  2px, #F7F8F9 2px);
//     }
// `;

export const SelectWithDescriptionInOptionItemTitlesWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2px;
`;

export const SelectWithDescriptionInOptionItemTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray1};
`;

export const SelectWithDescriptionInOptionItemSubTitle = styled.div`
    font-weight: 450;
    font-size: 13px;
    line-height: 16px;
    color: ${colors.darkGray3};
`;

export const CheckMarkSvgWrapper = styled.div`
    ${flexCenter};
    width: 24px;
    margin-left: auto;
`;