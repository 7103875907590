import React from 'react'
import { RadioLabelWrapper, RadioOptionWrapper, RadioSubLabelWrapper, RadioWrapper } from './Radio.styles';

export const Radio = ({ value, onChange, options, disabled, customWrapperStyle }) => {

  const handleRadioOptionClick = (selectedValue) => {
    if (disabled || value === selectedValue){return};
    onChange(selectedValue);
  }

  return (
    <RadioWrapper style={{ ...(customWrapperStyle ? customWrapperStyle : {}) }}>
      {options.map((opt, index) => {
        const isOptionSelected = opt.value === value;
        return (
          <RadioOptionWrapper disabled={disabled} style={{ ...(opt?.customOptionStyle ? opt.customOptionStyle : {}) }} selected={isOptionSelected} key={index} onClick={() => { handleRadioOptionClick(opt.value) }}>
            {opt?.prefixIcon && !isOptionSelected ? opt.prefixIcon : null}
            {opt?.prefixSelectedIcon && isOptionSelected ? opt.prefixSelectedIcon : null}
            <RadioLabelWrapper>
              {opt.label}
              {opt.subLabel ? <RadioSubLabelWrapper>{opt.subLabel}</RadioSubLabelWrapper> : null}
            </RadioLabelWrapper>
          </RadioOptionWrapper>
        )
      })}
    </RadioWrapper>
  )
}
