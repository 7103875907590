import styled from "@emotion/styled/macro";
import { colors } from "../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../common/styles/combos.styles";

export const DeepAnalysisPendingResultTitle = styled.div`
  color: ${colors.darkGray1};
  text-align: center;
  font-family: Circular;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -2.4px;
`;

export const DeepAnalysisPendingResultSubtitle = styled(DeepAnalysisPendingResultTitle)`
  font-size: 14px;
  font-weight: 450;
  line-height: normal;
  letter-spacing: normal;
  padding: 8px 0 24px 0;
`;

export const ContinueDeepAnalysisRowWrapper = styled.div`
  display: flex;
  padding: 16px 20px 20px 6px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const GraySpacer = styled.div`
  height: 1px;
  width: 90%;
  background-color: ${colors.lightGray2};
`;

export const ContinueDeepAnalysisTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

export const ContinueDeepAnalysisTitle = styled.div`
  color: ${colors.darkGray};
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ContinueDeepAnalysisSubtitle = styled.div`
  color: ${colors.darkGray2};
  font-family: Circular;
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const ContinueDeepAnalysisResultFooter = styled.div`
  ${flexCenter};
`;