import React, { useMemo, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import { RecaptchaAbsoluteWrapper, RecaptchaWrapper } from './LoginPage.styles'

export const useRecaptchaHook = () => {
  const recaptchaRef = useRef();


  const createRecapcthaToken = async () => {
    const tokenData = await recaptchaRef.current.executeAsync();
    return tokenData;
  }

  const RecaptchaComponent = () => {
    return (
      <RecaptchaWrapper>
        <RecaptchaAbsoluteWrapper>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={recaptchaRef}
            size="invisible"
          // onChange={recaptchaOnChange}
          />
        </RecaptchaAbsoluteWrapper>
      </RecaptchaWrapper>
    )
  }

  const MemoizedRecaptchaComponent = useMemo(() => RecaptchaComponent
    , [])

  const onRecaptchaReset = () => {
    window.grecaptcha && window.grecaptcha.reset();
  }

  return [
    createRecapcthaToken, onRecaptchaReset, MemoizedRecaptchaComponent
  ]

}
