import styled from "@emotion/styled/macro";
import { views } from "../../../../../../../common/styles/theme.styles";

export const EmptyStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 14.5px 0 0;
`;

export const EmptyStateSquares = styled.div`
    display: flex;
    gap: 8px;
    padding-left: 10.5px;

    ${views.tablet} {
        flex-direction: column;
    }
`;

export const EmptyStateTitle = styled.div`
    color: var(--Class-Secondary-CA-Light, #41557D);
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    padding-left: 4px;

    ${views.tablet} {
        padding-left: 8px;
    }
`;

export const EmptyStateSquareWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    border-radius: 8px;
    border: 1px solid #FFF;
    background: var(--System-Background-Base-Primary, #FFF);

    /* Card */
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 8px 16px 0px rgba(0, 0, 0, 0.04);

    cursor: pointer;
    
    width: 150px;
    height: 129px;
    padding: 24px 12px 16px 12px;

    transition: all 0.2s ease-in-out;
    :hover{
        transform: scale(1.05);
        border:1px solid #778FBF;
    }
        
    ${views.tablet} {
        width: 100%;
        height: auto;
        padding: 8px 12px;
        flex: 1;
        gap: 16px;
        flex-direction: row;
    }
`;

export const EmptyStateSquareLogoTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    ${views.tablet} {
        flex-direction: row;
        gap: 16px;
    }
`;

export const EmptyStateTitleVIPSyncWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${views.tablet} {
        flex-direction: row;
    }
`;

export const EmptyStateSquareLogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
`;

export const EmptyStateSquareLogo = styled.img`
    flex-shrink: 0;
    width: 48px;
    height: 48px;
`;

export const EmptyStateSquareTitle = styled.div`
    color: var(--Dark-Gray-1, #1A1B1D);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    ${props => props.isVipSync && 'white-space: nowrap;'};

    ${views.tablet} {
        text-align: left;
    }
`;

export const EmptyStateSVIPSyncLogoWrapper = styled.div`
    display: flex;
    height: 18px;
    padding: 1px 8px;
    justify-content: center;
    align-items: center;
    gap: 2px;

    border-radius: 25px;
    border: 1px solid var(--Class-Funds, #DEB482);
`;

export const EmptyStateVIPSyncText = styled.div`
    color: var(--Class-Funds, #DEB482);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
`;