import React from 'react';
import { StepLine, StepNodeWrapper, StepsPathWrapper } from './StepsPath.styles';

export const StepsPath = ({ stepsLength, currentStep, completedSteps, onJumpToStep }) => {
  return (
    <StepsPathWrapper>
      {[...Array(stepsLength).keys()].map((step, index) => {
        // const isDone = completedSteps.includes(index);
        const isDone = index < completedSteps.length;
        const isCurrent = currentStep === index;
        return (
          <React.Fragment key={index}>
            <StepNodeWrapper onClick={() => onJumpToStep(index)}>
              {isCurrent ? <StepCurrentSvg /> : isDone ? <StepDoneSvg /> : <StepDefaultSvg />}
            </StepNodeWrapper>
            {index < stepsLength - 1 && <StepLine isDone={isDone && ( index < completedSteps.length-1 || index === currentStep - 1 )} />}
          </React.Fragment>
        );
      })}
    </StepsPathWrapper>
  );
};

const StepDoneSvg = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.666504" y="0.666748" width="16.6667" height="16.6667" rx="8.33333" fill="#A6EDCF" />
    <path d="M12.0598 7.11322L8.16845 11.0068L6.22066 9.05964" stroke="#111213" strokeWidth="1.66667" />
  </svg>
);

const StepCurrentSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.416667" y="0.416667" width="19.1667" height="19.1667" rx="9.58333" fill="#111213" />
    <rect x="0.416667" y="0.416667" width="19.1667" height="19.1667" rx="9.58333" stroke="#A6EDCF" strokeWidth="0.833333" />
    <rect x="4.16699" y="4.1665" width="11.6667" height="11.6667" rx="5.83333" fill="#A6EDCF" />
  </svg>
);

const StepDefaultSvg = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.08366" y="1.08317" width="15.8333" height="15.8333" rx="7.91667" fill="#1A1B1D" />
      <rect x="1.08366" y="1.08317" width="15.8333" height="15.8333" rx="7.91667" stroke="#9097A3" strokeWidth="0.833333" />
    </svg>
  )
}