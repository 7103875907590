import { DatePicker, Input } from 'antd';
import moment from 'moment';
import React, { useState } from 'react'
import NumberFormat from 'react-number-format';
import { DeleteIcon, PlusIcon } from '../../../../../assets/icons/common/common-icons';
import { colors } from '../../../../styles/theme.styles';
import { disabledMoreThanToday } from '../../../../utils/dates.utils';
import { displayMoneyValue, floatFixed, numberWithCommas } from '../../../../utils/number-display.utils';
import { isNullOrUndefinedOrEmptyString } from '../../../../utils/object.utils';
import { SimpleSelect } from '../../../DropDownMenu/SimpleSelect';
import { ActionButtonWrapper, CreateButton, HeaderCell, HoldingsBadgeText, HoldingsBadgeTriangle, HoldingsBadgeWrapper, InputWithBadgeWrapper, RowActionsCell, RowCell, RowWrapper, cryptoTradingTableSelectButtonCustomStyles, TableBottomActions, TableHeaderRow, TableScrollContent, TotalInvestmentAmount, TotalInvestmentHeader, TotalNumberOfCoinsAmount, TotalNumberOfCoinsHeader, TotalsRow, Wrapper } from './CryptoTradingTable.styles';


const tableColumns = ['Date', 'Event', 'Quantity', 'Cost', 'Price', 'Notes'];
const eventTypeOptions = [
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
  { value: 'Deposit', label: 'Deposit' },
  { value: 'Transfer', label: 'Transfer' },
];

export const CryptoTradingTable = (props) => {
  const { onChange, value, rowValuePreFix, withError, holdings, currency} = props;
  const [editedRow, setEditedRow] = useState(value.length ? value[value.length - 1].id || ('t' + value[value.length - 1].tempId) : 0);

  const handleAddRowClick = (rows) => {
    const maxValueOfTempId = Math.max(...value.map(u => u.tempId || 0), 0);
    // let lastPricePerCoin = '';
    // if (rows.length) {
    //   lastPricePerCoin = rows.filter(row => row.pricePerCoin !== '').sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.pricePerCoin || '';
    // }

    const newRow = {
      date: moment().utc().set('D',15).startOf('D'),
      eventType: eventTypeOptions[0].value,
      quantity: '',
      cost: '',
      pricePerCoin: '',
      // pricePerCoin: lastPricePerCoin,
      remarks: '',
      tempId: maxValueOfTempId + 1,
    }
    setEditedRow('t' + (maxValueOfTempId + 1));
    onChange && onChange([...value, newRow]);
  }

  const handleAttributeRowUpdated = (updatedIndex, data, key) => {
    let updatedSortedSchedule = value.map((item, index) => (index === updatedIndex ? data : item));
    if (key === 'date') {
      updatedSortedSchedule = updatedSortedSchedule.sort((a, b) => new Date(a.date) - new Date(b.date));
    }
    onChange && onChange(updatedSortedSchedule);
  }

  const handleAttributeRowDeleted = (deletedIndex) => {
    const updatedSchedule = value.filter((item, index) => index !== deletedIndex);
    onChange && onChange(updatedSchedule);
  }

  const getTotalInvestmentValue = () => {
    return rowValuePreFix + displayMoneyValue(
      value?.reduce((acc, row) => acc + (isNullOrUndefinedOrEmptyString(row.cost) ? 0 : row.cost * (row.eventType === 'Sell' || row.eventType === 'Transfer' ? -1 : 1)), 0)
    )
  }

  const getTotalQuantityValue = () => {
    return numberWithCommas(floatFixed(value?.reduce((acc, row) => acc + (isNullOrUndefinedOrEmptyString(row.quantity) ? 0 : row.quantity * (row.eventType === 'Sell' || row.eventType === 'Transfer' ? -1 : 1)), 0), 2))
  }


  return (
    <Wrapper>
      <TableHeaderRow onClick={(e) => { e.stopPropagation() }}>
        {tableColumns.map((columnLabel, ind) => (
          <HeaderCell key={'hc1' + ind} >
            <span>{columnLabel}</span>
          </HeaderCell>
        ))}
      </TableHeaderRow>
      <TableScrollContent>
        {value?.map((row, index) => (
          <HistoryRow key={row.id || ('t' + row.tempId)}
            rowValuePreFix={rowValuePreFix} index={index}
            item={row} atrKey={row.atrKey}
            withError={withError}
            isEditMode={row.id ? (editedRow === row.id) : (editedRow === 't' + row.tempId)}
            setEditMode={() => setEditedRow(row.id ? row.id : 't' + row.tempId)}
            holdings={holdings}
            currency={currency}
            rowUpdated={(data, key) => { handleAttributeRowUpdated(index, data, key) }}
            rowDeleted={() => handleAttributeRowDeleted(index)} />
        ))}
      </TableScrollContent>
      <TableBottomActions>
        <CreateButton onClick={() => { handleAddRowClick(value) }}>
          <PlusIcon />
          <span className='button-text'>Add</span>
        </CreateButton>
        <TotalsRow>
          <RowCell></RowCell>
          <RowCell style={{ flexDirection: 'column' }}>
            <TotalNumberOfCoinsHeader>Number of coins</TotalNumberOfCoinsHeader>
            <TotalNumberOfCoinsAmount>{getTotalQuantityValue()}</TotalNumberOfCoinsAmount>
          </RowCell>
          <RowCell style={{ flexDirection: 'column' }}>
            <TotalInvestmentHeader>Total investment</TotalInvestmentHeader>
            <TotalInvestmentAmount>{getTotalInvestmentValue()} </TotalInvestmentAmount>
          </RowCell>
          <RowCell></RowCell>
          <RowCell></RowCell>
          <RowCell></RowCell>
        </TotalsRow>
      </TableBottomActions>
    </Wrapper>
  )
}

const HistoryRow = (props) => {
  const { TextArea } = Input;
  const { item, rowDeleted, isEditMode, setEditMode, rowValuePreFix, holdings, withError, currency } = props;
  const editModeStyle = { borderColor: 'transparent', backgroundColor: 'transparent' };

  const handleFieldChange = (field, value) => {
    props.rowUpdated({
      ...item,
      [field]: value
    }, field)
  }

  const handleFieldBlur = (field) => {
    const watchedFieldsArray = [item.cost, item.quantity, item.pricePerCoin];
    if (watchedFieldsArray.filter(i => i).length === 2) {
      let change = {};
      if (field !== 'cost' && !item.cost) {
        change.cost = Math.abs(item.quantity) * item.pricePerCoin;
      }
      else if (field !== 'quantity' && !item.quantity) {
        change.quantity = item.cost / item.pricePerCoin;
      }
      else if (field !== 'pricePerCoin' && !item.pricePerCoin) {
        change.pricePerCoin = item.cost / Math.abs(item.quantity);
      }
      props.rowUpdated({
        ...item,
        ...change
      }, '');
    }
  }

  return (
    <RowWrapper onClick={(e) => { e.stopPropagation(); setEditMode() }}>
      <RowCell>
        <DatePicker picker="month" disabled={item.eventId}
          disabledDate={disabledMoreThanToday}
          className={`innerTable`}
          allowClear={false}
          format={'MM-YYYY'}
          inputReadOnly
          placeholder=''
          style={isEditMode ? { height: '32px' } : { borderColor: 'transparent', backgroundColor: 'transparent', height: '32px' }}
          {...(item.date ? { suffixIcon: null } : {})}
          value={item.date ? moment(item?.date) : undefined} onChange={(val) => { handleFieldChange('date', (val ? val.format() : item?.date)) }} />
      </RowCell>
      <RowCell>
        <SimpleSelect value={item.eventType}
          className={`innerTable`}
          buttonStyle={cryptoTradingTableSelectButtonCustomStyles(isEditMode)}
          placement={"bottomLeft"}
          options={
            [...eventTypeOptions.map(opt => ({ value: opt.value, label: opt.label }))]}
          onChange={(val) => { handleFieldChange('eventType', val) }} />
      </RowCell>
      <RowCell>
        <NumberFormat
          thousandSeparator={true}
          customInput={Input}
          className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.quantity) ? ' inputEntryError' : ''}`}
          allowNegative={false}
          style={{ ...(isEditMode ? {} : editModeStyle) }}
          value={(isNullOrUndefinedOrEmptyString(item.quantity)) ? '' : parseFloat((+item?.quantity).toFixed(2))}
          onBlur={() => { handleFieldBlur('quantity') }}
          onValueChange={(values) => { handleFieldChange('quantity', values.floatValue) }}
          suffix={' ' + currency}
        />
      </RowCell>
      <RowCell>
        {/* {!isNullOrUndefined(holdings) && holdings !== 100 &&
          <NumberFormat
            thousandSeparator={true}
            customInput={Input}
            prefix={rowValuePreFix}
            className={`innerTable`}
            allowNegative={false}
            placeholder={isEditMode ? rowValuePreFix : ''}
            style={{ width: '100%', ...(isEditMode ? {} : editModeStyle), ...(holdings !== 100 ? { paddingLeft: '30px', color: colors.holdingsValueColor } : {}) }}
            value={(isNullOrUndefinedOrEmptyString(item?.cost)) ? '' : parseFloat(Math.abs((+item?.cost * (holdings / 100)).toFixed(2)).toFixed(2))}
            autoComplete="off"
            onBlur={() => handleFieldBlur('cost')}
            onValueChange={(values) => { handleFieldChange('cost', values.floatValue || '') }}
          />} */}
        <InputWithBadgeWrapper>
          <NumberFormat
            thousandSeparator={true}
            customInput={Input}
            className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.cost) ? ' inputEntryError' : ''}`}
            prefix={rowValuePreFix}
            allowNegative={false}
            placeholder={isEditMode ? rowValuePreFix : ''}
            style={{ width: '100%', ...(isEditMode ? {} : editModeStyle), ...(holdings !== 100 ? { paddingLeft: '30px', color: colors.holdingsValueColor } : {}) }}
            //  style={isIncognito && field.shouldBeHiddenIncognito ? {WebkitTextSecurity:'disc'}:{}} 
            value={(isNullOrUndefinedOrEmptyString(item?.cost)) ? '' : parseFloat((+item?.cost * (holdings / 100)).toFixed(2))}
            autoComplete="off"
            onBlur={() => handleFieldBlur('cost')}
            onValueChange={(values) => { handleFieldChange('cost', values.floatValue) }}
          />
          {holdings !== 100 && <HoldingsBadgeWrapper><HoldingsBadgeText></HoldingsBadgeText><HoldingsBadgeTriangle /></HoldingsBadgeWrapper>}
        </InputWithBadgeWrapper>
      </RowCell>
      <RowCell>
        <InputWithBadgeWrapper>
          <NumberFormat
            thousandSeparator={true}
            customInput={Input}
            prefix={rowValuePreFix}
            className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.pricePerCoin) ? ' inputEntryError' : ''}`}
            allowNegative={false}
            placeholder={isEditMode ? rowValuePreFix : ''}
            style={{ width: '100%', ...(isEditMode ? {} : editModeStyle) }}
            value={(isNullOrUndefinedOrEmptyString(item?.pricePerCoin)) ? '' : parseFloat(Math.abs((+item?.pricePerCoin)))}
            autoComplete="off"
            onBlur={() => handleFieldBlur('pricePerCoin')}
            onValueChange={(values) => { handleFieldChange('pricePerCoin', values.floatValue) }}
          />
        </InputWithBadgeWrapper>
      </RowCell>
      <RowCell>
        <TextArea rows={1}
          className={'innerTable'}
          style={{ resize: 'none', ...(isEditMode ? { borderRadius: '0 4px 4px 0', } : editModeStyle) }}
          value={item?.remarks} onChange={(e) => { handleFieldChange('remarks', e.target.value) }} />
      </RowCell>
      <RowActionsCell>
        <ActionButtonWrapper
          onClick={() => { rowDeleted && rowDeleted() }} >
          <DeleteIcon />
        </ActionButtonWrapper>
      </RowActionsCell>
    </RowWrapper>
  )
}