import { makeAutoObservable } from "mobx";
import { uploadDocuments, updateDocument, deleteDocument } from "./documents.service";
import { errorNotification, successNotification } from "../../../../../../layout/components/Notifications/notifications";
// import { createEvent, deleteEvent, updateEvent } from "./events.service";

export class DocumentsStore {

    // assetsIdsUploadingLoading
    documents = [];
    currentItemId = 0;
    loadingAssets = [];
    loadingLiabilities = [];

    constructor(){
        makeAutoObservable(this);
    }

    setItemDocuments(itemId,documents) {
        this.currentItemId = itemId;
        this.documents = documents;
    }
    uploadDocuments = async (acceptedFiles) => {
        const itemId = this.currentItemId;
        let formData = new FormData();
        Array.from(acceptedFiles).forEach(file=>{
          formData.append('files[]',file,file.name);
        }) 
        this.setAssetsLoadingsIds(itemId,true) 
        const uploadedDocuments = await uploadDocuments(this.currentItemId,formData);   
        if (uploadedDocuments.statusCode){
            errorNotification('Something went wrong');
        } else {
            successNotification('Uploaded successfully')
            this.setDocuments([...this.documents,...uploadedDocuments.documents]);
        }
        this.setAssetsLoadingsIds(itemId,false);
    }

    setAssetsLoadingsIds(id,shouldAdd){
        if (shouldAdd){
            this.loadingAssets.push(id);
        } else {
            this.loadingAssets = this.loadingAssets.filter(item=>item!==id);
        }
    }

    setDocuments(documents){
        this.documents = documents;
    }

    updateDocument = async (document) => {
        const {updatedAt,...dataToSend} = document;
        const updatedData = await updateDocument(document.id,dataToSend);
        if (updatedData.statusCode || updatedData === false){
            errorNotification('Something went wrong'); 
        } else {
            successNotification('Updated successfully');
            const updatedDocs = this.documents.map(d=>{
                if (d.id === document.id){
                    return document;
                } else {
                    return d;
                }
            });
            this.setDocuments(updatedDocs);
        }
    }

    deleteDocument = async (documentId) => {
        const isDeleted = await deleteDocument(documentId);
        if (isDeleted.statusCode || isDeleted === false){
            errorNotification('Something went wrong'); 
        } else {
            successNotification('Deleted successfully')
            const filteredDocs = this.documents.filter(ad=> ad.id !== documentId);
            this.setDocuments(filteredDocs);
        }
    }

    get isLoading(){
        return this.loadingAssets.includes(this.currentItemId);
    }

    get sortedDocuments(){ // sorted descending by document.year and within years by document.createdAt also descending
        return [...this.documents].sort((a,b)=>{
            if (a.year < b.year){
                return 1;
            } else if (a.year > b.year){
                return -1;
            } else {
                if (a.createdAt < b.createdAt){
                    return 1;
                } else if (a.createdAt > b.createdAt){
                    return -1;
                } else {
                    return 0;
                }
            }
        });
    }
 
}
