import styled from "@emotion/styled/macro";
import { colors, views } from "../../../../common/styles/theme.styles";

export const HoldingEntityEmptyStateRowIconWrapper = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  background: ${colors.lightGray2};
  border-radius: 6px;
  ${views.tablet} {
    border-radius: 8px;
    border: 1px solid ${colors.gray3};
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const HoldingEntityEmptyStateRowTextWrapper = styled.div`
  color: ${colors.darkGray3};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const HoldingEntityEmptyStateRowWrapper = styled.div`
  display: flex;
  height: 44px;
  padding: 0px 4px 0px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px dashed ${colors.gray3};
  cursor: pointer;
  :hover {
    ${views.desktop} {
      border: 1px dashed ${colors.darkGray1};
      ${HoldingEntityEmptyStateRowTextWrapper} {
        color: ${colors.darkGray1};
      }
      ${HoldingEntityEmptyStateRowIconWrapper} {
        svg {
          path {
            stroke: ${colors.darkGray1};
          }
        }
      }
    }
  }
    ${views.tablet} {
      border: none;
      color: ${colors.darkGray1};
      background: ${colors.lightGray1};
      svg {
        path {
          stroke: ${colors.darkGray2};
        }
      }
    }
`;