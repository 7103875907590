
export const beneficiaryRow = ({ isEditForm, updateField, label, description, validations = [] }) => ({
  fields: [
    {
      key: 'beneficiary',
      type:'beneficiary-select',
      label: label || 'Holding entity',
      description,
      allowClear: true,
      withSuffixIcon: true,
      setFnForSave: (isDistribution, value, formValues) => (!value ) ? null : value.trim(),
      ...(validations.length > 0 ? {
        validations
      } : {}),
      ...(isEditForm ? {

      } : {})
    },
  ]
})