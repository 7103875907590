
export const updateFieldsOnBlur = (field, item) => {
  let change = {};
  let { value, principal, interest } = item;
  value = value || 0;
  principal = principal || 0;
  interest = interest || 0;
  const sumOfPrincipalInterest = (principal !== 0 || interest !== 0) ? principal + interest : '';

  if (field === 'value') {
    if (principal && value >= principal && !interest) {
      change.interest = (value - principal) || '';
    } else if (interest && value >= interest && !principal) {
      change.principal = (value - interest) || '';
    } else if (principal && interest && (value <= sumOfPrincipalInterest)) {
      change.principal = '';
      change.interest = '';
    } else if (!value && (!interest || !principal)) {
      change.principal = '';
      change.interest = '';
    }
  } else if (field === 'principal' && value) {
    if (interest) {
      change.value = sumOfPrincipalInterest;
    } else if (principal) {
      if (principal >= value) {
        change.value = principal;
      } else {
        change.interest = value - principal;
      }
    }
  } else if (field === 'interest' && value) {
    if (principal) {
      change.value = sumOfPrincipalInterest;
    } else if (interest) {
      if (interest >= value) {
        change.value = interest;
      } else {
        change.principal = value - interest;
      }
    }
  } else {
    change.value = sumOfPrincipalInterest;
  }
  for (const [key, value] of Object.entries(change)) {
    if (value < 0) {
      change[key] = 0;
    }
  }
  return change;
}