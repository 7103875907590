import { Input } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState, useRef } from 'react';
import { CategorySelector } from '../../../../common/components/CategorySelector/CategorySelector';
import { ClassIconWrapper } from '../../../../common/components/CategorySelector/CategorySelector.styles';
import { useStore } from '../../../app/data/root.store';
import { AssetClassIcon } from '../../../assets/components/AssetClassIcon';
import { DeepAnalysisReviewResultsRow, DeepAnalysisReviewResultsRowCategory, DeleteActionWrapper, ResultRowCell, ReviewNameSkeleton, ReviewValueSkeleton } from './DeepAnalysisReviewResultModal.styles';
import { ReactComponent as EditPencilSvg } from './assets/edit-pencil-icon.svg';
import { ReactComponent as NewRowIcon } from './assets/new-row-icon.svg'
import { ReactComponent as EraseIcon } from './assets/erase-icon.svg';
import { DeepAnalysisReviewValueCell } from './DeepAnalysisReviewValueCell';
import { PrimaryButton } from '../../../../common/components/buttons/NewButtons.styles';
import { errorNotification } from '../../../layout/components/Notifications/notifications';

export const DeepAnalysisResultsRow = observer(({ item, itemIndex, isEdited, onEditedIndexChange, relevantClasses, handleCategoryChange, isNew, onClickDelete, scrollToMe, showSkeleton }) => {
  
  const [currentItem, setCurrentItem] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [isHoverOn, setIsHoverOn] = useState(false);
  const { uiStore, magicBoxAiStore } = useStore();
  const rowRef = useRef(null);

  useEffect(() => {
    if (!isNew) {
      setDefaultValues(item);
      setCurrentItem(item);
    }
  }, [item, isNew])

  useEffect(() => {
    if ( scrollToMe && rowRef.current){
        rowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [ scrollToMe, rowRef])  

  const handleFieldChange = (field, value) => {
    const updatedItem = { ...currentItem, [field]: value };
    setCurrentItem(updatedItem);
    if (field === 'currency' && !isNew) {
      magicBoxAiStore.updateReviewedItem(itemIndex, updatedItem);
    }
  };

  const handleBlur = () => {
    if (isEdited) {
      // Only update if there are changes
      const hasChanges = JSON.stringify(currentItem) !== JSON.stringify(defaultValues);

      if (hasChanges && !isNew) {
        magicBoxAiStore.updateReviewedItem(itemIndex, currentItem);
        onEditedIndexChange?.(null);
      }
    }
  };

  const handleItemCategoryChange = (clsId, catId) => {
    if (isNew) {
      setCurrentItem({ ...currentItem, categoryId: catId });
    } else {
      handleCategoryChange(clsId, catId);
    }
  }

  const handleAddNewItem = () => {
    if (currentItem?.categoryId && currentItem?.item_name && currentItem?.amount) {
      if (!currentItem?.currency) {
        currentItem.currency = 'USD';
      }

      magicBoxAiStore.addNewItem(currentItem);
      onEditedIndexChange?.(null);
      setCurrentItem({});
    }
    else {
      errorNotification('Please fill all fields');
    }
  }

  return (
    <DeepAnalysisReviewResultsRow
      ref={rowRef}
      isEdited={isEdited}
      onMouseEnter={() => { setIsHoverOn(true) }}
      onMouseLeave={() => { setIsHoverOn(false) }}
      onClick={() => { if (!isEdited) { onEditedIndexChange?.(itemIndex) } }}
      onBlur={handleBlur}
      isLoading={showSkeleton}
    >

      <div style={{ display: 'flex', alignItems: 'center', padding: '12px 8px', width: '100%' }}>
        <ReviewNameSkeleton active paragraph={false} loading={showSkeleton} avatar={{ size: 26, shape: 'circle' }}>
          {
            !isNew ? <ClassIconWrapper style={{ borderRadius: '38px' }} bgColor={currentItem?.color}><AssetClassIcon name={currentItem?.icon} /></ClassIconWrapper>
              : isEdited ? null :
                <NewRowIcon />
          }
          <ResultRowCell isActive={isEdited || isHoverOn}>
            <Input
              value={currentItem?.item_name}
              placeholder='New item'
              onChange={(e) => handleFieldChange('item_name', e.target.value)}
              style={{
                ...((isEdited || isHoverOn) ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }),
                height: '48px', borderRadius: '8px',
                ...(magicBoxAiStore.showMissingFieldsError && !isNew && !currentItem?.item_name && { borderColor: 'red' })
              }}
              suffix={!isEdited && isHoverOn && !isNew && <EditPencilSvg />}
            />

          </ResultRowCell>
        </ReviewNameSkeleton>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', padding: '12px 4px' }}>

        <ResultRowCell isActive={isEdited || isHoverOn} style={{ width: '164px' }}>
          <ReviewValueSkeleton active paragraph={false} loading={showSkeleton} title={{ active: true, width: 92 }}>
            <DeepAnalysisReviewValueCell key={itemIndex} {...{ handleFieldChange, currentItem, isEdited, isHoverOn, isNew, showError: magicBoxAiStore.showMissingFieldsError }} />
          </ReviewValueSkeleton>
        </ResultRowCell>
        <DeepAnalysisReviewResultsRowCategory>
          <div style={uiStore.isDesktopView ? { width: isNew ? '320px' : '280px', marginRight: 0 } : { width: '100%', margin: '16px 20px 0px' }}>
            <CategorySelector
              value={currentItem?.categoryId}
              options={relevantClasses}
              onCategoryChange={handleItemCategoryChange}
              showSkeleton={showSkeleton}
              disabled={false}
              withError={magicBoxAiStore.showMissingFieldsError && !currentItem?.categoryId && !isNew}
            />
          </div>
        </DeepAnalysisReviewResultsRowCategory>

        {!isNew &&
          <DeleteActionWrapper onClick={(e) => { onClickDelete(); e.stopPropagation() }}>
            {(isEdited || isHoverOn) && <EraseIcon />}
          </DeleteActionWrapper>}
        {isNew && isEdited && <PrimaryButton onClick={handleAddNewItem} style={{ marginLeft: '16px' }}>Add</PrimaryButton>}
      </div>
    </DeepAnalysisReviewResultsRow>
  )
})
