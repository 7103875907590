import styled from '@emotion/styled/macro';
import { Input } from 'antd';
import { colors, views } from '../../styles/theme.styles';

export const ModalBackDrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    display: flex;
    background-color: ${props=>props.backgroundColor ? props.backgroundColor : 'rgba(0, 0, 0, 0.6)'};
    align-items: center;
    justify-content: center;
    z-index: 1001;
    ${views.tablet}{
        pointer-events:auto;
    }
    
`;

export const ModalWrapper = styled.div`
    color:#000;
    width: ${props=>props.width ? props.width : '90vw'};
    max-width: 880px;
    background-color: #fff;
    // border: 1px solid gray;
    // padding:16px;
    height: ${props=>props.height ? props.height : '65vh'}; ;
    max-height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 0px 30px rgba(0, 0, 0, 0.02);
    /* transform: translateY(-32px); */
    border-radius: ${props => props.borderRadius ? (props.borderRadius + 'px') : 0};
    margin-left: ${props => props.noSidebarMargin ? '0' : '260px'};
    ${props=>props.isCorrectingUpward ? `
        // transform: translateY(-32px);
        margin-top:-32px;
    ` : ``};
    ${views.desktopWithMinimizedMenu}{
        margin-left: 80px;
    }
    ${views.tablet}{
        margin-left: 0px;
        max-width: calc(100% - 32px);
        // height:100%;
        // max-height:100%;
        margin-top:0px;
    }
    
`;

export const ModalHeader = styled.div`
    padding: ${props => props.padding || '2em 2em 1.5em'};
    border-bottom: ${props => props.noBorder ? '0' : `1px solid ${colors.gray3}`};
    color: ${colors.darkGray2};

    h1,h2,h3,h4 {
        font-size: 18px;
        margin: 0;
        color: ${colors.darkGray1}
    }
    
    p {margin: 0;}
`

export const ModalBody = styled.div`
    padding: ${props => props.padding || '1.5em 2em 1em'};
    flex-grow: 1;
    max-height: 100%;
    overflow: auto;
    label {
        color: ${colors.darkGray2};
    }
`

export const ModalReportTitleInput = styled(Input)`
    height: 34px;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
    border-radius: 6px;

    &:hover{
        border-color:#7d7d7d!important;
        box-shadow: none;
    }
    &:focus{
        border-color:#7d7d7d!important;
        box-shadow: none;
    }
    &.ant-input-affix-wrapper-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }
`;

export const ModalFooter = styled.div`

    display: flex;
    padding: ${props => props.padding || '1.5em 2em 2em'};
    border-top: ${props => props.noBorder ? '0' : `1px solid ${colors.gray3}`};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-end'};

`