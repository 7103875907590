import styled from '@emotion/styled/macro';
export const flexCenter = {
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center'
}

export const flexColumn = {
  display:'flex',
  flexDirection:'column',
}

export const flexVerticalCenter = {
  display:'flex',
  flexDirection:'row',
  alignItems:'center'
}

export const ScrollPanelWithShadows = styled.div`
    overflow-y: scroll;
    background:
    /* Shadow Cover TOP */
    linear-gradient(
      #f1f3f5 30%,
      rgba(255, 255, 255, 0)
    ) center top,
    
    /* Shadow Cover BOTTOM */
    linear-gradient(
      rgba(255, 255, 255, 0), 
      #f1f3f5 70%
    ) center bottom,
    
    /* Shadow TOP */
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    ) center top,
    
    /* Shadow BOTTOM */
    radial-gradient(
      farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    ) center bottom;
  
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
`;

export const textOverflow = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: "100%",
  textOverflow: "ellipsis"
};