import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { displayMoneyFormatter, percentageDisplay , numberWithCommas } from '../../../../common/utils/number-display.utils';
import { useStore } from '../../../app/data/root.store';
import { AssetClassIcon } from '../../../assets/components/AssetClassIcon';
import { ClassItemValue, ClassValueWrapper, ItemContent, ItemIconWrapper, ItemTitle, ItemValue, LightColorText, ListItemWrapper } from '../CommunityPage.styles';
import { ValueRemarks } from '../../../wealth/pages/class/components/ClassPage.styles';

export const CommunityOverviewClassesListView = (props) => {
  const { communityStore , uiStore } = useStore();
  const location = useLocation();

  useEffect(() => {
    communityStore.setUnSelectedClass();
    return () => { }
  }, [location, communityStore]);

  const handleClassClick = (assetClass,assetClassIndex) => {
    communityStore.setSelectedClassIndex(assetClassIndex);
  }


  return (
    <Observer>{() => (
      <>
        {communityStore.classesList.map((assetClass, assetClassIndex) => (
          <ListItemWrapper key={assetClass.id} onClick={() => { handleClassClick(assetClass, assetClassIndex) }}>
            <ItemIconWrapper color={assetClass.color}><AssetClassIcon name={assetClass.icon} /></ItemIconWrapper>
            <ItemContent>
              <ItemTitle>{assetClass.title} 
              <LightColorText> ({percentageDisplay(assetClass.percentageOfAssets,100,1)})</LightColorText>
              </ItemTitle>
            </ItemContent>
            {uiStore.isDesktopView && <ItemValue>{numberWithCommas(assetClass.numberOfInvestments)}</ItemValue>}
            <ClassValueWrapper>
              <ClassItemValue>{displayMoneyFormatter(assetClass.totalUsdValue, 'USD')}</ClassItemValue>
              {!uiStore.isDesktopView && <ValueRemarks>({numberWithCommas(assetClass.numberOfInvestments)} items)</ValueRemarks>}
            </ClassValueWrapper>
          </ListItemWrapper>
        ))} 
      </>
    )}</Observer>)
}
