import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors, views } from "../../../../common/styles/theme.styles";

export const ModalHeaderWrapper = styled.div`
  ${flexCenter};
  position: relative;
`;

export const CloseButtonSvgWrapper = styled.div`
  ${flexCenter};
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  ${flexCenter};
  width:100%;
  background-color: rgba(222, 203, 192, 0.25);
  padding: 16px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.darkGray2};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const ModalContent = styled.div`
padding: 48px 48px 0;
  display: flex;
  flex-direction: column;
  // align-items: center;
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.darkGray1};
  ${views.tablet}{
    padding: 48px 20px 0;
  }
`;

export const ModalContentTitle = styled.div`
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  display: flex;
  align-items: center;
  color: ${colors.darkGray1};
  padding-bottom: 4px;
`;

export const ModalContentSubtitle = styled.div`
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: ${colors.darkGray1};
padding-bottom: 24px;
`;

export const ModalContentPerksWrapper = styled.div`
`;

export const ModalContentPerk = styled.div`
display: flex;
gap:8px;
align-items: center;
padding-bottom: 8px;
`;

export const ModalActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  align-self: center;
  padding-top: 64px;
`;

export const NoCreditRequiredText = styled.div`
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${colors.darkGray2};
  align-self: center;
  padding-top: 24px;
  ${views.tablet}{
    text-align: center;
    padding-bottom:24px;
  }
`;