import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";

export const TierBadgeWrapper = styled.div`
    ${flexCenter};
    gap: 4px;
    padding: 2px 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    height:17px;
    border:1px solid #DC9F8B;
    border-radius: 6px;
    ${props=>props.noBg ? `` : `background-color: #000;`}
    ${props=>props.isClickable ? `cursor:pointer;` : ``}
    
`;