import { makeAutoObservable } from "mobx";
import { fetchClassData } from "../../../data/wealth.service";
import { ASSET_CLASSES_OVERVIEW_DATA } from "./ClassOverviewData.const";

export class WealthClassStore{
    showArchived = false;
    categoryFilter = 0;
    isLoadingMatricesData = false;
    allClassesMatricesData = {};
    selectedClassMatricesData = {};
    heroMetricsData = {};
    hasPerformanceData = false;
   
    constructor(wealthStore){
        makeAutoObservable(this);
        this.wealthStore = wealthStore;
    }
   
    setShowArchived(show) {
        this.showArchived = show;
    }
    setCategoryFilter(category) {
        this.categoryFilter = category;
    }

    setLoadingMatricesData(isLoading){
        this.isLoadingMatricesData = isLoading;
    }

    setHasPerformanceData(hasData) {
        this.hasPerformanceData = hasData;
    }

    // initClassPage(classType) {
    initClassPage() {
        this.setHasPerformanceData(false);
        this.setShowArchived(false);
        this.setCategoryFilter(0);
        this.getClassData();
    }

    getClassData = async() => {
        this.setLoadingMatricesData(true);
        try {
            const matricesData = await fetchClassData(this.selected.id);
            this.setHasPerformanceData( matricesData.classPerformance && Object.keys(matricesData.classPerformance).length !== 0);
            // console.log(matricesData );
            this.setMatricesData(matricesData);
            // this.setLiabilityData(data);
        } 
        //catch (e) {} 
        finally {
            // setTimeout(() => {
                
                this.setLoadingMatricesData(false);
            // }, 3000);
        }
    }

    setMatricesData(data){
        this.selectedClassMatricesData = data;
        const { classPerformance , ...rest} = data;
        this.allClassesMatricesData = {
            ...this.allClassesMatricesData,
            [this.selected.id] : rest
        }
        this.heroMetricsData = {...classPerformance, currency:'USD'};
        // this.allClassesMatricesData[this.selected.id] = data;
    }

    get selected(){
        return this.wealthStore.selectedClass;
    }

    get connectedBrokerageAccountIds() {
        if(this.wealthStore.filterResultSingleClassId && this.wealthStore.filterResultSingleClassId === 3) {
            return this.wealthStore.itemsList.filter(item => item.isConnected).map(item => item.id);
        }
        return this.selected.items.filter(item => item.isConnected).map(item => item.id);
    }

    get matricesProperties(){
        if (this.allClassesMatricesData.hasOwnProperty(this.selected.id)){
            // const propsObj = this.allClassesMatricesData[this.selected.id].properties;
            // return Object.keys(propsObj).filter(k=>k!=='itemValue').map(k=>({matrix:k,title:propsObj[k]}));
            return this.allClassesMatricesData[this.selected.id].properties?.filter(k=>k.matrix!=='itemValue') || [];
        }
        return [];
    }

    get showEmptyState(){
        return (!this.selected.archivedItems?.length && !this.selected.items?.length) ||
                (!this.showArchived && this.selected.items?.length === 0)
        //         (!wealthClassStore.showArchived && wealthClassStore.selected.items?.length === 0)
    } 
    get filteredSortedItems(){
        const itemsMatricesData = this.allClassesMatricesData[this.selected.id]?.items ?
            this.allClassesMatricesData[this.selected.id]?.items.reduce(
                (acc,curr)=>((acc[curr.itemId] = curr),acc), {} /* eslint-disable-line no-sequences*/
            )
         : {};
        const displayedItems = this.showArchived ? this.selected.archivedItems : this.selected.items;
        const filteredItems = displayedItems.filter(item=>this.categoryFilter === 0 ? true : this.categoryFilter === item.categoryId);
        const withMatricesData = filteredItems.slice().map(item=>({
            ...item,
            matrices: itemsMatricesData[item.id] 
        }));
        return withMatricesData.sort((a, b) => (a.usdValue < b.usdValue ? 1 : a.usdValue > b.usdValue ? -1 : 0));
    }

    get classOverviewDisplayData(){
        return ASSET_CLASSES_OVERVIEW_DATA[this.selected?.id];
    }

    get chartClassData(){
        // console.log(this.selectedClassMatricesData?.items);
        const itemsWithLiabilities = this.selectedClassMatricesData?.items?.filter(item=>item?.connectedLiabilities?.length > 0)
        // console.log(JSON.stringify(this.selectedClassMatricesData));
        // const itemsWithLiabilities = this.wealthStore.selectedClass?.items?.filter(asset=>asset.hasOwnProperty('connectedLiability'))
        const liabilitiesBlackSlice = (this.selectedClassMatricesData?.items?.reduce((acc,item)=>acc + item.itemValue, 0) - itemsWithLiabilities?.reduce((acc, item)=>acc + item.connectedLiabilities.reduce((acc, liability)=>acc + liability.usdValue, 0), 0)) || 0;
        // console.log(JSON.stringify(itemsWithLiabilities?.map(item=>item.connectedLiability.usdValue)));

        return {
            datasets: [
                {
                    data: this.wealthStore.selectedClass?.items?.map(asset=>asset.usdValue),
                    backgroundColor:  this.wealthStore.selectedClass?.color,
                    
                    // hoverBackgroundColor: this.assetsGroups.map(group=>group.color),
                    borderColor:'#000',
                    
                    // hoverBorderColor:'#000',
                    label:'Assets',
                    borderWidth: 6,
                    // labels: this.assetClass.items?.map(asset=>asset.title),
                },
                ...( itemsWithLiabilities?.length > 0 ? [
                    // {
                    //     data: [1],
                    //     backgroundColor:  '#000',
                    //     hoverBackgroundColor:'#000',
                    //     hoverBorderColor: '#000',
                    //     label:'spaceX',
                    //     weight: 0.3
                    // },
                    {
                        data: [...itemsWithLiabilities?.map(iwl=>iwl.connectedLiabilities.map(liability=>liability.usdValue)).flat(), liabilitiesBlackSlice],
                            // itemsWithLiabilities?.map(item=>item?.connectedLiability?.usdValue / ( item.usdValue * ((item.holdings || 100)/100)))
                            
                        // ],
                        backgroundColor:  [...itemsWithLiabilities.map(iwl=>iwl.connectedLiabilities.map(liability=>'#D37A7A')).flat(),'#000'],
                        // hoverBackgroundColor: ['#D37A7A','#000'],
                        label:'Liability',
                        // hoverBackgroundColor: this.assetsGroups.map(group=>group.color),
                        // hoverBorderColor: '#000',
                        borderColor:'#000',
                        borderWidth: 6,
                        // weight: 0.5
                    }
                ] : [] )
            ]
        };
    }
    
}