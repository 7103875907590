import { makeAutoObservable } from "mobx";
import { communityOverviewPieChartData, communitySingleClassPieChartData } from "../components/charts/CommunityPieChart/community.chart.data";
import { getCommunityData } from "./community.service";

const allocationsInfo = [
  {id: 'allocation1' , title: 'Less than $1M' , mobileTitle : 'Up to $1M'},
  {id: 'allocation2' , title: '$1M-$5M'},
  {id: 'allocation3' , title: '$5M-$10M'},
  {id: 'allocation4' , title: '$10M+'},
];
export class CommunityStore {
  isLoading = false;
  isLoadingInstitutions = false;
  isLoadingMoreInstitutions = false;
  pillarHoverData = { show: false, index: -1 };
  communityData = [];
  // communityInstitutions = [];
  cursor = 25;
  selectedTab = 'institutions';
  selectedClassId = 0;
  selectedClassIndex = -1;
  classesData = [];
  overviewAllocationData = {}
  itemsSortBy = 'totalValue';
  //itemsSortByColumn = {dataKey: 'usdValue', isItemProp: true};
  itemsSortDirection = 'desc';
  mobileSelectedInstitutionIndex = -1;



  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading = (loading) => {
    this.isLoading = loading;
  }

  getData = async () => {
    this.setIsLoading(true);
    try {
      const data = await getCommunityData();
      this.setData(data);
    }
    catch (e) { }
    finally {
      this.setIsLoading(false);
    }
  }

  setData(data) {
    this.communityData = data.communityData;
    this.overviewAllocationData = data.usersAllocations;
  }

  setPillarHoverData(index) {
    if (index === -1) {
      this.pillarHoverData = { show: false, index };
    } else {
      this.pillarHoverData = { show: true, index, ...this.pillarsData[index] };
    }
  }

  setIsLoadingInstitutions(isLoading) {
    this.isLoadingInstitutions = isLoading;
  }

  setIsLoadingMoreInstitutions(isLoading) {
    this.isLoadingMoreInstitutions = isLoading;
  }

  setCommunityInstitutions(data) {
    this.communityInstitutions = data;
  }

  setSelectedTab(selectedTab) {
    this.selectedTab = selectedTab;
  }

  setSelectedClassIndex(index) {
    this.mobileSelectedInstitutionIndex = -1;
    this.selectedClassIndex = index;
    this.selectedClassId = this.communityData[this.selectedClassIndex].id;
  }

  setUnSelectedClass(){
    this.mobileSelectedInstitutionIndex = -1;
    this.selectedClassIndex = -1;
    this.selectedClassId = 0;
  }

  setMobileSelectedInstitutionIndex(index){
    this.mobileSelectedInstitutionIndex = index;
  }

  setCursor(index) {
    this.cursor = index;
  }

  // fetchInstitutions = async () => {
  //   this.setIsLoadingInstitutions(true);
  //   try {
  //     setTimeout(() => {
  //       this.setCommunityInstitutions(DUMMY_DATA.slice(0, 20));
  //       this.setIsLoadingInstitutions(false); // move this back to finally clause
  //     }, 1500);
  //   } catch (e) { }
  //   finally {
  //     // this.setIsLoadingInstitutions(false);
  //   }
  // }

  // callNext = () => {
  //   this.setIsLoadingMoreInstitutions(true);
  //   try {
  //     setTimeout(() => {
  //       if (!this.hasMoreInstitutions) {
  //         console.log('no more to fetch');
  //         return
  //       }
  //       this.setCommunityInstitutions([...this.communityInstitutions, ...DUMMY_DATA.slice(this.communityInstitutions.length, this.cursor)]);
  //       this.setIsLoadingMoreInstitutions(false); // move this back to finally
  //     }, 1500);
  //   } catch (e) { } finally {
  //     this.setCursor(this.cursor + 5);
  //     // this.setIsLoadingMoreInstitutions(false);
  //   }
  // }

  setSortBy = (sortByParam) => {
    this.itemsSortBy = sortByParam;
}
// setSortByColumn = (dlCol) => {
//     this.itemsSortByColumn = dlCol;
// }
setSortDirection = (dir) => {
    this.itemsSortDirection = dir;
}

  get isOverviewView () {
    return this.selectedClassIndex === -1;
  }

  get isClassView () {
    return this.selectedClassIndex !== -1;
  }

  get isShowingInstitutions () {
    return this.isClassView || this.selectedTab === 'institutions';
  }

  get selectedClass () {
    return this.selectedClassIndex === -1 ? null : this.communityData[this.selectedClassIndex];
  }

  get selectedClassFromList () {
    return this.selectedClassIndex === -1 ? null : this.classesList[this.selectedClassIndex];
  }

  get hasMoreInstitutions() {
    return false; // this.cursor < DUMMY_DATA.length;
  }

  get totalNetWorthValue (){
    return this.communityData.reduce(
      (a, c) => (a + c.totalValue * (c.isLiability ? -1 : 1)),0
    );
  }
  get classesList () {
    const totalAssetsWorth = this.communityData.filter(c=>!c.isLiability).reduce(
      (a, b) => (a + b.totalValue),0
  );
    return this.communityData.map(communityClassData => {
      return {
        id: communityClassData.id,
        icon: communityClassData.icon,
        color: communityClassData.color,
        title: communityClassData.title,
        totalUsdValue: communityClassData.totalValue,
        isLiability: communityClassData.isLiability,
        numberOfInvestments : communityClassData.numberOfInvestments,
        percentageOfAssets: communityClassData.totalValue / totalAssetsWorth * 100
      }
    })
  }

  get institutionListCombined() {
    const allInstCombined = this.communityData.reduce((acc,classData)=>{
      Object.keys(classData.institutions).forEach(instKey=>{
        const instData = classData.institutions[instKey];
        if (instKey in acc){
          acc[instKey].totalValue += instData.totalValue; // (instData.totalValue * (classData.isLiability ? -1 : 1));
          acc[instKey].numberOfInvestments += instData.numberOfInvestments;
          acc[instKey].classes.push({classId: classData.id , color: classData.color , title : classData.title, icon: classData.icon , value: instData.totalValue})
        } else {
          acc[instKey] = { ...instData , classes : [
            {classId: classData.id , color: classData.color , title : classData.title, icon: classData.icon, value: instData.totalValue}
          ] };
        }
      });
      return acc;
    },{});
    return allInstCombined;
  }

  get institutionList() {
    if (this.selectedClassIndex !== -1 && this.selectedClass){
      const instListObj = this.selectedClass.institutions;
      return Object.keys(instListObj).map(insName=>({title:insName , ...instListObj[insName], totalClassesValue: this.institutionListCombined[insName].totalValue  , classes: this.institutionListCombined[insName].classes}))
    } else {
      const instListObj = this.institutionListCombined;
      return Object.keys(instListObj).map(insName=>({title:insName , ...instListObj[insName]}))
    }
  }
  get sortedInstitutions () {
    const otherItem = this.institutionList.find(ins => ins.title === 'Other');
    const sortedItems = this.institutionList.filter(ins => ins.title !== 'Other').sort((a,b)=>{ 
      if (this.itemsSortBy === 'totalValue'){
        return (b.totalValue) - (a.totalValue)
      }
      if (this.itemsSortBy === 'numberOfInvestments'){
        return (b.numberOfInvestments) - (a.numberOfInvestments)
      }
      // if (this.itemsSortByColumn.type === 'date'){
      //    return  +(new Date(b.sortValue)) - +(new Date(a.sortValue));
      // }
      // // if (this.itemsSortByColumn.type === 'assetName'){
      // //     return (''+(b.title || '')).localeCompare((a.title || ''), 'en', {sensitivity: 'base'});
      // // }
      // if (this.itemsSortByColumn.type === 'text'){
      //     return (''+(b.sortValue || '')).localeCompare((a.sortValue || ''));
      // }
      // if (this.itemsSortByColumn.type === 'liabilityAmount'){
      //     return b.sortValue?.reduce((c,d)=>c+d.usdValue,0) -  a.sortValue?.reduce((c,d)=>c+d.usdValue,0);
      // }
      // if (['percentage','multiplier'].includes(this.itemsSortByColumn.type)){
      //     return b.sortValue - a.sortValue;
      // }
      // if (this.itemsSortByColumn.type === 'bool'){
      //     return b.sortValue === a.sortValue ? 0 : b.sortValue ? 1 : -1;
      // }
      // By name :
      return (''+(b.title || '')).localeCompare((a.title || ''));
      
      //return (b.sortValue*b.holdings||1) - (a.sortValue*a.holdings||1)
    }); // ? 1 : a.sortValue > b.sortValue ? -1 : 0});

    return [...(this.itemsSortDirection === "asc" ? sortedItems.reverse() : sortedItems), ...(otherItem ? [otherItem] : [])];
  }
  get pillarsData() {
    return this.isOverviewView ? allocationsInfo.map(ai=>({...ai,percentage:this.overviewAllocationData[ai.id] || 0})) : 
      allocationsInfo.map(ai=>({...ai,percentage:this.selectedClass?.allocations[ai.id]?.percentageFromAllocation || 0}))
  }
  
  get maxPillarPercentages(){
    return Math.max(...this.pillarsData.map(p=>p.percentage), this.isClassView ? this.selectedClass?.myAllocation?.totalWorthPercentage : 0 );
  }

  get classFilterTagForHeaderIcon() {
    if (!this.selectedClass){return []}
    return [{
      id: this.selectedClass.id,
      title: this.selectedClass.title,
      type: 'classes',
      isLiability: this.selectedClass.isLiability,
      icon: this.selectedClass.icon,
      titlePrefix: '',
      tagColor: this.selectedClass.color,
      // selectedCategoriesCount: selectedClass.isAllCategoriesSelected ? null : selectedClass.selectedCategoriesCount
  }]
}

  get institutionsCount() {
    return this.selectedClass ? Object.keys(this.selectedClass.institutions).length : Object.keys(this.institutionListCombined).length;
  }
  get chartClassesData() {
    return communityOverviewPieChartData(this.communityData,this.pillarHoverData.id || null);
  }

  get chartSingleClassData(){
    return communitySingleClassPieChartData(this.selectedClass, null); 
    // ready to show allocation categiries pie ->.
    // return communitySingleClassPieChartData(this.selectedClass,this.pillarHoverData.id || null);
  }

  get chartClassCategories () {
    return Object.keys(this.selectedClass?.categoriesAllAllocationsValues);
  }

  get assetClasses () {
    return this.classesList.filter(g => !g.isLiability);
  }

  get liabilitiesClasses() {
    return this.classesList.filter(g => g.isLiability);
  }

}