import { ASSET_CATEGORIES_CREATE_EVENT } from '../pages/asset/components/Actuals/constants/createEvent.events.const';
export function getEventCreatedMixpanelData(createEventForm, createdEventDate, eventType, is_demo) {

    const { 
        values: { 
            eventRemarks: remarks = null, 
            id: asset_id, 
            title: asset_name,
            transactionValue: event_value,
            updatedValue: event_value_change = null,
            categoryClass: {
                title: asset_type
            } = null,
            category: {
                title: asset_category
            } = null,
            categoryId
        } 
    } = createEventForm;

    const date = new Date(createdEventDate);
    const event_date = `${date.getMonth() + 1}/${date.getFullYear()}`;
    
    const event_type = ASSET_CATEGORIES_CREATE_EVENT?.[categoryId]?.[eventType]?.['title'] || null;
    return {
        asset_id,
        asset_name, 
        asset_type, 
        asset_category, 
        event_type, 
        event_date, 
        event_value, 
        event_value_change, 
        created_from: "manually", 
        is_demo,
        remarks 
    }
}

export const getTransactionMenuOpenProperties = (item) => {
    const { title : item_name , id : item_id , category:{ title : category_title}} = item;
    return { item_id , item_name , category_title };
}
export const getTransactionMatchEventProperties = (transaction,connectedItem) => {
    const { providerTransactionId : provider_transaction_id , 
            id : transaction_id , 
            value: transaction_amount,
            date: transaction_date,
            remarks : description,
        } = transaction;
    
    
    const {title: transaction_source } = connectedItem ? connectedItem : {title:null};
    return {transaction_source, provider_transaction_id,transaction_id, transaction_amount, transaction_date, description };
} 

// 