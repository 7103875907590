import styled from '@emotion/styled/macro';
import { colors } from './theme.styles';

export const CommonActionMenuWrapper = styled.div`
    white-space: nowrap;
    border: 1px solid #DDE3EB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin-left: 20px;
`;

export const CommonActionMenuItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
    padding: 8px 12px;
    cursor: pointer;
    background-color: #FFFFFF;
    animation: all 0.2s;
    color: ${props => props.color ? props.color : colors.darkGray1};
    
    :first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    :last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    :hover {
        background: linear-gradient( 90deg, #000  2px, #F7F8F9 2px);
    }
    > a{
        display: flex;
        align-items: center;
        gap: 8px;
        height: 44px;
        padding: 8px 12px;
        color: ${props => props.color ? props.color : colors.darkGray1};
        flex: 1;
        margin: 0 -12px;
    }

`;

export const DeleteIconContainer = styled.div`
    svg{
        path{
            stroke:${props => props.color ? props.color : colors.darkGray1};
        }
    }
`;