import styled from "@emotion/styled/macro";
import { colors } from "../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../common/styles/combos.styles";


export const ModalCloseButtonWrapper = styled.div`
  position:absolute;
  right:16px;
  top:12px;
  width:24px;
  height:24px;
  cursor: pointer;
  path{
    stroke:${colors.darkGray3};
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0 0 32px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: ${colors.darkGray1};
  position: relative;
`;

export const InstitutionIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    // margin-left: 12px;
    img {
      width:40px;
      height:40px;
      border-radius: 50%;
    }
`;

export const LogoPlaceHolder = styled.div`
    height:40px;
    width:40px;
    background-color:${colors.gray3};
    border-radius:4px;
    ${flexCenter};
    svg {
        width:32px;
        height:32px;
    }
`;

export const ModalAltText = styled.div`
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #121D2A;
`;

export const ModalContent = styled.div`
  ${flexCenter};
  flex-direction: column;
  gap: 12px;
  width: 100%;
  flex: 1;
  padding:0 32px 32px;
`;

export const TitleText = styled.div`
    color:${colors.darkGray1};
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    font-weight: 700;
    padding:0 48px;
`;

export const DescriptionText = styled.div`
  color: ${colors.darkGray1};
  font-size: 14px;
  font-weight: 450;
  line-height: 18px;
  text-align: center;
  padding:0 24px;
`;


export const CancelSurveyModalFooter = styled.div`
  display: flex;
  gap: 12px;
  //justify-content: space-between;
  justify-content: flex-end;
  padding: 20px 32px;
  border-top: 1px solid ${colors.gray3};
`;

export const ActionButton = styled.div`
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#fff'} ;
    color: ${props => props.whiteText ? '#fff' : colors.darkGray1};
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.3);;
    border-radius: 6px;
    padding: ${props => props.isBack ? '10px 20px' : '8px 16px'};
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 1s;
    ${props => props.withIcon ? `gap: 6px` : ``};
    
    ${props => props.disabled ? `opacity: 0.2; cursor: default;` : ``}
`;
