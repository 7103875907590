export const ROUTES = {
  cashflow: {
    url: '/cashflow',
    title: 'Cash Flow',
  },
  onboarding: {
    url: '/onboarding',
    title: 'Onboarding',
  },
  community: {
    url: '/community',
    title: 'Community',
  },
  magicBox: {
    url: '/magic-box',
    title: 'Magic Box',
  },
  saltEdgeDone: {
    url: '/salt-edge-done',
    title: 'Salt Edge Done',
  },
  akoyaDone: {
    url: '/akoya-done',
    title: 'Akoya Done',
  },
  welcome: {
    url: '/welcome',
    title: 'Welcome',
  },
  wealth: {
    url: '/wealth',
    title: 'Wealth',
  },
  wealthOverview: {
    url: '/wealth/overview',
    title: 'Overview',
  },
  wealthAssets: {
    url: '/wealth/assets',
    title: 'Assets',
  },
  reports: {
    url: '/reports',
    title: 'Reports',
  },
  portfolios: {
    url: '/portfolios',
    title: 'Shared portfolios',
  },
  profile: {
    url: '/profile',
    title: 'My profile',
  },
  preferences: {
    url: '/profile/preferences',
    title: 'Preferences',
  },
  financialSettings: {
    url: '/profile/preferences/financial-settings',
    title: 'Financial settings',
  },
  entitiesManagement: {
    url: '/profile/preferences/entities-management',
    title: 'Entities management',
  },
  customClassList: {
    url: '/profile/preferences/custom-class-list',
    title: 'Custom classes and sub-classes',
  },
  apis: {
    url: '/profile/apis',
    title: 'APIs',
  },
  accounts: {
    url: '/profile/accounts',
    title: 'Synced institutions',
  },
  billing: {
    url: '/profile/billing',
    title: 'Billing',
  },
  roles: {
    url: '/profile/roles',
    title: 'Roles',
  },
  login: {
    url: '/login',
    title: 'Login',
  },
  register: {
    url: '/register',
    title: 'Register',
  },
  invitation: {
    url: '/invitation',
    title: 'Invitation',
  },
};