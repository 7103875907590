import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../../common/styles/combos.styles';
import { colors, sizes, views } from '../../../../../common/styles/theme.styles';

export const PageWrapper = styled.div`
    width:100%;
    // color:#2A2D33;
    // text-align:left;
    // text-align: center;
    // min-height:100%;
    /* overflow-y:auto; */
    // background-color:#FCFDFF;
    display:flex;
    flex-direction:column;
    /* padding-bottom:16px; */

    ${views.desktop}{}
    ${views.tablet}{
        padding-top: ${props=>props.isHeaderFixed ? `64` : `0`}px;
    }
    
`   

export const CenterContent = styled.div`
    width: 100%;
    // max-width: 880px;
    max-width: ${sizes.wealthContentWidth + 32}px;
    padding: 0 16px;
    margin: 0 auto;
    min-height:100%;
`;

export const HeaderWrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    padding-top:64px;
    padding-bottom: 16px;
    margin-bottom: 24px;
    position: sticky;
    top: -42px;
    // background-color: #fcfdff;
    border-bottom: 1px solid #e3e3e3;
`;

export const HeaderTitle = styled.div`
    flex:1;
    font-size: 24px;
    display: flex;
    align-items: center;
    padding-left:24px;
`;

export const TabsWrapper = styled.div`
    width: 100%;
    // background-color: #F6F8FE;
    // background-color: #FAFAFA;
    border-bottom:1px solid #DDE3EB;
    padding-right:14px;
    height: 44px;
    ${views.desktop}{
        position:sticky;
        top:53px;
    }

    ${props=>props.isFixed ? `
        position: fixed;
        top:72px;
    ` : ``}
    
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    z-index: 2;

    .ant-tabs-tab{
        margin:0px;
        padding: 8px 0;
        ${views.tablet}{
            padding: 9px 0;
        }
    }
    .ant-tabs-tab-btn{
        color:#3F3F45;
        font-weight: normal;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        //color:#000;
        color:#3F3F45;
        font-weight:normal;
    }

    .ant-tabs-ink-bar{
        background-color:#3F3F45;
        // background-color:#000;
    }

    .ant-tabs-top > .ant-tabs-nav{
        margin-bottom: 0px;
        padding: 4px 16px 0;
        ::before{
            border-bottom:none;
        }
    }

    .ant-tabs-nav-more {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

`;

export const TabsContentWrapper = styled.div`
    padding-bottom: 24px;
    // overflow-y: auto;
    position:relative;
    flex:1;
`;

export const TabTitleWrapper = styled.div`
    /* padding:0 12px 0 12px; */
    display:flex;
    align-items:center;
`;

export const TabTitleText = styled.div`
    font-size:14px;
    padding-right: ${props => props.hasAnyBadges ? '8px' : null};
    /* padding-left:8px; */
`;

export const ClassItemsCount = styled.span`
    height:24px;
    width:24px;
    background: #2A2D33;
    font-size: 14px;
    color:#fff;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    margin-left:8px;
`;
export const HeaderActions = styled.div`

`;


export const ItemsWrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    
`;

export const ItemWrapper = styled.div`
    width:100%;
    margin-bottom:16px;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius:4px;
    padding:24px 16px;
    display:flex;
    flex-direction:row;
    align-items:center;
    cursor: pointer;
`;

export const AssetClassIconWrapper = styled.div`
    width: 40px;
    height: 40px;   
    border-radius: 4px;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width:20px;
        height:20px;
    }
    path{
        /* stroke:#4F699D; */
        fill:#623e2e;
    }
`

export const ItemDescription = styled.div`
    flex:1;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    padding:0 16px 0 0;
`

export const ItemText = styled.div`
    display:flex;
    flex-direction:column;
`;

export const ItemTitle = styled.div`
  font-size:16px;
`;
export const ItemMoreInfos = styled.div`
    font-size:12px;
    color: #9097A3;
`;

export const ItemValue = styled.div`
    font-size:18px;
    padding-right:8px;
    font-weight: 300;
`

export const TabContentWrapper = styled.div`
    padding-top:16px;
`;

export const ActualsTableWrapper = styled.div`
    .tableRow{
        display: grid;
        grid-template-columns:120px 160px 160px 160px auto;
    }
`;

export const ActualsHeaderTableRow = styled.div`
    position:sticky;
    top:-16px;
    background-color:#f5f5f5;
    display: grid;
    grid-template-columns: 120px 160px 160px 160px auto;
    z-index:1;
    margin:0px -16px;
    padding: 0 16px;
`;

export const ActualsTableRow = styled.div`
    display: grid;
    grid-template-columns: ${props => props.isEdit ? '48px 120px 160px 160px 200px auto' : '48px 120px 160px 160px 160px auto'};
    position:relative;
    &:hover{background-color:#F1F4F8}
`; 

export const ActualsTableHeaderCell = styled.div`
    text-align:left;
    font-size:14px;
    color:#9D9D9D;
    padding:8px 8px;
`;

export const ActualsTableCell = styled.div`
    padding: 8px;
    text-align:left;
`
export const ActualsTableCellText = styled.div`
    font-size:16px;
    color:#3F3F45;
    &.transaction{
        color: #9e0101;
        &.green{
            color:#069406;
        }
    }
`

export const ActualsTablesActionsWrapper = styled.div`
    position:absolute;
    display:${props=>props.show? 'flex' : 'none'};
    right:0px;
    top:4px;
    ${ActualsTableRow}:hover &{
        display:flex;
    }
`;

export const TableActionButton = styled.div`
    background-color:#3F3F45;
    color:#fff;
    width: ${props=>props.isText ? 'auto' : '32px' };
    padding: ${props=>props.isText ? '0 32px' : '0' };
    height:32px;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor: pointer;
`;

export const ActualsCreateRow = styled.div`
    display: grid;
    grid-template-columns: ${props => props.isOpen ?  '120px 160px 160px 160px auto' : '1fr'};
    margin: 8px 8px 16px;
    text-align:left;
    padding:0;
`;

export const ActualsCreateCell = styled.div`
    padding-right: 8px;
`;
export const ActualsCreateCellActions = styled.div`
     display:flex;
     justify-content:flex-end;
`;


export const ActualsMenuWrapper = styled.div`
    width: 285px;
    background: #FFFFFF;
    box-shadow: 0px 0.2px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px #E5E9F2;
`;

export const ActualsMenuItem = styled.div`
    padding: 8px 12px;
    cursor: pointer;
    width: 100%;
    display: ${props=>props.isHidden ? 'none' : 'block'};
    text-align:left;
    &:hover{
        background-color:#ECF0F4;
    }
`;

export const ActualsMenuItemTitle = styled.div`
    color:#3F3F45;
    font-size:16px;
`;


export const ActualsMenuItemDescription = styled.div`
    color:#9D9D9D;
    font-size:14px;
`;

export const HeroOverviewWrapper = styled.div`
    display:flex;
    flex:1;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    position:relative;
`;

export const HeroCircleWrapper = styled.div`
    width:400px;
    position:relative;
    height:400px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:start;
`;

export const Circle = styled.div`
    // background-color:#000;
    //border:10px solid ${props=>props.color};    
    height:300px;
    /* border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%; */
    width:300px;
    margin-top: ${props => props.isLoginRequired ? '16' : '46'}px;
    display:flex;
    ${flexCenter};
    flex-direction:column;
    z-index:3;
`;

export const HeroWorthValue = styled.div`
    color: #fff;
    font-size:32px;
    // font-weight:700;
`;

export const HeroDescriptionBig = styled.div`
    color:#fff;
    font-weight:600;
    font-size:18px;
    line-height:20px;
`;

export const HeroDescriptionSmall = styled.div`
    display: flex;
    align-items: center;
    color: white;
    font-size:14px;
    gap: 8px;
    margin-top:8px;
`;



export const ValueChangesButton = styled.div`
    color: #9097A3;
    font-size:12px;
    svg {margin-left:8px}
    margin-top:8px;
    cursor: pointer;
    ${flexCenter};
    pointer-events:auto;
    svg {
        path {
            stroke:#9097A3;
        }
    }
    ${props=>props.isActive ? `
        background-color: #80808045;
    ` : `
    
    `}
`;
export const ValueChangeTextWrapper = styled.div`
    ${flexCenter};
`;

export const ValueChangeDirectionBox = styled.div`
    width:14px;
    height:14px;
    //${flexCenter};
    border-radius:1px;
    background-color:${props => props.isDown ? '#FF947C' : '#72E1AC'};
    position: relative;
    margin-right: 8px;
    svg{
        position:absolute;
        left:3px;
        top:3px;
        ${props => props.isDown ? `transform:rotate(90deg);top:4px` : ``}
    }
`;

export const ValueChangeText = styled.div`
    font-size:14px;
    /* margin-left:8px; */
    color:${props => props.change < 0 ? '#FF947C' : props.change > 0 ? '#72E1AC' : 'white'};
`;



export const ItemDoughnutChartWrapper = styled.div`
    position: absolute;
    z-index: 1;
    top: ${props => props.isLoginRequired ? '16' : '46'}px;
`;

export const ProviderLinkWrapper = styled.div`
    cursor: pointer;
    margin-top: 12px;
`;

export const TabTitleTransactionsCount = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:20px;
    font-size:12px;
    line-height: 16px;
    padding:2px 6px 2px 5px;
    color:#fff;
    text-align:center;
    min-width:22px;
    font-weight: 500;
    letter-spacing: 0px;
    background-color: #82AAFA;
    border: 1px solid #FFFFFF;
`;

export const TabTitlePassedEventsCount = styled(TabTitleTransactionsCount)`
    background-color: ${colors.redNotification};
    ${props => props.hasTrns ? `z-index:-1; margin-left:-4px;` : null}
`;

export const TabTitleVipSyncCount = styled(TabTitleTransactionsCount)`
    background-color: #DEB482;
`;

export const EventsTabIndicationsWrapper = styled.div`
    display: flex;
`;

export const MobilAssetActionsButtonWrapper = styled.div`
    width:36px;
    height:36px;
    ${flexCenter}
    border-radius: 12px;
    border: 1px solid ${colors.gray3};
    cursor: pointer;
`;

export const AssetClosedBoxWrapper = styled.div`
    border-radius: 10px;  
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    ${views.desktop}{
        width: calc(100% - 54px);  
        height: 260px;
        margin-left: 46px;
    }
    ${views.tablet}{
        flex:1;
        width: calc(100% - 96px);  
        padding: 60px;
    }
`;

export const AssetClosedTitle = styled.div`
    color:${colors.darkGray1};
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    ${views.desktop}{
        padding:0 16px;
    }
`;

export const AssetClosedDates = styled.div`
    color:${colors.darkGray2};
    font-size: 12px;
    font-weight: 450;
`;