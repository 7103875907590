import styled from "@emotion/styled/macro";
import { flexCenter, flexColumn } from "../../../../common/styles/combos.styles";
import { colors, shadows } from "../../../../common/styles/theme.styles";

export const DeepAnalysisSuccessModalContent = styled.div`
  ${flexColumn};
  justify-content: center;
  flex:1;
  width: 100%;
  border-top: 1px solid ${colors.lightGray2};
  border-bottom: 1px solid ${colors.lightGray2};
  padding: 40px 108px 48px 108px;

  .ant-input {
    border: 1px solid ${colors.gray3};
    border-radius: 8px;
  }

  .ant-input:focus, .ant-input-focused {
    border-color: ${colors.darkGray1};
    box-shadow: none;
  }

  overflow-y: auto;
`;

export const DeepAnalysisSuccessBody = styled.div`
  display: flex;
  width: 100%;
  padding: var(--font-line-height-lg, 40px);
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 16px;
`;

export const DeepAnalysisSuccessModalUpperSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const DeepAnalysisSuccessModalTextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
`;

export const DeepAnalysisSuccessModalTextAreaLabel = styled.div`
  color: var(--Dark-Gray-2, #585D66);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 22px;
`;

export const DeepAnalysisSuccessModalFooter = styled.div`
  ${flexCenter};
  justify-content: space-between;
  padding: 16px 24px;
`;

export const DeepAnalysisSuccessRowWrapper = styled.div`
  display: flex;
  padding: 0px var(--font-line-height-lg, 40px);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const DocumentRowWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  align-self: stretch;
  gap:8px;
  border-radius: 10px;
  border: 1px solid ${colors.gray3};
  background: $fff;
`;

export const DocumentIconWrapper = styled.div`
  width: 32px;
  height: 32px;
`;

export const DocumentDetailsWrapper = styled.div`
  ${flexColumn};
  flex:1;
  padding: 2.5px 0;
   ${props => props.overflowHidden && `
    overflow: hidden;
  `}
`;

export const DocumentTitle = styled.div`
  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 18px;
   ${props => props.overflowHidden && `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `}
`;

export const DocumentSize = styled(DocumentTitle)`
  color: ${colors.darkGray3};
  font-size: 12px;
  font-weight: 450;
  line-height: 15px;
`;

export const AIBetaTextWrapper = styled.div`
  min-height: 67.5px;
`;

export const AiBetaText = styled.span`
  color: #2A7BB5;
  font-family: Circular;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding-left: 6px;
`;

export const WhatKindDocumentWrapperHoverEffect = styled.div`
  pointer-events:none;
  flex-direction: column;
  position: absolute;
  border-radius: 8px;
  background-color: ${colors.darkGray1};
  color: #fff;
  line-height: 18px;
  bottom: -4px;
  right: 0;
  transform: translateY(100%);
  z-index: 1;
  box-shadow: ${shadows.popupShadow};
  // transition: all 0.2s;
  opacity:0;
  align-items: center;
  gap: 10px;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 450;
  width: 368px;
`;

export const WhatKindDocumentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${colors.darkGray1};
  text-align: center;
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  position: relative;
  :hover {
    ${WhatKindDocumentWrapperHoverEffect} {
      display: flex;
      z-index: 1;
      opacity:1;
      pointer-events:auto;
    }
  }
`;