import { MagicboxAIModalHeaderCloseIcon, MagicboxAIModalHeaderModalHeader, MagicboxAIModalHeaderModalTitle, MagicboxAIModalHeaderTextWrapper } from './MagicBoxAiActivationFlow.styles';

export const MagicboxAIModalHeader = ({ onClose }) => {

  return (
    <MagicboxAIModalHeaderModalHeader>
      <MagicboxAIModalHeaderTextWrapper>
        <MagicboxAIModalHeaderModalTitle>Magic Box AI</MagicboxAIModalHeaderModalTitle>
        <MagicboxAIModalHeaderCloseIcon onClick={onClose} />
      </MagicboxAIModalHeaderTextWrapper>
    </MagicboxAIModalHeaderModalHeader>
  );
}
