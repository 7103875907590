import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";
import { containerRow } from "../../common-fields/container-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { isRequired } from "../../input-validations";


export const creditCardAddItemSections = (isEditForm) => { 
    return ({updateField,updateValue}) => {
        const {NAME_LABEL, NAME_DESCRIPTION, TITLE_LABEL, VALUE_LABEL, VALUE_DESCRIPTION, TITLE_DESCRIPTION} = getTranslations().new_item_modal.credit_cards;
        return ([
            {
                title:'General info',
                rows:[
                    ...masterInstitutionInputRows(NAME_LABEL,NAME_DESCRIPTION, 1, false, updateValue),
                    {fields:[
                        {key:'title', label: TITLE_LABEL,validations:[isRequired], description:TITLE_DESCRIPTION}
                    ]},
                    beneficiaryRow({}),
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : []),
                    {fields:[
                        {key:'value', label: VALUE_LABEL, description: VALUE_DESCRIPTION ,validations:[isRequired],generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                        }},
                        {key:'currency',size:0.3,onChange:({value})=>{
                            updateField('value',{prefix:CURRENCY_SYMBOL[value] || ''});
                        }}
                    ]},
                ]
            },{
                title:`Anything more you'd like to add`,
                rows:[
                    ...(isEditForm ? [
                        containerRow({isEditForm, updateField}),
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),] : []),
                    {fields:[
                        {key:'remarks'}
                    ]}
                ]
            }
        ])
    }
}
