import { Observer } from 'mobx-react'
import React from 'react'
import { CloseXIcon, CommunityIcon } from '../../../../assets/icons/common/common-icons'
import { useStore } from '../../../app/data/root.store'
import { CloseXIconWrapper, CommunityBreadCrumbsHomeIconWrapper, CommunityBreadcrumbsWrapper, FilterTagTitle, FilterTagWrapper } from './CommunityBreadcrumbs.styles'

export const CommunityBreadcrumbs = (props) => {
  const { communityStore } = useStore();

  const handleHomeClick = () => {
    communityStore.setUnSelectedClass();
  }

  return (
    <Observer>{() => (
      <CommunityBreadcrumbsWrapper>
        <CommunityBreadCrumbsHomeIconWrapper onClick={handleHomeClick}>
          <CommunityIcon />
          Community /
        </CommunityBreadCrumbsHomeIconWrapper>
        <FilterTagWrapper tagColor={communityStore.selectedClass?.color}>
          <FilterTagTitle>{communityStore.selectedClass?.title}</FilterTagTitle>
          <CloseXIconWrapper onClick={handleHomeClick}><CloseXIcon /></CloseXIconWrapper>
        </FilterTagWrapper>
      </CommunityBreadcrumbsWrapper>
    )}</Observer>
  )
}