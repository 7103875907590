import { Observer } from 'mobx-react'
import React from 'react'
import { ModalBackDrop, ModalWrapper } from '../../../../common/components/BaseModal/BaseModal.styles'

export const OnBoardingTourModal = (props) => {
  const {onBackDropClick} = props;

  return (<Observer>{() => (
    <ModalBackDrop onClick={() => { onBackDropClick && onBackDropClick() }}>
      <ModalWrapper width={'auto'} height={'auto'} onClick={(e) => { e.stopPropagation() }}>
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/QfSEaGXIH1A`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </ModalWrapper>
    </ModalBackDrop>
  )}</Observer>)
}
