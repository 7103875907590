import React from 'react'
import { ClassPageTickersListViewEmptyStateText, ClassPageTickersListViewEmptyStateWrapper } from './ClassPage.styles'
import { ReactComponent as NoTickersIcon } from './icons/no-tickers-icon.svg';

export const ClassPageTickersListViewEmptyState = () => {
  return (
    <ClassPageTickersListViewEmptyStateWrapper>
      <NoTickersIcon />
      <ClassPageTickersListViewEmptyStateText>Stocks view isn't available</ClassPageTickersListViewEmptyStateText>
    </ClassPageTickersListViewEmptyStateWrapper>
  )
}
