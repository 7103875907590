import styled from "@emotion/styled/macro";
import { mobileVars } from "../../../../common/styles/theme.styles";
import { NavLink } from "react-router-dom"
import { flexCenter } from "../../../../common/styles/combos.styles";


export const FooterWrapper = styled.div`
    display: flex;
    // grid-area: header;
    align-items: center;
    justify-content: space-between;
    padding: 10px 8px;
    position:fixed;
    border-top:1px solid ${mobileVars.headerFooterBorderColor};
    background-color:${mobileVars.blackBackgroundColor};
    box-shadow: 0px -2px 15px 0px rgba(202, 198, 255, 0.20);
    // border-bottom: 1px solid #25272C;
    width: 100%;

    //justify-content: center;
    align-items: center;

    // height:${mobileVars.footerHeight};
    // min-height:${mobileVars.footerHeight};

    height: calc( env(safe-area-inset-bottom) + ${mobileVars.footerHeight} );
    min-height: calc( env(safe-area-inset-bottom) + ${mobileVars.footerHeight} );
    position: fixed;
    transition: bottom 0.3s ease-in;
    bottom:${props => props.isHidden ? `calc(0px - env(safe-area-inset-bottom) - ${mobileVars.footerHeight} )` : 0};
    // bottom:0;
    width:100%;
    z-index: 3;
    padding-bottom: calc( env(safe-area-inset-bottom) + 8px );
`;

export const MenuButtonWrapper = styled.div`
    color:#FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 64px;
    opacity: ${props=>props.isSelected ? 1 : 0.5};
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    padding: 2px 0;
`;

export const MenuLinkButtonWrapper = styled(NavLink)`
    color:#FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    padding: 2px 0;

    width: 64px;
    opacity:0.5;
    .normalIcon{
        display: block;
    }
    .selectedIcon{
        display: none;
    }
    &.active{
        .normalIcon{
            display: none;
        }
        .selectedIcon{
            display: block;
        }
    }
`;

export const AddButtonWrapper = styled.div`
    width:39px;
    height:39px;
    border:1px solid #fff;
    border-radius: 50%;
    ${flexCenter};
`;

export const MenuButtonText = styled.span`
    color:#EAF0F1;
    font-size: 13px;
    line-height: 13px;
    font-style: normal;
    font-weight: 450;
    // color:blue;
`;