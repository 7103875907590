import { ReactComponent as CsvIcon } from './csv-icon.svg'
import { ReactComponent as DocIcon } from './doc-icon.svg'
import { ReactComponent as DocxIcon } from './docx-icon.svg'
import { ReactComponent as JpgIcon } from './jpg-icon.svg'
import { ReactComponent as PdfIcon } from './pdf-icon.svg'
import { ReactComponent as PngIcon } from './png-icon.svg'
import { ReactComponent as PptIcon } from './ppt-icon.svg'
import { ReactComponent as PptxIcon } from './pptx-icon.svg'
import { ReactComponent as XslIcon } from './xls-icon.svg'
import { ReactComponent as XlsxIcon } from './xlsx-icon.svg'

export const docTypes = {
  'xls': <XslIcon />,
  'xlsx': <XlsxIcon />,
  'xlsm': <XlsxIcon />,
  'pdf': <PdfIcon />,
  'ppt': <PptIcon />,
  'pptx': <PptxIcon />,
  'doc': <DocIcon />,
  'docx': <DocxIcon />,
  'csv': <CsvIcon />,
  'jpg': <JpgIcon />,
  'jpeg': <JpgIcon />,
  'png': <PngIcon />
}