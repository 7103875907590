import { LoadingOutlined } from '@ant-design/icons';
import { signInWithPopup } from 'firebase/auth';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PasswordDisplayer } from '../../../../common/components/PasswordDisplayer/PasswordDisplayer';
import { postData } from '../../../../common/utils/network.utils';
import { useStore } from '../../../app/data/root.store';
import { auth, provider } from '../../../user/data/firebase.config';
import { AUTH_STEPS } from '../../../user/data/user.store';
// import GoogleLetterSign from '../../assets/google-letter-sign.png';
import { AppLoaderContainer, ContinueButton, FormMessage, GoogleSignInButton, HorizontalLine, HorizontalSeparatorWrapper, InputLabel, InputWrapper, LinkSpan, LoginForm, LoginInput, SignUpStepHeader, SignUpStepSubHeader, SignUpStepWrapper } from '../LoginPage.styles';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { capitalize, transformEmailForMixpanel } from '../../../../common/utils/string.utils';
import { ls } from '../../../../common/utils/localstorage.util';
import { AppLoader } from '../../../../common/components/Loaders/AppLoader';

export const InvitationStep = observer((props) => {
  const location = useLocation();
  const { userStore } = useStore()
  const { mixpanel, trackEvent, mixpanelReset } = useAnalytics()
  const [message, setMessage] = useState('');
  const [showPasswordEmptyError, setShowPasswordEmptyError] = useState(false);
  const [isWaitingForGoogleResponse, setIsWaitingForGoogleResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(true);

  const { email, password } = userStore.authFields; 
  const passwordInputRef = useRef();
  const du = parseInt(new URLSearchParams(location.search).get('du'));
  const [isUserExist, setIsUserExist] = useState(false);
  const [inviterName, setInviterName] = useState('');
  const [granteeName, setGranteeName] = useState('');

  

  useEffect(() => {
    const fetchData = async () => {
      const invToken = new URLSearchParams(location.search).get('token');
      if (invToken) {
        const data = await postData("authorization/validate",{token:invToken}).catch(e => {
          window.location.href='/';
        });
        setIsUserExist(data.isUserExist);
        userStore.setAuthFields('email', data.granteeEmail);
        userStore.setAuthFields('isInvitation', true);
        userStore.setAuthFields('invitationToken', invToken);
        setInviterName(data.ownerFirstName + ( data.ownerLastName ? ' ' + data.ownerLastName : ''));
        setGranteeName(capitalize(data.granteeName));
        ls.set('isAfterInvitation', 1);
        setIsValidating(false);
      } else {
        window.location.href='/';
      }
      
    };

    fetchData();
    
    return () => { }
  }, [ userStore, location]);

  

  const handleContinueWithGoogle = () => {
    
    setIsWaitingForGoogleResponse(true);
    signInWithPopup(auth, provider).then(data => {
      
      if (data.user.email !== email){  
        alert('You logged in with wrong account.')
        return;
      }
      
      const formData = {
        email: data.user.email,
        token: data.user.accessToken,
      }

      mixpanel.identify(transformEmailForMixpanel(formData.email));

      const urlParams = new URLSearchParams(location.search);
      const paramsToSend = {
        utm_source: urlParams.get('utm_source') || mixpanel.get_property('utm_source'),
        utm_medium: urlParams.get('utm_medium') || mixpanel.get_property('utm_medium'),
        utm_campaign: urlParams.get('utm_campaign') || mixpanel.get_property('utm_campaign'),
        utm_id: urlParams.get('utm_id') || mixpanel.get_property('utm_id'),
        distinct_id: urlParams.get('distinct_id') || mixpanel.get_distinct_id(),
      }

      const queryString = Object.keys(paramsToSend).filter(key => paramsToSend[key]).map(key => key + '=' + paramsToSend[key]).join('&');
      postData("user/invitation-register-with-google-auth" + (queryString ? ("?" + queryString) : ''), formData)
        .then(data => {
          window.localStorage.removeItem('mobileMessageSeen')

          if (data.success) {
            const { userId } = data;
            mixpanel.identify(userId.toString());
            window.location.reload();
          } else {
            setIsWaitingForGoogleResponse(false);
          }
        }, error => {
          mixpanelReset()
          setIsWaitingForGoogleResponse(false);
          console.error(error);
        });

    }, error => {
      mixpanelReset()
      setIsWaitingForGoogleResponse(false);
      console.error(error);
    }).finally(() => {
      trackEvent('CTA Clicked', {
        cta_text: "Continue with Google",
        location: "Main login page",
        destination: 'Google authentication page',
        type: "Button",
        url: window.location.href
      })
    })
  }

  const login = async () => {
    if (!isUserExist) {
      userStore.setAuthStep(AUTH_STEPS.SET_PASSWORD_STEP)
    } else {
      trackEvent('CTA Clicked', {
        cta_text: "Continue",
        location: "Main login page",
        destination: 'Code verification page',
        type: "Button",
        url: window.location.href
      });

      const formData = {
        email: email,
        password: password,
        du
      };

      if (formData.email && formData.password) {
        setIsLoading(true);
        try {
          const data = await postData("user/login", formData);
          //The change is related to the 'Enhance your experience...' screen that appears every time the user uses his phone.
          window.localStorage.removeItem('mobileMessageSeen')
          
          mixpanel.identify(transformEmailForMixpanel(formData.email));

          if (!data.statusCode) {
            window.localStorage.setItem('showNotifications', '1');

            if (['demo@vyzer.co'].includes(formData.email)) {
              window.location.reload();
            } else {
              userStore.setAuthStep(AUTH_STEPS.EMAIL_VERIFICATION_STEP);
              window.localStorage.setItem('wfc', '1');
              window.localStorage.setItem('regMail', formData.email);
            }
          }
        } catch (error) {
          if (error.message) {
            setMessage(Array.isArray(error.message) ? capitalizeTheFirstLetter(error.message) : error.message);
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        setMessage("Please fill password or continue with google");
        setShowPasswordEmptyError(true);
      }
    }
  };

  const capitalizeTheFirstLetter = (arrayOfStrings) => {
    return arrayOfStrings.map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' , ');
  }

  const handleEmailInputChange = (e) => {
    userStore.setAuthFields('email', e.target.value);
  }

  const handlePasswordInputChange = (value) => {
    if (showPasswordEmptyError){
      setShowPasswordEmptyError(false);
      setMessage("");
    }
    
    userStore.setAuthFields('password', value);
  }

  const handleForgotPassword = () => {
    trackEvent('CTA Clicked', {
      cta_text: "Forgot password?",
      location: "Main login page",
      destination: 'Forgot password page',
      type: "Hyperlink",
      url: window.location.href
    })
    userStore.setAuthFields('password', '');
    userStore.setAuthStep(AUTH_STEPS.FORGOT_PASSWORD_STEP);
  }

  const passKeyPress = (e) => {
    if (e.keyCode === 13) {
      login();
    }
  }

  return (
    isValidating ? <AppLoaderContainer><AppLoader mobileFixed /></AppLoaderContainer> : <>
      <SignUpStepWrapper>
        <div style={{marginBottom : '8px'}}>
          <LetterSvg  />
        </div>
        <SignUpStepHeader style={{textAlign:'center'}}>{granteeName}, You have new invitation</SignUpStepHeader>
        <SignUpStepSubHeader style={{margin : '0px -24px' , maxWidth : '400px', paddingBottom:'0'}}>
          {isUserExist ? `Log in to your account` : `Sign up`} to access {inviterName}'s Vyzer account</SignUpStepSubHeader>
        <GoogleSignInButton black onClick={handleContinueWithGoogle} isACtive={isWaitingForGoogleResponse} >{isWaitingForGoogleResponse ? <LoadingOutlined /> : <><GoogleWhiteSvg /> Continue with Google</>}</GoogleSignInButton>
        <HorizontalSeparatorWrapper><HorizontalLine />Or<HorizontalLine /></HorizontalSeparatorWrapper>
        <LoginForm>
          <InputWrapper>
            <InputLabel>Email</InputLabel>
            <LoginInput onChange={handleEmailInputChange} type="text" value={email} disabled name="email" />
          </InputWrapper>
          {isUserExist && 
          <InputWrapper isError={showPasswordEmptyError}>
            <InputLabel>Password</InputLabel>
            <PasswordDisplayer ref={passwordInputRef} passKeyPress={passKeyPress} onChange={handlePasswordInputChange} placeholder='Create a secure password' />
          </InputWrapper> }
          <FormMessage haveMessage={message !== ''}>
            {message}
          </FormMessage>
        </LoginForm>
        <ContinueButton white onClick={login}>{isLoading ? <LoadingOutlined /> : (isUserExist ? 'Continue' : 'Continue with email')}</ContinueButton>
        {isUserExist && <LinkSpan style={{ marginTop: '40px' }} type="link" onClick={handleForgotPassword}>Forgot password?</LinkSpan>}
      </SignUpStepWrapper>
    </>
  )
})

const LetterSvg = () => {
  return (<svg width="61" height="37" viewBox="0 0 61 37" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.5 15.5576C1.5 12.2439 4.18629 9.55762 7.5 9.55762H49.8529C53.1666 9.55762 55.8529 12.2439 55.8529 15.5576V29.4988C55.8529 32.8125 53.1666 35.4988 49.8529 35.4988H7.50001C4.1863 35.4988 1.5 32.8125 1.5 29.4988V15.5576Z" fill="white" stroke="#1A1B1D" strokeWidth="1.5" strokeLinejoin="round"/>
  <path d="M8.5 15.5L27.9476 25.2238C28.2953 25.3977 28.7047 25.3977 29.0524 25.2238L48.5 15.5" stroke="#1A1B1D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <circle cx="52.5" cy="11.5" r="7.5" fill="#2565E7" stroke="white" strokeWidth="2"/>
  </svg>
  )
}

const GoogleWhiteSvg = () => {
  return (
    <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.2222 4.25C21.4243 4.25 24.2934 5.37937 26.5628 7.22296L23.0717 10.6114C21.7348 9.6077 20.0633 9 18.2222 9C13.764 9 10.1591 12.4883 10.1591 16.8333C10.1591 21.1784 13.764 24.6667 18.2222 24.6667C21.9705 24.6667 24.8667 22.8075 25.5588 19.5097L25.6221 19.2083H25.3141H18.4722V14.75H30.3438C30.4959 15.4386 30.5934 16.1622 30.5934 16.8333C30.5934 24.8474 24.7102 29.4167 18.2222 29.4167C11.0209 29.4167 5.25 23.8049 5.25 16.8333C5.25 9.86172 11.0209 4.25 18.2222 4.25Z" fill="white" stroke="#1A1B1D" strokeWidth="0.5"/>
    </svg>
  ) 
}