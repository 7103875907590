import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../styles/combos.styles";
import { colors } from "../../../../styles/theme.styles";

export const AmortizationWrapper = styled.div`
  ${flexCenter};
  width: 100%;
  ${props=>props.withError ? `border-color: #FF7676;` : ``};
`;
export const EndDateHintWrapper = styled.div`
  ${flexCenter};
  position: absolute;
  right: 16px;
  top: 50%;
  font-size: 12px;
  transform: translateY(-50%);
  color: ${colors.darkGray3};
`;

export const AmountWrapper = styled.div`
  display: flex;
  flex: 0.7;
  position: relative;
`;

export const PeriodWrapper = styled.div`
  display: flex;
  flex: 0.3;
  ${props=>props.withError ? `border-right: none` : ``};
`;