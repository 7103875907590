import { observer } from 'mobx-react'
import { useStore } from "../../../app/data/root.store";
import { DeepAnalysisFailModal } from "../DeepAnalysisFailModal/DeepAnalysisFailModal";
import { DeepAnalysisReviewResultModal } from '../DeepAnalysisReviewResultModal/DeepAnalysisReviewResultModal';
import { ContinueDeepAnalysisResultModal } from '../ContinueDeepAnalysisResultModal/ContinueDeepAnalysisResultModal';
import { ManualAnalysisPendingResultModal } from '../ManualAnalysisPendingResultModal/ManualAnalysisPendingResultModal';

export const MagicBoxAiModals = observer(({ onFlowStepChange }) => {
    const { magicBoxAiStore, userStore } = useStore();
    const { file, submitted, fileForUpload } = magicBoxAiStore;

    if (fileForUpload?.errors) {
        return <DeepAnalysisFailModal />;
    }

    if (!file) {
        return null;
    }

    const { llmStatus, status } = file;
    const onDone = () => {
        if (userStore.isGetStarted) {
            if ('done' === file.llmStatus) {
                window.location.href = 'wealth';
            }
        } else {
            onFlowStepChange && onFlowStepChange(1);
        }
    };


    const goToManualResult = (('Processing' === status) && (('failed' === llmStatus) || ('timeout' === llmStatus)));


    return (
        <>
            {
                goToManualResult &&
                <ManualAnalysisPendingResultModal onClose={onDone} />
            }
            {
                (('done' === llmStatus) || (('confirmed' === llmStatus) && (userStore.isWelcome))) &&
                (
                    submitted ?
                        (<ContinueDeepAnalysisResultModal onClose={onDone} />) :
                        ((('Processing' === status)) && <DeepAnalysisReviewResultModal onFlowStepChange={onFlowStepChange} />)
                )
            }
        </>
    );
});
