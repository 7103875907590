import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors, shadows, views } from "../../../../common/styles/theme.styles";
import bgImg from '../../images/welcome-modal-bg.png';

export const ModalBgWrapper = styled.div`
  background: url(${bgImg}) no-repeat;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  display:flex;
  flex-direction:column;
  align-items:center;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 24px 0;
`;

export const ModalContent = styled.div`
  ${flexCenter};
  flex-direction: column;
  padding: 0 86px;
  /* background-color: #F7F8F9; */
  ${views.tablet}{
    padding:0 24px;
  }
`;

export const ModalTitle = styled.div`
  color: ${colors.darkGray1};
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
`;

export const ModalPromptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  padding-top: 24px;
  padding-bottom: 32px;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  white-space: pre-line;
`;

export const ModalPromptRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ModalFooter = styled.div`
  ${flexCenter};
  flex-direction: column;
  ${views.tablet}{
    padding-bottom:24px;
  }
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props=>props.black ? colors.darkGray1 : '#fff'} ;
  box-shadow: ${shadows.toggleShadow};
  border-radius: 8px;
  padding:8px 56px;
  font-size:14px;
  color: ${props=> props.black ? '#fff' : '#000' };
  cursor: pointer;
  text-align:center;
  transition:all 0.2s;
  flex: 1;
  ${props => props.flexGrow ? `flex-grow: ${props.flexGrow}` : null};
  ${props => props.nowrap ? `white-space: nowrap` : null};
  svg{
    margin-left:8px;
  }
`;