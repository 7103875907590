import React from 'react';
import { Observer } from 'mobx-react';
import { DemoModeBanner } from '../../../layout/components/Banner/DemoModeBanner';

export const DemoAccountStep = (props) => {

  return (<Observer>{() => (
    <>
      <DemoModeBanner />
    </>
  )}</Observer>)
}