import { makeAutoObservable } from "mobx";
import { errorNotification } from "../../layout/components/Notifications/notifications";
import { getReportByType, getReports } from "./reports.service";

export class ReportsStore {
  data = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchData = async () => {
    this.setIsLoading(true);
    try {
      const result = await getReports();
      this.setData(result);
    } catch (error) {
      errorNotification('Failed to fetch reports');
    }
    finally {
      this.setIsLoading(false);
    }
  }

  setData = (data) => {
    this.data = data;
  }

  getReportByType = async (reportType) => {
    // this.setIsLoading(true);
    try {
      const result = await getReportByType({type: reportType});
      this.setData([result]);
    } catch (error) {
      errorNotification('Failed to fetch reports');
    }
    finally {
      // this.setIsLoading(false);
    }
  }

  setIsLoading = (loading) => {
    this.isLoading = loading;
  }

}