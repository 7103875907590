import { useEffect, useState } from 'react'
import { useStore } from '../../app/data/root.store';

//export const useFormHook = (formName,callerName = '') => {
export const useFormHook = (formName) => {
    const [formState, setFormState] = useState();
    const {formsStore} = useStore();
    const currentFormPointer = formsStore.forms[formName];
    
    useEffect(() => {
    
        if (formsStore.forms[formName]){
            // console.log("Setting state", callerName , formsStore.forms[formName])
            setFormState(formsStore.forms[formName]);
        } else {
            // console.log("Setting reg fn" , callerName);
            formsStore.setRegFn(formName, (registeredForm) => {
                // console.log("Reg fn" , callerName);
                setFormState(registeredForm);
            })
            formsStore.setUnRegFn(formName, () => {
                // console.log("Un Reg fn" , callerName);
                setFormState({});
            })
        }
        return () => {          
            // cleanup
        }
    }, [formsStore, currentFormPointer, formName ])
    //}, [formsStore, currentFormPointer, formName , callerName])
  
    return formState;
}
