import React from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../../app/data/root.store';
import { CategoriesCardContent, CategoriesCardHeader, CategoryItem, ClassButton, ClassCategoriesButtonText, ClassCategoriesCardWrapper, ClassesListWrapper, ClassItemWrapper, ClassRowCategoriesButton, ClassRowWrapper, SelectedCategoriesCount } from './AssetClassesParameterFilter.styles';
import { ArrowDownIcon } from '../../../../../assets/icons/common/common-icons';
import { Checkbox } from 'antd';


export const AssetClassesParameterFilter = () => {
    const { filtersStore } = useStore();
    return (<Observer>{() => (
        <ClassesListWrapper>
             {filtersStore.classes.map((cls)=>(
                <ClassItemWrapper key={cls.id}>
                <ClassRowWrapper >
                    <ClassButton 
                    onClick={()=>{filtersStore.handleSelectClass(cls.id)}}
                    isSelected={cls.isSelected} color={cls.color}>{cls.title}</ClassButton>
                    <ClassRowCategoriesButton  onClick={()=>{filtersStore.handleOpenCloseClass(cls.id)}}>
                        {cls.isSelected && cls.categories.length > 1 && <>
                            {!cls.isOpen && cls.isAllCategoriesSelected && <ClassCategoriesButtonText>Categories</ClassCategoriesButtonText>}
                            {!cls.isAllCategoriesSelected && (cls.selectedCategoriesCount > 0) && <SelectedCategoriesCount>{cls.selectedCategoriesCount}</SelectedCategoriesCount>}
                            <ArrowDownIcon style={{ transition: '0.2s', transform: ` rotate(${cls.isOpen ? -180 : 0}deg)` }} />
                        </>}
                    </ClassRowCategoriesButton>
                </ClassRowWrapper>
                {cls.isOpen && <ClassCategoriesCardWrapper>
                    <CategoriesCardHeader>
                        <Checkbox checked={cls.isAllCategoriesSelected} onChange={()=>{filtersStore.handleSelectAllCategories(cls.id,cls.isAllCategoriesSelected)}}>Categories</Checkbox>
                    </CategoriesCardHeader>
                    <CategoriesCardContent>
                        {cls.categories.map(category=>(
                            <CategoryItem key={category.id}
                                // onClick={}
                                >
                                     <Checkbox checked={category.isSelected} 
                                     onChange={()=>{filtersStore.handleSelectCategory(category.id)}} >{category.title}</Checkbox>
                            </CategoryItem>
                        ))}
                    </CategoriesCardContent>
                </ClassCategoriesCardWrapper>}
                </ClassItemWrapper>
             ))}
            
        </ClassesListWrapper>
    )}</Observer>)
}
