import styled from '@emotion/styled/macro';
import { Input } from "antd";
import { InputWrapper as FDIW , RowWrapper } from '../../../../common/components/form-displayer/FormDisplayer.styles';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors } from '../../../../common/styles/theme.styles';

export const FormWrapper = styled.div`
    padding: 32px 0 4px 0;
    display:flex;
    flex-direction:column;
`

export const SimulationRowWrapper = styled(RowWrapper)`
    margin-bottom:16px;
`;

export const InputWrapper = styled(FDIW)`
    display:flex;
    flex-direction:column;
    margin-bottom:16px;
`;

export const InputLabel = styled.div`
    color:#272D33;
    font-size:14px;
    font-weight:500;
`;

export const OptionalText = styled.span`
    color: ${colors.gray1};
    font-size: 12px;
    line-height: 14px;
    margin-left: 4px;
`;

export const BigNoBorderInput = styled(Input)`
    border:none;
    font-size:24px;
    padding:4px 0;
    height: 44px;
    ::placeholder{
        color: #9EA4AC;
    }
  
`

export const InputsWrapper = styled.div`
    flex:1;
    display:flex;
    flex-direction:row;
    align-items:center;
`;

export const IncomeExpenseButtonsWrapper = styled.div`
    flex:1;
    display:flex;
    flex-direction:row;
    margin:0 -8px 16px;
`;

export const IncomeExpenseButton = styled.div`
    cursor: pointer;
    height:40px;
    flex:1;
    margin:0 8px;
    border: 1px solid #C1C9D0;
    font-size:14px;
    font-weight:500;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:${props=>props.isSelected ? '#272D33' : '#fff'};
    color:${props=>props.isSelected ? '#fff' : '#272D33'};
    transition: all 0.2s;
    .button-text{
        padding-left:8px;
    }
    ${props=>props.disabled ? `
        opacity: 0.4;
        cursor: not-allowed;
    ` : ``}
`;

export const DatapointTransactionsListItem = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    font-size:14px;
    padding: 4px 0;
    &:not(:last-child){
        border-bottom: 1px solid #e0e0e0;
    }
`;

export const ItemDescription = styled.div`
    max-width:240px;
    padding-right:16px;
`;

export const ItemValue = styled.div`
    padding:2px;
    background-color:${props=>props.isPositive ? '#E4FDE6' : '#FDE4E4'};
    color:${props=>props.isPositive ? '#3AD249' : '#EF6262'};
`;

export const GroupDate = styled.div`
    color: #808080;
`

export const ActionsWrapper = styled.div`
    ${flexCenter};
    margin-top:16px;
`;

export const AddSaveButton = styled.div`
    //background-color:green;
    background:#000;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    color:#fff; 
    padding: 12px 16px;
    ${flexCenter};
    display:inline-flex;
    cursor: pointer;
    svg{
        margin-right:8px;
    }
`

export const ValidationError = styled.div`
    color:#FF7676;
    font-size:12px;
    height: 22px;
    line-height: 22px;
`;