import { Drawer, Input } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import NumberFormat from 'react-number-format';
import { DeleteIcon, PlusIcon } from '../../../../../assets/icons/common/common-icons';
import { fetchTickerRealTimePrice, searchTickers } from '../../../../../modules/add-item/data/add-item.service';
import { defaultTickers } from '../../../../../modules/add-item/data/create-item-tickers.const';
import { errorNotification, successNotification } from '../../../../../modules/layout/components/Notifications/notifications';
import { displayMoneyValue } from '../../../../utils/number-display.utils';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../../utils/object.utils';
import { LogoLoader } from '../../../LogoLoader/LogoLoader';
import { TickerAutoCompleteResultRow } from './TickerAutoCompleteResultRow';
import { ActionButtonWrapper, ArrowUpSvgWrapper, CloseButtonWrapper, CreateButton, DrawerHeaderTitle, DrawerInputWrapper, HeaderCell, ItemLogoWrapper, ListAndLoaderWrapper, LogoPlaceHolder, NumberOfSharesHeaderCell, QuantityBelowNameWrapper, QuantitySuffixWrapper, RowActionsCell, RowCell, RowWrapper, SearchInput, SearchInputAndResultsWrapper, SearchTickersMobileHeader, SearchTickersMobileHeaderTitleAndClose, SearchTickersMobileWrapper, SearchingLoaderWrapper, SelectedTickerTextOverflow, SelectedTickerTextWrapper, SelectedTickerWrapper, TableBottomActions, TableHeaderRow, TableScrollContent, TickerAutoCompleteWrapper, TickerResultsListWrapper, Wrapper } from './TickerTradingTable.styles';


const inputSearchDebounce = debounce((cb) => { cb() }, 1000);

export const TickerTradingTable = ({ onChange, value, withError, isDesktopView, currency }) => {
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [shouldFetchPrices, setShouldFetchPrices] = useState(true);

  

useEffect(() => {
  const selected = value.filter(row => !isNullOrUndefined(row.selectedObject))
    .map(row => {
      const { symbol, name, exchange, instrument_type } = row.selectedObject;
      return `${symbol}_${name}_${exchange}_${instrument_type}`;
    });

  setSelectedTickers(selected.length ? selected : []);
}, [value]);


useEffect(() => {
  let mounted = true;
  
  const fetchPrices = async () =>{
    const pricesPromises = value.map(row => !isNullOrUndefined(row.selectedObject) ? fetchTickerRealTimePrice(row.selectedObject.symbol, row.selectedObject.exchange,  row.selectedObject.currency, currency) : null );
    const pricesResponse = await Promise.allSettled(pricesPromises);
    
    if (mounted) {
      let updatedSortedSchedule = value.map((item, index) =>  ({
        ...item,
        pricePerShare:pricesResponse[index]?.value?.itemCurrencyPrice,
        symbolCurrencyPrice: pricesResponse[index]?.value?.symbolCurrencyPrice
       }));
      setShouldFetchPrices(false);
      onChange?.(updatedSortedSchedule);
    }
  }
  if (shouldFetchPrices){
    fetchPrices();  
  }
  return () => mounted = false; 

}, [value,currency,onChange,shouldFetchPrices]);

  const handleAddRowClick = () => {
    const maxValueOfTempId = Math.max(...value.map(u => u.tempId || 0), 0);
    const newRow = {
      searchStr: '',
      quantity: '',
      selectedObject: null,
      pricePerShare: '',
      tempId: maxValueOfTempId + 1,
    }
    onChange?.([...value, newRow]);
  }

  const handleAttributeRowUpdated = (updatedIndex, data) => {
    let updatedSortedSchedule = value.map((item, index) => (index === updatedIndex ? data : item));
    onChange?.(updatedSortedSchedule);
  }

  const handleAttributeRowDeleted = (deletedIndex) => {
    const updatedSchedule = value.filter((item, index) => index !== deletedIndex);
    onChange?.(updatedSchedule);
  }

  return (
    <Wrapper>
      <TableHeaderRow onClick={(e) => { e.stopPropagation() }}>
        <HeaderCell>
          <span>Ticker name</span>
        </HeaderCell>
        <NumberOfSharesHeaderCell>
          <span>Current number of shares</span>
        </NumberOfSharesHeaderCell>
      </TableHeaderRow>
      <TableScrollContent>
        {value?.map((row, index) => (
          <HistoryRow key={row.id || ('t' + row.tempId)}
            index={index}
            item={row} atrKey={row.atrKey}
            isDesktopView={isDesktopView}
            withError={withError}
            selectedTickers={selectedTickers}
            currency={currency}
            rowUpdated={(data) => { handleAttributeRowUpdated(index, data) }}
            rowDeleted={() => handleAttributeRowDeleted(index)} />
        ))}
      </TableScrollContent>
      <TableBottomActions>
        <CreateButton onClick={() => { handleAddRowClick(value) }}>
          <PlusIcon />
          <span className='button-text'>Add ticker</span>
        </CreateButton>
      </TableBottomActions>
      {/* <div style={{ marginTop: 'auto' }}>*You can add historical information, including purchases & sales, cost basis, etc. later.</div> */}
    </Wrapper>
  )
}

const HistoryRow = ({ item, isDesktopView, rowDeleted, withError, rowUpdated, selectedTickers , currency }) => {
  const handleFieldChange = (field, value) => {
    rowUpdated?.({
      ...item,
      [field]: value
    })
  }

  const handleFieldBlur = (field) => {
    // const watchedFieldsArray = [item.quantity, item.searchStr];
    // if (watchedFieldsArray.filter(i => i).length === 2) {
    //   let change = {};
    //   if (field !== 'cost' && !item.cost) {
    //     change.cost = Math.abs(item.quantity) * item.pricePerCoin;
    //   }
    //   else if (field !== 'quantity' && !item.quantity) {
    //     change.quantity = item.cost / item.pricePerCoin;
    //   }
    //   else if (field !== 'pricePerCoin' && !item.pricePerCoin) {
    //     change.pricePerCoin = item.cost / Math.abs(item.quantity);
    //   }
    //   rowUpdated?.({
    //     ...item,
    //     ...change
    //   });
    // }
  }

  const [isSearchingTickers, setIsSearchingTickers] = useState(false);
  const [tickerSearchResults, setTickerSearchResults] = useState([...defaultTickers]);
  const [isFocusingInput, setIsFocusingInput] = useState(false);
  const [inputPosition , setInputPosition] = useState({x:0,y:0});
  const [isSelectTickerDrawerOpen,setIsSelectTickerDrawerOpen] = useState(false)

  const listRef = useRef();
  const searchInputRef = useRef();

  const searchForTickers = async (str) => {
    if (str === '') {
      setTickerSearchResults([]);
      setIsSearchingTickers(false);
    } else {
      setIsSearchingTickers(true);
      try {
        const data = await searchTickers(str);
        setTickerSearchResults([...data]);
      }
      //catch (e) {} 
      finally {
        setIsSearchingTickers(false);
      }
    }
  }

  const handleChange = (e) => {
    rowUpdated?.({
      ...item,
      searchStr: e.target.value,
      selectedObject: null,
    })
    setIsSearchingTickers(true);
    inputSearchDebounce(() => {
      searchForTickers(e.target.value);
    })
  }

  const handleFocusIn = () => {
    if (item.searchStr === '') {
      setTickerSearchResults([...defaultTickers]);
    }
    // get input position for portal
    // console.log(searchInputRef.current);
    setTimeout(() => {
      if (searchInputRef.current){
        const inputPos = searchInputRef.current.getBoundingClientRect();
        // console.log(inputPos);
        setInputPosition({x:inputPos.x,y:inputPos.y + 48})
      }
    }, 0);
    setIsFocusingInput(true);
    setIsSelectTickerDrawerOpen(true);
  }

  const handleBlur = (field) => {
    if (isDesktopView){
      setIsFocusingInput(false);
      setIsSelectTickerDrawerOpen(false);
    }
  }

  const handleCloseDrawer = () => {
      setIsFocusingInput(false);
      setIsSelectTickerDrawerOpen(false);
  }

  const handleTickerClick = async (ticker) => {
    let currencyValue;
    try {
      currencyValue = await fetchTickerRealTimePrice(ticker.symbol, ticker.exchange, ticker.currency, currency);
      successNotification('Successfully fetched usd value');
    } catch (error) {
      errorNotification('Failed fetching usd value');
    }
    rowUpdated?.({
      ...item,
      selectedObject: ticker,
      openOnLoad: false,
      pricePerShare: currencyValue.itemCurrencyPrice,
      symbolCurrencyPrice: currencyValue.symbolCurrencyPrice
    })
    setIsFocusingInput(false);
    setIsSelectTickerDrawerOpen(false);
  }

  return (
    <RowWrapper onClick={(e) => { e.stopPropagation() }}>
      <RowCell style={{ position: 'relative' }}>
        <TickerAutoCompleteWrapper>
          <SearchInputAndResultsWrapper ref={searchInputRef}>

            <SearchInput type="text"
              className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.searchStr) ? ' inputEntryError' : ''}`}
              style={{ borderRadius: '8px 0 0 8px' }}
              value={item?.searchStr}
              onChange={handleChange}
              onFocus={handleFocusIn}
              autoFocus={isDesktopView && item?.openOnLoad}
              onBlur={handleBlur}
              placeholder='Search stocks, bond, ETFs etc.'
            />

            { ( isFocusingInput || isSelectTickerDrawerOpen) && (tickerSearchResults.length > 0 || isSearchingTickers) &&
              <>
              { isDesktopView && createPortal(
                <ListAndLoaderWrapper left={inputPosition.x} top={inputPosition.y}>
                  <TickerResultsListWrapper ref={listRef}>
                    {tickerSearchResults.map((ticker, ind) => {
                      const { symbol, name, exchange, instrument_type } = ticker;
                      const isTickerSelected = selectedTickers.includes(`${symbol}_${name}_${exchange}_${instrument_type}`);
                      return <TickerAutoCompleteResultRow key={ind} searchStr={item?.searchStr} ticker={ticker} isSeparator={ticker.isSeparator} handleItemClick={handleTickerClick} isTickerSelected={isTickerSelected} />
                    })}
                  </TickerResultsListWrapper>
                  {isSearchingTickers && <SearchingLoaderWrapper> <LogoLoader wbg /> </SearchingLoaderWrapper>}
                </ListAndLoaderWrapper>,document.getElementById('root')) 
        
                }
                <ArrowUpSvgWrapper><ArrowUpSvg /></ArrowUpSvgWrapper>
              </>
            }

            { !isDesktopView && 
                      <Drawer 
                      placement="left" className={'tickersSearchDrawerWrapper_mobile'} 
                       width={'100%'}
                       height={'100%'}
                       visible={ isSelectTickerDrawerOpen} 
                       closable={false}
                       onClose={()=>{handleCloseDrawer()}} >
                        <SearchTickersMobileWrapper>
                          {isSelectTickerDrawerOpen && <SearchTickersMobileHeader>
                              <SearchTickersMobileHeaderTitleAndClose>
                              <CloseButtonWrapper onClick={()=>{handleCloseDrawer()}}>
                              <BackArrowIcon />
                          </CloseButtonWrapper>
                                  <DrawerHeaderTitle>Add a Holding</DrawerHeaderTitle>
                                  
      
                              </SearchTickersMobileHeaderTitleAndClose>
      
                                <DrawerInputWrapper >
      
                                <SearchInput type="text"
                    
                    className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.searchStr) ? ' inputEntryError' : ''}`}
                     style={{ borderRadius: '0px 0 0 0px' }}
                    value={item?.searchStr}
                    onChange={handleChange}
                    
                    autoFocus={true}
                    // onBlur={() => setIsFocusingInput(false)}
                    placeholder='Search stocks, bond, ETFs etc.'
                  />
      
                                </DrawerInputWrapper>
                          </SearchTickersMobileHeader>} 
              
                        {/* <TickerResultsListWrapper ref={listRef}> */}
                          {tickerSearchResults.map((ticker, ind) => {
                            const { symbol, name, exchange, instrument_type } = ticker;
                            const isTickerSelected = selectedTickers.includes(`${symbol}_${name}_${exchange}_${instrument_type}`);
                            return <TickerAutoCompleteResultRow key={ind} searchStr={item?.searchStr} ticker={ticker} isSeparator={ticker.isSeparator} handleItemClick={handleTickerClick} isTickerSelected={isTickerSelected} />
                          })}
                        {/* </TickerResultsListWrapper> */}
                        </SearchTickersMobileWrapper>
                        {isSearchingTickers && <SearchingLoaderWrapper> <LogoLoader wbg /> </SearchingLoaderWrapper>}
                      
                      </Drawer>
            }

            {!isFocusingInput && item?.selectedObject ? <SelectedTickerWrapper>
              <div style={{ paddingRight: '8px' }}>
                {item.selectedObject.imageUrl ? <ItemLogoWrapper><img alt={item.symbol} src={'data:image/png;base64,' + item.selectedObject.imageUrl} /></ItemLogoWrapper> :
                item.selectedObject.logoUrl ? <ItemLogoWrapper><img alt={item.symbol} src={item.selectedObject.logoUrl} /></ItemLogoWrapper> :
                  <LogoPlaceHolder>
                    {(item.selectedObject.name && item.selectedObject.name !== '') ? item.selectedObject.name.charAt(0).toUpperCase() : (item.selectedObject.symbol && item.selectedObject.symbol !== '') ? item.selectedObject.symbol.charAt(0).toUpperCase() : (item.selectedObject.type && item.selectedObject.type !== '') ? item.selectedObject.type.charAt(0).toUpperCase() : ''}
                  </LogoPlaceHolder>
                }
              </div>
              <SelectedTickerTextWrapper>
                <SelectedTickerTextOverflow>{item.selectedObject.symbol} , {item.selectedObject.name}</SelectedTickerTextOverflow>
                {item?.pricePerShare && !isFocusingInput && !isDesktopView ? <QuantityBelowNameWrapper>{displayMoneyValue(parseFloat((+item?.pricePerShare).toFixed(2)), currency)}</QuantityBelowNameWrapper> : null}
              </SelectedTickerTextWrapper>
            </SelectedTickerWrapper> : null}

          </SearchInputAndResultsWrapper>
        </TickerAutoCompleteWrapper>
        {item?.pricePerShare && !isFocusingInput && isDesktopView ?
          <QuantitySuffixWrapper>
            <div>{displayMoneyValue(parseFloat((+item?.pricePerShare).toFixed(2)), currency)}</div>
          </QuantitySuffixWrapper>
          : null}
      </RowCell>
      <RowCell style={{ position: 'relative' }}>
        <NumberFormat
          thousandSeparator={true}
          customInput={Input}
          className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.quantity) ? ' inputEntryError' : ''}`}
          allowNegative={false}
          style={{ borderRadius: '0 8px 8px 0' }}
          value={(isNullOrUndefinedOrEmptyString(item.quantity)) ? '' : parseFloat((+item?.quantity).toFixed(4))}
          onBlur={() => { handleFieldBlur('quantity') }}
          onValueChange={(values) => { handleFieldChange('quantity', values.floatValue) }}
          placeholder='Quantity'
          required={true}
        />
        {item?.pricePerShare && item.quantity ?
          <QuantitySuffixWrapper>
            <div>{displayMoneyValue(parseFloat((+item.quantity * item?.pricePerShare).toFixed(2)), currency)}</div>
          </QuantitySuffixWrapper>
          : null}
      </RowCell>
      <RowActionsCell>
        <ActionButtonWrapper
          onClick={rowDeleted} >
          <DeleteIcon />
        </ActionButtonWrapper>
      </RowActionsCell>
    </RowWrapper>
  )
}

const ArrowUpSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.9867 13.6807L12.0004 10.3473L16.014 13.6807" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}

const BackArrowIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6667 2.6665L5.33341 7.99984L10.6667 13.3332" stroke="#9097A3" strokeWidth="1.6" />
    </svg>
  )
}