import { Dropdown  } from 'antd';
import { Observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowDownIcon } from '../../../assets/icons/common/common-icons';
import { AssetClassIcon } from '../../../modules/assets/components/AssetClassIcon';
import { ArrowDownWrapper, CategoryCheckMarkSvgWrapper, CategorySelectorActionMenuContent, CategorySelectorActionMenuItem, CategorySelectorActionMenuItemContentWrapper, CategorySelectorActionMenuItemTitleWrapper, CategorySelectorActionMenuWrapper, CategorySelectorSkeleton, CategorySelectorTriggerButton, CategoryTitle, CheckMarkSvgWrapper, ClassArrowDownWrapper, ClassCategoryTitlesWrapper, ClassIconWrapper, ClassIconWrapperMenuItem, ClassTitle, HiddenCategoriesList, HiddenCategoryMenuItem, HiddenCategoryMenuItemTitle, IgnoreClassSvgWrapper, MultipleCategoriesSelectorActionMenuItemWrapper } from './CategorySelector.styles';

export const CategorySelector = ({ value, options, onCategoryChange, disabled, withIgnore = false, withError = false, showSkeleton }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleSetItem = (cls, cat) => {
    setIsOpen(false);
    onCategoryChange?.(cls.id, cat === -1 ? -1 : cat.id);
  }

  useEffect(() => {
    if (value === -1) { // value === -1 => ignored
      setSelectedCategory(null);
      setSelectedMenuItem(null);
    } else if (value !== null) { // value === null => placeholder
      const relevantClass = options?.find(opt =>
        opt.categories.some(category => category.id === value)
      );
      const relevantCategory = relevantClass?.categories.find(category => category.id === value);
      setSelectedMenuItem(relevantClass || null);
      setSelectedCategory(relevantCategory || null);
    }
  }, [value, options]);

  return (<Observer>{() => (
    <Dropdown
      className='formDisplayerDefaultSelect'
      trigger={['click']}
      overlay={CategorySelectorActionMenu({
        options,
        ...(selectedMenuItem !== null ? { selectedMenuItem, selectedCategory } : {}),
        withIgnore,
        isOpen,
        isIgnored: value === -1,
        setItem: handleSetItem,
      })}
      onVisibleChange={(open) => { setIsOpen(open) }}
      disabled={disabled}
    >
      <CategorySelectorTriggerButton isOpen={isOpen} withError={value === null && withError}>
        <CategorySelectorSkeleton active={true} loading={showSkeleton} paragraph={{ rows: 1 }} avatar={{active: true, shape: 'square', size: 26}}>
          {value === -1 ? <IgnoreClassSvgWrapper><IgnoreClassSvg /></IgnoreClassSvgWrapper> : selectedMenuItem !== null ? <ClassIconWrapper bgColor={selectedMenuItem?.color}><AssetClassIcon name={selectedMenuItem?.icon} /></ClassIconWrapper> : null}
          <ClassCategoryTitlesWrapper>
            <ClassTitle withError={value === null && withError}>{value === null ? 'Please select a category' : selectedMenuItem?.title}</ClassTitle>
            {value === -1 ? 'Ignore this account' : value !== null ? <CategoryTitle>{selectedCategory?.title}</CategoryTitle> : null}
          </ClassCategoryTitlesWrapper>
          {!disabled ? <ArrowDownWrapper isOpen={isOpen}><ArrowDownIcon /></ArrowDownWrapper> : null}
        </CategorySelectorSkeleton>
      </CategorySelectorTriggerButton>
    </Dropdown>
  )}</Observer>)
}

function CategorySelectorActionMenu({ options, selectedMenuItem, selectedCategory, setItem, isIgnored, withIgnore, isOpen }) {
  const [openClassIndex, setOpenClassIndex] = useState(-1);
  const menuRef = useRef(null);


  useEffect(() => {
    setOpenClassIndex(selectedMenuItem?.id || -1);
  }, [selectedMenuItem]);

  useEffect(() => {
    if (menuRef.current) {
      if (isOpen) {
        setTimeout(() => {
          const menuPos = menuRef.current.getBoundingClientRect();
          if (menuPos.bottom > window.innerHeight) {
            menuRef.current.style.maxHeight = (window.innerHeight - menuPos.top - 10) + 'px';
          }
        }, 100)
      } else {
        menuRef.current.style.maxHeight = '';
      }
    }

  }, [isOpen]);

  const handleSetCategory = (cls, cat) => {
    setItem(cls, cat);
    setOpenClassIndex(cls.id);
  }

  const handleMultipleCategoryToggle = (e, cls) => {
    e.stopPropagation();
    setOpenClassIndex(cls.id !== openClassIndex ? cls.id : -1);
  }

  const handleSetSingleCategoryItem = (cls) => {
    setItem(cls, cls.categories[0]);
    setOpenClassIndex(cls.id);
  }

  const handleIgnored = () => {
    setOpenClassIndex(-1);
    setItem(openClassIndex, -1);
  }


  return (
    <CategorySelectorActionMenuWrapper >
      <CategorySelectorActionMenuContent ref={menuRef}>
        {options?.map((cls, index) => {
          const { isSingleCategory, categories } = cls;
          return (

            isSingleCategory ?
              <CategorySelectorActionMenuItem selectedColor={openClassIndex === cls.id ? cls.color : '#fff'} onClick={() => { handleSetSingleCategoryItem(cls) }} key={index}>
                <ClassIconWrapperMenuItem selectedColor={openClassIndex === cls.id ? cls.color : ''}><AssetClassIcon name={cls.icon} /></ClassIconWrapperMenuItem>
                <CategorySelectorActionMenuItemContentWrapper>
                  <CategorySelectorActionMenuItemTitleWrapper isSelected={openClassIndex === cls.id}>{cls.title}{cls.isLiability ? ' (Liability)' : ''}</CategorySelectorActionMenuItemTitleWrapper>
                </CategorySelectorActionMenuItemContentWrapper>
                {openClassIndex === cls.id ? <CheckMarkSvgWrapper><CheckMarkSvg /></CheckMarkSvgWrapper> : null}
              </CategorySelectorActionMenuItem>

              :

              <MultipleCategoriesSelectorActionMenuItemWrapper key={index}>
                <CategorySelectorActionMenuItem selectedColor={openClassIndex === cls.id ? cls.color : '#fff'} onClick={(e) => { handleMultipleCategoryToggle(e, cls) }}>
                  <ClassIconWrapperMenuItem selectedColor={openClassIndex === cls.id ? cls.color : ''}><AssetClassIcon name={cls.icon} /></ClassIconWrapperMenuItem>
                  <CategorySelectorActionMenuItemContentWrapper>
                    <CategorySelectorActionMenuItemTitleWrapper isSelected={openClassIndex === cls.id}>{cls.title}{cls.isLiability ? ' (Liability)' : ''}</CategorySelectorActionMenuItemTitleWrapper>
                  </CategorySelectorActionMenuItemContentWrapper>
                  <ClassArrowDownWrapper isOpen={openClassIndex === cls.id}><ArrowDownIcon /></ClassArrowDownWrapper>
                </CategorySelectorActionMenuItem>
                {openClassIndex === cls.id ? <HiddenCategoriesList>
                  {categories.map((cat, ind) => (
                    <HiddenCategoryMenuItem isSelected={cat.id === selectedCategory?.id} onClick={() => { handleSetCategory(cls, cat); }} key={'' + cat.id + ind}>
                      <HiddenCategoryMenuItemTitle>{cat.title}</HiddenCategoryMenuItemTitle>
                      {cat.id === selectedCategory?.id ? <CategoryCheckMarkSvgWrapper><CheckMarkSvg /></CategoryCheckMarkSvgWrapper> : null}
                    </HiddenCategoryMenuItem>
                  ))}
                </HiddenCategoriesList> : null}
              </MultipleCategoriesSelectorActionMenuItemWrapper>
          )
        })}

        {withIgnore ? <CategorySelectorActionMenuItem isIgnoredMenuItem selectedColor={isIgnored ? '#9097A3' : '#fff'} onClick={handleIgnored}>
          <ClassIconWrapperMenuItem isIgnored={isIgnored}>{isIgnored ? <IgnoreSelectedSvg /> : <IgnoreSvg />}</ClassIconWrapperMenuItem>
          <CategorySelectorActionMenuItemContentWrapper>
            <CategorySelectorActionMenuItemTitleWrapper>Ignore this account</CategorySelectorActionMenuItemTitleWrapper>
          </CategorySelectorActionMenuItemContentWrapper>
          {isIgnored ? <CheckMarkSvgWrapper><CheckMarkSvg /></CheckMarkSvgWrapper> : null}
        </CategorySelectorActionMenuItem> : null}

      </CategorySelectorActionMenuContent>
    </CategorySelectorActionMenuWrapper>
  )
}

const CheckMarkSvg = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4033 6.59531L7.44177 11.5597L4.95834 9.07699" stroke="white" strokeWidth="2" />
    </svg>
  )
}

const IgnoreSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z" fill="#585D66" />
      <rect x="4.5" y="7" width="7" height="2" fill="white" />
    </svg>
  )
}

const IgnoreSelectedSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 8C2 4.68629 4.68629 2 8 2V2C11.3137 2 14 4.68629 14 8V8C14 11.3137 11.3137 14 8 14V14C4.68629 14 2 11.3137 2 8V8Z" fill="white" />
      <rect x="5.5" y="7.5" width="5" height="1" fill="#9097A3" />
    </svg>
  )
}

const IgnoreClassSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="2" fill="#9097A3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19 14L7 14V12L19 12V14Z" fill="white" />
    </svg>
  )
}