import { makeAutoObservable } from "mobx";

export class CashAccountTransactionsStore {

    transactions = [];
    month = 0; 
    year = 0; 
    // filteredTransactions = [];

    constructor() {
        makeAutoObservable(this);
    }
    
    setData(data) {
        this.transactions = data;
    }

    setTimeData(today) {
        this.month = today.month();
        this.year = today.year();
    }

    setTime(selectedYear, selectedMonth) {
        this.year = selectedYear;
        this.month = selectedMonth;
    }

    get filteredTransactions() {
        return this.transactions.length > 0 ? this.transactions.find(trn => trn.year === this.year).months[this.month] : [];
    }

    get totalInflowValue() {
        return this.filteredTransactions?.length > 0 ?
            this.filteredTransactions.filter(trn=>trn.value < 0).reduce((a, b) => a + b.value, 0) * -1
            : 0
    }

    get totalOutflowValue() {
        return this.filteredTransactions?.length > 0 ?
            this.filteredTransactions.filter(trn=>trn.value > 0).reduce((a, b) => a + b.value , 0)
            : 0
    }

}