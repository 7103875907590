import { BaseModal } from "../../../../../../../common/components/BaseModal/BaseModal"
import { ActionButton, ActionsWrapper, HoldingsUnlockModalAltText, HoldingsUnlockModalContent, HoldingsUnlockModalMainText, HoldingsUnlockModalWrapper } from "./HoldingsUnlockModal.styles"

export const HoldingsUnlockModal = ({ unlock, cancel }) => {
  return (
    <BaseModal width={'400px'} height={'auto'} borderRadius={8} onBackDropClick={cancel}>
      <HoldingsUnlockModalWrapper>
        <HoldingsUnlockModalContent>
          <HoldingsUnlockModalMainText>You are changing your asset's ownership percentage.</HoldingsUnlockModalMainText>
          <HoldingsUnlockModalAltText>This will affect all the relevant values in this asset. </HoldingsUnlockModalAltText>
        </HoldingsUnlockModalContent>
        <ActionsWrapper>
          <ActionButton onClick={cancel}>Cancel</ActionButton>
          <ActionButton black onClick={unlock}>Got it</ActionButton>
        </ActionsWrapper>
      </HoldingsUnlockModalWrapper>
    </BaseModal>
  )
}