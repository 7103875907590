import { Observer } from 'mobx-react';
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { LogoLoader } from '../../../../../common/components/LogoLoader/LogoLoader';
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import { useStore } from '../../../../app/data/root.store';
import { TickersList } from '../../asset/components/Tickers/TickersList';
import { ClassPageTickersListViewWrapper, LogoLoaderWrapper } from './ClassPage.styles';
import { ClassPageTickersListViewEmptyState } from './ClassPageTickersListViewEmptyState';

export const ClassPageTickersListView = (props) => {
  const { tickersStore } = useStore();
  const history = useHistory();

  useEffect(() => {
    tickersStore.getTickersData([]);
    // return () => {}
  }, [tickersStore])

  const handleTickerClick = (assetId) => {
    navigateToUrl(history, `/wealth/assets/brokerage-accounts/${assetId}/holdings`);
  }

  return (<Observer>{() => (
    <ClassPageTickersListViewWrapper>
      {tickersStore.isLoadingTickersData ?
        <LogoLoaderWrapper>
          <LogoLoader wbg />
        </LogoLoaderWrapper>
        :
        !tickersStore.tickers?.length ?
          <ClassPageTickersListViewEmptyState />
          :
          <TickersList tickers={tickersStore.sortedTickersList} isClassView={true} onTickerClick={handleTickerClick} closedHoldingsInDisplayCount={tickersStore.closedHoldingsInDisplayCount} />}
    </ClassPageTickersListViewWrapper>
  )}</Observer>)
}