import React from 'react';
import { AdamChatIconWrapper, ChatRowWrapper, TextContainer } from './ChatRow.styles';
import adamChatImg from '../../assets/adam-chat.png'


export const ChatRow = ({children,duration = 300, minHeight, textOnly = false, customTextWrapperStyle}) => {
  return (
    <ChatRowWrapper duration={duration}>
      {!textOnly ? <AdamChatIconWrapper ><img src={adamChatImg} alt={'adam chat'} /></AdamChatIconWrapper> : null}
      <TextContainer minHeight={minHeight} textOnly={textOnly} style={{...(customTextWrapperStyle ? customTextWrapperStyle : {})}}>{children}</TextContainer>
    </ChatRowWrapper>
  )
}
