//import React, { useEffect, useRef } from 'react';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CloseSymmetricIcon } from "../../../../assets/icons/common/common-icons";
import { BaseModal } from "../../../../common/components/BaseModal/BaseModal";
import { navigateToUrl } from "../../../../common/utils/navigation.utils";
import { useStore } from "../../../app/data/root.store";
import { updateActivationStep } from "../../../user/data/user.service";
import { IntroModalCloseBtnWrapper, IntroModalContent, IntroModalHeader, StepActionButton, StepDescription, StepSingleButton } from "./ChecklistPane.styles";
import { CalendlyPopupWidget } from "../../../../common/components/CalendlyPopupWidget/CalendlyPopupWidget";
import { startIntercomNewMessage } from "../../../../common/utils/intercom.utils";
import { ROUTES } from "../../../../common/constants/routes.const";

export function QuickIntroStep() {
    const { userStore} = useStore();
    const [showIntroVideoModal,setShowIntroVideoModal] = useState(false);
    const handleBookCallClick = () => {
        updateActivationStep({'flows.getStartedPane.bookACall' : true});
        userStore.setGetStartedStepAsComplete('bookACall');
      }

    return (
        <>
            {/* <StepActionButton onClick={handleBookDemoCallOnClick}><CalendarIcon />Book a demo call</StepActionButton> */}
            <CalendlyPopupWidget onScheduleSuccess={handleBookCallClick} triggerComponent={<StepActionButton><CalendarIcon />Schedule a call</StepActionButton>} triggerWrapperStyles={{marginBottom: '8px'}} />
            <StepActionButton onClick={startIntercomNewMessage}><IntercomIcon />Chat with us</StepActionButton>
            <StepActionButton onClick={()=>{setShowIntroVideoModal(true)}}><WatchIntroIcon />Watch Litan’s intro</StepActionButton>
            {showIntroVideoModal && <BaseModal width="560px" height="400px" borderRadius={4} onBackDropClick={()=>{setShowIntroVideoModal(false)}} >
                <IntroModalHeader><h2>Getting Started</h2><IntroModalCloseBtnWrapper onClick={()=>{setShowIntroVideoModal(false)}}><CloseSymmetricIcon /></IntroModalCloseBtnWrapper></IntroModalHeader>
                <IntroModalContent>
                    <iframe src='https://www.youtube.com/embed/MNCsAHhLQEc?rel=0&autoplay=1'
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'
                    width={'100%'}
                    height={304}
                    />
                </IntroModalContent>
            </BaseModal>}
        </>
        
    )
}

export function UploadYourFinancialStatementsStep(){
    let history = useHistory();
    return (
        <DescriptionAndButtonStep 
            description={'Use the Magic box to convert your financial documents into data.'}
            buttonText={'Upload'}
            onButtonAction={()=>{navigateToUrl(history, ROUTES.magicBox.url)}}
        />
    )
}

export function SyncYourCashAccountsStep(){
    const { createItemStore } = useStore();
    const handleButtonClick = () => {
        createItemStore.setInstitutionStep('bank');
        createItemStore.setShowModal(true);
    }
    return (
        <DescriptionAndButtonStep 
            description={'Real-time automated transaction tracking.'}
            buttonText={'Search for bank'}
            onButtonAction={handleButtonClick}
        />
    )
}

export function AddAssetManuallyStep(){
    const { createItemStore } = useStore();
    const handleButtonClick = () => {
        createItemStore.setCategoryStep(true);
        createItemStore.setShowModal(true);
    }
    return (
        <DescriptionAndButtonStep 
            description={'For better tracking, add assets with expected cash flow (like real estate, private equity).'}
            buttonText={'Add'}
            isPlusIcon
            onButtonAction={handleButtonClick}
        />
    )
}

export function PlanYourCashFlowStep(){
    let history = useHistory();
    const { metadataStore , userStore} = useStore();

    const handleButtonClick = () => {
        navigateToUrl(history,ROUTES.cashflow.url);
        if (metadataStore.classWithItemsCount >= 2){
            updateActivationStep({'flows.getStartedPane.futureCashFlow' : true});
            userStore.setGetStartedStepAsComplete('futureCashFlow');
        }
    }
    return (
        <DescriptionAndButtonStep 
            description={'Forecast your future cash flow by projecting your expected inflows and outflows, and considering potential scenarios.'}
            buttonText={'Start planning'}
            onButtonAction={handleButtonClick}
        />
    )
}

export function BookACallStep() {
    const { userStore} = useStore();
    const handleBookCallClick = () => {
        updateActivationStep({'flows.getStartedPane.bookACall' : true});
        userStore.setGetStartedStepAsComplete('bookACall');
      }

    return (
        <>
            <CalendlyPopupWidget onScheduleSuccess={handleBookCallClick} triggerComponent={<StepActionButton><CalendarIcon />Schedule a call</StepActionButton>} triggerWrapperStyles={{marginBottom: '8px'}} />
            {!userStore.isTier4 ? <StepActionButton onClick={startIntercomNewMessage}><IntercomIcon />Chat with us</StepActionButton> : null}
        </>
        
    )
}

const DescriptionAndButtonStep = (props) => {
    const {description,buttonText,onButtonAction,isPlusIcon} = props;
    return (
    <>
        <StepDescription>
            {description}
        </StepDescription>
        <StepSingleButton onClick={onButtonAction}>
            {isPlusIcon && <SingleButtonPlusIcon /> }
            {buttonText}
            {!isPlusIcon && <SingleButtonArrowIcon /> }
        </StepSingleButton>
    </> )
}

const CalendarIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.33301 4.86838C3.33301 4.79167 3.39519 4.72949 3.47189 4.72949H16.5275C16.6042 4.72949 16.6663 4.79167 16.6663 4.86838V16.5906C16.6663 16.6673 16.6042 16.7295 16.5275 16.7295H3.47189C3.39519 16.7295 3.33301 16.6673 3.33301 16.5906L3.33301 4.86838ZM5.04052 6.43537C4.96382 6.43537 4.90164 6.49755 4.90164 6.57425V14.8847C4.90164 14.9614 4.96382 15.0236 5.04052 15.0236H14.9588C15.0355 15.0236 15.0977 14.9614 15.0977 14.8847V6.57425C15.0977 6.49755 15.0355 6.43537 14.9588 6.43537H5.04052Z" fill="#1A1B1D"/>
        <path d="M16 10L4 10L4 6L16 6L16 10Z" fill="#1A1B1D"/>
        <path d="M14.5308 3.27637L14.5308 7.08589L12.753 7.08589L12.753 3.27637L14.5308 3.27637Z" fill="#1A1B1D"/>
        <path d="M7.3335 3.27637L7.3335 7.08589L5.55572 7.08589L5.55572 3.27637L7.3335 3.27637Z" fill="#1A1B1D"/>
    </svg>
)

const IntercomIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6663 17.5C16.6663 17.5 14.4216 16.6305 12.6059 15.9344H4.96967C4.06634 15.9344 3.33301 15.1684 3.33301 14.2248V4.20812C3.33301 3.26547 4.06634 2.5 4.96967 2.5H15.0292C15.933 2.5 16.6659 3.265 16.6659 4.20859V12.8355L16.6663 17.5ZM14.6954 11.8834C14.6597 11.8384 14.6149 11.8011 14.5639 11.774C14.5128 11.7468 14.4566 11.7304 14.3988 11.7258C14.3409 11.7212 14.2827 11.7284 14.2279 11.7471C14.173 11.7657 14.1227 11.7954 14.0802 11.8342C14.0659 11.8464 12.6444 13.0909 9.9992 13.0909C7.38682 13.0909 5.94348 11.8553 5.91777 11.8328C5.87514 11.7942 5.82484 11.7647 5.77006 11.7462C5.71527 11.7277 5.65718 11.7206 5.59946 11.7253C5.54174 11.7301 5.48563 11.7465 5.43469 11.7736C5.38375 11.8008 5.33907 11.838 5.30348 11.883C5.22726 11.9766 5.18998 12.0952 5.1992 12.2148C5.20725 12.3339 5.26146 12.4453 5.35063 12.5261C5.41825 12.5861 7.03491 14.0027 9.9992 14.0027C12.964 14.0027 14.5806 12.5861 14.6482 12.5261C14.7372 12.4452 14.7913 12.3338 14.7992 12.2148C14.8084 12.0954 14.7713 11.977 14.6954 11.8834Z" fill="#1A1B1D"/>
    </svg>
)

const WatchIntroIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="14" height="14" rx="4.66667" fill="#1A1B1D"/>
        <path d="M8.1333 6.89589C8.1333 6.82386 8.21144 6.77898 8.27366 6.81528L13.5951 9.91945C13.6568 9.95546 13.6568 10.0447 13.5951 10.0807L8.27366 13.1849C8.21144 13.2211 8.1333 13.1763 8.1333 13.1042V6.89589Z" fill="white"/>
    </svg>
)

const SingleButtonArrowIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 9L4.5 9M13.5 9L9.75 4.5M13.5 9L9.75 13.5" stroke="white" strokeWidth="1.5"/>
    </svg>
)

const SingleButtonPlusIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 14L8 2" stroke="white" strokeWidth="1.5" strokeLinejoin="round"/>
        <path d="M14 8H2" stroke="white" strokeWidth="1.5" strokeLinejoin="round"/>
    </svg>
)