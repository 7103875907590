import styled from "@emotion/styled/macro";
import { colors } from "../../../../common/styles/theme.styles";

export const DeepAnalysisPendingResultTitle = styled.div`
  color: ${colors.darkGray1};
  text-align: center;
  font-family: Circular;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -2.4px;
`;

export const DeepAnalysisPendingResultSubtitle = styled(DeepAnalysisPendingResultTitle)`
  font-size: 14px;
  font-weight: 450;
  line-height: normal;
  letter-spacing: normal;
  padding: 8px 0 24px 0;
`;