import { Drawer } from 'antd';
import React from 'react';
import { BaseModal } from '../BaseModal/BaseModal';
import { LimitedAccessButtonWrapper, LimitedAccessDescription, LimitedAccessModalBody, LimitedAccessModalWrapper, LimitedAccessTitle } from './LimitedSharedAccess.styles';
import { ReactComponent as EyesSvg } from './assets/eyes-icon.svg';
import { useSwipeable } from 'react-swipeable';

export const LimitedSharedAccess = ({ variant = 'modal', onClose, visible, ...props }) => {

  return (
    variant === 'modal' ?
      <BaseModal width={'400px'} height={'232px'} borderRadius={8} >
        <LimitedSharedAccessContent onClose={onClose} withBorderRadius {...props} />
      </BaseModal>
      :
      <Drawer
        closable={false}
        visible={visible}
        // width={340}
        // height={'calc(100% - 32px)'}
        height={'fit-content'}
        className={'limitedAccessDrawerContainer_mobile'}
        placement={'bottom'}
        destroyOnClose={true}
        onClose={onClose} >
        <LimitedSharedAccessContent onClose={onClose} isMobileDrawer {...props} />
      </Drawer>
  )
}

const LimitedSharedAccessContent = ({ isMobileDrawer, onClose, withBorderRadius = false, portfolioDetails }) => {
  
  const swipeHandlers = useSwipeable(isMobileDrawer ? {
    onSwipedDown: (eventData) => {
      onClose?.()
    }
  } : {});

  return (
    <LimitedAccessModalWrapper withBorderRadius={withBorderRadius} {...swipeHandlers}>
      <LimitedAccessModalBody>
        <EyesSvg />
        <LimitedAccessTitle>
          Limited Access
        </LimitedAccessTitle>
        <LimitedAccessDescription>
          You are {portfolioDetails.roleDescription} for this portfolio with {portfolioDetails.permission.toLowerCase()} permissions.<br />
          To extend permissions, contact the account owner/admin.
        </LimitedAccessDescription>
      </LimitedAccessModalBody>
      <LimitedAccessButtonWrapper onClick={onClose}>
        Got it
      </LimitedAccessButtonWrapper>
    </LimitedAccessModalWrapper>
  )
}