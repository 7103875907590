import React from 'react'
import {  HelpMessage  } from './GetInTouchDropDown.styles';
import { Dropdown } from 'antd';

export const GetInTouchDropDown = (props) => {
  const { buttonText } = props;

  return (
            <Dropdown
                overlay={HelpMessageActionMenu({})}
                trigger={['hover']}  overlayClassName="asset-action-menu-wrapper">
            <HelpMessage>
                <span>{buttonText || 'Any questions? Contact us'}</span>
            </HelpMessage>
            </Dropdown>
    )
}


function HelpMessageActionMenu() {
    return (
      <div className="asset-action-menu-position-fix-wrapper2">
        <div className="asset-action-menu-content multi-shadows">
          <div className="asset-action-menu-item" onClick={() => { window.open('https://vyzer.co/pricing#pricingFAQSection','_blank');}}>
            <span className='asset-action-menu-item-child'>FAQ</span>
          </div>
          <div className="asset-action-menu-item" onClick={() => { window.Intercom && window.Intercom('show') }}>
            {/* <span> <DeleteOutlined />Delete {itemTitle}</span> */}
            <span className='asset-action-menu-item-child'>Support</span>
          </div>
        </div>
      </div>
    )
  }