import React, { useEffect, useRef } from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../app/data/root.store';
import { useHistory } from "react-router-dom";
import {
    ModalBackDrop, ModalWrapper,
    CreateHeaderWrapper, CloseButtonWrapper, CreateHeaderContentWrapper, CreateContentWrapper, ClassIconWrapper, CreateItemBackButtonWrapper, CreateItemHeaderTitle, CreateItemAccountsHeaderTitle, CreateItemAccountsTitle, CreateItemAccountsSubTitle, CreateItemVipSyncHeaderTitle
} from './CreateItemModal.styles'
// import { WizardButton } from './AddItem.styles';    
// import { LoadingOutlined } from '@ant-design/icons';
import { ADD_ITEM_STEP } from '../data/create-item.store';
import { AddItemPlaidAccountsStep } from './steps/AddItemPlaidAccountStep';
import { AddItemFormStep } from './steps/AddItemFormStep';
import { LinkArrowIcon, ModalBackIcon, ModalCloseIcon, UpgradeCrownIcon } from '../../../assets/icons/common/common-icons';
import { AssetClassIcon } from '../../assets/components/AssetClassIcon';
import { AddItemSaltedgeAccountsStep } from './steps/AddItemSaltedgeAccountStep';
import { CreateItemClassCategoryStep } from './steps/CreateItemClassCategoryStep';
import { useFormHook } from '../../form-displayer-connector/data/useFormHook';
import { useModalUpwardCorrectionHook } from '../../../common/components/BaseModal/useModalUpwardCorrectionHook';
import { InstitutionLogoWrapper, InstitutionName, InstitutionStepWrapper, InstitutionTextWrapper, InstitutionUrl, LogoPlaceHolder, VipSyncRowWrapper } from './steps/addItemInstitutionStep.styles';
import { InstitutionSearchInputAndResults } from './InstitutionSearchInputAndResults';
import { AddItemChooseMethodStep } from './steps/AddItemChooseMethodStep';
import { CommonBadge } from '../../../common/components/CommonBadge/CommonBadge';
import { cleanURL } from '../../../common/utils/string.utils'
import { VipSyncFlow } from './steps/VipSyncFlow/VipSyncFlow';
import { DemoStartPortfolioButton, UpgradePortfolioButton } from '../../../common/components/buttons/NewButtons.styles';
import { Drawer } from 'antd';
import { AddItemAkoyaAccountsStep } from './steps/AddItemAkoyaAccountStep';
import { ROUTES } from '../../../common/constants/routes.const';

export function NewCreateItemModal(props) {
    const { onClose, onAddItem, isOnBoarding } = props;
    const { createItemStore , uiStore } = useStore();
    const modalWrapperRef = useRef();
    const isCorrectingUpward = useModalUpwardCorrectionHook(modalWrapperRef);
    
    useEffect(() => {
        return () => {
            createItemStore.setChooseMethodStep();
            createItemStore.setHideBackButton(false);
        }
    }, [createItemStore]);

    const handleModalBackDropOnClick = () => {
        if (createItemStore.step === ADD_ITEM_STEP.CHOOSE_METHOD) {
            onClose && onClose(true);
        }
    }

    const isAccountsStep = [ADD_ITEM_STEP.PLAID_ACCOUNTS, ADD_ITEM_STEP.SALTEDGE_ACCOUNTS, ADD_ITEM_STEP.AKOYA_ACCOUNTS];

    return (<Observer>{() => (
        <>
        {uiStore.isDesktopView ? 
         <ModalBackDrop onClick={handleModalBackDropOnClick}>
         <ModalWrapper isWhiteBackgroundColor={isAccountsStep.includes(createItemStore.step)} hasMinHeight={createItemStore.step !== ADD_ITEM_STEP.CHOOSE_METHOD} onClick={(e) => { e.stopPropagation() }} ref={modalWrapperRef} isCorrectingUpward={isCorrectingUpward}>
             <NewCreateItemModalContent onClose={onClose} onAddItem={onAddItem} isOnBoarding={isOnBoarding} />
         </ModalWrapper>
     </ModalBackDrop>:
       
       <Drawer
       closable={false}
       visible={true }
        width={ '100%' }
        height={'auto'}
   className={'createItemDrawerContainer_mobile'}
   placement={'left'}
   onClose={()=>{handleModalBackDropOnClick()}}>
        <NewCreateItemModalContent onClose={onClose} onAddItem={onAddItem} isOnBoarding={isOnBoarding} />
   </Drawer>
   }

       
        
        </>
    )}</Observer>)
}

function NewCreateItemModalContent(props) {
    const { onClose, onAddItem, isOnBoarding } = props;
    const { createItemStore, userStore , billingStore, uiStore} = useStore();
    const history = useHistory();
    const addItemForm = useFormHook('addItemForm');

    const handleBackButtonOnClick = () => {
        if (createItemStore.step === ADD_ITEM_STEP.MANUALLY_FORM) {
            createItemStore.setCategoryStep();
        }
        if (createItemStore.step === ADD_ITEM_STEP.CATEGORY && createItemStore.classId !== 0){
            createItemStore.setCategoryStep();
        }
    }

    const handleBackButtonAiChangeClassOnClick = () => {
        createItemStore.setCategoryStep({isMagicBoxAi:true, title:addItemForm?.values?.title , value: addItemForm?.values?.value , currency: addItemForm?.values?.currency});
    }

    const handleStartDemoPortfolioOnClick = async () => {
        if (uiStore.isMobileAppView || userStore.isRelevantVariant === 6) {
            await billingStore.setStarterPlan();
            window.location.href = `${ROUTES.wealthOverview.url}?subscribed_success&sw`;
        } else {
            billingStore.setShowPricingTableModal(true);
        }
    }

    const handleOnCloseClick = () => {
        if (createItemStore.step === ADD_ITEM_STEP.INSTITUTION) {
            handleInstitutionSyncClose();
        } else {
            onClose(true);
        }
    }

    const handleInstitutionSyncClose = () => {
        createItemStore.setIsSaltedgeInProcess(false);
        createItemStore.setIsAkoyaInProcess(false);
        createItemStore.setChooseMethodStep();
        onClose(true);
    }

    const isAccountsStep = [ADD_ITEM_STEP.PLAID_ACCOUNTS, ADD_ITEM_STEP.SALTEDGE_ACCOUNTS];

    return (<Observer>{() => (
        <>
                <CreateHeaderWrapper>
                    <CreateHeaderContentWrapper>
                        {(createItemStore.showHeaderBackButton || (createItemStore.step === ADD_ITEM_STEP.CATEGORY && !uiStore.isDesktopView && createItemStore.classId !== 0)) &&
                            <CreateItemBackButtonWrapper onClick={handleBackButtonOnClick} hide={(createItemStore.step === ADD_ITEM_STEP.MANUALLY_FORM && addItemForm?.values?.isMagicBoxAi ) || createItemStore.hideBackButton || (createItemStore.step === ADD_ITEM_STEP.MANUALLY_FORM && addItemForm?.currentSectionIndex !== 0)}>
                                <ModalBackIcon />
                            </CreateItemBackButtonWrapper>}
                        {createItemStore.showHeaderClassIcon && createItemStore.categoryId !== 0 && <ClassIconWrapper color={createItemStore.selectedClass.color} size={32} iconSize={20}>
                            <AssetClassIcon name={createItemStore.selectedClass.icon} />
                        </ClassIconWrapper>}
                        {isAccountsStep.includes(createItemStore.step) ? <CreateItemAccountsHeaderTitle>
                            <span style={{ color: '#20CD7B' }}>Successful sync.</span>
                            <CreateItemAccountsTitle>Choose a category for each account:</CreateItemAccountsTitle>
                            <CreateItemAccountsSubTitle>This classification will create an asset / liability in that selected class.</CreateItemAccountsSubTitle>
                        </CreateItemAccountsHeaderTitle> :
                            createItemStore.step === ADD_ITEM_STEP.VIP_SYNC && createItemStore.vipSyncStepIndex !== 0 ?
                                <CreateItemVipSyncHeaderTitle>
                                    <VipSyncHeaderRow item={createItemStore.selectedVipSyncInstitution} />
                                </CreateItemVipSyncHeaderTitle> :
                                createItemStore.headerTitle && <CreateItemHeaderTitle>
                                    <span>{createItemStore.headerTitle}</span>
                                    {createItemStore.step === ADD_ITEM_STEP.MANUALLY_FORM && addItemForm?.values?.isMagicBoxAi && addItemForm?.currentSectionIndex === 0 && <span onClick={handleBackButtonAiChangeClassOnClick} style={{color:'#2565E7' , fontSize:'16px' , fontWeight:'450' , display:'flex' , alignItems:'center' , cursor:'pointer' , marginTop: '2px' }}>Change asset class<ArrowLinkSvg/></span>}
                                    {/* {userStore.isFreeTier && createItemStore.isManuallyStep && <CommonBadge noMargin badgeType="limitedFeature" title={`Add manually up to ${userStore.userLimitations.manualActiveItems.manualItems} items`} rounded clickable onClick={()=>{ billingStore.setShowPricingTableModal(true)}} />} */}
                                    {userStore.isTier2or3 && createItemStore.isManuallyStep && userStore.isCreateItemManuallyLimited && <CommonBadge noMargin  color={'#2D72FF'} badgeType="limitedFeature" title={`Add manually up to ${userStore.userLimitations.manualActiveItems.manualItems}  items`} rounded clickable onClick={()=>{ billingStore.setShowPricingTableModal(true)}} />}
                                    {/* {userStore.isFreeTier && createItemStore.isSyncingStep && <CommonBadge noMargin badgeType="limitedFeature" title={`Up to ${userStore.userLimitations.financialInstitutionSync.syncedInstitutions} synced institutions`} rounded clickable onClick={()=>{ billingStore.setShowPricingTableModal(true)}} />} */}
                                    {userStore.isTier2or3 && createItemStore.isSyncingStep && userStore.isAddSyncInstitutionLimited && <CommonBadge noMargin color={'#2D72FF'} badgeType="limitedFeature" title={`Up to ${userStore.userLimitations.financialInstitutionSync.syncedInstitutions}  synced institutions`} rounded clickable onClick={()=>{ billingStore.setShowPricingTableModal(true)}} />}
                                </CreateItemHeaderTitle>}
                                
                                {(userStore.isDemoMode && uiStore.isDesktopView) && <><div style={{flex:1}} /><DemoStartPortfolioButton height={38} onClick={handleStartDemoPortfolioOnClick}>Start My Portfolio Free<LinkArrowIcon /></DemoStartPortfolioButton></>}

                                {(userStore.isFreeTier && uiStore.isDesktopView && ((createItemStore.isManuallyStep && userStore.isCreateItemManuallyLimited) || (createItemStore.isSyncingStep && userStore.isAddSyncInstitutionLimited))) && <><div style={{flex:1}}></div><UpgradePortfolioButton style={{margin:0}} onClick={()=>{billingStore.setShowPricingTableModal(true)}}><UpgradeCrownIcon />Upgrade</UpgradePortfolioButton> </>}

                                {(userStore.isTier2or3 && uiStore.isDesktopView &&
                                    ((createItemStore.isManuallyStep && userStore.isCreateItemManuallyLimited) || (createItemStore.isSyncingStep && userStore.isAddSyncInstitutionLimited))) 
                                    && <><div style={{flex:1}}></div><UpgradePortfolioButton blueBg style={{margin:0}} onClick={()=>{billingStore.setShowPricingTableModal(true)}}><UpgradeCrownIcon />Upgrade</UpgradePortfolioButton> </>}
                    </CreateHeaderContentWrapper>
                    <CloseButtonWrapper onClick={handleOnCloseClick}>
                        <ModalCloseIcon />
                    </CloseButtonWrapper>
                </CreateHeaderWrapper>
                <CreateContentWrapper isWhiteBackgroundColor={isAccountsStep.includes(createItemStore.step)}>
                    {createItemStore.step === ADD_ITEM_STEP.CHOOSE_METHOD && <AddItemChooseMethodStep />}
                    {createItemStore.step === ADD_ITEM_STEP.INSTITUTION && <InstitutionStepWrapper><InstitutionSearchInputAndResults onInstitutionSyncClose={handleInstitutionSyncClose} /></InstitutionStepWrapper>}
                    {createItemStore.step === ADD_ITEM_STEP.CATEGORY && <CreateItemClassCategoryStep />}
                    {createItemStore.step === ADD_ITEM_STEP.MANUALLY_FORM && <AddItemFormStep history={history} isOnBoarding={isOnBoarding} onAddItem={onAddItem} />}
                    {createItemStore.step === ADD_ITEM_STEP.PLAID_ACCOUNTS && <AddItemPlaidAccountsStep
                        onFinish={(metadataStore) => { onClose(); userStore.updateData(); metadataStore.fetchData(); }} />}
                    {createItemStore.step === ADD_ITEM_STEP.SALTEDGE_ACCOUNTS && <AddItemSaltedgeAccountsStep onFinish={(metadataStore) => { onClose(); userStore.updateData(); metadataStore.fetchData(); }} />}
                    {createItemStore.step === ADD_ITEM_STEP.AKOYA_ACCOUNTS && <AddItemAkoyaAccountsStep onFinish={(metadataStore) => { onClose(); userStore.updateData(); metadataStore.fetchData(); }} />}
                    {createItemStore.step === ADD_ITEM_STEP.VIP_SYNC && <VipSyncFlow stepIndex={createItemStore.vipSyncStepIndex} item={createItemStore.selectedVipSyncInstitution} />}
                </CreateContentWrapper>        
        </>
    )}</Observer>)
}

function VipSyncHeaderRow({ item }) {
    return (
        <VipSyncRowWrapper isHeader>
            <InstitutionLogoWrapper>
                {item.logoBase64 ? <img alt={item.name} src={'data:image/png;base64,' + item.logoBase64} /> :
                    item.logoURL ? <img alt={item.name} src={item.logoURL} /> : <LogoPlaceHolder />}
            </InstitutionLogoWrapper>
            <InstitutionTextWrapper style={{ flex: 1, justifyContent: 'center' }}>
                <InstitutionName>{item.name}</InstitutionName>
                <InstitutionUrl>{cleanURL(item.homeURL)}</InstitutionUrl>
            </InstitutionTextWrapper>
            <CommonBadge isCTA badgeType={'vipService'} color={'#DEB482'} />
        </VipSyncRowWrapper>
    )
}

const ArrowLinkSvg = () => (
    <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9675 8.875C10.9675 8.875 15.233 8.875 17.1652 8.875C17.1652 10.8072 17.1652 15.0727 17.1652 15.0727" stroke="#2565E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.25 16.7969L16.8958 9.15103" stroke="#2565E7" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )