import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../../../../../common/styles/theme.styles';


export const HeroContentWrapper = styled.div`
    display:flex;
    flex:1;
    flex-direction: column;
`   

export const FlexSpace = styled.div`
    flex:1;
`;

export const HeaderWrapper = styled.div`
    ${flexCenter};
    // border-bottom:1px solid gray;
    padding:16px 24px;
    gap:16px;
    ${views.tablet}{
        flex-direction: column;
        align-items: stretch;
        padding-top: 40px;
    }
`;

export const HeaderMobileTitle = styled.div`
    color: #FFF;
    font-size: 16px;
    font-weight: 450;
`;
export const TimeFramesButton = styled.div`
    ${flexCenter}
    border: 1px solid #2E2F32;
    border-radius: 6px;
    min-width: 180px;
    max-width: 230px;
    height: 36px;
    gap:4px;
    padding:0 16px;
`;

export const TimeFrameButtonLabel = styled.span`
    font-weight: 450;
    font-size: 12px;
    color:${colors.gray2}
`;

export const TimeFrameButtonValueLabel = styled.span`
    flex:1;
    font-weight: 450;
    font-size: 14px;
    color:#fff;
`;

export const HeaderInfoLabel = styled.div`
    ${flexCenter};
    gap:4px;
    font-weight: 450;
    font-size: 12px;
    color:${props=>props.inflow ? colors.inflowText : props.outflow ? colors.outflowText : colors.darkGray3};
`;

export const HeaderInfoLabelBox = styled.div`
    border-width: ${props=>props.isTarget ? 0 : 1}px;
    border-style: solid;
    border-color:${props=>props.inflow ? colors.inflowText : props.outflow ? colors.outflowText : 'none'};
    border-radius: 2px;
    width: 10px;
    min-width: 10px;
    height: 10px;

    ${props => props.isTarget ? `
        background: repeating-linear-gradient(
            135deg,
            #000000 -1px,
            #000000 1px,
            #828282 0px,
            #828282 2px
        );
    ` : ``}
`;

export const HeroDataWrapper = styled.div`
    flex:1;
    display:grid;
    grid-template-columns: 120px repeat(${props=> props.dataColumns}, auto) 120px; 
    ${views.tablet}{
        grid-template-columns: 40px repeat(${props=> props.dataColumns}, auto) 40px; 
        min-height: 120px;
    }
`;

export const ArrowGridCellWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;  //${props=>props.leftArrow ? 'flex-end' : 'flex-start;'}
`;

export const ArrowButtonWrapper = styled.div`
    width: 28px;
    height: 28px;
    border: 1px solid ${colors.gray2};
    border-radius: 16px;
    cursor: ${props=>props.disabled ? 'not-allowed' : 'pointer'};
    ${props=>props.disabled ? `opacity:0.3;` : ``}
    ${flexCenter};
`;

export const ArrowButtonArrow = styled.div`
    width: 0; 
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    ${props=>props.isLeft ? `
        border-right:6px solid ${colors.gray2}; 
        margin-right: 2px;
    ` : `
        border-left:6px solid ${colors.gray2}; 
        margin-left: 2px;
    `}
    
`;




export const DataBarWrapper = styled.div`
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-height: 220px;
`;

export const DataBar = styled.div`
    background-color: ${props=>props.inflow ? '#175236' : '#501F1F'};
    border-top: 2px solid ${props=>props.inflow ? '#31AF73;' : '#E44B54'};
    height: ${props=>props.height}px;
    width: 100%;  
`;

export const TargetDataBar = styled.div`
    // background: repeating-linear-gradient(
    //     135deg,
    //     #000000 -1px,
    //     #000000 1px,
    //     ${props=>props.inflow ? '#175236' : '#501F1F'} 0px,
    //     ${props=>props.inflow ? '#175236' : '#501F1F'} 2px
    // );
    ${props=>props.inflow ? `
        background-image: linear-gradient(135deg, #263d33 16.67%, #132c21 16.67%, #132c21 50%, #263d33 50%, #263d33 66.67%, #132c21 66.67%, #132c21 100%);
        background-size: 8.49px 8.49px;
    ` : `
        background-image: linear-gradient(135deg, #412a2b 16.67%, #301819 16.67%, #301819 50%, #412a2b 50%, #412a2b 66.67%, #301819 66.67%, #301819 100%);
        background-size: 8.49px 8.49px;
    `}
    

    height: ${props=>props.height}px;
    width: 100%;  
`;


export const DataBarTooltipWrapper = styled.div`
    width: 220px;
    // height: 174px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: ${colors.darkGray1};
    border: 1px solid #2E2F32;

    box-shadow: ${shadows.menuPopup};
    border-radius: 8px;
    transition: all 0.3s;
`;

export const DataBarTooltipPositionHiddenWrapper = styled.div`
       
`;
export const BarsWrapper = styled.div`
    position: relative;
    width: ${props=> props.isSingleLine ? '320px' : '100%'};
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;

export const BarsGridCellWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding:0 16px;
    ${DataBarTooltipWrapper} {
        opacity: 0;
        z-index: 1;
        position: absolute;
        left:50%;
        transform: translateX(-50%) translateY(-20px);
        pointer-events: none;
        ${views.desktop}{
            bottom:60%;
        }
        ${views.tablet}{
            top:20%;
            z-index: 3;
        }
    }
    :hover{
        ${DataBarTooltipWrapper} {
            opacity: 1;
            pointer-events: auto;
        
    } 
`;


export const BarsBottomText = styled.div`
    color:${colors.gray3};
    font-weight: 450;
    font-size: 13px;
    padding:12px 0 24px;
`;


export const DataBarTooltipHeader = styled.div`
    ${flexCenter};
    background: #2D2F33;
    border-radius: 8px 8px 0px 0px;
    padding: 6px 0;

    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    width:100%;
`;

export const DataBarTooltipContent = styled.div`
    display: flex;
    padding: 20px 16px;
    gap: 8px;
`;

export const DataBarTooltipInflowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    color: ${colors.inflowText};
    border-left: 2px solid ${colors.inflowText};
    padding-left: 8px;
`;

export const DataBarTooltipOutflowWrapper = styled(DataBarTooltipInflowWrapper)`
    color: ${colors.outflowText};
    border-left: 2px solid ${colors.outflowText};
`;

export const TooltipDataTitle = styled.div`
    padding-bottom: 2px;
`;

export const TooltipDataActual = styled.div`
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 8px;
`;

export const TooltipDataTargetWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${colors.darkGray3};
    padding-bottom: 2px;
`;

export const DiagonalLines = styled.div`
    height: 15px;
    width: 15px;
    background-image: ${props=>props.isOutflow ? `
    linear-gradient(-45deg, ${colors.outflowText} 25%, transparent 25%, transparent 50%, ${colors.outflowText} 50%, ${colors.outflowText} 75%, transparent 75%, #fff);
    ` : `
    linear-gradient(-45deg, ${colors.inflowText} 25%, transparent 25%, transparent 50%, ${colors.inflowText} 50%, ${colors.inflowText} 75%, transparent 75%, #fff);
    `};
     
    background-size: 4px 4px;
    border-radius: 4px;
    opacity: 0.4;
`;

export const TooltipDataTarget = styled.div`
    color: ${colors.darkGray3};
`;

export const DataBarTooltipFooter = styled.div`
    ${flexCenter};
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    padding: 6px 0 10px 0;
    border-top: 1px solid #2E2F32;
    width:100%;
    border-radius: 0px 0px 8px 8px;
`;

export const MobileBottomMapItem = styled.div`
    ${flexCenter};
    gap:16px;
    padding:8px 8px 24px;
`;

export const SubHeader = styled.div`
    color: ${colors.darkGray3};
    font-family: Circular;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    padding: 8px 16px 0px 16px;
`;