
import { Drawer, Tabs } from 'antd';
import { Observer } from 'mobx-react';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MobileLeftCornerMask, MobileRightCornerMask } from '../../../../../assets/icons/common/common-icons';
import { LogoLoader } from '../../../../../common/components/LogoLoader/LogoLoader';
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import { useStore } from '../../../../app/data/root.store';
import { CardContentBoxWrapper, ItemCardContentBoxWrapper } from '../../../components/wealth.commons.styles';
import { WealthHero } from '../../overview/components/hero/WealthHero';
import { AssetActionsMenu } from './AssetActionsMenu';
import { AssetOverviewHeroContent } from './AssetOverviewHeroContent';
import './AssetPage.css';
import { CenterContent, EventsTabIndicationsWrapper, PageWrapper, TabTitlePassedEventsCount, TabTitleText, TabTitleTransactionsCount, TabTitleVipSyncCount, TabTitleWrapper, TabsContentWrapper, TabsWrapper } from './AssetPage.styles';
import { AssetPageHeader } from './AssetPageHeader';
import { CloseItemModal } from './CloseItemModal/CloseItemModal';
import { AssetDocumentsTab } from './Documents/DocumentsTab';
import { AssetEventsTab } from './Events/AssetEventsTab/AssetEventsTab';
import { EditTargetEvent } from './Events/EditTargetEvent';
import { AmortizationLoansInfoModal } from './Info/AmortizationFormModal/AmortizationLoansInfoModal';
import { AssetEditForm } from './Info/AssetEditForm';
import { OpenItemModal } from './OpenItemModal/OpenItemModal';
import { AssetsTickersTab } from './Tickers/AssetTickersTab/AssetsTickersTab';
import { AssetTransactionsTab } from './Transactions/AssetTransactionsTab';
import { UnlockMagicBoxModal } from './UnlockMagicBoxModal';
import { AssetVipSyncingTab } from './VipSyncing/AssetVipSyncingTab';
import { CloseAccountNotFoundItemModal } from './CloseAccountNotFoundItemModal/CloseAccountNotFoundItemModal';

const eligibleTabs = ['events', 'holdings', 'transactions', 'documents', 'info'];

export function AssetPage(props) {
  let { id, activeTab, className } = useParams();
  const { isWithBanner } = props;
  let history = useHistory();
  const tabsHeaderRef = useRef(null)
  const { wealthStore, itemStore, uiStore, documentsStore, tickersStore, filtersStore, userStore, metadataStore } = useStore();
  const { TabPane } = Tabs;
  const [showAmortizationLoanModal, setShowAmortizationLoanModal] = useState(false)
  const [isHeaderFixed,setIsHeaderFixed] = useState(false);
  const [isTabsWrapperFixed,setIsTabsWrapperFixed] = useState(false);
  const [showItemMobileMenu , setShowItemMobileMenu] = useState(false);

  const handleTabChange = (key) => {
    navigateToUrl(history, (activeTab ? '' : (id + '/')) + key, true)
  }

  const closeAmortizationUpdateModal = () => {
    setShowAmortizationLoanModal(false)
  }

  const updateAmortizationInitialDetails = (data) => {
    itemStore.updateLoanAmortizationInitialDetails(id,data);
  }

  const handleAmortizationModal = (itemStoreRef) => {
    const { categoryId, isConnected } = itemStoreRef.item;
    if (categoryId !== 37 || !isConnected) return false;
    const { interest, fixedMonthlyPayment, amount_borrowed, value, startDate, principalFirstRepayment} = itemStoreRef.item;
    if ([interest, fixedMonthlyPayment, amount_borrowed, value, startDate, principalFirstRepayment].some(i => i === null)) {
      setShowAmortizationLoanModal(true)
      return true;
    }
  };


  useEffect(() => {
    
    window.scrollTo(0,1);
    return () => {
      
    }
  }, []);
  
  const checkScroll = useCallback(() => {
    const { scrollY } = window;
    setIsHeaderFixed(scrollY > ( isWithBanner ? 112 : 64));
    if (tabsHeaderRef.current) {
      const { y } = tabsHeaderRef.current.getBoundingClientRect();
      // console.log(y);
      setIsTabsWrapperFixed(y < 74);
    }
    // documentsStore.uploadDocuments(acceptedFiles)
  }, [setIsHeaderFixed,setIsTabsWrapperFixed, isWithBanner]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', checkScroll);
    
    // Initial check to set the state correctly on mount
    setTimeout(checkScroll, 0);

    return () => window.removeEventListener('scroll', checkScroll);
  }, [checkScroll]);

  useEffect(() => {
    (async function getItem() {

      await itemStore.getItemData(id);
      wealthStore.setShouldCheckLastChangesWhenExitItemPage(false);
      handleAmortizationModal(itemStore);
      if (filtersStore.countActiveFilters === 0 ){
        filtersStore.handleSelectClass(itemStore.item.categoryClass.id);
        filtersStore.setAppliedFiltersFromSelection();
        wealthStore.refreshWealthData(false);
      }
    })();

    return () => {
      itemStore.setHoldingsView(true);
      tickersStore.setData([]);
      if (wealthStore.shouldCheckLastChangesWhenExitItemPage) {
        wealthStore.getLastChanges(true);
      }
      // wealthStore.exitAssetView();
    }
  }, [id, itemStore, filtersStore, wealthStore, tickersStore]);

  useEffect(() => {
    if (!activeTab || eligibleTabs.includes(activeTab) || (activeTab === 'vip-sync' && itemStore.isShowingVipSyncAwareness && ['TIER_1','TIER_2','TIER_3','TIER_4'].includes(userStore.data?.profile?.tier))) {
      itemStore.setActiveTabUrl(activeTab);
    } else {
      navigateToUrl(history, `/wealth/assets/${className}/${id}`, true);
    }
    // return () => { }
  }, [activeTab, itemStore, history, id, className, userStore]);

  useEffect(() => {
    wealthStore.setSelectedClassUrlName(className);
    //setSelectedTab(activeTab||'overview');
    return () => {
      //cleanup
    }
  }, [className, wealthStore]);

  const handleUnlockModalPromptClick = () => {
    userStore.updateSettings('promptDismissed', { unlockMagicBox: true });
    itemStore.updateItemSettings({ showUnlockMagicBoxModal: false });
  }

  const handleUpdateMetadata = () => {
    metadataStore.fetchData();
  }

  const handleItemClose = () => {
    itemStore.closeReopenAsset(itemStore.item.id, itemStore.item.closedAt, false, handleUpdateMetadata);
  }

  return (
    <Observer>{() => (
      <PageWrapper isHeaderFixed={isHeaderFixed}>
        {showAmortizationLoanModal && <AmortizationLoansInfoModal item={itemStore.item} onClose={closeAmortizationUpdateModal} onSave={updateAmortizationInitialDetails} />}
        {(!itemStore.isLoadedOnce || !itemStore.item?.id) ?
            <LogoLoader backdrop absolute/>
          :
          <>
          {!uiStore.isDesktopView && <WealthHero isOnlyHeader headerNotFixed withoutIcon/>}
            <ItemCardContentBoxWrapper>
              <AssetPageHeader isFixed={isHeaderFixed} />
              <AssetOverviewHeroContent />
            </ItemCardContentBoxWrapper>
            <CardContentBoxWrapper marginTop={uiStore.isDesktopView  ? 16 : 0}>
              <div ref={tabsHeaderRef} style={{width:'100%' , height: isTabsWrapperFixed ? '44px' : 0}}></div>
              <TabsWrapper isFixed={isTabsWrapperFixed}>

                <Tabs activeKey={itemStore.activeTab} onChange={handleTabChange} >
                  {/* <TabPane tab={<TabTitleWrapper>
                  <TabTitleText>Overview</TabTitleText></TabTitleWrapper>} key='overview' /> */}

                  { [1,11].includes(itemStore.item.categoryClass.id) && <TabPane tab={<TabTitleWrapper>
                    {/* <InfoTabIcon/> */}
                    <TabTitleText>Transactions</TabTitleText></TabTitleWrapper>} key='transactions' />}

                  {!(itemStore.item.categoryClass.id === 3 && itemStore.item.isConnected) && ( ![1,11].includes(itemStore.item.categoryClass.id) || !itemStore.item.isConnected) &&
                    <TabPane tab={
                      <TabTitleWrapper>
                        <TabTitleText hasAnyBadges={itemStore.transactionsEventNotificationsCount > 0 || itemStore.pastEventNotificationsCount > 0}>Events</TabTitleText>
                        <EventsTabIndicationsWrapper>
                          {itemStore.transactionsEventNotificationsCount > 0 && <TabTitleTransactionsCount>{itemStore.transactionsEventNotificationsCount}</TabTitleTransactionsCount>}
                          {itemStore.pastEventNotificationsCount > 0 && <TabTitlePassedEventsCount hasTrns={itemStore.transactionsEventNotificationsCount > 0} >{itemStore.pastEventNotificationsCount}</TabTitlePassedEventsCount>}
                        </EventsTabIndicationsWrapper>
                      </TabTitleWrapper>} key='events' />
                  }

                  {itemStore.item.categoryClass.id === 3 && itemStore.item.isConnected &&
                    <TabPane tab={
                      <TabTitleWrapper>
                        <TabTitleText>Holdings {tickersStore.tickers?.length ? `(${tickersStore.tickers.length})` : ''}</TabTitleText>
                      </TabTitleWrapper>} key='holdings' />
                  }

                  <TabPane tab={<TabTitleWrapper>
                    {/* <InfoTabIcon/> */}
                    <TabTitleText>{itemStore.item.categoryClass.isLiability ? 'Info & terms' : 'Asset info'} </TabTitleText></TabTitleWrapper>} key='info' />
                  <TabPane tab={<TabTitleWrapper>
                    {/* <DocumentsTabIcon/> */}
                    <TabTitleText>{uiStore.isDesktopView ? 'Documents' : 'Docs'} {documentsStore.documents.length && uiStore.isDesktopView ? `(${documentsStore.documents.length})` : ''}</TabTitleText></TabTitleWrapper>} key='documents' />

                    {itemStore.isShowingVipSyncAwareness && ['TIER_1','TIER_2','TIER_3','TIER_4'].includes(userStore.data?.profile?.tier) &&
                    <TabPane tab={
                      <TabTitleWrapper style={{gap:'2px'}}>
                        <TabTitleText>Vip Syncing</TabTitleText>
                        {!itemStore.item?.settings?.promptDismissed?.vipSyncAwareness ? <EventsTabIndicationsWrapper>
                          <TabTitleVipSyncCount>1</TabTitleVipSyncCount>
                        </EventsTabIndicationsWrapper> : null}
                      </TabTitleWrapper>} key='vip-sync' />
                  }
                </Tabs>

                {!uiStore.isDesktopView && <AssetActionsMenu />}
                {/* {!uiStore.isDesktopView && <MobilAssetActionsButtonWrapper onClick={()=>{setShowItemMobileMenu(true)}}><MobileThreeDots /></MobilAssetActionsButtonWrapper> } */}

                {!uiStore.isDesktopView && isTabsWrapperFixed && 
              <>
          
                  <MobileLeftCornerMask style={{position:'absolute', top:'0' , left:'0'}}/>
                <MobileRightCornerMask style={{position:'absolute', top:'0' , right:'0'}}/>
              </>
              }

              </TabsWrapper>
              <TabsContentWrapper id='tabs-content-wrapper'>


                {itemStore.activeTab === 'events' && <AssetEventsTab id={id} item={itemStore.item} isIncognito={uiStore.isIncognitoMode} handleAmortizationModal={handleAmortizationModal} />}

                {itemStore.activeTab === 'holdings' && <AssetsTickersTab id={id} item={itemStore.item} tickers={tickersStore.tickers} isIncognito={uiStore.isIncognitoMode} />}

                {itemStore.activeTab === 'transactions' && <CenterContent><AssetTransactionsTab id={id} item={itemStore.item} isIncognito={uiStore.isIncognitoMode} /></CenterContent>}

                {itemStore.activeTab === 'documents' &&  <AssetDocumentsTab /> }

                {itemStore.activeTab === 'info' && <CenterContent><AssetEditForm isIncognito={uiStore.isIncognitoMode} id={id} asset={itemStore.item} /></CenterContent>}

                {itemStore.activeTab === 'vip-sync' && <CenterContent><AssetVipSyncingTab id={id} item={itemStore.item} onClose={() => {navigateToUrl(history, `/wealth/assets/${className}/${id}`, true);}} /></CenterContent>}

              </TabsContentWrapper>

            </CardContentBoxWrapper></>}
            {itemStore?.itemSettings?.showUnlockMagicBoxModal && <UnlockMagicBoxModal onClose={handleUnlockModalPromptClick} />}
            {!uiStore.isDesktopView && <Drawer
              closable={false}
              visible={showItemMobileMenu}
            width={  340  }
            // height={'calc(100% - 32px)'}
            className={'asset_actions_DrawerContainer_mobile'}
            placement='bottom'
            destroyOnClose={true}
            onClose={()=>{setShowItemMobileMenu(false)}} >
              <h2>Hello</h2>
              <h2>Hello</h2>
              <h2>Hello</h2>
            </Drawer>}
            {itemStore.editTargetsModal.show && <EditTargetEvent field={itemStore.editTargetsModal.field} onClose={()=>{itemStore.setShowEditTargetModal(false,'')}} />}
            {itemStore.showCloseItemModal && <CloseItemModal onItemClose={handleItemClose} onClose={()=>{itemStore.setShowCloseItemModal(false)}} />}
            {itemStore.showCloseAccountNotFoundItemModal && <CloseAccountNotFoundItemModal onItemClose={handleItemClose} onClose={()=>{itemStore.setShowCloseAccountNotFoundItemModal(false)}} />}
            {itemStore.showOpenItemModal && <OpenItemModal userStore={userStore} itemName={itemStore.item.title} onClose={()=>{itemStore.setShowOpenItemModal(false)}} /> }
      </PageWrapper>
    )}</Observer>)
}

