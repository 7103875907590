import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CloseSymmetricIcon } from "../../../../assets/icons/common/common-icons";
import { BaseModal } from "../../../../common/components/BaseModal/BaseModal";
import { CalendlyPopupWidget } from "../../../../common/components/CalendlyPopupWidget/CalendlyPopupWidget";
import { startIntercomNewMessage } from "../../../../common/utils/intercom.utils";
import { navigateToUrl } from "../../../../common/utils/navigation.utils";
import { useStore } from "../../../app/data/root.store";
import { updateActivationStep } from "../../../user/data/user.service";
import { IntroModalCloseBtnWrapper, IntroModalContent, IntroModalHeader, StepActionButton, StepActionButtonLink, StepDescription, StepSingleButton } from "./DemoOverviewPane.styles";
import { ROUTES } from "../../../../common/constants/routes.const";

export function LearnAboutStep() {
    const { userStore } = useStore()
    const [showIntroVideoModal,setShowIntroVideoModal] = useState(false);

    const handleBookDemoCallOnClick = () => {
        updateActivationStep({'demo_flows.getStartedPane.bookACall' : true});
        userStore.setDemoStepAsComplete('bookACall');
      }

    return (
        <>
            <CalendlyPopupWidget onScheduleSuccess={handleBookDemoCallOnClick} triggerComponent={<StepActionButton fullWidth><CalendarIcon />Book a demo call</StepActionButton>} triggerWrapperStyles={{marginBottom: '8px'}} />
            <StepActionButton onClick={startIntercomNewMessage}><IntercomIcon />Chat with us</StepActionButton>
            <StepActionButton onClick={()=>{setShowIntroVideoModal(true)}}><WatchIntroIcon />Quick overview</StepActionButton>
            {showIntroVideoModal && <BaseModal width="560px" height="400px" borderRadius={4} onBackDropClick={()=>{setShowIntroVideoModal(false)}} >
                <IntroModalHeader><h2>Your Private Investments Concierge</h2><IntroModalCloseBtnWrapper onClick={()=>{setShowIntroVideoModal(false)}}><CloseSymmetricIcon /></IntroModalCloseBtnWrapper></IntroModalHeader>
                <IntroModalContent>
                    <iframe src='https://www.youtube.com/embed/b3JRKnUALng?rel=0&autoplay=1'
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'
                    width={'100%'}
                    height={304}
                    />
                </IntroModalContent>
            </BaseModal>}
        </>
        
    )
}

export function GetFamiliarCashFlowStep(){
    let history = useHistory();
    const { metadataStore , userStore , cashFlowStore} = useStore();

    const handleButtonClick = () => {
        cashFlowStore.setShouldStartLearnTour(true);
        navigateToUrl(history,ROUTES.cashflow.url);
        if (metadataStore.classWithItemsCount >= 2){
            updateActivationStep({'demo_flows.getStartedPane.cashFlowPlaning' : true});
            userStore.setDemoStepAsComplete('cashFlowPlaning');
        }
    }
    return (
        <DescriptionAndButtonStep 
            description={'Vyzer forecasts your expected cash flow through AI-driven projection scenarios so you can plan your financial future.'}
            buttonText={'Learn'}
            onButtonAction={handleButtonClick}
        />
    )
}

export function UploadYourFinancialStatementsStep(){
    let history = useHistory();
    const { userStore ,  magicBoxStore} = useStore();

    const handleButtonClick = () => {
        magicBoxStore.setShouldStartLearnTour(true);
        updateActivationStep({'demo_flows.getStartedPane.magicBox' : true});
        userStore.setDemoStepAsComplete('magicBox');
        navigateToUrl(history, ROUTES.magicBox.url);
        
    }
    return (
        <DescriptionAndButtonStep 
            description={'With one click, Vyzer sets up your portfolio and manages your assets.'}
            buttonText={'Learn more'}
            onButtonAction={handleButtonClick}
        />
    )
}


export function NeedMoreHelpStep() {
    const { userStore } = useStore();
    const handleBookDemoCallOnClick = () => {
        updateActivationStep({'demo_flows.getStartedPane.bookACall' : true});
        userStore.setDemoStepAsComplete('bookACall');
      }

    return (
        <>
            <StepDescription>Don’t hesitate to contact us.<br/>We're always here to assist.</StepDescription>
            <CalendlyPopupWidget onScheduleSuccess={handleBookDemoCallOnClick} triggerComponent={<StepActionButton fullWidth><CalendarIcon />Book a demo call</StepActionButton>} triggerWrapperStyles={{marginBottom: '8px'}} />
            <StepActionButton style={{marginBottom:'8px'}} fullWidth onClick={startIntercomNewMessage}><IntercomIcon />Chat with us</StepActionButton>
            <StepActionButtonLink target="_blank" href="https://vyzer.co/pricing#pricingFAQSection" fullWidth ><PricingFaqIcon /><span style={{flex:1}}>Pricing FAQ’s</span><SingleButtonArrowIcon /></StepActionButtonLink>
            <StepActionButtonLink target="_blank" href="https://vyzer.co/security" fullWidth ><SwcurityIcon /><span style={{flex:1}}>Security</span><SingleButtonArrowIcon /></StepActionButtonLink>
        </>
        
    )
}

export function ScheduleCallStep() {
    const { userStore } = useStore();
    const handleBookACallClick = () => {
        updateActivationStep({'demo_flows.getStartedPane.bookACall' : true});
        userStore.setDemoStepAsComplete('bookACall');
    }

    return (
        <>
            <StepDescription>To understand your needs and to walk you through, please schedule a 1:1 call</StepDescription>
            <CalendlyPopupWidget onScheduleSuccess={handleBookACallClick} triggerComponent={<StepActionButton fullWidth><CalendarIcon />Schedule a call</StepActionButton>} triggerWrapperStyles={{marginBottom: '8px'}} />
        </>
        
    )
}

const DescriptionAndButtonStep = (props) => {
    const {description,buttonText,onButtonAction } = props;
    return (
    <>
        <StepDescription>
            {description}
        </StepDescription>
        <StepSingleButton onClick={onButtonAction}>
            {/* {isPlusIcon && <SingleButtonPlusIcon /> } */}
            {buttonText}
            <ActionButtonIcon />
            {/* {!isPlusIcon && <SingleButtonArrowIcon /> } */}
        </StepSingleButton>
    </> )
}

const ActionButtonIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.1268 15.4986C10.0847 15.4995 10.0424 15.5 10 15.5C6.96243 15.5 4.5 13.0376 4.5 10C4.5 6.96243 6.96243 4.5 10 4.5C13.0376 4.5 15.5 6.96243 15.5 10C15.5 10.1606 15.4931 10.3195 15.4796 10.4766L16.9192 11.0669C16.9724 10.719 17 10.3627 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C10.2252 17 10.4479 16.9894 10.6676 16.9686L10.1268 15.4986Z" fill="#F7F8F9"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3697 9.20134C12.0365 8.21228 11.1014 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.0004 8.08759 11.8635 8.93648 12.2632L9.56695 13.9768C7.56149 13.7609 6 12.0628 6 10C6 7.79086 7.79086 6 10 6C12.1653 6 13.9288 7.72049 13.9979 9.86899L12.3697 9.20134Z" fill="#F7F8F9"/>
        <path d="M10 10L19 13.6906L15.305 15.6136L13.5871 19.75L10 10Z" fill="#F7F8F9"/>
    </svg>
)

const CalendarIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.33301 4.86838C3.33301 4.79167 3.39519 4.72949 3.47189 4.72949H16.5275C16.6042 4.72949 16.6663 4.79167 16.6663 4.86838V16.5906C16.6663 16.6673 16.6042 16.7295 16.5275 16.7295H3.47189C3.39519 16.7295 3.33301 16.6673 3.33301 16.5906L3.33301 4.86838ZM5.04052 6.43537C4.96382 6.43537 4.90164 6.49755 4.90164 6.57425V14.8847C4.90164 14.9614 4.96382 15.0236 5.04052 15.0236H14.9588C15.0355 15.0236 15.0977 14.9614 15.0977 14.8847V6.57425C15.0977 6.49755 15.0355 6.43537 14.9588 6.43537H5.04052Z" fill="#1A1B1D"/>
        <path d="M16 10L4 10L4 6L16 6L16 10Z" fill="#1A1B1D"/>
        <path d="M14.5308 3.27637L14.5308 7.08589L12.753 7.08589L12.753 3.27637L14.5308 3.27637Z" fill="#1A1B1D"/>
        <path d="M7.3335 3.27637L7.3335 7.08589L5.55572 7.08589L5.55572 3.27637L7.3335 3.27637Z" fill="#1A1B1D"/>
    </svg>
)

const IntercomIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6663 17.5C16.6663 17.5 14.4216 16.6305 12.6059 15.9344H4.96967C4.06634 15.9344 3.33301 15.1684 3.33301 14.2248V4.20812C3.33301 3.26547 4.06634 2.5 4.96967 2.5H15.0292C15.933 2.5 16.6659 3.265 16.6659 4.20859V12.8355L16.6663 17.5ZM14.6954 11.8834C14.6597 11.8384 14.6149 11.8011 14.5639 11.774C14.5128 11.7468 14.4566 11.7304 14.3988 11.7258C14.3409 11.7212 14.2827 11.7284 14.2279 11.7471C14.173 11.7657 14.1227 11.7954 14.0802 11.8342C14.0659 11.8464 12.6444 13.0909 9.9992 13.0909C7.38682 13.0909 5.94348 11.8553 5.91777 11.8328C5.87514 11.7942 5.82484 11.7647 5.77006 11.7462C5.71527 11.7277 5.65718 11.7206 5.59946 11.7253C5.54174 11.7301 5.48563 11.7465 5.43469 11.7736C5.38375 11.8008 5.33907 11.838 5.30348 11.883C5.22726 11.9766 5.18998 12.0952 5.1992 12.2148C5.20725 12.3339 5.26146 12.4453 5.35063 12.5261C5.41825 12.5861 7.03491 14.0027 9.9992 14.0027C12.964 14.0027 14.5806 12.5861 14.6482 12.5261C14.7372 12.4452 14.7913 12.3338 14.7992 12.2148C14.8084 12.0954 14.7713 11.977 14.6954 11.8834Z" fill="#1A1B1D"/>
    </svg>
)

const WatchIntroIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="14" height="14" rx="4.66667" fill="#1A1B1D"/>
        <path d="M8.1333 6.89589C8.1333 6.82386 8.21144 6.77898 8.27366 6.81528L13.5951 9.91945C13.6568 9.95546 13.6568 10.0447 13.5951 10.0807L8.27366 13.1849C8.21144 13.2211 8.1333 13.1763 8.1333 13.1042V6.89589Z" fill="white"/>
    </svg>
)

const PricingFaqIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="14" height="14" rx="7" fill="#1A1B1D"/>
    <path d="M10.4243 11.648H9.24833C9.22656 11.5064 9.20478 11.3649 9.20478 11.2016C9.20478 10.6244 9.44433 10.0909 10.065 9.62267L10.5877 9.23067C10.9143 8.99111 11.0559 8.69711 11.0559 8.37044C11.0559 7.86956 10.6966 7.39044 9.967 7.39044C9.19389 7.39044 8.85633 8.01111 8.85633 8.62089C8.85633 8.75156 8.86722 8.87133 8.89989 8.96933L7.52789 8.91489C7.48433 8.76244 7.47344 8.58822 7.47344 8.42489C7.47344 7.24889 8.35544 6.11644 9.967 6.11644C11.6548 6.11644 12.5368 7.18355 12.5368 8.28333C12.5368 9.14356 12.1012 9.75333 11.4588 10.2107L11.0123 10.5264C10.6312 10.7987 10.4243 11.1362 10.4243 11.5827V11.648ZM8.99789 13.2269C8.99789 12.7478 9.379 12.3558 9.85811 12.3558C10.3372 12.3558 10.7292 12.7478 10.7292 13.2269C10.7292 13.706 10.3372 14.0871 9.85811 14.0871C9.379 14.0871 8.99789 13.706 8.99789 13.2269Z" fill="white"/>
    <rect x="3" y="3" width="14" height="14" rx="7" stroke="#1A1B1D" strokeWidth="1.5"/>
</svg>
)

const SwcurityIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.09305 5.10173L7.12669 5.08442L7.15842 5.06382L10.5 2.89422L13.8416 5.06382L13.8733 5.08442L13.9069 5.10173L17.25 6.82126V7.21784C17.25 11.6194 14.5684 15.566 10.5 17.1962C6.43158 15.566 3.75 11.6194 3.75 7.21784V6.82126L7.09305 5.10173Z" fill="#1A1B1D" stroke="#1A1B1D" strokeWidth="1.5"/>
        <path d="M13.4848 7.66048L9.69067 11.4568L7.79158 9.55823" stroke="white" strokeWidth="1.5"/>
    </svg>
)


const SingleButtonArrowIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.1427 5.14325C6.1427 5.14325 10.0426 5.14325 11.8091 5.14325C11.8091 6.90982 11.8091 10.8097 11.8091 10.8097" stroke="#1A1B1D" strokeWidth="1.37143" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.57166 12.3841L11.5621 5.3936" stroke="#1A1B1D" strokeWidth="1.37143" strokeLinecap="round"/>
    </svg>
)