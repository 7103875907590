export const classIconsBase64 = {
  'cash': 'PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjUgM0MyLjk0NzcyIDMgMi41IDMuNDQ3NzIgMi41IDRWMjBDMi41IDIwLjU1MjMgMi45NDc3MSAyMSAzLjUgMjFIMjAuNUMyMS4wNTIzIDIxIDIxLjUgMjAuNTUyMyAyMS41IDIwVjE2Ljc1SDE3QzE0LjM3NjYgMTYuNzUgMTIuMjUgMTQuNjIzMyAxMi4yNSAxMkMxMi4yNSA5LjM3NjY0IDE0LjM3NjYgNy4yNDk5OSAxNyA3LjI0OTk5SDIxLjVWNEMyMS41IDMuNDQ3NzIgMjEuMDUyMyAzIDIwLjUgM0gzLjVaTTIxLjUgOC43NDk5OUgxN0MxNS4yMDUxIDguNzQ5OTkgMTMuNzUgMTAuMjA1MSAxMy43NSAxMkMxMy43NSAxMy43OTQ5IDE1LjIwNTEgMTUuMjUgMTcgMTUuMjVIMjEuNVY4Ljc0OTk5Wk02IDcuOTk5OTlIOS44ODg2OFY2LjQ5OTk5SDZWNy45OTk5OVpNMTguMzE4NiAxMi4wMjcyQzE4LjMxODYgMTIuNTc2MyAxNy44ODMzIDEzLjAyMTUgMTcuMzQ2NCAxMy4wMjE1QzE2LjgwOTUgMTMuMDIxNSAxNi4zNzQzIDEyLjU3NjMgMTYuMzc0MyAxMi4wMjcyQzE2LjM3NDMgMTEuNDc4IDE2LjgwOTUgMTEuMDMyOCAxNy4zNDY0IDExLjAzMjhDMTcuODgzMyAxMS4wMzI4IDE4LjMxODYgMTEuNDc4IDE4LjMxODYgMTIuMDI3MloiIGZpbGw9IiNmZmYiLz4KPC9zdmc+',
  'long': 'PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy45Njc1IDEwLjY4MzdDMTYuODc0IDguNjIyMDMgMTUuMTEzIDYuNzY0ODQgMTMuMjYwMSA1LjQzMzE3QzEyLjE2NTEgNC42NDYyIDExLjAxMyA0LjAyNDM2IDkuOTEyMTMgMy42NTA1QzkuNDYwMDMgMy40OTY5NiA5LjAwNDk0IDMuMzgxMzYgOC41NTgyNyAzLjMxNjRDMTMuMDg1MSAxLjY3NzIgMTguNDQxMiAzLjcyNTAyIDIxLjMzNiA4LjczODg5TDE3Ljk2NzUgMTAuNjgzN1pNMTYuNjY3NSAxMS40MzQyTDguNjg0MjggMTYuMDQzNEM3LjU3OTA2IDE0LjI5ODUgNi44OTUwNCAxMi4wNjA5IDYuNjgzNDYgOS45NDI4QzYuNTYwODggOC43MTU3NCA2LjYwMDI3IDcuNTU5NDYgNi43OTIyNSA2LjU5MzU1QzYuOTQ3NTMgNS44MTIzMiA3LjE5MzYyIDUuMTk5MjUgNy40OTcwOCA0Ljc2ODkxQzguMDIxNTEgNC43MjEyNiA4LjY3NTUyIDQuODE0NjcgOS40Mjk3NiA1LjA3MDgzQzEwLjM2MjMgNS4zODc1MiAxMS4zODMzIDUuOTMxNTUgMTIuMzg0NyA2LjY1MTIzQzE0LjExMzMgNy44OTM1MyAxNS43MDkgOS42MDQ2OCAxNi42Njc1IDExLjQzNDJaTTUuMTkwODggMTAuMDkxOUM1LjQxNzY5IDEyLjM2MjQgNi4xNDU2IDE0LjgxNiA3LjM4NDI1IDE2Ljc5MzlMNC4wMTU0NyAxOC43Mzg5QzEuMTIwNjIgMTMuNzI0OSAyLjAyNTM0IDguMDYyMjMgNS43MDg2NCA0Ljk2MTUzQzUuNTQxNTYgNS4zODA4NiA1LjQxNDEyIDUuODMyOCA1LjMyMTAzIDYuMzAxMTNDNS4wOTQzOSA3LjQ0MTQgNS4wNTY4NSA4Ljc1MDEgNS4xOTA4OCAxMC4wOTE5WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTEyLjcxNTkgMTUuMTcyMUwxNC4xNTkzIDE0LjMzODhMMTcuNzI5MyAyMC41MjIzTDE2LjI4NTkgMjEuMzU1NkwxMi43MTU5IDE1LjE3MjFaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNNi41Mjc5NCA0LjUwMjU3QzYuMTg5MDUgMy45MTU1OSA2LjA1Nzc3IDMuMzU2OTQgNi42MDkyNSAzLjAzODU0QzcuMTYwNzIgMi43MjAxNSA3LjU3ODg5IDMuMTEzMTcgNy45MTc3OCAzLjcwMDE0QzcuOTE3NzggMy43MDAxNCA3LjU1NTUxIDMuODA4MjIgNy4xNzkwOSA0LjAyNTU0QzYuODAyNjggNC4yNDI4NiA2LjUyNzk0IDQuNTAyNTcgNi41Mjc5NCA0LjUwMjU3WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+',
  'brokerage': 'PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjUgM0MyLjk0NzcyIDMgMi41IDMuNDQ3NzIgMi41IDRWMjBDMi41IDIwLjU1MjMgMi45NDc3MSAyMSAzLjUgMjFIMjAuNUMyMS4wNTIzIDIxIDIxLjUgMjAuNTUyMyAyMS41IDIwVjRDMjEuNSAzLjQ0NzcyIDIxLjA1MjMgMyAyMC41IDNIMy41Wk02Ljc1IDEyVjE3SDguNzVWMTJINi43NVpNMTEuMDAxNyAxN1Y4SDEzLjAwMTdWMTdIMTEuMDAxN1pNMTUuMjUzNCAxMFYxN0gxNy4yNTM0VjEwSDE1LjI1MzRaIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPg==',
  'real': 'PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMyAySDYuNVY2LjVIM0MyLjQ0NzcyIDYuNSAyIDYuOTQ3NzIgMiA3LjVWMjJIOC41VjE3LjVIMTFWMjJIMjFDMjEuNTUyMyAyMiAyMiAyMS41NTIzIDIyIDIxVjE1LjVIMTcuNVYxMUgxM1YyWk04LjcwMTk0IDYuMjk4MDZWNC4yMDE5NEgxMC43OTgxVjYuMjk4MDZIOC43MDE5NFpNNC4xOTgyIDEwLjgwMThWOC42OTgyMkg2LjMwMThWMTAuODAxOEg0LjE5ODJaTTguNjk4MiAxNS4zMDE4VjEzLjE5ODJIMTAuODAxOFYxNS4zMDE4SDguNjk4MlpNNC4xOTUxNCAxNy42OTUyVjE5LjgwNDlINi4zMDQ4NlYxNy42OTUySDQuMTk1MTRaTTQuMTk4MiAxMy4xOTgyVjE1LjMwMDNINi4zMDAyN1YxMy4xOTgySDQuMTk4MlpNOC42OTgyIDguNjk4MjJWMTAuODAxOEgxMC44MDE4VjguNjk4MjJIOC42OTgyWk0xMy4xOTUxIDE3LjY5NTFWMTkuODA0OUgxNS4zMDQ5VjE3LjY5NTFIMTMuMTk1MVpNMTcuNjk1MSAxOS44MDQ5VjE3LjY5NTJIMTkuODA0OVYxOS44MDQ5SDE3LjY5NTFaTTEzLjE5OSAxMy4xOTlWMTUuMzAxMUgxNS4zMDFWMTMuMTk5SDEzLjE5OVoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+',
  'funds': 'PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjAxMjI3IDExLjVMMTEuNSAxMS41QzEyLjA1MjMgMTEuNSAxMi41IDExLjk0NzcgMTIuNSAxMi41TDEyLjUgMjEuOTg3N0MxNy43OTA1IDIxLjcyNzIgMjIgMTcuMzU1MiAyMiAxMkMyMiA2LjQ3NzE1IDE3LjUyMjggMiAxMiAyQzYuNjQ0OCAyIDIuMjcyNzggNi4yMDk0NCAyLjAxMjI3IDExLjVaIiBmaWxsPSIjZmZmIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTAuNSAyMS44ODgyQzYuMTc4MDMgMjEuMjM4MSAyLjc2MTg4IDE3LjgyMiAyLjExMTc2IDEzLjVIMTAuNUwxMC41IDIxLjg4ODJaIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPgo=',
  'loans': 'Cjxzdmcgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTguNzY2NSAyLjgxMzUyQzE0LjcxMTggLTAuMTgwODc5IDkuMTIzNDMgLTAuMTQ4MDkxIDUuMTAyMjggMi45MTE4OEw5LjQzMDM0IDcuMjM5OTRDMTAuOTc5IDYuNDAzMTEgMTIuODQ5OCA2LjM3NjkzIDE0LjQxODYgNy4xNjE0TDE4Ljc2NjUgMi44MTM1MlpNNy4yMDY4NyA5LjQ5MjVMMi44NzA4NyA1LjE1NjVDLTAuMTQ4MzM1IDkuMTczNTUgLTAuMTY3NDU3IDE0LjczIDIuODEzNTEgMTguNzY2NUw3LjE2MTM5IDE0LjQxODdDNi4zODczOCAxMi44NzA4IDYuNDAyNTQgMTEuMDI4OSA3LjIwNjg3IDkuNDkyNVpNMjAuOTkyNSA0Ljk3ODM0TDE2LjY4MTIgOS4yODk1NkMxNy42MjMyIDEwLjkxNCAxNy42NDk1IDEyLjkyMzggMTYuNzYwMSAxNC41Njk3TDIxLjA4ODEgMTguODk3N0MyNC4yMTI4IDE0Ljc5MTYgMjQuMTgwOSA5LjA1MTE4IDIwLjk5MjUgNC45NzgzNFpNMTguODQzNSAyMS4xMjkxTDE0LjUwNzUgMTYuNzkzMUMxMi44NzM0IDE3LjY0ODcgMTAuODkzNiAxNy42MTE0IDkuMjg5NTUgMTYuNjgxM0w0Ljk3ODMzIDIwLjk5MjVDOS4wMzMyNyAyNC4xNjY5IDE0Ljc0MTEgMjQuMjEyNSAxOC44NDM1IDIxLjEyOTFaIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPg==',
  'businesses': 'Cjxzdmcgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTUuNSAyQzE2LjMyODQgMiAxNyAyLjY3MTU3IDE3IDMuNUwxNyA3QzE3IDcuNTUyMjkgMTYuNTUyMyA4IDE2IDhDMTUuNDQ3NyA4IDE1IDcuNTUyMjkgMTUgN0wxNSA0TDkgNEw5IDdDOSA3LjU1MjI4IDguNTUyMjggOCA4IDhDNy40NDc3MSA4IDcgNy41NTIyOCA3IDdMNyAzLjVDNyAyLjY3MTU3IDcuNjcxNTcgMiA4LjUgMkwxNS41IDJaIiBmaWxsPSIjZmZmIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMyA2LjI1QzIuNTg1NzkgNi4yNSAyLjI1IDYuNTg1NzkgMi4yNSA3VjIxQzIuMjUgMjEuNDE0MiAyLjU4NTc5IDIxLjc1IDMgMjEuNzVIMjFDMjEuNDE0MiAyMS43NSAyMS43NSAyMS40MTQyIDIxLjc1IDIxVjdDMjEuNzUgNi41ODU3OSAyMS40MTQyIDYuMjUgMjEgNi4yNUgzWk05IDlWMTNIN1Y5SDlaTTE3IDEzVjlIMTVWMTNIMTdaIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPg==',
  'crypto': 'PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMC41IDMuNUgxNlYxSDEzVjMuNUgxMVYxSDhWMy41SDMuNVY4SDFWMTFIMy41VjEzSDFWMTZIMy41VjIwLjVIOFYyM0gxMVYyMC41SDEzVjIzSDE2VjIwLjVIMjAuNVYxNkgyM1YxM0gyMC41VjExSDIzVjhIMjAuNVYzLjVaTTcgN0gxN1YxN0g3VjdaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNOSA5SDE1VjE1SDlWOVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPg==',
  'other': 'Cjxzdmcgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTIuNzExMSAyMS43Mjk2TDIzLjUzODUgMTBIMTcuODQ2TDEyLjcxMTEgMjEuNzI5NlpNMTYuNTM2IDEwTDEyIDIwLjM2MTdMNy40NjQgMTBIMTYuNTM2Wk0xNy45NDUxIDguNUgyMy41TDIxLjI3NjQgNC4wNTI3OUMyMS4xMDcgMy43MTQgMjAuNzYwNyAzLjUgMjAuMzgyIDMuNUgxNi44ODU5TDE3Ljk0NTEgOC41Wk0xNS42NTkzIDMuNUwxNi43MTg1IDguNUg3LjI4MTU1TDguMzQwNzQgMy41SDE1LjY1OTNaTTYuMDU0OTIgOC41TDcuMTE0MTEgMy41SDMuNjE4MDRDMy4yMzkyNyAzLjUgMi44OTMwMSAzLjcxNCAyLjcyMzYyIDQuMDUyNzlMMC41MDAwMDkgOC41SDYuMDU0OTJaTTExLjI4ODkgMjEuNzI5N0w2LjE1NDA1IDEwSDAuNDYxNTQ4TDExLjI4ODkgMjEuNzI5N1oiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==',
  'lia-loans': 'PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOS44NjQzIDMuODk5NDFINC4xMzU2OVYyLjA5OTQxSDE5Ljg2NDNWMy44OTk0MVpNMjAuNDAwMSAyMS44OTk0SDMuNjAwMTVWMjAuMDk5NEgyMC40MDAxVjIxLjg5OTRaTTQgMThMMjAgMThDMjAgMTMuOTIgMTUuNDI4NiAxMiAxNS40Mjg2IDEyQzE1LjQyODYgMTIgMTkuNDI4NiAxMC4wOCAxOS40Mjg2IDZMNC41NzE0MyA2QzQuNTcxNDMgMTAuMDggOC41NzE0MyAxMiA4LjU3MTQzIDEyQzguNTcxNDMgMTIgNCAxMy45MiA0IDE4WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==',
  'lia-credit': 'PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjUgNEMxLjk0NzcyIDQgMS41IDQuNDQ3NzIgMS41IDVWOEgyMi41VjVDMjIuNSA0LjQ0NzcyIDIyLjA1MjMgNCAyMS41IDRIMi41Wk0yMi41IDEwSDEuNVYxOEMxLjUgMTguNTUyMyAxLjk0NzcyIDE5IDIuNSAxOUgyMS41QzIyLjA1MjMgMTkgMjIuNSAxOC41NTIzIDIyLjUgMThWMTBaIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPg==',
  'lia-tax': 'PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAyQzYuNDc3MTUgMiAyIDYuNDc3MTUgMiAxMkMyIDE3LjUyMjggNi40NzcxNSAyMiAxMiAyMkMxNy41MjI4IDIyIDIyIDE3LjUyMjggMjIgMTJDMjIgNi40NzcxNSAxNy41MjI4IDIgMTIgMlpNOC4zNTI4OCA5LjMxNjk5QzkuMDcwMzIgOS43MzEyIDkuOTg3NzEgOS40ODUzOSAxMC40MDE5IDguNzY3OTVDMTAuODE2MSA4LjA1MDUxIDEwLjU3MDMgNy4xMzMxMyA5Ljg1Mjg4IDYuNzE4OTFDOS4xMzU0NCA2LjMwNDcgOC4yMTgwNiA2LjU1MDUxIDcuODAzODQgNy4yNjc5NUM3LjM4OTYzIDcuOTg1MzkgNy42MzU0NCA4LjkwMjc4IDguMzUyODggOS4zMTY5OVpNMTUuNjE2IDcuNzM2ODZMMTMuODg0IDYuNzM2ODZMOC4zODM5OCAxNi4yNjMxTDEwLjExNiAxNy4yNjMxTDE1LjYxNiA3LjczNjg2Wk0xNi4xOTYyIDE2LjczMkMxNS43ODIgMTcuNDQ5NCAxNC44NjQ2IDE3LjY5NTMgMTQuMTQ3MSAxNy4yODFDMTMuNDI5NyAxNi44NjY4IDEzLjE4MzkgMTUuOTQ5NCAxMy41OTgxIDE1LjIzMkMxNC4wMTIzIDE0LjUxNDYgMTQuOTI5NyAxNC4yNjg3IDE1LjY0NzEgMTQuNjgzQzE2LjM2NDYgMTUuMDk3MiAxNi42MTA0IDE2LjAxNDYgMTYuMTk2MiAxNi43MzJaIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPg==',
}