import debounce from 'lodash/debounce';
import { useRef, useState } from 'react';
import { Observer } from 'mobx-react'
import { CryptoAutoCompleteWrapper, CryptoResultsListWrapper, ListAndLoaderWrapper, LoadMoreLoaderWrapper, SearchingLoaderWrapper, SearchInput, SearchInputAndResultsWrapper, } from './CryptoAutoComplete.styles';
// import { useStore } from '../../../../../modules/app/data/root.store';
import { LogoLoader } from '../../../LogoLoader/LogoLoader';
import { 
  fetchCryptoCoinUsdValue,
  // searchInstitutions,
   searchCryptoCoins } from '../../../../../modules/add-item/data/add-item.service';
// import { investorsDefaultInstitutions, banksDefaultInstitutions } from '../../../../../modules/add-item/data/create-item-institutions.const';
import { CryptoAutoCompleteResultRow } from './CryptoAutoCompleteResultRow';
import { defaultCryptoCoins } from '../../../../../modules/add-item/data/create-item-crypto-coins.const';
import { errorNotification, successNotification } from '../../../../../modules/layout/components/Notifications/notifications';

const inputSearchDebounce = debounce((cb) => { cb() }, 1000);
const scrollBottomDebounce = debounce((cb) => { cb() }, 200);

export const CryptoAutoComplete = (props) => {
  const { value, onChange, 
    // classId,
     showOnlyManual } = props;
  const [searchInput, setSearchInput] = useState(value?.text || '');
  const [searchMeta, setSearchMeta] = useState(null);
  const [isLoadingMoreCryptoCoins, setIsLoadingMoreCryptoCoins] = useState(false);
  const [isSearchingCryptoCoins, setIsSearchingCryptoCoins] = useState(false);
  const [cryptoSearchResults, setCryptoSearchResults] = useState([...defaultCryptoCoins]);
  const [isFocusingInput, setIsFocusingInput] = useState(false);

  // const { metadataStore } = useStore();
  const listRef = useRef();

  const searchForInst = async (str) => {
    if (str === '') {
      // const defaultInsts = classId === 1 ? banksDefaultInstitutions : investorsDefaultInstitutions;
      setSearchResults('', null, []);
      // setSearchResults('', null, defaultInsts);
      setIsSearchingCryptoCoins(false);
    } else {
      setIsSearchingCryptoCoins(true);
      try {
        // if (!showOnlyManual) {
        //   const data = await searchInstitutions(str);
        //   setSearchResults(str, data.meta, data.items);
        // } else {
          const data = await searchCryptoCoins(str);
          setSearchResults(str, data.meta, data.items);
          // setSearchResults(str, null, []);
        // }
      }
      //catch (e) {} 
      finally {
        setIsSearchingCryptoCoins(false);
      }
    }
  }

  const setSearchResults = (str, meta, items) => {
    const strLc = str.toLowerCase();
    setSearchInput(strLc);
    setSearchMeta(meta);
    // const userInstitutions = (strLc ? metadataStore.sortedCryptoCoins.filter(item => item.value.toLowerCase().includes(strLc)) : metadataStore.sortedCryptoCoins).map(item => ({
    //   name: item.value, isManual: true
    // }));
    // setCryptoSearchResults([
    //   ...userInstitutions,
    //   ...((userInstitutions.length > 0 && items.length > 0) ? [{ isSeparator: true }] : []),
    //   ...items]);
    setCryptoSearchResults([...items])
  }

  const checkForLoadingMore = async () => {
    if (searchMeta !== null && searchMeta.currentPage < searchMeta.totalPages && !showOnlyManual) {
      setIsLoadingMoreCryptoCoins(true);
      try {
        const data = await searchCryptoCoins(searchInput, searchMeta.currentPage + 1, searchMeta.itemsPerPage);
        // const data = await searchInstitutions(searchInput, searchMeta.currentPage + 1, searchMeta.itemsPerPage);
        setSearchResults(searchInput, data.meta, [...cryptoSearchResults.filter(i => i.id), ...data.items]);
      }
      //catch (e) {} 
      finally {
        setIsLoadingMoreCryptoCoins(false);
      }
    }
  }

  const handleChange = (e) => {
    onChange && onChange({ inst: null, text: e.target.value });
    setIsSearchingCryptoCoins(true);
    inputSearchDebounce(() => {
      searchForInst(e.target.value);
    })
  }

  const handleFocusIn = () => {
    setSearchResults(searchInput, searchMeta, cryptoSearchResults.filter(i => i.id));
    setIsFocusingInput(true);
  }
  const handleInstClick = async (inst) => {
    // console.log(inst);
    let usdValue;
    try {
      usdValue = await fetchCryptoCoinUsdValue(inst.symbol);
      successNotification('Successfully fetched usd value');
    } catch (error) {
      errorNotification('Failed fetching usd value');
    }
    onChange && onChange({ inst: inst.guid, text: inst.name, isSelectedCoinFromDropdown: true, usdValue: usdValue || null });
  }

  const onScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        scrollBottomDebounce(() => {
          checkForLoadingMore();
        })
      }
    }
  };

  return (<Observer>{() => (
    <CryptoAutoCompleteWrapper>
      <SearchInputAndResultsWrapper>
        <SearchInput type="text"
          value={value?.text}
          onChange={handleChange}
          onFocus={() => { handleFocusIn() }}
          onBlur={() => setIsFocusingInput(false)}
        />
        {isFocusingInput && (cryptoSearchResults.length > 0 || isSearchingCryptoCoins) && <ListAndLoaderWrapper>
          <CryptoResultsListWrapper ref={listRef} onScroll={onScroll}>
            {cryptoSearchResults.map((inst, ind) => (
              <CryptoAutoCompleteResultRow key={ind} searchStr={value?.text} inst={inst} isSeparator={inst.isSeparator} handleItemClick={handleInstClick} />
            ))}
            {isLoadingMoreCryptoCoins && <LoadMoreLoaderWrapper> <LogoLoader wbg /> </LoadMoreLoaderWrapper>}
          </CryptoResultsListWrapper>
          {isSearchingCryptoCoins && <SearchingLoaderWrapper > <LogoLoader wbg /> </SearchingLoaderWrapper>}
        </ListAndLoaderWrapper>}
      </SearchInputAndResultsWrapper>
    </CryptoAutoCompleteWrapper>
  )}</Observer>)
}