import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors, views } from "../../../../common/styles/theme.styles";
import { NavLink } from "react-router-dom";


export const SharedPortfolioBannerWrapper = styled.div`
   grid-area: logo;
   background:   ${props=> props.isOwner ? '#2F3236' : props.color};
   ${flexCenter};
   justify-content: flex-start;
   padding:0 12px 0 20px;
   gap:8px;
   cursor: pointer;
   :hover {
      filter: brightness(90%);
   }
   ${views.desktopWithMinimizedMenu}{
      gap:4px;
   }
`;

export const IconWrapper = styled.div`
  ${flexCenter}
  height: 30px;
  width: 30px;
  min-width: 30px;
  background-color: ${props=> props.isOwner ? '#111213' : 'rgba(255, 255, 255, 0.37)'};
  border: 1px solid gba(255, 255, 255, 0.56);
  border-radius: 4px;
`;

export const IconLetter = styled.div`
  color:  ${props=> props.isOwner ? '#fff' : '#111213'};
  font-family: Domine;
  font-size: 18px;
  font-weight: 400;
`;

export const SharedPortfolioName = styled.div`
  flex:1;
  color:  ${props=> props.isOwner ? '#fff' : '#111213'};
  font-family: Domine;
  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SharedPortfoliosMenuWrapper = styled.div`
  width: 264px;
  background-color: rgba(26, 27, 29, 0.95);
  border-radius: 8px;
  padding:16px;
  border:1px solid ${colors.darkGray2};
  box-shadow:  0px 4px 4px 0  px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow-y: auto;
`;

export const MenuTitle = styled.div`
  font-size: 15px;
  color:#fff;
  font-weight: 450;
  padding:0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuSubTitle = styled.div`
  font-size: 13px;
  color:${colors.gray2};
  font-weight: 450;
  line-height: 18px;
  margin-bottom: 16px;
  padding-left:8px;
`;

export const PortfolioItemWrapper = styled.div`
   ${flexCenter}
   width:100%;
   border-radius: 6px;
   padding:0 10px;
   height: 40px;
   min-height: 40px;
   ${props=>props.isSelected ? `
    background-color: ${colors.darkGray2};
   ` : `
    cursor: pointer;
    :hover{
      background-color: #2F3236;  
    } 
   `};
`;


export const RolesNameIconWrapper = styled.div`
    ${flexCenter};
    width: 24px;
    min-width: 24px;
    height:24px;
    border-radius: 6px;
    margin-right: 6px;
    color:${colors.darkGray1};
    background-color: ${props=> props.color}; 
    font-weight: 400;
    font-family: Domine;
    ${props=>props.isOwner ? `
      color: #fff;
      background-color: #111213;
      border:1px solid #fff;
    `:``}
`

export const RoleIconLetter = styled.div`
  font-size: 16px;
  line-height: 16px;
  margin-top: 2px
`;

export const RoleName = styled.div`
    font-size: 14px;
    font-weight: 450;
    color: #fff;
    flex:1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SeparatorLine = styled.div`
    height: 1px;
    width: 100%;
    background-color: #3F444F;
    margin:8px 0;
`;

export const SwitchPortfolioText = styled.div`
  font-size: 12px;
  font-weight: 450;
  color:${colors.gray2};
  margin-bottom: 8px; 
`;

export const MobileSwitchPortfolioText = styled.div`
  color:${props=> props.isOwner ? '#fff' : colors.darkGray1};
  font-size: 14px;  
  font-weight: 450;
`;

export const MobileSharedPortfolioBannerWrapper = styled.div`
  ${flexCenter}
  width: 100%;
  background: ${props=> props.isOwner ? '#2F3236' : props.color};
  color:  ${props=> props.isOwner ? '#fff' : '#111213'};
  height: 48px;
  min-height: 48px;
  display: flex;
  gap:8px;
  padding:0 12px 0 16px;
  ${props=>props.rounded ? `
    border-radius: 12px;
  `:``}
`;

export const SwitchLink = styled(NavLink)`
    ${flexCenter};
    gap:8px;
    text-decoration: none;
`