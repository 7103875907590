import { Player } from "@lottiefiles/react-lottie-player";
import magicBoxAiAnimatedIconData from '../assets/magic-box-animated-icon-gray.json'
import styled from "@emotion/styled/macro";

export const MagicBoxAnimatedIconGrey = (props) => {
  const width = props?.width || '24px';
  const margin = props?.margin || '0';
  const display = props?.display || 'block';

  return (
    <MagicBoxAnimatedIconStyled fill={props.fill}>
      <Player
        loop={true}
        src={magicBoxAiAnimatedIconData}
        speed={1}
        autoplay
        style={{ width: width, margin: margin, display: display }}
      />
    </MagicBoxAnimatedIconStyled>
  );
};

const MagicBoxAnimatedIconStyled = styled.div`
  g {
    path {
      fill: ${({ fill }) => fill};
    }
  }
    
  display: flex;
  height: 100%;
  align-items: center;
`;