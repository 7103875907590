import React, { useState } from 'react';
import { ModalCloseIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { SecondaryButton } from '../../../../common/components/buttons/NewButtons.styles';
import { Radio } from '../../../../common/components/form-displayer/input-types/Radio/Radio';
import { ModalClose, ModalContent, ModalFooter, ModalHeader, ModalTitle } from '../../../wealth/pages/asset/components/Events/AssetEventModal.styles';
import { InstitutionLogo } from '../../utils/InstitutionLogo';
import { ConnectedAccountActionPrefixIcon, ConnectedAccountActionPrefixSelectedIcon } from '../icons/connected-institutions-icons';
import { ModalPromptWrapper, PromptQuestion, RadioSelectedOptionWrapper } from './ConnectedInstitutionModal.styles';

export const ConnectedInstitutionDeleteModal = ({ handleDelete, onClose, title, connectedAccountsLength, institution, provider, hideDeleteOption, isDesktopView }) => {
  const radioOptions = [
    {
      prefixIcon: <RadioSelectedOptionWrapper><ConnectedAccountActionPrefixIcon /></RadioSelectedOptionWrapper>,
      prefixSelectedIcon: <ConnectedAccountActionPrefixSelectedIcon style={{ marginRight: '2px' }} />,
      label: connectedAccountsLength > 1 ? 'Manage the assets manually' : 'Manage this asset manually',
      value: 'manual',
      customOptionStyle: { display: 'flex', alignItems: 'center', gap: '10px' },
    },
    {
      prefixIcon: <RadioSelectedOptionWrapper><ConnectedAccountActionPrefixIcon /></RadioSelectedOptionWrapper>,
      prefixSelectedIcon: <ConnectedAccountActionPrefixSelectedIcon style={{ marginRight: '2px' }} />,
      label: connectedAccountsLength > 1 ? 'Close the assets (history will be saved)' : 'Close this asset (history will be saved)',
      value: 'archive',
      customOptionStyle: { display: 'flex', alignItems: 'center', gap: '10px' },
    },
    ...(hideDeleteOption ? [] : [{
      prefixIcon: <RadioSelectedOptionWrapper><ConnectedAccountActionPrefixIcon /></RadioSelectedOptionWrapper>,
      prefixSelectedIcon: <ConnectedAccountActionPrefixSelectedIcon style={{ marginRight: '2px' }} />,
      label: connectedAccountsLength > 1 ? 'Delete the assets and all associated history' : 'Delete this asset and all its history',
      value: 'delete',
      customOptionStyle: { color: '#FF0000', display: 'flex', alignItems: 'center', gap: '10px' },
    }]),
  ]
  const [value, setValue] = useState('');

  return (
    <BaseModal onBackDropClick={onClose} width={'600px'} height={'auto'} borderRadius={'8'}>
      <ModalHeader style={{ boxShadow: 'inset 0px -1px 0px #DDE3EB', alignItems: 'center', gap: '12px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', borderBottom: 'none' }}>
        <InstitutionLogo institution={institution} provider={provider} customWrapperStyles={{ marginLeft: '12px' }} />
        <ModalTitle>
          Delete {title}
        </ModalTitle>
        <ModalClose onClick={onClose}>
          <ModalCloseIcon />
        </ModalClose>
      </ModalHeader>
      <ModalContent style={{ gap: '24px', backgroundColor: '#FDFEFE', padding: isDesktopView ? '24px 68px 32px 68px' : '16px' }}>
        <ModalPromptWrapper>
          <PromptQuestion>Are you sure you want to delete {title}?</PromptQuestion>

          {connectedAccountsLength > 0 ? <>
          {connectedAccountsLength === 1 ? `There is 1 connected account ` : `There are ${connectedAccountsLength} connected accounts `}
           associated with {title}. Please select how to proceed:
          <Radio
            value={value}
            options={radioOptions}
            onChange={(val) => { setValue(val) }}
            customWrapperStyle={{ flexDirection: 'column', gap: '8px' }}
          /></> : null}
        </ModalPromptWrapper>
      </ModalContent>
      <ModalFooter style={{ boxShadow: 'inset 0px -1px 0px #DDE3EB', display: 'flex', justifyContent: 'space-between', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
        <SecondaryButton onClick={onClose} style={{ minHeight: '38px', padding: '5px 16px' }}>Cancel</SecondaryButton>
        <SecondaryButton style={{ minHeight: '38px', color: '#FF0000', gap: '4px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)' }} onClick={()=>{!(value === '' && connectedAccountsLength > 0) && handleDelete?.(value)}} disabled={value === '' && connectedAccountsLength > 0}><EraseSvg />Delete {title}</SecondaryButton>
      </ModalFooter>
    </BaseModal>
  )
}

const EraseSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.25 9.5C18.25 9.5 17.7262 15.9022 17.4194 18.9881C17.369 19.4947 16.9419 19.8757 16.4328 19.876L7.65189 19.8811C7.14227 19.8814 6.7143 19.5 6.66174 18.9931C6.34059 15.8953 5.75 9.5 5.75 9.5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M19.5 6.55273H4.5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M16.5 6.55288C15.4225 6.55288 15.0944 5.67353 15.042 4.75719C15.004 4.09153 14.5116 3.5 13.8448 3.5H10.1552C9.48842 3.5 8.99604 4.09153 8.95799 4.75719C8.90562 5.67353 8.57753 6.55288 7.5 6.55288" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}