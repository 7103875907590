import React, { useRef, useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import { DataLayersIconWithText, DataLayersIconWrapper, MobileDataLayerButtonText, MobileDataLayerButtonWrapper, MobileDataLayersTableCell, MobileSortButtonWrapper, SortHoverIconWrapper, TableHeaderCell, TableHeaderSortIconWrapper, TableHeaderTextWrapper, } from '../WealthOverviewPage.styles';
import { useStore } from '../../../../../app/data/root.store';
import { ReactComponent as DataLayersIcon } from '../assets/data-layers-icon.svg';
import { ReactComponent as SortIcon } from '../assets/sort-icon.svg';
import { ReactComponent as SortHoverIcon } from '../assets/sort-hover-icon.svg';
import { BulkEditHeaderCancelEditButton, BulkEditHeaderEditButton, BulkEditHeaderText, BulkEditHeaderWrapper, CancelButtonIconWrapper, ItemSelectedCheckbox, ItemSelectedCheckboxWrapper, TableHeaderWrapper } from '../../../class/components/ClassPage.styles';
import { ItemsListHeaderTabs } from './ItemsListHeaderTabs';
import { MobileLeftCornerMask, MobileRightCornerMask } from '../../../../../../assets/icons/common/common-icons';
import { AssetActionMenuContent, AssetActionMenuItem, AssetActionMenuItemChild, AssetActionMenuWrapper } from '../../../asset/components/AssetPageHeader.styles';
import { Dropdown } from 'antd';
import { BulkEditModal } from './BulkEditModal/BulkEditModal';
import { ItemSelectedIcon } from './ItemsListView';
import { colors } from '../../../../../../common/styles/theme.styles';
import { ActionLockedIconWithTooltip } from '../../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { EraseIcon } from '../../../asset/Icons/icons'


const dataLayersDefaultSortByDirectionAsc = ['class', 'category', 'customClass', 'customSubClass'];
export function ItemsListHeader(props) {
  const { itemStore, wealthStore, uiStore, userStore, billingStore, filtersStore, dataLayersStore, inflowOutflowDataLayersStore } = useStore();
  const [isTableHeaderFixed, setIsTableHeaderFixed] = useState(false);
  const [isShowBulkEditModal, setIsShowBulkEditModal] = useState(false);
  const tableHeaderRef = useRef(null)
  const handleColumHeaderClick = (dl) => {
    if (dataLayersStore.sortBy === dl.dataKey) {
      dataLayersStore.setSortDirection(dataLayersStore.sortByColumn.direction === 'asc' ? 'desc' : 'asc');
    } else {
      dataLayersStore.setSortBy(dl.dataKey);
      dataLayersStore.setSortByColumn({ ...dl, direction: dataLayersDefaultSortByDirectionAsc.includes(dl.dataKey) ? 'asc' : 'desc' });
    }
  }

  const handleMobileSortClick = () => {
    dataLayersStore.setSortDirection(dataLayersStore.sortByColumn.direction === 'desc' ? 'asc' : 'desc');
  }

  const getValueColumnName = () => {
    if (wealthStore.selectedClass?.id === 10) {
      return 'Balance'
    }

    if (['crypto-holdings', 'holdings', 'items'].includes(wealthStore.listViewMode) || (wealthStore.isAllocationsViewMode && wealthStore.allocateBy === 'accounts')) { return 'Asset value' }

    return wealthStore.isAllocationsViewMode && ['classes', 'categories'].includes(wealthStore.allocateBy) ? 'Total value' : 'Assets value'
  }

  const checkScroll = () => {

    if (tableHeaderRef.current) {
      const { y } = tableHeaderRef.current.getBoundingClientRect();
      // console.log(y);
      setIsTableHeaderFixed(y < 64);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);

    // Initial check to set the state correctly on mount
    checkScroll();

    return () => window.removeEventListener('scroll', checkScroll);
  }, []);

  const onEditAttribute = (attrKey) => {
    if (userStore.isActionBlockedByRole('bulkEdit')) {
      userStore.setShowLimitedSharedAccess(true);
    } else if (userStore.isActionBlockedByPaymentStatus('bulkEdit')) {
      billingStore.setShowPricingTableModal(true);
    } else {
      wealthStore.setSelectedAttributeToEdit(attrKey);
      setIsShowBulkEditModal(true);
    }
  }

  const handleSaveBulkEditChanges = async (value) => {
    if('bulkDelete' === value) {
      await wealthStore.bulkDelete();
    } else{
      wealthStore.saveBulkEditChanges(value);
    }

    itemStore.setItemUpdated(true);
    setIsShowBulkEditModal(false);
  }

  return (<Observer>{() => (
    <>
      <div ref={tableHeaderRef} style={{ width: '100%', height: isTableHeaderFixed ? '50px' : 0 }}></div>
      <TableHeaderWrapper
        isFixed={isTableHeaderFixed}
        withStickyBreadcrumbs={filtersStore.countActiveFilters > 0}
        isWithDataLayersButton={uiStore.isDesktopView}
        matricesColumns={wealthStore.viewType === 'cashflow' ? (uiStore.isDesktopView ? inflowOutflowDataLayersStore.columns.length : 1) : (dataLayersStore.dataLayersHeaders.length)}
        isCashFlowDataView={wealthStore.viewType === 'cashflow'}
      >

        <ItemsListHeaderTabs />

        {/* {wealthStore.viewType === 'overview' && wealthStore.listViewMode !== 'holdings' && <> */}
        {wealthStore.viewType === 'overview' && <>
          {uiStore.isDesktopView ? <>
            <DataLayersIconWithText>
              <DataLayersIconWrapper onClick={() => { dataLayersStore.setShowDrawer(true) }}><DataLayersIcon /> Adjust</DataLayersIconWrapper>
            </DataLayersIconWithText>

            {dataLayersStore.dataLayersHeaders.map(dl => (
              <TableHeaderCell key={dl.dataKey} clickable onClick={() => { handleColumHeaderClick(dl) }}>
                {dl.htmlTitle ? <TableHeaderTextWrapper dangerouslySetInnerHTML={{ __html: dl.htmlTitle }} /> :
                  <TableHeaderTextWrapper>
                    {dl.title}
                    {dl.subTitle ? <><br />{dl.subTitle}</> : null}
                  </TableHeaderTextWrapper>}
                {dataLayersStore.sortBy === dl.dataKey ? <TableHeaderSortIconWrapper isAsc={dataLayersStore.sortByColumn.direction === 'asc'}>
                  {/* V */}
                  <SortIcon />
                </TableHeaderSortIconWrapper> : null}
                {dataLayersStore.sortBy !== dl.dataKey ? <SortHoverIconWrapper><SortHoverIcon /></SortHoverIconWrapper> : null}
              </TableHeaderCell>
            ))}
            <TableHeaderCell isValue clickable onClick={() => { handleColumHeaderClick({ dataKey: 'usdValue' }) }}>
              {dataLayersStore.sortBy === 'usdValue' ? <TableHeaderSortIconWrapper isValue isAsc={dataLayersStore.sortByColumn.direction === 'asc'}>
                {/* V */}
                <SortIcon />
              </TableHeaderSortIconWrapper> : null}
              {dataLayersStore.sortBy !== 'usdValue' ? <SortHoverIconWrapper><SortHoverIcon /></SortHoverIconWrapper> : null}
              <TableHeaderTextWrapper>{getValueColumnName()}</TableHeaderTextWrapper>
            </TableHeaderCell>
          </> : <>


            <MobileDataLayersTableCell >
              <MobileDataLayerButtonWrapper onClick={() => { dataLayersStore.setShowDrawer(true) }}>
                <MobileDataLayerButtonText>
                  {dataLayersStore.dataLayersHeaders.length > 0 ? `${dataLayersStore.dataLayersHeaders[0].title}${dataLayersStore.dataLayersHeaders[0].subTitle ? ' - ' + dataLayersStore.dataLayersHeaders[0].subTitle : ''}` : getValueColumnName()}
                </MobileDataLayerButtonText><DataLayersIcon />
              </MobileDataLayerButtonWrapper>
              <MobileSortButtonWrapper isValue isAsc={dataLayersStore.sortByColumn.direction === 'asc'} onClick={() => { handleMobileSortClick() }} >
                {/* V */}
                <SortIcon />
              </MobileSortButtonWrapper>

            </MobileDataLayersTableCell>

          </>}
        </>}

        {wealthStore.viewType === 'cashflow' && <>
          {(uiStore.isDesktopView ? inflowOutflowDataLayersStore.columns : [inflowOutflowDataLayersStore.columns[inflowOutflowDataLayersStore.columns.length - 1]]).map(cl => (
            <TableHeaderCell key={cl.label} isValue>
              {cl.label}
            </TableHeaderCell>
          ))}
        </>}

        {!uiStore.isDesktopView &&
          <>

            <MobileLeftCornerMask style={{ position: 'absolute', top: '0', left: '0' }} />
            <MobileRightCornerMask style={{ position: 'absolute', top: '0', right: '0' }} />
          </>
        }
      </TableHeaderWrapper>
      {wealthStore.isBulkEditMode &&
        (['items', 'holdings', 'crypto-holdings'].includes(wealthStore.listViewMode) || (wealthStore.isAllocationsViewMode && wealthStore.allocateBy === 'accounts')) &&
        <><BulkEditHeaderWrapper withStickyBreadcrumbs={filtersStore.countActiveFilters > 0} >
          <ItemSelectedCheckboxWrapper onClick={() => { wealthStore.bulkEditToggleSelectAllItems() }}
            isSelected={wealthStore.isAllItemsSelectedForBulkEdit} isHeaderItem >
            <ItemSelectedCheckbox isSelected={wealthStore.isAllItemsSelectedForBulkEdit}
              isPartialSelected={wealthStore.isPartialItemsSelectedForBulkEdit}>
              {wealthStore.isAllItemsSelectedForBulkEdit && <ItemSelectedIcon />}
              {wealthStore.isPartialItemsSelectedForBulkEdit && <div style={{ width: '4px', height: '1px', marginTop: '1px', backgroundColor: colors.darkGray2 }} />}
            </ItemSelectedCheckbox>



          </ItemSelectedCheckboxWrapper>
          <BulkEditHeaderText>
            {wealthStore.selectedItemsForBulkEdit.length === 0 ? 'Select items to edit' : `${wealthStore.selectedItemsForBulkEdit.length} item${wealthStore.selectedItemsForBulkEdit.length === 1 ? '' : 's'} selected`}
          </BulkEditHeaderText>
          <div style={{ position: 'relative' }}>
            <Dropdown
              overlay={BulkEditDropDownMenu({
                onEditAttribute,
                userStore: userStore,
                selectedItemsCount: wealthStore.selectedItemsForBulkEdit.length
              })}
              getPopupContainer={trigger => trigger.parentNode}
              trigger={['click']} placement="bottomLeft"
              overlayStyle={{ width: 248 }}
              disabled={wealthStore.selectedItemsForBulkEdit.length === 0}
            // onVisibleChange={(visible) => setIsOverlayOpen(visible)} 
            >
              <div><BulkEditHeaderEditButton disabled={wealthStore.selectedItemsForBulkEdit.length === 0}><EditBulkButtonIcon />Edit</BulkEditHeaderEditButton></div>

            </Dropdown>
          </div>
          <span style={{ flex: 1 }}></span>
          <BulkEditHeaderCancelEditButton onClick={() => { wealthStore.setIsBulkEditMode(false, true) }} ><CancelButtonIconWrapper ><EditBulkCancelIcon /></CancelButtonIconWrapper>Cancel edit mode</BulkEditHeaderCancelEditButton>
        </BulkEditHeaderWrapper>
          {isShowBulkEditModal && <BulkEditModal
            onBackDropClick={() => { setIsShowBulkEditModal(false) }}
            handleActionClick={(value) => { handleSaveBulkEditChanges(value) }}
            editedAttribute={wealthStore.selectedAttributeToEdit} />}
        </>}
    </>
  )}</Observer>)
}

const EditBulkButtonIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M16.3981 8.79688L11.003 3.587" stroke={color || "white"} strokeWidth="1.5" />
    <path d="M8.90662 16.5683L8.90566 17.3183C9.10988 17.3186 9.30538 17.2355 9.447 17.0884L8.90662 16.5683ZM3.50286 11.3674L2.96248 10.8473C2.82087 10.9945 2.74537 11.193 2.75345 11.3971L3.50286 11.3674ZM3.70831 16.5617L2.9589 16.5913L2.98735 17.3107L3.70735 17.3117L3.70831 16.5617ZM13.689 2.22602L17.6518 6.04001L18.6919 4.95926L14.7292 1.14526L13.689 2.22602ZM17.6585 6.3935L8.36625 16.0482L9.447 17.0884L18.7393 7.43368L17.6585 6.3935ZM4.04324 11.8875L13.3355 2.23278L12.2548 1.1926L2.96248 10.8473L4.04324 11.8875ZM8.90758 15.8183L3.70927 15.8117L3.70735 17.3117L8.90566 17.3183L8.90758 15.8183ZM4.45772 16.532L4.25227 11.3378L2.75345 11.3971L2.9589 16.5913L4.45772 16.532ZM17.6518 6.04001C17.7512 6.13576 17.7543 6.29402 17.6585 6.3935L18.7393 7.43368C19.4095 6.73732 19.3883 5.62948 18.6919 4.95926L17.6518 6.04001ZM14.7292 1.14526C14.0328 0.475043 12.925 0.496236 12.2548 1.1926L13.3355 2.23278C13.4313 2.1333 13.5895 2.13027 13.689 2.22602L14.7292 1.14526Z" fill={color || "white"} />
    <path d="M19 16.5977H12" stroke={color || "white"} strokeWidth="1.5" />
  </svg>
)

const EditBulkButtonArrowIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3197 7.98829L13.653 12.002L10.3197 16.0156" stroke="black" strokeWidth="1.5" />
  </svg>
)

const EditBulkCancelIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" rx="12" fill="#585D66" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.7881 16.9139L7.30278 8.42867L8.21191 7.51953L16.6972 16.0048L15.7881 16.9139Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.6972 8.42776L8.21187 16.913L7.30273 16.0039L15.788 7.51862L16.6972 8.42776Z" fill="white" />
  </svg>
)

function BulkEditDropDownMenu({
  onEditAttribute,
  userStore,
  selectedItemsCount
}) {

  const EditItemsMenuItem = ({ title }) => (
    <AssetActionMenuItem withBorderBottom pl={16} pr={8} onClick={(e) => { e.stopPropagation() }}>
      <AssetActionMenuItemChild isStretch isBold notClickable><EditBulkButtonIcon color={'#1A1B1D'} />Edit selected items<span style={{ flex: 1 }} />
        {/* <EditBulkButtonArrowIcon /> */}
      </AssetActionMenuItemChild>
    </AssetActionMenuItem>
  );

  const EditPropMenuItem = ({ title, attrKey }) => (
    <AssetActionMenuItem pl={24} pr={8} onClick={() => { onEditAttribute(attrKey) }}>
      <AssetActionMenuItemChild withArrow>
        {title}
        {userStore?.isActionBlockedByRole('bulkEdit') || userStore.isActionBlockedByPaymentStatus('bulkEdit') ? <ActionLockedIconWithTooltip iconColor={'#9097A3'} marginLeft={'auto'} isRoleBlocked={userStore?.isActionBlockedByRole('bulkEdit')} /> :
          <EditBulkButtonArrowIcon />}
      </AssetActionMenuItemChild>
    </AssetActionMenuItem>
  );

  const DeleteItemsMenuItem = () => {
    const pluralSuffix = (1 === selectedItemsCount) ? '' : 's';

    return (
      < AssetActionMenuItem isRed withBorderTop pl={16} pr={8} onClick={() => onEditAttribute('bulkDelete')} >
        <AssetActionMenuItemChild isStretch isBold isRed>
          <EraseIcon />Delete item{pluralSuffix}
        </AssetActionMenuItemChild>
      </AssetActionMenuItem >
    );
  }



  return (
    <AssetActionMenuWrapper>
      <AssetActionMenuContent>
        {/* <CloseReopenMenuItem /> */}
        <EditItemsMenuItem />
        <EditPropMenuItem title={'Holding entity'} attrKey={'beneficiary'} />
        <EditPropMenuItem title={'Custom class and sub-class'} attrKey={'customClass'} />
        <EditPropMenuItem title={'Risk level'} attrKey={'riskLevel'} />
        <EditPropMenuItem title={'Liquidity level'} attrKey={'liquidity'} />
        <DeleteItemsMenuItem />
      </AssetActionMenuContent>
    </AssetActionMenuWrapper>
  );
}
