import { Input } from 'antd';
import React, { useState } from 'react'
import { PlusIcon } from '../../../../../assets/icons/common/common-icons';
import { SimpleSelect } from '../../../DropDownMenu/SimpleSelect';
import { PlusIconWrapper } from '../SharesTradingTable/SharesTradingTable.styles';
import { SelectWithCustomOptionWrapper } from './SelectWithCustomOption.styles'

export const SelectWithCustomOption = (props) => {
  const { value, field, onChange, allowClear
    // getPopupContainer
  } = props;
  const [isInputMode, setIsInputMode] = useState(false);
  const [customOption,setCustomOption] = useState(''); 

  const handleCustomInputBlur = (e) => {
    setCustomOption(e.target.value);
    setIsInputMode(false);
    // if (e.target.value === '') {
    //   setIsInputMode(false)
    // }
  }

  const handleSimpleSelectChange = (val) => {
    if (val === 'custom') {
      onChange('');
      setIsInputMode(true);
    }
    else {
      onChange(val);
    }
  }

  return (
    <SelectWithCustomOptionWrapper>
      {isInputMode ?
        <Input autoFocus type='text' value={value} allowClear={allowClear} onChange={(e) => { onChange(e.target.value) }} onBlur={handleCustomInputBlur} />
        :
        <SimpleSelect value={value}
          buttonStyle={{ boxShadow: 'none', fontWeight: 'normal', height: '32px' }}
          placement={"bottomLeft"}
          allowClear={allowClear}
          options={
            [...field.options,
              ...(customOption === '' ? [] : [{value:customOption, label: customOption}]),
            // [...field.options.map(opt => ({ value: opt, label: opt })),
            {
              value: 'custom', label: <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}><PlusIconWrapper><PlusIcon /></PlusIconWrapper>Add</div>
            }
            ]}
          onChange={handleSimpleSelectChange} />
      }
    </SelectWithCustomOptionWrapper>
  )
}
