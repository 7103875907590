import { makeAutoObservable } from "mobx";
import { errorNotification } from "../../layout/components/Notifications/notifications";
import { uploadLLMDocument, getFileLLMStatus, getLastByUser, getLLMResult, deleteDocument, approveFile, getCurrencyRate, addItems, confirmLLM, confirmUploadedFiles, hasLLMResult } from "./magic-box.service";
import { CURRENCY_SYMBOL } from "../../../common/constants/currency-symbol.const";

export const MAX_MAGIC_BOX_FILE_SIZE_MB = 25;
export const LLM_FAILED_MESSAGE = 'Despite its best efforts, Magic Box AI was unable to describe this document.';
export const LLM_IN_PROCESS_MESSAGE = 'Hang tight! Our AI is reviewing your document to verify its details and ensure it meets our processing criteria. This step will only take a few seconds';
export const AI_LOADING_MSG_WELCOME = `Hold on! Our AI is now retrieving the detailed assets and liabilities from your document. This step might take up to 2 minutes, but you can continue to the next step if you're in a hurry. We'll notify you once everything is ready!`;
export const AI_LOADING_ACCOUNT = `Hold on! Our AI is now retrieving the detailed assets and liabilities from your document. This step might take up to 2 minutes. Feel free to explore Vyzer while we work—we'll notify you as soon as everything is ready!`;
export const SUPPORTED_FILE_FORMATS = ['.pdf', '.csv', '.xls', '.xlsx', '.docx', '.jpg', '.jpeg', '.png', 'gif', 'webp', 'bmp', '.xlsm'];
export class MagicBoxAiStore {

  metadataStore = null;
  isDoneWithMagicBoxAI = false;
  file = null;
  fileForUpload = null;
  isUploading = false;
  loading = false;
  llmResult = null;
  submitted = false;
  isTyping = false;
  switchToManualReview = false;
  currencyTable = { 'USD': 1 };
  showMissingFieldsError = 0;

  constructor(metadataStore) {
    this.metadataStore = metadataStore;
    makeAutoObservable(this);
  }

  reset() {
    this.isDoneWithMagicBoxAI = false;
    this.file = null;
    this.isTyping = false;
    this.fileForUpload = null;
    this.isUploading = false;
    this.loading = false;
    this.llmResult = null;
    this.submitted = false;
    this.switchToManualReview = false;
  }

  async fetchData() {
    this.setLoading(true);
    try {
      const llmFile = await getLastByUser();
      this.setFile(llmFile);
      await this.handleFileLLMStatus(llmFile);
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  }

  async handleFileLLMStatus() {
    if (!this.file) return;
    if (!this.file.llmStatus) {
      this.setSwitchToManualReview(true);
      return;
    }

    switch (this.file.llmStatus) {
      case 'in process':
        this.checkFileStatusPeriodically();
        break;
      case 'done':
        this.checkResultPeriodically();
        break;
      case 'failed':
      case 'timeout':
        if (this.file.status === 'Processing') {
          this.setSwitchToManualReview(true);
        }
        break;
      default:
        break;
    }
  }

  async uploadDocument(acceptedFiles, fileRejections) {
    if (this.isUploading) return;

    const totalFiles = (fileRejections.length + acceptedFiles.length);
    if (totalFiles > 1) {
      errorNotification('You can only upload one file at a time');
      return;
    }

    const acceptedFile = acceptedFiles[0];
    this.setFileForUpload(acceptedFile ?? fileRejections[0]);

    if (acceptedFile) {
      this.setUploading(true);
      try {
        let formData = new FormData();
        formData.append('files', acceptedFile, acceptedFile.name);
        const uploadedDocuments = await uploadLLMDocument(formData);
        if (uploadedDocuments.statusCode) {
          errorNotification('Something went wrong');
        } else {
          this.setFile(uploadedDocuments);
          this.checkFileStatusPeriodically();
        }
      } catch (e) {
      } finally {
        this.setUploading(false);
      }
    }
  }

  async checkFileStatusPeriodically() {
    const llmStatus = await getFileLLMStatus(this.file.id);
    if ('in process' === llmStatus) {
      setTimeout(() => this.checkFileStatusPeriodically(), 3000);
      return;
    }

    await this.fetchData();
  }

  async checkResultPeriodically() {
    let timeout;
    try {
      const isLLMResultExist = await hasLLMResult(this.file.id);
      if (!isLLMResultExist) {
        timeout = setTimeout(() => this.checkResultPeriodically(), 3000);
        return;
      }

      this.downloadLLMResult();
    } catch (e) {
      clearTimeout(timeout);
      console.log(e);
    }
  }

  async downloadLLMResult() {
    //Function that get the result of the file from backend
    const llmResult = await getLLMResult(this.file.id);
    if (llmResult.statusCode) {
      errorNotification('Something went wrong');
    } else {
      llmResult.data = this.fixItemsData(llmResult.data);
      this.setLLMResult(llmResult);
      this.processLLMResultCurrencies(llmResult?.data);
    }
  }

  fixItemsData(llmResultData) {
    if (!Array.isArray(llmResultData)) {
      return;
    }

    let symbolsTable;
    for (let i = 0, l = llmResultData.length; i < l; i++) {
      const currency = llmResultData[i].currency;
      if (currency) {
        if (!CURRENCY_SYMBOL[currency]) {
          if (!symbolsTable) {
            symbolsTable = Object.entries(CURRENCY_SYMBOL).map(([code, symbol]) => [symbol, code]);
          }

          llmResultData[i].currency = (symbolsTable[currency] ?? 'USD');
        }
      } else {
        llmResultData[i].currency = 'USD';
      }
    }

    return llmResultData;
  }

  async approveLLM(notes) {
    await approveFile(this.file.id, notes);
    const updatedFile = { ...this.file, status: 'Processing', notes };
    this.setFile(updatedFile);
  }

  async confirmUpload() {
    if ('done' === this.file.llmStatus) {
      await confirmLLM(this.file.id);
    } else {
      await confirmUploadedFiles([this.file.id]);
    }
  }

  async deleteDocument() {
    try {
      if (!this.file) return;
      this.setUploading(true);
      await deleteDocument(this.file.id);
      this.setUploading(false);
      this.reset();
    } catch (e) {
      console.log(e);
    }
  }



  async getCurrencyRate(currency) {
    let currencyRate = 0;
    if (!currency) return 0;
    if (!this.currencyTable[currency]) {
      try {
        const currencyRateObj = await getCurrencyRate(currency);
        if (currencyRateObj.statusCode) { } else {
          currencyRate = currencyRateObj.rate;
        }
      } catch (_e) {

      }
      // this.currencyTable[currency] = currencyRate;
    }

    return currencyRate;
  }


  async addItems() {
    if (this.isAnyAccountWithoutCategory || this.isAnyAccountWithoutAllFields) {
      this.setShowMissingFieldsError();
      errorNotification(this.isAnyAccountWithoutCategory ? "Please select category for all items" : "Please fill all empty fields");
    } else {
      const items = {
        assets: this.processedLLMResult.map(item => {
          return {
            categoryId: item.categoryId,
            value: +item.amount,
            currency: item.currency,
            title: item.item_name
          };
        })
      };

      try {
        const response = await addItems(items);
        if (response?.statusCode) {
          errorNotification('Something went wrong');
        } else {
          await this.confirmUpload();
          this.setSubmitted(true);
        }
      } catch (e) {
        errorNotification('Something went wrong');
      }
    }
  }



  async processLLMResultCurrencies(llmResultData) {
    if (!Array.isArray(llmResultData)) {
      return;
    }

    const uniqueMissingCurrencies = [...new Set(llmResultData.map(item => item.currency).filter(cur => !this.currencyTable.hasOwnProperty(cur)))];
    const currencyPromises = uniqueMissingCurrencies.map(currency => this.getCurrencyRate(currency));
    const currencyRateValues = await Promise.all(currencyPromises);
    const currencyRates = uniqueMissingCurrencies.reduce((acc, cur, index) => {
      acc[cur] = currencyRateValues[index] || 1;
      return acc;
    }, {});
    // console.log("Currency Rates", currencyRates);
    this.setCurrencyRates(currencyRates);

  }

  get processedLLMResult() {
    const categoriesTitleObj = this.metadataStore.categoriesTitleObj;
    const categoriesObj = this.metadataStore.categoriesObj;
    const result = (this.llmResult?.data || []).map(item => {
      const lowerCaseCategory = item.category?.toLowerCase();
      const currencyRate = this.currencyTable[item.currency];
      const categoryId = item.categoryId ? item.categoryId : categoriesTitleObj[lowerCaseCategory]?.id || null;
      const classId = categoryId ? categoriesObj[categoryId]?.classId || null : null;
      const relevantClass = classId ? this.metadataStore.classes.find(cls => cls.id === classId) : null;
      return {
        ...item,
        categoryId,
        classId,
        color: relevantClass?.color ?? '#FFFFFF',
        icon: relevantClass?.icon,
        usdValue: isNaN(item.amount) ? undefined : ((item.amount || 0) / (currencyRate || 1)),
        isFetchRate: !currencyRate
      };
    }).filter(item => item);
    return result;
  }

  get totals() {
    const totalsObj = {
      assets: { count: 0, sum: 0 },
      liabilities: { count: 0, sum: 0 }
    };

    if (!this.llmResult) return totalsObj;

    const assetsCategoriesObj = this.metadataStore.assetCategoriesObj;
    const liabilityCategoriesObj = this.metadataStore.liabilityCategoriesObj;
    const assets = this.processedLLMResult.filter(item => assetsCategoriesObj[item.categoryId]);
    const liabilities = this.processedLLMResult.filter(item => liabilityCategoriesObj[item.categoryId]);

    totalsObj.assets.count = assets.length;
    totalsObj.assets.sum = assets.reduce((acc, item) => (acc + (isNaN(item.usdValue) ? 0 : item.usdValue)), 0);;

    totalsObj.liabilities.count = liabilities.length;
    totalsObj.liabilities.sum = liabilities.reduce((acc, item) => (acc + (isNaN(item.usdValue) ? 0 : item.usdValue)), 0);

    return totalsObj;
  }

  get isFetchAnyRate() {
    return this.processedLLMResult.some(item => item.isFetchRate);
  }

  get isAnyAccountWithoutAllFields() {
    return this.processedLLMResult.some(item => !item.categoryId || isNaN(item.amount) || !item.currency || !item.item_name);
  }
  get isAnyAccountWithoutCategory() {
    return this.processedLLMResult.some(item => !item.categoryId);
  }

  get firstAccountWithoutAllFields() {
    return this.processedLLMResult.findIndex(item => !item.categoryId || isNaN(item.amount) || !item.currency || !item.item_name);
  }

  setCurrencyRates(rates) {
    this.currencyTable = { ...this.currencyTable, ...rates };
  }

  rejectReviewResult() {
    this.llmResult.llmStatus = 'rejected';
    this.setSubmitted(true);
  }

  setSubmitted(submitted) {
    this.submitted = submitted;
  }

  setLLMResult(llmResult) {
    // console.log("Set results" , llmResult)
    this.llmResult = llmResult;
  }

  setFile(file) {
    this.file = file;
  }

  setFileForUpload(file) {
    this.fileForUpload = file;
  }

  setUploading(isUploading) {
    this.isUploading = isUploading;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  changeReviewedItemCategory(index, categoryId) {
    const updatedData = [...this.llmResult.data];
    updatedData[index].categoryId = categoryId;
    this.llmResult.data = updatedData;
  }

  updateReviewedItem(index, reviewedItem) {
    const updatedData = [...this.llmResult.data];
    updatedData[index] = reviewedItem;
    this.processLLMResultCurrencies([reviewedItem]);
    this.llmResult.data = updatedData;
  }

  deleteReviewedItem(index) {
    const updatedData = [...this.llmResult.data];
    updatedData.splice(index, 1);

    this.llmResult.data = updatedData;
  }

  setSwitchToManualReview(switchToManualReview) {
    this.switchToManualReview = switchToManualReview;
  }

  setShowMissingFieldsError(show) {
    this.showMissingFieldsError++;
  }

  setIsTyping(isTyping) {
    this.isTyping = isTyping;
  }

  addNewItem(item) {
    this.processLLMResultCurrencies([item]);
    this.llmResult.data = [...this.llmResult.data, item];
  }
}