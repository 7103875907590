import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../common/styles/combos.styles";
import { colors, shadows, views } from "../../../common/styles/theme.styles";

export const CommunityPageHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    ${views.tablet}{
        padding-top: 24px;
    }
`;

export const HeaderTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.darkGray1};
`;

export const HeaderAltText = styled.div`
    font-size: 13px;
    color: ${colors.darkGray2};
`;

export const CommunityPageBoxesWrapper = styled.div`
    display: flex;
    gap: 20px;
    padding-top: 24px;
    ${views.tablet}{
        flex-direction: column;
        padding: 24px;
    }
`;

export const CommunityPageBoxArrow = styled.div`
    position: absolute;
    right: 8px;
    bottom: 8px;
    ${flexCenter};
    background-color: ${colors.lightGray2};
    padding: 2px 8px;
    border-radius: 6px;
    transition: all 0.2s;
    svg {
        transform: rotate(0deg);
        transition: transform 0.2s;
        path {
            transition: stroke 0.4s;
            stroke: #000;
        }
    }
    ${views.tablet}{
        display: none;
    }
`;

export const CommunityPageBoxHoverEffect = styled.div`
    // display: none;
    pointer-events:none;
    flex-direction: column;
    align-items: center;
    width: 280px;
    
    
    background-color: #fff;
    
    
    z-index: 1;
    box-shadow: ${shadows.popupShadow};
   
    
    
    ${views.tablet}{
        border-radius: 0 8px 8px 0;
        flex:1;
        img {
            width: 100%;
            border-bottom-right-radius: 8px;
        }
    }
    ${views.desktop}{
        border-radius: 8px;
        position: absolute;
        opacity:0;
        transition: all 0.2s;
        transform: translateY(100%);
        bottom: -8px;
        img {
            width: 280px;
            height: 140px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
`;

export const HoverEffectTitle = styled.div`
    ${flexCenter};
    font-size: 13px;
    color: ${colors.darkGray1};
    padding: 12px 16px;
    text-align: start;
`;

export const CommunityPageBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 4px;
    border-radius: 8px;
    box-shadow: ${shadows.cardShadow};
    background-color: #fff;
    width: 280px;
    
    position: relative;
    border: 1px solid #fff;
    transition: all 0.2s;
    
    ${views.desktop}{
        height: 140px;
        :hover {
            border: 1px solid ${colors.darkGray1};
            ${CommunityPageBoxArrow} svg {
                transform: rotate(90deg);
                path {
                    stroke: #fff;
                }
            }
            ${CommunityPageBoxArrow} {
                background-color: ${colors.darkGray1};
            }
            ${CommunityPageBoxHoverEffect} {
                display: flex;
                z-index: 1;
                opacity:1;
                pointer-events:auto;
            }
        }
    }
    ${views.tablet}{
        width: 100%;
        flex-direction: row;
        
    }
`;

export const CommunityPageBoxTextAndIconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:4px;
    flex:1;
    ${views.desktop}{
       justify-content: center;
    }
`;

export const CommunityPageBoxText = styled.div`
    font-size: 15px;
    color: ${colors.darkGray1};
`;

export const CommunityPageBody = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    
    box-shadow: ${shadows.cardShadow};
    img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
    ${views.desktop}{
        margin-top: 20px;
    }
   
`;

export const ActionButtonWrapper = styled.div`
    background: #2565E7;
    color: #fff;
    display: flex; 
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 42px;
    padding: 14px 24px;
    cursor: pointer;
`

export const LimitedTierContentWrapper = styled.div`
    background: #fff;
    
    color: ${colors.darkGray1};
    display: flex; 
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width : 280px;
    gap:8px;
    ${views.desktop}{
       justify-content: center;
    }
`;

export const LimitedTierContentTitle = styled.div`
    font-size: 18px;
    font-family: 'Domine';
    font-weight: 700;
`;

export const LimitedTierContentText = styled.div`
    font-size: 14px;
    font-weight: 450;
    text-align:center;
    margin-bottom: 24px;
`;

export const ActionButtonText = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
`
export const MobileHeaderTitle = styled.div`
    font-weight: 450;
    font-size: 18px;
    color: #fff;
`;