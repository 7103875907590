import moment from "moment";

export function getProfileAdditionalInfoMixpanelData(responseData, is_demo, kidsOption) {
    const {
        country, 
        familyStatus: family_status, 
        kids,
        zipCode: zip_code,
        dateOfBirth,
    } = responseData;
    const children = kidsOption.filter(i => i.val === kids)[0]?.text || null;
    const birthday = dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : null;

    return {
        country,
        family_status, 
        children,
        zip_code,
        birthday,
        is_demo
    }
}

// moment(aaa).format('YYYY')

export function getProfileFinancialSettings(responseData, is_demo, kidsOption) {
    const { 
        monthlyIncome: salary,
        monthlyIncomeCurrency: salary_currency, 
        monthlyExpenses: monthly_cost, 
        monthlyExpensesCurrency: monthly_cost_currency, 
        defaultCurrency: preferred_currency,
        country, 
        familyStatus: family_status, 
        kids,
        zipCode: zip_code,
        dateOfBirth,
    } = responseData;
    const children = kidsOption.filter(i => i.val === kids)[0]?.text || null;
    const birthday = dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : null;

    return {
        salary,
        salary_currency,
        monthly_cost,
        monthly_cost_currency,
        preferred_currency,
        country,
        family_status, 
        children,
        zip_code,
        birthday,
        is_demo
    };
}