
export const defaultCryptoCoins = [
  {
    coinName: "Bitcoin",
    dataAvailableFrom: null,
    externalId: "1182",
    fullName: "Bitcoin (BTC)",
    id: 7621,
    imageUrl: "https://www.cryptocompare.com/media/37746251/btc.png",
    name: "BTC",
    symbol: "BTC",
  },
  {
    coinName: "Ethereum",
    dataAvailableFrom: null,
    externalId: "7605",
    fullName: "Ethereum (ETH)",
    id: 7697,
    imageUrl: "https://www.cryptocompare.com/media/37746238/eth.png",
    name: "ETH",
    symbol: "ETH",
  },
  {
    coinName: "Binance Coin",
    dataAvailableFrom: null,
    externalId: "204788",
    fullName: "Binance Coin (BNB)",
    id: 7952,
    imageUrl: "https://www.cryptocompare.com/media/37746880/bnb.png",
    name: "BNB",
    symbol: "BNB",
  },
  {
    coinName: "Buff Doge Coin",
    dataAvailableFrom: null,
    externalId: "949406",
    fullName: "Buff Doge Coin (DOGECOIN)",
    id: 7113,
    imageUrl: "https://www.cryptocompare.com/media/40420712/dogecoin.png",
    name: "DOGE",
    symbol: "DOGE",
  },
  {
    coinName: "USD Coin",
    dataAvailableFrom: null,
    externalId: "925809",
    fullName: "USD Coin (USDC)",
    id: 2482,
    imageUrl: "https://www.cryptocompare.com/media/34835941/usdc.png",
    name: "USDC",
    symbol: "USDC",
  },
];