import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../../common/styles/combos.styles";
import { colors, views } from "../../../../../common/styles/theme.styles";
import { PrimaryButtonWithIcon } from "../../../../../common/components/buttons/NewButtons.styles";

export const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 16px;
  flex: 1;
`;

export const EmptyStateActionButton = styled.div`
  ${flexCenter};
  color: ${colors.darkGray1};
  font-weight: 500;
  min-width: 220px;
  padding: 10px 40px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  gap: ${props=>props.isLocked ? `0` : `8px`};
  cursor: pointer;
  svg{
    path{
      fill:#000;
    }
  }
`;

export const EmptyStateActionButtonText = styled.div`
  padding-left: 8px;
`;

export const NoTransactionsSkeletonWrapper = styled.div`
  ${flexCenter};
  padding: 24px 0;
  width: 100%;
`;

export const EmptyStateMessageWrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  padding: 36px 0;
  gap: ${props=>props.isSmallGap ? '6' : '18'}px;
`;
export const EmptyStateMessage = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color:${colors.gray2};
    text-align:center;
`;

export const EditCostOfLivingWrapper = styled.div`
  ${flexCenter};
  width: 100%;
  border-top: 1px solid ${colors.gray3};
`;

export const EditCostOfLivingButton = styled.div`
  ${flexCenter};
  gap: 8px;
  cursor: pointer;
  min-height: 56px;

  background: #FDFDFD;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  ${props=>props.isSmallScreen ? 'padding:16px' : ``};
`;

export const PlanFutureCashFlowEmptyStateWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 52px;
  background-color: ${colors.darkHeroBackground};
  border-radius: 16px;
  justify-content: space-between;
  margin-bottom: 8px;
  ${views.tablet}{
    flex-direction: column;
    border-radius: 0px;
    gap: 0px;
    margin-bottom: 0px;
  }
`;

export const PlanFutureCashFlowEmptyStateIllustrationWrapper = styled.div`
  ${flexCenter};
`;

export const PlanFutureCashFlowEmptyStateTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 62px;
  padding-left: 56px;
  ${views.tablet}{
    padding: 16px 24px 0px;
  }
`;

export const PlanFutureCashFlowEmptyStateTitle = styled.div`
  white-space: pre-line;
  font-weight: 700;
  font-size: 24px;
  line-height: 120.5%;
  color: #fff;
`;

export const PlanFutureCashFlowEmptyStatePrompt = styled.div`
  white-space: pre-line;
  font-weight: 450;
  font-size: 14px;
  line-height: 140.34%;
  color: #fff;
`;

export const GetFamiliarWithCashflowEmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
    min-width: 200px;
    flex: 1;
    margin-left: 16px;
    gap: 12px;
`;

export const GetFamiliarWithCashflowCardWrapper = styled.div`
    display:flex;
    flex-direction:column;
    overflow-y:hidden;
    min-height: ${props=>props.isCardOpen ? `auto` : `58px`};
    /* max-height:40%;     */

    background: #FDFDFD;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
`;

export const GetFamiliarWithCashflowSubtitle = styled.div`
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  padding-top: 8px;
  color: ${colors.darkGray3};
`;

export const ActionButton = styled(PrimaryButtonWithIcon)`
    // background-color: ${props => props.black ? colors.darkGray1 : colors.lightGray1} ;
    // color: ${props => props.black ? '#fff' : '#000'};
    // box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    // border-radius: 6px;
    // padding: ${props => props.isBack ? '10px 20px' : '8px 16px'};
    // font-weight: 450;
    // font-size: 16px;
    // line-height: 20px;
    width: fit-content;
    // cursor: pointer;
    // display: flex;
    // align-items: center;
    // transition: opacity 1s;
    // ${props => props.withIcon ? `gap: 6px` : ``};
    // ${props => props.isBack ? `margin-right: auto` : ``};
    // ${props => props.disabled ? `opacity: 0.2; cursor: default;` : ``}
`;