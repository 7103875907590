import React from 'react';
import { Observer } from 'mobx-react';
import { AssetEventsWrapper } from './AssetEvents.styles';
import { AssetEventRow } from './AssetEventRow';
import { AssetPredictedEventRow } from './AssetPredictedEventRow';
import { OldEventRow } from './OldEventRow/OldEventRow';
import { useStore } from '../../../../../app/data/root.store';

export const AssetEventsList = (props) => {
  const { item, startIndex, visibleItems, isIncognito, events, markedEventIndex, openEditEventModal, onCloseEditEventModal } = props;

  const { userStore } = useStore();

  const isEditDisabled = (item.closedAt !== null) || userStore.isActionBlockedByRole('editEvent')

  return (<Observer>{() => (
    <AssetEventsWrapper id="item-events-list-wrapper">
      {events.slice(startIndex, startIndex + visibleItems).map((event, ind) =>
        event.isWithoutEvent ?
          <OldEventRow key={ind} isFirstRow={ind === 0} isIncognito={isIncognito} id={item.id} item={item} event={event} itemCategoryId={item.categoryId}  itemHoldings={item.holdings}  isMarked={ind + startIndex === markedEventIndex} />
          : (event.id ?
            <AssetEventRow key={ind} isFirstRow={ind === 0} isIncognito={isIncognito} ind={ind} eventIndex={ind + startIndex} itemId={item.id} itemHoldings={item.holdings} itemCategoryId={item.categoryId} itemCurrency={item.currency} event={event} isMarked={ind + startIndex === markedEventIndex} openEditEventModal={openEditEventModal} onCloseEditEventModal={onCloseEditEventModal} isEditDisabled={isEditDisabled} />
            :
            <AssetPredictedEventRow key={ind} isFirstRow={ind === 0} isIncognito={isIncognito} ind={ind} eventIndex={ind + startIndex} itemId={item.id} itemHoldings={item.holdings} itemCategoryId={item.categoryId} itemCurrency={item.currency} event={event} isMarked={ind + startIndex === markedEventIndex} openEditEventModal={openEditEventModal} onCloseEditEventModal={onCloseEditEventModal} isEditDisabled={isEditDisabled} />
          ))}
    </AssetEventsWrapper>
  )}</Observer>)
}