import React from 'react'
import { BaseModal } from '../../../../../../common/components/BaseModal/BaseModal'
import { PrimaryButton } from '../../../../../../common/components/buttons/NewButtons.styles'
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent/useTrackEvent'
import { CloseButtonWrapper, OpenItemModalMessage, OpenItemModalTitle, ReOpenItemModalWrapper } from './OpenItemModal.styles'
import { ReactComponent as OpenItemIconSvg } from './assets/open-item-icon.svg'

export const OpenItemModal = ({ onClose, itemName, userStore }) => {

  useTrackEvent('Popup Viewed', {
    title: 'Reopen Confirmation',
    tier: userStore.data?.profile?.tier,
    url: window.location.href
  })

  return (
    <BaseModal width={'600px'} height={'auto'} borderRadius={8}>
      <ReOpenItemModalWrapper>
        <OpenItemIconSvg />
        <OpenItemModalTitle>You’ve reopened this asset.</OpenItemModalTitle>
        <OpenItemModalMessage>
          You are now able to edit <span style={{fontWeight:'600'}}>{itemName}</span>.
          Your net worth will include its asset value, and future cash flow events will be reflected in your planning.
        </OpenItemModalMessage>
        <PrimaryButton onClick={onClose} style={{ width: '186px', marginTop: '60px' }}>Got it</PrimaryButton>
        <CloseButtonWrapper onClick={onClose}><ModalCloseButtonSvg /></CloseButtonWrapper>
      </ReOpenItemModalWrapper>
    </BaseModal>
  )
}

const ModalCloseButtonSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="6.28033" y1="5.83344" x2="18.3011" y2="17.8543" stroke="#9097A3" strokeWidth="1.5" />
      <line x1="5.9267" y1="17.8544" x2="17.9475" y2="5.83362" stroke="#9097A3" strokeWidth="1.5" />
    </svg>
  )
}