import styled from "@emotion/styled";
import { colors } from "../../../../../../common/styles/theme.styles";

export const EditTargetEventModalHeader = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    padding: 16px;
    color: #000000d9;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
    position: relative;
`;

export const ModalCloseButtonWrapper = styled.div`
    position:absolute;
    right:16px;
    top:16px;
    width:24px;
    height:24px;
    cursor: pointer;
`;

export const StepWrapper = styled.div`
    display:flex;
    align-items: center;
    text-align: center;
    flex-direction:column;
    background-color: #FDFEFE;
    flex:1;
    padding: 80px 114px;
`;

export const FormStepFooter = styled.div`
    width:100%;
    padding: 12px 24px 12px 20px;
    border-top: 1px solid #e3e3e3;
    justify-content:space-between;
    flex-direction: row-reverse;
    display:flex;
    align-items:center;
`;

export const EditTargetEventTitle = styled.div`
    color: ${colors.darkGray1};
    font-family: Domine;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 16px;
    padding-bottom: 8px;
`;

export const EditTargetEventDescription = styled.div`
    color: #26282C;
    text-align: center;
    font-family: Circular;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 22px;
`;