import styled from '@emotion/styled/macro';
import { colors, shadows } from '../../../../common/styles/theme.styles';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { BlueButton } from '../../../../common/components/buttons/NewButtons.styles';

export const ComparePlansModalWrapper = styled.div`
  display: flex;
  flex:1;
`;

export const LoaderWrapper = styled.div`
  ${flexCenter};
  height:600px;
  padding-top:256px;
`;

export const ModalCloseButtonWrapper = styled.div`
  position:fixed;
  display: flex;
  gap: 8px;
  right:20px;
  top:20px;
  font-weight: 450;
  font-size: 14px;
  line-height: 17.71px
  color: ${colors.darkGray2};
`;

export const FaqWrapper = styled.div`
  padding: 4px 12px 4px 10px;
  ${flexCenter};
  gap: 4px;
  svg {
    path {
      stroke: ${colors.darkGray2};
    }
  }
  background-color: #ECEFF5;
  border-radius: 6px;
  color: ${colors.darkGray1};
  cursor: pointer;
`;

export const LearnAboutFamilyOfficeWrapper = styled.div`
  padding: 4px 12px 4px 10px;
  ${flexCenter};
  gap:6px;
  background-color: #ECEFF5;
  border-radius: 6px;
  cursor: pointer;
`;

export const ExploreDemoLink = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 7px 10px 7px 16px;
  border-radius: 8px;
  background: #ECEFF5;
  color: ${colors.darkGray1};
  margin-left: auto;
  font-size: 14px;
  font-weight: 450;
  width: fit-content;
  line-height: 17.71px;
  cursor: pointer;
  svg {
    path {
      stroke: ${colors.darkGray2};
    }
  }
`;

export const ModalLeftPaneWrapper = styled.div`
  width: 24%;
  background: url('${props => props.bgImage}') no-repeat;
  background-size: contain;
  background-color: #101214;
  background-position: right bottom;
  color: #fff;
  font-weight: 450;
  font-size: 16px;
  line-height: 138%;
`;

export const ModalLeftPaneTextWrapper = styled.div`
  display: flex;
  svg {
    flex-shrink: 0;
    path {
      fill: #fff;
    }
  }
`;

export const ConciergeWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 124px 36px 16px 36px;
`;

export const ConciergeLeftPaneTitle = styled.div`
  font-family: 'Domine';
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  color: ${colors.lightGray2};
  max-width: 172px;
`;

export const ModalRightPaneWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 3;
  height: 100vh;
  overflow-y: hidden;
`;

export const ModalRightPaneHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 72px;
  padding: 20px 28px;
`;

export const ArrowLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: #ECEFF5;
`;

export const ModalRightPaneContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding:24px 48px;
  gap:28px;
  overflow-y: auto;
`;

export const PlanTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PlanText = styled.div`
font-size: 16px;
font-weight: 500;
color: ${colors.darkGray1};
`;

export const PlanSubText = styled.div`
color: ${colors.darkGray2};
font-size: 14px;
font-weight: 450;
line-height: 14px;
`;

export const ModalRightPaneFooter = styled.div`
  ${flexCenter};
  gap: 4px;
  margin-top: auto;
  padding-bottom: 8px;
`;

export const ComparePlansButton = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 14px 24px;
  height: 48px;
  cursor:pointer;
  border-radius: 30px;
  box-shadow: 0px 1px 10px rgba(0, 71, 255, 0.15);
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
`;

export const ContinueButton = styled(ComparePlansButton)`
  width: 304px;
  height: 48px;
  background-color: #2565E7;
  color: #fff;
`;

export const PlanSwitchWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: center;
  border: 1px solid #ACB1BB;
  border-radius:100px;
  padding:3px;
  font-weight: 450;
  font-size: 15px;
  line-height: 19px;
  width: 364px;
  margin-bottom: 24px;
`;

export const PlanSwitchOption = styled.div`
  font-size:14px;
  font-weight:500;
  cursor: pointer;
  color:${props => props.isSelected ? '#fff' : colors.darkGray2};
  background-color:${props => props.isSelected ? props.isBlack ? '#000' : colors.blueAzul : 'none'};
  border-radius:100px;
  padding:6px 18px;
`;

export const AnnuallyTooltipWrapper = styled.div`
  color: ${colors.gray3};
  font-size:14px;
  padding:4px;
  text-align:center;
`;

export const TooltipBoldText = styled.div`
  font-weight:600;
  display:inline-flex;
`;

export const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PlanRowInvisibleBorder = styled.div`
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid ${props => props.isSelected ? 'transparent' : colors.gray3};
  position: relative;
  ${props => props.isSelected ? `
  ::after {
    content: '';
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    border: 2px solid #2565E7;
    border-radius: 8px;
  }
  ` : ``};
`;

export const PlanRowWrapper = styled.div`
  position: relative;
  display: flex;
  width:  100%;
  border-radius: 8px;
  padding: 18px 16px;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray2};
  ${props => props.isSelected ? `
  background-color: rgba(232, 238, 251, 0.5);
  ` : `
  `};
`;

export const PlanDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
`;

export const RecommendedPlanBanner = styled.div`
  padding: 6px 16px;
  font-weight: 450;
  font-size: 12px;
  line-height: 110.5%;
  
  background-color: ${props => props.isSelected ? colors.blueAzul : '#F1F6FD'};
  color: ${props => props.isSelected ? '#fff' : colors.darkGray1};
  
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const PlanSelectionCircle = styled.div`
  display: grid;
  place-items: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid ${props => props.isSelected ? colors.blueAzul : '#DDE3EB'};
  box-shadow: ${shadows.toggleShadow};
`;

export const PlanSelectionCircleFill = styled.div`
  width: 8px;
  height: 8px;
  background-color: #2565E7;
  border-radius: 50%;
`;

export const PlanTitleWrapper = styled.div`
  font-family: 'Domine';
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray1};
`;

export const PlanPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray2};
`;

export const PlanPriceValue = styled.div`
  font-family: 'Domine';
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray1};
  padding-top: 22px;
  gap: 4px;
`;

export const PlanPriceDiscountValue = styled.div`
  font-family: 'Domine';
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  text-decoration: line-through;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray1};
`;

export const PlanPriceDescription = styled.div`
  font-weight: 450;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  color: #585D66;
  white-space: pre-line;
  font-family: Circular;
`;

export const PlanTitleText = styled.div`
  font-family: 'Domine';
  font-size: 20px;
  color: ${props => props.isBlack ? '#fff' : colors.darkGray1};
  font-weight: 700;
  line-height: normal;
`;

export const BoldedPriceText = styled.div`
  color: ${colors.darkGray1};
  font-family: Domine;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;



export const GridWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 260px repeat(${props => props.isTier1Hidden ? '2' : '3'}, 1fr) 1fr;
`;

export const StickyGridHeadersBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 72px;
  height: 86px;
  z-index: 3;
  background: #fff;
`;

export const EmptyTableHeaderCell = styled.div`
  ${props => props.isSticky ? `
  position: sticky;
  top: 19px;
  padding-top: 43px;
  z-index: 3
  background: #fff;
  ` : ``}
`;

export const Cell = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  // border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  // ${props => props.isLastColumn ? `
  // margin-left: 24px;
  // border-left: 1px solid rgba(0, 0, 0, 0.05);
  // ` : ``};
  ${props => props.isLastRow ? `
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  ` : ``};
  ${props=>props.isFirstColumn ? `
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  ` : ``}
  ${props=>props.isSectionDivider ? `
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  ` : ``}
  ${props=>props.isFirstRow ? `
  border-top: 1px solid ${colors.gray3};
  ${props.isRecommended ? `` : `
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  `}
  ` : ``}
  ${props=>props.isHovered ? `
  background: ${colors.gray3};
  ` : ``}
`;

export const RecommendedCell = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: ${props => props.isLastColumn ? `-1` : `0`}px;
  border-left: 2px solid ${props => props.isBlack ? '#000' : colors.blueAzul};
  border-right: 2px solid ${props => props.isBlack ? '#000' : colors.blueAzul};
  ${props => props.isLastRow ? `
  border-bottom: 6px solid ${props.isBlack ? '#000' : colors.blueAzul};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  ` : ``};
  pointer-events: none;
`;

export const RowHeaderCell = styled.div`
  display: flex;
  position: relative;
  padding: 10px 16px;
  // border: 1px solid rgba(0, 0, 0, 0.05);
  font-weight: 450;
  font-size: 14px;
  line-height: 115%;
  color: ${colors.darkGray1};
  white-space: pre-line;
  align-items: ${props => props.isFirst ? 'flex-end' : 'center'};
  ${props => props.isFirst ? `` : `
  border-top: none;
  ${props.isHovered ? `
  background: ${colors.gray3};
  ` : ``}
  `};
`;

export const ColumnHeaderCell = styled.div`
  position: relative;
  font-family: 'Domine';
  font-size: 20px;
  line-height: 23px;
  color: ${props => props.isBlack ? '#fff' : colors.darkGray1};
  ${flexCenter};
  // padding: 10px 0;
  // background-color: ${props => props.isBlack ? '#000' : colors.gray3};
  
  
  background-color: ${props => props.isBlack ? '#000' : ''};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  // border-top: 1px solid ${colors.gray3};


  :nth-of-type(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }


  :not(:last-of-type) {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
  };
  :nth-of-type(${props => props.isPayedTier ? '1' : '2'}) {
    // border-top: 1px solid ${colors.gray3};
  };
  :nth-of-type(${props => props.isPayedTier ? '3' : '4'}) {
    // border-top: 1px solid ${colors.gray3};
  }
  // :nth-of-type(${props => props.isPayedTier ? '4' : '5'}) {
  //   margin-left: 24px;
  // }
  
  ${props=>props.isSticky ? `
  position: sticky;
  top: 19px;
  z-index: 3;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.05);
  ` : ``};
`;

export const RecommendedHeader = styled.div`
  ${flexCenter};
  position: absolute;
  top: -43px;
  height: 44px;
  left: 0;
  right: -1px;
  color: #fff;
  background-color: ${props=>props.isBlack ? `#000` : `#2565E7`};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  white-space: pre-line;
  font-weight: 450;
  font-size: 12px;
  line-height: 115.5%;
  font-family: 'Circular';
`;

export const SectionDividerCell = styled.div`
  color: ${colors.darkGray3};
  font-family: Circular;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 8px;
`;

export const LearnMoreWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -24px;
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
`;

export const SelectPlanButton = styled(BlueButton)`
  padding: 12px 0;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.darkGray1};
  border: 1px solid #DDE3EB;
  border-radius: 25px;
  min-width: 150px;
  margin-top: 18px;
  ${props => props.isSelected ? `
    background-color: ${props.isBlack ? '#000' : colors.blueAzul};
    color: #fff;
  ` : ``}
  ${props => props.isBlack ? `
  :hover {
    background-color: #000;
  }
  ` : ``}
  ${props => props.isActualTier && `background: #2565E7;color: #FFF;cursor: default;opacity: 0.4;`}
  transition: all 0.2s;
`;

export const VyzerSvgWrapper = styled.div`
  ${flexCenter};
  height: 72px;
`;

export const MobileComparePlansContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 32px;
`;

export const MobileComparePlansHeader = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  height: 72px;
  width: 100%;
  padding: 20px 16px 20px 28px;
  border-bottom: 1px solid ${colors.gray3};
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 1;
`;

export const TitleAndPlanSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 110px;
  padding: 24px 24px 40px 24px;
`;

export const MobileComparePlansTitle = styled.div`
  color: #121D2A;
  font-size: 32px;
  font-family: Domine;
  font-weight: 700;
  line-height: 38px;
`;

export const MobileComparePlansModalCardsWrapper = styled.div`
  display: flex;
  padding: 32px 20px 40px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: #F7F8F9;
`;

export const MobileComparePlansModalCard = styled.div`
  display: flex;
  padding: 32px 32px 40px 32px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 15px 0px rgba(47, 66, 78, 0.15), 0px 0px 2px 0px rgba(0, 0, 0, 0.17);
`;

export const FamilyOfficeCardWrapper = styled.div`
  display: flex;
  padding: 32px 24px 28px 24px;
  flex-direction: column;
  gap: 32px;
  border-radius: 12px;
  background: radial-gradient(93.66% 66.54% at 51.19% -31.75%, rgba(241, 138, 116, 0.27) 46.35%, rgba(242, 160, 142, 0.00) 100%), #030A13;
  box-shadow: 0px 4px 15px 0px rgba(150, 105, 52, 0.10), 0px 0px 2px 0px rgba(150, 105, 52, 0.17);
  position: relative;
`;

export const FamilyOfficeLabel = styled.div`
  ${flexCenter};
  gap: 2px;
  position: absolute;
  right: -6px;
  top: 8px;
  width: 128px;
  height: 38px;
  color: #FFCEAB;
`;

export const FamilyOfficeCardDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FamilyOfficeCardTitle = styled.div`
  color: #fff;
  font-size: 24px;
  font-family: Domine;
`;

export const FamilyOfficeCardText = styled.div`
  color: #fff;
  font-size: 17px;
  font-family: Circular;
  font-weight: 450;
  line-height: 23px;
`;

export const LearnMoreText = styled.div`
  color: #FFCEAB;
  font-size: 16px;
  font-family: Circular;
  font-weight: 500;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CardTitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CardTitleWrapper = styled.div`
  color: #121D2A;
  font-size: 24px;
  font-family: Domine;
  font-weight: 700;
`;

export const CardSubtitleWrapper = styled.div`
  color: #121D2A;
  font-size: 17px;
  font-family: Circular;
  font-weight: 450;
  line-height: 23px;
`;

export const CardPlanPriceWrapper = styled.div`
  display: flex;
  padding-top: 6px;
  align-items: center;
  gap: 8px;
`;

export const BigDiscountLabeWrapper = styled.div`
  width: fit-content;
  font-family: 'Circular';
  height: ${props => props.height || 20}px;
  background-color:  #121D2A;
  padding: 0px 8px 0;
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 12px;
  line-height: ${props => props.lineHeight || 20}px;
  font-weight: 500;
  border-radius: 8px 0;
  white-space: nowrap;
`;

export const CardPlanPriceValue = styled.div`
  display: flex;
  align-items: center;
  gap:8px;
  color: #121D2A;
  font-size: 32px;
  font-family: Domine;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CardPlanDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${colors.darkGray2};
  font-size: 14px;
  font-family: Circular;
  font-style: normal;
  font-weight: 450;
  line-height: 15.5px;
`;

export const CardPortfolioAndFeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const CardPortfolioAndFeaturesTitle = styled.div`
  color: ${colors.darkGray3};
  font-size: 13px;
  font-weight: 500;
  font-family: Circular;
`;

export const CardPerksRowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardPerkRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-weight: 450;
  line-height: 22px;
  svg{
    margin-top: 4px;
  }
`;

export const MobileComparePlansFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px;
`;

export const HoverDataCompTitle = styled.div`
  width: 100%;
  color: ${colors.darkGray1};
  font-family: Domine;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
`;

export const HoverDataCompSubTitle = styled.div`
  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 19px;
`;

export const MagicBoxCell = styled.div`
  text-align: center;
  line-height: 16px;
`;

export const TooltipContainer = styled.div`
  margin-left: auto;
  @media (min-width: 865px) {
    display: none;
  }
`;

export const LinkArrowIconWrapper = styled.div`
  ${flexCenter};
  svg {
    path {
      stroke: #fff;
    }
  }
`;