import { useEffect, useState } from 'react';

export const useAnimationsStepDelayedHook = ({animationsStepDelayed = []}) => {
  const [animationStep, setAnimationStep] = useState(0);

  useEffect(() => {
    if (animationStep === animationsStepDelayed.length) { return }
    const timeout = setTimeout(() => {
      setAnimationStep(animationStep + 1);
    }, animationsStepDelayed[animationStep]);
    return () => {
      clearTimeout(timeout);
    };
  }, [animationStep, animationsStepDelayed]);

  return animationStep;
}
