import { Observer } from "mobx-react";
import React, { forwardRef, useState } from "react";
import { LoginPasswordInput, NewPasswordRule, PasswordRuleWrapper, RuleMark, RuleText } from './PasswordDisplayer.styles';
import { ReactComponent as CheckmarkIcon } from './assets/checkmark-icon.svg';

const passwordValidators = {
    containsSpecialCharacter: (str) => /^(?=.*[\W_])/.test(str),
    containsLowercase: (str) => /^(?=.*[a-z])/.test(str),
    containsUppercase: (str) => /^(?=.*[A-Z])/.test(str),
    containsDigit: (str) => /^(?=.*[\d])/.test(str),
};

export const PasswordDisplayer = forwardRef((props, passwordInputRef) => {
    const [passwordValue, setPasswordValue] = useState('');

    const handlePasswordChange = (e) => {
        const newValue = e.target.value;
        setPasswordValue(newValue);
        if (props.onChange) {
            props.onChange(newValue);
        }
    };

    return (<Observer>{() => (
        <>
            <LoginPasswordInput name="password" className="password-input-wrapper" autoComplete="new-password" ref={passwordInputRef} onKeyDown={props?.passKeyPress} onChange={handlePasswordChange} changepasswordview={props.changePasswordView ? 1 : 0} />
            {props.showHints && (
                <PasswordRuleWrapper changepasswordview={props.changePasswordView ? 1 : 0}>
                    <NewPasswordRule isValid={passwordValidators.containsUppercase(passwordValue)}>
                        <RuleMark>{passwordValidators.containsUppercase(passwordValue) ? <CheckmarkIcon /> : 'A'}</RuleMark>
                        <RuleText isValid={passwordValidators.containsUppercase(passwordValue)}>Uppercase</RuleText>
                    </NewPasswordRule>
                    <NewPasswordRule isValid={passwordValidators.containsLowercase(passwordValue)}>
                        <RuleMark>{passwordValidators.containsLowercase(passwordValue) ? <CheckmarkIcon /> : <RuleMark fontWeight={'400'} fontSize={'28'} lineHeight={'22'}>a</RuleMark>}</RuleMark>
                        <RuleText isValid={passwordValidators.containsLowercase(passwordValue)}>Lowercase</RuleText>
                    </NewPasswordRule>
                    <NewPasswordRule isValid={passwordValidators.containsSpecialCharacter(passwordValue)}>
                        <RuleMark>{passwordValidators.containsSpecialCharacter(passwordValue) ? <CheckmarkIcon /> : <RuleMark fontWeight={'600'}>%</RuleMark>}</RuleMark>
                        <RuleText isValid={passwordValidators.containsSpecialCharacter(passwordValue)}>Symbol</RuleText>
                    </NewPasswordRule>
                    <NewPasswordRule isValid={passwordValidators.containsDigit(passwordValue)}>
                        <RuleMark>{passwordValidators.containsDigit(passwordValue) ? <CheckmarkIcon /> : <RuleMark>123</RuleMark>}</RuleMark>
                        <RuleText isValid={passwordValidators.containsDigit(passwordValue)}>Number</RuleText>
                    </NewPasswordRule>
                    <NewPasswordRule isValid={passwordValue.length >= 8}>
                        <RuleMark>{passwordValue.length >= 8 ? <CheckmarkIcon /> : <RuleMark>8+</RuleMark>}</RuleMark>
                        <RuleText isValid={passwordValue.length >= 8}>Characters</RuleText>
                    </NewPasswordRule>
                </PasswordRuleWrapper>
            )}
        </>
    )}</Observer>)
})