import React, { useEffect } from 'react';
import { Button } from "antd";
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import { LogoLoader } from '../../../../common/components/LogoLoader/LogoLoader';
import { InstitutionAccountsListWrapper, InstitutionAccountsPanelContentWrapper, InstitutionAccountsPanelWrapper } from './ConnectedInstitutionsList.styles';
import { PlaidInstitutionAccountRow } from './PlaidInstitutionAccountRow';

export function PlaidInstitutionAccounts(props) {
  const { inst, accounts, isIncognito, isLoginRequired } = props;
  const { connectedInstitutionsStore } = useStore();

  useEffect(() => {
    connectedInstitutionsStore.fetchPlaidAccounts(inst.id);

    return () => {
      //cleanup
    }
  }, [inst, connectedInstitutionsStore]);

  return (<Observer>{() => (
    <InstitutionAccountsPanelWrapper>
      {inst.isLoadingAccounts ? <div style={{ padding: '8px' }} className="loading-wrapper">
        <LogoLoader wbg />
      </div> :
        (inst.accountsLoaded ?
          <InstitutionAccountsPanelContentWrapper>
            {inst.showErrorMessage ?
              <div>
                <div>An error occurred while trying to get Institution accounts</div>
                <div><Button onClick={() => { connectedInstitutionsStore.fetchAccounts(inst.id) }} type="link" >Try again</Button>, or contact support</div>
              </div> :
              <InstitutionAccountsListWrapper>
                {accounts.map(ac =>
                  <PlaidInstitutionAccountRow key={ac.accountId} account={ac} inst={inst} isIncognito={isIncognito} isLoginRequired={isLoginRequired} />
                )}
              </InstitutionAccountsListWrapper>
            }
          </InstitutionAccountsPanelContentWrapper> : <></>)
      }
    </InstitutionAccountsPanelWrapper>
  )}</Observer>)
}