export const masterInstitutionInputRows = (inst_label, inst_description,classId, showOnlyManual, updateValueFn, withAutoSyncSuggestion = false, withAutoSyncSuggestionOnlyForVip = false , validations = []) => ([{
    fields:[
        {key:'masterInstitution', type:'institutionAutoComplete', label: inst_label, description: inst_description, 
            classId,
            showOnlyManual,
            withAutoSyncSuggestion,
            withAutoSyncSuggestionOnlyForVip,
            onChange:({value,formValues})=>{
                updateValueFn('name', value.text);
                updateValueFn('mainInstitutionListGuid', value.inst);
                // if (formValues.title === ''){
                //     updateValue('title', value);
                // }
            },
            defaultValueFn:(values)=>({inst: values.mainInstitutionListGuid, text: values.name  }),
            ...(validations.length > 0 ? {
                validations
            } : {}),
            setFnForSave: ()=>{return null}
        },
    ]
},
{
    fields:[{
        key:'name', 
        type:'text',
        isHidden:true
    }]
},
{
    fields:[{
        key:'mainInstitutionListGuid', 
        isHidden:true,
        type:'text',
    }]
}]);