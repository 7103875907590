import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { getData, postData } from '../../../../common/utils/network.utils';

export const SaltedgeLink = (props) => {
  const { reconnectId, providerCode, onSuccess, isDisabled, waitingForResponseCB, checkInstitutionExistence } = props;
  const [shouldKeepPulling, setShouldKeepPulling] = useState(true);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const pullingIntervalRef = useRef(null);
  const windowClosedIntervalRef = useRef(null);
  const saltEdgeTab = useRef(null);

  const initSaltedgeLink = async () => {
    const res = await postData(`salt-edge/${reconnectId ? 'reconnect' : 'customer'}`, reconnectId ? { connection_id: reconnectId } : providerCode ? { providerCode } : {})
    saltEdgeTab.current = window.open(res.connect_url);
    setIsWaitingForResponse(true);
    waitingForResponseCB?.(true);
    if (reconnectId) {
      pullingIntervalRef.current = setTimeout(() => { checkReconnectWindow() }, 2000);
    } else {
      const currentDate = moment().format();
      pullingIntervalRef.current = setTimeout(() => { pullNewInsts(currentDate, true) }, 5000);
    }
  }

  const checkReconnectWindow = async () => {
    if (saltEdgeTab.current.closed) {
      const res = await postData(`salt-edge/institutions/${reconnectId}/reconnect-done`)
      setIsWaitingForResponse(false);
      waitingForResponseCB?.(false);
      onSuccess?.(res);
    } else {
      pullingIntervalRef.current = setTimeout(() => { checkReconnectWindow() }, 2000);
    }
  }
  const pullNewInsts = async (fromDate, keepPulling) => {
    try {
      const res = await getData('salt-edge/institutions/timestamp/' + fromDate);
      if (res.data && res.data.length > 0) {
        setIsWaitingForResponse(false);
        waitingForResponseCB?.(false);
        onSuccess?.(res.data[0]);
      } else {
        if (keepPulling) {
          pullingIntervalRef.current = setTimeout(() => {
            pullNewInsts(fromDate, !saltEdgeTab?.current?.closed);
          }, 3000);
        } else {
          setIsWaitingForResponse(false);
          waitingForResponseCB?.(false);
        }
      }
    } catch (err) {
      if (keepPulling) {
        pullingIntervalRef.current = setTimeout(() => {
          pullNewInsts(fromDate, keepPulling);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (!shouldKeepPulling) {
      clearTimeout(pullingIntervalRef.current);
    }
    return () => { }
  }, [shouldKeepPulling]);

  useEffect(() => {
    if (!isWaitingForResponse) {
      clearTimeout(windowClosedIntervalRef.current);
    }
    return () => { }
  }, [isWaitingForResponse]);

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(pullingIntervalRef.current);
  //   }
  // }, []);

  const handleLinkClick = (event) => {
    if (isDisabled) return;
    event.stopPropagation();
    if (!checkInstitutionExistence || !checkInstitutionExistence?.()) {
      initSaltedgeLink();
    }
  }

  return <>
    {isWaitingForResponse && props.loadingComp ? <>
      {React.cloneElement(props.loadingComp, {
        onClick: () => { setShouldKeepPulling(false); setIsWaitingForResponse(false) }
      })}
    </> : <>
      {React.cloneElement(props.children, {
        onClick: handleLinkClick
      })}
    </>}
  </>
}