import React from 'react'
import { useStore } from '../../../../../app/data/root.store';
import { ClassPageItemsListViewWrapper } from '../../../class/components/ClassPage.styles';
import { Observer } from 'mobx-react';
import { ClassPageTickersListView } from '../../../class/components/ClassPageTickersListView';
import { ItemsListView } from './ItemsListView';
import { WealthOverviewClassesListView } from './ClassesListView';
import { useHistory } from 'react-router-dom';
import { navigateToUrl } from '../../../../../../common/utils/navigation.utils';
import { getUrlDisplayName } from '../../../../../../common/utils/string.utils';

export const ItemsListViewSwitcher = (props) => {
  const { isIncognito } = props;
  const { wealthStore, uiStore, filtersStore  } = useStore();
  const history = useHistory();


  const handleItemClick = (item) => {
    if (wealthStore.isBulkEditMode){
      if (!item.isContainer){  
        wealthStore.addOrRemoveItemForBulkEdit(item.id);
      }
    } else {
      if (item.isContainer){
        filtersStore.handleSelectContainer(item.title);
        filtersStore.setAppliedFiltersFromSelection();
        wealthStore.refreshWealthData(false, history);
      } else {
          navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(item.categoryClass.title)}/${item.id}`);
      }
    }
  }

  return (<Observer>{() => (
    <>
    <ClassPageItemsListViewWrapper>
      {/* special cases for list views */}
      {/* Brokerage accounts - holdings view */}
      {wealthStore.isSingleClassFilterResults && wealthStore.filterResultSingleClassId === 3 && wealthStore.listViewMode === 'holdings' ?
        <ClassPageTickersListView isIncognito={uiStore.isIncognitoMode} />
        :
        (wealthStore.isAllocationsViewMode && wealthStore.allocateBy !== 'accounts' ?
            <WealthOverviewClassesListView isIncognito={uiStore.isIncognitoMode} />
            :
            // more cases ( another check ? <customComp> : <ItemsListView isIncognito={isIncognito} />)
            <ItemsListView isIncognito={isIncognito} handleItemClick={handleItemClick} />)}
    </ClassPageItemsListViewWrapper>
    </>
  )}</Observer>)
}

