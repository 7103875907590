import { Checkbox } from 'antd'
import { useEffect } from 'react'
import { ConsentCheckWrapper, VipSyncConsentText, VipSyncConsentWrapper } from '../../../AddItem.styles'
import { useAnalytics } from '../../../../../app/data/AnalyticsProvider';

export const VipSyncConsent = ({ isConsentApproved, setIsConsentApproved, userStore, item, isActivationFlow }) => {
  const { mixpanelTrack } = useAnalytics();

  useEffect(() => {
    mixpanelTrack('VIP Syncing Service Viewed', {
      step_name: 'Service Agreement',
      step_number: 2,
      institution: item.name,
      url: window.location.href
    });
    // return () => {}
  }, [mixpanelTrack, item.name])

  return (
    <VipSyncConsentWrapper isActivationFlow={isActivationFlow}>
      <VipSyncConsentText>
        {`Dear ${userStore.firstNameCapitalize},\n
          We need your permission to contact ${item.name} so you can use our VIP Syncing Service.\n
          We will email you an agreement to sign. Once signed, we can request ${item.name} to:\n
          1. Allow Read-Only mode access for Vyzer to your account.
          2. Sign Vyzer as a CC to your account's email updates.\n
          Rest assured that our access will be strictly read-only, and
          we cannot make any changes or transactions on your behalf.`}
      </VipSyncConsentText>
      <ConsentCheckWrapper onClick={() => { setIsConsentApproved(!isConsentApproved) }}>
        <Checkbox checked={isConsentApproved}></Checkbox>
        <div style={{ ...(!isActivationFlow ? { maxWidth: '380px' } : {}) }}>I authorize Vyzer to contact <span style={{ color: '#1A1B1D', fontWeight: '500', fontStyle: 'italic' }}>{item.name}</span> and get Read-Only mode access to my account.</div>
      </ConsentCheckWrapper>
    </VipSyncConsentWrapper>
  )
}