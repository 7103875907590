import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { PrimaryButtonWithIcon } from '../../../../../../common/components/buttons/NewButtons.styles';
import { useStore } from '../../../../../app/data/root.store';
import { AssetVipSyncingTabWrapper, CloseButtonWrapper, ConsentReminder, NewVipSyncBadge, PromptWrapper } from './AssetVipSyncingTab.styles';
import { ActionLockedIconWithTooltip } from '../../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';

export const AssetVipSyncingTab = ({ id, item, onClose }) => {
  const { userStore, createItemStore, itemStore } = useStore();

  useEffect(() => {
    if (!item?.settings?.promptDismissed?.vipSyncAwareness) {
      itemStore.dismissVipSyncAwareness(id);
    }
    // return () => {}
  }, [id, item, itemStore])
  

  const handleVipSyncClick = () => {
    if (userStore.isActionBlockedByRole('vipSyncFromItemPageTab')) {
      userStore.setShowLimitedSharedAccess(true);
    } else if (userStore.isVipSyncInstitutionLimited) {
      createItemStore.setShowAddVipSyncLimitModal(true);
    } else {
      createItemStore.setIsFromVipVipSyncingTab(true);
      createItemStore.setSelectedVipSyncInstitution(item.institutionsList);
      createItemStore.setVipSyncStep();
      createItemStore.setShowModal(true);
    }
  }

  // const handleCloseButtonClick = () => {
  //   itemStore.dismissVipSyncAwareness(id);
  //   onClose?.();
  // }

  return (
    <Observer>{() => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CloseButtonWrapper 
        // onClick={handleCloseButtonClick}
        >
          {/* <CloseButtonSvg /> */}
        </CloseButtonWrapper>
        <AssetVipSyncingTabWrapper>
          <NewVipSyncBadge>New</NewVipSyncBadge>
          <PromptWrapper>
            {`You can now sync this account
          with our VIP Syncing Service`}
          </PromptWrapper>
          <ConsentReminder>
            This requires Vyzer to have <span style={{ fontWeight: '700' }}>read-only access</span> to your account.<br />
            The initial authorization may take some time, but it will fully serve your needs.
          </ConsentReminder>
          <PrimaryButtonWithIcon noStroke onClick={handleVipSyncClick} style={{ width: 'fit-content', marginTop: '16px' }}>
            <VipSyncSvg />VIP Syncing Service
            {userStore.isActionBlockedByRole('vipSyncFromItemPageTab') && <ActionLockedIconWithTooltip iconColor={'#DDE3EB'} isRoleBlocked={true} />}
          </PrimaryButtonWithIcon>
        </AssetVipSyncingTabWrapper>
      </div>
    )}</Observer>)
}

// const CloseButtonSvg = () => {
//   return (
//     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <line x1="6.28033" y1="5.83295" x2="18.3011" y2="17.8538" stroke="#9097A3" strokeWidth="1.5" />
//       <line x1="5.92695" y1="17.8544" x2="17.9478" y2="5.83362" stroke="#9097A3" strokeWidth="1.5" />
//     </svg>
//   )
// }

export const VipSyncSvg = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2.19507L3.36578 5.48769L6.14621 0V9.21931H0V2.19507Z" fill="white" />
      <path d="M6.14624 0L8.92667 5.48769L12.2924 2.19507V9.21931H6.14624V0Z" fill="white" />
      <line x1="0.614746" y1="11.2625" x2="11.6779" y2="11.2625" stroke="white" strokeWidth="1.47509" />
    </svg>
  )
}