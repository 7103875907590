import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors, views } from '../../../../common/styles/theme.styles';

const inputPaddingLeft = 24;
const inputHeight = 32;
const mobileInputHeight = 36;

export const HeaderSearchBoxWrapper = styled.div`
    width: 100%;
    position: relative;
    .ant-select-focus-visible {
        outline: none;
    }
     .ant-select .ant-select-selector {
        background-color:${colors.lightGray2};
        border: 1px solid ${colors.lightGray2};
        box-shadow: none;
        border-radius: 26px;
        height: ${inputHeight}px;
        ${views.tablet}{
            background-color: ${colors.darkHeroBackground};
            border: 1px solid #3B3F46;
            border-radius: 12px;
            height: ${mobileInputHeight}px;
            color:#fff;
        }
    }
    .ant-select:hover .ant-select-selector, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border: 1px solid ${colors.gray3};
        box-shadow: none;
        height: ${inputHeight}px;
        ${views.tablet}{
            height: ${mobileInputHeight}px;
        }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
        height: ${inputHeight-2}px;
        padding-left: ${inputPaddingLeft}px;
        ${views.tablet}{
            height: ${mobileInputHeight-2}px;
        }
    } 
`;

export const HeaderSearchBoxResultRowWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    ${props=>props.isFullWidth ? `width:100%;` : ``}
`;

export const HeaderSearchBoxEmptyStateWrapper = styled.div`
    /* background-color: #fff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 0px 16px rgba(0, 0, 0, 0.16);
    border-radius: 4px; */
`;

export const ItemLogoWrapper = styled.div`
    height:24px;
    min-width:24px;
    border-radius: 23px;
    /* margin-right:12px; */
    img{
        height:24px;
        width:24px;
    }
`;

export const ItemIconWrapper = styled.div`
    height:24px;
    min-width:24px;
    border-radius: 23px;
    background-color:${props => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right:12px; */
    svg{
        width:16px;
        height:16px;
    }
    path{
        fill:#fff;
    }
`;

export const ItemContent = styled.div`
    flex:1;
`;

export const ItemText = styled.div`
    display:flex;
    flex-direction:column;
    gap:2px;
`;

export const ItemTitle = styled.div`
    color:${colors.darkGray2};
    font-size:14px;
    line-height:17px;
    font-weight:400;
    display:flex;
    align-items:center;
    ${props => !props.isLightTheme && views.tablet}{
        color:#fff;
    }

`;


export const ItemTitleHoldingsBadgeWrapper = styled.div`
    display:  inline-flex;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    margin-left:4px;
    // transform:translateY(-2px);
`;

export const ItemTitleHoldingsBadgeText = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.assetHoldingsBadgeBgColor};
    color:${colors.assetHoldingsBadgeTextColor};
    padding: 1px 0px 1px 4px;
    border-radius: 2px 0px 0px 2px;
`;

export const ItemTitleHoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid ${colors.assetHoldingsBadgeBgColor};
`;


export const ItemArchivedBadge = styled.div`
    ${flexCenter}   
    display:inline-flex; 
    padding: 2px 6px;
    // width: 60px;
    height: 14px;
    background-color: ${colors.darkGray3};
    color: #fff;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    border-radius: 4px;
    margin-left: 8px;
`;

export const ItemConnectedBadge = styled.div`
    ${flexCenter}    
    padding: 2px 4px;
    height: 16px;
    background-color: #EBFFE3;
    color: #20CD7B;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    border-radius: 4px;
    margin-left: 8px;
    svg{
        width:10px;
        height:10px;
        path{
            fill: #20CD7B;
        }
    }
`;

export const ItemConnectedCircle = styled.div`
    width:4px;
    height:4px;
    background-color:#20CD7B;
    border-radius:50%;
    margin-right:4px;
`;

export const ItemLoginRequiredBadge = styled.div`
    ${flexCenter}    
    padding: 2px 4px;
    height: 16px;
    background-color:#FCE6E6;
    color:#912121;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    border-radius: 4px;
    margin-left: 8px;
    svg{
        width:10px;
        height:10px;
        margin-right:4px;
        path{
            fill: #912121;
        }
    }
`;

export const ItemMoreInfos = styled.div`
    font-size:12px;
    line-height: 14px;
    color: #9097A3;
    display:flex;
    flex-direction:row;
    align-items:center;
`;

export const MoreInfosItem = styled.div`
    font-size:12px;
    color: #9097A3;
    &:not(:last-child){padding-right:8px;}
    display: flex;
    align-items: center;
`;

export const SearchTextWrapper = styled.div`
    ${flexCenter};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.darkGray1};
    margin-bottom: 4px;
`;

export const SearchAltTextWrapper = styled(SearchTextWrapper)`
    color: ${colors.darkGray3};
    font-weight: 400;
    padding-bottom: 38px;
`;

export const SearchIconWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 8px;
    left: 12px;
    align-items: center;
    svg{
        width: 16px;
        height: 16px;
        circle, line {
            // stroke: ${props => props.hasSearchInput ? colors.darkGray1 : '#9097A3' }
            stroke:  '#9097A3' 
        }
    }
    ${views.tablet}{
        top: 10px;
    }
`;

export const SearchPlaceholderWrapper = styled.span`
    padding-left: ${inputPaddingLeft}px;
    line-height: 30px;
    ${views.tablet}{
        line-height: 34px;
    }
`;

export const SearchIconEmptyStateWrapper = styled.div`
    ${flexCenter};
    svg{
        width: 32px;
        height: 32px;
    }
    padding-top: 40px;
    padding-bottom: 16px;
`;

export const HighlightedSearchText = styled.span`
    font-weight: 500;
    color: ${colors.darkGray1};
    ${views.tablet}{
       color:#a9b5ff;
    }
`;

export const ItemValue = styled.div`
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 14px;
    font-weight: 450;
`;

export const StockLogoWrapper = styled.div`
  ${flexCenter}
  height:24px;
  width:24px;
  border-radius: 23px;
  margin-right: 6px;
  img{
    border-radius: 23px;
    object-fit: contain;
    height:24px;
    width:24px;
    background-color: #fff;
  }
`;

export const TickerIconPlaceholder = styled.div`
  ${flexCenter};
  background-color: #B3E8CF;
  color: ${colors.darkGray1};
  font-weight: 500;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  font-size: 11px;
`;


export const CryptoCoinIconPlaceholder = styled.div`
  ${flexCenter};
  background-color: #FBD384;
  color: ${colors.darkGray1};
  font-weight: 500;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  font-size: 11px;
`;