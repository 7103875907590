import React, { useState } from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import { DatePicker, Drawer } from "antd";
import {
  ListWrapper, TransactionsPanelBigScreenWrapper, TransactionsMonthPickerWrapper, PreviousMonthButtonWrapper, NextMonthButtonWrapper,
  EmptyActualEventsStateWrapper, DrawerOrPanelInnerContentWrapper, DatePickerWrapper, TransactionsContentWrapper, BottomActionsWrapper, ExpectedTrnsCardWrapper, ActualTrnsCardWrapper, CardContent, CreateNewAssetButton, HeaderStats, CashBox, TransactionsCardHeader, TitlesWrapper, CardTitle, CardSubtitle,
} from './TransactionsPanel.styles';
// import { displayMoneyValue } from '../../../../common/utils/number-display.utils';
import { ArrowDownIcon, ArrowDownSmallTriangleIcon, } from '../../../../assets/icons/common/common-icons'
import { addMonths, enabledBetween, monthDiff } from '../../../../common/utils/dates.utils';
import { CompletelyEmptyState } from './EmptyStates/CompletelyEmptyState';
import { NoCashflowThisMonthEmptyState } from './EmptyStates/NoCashflowThisMonthEmptyState';
import { EditCostOfLivingButton, } from './EmptyStates/EmptyState.styles';
import { ReactComponent as EditCostOfLivingIcon } from './assets/edit-cost-of-living-icon.svg';
import { ActionLocked } from '../../../../common/components/ActionLocked/ActionLocked';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { ActualTransactionItemRow } from './ActualTransactionItemRow';
import { ExpectedTransactionItemRow } from './ExpectedTransactionItemRow';
import { CountAnimation } from '../../../../common/components/count-animation/CountAnimation';

export function TransactionsPanel(props) {
  const {  isWithBanner } = props;
  const { uiStore , cashFlowStore } = useStore();

  return (<Observer>{() => (
    <>
      {!uiStore.isDesktopView ? <TransactionsContent {...props} isMobileMode /> : <>
      {uiStore.cashflowScreenView === 'small' && <>
        <TransactionsDrawer {...props} showTransactionsPanel={cashFlowStore.showTransactionsPanel} setShowTransactionsPanel={(show)=>{cashFlowStore.setShowTransactionsPanel(show)}} />
      </>}
      {uiStore.cashflowScreenView === 'big' && <>
        <TransactionsPanelBigScreenWrapper show={true} isWithBanner={isWithBanner}>
          <TransactionsContent {...props} />
        </TransactionsPanelBigScreenWrapper>
      </>}
      </> }
    </>
  )}</Observer>)
}

export function TransactionsContent(props) {
  const { isIncognito, setShowCostOfLivingModal, isMobileMode } = props;
  const { cashFlowStore, metadataStore, userStore, uiStore, createItemStore } = useStore();
  const [isTrnActualOpen, setIsTrnActualOpen] = useState(false);

  const handleCreateAssetClick = () => {
    if (!userStore.isActionBlockedByRole('cashflowNoAssetEmptyStateCreateItem')) {
      createItemStore.setCategoryStep(true);
      createItemStore.setShowModal(true);
    }
  }

  return (<Observer>{() => (
    <DrawerOrPanelInnerContentWrapper>
      <DatePickerWrapper isSmallScreen={uiStore.cashflowScreenView === 'small'}>
        <TransactionsMonthPicker isMobileMode={isMobileMode} cashFlowStore={cashFlowStore} metadataStore={metadataStore} />
      </DatePickerWrapper>
      <TransactionsContentWrapper isSmallScreen={uiStore.cashflowScreenView === 'small'}>
        <ExpectedTrnsCardWrapper>
          {!isMobileMode &&
          <TransactionsCardHeader isOpen={true}>
            <TitlesWrapper>
              <CardTitle>Remaining expected events</CardTitle>
            </TitlesWrapper>
            <HeaderStats>
              <CashBox cashIn>
                <CountAnimation currency={'USD'} value={cashFlowStore.expectedTransactionCashIn } duration={0.3} />
                
                 {/* {displayMoneyValue(cashFlowStore.expectedTransactionCashIn, 'USD', isIncognito)} */}
              </CashBox>
              <CashBox>
              <CountAnimation currency={'USD'} value={cashFlowStore.expectedTransactionCashOut } duration={0.3} />
                {/* {displayMoneyValue(cashFlowStore.expectedTransactionCashOut, 'USD', isIncognito)} */}
              </CashBox>
            </HeaderStats>
          </TransactionsCardHeader> }
          <CardContent>
            <>
              {!cashFlowStore.hasCostOfLiving && !metadataStore.hasNonCashAccountAssets && !cashFlowStore.expectedTransactionsOnSelectedMonth.length ? <CompletelyEmptyState setShowCostOfLivingModal={setShowCostOfLivingModal} /> :
                !cashFlowStore.expectedTransactionsOnSelectedMonth.length ? <NoCashflowThisMonthEmptyState hasActualTransactionsOnSelectedMonth={cashFlowStore.actualTransactionsOnSelectedMonth.length > 0} setShowCostOfLivingModal={setShowCostOfLivingModal} /> : null}
              {cashFlowStore.expectedTransactionsOnSelectedMonth.length ? <ListWrapper isAnimate={cashFlowStore.isAnimateDateChange}>
                {cashFlowStore.expectedTransactionsOnSelectedMonth?.map((trn, ind) => {

                  const trnRelatedClass = trn.itemCategoryId ? metadataStore.classesObj[metadataStore.categoriesObj[trn.itemCategoryId].classId] : null;
                  const isSimulation = trn.eventType === 'Simulation';
                  const isProfileEvent = ['Profile monthly income', 'Profile monthly expenses'].includes(trn.eventType);

                  return (
                    <ExpectedTransactionItemRow key={ind} trn={trn} trnRelatedClass={trnRelatedClass} isSimulation={isSimulation} isProfileEvent={isProfileEvent} isIncognito={isIncognito} setShowCostOfLivingModal={setShowCostOfLivingModal} />
                  )

                })}
              </ListWrapper> : null}
            </>
          </CardContent>
        </ExpectedTrnsCardWrapper>
        <ActualTrnsCardWrapper isCardOpen={isTrnActualOpen}>
          <TransactionsCardHeader onClick={() => { setIsTrnActualOpen(!isTrnActualOpen) }} isOpen={isTrnActualOpen} isCollapsible={true}>
            <TitlesWrapper>
              <CardTitle>Actual events <ArrowDownIcon style={{ transition: '0.2s', transform: ` rotate(${isTrnActualOpen ? -180 : 0}deg)` }} /></CardTitle>
              {isTrnActualOpen ? <CardSubtitle>Approved events (Actuals) are not taken into account in cashflow expectations.</CardSubtitle> : null}
            </TitlesWrapper>
          </TransactionsCardHeader>
          {isTrnActualOpen && <CardContent >
            <>
              {!cashFlowStore.actualTransactionsOnSelectedMonth.length ? <EmptyActualEventsState /> : <ListWrapper isAnimate={cashFlowStore.isAnimateDateChange}>
                {cashFlowStore.actualTransactionsOnSelectedMonth?.map((trn, ind) => {
                  const trnRelatedClass = trn.itemCategoryId ? metadataStore.classesObj[metadataStore.categoriesObj[trn.itemCategoryId].classId] : null;
                  const hasTransactionsOnEvent = trn.eventData?.transactions && trn.eventData?.transactions.length > 0;
                  return (
                    <ActualTransactionItemRow key={ind} trn={trn} trnRelatedClass={trnRelatedClass} hasTransactionsOnEvent={hasTransactionsOnEvent} isIncognito={isIncognito} />
                  )
                })}
              </ListWrapper>
              }
            </>
          </CardContent>}
        </ActualTrnsCardWrapper>
        {!metadataStore.hasNonCashAccountAssets && <ActionLocked action={'cashflowNoAssetEmptyStateCreateItem'}><CreateNewAssetButton onClick={handleCreateAssetClick}>+ Create new asset {(userStore.isReadOnlyMode || userStore.isActionBlockedByRole('cashflowNoAssetEmptyStateCreateItem')) && <ActionLockedIconWithTooltip isRoleBlocked={userStore.isActionBlockedByRole('cashflowNoAssetEmptyStateCreateItem')} />}</CreateNewAssetButton></ActionLocked>}
        {!cashFlowStore.hasCostOfLiving && <EditCostOfLivingButton onClick={() => { setShowCostOfLivingModal(true) }}><EditCostOfLivingIcon />Set cost of living</EditCostOfLivingButton>}
      </TransactionsContentWrapper>
      <BottomActionsWrapper isSmallScreen={uiStore.cashflowScreenView === 'small'}>
        {cashFlowStore.hasCostOfLiving && <EditCostOfLivingButton onClick={() => { setShowCostOfLivingModal(true) }}><EditCostOfLivingIcon />Household cost of living</EditCostOfLivingButton>}
      </BottomActionsWrapper>
    </DrawerOrPanelInnerContentWrapper>
  )}</Observer>)
}

function TransactionsDrawer(props) {
  const { showTransactionsPanel, setShowTransactionsPanel } = props;
  return (
    <Drawer
      closable={false}
      visible={showTransactionsPanel}
      width={340}
      className={'transactionsDrawerContainer'}
      placement='left'
      onClose={() => { setShowTransactionsPanel(false) }}>
      <TransactionsContent {...props} />
    </Drawer>
  )
}

const TransactionsMonthPicker = (props) => {
  const { cashFlowStore, isMobileMode } = props;
  const { monthsList } = cashFlowStore;
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const latestRowDate = addMonths(new Date(), monthsList[0]?.monthIndex);

  const disabledDate = (current) => {
    return enabledBetween(current, new Date(), latestRowDate)
  }

  const handleTimeframeChange = (date, dateString) => {
    const monthIndex = monthDiff(new Date(), date.toDate());
    cashFlowStore.setDisplayTransactions(cashFlowStore.displayTransactionsDataSet, monthIndex);
  }

  const handlePreviousMonthClick = () => {
    if (cashFlowStore.displayTransactionsMonth > 0) {
      cashFlowStore.setDisplayTransactions(cashFlowStore.displayTransactionsDataSet, cashFlowStore.displayTransactionsMonth - 1);
    }
  }

  const handleNextMonthClick = () => {
    if (cashFlowStore.displayTransactionsMonth < monthsList[0]?.monthIndex) {
      cashFlowStore.setDisplayTransactions(cashFlowStore.displayTransactionsDataSet, cashFlowStore.displayTransactionsMonth + 1);
    }
  }

  return (
    <TransactionsMonthPickerWrapper>
      <PreviousMonthButtonWrapper disabled={cashFlowStore.displayTransactionsMonth <= 0} onClick={handlePreviousMonthClick}><ArrowDownSmallTriangleIcon /></PreviousMonthButtonWrapper>
      <DatePicker inputReadOnly dropdownClassName='cashflowDatePicker' allowClear={false} format={isMobileMode ? 'MMMM YYYY' : 'MMM YYYY'} defaultValue={cashFlowStore.transactionsMonthPickerDate} value={cashFlowStore.transactionsMonthPickerDate}
        onChange={handleTimeframeChange} picker='month' disabledDate={disabledDate}
        onOpenChange={(open) => { setTimeout(() => { setIsDatePickerOpen(open) }, open ? 0 : 100) }}
      />
      {isDatePickerOpen && <div style={{ position: 'absolute', top: 0, left: 35, width: '130px', height: '36px', backgroundColor: 'transparent' }} />}
      <NextMonthButtonWrapper disabled={cashFlowStore.displayTransactionsMonth >= monthsList[0]?.monthIndex} onClick={handleNextMonthClick}><ArrowDownSmallTriangleIcon /></NextMonthButtonWrapper>
    </TransactionsMonthPickerWrapper>
  )
}

export const EmptyActualEventsState = () => {
  return (
    <EmptyActualEventsStateWrapper>No actual event yet</EmptyActualEventsStateWrapper>
  )
}