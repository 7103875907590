import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CloseXIcon, EditIcon, DeleteIcon } from '../../../assets/icons/common/common-icons';
import { attributeDisplayFormats } from '../../../modules/wealth/pages/asset/components/assetFields';
import { Wrapper, Title, TableHeaderRow, HeaderCell, RowWrapper, RowCell, RowActionsCell, TableScrollContent, ActionButtonWrapper, SaveButton } from './HistoryDropDown.styles'
import { Input,DatePicker  } from "antd";
// import { disabledMoreThanToday } from '../../utils/dates.utils';
import NumberFormat from 'react-number-format';
import { errorNotification } from '../../../modules/layout/components/Notifications/notifications';
import { ActionLocked } from '../ActionLocked/ActionLocked';
import { ActionLockedIconWithTooltip } from '../ActionLocked/ActionLockedIconWithTooltip';
import { useStore } from '../../../modules/app/data/root.store';
import { Observer } from 'mobx-react';

const getDisplayDate = (date) => {
    const d = new Date(date);
    return (("0"+(d.getMonth()+1)).slice(-2) + "/" +d.getFullYear());
}

const tableColumns = ['Date','Value','Note','Edit'];
export function HistoryDropDown(props){
    
    const {atrKey,label,history} = props;

    const handleAttributeRowUpdated = (data) => {
        props.attributeHistoryUpdated && props.attributeHistoryUpdated({...data,attribute:atrKey})
    }

    const handleAttributeRowDeleted = (itemId,atrKey) => {
        props.attributeHistoryDeleted && props.attributeHistoryDeleted(itemId,atrKey);
    }

    return (
        <Wrapper>
            <Title onClick={(e)=>{e.stopPropagation()}}>{label} History</Title>
            <TableHeaderRow onClick={(e)=>{e.stopPropagation()}}>  
              {tableColumns.map((columnLabel,ind)=>(
                <HeaderCell key={'hc1'+ind}>
                    <span>{columnLabel}</span>
                </HeaderCell>
              ))}
            </TableHeaderRow> 
            <TableScrollContent>
            {history.map((row,index)=>(
                <HistoryRow key={row.id} item={row} atrKey={atrKey} rowUpdated={handleAttributeRowUpdated} rowDeleted={handleAttributeRowDeleted}/>
            ))}
            </TableScrollContent>
            {/* {eventTypes.map((event,index)=>(
                <ActualsMenuItem onClick={()=>{props.setItem(index)}} key={index} isHidden={ index === 0 && props.isValueDisabled} > 
                    <ActualsMenuItemTitle>{event.title}</ActualsMenuItemTitle>
                    <ActualsMenuItemDescription>{event.description}</ActualsMenuItemDescription>
                </ActualsMenuItem> 
            ))} */}
        </Wrapper>
    )
}

const HistoryRow = (props) => {
    const { TextArea } = Input;
    const {atrKey,item} = props;
    const [isEdit,setIsEdit] = useState(false);
    const [isDelete,setIsDelete] = useState(false);
    const defaultValues = {...item};
    const [rowData,setRowData] = useState({});
    const { userStore } = useStore();
    // console.log(rowData);

    useEffect(() => {
        setRowData(item);
    }, [item]);

    const handleFieldChange = (field,value) => {
        setRowData({
          ...rowData,
          [field]: value
        })
    }

    const cancelChanges = () => {
        setRowData(defaultValues);
        setIsEdit(false);
    }

    const saveCahnges = () => {
        if (rowData.value || rowData.value === 0){
            setIsEdit(false);
            props.rowUpdated && props.rowUpdated(rowData);
        } else {
            errorNotification(`Can't save empty value.\nPlease set value or delete the update`,{
                style:{whiteSpace:'pre-line'}
            });
        }
    }

    const approveDelete = () => {
        // setIsDelete(false);
        props.rowDeleted && props.rowDeleted(item.id,atrKey);
    }

    return (<Observer>{() => (
        <RowWrapper onClick={(e)=>{e.stopPropagation()}} isEdit={isEdit || isDelete}>
            <RowCell>
                { isEdit ? 
                    <DatePicker picker="month" disabled={rowData.eventId} 
                    inputReadOnly
                    // disabledDate={disabledMoreThanToday} 
                    value={ moment(rowData?.date) } onChange={(val)=>{handleFieldChange('date', (val ?val.format(): rowData?.date))}}/> :
                    getDisplayDate(rowData.date)
                }
            </RowCell>
            <RowCell>

                { isEdit ? 
                     <NumberFormat 
                     thousandSeparator={true} 
                     customInput={Input}
                    //  style={isIncognito && field.shouldBeHiddenIncognito ? {WebkitTextSecurity:'disc'}:{}} 
                     value={rowData?.value}
                     autoComplete="off"
                     onValueChange={(values) => {handleFieldChange('value',values.floatValue)}}
                    />
                    //  <Input type="number" value={rowData?.value} onChange={(e)=>{handleFieldChange('value',e.target.value)}}/>
                 : ( attributeDisplayFormats.hasOwnProperty(atrKey) ? 
                    attributeDisplayFormats[atrKey](rowData.value) :
                    <NumberFormat value={rowData.value} displayType={'text'} thousandSeparator={true} />  )
                }
            </RowCell>
            <RowCell>
                { isEdit ? 
                    <TextArea rows={1} style={{resize:'none'}} value={rowData?.remarks} onChange={(e)=>{handleFieldChange('remarks',e.target.value)}}/> :
                    rowData.remarks
                }
            </RowCell>
            <ActionLocked action={'editHistoryDropdown'}>
                <RowActionsCell show={isEdit || isDelete}>
                    {userStore?.isReadOnlyMode ?
                    <ActionLockedIconWithTooltip iconColor={'#fff'} /> :
                    isEdit ? <>
                        <SaveButton onClick={saveCahnges}>Save</SaveButton>
                        <ActionButtonWrapper onClick={cancelChanges}>
                            <CloseXIcon />
                        </ActionButtonWrapper>
                    </> : (
                    isDelete ? <>
                        <SaveButton onClick={approveDelete}>Delete</SaveButton>
                        <ActionButtonWrapper onClick={()=>{setIsDelete(false)}}>
                            <CloseXIcon />
                        </ActionButtonWrapper>
                    </>:
                    <>
                        <ActionButtonWrapper style={{marginRight:'16px'}} onClick={()=>{setIsEdit(!isEdit)}} >
                            <EditIcon />
                        </ActionButtonWrapper>
                        <ActionButtonWrapper 
                            //onClick={()=>{showDeleteUpdateConfirm()}}>
                            onClick={()=>{setIsDelete(true)}} >
                            <DeleteIcon />
                        </ActionButtonWrapper>
                    </>)}
                </RowActionsCell>
            </ActionLocked>
        </RowWrapper>
    )}</Observer>)
}
  