import { Checkbox, Drawer, Tooltip } from "antd";
import { Observer } from 'mobx-react';
import React from 'react';
import { ArrowDownIcon, LockIcon, ModalCloseIcon } from '../../../assets/icons/common/common-icons';
import { useStore } from '../../app/data/root.store';
import { ApplyFilterButton, AttributeTitle, CheckboxWrapper, ClearFilterButton, FiltersPanelContentWrapper, HeaderCloseButton, HeaderSubTitle, HeaderTitle, MaxSelectionMessageText, MaxSelectionMessageWrapper, PanelFooter, PanelHeader, PanelHeaderRowWrapper, PanelWrapper, ParamHeaderWrapper, ParamTitle, ParamTitleSelectionCount, ParameterPanelWrapper, SubAttributeWrapper, SubAttributesWrapper } from './DataLayersPanel.styles';
import { ReactComponent as MaxSelectionMessageIcon } from './assets/max-selection-message-icon.svg';

export function DataLayersPanel(props) {
    const { dataLayersStore, uiStore } = useStore();

    const handleFiltersPanelContentOnClose = () => {
        dataLayersStore.setShowDrawer(false);
    }

    return (<Observer>{() => (
        <Drawer
            closable={false}
            visible={dataLayersStore.showDrawer}
            width={340}
            height={'calc(100% - 32px)'}
            className={uiStore.isDesktopView ? 'filtersDrawerContainer' : 'filtersDrawerContainer filtersDrawerContainer_mobile'}
            placement={uiStore.isDesktopView ? 'left' : 'bottom'}
            onClose={handleFiltersPanelContentOnClose} >
            <DataLayersPanelContent />
        </Drawer>
    )}</Observer>)
}

function DataLayersPanelContent(props) {
    const { dataLayersStore , uiStore } = useStore();

    const handleClearFilterButtonOnClick = () => {
        dataLayersStore.handleClearDataLayerSelections();
        dataLayersStore.closeAllDataLayers();
        if (!uiStore.isDesktopView){
            dataLayersStore.setSortByForMobile();
        }
    }

    const handleApplyFilterButtonOnClick = () => {
        dataLayersStore.setAppliedDataLayers();
        dataLayersStore.setShowDrawer(false);

        if (!uiStore.isDesktopView){
            dataLayersStore.setSortByForMobile();
        }

    }

    const handleHeaderCloseButtonOnClick = () => {
        dataLayersStore.setShowDrawer(false);
    }

    return (<Observer>{() => (
        <PanelWrapper>
            <PanelHeader>
                <PanelHeaderRowWrapper>
                    <HeaderTitle>{uiStore.isDesktopView ? 'Adjust Data Layers' : 'Select a Custom Metric'}</HeaderTitle>
                    <HeaderCloseButton onClick={handleHeaderCloseButtonOnClick}><ModalCloseIcon /> </HeaderCloseButton>
                </PanelHeaderRowWrapper>
                {uiStore.isDesktopView && <HeaderSubTitle>Select up to 4 metrics columns</HeaderSubTitle>}
            </PanelHeader>
            <FiltersPanelContentWrapper>
                {dataLayersStore.panelDataLayers.map((attr, ind) => (
                    <ParameterPanelWrapper key={attr.dataKey} >
                        <AttrHeader attr={attr} />
                        {attr.subAttributes.length > 0 && attr.isOpen &&
                            <SubAttributesWrapper>
                                {attr.subAttributes?.map((option, index) => (
                                    <SubAttributeWrapper  key={index} onClick={()=>{dataLayersStore.setListOptionSelected(attr.dataKey,option.dataKey,!option.isSelected)}}>
                                        <CheckboxWrapper>
                                            <Tooltip placement='topRight' title={!option.isLocked && dataLayersStore.isMaxSelectedCount && !option.isSelected ? 'A maximum of four metrics can be selected' : ''} >
                                                <Checkbox disabled={!option.isSelected && dataLayersStore.isMaxSelectedCount} checked={option.isSelected} ></Checkbox>
                                            </Tooltip>
                                        </CheckboxWrapper>
                                        <AttributeTitle disabled={!option.isSelected && dataLayersStore.isMaxSelectedCount}>{option.title}</AttributeTitle>
                                    </SubAttributeWrapper>
                                ))}
                            </SubAttributesWrapper>
                        }
                    </ParameterPanelWrapper>
                ))}
                {/* Show More Button if we want to bring it back */}
                {/* {dataLayersStore.shouldShowMorePanelDataLayers && <ShowMoreButton onClick={() => {dataLayersStore.setIsShowAllAttributes(!dataLayersStore.isShowAllAttributes)}} isOpen={dataLayersStore.isShowAllAttributes}>Show {dataLayersStore.isShowAllAttributes ? 'less' : 'more'} <ArrowDownSvg /></ShowMoreButton>} */}
            </FiltersPanelContentWrapper>
            <MaxSelectionMessageWrapper isVisible={dataLayersStore.showMaxSelectedMessage}><MaxSelectionMessageIcon /><MaxSelectionMessageText>Select up to 4 metrics</MaxSelectionMessageText></MaxSelectionMessageWrapper>
            <PanelFooter>
                <ClearFilterButton onClick={handleClearFilterButtonOnClick}>Clear</ClearFilterButton>
                <ApplyFilterButton isDisabled={dataLayersStore.selectedAttributesCount === 0 && dataLayersStore.appliedDataLayers.length === 0} 
                    onClick={()=>{(dataLayersStore.selectedAttributesCount > 0 || dataLayersStore.appliedDataLayers.length > 0 ) && handleApplyFilterButtonOnClick() }}>Apply {dataLayersStore.selectedAttributesCount > 1 ? `(${dataLayersStore.selectedAttributesCount})` : '' } </ApplyFilterButton>
            </PanelFooter>
        </PanelWrapper>
    )}</Observer>)
}

function AttrHeader({ attr }) {
    const { dataLayersStore } = useStore();

    const handleHeaderRowClick = () => {
        if (attr.isLocked) return;
        if (attr.subAttributes.length ){
            dataLayersStore.setDataLayerIsOpen(attr.dataKey, !attr.isOpen) 
        } else {
            dataLayersStore.setDataLayerIsSelected(attr.dataKey, !attr.isSelected )
        }
    }

    return (<Observer>{() => (
        <ParamHeaderWrapper onClick={handleHeaderRowClick} isOpen={attr.isOpen}>
            
            <CheckboxWrapper>
            <Tooltip placement='topRight' title={!attr.isLocked && dataLayersStore.isMaxSelectedCount && !attr.isSelected ? 'A maximum of four metrics can be selected' : ''} >
                <Checkbox disabled={(attr.isLocked || dataLayersStore.isMaxSelectedCount) && !attr.isSelected} checked={attr.isSelected || attr.isLocked} onClick={(e)=>{e.stopPropagation()}} onChange={(e) => { dataLayersStore.setDataLayerIsSelected(attr.dataKey, e.target.checked) }}></Checkbox>
            </Tooltip>
            </CheckboxWrapper>
            <ParamTitle disabled={(attr.isLocked || dataLayersStore.isMaxSelectedCount) && !attr.isSelected}>{attr.title}
                {attr.isLocked ? <Tooltip placement='top' title="This column is mandatory" ><LockIcon className={'attr-lock-icon'}/></Tooltip> : null}
            </ParamTitle>
            {attr.subAttributes.length > 0 ? attr.selectedCount > 0 && dataLayersStore.maxSelectedCount > 1 ?
                <ParamTitleSelectionCount><Tooltip placement='topRight' title={'Selected metrics in this category'}>{attr.selectedCount}</Tooltip></ParamTitleSelectionCount> :
                <ArrowDownIcon style={{ transition: '0.2s', transform: ` rotate(${attr.isOpen ? -180 : 0}deg)` }} /> : null}
        </ParamHeaderWrapper>
    )}</Observer>)
}

// Icon for show more button if needed
// const ArrowDownSvg = () => {
//     return (
//         <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M10.5 15L10.5 5M10.5 15L15.5 10.8333M10.5 15L5.5 10.8333" stroke="#585D66" strokeWidth="1.5"/>
//         </svg>
//     )
// }