export const formatItemEvent = (asset) => {
  const {
    id: item_id,
    title: item_name,
    categoryClass: { title: item_type },
    category: { title: item_subtype },
    connectedProvider: item_source,
    currency: item_currency,
    expectedEndDate: expected_end_date,
    address: property_address,
    beneficiary: holding_entity,
    name: sponsor_name,
  } = asset;

  const sum = {
    item_id,
    item_name,
    item_type,
    item_subtype,
    item_source,
    item_currency,
    expected_end_date,
    property_address,
    holding_entity,
    sponsor_name,
  }

  // clean nullish and empty values from array
  return Object.keys(sum)
    .filter((k) => sum[k] != null && sum[k] !== "")
    .reduce((a, k) => ({ ...a, [k]: sum[k] }), {});
}

/*
? Missing Properties

item_sector - the sector of the item, where applicable (e.g. industrial, energy, real estate, IT)
item_balance - the balance of the item (e.g. crypto, credit, brokerage account balance)
created_from = the source from which the item was created (manually, Plaid/SaltEdge link, magic box)
default_cash_account = the cash account linked to the item
percentage_ownership = the ownership percentage of the item, as reflected in the user's net worth
expected_performance_amount = the value of the performance metric (1.2, 100,000)
expected_performance_metric = user input of the item performance metric (% return/yield/interest/value increase, cash return)
expected_performance_term = value of the term over which the performance metric applies (monthly, quarterly, yearly)
expected_contribution_amount = value of the expected contribution
expected_contribution_term = term of the expected contribution (monthly, quarterly, yearly)
expected_distribution_amount = value of the expected distribution
expected_distribution_term = term of the expecetd distribution (monthly, quarterly, yearly)
committed_capital = value of total committed capital for the item

*/
