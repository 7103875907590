import { Select } from 'antd';
import { Observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { CloseSymmetricIcon } from '../../../assets/icons/common/common-icons';
import { HeaderSearchBoxEmptyState } from '../../../modules/layout/components/HeaderSearchBox/HeaderSearchBoxEmptyState';
import { HeaderSearchBoxResultRow } from '../../../modules/layout/components/HeaderSearchBox/HeaderSearchBoxResultRow';
import { ReactComponent as SearchResultsIcon } from '../../../modules/layout/components/HeaderSearchBox/icons/search-icon.svg';
import { displayMoneyValue } from '../../utils/number-display.utils';
import { BaseModal } from '../BaseModal/BaseModal';
import { PrimaryButton } from '../buttons/NewButtons.styles';
import { CloseBtnWrapper, CreateNewLoanButtonWrapper, HeaderTitle, HeaderWrapper, ModalFooterWrapper, ModalWrapper, PlusSvgWrapper, SearchBoxWrapper, SearchIconWrapper } from '../connect-asset-modal/ConnectAssetModal.styles';
import { InputWrapper } from '../form-displayer/FormDisplayer.styles';
import { LinkedLiabilitiesHeaderSubTitle, SelectedItemWrapper } from './ConnectCollateralModal.styles';
import { useStore } from '../../../modules/app/data/root.store';
import { isNullOrUndefinedOrEmptyString } from '../../utils/object.utils';

export function ConnectCollateralModal({ onClose, onSelectLiabilityForCollateral, loansWithoutCollaterals }) {
  const { Option } = Select;
  const { itemStore, createItemStore, uiStore } = useStore();
  const [inputVal, setInputVal] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSearchWithFocus, setIsSearchWithFocus] = useState(true);
  const selectRef = useRef(null);

  const handleCreateNewLoanClick = () => {
    createItemStore.setHideBackButton(true);
    createItemStore.openModalForSpecificCategory(10, 37);
    onClose?.();
  }

  useEffect(() => {
    setSelectedOption(loansWithoutCollaterals?.find(i => i.id === inputVal))
  }, [inputVal, loansWithoutCollaterals])

  const handleSelect = (selected, option) => {
    if (option.value === 'newLoanCreation') return;
    setInputVal(option.children.props.item.id);
    setIsSearchWithFocus(false);
    setSearchVal('');
  }

  const handleSelectedItemClick = () => {
    const selectRefElement = selectRef.current;
    if (selectRefElement) {
      selectRefElement.focus();
    }
    setIsSearchWithFocus(true);
  }

  const outstandingLoanValue = () => {
    return itemStore.connectedCollaterals?.reduce((acc, col) => acc + (parseFloat(col.usdValue) * (col.holdings / 100)), 0);
  }

  return (<Observer>{() => (
    <BaseModal onBackDropClick={onClose} width={'538px'} height={'auto'} borderRadius={4}  >
      <HeaderWrapper style={{ alignItems: 'normal' }}>
        <div>
          <HeaderTitle>
            Link a loan against cash value
          </HeaderTitle>
          <LinkedLiabilitiesHeaderSubTitle>Outstanding loans: {displayMoneyValue(outstandingLoanValue(), itemStore.item?.currency)}</LinkedLiabilitiesHeaderSubTitle>
          <LinkedLiabilitiesHeaderSubTitle>Available loan balance: {displayMoneyValue((itemStore.item?.value * ((itemStore.item?.availableCashValueBorrowPercentage / 100) || 1)) - outstandingLoanValue(), itemStore.item?.currency)}</LinkedLiabilitiesHeaderSubTitle>
        </div>
        <CloseBtnWrapper onClick={onClose}>
          <CloseSymmetricIcon />
        </CloseBtnWrapper>
      </HeaderWrapper>
      <ModalWrapper>
        <InputWrapper>
          <SearchBoxWrapper>
            <Select
              ref={selectRef}
              autoFocus
              onFocus={() => { setIsSearchWithFocus(true) }}
              onBlur={() => { setIsSearchWithFocus(false) }}
              defaultOpen
              open={isSearchWithFocus}
              // allowClear
              onClear={() => setInputVal('')}
              value={inputVal}
              showSearch
              placeholder='Search liability to link'
              optionFilterProp='label'
              suffixIcon={null}
              style={{ width: '100%', display: 'flex' }}
              dropdownMatchSelectWidth={428}
              onSearch={(val) => setSearchVal(val)}
              onSelect={handleSelect}
              notFoundContent={<HeaderSearchBoxEmptyState />}
              dropdownClassName={`${!uiStore.isDesktopView ? 'connectLiabilitySearchBoxDropdown_mobile ': ''}headerSearchBoxDropdown`}
              optionLabelProp="label" >
              {loansWithoutCollaterals.map((loan, index) => (
                <Option key={index} value={loan.id} label={loan.title}>
                  <HeaderSearchBoxResultRow item={{ ...loan, classColor: '#EB7E7E', classIcon: 'lia-loans' }} inputValue={searchVal} showValueInRow isLightTheme />
                </Option>
              ))}
              <Option value={'newLoanCreation'}>
                <CreateNewLoanButtonWrapper onClick={handleCreateNewLoanClick}>
                  <PlusSvgWrapper>
                    <PlusSvg />
                  </PlusSvgWrapper>
                  Create new loan
                </CreateNewLoanButtonWrapper>
              </Option>
            </Select>
            <SearchIconWrapper hasSearchInput={inputVal !== '' || searchVal !== ''} ><SearchResultsIcon /></SearchIconWrapper>
          </SearchBoxWrapper>
          {!isNullOrUndefinedOrEmptyString(selectedOption) && !isSearchWithFocus ?
            <SelectedItemWrapper onClick={handleSelectedItemClick}>
              <HeaderSearchBoxResultRow item={{ ...selectedOption, classColor: '#EB7E7E', classIcon: 'lia-loans' }} inputValue={''} showValueInRow isLightTheme />
            </SelectedItemWrapper>
            :
            null}
        </InputWrapper>
      </ModalWrapper>
      <ModalFooterWrapper>
        <PrimaryButton disabled={!inputVal} onClick={() => { onSelectLiabilityForCollateral([inputVal]) }} >
          Link a loan
        </PrimaryButton>
      </ModalFooterWrapper>
    </BaseModal>
  )}</Observer>)
}

const PlusSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 14L8 2" stroke="#1A1B1D" strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M14 8H2" stroke="#1A1B1D" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  )
}