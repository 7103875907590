import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../styles/combos.styles";
import { colors, shadows } from "../../../../styles/theme.styles";

export const Wrapper = styled.div`
    width: 100%;
`;

const columns = {
    date: '96px',
    eventType: '100px',
    quantity: '123px',
    cost: '100px',
    pricePerCoin: '120px',
    remarks: '105px',
}

export const TableRowSizes = styled.div`
    display: grid;
    grid-template-columns: ${columns.date} ${columns.eventType} ${columns.quantity} ${columns.cost} ${columns.pricePerCoin} ${columns.remarks};
    // date: 96px eventType 80px cost: 104px quantity: 104px pricePerCoin: 80px remarks: 100px;
    padding: 8px 0;
    /* grid-template-columns: 130px 120px  ${props=>props.isWithHoldings ? '240' : '140'}px auto; */
    /* gap:8px; */
`;

export const TableHeaderRow = styled(TableRowSizes)`
    /* border-bottom:1px solid ${colors.gray3}; */
    /* grid-template-columns: 140px 90px 200px 128px; */
    /* padding:4px 0px 8px; */
`;

export const HeaderCell = styled.div`
    font-size:12px;
    color: ${colors.darkGray2}; // #9D9D9D;
    padding-left: 11px;
    :nth-of-type(2), :nth-of-type(4){
        padding-left: 8px;
    }
`;

export const TableScrollContent = styled.div`
    /* overflow-y:auto; */
    .ant-picker , .ant-input{
        border-radius:0px;
        &:hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
            box-shadow: none;
        }
    }

    .ant-input-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }

    .ant-dropdown-trigger{
        font-size: 14px;
        padding-left: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 0px;
    }
`;

export const LifeInsuranceBeneficiaryToggle = styled.div`
    ${flexCenter};
    padding: 6px 16px;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid ${colors.gray3};
    background: #fff;
    box-shadow: ${shadows.toggleShadow};
    cursor: pointer;
    ${props=> props.disabled ? `
    opacity: 0.4;
    cursor: not-allowed;
  ` : ``}
`;

export const LifeInsuranceBeneficiaryWrapper = styled.div`
    display: flex;
    width: 100%;
    font-family: Circular;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    color: ${colors.darkGray2};
    padding-bottom: 16px;
`;

export const LifeInsuranceBeneficiarySimpleLabel = styled.div`
    color: ${colors.darkGray1};
    margin-bottom: 4px;
    font-size:16px;
    line-height: 16px;
    font-weight: normal;
`;

export const LifeInsuranceBeneficiaryAdvancedLabel = styled.div`
    color: ${colors.darkGray1};
    margin-bottom: 4px;
    font-size:16px;
    line-height: 16px;
    font-weight: normal;
`;

export const TableBottomActions = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    padding: 8px 0;
    gap: 26px;
`;

export const CreateButton = styled.div`
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid ${colors.gray3};
    background: #fff;
    box-shadow: ${shadows.toggleShadow};
    cursor: pointer;
    ${props=> props.disabled ? `
    opacity: 0.4;
    cursor: not-allowed;
  ` : ``}
`;

export const RowWrapper = styled(TableRowSizes)`
    /* grid-template-columns: 140px 90px 200px 128px; */
    // padding:${props=>props.isEdit ? '4' : '8'}px 16px;
    padding: 8px 0px;
    position:relative;
    :not(:last-of-type){
        border-bottom:1px solid ${colors.gray3};
    }
    :last-of-type{
        padding-bottom:0px;
    }
    min-height:48px;
    /* &:hover{
        background-color:${colors.lightGray1}; // #2A2A2E;
    } */
`;

export const RowCell = styled.div`
    font-size:14px;
    line-height:24px;
    /* color: #FFFFFF; */
    color: ${colors.darkGray1};
    align-items: center;
    display:flex;

    //:not(:first-of-type, :last-of-type) {
    //    .ant-input{
    //        border-left: none;
    //    }
    //    .ant-picker{
    //        border-left: none;
    //    }
    //    .ant-dropdown-trigger{
    //        border-left: none;
    //    }
    //
    //}
    :first-of-type {
        .ant-input{
            border-radius: 4px 0 0 4px;
        }
        .ant-picker{
            border-radius: 4px 0 0 4px;
        }
        .ant-dropdown-trigger{
            border-radius: 4px 0 0 4px;
        }
    }
    .ant-input{
        height:32px;
        font-size: 14px;
        &.inputEntryError{
            border: 1px solid #FF7676;
        }
    }
    .ant-picker{
        font-size: 14px;
    }
    .ant-picker > .ant-picker-input > input{
        font-size: 14px;
    }
    .ant-input-affix-wrapper{
        height:32px;
        .ant-input {
            font-size: 14px;
            height:22px;
        }
    }

    ${props=>!props.isEditMode ? `
    .ant-input-affix-wrapper {
        .ant-input {
            font-size: 14px;
            background-color: transparent;
            border-color: transparent;
        }
    }
    ` : null}
`;

export const InputWithBadgeWrapper = styled.div`
    position:relative;
    width:${props=>props.isWithHoldings ? '50' : '100'}%;
    .ant-input{
        font-size: 14px;
    }
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 8px;
`;

export const RowActionsCell = styled.div`
    align-items: center;
    display:flex;
    position:absolute;
    left:100%;
    top:3px;
    opacity:0;
    ${RowContainer}:hover &{
        opacity:1;
    }
    &:hover{
        opacity:1;
    }
    
`;

export const ActionButtonWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px;
    svg{
        height: 24px;
        path {
            stroke: #000;
        }
    }
`;



export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  .ant-input{
      height:38px;
      font-size: 14px;
      &.inputEntryError{
          border: 1px solid #FF7676;
      }
  }
`;

export const PlusIconWrapper = styled.div`
    ${flexCenter};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    box-shadow: ${shadows.toggleShadow};
`;