import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../styles/combos.styles';
import { colors } from '../../../../styles/theme.styles';

export const Wrapper = styled.div`
    width: 100%;
    /* background: #fff;
    box-shadow: 0px 0.2px 2px rgba(0, 0, 0, 0.2), 0px 1px 4px #E5E9F2; */
    border-radius: 9px;
    display:flex;
    flex-direction:column;
    /* max-height:340px; */
    padding:16px 0px 0px;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    padding:0 16px 8px;
    color: ${colors.darkGray1}; // #FFFFFF;
`;

export const TableRowSizes = styled.div`
    display: grid;
    grid-template-columns: 180px 148px auto auto;
    gap:8px;
`;

export const TableHeaderRow = styled(TableRowSizes)`
    border-bottom:1px solid ${colors.gray3};
    /* grid-template-columns: 140px 90px 200px 128px; */
    /* padding:4px 0px 8px; */
`;

export const HeaderCell = styled.div`
    font-size:14px;
    line-height: 15px;
    color: ${colors.darkGray2};
    padding-left: 8px;
    display: flex;
    align-items: center;
    padding-bottom:4px;
`;

export const TableScrollContent = styled.div`
    /* overflow-y:auto; */
    .ant-picker , .ant-input{
        border-radius:6px;
        
        &:hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
            box-shadow: none;
        }
    }
    .ant-picker-input{
        >input {
            font-size: 15px;
            line-height: 15px;
        }
        >.ant-picker-suffix{
            margin-top:-1px;
        }
    }

    .ant-input,.ant-picker{
        font-size: 15px!important;
        line-height: 24px!important;
        height:34px!important;
        // border-color:'green';
        // border-color:${'#d9d9d9'};
       
    }
    .ant-input-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }
`;

export const TableBottomActions = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    padding-top:16px;
`;

export const RowWrapper = styled(TableRowSizes)`
    /* grid-template-columns: 140px 90px 200px 128px; */
    // padding:${props=>props.isEdit ? '4' : '8'}px 16px;
    padding: 8px 0px;
    position:relative;
    :not(:last-of-type){
        border-bottom:1px solid ${colors.lightGray2};
    }
    // :last-of-type{
    //     padding-bottom:0px;
    // }
    min-height:48px;
    /* &:hover{
        background-color:${colors.lightGray1}; // #2A2A2E;
    } */
    opacity : ${props=>props.isHidden ? 0.3 : 1 };
     ${props=>props.isHidden ? 
        `height:0px; min-height:0px;overflow:hidden;padding: 0px;border-bottom: none!important;` 
        // `min-height: 38px;height: 38px;padding:0px`
     : ``}
`;

export const RowWRapperBlocker = styled.div`
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background-color: #f2f2f2c7;
`;
    
export const RowCell = styled.div`
    font-size:14px;
    line-height:24px;
    /* color: #FFFFFF; */
    color: ${colors.darkGray1};
    align-items: center;
    display:flex;
    ${props=>props.gridColumn ? `
        grid-column: ${props.gridColumn}
    ` : ``}
    /* padding-right:8px; */
    // margin: 0px 4px;
    /* border: 1px solid #fff; */
    /* padding: 0px 4px; */
    /* ${RowWrapper}:hover &{
        border-radius: 8px;
        border: 1px solid #e8eef3;
        background-color: ${colors.lightGray1};
    } */
`;

export const EndDateText = styled.div`
    font-size:18px;
`;
export const RowActionsCell = styled.div`
    /* position:absolute; */
    align-items: center;
    display:flex;
    position:absolute;
    left:100%;
    top:10px;
    /* right:0px;
    top:4px; */
    opacity:0;
    // visibility:hidden;

    ${props=> !props.isRowHidden ? `
        ${RowWrapper}:hover &{
            opacity:1;
            // visibility:visible;
        }
        &:hover{
            opacity:1;
            // visibility:visible;
        }
    ` : ``}
    
    
`;

export const ActionButtonWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px;
    svg{
        height: 24px;
        path {
            stroke: #000;
        }
    }
`;

export const SaveButton = styled.div`
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:80px;
    color:#3F3F45;
    font-size:16px;
    height: 32px;
    margin-right: 16px;
    cursor: pointer;
`;


export const CreateButton = styled.div`
    ${flexCenter};
    cursor: pointer;
    color:${colors.darkGray1};
    border:1px solid ${colors.darkGray1};
    padding:2px 28px 2px 24px;
    border-radius:6px;
    font-size:14px;
    font-weight: 500;
    svg{
        margin-right:4px;
        stroke:${colors.darkGray1};
    }
    .button-text{
        margin-left:4px;
    }
    ${props=> props.disabled ? `
    opacity: 0.4;
    cursor: not-allowed;
  ` : ``}
`;

export const AmountWrapper = styled.div`
    flex: 1;
`;

export const InputWithBudgeWrapper = styled.div`
    position:relative;
    flex: 1;
    // width:${props=>props.isWithHoldings ? '50' : '100'}%;
`

export const HoldingsBadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    position:absolute;
    left:8px;
    top: 50%;
    transform: translateY(-50%);
    
`;

export const HoldingsBadgeText = styled.div`
    background-color: ${colors.holdingsBadgeColor};
    width:8px;
    height:12px;
    border-radius: 2px 0px 0px 2px;
`;

export const HoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid ${colors.holdingsBadgeColor};
`;


export const TooltipWrapper = styled.div`
    display: flex;
    margin-left: 4px;
`;

export const AmountPercentageWrapper = styled.div`
    ${flexCenter};
    // padding-right:42px;
`