import { useEffect } from 'react'
import { CommonBadge } from '../../../../../../common/components/CommonBadge/CommonBadge'
import { cleanURL } from '../../../../../../common/utils/string.utils'
import { VipSyncIntroText, VipSyncIntroWrapper } from '../../../AddItem.styles'
import { InstitutionLogoWrapper, InstitutionName, InstitutionTextWrapper, InstitutionUrl, LogoPlaceHolder, SaltEdgeLoadingRowWrapper } from '../../addItemInstitutionStep.styles'
import { InstitutionPlaceholderIcon } from '../../../../../../assets/icons/common/common-icons'
import { useAnalytics } from '../../../../../app/data/AnalyticsProvider'

export const VipSyncIntro = ({ item }) => {
  const { mixpanelTrack } = useAnalytics();

  useEffect(() => {
    mixpanelTrack('VIP Syncing Service Viewed', {
      step_name: 'Service Overview',
      step_number: 1,
      institution: item.name,
      url: window.location.href
    });

    // return () => {}
  }, [mixpanelTrack, item.name])


  return (
    <VipSyncIntroWrapper>
      <VipSyncSelectedInstitutionRow item={item} />
      <VipSyncIntroText>Our VIP Syncing Service requires <span style={{ color: '#1A1B1D', fontWeight: '700' }}>read-only</span> access to your account.
        <br />
        <br />
        Please note that this setup process may take some time, depending on how quickly {item.name} responds. Once complete, your account will auto-sync with Vyzer's VIP service.</VipSyncIntroText>
    </VipSyncIntroWrapper>
  )
}

export const VipSyncSelectedInstitutionRow = ({ item, isFullWidth, withoutPadding, resetWhiteSpace }) => {
  return (
    <SaltEdgeLoadingRowWrapper style={{ alignItems: 'center' }} isFullWidth={isFullWidth} withoutPadding={withoutPadding} resetWhiteSpace={resetWhiteSpace}>
      <InstitutionLogoWrapper>
        {item.logoBase64 ? <img alt={item.name} src={'data:image/png;base64,' + item.logoBase64} /> :
          item.logoURL ? <img alt={item.name} src={item.logoURL} /> : <LogoPlaceHolder ><InstitutionPlaceholderIcon /></LogoPlaceHolder>}
      </InstitutionLogoWrapper>
      <InstitutionTextWrapper style={{ justifyContent: 'center' }}>
        <InstitutionName>{item.name}</InstitutionName>
        <InstitutionUrl>{cleanURL(item.homeURL)}</InstitutionUrl>
      </InstitutionTextWrapper>
      <CommonBadge isCTA badgeType={'vipService'} tooltipContent={`Sync this account manually by providing Vyzer a Read-Only access directly to your account.
  This service can take time, but it will fully serve your needs.`} color={'#DEB482'} />
    </SaltEdgeLoadingRowWrapper>
  )
}