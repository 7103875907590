
import React , { useEffect } from 'react';
import {AddressSearchInput } from './AddressSearchInput';
import { Observer} from 'mobx-react';
import { useStore } from '../../../../../modules/app/data/root.store';

export function AddressAutocomplete(props) {
    const { inputKey, handleChange, disabled, value } = props;

    const { metadataStore} = useStore();

    useEffect(() => {
        if (!metadataStore.isMapsApiLoaded && !metadataStore.isLoadingMapsApi){
            metadataStore.setIsLoadingMapsApi(true);
            const googleMapScript = document.createElement('script');
            googleMapScript.src=`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
            googleMapScript.async = true;
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener('load', () => {
                metadataStore.setIsLoadingMapsApi(false);
          });
        }
      }, [metadataStore]);

    return (<Observer>{()=>(
        <>
        { metadataStore.isMapsApiLoaded && 
            <AddressSearchInput value={value} disabled={disabled} valueChanged={(val)=>{handleChange(inputKey,val)}}/>}
        </>
    )}</Observer>)
}