import React from "react";
import { EmptyStatesConst } from "./EmptyStates.consts";
import { EmptyStateSquare } from "./EmptyStateSquare";
import { EmptyStateTitle, EmptyStateContainer, EmptyStateSquares } from "./EmptyState.styles";

const filters = {
    1: {
        syncMode: 'bank',
        institutions: [3059, 2956, 2932, 147801, 3468, -1]
    },
    4: {
        syncMode: 'investor',
        institutions: [147131, 147133, 147628, 148001, 147137, -1]
    },
    8: {
        syncMode: 'investor',
        institutions: [147131, 147132, 147135, 147130, 148114, -1]
    }
}

export const EmptyState = ({ filter }) => {
    const currFilter = filters[filter];
    if (!currFilter) {
        return null;
    }

    const title = currFilter.syncMode === 'bank' ? 'Sync your bank...' : 'Sync your investor portals...';
    const institutions = currFilter.institutions.map((id) => EmptyStatesConst.find((emptyState) => emptyState.id === id));
    return (
        <EmptyStateContainer>
            <EmptyStateTitle>{title}</EmptyStateTitle>
            <EmptyStateSquares>
                {institutions.map((institution) => <EmptyStateSquare institution={institution} syncMode={currFilter.syncMode} />)}
            </EmptyStateSquares>
        </EmptyStateContainer>
    );
};