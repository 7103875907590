import React from 'react';
import { ModifiedTooltip } from '../ModifiedTooltip/ModifiedTooltip';
import { CommonBadgeWrapper } from './CommonBadge.styles';
import { isNullOrUndefined } from '../../utils/object.utils';
import moment from 'moment';

const ConnectedSvg = () => {
  return (
    <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2.5" cy="3" r="2.5" fill="#20CD7B" />
    </svg>
  )
}

const LoginRequiredSvg = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9145 10.5037L7.72481 3.50026C7.39914 2.95434 6.60141 2.95434 6.26552 3.50026L2.08641 10.5037C1.7505 11.0705 2.15983 11.7845 2.81075 11.7845H11.1899C11.8514 11.7845 12.2611 11.0709 11.9145 10.5037ZM6.55952 6.18836C6.55952 5.91519 6.78019 5.68428 7.0636 5.68428C7.33677 5.68428 7.56769 5.90495 7.56769 6.18836V8.29907C7.56769 8.57224 7.34702 8.80316 7.0636 8.80316C6.79044 8.80316 6.55952 8.58249 6.55952 8.29907V6.18836ZM7.05294 10.367C6.76952 10.367 6.54885 10.1361 6.54885 9.86296C6.54885 9.57955 6.77977 9.35888 7.05294 9.35888C7.33635 9.35888 7.55702 9.5898 7.55702 9.86296C7.55702 10.1361 7.33635 10.367 7.05294 10.367Z" fill="#912121" />
    </svg>
  )
}

const NotFoundSvg = () => {
  return (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21387 7.50928H6.37842V7.27637C6.37842 6.8457 6.54102 6.63037 7.12549 6.29199C7.74512 5.92725 8.06592 5.46582 8.06592 4.80664V4.79785C8.06592 3.80029 7.23535 3.10156 5.97852 3.10156C4.62939 3.10156 3.86475 3.85742 3.84277 4.93408V4.94287L5.0293 4.93848H5.03809C5.06006 4.44189 5.39404 4.12109 5.89941 4.12109C6.40039 4.12109 6.73438 4.42432 6.73438 4.8374V4.84619C6.73438 5.25928 6.56299 5.47461 5.99609 5.81299C5.38965 6.16895 5.14795 6.56445 5.20508 7.25439L5.21387 7.50928ZM5.84229 9.62744C6.33447 9.62744 6.6377 9.34619 6.6377 8.89355C6.6377 8.43652 6.33447 8.15527 5.84229 8.15527C5.3501 8.15527 5.04248 8.43652 5.04248 8.89355C5.04248 9.34619 5.3501 9.62744 5.84229 9.62744Z" fill="#585D66"/>
    <rect x="0.6" y="1.1" width="10.8" height="10.8" rx="5.4" stroke="#585D66" strokeWidth="1.2"/>
  </svg>
  )
}

const VipServiceSvg = ({color}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.7381 7.08294L3 4.40437V10.1187H8H13V4.40437L10.2619 7.08294L8 2.61865L5.7381 7.08294Z" fill={color || "#F3A154"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 12.3807H3.5V11.1807H12.5V12.3807Z" fill={color || "#F3A154"} />
    </svg>
  )
}

const CtaArrowSvg = ({ strokeColor }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.37402 7.5C8.37402 7.5 11.7864 7.5 13.3322 7.5C13.3322 9.04575 13.3322 12.4581 13.3322 12.4581" stroke={strokeColor || '#912121'} strokeWidth="1.42493" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 13.8359L13.1167 7.71926" stroke={strokeColor || '#912121'} strokeWidth="1.42493" strokeLinecap="round" />
    </svg>
  )
}

export const ClosedItemSvg = ({style}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M2.90002 7C2.90002 6.44772 3.34774 6 3.90002 6H11.9C12.4523 6 12.9 6.44772 12.9 7V12C12.9 12.5523 12.4523 13 11.9 13H3.90002C3.34774 13 2.90002 12.5523 2.90002 12V7Z" fill="#585D66"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.8999 5.3002C4.8999 3.58811 6.28782 2.2002 7.9999 2.2002C9.71199 2.2002 11.0999 3.58811 11.0999 5.3002V7.8002C11.0999 8.13157 10.8313 8.4002 10.4999 8.4002C10.1685 8.4002 9.8999 8.13157 9.8999 7.8002V5.3002C9.8999 4.25085 9.04924 3.4002 7.9999 3.4002C6.95056 3.4002 6.0999 4.25085 6.0999 5.3002V7.8002C6.0999 8.13157 5.83127 8.4002 5.4999 8.4002C5.16853 8.4002 4.8999 8.13157 4.8999 7.8002L4.8999 5.3002Z" fill="#585D66"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.85557 11.5249C7.66034 11.7203 7.34372 11.7203 7.14841 11.5251L5.62611 10.0032C5.43082 9.80799 5.43077 9.49141 5.626 9.29612L5.9795 8.94251C6.17473 8.74722 6.49131 8.74717 6.68661 8.9424L7.36014 9.61573C7.43826 9.69384 7.56491 9.69381 7.643 9.61567L9.48291 7.77471C9.67812 7.57939 9.9947 7.5793 10.19 7.77451L10.5437 8.12796C10.739 8.32317 10.7391 8.63975 10.5439 8.83507L7.85557 11.5249Z" fill="#DDE3EB" stroke="#585D66" strokeWidth="0.2" strokeLinecap="square"/>
    </svg>
  )
}

export const CommonBadge = ({ tooltipContent, badgeWrapperStyle, prefixIcon, title, suffixIcon, badgeType, onClick, clickable, isLongToolTipText, color, rounded, noMargin, isCTA = false, lastSync }) => {

  const badgeTypes = {
    connected: {
      tooltipContent: `Institution is connected.\nLast synced: ${moment(lastSync).format('lll')}`,
      // tooltipContent: `Institution is connected.`,
      title: `Connected`,
      style: {
        color: '#20CD7B', backgroundColor: '#EBFFE3', padding: '0px 6px 0px 5px',
        ...(isCTA ? { height: '24px', borderRadius: '6px' } : { height: '16px', borderRadius: '4px' })
      },
      prefixIcon: <ConnectedSvg />
    },
    loginRequired: {
      tooltipContent: `Reconnect\nLast synced: ${moment(lastSync).format('lll')}`,
      // tooltipContent: `Reconnect`,
      title: `Login required`,
      style: {
        color: '#912121', backgroundColor: '#FCE6E6', padding: '0px 6px 0px 5px',
        ...(isCTA ? { height: '24px', borderRadius: '6px' } : { height: '16px', borderRadius: '4px' })
      },
      prefixIcon: <LoginRequiredSvg />,
      ...(isCTA ? {
        suffixIcon: <CtaArrowSvg />
      } : {})
    },
    notFound: {
      title: `Account not found`,
      tooltipContent: `This account is no longer active.\nYou are now managing the asset manually.\nPlease contact us if you have any questions.`,
      longToolTip: true,
      style: {
        color: '#585D66', backgroundColor: '#E7E8EE', padding: '0px 6px 0px 5px',
        ...(isCTA ? { height: '24px', borderRadius: '6px' } : { height: '16px', borderRadius: '4px' })
      },
      prefixIcon: <NotFoundSvg />,
    },
    vipService: {
      title: `VIP Syncing Service`,
      tooltipContent: `This is a VIP service.\nContact us for any question`,
      style: {
        color: color || '#DEB482', padding: '0px 6px 0px 5px', border: `1px solid ${color || '#DEB482'}`,
        ...(isCTA ? { height: '24px', borderRadius: '25px' } : { height: '16px', borderRadius: '4px' }),
      },
      prefixIcon: <VipServiceSvg color={color  || '#DEB482'} />,
    },
    limitedFeature: {
      title: `Limited feature`,
      tooltipContent: `Upgrade`,
      style: {
        color: color || '#F3A154', padding: '0px 6px 0px 5px', border: `1px solid ${color || '#F3A154'}`, fontSize: '13px', borderRadius: '25px' ,
        ...(rounded ? {borderRadius: '25px'} : {})
      },
      prefixIcon: <VipServiceSvg color={color || '#F3A154'} />,
    },
    closed: {
      title: `Closed`,
      tooltipContent: `Closed items are still affect all historical performance, but they have no value in the net worth and no expected cash flow events.`,
      style: {
        color: '#585D66', backgroundColor: '#DDE3EB', padding: '0px 6px', gap:'2px',
        ...(isCTA ? { height: '24px', borderRadius: '6px' } : { height: '16px', borderRadius: '4px' })
      },
      prefixIcon: <ClosedItemSvg />,
    },
  }

  
  return (
    <div style={{flexShrink:0, minWidth:'max-content'}}>
      <ModifiedTooltip title={(tooltipContent || tooltipContent === '') ? tooltipContent : badgeTypes[badgeType]?.tooltipContent || ''}
        isLongText={ !isNullOrUndefined(isLongToolTipText) ? isLongToolTipText : badgeTypes[badgeType]?.longToolTip || false }
        overlayInnerStyle={{ whiteSpace: 'pre-line' }}>
        <CommonBadgeWrapper style={{ ...badgeWrapperStyle, ...badgeTypes[badgeType]?.style, ...(isCTA || clickable ? { cursor: 'pointer' } : {}) }} noMargin={noMargin} onClick={onClick}>
          {prefixIcon ? prefixIcon : badgeTypes[badgeType]?.prefixIcon || null}
          {title ? title : badgeTypes[badgeType]?.title || ''}
          {suffixIcon ? suffixIcon : badgeTypes[badgeType]?.suffixIcon || null}
        </CommonBadgeWrapper>
      </ModifiedTooltip>
    </div>
  )
}