import React from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../../app/data/root.store';
import { CategoriesCardContent, CategoriesCardHeader, CategoryItem, ClassButton, ClassCategoriesButtonText, ClassCategoriesCardWrapper, ClassesListWrapper, ClassItemWrapper, ClassRowCategoriesButton, ClassRowWrapper, SelectedCategoriesCount } from './AssetClassesParameterFilter.styles';
import { ArrowDownIcon } from '../../../../../assets/icons/common/common-icons';
import { Checkbox } from 'antd';


export const AssetCustomClassesParameterFilter = () => {
    const { filtersStore } = useStore();

    const handleSelectCustomClass = (cls) => {
        if (cls.isUnspecifiedCustomSubClasses){
            filtersStore.handleSelectUnspecifiedSubClass(cls.isSelected);
        } else {
            filtersStore.handleSelectCustomClass(cls.id)
        }
    }

    return (<Observer>{() => (
        <ClassesListWrapper>
             {filtersStore.customClasses.map((cls,index)=>(
                <ClassItemWrapper key={index+''+cls.id}>
                <ClassRowWrapper >
                    <ClassButton 
                    onClick={()=>{handleSelectCustomClass(cls)}}
                    isSelected={cls.isSelected} color={cls.color}>{cls.name}</ClassButton>
                    <ClassRowCategoriesButton  onClick={()=>{ filtersStore.handleOpenCloseCustomClass(cls.id)}}>
                        {cls.isSelected && cls.subClasses.length > 0 && <>
                            {!cls.isOpen && cls.isAllSubClassesSelected && <ClassCategoriesButtonText>Sub-classes</ClassCategoriesButtonText>}
                            {!cls.isAllSubClassesSelected && (cls.selectedSubClassesCount > 0) && <SelectedCategoriesCount>{cls.selectedSubClassesCount}</SelectedCategoriesCount>}
                            <ArrowDownIcon style={{ transition: '0.2s', transform: ` rotate(${cls.isOpen ? -180 : 0}deg)` }} />
                        </>}
                    </ClassRowCategoriesButton>
                </ClassRowWrapper>
                {cls.isOpen && <ClassCategoriesCardWrapper>
                    <CategoriesCardHeader>
                        <Checkbox checked={cls.isAllSubClassesSelected} onChange={()=>{filtersStore.handleSelectAllSubClasses(cls.id,cls.isAllCategoriesSelected)}}>Sub-classes</Checkbox>
                    </CategoriesCardHeader>
                    <CategoriesCardContent>
                        {cls.subClasses.map((subClass,index)=>(
                            <CategoryItem key={index}
                                // onClick={}
                                >
                                     <Checkbox checked={subClass.isSelected} 
                                     onChange={()=>{filtersStore.handleSelectSubClass(cls.id,subClass.id)}} >{subClass.name}</Checkbox>
                            </CategoryItem>
                        ))}
                    </CategoriesCardContent>
                </ClassCategoriesCardWrapper>}
                </ClassItemWrapper>
             ))}
            
        </ClassesListWrapper>
    )}</Observer>)
}
