import styled from "@emotion/styled/macro";
import { colors, views } from "../../../../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../../../../common/styles/combos.styles";
import { LoadingOutlined } from '@ant-design/icons';


export const ModalCloseButtonWrapper = styled.div`
  position:absolute;
  right:16px;
  top:16px;
  width:24px;
  height:24px;
  cursor: pointer;
  path{
    stroke:${colors.darkGray3};
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  gap: 14px;
  ${flexCenter};
  justify-content: flex-start;
  padding: 20px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #121D2A;
  position: relative;
  white-space: pre-line;
  text-align: center;
  box-shadow: ${props => props.noBorderBottom ? '' : '0px -1px 0px 0px #DDE3EB inset'};
  ${views.tablet}{
    padding:32px 16px 0;
  }
`;



export const ModalContent = styled.div`
  width: 100%;
  flex: 1;
  padding: 16px 24px;
  overflow-y: auto;
`;



export const ModalFooter = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  padding: ${props => props.padding ? props.padding : '16px 24px'};
  box-shadow: 0px 1px 0px 0px #E7E8EA inset;
  // padding-right: 64px;
  // padding-bottom: 20px;
`;

export const ActionButton = styled.div`
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#fff'} ;
    color: ${props => props.whiteText ? '#fff' : colors.darkGray1};
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: ${props => props.isBack ? '10px 20px' : (props.isDelete ? '8px 24px 8px 20px' : '8px 16px')};
    min-width: ${props => props.isDelete ? '156.81px' : 'auto'};
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 1s;
    justify-content: center;
    ${props => props.withIcon ? `gap: 6px` : ``};
    ${props => props.isBack ? `margin-right: auto` : ``};
    ${props => props.disabled ? `opacity: 0.2; cursor: default;` : ``}
`;

export const DeleteComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
`;

export const DeleteComponentChild = styled.div`
    display: flex;
    width: 279px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

export const DeleteComponentIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center
`;

export const DeleteComponentMainText = styled.div`
    color: var(--Dark-Gray-1, #1A1B1D);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

export const DeleteComponentSecondaryText = styled.div`
    color: var(--Dark-Gray-2, #585D66);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 18px;
`;

export const WhiteEraseIcon = styled.div`
    display: flex;
    path{
      stroke: #FFFFFF !important;
    }
`;

export const BulkEditButtonText = styled.div`
    color: var(--White, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const BulkDeleteLoadingIcon = styled(LoadingOutlined)`
    font-size: 24px;
`;