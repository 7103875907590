import { Observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ActionLocked } from '../../../../common/components/ActionLocked/ActionLocked'
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip'
import { navigateToUrl } from '../../../../common/utils/navigation.utils'
import { useStore } from '../../../app/data/root.store'
import { CreateMenuItemIcon, CreateMenuItemSubTitle, CreateMenuItemTextContent, CreateMenuItemTitle, CreateMenuItemWrapper, RecommendedItemBadge } from '../../../layout/components/Header/Header.styles'
import { CreateMenuInvsIcon, CreateMenuMagicBoxIcon } from '../../../layout/components/Header/icons/header-icons'
import { AddItemChooseMethodStepWrapper, MethodWrapper } from '../AddItem.styles'
import { ROUTES } from '../../../../common/constants/routes.const'

export const AddItemChooseMethodStep = () => {
  const { createItemStore, userStore } = useStore();
  const history = useHistory();

  const onCreateSyncClick = (syncMode) => {
    if (!userStore.isActionBlockedByRole('createItemInvestor') || userStore.isActionBlockedByRole('createItemBank')) {
      createItemStore.setInstitutionStep(syncMode);
      createItemStore.setShowModal(true);
    }
  }

  const onCreateManuallyClick = () => {
    if (!userStore.isActionBlockedByRole('createItemManually')) {
      createItemStore.setCategoryStep(true);
      createItemStore.setShowModal(true);
    }
  }

  const onMagicBoxClick = () => {
    if (!userStore.isActionBlockedByRole('createItemMagicBox')) {
      navigateToUrl(history, ROUTES.magicBox.url);
    }
  }

  return (<Observer>{() => (
    <AddItemChooseMethodStepWrapper>
      {/* <MenuWrapper width={'358px'}>  */}

      <ActionLocked padding={'0 16px'} action={'createItemInvestor'} blockCb={()=>{createItemStore.setShowModal(false);}}>
        <MethodWrapper onClick={() => { onCreateSyncClick('investor') }}>
          <CreateMenuItemWrapper>
            <CreateMenuItemIcon>
              <CreateMenuInvsIcon />
            </CreateMenuItemIcon>
            <CreateMenuItemTextContent>
              <CreateMenuItemTitle>Sync an investor portal</CreateMenuItemTitle>
              <CreateMenuItemSubTitle>Search for any operator / sponsor / private fund account.</CreateMenuItemSubTitle>
            </CreateMenuItemTextContent>
          {(userStore?.isDemoOrReadOnlyMode || userStore.isActionBlockedByRole('createItemBank')) && <ActionLockedIconWithTooltip isRoleBlocked={userStore.isActionBlockedByRole('createItemInvestor')} />}
          </CreateMenuItemWrapper>
        </MethodWrapper>
      </ActionLocked>

      <ActionLocked padding={'0 16px'} action={'createItemBank'} blockCb={()=>{createItemStore.setShowModal(false);}}>
        <MethodWrapper onClick={() => { onCreateSyncClick('bank') }}>
          <CreateMenuItemWrapper>
            <CreateMenuItemIcon>
              <SyncBankSvg />
            </CreateMenuItemIcon>
            <CreateMenuItemTextContent>
              <CreateMenuItemTitle>Sync bank & brokerage accounts</CreateMenuItemTitle>
              <CreateMenuItemSubTitle>Securely sync a bank / brokerage / long term savings account.</CreateMenuItemSubTitle>
            </CreateMenuItemTextContent>
          {(userStore?.isDemoOrReadOnlyMode || userStore.isActionBlockedByRole('createItemBank')) && <ActionLockedIconWithTooltip isRoleBlocked={userStore.isActionBlockedByRole('createItemBank')} />}
          </CreateMenuItemWrapper>
        </MethodWrapper>
      </ActionLocked>

      {/* <MenuItemWrapper disabled onClick={(e) => e.stopPropagation()}> */}
      <ActionLocked padding={'0 16px'} action={'createItemMagicBox'} blockCb={()=>{createItemStore.setShowModal(false);}}>
        <MethodWrapper onClick={onMagicBoxClick}>
          <CreateMenuItemWrapper>
            <CreateMenuItemIcon>
              <CreateMenuMagicBoxIcon />
            </CreateMenuItemIcon>
            <CreateMenuItemTextContent>
              {/* <NewItemBadge>NEW</NewItemBadge> */}
              <RecommendedItemBadge>Recommended</RecommendedItemBadge>
              {/* <CreateMenuItemBadge >Coming soon</CreateMenuItemBadge> */}
              <CreateMenuItemTitle>Vyzer magic box</CreateMenuItemTitle>
              <CreateMenuItemSubTitle>Upload any financial document, Vyzer will create an asset/s from it. </CreateMenuItemSubTitle>
            </CreateMenuItemTextContent>
          {(userStore?.isDemoOrReadOnlyMode || userStore.isActionBlockedByRole('createItemMagicBox')) && <ActionLockedIconWithTooltip isRoleBlocked={userStore.isActionBlockedByRole('createItemMagicBox')} />}
          </CreateMenuItemWrapper>
        </MethodWrapper>
      </ActionLocked>
      {/* <MenuSeparators /> */}
      <ActionLocked padding={'0 16px'} action={'createItemManually'} blockCb={()=>{createItemStore.setShowModal(false);}}>
        <MethodWrapper onClick={onCreateManuallyClick}>
            <CreateMenuItemWrapper>
              <CreateMenuItemIcon>
                <ManualItemSvg />
              </CreateMenuItemIcon>
              <CreateMenuItemTextContent>
                <CreateMenuItemTitle>Add manually</CreateMenuItemTitle>
                <CreateMenuItemSubTitle style={{ lineHeight:'normal' }}>Add any type of asset or liability</CreateMenuItemSubTitle>
              </CreateMenuItemTextContent>
            {userStore.isActionBlockedByRole('createItemMagicBox') && <ActionLockedIconWithTooltip isRoleBlocked={userStore.isActionBlockedByRole('createItemMagicBox')} />}
            </CreateMenuItemWrapper>
            {/* {props.userStore?.isReadOnlyMode && <ActionLockedIconWithTooltip />} */}
          </MethodWrapper>
        {/* <MethodWrapper style={{ padding: '0px', width: '100%' }} onClick={onCreateManuallyClick}>
          <CreateMenuAddManual style={{ borderTop: 'none' }}>
            Add Manually
            {userStore?.isDemoOrReadOnlyMode && <ActionLockedIconWithTooltip />}
          </CreateMenuAddManual>
        </MethodWrapper> */}
      </ActionLocked>
      {/* </MenuWrapper> */}

    </AddItemChooseMethodStepWrapper>
  )}</Observer>)
}

const SyncBankSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
    <line x1="18.5" y1="23" x2="18.5" y2="34" stroke="#585D66"/>
    <line x1="15.5" y1="28" x2="15.5" y2="35" stroke="#585D66"/>
    <line x1="21.5" y1="29" x2="21.5" y2="40" stroke="#585D66"/>
    <line x1="24.5" y1="36" x2="24.5" y2="41" stroke="#585D66"/>
    <line x1="27.5" y1="35" x2="27.5" y2="40" stroke="#585D66"/>
    <line x1="30.5" y1="35" x2="30.5" y2="46" stroke="#585D66"/>
    <line x1="33.5" y1="30" x2="33.5" y2="41" stroke="#585D66"/>
    <line x1="36.5" y1="28" x2="36.5" y2="35" stroke="#585D66"/>
    <line x1="39.5" y1="22" x2="39.5" y2="33" stroke="#585D66"/>
    <line x1="42.5" y1="22" x2="42.5" y2="28" stroke="#585D66"/>
    <line x1="45.5" y1="25" x2="45.5" y2="31" stroke="#585D66"/>
    <line x1="48.5" y1="21" x2="48.5" y2="32" stroke="#585D66"/>
    </svg>
  )
}

const ManualItemSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
      <path d="M42 18H47L47 37.5" stroke="#585D66"/>
      <path d="M35.6 49L18 49L18 16L42 16L42 35" stroke="#585D66"/>
      <path d="M42.4496 35L42.5975 35.5236C42.9488 36.7679 44.3707 37.3569 45.499 36.7254L45.9737 36.4597L47.7713 38.2573L47.5056 38.7321C46.8742 39.8604 47.4632 41.2823 48.7075 41.6336L49.2311 41.7814V44.3236L48.7075 44.4715C47.4632 44.8228 46.8742 46.2447 47.5056 47.373L47.7713 47.8478L45.9737 49.6454L45.5403 49.4028C44.3991 48.7641 42.962 49.3745 42.6293 50.6392L42.4496 51.3223L39.9074 51.1051L39.7596 50.5815C39.4083 49.3372 37.9864 48.7482 36.8581 49.3796L36.3833 49.6454L34.5857 47.8477L34.8514 47.373C35.4828 46.2447 34.8939 44.8228 33.6496 44.4715L33.126 44.3236L33.126 41.7814L33.6496 41.6336C34.8939 41.2823 35.4829 39.8604 34.8514 38.7321L34.5857 38.2573L36.3833 36.4597L36.8581 36.7254C37.9864 37.3569 39.4083 36.7679 39.7596 35.5236L39.9074 35H42.4496Z" stroke="#585D66" strokeLinejoin="round"/>
      <circle cx="41.1786" cy="43.1604" r="2.96591" stroke="#585D66"/>
      <line x1="22" y1="26.5" x2="29" y2="26.5" stroke="#585D66"/>
      <line x1="22" y1="22.5" x2="29" y2="22.5" stroke="#585D66"/>
    </svg>
  )
}