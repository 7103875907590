import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { toJS } from 'mobx'
import { Observer } from 'mobx-react';
import { useStore } from '../../app/data/root.store';
import {
  PageWrapper,
  ChartBoxWrapper, ChartHeaderWrapper, ChartWrapper,
  ChartSelectButtonWrapper,
  ChartDisplayAccountsDropdownButton,
  ChartHeaderInputsWrapper, ChartDisplayPeriodInputWrapper, ChartDisplayPeriodOption, CostOfLivingFormDescription, CostOfLivingModalHeader, CostOfLivingModalTitle, CostOfLivingModalContent, CostOfLivingModalFooter, ChartHeaderTitleWrapper, ChartHeaderTitleText, ChartHeaderTitleValue, ChartTopHeaderTitle, LegendsWrapper, CashAccountsDropDownWrapper, ChartHeaderTextDropdownIconWrapper, CashAccountsDropDownTopTextContainer, CashAccountsDropDownTopText, CashAccountsDropDownAccountsList, CashAcountIconTextWrapper, CashAccountsDropDownAccountRow, CashAccountLogoWrapper, CashAccountTitle, CashAccountValue, ClassIconWrapper, PageContent, ChartAndScenariosWrapper, ScenariosCardWrapper, ContentWithEmptyStateBannerWrapper, HeaderTitle, CashFlowMobileHeader, MobileTitleDate, MobileTitleDescription, MobileInflowBoxValue, MobileInflowBoxDescription, MobileInflowBoxWrapper, MobileExpectedInflowOutflowWrapper
} from './CashFlowPage.styles';
import { LogoLoader } from '../../../common/components/LogoLoader/LogoLoader';
import { Select, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
// import { Line } from 'react-chartjs-2';
// import { NewSimulationDrawer } from './NewSimulationDrawer/NewSimulationDrawer';
// import { putData } from '../../../common/utils/network.utils';
import { TransactionsPanel } from './TransactionsPanel/TransactionsPanel';
import { displayMoneyValue } from '../../../common/utils/number-display.utils';
// import { SimulationsDrawer } from './SimulationsDrawer/SimulationsDrawer';
import { ModalCloseIcon, SyncedLightningIcon, ArrowDownIcon } from '../../../assets/icons/common/common-icons'
import { costOfLivingFormSections } from './cashFlowPage.const';
import { BaseModal } from '../../../common/components/BaseModal/BaseModal';
import { FormWithStore } from '../../form-displayer-connector/components/FormWithStore';
import {
  // ActionButton,
  ActionButtonNoBackground
} from '../../../common/components/buttons/Buttons.styles';
import { CreateEventModalClose } from '../../wealth/pages/asset/components/Actuals/CreateEvent.styles';
import { SimulationsPanel } from './SimulationsPanel/SimulationsPanel';

// import { CashFlowMonthsSlider } from './MonthsSlider/CashFlowMonthsSlider';
import { EmptyStateActionButton, EmptyStateActionButtonText } from './TransactionsPanel/EmptyStates/EmptyState.styles';
import { ActionLocked } from '../../../common/components/ActionLocked/ActionLocked';
import { ActionLockedIconWithTooltip } from '../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
// import { LineGraph } from '../../../common/components/charts/LineGraph';
import { getCashFlowEventData } from './cashflow.mixpanel-events';
// import { useHistory } from 'react-router-dom';
import { CashFlowChart } from './CashflowChart/CashFlowChart';
import { AssetClassIcon } from '../../assets/components/AssetClassIcon';
import { AppHeader } from '../../layout/components/Header/Header';
import { RightSidePushMenuWrapper } from '../../layout/components/Layout.styles';
import { PlanFutureCashFlowEmptyState } from './TransactionsPanel/EmptyStates/PlanFutureCashFlowEmptyState';
import { GetFamiliarWithCashflowEmptyState } from './TransactionsPanel/EmptyStates/GetFamiliarWithCashflowEmptyState';
import { PlanFutureCashFlowHeader } from './PlanFutureCashFlowHeader/PlanFutureCashFlowHeader';
import { PrimaryButton } from '../../../common/components/buttons/NewButtons.styles';
import { LimitationModal } from '../../../common/components/LimitationModal/LimitationModal';
import { startIntercomTourById } from '../../../common/utils/intercom.utils';
import { CashFlowMobileBottomPanel } from './CashFlowMobileBottomPanel';
import { AppLoader } from '../../../common/components/Loaders/AppLoader';
import { useAnalytics } from '../../app/data/AnalyticsProvider';

const chartSelectedPointImage = new Image();
chartSelectedPointImage.src = 'https://www.chartjs.org/docs/latest/favicon.ico';
export function CashFlowPage(props) {
  const { Option } = Select;
  const { cashFlowStore, uiStore, userStore, formsStore, wealthStore, createItemStore, metadataStore, billingStore } = useStore();
  const [showCostOfLivingModal, setShowCostOfLivingModal] = useState(false);
  const [isHoverScenarios, setIsHoverScenarios] = useState(false);
  const [isCashAccountTooltipOpen, setIsCashAccountTooltipOpen] = useState(false);

  const { mixpanelTrack } = useAnalytics();

  const pageContentRef = useRef();
  // let history = useHistory();

  useEffect(() => {
    wealthStore.setClassPageView('cash-accounts');
    wealthStore.setIsCashFlowView(true);
    return () => {
      wealthStore.setIsCashFlowView(false);
    }
  }, [wealthStore])


  useEffect(() => {
    async function fetchData() {
      await cashFlowStore.getData(true);
      if (cashFlowStore.shouldStartLearnTour) {
        cashFlowStore.setShouldStartLearnTour(false);
        startIntercomTourById('398462');
      }
    }
    fetchData();
    return () => {
      cashFlowStore.resetData();
    }
  }, [cashFlowStore]);

  useEffect(() => {
    uiStore.setIsInCashflowProjectionView(true);
    uiStore.setIsRightMenuOpen(false);
    return () => {
      uiStore.setIsInCashflowProjectionView(false);
    }
  }, [uiStore]);

  const handleChartDisplayCashAccounts = (value) => {
    cashFlowStore.setDisplayAccounts(value);
  }

  const handleCloseCostOfLivingModal = () => {
    if (!userStore.isDemoMode) {
      cashFlowStore.updateIntercomCostOfLivingKey();
    }
    setShowCostOfLivingModal(false);
  }
  const handleCostOfLivingFormSubmit = () => {
    if (userStore?.isActionBlockedByRole('updateFinancialSettings')) {
      userStore?.setShowLimitedSharedAccess(true);
      setShowCostOfLivingModal(false);
    } else if (formsStore.forms['costOfLivingForm'].currentSectionErrors.isValid) {
      const formValues = toJS(formsStore.forms['costOfLivingForm']?.valuesForSave);
      const { currency, isActionLocked, ...dataToSend } = formValues;
      cashFlowStore.updateCostOfLiving(dataToSend);
      setShowCostOfLivingModal(false);
      mixpanelTrack('Account Updated', getCashFlowEventData(formValues, userStore.isDemoMode))
    } else {
      formsStore.forms['costOfLivingForm'].setErrors(formsStore.forms['costOfLivingForm'].currentSectionErrors.errors)
    }
  }

  const handleSyncBankClick = () => {
    createItemStore.setInstitutionStep('bank');
    createItemStore.setShowModal(true);
  }


  return (<Observer>{() => (
    <>
      {uiStore.isDesktopView ? <AppHeader className="header" /> :
        <CashFlowMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
          <HeaderTitle>Cashflow planning</HeaderTitle>
        </CashFlowMobileHeader>}
      {uiStore.isDesktopView && <RightSidePushMenuWrapper />}
      <PageWrapper >

        {cashFlowStore.isLoading || !metadataStore.isLoaded ?
          <AppLoader backdrop absolute mobileFixed />
          // <LogoLoader backdrop />
          :
          <PageContent ref={pageContentRef} id="cashflowPageWrapper">
            {!cashFlowStore.hasCostOfLiving && !cashFlowStore.hasAnyCashDataEvents && cashFlowStore?.data?.simulations.length === 0 ? <PlanFutureCashFlowEmptyState isMobile={!uiStore.isDesktopView} /> : null}

            <ContentWithEmptyStateBannerWrapper>

              <ChartAndScenariosWrapper>

                {!uiStore.isDesktopView && !cashFlowStore.hasCostOfLiving && !cashFlowStore.hasAnyCashDataEvents && cashFlowStore?.data?.simulations.length === 0 ? null :

                  <ChartBoxWrapper>
                    {uiStore.isDesktopView && (cashFlowStore.hasCostOfLiving || cashFlowStore.hasAnyCashDataEvents || cashFlowStore?.data?.simulations.length > 0) ?
                      <PlanFutureCashFlowHeader />
                      : null}
                    <ChartHeaderWrapper>

                      {cashFlowStore.hasCostOfLiving || cashFlowStore.hasAnyCashDataEvents || cashFlowStore?.data?.simulations.length > 0 ? <>
                        <ChartHeaderTitleWrapper>
                          {!uiStore.isDesktopView && <MobileTitleDate>{moment().add(cashFlowStore.displayTransactionsMonth, 'M').format('MMMM YYYY')}</MobileTitleDate>}
                          <ChartHeaderTitleValue>
                            {uiStore.isDesktopView ?
                              displayMoneyValue(cashFlowStore.currentPosition, 'USD', uiStore.isIncognitoMode) :
                              displayMoneyValue(cashFlowStore.data.cashData?.[cashFlowStore.displayTransactionsMonth], 'USD', uiStore.isIncognitoMode)
                            }</ChartHeaderTitleValue>
                          {!uiStore.isDesktopView && <>
                            <MobileTitleDescription>Expected cash accounts balance</MobileTitleDescription>
                            <MobileExpectedInflowOutflowWrapper>
                              <MobileInflowBoxWrapper isInflow>
                                <MobileInflowBoxValue isInflow>{displayMoneyValue(cashFlowStore.expectedTransactionCashIn, 'USD', uiStore.isIncognitoMode)}</MobileInflowBoxValue>
                                <MobileInflowBoxDescription isInflow>Expected Inflow</MobileInflowBoxDescription>
                              </MobileInflowBoxWrapper>
                              <MobileInflowBoxWrapper >
                                <MobileInflowBoxValue >{displayMoneyValue(cashFlowStore.expectedTransactionCashOut, 'USD', uiStore.isIncognitoMode)}</MobileInflowBoxValue>
                                <MobileInflowBoxDescription>Expected Outflow</MobileInflowBoxDescription>
                              </MobileInflowBoxWrapper>
                            </MobileExpectedInflowOutflowWrapper>
                          </>}


                          {uiStore.isDesktopView && cashFlowStore.data.cashAccounts?.length > 0 ? <Dropdown
                            overlay={<CashAccountsMenu cashAccounts={cashFlowStore.data.cashAccounts} metadataStore={metadataStore} />}
                            trigger={[
                              // 'hover',
                              'click']}
                            placement="bottomLeft"
                            visible={isCashAccountTooltipOpen}
                            onVisibleChange={(visible) => { setIsCashAccountTooltipOpen(visible) }}
                          >
                            <ChartHeaderTitleText>Current cash accounts balance
                              <ChartHeaderTextDropdownIconWrapper isOpen={isCashAccountTooltipOpen}>
                                <ArrowDownIcon />
                              </ChartHeaderTextDropdownIconWrapper>
                            </ChartHeaderTitleText>
                          </Dropdown> : null}
                        </ChartHeaderTitleWrapper>
                        {!cashFlowStore.data.cashAccounts?.length && uiStore.isDesktopView && <ChartHeaderInputsWrapper style={{ marginRight: '70px' }}>
                          <ActionLocked action={'cashflowSyncBankEmptyState'}>
                            <EmptyStateActionButton isLocked={userStore.isReadOnlyMode} onClick={handleSyncBankClick}><SyncedLightningIcon /><EmptyStateActionButtonText>Sync bank account</EmptyStateActionButtonText> {(userStore.isReadOnlyMode || userStore.isActionBlockedByRole('cashflowSyncBankEmptyState')) && <ActionLockedIconWithTooltip isRoleBlocked={userStore.isActionBlockedByRole('cashflowSyncBankEmptyState')} />}</EmptyStateActionButton>
                          </ActionLocked>
                        </ChartHeaderInputsWrapper>}</> : <ChartHeaderTitleWrapper><ChartTopHeaderTitle noMargin>Expected cash flow</ChartTopHeaderTitle></ChartHeaderTitleWrapper>}
                      {/* <h2>Projected cash flow</h2> */}
                      <ChartHeaderInputsWrapper>

                        {uiStore.isDesktopView &&
                          <ChartSelectButtonWrapper>
                            <ChartDisplayAccountsDropdownButton
                              onClick={e => e.preventDefault()}>
                              <span>Accounts</span>
                              {cashFlowStore.displayedAccounts?.length > 0 && <span className="accountsSum">{cashFlowStore.displayedAccounts.length}</span>}
                              <CaretDownOutlined />
                            </ChartDisplayAccountsDropdownButton>
                            {/* <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                              Hover me <DownOutlined />
                          </a> */}
                            <Select
                              onChange={(val) => { handleChartDisplayCashAccounts(val) }}
                              // value={totalDisaplyCurrency}
                              style={{ width: '160px', height: '32px', overflow: 'hidden', opacity: '0', position: 'absolute', top: '0', left: '0' }}
                              dropdownStyle={{ minWidth: '216px', marginLeft: '-28px', left: '-50px' }}
                              mode="multiple"
                              placeholder="Accounts"
                              getPopupContainer={() => document.getElementById('cashflowPageWrapper')}
                            // showSearch={true}
                            >
                              {cashFlowStore.data.cashFlowChartData?.map((ca, ind) => (
                                <Option key={ca.id} value={ca.id}>{ca.label}</Option>
                              ))}
                            </Select>
                          </ChartSelectButtonWrapper>
                        }
                        <ChartDisplayPeriodInputWrapper>
                          {uiStore.isDesktopView && <ChartDisplayPeriodOption selected={cashFlowStore.displayMonths === 6} onClick={() => { cashFlowStore.setDisplayMonths(6) }}>6M</ChartDisplayPeriodOption>}
                          <ChartDisplayPeriodOption selected={cashFlowStore.displayMonths === 12} onClick={() => { cashFlowStore.setDisplayMonths(12) }}>1Y</ChartDisplayPeriodOption>
                          <ChartDisplayPeriodOption selected={cashFlowStore.displayMonths === 36} onClick={() => { cashFlowStore.setDisplayMonths(36) }}>3Y</ChartDisplayPeriodOption>
                        </ChartDisplayPeriodInputWrapper>
                      </ChartHeaderInputsWrapper>
                    </ChartHeaderWrapper>
                    <ChartWrapper isHoverScenarios={isHoverScenarios}>
                      <CashFlowChart />
                    </ChartWrapper>
                    <LegendsWrapper id="legend-container" ></LegendsWrapper>
                    {cashFlowStore.isLoadingSimulationsChanges &&
                      <LogoLoader backdrop absolute />
                    }
                  </ChartBoxWrapper>

                }
                {uiStore.isDesktopView ?

                  <ScenariosCardWrapper onMouseEnter={() => setIsHoverScenarios(true)}
                    onMouseLeave={() => setIsHoverScenarios(false)}  >
                    <SimulationsPanel />
                  </ScenariosCardWrapper> :
                  <CashFlowMobileBottomPanel isIncognito={uiStore.isIncognitoMode} setShowCostOfLivingModal={setShowCostOfLivingModal} />
                }
              </ChartAndScenariosWrapper>

              {
                uiStore.isDesktopView &&
                <>
                  {!cashFlowStore.hasCostOfLiving && !cashFlowStore.hasAnyCashDataEvents ?
                    <GetFamiliarWithCashflowEmptyState setShowCostOfLivingModal={setShowCostOfLivingModal} /> :
                    <TransactionsPanel isIncognito={uiStore.isIncognitoMode} setShowCostOfLivingModal={setShowCostOfLivingModal} />}
                </>
              }

              {showCostOfLivingModal &&
                <BaseModal width={'512px'} height={'auto'} >
                  <CostOfLivingModalHeader>
                    <CostOfLivingModalTitle>
                      Cost of living
                    </CostOfLivingModalTitle>
                    <CreateEventModalClose onClick={handleCloseCostOfLivingModal}>
                      <ModalCloseIcon />
                    </CreateEventModalClose>
                  </CostOfLivingModalHeader>
                  <CostOfLivingModalContent>
                    <CostOfLivingFormDescription>
                      Filling monthly income and expenses help us to better project your cash flow
                    </CostOfLivingFormDescription>
                    <div style={{ flex: 1, overflowY: 'auto', textAlign: 'left' }}>
                      <FormWithStore formName={'costOfLivingForm'} sections={costOfLivingFormSections} defaultValues={{
                        currency: cashFlowStore.data?.costOfLiving.defaultCurrency,
                        monthlyIncome: cashFlowStore.data?.costOfLiving.monthlyIncome,
                        monthlyIncomeCurrency: cashFlowStore.data?.costOfLiving.monthlyIncomeCurrency || 'USD',
                        monthlyExpenses: cashFlowStore.data?.costOfLiving.monthlyExpenses,
                        monthlyExpensesCurrency: cashFlowStore.data?.costOfLiving.monthlyExpensesCurrency || 'USD',
                        isActionLocked: userStore?.isReadOnlyMode || userStore?.isActionBlockedByRole('updateFinancialSettings'),

                      }} />
                    </div>
                  </CostOfLivingModalContent>
                  <CostOfLivingModalFooter>
                    <ActionButtonNoBackground onClick={handleCloseCostOfLivingModal} >Close</ActionButtonNoBackground>
                    <ActionLocked action={'costOfLiving'} blockCb={handleCloseCostOfLivingModal} >
                      <PrimaryButton onClick={handleCostOfLivingFormSubmit} style={{ gap: '4px' }}>Update {(userStore.isReadOnlyMode || userStore?.isActionBlockedByRole('updateFinancialSettings')) && <ActionLockedIconWithTooltip iconColor={'#DDE3EB'} marginLeft={'0'} isRoleBlocked={userStore?.isActionBlockedByRole('updateFinancialSettings')} />}</PrimaryButton>
                      {/* <ActionButton onClick={handleCostOfLivingFormSubmit} >Update {userStore.isReadOnlyMode && <ActionLockedIconWithTooltip iconColor={'#fff'} />}</ActionButton> */}
                    </ActionLocked>
                  </CostOfLivingModalFooter>
                </BaseModal>}
            </ContentWithEmptyStateBannerWrapper>
          </PageContent>}
      </PageWrapper>
      {cashFlowStore.showScenariosLimitationPopup && <LimitationModal modalType="scenarios"
        paramsForText={[userStore.userLimitations.cashFlowScenarios.scenariosLimit]}
        onUpgrade={() => { billingStore.setShowPricingTableModal(true) }}
        onClose={() => cashFlowStore.setShowScenariosLimitationPopup(false)} />}
    </>
  )}</Observer>)
}

const CashAccountsMenu = ({ cashAccounts, metadataStore }) => {
  const cashAccountsMenuRef = useRef(null);

  return (
    <CashAccountsDropDownWrapper width={'360px'} ref={cashAccountsMenuRef} >
      <CashAccountsDropDownTopTextContainer>
        <CashAccountsDropDownTopText >
          This is the sum of your current cash accounts balance, which is the base of the projected balances.
          <br /><br />
          TIP: To auto-correct this number, make sure all of your bank account are synced in.
        </CashAccountsDropDownTopText>
      </CashAccountsDropDownTopTextContainer>
      {cashAccounts && cashAccounts.length > 0 && <CashAccountsDropDownAccountsList>
        {cashAccounts.map(item => {
          let logo

          if (metadataStore?.itemsMeta?.[item.id]?.logoBase64) {
            logo = { type: 'logoBase64', location: metadataStore.itemsMeta[item.id].logoBase64 }
          } else if (metadataStore.itemsMeta[item.id].logoUrl) {
            logo = { type: 'logoUrl', location: metadataStore.itemsMeta[item.id].logoUrl }
          } else {
            logo = { type: 'default', location: 'null' }
          }

          return (
            <CashAccountsDropDownAccountRow key={item.id}>
              <CashAcountIconTextWrapper key={`${item.id}_wrap`}>
                {logo.type === 'logoBase64' && <CashAccountLogoWrapper><img alt={item.connectedInstitutionName} src={'data:image/png;base64,' + logo.location} /></CashAccountLogoWrapper>}
                {logo.type === 'logoUrl' && <CashAccountLogoWrapper><img alt={item.connectedInstitutionName} src={logo.location} /></CashAccountLogoWrapper>}
                {logo.type === 'default' && <ClassIconWrapper><AssetClassIcon name={'cash'} /></ClassIconWrapper>}

                <CashAccountTitle>{item.title}</CashAccountTitle>
              </CashAcountIconTextWrapper>
              <CashAccountValue>{displayMoneyValue(item.holdingsValue, item.currency)}</CashAccountValue>
            </CashAccountsDropDownAccountRow>
          )
        })}
      </CashAccountsDropDownAccountsList>}
    </CashAccountsDropDownWrapper>
  )
}


