import React, { useState } from 'react'
import { DatePicker } from 'antd';
import moment from 'moment';

import { CustomValueLabel, DatePickerWithCustomOptionWrapper, ExtraFooterButton, ExtraFooterWrapper } from './DatePickerWithCustomOption.styles';

export const DatePickerWithCustomOption = (props) => {
  const { customOptions, onChange, value, placeHolder,  ...rest } = props;
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const ExtraFooter = () => {
    return (<ExtraFooterWrapper>
      {customOptions.map((option, index)=>{
        return (<ExtraFooterButton key={index} onClick={()=>{onChange(option.value); setIsDatePickerOpen(false)}}>{option.text}</ExtraFooterButton>)
      })}
    </ExtraFooterWrapper>)
  }
  
  const SelectedCustomOptionText = () => {
    return customOptions.find(option=> option.value === value).text;
  }

  return (
    <DatePickerWithCustomOptionWrapper>
      <DatePicker {...rest} renderExtraFooter={ExtraFooter}
        value={(value && moment(value).isValid()) ? moment(value) : ''} 
        onChange={(val) => { onChange(val ? val.format() : null) }}
        onOpenChange={(open) => { setIsDatePickerOpen(open) }}
        open={isDatePickerOpen}
        placeholder={value ? '' : 'Select month'}
      />
      { (value && !moment(value).isValid() ) && <CustomValueLabel disabled={rest.disabled}>{SelectedCustomOptionText()}</CustomValueLabel>}
    </DatePickerWithCustomOptionWrapper>
  )
}
