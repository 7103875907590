import React, { useState } from 'react';
import { BaseModal } from '../../../../../../common/components/BaseModal/BaseModal';
import { CloseIconWrapper, ConformationBody, ConformationButtonText, ConformationFooter, ConformationHeader, ConformationText, ConformationTextTitle } from './Conformation.style';
import { PrimaryButton, SecondaryButton } from '../../../../../../common/components/buttons/NewButtons.styles';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';
import { CloseIcon, ConformationIcon } from './icons/icons';


export const Conformation = (props) => {
    const { when, onSave } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);
    const history = useHistory();

    const handleBlockedNavigation = (location) => {
        if (nextLocation) {
            return true;
        }

        setNextLocation(location);
        setIsOpen(true);

        return false;
    };

    const closeConfirm = () => {
        setNextLocation(null);
        setIsOpen(false);
    };

    const save = () => {
        onSave();
        closeConfirm();
    };

    const onDontSave = () => {
        setIsOpen(false);
        if (nextLocation) {
            history.push(nextLocation.pathname);
        }
    };

    return (
        <>
            <Prompt
                when={when}
                message={handleBlockedNavigation}
            />
            {
                isOpen &&
                <BaseModal borderRadius={8} width={'520px'} height={'auto'}>
                    <ConformationHeader>
                        <CloseIconWrapper onClick={closeConfirm}>
                            <CloseIcon style={{ stroke: '#9097A3' }} />
                        </CloseIconWrapper>
                    </ConformationHeader>
                    <ConformationBody>
                        <ConformationIcon />
                        <ConformationTextTitle>Unsaved Changes Detected</ConformationTextTitle>
                        <ConformationText>It looks like you've made some changes that haven't been saved.</ConformationText>
                        <ConformationText>What would you like to do?</ConformationText>
                    </ConformationBody>
                    <ConformationFooter>
                        <SecondaryButton padding='12px 32px' onClick={onDontSave}>
                            <ConformationButtonText>Don't save</ConformationButtonText>
                        </SecondaryButton>
                        <SecondaryButton padding='12px 32px' style={{ fontSize: '15px' }} onClick={closeConfirm}>
                            <ConformationButtonText>Review changes</ConformationButtonText>
                        </SecondaryButton>
                        <PrimaryButton padding='12px 32px' style={{ fontSize: '15px' }} onClick={save}>
                            <ConformationButtonText isPrimary>Save changes</ConformationButtonText>
                        </PrimaryButton>
                    </ConformationFooter>
                </BaseModal>
            }
        </>
    );
}