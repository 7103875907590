import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors } from "../../../../common/styles/theme.styles";

export const CommunityBreadcrumbsWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 500;
    height: 20px;
    padding-left: calc((100vw - 1140px)/2 - 20px);
    width: 100%;
    flex:1;
`;

export const CommunityBreadCrumbsHomeIconWrapper = styled.div`
    /* margin-left: 12px; */
    /* height:24px;
    width:64px; */
    gap: 4px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-size: 14px;
    line-height: 17px;
    color: #1A1B1D;
    cursor: pointer;
    svg{
        width:18px;
        height:18px;
    }
    path{
        // transition:all 0.2s;
        /* stroke:#4F699D; */
        fill: #000;
    }
`;

export const FilterTagWrapper = styled.div`
    ${flexCenter};
    gap: 4px;
    padding: 4px 4px 4px 8px;
    border-radius: 22px;
    font-size: 12px;
    line-height: 14px;
    background: ${props=>props.tagColor ? props.tagColor : colors.darkGray2};
    flex-shrink: 0;
    cursor: default;
    margin-left: 4px;
    
`;

export const FilterTagTitle = styled.div`
    color: #fff;
    font-size: 12px;
    line-height: 14px;
`;


export const CloseXIconWrapper = styled.div`
    ${flexCenter};
    border-radius: 30px;
    width: 16px;
    height: 16px;
    background: rgba(255, 255, 255, 10%);
    opacity: 70%;
    cursor: pointer;
    :hover{
        background: rgba(255, 255, 255, 30%); 
        opacity: 100%;
    }

    svg{
        width: 12px;
        height: 12px;
        path {
            stroke-width: 2;
        }
    }
`;