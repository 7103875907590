// import { message } from "antd";
import { makeAutoObservable } from "mobx";
import { getUrlDisplayName } from "../../../common/utils/string.utils";
import { updateAssetCashAccountId } from "../../wealth/data/wealth.service";
import { setOnBoardingComplete, setCreateItemDone, setWelcomeDone, getOnBoardingData, completeQuestionnaire, demoDone, overrideQuestionnaireAnswers } from './onBoarding.service';
import { ls } from "../../../common/utils/localstorage.util";
import { ROUTES } from "../../../common/constants/routes.const";
import { newQuestionnaireStepsData } from "../components/Questionnaire/QuestionnaireSteps.const";

export const ON_BOARDING_STEPS_TEXTS = [
    {title:'Create account', text: 'Are you a human being?',menuText:'Create account'},
    {title:'Add a cash generating asset', text: 'Get started with your first one',menuText:'Add asset'},
    {title:'Link asset to your bank account', text: 'Easily track events when they occur.',menuText:'Link bank account'}
  ]

export const ON_BOARDING_STEP = {
    WELCOME : 'welcome',
    ITEMS : 'items',
    ITEM : 'item',
    CONNECT: 'connect',
    SELECT_ACCOUNT: 'select_account',
    ITEM_FORM : 'item_form'
    // CLASS : 'class',
    // AUTO_OR_MANUALLY : 'auto_manually',
    // CATEGORY : 'catergory',
    // PLAID_ACCOUNTS: 'plaid_accounts',
    // MANUALLY_FORM: 'manually_form'
}

const saltedgeCheckingAccountsNatures = ['account','checking'];


export class OnBoardingStore{
    step = ON_BOARDING_STEP.WELCOME;
    stepInd = 0;
    showLetsGoAnimation = false;
    animateWelcomeMenuOut = false;
    completeAnimationStage = 0;
    item = {};
    connectedAccountId = '';
    connectAccountLoading = false;
    questionnaireStep = 'welcome_step';
    noAnimationStep = 'welcome_step';
    isStepAnimateIn = true;
    isStepAnimateOut = false;
    isAnimateStep = 'none';
    questionnaireData = {};
    isLoadingQuestionnaireRequest = false;
    showDemoModeModal = false;
    isLoadingDemoEnd = false;
    isQuestionnaireFooterSticky = false;
    isQuestionnareTitleSticky = false;
    isPlanFromSelector = null;
    questionnaireStepsData = [];
    currentStepIndex = 0;
    currentStepData = {};
    //onBoardingStore;

    constructor(){
        makeAutoObservable(this);             
    }

    startQuestionnaire(){
        // // setTimeout(()=>{
        //     this.setAnimateIn(false);
        //     setTimeout(()=>{
        //         this.setQuestionnaireStep('name_step');
        //         setTimeout(()=>{
        //             this.setAnimateIn(true);
        //         },0);
        //     },800);
        // // },3000);
        this.setQuestionnaireStep('name_step');
        this.setNoAnimationStep('name_step');
        const scoreFromPlanSelector = ls.get('questionnaireScore');
        this.isPlanFromSelector = scoreFromPlanSelector;
    }

    moveToStep(nextStep){
        this.setNoAnimationStep(nextStep);
        this.setIsAnimateStep('out');
        //setTimeout(()=>{
            
            setTimeout(()=>{
                this.setQuestionnaireStep(nextStep);
                // setTimeout(()=>{
                    this.setIsAnimateStep('in');
                    setTimeout(()=>{
                        //this.setIsAnimateStep('none');
                    },200);
                // },1000);
            },200);
        // },300);
    }

    handleNext = (nextStep) => {
        // to do - check if current step is already exist in the array and update it
        const stepData = {
            key : this.questionnaireStep,
            data: this.currentStepData
        }
        const isDataExist = this.questionnaireStepsData.find(qs=>qs.key === this.questionnaireStep);
        
        this.questionnaireStepsData = isDataExist ? 
            this.questionnaireStepsData.map(sd => sd.key === this.questionnaireStep ? stepData : sd) :
            [...this.questionnaireStepsData,stepData]; 
        
        this.overrideQuestionnaireAnswers();
        this.currentStepIndex++;

        let dataForNextStep = {};
        // is next step a new step
        if (this.questionnaireStepsData.length > this.currentStepIndex){
            // check if next key is same step as next step - if so load data,
            // if not - clear next steps data
            if (this.questionnaireStepsData[this.currentStepIndex].key === nextStep){
                dataForNextStep = {...this.questionnaireStepsData[this.currentStepIndex].data};
            } else {
                this.questionnaireStepsData.length = this.currentStepIndex;
            }
        } 
        this.currentStepData = dataForNextStep; 
        this.moveToStep(nextStep);
    }

    jumpToStep = (step) => {
        this.currentStepIndex = this.questionnaireStepsData.findIndex(({key})=>key === step);
        this.currentStepData = {...this.questionnaireStepsData[this.currentStepIndex].data};
        this.moveToStep(step);
    }

    handleBackButton = () => {
        if (this.currentStepIndex === 0) {
            this.currentStepData = {};
            this.moveToStep('welcome_step');
        } else {
            this.currentStepIndex--;
            const prevStepKey = this.questionnaireStepsData[this.currentStepIndex].key;
    
            const stepConfig = newQuestionnaireStepsData[prevStepKey];
            const stepData = typeof stepConfig === 'function' ? stepConfig({}) : stepConfig;
            if (stepData?.isInsightsStep) {
                this.handleBackButton();
            } else {
                this.currentStepData = {...this.questionnaireStepsData[this.currentStepIndex].data};
                this.moveToStep(prevStepKey);
            }
        }
    }

    deleteCurrentStepData = (key) => {
        delete this.currentStepData[key];
    }
    updateCurrentStepData = (key,value) => {
        this.currentStepData[key] = value;
    }

    async completeQuestionnaire(goToDemo = false, onDone){
        const qObj = {
            questionnaire : this.questionnaireStepsData,
            goToDemo,
            ...(this.isPlanFromSelector !== null ? {
                overrideScore : this.isPlanFromSelector
            } : {})
        }
        
        this.setLoadingQuestionnaire(true);
        ls.remove('questionnaireScore');
        try {
            await Promise.all([
                completeQuestionnaire(qObj),
                new Promise(resolve => setTimeout(resolve, 3000))
            ]);
            onDone();
        } 
        finally {
            //this.setLoadingLiabilityData(false);
        }
    }

    async overrideQuestionnaireAnswers(){
        try {
            await overrideQuestionnaireAnswers(this.questionnaireStepsData);
        } catch (error) {
            
        } finally {

        }
    }

    async completeQuestionnaireStgTesting(){        
        this.setLoadingQuestionnaire(true);
        ls.remove('questionnaireScore');
        try {
            // await completeQuestionnaire(qObj);
            setTimeout(()=>{
                window.location.href = ROUTES.wealthOverview.url;
            },3000);
        } 
        //catch (e) {} 
        finally {
            //this.setLoadingLiabilityData(false);
        }
    }

    

    setIsQuestionnaireFooterSticky(isSticky) {
        this.isQuestionnaireFooterSticky = isSticky;
    }
    setAnimateIn(isAnimate){
        this.isStepAnimateIn = isAnimate;
    }
    setAnimateOut(isAnimate){
        this.isStepAnimateOut = isAnimate;
    }

    setIsAnimateStep(animate){
        this.isAnimateStep = animate;
    }
    setQuestionnaireStep(step){
        this.questionnaireStep = step;
    }
    setNoAnimationStep(step){
        this.noAnimationStep = step;
    }
    setQuestionnaireData(data){
        this.questionnaireData = data;
    }
    setLoadingQuestionnaire(isLoading){
        this.isLoadingQuestionnaireRequest = isLoading;
    }
    setShowDemoModeModal(showModal){
        this.showDemoModeModal = showModal;
    }
    handleGetStarted(){
        setWelcomeDone();
        this.setItemStep();    
    }

    handleItemCreated(item){
        setCreateItemDone();
        this.item = item;
        this.setConnectStep(); 
    }

    handleConnectDone(provider,accounts,inst){
        this.step = ON_BOARDING_STEP.SELECT_ACCOUNT;
        if (provider === 'plaid'){
            const firstCheckingAccount = accounts.find(pa=>pa.type==='depository');
            if (firstCheckingAccount) {
                this.connectedAccountId = firstCheckingAccount.accountId;
            }
        }
        if (provider === 'saltEdge'){
            const firstCheckingAccount = accounts.find(sa=>saltedgeCheckingAccountsNatures.includes(sa.nature));
            if (firstCheckingAccount) {
                this.connectedAccountId = firstCheckingAccount.id;
            }
        }
    }

    getConnectStepData  = async () => {
        const data = await getOnBoardingData();
        this.item = data.assets[0];
    }

    handleLetsGo(){
        // this.setShowLetsGoAnimation(true);
        setWelcomeDone();
        this.showLetsGoAnimation = true;
        setTimeout(()=>{
            this.setAnimateWelcomeMenu(true);
            setTimeout(()=>{
              this.setItemsStep();
            },300);
        },500);
        //this.setShowLetsGoAnimation(true);
    }

    handleComplete(){
        setOnBoardingComplete();
        this.setCompleteAnimationStage(1);
        const newItemUrl = `/wealth/assets/${getUrlDisplayName(this.item.categoryClass.title)}/${this.item.id}`;
        setTimeout(()=>{
            this.setCompleteAnimationStage(2);
            setTimeout(()=>{
                this.setCompleteAnimationStage(3);
            },100)
        },200)
        setTimeout(()=>{
            window.location.href = newItemUrl;
        },1000)
    }

    endDemoMode = async () => {
        this.setLoadingDemoEnd(true);
        try {
            await demoDone();
            window.location.replace(ROUTES.wealthOverview.url);
            // window.location.reload();
        } 
        //catch (e) {} 
        finally {
            this.setLoadingDemoEnd(false);
        }
    }
    setLoadingDemoEnd(loading){
        this.isLoadingDemoEnd = loading;
    }
    setCompleteAnimationStage(stage){
        this.completeAnimationStage = stage;
    }

    setConnectAccountLoading(isLoading){
        this.connectAccountLoading = isLoading;
    }

    setAnimateWelcomeMenu(animate){
        this.animateWelcomeMenuOut = true;
    }

    setItemStep(){
        this.step = ON_BOARDING_STEP.ITEM;
        this.stepInd = 1;
    }

    setConnectStep(){
        this.step = ON_BOARDING_STEP.CONNECT;
        this.stepInd = 2;
    }

    setItemsStep(){
        this.step = ON_BOARDING_STEP.ITEMS;
        // this.stepInd = 1;
    }

    setConnectedAccountId(accountId){
        this.connectedAccountId = accountId;
    }

    setIsQuestionnareTitleSticky(isSticky){
        this.isQuestionnareTitleSticky = isSticky;
    }

    createPlaidSaltedgeAccountsDone = async (accounts) => {
        const selectedAccountAsset = accounts.find(ac=>ac.accountId === this.connectedAccountId);
        await updateAssetCashAccountId(this.item.id,selectedAccountAsset.id);
        this.handleComplete();
        
    }

    get isTier4MessageStep() {
        return this.isPlanFromSelector === '4' || ( this?.questionnaireData?.q3?.[0] !== '1-10' && this?.questionnaireData?.q4?.[0] === 'White glove' &&  this.isPlanFromSelector === null );
    }

    get shouldHideSideBarSteps(){
        const currentStepConfig = newQuestionnaireStepsData[this.noAnimationStep];
        const currentStepData = typeof currentStepConfig === 'function' ? currentStepConfig({}) : currentStepConfig;
        return currentStepData.showPathSteps === false;
    }

    get sideBarSteps(){
        return this.questionnaireStepsData
        .filter(({ key }) => {
          const stepConfig = newQuestionnaireStepsData[key];
          const stepData = typeof stepConfig === 'function' ? stepConfig({}) : stepConfig;
          return stepData.isPathStep !== false;
        })
    } 

    get currentSideBarStepIndex(){
        const currentStepConfig = newQuestionnaireStepsData[this.noAnimationStep];
        const currentStepData = typeof currentStepConfig === 'function' ? currentStepConfig({}) : currentStepConfig;
            
        return this.questionnaireStepsData.slice(0,this.currentStepIndex).reduce(
            (acc, {key}, index) => {
                const stepConfig = newQuestionnaireStepsData[key];
                const stepData = typeof stepConfig === 'function' ? stepConfig({}) : stepConfig;
                return acc + (stepData.isPathStep !== false ? 1 : 0);
            },
            0
        ) - (currentStepData.isPathStep === false ? 1 : 0);
    }

    get stepsPathLength() {
        const BASE_PATH_LENGTH = 6;
        const userType = this.questionnaireStepsData.find(({key})=>key === 'individual_or_advisor_step'); 
        if (userType) {
            const isAdvisor = userType.data?.value === 'advisor';
            if (!isAdvisor) {
                const lookingForStep = this.questionnaireStepsData.find(({key})=>key === 'looking_for_step');
                if ( lookingForStep && lookingForStep.data?.value === 'where_and_how_to_invest_step' ){
                    const typeOfToolStep = this.questionnaireStepsData.find(({key})=>key === 'where_and_how_to_invest_type_of_tool_step');
                    if (typeOfToolStep && typeOfToolStep.data?.value !== 'refining_my_investment_strategy'){
                        const investmentType = this.questionnaireStepsData.find(({key})=>key === 'finding_new_investment_opportunities_and_planning_step');
                        if (investmentType && investmentType.data?.value === 'private_investments'){
                            return BASE_PATH_LENGTH + 2;
                        }
                        return BASE_PATH_LENGTH + 1;
                    }
                }
                
            } else {
                const lookingForStep = this.questionnaireStepsData.find(({key})=>key === 'advisor_looking_for_step');
                if ( lookingForStep && lookingForStep.data?.value === 'where_and_how_to_invest_step' ){
                    const typeOfToolStep = this.questionnaireStepsData.find(({key})=>key === 'advisor_where_and_how_to_invest_type_of_tool_step');
                    if (typeOfToolStep && typeOfToolStep.data?.value !== 'refining_my_investment_strategy'){
                        const investmentType = this.questionnaireStepsData.find(({key})=>key === 'advisor_finding_new_investment_opportunities_and_planning_step');
                        if (investmentType && investmentType.data?.value === 'private_investments'){
                            return BASE_PATH_LENGTH + 3;
                        }
                        return BASE_PATH_LENGTH + 2;
                    }
                }
                return BASE_PATH_LENGTH + 1;
            }
        }
        return BASE_PATH_LENGTH;
        
    }
    
}