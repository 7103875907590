import React from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
// import { Drawer } from "antd";
import { SimulationsPanelWrapper, SimulationsContentWrapper, SimulationsTitle, SimulationsSubTitle, SimulationItemsList, SimulationHeaderWrapper, SimulationsHeaderAndItemsListWrapper, SimulationHeaderTitleWrapper, SimulationHeaderActionsWrapper, CreateSimulationsButton, EmptyScenariosWrapper } from './SimulationsPanel.styles';
import { SimulationsIcon } from '../../../../assets/icons/common/common-icons';
import { CashFlowSimulationRow } from './SimulationRow/CashFlowSimulationRow';
import { NewSimulationDrawer } from '../NewSimulationDrawer/NewSimulationDrawer';
import { ActionLocked } from '../../../../common/components/ActionLocked/ActionLocked';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
// import { TransactionsCard } from '../TransactionsPanel/TransactionsCard/TransactionsCard';
// import { AddSimulationButton, SimulationsActionWrapper } from '../CashFlowPage.styles';
import {ReactComponent as EmptyScenariosSkeletonSvg} from '../assets/empty-scenarios-skeleton.svg';
import { CommonBadge } from '../../../../common/components/CommonBadge/CommonBadge';

export function SimulationsPanel(props) {
  const { uiStore, cashFlowStore , userStore  } = useStore();
  const { isWithBanner } = props;

  const addSimulation = () => {
    if (userStore.isDemoOrReadOnlyMode){
      uiStore.setShowDemoLimitationModal(true);
    } else if (userStore.userLimitations.cashFlowScenarios.scenariosLimit === null || userStore.userLimitations.cashFlowScenarios.scenariosLimit > cashFlowStore.sortedSimulations.length){
      cashFlowStore.setEditedScenario({});
      cashFlowStore.setShowScenariosPanel(true);
    } else {
      cashFlowStore.setShowScenariosLimitationPopup(true);
    }
  }
  const editSimulation = (scenario) => {
    cashFlowStore.setEditedScenario(scenario);
    cashFlowStore.setShowScenariosPanel(true);
  }

  return (<Observer>{() => (
    <>
      <SimulationsPanelWrapper isWithBanner={isWithBanner}>

        {/* Small screen ( less then 1520 ) - show simulation button with simulation drawer */}
        {/* {uiStore.screenSize === 'small' && <>
          <SimulationsDrawerOpenerPanel>
            <SimulationsDrawerIconWrapper isWithBanner={isWithBanner}>
              <SimulationsDrawerOpenerIcon onClick={() => setShowSimulationsDrawer(true)} />
            </SimulationsDrawerIconWrapper>
          </SimulationsDrawerOpenerPanel>
          <SimulationsDrawer showSimulationsDrawer={showSimulationsDrawer} setShowSimulationsDrawer={setShowSimulationsDrawer}
            editSimulation={editSimulation} addSimulation={addSimulation}
          />
        </>} */}

        {/* Big screen - show simulations panel content */}
        {/* {uiStore.screenSize === 'big' && <> */}
          <SimulationsContent editSimulation={editSimulation} addSimulation={addSimulation} />
        {/* </>} */}

      </SimulationsPanelWrapper>
      <NewSimulationDrawer
        // showAddSimulationMenu={showAddSimulationMenu}
        // setShowAddSimulationMenu={setShowAddSimulationMenu}
        isIncognito={uiStore.isIncognitoMode} editedSimulation={cashFlowStore.editedScenario} />
    </>
  )}</Observer>)
}

export function SimulationsContent({ editSimulation, addSimulation }) {
  const { uiStore, cashFlowStore, userStore , billingStore} = useStore();

  return (<Observer>{() => (
    <SimulationsContentWrapper >
      <SimulationsHeaderAndItemsListWrapper>
        <SimulationHeaderWrapper  >
          <SimulationHeaderTitleWrapper hasSimulations={cashFlowStore.data?.simulations?.length > 0}>
            <SimulationsTitle>
              { uiStore.isDesktopView && <><SimulationsIcon />Scenarios</> }
              {userStore.isFreeTier && <CommonBadge badgeType="limitedFeature" title={`Up to ${userStore.userLimitations.cashFlowScenarios.scenariosLimit} scenarios`} rounded clickable onClick={()=>{ billingStore.setShowPricingTableModal(true)}} />}
              {userStore.isPayedTier && (userStore.userLimitations.cashFlowScenarios.scenariosLimit !== null && userStore.userLimitations.cashFlowScenarios.scenariosLimit <= cashFlowStore.sortedSimulations.length) && <CommonBadge color={'#2D72FF'} badgeType="limitedFeature" title={`Up to ${userStore.userLimitations.cashFlowScenarios.scenariosLimit} scenarios`} rounded clickable onClick={()=>{ billingStore.setShowPricingTableModal(true)}} />}
            </SimulationsTitle>
            <SimulationsSubTitle>Explore how potential income or expenses will effect your projected cash flow</SimulationsSubTitle>
          </SimulationHeaderTitleWrapper>
          <SimulationHeaderActionsWrapper>
          <ActionLocked action={'addScenario'}>
            <CreateSimulationsButton onClick={addSimulation}>
              <WhitePlusSvg />
              New scenario {(userStore?.isReadOnlyMode || userStore.isActionBlockedByRole('addScenario')) && <ActionLockedIconWithTooltip iconColor={'#DDE3EB'} marginTop={'-2px'} isRoleBlocked={userStore.isActionBlockedByRole('addScenario')} />}
            </CreateSimulationsButton>
            </ActionLocked>
          </SimulationHeaderActionsWrapper>
        </SimulationHeaderWrapper>
        {cashFlowStore.sortedSimulations.length ? <SimulationItemsList>
          {cashFlowStore.sortedSimulations.map((sm, ind) => (
            <CashFlowSimulationRow {...sm} key={sm.id} isIncognito={uiStore.isIncognitoMode} onEditSimulation={() => { editSimulation(sm) }} />
          ))}
        </SimulationItemsList> : <EmptyScenariosWrapper><EmptyScenariosSkeletonSvg /></EmptyScenariosWrapper>}
      </SimulationsHeaderAndItemsListWrapper>
      {/* <SimulationsActionWrapper>
        <ActionLocked action={'addScenario'}>
          <AddSimulationButton onClick={addSimulation}>+ Add a Scenario {userStore.isReadOnlyMode && <ActionLockedIconWithTooltip marginTop={'-2px'} />}</AddSimulationButton>
        </ActionLocked>
      </SimulationsActionWrapper> */}
    </SimulationsContentWrapper>
  )}</Observer>)
}

// function SimulationsDrawer({
//   showSimulationsDrawer, setShowSimulationsDrawer, editSimulation, addSimulation }) {
//   return (
//     <Drawer
//       closable={false}
//       visible={showSimulationsDrawer}
//       width={340}
//       className={'simulationDrawerContainer'}
//       onClose={() => { setShowSimulationsDrawer(false) }}>
//       <SimulationsContent editSimulation={editSimulation} addSimulation={addSimulation} />
//     </Drawer>
//   )
// }

const WhitePlusSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 14L8 2" stroke="white" strokeWidth="1.5" strokeLinejoin="round"/>
      <path d="M14 8H2" stroke="white" strokeWidth="1.5" strokeLinejoin="round"/>
    </svg>
  )
}