import { getData, postData, putData } from '../../../common/utils/network.utils';

export const setWelcomeDone = async () => {
    return (await  postData("user/onBoardingWelcomeDone",{}));
}

export const setCreateItemDone = async () => {
    return (await  postData("user/onBoardingCreateItemDone",{}));
}

export const setOnBoardingComplete = async () => {
    return (await  postData("user/onBoardingComplete",{}));
}

export const completeQuestionnaire = async (questionnaireData) => {
    return (await  putData("profile/questionnaire",questionnaireData));
}

export const overrideQuestionnaireAnswers = async (questionnaireData) => {
    return (await  putData("profile/questionnaireOverride",questionnaireData));
}

export const demoDone = async () => {
    return (await  postData("user/demoDone",{}));
}

export const getOnBoardingData = async () => {
    return (await getData("wealth/welcome")).data;
}
