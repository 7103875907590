import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors, views } from "../../../../common/styles/theme.styles";

export const CustomSubClassListMobileWrapper = styled.div`
  flex:1;
  border: 1px solid ${colors.gray3};
  border-radius: 8px;
  max-width: 100%;
`;

export const CustomSubClassListTitle = styled.div`
  display: flex;
  padding: 8px 0 8px 12px;
  align-items: flex-end;
  gap: 4px;
  align-self: stretch;
  color: ${colors.darkGray2};
  font-family: Circular;
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const CustomSubClassRowWrapper = styled.div`
  display: flex;
  padding: 6px 8px 6px 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1;
  cursor: pointer;
  border-radius: 8px;
`;

export const CustomSubClassInputWrapper = styled.div`
  width: 100%;
  
  .ant-input{
      padding-left: 39px;
      height: 40px;
      font-size: 14px;
      font-weight: 450;
      outline: none;
      border: none;
      ${props => props.withError ? `
        border: 1px solid red;
    ` : ``}
    :hover, :active, :focus {
      border: none;
    }
  }

  .ant-input-borderless {
      background-color: #fff;
  }
`;

export const EraseSubClassSvgWrapper = styled.div`
  ${flexCenter}
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 17px;
`;

export const CustomClassRowCollapsibleDetailsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    max-width: 100%;
`;

export const CustomSubClassRowWrapperTextWrapper = styled.div`
  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  flex:1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CustomSubClassEmptyStateRowWrapper = styled.div`
  display: flex;
  padding: 8px 0 8px 12px;
  align-items: center;
  gap: 8px;
  flex: 1;
  cursor: pointer;
  border-radius: 8px;
  color: ${colors.darkGray1};
  background: ${colors.lightGray1};
  svg {
    path {
      stroke: ${colors.darkGray2};
    }
  }
  ${views.tablet} {
    padding: 12px 0px 12px 12px;
  }
`;

export const CustomSubClassEmptyStateRowIconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
`;

export const CustomSubClassEmptyStateRowTextWrapper = styled.div`
  color: ${colors.darkGray3};
  font-family: Circular;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;