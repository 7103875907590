import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../common/styles/combos.styles';
import { colors, views, shadows, sizes,  } from '../../../../common/styles/theme.styles';
import { keyframes } from '@emotion/react';
import { Input } from 'antd';
import ImageWelcome from './assets/Imagewelcome.jpg'
import { PrimaryButtonWithIcon } from '../../../../common/components/buttons/NewButtons.styles';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    margin:0 auto;
    margin-top:0px;
    
    ${views.desktop} {
        overflow-y: auto;
    }
    /* padding-bottom: 125px; */
    
`

export const StepWrapper = styled.div`
    width: ${props=>props.width ? props.width : '100%'};
    display: flex;
    flex-direction: column;
    align-items:center;
    margin:0 auto;
    padding:0 32px;
    margin-top:${props=>props.marginTop || '0px'};

    ${views.tablet} {
        position: relative;
        padding: 0 ${props => props.mobileMargin ? `24px` : ''};
        text-align: center;
        margin-top: 0px;
        width: auto;
       //  margin-top: ${props=>props.mobileMarginTop || '16px'};
    }
`

export const StepNumberText = styled.div``;
export const StepText = styled.div`
    display:flex;
    flex-direction:column;
`;
export const StepTitle = styled.div`
    font-size:16px;
    font-weight:500;
    color:#050E1F;
`;
export const StepSubTitle = styled.div`
    color:${colors.gray1};
    font-size:14px;
`;

export const WelcomeStepTitle = styled.div`
    color: ${colors.darkGray1};
    font-family: 'Domine';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    text-align:center;
    /* transform:translateY(${props=>props.isAnimateIn ? 0 : -200}px);
    opacity:${props=>props.isAnimateIn ? 1 : 0};
    transition:all 0.4s ease-in; */

    ${views.mobile} {
        font-size: 28px;
        font-weight: 400;
        line-height: 30px;
        width: 100%;
    }
`;


export const WelcomeStepSubTitle = styled.div`
    font-size:18px;
    color:${colors.darkGray1};
    /* transform:translateY(${props=>props.isAnimateIn ? 0 : -240}px);
    opacity:${props=>props.isAnimateIn ? 1 : 0};
    transition:all 0.4s 0.1s ease-in; */
    ${views.tablet} {
        text-align: center;
        font-family: Circular;
        font-style: normal;
        line-height: 25px;
    }
`;

export const QuestionnaireStepHeaderWrapper = styled.div`
    ${flexCenter};
    width: 100%;
    flex-direction: column;
`;

export const QuestionnaireStepHeaderTextContent = styled.div`
    ${flexCenter};
    width: 100%;
    flex-direction: column;
    opacity: ${props=> props.isAnimate === 'out' ? 0 : 1};
    transform-origin: center;
    padding:0 32px;
    // transform: rotateY(${props=>props.isAnimate === 'out' ? '90deg' : '0deg'});
    // transform: translateY(${props=>props.isAnimate === 'out' ? '-10px' : '0px'});
    transition: all 0.2s ease-${props=> props.isAnimate === 'out' ? 'out' : 'in'};
`

export const QuestionnaireTopHeaderWrapper = styled.div`
    ${flexCenter};
    width:100%;
    height:${sizes.headerHeight};
    min-height:${sizes.headerHeight};
    background-color: #fff;
    position:sticky;
    top:0;
    z-index: 8;
    padding: 16px;
    gap: 16px;
`;

export const QuestionnaireTitleWrapper = styled.div`
    ${flexCenter};
    gap: 4px;
    flex-direction: column;
    margin-bottom: 24px;
    ${views.tablet} {
        margin-bottom: 16px;
    }

`;

export const QuestionnaireTopSubTitle = styled.div`
    color:${colors.darkGray1};
    white-space: pre-line;
    font-size:18px;
    text-align: center;
    font-family: Domine;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const QuestionnaireSubTitle = styled.div`
    color:${colors.darkGray1};
    white-space: pre-line;
    text-align: center;
    font-family: Circular;
    font-size:14px;
    font-weight: 450;
    font-style: normal;
    line-height: normal;
    padding-top: 36px;
`;


export const QuestionnaireStepTitle = styled.div`
    color: ${colors.darkGray1};
    font-family: 'Domine';
    font-style: normal;
    font-size: 26px;
    font-weight: 400;
    line-height: 36px;
    white-space: pre-line;
    text-align:center;
    /* transform:translateY(${props=>props.isAnimateIn ? 0 : -50}px);
    opacity:${props=>props.isAnimateIn ? 1 : 0};
    transition:all 0.2s ease-in; */
    
    ${props=>props.isSticky ? `font-size:14px; font-weight:500;` : ``}
`;

export const QuestionnaireStepSubTitle = styled.div`
    /* margin-top:8px; */
    font-size:14px;
    color:${colors.darkGray1};
    /* transform:translateY(${props=>props.isAnimateIn ? 0 : -10}px);
    opacity:${props=>props.isAnimateIn ? 1 : 0};
    transition:all 0.2s 0.2s ease-in; */
    white-space: pre-line;
    text-align:center;
    ${views.mobile} {
        ${props => props.isCenterAlignText ? `` : `text-align: left;`}
    }
`;

export const QuestionnaireContentWrapper = styled.div`
    display: flex;
    flex: 1;
    // margin-top:46px;
    margin-bottom: 52px;
    opacity: ${props=> props.isAnimate === 'out' ? 0 : 1};
    transition: opacity 0.2s ease-${props=> props.isAnimate === 'out' ? 'out' : 'in'};
    ${views.desktop}{
        min-height: ${props=>props.height ? props.height : '352px'};
    }
    ${views.tablet} {
        margin-top: 0;
        margin-bottom: 16px;
        width: 100%;
        ${props => props.withoutFullWidth ? `
            justify-content: center;
            ` : ``}
    }
`;

export const InputWithLabelOnTopWrapper = styled.div`
    position: relative;
    width: 100%;

    .ant-dropdown-trigger {

      .simple_select_button_text {
        padding-top: 18px;
        font-size: 18px;
        color:${colors.darkGray1};
        font-weight:450;
      }
      .select-arrow{
        width:18px;
        height:18px;
        margin-right: 4px; 
      }
      ${props => props.withError ? `
            border: 1px solid red;
        ` : ``}
    }

    .ant-input{
        padding-top: 26px;
        font-size: 18px;
        :hover{
            border-color: #1A1B1D;
        }
        ${props => props.withError ? `
            border: 1px solid red;
        ` : ``}

    }

    .ant-picker .ant-picker-input input{
      padding-top: 18px;
      font-size: 18px;
      color:${colors.darkGray1};
      font-weight:450;
    }
    .ant-picker .ant-picker-input .ant-picker-suffix{
      font-size:18px;
    }

    ${views.tablet} {
        max-width: 420px;
        width: 100%;
    }
`;

export const FloatingLabeInInput = styled.div`
    position:absolute;
    top:6px;
    left:12px;
    font-size: 13px;
    color:${colors.darkGray3};
    font-weight: 450;
    pointer-events: none;
    z-index: 2;
`;

export const QuestionnaireNameInput = styled(Input)`
    height: 72px;
    padding: 8px 16px;
    font-weight: 450;
    font-size: 16px;
    min-width: 320px;
    text-align: center;
    background: #FFFFFF;
    line-height: 18px;
    border: 1px solid #1A1B1D;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    &:hover{
        border-color:#7d7d7d!important;
        box-shadow: none;
    }
    &:focus{
        border-color:#7d7d7d!important;
        box-shadow: none;
    }
    &.ant-input-affix-wrapper-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }
`;


export const AnswersWrapper = styled.div`
    ${flexCenter};
    flex-wrap:wrap;
    align-content: center;
    height: fit-content;
    max-width: ${props=>props.width ? props.width : '700px'};
    padding: 0 10px;
    ${views.tablet} {
        padding: 0 20px;
        width: 100%;
    }
`

export const NetWorthBar = styled.div`
    width: 48px;
    height: ${props => props.height ? props.height : 24}px;
    border-radius: 4px;
    background: ${colors.gray3};
    ${views.tablet} {
        height: 48px;
        width: ${props => props.height ? props.height : 24}px;
    }
`;

export const AnswerItemWrapper = styled.div`
    // width: 320px;
    min-width: 320px;
    max-width: 320px;
    flex: 1;
    margin:8px;
    ${props=>props.isOtherInput ? `gap:8px;`: ``}
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 1px 8px rgba(0, 0, 0, 0.08);
    background: ${props=>props.isSelected ? 'blueAzul' : '#FFFFFF'};
    border: 1px solid ${props=>props.isSelected ? colors.blueAzul : '#ffffff' };
    border-radius: 8px;
    padding:${props=>props.isOtherWithTextArea ? `0`: `24`}px;
    ${flexCenter};
    color: ${props=>props.isSelected ? colors.blueAzul : '#000000'};
    font-size:16px;
    font-weight:450;
    line-height:20px;
    cursor: pointer;
    max-height: 72px;
    text-align: center;
    white-space: pre-line;
    /* transform:scale(${props=>props.isAnimateIn ? 1 : 0});
    opacity:${props=>props.isAnimateIn ? 1 : 0};
    transition:transform 0.3s ${props=>props.index*0.10 + 0.4}s ease-in, opacity 0.3s ${props=>props.index*0.15 + 0.4}s ease-in, border 0.2s ease-in, color 0.2s ease-in; */
    :hover {
        ${views.desktop} {
            border: 1px solid ${colors.darkGray1};
            background: #a5c2fd12;
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
            ${NetWorthBar} {
                ${({ index }) => {
                    const colors = ['#EDEFE2', '#C5DAD0', '#A3BEB1', '#708C95'];
                    return `background: ${colors[index] || ''};`;
                }}
            }
        }
    }
    svg {
        path {
            ${props=>props.isSelected ? `fill: ${colors.blueAzul};` : ``}
        }
    }
    ${views.tablet} {
        color: ${props=>props.isSelected ? '#fff' : '#000'};
        background: ${props=>props.isSelected ? colors.blueAzul : '#fff' };
        svg {
            flex-shrink: 0;
            path {
                ${props=>props.isSelected ? `fill: #fff;` : ``}
            }
        }
        min-width: 100%;
    }
`

export const OtherAnswerTextAreaWrapper = styled.div`
    width: 100%;
    
    .ant-input {
        color: #2565E7;
    }

    .ant-input:focus, .ant-input-focused {
        color: #000;
    }
    
`;

export const AnythingElseTextAreaWrapper = styled.div`
    width: 80vw;
    max-width: 700px;
    .ant-input {
        border: 1px solid #9097A3;
        border-radius: 6px;
    }

    .ant-input:focus, .ant-input-focused {
        border-color: ${colors.darkGray1};
        box-shadow: none;
    }
    ${views.tablet}{
        // width: 80vw;
        width: 100%;
        min-width: 320px;
        max-width: 420px;
        margin: 0 20px;
    }
`;

export const MaybeLaterWrapper = styled.div`
    color: ${colors.darkGray1};
    text-align: center;
    font-family: Circular;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    margin-top: 24px;
    :hover {
        font-weight: 600;
    }
`;

export const QuestionnaireFooter = styled.div`
    ${flexCenter};
    flex-direction: column;
    padding: 0 0 16px 0 ;
    /* max-width: 320px; */
    /* position: sticky; */
    /* width: 100%; */
    /* bottom: 0; */

    ${props => props.isSticky ? `
        background-color: #fff;
        border-top: 1px solid ${colors.gray3};
        z-index: 5;
    ` : null}

    ${views.tablet}{
        // position: fixed;
        // bottom:0;
        width: 100%;
        background-color: #fff;
        padding: 16px 20px 0 20px;
    }
`;

export const QuestionnaireContinueButton = styled(PrimaryButtonWithIcon)`
    fontSize: 16px;
    padding: 28px 0; 
    margin-bottom: env(safe-area-inset-bottom);
    font-weight: 450;
    width: 320px;
    border-radius: 50px;
    ${views.tablet} {
        // width: 80%;
        width: 100%;
        min-width: 320px;
        max-width: 420px;
    }
`;

export const QuestionnaireBackButtonWrapper = styled.div`
    ${flexCenter};
    gap: 4px;
    cursor: pointer;
    svg {
        width: 16px;
        height: 16px;
    }
`;

export const QuestionnaireMobileHeaderButtonWrapper = styled.div`
    ${flexCenter};
    width: 28px;
    height: 28px;
    background-color: #E8E9ED;
    border-radius: 50px;
    cursor: pointer;
`;

export const QuestionnaireDoneButton = styled.div`
     ${flexCenter};
     width: ${props=>props.computedWidth ? props.computedWidth : 280}px;
    cursor: pointer;
    background-color:#000;
    height:56px;
    border-radius:6px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    padding:16px 24px;
    
    font-weight: 500;
    font-size: 16px;
    color:#fff;
    margin: 0 8px 18px 8px;
    /* transform:scale(${props=>props.isAnimateIn ? 1 : 0}); */
    opacity:${props=>props.isDisabled ? 0.4 : 1};
    /* opacity:${props=>props.isAnimateIn ? (props.isDisabled ? 0.4 : 1 ) : 0}; */
    transition:transform 0.3s ${props=>props.enterDelay}s ease-in, opacity 0.3s ease-in;
`;

export const PopoverWrapper = styled.div`
    display: flex;
    ${views.mobile} {
        width: ${props=>props.computedWidth ? props.computedWidth : 280}px;
    }
`;

export const WhyWeAskWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center; 
    gap: 6px;
    padding: 6px 12px;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid #DDE3EB;
    border-radius: 8px;
    color: ${colors.darkGray1};
    cursor: pointer;
`;

export const WhyWeAskWrapperHover = styled.div`
    background-color: ${colors.darkGray1};
    box-shadow: ${shadows.menuPopup};
    border-radius: 8px;
    padding: 8px 12px;
    color: #fff;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
`;

export const AccreditedInvestorWrapperHover = styled(WhyWeAskWrapperHover)`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const AccreditedInvestorBulletsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
`;

export const AccreditedInvestorBullet = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`

// export const ProgressDotsWrapper = styled.div`
//     display: flex;
//     gap: 6px;
//     margin-top: 6px;

//     ${views.mobile} {
//         margin-top: 12px;
//     }
// `;

export const PrgoressDot = styled.div`
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: ${props=>props.isCurrentStep ? '#000' : '#C4C4C4'};
`;

export const AccreditedInvestorHintWrapper = styled.div`
    display: flex;
    ${views.mobile} {
        width: ${props=>props.computedWidth ? props.computedWidth : 280}px;
        margin-bottom: 6px;    
    }
`;

export const AccreditedInvestorHint = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    gap:6px;
    font-size: 14px;
    color: ${colors.darkGray2};
    cursor: pointer;
    padding: 12px 10px 12px 0;
`;

export const LoadingBarWrapper = styled.div`
    background-color: ${colors.gray3};
    height: 4px;
    position: relative;
    width: 472px;
    margin-top:24px;
    margin-bottom:16px;

    ${views.mobile} {
        max-width: 85vw;
    }
`;

const progressBar = keyframes`
    0% { left:0; width: 0; }
    50% { left:0; width: 100% }
    100% { left:100%; width: 0 }
`;

export const LoadingAnimatedBar = styled.div`
    background-color: ${colors.darkGray1};
    height: 4px;
    width: 100%;
    position: absolute;
    /* transition : width 5s ease; */
    animation: ${progressBar} 5s ease-in-out infinite;
`;

export const LoadingMessage = styled.div`
    font-size:14px;
    color:${colors.darkGray3};
`;

export const BottomHelperButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 0 8px 8px;
`

export const WelcomeStepTitlesWrapper = styled.div`
    ${flexCenter};
    gap: 8px;
    margin-bottom: 24px;
    flex-direction: column;
    // ${views.tablet} {
    //     margin-bottom: 24px;
    // }
`;

export const QuestionnaireMessageStepTitle = styled.div`
    color: ${colors.darkGray1};
    font-family: 'Domine';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align:center;
    /* transform:translateY(${props=>props.isAnimateIn ? 0 : -50}px);
    opacity:${props=>props.isAnimateIn ? 1 : 0};
    transition:all 0.2s ease-in; */
    ${views.mobile} {
        width: ${props=>props.computedWidth ? props.computedWidth : 280}px;
        ${props => props.alignCenter ? '' : `text-align:start;`}
        font-size: 28px;
        font-weight: 400;
        line-height: 30px;
        padding-bottom: 8px;
    }
    ${props=>props.isSticky ? `font-size:14px; font-weight:500;` : ``}

`;

export const WelcomeLaptopImage = styled.div`
  display: flex;
  width:100%;
  min-height: 244px;
  background: url(${ImageWelcome}) no-repeat;
  background-size: 100% 244px;
  padding-top: 8px;
  margin-bottom: 88px;
`;

export const FinishQuestionnaireMessageWrapper = styled.div`
        ${flexCenter}
        height: 100vh;
        flex-direction: column;
        font-size: 30px;
        text-align: center;
`;

export const MobileProfileMenuItemWrapper = styled.div`
    ${flexCenter};
    justify-content: flex-start;
    gap:8px;
    padding: 8px 16px;
`;

export const MobileProfileMenuLinkWrapper = styled.a`
    ${flexCenter};
    justify-content: flex-start;
    gap:8px;
    padding: 8px 0 8px 16px;
`;