import { Checkbox, Popover } from 'antd';
import { Observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ArrowDownIcon, CheckmarkIcon, LinkArrowIcon } from '../../../../assets/icons/common/common-icons';
import { useStore } from '../../../app/data/root.store';
import { updateActivationStep } from '../../../user/data/user.service';
import { CloseButtonWrapper, CompletedText, Header, HoverArrowIcon, PaneClosedButton, PaneClosedButtonTitle, PaneWrapper, ProgressBarFill, ProgressBarWrapper, StepActionsWrapper, StepContent, StepHeader, StepMark, StepMenuButton, StepMenuCheckboxWrapper, StepMenuWrapper, StepSubTitle, StepTitle, StepWrapper, StepsWrapper, Title, TitleAndCloseButton } from './DemoOverviewPane.styles';
import { GetFamiliarCashFlowStep, LearnAboutStep, NeedMoreHelpStep, UploadYourFinancialStatementsStep } from './DemoOverviewSteps';

const activationSteps = [
    { id: 1 , title : 'Learn about Vyzer' , innerComp : LearnAboutStep , isDone : true },
    { id: 2 , title : 'Get familiar with Cash Flow Planning' , innerComp : GetFamiliarCashFlowStep  , doneKey : 'cashFlowPlaning' },
    { id: 3 , title : 'Convert document via Magic Box' , innerComp : UploadYourFinancialStatementsStep , doneKey : 'magicBox' },
    { id: 4 , title : 'Start my portfolio' , innerComp : null , showPricingTable : true , hideMarkAsComplete : true  },
    { id: 5 , title : 'Need more help?' , subTitle: '(optional)' , innerComp : NeedMoreHelpStep , doneKey : 'moreHelp' , hideMarkAsComplete : true },
];

export function DemoOverviewPane(props) {
    const [activeStepId,setActiveStepId] = useState(0)  
    const { uiStore, userStore , billingStore } = useStore();
    
    useEffect(() => {
        const doneList = userStore.getDemoDoneSteps;
        const currentStep =  !doneList.includes('cashFlowPlaning') ? 2 : !doneList.includes('magicBox') ? 3 : 5;
        setActiveStepId(currentStep);
        return () => { }
      }, [userStore,userStore.getDemoDoneSteps]);

      const handleMarkAsComplete = (itemDoneKey) => {
        updateActivationStep({['demo_flows.getStartedPane.' + itemDoneKey] : true});
        userStore.setDemoStepAsComplete(itemDoneKey);
    }

    return (<Observer>{() => (
        <PaneWrapper>
            <Header>
                <TitleAndCloseButton>
                    <Title>Quick overview</Title>
                    <CloseButtonWrapper onClick={()=>{uiStore.setDemoRightMenuOpen(false)}}><CloseButtonSvg /></CloseButtonWrapper>
                </TitleAndCloseButton>
                <ProgressBarWrapper ><ProgressBarFill complete={userStore.completeDemoPercentages}/></ProgressBarWrapper>
                <CompletedText>{userStore.completeDemoPercentages}% completed 🎉</CompletedText>
                
            </Header>
            <StepsWrapper>
                {activationSteps.map((item,index)=>(
                    <ActivationChecklistStep key={item.id} item={item} index={index}
                        setActive={()=>{item.showPricingTable ? billingStore.setShowPricingTableModal(true) : setActiveStepId(item.id)}}
                        isActive={activeStepId=== item.id}
                        isDone={item.isDone || userStore.getDemoDoneSteps.includes(item.doneKey)}
                        markAsComplete={()=>{handleMarkAsComplete(item.doneKey)}} />
                ))}
            </StepsWrapper>
            <PaneClosedButton show={!uiStore.isRightMenuOpen} onClick={()=>{uiStore.setDemoRightMenuOpen(true)}}>
                <TitleAndCloseButton>
                    <PaneClosedButtonTitle>Explore</PaneClosedButtonTitle>
                    <PaneClosedButtonTitle>{userStore.completeDemoPercentages}%</PaneClosedButtonTitle>
                </TitleAndCloseButton>
                <ProgressBarWrapper ><ProgressBarFill complete={userStore.completeDemoPercentages}/></ProgressBarWrapper>
            </PaneClosedButton>
        </PaneWrapper>
    )}</Observer>)
}

export function ActivationChecklistStep(props) {
    const { item , isDone, isActive, index, setActive , markAsComplete } = props;
    const stepMenu = () => {
        return (
          <StepMenuWrapper>
                
                <StepMenuCheckboxWrapper >
                    <Checkbox onChange={()=>{markAsComplete()}}>Mark as complete</Checkbox>
                </StepMenuCheckboxWrapper>
          </StepMenuWrapper>
        )
    }

    return (
        <StepWrapper isActive={isActive} onClick={setActive}>
            <StepHeader>
                <StepMark isActive={isActive} isDone={isDone}>{ isDone ? <CheckmarkIcon/> : index+1}</StepMark>
                <StepTitle withArrow={item.showPricingTable}>{item.title}{item.showPricingTable && <LinkArrowIcon />}{item.subTitle && <><br/><StepSubTitle>{item.subTitle}</StepSubTitle></>}</StepTitle>
                {!item.isAction &&
                <StepActionsWrapper>
                    {!isActive && <HoverArrowIcon >
                        <ArrowDownIcon />
                    </HoverArrowIcon>}
                    {isActive && !isDone && !item.hideMarkAsComplete && <Popover overlayClassName='checklist-popover' trigger={['click']} placement="topRight" arrowContent={false} content={stepMenu()}><StepMenuButton><ThreeDotsIcon/></StepMenuButton></Popover> }
                </StepActionsWrapper>
                }
            </StepHeader>
            {isActive && item.innerComp !== null && <StepContent>
                 
                    {item.innerComp()}
                
            </StepContent>}
        </StepWrapper>
    )
}

const CloseButtonSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.58496 8.50928L12.1708 12.0951L8.58496 15.681" stroke="#ACB1BB" strokeWidth="1.5"/>
            <path d="M13.835 8.50928L17.4208 12.0951L13.835 15.681" stroke="#ACB1BB" strokeWidth="1.5"/>
        </svg>
    )
}

const ThreeDotsIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="1" cy="1" r="1" transform="matrix(1 1.74846e-07 1.74846e-07 -1 1 7)" fill="black"/>
            <circle cx="1" cy="1" r="1" transform="matrix(1 1.74846e-07 1.74846e-07 -1 5 7)" fill="black"/>
            <circle cx="1" cy="1" r="1" transform="matrix(1 1.74846e-07 1.74846e-07 -1 9 7)" fill="black"/>
        </svg>
    )
}