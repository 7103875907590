import styled from '@emotion/styled/macro'
import {
    Link
  } from "react-router-dom";

  import { Input } from 'antd';
import { colors, views, shadows } from '../../../common/styles/theme.styles';
import { flexCenter } from '../../../common/styles/combos.styles';
 
export const LoginWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 3;
    overflow-y: auto;  
    height:100%;
    ${views.tablet} {
        min-height: 100vh;
    }
`

export const LoginCenterContent = styled.div`
    width: 100%;
    /* max-width: 532px; */
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin: 0 auto;
    flex: 1;
    display: flex;
    flex-direction: column;  
    padding-bottom:36px;
    background-color: #fff;

    .ant-btn-link {
        color: ${colors.darkGray2}
    }

    ${views.tablet} {
        padding: 72px 24px 16px;
        padding-top: 72px;
        justify-content: center;
    
    
`;

export const LogoHeader = styled.div`
    display: flex;
    align-items: center;
    padding: ${props=>props.isRegister ? '24px 24px 8px' : '72px 24px 58px'};
    width: 100%;
    justify-content: center;
`

export const Title = styled.div`
   color:#26282c;
   font-size:24px;
   line-height:28px;
   font-weight:500;
   margin-top:54px;
   padding-bottom:8px;
`  

export const SubTitle = styled.div`
   color:#26282C;
   font-size:16px;
   /* text-align:center; */
`  

export const FormBox = styled.div`
    background: #fff;
    /* border: 1px solid #DDE3EB; */
    /* box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08); */
    /* border-radius: 4px; */
    // background: #FFFFFF;
    // box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
    width:100%;
    max-width: 532px;
    padding:40px 24px 48px;
    margin-top:24px;
    margin-bottom:40px;

    ${views.mobile} {
        padding: 0;
    }
`

export const LoginForm = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
`

export const LinkButtonWrapper = styled.div`
    ${flexCenter};
    ${views.mobile} {
        justify-content: flex-start;
        .ant-btn-link {
            width: fit-content;
            padding: 0;
        }
        .ant-btn-link > span {
            color: #2565E7;
            text-decoration: underline;
        }
    }
`;

export const VerificationForm = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
`

export const InputWrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    margin-bottom:24px;
    ${props=>props.isError ? `
        .password-input-wrapper{
            border-color:red;
        }
    ` : ``}
`

export const InputLabel = styled.div`
    color:#3D3D3D;
    font-size:14px;
    text-align:left;
    font-weight:500;
    padding-bottom:8px;
    padding-left: 1px;
`

export const InputDescription = styled.div`
    color:#6b6a6a;
    font-size:12px;
    text-align:left;
    font-weight:500;
    padding-bottom:4px;
    padding-left: 1px;
`

export const PasswordRuleWrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
`;
export const PasswordRule = styled.div`
    color:${props=>props.isValid ? 'black' : '#b9b9b9'};
    flex:0.5;
    min-width: 50%;
    padding: 4px 16px;
    position: relative;
    font-size:14px;
    line-height:18px;
    .anticon{
        position:absolute;
        left:0px;
        top:6px;
    }
    :nth-child(2n){
        padding-right:0px
    }
`;

export const VerCodeInput = styled.input`
    width:100%;
    height:56px;
    
    font-size:20px;
    padding:12px;
    background-color:#FFFFFF;
    border: 1px solid ${colors.gray3};
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    text-align:center;
    :hover{
        border-color:${colors.gray3};
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    }
    :focus{
        border-color:${colors.darkGray1};
    }
    :focus-visible{
        outline:none;
        border-color:${colors.darkGray1};
    }
    :active{
        border-color:${colors.darkGray1};
    }
    :focus::-webkit-input-placeholder {
        opacity: 0;
    }
`

export const WrongCodeMsg = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: #FF0000;
    padding-bottom: 16px;
`

export const LoginInput = styled(Input)`
    width:100%;
    height:44px;
    // border: 1px solid #DFDFDF;
    font-size:14px;
    padding:12px;
    background-color:#FFFFFF;
    
    border: 1px solid ${colors.gray3};
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    :hover{
        border-color:${colors.gray3};
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    }
    :focus{
        border-color:${colors.darkGray1};
    }
    :active{
        border-color:${colors.darkGray1};
    }
`

export const LoginPasswordInput = styled(Input.Password)`
    height:33px;
    border: 1px solid #DFDFDF;
    font-size:14px;
    padding:0 12px;
    background-color:#FFFFFF;
    box-shadow: ${shadows.inputShadow};
    border-radius: 4px;
    :focus{
        border-color:${colors.darkGray1};
    }
    :active{
        border-color:${colors.darkGray1};
    }
`;

export const FormMessage = styled.div`
    color:#FF0000;
    font-size:14px;
    padding-bottom: 16px;
    white-space: pre-line;
    display: ${props=>props.haveMessage ? 'block' : 'none'};
`;

export const LoginFormButton = styled.div`
    background-color:black;
    color:#fff;
    border:1px solid #000;
    padding:16px;
    font-size:16px;
    height:auto;
    text-align: center;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition:all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 8px;
    :hover{
        background-color:#353A44;
    }
    :active{
        background-color:#353A44;
        opacity: 0.8;
    }
    ${props=>props.disabled ? `opacity:0.6;cursor:default` : ``}
`

export const TextLink = styled(Link)`
    color:#35393F;
`

export const RecaptchaWrapper = styled.div`
    position: relative;
    height:0px;
    overflow:hidden;
`

export const RecaptchaAbsoluteWrapper = styled.div`
    // transform:scale(0.8);
    // position: absolute;
    // // right: -62px;
    // left: 24px;
    // transform-origin:0 0;
`

export const TermOfUseMessage = styled.div`
    font-weight: 450;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: ${colors.darkGray3};
    a{
        color: ${colors.darkGray3};
        text-decoration:underline;
    }
`;

export const MobileExtraStepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 60px;
`;

export const AlreadySignedUpText = styled.div`
    padding-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.darkGray2};
`;

export const LoginFormSignUpButton = styled.div`
    ${flexCenter};
    padding: 18px 0;
    border-radius: 8px;
    box-shadow: ${shadows.selectMenuShadow};
    border: 1px solid ${colors.gray3};
    /* background: #fff; */
`;

export const PopoverWrapper = styled.div`
    display: flex;
    margin-top: 40px;
`
export const AntPopoverButton = styled.div`
    display: flex;
    align-Items: center; 
    gap: 6px; 
    height: 44px; 
    /* padding-left: 10px; */
    padding-right: 10px;
    cursor: pointer;
`

export const LinkSpan = styled.span`
    cursor: pointer;
    text-decoration: underline;
    color: #2565E7;
`;

export const SignUpStepWrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 328px;
    width:100%;
    padding-bottom:5vh;
    ${views.tablet} {
        flex:1;
    }
`;

export const ForgotPasswordStepWrapper = styled(SignUpStepWrapper)`
    max-width: ${props=>props.isResetSuccessful ? '328' : '424'}px;
    width:100%;
    padding-bottom:10vh;
`;

export const SignUpStepHeader = styled.div`
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
`;

export const SetPasswordStepHeader = styled(SignUpStepHeader)`
    margin-bottom: ${props=>props.isResetSuccessful ? `6` : `48`}px;
    white-space: pre-line;
    ${props=>props.isResetSuccessful ? `text-align: center` : ``};
`;

export const SignUpStepSubHeader = styled.div`
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding-top: 2px;
    padding-bottom: ${props=>props.isResetSuccessful ? `40` : `24`}px;
    max-width: 328px;
`;

export const GoogleSignInButton = styled.div`
    ${flexCenter};
    width: 100%;
    gap: 8px;
    padding: 12px 0;
    border: 1px solid ${props=> props.isACtive ? colors.darkGray1 : colors.gray3};
    background-color:${props=> props.black ? colors.darkGray1 : (props.isACtive ? colors.lightGray1 : '#fff')};
    color:${props=> props.black ? '#fff' : colors.darkGray1};
    font-size:${props=> props.black ? '16px' : '14px'};
    font-weight:${props=> props.black ? '500' : 'normal'};
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    margin-top: 32px;
    height: 52px;
    cursor: pointer;
    :hover{
        ${props=> props.black ? `
            background-color:#353A44;
        `:`
            border: 1px solid ${colors.darkGray1};
        `}
    }
`;

export const HorizontalSeparatorWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 24px 0;
`;

export const HorizontalLine = styled.div`
    height: 1px;
    background-color: ${colors.gray3};
    width: 100%;
`;

export const ContinueButton = styled.div`
    ${flexCenter};
    width: 100%;
    background-color: ${props=>props.white ? '#fff' : colors.darkGray1};
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${props=>props.white ? colors.darkGray1 : '#fff'};
    border: 1px solid ${props=>props.white ? colors.gray3 : colors.darkGray1};
    padding: 16px 0;
    border-radius: 8px;
    height: 52px;
    opacity: ${props=>props.disabled ? 0.3 : 1};
    ${props=>props.disabled ? `` : `cursor: pointer;`}
    :hover{
        ${props=> props.white ? `
            border: 1px solid ${colors.darkGray1};
        `:`
            background-color:#353A44;
        `}
    }
    :active{
        ${props=> props.white ? `
            border: 1px solid ${colors.darkGray1};
        `:`
            background-color:#353A44;
        `}
        opacity: 0.8;
    }
`;

export const ActionButton = styled.div`
    color: ${colors.darkGray1};
    padding: 10px 24px;
    cursor: pointer;
    border: 1px solid ${colors.gray3};
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    transition: all 0.2s;
    :hover {
        color: #fff;
        background-color: ${colors.darkGray1};
    }
    pointer-events:auto;
`;

export const OnBoardingHeaderLink = styled.div`
    font-weight: 450;
    font-size: 13px;
    line-height: 16px;
    color: ${colors.darkGray1};
    margin-left: auto;
    margin-right: 16px;
    pointer-events:auto;
`;

export const BackButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 8px;
    padding: 8px 0;
    align-self: flex-start;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.darkGray1};
`;

export const ExistingEmailWrapper = styled.div`
    padding-top: 10px;
`;

export const ExistingEmailSpan = styled.span`
    color: #2565E7;
    text-decoration: underline;
    cursor: pointer;
`;

export const RestSuccessfulSvgWrapper = styled.div`
    ${flexCenter};
    padding-bottom: 16px;
`;

export const AppLoaderContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`;