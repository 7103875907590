import styled from '@emotion/styled/macro'
import { flexCenter } from '../../styles/combos.styles';
import { colors, views } from '../../styles/theme.styles';

export const ModalCloseButtonWrapper = styled.div`
    position:absolute;
    right:16px;
    top:16px;
    width:24px;
    height:24px;
    cursor: pointer;
`;

export const LimitationModalWrapper = styled.div`
  display: flex;
  flex:1;
  ${views.tablet}{
    flex-direction: column;
    height: 100%;
  }
`;

export const ModalContent = styled.div`
  display:flex;
  flex: 1;
  flex-direction:column;
  align-items:center;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  ${flexCenter};
`;

export const TitleWrapper = styled(IconWrapper)`
  font-family: Domine;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const DescriptionWrapper = styled.div`
  text-align: center;
  padding: 0 ${props => props.sidePadding || '75'}px;
  font-weight: 450;
  font-size: 14px;
  line-height: 138%;
  color: ${colors.darkGray1};
  white-space: pre-line;
`;

export const ModalLeftPaneWrapper = styled.div`
  flex: 2;
  background: url('${props => props.bgImage}') no-repeat;
  background-size: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: #fff;
  font-weight: 450;
  font-size: 16px;
  line-height: 138%;
  ${views.tablet}{
    border-radius: 0px;
    flex: 1.5;
    // background-size: cover;
  }
`;

export const MaybeLaterButton = styled.div`
  font-weight: 450;
  font-size: 13px;
  line-height: 138%;
  color: ${colors.darkGray2};
  cursor: pointer;
`;

export const LinkArrowIconWrapper = styled.div`
  ${flexCenter};
  width: 20px;
  height: 20px;
  svg {
    width: 20px;
    height: 20px;
    path{
      stroke:#fff;
    }
  }
`;