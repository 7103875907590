import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors, shadows, views } from "../../../../common/styles/theme.styles";

export const HeroWrapper = styled.div`
  
  background-color: ${colors.darkHeroBackground};
  /* Card Shadow */
  box-shadow: ${shadows.cardShadow};
  border: 1px solid #DDE3EB;
  border-radius: 8px;
  // padding:62px;
  display:flex;
  flex-direction:column;
  /* margin-bottom:16px; */
  margin-top:${props => props.topOffset ? props.topOffset : 0}px;
  ${props => props.borderlessBottom ? `border-bottom:none; border-bottom-left-radius:0px; border-bottom-right-radius:0px;` : ``}
  ${views.tablet}{
    border:none;
    border-radius: 0px;
  }
  ${views.desktop} {
    height:400px;
  }
`;

export const HeroTitle = styled.div`
  font-weight:500;
  font-size:16px;
  color:#fff;
  flex:1;
  ${flexCenter};
  gap: 8px;
  justify-content:flex-start;
`;

export const HeroTabTitleWrapper = styled.div`
    /* padding:0 12px 0 12px; */
    display:flex;
    align-items:center;
`;

export const HeroTabTitle = styled.div`

`;

export const HeroTabsWrapper = styled.div`
    margin-bottom: -1px;
        .ant-tabs-tab{
        margin:0px;
        padding: 24px 0
    }
    .ant-tabs-tab-btn{
        color:${colors.gray1};
        font-weight: normal;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        //color:#000;
        color:#FFF;
        font-weight:normal;
    }

    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {

        /* background-color:${colors.gray1}; */
        background-color:#fff;
        height: 2px;
        // background-color:#000;
    }

    .ant-tabs-top > .ant-tabs-nav{
        margin-bottom: 0px;
        /* padding: 0px 16px 0; */
        ::before{
            border-bottom:none;
        }
    }
`;

export const HeroMetricsPane = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 5;
`;

export const HeroHeader = styled.div`
    /* min-height:40px; */
    display:flex;
    /* align-items: flex-end; */
    justify-content:flex-start;
    /* border-bottom:1px solid ${colors.darkGray2}; */
    padding: 20px 24px 0;
    gap: 12px;
    ${views.tablet}{
      height:64px;
      background-color: ${colors.darkHeroBackground};
      position: ${props=> props.isFixed ? `fixed` : `relative`};
      top:0px;
      width:100%;
      z-index: 1;
      padding:0 24px 0 0;
      gap:0px;
    }
`;

export const HeroGraphPane = styled.div`
    flex: 3.8;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const HeroContent = styled.div`
    display:flex;
    flex:1;
    ${views.tablet}{
      flex-direction:column;
      align-items:stretch;
    }
`;

export const ChartWrapper = styled.div`
     // padding:16px 0px;
     flex:1;
     /* padding:16px 0px; */
     max-width:276px;
     width:100%;
     ${views.tablet}{
      width:80%;
      padding:12px 0px;
    }
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
`;

export const CommunityMetricsPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${views.tablet}{
    align-items:stretch;
  }
`;

export const CommunityOverviewHeroDataWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${props=>props.isClassView ? 24 : 32}px;
  ${views.tablet}{
    justify-content: space-between;
    gap:0;
    align-items: center;
    padding:12px 24px;
  }
`;

export const CommunityOverviewHeroDataHeader = styled.div`
  display: flex;
  color: ${colors.darkGray3};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  ${views.tablet}{
    justify-content: center;
  }
`;

export const SingleClassTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const SingleClassTitle = styled.div`
  color: ${props=>props.color ? props.color : '#fff'};
  font-size: 14px;
  line-height: 18px;
`;

export const CommunityPillarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  ${views.tablet}{
    width: ${props=>props.isClassView ? '15' : '20'}%;
    height: ${props=> Math.max((props.maxPillarPercentages / 100 * 200) + 35 , 150)}px
  }
`;

export const CommunityPillarPercentage = styled.div`
  color: ${colors.darkGray3};
  font-size: 12px;
  line-height: 15px;
  position: relative;
`;

export const CommunityPillarRectangleHoverEffect = styled.div`
  pointer-events:none;
  flex-direction: column;
  align-items: center;
  width: 280px;
  position: absolute;
  top: -20px;
  transform: translateX(-50%) translateY(-100%);
  left: 50%;
  z-index: 1;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 0px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #4C4E52;
  transition: all 0.2s;
  opacity:0;
  background-color: #202429;
  color: ${colors.lightGray1};
  font-size: 13px;
  line-height: 16px;
  padding: 12px;
  border-radius: 8px;
  :after {
    content: '';
    position: absolute;
    /* background-color: #202429; */
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    bottom: -4px;
    /* width: 9px;
    height: 9px; */
    /* border-radius: 100%; */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #202429;
    z-index: -1;
  }
  :before{
    content: '';
    position: absolute;
    /* background-color: #202429; */
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    bottom: -4px;
    /* width: 9px;
    height: 9px; */
    /* border-radius: 100%; */
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 8px solid #757575;
    z-index: -1;
  }


`;

export const CommunityPillarRectangleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  :hover {
    ${CommunityPillarRectangleHoverEffect} {
      display: flex;
      z-index: 1;
      opacity:1;
      pointer-events:auto;
    }
  }
  ${views.tablet}{
    width:100%;
  }
`;

export const CommunityPillarRectangle = styled.div`
  width: ${props=>props.classColor ? 60 : 72}px;
  ${props => props.height ? `height: calc(100% * ${props.height / 100})` : ``};
  background-color: ${props=>props.classColor ? props.classColor + '40' : '#202429'};
  border-top: 1px solid ${props=>props.classColor ? props.classColor : colors.darkGray3};
  transition: all 0.2s;
  opacity: ${props=>props.isDimmed ? 0.5 : 1};
  ${views.tablet}{
    width:100%;
    ${props => props.height && props.maxPillarPercentages ? `height: calc(${props.height / props.maxPillarPercentages * 100}% - 15px)` : ``};
  }
`;

export const CommunityPillarTotal = styled.div`
  color: #fff;
  ${props=>props.isClassView ? `
    font-size: 12px;
    line-height: 15px;
    ` : `
    font-size: 14px;
    line-height: 18px;
  `};
  ${views.tablet}{
    white-space: nowrap;
  }
`;

export const VerticalSeparator = styled.div`
    width: 1px;
    background-color: #25272C;
    /* margin: 0 24px; */
    margin-top:20px;
    align-self: stretch;
`;