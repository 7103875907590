import { Popover } from 'antd';
import { Observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from "../../../app/data/root.store";
import { SectionTitle, SettingsMenuLink, SettingsMenuWrapper } from './UserProfile.styles';
import { PortfolioSettingsIcon } from './icons/sidebar-icons';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { SideBarUpgradeButton } from './Sidebar.styled';
import { UpgradeCrownIcon } from '../../../../assets/icons/common/common-icons';
import { TierBadge } from '../TierBadge/TierBadge';
import { ROUTES } from '../../../../common/constants/routes.const';

const links = [
  { path: ROUTES.profile.url },
  { path: ROUTES.roles.url },
  { path: ROUTES.billing.url },
  { path: ROUTES.accounts.url },
]

export const PortfolioSettingsMenu = (props) => {

  const { userStore, uiStore , billingStore} = useStore();
  const location = useLocation();
  const [isSelected, setIsSelected] = useState('');
  const [isUserDetailsSelected, setIsUserDetailsSelected] = useState(false);

  useEffect(() => {
    // console.log(location.pathname);
    if (links.find(link => link.path === location.pathname)) {
      setIsSelected(location.pathname);
    }

    return () => {
      setIsSelected('');
    }
  }, [location])



  const settingsMenu = (isIncognito) => {

    const handleConnectedAccountsClick = (e) => {
      if (userStore.isDemoMode) {
        e.preventDefault();
        setIsUserDetailsSelected(false);
        return billingStore.setShowPricingTableModal(true);
      }
    }  

    const handleRolesClick = (e) => {
      if (userStore.isActionBlockedByRole('viewRolesPageFromSidebar')) {
        e.preventDefault();
        e.stopPropagation();
        userStore.setShowLimitedSharedAccess(true);
      }
    }

    const handleSubscriptionsClick = (e) => {
      if (userStore.isActionBlockedByRole('viewBillingPageFromSidebar')) {
        e.preventDefault();
        e.stopPropagation();
        setIsUserDetailsSelected(false);
        userStore.setShowLimitedSharedAccess(true);
      }
    }

    return (
      <>
      
      <SettingsMenuWrapper>
        <SectionTitle>Portfolio Settings</SectionTitle>
        
       
          <SettingsMenuLink selected={ROUTES.accounts.url === isSelected} onClick={handleConnectedAccountsClick} to={ROUTES.accounts.url}>{ROUTES.accounts.title}{userStore.isDemoMode && <ActionLockedIconWithTooltip iconColor={'grey'} marginTop={'-2px'} />}</SettingsMenuLink>
          {userStore.isFreeTier && <SideBarUpgradeButton isProfileMenu onClick={()=>{setIsUserDetailsSelected(false);billingStore.setShowPricingTableModal(true)}} ><UpgradeCrownIcon />Upgrade</SideBarUpgradeButton>}
          {userStore.isTier2 && <SideBarUpgradeButton isBlueBtn isProfileMenu onClick={()=>{setIsUserDetailsSelected(false);billingStore.setShowPricingTableModal(true)}} ><UpgradeCrownIcon />Upgrade</SideBarUpgradeButton>}
   
          <SettingsMenuLink selected={ROUTES.preferences.url === isSelected} to={ROUTES.preferences.url}>{ROUTES.preferences.title}</SettingsMenuLink>
          <SettingsMenuLink selected={ROUTES.roles.url === isSelected} to={ROUTES.roles.url} onClick={handleRolesClick}>{ROUTES.roles.title}{userStore.isActionBlockedByRole('viewRolesPageFromSidebar') && <ActionLockedIconWithTooltip iconColor={'#ACB1BB'} isRoleBlocked={true}  />}</SettingsMenuLink>
          <SettingsMenuLink selected={ROUTES.billing.url === isSelected} onClick={handleSubscriptionsClick} to={ROUTES.billing.url}>{ROUTES.billing.title}<TierBadge tier={userStore.getTierType} /> {userStore.isActionBlockedByRole('viewBillingPageFromSidebar') && <ActionLockedIconWithTooltip iconColor={'#ACB1BB'} isRoleBlocked={true} />}</SettingsMenuLink>
        
      </SettingsMenuWrapper>
      </>
    )
  }

  return <Observer>{() => (
    <Popover onVisibleChange={(visible) => setIsUserDetailsSelected(visible)} visible={isUserDetailsSelected} trigger="click" placement="rightBottom" overlayClassName={'userProfilePopover' + (userStore.isDemoMode ? ' demo' : '')} content={settingsMenu(uiStore.isIncognitoMode)}>
        <ul style={{margin:'8px 16px'}}>
          <li>
            <div className='menuButton'>
            <PortfolioSettingsIcon />Portfolio Settings
            </div>
          </li>
        </ul>
    </Popover>
  )}</Observer>
}



