import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";
import { containerRow } from "../../common-fields/container-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { isBetweenZeroAndHundred, isRequired } from "../../input-validations";

export const createCashAccountsAddItemSections = (isEditForm) => {
    return ({updateField, updateValue}) => { 
        const {NAME_LABEL, NAME_DESCRIPTION, VALUE_LABEL, VALUE_DESCRIPTION} = getTranslations().new_item_modal.cash_accounts;
        return ([
            {
                title:'General info',
                rows:[
                    ...masterInstitutionInputRows(NAME_LABEL,NAME_DESCRIPTION, 1, false, updateValue, !isEditForm),
                    {fields:[
                        {key:'title', validations:[isRequired]}
                    ]},
                    {fields:[
                        {key:'value', type: 'numberWithHoldings', label: VALUE_LABEL, description: VALUE_DESCRIPTION, validations:[isRequired], allowNegative: true ,generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                        }},
                        {key:'currency',size:0.3,onChange:({value})=>{
                            updateField('value',{prefix:CURRENCY_SYMBOL[value] || ''});
                        }}
                    ]},
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : [
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden:true,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                    onBlur:({value})=>{
                                        updateField('value',{holdings:value || ''});
                                        updateField('cashInvested',{holdings:value || ''});
                                    },
                                    generatePropsFn : (values)=>{
                                        return {isHidden: values.holdings === 100}
                                    }
                                }
                            ]
                        },
                        {
                            fields:[
                                {type:'checkbox', key:'isPartialHolding',checkboxLabel: 'This asset is 100% owned by me', description: 'If you are a co-owner of this account, uncheck this box.' ,
                                defaultValue: true,
                                fieldCustomStyle: {marginTop:'-24px'},
                                    onChange: ({value})=>{
                                        const newHoldingValue = value ? 100 : 50;
                                        updateValue('holdings',newHoldingValue);
                                        updateField('holdings',{isHidden:value});
                                        updateField('value',{holdings:newHoldingValue});
                                        updateField('cashInvested',{holdings:newHoldingValue});
                                        
                                    }
                                },
                            ]
                        },                            
                    ])
                ]
            },{
                title: 'Projection', 
                rows:[
                    {fields:[
                        {key:'apy', label: 'Annual percentage yield (APY) %'}
                    ]},
                ]
            },{
                title:'More info',
                rows:[
                    ...(isEditForm ? [
                        containerRow({isEditForm, updateField}),
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isLocked: true, UnlockComponent:HoldingsUnlockModal,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                // onBlur:({value})=>{
                                //     updateField('monthlyRent',{holdings:value || ''});
                                //     updateField('expectedMonthlyExpenses',{holdings:value || ''});
                                //     updateField('expectedSalePrice',{holdings:value || ''});
                                // },
                                }
                            ]
                        },
                    ] : []),
                    beneficiaryRow({label: 'Holding entity'}),
                    ...(isEditForm ? [
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {fields:[
                        {key:'remarks', label: 'Notes'}
                    ]}
                ]
            }
        ])
    }
};
export const createCashAccountsPhysicalCashAddItemSections = (isEditForm) => {
    return ({updateValue,updateField}) => { 
        const { physical_cash } = getTranslations().new_item_modal.cash_accounts;
        return ([
        {
            title:'General info',
            rows:[
                {fields:[
                    {key:'title', label: physical_cash.TITLE_LABEL, description: physical_cash.TITLE_DESCRIPTION, validations:[isRequired]}
                ]},
                {fields:[
                    {key:'value', description: physical_cash.VALUE_DESCRIPTION, validations:[isRequired],generatePropsFn : (values)=>{
                        return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                    },label: 'Current amount'},
                    {key:'currency',size:0.3,onChange:({value})=>{
                        updateField('value',{prefix:CURRENCY_SYMBOL[value] || ''});
                    }}
                ]},
                ...(isEditForm ? [
                    ...customClassAndSubClassRows({updateValue,updateField}),
                    containerRow] : []),
                {fields:[
                    {key:'remarks', label: 'Anything else you’d like to add?', description: physical_cash.REMARKS_DESCRIPTION}
                ]}
            ]
        }])
    }
};