
import React from 'react';
import { AutoComplete } from "antd";

import { Observer} from 'mobx-react';
import { useStore } from '../../../../modules/app/data/root.store';

export function BeneficiaryAutocomplete(props) {
    const { inputKey, handleChange, disabled, value, scrollContainerId } = props;

    const {metadataStore} = useStore();

    return (<Observer>{()=>(
        <AutoComplete value={value}  options={metadataStore.sortedBeneficiaries} 
        disabled={disabled}  filterOption={true}
         onChange={(val)=>{handleChange(inputKey,val)}} 
         getPopupContainer={() => scrollContainerId ? ( document.getElementById(scrollContainerId) || document.body) : document.body} />
    )}</Observer>)

}