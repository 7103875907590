import { Observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { PrimaryButton, SecondaryButtonBorderless } from '../../../../common/components/buttons/NewButtons.styles';
import { PasswordDisplayer } from '../../../../common/components/PasswordDisplayer/PasswordDisplayer';
import { useStore } from '../../../app/data/root.store';
import { errorNotification, successNotification } from '../../../layout/components/Notifications/notifications';
import { RestSuccessfulSvgWrapper, SetPasswordStepHeader, SignUpStepSubHeader } from '../../../login/components/LoginPage.styles';
import { ReactComponent as CloseXIcon } from '../ExportAllDataModal/asstes/close-x-icon.svg';
import { ActionButtonsWrapper, CloseBtnWrapper, DataBoxInputWrapper, HeaderTitle, HeaderWrapper, ModalContentWrapper, ModalInputLabel, ModalInputWrapper, ModalLoginPasswordInput } from './ChangePasswordModal.styles';
import { isValidPassword } from '../../../../common/utils/string.utils';

export function ChangePasswordModal(props) {
  const { onClose } = props;
  const { userStore } = useStore();
  const passwordInputRef = useRef(null);
  const oldPasswordInputRef = useRef(null);
  const [passwordForClipboard, setPasswordForClipboard] = useState('');
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);

  const handleNewPasswordOnClick = async () => {
    const isPasswordValid = isValidPassword(passwordInputRef.current.state.value);
    if (!isPasswordValid) {
      return errorNotification('Password must be at least 8 characters and contain 1 number, 1 lowercase letter, 1 uppercase letter, and 1 special character');      
    }
    let data;
    setPasswordForClipboard(passwordInputRef.current.state.value);
    if (userStore.data.profile?.isPasswordEmpty) {
      data = await userStore.changePassword({
        oldPassword: '',
        newPassword: passwordInputRef.current.state.value
      });
    } else {
      // check if new password is not the same as old one
      const passwordChanged = oldPasswordInputRef.current.state.value !== passwordInputRef.current.state.value;
      if (passwordChanged) {
        data = await userStore.changePassword({
          oldPassword: oldPasswordInputRef.current.state.value,
          newPassword: passwordInputRef.current.state.value
        });
      }
    }
    setIsResetSuccessful(data?.success);
  }

  const handleCopyPasswordClick = () => {
    navigator.clipboard.writeText(passwordForClipboard);
    successNotification('Password copied');
  }

  return (<Observer>{() => (
    <BaseModal width={'480px'} height={'auto'} borderRadius={4}>
      <HeaderWrapper>
        <HeaderTitle>{userStore.data.profile?.isPasswordEmpty ? 'Set' : 'Change'} password</HeaderTitle>
        <CloseBtnWrapper onClick={onClose}><CloseXIcon /></CloseBtnWrapper>
      </HeaderWrapper>
      <ModalContentWrapper isResetSuccessful={isResetSuccessful}>
        {!isResetSuccessful ? <>
          {!userStore.data.profile?.isPasswordEmpty ? <ModalInputWrapper>
            <ModalInputLabel>Old Password</ModalInputLabel>
            <ModalLoginPasswordInput name="oldPassword" ref={oldPasswordInputRef} placeholder="Enter your old password" />
          </ModalInputWrapper> : null}
          <DataBoxInputWrapper>
            <ModalInputLabel>New Password</ModalInputLabel>
            <PasswordDisplayer changePasswordView showHints={true} ref={passwordInputRef} />
          </DataBoxInputWrapper>
        </> :
          <>
            <RestSuccessfulSvgWrapper><RestSuccessfulSvg /></RestSuccessfulSvgWrapper>
            <SetPasswordStepHeader isResetSuccessful>{userStore.data.profile?.isPasswordEmpty ? `Your password was\nsuccessfully added` : `Your password was\nupdated successfully`}</SetPasswordStepHeader>
            <SignUpStepSubHeader isResetSuccessful>{userStore.data.profile?.isPasswordEmpty ? `From now on you can log in with your Google\naccount or with your email and password.` : `From now you need to log in your Vyzer\naccount with your new password.`}</SignUpStepSubHeader>
          </>}
      </ModalContentWrapper>
      <ActionButtonsWrapper>
        {!isResetSuccessful ? <>
          <SecondaryButtonBorderless onClick={() => onClose()}>Cancel</SecondaryButtonBorderless>
          <PrimaryButton onClick={handleNewPasswordOnClick} >{userStore.data.profile?.isPasswordEmpty ? 'Set' : 'Save'} password</PrimaryButton>
        </> :
          <>
            <SecondaryButtonBorderless isResetSuccessful onClick={handleCopyPasswordClick}><CopyToClipboardSvg />Copy new password</SecondaryButtonBorderless>
            <PrimaryButton onClick={() => { onClose && onClose(); }} >Done</PrimaryButton>
          </>}
      </ActionButtonsWrapper>
    </BaseModal>
  )}</Observer>)
}

const RestSuccessfulSvg = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5103 11.604L13.5059 18.6125L9.99983 15.1075" stroke="black" strokeWidth="2" />
      <rect x="0.75" y="0.75" width="28.5" height="28.5" rx="14.25" stroke="#1A1B1D" strokeWidth="1.5" />
    </svg>
  )
}

const CopyToClipboardSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.375" y="5.25" width="9.375" height="11.25" rx="0.625" stroke="#1A1B1D" strokeWidth="1.25" />
      <path d="M7.5 2.75H15C15.6904 2.75 16.25 3.30964 16.25 4V12.75" stroke="#1A1B1D" strokeWidth="1.25" strokeLinecap="round" />
    </svg>
  )
}