import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../app/data/root.store'
import { MagicBoxAIDropArea } from './MagicBoxAIDropArea'
import { MagicBoxAiModals } from './MagicBoxAIModals/MagicBoxAIModals'
import { AppHeader } from '../../layout/components/Header/Header'
import { HeaderTitle, MobileHeader } from './MagicBox.styles'
import { MobileBackButtonWrapper } from '../../layout/components/MobileLayout.styles'
import { MobileBackButton } from '../../../assets/icons/common/common-icons'
import { PageWrapper, RightSidePushMenuWrapper } from '../../layout/components/Layout.styles'
import { ActivationChecklistPane } from '../../activation/components/checklist-pane/ChecklistPane'
import { MagicBoxAiPageButtonWrapper, MagicBoxAiPageDropAreaSkipButton, MagicBoxAiPageDropAreaSkipButtonText, SectionWrapper } from './MagicBoxAiActivationFlow.styles'
import { PrimaryButton, PrimaryButtonWithIcon } from '../../../common/components/buttons/NewButtons.styles'
import { LoadingOutlined } from '@ant-design/icons'
import { useAnalytics } from '../../app/data/AnalyticsProvider'

export const MagicBoxAIPage = observer(() => {
    const { magicBoxAiStore, uiStore, userStore } = useStore();
    const [isLoading, setIsLoading] = useState(false);
    const { trackEvent } = useAnalytics()

    useEffect(() => magicBoxAiStore.fetchData(), [magicBoxAiStore]);

    const onClickSkip = (e) => {
        e.stopPropagation();
        trackEvent('CTA Clicked', {
            cta_text: 'I don’t have a balance sheet',
            location: 'Magic box ai activation flow',
            destination: 'manual magic box',
            type: "Button",
            url: window.location.href
        })
        magicBoxAiStore.setSwitchToManualReview(true)
        magicBoxAiStore.setSwitchToManualReview(true);
    };

    const llmStatus = magicBoxAiStore.file?.llmStatus;
    const showBtn = (!magicBoxAiStore.isUploading && (('done' === llmStatus) || ('failed' === llmStatus) || ('timeout' === llmStatus) || ('rejected' === llmStatus)));
    const disabledBtn = (magicBoxAiStore.isTyping || ('in process' === llmStatus));
    const submitButton = (('done' === llmStatus) || ('failed' === llmStatus) || ('timeout' === llmStatus));

    const onClickBtn = async () => {
        setIsLoading(true);
        switch (llmStatus) {
            case 'done':
                await magicBoxAiStore.approveLLM();
                break;
            case 'failed':
            case 'timeout':
                await magicBoxAiStore.approveLLM();
                await magicBoxAiStore.confirmUpload();
                break;
            case 'rejected':
                await magicBoxAiStore.deleteDocument();
                break;
            default:
                break;
        }
        setIsLoading(false);
    };

    return (
        <>
            {
                uiStore.isDesktopView ? <AppHeader /> :
                    <MobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
                        <MobileBackButtonWrapper onClick={() => { uiStore.setIsAppMobileMenuOpen(true) }}><MobileBackButton /></MobileBackButtonWrapper>
                        <HeaderTitle>Magic box</HeaderTitle>
                    </MobileHeader>
            }
            <RightSidePushMenuWrapper>
                {userStore.isViewingOwnPortfolio && userStore.isGetStarted && <ActivationChecklistPane />}
            </RightSidePushMenuWrapper>
            <PageWrapper withHeader>
                <SectionWrapper style={{ gap: '38px' }}>
                    <MagicBoxAIDropArea gray skipButton={<SkipButton onClick={onClickSkip} />} />
                    {
                        showBtn &&
                        <MagicBoxAiPageButtonWrapper>
                            {
                                submitButton ?
                                    <PrimaryButton disabled={disabledBtn} onClick={onClickBtn} borderRadius={39} style={{ minWidth: '86px' }}>{isLoading ? <LoadingOutlined /> : "Submit"}</PrimaryButton> :
                                    <PrimaryButtonWithIcon borderRadius={39} onClick={()=> magicBoxAiStore.deleteDocument()}><UploadIconSvg />Upload another file</PrimaryButtonWithIcon>
                            }
                        </MagicBoxAiPageButtonWrapper>
                    }
                </SectionWrapper>
            </PageWrapper>
            <MagicBoxAiModals />
        </>
    );
});

const SkipButton = ({ onClick }) => {
    return (
        <MagicBoxAiPageDropAreaSkipButton onClick={onClick}>
            <MagicBoxAiPageDropAreaSkipButtonText>I don’t have a balance sheet</MagicBoxAiPageDropAreaSkipButtonText>
        </MagicBoxAiPageDropAreaSkipButton>
    );
}

export const UploadIconSvg = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 4.75L12.5 15.25" stroke='white' strokeWidth="1.5" />
            <path d="M16 8.25L12.5 4.75L9 8.25" stroke='white' strokeWidth="1.5" />
            <path d="M5.5 13.3895V18.25C5.5 18.8023 5.94772 19.25 6.5 19.25L18.5 19.25C19.0523 19.25 19.5 18.8023 19.5 18.25L19.5 13.25" stroke='white' strokeWidth="1.5" />
        </svg>
    )
};
