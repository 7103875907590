import styled from '@emotion/styled/macro';
import { Button, Checkbox } from 'antd';
import { ModalHeader } from "../../../../../../../common/components/BaseModal/BaseModal.styles";
import { colors } from '../../../../../../../common/styles/theme.styles';


export const ExportViewModalHeader = styled(ModalHeader)`
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 18px 24px;
  
  button {
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    opacity: .6;
    &:hover { opacity: 1 }
    &:active { opacity: .8 }
  }
`

export const ModalHeaderTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const HeaderTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.darkGray1};
`;

export const HeaderSubtitle = styled.p`
  font-size: 12px;
  color: ${colors.darkGray2};
`;

export const ActionButton = styled(Button)`
  &.ant-btn-primary{
    border: 0;
    background: ${colors.darkGray1};
    border-radius: 6px;
    line-height: 1em;
    display: flex;
    align-items: center;
    font-size: 15px;
    gap: 4px;
    padding: 1.25em 1em;
    &[disabled] {
      border: 0;
      background: ${colors.darkGray1};
      color: #fff;
      opacity: .6; 
    }
  }

    svg {
        vertical-align: middle;
        margin-top: -3px;
    }

  /* .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    border: 1px solid red;
  }
  .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    border: 1px solid red;
  } */
`

export const FiltersCheckbox = styled(Checkbox)`
  margin-top: 12px;
  /* .css-mgehc4-FiltersCheckbox .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: 14px;
  } */
  .ant-checkbox-checked .ant-checkbox-inner::after {

  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${colors.darkGray3};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${colors.darkGray1};
    border-color: ${colors.darkGray3};
    border-radius: 4px;
  }
  .ant-checkbox-checked::after {
    border-color: ${colors.gray2};
  }
`