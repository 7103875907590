import React from 'react'
import {
  CryptoAutoCompleteResultRowWrapper,
  HighlightedSearchText, ItemContent, ItemLogoWrapper, ItemText, ItemTitle, LogoPlaceHolder, Separator,
  // ItemMoreInfos, MoreInfosItem,
} from './CryptoAutoComplete.styles';
import { ReactComponent as InstitutionPlaceholderIcon } from './icons/institution-placeholder-icon.svg';

export const CryptoAutoCompleteResultRow = (props) => {
  const { inst,
    handleItemClick,
    searchStr, isSeparator } = props;
  const hightlightSearchTerm = (title) => {
    if (searchStr) {
      const index = title?.toLowerCase().indexOf(searchStr.toLowerCase());
      if (index !== -1) {
        const length = searchStr.length;
        const prefix = title.substring(0, index);
        const suffix = title.substring(index + length);
        const match = title.substring(index, index + length);
        return (
          <span>{prefix}<HighlightedSearchText>{match}</HighlightedSearchText>{suffix}</span>
        )
      }
    }
    return title;
  }
  return (
    !isSeparator ? <CryptoAutoCompleteResultRowWrapper
      onMouseDown={() => { handleItemClick(inst) }}
    >
      {inst.imageUrl ? <ItemLogoWrapper><img alt={inst.coinName} src={inst.imageUrl} /></ItemLogoWrapper>
        : <LogoPlaceHolder ><InstitutionPlaceholderIcon /></LogoPlaceHolder>
      }
      <ItemContent>
        <ItemText>
          <ItemTitle>{hightlightSearchTerm(inst.coinName)}</ItemTitle>
          {/* <ItemMoreInfos>
            <MoreInfosItem>
              {inst.homeURL}
            </MoreInfosItem>
          </ItemMoreInfos> */}
        </ItemText>
      </ItemContent>
    </CryptoAutoCompleteResultRowWrapper> : <Separator />
  )
}
