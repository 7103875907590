import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";
import { containerRow } from "../../common-fields/container-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { isBetweenZeroAndHundred, isRequired } from "../../input-validations";

export const createPrivateLoanAddItemSections = (isEditForm) => {
    return ({updateValue,updateField}) => {
        const { private_loans } = getTranslations().new_item_modal;
        return ([
            {
                title:'General info',
                rows:[
                    ...masterInstitutionInputRows('Borrower','The person or organization you lent money to.', 5, true, updateValue),
                    {fields:[
                        {key:'title',label: private_loans.TITLE_LABEL, validations:[isRequired], description:private_loans.TITLE_DESCRIPTION}, 
                    ]},
                    beneficiaryRow({}),
                    {fields:[
                        {key:'value', type: 'numberWithHoldings',label: private_loans.VALUE_LABEL, validations:[isRequired], 
                        description:`The most updated remaining value of the loan (taking into account repayments you've received)`,
                        onBlur:({value})=>{
                            updateValue('cashInvested',value);
                        },generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                        }},
                        {key:'currency',size:0.3,onChange:({value})=>{
                            updateValue('cashInvestedCurrency',value);
                            updateField('value',{prefix:CURRENCY_SYMBOL[value] || ''});
                            updateField('cashInvested',{prefix:CURRENCY_SYMBOL[value] || ''});
                        }},
                        // {
                        //     key:'cashInvested', 
                        //     isHidden:true,
                        //     defaultValueFn:(values)=>values.value
                        // },
                    ]},
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : [
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden:true,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                    onBlur:({value})=>{
                                        updateField('value',{holdings:value || ''});
                                        updateField('cashInvested',{holdings:value || ''});
                                    },
                                    generatePropsFn : (values)=>{
                                        return {isHidden: values.holdings === 100}
                                    }
                                }
                            ]
                        },
                        {
                            fields:[
                                {type:'checkbox', key:'isPartialHolding',checkboxLabel: 'This asset is 100% owned by me', description: 'If you are a co-owner of this asset, uncheck this box.' ,
                                fieldCustomStyle: {marginTop:'-24px'},
                                defaultValue: true,
                                    onChange: ({value})=>{
                                        const newHoldingValue = value ? 100 : 50;
                                        updateValue('holdings',newHoldingValue);
                                        updateField('holdings',{isHidden:value});
                                        updateField('value',{holdings:newHoldingValue});
                                        updateField('cashInvested',{holdings:newHoldingValue});
                                    }
                                },
                            ]
                        },
                        ]),
                    // {fields:[
                    //     {key:'value', label: 'Current Loan Value', validations:[isRequired],
                    //      description:`The most updated remaining value of the loan (taking into account repayments you've received)` },
                    //     {key:'currency',size:0.3}
                    // ]},
                ]
            },{
                title:'Projection',
                rows:[
                    {fields:[
                        {key:'interest', label: private_loans.INTEREST_LABEL, description: private_loans.INTEREST_DESCRIPTION}
                    ]},
                    {fields:[
                        {key:'expectedEndDate', description: 'The date you expect this loan to be repaid in full'}
                    ]},
                    {fields:[
                        {key:'interestRepaymentPeriod', description: `How often the borrower pays the loan's interest`}
                    ]},
                    {fields:[
                        {key:'principalRepaymentPeriod', description: `How often the borrower repays the loan principal`}
                    ]},
                ]
            },{
                title: 'Performance', 
                rows:[
                    {fields:[
                        {key:'startDate', label: private_loans.START_DATE_LABEL, defaultValueFn: ()=>{return new Date()}, description: private_loans.START_DATE_DESCRIPTION }
                    ]},
                    ...(isEditForm ? [] : [
                    {fields:[
                            {key:'cashInvested', type: 'numberWithHoldings', label: private_loans.CASH_INVESTED_LABEL,description: private_loans.CASH_INVESTED_DESCRIPTION,prefix:CURRENCY_SYMBOL['USD']},
                            {key:'cashInvestedCurrency',size:0.3 , label: ' ', defaultValue: 'USD',prefix:CURRENCY_SYMBOL['USD']}
                    ]},
                    ])
                ]
            },{
                title:`More Info`,
                rows:[
                    ...(isEditForm ? [
                        containerRow({isEditForm, updateField}),
                        {
                            fields:[
                                {key:'holdings',  label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth',  isLocked: true,  UnlockComponent:HoldingsUnlockModal,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                // onBlur:({value})=>{
                                    //     updateField('monthlyRent',{holdings:value || ''});
                                    //     updateField('expectedMonthlyExpenses',{holdings:value || ''});
                                    //     updateField('expectedSalePrice',{holdings:value || ''});
                                    // },
                                }
                            ]
                        },
                        {
                            fields:[
                                {key:'defaultCashAccountId'}
                            ]
                        },
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {fields:[
                        {key:'remarks'}
                    ]}
                ]
            }
        ])
    }
}


export const createP2pLendingPlatformAddItemSections = (isEditForm) => {
    return ({updateValue,updateField}) => {
        const { p2p_lending_platform } = getTranslations().new_item_modal;
        const {CASH_INVESTED_LABEL} = getTranslations().common.asset_fields;
        return ([
            {
                title:'General info',
                rows:[
                    ...masterInstitutionInputRows('Platform',p2p_lending_platform.NAME_DESCRIPTION, 5, true, updateValue),
                    {fields:[
                        {key:'title', validations:[isRequired]}
                    ]},
                    beneficiaryRow({}),
                    {fields:[
                        {key:'value', type: 'numberWithHoldings', label: p2p_lending_platform.VALUE_LABEL, validations:[isRequired],
                        description:'The account’s current value. Which will be added to your net worth.',
                        onBlur:({value})=>{
                            updateValue('cashInvested',value);
                        },generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                        }},
                        {key:'currency',size:0.3,onChange:({value})=>{
                            updateValue('cashInvestedCurrency',value);
                            updateField('value',{prefix:CURRENCY_SYMBOL[value] || ''});
                            updateField('cashInvested',{prefix:CURRENCY_SYMBOL[value] || ''});
                            updateField('expectedMonthlyContribution',{prefix:CURRENCY_SYMBOL[value] || ''});
                        }}
                    ]},
                    ...( isEditForm ? [] : [
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden:true,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                    onBlur:({value})=>{
                                        updateField('value',{holdings:value || ''});
                                        updateField('cashInvested',{holdings:value || ''});
                                        updateField('expectedMonthlyContribution',{holdings:value || ''});
                                    },
                                    generatePropsFn : (values)=>{
                                        return {isHidden: values.holdings === 100}
                                    }
                                }
                            ]
                        },
                        {
                            fields:[
                                {type:'checkbox', key:'isPartialHolding',checkboxLabel: 'This asset is 100% owned by me', description: 'If you are a co-owner of this account, uncheck this box.' ,
                                fieldCustomStyle: {marginTop:'-24px'},
                                defaultValue: true,
                                    onChange: ({value})=>{
                                        const newHoldingValue = value ? 100 : 50;
                                        updateValue('holdings',newHoldingValue);
                                        updateField('holdings',{isHidden:value});
                                        updateField('value',{holdings:newHoldingValue});
                                        updateField('cashInvested',{holdings:newHoldingValue});
                                        updateField('expectedMonthlyContribution',{holdings:newHoldingValue});
                                    }
                                },
                            ]
                        },
                        ]),
                ]
            },{
                title: 'Projection', 
                rows:[
                    {fields:[
                        {key:'expectedIRR',label: p2p_lending_platform.EXPECTED_IRR_LABEL , description: p2p_lending_platform.EXPECTED_IRR_DESCRIPTION}
                    ]},
                    {fields:[
                        {key:'expectedMonthlyContribution',
                        type: 'numberWithHoldings',
                        generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || 'USD',holdings:values.holdings}
                        },
                        description: p2p_lending_platform.EXPECTED_MONTHLY_CONTRIBUTION_DESCRIPTION}
                    ]},
                ]
            },{
                title: 'Performance', 
                rows:[
                    {fields:[
                        {key:'startDate', label: p2p_lending_platform.START_DATE_LABEL, description: p2p_lending_platform.START_DATE_DESCRIPTION, defaultValueFn: ()=>{return new Date()} }
                    ]},
                    ...(isEditForm ? [] : [
                    {fields:[
                            {key:'cashInvested', type: 'numberWithHoldings', label: CASH_INVESTED_LABEL, description: p2p_lending_platform.CASH_INVESTED_DESCRIPTION,prefix:CURRENCY_SYMBOL['USD']},
                            {key:'cashInvestedCurrency',size:0.3 , label: ' ', defaultValue: 'USD',prefix:CURRENCY_SYMBOL['USD']}
                    ]},
                    ])
                ]
            },{
                title:`More Info`,
                rows:[
                    ...(isEditForm ? [
                       containerRow({isEditForm, updateField}),
                        {
                            fields:[
                                {key:'holdings',  label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth',  isLocked: true,  UnlockComponent:HoldingsUnlockModal,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                // onBlur:({value})=>{
                                //     updateField('monthlyRent',{holdings:value || ''});
                                //     updateField('expectedMonthlyExpenses',{holdings:value || ''});
                                //     updateField('expectedSalePrice',{holdings:value || ''});
                                // },
                                }
                            ]
                        },
                        {
                            fields:[
                                {key:'defaultCashAccountId'}
                            ]
                        },
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {fields:[
                        {key:'remarks'}
                    ]}
                ]
            }
        ])
    }
}