import { Input } from 'antd';
import moment from 'moment';
import React from 'react'
import NumberFormat from 'react-number-format'
import { SimpleSelect } from '../../../DropDownMenu/SimpleSelect';
import { AmortizationWrapper, AmountWrapper, EndDateHintWrapper, PeriodWrapper } from './AmortizationPeriod.styles';

export const AmortizationPeriod = (props) => {
  const { value, onChange, startDate, withError, disabled } = props;

  const handleOnChange = (fieldName, fieldValue) => {
    onChange && onChange({ ...value, [fieldName] : fieldValue })
  }

  return (
    <AmortizationWrapper withError={withError}>
      <AmountWrapper>
        <NumberFormat
          customInput={Input}
          allowNegative={false}
          value={value.amount || ''}
          className={disabled ? 'ant-input-disabled' : null}
          disabled={disabled}
          onValueChange={(values) => {
            if (value.amount !== Math.ceil(values.floatValue)) {
              handleOnChange('amount', Math.ceil(values.floatValue));
            }
          }}
        />
        {value.amount && startDate ? <EndDateHintWrapper>{moment(startDate).add(value.amount, value.period === 'Annually' ? 'Y' : 'M').format('MMM YYYY').toString()}</EndDateHintWrapper> : null}
      </AmountWrapper>
      <PeriodWrapper>
        <SimpleSelect
          buttonStyle={{ boxShadow: 'none', fontWeight: 'normal', height: '38px', border: '1px solid #d9d9d9', borderRadius: '2px', borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderLeft: 'none', ...(withError ? { borderColor: '#FF7676' } : {}) }}
          placement={"bottomLeft"}
          allowClear={false}
          placeHolder={'Years'}
          disabled={disabled}
          className={disabled ? 'ant-dropdown-disabled' : null}
          value={value.period || ''}
          onChange={(option) => { handleOnChange('period', option) }}
          options={[
            { label: 'Years', value: 'Annually' },
            { label: 'Months', value: 'Monthly' }
          ]}
        />
      </PeriodWrapper>
    </AmortizationWrapper>
  )
}
