import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../common/styles/combos.styles';

export const StepsPathWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 132px;
`;

export const StepNodeWrapper = styled.div`
  ${flexCenter}
  cursor: pointer;
  border-radius: 50%;
  :hover {
    opacity: 0.8;
    background: #A6EDCF;
  }
`;

export const StepLine = styled.div`
  width: 2px;
  height: 40px;
  background-color: ${({ isDone }) => (isDone ? '#3A6E64' : '#333')};
`;