// import { Player } from "@lottiefiles/react-lottie-player";
import { Observer } from 'mobx-react';
import React from 'react';
// import { ActionLocked } from '../../../common/components/ActionLocked/ActionLocked';
// import { ActionLockedIconWithTooltip } from '../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { useStore } from '../../../app/data/root.store';
// import heroAnimData from '../assets/hero-lottie.json';
import { AltTitle, HeroFlexWrapper, HeroTitle, HeroWrapper, LeftPanelContentWrapper, LeftPanelWrapper, RightPanelWrapper, TitlesWrapper } from './Roles.styles';
import mobileHeroImg from './assets/roles-hero-mobile.png';
import heroImg from './assets/roles-hero.png';


export function RolesHero() {
  const { uiStore } = useStore();

  return (<Observer>{() => (
    <HeroWrapper >
      <HeroFlexWrapper >
        <LeftPanelWrapper>
          <LeftPanelContentWrapper>
            <TitlesWrapper>
              <HeroTitle>Share limited access to{uiStore.isDesktopView? ` ` : <br/>}your portfolio</HeroTitle>
              <AltTitle>Invite your financial advisor, CPA, wealth{uiStore.isDesktopView? ` ` : <br/>}manager or{uiStore.isDesktopView? <br/> :` ` }your family members.</AltTitle>
            </TitlesWrapper>
          </LeftPanelContentWrapper>
        </LeftPanelWrapper>
        <RightPanelWrapper bgImage={heroImg} mobileBgImage={mobileHeroImg}>
          
          {/* <img src={heroImg} /> */}
          {/* <Player
            ref={lottieRef}
            src={heroAnimData}
            //play={showLetsGoAnim}
            // onComplete={event => {
            //   console.log("A" , event);
            //   setPlayerDir(playerDir === 1 ? -1 : 1);
            // }}
            // loop={false}
            onEvent={event => {
              if (event === 'complete') {
                handleDirDone();
              }
            }}
            keepLastFrame
            autoplay={true}
            speed={0.5}
            // loop 
            style={{ width:  uiStore.isDesktopView ? '400px' : '100vw' }}
          /> */}
        </RightPanelWrapper>
      </HeroFlexWrapper>

    </HeroWrapper>
  )}</Observer>)
}   