import styled from "@emotion/styled/macro";
import { Input } from "antd";
import { flexCenter } from "../../../../styles/combos.styles";
import { colors, shadows, views } from "../../../../styles/theme.styles";

export const Wrapper = styled.div`
    width: 100%;
    border-radius: 9px;
    display:flex;
    flex-direction:column;
    padding:16px 0px 0px;
`;

const columns = {
    tickerSearch: '5fr',
    quantity: '3fr',
}

export const TableRowSizes = styled.div`
    display: grid;
    grid-template-columns: ${columns.tickerSearch} ${columns.quantity};
    padding: 8px 0;
`;

export const TableHeaderRow = styled(TableRowSizes)`
`;

export const HeaderCell = styled.div`
    font-size:12px;
    color: ${colors.darkGray2};
    padding-left: 11px;
    :nth-of-type(2), :nth-of-type(4){
        padding-left: 8px;
    }
`;

export const TableScrollContent = styled.div`
    .ant-picker , .ant-input{
        border-radius:0px;
        &:hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
            box-shadow: none;
        }
    }

    .ant-input-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }

    .ant-dropdown-trigger{
        font-size: 14px;
        padding-left: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 0px;
    }
`;

export const TableBottomActions = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    padding-top:8px;
    gap: 26px;
`;

export const CreateButton = styled.div`
    ${flexCenter};
    cursor: pointer;
    color: #fff;
    background-color: ${colors.darkGray1};
    padding: 5px 16px 5px 8px;
    border-radius:8px;
    font-size:14px;
    font-weight: 500;
    max-height: 32px;

    svg{
        path{
            stroke: #fff;
        }
        margin-right:4px;
    }
    .button-text{
        margin-left:4px;
    }
`;

export const RowWrapper = styled(TableRowSizes)`
    padding: 8px 0px;
    position:relative;
    // :not(:last-of-type){
    //     border-bottom:1px solid ${colors.gray3};
    // }
    :last-of-type{
        padding-bottom:0px;
    }
    min-height:48px;
`;

export const RowCell = styled.div`
    font-size:14px;
    line-height:24px;
    color: ${colors.darkGray1};
    align-items: center;
    display:flex;

    .ant-input{
        height:48px;
        font-size: 14px;
        &.inputEntryError{
            border: 1px solid #FF7676;
        }
    }
    .ant-picker{
        font-size: 14px;
    }
    .ant-picker > .ant-picker-input > input{
        font-size: 14px;
    }
    .ant-input-affix-wrapper{
        height:32px;
        .ant-input {
            font-size: 14px;
            height:22px;
        }
    }

    ${props=>!props.isEditMode ? `
    .ant-input-affix-wrapper {
        .ant-input {
            font-size: 14px;
            background-color: transparent;
            border-color: transparent;
        }
    }
    ` : null}
`;

export const InputWithBadgeWrapper = styled.div`
    position:relative;
    width:${props=>props.isWithHoldings ? '50' : '100'}%;
    .ant-input{
        font-size: 14px;
    }
`

export const HoldingsBadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    position:absolute;
    left:8px;
    top: 50%;
    transform: translateY(-50%);
    
`;

export const HoldingsBadgeText = styled.div`
    background-color: ${colors.holdingsBadgeColor};
    width:8px;
    height:12px;
    border-radius: 2px 0px 0px 2px;
`;

export const HoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid ${colors.holdingsBadgeColor};
`;

export const RowActionsCell = styled.div`
    align-items: center;
    display:flex;
    position:absolute;
    top:14px;
    opacity:0;
    ${RowWrapper}:hover &{
        opacity:1;
    }
    &:hover{
        opacity:1;
    }
    ${views.desktop}{
        left:100%;
    }
    ${views.tablet}{
        right: -14px;
        background-color: #fff;
        border-radius: 50%;
    }
`;

export const ActionButtonWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px;
    svg{
        height: 24px;
        path {
            stroke: #000;
        }
    }
`;

export const TotalsRow = styled.div`
    display: grid;
    grid-template-columns: ${columns.tickerSearch} ${columns.quantity};
    padding: 8px 0;
    flex: 1;
    border-top: 1px solid ${colors.gray3};
`;

export const PlusIconWrapper = styled.div`
    ${flexCenter};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    box-shadow: ${shadows.toggleShadow};
`;

export const TotalInvestmentHeader = styled.div`
    color: ${colors.darkGray2};
    font-size: 12px;
    width: 100%;
`;
export const TotalInvestmentAmount = styled(TotalInvestmentHeader)``;
export const TotalNumberOfCoinsHeader = styled(TotalInvestmentHeader)``;
export const TotalNumberOfCoinsAmount = styled(TotalInvestmentHeader)``;

export const cryptoTradingTableSelectButtonCustomStyles = (isEditMode) => {
    return {
        boxShadow: 'none',
        fontWeight: 'normal',
        height: '32px',
        ...(isEditMode ? {} : {
            backgroundColor:'transparent',
            borderColor: 'transparent',
        })
    }
}

export const TickerAutoCompleteWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const SearchInputAndResultsWrapper = styled.div`
  position: relative;
  width:100%;
`;

export const SearchInput = styled(Input)`
  height: 48px;
  border: 1px solid ${colors.darkGray2};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  &:hover{
      border-color:#7d7d7d!important;
      box-shadow: none;
  }
  &:focus{
      border-color:#7d7d7d!important;
      box-shadow: none;
  }
  &.ant-input-affix-wrapper-focused{
      border-color:#7d7d7d;
      box-shadow: none;
  }
`

export const ArrowUpSvgWrapper = styled.div`
    position: absolute;
    right: 16px;
    top: 50%;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
`;

export const ListAndLoaderWrapper = styled.div`
    position: absolute;
    width: 592px;
    z-index: ${props=>props.left ? 124 : 0};
    top:${props=>props.top}px;
    left:${props=>props.left}px;
    ${views.tablet}{
        width: 100%;
        z-index: ${props=>props.left ? 999 : 0};
    }
`;

export const TickerResultsListWrapper = styled.div`
    margin-top: 7px;
    box-shadow:${shadows.menuPopup};
    border: 1px solid ${colors.gray3};
    background-color:#fff;
    border-radius:8px;
    min-height:48px;
    max-height:398px;
    overflow-y:auto;  
`;

export const LoadMoreLoaderWrapper = styled.div`
    padding:24px;
    ${flexCenter};
`;

export const SearchingLoaderWrapper = styled.div`
    position:absolute;
    top: 7px;
    bottom:0;
    left:0;
    right:0;
    background-color:${colors.lightGray2};
    border-radius:8px;
    opacity:0.7;
    ${flexCenter};
    min-height: 140px;
`;

export const SelectedTickerWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 11px;
    font-size: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    border-radius: 6px 0 0 6px;
    border: 1px solid ${colors.gray3};
    pointer-events: none;
`;

export const SelectedTickerTextWrapper = styled.div`
  width: 232px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const QuantitySuffixWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    // left: 40%;
    color: ${colors.darkGray3};
    font-family: Circular;
    font-size: 13px;
    font-weight: 450;
    pointer-events: none;
    padding-right: 11px;
`;

export const TickerAutoCompleteResultRowWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 16px;
    gap: 8px;
    border-left: 2px solid #fff;
    :hover{
        border-left: 2px solid #000;
        background-color: ${colors.lightGray1};
    }
    cursor: pointer;
    ${props=>props.isTickerSelected ? `
    opacity: 0.4;
    cursor: not-allowed;
    ` : ``}
`;

export const HighlightedSearchText = styled.span`
    font-weight: 500;
    color: ${colors.darkGray1};
`;

export const ItemLogoWrapper = styled.div`
    height:30px;
    width:30px;
    border-radius: 24px;
    img{
        height:30px;
        width:30px;
        border-radius: 24px;
        object-fit: contain;
        background-color: #fff;
    }
`;

export const SelectedCheckMarkSvgWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Separator = styled.div`
    width:100%;
    height:1px;
    background-color:${colors.gray3};
`;

export const LogoPlaceHolder = styled.div`
    ${flexCenter};
    height:32px;
    width:32px;
    background-color: #FBD384;
    color: ${colors.darkGray1};
    font-weight: 500;
    border-radius:50%;
`;

export const ItemContent = styled.div`
    flex:1;
`;

export const ItemText = styled.div`
    display:flex;
    flex-direction:column;
    gap:2px;
`;

export const ItemTitle = styled.div`
    color:${colors.darkGray2};
    font-size:14px;
    line-height:17px;
    font-weight:400;
`;

export const ItemMoreInfos = styled.div`
    font-size:12px;
    line-height: 14px;
    color: #9097A3;
    display:flex;
    flex-direction:row;
    align-items:center;
`;

export const MoreInfosItem = styled.div`
    font-size:12px;
    color: #9097A3;
    &:not(:last-child){padding-right:8px;}
    display: flex;
    align-items: center;
`;

export const SearchTickersMobileWrapper = styled.div`
    height:100%;
    padding-top:120px;
`

export const SearchTickersMobileHeader = styled.div`
    position:fixed;
    height: 120px;
    border-bottom: 1px solid gray;
    display: flex;
    flex-direction: column;
    top:0;
    width:100%;
    background-color:#fff;
    z-index: 1;
`;

export const SearchTickersMobileHeaderTitleAndClose = styled.div`
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding:16px 0;
`;

export const DrawerInputWrapper = styled.div`
    padding:0 16px;
`;


export const DrawerHeaderTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
`;

export const CloseButtonWrapper = styled.div`
    // margin:8px 8px 8px 0;
    cursor: pointer;
    padding: 0px 16px;
    display: flex;
    align-items: center;
`;