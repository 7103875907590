import styled from '@emotion/styled/macro';
import { flexCenter } from '../../../../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../../../../common/styles/theme.styles';
import { TableRowSizes } from './AssetEventsTab/AssetEventsTab.styles';

export const EventTimeLine = styled.div`
    position: absolute;
    left: 47px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #DDE3EB;
`;

const isArrowDisabled = (isDisabled) => {
    return isDisabled? `
        background-color: #ACB1BB;
        cursor: default;
    ` : `
        background-color: #1A1B1D;
        cursor: pointer;
    `;
}

export const ArrowIconWrapper = styled.div`
    ${props => isArrowDisabled(props.disabled)};
    
    margin-left: 39px;
    top: ${props => props.up ? '15px' : '214px'};
    z-index: 1;
    transform: ${props => props.up ? 'rotate(180deg)' : 'rotate(0deg)'};
    width: 17px;
    height: 17px;
    border-radius: 40px;
    display: grid;
    place-items: center;
    
    svg {
        path {
            stroke: white;
        }
    }
`;

export const EventSliderWrapper = styled.div`
    position: relative;
    padding-bottom: 8px;
`;

export const ArrowRowWrapper = styled.div`
    display:flex;
    align-items:center;
`;

export const NotificationsCountsWrapper = styled.div`
    margin-left:8px;
    display:flex;
`;

export const PastNotificationsCount = styled.div`
    background-color:${colors.redNotification};
    border-radius:20px;
    font-size:12px;
    line-height: 16px;
    /* font-weight:600; */
    padding:2px 6px 2px 5px;
    color:#fff;
    margin-right:8px;
    text-align:center;
    min-width:20px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: bold;
    letter-spacing: -1px;
    
`;
export const TransactionsNotificationsCount = styled(PastNotificationsCount)`
    background-color:#82AAFA;
`;

export const EventRow = styled(TableRowSizes)`
    /* display: flex;
    padding: 8px 20px;
    margin: 0px 0px; */
    color: ${props => props.hasHappened ? '#1A1B1D' : '#959CAA'};
    transition:background-color 0.2s;
    background-color:${props => props.isMarked ? '#F1F4F8' : props.isOverdue ? '#FFFBFB' : 'none'}; 
    position: relative;
    .showOnHover{
        display:none;
    }
    &:hover{
        background-color:${colors.lightGray1};
        ::before{
            content:'';
            position:absolute;
            left:0%;
            top:0;
            width:2px;
            height:100%;
            background-color:#000000;
        }    
        /* grid-template-columns: 90px repeat(2, ${props=> 128 - (props.categoryAttributeCount || 0) * 10 }px) ${props=>props.categoryAttributeCount ? `repeat(${props.categoryAttributeCount}, 85px)` : ``} ${props=> 128 - (props.categoryAttributeCount || 0) * 10 }px auto; */
        grid-template-columns:
        104px
        ${props=> 160 - (props.categoryAttributeCount || 0) * 16}px
        ${props=>props.categoryAttributeCount ? `repeat(${props.categoryAttributeCount}, 80px)` : ``}
        ${props=> 128 - (props.categoryAttributeCount || 0) * 8 }px
        ${props=> 128 - (props.categoryAttributeCount || 0) * 8 }px
        auto;

        .hideOnHover{
            display:none;
        }
        .showOnHover{
            display:flex;
        }
    }
    
    cursor: pointer;
    padding:10px 0px;
    min-height:54px;
    :not(:last-of-type){
        border-bottom:1px solid ${colors.lightGray2};
    }
`;

export const EventRowSection = styled.div`
    // flex: ${props => props.flex || 0};
    /* ${flexCenter}; */
    // gap: 2px;
    padding: 0 8px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    // flex-direction: column;
    ${props=>props.isCurrencySymbolNotSupported ? `align-items: flex-end` : ``};
    ${props=>props.hiddenOverflow ? `overflow:hidden` : ``};
`;

export const CellText = styled.div`
    font-size:13px;
    color:${colors.darkGray1};
    line-height:14px;
`;

export const EventPredictedAmount = styled.div`
    color:${colors.gray2};
    font-size:12px;
    line-height:14px;
    display:flex;
    align-items:center;
    margin-top:2px;
`;

export const EventAmountAndLinkIconWWrapper = styled.div`
    display:flex;
    //justify-content:flex-start;
    align-items:center;
    gap:6px;
`;

export const EventAmount = styled.div`
    color: ${props => props.isDistribution ? colors.inflow2 : colors.darkGray1  };
    font-weight: ${props => props.isDistribution ? 500 : 400  };
    font-size:14px;
    line-height:16px;
`;

export const BaseAdditionalAmountData = styled.div`
    font-size: 12px;
`;

export const EventAtrSubText = styled.div`
     color:${colors.gray2};
     font-size:14px;
    line-height:16px;
`;

export const ArrowDownWrapper = styled.div`
    ${flexCenter};
    width: 12px;
    height: 12px;
    background: ${colors.lightGray2};
    border-radius: 24px;
    margin: 0px 4px;
    position:absolute;
    right:0px;
    top:6px;
`;

export const EventRemarks = styled.div`
    overflow:hidden;
    display:inline-block; 
    white-space: nowrap;
    text-overflow:ellipsis;
    max-width:100%;
    /* width:100%; */
    
    font-size:12px;
    color:${colors.darkGray1};
    line-height:14px;
    ${props=>props.hovered ? `align-self:center` : ``};
`;

export const EventHoverColumn = styled.div`
    display:flex;
    width:100%;
    padding-right:4px;
    /* background-color:lavender;
    height:5px; */
`;

export const HoverColumnRemarksWrapper = styled.div`
    overflow:hidden;
    display: flex;
`;

export const HoverColumnActionsWrapper = styled.div`
    flex:1;
    padding:0 8px 0 16px;
    display:flex;
    align-items:center;
    justify-content:flex-end;
`;

export const ActionButtonWrapper = styled.div`
    background: ${props=>props.isApprove ? colors.darkGray1 : '#F1F4F8'};
    color: ${props=>props.isApprove ? '#fff' : colors.darkGray1};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    border-radius: 30px;
    height:28px;
    display:flex;
    gap:4px;
    ${flexCenter};
    :not(:first-of-type){
        margin-left:8px;
    }
    ${props=>props.isOnlyIcon ? `
        width:28px;
        min-width:28px;
    ` : `
        padding:0 8px;
    `} 
`;

// export const ApproveButtonWrapper = styled.div`
//     height:28px;
//     display:flex;
//     ${flexCenter};
// `;

export const EventRowClockIconWrapper = styled.div`
    padding-left:4px;
    display:flex;
    ${flexCenter};
    svg {
        width:11px;
        height:11px;
        circle {
            stroke: ${colors.gray2}
        }
        path {
            stroke:${colors.gray2}
        }
    }
`;


export const EventRowIconWrapper = styled.div`
    background-color: ${props => props.isCanceled ? '#FCEEEB' : ( props.hasHappened ? '#000' : '#ACB1BB')};
    border-radius: 40px;
    display: grid;
    place-items: center;
    width: 24px;
    height: 24px;
    z-index: 1;
    path {
        fill: ${props=>props.isCanceled ? '#A32509' : 'white'};
        stroke: ${props=>props.isCanceled ? '#A32509' : 'white'};
    }
`;

export const EventRowSectionMainTxt = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
`;

export const HoldingsBadgeSquare = styled.div`
    margin-left: 4px;
    width: 8px;
    height: 10px;
    background-color: ${colors.holdingsBadgeColor};
    border-radius: 0px; // 2px 0px 0px 2px;
`;

export const HoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid ${colors.holdingsBadgeColor};
`;

export const EventRowSectionMainTxtPredictedText = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-decoration:line-through;
    color:gray;
    padding:0 4px;
`;

export const EventRowSectionAltTxt = styled.div`
    width: 100%;
    font-size: 14px;
    line-height: 14px;
`;

export const EventRowActionBtnWrapper = styled.div`
    position: relative;
    display: grid;
    place-items: center;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
`;

export const ThreeDotsIconWrapper = styled.div`
    display: grid;
    place-items: center;
    cursor: pointer;
    padding: 12px 4px;
`;

const EventRowBadge = styled.div`
    ${flexCenter};
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    gap: 4px;
    padding: 2px 6px;
    border-radius: 4px;
    align-self:flex-end;
    margin-right:12px;
    svg{
        width: 14px;
        min-width:14px;
        height: 14px;
    }
    ${views.tablet}{
        ${props=>props.mobileBox ? `
            width:26px;
            height:26px;
            border-radius: 6px;
        ` : `
    
        `}
    }
    
`;

export const ActualEventRowBadgeWrapper = styled(EventRowBadge)`
    background-color: #E2F0FA;
    color: #494DAE;
    svg{
        path{
            fill: #494DAE;
        }
    }
`;

export const TargetEventRowBadgeWrapper = styled(EventRowBadge)`
    color: #E48A04;
    background-color: #FAF1E4;
    svg{
        circle{
            stroke: #E48A04;
        }
        path{
            stroke: #E48A04;
        }
    }
`;

export const OverdueEventRowBadgeWrapper = styled(EventRowBadge)`
    background-color: #FAE4E4;
    color: #E43A04 ;
    svg{
        width: 13px;
        min-width:13px;
        height: 13px;
        path{
            fill: #E43A04;
        }
    }
`;

export const CanceledEventRowBadgeWrapper = styled(EventRowBadge)`
    background-color: #F7F8F9;
    color: #9097A3 ;
    svg{
        circle{
            stroke: #9097A3;
        }
        path{
            stroke: #9097A3;
        }
    }
`;


export const EventPreviewWrapper = styled.div`
    position: relative;
    display: grid;
    place-items: center;
    background: white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.04);
    margin-top: 20px;
    width: 450px;
    height: 102px;
    `;

export const EventTransactionIconWrapper = styled.div`
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    left: calc(50% - 20px);
    z-index: 1;
    border-radius: 40px;
    background-color: ${props=>props.bgColor || 'white'};
    width: 40px;
    height: 40px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.16));
    .approveIcon {
        width: 24px;
        height: 24px;
    }
    path {
        fill: white;
        stroke: white;
    }
    `;

export const EventTransactionIcon = styled.div`
    border-radius: 40px;
    width: 40px;
    height: 40px;
    background-image: url("data:image/png;base64,${props=>props.logoUrl}");
    background-size: contain;
    background-position: center;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.16));
    `;

export const EventTransactionValue = styled.div`
    margin-top: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    `;
export const EventTransactionDate = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #ACB1BB;
    `;
export const EventTransactionDescription = styled.div`
    font-size: 12px;
    line-height: 16px;
    `;

export const EventPreviewWrapperCutout = styled.div`
    position: absolute;
    bottom: -5px;
    width: 100%;
    height: 5px;
    background-image: url("${props => props.img}");
    background-repeat: repeat-x;
    `;

export const EventRowDateWrapper = styled.div`
    display: flex;
    ${props=>props.isHidden ? 'visibility: hidden;' : '' }
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    font-size: 12px;
    font-weight: 500;
    text-transform:uppercase;
    line-height:16px;
    
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #DDE3EB;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 1;
    `;

export const EventRowIndicationAndDateWrapper = styled.div`
    display:flex;
    align-items:center;
`;

export const IndicationWrapper = styled.div`
    margin:0 6px 0 4px;
    width:8px;
`;

export const IndicationCircle = styled.div`
    width:8px;
    height:8px;
    border-radius:16px;
    background-color:${prop=>prop.isSuggestion ? '#738EF1' : '#FF7B7B'};
`;

export const EventRowShortnameMonth = styled.div``;

export const EventRowFullYear = styled.div``;



export const EventRowLinkedTransactionIconWrapper = styled.div`
    width:15px;
    min-width:15px;
    height:15px;
    background-color: ${props => props.isSuggested ? '#738EF1' : props.isDistribution ? colors.inflow2 : colors.darkGray2  };
    border-radius:50%;
    display:flex;
    ${flexCenter};
    svg {
        width:12px;
        height:12px;
        path {
            fill: #ffffff;
        }
    }
`;

export const EventRowCanceledTxt = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 4px;
    background-color: #FCE6E6;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #912121;
    `;

    export const HiddenDiv = styled.div`
        height: 0px;
        width: 100%;
        background-color: #fff;
    `;

export const BackToTodayButton = styled.div`
    ${flexCenter};
    color: #fff;
    position: fixed;
    gap: 6px;
    padding: 4px 16px 4px 12px;
    bottom: 20px;
    /* left: 650px; */
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    background-color: ${colors.darkGray1};
    box-shadow: ${shadows.toggleShadow};
    cursor: pointer;
    svg{
        ${props=>props.isJumpDown ? `transform: rotate(180deg)` : null}
    }
    z-index:2;
`;

export const ExclamationIconWrapper = styled.div`
        display: grid;
        place-items: center;
        position: absolute;
        top: -10px;
        right: -10px;
        width:18px;
        height:18px;
        border-radius: 50%;
        background-color: ${colors.redNotification};
    `;

export const TransactionSuggestionIconWrapper = styled.div`
        display: grid;
        place-items: center;
        position: absolute;
        top: -10px;
        right: -10px;
        width:18px;
        height:18px;
        border-radius:50%;
        background-color: #82AAFA;
`;

export const EventRowSuggestionBugIndication = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:6px; 
    margin:-8px;
    height: 32px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 9, 224, 0.04), 0px 0px 3px rgba(8, 61, 197, 0.16);
    border-radius: 27px;
    padding:0px 14px;
    ${EventRow}:hover & {
        box-shadow:none;
        background:none;
    }
`;

export const EventRowSuggestionBugIndicationText = styled.div`
    font-size: 14px;
    color: #738EF1;
`;

export const AcronymOptionWrapper = styled.span`
    color: ${colors.gray1};
    padding-left: 4px;
`;

export const UnfixedMoneyWrapper = styled.div`
    display: flex;
`;

export const UnfixedMoneyCurrency = styled.span``;

export const MobileEventRowWrapper = styled.div`
    ${flexCenter}
    padding:12px 20px;
`;

export const MobileEventRowIconWrapper = styled.div`
`;

export const MobileEventRowDescriptionWrapper = styled.div`
    flex:1;
    display: flex;
    flex-direction: column;
`;

export const MobileEventRowValueWrapper = styled.div`
    font-size: 15px;
    font-weight: 450;
`;

export const MobileEventTitle = styled.div`

`;

export const MobileEventMoreDetailsWrapper = styled.div`
    display: flex;
    align-items:center;
    color:${colors.gray2};
    font-size:12px;
    line-height:14px;
`
export const MobileEventMoreDetails = styled.div`
    
`;

export const MobileEventMoreDetailsSeparator = styled.div`
    margin:0 8px;
`;