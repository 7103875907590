import styled from "@emotion/styled";
import { flexCenter } from "../../styles/combos.styles";
import { colors } from "../../styles/theme.styles";

export const ItemLogoWrapper = styled.div`
    height:26px;
    width:26px;
    border-radius: 50%;
    margin-right:12px;
    img{
        border-radius: 50%;
        height: ${props=>props.logoImgSize || '26px'};
        width: ${props=>props.logoImgSize || '26px'};
        object-fit: contain;
        background-color: #fff;
        
    }
    ${props=>props.customStyles ? props.customStyles : ``}
`;

export const ItemIconWrapper = styled.div`
    height:26px;
    width:26px;
    min-width:26px;
    border-radius: 23px;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    svg{
        width: ${props=>props.iconSize || 16}px;
        height:${props=>props.iconSize || 16}px;
    }
    path{
        fill:#fff;
    }
    ${props=>props.customStyles ? props.customStyles : ``}
`;

export const ContainerSvgWrapper = styled.div`
    ${flexCenter};
    border-radius: 23px;
    margin-right: 12px;
    svg {
        width: 26px;
        height: 26px;
    }
    ${props=>props.customStyles ? props.customStyles : ``}
`;

export const LogoPlaceHolder = styled.div`
    ${flexCenter}
    height:26px;
    width:26px;
    min-width: 26px;
    background-color: ${props=>props.crypto ? '#FBD384' : '#B3E8CF'};
    font-weight: 500;
    color: ${colors.darkGray1};
    border-radius:50%;
    margin-right: 12px;
    svg{
        height:26px;
        width:26px;
    }
    ${props=>props.customStyles ? props.customStyles : ``}
`;
