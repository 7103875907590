import React from 'react'
import { TickersListEEmptyStateText, TickersListEmptyStateTitle, TickersListEmptyStateWrapper } from './AssetTickersTab/AssetsTickersTab.styles';
import { ReactComponent as NoTickersConnectedAccountIcon } from './icons/no-tickers-connected-account-icon.svg';


export const TickersListEmptyState = () => {
  return (
    <TickersListEmptyStateWrapper>
      <NoTickersConnectedAccountIcon />
      <TickersListEmptyStateTitle>Holdings are unavailable</TickersListEmptyStateTitle>
      <TickersListEEmptyStateText>Your account works fine, but Vyzer is unable to retrieve your account holdings.</TickersListEEmptyStateText>
    </TickersListEmptyStateWrapper>
  )
}
