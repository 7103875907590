import moment from "moment";
export const privateLoanOverviewData = {
    value: {title:'Current asset worth', subTitle: 'This will be reflected in your net worth'},
    stats : [
        {title:'Interest rate', subTitle : 'Annually' , dataKey: 'interest' , type : 'perc' },
        {
            title:'Remaining Time', dataKey: 'remainingMonths', type: 'date', 
            valueFn: (itemData) => { return itemData.remainingMonths ? ( itemData.remainingMonths + (itemData.remainingMonths === 1 ? ' Month' : ' Months')) : '--'},
            subTitleFn : (itemData) => {
                return itemData.expectedEndDate ? 'End Date: ' + moment(itemData.expectedEndDate).format('MMM, yyyy') : 'Missing end date'
            },  
        },
        {title:'Total Cash Lent', subTitle : 'Original loan amount' , dataKey: 'contributions', multipleValues: true  ,
        // childs:[
        //     {title:'Net exposure', subTitle : 'Cash in risk' , dataKey: 'cashInRisk' },
           
        // ]
        },
        {title:'Total Income', subTitle : 'Total cash received to date' ,dataKey: 'distributions' , multipleValues: true, showTargetActual: true, 
        hideAboveBelow: true,isActualAboveTargetPositive: true,
        // {title:'Total Income', subTitle : 'Total cash received to date' ,dataKey: 'distributionsTimeFrames' , multipleValues: true,
        // childs:[
        //     {title:'Next Receivable',
        //     valueFn: (itemData) => { return itemData.nextReceivableMonths ? ( itemData.nextReceivableMonths + (itemData.nextReceivableMonths === 1 ? ' Month' : ' Months')) : '--'},
        //     subTitleFn : (itemData) => {
        //         return itemData.nextReceivable ? 'Expected: ' + moment(itemData.nextReceivable).format('MMM, yyyy') : 'Missing repayment info'
        //     },
        //     }
        // ]
        }
    ],
    visuals : []
}

export const p2pOverviewData = {
    value: {title:'Current Balance', lastUpdate: true},
    stats : [
        {title:'Performance', subTitle: 'ROI', type: 'perc', dataKey:'roi', multipleValues:true, hasMetricBars: true},
        {},
        {title:'Total Income', dataKey: 'distributions' , multipleValues: true, showTargetActual: true,hideAboveBelow: true, isActualAboveTargetPositive: true,},
        // {title:'Total Income', dataKey: 'distributionsTimeFrames' , multipleValues: true},
        {title:'Total Contribution', subTitle : 'Including all contributions' , dataKey: 'contributions', multipleValues: true }
    ],
    visuals : []
}
