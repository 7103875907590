import React , { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom'

import { postData } from '../../../../common/utils/network.utils';
import { LogoLoader } from '../../../../common/components/LogoLoader/LogoLoader';
import { PageWrapper } from '../../../layout/components/Layout.styles';




export function SaltEdgeRedirectComponent(props) {

  // const [publicToken,setPublicToken] = useState('')
  // const { connection_id } = useParams();
  const connection_id = new URLSearchParams(useLocation().search).get('connection_id');
  // console.log(connection_id);

  const [isSendingRequest,setIsSendingRequest] = useState(true);
  // const [connectedInstitutions,setConnectedInstitutions] = useState([]);
  // const [assetCategories,setAssetCategories] = useState([]);
  
    useEffect(() => {
        // console.log('REA', ready, open.name );
        
        // console.log('Here' , connection_id) ;
        if (!connection_id) {
          window.close();
        }
        //
        postData('salt-edge/institutions/'+connection_id)
        .then(res=>{
            // console.log("YO", res);
            window.close();
            setIsSendingRequest(false);
            
            // const data = res.data;
            // setConnectedInstitutions(data.connectedInstitutions);
            // setAssets(data.assets);
            // setLiabilities(data.liabilities);
            // setAssetCategories(data.assetCategories);
            
        }).finally(()=>{
          window.close();
            // setIsLoadingInitData(false);
        })
      
       
       return () => {
         //cleanup
       }
  }, [connection_id]);


  

  return (<PageWrapper style={{alignItems:'center'}}>
    

  
    { isSendingRequest ? 
              <LogoLoader wbg/>
               : <div onClick={()=>{window.close()}}>Done</div>
      }
   </PageWrapper>)
}

