import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../styles/combos.styles";
import { colors, shadows } from "../../../../styles/theme.styles";

export const Wrapper = styled.div`
    width: 100%;
    /* background: #fff;
    box-shadow: 0px 0.2px 2px rgba(0, 0, 0, 0.2), 0px 1px 4px #E5E9F2; */
    border-radius: 9px;
    display:flex;
    flex-direction:column;
    /* max-height:340px; */
    padding:16px 0px 0px;
`;

const columns = {
    date: '96px',
    eventType: '100px',
    quantity: '123px',
    cost: '100px',
    pricePerCoin: '120px',
    remarks: '105px',
}

export const TableRowSizes = styled.div`
    display: grid;
    grid-template-columns: ${columns.date} ${columns.eventType} ${columns.quantity} ${columns.cost} ${columns.pricePerCoin} ${columns.remarks};
    // date: 96px eventType 80px cost: 104px quantity: 104px pricePerCoin: 80px remarks: 100px;
    padding: 8px 0;
    /* grid-template-columns: 130px 120px  ${props=>props.isWithHoldings ? '240' : '140'}px auto; */
    /* gap:8px; */
`;

export const TableHeaderRow = styled(TableRowSizes)`
    /* border-bottom:1px solid ${colors.gray3}; */
    /* grid-template-columns: 140px 90px 200px 128px; */
    /* padding:4px 0px 8px; */
`;

export const HeaderCell = styled.div`
    font-size:12px;
    color: ${colors.darkGray2}; // #9D9D9D;
    padding-left: 11px;
    :nth-of-type(2), :nth-of-type(4){
        padding-left: 8px;
    }
`;

export const TableScrollContent = styled.div`
    /* overflow-y:auto; */
    .ant-picker , .ant-input{
        border-radius:0px;
        &:hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
            box-shadow: none;
        }
    }

    .ant-input-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }

    .ant-dropdown-trigger{
        font-size: 14px;
        padding-left: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 0px;
    }
`;

export const TableBottomActions = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    padding-top:8px;
    gap: 26px;
`;

export const CreateButton = styled.div`
    ${flexCenter};
    cursor: pointer;
    color: #fff;
    background-color: ${colors.darkGray1};
    padding: 5px 16px 5px 8px;
    border-radius:8px;
    font-size:14px;
    font-weight: 500;
    max-height: 32px;

    svg{
        path{
            stroke: #fff;
        }
        margin-right:4px;
    }
    .button-text{
        margin-left:4px;
    }
`;

export const RowWrapper = styled(TableRowSizes)`
    /* grid-template-columns: 140px 90px 200px 128px; */
    // padding:${props=>props.isEdit ? '4' : '8'}px 16px;
    padding: 8px 0px;
    position:relative;
    :not(:last-of-type){
        border-bottom:1px solid ${colors.gray3};
    }
    :last-of-type{
        padding-bottom:0px;
    }
    min-height:48px;
    /* &:hover{
        background-color:${colors.lightGray1}; // #2A2A2E;
    } */
`;

export const RowCell = styled.div`
    font-size:14px;
    line-height:24px;
    /* color: #FFFFFF; */
    color: ${colors.darkGray1};
    align-items: center;
    display:flex;

    //:not(:first-of-type, :last-of-type) {
    //    .ant-input{
    //        border-left: none;
    //    }
    //    .ant-picker{
    //        border-left: none;
    //    }
    //    .ant-dropdown-trigger{
    //        border-left: none;
    //    }
    //
    //}
    :first-of-type {
        .ant-input{
            border-radius: 4px 0 0 4px;
        }
        .ant-picker{
            border-radius: 4px 0 0 4px;
        }
        .ant-dropdown-trigger{
            border-radius: 4px 0 0 4px;
        }
    }
    .ant-input{
        height:32px;
        font-size: 14px;
        &.inputEntryError{
            border: 1px solid #FF7676;
        }
    }
    .ant-picker{
        font-size: 14px;
    }
    .ant-picker > .ant-picker-input > input{
        font-size: 14px;
    }
    .ant-input-affix-wrapper{
        height:32px;
        .ant-input {
            font-size: 14px;
            height:22px;
        }
    }

    ${props=>!props.isEditMode ? `
    .ant-input-affix-wrapper {
        .ant-input {
            font-size: 14px;
            background-color: transparent;
            border-color: transparent;
        }
    }
    ` : null}
`;

export const InputWithBadgeWrapper = styled.div`
    position:relative;
    width:${props=>props.isWithHoldings ? '50' : '100'}%;
    .ant-input{
        font-size: 14px;
    }
`

export const HoldingsBadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    position:absolute;
    left:8px;
    top: 50%;
    transform: translateY(-50%);
    
`;

export const HoldingsBadgeText = styled.div`
    background-color: ${colors.holdingsBadgeColor};
    width:8px;
    height:12px;
    border-radius: 2px 0px 0px 2px;
`;

export const HoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid ${colors.holdingsBadgeColor};
`;

export const RowActionsCell = styled.div`
    /* position:absolute; */
    align-items: center;
    display:flex;
    position:absolute;
    left:100%;
    top:10px;
    /* right:0px;
    top:4px; */
    opacity:0;
    // visibility:hidden;
    ${RowWrapper}:hover &{
        opacity:1;
        // visibility:visible;
    }
    &:hover{
        opacity:1;
        // visibility:visible;
    }
    
`;

export const ActionButtonWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px;
    svg{
        height: 24px;
        path {
            stroke: #000;
        }
    }
`;

export const TotalsRow = styled.div`
    display: grid;
    grid-template-columns: ${columns.eventType} ${columns.quantity} ${columns.cost} ${columns.pricePerCoin} ${columns.remarks};
    // 90px 106px 104px 104px 80px 100px;
    padding: 8px 0;
    flex: 1;
    border-top: 1px solid ${colors.gray3};
`;

export const PlusIconWrapper = styled.div`
    ${flexCenter};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    box-shadow: ${shadows.toggleShadow};
`;

export const TotalInvestmentHeader = styled.div`
    color: ${colors.darkGray2};
    font-size: 12px;
    width: 100%;
`;
export const TotalInvestmentAmount = styled(TotalInvestmentHeader)``;
export const TotalNumberOfCoinsHeader = styled(TotalInvestmentHeader)``;
export const TotalNumberOfCoinsAmount = styled(TotalInvestmentHeader)``;

export const cryptoTradingTableSelectButtonCustomStyles = (isEditMode) => {
    return {
        boxShadow: 'none',
        fontWeight: 'normal',
        height: '32px',
        ...(isEditMode ? {} : {
            backgroundColor:'transparent',
            borderColor: 'transparent',
        })
        //backgroundColor:'red'
    }
}