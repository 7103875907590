import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalCloseIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { navigateToUrl } from '../../../../common/utils/navigation.utils';
import { useStore } from '../../../app/data/root.store';
import { ActionButton, CancelPlanModalFooter, ModalAltText, ModalBgWrapper, ModalCloseButtonWrapper, ModalContent, ModalHeader } from './CancelPlanModal.styles';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { ROUTES } from '../../../../common/constants/routes.const';

export const CancelPlanModal = (props) => {
  const { onBackDropClick } = props;
  const history = useHistory();
  const { userStore, billingStore } = useStore();
  const { mixpanelTrack } = useAnalytics()

  useEffect(() => {
    mixpanelTrack('Popup Viewed', {
      destination: ROUTES.wealthOverview.url,
      location: 'Billing Page',
      title: `Explore Vyzer for an Extra 90 Days Risk-Free!`,
      cta_text: 'Keep exploring',
      type: 'Cancel Subscription Modal',
      is_demo: userStore.isDemoMode
    })

    // return () => {}
  }, [mixpanelTrack, userStore.isDemoMode])

  const handleActionClick = (action) => {
    mixpanelTrack('Popup Clicked', {
      click_type: 'CTA click',
      destination: ROUTES.wealthOverview.url,
      location: 'Billing Page',
      title: `Explore Vyzer for an Extra 90 Days Risk-Free!`,
      cta_text: `${action === 'cancel' ? 'I want to cancel' : 'Keep exploring'}`,
      type: 'Cancel Subscription Modal',
      is_demo: userStore.isDemoMode
    })
    if (action === 'cancel') {
      billingStore.setShowCancelSurveyModal(true);
    } else {
      navigateToUrl(history, ROUTES.wealthOverview.url)
    }
    onBackDropClick && onBackDropClick();
  }

  return (<Observer>{() => (
    <BaseModal width={'700px'} height={'auto'} borderRadius={8} onBackDropClick={onBackDropClick}>

      <ModalHeader>
        <ModalCloseButtonWrapper onClick={onBackDropClick}>
          <ModalCloseIcon />
        </ModalCloseButtonWrapper>
        {/* Explore Vyzer for an Extra 90 Days Risk-Free! 🎉 */}
        {userStore.isFreeTier ? `This service is FREE forever 🎉` : `Are you sure you want to
        cancel your membership?`}

        <ModalAltText>
          {/* Take advantage of our 90-day money-back guarantee policy and explore Vyzer without any risk. If you don't find value in our services, you can simply get your money back. There's nothing to lose. */}
          {userStore.isFreeTier ? `Vyzer lets you streamline portfolio tracking, gain valuable
            insights, and make data-driven investment decisions.
            
            We welcome you to take advantage of this free forever plan to
            explore Vyzer without risk. You can upgrade your plan anytime
            for a comprehensive service and more features.`
            :
            `We will downgrade your account to read-only mode, but your data will remain
              secure, and you can export it anytime.
              Vyzer will continue to send you portfolio notifications and sync your accounts.`}
        </ModalAltText>
      </ModalHeader>
      <ModalContent>
        <ModalBgWrapper />
      </ModalContent>

      <CancelPlanModalFooter>
        <ActionButton onClick={() => { handleActionClick('cancel') }}>I want to cancel</ActionButton>
        <ActionButton onClick={handleActionClick} backgroundColor={'#2565E7'} whiteText>Keep exploring</ActionButton>
      </CancelPlanModalFooter>

    </BaseModal>
  )}</Observer>)
}


