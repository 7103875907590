import React , { useState } from 'react';
import { Observer} from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import { ButtonsWrapper, CategoryStepWrapper,
    CategoryButtonDescription, RealEstateButtonTitle,
    RealEstateInnerTitleWrapper, RealEstateInnerTitle
    // SyncOptionsButtonsWrapper 
} from '../AddItem.styles'
import {
    // ThunderboltOutlined , 
    LeftOutlined,
    //  LoadingOutlined
     } from '@ant-design/icons';
import { CategoryButton } from '../CreateItemModal.styles';
// import { PlaidLink } from '../../../connected-institutions/components/plaid-link/PlaidLink';
// import { notification } from "antd";
// import { SaltedgeLink } from '../../../connected-institutions/components/saltedge-link/SaltedgeLink';
// import {SyncOnlinePlatformButton} from '../SyncOnlinePlatformButton';



const ignoreCreateItemManuallyLimitByCategoryId = [39]
export function AddItemCategoryStep(props) {

    const {isOnBoarding} = props;
    const {createItemStore, metadataStore ,  uiStore, userStore} = useStore();
    // const testErrorRef = useRef('');
    // const [showSyncButtonOptions, setShowSyncButtonOptions] = useState(true)
    const handleCategoryItemOnClick = (categoryId) => {
        if (userStore.isDemoOrReadOnlyMode){
            uiStore.setShowDemoLimitationModal(true);
        } else {
            if (!ignoreCreateItemManuallyLimitByCategoryId.includes(categoryId) && userStore.isCreateItemManuallyLimited){
                createItemStore.setShowAddManualItemLimitModal(true);
            } else {
                createItemStore.selectCategory(categoryId);
            }
            
        }
    }
    // useEffect(() => {
    //     setShowConnectAndSubTitle(true);
    //     // setShowSyncButtonOptions(false);
    //     return () => {}
    // }, [createItemStore.classId]);

    return (<Observer>{()=>(
        
        <CategoryStepWrapper isOnBoarding={isOnBoarding}> 
        
            {/* <h2>Add {metadataStore.assetClassesObj[createItemStore.classId].title}</h2> */}
            {/* {isOnBoarding && <>
                <SelectCategoryClassTitle>Add your {createItemStore.selectedClass.title}</SelectCategoryClassTitle>
                {createItemStore.selectedClassCategories.length > 1 && 
                    <SelectCategoryClassSubTitle>
                        {createItemStore.selectedClassCategories.map(cc=>cc.title).join(', ')}
                    </SelectCategoryClassSubTitle>
                }
            </>} */}
            <ButtonsWrapper>
            {createItemStore.classId === 4 ? <RealEstateSelectCategory selectCategory={(categoryId)=>{handleCategoryItemOnClick(categoryId) }} /> : <>
            { metadataStore.categories.filter(category=>category.classId === createItemStore.classId && !category.isHidden).map((item)=>(
                    <CategoryButton key={item.id} onClick={()=>{handleCategoryItemOnClick(item.id)}}>
                        {item.addItemTitle || item.title}
                        {item?.description ? <CategoryButtonDescription>{item.description}</CategoryButtonDescription> : null}
                        {item?.customDescription ? customCategoryDescriptions[item.id] : null}
                    </CategoryButton>
            ))}
            </> }
            </ButtonsWrapper>
            {/* <WizardButton onClick={()=>{createItemStore.selectType(ADD_ITEM_TYPE.LIABILITY)}}>Liability</WizardButton> */}
        </CategoryStepWrapper>
    )}</Observer>)
}

const customCategoryDescriptions = {
    40: <CategoryButtonDescription>Includes a <span style={{fontWeight:700}}>cash value</span> component that you can borrow against or withdraw.</CategoryButtonDescription>
}

function RealEstateSelectCategory(props) {
    const [step,setStep] = useState(0);
    const [isSole,setIsSole] = useState(false);
    return (
    <div>
        { step === 0 && <>
            <CategoryButton onClick={()=>{setIsSole(true); setStep(1)}}>
                <RealEstateButtonTitle>Sole ownership</RealEstateButtonTitle>
                <CategoryButtonDescription>You're the only investor in this property</CategoryButtonDescription>
            </CategoryButton>
            <CategoryButton onClick={()=>{setIsSole(false);setStep(1)}}>
                <RealEstateButtonTitle>Syndication</RealEstateButtonTitle>
                <CategoryButtonDescription>Others invested with you in this property</CategoryButtonDescription>
            </CategoryButton>
        </>}
        { step === 1 && <>
            <RealEstateInnerTitleWrapper>
            <RealEstateInnerTitle onClick={()=>{setIsSole(false);setStep(0)}}> <LeftOutlined  />  {isSole ? 'Sole ownership' : 'Syndication'}</RealEstateInnerTitle>
            <CategoryButtonDescription>Select Holding Type</CategoryButtonDescription>
            </RealEstateInnerTitleWrapper>
            <CategoryButton onClick={()=>{props.selectCategory(isSole? 15 : 16 )}}>
                <RealEstateButtonTitle>Rental</RealEstateButtonTitle>
                <CategoryButtonDescription>An investment with an ongoing cash flow</CategoryButtonDescription>
            </CategoryButton>
            <CategoryButton onClick={()=>{props.selectCategory(isSole? 17 : 18 )}}>
                <RealEstateButtonTitle>Development</RealEstateButtonTitle>
                <CategoryButtonDescription>An investment with no ongoing cash flow that will be cashed out in the future</CategoryButtonDescription>
            </CategoryButton>
            {isSole && <CategoryButton onClick={()=>{props.selectCategory(21)}}>
                <RealEstateButtonTitle>Residence</RealEstateButtonTitle>
                <CategoryButtonDescription>An investment without any current or future expected income</CategoryButtonDescription>
            </CategoryButton>}
            
        </>}
        
    </div>
    )
}