import styled from "@emotion/styled/macro";
import { colors, views } from "../../../../../../../common/styles/theme.styles";
import { flexCenter } from "../../../../../../../common/styles/combos.styles";

export const EventPredictedAmount = styled.div`
    color:${colors.gray2};
    font-size:12px;
    line-height:14px;
    display:flex;
    align-items:center;
    margin-left:${props=>props.marginLeft || 0}px;
    ${views.desktop}{
        margin-top:2px;
    }
`;

export const EventRowClockIconWrapper = styled.div`
    padding-left:4px;
    display:flex;
    ${flexCenter};
    svg {
        width:11px;
        height:11px;
        circle {
            stroke: ${colors.gray2}
        }
        path {
            stroke:${colors.gray2}
        }
    }
`;

export const EventLabelWithPredictedAmountWrapper = styled.div`
    display: flex;
`;