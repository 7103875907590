import React, { useState } from 'react';
import { AssetClassIcon } from '../../../modules/assets/components/AssetClassIcon';
import { ContainerSvgWrapper, ItemIconWrapper, ItemLogoWrapper, LogoPlaceHolder } from './ItemLogo.styles';

export const ItemLogo = ({ item, customStyles = {} , iconSize, logoImgSize }) => {
  const [isCorruptedImage, setIsCorruptedImage] = useState(false);

  return (
    item.logoBase64 ? <ItemLogoWrapper customStyles={customStyles} logoImgSize={logoImgSize} ><img alt={item.connectedInstitutionName} src={'data:image/png;base64,' + item.logoBase64} /></ItemLogoWrapper> :
      item.logoUrl && !isCorruptedImage ? <ItemLogoWrapper customStyles={customStyles} logoImgSize={logoImgSize} ><img alt={item.connectedInstitutionName} src={item.logoUrl} onError={() => { setIsCorruptedImage(true) }} /></ItemLogoWrapper> :
        item.categoryId === 39 ?
          ( item.stockDetails?.imageUrl ? 
            <ItemLogoWrapper customStyles={customStyles} logoImgSize={logoImgSize} ><img alt={item.connectedInstitutionName} src={item.stockDetails?.imageUrl} onError={() => { setIsCorruptedImage(true) }} /></ItemLogoWrapper> : 
            <LogoPlaceHolder customStyles={customStyles} >
            {(item.title && item.title !== '') ? item.title.charAt(0).toUpperCase() : (item.symbol && item.symbol !== '') ? item.symbol.charAt(0).toUpperCase() : (item.type && item.type !== '') ? item.type.charAt(0).toUpperCase() : ''}
          </LogoPlaceHolder>) : 

item.categoryId === 36 ?
( item.cryptoCoinDetails?.imageUrl ? 
  <ItemLogoWrapper customStyles={customStyles} logoImgSize={logoImgSize} ><img alt={item.connectedInstitutionName} src={item.cryptoCoinDetails?.imageUrl} onError={() => { setIsCorruptedImage(true) }} /></ItemLogoWrapper> : 
  <LogoPlaceHolder customStyles={customStyles} crypto >
  {(item.title && item.title !== '') ? item.title.charAt(0).toUpperCase() : (item.symbol && item.symbol !== '') ? item.symbol.charAt(0).toUpperCase() : (item.type && item.type !== '') ? item.type.charAt(0).toUpperCase() : ''}
</LogoPlaceHolder>) :

          item.isContainer && !item.isConnected ?
            <ContainerSvgWrapper customStyles={customStyles} >
              <ContainerSvg />
            </ContainerSvgWrapper> :
            <ItemIconWrapper customStyles={customStyles} color={item.categoryClass.color} iconSize={iconSize}>
              <AssetClassIcon name={item.categoryClass.icon} />
            </ItemIconWrapper>
  )

}

const ContainerSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.1359 7.02897C14.1749 7.02897 12.5852 8.61867 12.5852 10.5797C12.5852 12.5407 14.1749 14.1304 16.1359 14.1304C18.0969 14.1304 19.6866 12.5407 19.6866 10.5797C19.6866 8.61867 18.0969 7.02897 16.1359 7.02897ZM10.5562 10.5797C10.5562 7.4981 13.0543 5 16.1359 5C19.2175 5 21.7156 7.4981 21.7156 10.5797C21.7156 13.6612 19.2175 16.1593 16.1359 16.1593C13.0543 16.1593 10.5562 13.6612 10.5562 10.5797ZM9.87971 17.8505C7.91872 17.8505 6.32902 19.4402 6.32902 21.4012C6.32902 23.3621 7.91872 24.9518 9.87971 24.9518C11.8407 24.9518 13.4304 23.3621 13.4304 21.4012C13.4304 19.4402 11.8407 17.8505 9.87971 17.8505ZM4.30005 21.4012C4.30005 18.3196 6.79815 15.8215 9.87971 15.8215C12.9613 15.8215 15.4594 18.3196 15.4594 21.4012C15.4594 24.4827 12.9613 26.9808 9.87971 26.9808C6.79815 26.9808 4.30005 24.4827 4.30005 21.4012ZM18.5029 21.4012C18.5029 19.4402 20.0926 17.8505 22.0536 17.8505C24.0146 17.8505 25.6043 19.4402 25.6043 21.4012C25.6043 23.3621 24.0146 24.9518 22.0536 24.9518C20.0926 24.9518 18.5029 23.3621 18.5029 21.4012ZM22.0536 15.8215C18.9721 15.8215 16.474 18.3196 16.474 21.4012C16.474 24.4827 18.9721 26.9808 22.0536 26.9808C25.1352 26.9808 27.6333 24.4827 27.6333 21.4012C27.6333 18.3196 25.1352 15.8215 22.0536 15.8215Z" fill="#2F3642" />
    </svg>
  )
}