import styled from "@emotion/styled/macro";
import { colors, shadows } from '../../../common/styles/theme.styles';
import { Input } from 'antd';

export const LoginPasswordInput = styled(Input.Password)`
    height: ${props=>props.changepasswordview ? '34px' : '33px'};
    // border: 1px solid #DFDFDF;
    font-size: ${props=>props.changepasswordview ? '15px' : '14px'};
    padding: ${props=>props.changepasswordview ? '8px 16px' : '0 12px'};
    background-color:#FFFFFF;
    box-shadow: ${props=>props.changepasswordview ?  '0px 0px 1px rgba(0, 0, 0, 0.3)' : shadows.inputShadow};
    border-radius: ${props=>props.changepasswordview ? '6px' : '4px'};
    margin-bottom: ${props=>props.changepasswordview ? '12px' : '4px'};

    height:44px;
    border: 1px solid ${colors.gray3};
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    // ${props=>props.changepasswordview ? 'border: none;' : ''}

    // &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled){
    //     border: 1px solid transparent;
    // };
    
    &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
            border: 1px solid ${colors.gray3};
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
    }

    &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus-within{
            border: 1px solid ${colors.darkGray1};    
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
    }
`;

export const PasswordRuleWrapper = styled.div`
    display:flex;
    flex-direction:row;
    /* flex-wrap: ${props=>props.changepasswordview ? 'initial' : 'wrap'}; */
    ${props=>props.changepasswordview ? 'justify-content: space-around;' : ''}     
        justify-content: space-between;
        padding-top: 12px;
        height: 55px;
        flex-wrap: initial;
`;

export const PasswordRule = styled.div`
    color:${props => props.isValid ? 'black' : '#b9b9b9'};
    flex:0.5;
    min-width: 50%;
    padding: 4px 16px;
    position: relative;
    font-size:12px;
    line-height:18px;
    .anticon{
        position:absolute;
        left:0px;
        top:6px;
    }
    :nth-of-type(2n){
        padding-right:0px
    }
`;

export const NewPasswordRule = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const RuleMark = styled.div`
    display: flex;
    align-items: center;
    font-weight: ${props=>props.fontWeight ? props.fontWeight : 500};
    font-size: ${props=>props.fontSize ? props.fontSize : '22'}px;
    line-height: ${props=>props.lineHeight ? props.lineHeight : '26'}px;
    color: ${colors.gray2};
`;

export const RuleText = styled(RuleMark)`
    font-size: 12px;
    line-height: 14px;
    ${props=>props.isValid && `color:#2565E7`}
`;