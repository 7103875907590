import { getData, postData } from '../../../common/utils/network.utils';

export const fetchUserPlanData = async () => {
    return (await getData('payments/billing-information')).data;
}

export const fetchBillingUrlData = async () => {
    return (await getData('payments/billing-portal')).data;
}

export const fetchPricingTable = async () => {
    return (await getData('payments/pricing-table-data')).data;
}

export const fetchSubscriptionUrl = async (priceId, cancelRedirectUrl) => {
    return (await postData('payments/create-checkout-session',{priceId, cancelRedirectUrl}));
}

export const setStarterPlan = async () => {
    return (await postData('payments/starter-plan'));
}

export const createDeepLink = async (flowType , price_id) => {
    return (await postData('payments/create-deep-link', {flowType , price_id}));
}