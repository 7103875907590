import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Observer } from 'mobx-react';
import { Dropdown } from 'antd';
// import moment from 'moment';
import {
  PieWrapper, PieTextContent, PieTextLabel, PieTextBigValue,
  PieValueContainer
  // , ClassIconWrapper, ClassTitleText, ClassSumText
} from './WealthPieChart.styles';
// import { Doughnut } from 'react-chartjs-2';
import { useStore } from '../../../../app/data/root.store';
// import { AssetClassIcon } from '../../../../assets/components/AssetClassIcon';
// import { LiabilityClassIcon } from '../../../../liabilities/components/LiabilityClassIcon';
import { displayMoneyValue } from '../../../../../common/utils/number-display.utils';
// import { customTooltip } from './chartTooltips';

import { CountAnimation } from '../../../../../common/components/count-animation/CountAnimation';
import { MenuItemWrapperWealthPie, MenuWrapperWealthPie } from '../../../../../common/components/DropDownMenu/DropDownMenu.styles';
import { ValueChangeDirectionBox, ValueChangesButton, ValueChangeText, ValueChangeTextWrapper } from '../../../pages/asset/components/AssetPage.styles';
import { ArrowDownIcon, DirectionUp, CheckmarkIcon } from '../../../../../assets/icons/common/common-icons';
import { isNullOrUndefined } from '../../../../../common/utils/object.utils';
import { DotsLoader } from '../../../../../common/components/Loaders/DotsLoader.styles';
import { ChartComponent } from '../../../../../common/components/charts/ChartComponent';
import { allocationByFilterOptions } from '../../../../allocations/data/allocations.const';
import { MagicBoxAnimatedIconGrey } from '../../../../magic-box/components/MagicBoxAnimatedIconGrey';

const changesDataPoints = [
  'previous month',
  'previous quarter',
  'previous year'
];

export function WealthAllocationPieChart(props) {

  const { wealthStore, uiStore, filtersStore } = useStore();
  let history = useHistory();
  const { bigTextSize } = props;
  const [hoverData, setHoverData] = useState({ datasetIndex: null, index: null, isHoveredOnce: false });
  const [showChangesSince, setShowChangesSince] = useState(0);
  const containerRef = useRef();
  const textRef = useRef();
  const [textSize, setTextSize] = useState(bigTextSize ?? 32);
  // const optionsRef = useRef(null);
  const [chartOptions, setChartOptions] = useState({ cutout: '90%' });
  // const getTooltipItem = (dataSetIndex, dataIndex) => {
  //     // console.log("mm",wealthStore.isLibabilityClassView)
  //     // const assetGroup = wealthStore.assetsGroups[dataIndex];
  //     const relevantGroup = (dataSetIndex === 1 ? ( dataIndex < 2 ? wealthStore.liabilitiesGroups[dataIndex] : null ) : wealthStore.assetsGroups[dataIndex]) ;
  //     return relevantGroup ? {
  //       label: relevantGroup.title,
  //       value: relevantGroup.totalUsdValue,
  //       isIncognito: uiStore.isIncognitoMode,
  //       show: true
  //     } : {
  //       show: false
  //     };
  //   }

  // const someCallBack = useCallback(() => {
  //   console.log("Here");
  //   setHoveredClass(null);
  // },[setHoveredClass]);

  const getChartOptions = useCallback(() => {
    return {
      maintainAspectRatio: true,
      // animation:false,
      // tooltips: { enabled: false },
      // tooltips: customTooltip(getTooltipItem),
      cutout: '90%',
      onHover: (event, chartElement) => {
        // console.log(event,chartElement);
        // const canvasPosition = getRelativePosition(e, chart);
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';

        // if (chartElement[0] && chartElement[0]._index === 4){
        //   someCallBack();
        // }
        if (chartElement.length) {
          const relevantGroup = wealthStore.chartAssetsDataForTooltips[chartElement[0].index];
          // if (datasetIndex === 1){
          //     const liabilities =  wealthStore.chartAssetsDataForTooltips.filter(g => g.isLiability || g.usdValue < 0);
          //     if (dataIndex < liabilities.length) {
          //       relevantGroup = liabilities[dataIndex];
          //     }
          // } else {
          //   relevantGroup = wealthStore.chartAssetsDataForTooltips.filter(g => !g.isLiability && g.usdValue > 0)[dataIndex];
          // }
          setHoverData(relevantGroup ? {
            // datasetIndex,
            // dataIndex,
            show: true,
            title: relevantGroup.title,
            value: relevantGroup.usdValue,
            isHoveredOnce: true
          } : { show: false, isHoveredOnce: true })
        } else {
          setHoverData({
            show: false,
            isHoveredOnce: true
            // datasetIndex:null,
            // dataIndex:null
          })
        }
        // setSomeNumber(someNumber+1);
      },

      onClick: (event, chartElement) => {

        const relevantGroup = wealthStore.chartAssetsDataForTooltips[chartElement[0].index];
        const allocationFilterOptions = allocationByFilterOptions[wealthStore.allocateBy];
        let refreshData = true;
        if (wealthStore.allocateBy === 'customClasses') {
          filtersStore.handleSelectCustomClass(relevantGroup.filterValue);
        } else if (wealthStore.allocateBy === 'customSubClasses') {
          filtersStore.handleSelectSubClassFromAllocation(relevantGroup.customClassId, relevantGroup.filterValue);
        }
        else if (allocationFilterOptions) {
          if (allocationFilterOptions.filterType === 'list') {
            filtersStore.setListOptionSelectedFromAllocation(allocationFilterOptions.filterPropName, relevantGroup.filterValue);
          }
        } else {
          refreshData = false
        }
        if (refreshData) {
          filtersStore.setAppliedFiltersFromSelection();
          wealthStore.refreshWealthData(false, history);
        }
      },
      plugins: {
        tooltip: { enabled: false },
      }
    }
  }, [
    filtersStore, history,
    wealthStore])

  useEffect(() => {
    setChartOptions(getChartOptions());
    return () => {
    }
  }, [getChartOptions, wealthStore.allocateBy])

  useEffect(() => {
    const containerElement = containerRef.current;
    const textElement = textRef.current;

    if (containerElement && textElement) {
      const availableWidth = containerElement.clientWidth - 80;
      const textWidth = textElement.scrollWidth;

      setTextSize(textWidth > availableWidth ? (availableWidth / textWidth) * 32 : 32);
    }
  }, []);

  return (<Observer>{() => (
    <PieWrapper>

      <ChartComponent key="catCharts" type='doughnut' data={wealthStore.chartAssetsData} options={chartOptions} height={props.height} />




      {/* <Doughnut 
        // getElementAtEvent={onDataPointClick}
        // redraw={false}
        data={wealthStore.chartAssetsData}
        height={props.height}
        options={chartOptions} /> */}
      <PieTextContent contentTop={props.contentTop} ref={containerRef}>

        <PieTextLabel>{hoverData.show ? hoverData.title : `Total ${wealthStore.isMainResults ? 'net' : 'items'} worth`}</PieTextLabel>

        <PieTextBigValue isIncognito={uiStore.isIncognitoMode} fontSize={textSize} ref={textRef}>
          {uiStore.isIncognitoMode ? displayMoneyValue(hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue, 'USD', true) :
            // displayMoneyValue(hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue, 'USD', false)
            <PieValueContainer>
              <CountAnimation style={{ fontSize: `${textSize}px` }} currency={'USD'} value={hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue} noAnim={hoverData.isHoveredOnce} />
              {wealthStore.hasLLMInProcessItem && <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(241,243,245)' />}
            </PieValueContainer>
          }
          <span style={{ position: 'absolute', color: 'transparent', left: 0, top: 0 }}>{displayMoneyValue(hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue, 'USD', false)}</span>
        </PieTextBigValue>
        {(wealthStore.isMainResults || filtersStore.isSingleClassFilterWithAllCategoriesSelected) && wealthStore.selectedClassHistoryPoints && wealthStore.selectedClassHistoryPoints.length ? <>
          {wealthStore.selectedClassHistoryPoints && wealthStore.selectedClassHistoryPoints.length > 0 && wealthStore.selectedClassHistoryPoints[showChangesSince] ?
            <>
              <Dropdown
                overlay={
                  ValueChangeDropDown({
                    showChangesSince,
                    selectChangesSince: (index) => { setShowChangesSince(index) }
                  })
                }
                getPopupContainer={() => document.getElementById('wealthPageContent')}
                trigger={['click']} placement="bottomCenter" >
                <ValueChangesButton>Since {changesDataPoints[showChangesSince]}<ArrowDownIcon /></ValueChangesButton>
                {/* <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        Hover me <DownOutlined />
                                    </a> */}
              </Dropdown>

              <ValueChangeTextWrapper>
                {!isNullOrUndefined(wealthStore.selectedClassHistoryPoints[showChangesSince].change) && parseFloat(wealthStore.selectedClassHistoryPoints[showChangesSince].change.toFixed(2)) !== 0 &&
                  <ValueChangeDirectionBox isDown={wealthStore.selectedClassHistoryPoints[showChangesSince].change < 0}>
                    <DirectionUp />
                  </ValueChangeDirectionBox>
                }
                <ValueChangeText change={!isNullOrUndefined(wealthStore.selectedClassHistoryPoints[showChangesSince].change) ? parseFloat(wealthStore.selectedClassHistoryPoints[showChangesSince].change.toFixed(2)) : 0}>{
                  wealthStore.selectedClassHistoryPoints[showChangesSince].change ?
                    parseFloat(wealthStore.selectedClassHistoryPoints[showChangesSince].change.toFixed(2))
                    : 0
                }%</ValueChangeText>
              </ValueChangeTextWrapper>
            </> : wealthStore.isLoadingLastChangesData ? <DotsLoader style={{ marginTop: '24px', marginBottom: '18px' }} /> : null}
        </> : null}
      </PieTextContent>
    </PieWrapper>
  )}</Observer>)
}


const valueChangeOptions = [
  { value: 0, text: 'Previous month' },
  { value: 1, text: 'Previous quarter' },
  { value: 2, text: 'Previous year' },
]


function ValueChangeDropDown(props) {
  const { showChangesSince, selectChangesSince } = props
  return (
    <MenuWrapperWealthPie>
      {valueChangeOptions.map(item => (
        <MenuItemWrapperWealthPie key={item.value} onClick={() => { selectChangesSince(item.value) }}>{item.text} {showChangesSince === item.value && <CheckmarkIcon />}</MenuItemWrapperWealthPie>
      ))}
    </MenuWrapperWealthPie>
  )
}