import { LoadingOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { QuestionMarkIcon } from '../../../../assets/icons/common/common-icons';
import { postData } from '../../../../common/utils/network.utils';
import { useStore } from '../../../app/data/root.store';
import { AUTH_STEPS } from '../../../user/data/user.store';
import { AntPopoverButton, BackButtonWrapper, ForgotPasswordStepWrapper, InputWrapper, LoginFormButton, PopoverWrapper, SignUpStepHeader, SignUpStepSubHeader, VerCodeInput, VerificationForm, WrongCodeMsg } from '../LoginPage.styles';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';

export const EmailVerificationStep = observer(() => {
  const { userStore, uiStore } = useStore();
  // eslint-disable-next-line
  const [message, setMessage] = useState('');
  const [showWrongCodeMsg, setWrongCodeMsg] = useState(false);
  const verificationCodeInputRef = useRef();
  const [verificationCodeInput, setVerificationCodeInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { email } = userStore.authFields;
  const { trackEvent } = useAnalytics();


  const verify = async () => {
    if (!verificationCodeInputRef.current.value) return;

    trackEvent('CTA Clicked', {
      cta_text: "Submit verification code",
      location: "Code verification page",
      destination: userStore.prevAuthStep === AUTH_STEPS.LOG_IN_STEP ? 'Welcome page' : 'Set password page',
      type: "Button",
      url: window.location.href
    });

    setIsLoading(true);
    try {
      let endpoint, postDataParams;
      if ([AUTH_STEPS.LOG_IN_STEP,AUTH_STEPS.INVITATION].includes(userStore.prevAuthStep)) {
        endpoint = "user/verify";
        postDataParams = {
          code: verificationCodeInputRef.current.value
        };
      } else {
        endpoint = "user/pre-register-verify";
        postDataParams = {
          code: verificationCodeInputRef.current.value,
          email
        };
      }

      const data = await postData(endpoint, postDataParams);
      if (data.message) {
        setMessage(Array.isArray(data.message) ? data.message.join(' , ') : data.message);
      }

      if (data.success) {
        localStorage.removeItem('wfc');
        localStorage.removeItem('regMail');
        if ([AUTH_STEPS.LOG_IN_STEP,AUTH_STEPS.INVITATION].includes(userStore.prevAuthStep)) {
          window.location.reload();
        } else {
          userStore.setAuthFields('password', '');
          userStore.setAuthFields('verifyCode', verificationCodeInputRef.current.value);
          userStore.setAuthStep(AUTH_STEPS.SET_PASSWORD_STEP);
        }
      } else if (!data.success) {
        setWrongCodeMsg(true);
      }
    } catch (error) {
      setWrongCodeMsg(true);
    } finally {
      setIsLoading(false);
    }
  };

  const verKeyPress = (e) => {
    if (e.keyCode === 13) {
      verify();
    }
  }

  const verificationCodeChanged = (e) => {
    setVerificationCodeInput(e.target.value);
    if (e.target.value.length === 6) {
      verify();
    }
  }

  const getPopoverTrigger = () => {
    return uiStore.onboardingScreenView === 'normal' ? 'hover' : 'click';
  }

  return (
    <>
      <BackButtonWrapper onClick={() => { userStore.setAuthStep( userStore.prevAuthStep ) }}><BackArrowSvg /> Back</BackButtonWrapper>
      <ForgotPasswordStepWrapper>
        <SignUpStepHeader>Let’s verify your email</SignUpStepHeader>
        <SignUpStepSubHeader>Enter the code we sent to {email}</SignUpStepSubHeader>
        <VerificationForm>
          <InputWrapper>
            <VerCodeInput
              autoFocus
              placeholder="••••••" ref={verificationCodeInputRef}
              id="codeInput" type="text" name="code" onKeyDown={verKeyPress} onChange={verificationCodeChanged} autoComplete="off" />
          </InputWrapper>
          {showWrongCodeMsg &&
            <WrongCodeMsg>This verification code is expired or incorrect.</WrongCodeMsg>
          }
          <LoginFormButton onClick={verify} disabled={!verificationCodeInput}>
            {isLoading ? <LoadingOutlined /> : 'Submit verification code'}
          </LoginFormButton>
        </VerificationForm>
        {/* <FormMessage haveMessage={message !== ''}>
          {message}
        </FormMessage> */}
        <PopoverWrapper>
          <Popover trigger={getPopoverTrigger()} overlayClassName={`darkThemePopover ${uiStore.onboardingScreenView}`} title={`Check your spam or promotion email filter to make sure that emails coming from Vyzer aren't being placed in those folders. If they are, add the @vyzer.co email domain to your email account's safe senders list.`}><AntPopoverButton><QuestionMarkIcon />Didn’t receive a code?</AntPopoverButton></Popover>
        </PopoverWrapper>
      </ForgotPasswordStepWrapper>
    </>
  )
})

const BackArrowSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6803 16.0137L10.347 12L13.6803 7.98633" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}