import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../../common/styles/combos.styles";
import { colors } from "../../../../../common/styles/theme.styles";

export const UnlockMagicBoxModalWrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  padding: 24px;
  position: relative;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
`;

export const ModalContent = styled.div`
  display:flex;
  flex-direction:column;
  width: 100%;
  background-color: #000;
  border-radius: 8px;
  padding: 20px 0;
  gap: 24px;
`;

export const UnlockMagicBoxModalTitle = styled.div`
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 43px;
  color: ${colors.darkGray1};
`;

export const UnlockMagicBoxModalSubtitle = styled.div`
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${colors.darkGray1};
  text-align: center;
  white-space: pre-line;
`;

export const ContentTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 24px;
`;

export const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
`;

export const ContentPrompt = styled.div`
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.gray2};
  white-space: pre-line;
`;

export const ActionButton = styled.div`
    background-color: ${props => props.black ? colors.darkGray1 : colors.lightGray1} ;
    color: ${props => props.black ? '#fff' : '#000'};
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: ${props => props.isBack ? '10px 20px' : '8px 16px'};
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 1s;
    ${props => props.withIcon ? `gap: 6px` : ``};
    ${props => props.isBack ? `margin-right: auto` : ``};
    ${props => props.disabled ? `opacity: 0.2; cursor: default` : ``};
    /* ${props => props.black ? `` : `border: 1px solid ${colors.gray3}`}; */
    border: 1px solid ${colors.gray3};
    svg{
      path{
        stroke:#fff;
      }
    }
`;