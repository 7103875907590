import { Switch } from "antd";
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import { displayMoneyValue } from '../../../../common/utils/number-display.utils';
import { isNullOrUndefinedOrZeroOrEmptyString } from '../../../../common/utils/object.utils';
import { useStore } from '../../../app/data/root.store';
import { ConnectedInstitutionAccountDisableModal } from './ConnectedInstitutionAccountDisableModal';
import { ConnectedInstitutionModal } from './ConnectedInstitutionModal';
import { AccountAltItemTitleLink, AccountAltSeparator, AccountItemAltHeader, AccountItemHeader, AccountItemHeaderWrapper, AccountItemWrapper } from './ConnectedInstitutionsList.styles';
import { useHistory } from "react-router-dom";
import { ItemLinkArrowIcon } from "./institutionPanel.commons";
import { navigateToUrl } from "../../../../common/utils/navigation.utils";
import { getUrlDisplayName } from "../../../../common/utils/string.utils";
import { ConnectedInstitutionAccountDeleteNotFoundModal } from "./ConnectedInstitutionAccountDeleteNotFound";
import { CommonBadge } from "../../../../common/components/CommonBadge/CommonBadge";
import { ModifiedTooltip } from "../../../../common/components/ModifiedTooltip/ModifiedTooltip";
import { isCashAccount, isCreditCard, isLiability } from "../../utils/akoya.utils";

export const AkoyaInstitutionAccountRow = (props) => {
  const { account, inst, isIncognito, isLoginRequired } = props;
  const { connectedInstitutionsStore, userStore, billingStore, metadataStore, itemStore, wealthStore } = useStore();
  const [showConnectedInstitutionModal, setShowConnectedInstitutionModal] = useState(false);
  const [showConnectedInstitutionAccountDisableModal, setShowConnectedInstitutionAccountDisableModal] = useState(false);
  const [showConnectedInstitutionAccountNotFoundDeleteModal, setShowConnectedInstitutionAccountNotFoundDeleteModal] = useState(false);
  let history = useHistory();

  const handleUpdateMetadata = () => {
    metadataStore.fetchData();
  }

  const handleSwitchOnClick = (isChecked, e) => {
    if (isChecked) {
      if (userStore.isDemoOrReadOnlyMode) {
        e.stopPropagation();
        billingStore.setShowPricingTableModal(true);
      }
      else {
        setShowConnectedInstitutionModal(true);
      }
    } else {
      setShowConnectedInstitutionAccountDisableModal(true);
    }
  }


  const handleItemAssetLinkClick = (categoryId, itemId) => {
    const astClass = metadataStore.classesObj[metadataStore.categoriesObj[categoryId].classId];
    navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(astClass.title)}/${itemId}`);
  }

  const handleCreateItem = (categoryId, connectedAccountName, connectedAccountHoldings) => {
    const updatedAccount = { ...account, name: connectedAccountName, holdings: isNullOrUndefinedOrZeroOrEmptyString(connectedAccountHoldings) ? 100 : connectedAccountHoldings, action: { categoryId: categoryId, createNew: true } };
    connectedInstitutionsStore.connectFromPanel(inst.id, updatedAccount, 'akoya', () => {
      metadataStore.fetchData();
      wealthStore.getLastChanges(true);
    });
    setShowConnectedInstitutionModal(false);
  }

  const handleDisableItem = (action) => {
    if (action === 'manual') {
      connectedInstitutionsStore.disconnectAccount(account.accountId, 'akoya');
      setShowConnectedInstitutionAccountDisableModal(false);
    }

    else if (action === 'archive') {
      itemStore.closeReopenAsset(account.astId, null, false, () => {
        connectedInstitutionsStore.disconnectAccountLocally(account.accountId, 'akoya');
        handleUpdateMetadata();
        setShowConnectedInstitutionAccountDisableModal(false);
      });
    }

    else if (action === 'delete') {
      itemStore.deleteAsset(account.astId, false, () => {
        connectedInstitutionsStore.disconnectAccountLocally(account.accountId, 'akoya');
        handleUpdateMetadata();
        wealthStore.refreshWealthData(false, history);
        setShowConnectedInstitutionAccountDisableModal(false);
      });
    }
    itemStore.itemUpdated(account.astId)
  }

  const handleDisableNotFoundItem = (action) => {
    if (action === 'manual') {
      connectedInstitutionsStore.disconnectAccount(account.accountId, 'akoya');
      setShowConnectedInstitutionAccountDisableModal(false);
    }

    else if (action === 'archive') {
      itemStore.closeReopenAsset(account.astId, null, false, () => {
        connectedInstitutionsStore.disconnectAccountLocally(account.accountId, 'akoya');
        handleUpdateMetadata();
        setShowConnectedInstitutionAccountDisableModal(false);
      });
    }

    else if (action === 'delete') {
      itemStore.deleteAsset(account.astId, false, () => {
        connectedInstitutionsStore.disconnectAccountLocally(account.accountId, 'akoya');
        handleUpdateMetadata();
        wealthStore.refreshWealthData(false, history);
        setShowConnectedInstitutionAccountDisableModal(false);
      });
    }
    itemStore.itemUpdated(account.astId)
    connectedInstitutionsStore.fetchAkoyaAccounts(inst.id, true);
  }

  return (
    <Observer>{() => (
      <AccountItemWrapper key={account.id} isLoginRequired={isLoginRequired}>
        {/* <AccountItemIconWrapper>
          <div style={{ backgroundColor: 'lightgrey', borderRadius: '50%', width: '24px', height: '24px' }} />
        </AccountItemIconWrapper> */}
        <AccountItemHeaderWrapper>
          <AccountItemHeaderWrapper>
            <AccountItemHeader>
              {account.accountName}
              {account.notFound && <CommonBadge badgeType={'notFound'} />}
            </AccountItemHeader>
            <AccountItemAltHeader>
              {account.astId ? <>
                <span>{metadataStore.categoriesObj[account.astCategoryId].title}</span>
                <AccountAltSeparator />
                <AccountAltItemTitleLink onClick={() => { handleItemAssetLinkClick(account.astCategoryId, account.astId) }} >{account.astTitle}<ItemLinkArrowIcon /></AccountAltItemTitleLink>
              </> : account.accountType}
            </AccountItemAltHeader>
          </AccountItemHeaderWrapper>
        </AccountItemHeaderWrapper>
        <div style={{ paddingRight: '16px' }}>{displayMoneyValue(account.balance, account.currency, isIncognito, 100)}</div>
        <ModifiedTooltip placement={'left'} title={userStore.isActionBlockedByRole('institutionsAccountToggle') ? userStore.actionBlockedByRolePrompt : ''}>
          <Switch
            disabled={isLoginRequired || account.notFound || userStore.isActionBlockedByRole('institutionsAccountToggle')}
            checked={(account.astId && !account.notFound) ? true : false}
            onChange={(isChecked, e) => handleSwitchOnClick(isChecked, e)}></Switch>
        </ModifiedTooltip>
        {showConnectedInstitutionModal && <ConnectedInstitutionModal
          isLiability={isLiability(account)}
          isCreditCard={isCreditCard(account)}
          isCashAccount={isCashAccount(account)}
          account={account}
          institution={inst}
          onClose={() => setShowConnectedInstitutionModal(false)}
          handleOk={(categoryId, connectedAccountName, connectedAccountHoldings) => handleCreateItem(categoryId, connectedAccountName, connectedAccountHoldings)}
          amount={account.balance}
          amountCurrency={account.currency}
          isIncognito={isIncognito}
          provider={'akoya'}
        />}
        {showConnectedInstitutionAccountDisableModal && <ConnectedInstitutionAccountDisableModal
          onClose={() => setShowConnectedInstitutionAccountDisableModal(false)}
          astTitle={account.astTitle}
          astCategoryId={account.astCategoryId}
          title={account.accountName}
          institution={inst}
          amount={account.balance}
          amountCurrency={account.currency}
          isIncognito={isIncognito}
          provider={'akoya'}
          handleOk={handleDisableItem}
          isActionLocked={userStore.isDemoOrReadOnlyMode} />}
        {showConnectedInstitutionAccountNotFoundDeleteModal && <ConnectedInstitutionAccountDeleteNotFoundModal
          onClose={() => setShowConnectedInstitutionAccountNotFoundDeleteModal(false)}
          account={account}
          title={account.accountName}
          handleOk={handleDisableNotFoundItem}
          isActionLocked={userStore.isDemoOrReadOnlyMode} />}
      </AccountItemWrapper>
    )}</Observer>
  )
}
