import styled from "@emotion/styled/macro";
import { views } from "../../../common/styles/theme.styles";

export const AppWrapper = styled.div`
  height: 100%;
  // height: 100vh;
  transition: grid-template-columns 0.3s;
  overflow-x: hidden;
  ${props => props.appLayout ? `
    display: grid;
    grid-template-columns: ${props.isSmallMenu ? 80 : 260}px 1fr ${props.withPushMenu ? `260px` : ` 0px`};
    grid-template-rows: auto 52px 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "banner banner banner"
      "logo content pushMenu"
      "sidebar content pushMenu";
  ` :``}

  ${props => props.emptySideMenuLayout ? `
    display: grid;
    grid-template-columns: 80px 1fr;
  `:``}
`

export const RightSidePushMenuWrapper = styled.div`
  grid-area: pushMenu;
  overflow-x: hidden;
  ${views.tablet}{
    display: none;
  }
`;

export const Banner = styled.div`
  grid-area: banner;
`

export const LogoAreaWrapper = styled.div`
  grid-area: logo;
  background: #1b1c1e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1em;
  padding-left:28px;
`

export const LogoAndBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:8px;
`;

export const MinimizeButtonWrapper = styled.div`
  height: 20px;
  width: 11px;
  transform:rotate(${props=>props.isOpen ? 90 : 270}deg);
  margin-right: 16px; 
  transition: transform 0.3s;
  cursor: pointer;
`;

export const PageWrapper = styled.div`
  position:relative;
  display: flex;
  justify-content: center;
  padding: 45px 45px 0;
  background-color: #f2f3f5;
  ${props=>props.withHeader ? `` : `
    grid-area:content;
  `}
  overflow:auto;
  align-items: flex-start;
  min-height:100%;
  & > div {
    width: 100%;
    max-width: 880px;
    margin-bottom:45px;
  }

  ${views.tablet}{
    padding:0;
    & > div {
      margin-bottom:0px;
    }
    padding-bottom: ${props=>props.noPaddingBottom ? '0px' : 'calc( env(safe-area-inset-bottom) + 64px )'};
  }

  ${props => props.isPopup ? `
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
  ` : ``}
`