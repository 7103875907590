import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CactusIcon, InstitutionPlaceholderIcon, PlusIcon, SearchIcon } from '../../../assets/icons/common/common-icons';
import { CommonBadge } from '../../../common/components/CommonBadge/CommonBadge';
import { DotsLoader } from '../../../common/components/Loaders/DotsLoader.styles';
import { LogoLoader } from '../../../common/components/LogoLoader/LogoLoader';
import { PrimaryButtonWithIcon, SecondaryButton } from '../../../common/components/buttons/NewButtons.styles';
import { debounce } from '../../../common/utils/functions.utils';
import { navigateToUrl } from '../../../common/utils/navigation.utils';
import { isNullOrUndefinedOrEmptyString } from '../../../common/utils/object.utils';
import { useStore } from '../../app/data/root.store';
import { PlaidLink } from '../../connected-institutions/components/plaid-link/PlaidLink';
import { SaltedgeLink } from '../../connected-institutions/components/saltedge-link/SaltedgeLink';
import { errorNotification, infoNotification } from '../../layout/components/Notifications/notifications';
import { ReactComponent as SyncAccountIcon } from '../assets/sync-account-icon.svg';
import { SYNC_MODES } from '../data/create-item.store';
import { ActionButton, ActivationFlowInstitutionEmptyStateMessage, ActivationFlowInstitutionEmptyStateWrapper, AddButtonText, AddIconWrapper, AddManuallyButton, AddManuallyButtonWrapper, CleanSearchAddManuallyMessage, InstitutionExistsFooterWrapper, InstitutionExistsWrapper, InstitutionItemSwitcherWrapper, InstitutionItemWrapper, InstitutionLogoWrapper, InstitutionName, InstitutionTextWrapper, InstitutionUrl, InstitutionsResultsListWrapper, ItemRowLoader, ListAndLoaderWrapper, LoadMoreLoaderWrapper, LogoPlaceHolder, SaltEdgeLoadingFooterWrapper, SaltEdgeLoadingRowWrapper, SaltEdgeLoadingWrapper, SearchInput, SearchInputAndResultsWrapper, SearchingLoaderWrapper, SyncTabPrompt, SyncWhiteIconWrapper, TextPromptWrapper, VipSyncRowWrapper, HighlightedSearchText } from './steps/addItemInstitutionStep.styles';
import { cleanURL } from '../../../common/utils/string.utils'
import { AkoyaLink } from '../../connected-institutions/components/akoya-link/AkoyaLink';


const inputSearchDebounce = debounce((cb) => { cb() }, 1000);
const scrollBottomDebounce = debounce((cb) => { cb() }, 200);
const syncTabPromptTooltipTitle = <>
  Please go to your <span style={{ fontWeight: '700' }}>{`My account > Synced accounts`}</span>. <br />
  You’ll find there your institution accounts, please go inside and turn ON the accounts in order to reflect them in your wealth
</>;

export const InstitutionSearchInputAndResults = (props) => {
  const { isActivationFlow, setVipSyncStepIndex, onInstitutionSyncClose } = props;
  const { createItemStore, metadataStore, uiStore, userStore } = useStore();
  const [inputVal, setInputVal] = useState('');
  const listRef = useRef();
  const plaidErrorRef = useRef(null);
  const plaidDoneRef = useRef(false);
  const [selectedProviderInst, setSelectedProviderInst] = useState({});
  const [existingInstitution, setExistingInstitution] = useState(false);
  const [inspectedInstitution, setInspectedInstitution] = useState(null);
  const [isDuplicatedAccount, setIsDuplicatedAccount] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (createItemStore.manualSearchStr) {
      setInputVal(createItemStore.manualSearchStr);
      createItemStore.setManualSearchStr(null);
    } else {
      const instNameParam = new URLSearchParams(window.location.search).get('inst');
      const searchFor = !isNullOrUndefinedOrEmptyString(instNameParam) ? instNameParam : '';
      setInputVal(searchFor);
      createItemStore.searchForInst(searchFor);
    }
    // }
    const cleanURL = window.location.pathname;
    window.history.replaceState({}, '', cleanURL);
    return () => {
      createItemStore.setIsSaltedgeInProcess(false);
    }
  }, [createItemStore]);


  const handleChange = (e) => {
    setInputVal(e.target.value);
    createItemStore.setIsSearchingInstitutions(true);
    inputSearchDebounce(() => {
      createItemStore.searchForInst(e.target.value);
    })
  }

  const onScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        scrollBottomDebounce(() => {
          createItemStore.checkForLoadingMore();
        })
      }
    }
  };

  const handleClearExisting = () => {
    setInputVal('');
    setExistingInstitution(false);
    setInspectedInstitution(null);
    createItemStore.searchForInst('');
  }

  const plaidConnectSuccess = (data) => {
    if (data.error) {
      if (data.error === 'Institution exist') {
        if (plaidDoneRef.current === false) {
          plaidErrorRef.current = "INST_EXIST";
        } else {
          infoNotification('Institution already connected');
          plaidDoneRef.current = false;
        }
      } else {
        errorNotification('Something went wrong');
      }
    }
    else {
      createItemStore.setPlaidAccounts(data.accounts, data.institution, metadataStore);
      createItemStore.setShowModal(true);
    }
    createItemStore.setIsPlaidInProcess(false);
  }

  const saltedgeConnectSuccess = (data) => {
    if (data.error) {
      errorNotification('Something went wrong');
    }
    else {
      const { accounts, ...instProp } = data;
      const { institutionName: name, id, logoURL } = instProp
      createItemStore.setSaltedgeAccounts(accounts, { name, id, logoURL }, metadataStore);
      createItemStore.setShowModal(true);
      // createItemStore.setSaltedgeAccounts(data.accounts,{name:data.institutionName,id:data.id},metadataStore);
      //props.afterConnect && props.afterConnect('saltEdge',data.accounts,data.institution);
    }
  }

  const akoyaConnectSuccess = (accounts) => {
    createItemStore.setAkoyaAccounts(accounts, metadataStore);
    createItemStore.setShowModal(true);
  }

  const akoyaConnectError = ({ error }) => {
    createItemStore.setIsAkoyaInProcess(false);
    if (error.code === 3) {
      setIsDuplicatedAccount(true);
    } else {
      errorNotification('Something went wrong');
    }
  }

  const plaidDone = (isExit) => {
    if (plaidErrorRef.current === "INST_EXIST") {
      infoNotification('Institution already connected');
      plaidErrorRef.current = null;
    }
    plaidDoneRef.current = true;
    if (isExit) {
      createItemStore.setIsPlaidInProcess(false);
    }
  }

  const onSaltEdgeLoad = (inst, isInstLoading) => {
    createItemStore.setIsSaltedgeInProcess(isInstLoading);
    setSelectedProviderInst(isInstLoading ? inst : {});
  }

  const onAkoyaLoad = (inst, isInstLoading) => {
    setIsDuplicatedAccount(false);
    createItemStore.setIsAkoyaInProcess(isInstLoading);
    setSelectedProviderInst(isInstLoading ? inst : {});
  }

  const onPlaidLoad = (inst, isInstLoading) => {
    createItemStore.setIsPlaidInProcess(true);
    setSelectedProviderInst(isInstLoading ? inst : {});
  }

  const onCheckInstitutionExistence = (inst) => {
    if (isActivationFlow) return false;
    const existingInstitution = metadataStore.checkInstitutionExistence(inst.institutionId);
    if (existingInstitution) {
      setInspectedInstitution(inst);
    }
    // console.log(existingInstitution);
    setExistingInstitution(existingInstitution);
    return existingInstitution ? true : false;
  }

  const handleReviewSyncedAccountsClick = () => {
    createItemStore.setShowModal(false);
    navigateToUrl(history, `/profile/accounts?inst=${existingInstitution.id}&p=${existingInstitution.connectedProvider}`);
  }

  const handleVipSyncRowClick = (inst) => {
    createItemStore.setSelectedVipSyncInstitution(inst);
    if (isActivationFlow) {
      setVipSyncStepIndex?.(0);
    } else {
      createItemStore.setVipSyncStep();
    }
  }

  const handleBlockAction = () => {
    if (userStore.isDemoOrReadOnlyMode) {
      uiStore.setShowDemoLimitationModal(true);
    } else {
      createItemStore.setShowAddSyncLimitModal(true);
    }
  }

  const handleBlockVip = () => {
    if (userStore.isDemoOrReadOnlyMode) {
      uiStore.setShowDemoLimitationModal(true);
    } else {
      createItemStore.setShowAddVipSyncLimitModal(true);
    }
  }


  return (<Observer>{() => (
    <>
      {/* <InstitutionStepSubTitle>
                {createItemStore.syncMode === SYNC_MODES.INVESTOR ? 
                'Search for any private fund  / operator / sponsor / online account' : 
                'Sync your bank accounts to track cashflow.'}
            </InstitutionStepSubTitle> */}

      <SearchInputAndResultsWrapper hasMaxWidth={!isActivationFlow} isHidden={existingInstitution || createItemStore.isAnyProviderInProcess}>
        <SearchInput type="text" autoFocus
          placeholder={createItemStore.syncMode === SYNC_MODES.INVESTOR ? 'Search a fund / operator / online account' : 'Search a Bank account / Brokerage / any online platform'}
          value={inputVal} onChange={handleChange}
          allowClear
          //ref={searchInputRef} 
          // value={transactionsStore.filtersValues.str} onChange={(e)=>{transactionsStore.setFilterStr(e.target.value)}} 
          prefix={<SearchIcon />} />
        <ListAndLoaderWrapper>
          <InstitutionsResultsListWrapper ref={listRef} onScroll={onScroll}>
            {createItemStore.searchedInstitutions.map(inst => (
              <InstitutionItemSwitcherWrapper key={inst.id}>
                {inst.providerName === 'plaid' &&
                  <PlaidLink
                    institutionId={inst.institutionId}
                    onSuccess={plaidConnectSuccess} 
                    checkInstitutionExistence={() => onCheckInstitutionExistence(inst)}
                    onDone={() => { plaidDone(false) }} onExit={() => { plaidDone(true) }}
                    onConnectionStart={() => onPlaidLoad(inst, true)} >
                    <InstitutionRow item={inst} searchTerm={inputVal} isBlocked={userStore.isDemoOrReadOnlyMode || userStore.isAddSyncInstitutionLimited} onBlockClick={handleBlockAction} />
                  </PlaidLink>
                }
                {inst.providerName === 'saltEdge' &&
                  <SaltedgeLink providerCode={inst.institutionCode} onSuccess={saltedgeConnectSuccess} checkInstitutionExistence={() => onCheckInstitutionExistence(inst)} waitingForResponseCB={(isLoading) => { onSaltEdgeLoad(inst, isLoading) }} loadingComp={
                    <ItemRowLoader ><DotsLoader black /></ItemRowLoader>
                  } >
                    <InstitutionRow item={inst} searchTerm={inputVal} isBlocked={userStore.isDemoOrReadOnlyMode || userStore.isAddSyncInstitutionLimited} onBlockClick={handleBlockAction} />
                  </SaltedgeLink>
                }
                {inst.providerName === 'akoya' &&
                  <AkoyaLink connectorId={inst.institutionId}
                    onSuccess={akoyaConnectSuccess}
                    onError={akoyaConnectError}
                    waitingForResponseCB={(isLoading) => { onAkoyaLoad(inst, isLoading) }}
                    checkInstitutionExistence={() => onCheckInstitutionExistence(inst)}
                    loadingComp={<ItemRowLoader ><DotsLoader black /></ItemRowLoader>}>
                    <InstitutionRow item={inst} searchTerm={inputVal} isBlocked={userStore.isDemoOrReadOnlyMode || userStore.isAddSyncInstitutionLimited} onBlockClick={handleBlockAction} />
                  </AkoyaLink>
                }
                {inst.providerName === 'manual' &&
                  <InstitutionRow item={inst} searchTerm={inputVal} isBlocked={userStore.isDemoOrReadOnlyMode || userStore.isAddSyncInstitutionLimited} onBlockClick={handleBlockAction} onClick={() => { createItemStore.setCategoryStep({ name: inst.name }) }} />
                }
                {inst.providerName === 'vip' &&
                  <VipSyncRow item={inst} searchTerm={inputVal} isBlocked={userStore.isDemoOrReadOnlyMode || userStore.isVipSyncInstitutionLimited} onBlockClick={handleBlockVip} onClick={() => handleVipSyncRowClick(inst)} checkInstitutionExistence={() => onCheckInstitutionExistence(inst)} />
                }
              </InstitutionItemSwitcherWrapper>
            ))}
            {createItemStore.isLoadingMoreInstitutions && <LoadMoreLoaderWrapper>
              <LogoLoader wbg />
            </LoadMoreLoaderWrapper>}
          </InstitutionsResultsListWrapper>
          {createItemStore.isSearchingInstitutions && <SearchingLoaderWrapper >
            <LogoLoader wbg />
          </SearchingLoaderWrapper>}
        </ListAndLoaderWrapper>

        {
          inputVal !== '' &&
          (
            !isActivationFlow ?
              <AddManuallyButtonWrapper onClick={() => { createItemStore.setCategoryStep({ name: inputVal }) }}>
                <AddIconWrapper><PlusIcon /></AddIconWrapper>
                <AddButtonText>Add "{inputVal}" Manually</AddButtonText>
              </AddManuallyButtonWrapper>
              :
              (
                createItemStore.searchedInstitutions.length === 0 &&
                <ActivationFlowInstitutionEmptyStateWrapper>
                  <CactusIcon />
                  <ActivationFlowInstitutionEmptyStateMessage>No results found</ActivationFlowInstitutionEmptyStateMessage>
                </ActivationFlowInstitutionEmptyStateWrapper>
              )
          )
        }
      </SearchInputAndResultsWrapper>

      {inputVal === '' && !isActivationFlow && !existingInstitution && !createItemStore.isAnyProviderInProcess &&
        <CleanSearchAddManuallyMessage>
          <span className='visible-text'>{createItemStore.syncMode === SYNC_MODES.INVESTOR ? `Can't find your investor portal?` : `Can't find your online portal?`}</span>
          <AddManuallyButton onClick={() => { createItemStore.setCategoryStep() }}>Add manually</AddManuallyButton>
        </CleanSearchAddManuallyMessage>
      }

      {(createItemStore.isSaltedgeInProcess || createItemStore.isAkoyaInProcess) && !isActivationFlow ? <SaltEdgeLoadingWrapper>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, paddingTop: '60px' }}>
          <ProviderLoadingRow item={selectedProviderInst} />
          <TextPromptWrapper>
            Syncing your account...
            <br />
            <br />
            This process can take a while. You can keep exploring vyzer, a pop up will shows up when it’s done.
            Meanwhile please <span style={{ color: '#FF0000', fontWeight: '600' }}>don’t close the portal log-in tab</span> in your browser you’ve been in.
          </TextPromptWrapper>
        </div>
        <SaltEdgeLoadingFooterWrapper>
          <ActionButton onClick={onInstitutionSyncClose}>Got it</ActionButton>
          <Tooltip title={syncTabPromptTooltipTitle} placement="top" overlayInnerStyle={{ fontSize: '12px', background: 'rgba(0, 0, 0, 0.9)', borderRadius: '4px' }} overlayStyle={{ minWidth: '350px' }}>
            <SyncTabPrompt>Oops. I closed the syncing tab<InfoCircleFilled style={{ fontSize: '12px', color: '#585D66' }} /></SyncTabPrompt>
          </Tooltip>
        </SaltEdgeLoadingFooterWrapper>
      </SaltEdgeLoadingWrapper> : null}

      {existingInstitution && !createItemStore.isSaltedgeInProcess && !createItemStore.isAkoyaInProcess ? <InstitutionExistsWrapper hasMaxWidth={!isActivationFlow}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, paddingTop: uiStore.isDesktopView ? '60px' : '' }}>
          <InstitutionExistsRow item={inspectedInstitution} isActivationFlow />
          {existingInstitution.connectedProvider === 'vip' ? <TextPromptWrapper>
            {existingInstitution.name} has already been synced<br />
            via the VIP syncing service.
            <br /><br />
            To review the connection status of {existingInstitution.name}'s accounts,<br /> go to the Synced Account page.
          </TextPromptWrapper> :
            isDuplicatedAccount ? <TextPromptWrapper style={{ padding: '40px 24px 0' }}>
              <span style={{ fontWeight: '700', fontSize: '16px', lineHeight: '20px' }}>You've synced the same account you have in Vyzer.</span>
              <br /><br />
              The username and password you've entered <b>match a {existingInstitution.name} account already connected to your Vyzer portfolio.</b><br /><br />
              If you want to sync a different {existingInstitution.name} account, please use the correct username and password.
              {/* Seems like you were trying to sync a new {existingInstitution.name} account, but you entered the same username and password as the account you already have in Vyzer. */}
            </TextPromptWrapper> :
              <TextPromptWrapper>
                You already have accounts from {existingInstitution.name} in your
                <br />
                synced account list.
                <br />
                <br />
                <span style={{ fontWeight: '700', fontSize: '16px', lineHeight: '20px' }}>Are you sure you want to sync another {existingInstitution.name} account?</span>
                <br />
                Please ensure you don’t create duplications
              </TextPromptWrapper>
          }
        </div>
        <InstitutionExistsFooterWrapper>
          <SecondaryButton style={{ padding: '10px 20px', maxHeight: '38px', ...(uiStore.isDesktopView ? {} : { width: '100%', minHeight: '48px', borderRadius: '40px' }) }} onClick={handleClearExisting}>Back</SecondaryButton>
          {uiStore.isDesktopView && <div style={{ flex: 1 }}></div>}
          {inspectedInstitution.providerName === 'plaid' ?
            <PlaidLink onSuccess={plaidConnectSuccess}
              institutionId={inspectedInstitution.institutionId}
              onDone={() => { plaidDone(false) }} onExit={() => { plaidDone(true) }}
              onConnectionStart={() => onPlaidLoad(inspectedInstitution, true)} >
              <SecondaryButton style={{ padding: '10px 20px', maxHeight: '38px', ...(uiStore.isDesktopView ? {} : { width: '100%', minHeight: '48px', borderRadius: '40px' }) }}>Add one more {existingInstitution.name}</SecondaryButton>
            </PlaidLink> :
            inspectedInstitution.providerName === 'saltEdge' ?
              <SaltedgeLink providerCode={inspectedInstitution.institutionCode} onSuccess={saltedgeConnectSuccess}
                waitingForResponseCB={(isLoading) => { onSaltEdgeLoad(inspectedInstitution, isLoading) }}>
                <SecondaryButton style={{ padding: '10px 20px', maxHeight: '38px', ...(uiStore.isDesktopView ? {} : { width: '100%', minHeight: '48px', borderRadius: '40px' }) }}>Add one more {existingInstitution.name}</SecondaryButton>
              </SaltedgeLink> :
              inspectedInstitution.providerName === 'akoya' ?
                <AkoyaLink
                  connectorId={inspectedInstitution.institutionId}
                  onSuccess={akoyaConnectSuccess}
                  onError={akoyaConnectError}
                  waitingForResponseCB={(isLoading) => { onAkoyaLoad(inspectedInstitution, isLoading) }}>
                  <SecondaryButton style={{ padding: '10px 20px', maxHeight: '38px', ...(uiStore.isDesktopView ? {} : { width: '100%', minHeight: '48px', borderRadius: '40px' }) }}>{isDuplicatedAccount ? 'Sync again' : `Add one more ${existingInstitution.name}`}</SecondaryButton>
                </AkoyaLink> : null
          }
          <PrimaryButtonWithIcon style={{ maxHeight: '38px', ...(uiStore.isDesktopView ? {} : { width: '100%', minHeight: '48px', borderRadius: '40px' }) }} onClick={handleReviewSyncedAccountsClick}>Review my synced accounts<ArrowLinkSvg /></PrimaryButtonWithIcon>
          {/* <ActionButton onClick={() => { createItemStore.setShowModal(false) }}>Got it</ActionButton> */}
        </InstitutionExistsFooterWrapper>
      </InstitutionExistsWrapper> : null}
      {createItemStore.isAnyProviderInProcess && isActivationFlow && !existingInstitution ? <ProviderLoadingRow item={selectedProviderInst} isActivationFlow /> : null}
      {createItemStore.isPlaidInProcess ? <SaltEdgeLoadingWrapper><LogoLoader /></SaltEdgeLoadingWrapper> : null}

    </>
  )}</Observer>)
}

function highlightSearchTerm(title, searchTerm) {
  if (searchTerm) {
    const index = title.toLowerCase().indexOf(searchTerm.toLowerCase());
    if (index !== -1) {
      const length = searchTerm.length;
      const prefix = title.substring(0, index);
      const suffix = title.substring(index + length);
      const match = title.substring(index, index + length);
      return (
        <span>{prefix}<HighlightedSearchText>{match}</HighlightedSearchText>{suffix}</span>
      )
    }
  }
  return title;
}

function InstitutionRow({ item, searchTerm, onClick, isBlocked, onBlockClick }) {
  return (<InstitutionItemWrapper onClick={isBlocked ? onBlockClick : onClick} >
    <InstitutionLogoWrapper>
      {item.logoBase64 ? <img alt={item.name} src={'data:image/png;base64,' + item.logoBase64} /> :
        item.logoURL ? <img alt={item.name} src={item.logoURL} /> : <LogoPlaceHolder ><InstitutionPlaceholderIcon /></LogoPlaceHolder>}
    </InstitutionLogoWrapper>
    <InstitutionTextWrapper>
      <InstitutionName>{highlightSearchTerm(item.name, searchTerm)}</InstitutionName>
      <InstitutionUrl>{cleanURL(item.homeURL)}</InstitutionUrl>
    </InstitutionTextWrapper>
  </InstitutionItemWrapper>
  )
}

function ProviderLoadingRow({ item, isActivationFlow }) {
  return (
    <SaltEdgeLoadingRowWrapper isFullWidth={isActivationFlow}>
      <InstitutionLogoWrapper>
        {item.logoBase64 ? <img alt={item.name} src={'data:image/png;base64,' + item.logoBase64} /> :
          item.logoURL ? <img alt={item.name} src={item.logoURL} /> : <LogoPlaceHolder ><InstitutionPlaceholderIcon /></LogoPlaceHolder>}
      </InstitutionLogoWrapper>
      <InstitutionTextWrapper style={{ flex: 1 }}>
        <InstitutionName>{item.name}</InstitutionName>
        <InstitutionUrl>{cleanURL(item.homeURL)}</InstitutionUrl>
      </InstitutionTextWrapper>
      <SyncWhiteIconWrapper><SyncAccountIcon /></SyncWhiteIconWrapper>
    </SaltEdgeLoadingRowWrapper>
  )
}

function InstitutionExistsRow({ item, isActivationFlow }) {
  return (
    <SaltEdgeLoadingRowWrapper style={{ alignItems: 'center' }} isFullWidth={isActivationFlow}>
      <InstitutionLogoWrapper>
        {item.logoBase64 ? <img alt={item.name} src={'data:image/png;base64,' + item.logoBase64} /> :
          item.logoURL ? <img alt={item.name} src={item.logoURL} /> : <LogoPlaceHolder ><InstitutionPlaceholderIcon /></LogoPlaceHolder>}
      </InstitutionLogoWrapper>
      <InstitutionTextWrapper style={{ flex: 1, justifyContent: 'center' }}>
        <InstitutionName>{item.name}</InstitutionName>
        <InstitutionUrl>{cleanURL(item.homeURL)}</InstitutionUrl>
      </InstitutionTextWrapper>
      <CommonBadge isCTA={item.providerName === 'vip'} badgeType={item.isLoginRequired ? 'loginRequired' : item.providerName === 'vip' ? 'vipService' : 'connected'} lastSync={item.lastSync} color={'#DEB482'} />
    </SaltEdgeLoadingRowWrapper>
  )
}

function VipSyncRow({ item, searchTerm, onClick, checkInstitutionExistence, isBlocked, onBlockClick }) {

  const handleRowClick = () => {
    if (!checkInstitutionExistence || !checkInstitutionExistence?.()) {
      onClick?.();
    }
  }

  return (
    <VipSyncRowWrapper onClick={isBlocked ? onBlockClick : handleRowClick} >
      <InstitutionLogoWrapper>
        {item.logoBase64 ? <img alt={item.name} src={'data:image/png;base64,' + item.logoBase64} /> :
          item.logoURL ? <img alt={item.name} src={item.logoURL} /> : <LogoPlaceHolder ><InstitutionPlaceholderIcon /></LogoPlaceHolder>}
      </InstitutionLogoWrapper>
      <InstitutionTextWrapper style={{ justifyContent: 'center' }}>
        <InstitutionName>{highlightSearchTerm(item.name, searchTerm)}</InstitutionName>
        <InstitutionUrl>{cleanURL(item.homeURL)}</InstitutionUrl>
      </InstitutionTextWrapper>
      <CommonBadge isCTA badgeType={'vipService'} tooltipContent={`Sync this account automatically by providing Vyzer a Read-Only access directly to your account.
        This process can take time, but it will fully serve your needs.`} color={'#DEB482'} />
    </VipSyncRowWrapper>
  )
}

const ArrowLinkSvg = () => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.1708 8.1875C10.1708 8.1875 14.0097 8.1875 15.7487 8.1875C15.7487 9.92646 15.7487 13.7654 15.7487 13.7654" stroke="white" strokeWidth="1.60304" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.625 15.3164L15.5063 8.43514" stroke="white" strokeWidth="1.60304" strokeLinecap="round" />
    </svg>
  )
}