import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, Switch, Tooltip } from 'antd';
import { Observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { FutureEventsArrowIcon, ThreeDots2Icon } from '../../../../../../../assets/icons/common/common-icons';
import { ActionLocked } from '../../../../../../../common/components/ActionLocked/ActionLocked';
import { ActionLockedIconWithTooltip } from '../../../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { useStore } from '../../../../../../app/data/root.store';
import { CreateEvent } from '../../Actuals/CreateEvent';
import { ASSET_CATEGORIES_CREATE_EVENT, DEFAULT_EVENTS_TYPES } from '../../Actuals/constants/createEvent.events.const';
import { AssetClosedTabMessage, TabWrapper } from '../../Info/AssetInfo.styles';
import { AssetEventSlider } from '../AssetEventSlider';
import { categoriesEventAmountComputedTitle, categoriesEventAmountTitle, categoriesEventAssetValueTitle, categoriesEventsAfterAmountAttributes, categoriesEventsAttributes } from '../categories-events-attributes.const';
import { ActionButton, ArrowRightSvgWrapper, CenterContent, CenterContentWrapper, EventMenuItemIconWrapper, EventMenuItemText, EventTypeActionMenuContent, EventTypeActionMenuItem, EventTypeActionMenuItemContentWrapper, EventTypeActionMenuItemTitleWrapper, EventTypeActionMenuWrapper, EventsMenu, EventsMenuIconWrapper, EventsMenuItemWrapper, EventsMenuWrapper, HeaderTableCell, HeaderTableColumns, HeaderTop, HeaderWrapper, HeroWorthHoldingsBadge, HeroWorthHoldingsBadgeText, HeroWorthHoldingsBadgeTriangle, HistoricActualEventsPromptWrapper, LoadFutureEventsButton, LoadFutureEventsButtonWrapper, StatusHideCanceledEventsIcon, StickyWrapper } from './AssetEventsTab.styles';
import { ReactComponent as HideCanceledEventsIcon } from './assets/hide-event-icon.svg';
import { ClosedItemSvg } from '../../../../../../../common/components/CommonBadge/CommonBadge';

export const AssetEventsTab = (props) => {
  const { id, item, isIncognito } = props;
  const { itemStore, uiStore, userStore } = useStore();
  const [eventType, setEventType] = useState(-1);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [openEditEventModal, setOpenEditEventModal] = useState(false);
  const categoryAttributeColumns = categoriesEventsAttributes[item.categoryId] || [];
  // const categoriesEventAmountColumnTitle = categoriesEventAmountTitle[item.categoryId] || 'Amount';
  const categoriesEventValueColumnTitle = categoriesEventAssetValueTitle[item.categoryId] || 'Asset value';
  const categoryAttributeAfterAmountColumns = categoriesEventsAfterAmountAttributes[item.categoryId] || [];
  const tableColumns = ['Date', 'Event', ...categoryAttributeColumns.map(cac => cac.title),  'Amount' , ...categoryAttributeAfterAmountColumns.map(cac => cac.title), categoriesEventValueColumnTitle, 'Notes', 'Status']; //TODO: re-think this approach
  const columnsWithHoldingsBadge = ['Event', 'Amount', 'Asset value' ,'Shares +/-' ,'Balance'];
  const columnsWithShortHoldingsBadge = ['Shares +/-'];

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    if (isOverlayOpen && menuRef.current) {
      const timeoutId = setTimeout(() => {
        setMenuHeight(menuRef.current.offsetHeight);
      }, 10);
      return () => clearTimeout(timeoutId);
    }
  }, [isOverlayOpen]);

  useEffect(() => {
    return () => {
      itemStore.setEventsSliderShowFutureEvents(false);
    }
  }, [itemStore])

  const handleToggleFutureEventsClick = () => {
    if (!itemStore.isLoadFutureDisables) {
      itemStore.setEventsSliderShowFutureEvents(!itemStore.eventsSliderShowFutureEvents);
    }
  }

  const handleHistoricalActualEventPromptClick = () => {
    userStore.updateSettings('promptDismissed', { historicalActualEvents: true });
    itemStore.updateItemSettings({ showHistoricalActualEventsPrompt: false });
  }

  function createEventsMenu(props) {
    return (
      <EventsMenuWrapper>
        <EventsMenuItemWrapper onClick={() => { props.hideCanceledEvents() }}>
          <EventMenuItemIconWrapper>
            <HideCanceledEventsIcon />
          </EventMenuItemIconWrapper>
          <EventMenuItemText>Hide 'canceled' events ({itemStore.canceledItemsEventsCount})</EventMenuItemText>
          <Switch checked={itemStore.isItemHideCancelledEvents} size="small" />
        </EventsMenuItemWrapper>
      </EventsMenuWrapper>
    )
  }

  // const handleCreateEvent = () => {
  //   if (!item.isConnected) setShowCreateEventModal(true);
  //   if (item.categoryId === 37 && !props.handleAmortizationModal(itemStore)) {
  //     setShowCreateEventModal(true)
  //   }
  // }

  const createEventTypes = ASSET_CATEGORIES_CREATE_EVENT.hasOwnProperty(item.categoryId) ? ASSET_CATEGORIES_CREATE_EVENT[item.categoryId] : DEFAULT_EVENTS_TYPES;

  const handleEventTypeDropdownSelect = (index) => {
    if (createEventTypes[index].title === 'Exit event' && itemStore.isExitEventExist) {
      
      if (!itemStore.eventsSliderShowFutureEvents) {
        handleToggleFutureEventsClick();
      }
      setOpenEditEventModal(createEventTypes[index].title);
    } else {
      setEventType(index);
      if (!item.isConnected) {
        setShowCreateEventModal(true);
      }
      if (item.categoryId === 37 && !props.handleAmortizationModal(itemStore)) {
        setShowCreateEventModal(true)
      }
    }
  }

  return (
    <Observer>{() => (
      <TabWrapper>
        <CenterContentWrapper>
          <CenterContent>
            <StickyWrapper isSticky={uiStore.isAssetEventsHeaderSticky}>
              <HeaderWrapper>
                <HeaderTop>
                  <div style={ !uiStore.isDesktopView ? { flexGrow: '0.5', marginRight: item.closedAt !== null ? '16px' : 0 } : {flexGrow: '1'}}>
                    {item.closedAt !== null ? <AssetClosedTabMessage>
                      <ClosedItemSvg style={{minWidth:'16px'}}/>
                      <span>Edit events and Info is not available when item is closed</span>
                    </AssetClosedTabMessage>:
                    <LoadFutureEventsButtonWrapper isDisabled={itemStore.isLoadFutureDisables} isShowingFutureEvents={itemStore.eventsSliderShowFutureEvents} onClick={handleToggleFutureEventsClick}>
                      <LoadFutureEventsButton><FutureEventsArrowIcon /> {itemStore.eventsSliderShowFutureEvents ? 'Hide' : 'View'} future events</LoadFutureEventsButton>
                    </LoadFutureEventsButtonWrapper>
                    }
                  </div>
                  {/* <HeaderTitle>Events</HeaderTitle> */}
                  {/* <div style={{display:'flex', alignItems:'center'}}> */}
                  <div style={ !uiStore.isDesktopView ? {  flexGrow: '0.5' , flexShrink:0 } : {}}>
                  <ActionLocked action={'createEvent'}>
                    <Dropdown
                      overlay={EventTypeActionMenu({
                        createEventTypes,
                        setItem: (index) => handleEventTypeDropdownSelect(index),
                        isValueDisabled: item.plaidConnectedAccountId && !item.isLoginRequired,
                        itemClosed: item.closedAt !== null,
                        menuRef
                      })}
                      disabled={item.isConnected && item.categoryId !== 37}
                      trigger={['click']}
                      className='formDisplayerDefaultSelect'
                      placement="bottomRight"
                      overlayStyle={{ height: menuHeight }}
                      onOpenChange={(visible) => setIsOverlayOpen(visible)}
                      getPopupContainer={() => document.getElementById('wealthPageContent')}
                    >
                      <ActionButton style={{ width: 'auto' }} withIcon
                        // onClick={handleCreateEvent}
                        disabled={item.isConnected && item.categoryId !== 37} ><PlusOutlined />Create event{(userStore?.isReadOnlyMode || userStore.isActionBlockedByRole('createEvent')) && <ActionLockedIconWithTooltip iconColor={'#fff'} isRoleBlocked={userStore.isActionBlockedByRole('createEvent')} />}</ActionButton>
                    </Dropdown>
                  </ActionLocked>
                  </div>
                    {uiStore.isDesktopView &&
                  <EventsMenu>
                    <Dropdown
                      overlay={
                        createEventsMenu({
                          hideCanceledEvents: () => itemStore.setHidingCanceledEvents(!itemStore.isHidingCanceledEvents)
                        })
                      }
                      trigger={['click']}
                      placement="bottomRight"
                      getPopupContainer={() => document.getElementById('wealthPageContent')}
                      >
                      <EventsMenuIconWrapper>
                        <ThreeDots2Icon />
                      </EventsMenuIconWrapper>
                    </Dropdown>
                  </EventsMenu> }
                  
                  {showCreateEventModal && <CreateEvent eventTypeIndex={eventType} id={id} item={item} onClose={() => { setShowCreateEventModal(false); }} />}
                  
                </HeaderTop>
                { uiStore.isDesktopView && 
                <HeaderTableColumns categoryAttributeCount={categoryAttributeColumns.length + categoryAttributeAfterAmountColumns.length}>
                  {tableColumns.map((columnLabel, ind) => (
                    <HeaderTableCell key={'hc1' + ind} >
                      <span>
                      {  columnLabel === 'Amount' ? 
                         categoriesEventAmountComputedTitle[item.categoryId] ? categoriesEventAmountComputedTitle[item.categoryId][itemStore.hasEventWithTwoTransactions ? 1 : 0] :  categoriesEventAmountTitle[item.categoryId] || 'Amount'    
                         : columnLabel }
                        
                        {columnLabel === 'Status' && itemStore.isHidingCanceledEvents ?
                          <Tooltip
                            title="Canceled events are hidden"
                            align={{ offset: [0, 3] }}
                            placement="top"
                            color={'#000000'}
                            overlayInnerStyle={{ borderRadius: '5px', padding: '4px 8px', minHeight: '0', fontSize: '12px' }}>
                            <StatusHideCanceledEventsIcon />
                          </Tooltip> : null}
                      </span>
                      {columnsWithHoldingsBadge.includes(columnLabel) && !itemStore.isHoldingsView && item.holdings !== 100 ?
                        <HeroWorthHoldingsBadge><HeroWorthHoldingsBadgeText>{columnsWithShortHoldingsBadge.includes(columnLabel) ? <>&nbsp;</> : '100%'}</HeroWorthHoldingsBadgeText><HeroWorthHoldingsBadgeTriangle /></HeroWorthHoldingsBadge> : null}
                    </HeaderTableCell>
                  ))}
                </HeaderTableColumns> }
              </HeaderWrapper>
            </StickyWrapper>
            <AssetEventSlider isIncognito={isIncognito} openEditEventModal={openEditEventModal} onCloseEditEventModal={() => { setOpenEditEventModal(false) }} />
          </CenterContent>
        </CenterContentWrapper>
        {itemStore.itemSettings?.showHistoricalActualEventsPrompt && <HistoricActualEventsPromptWrapper>
          By default, all historic actual events were<br />created based on your cashflow projection.
          <ActionButton style={{ width: 'fit-content', padding: '8px 16px', marginLeft: '8px' }} onClick={handleHistoricalActualEventPromptClick}>Got it</ActionButton>
        </HistoricActualEventsPromptWrapper>}
      </TabWrapper>
    )}</Observer>)
}

function EventTypeActionMenu({ createEventTypes, setItem, isValueDisabled, menuRef, itemClosed }) {
  return (
    <EventTypeActionMenuWrapper>
      <EventTypeActionMenuContent ref={menuRef}>
        {createEventTypes.map((eventType, index) => (
          eventType.isPredicted || eventType.isHidden ? null :
            <EventTypeActionMenuItem onClick={() => { if (!itemClosed){setItem(index)} }} key={index} isHidden={index === 0 && isValueDisabled && !eventType.isAlwaysVisible} disabled={itemClosed} >
              <EventTypeActionMenuItemContentWrapper>
                <EventTypeActionMenuItemTitleWrapper>{eventType.title}</EventTypeActionMenuItemTitleWrapper>
                <ArrowRightSvgWrapper><ArrowRightSvg /></ArrowRightSvgWrapper>
              </EventTypeActionMenuItemContentWrapper>
            </EventTypeActionMenuItem>
        ))}
      </EventTypeActionMenuContent>
    </EventTypeActionMenuWrapper>
  )
}

const ArrowRightSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3196 7.98658L13.6529 12.0002L10.3196 16.0139" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}