import { Dropdown } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { ArrowDownIcon, TooltipIcon } from '../../../../../../assets/icons/common/common-icons';
import { MenuItemWrapper, MenuItemWrapperWealthPie, MenuWrapper, MenuWrapperWealthPie } from '../../../../../../common/components/DropDownMenu/DropDownMenu.styles';
import { ModifiedTooltip } from '../../../../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { colors } from '../../../../../../common/styles/theme.styles';
import { displayMoneyValueWithDigits } from '../../../../../../common/utils/number-display.utils';
import { StatInfoBoxWrapper, StatInfoText } from '../AssetOverviewHeroContent.styles';
import { ItemMetricBars } from './ItemMetricBars';
import { ItemTargetActualPerformance } from './ItemTargetActualPerformance';
import { CheckMarkSvgWrapper, StatBox, StatContentAndGraph, StatContentWrapper, StatDescriptionBig, StatGraphWrapper, StatMultiContentWrapper, StatSubWrapper, StatTargetValue, StatTimeFrameSelectorWrapper, StatValue, StatsArrayActionMenuContent, StatsArrayActionMenuItem, StatsArrayActionMenuItemSubTitle, StatsArrayActionMenuItemTitle, StatsArrayActionMenuItemTitlesWrapper, StatsArrayActionMenuWrapper, ValueChangesButton } from './OverviewTab.styles';
import { MagicBoxAnimatedIconGrey } from '../../../../../magic-box/components/MagicBoxAnimatedIconGrey';

export function StatInfoWrapper(props) {
  const { stat, itemData, isIncognito, isHoldingView, isClassView, hasLLMInProcessItem } = props;
  const [dataIndex, setDataIndex] = useState(0);
  //const statKeys = stat.isArray ? stat.stats.map((item, index) => ({ index, title: typeof item.title === 'function' ? item.title(itemData) : item.title, menuDescription: item.menuDescription })) : [];
  const dependedStat = (stat.isDepended && stat.getStatFn ) ? stat.getStatFn(itemData) : stat;
  const statKeys = dependedStat.isArray ? dependedStat.stats.map((item, index) => ({ ...item, index })) : [];
  const currentStat = dependedStat.isArray ? dependedStat.stats[dataIndex] : dependedStat;
  const [selectedTimeFrameIndex, setSelectedTimeFrameIndex] = useState(currentStat.defaultOpenIndex || 0);
  const [previousSelectedTimeFrameIndex, setPreviousSelectedTimeFrameIndex] = useState(0);

  const handleChangeDataKey = (index) => {
    if (dependedStat.isArray) {
      const nextStat = dependedStat.stats[index];
      const isResetDefaultOpenIndex = dependedStat.stats[previousSelectedTimeFrameIndex]?.hasOwnProperty('defaultOpenIndex') && !nextStat?.hasOwnProperty('defaultOpenIndex');
      setSelectedTimeFrameIndex((previousSelectedTimeFrameIndex === 0 && nextStat.defaultOpenIndex) ? nextStat.defaultOpenIndex : !isResetDefaultOpenIndex ? previousSelectedTimeFrameIndex : 0);
      setPreviousSelectedTimeFrameIndex(selectedTimeFrameIndex);
    }
    setDataIndex(index);
  }

  return (
    <StatInfoBoxWrapper>
      <StatInfoBox
        stat={currentStat}
        itemData={itemData}
        isIncognito={isIncognito}
        isHoldingView={isHoldingView}
        isClassView={isClassView}
        onChangeDataKey={(index) => { handleChangeDataKey(index) }}
        selectedTimeFrameIndex={selectedTimeFrameIndex}
        setSelectedTimeFrameIndex={setSelectedTimeFrameIndex}
        statKeys={statKeys}
        hasLLMInProcessItem={hasLLMInProcessItem}
      />
    </StatInfoBoxWrapper>
  )
}

export function StatInfoBox(props) {
  const { stat, itemData, isIncognito, isHoldingView, isClassView, statKeys, onChangeDataKey, selectedTimeFrameIndex, setSelectedTimeFrameIndex, hasLLMInProcessItem } = props;
  // stat.title = typeof stat.title === 'function' ? stat.title(itemData) : stat.title;
  const [isOpen, setIsOpen] = useState(false);

  const isHidingMultipleValues = stat.isMultipleValuesHiddenFn?.(itemData, selectedTimeFrameIndex);

  const StatValueComp = () => {
    if (stat.valueFn) return stat.valueFn(itemData, isIncognito, isHoldingView, selectedTimeFrameIndex);
    if (stat.type === 'perc') {
      return (
        <NumberFormat
          value={(stat.multipleValues ? itemData[stat.dataKey][selectedTimeFrameIndex].actual : itemData[stat.dataKey]) || 0}
          displayType={'text'}
          suffix={['moic','dpi'].includes(stat.dataKey) ? 'x' : '%'}
          decimalScale={stat.specificDecimalScale || 2} />
      )
    }
    if (stat.type === 'date') return (moment(itemData[stat.dataKey]).format('MMM DD, YYYY'));
    if (stat.type === 'number' && itemData.hasOwnProperty(stat.dataKey)) {
      return (
        <NumberFormat
          displayType={'text'}
          value={stat.multipleValues ? itemData[stat.dataKey][selectedTimeFrameIndex].actual : itemData[stat.dataKey]}
          thousandSeparator={true}
        />
      )
    }

    if (stat.type === 'number' && !itemData.hasOwnProperty(stat.dataKey)) return "--"

    const value = stat.multipleValues ? (itemData?.[stat.dataKey]?.[selectedTimeFrameIndex]?.actual || 0) : (itemData[stat.dataKey] || 0);
    const currency = stat.customCurrency ? stat.customCurrency : itemData.currency;
    const holding = isHoldingView && itemData.holdings && !stat.ignoreHoldings ? itemData.holdings : 100;


    return (
      <StatInfoText>
        {displayMoneyValueWithDigits(value, currency, isIncognito, holding, stat.digits || 2)}
        {(hasLLMInProcessItem && !isIncognito) && <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(241,243,245)' />}
      </StatInfoText>
    )
  }


  return (
    <StatBox isClassView={isClassView} haveChilds={stat.childs && stat.childs.length} onClick={() => { setIsOpen(!isOpen) }} isOpen={isOpen}>
      {statKeys && statKeys.length > 0 ?
        <div style={{display:'flex'}}>
        <Dropdown
          overlay={StatsArrayActionMenu({ statKeys, onChangeDataKey, itemData, selectedStatTitle: (typeof stat.title === 'function' ? stat.title(itemData) : stat.title) })}
          trigger={['click']}
          placement="bottomLeft"
          overlayClassName='statInfoBoxDropDownWrapper'
          getPopupContainer={() => document.getElementById('wealthPageContent')}
        >
          <ValueChangesButton style={{ fontSize: '14px', lineHeight: '16px', marginBottom: '8px', padding: '0 2px' }} borderLess>
            {typeof stat.title === 'function' ? stat.title(itemData) : stat.title}<ArrowDownIcon />
          </ValueChangesButton>
        </Dropdown>
        {stat.description && <ModifiedTooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={stat.description} placement="top" overlayInnerStyle={{ minWidth: '370px', fontSize: '12px', background: 'rgba(0, 0, 0, 0.9)' }}><TooltipIcon style={{marginBottom: '3px' , marginLeft:'4px'}} /></ModifiedTooltip>}
        </div>
        :
        <StatDescriptionBig isClassView={isClassView}>{typeof stat.title === 'function' ? stat.title(itemData) : stat.title}{stat.description && <ModifiedTooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={stat.description} placement="top" overlayInnerStyle={{ minWidth: '370px', fontSize: '12px', background: 'rgba(0, 0, 0, 0.9)' }}><TooltipIcon /></ModifiedTooltip>}</StatDescriptionBig>
      }
      <StatSubWrapper>
        {stat.hasOwnProperty('customComp') ? stat.customComp(itemData, isHoldingView) : 
        itemData?.hasOwnProperty(stat.dataKey) ?
          stat.hasOwnProperty('emptyStateComp') && stat.hasOwnProperty('showEmptyStateFn') && stat.showEmptyStateFn(itemData) ? stat.emptyStateComp(itemData, isHoldingView) :
            <>
              <StatTimeFrameSelectorWrapper>
                {stat.multipleValues && !isHidingMultipleValues && itemData[stat.dataKey].length > 1 && <Dropdown
                  overlay={
                    TimeFrameDropDown({
                      isClassView,
                      hiddenIndexes: stat.hiddenIndexes || [],
                      itemsList: stat.showTotalOnly ? itemData[stat.dataKey].filter(i => i.label === 'Total') : itemData[stat.dataKey],
                      selectTimeFrame: (index) => { setSelectedTimeFrameIndex(index) },
                      multipleValuesLabels: stat.multipleValuesLabels || {}
                    })
                  }
                  overlayClassName='statInfoBoxDropDownWrapper'
                  getPopupContainer={() => document.getElementById('wealthPageContent')}
                  trigger={['click']} placement="bottomCenter" disabled={itemData[stat.dataKey].length < 2} >
                  <ValueChangesButton borderLess color={colors.gray2}>
                    {stat.multipleValuesLabels?.[itemData[stat.dataKey][selectedTimeFrameIndex].label] ? stat.multipleValuesLabels?.[itemData[stat.dataKey][selectedTimeFrameIndex].label] : itemData[stat.dataKey][selectedTimeFrameIndex].label}<ArrowDownIcon />
                  </ValueChangesButton>
                </Dropdown>}
              </StatTimeFrameSelectorWrapper>
              <StatContentAndGraph>
                <StatMultiContentWrapper fullWidth={!stat.hasMetricBars}>
                  <StatContentWrapper>
                    <StatValue showInlineTargetActual={stat.showInlineTargetActual}>
                      <StatValueComp />
                      {stat.showInlineTargetActual && props.itemData[stat.dataKey] && props.itemData[stat.dataKey].length > 0 && <ItemTargetActualPerformance stat={stat} itemData={props.itemData} selectedTimeFrameIndex={selectedTimeFrameIndex} />}
                    </StatValue>
                  </StatContentWrapper>
                  {stat.additionalDataFn && stat.additionalDataFn(itemData, selectedTimeFrameIndex, isHoldingView, isIncognito)}
                  {
                    stat.hasMetricBars && <StatTargetValue>
                      {typeof itemData[stat.dataKey][selectedTimeFrameIndex].target === 'number' ? `${parseFloat(itemData[stat.dataKey][selectedTimeFrameIndex].target.toFixed(2))}${stat.type === 'perc' ? '%' : 'x'} target` : ``}
                    </StatTargetValue>}
                </StatMultiContentWrapper>
                {
                  stat.hasMetricBars ? <StatGraphWrapper>
                    <ItemMetricBars stat={stat} itemData={props.itemData} selectedTimeFrameIndex={selectedTimeFrameIndex} />

                  </StatGraphWrapper> : null}
              </StatContentAndGraph>
            </> : null}
         
      </StatSubWrapper>
      {stat.showTargetActual && !stat.hideAboveBelow && props.itemData[stat.dataKey] && props.itemData[stat.dataKey].length > 0 && <ItemTargetActualPerformance stat={stat} itemData={props.itemData} selectedTimeFrameIndex={selectedTimeFrameIndex} />}
    </StatBox>
  )
}

function TimeFrameDropDown(props) {
  const { itemsList, selectTimeFrame, hiddenIndexes, isClassView, multipleValuesLabels } = props;
  if (isClassView) {
    return (
      <MenuWrapperWealthPie>
        {itemsList && itemsList.map((item, index) => (
          hiddenIndexes.includes(index) ? null :
            <MenuItemWrapperWealthPie key={index} onClick={() => { selectTimeFrame && selectTimeFrame(index) }}>
              {multipleValuesLabels?.[item.label] ? multipleValuesLabels?.[item.label] : item.label}
            </MenuItemWrapperWealthPie>
        ))}
      </MenuWrapperWealthPie>
    )
  }

  return (
    <MenuWrapper>
      {itemsList && itemsList.map((item, index) => (
        hiddenIndexes.includes(index) ? null :
          <MenuItemWrapper key={index} onClick={() => { selectTimeFrame && selectTimeFrame(index) }}>
            {multipleValuesLabels?.[item.label] ? multipleValuesLabels?.[item.label] : item.label}
          </MenuItemWrapper>
      ))}
    </MenuWrapper>
  )
}

function StatsArrayActionMenu(props) {
  const { statKeys, onChangeDataKey, selectedStatTitle, itemData } = props;

  return (
    <StatsArrayActionMenuWrapper>
      <StatsArrayActionMenuContent>
        {statKeys.map(sk => (
          <StatsArrayActionMenuItem selectedItem={selectedStatTitle === (typeof sk.title === 'function' ? sk.title(itemData) : sk.title)} key={sk.index} onClick={() => { onChangeDataKey(sk.index) }}>
            <div style={{ display: 'flex' , flex:1 }}>
              <StatsArrayActionMenuItemTitlesWrapper>
                <StatsArrayActionMenuItemTitle>{typeof sk.title === 'function' ? sk.title(itemData) : sk.title}</StatsArrayActionMenuItemTitle>
                <StatsArrayActionMenuItemSubTitle>{sk.menuDescription}</StatsArrayActionMenuItemSubTitle>
              </StatsArrayActionMenuItemTitlesWrapper>
              <CheckMarkSvgWrapper>{selectedStatTitle === (typeof sk.title === 'function' ? sk.title(itemData) : sk.title) ? <CheckMarkSvg /> : null}</CheckMarkSvgWrapper>
            </div>
          </StatsArrayActionMenuItem>
        ))}
      </StatsArrayActionMenuContent>
    </StatsArrayActionMenuWrapper>
  )
}

const CheckMarkSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5106 8.604L10.5061 15.6125L7.00008 12.1075" stroke="black" strokeWidth="2" />
    </svg>
  )
}