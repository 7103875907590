import { useEffect, useState } from 'react';
import { useStore } from '../../../modules/app/data/root.store';

export const useModalUpwardCorrectionHook = (modalWrapperRef) => {
  const { uiStore } = useStore();
  const [isCorrectingUpward, setIsCorrectingUpward] = useState(false);

  useEffect(() => {
    const resizeListener = () => {
      if (modalWrapperRef.current) {
        const { clientHeight } = modalWrapperRef.current;
        setIsCorrectingUpward(uiStore.windowHeight - clientHeight > 128);
      }
    }
    window.addEventListener("resize", resizeListener);
    resizeListener();
    return () => window.removeEventListener("resize", resizeListener);
  }, [uiStore.windowHeight, modalWrapperRef]);

  return isCorrectingUpward;
}
