import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../styles/combos.styles";
import { colors } from "../../../../styles/theme.styles";

  export const AmortizationCollateralAutoCompleteWrapper = styled.div`
    ${flexCenter};
  `;

const inputPaddingLeft = 24;

  export const InputWrapper = styled.div`
    width:100%;
    position: relative;
    .ant-select{
        width: 100%;
        font-size:18px;
    }

    .ant-select-arrow path{
        stroke:#000;
    }
    .ant-select-disabled .ant-select-arrow path{
        stroke: rgba(0, 0, 0, 0.25);
    }

    .ant-select .ant-select-selector{
        border-color:${props=>props.withError ? '#FF7676' : '#d9d9d9'};
    }
    
    .ant-select-single .ant-select-selector,.ant-select-single .ant-select-selector .ant-select-selection-item{
        height:38px;
        line-height:38px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
        line-height:38px;
    }
    .ant-select:hover .ant-select-selector{
        border-color:#7d7d7d;
    }
    .ant-select-focused .ant-select-selector{
        border-color:#7d7d7d!important;
        box-shadow: none!important;
    }
    .ant-picker:not(.innerTable){
        width: 100%;
        font-size:18px;
        height:38px;
        border-color:${props=>props.withError ? '#FF7676' : '#d9d9d9'};
        &:hover{
            border-color:#7d7d7d;
        }
    }
    .ant-dropdown-trigger:not(.innerTable){
        &:hover{
            border-color:#7d7d7d;
        }
    }
    .ant-picker-input > input{
        font-size:18px;
        line-height:18px;
    }
    .ant-picker-focused{
        border-color:#7d7d7d!important;
        box-shadow: none!important;
    }
    .ant-input:not(.innerTable){
        color:${props=>props.textColor && '#FF7676' };
        font-size:18px;
        // border-color:'green';
        border-color:${props=>props.withError ? '#FF7676' : '#d9d9d9'};
        &:hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
            box-shadow: none;
        }
        &.holdings-left{
            border-top-right-radius:0px!important;
            border-bottom-right-radius:0px!important; 
            // border-right:0px;
        }
        &.holdings-right{
            border-top-left-radius:0px!important;
            border-bottom-left-radius:0px!important; 
        }
    }
    .ant-input:not(.innerTable):not(textarea){
        height:38px;
    }
    .ant-input-affix-wrapper{
        border-radius:4px;
        &:hover{
            border-color:#7d7d7d;
        }
        &:focus{
            border-color:#7d7d7d;
        }
        .ant-input{
            box-shadow:none;
        }
    }
    .ant-input-affix-wrapper-focused{
        border-color:#7d7d7d;
    }
    
    .ant-input-focused{
        border-color:#7d7d7d;
        box-shadow: none;
    }

    .ant-checkbox-wrapper{
        .ant-checkbox-input, .ant-checkbox-inner {
            width: 16px;
            height: 16px;
            :focus{
                border-color:${colors.darkGray1};
            }
        }
        .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color:${colors.darkGray1};
        }
        .ant-checkbox-checked{
            .ant-checkbox-inner {
                background-color:${colors.darkGray1};
                border-color:${colors.darkGray1};
                ::after{
                    transform: rotate(45deg) scale(1) translate(-56%, -58%);
                }
            }
            ::after {
                border-color:${colors.darkGray1};
            }
        }
        :hover{
            .ant-checkbox-inner {
                border-color:${colors.darkGray1};
            }
        }
        &.ant-checkbox-wrapper-checked:hover{
            .ant-checkbox-inner {
                border-color:${colors.darkGray1};
            }
        }
    }
    

    .ant-switch-checked{
        background-color: #65A87F; //${colors.darkGray1};
    }

`;

export const SearchBoxWrapper = styled.div`
    width: 100%;
    position: relative;
    
    .ant-select-focus-visible {
        outline: none;
    }
    .ant-select .ant-select-selector {
        border: 1px solid ${colors.gray3};
        box-shadow: none;
        // border-radius: 34px;
        height: 40px;
        /* padding: 2px 36px ; */
        padding: 0 36px ;
        /* overflow: hidden;
        text-overflow: ellipsis; */
    }

    .ant-select:hover .ant-select-selector, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border: 1px solid ${colors.gray3};
        box-shadow: none;
        height: 40px;
        padding-left: 36px;
        /* padding-left: ${inputPaddingLeft}px; */
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
        height: 40px;
        padding-left: ${inputPaddingLeft}px;
    } 
`;

export const SearchIconWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 12px;
    left: 12px;
    align-items: center;
    svg{
        width: 16px;
        height: 16px;
        circle, line {
            stroke: ${props => props.hasSearchInput ? colors.darkGray1 : '#9097A3' }
        }
    }
`;

export const SelectedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding: 0 12px;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  // border-radius: 34px;
  border: 1px solid ${colors.gray3};

  :hover {
    border: 1px solid ${colors.darkGray1};
  }
`;