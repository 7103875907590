import styled from "@emotion/styled/macro";
import { Input } from 'antd';
import { flexCenter, textOverflow } from "../../../../styles/combos.styles";
import { colors, shadows } from "../../../../styles/theme.styles";
import { PrimaryButtonWithIcon } from "../../../buttons/NewButtons.styles";

const inputPaddingLeft = 24;

export const InstitutionAutoCompleteWrapper = styled.div`  
  width: 100%;
  position: relative;
`;

export const SearchInputAndResultsWrapper = styled.div`
    /* max-width:530px; */
    position: relative;
    width:100%;
`;

export const SearchInput = styled(Input)`
    height:40px;
    border: 1px solid ${colors.darkGray2};
    /* border-radius: 34px;
    border-bottom: 1px solid #DDE3EB;
    border-width:0 0 1px 0;
    border-radius:4px 4px 0px 0px; */
    &:hover:not([disabled]){
        border-color:#7d7d7d!important;
        box-shadow: none;
        // border-right:none!important;
    }
    &:focus{
        border-color:#7d7d7d!important;
        box-shadow: none;
        // border-right:none!important;
    }
    &.ant-input-affix-wrapper-focused{
        border-color:#7d7d7d;
        box-shadow: none;
        // border-right:none!important;
    }
`

export const ListAndLoaderWrapper = styled.div`
    position: absolute;
    width: 100%;
    z-index: 4;
`;

export const AutoSyncPopoverWrapper = styled(ListAndLoaderWrapper)`
    position: static;
`;

export const AddManuallyButtonWrapper = styled.div`
    margin-top:16px;
    box-shadow:${shadows.menuPopup};
    border: 1px solid ${colors.gray3};
    background-color:#fff;
    border-radius:8px;
    min-height:64px;
    max-height:64px;
    padding:16px;
    position:relative;
    ${flexCenter};
    justify-content:flex-start;
    cursor: pointer;
    overflow:hidden;
    // border-left:2px solid ${colors.lightGray1};
    ::before{
        content:' ';
        position:absolute;
        left:0;
        top:50%;
        height:0%;
        width:2px;
        background-color:${colors.darkGray1};
        // transition:all 0.25s ease-in-out;
    }
    :hover{
        background-color:${colors.lightGray1};
        //border-left:2px solid ${colors.darkGray1};
        ::before{
            top:0%;
            height:100%;
        }
    } 
`;

export const AddIconWrapper = styled.div`
    width:32px;
    min-width:32px;
    height:32px;
    ${flexCenter};
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    border-radius:50%;
    margin-left:2px;
`;

export const InstitutionsResultsListWrapper = styled.div`
    margin-top:16px;
    box-shadow:${shadows.menuPopup};
    border: 1px solid ${colors.gray3};
    background-color:#fff;
    border-radius:8px;
    // min-height:300px;
    min-height:48px;
    max-height:300px;
    overflow-y:auto;  
`;

export const AutoSyncPopoverContent = styled(InstitutionsResultsListWrapper)`
    position: relative;
`;

export const AutoSyncPopoverContentRow = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 12px 16px;
    // gap: 8px;
    :first-of-type {
        background-color: ${colors.gray3};
    }
`;

export const AutoSyncTitle = styled.div`
    font-family: 'Domine';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: ${colors.darkGray1};
`;

export const AutoSyncSubTitle = styled.div`
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    color: ${colors.darkGray1};
`;

export const CloseIconWrapper = styled.div`
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
`;

export const OnClickPropagationWrapper = styled.div``;

export const InstitutionAutoCompleteResultRowWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: ${props => props.isAutoSync ? `12px 16px` : `8px 16px`};
    gap: 8px;
    ${props => props.isAutoSync ? `` : `
        border-left: 2px solid #fff;
        :hover{
            border-left: 2px solid #000;
            background-color: ${colors.lightGray1};
        }
    `};
    cursor: pointer;
`;

export const Separator = styled.div`
    width:100%;
    height:1px;
    background-color:${colors.gray3};
`;

export const SearchPlaceholderWrapper = styled.span`
    padding-left: ${inputPaddingLeft}px;
`;

export const InstitutionAutoCompleteEmptyStateWrapper = styled.div`
    /* background-color: #fff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 0px 16px rgba(0, 0, 0, 0.16);
    border-radius: 4px; */
`;

export const SearchIconWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    align-items: center;
    svg{
        width: 16px;
        height: 16px;
        circle, line {
            stroke: ${props => props.hasSearchInput ? colors.darkGray1 : '#9097A3'}
        }
    }
`;

export const SearchIconEmptyStateWrapper = styled.div`
    ${flexCenter};
    svg{
        width: 32px;
        height: 32px;
    }
    padding-top: 40px;
    padding-bottom: 16px;
`;

export const SearchTextWrapper = styled.div`
    ${flexCenter};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.darkGray1};
    margin-bottom: 4px;
`;

export const SearchAltTextWrapper = styled(SearchTextWrapper)`
    color: ${colors.darkGray3};
    font-weight: 400;
    padding-bottom: 38px;
`;

export const InstitutionItemSwitcherWrapper = styled.div``;

export const LoadMoreLoaderWrapper = styled.div`
    padding:24px;
    ${flexCenter};
`;

export const SearchingLoaderWrapper = styled.div`
    position:absolute;
    top:16px;
    bottom:0;
    left:0;
    right:0;
    background-color:${colors.lightGray2};
    border-radius:8px;
    opacity:0.7;
    ${flexCenter};
`;

export const CleanSearchAddManuallyMessage = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    cursor: pointer;
    margin-top:64px;
    .visible-text{    
        color:${colors.darkGray3};
        font-size:12px;
    }
    .add-manually-text{
        color:${colors.darkGray1};
        font-size:12px;
        visibility:hidden;
    }
    :hover{
        .add-manually-text{
            visibility:visible;
        }
    }
`;

export const AddButtonText = styled.div`
    margin-left:16px;
    max-width: calc(100% - 64px);
    font-size:14px;
    line-height:16px;
    font-weight:500;
    color:${colors.darkGray1};
    ${textOverflow};
`;

export const HighlightedSearchText = styled.span`
    font-weight: 500;
    color: ${colors.darkGray1};
`;

export const ItemLogoWrapper = styled.div`
    height:30px;
    width:30px;
    border-radius: 23px;
    /* margin-right:12px; */
    img{
        height:30px;
        width:30px;
    }
`;

export const LogoPlaceHolder = styled.div`
    ${flexCenter}
    height:32px;
    width:32px;
    min-width:32px;
    background-color:${colors.gray3};
    border-radius:50%;
    svg{
        height:24px;
        width:24px;
    }
`;

export const ItemIconWrapper = styled.div`
    height:24px;
    width:24px;
    border-radius: 23px;
    background-color:${props => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right:12px; */
    svg{
        width:16px;
        height:16px;
    }
    path{
        fill:#fff;
    }
`;

export const ItemContent = styled.div`
    flex:1;
    max-width: calc(100% - 40px);
`;

export const ItemText = styled.div`
    display:flex;
    flex-direction:column;
    gap:2px;
    max-width: 100%;
`;

export const ItemTitleAndBadge = styled.div`
    ${flexCenter};
    justify-content: flex-start;
`;

export const ItemTitle = styled.div`
    color:${colors.darkGray2};
    font-size:14px;
    line-height:17px;
    font-weight:400;
    // flex:1;
    display: block;
    // flex: 1;
    ${textOverflow};
`;

export const ItemMoreInfos = styled.div`
    font-size:12px;
    line-height: 14px;
    color: #9097A3;
    display:flex;
    flex-direction:row;
    align-items:center;
    
`;

export const MoreInfosItem = styled.div`
    font-size:12px;
    color: #9097A3;
    &:not(:last-child){padding-right:8px;}
    display: block;
    align-items: center;
    ${textOverflow};
`;

export const SyncInstitutionButton = styled.div`
    ${flexCenter};
    background-color: ${colors.darkGray1};
    padding: 12px 24px 12px 20px;
    gap: 6px;
    box-shadow: ${shadows.buttonShadow};
`;

export const ReviewMySyncedAccountsButton = styled(PrimaryButtonWithIcon)`
    padding: 6px 16px;
    gap:2px;
    font-size: 13px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;