import { Observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PlusIcon } from '../../../../../assets/icons/common/common-icons';
import { ActionLockedIconWithTooltip } from '../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { ROUTES } from '../../../../../common/constants/routes.const';
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import { useStore } from '../../../../app/data/root.store';
import { AssetClassIcon } from '../../../../assets/components/AssetClassIcon';
import { ActionsWrapper, ClassPageEmptyStateAddItemText, ClassPageEmptyStateTitle, ClassPageEmptyStateWrapper, ItemIconWrapper, ManuallyAddClassButton, SyncPopupButton, UploadMagicBoxButton } from './ClassPage.styles';

const magicBoxClassIds = [4, 5, 6, 7, 8];

const classTitles = {
    1: `It's time to add your cash accounts, such as checking accounts, saving accounts, and more.`,
    2: `It's time to add your long term savings, such as retirement accounts, children's savings, and more.`,
    3: `It’s time to add some brokerage accounts`,
    4: `It's time to add some real estate investments, such as syndications, rental properties, and more.`,
    5: `It's time to add the money you lent privately or through P2P platforms.`,
    6: `It's time to add some private investments, such as startups and traditional businesses.`,
    7: `It's time to add some investments such as collectibles, vehicles, and more.`,
    8: `It's time to add some funds investments, such as private equities, real estate, and more.`,
    9: `It's time to add some crypto investments. You can add specific coins or wallets.`,
    10: `It's time to add your loans, such as mortgages and business loans, and more.`,
    11: `It's time to add your credit cards.`
}

export const ClassPageEmptyState = (props) => {
    // const { selectedClass } = props;
    // const { icon, color, title, isLiability } = selectedClass;
    const history = useHistory();
    const { createItemStore, userStore, billingStore, filtersStore, metadataStore } = useStore();
    const { tagColor, title: tagTitle, icon: tagIcon, isLiability, id: classId } = filtersStore.singleClassFilter || {};


    const handleAddClassItemOnClick = () => {
        if (userStore.isActionBlockedByRole('classPageEmptyStateAddManually')) {
            userStore.setShowLimitedSharedAccess(true);
        } else if (userStore.isDemoOrReadOnlyMode) {
            return billingStore.setShowPricingTableModal(true);
        } else if (userStore.isCreateItemManuallyLimited){
            createItemStore.setShowAddManualItemLimitModal(true);
        } else {
            createItemStore.selectClass(classId);
            if (createItemStore.selectedClassCategories.length === 1) {
                createItemStore.selectCategory(createItemStore.selectedClassCategories[0].id);
            }
            createItemStore.setShowModal(true);
        }

    }

    const handleUploadMagicBoxButtonOnClick = () => {
        if (userStore.isActionBlockedByRole('classPageEmptyStateUploadMagicBox')) {
            userStore.setShowLimitedSharedAccess(true);
        } else if (userStore.isDemoOrReadOnlyMode) {
            billingStore.setShowPricingTableModal(true);
        } else {
            navigateToUrl(history, ROUTES.magicBox.url);
        }
    }

    const handleSyncPopupButtonOnClick = () => {
        if (userStore.isActionBlockedByRole('classPageEmptyStateSyncNew')) {
            userStore.setShowLimitedSharedAccess(true);
        } else if (userStore.isDemoOrReadOnlyMode) {
            billingStore.setShowPricingTableModal(true);
        } else {
            createItemStore.setInstitutionStep('bank');
            createItemStore.setShowModal(true);
        }
    }

    return (<Observer>{() => (
        <ClassPageEmptyStateWrapper>
            <ItemIconWrapper color={tagColor} style={{ margin: '0px' }}>
                <AssetClassIcon name={tagIcon} />
            </ItemIconWrapper>
            <ClassPageEmptyStateTitle>No {isLiability ? 'liabilities' : 'assets'}</ClassPageEmptyStateTitle>
            <ClassPageEmptyStateAddItemText>
               {classTitles.hasOwnProperty(classId) ? classTitles[classId] :
                 `It’s time to add some ${tagTitle}, like:\n${metadataStore.categories.filter(category=>category.classId === classId && !category.isHidden).map(item=>' ' + item.title)}...`}</ClassPageEmptyStateAddItemText>
            <ActionsWrapper>
                <ManuallyAddClassButton onClick={handleAddClassItemOnClick}>
                    <PlusIcon /> Add manually {(userStore?.isDemoOrReadOnlyMode || userStore.isActionBlockedByRole('classPageEmptyStateAddManually') ) && <ActionLockedIconWithTooltip iconColor={'#fff'} marginTop={'2px'} isRoleBlocked={userStore.isActionBlockedByRole('classPageEmptyStateAddManually')} />}
                </ManuallyAddClassButton>
                {magicBoxClassIds.includes(classId) ?
                    <UploadMagicBoxButton onClick={handleUploadMagicBoxButtonOnClick} classColor={tagColor}>Upload via Magic box{(userStore.isDemoOrReadOnlyMode || userStore.isActionBlockedByRole('classPageEmptyStateUploadMagicBox')) ? <ActionLockedIconWithTooltip iconColor={'#fff'} marginTop={'-2px'} isRoleBlocked={userStore.isActionBlockedByRole('classPageEmptyStateUploadMagicBox')} /> : null}</UploadMagicBoxButton>
                    :
                    <SyncPopupButton onClick={handleSyncPopupButtonOnClick} classColor={tagColor}>Sync {tagTitle}{(userStore.isDemoOrReadOnlyMode || userStore.isActionBlockedByRole('classPageEmptyStateSyncNew')) ? <ActionLockedIconWithTooltip iconColor={'#fff'} marginTop={'-2px'} /> : null}</SyncPopupButton>}
            </ActionsWrapper>
        </ClassPageEmptyStateWrapper>
    )}</Observer>)
}
