import moment from "moment";
import { StatAdditionalDataWrapper, LoanAdditionalDataBox, StatRemainingTimeWrapper } from "../OverviewTab.styles";
// import NumberFormat from 'react-number-format';
import { displayMoneyValue } from "../../../../../../../common/utils/number-display.utils";


export const loansAmortizationOverviewData = {
    value: { title: 'Balance', subTitle: 'Remaining loan amount', lastUpdate: true },
    stats: [
        { title: 'Interest rate', subTitle: 'Annual interest rate', dataKey: 'mostRecentInterest', type: 'perc', specificDecimalScale: 3 },
        {
            title: 'Remaining time', dataKey: 'remainingMonths', type: 'date',
            valueFn: (itemData) => {
                return itemData.remainingMonths ?
                    <StatRemainingTimeWrapper>
                        {itemData.remainingMonths} Month{itemData.remainingMonths > 1 ? "s" : ''}
                    </StatRemainingTimeWrapper>
                    : '--'
            },
            additionalDataFn: (itemData, selectedTimeFrameIndex ) => (
                itemData.remainingMonths ? <StatAdditionalDataWrapper>
                    <LoanAdditionalDataBox> {moment().add(itemData.remainingMonths,'M').format('MMM, yyyy')}</LoanAdditionalDataBox>
                </StatAdditionalDataWrapper> : '--'
            )
            
            // additionalDataFn: (itemData, selectedTimeFrameIndex) => { return <StatAdditionalDataWrapper><LoanAdditionalDataBox>Remaining balance {displayMoneyValue(itemData.amount_borrowed[0].actual - itemData.loanData[0].actual, itemData.currency)}</LoanAdditionalDataBox></StatAdditionalDataWrapper> }
        },
        {
            title: 'Paid amount', subTitle: '', dataKey: 'paidAmounts', multipleValues: true,
            additionalDataFn: (itemData, selectedTimeFrameIndex, isHoldingView,isIncognito) => {
                return (itemData.paidAmounts && itemData.paidAmounts.length) ? <StatAdditionalDataWrapper>
                    <LoanAdditionalDataBox>Interest <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex]?.interest, itemData.currency,isIncognito,isHoldingView ? itemData.holdings : 100)}</span></LoanAdditionalDataBox>
                    <LoanAdditionalDataBox>Principal <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex]?.principal, itemData.currency,isIncognito,isHoldingView ? itemData.holdings : 100)}</span></LoanAdditionalDataBox>
                </StatAdditionalDataWrapper> : null
            }
        },
        { title: 'Borrowed amount', subTitle: '', dataKey: 'amount_borrowed' , multipleValues: true },
    ],
    visuals: []

}