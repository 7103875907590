import styled from '@emotion/styled/macro';

export const SmallActionButton = styled.div`
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.32), 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    background-color:#fff;
    padding:4px 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display:flex;
    align-items:center;
    cursor: pointer;
    .buttonIcon{
        margin-right:4px;
        // padding:0 8px 0 0;
    }
    ${props=> props.disabled ? `
    opacity: 0.4;
    cursor: not-allowed;
  ` : ``}
    
`;

export const ActionButton = styled.div`
    background: #2A2D33;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding:${props=>props.withIcon ? '8px 40px 8px 32px' : '8px 40px'};
    // padding: 8px 32px;
    font-size:14px;
    color:#fff;
    cursor: pointer;
    display:flex;
    align-items:center;
    .anticon{
        padding:0 8px 0 0;
    }
    ${props=>props.disabled ? `opacity:0.6;cursor:default` : ``}
`;

export const ActionButtonNoBackground = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 40px;
    cursor: pointer;
`;

export const DropDownButtonWrapper = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding: 10px 8px 10px 12px;
    height: 40px;
    cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};
    ${props => props.isDisabled ? `
        background-color: #f5f5f5;
        color:rgba(0, 0, 0, 0.25);
    ` : ``}
    :hover {
        border-color:#7d7d7d;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0.5px 4px , rgba(0, 0, 0, 0.3) 0px 0px 1px;
    }
`;

export const DropDownButtonText = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

// export const DropDownButtonIcon = styled.div`

// `;