import { Player } from "@lottiefiles/react-lottie-player"
import { Observer } from "mobx-react"
import { useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { LinkArrowIcon, UpgradeCrownIcon } from "../../../../assets/icons/common/common-icons"
import { ActionLockedIconWithTooltip } from "../../../../common/components/ActionLocked/ActionLockedIconWithTooltip"
import { useAnalytics } from "../../../app/data/AnalyticsProvider"
import { useStore } from "../../../app/data/root.store"
import { TierBadge } from "../TierBadge/TierBadge"
import { ActivationFlowStepsMenu } from "./ActivationFlowStepsMenu"
import { CommunityMenu } from "./CommunityMenu"
import { PortfolioSettingsMenu } from "./ProtfolioSettingsMenu"
import { DemoStartPortfolioButton, MagicBoxInProcessTooltip, SeparatorLine, SideBarUpgradeButton, SidebarNav, SidebarWrapper } from "./Sidebar.styled"
import { UserProfileMenu } from "./UserProfileMenu"
import magicBoxSpinnerAnimData from './assets/magic-box-spinner.json'
import { CashflowIcon, HomeIcon, MagicBoxIcon, ReportsIcon } from "./icons/sidebar-icons"
import { ROUTES } from "../../../../common/constants/routes.const"

export const Sidebar = ({isWelcome}) => {
  const location = useLocation();
  const [isMagicBoxLinkHovered, setIsMagicBoxLinkHovered] = useState(false);
  const { uiStore, filtersStore, wealthStore, userStore, metadataStore, communityStore, billingStore } = useStore();

  const { trackEvent } = useAnalytics();

  const handleWealthClick = () => {
    filtersStore.handleClearFilterSelections();
    filtersStore.closeAllParameters();
    if (location.pathname.includes(ROUTES.wealth.url)) {
      wealthStore.refreshWealthData();
    }
  }

  const handleCommunityClick = (e) => {
    if (userStore.isActionBlockedByRole('viewCommunityPageFromSidebar')) {
      e.preventDefault();
      e.stopPropagation();
      userStore.setShowLimitedSharedAccess(true);
    } else {
      handleMenuItemClick('community');
      communityStore.setUnSelectedClass();
      communityStore.setSelectedTab('institutions');
    }
  }

  const handleMagicBoxOnClick = (e) => {
    if (userStore.isActionBlockedByRole('viewMagicBoxPageFromSidebar')) {
      e.preventDefault();
      e.stopPropagation();
      userStore.setShowLimitedSharedAccess(true);
    }
  }

  const handleMenuItemClick = (destination) => {
    trackEvent('CTA Clicked', {
      destination,
      location: 'Side menu',
      cta_text: destination,
      type: 'Button',
      url: window.location.href
    })
  }

  const handleStartMyPortfolioClick = async () => {
    if (uiStore.isMobileAppView || userStore.isRelevantVariant === 6) {
      await billingStore.setStarterPlan();
      window.location.href = `${ROUTES.wealthOverview.url}?subscribed_success&sw`;
    } else {
      billingStore.setShowPricingTableModal(true);
    }
  }

  return (<Observer>{() => (
    <SidebarWrapper isSmall={uiStore.isSideMenuMinimized && !isWelcome}>

      
      { isWelcome && !uiStore.isRouteWithEmptySideBarLayout && userStore.isRelevantVariant !== 3 ? 
          <ActivationFlowStepsMenu />
        

         :
          <> {!userStore.isEmptySharedSideMenu ? <SidebarNav role="navigation" aria-label="Main" >
            {uiStore.isDesktopView ? null :    <div style={{marginLeft:'20px', display:'flex', alignItems:'center', gap:'16px'}}>   <svg width="50" height="20" viewBox="0 0 50 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
<path d="M0.282181 4.5332H2.46511L5.56123 11.6612L8.65734 4.5332H10.8403L6.2961 15.0024H4.82592L0.281738 4.5332H0.282181Z" fill="#FFFFFF"/>
<path d="M28.2086 6.4268H22.7835V4.5332H31.9679L26.1557 13.1088H31.5821V15.0019H22.4146L28.209 6.42635L28.2086 6.4268Z" fill="#FFFFFF"/>
<path d="M49.7161 4.5332H45.578H43.7743H43.4619V15.0024H45.578V6.4268H48.8923L49.7161 4.5332Z" fill="#FFFFFF"/>
<path d="M13.6828 19.1108H15.9273L22.2717 4.5332H20.0441L17.0373 11.4386L13.7625 4.5332H11.4906L15.968 13.9109L13.6828 19.1108Z" fill="#FFFFFF"/>
<path d="M40.0751 11.5177L40.0467 11.5787C39.5132 12.7224 38.5381 13.3267 37.2271 13.3267C35.3583 13.3267 34.1943 11.8667 33.9612 10.3647H42.3562L42.3571 10.1939C42.3664 8.46446 41.7775 6.91464 40.6985 5.8298C39.7326 4.85867 38.4655 4.32422 37.1298 4.32422C34.258 4.32422 31.922 6.76509 31.922 9.7652C31.922 12.7653 34.2523 15.1677 37.2271 15.1677C39.3243 15.1677 40.9542 14.1926 41.8076 12.4627L40.0751 11.5181V11.5177ZM37.1303 6.16472C38.4836 6.16472 39.6906 7.13231 40.1313 8.54366H34.0912C34.4828 7.32875 35.6092 6.16472 37.1303 6.16472Z" fill="#FFFFFF"/>
</svg>
<TierBadge tier={userStore.getTierType} /></div>}
            <ul>
              <li><NavLink to={ROUTES.wealthOverview.url} onClick={(e) => { handleMenuItemClick('my portfolio'); handleWealthClick(e) }}><HomeIcon />{uiStore.isSideMenuMinimized || !userStore.isViewingOwnPortfolio ? 'Portfolio' : 'My Portfolio'}</NavLink></li>
              <li><NavLink to={ROUTES.cashflow.url} onClick={() => { handleMenuItemClick('cashflow planning') }}><CashflowIcon />{uiStore.isSideMenuMinimized ? 'Planning' : 'Cash Flow Planning'}</NavLink></li>

              <li><NavLink to={ROUTES.reports.url} onClick={() => { handleMenuItemClick('reports') }}><ReportsIcon />{ROUTES.reports.title}</NavLink></li>
              <li style={{position:"relative"}}>
              
              <NavLink onMouseEnter={()=>{setIsMagicBoxLinkHovered(true)}} onMouseLeave={()=>{setIsMagicBoxLinkHovered(false)}} onClick={(e) => { handleMenuItemClick('magic-box'); handleMagicBoxOnClick(e) }} to={ROUTES.magicBox.url}>
                {!userStore?.isDemoOrReadOnlyMode && metadataStore.magicBoxFilesInProcessCount > 0 ? <Player
                    loop={true}
                    src={magicBoxSpinnerAnimData}
                    speed={1}
                    autoplay
                    style={{ height: '24px' , width:'24px' }}
                  /> : <MagicBoxIcon />}{ROUTES.magicBox.title}
                  {userStore.isActionBlockedByRole('viewMagicBoxPageFromSidebar') && !uiStore.isSideMenuMinimized && <ActionLockedIconWithTooltip iconColor={'#ACB1BB'} marginTop={'-2px'} isRoleBlocked={true} />}
              </NavLink>
              {/* } */}
                <MagicBoxInProcessTooltip isDisplayed={isMagicBoxLinkHovered && !userStore?.isDemoOrReadOnlyMode && metadataStore.magicBoxFilesInProcessCount > 0}>{metadataStore.magicBoxFilesInProcessCount} file{metadataStore.magicBoxFilesInProcessCount > 1 ? 's' : ''} in process</MagicBoxInProcessTooltip>
                
                </li>
            </ul>
            <CommunityMenu handleCommunityClick={handleCommunityClick} />
            {userStore.isDemoMode && !uiStore.isSideMenuMinimized && <DemoStartPortfolioButton onClick={handleStartMyPortfolioClick}>Start My Portfolio Free<LinkArrowIcon /></DemoStartPortfolioButton>}
            {userStore.isReadOnlyMode && !uiStore.isSideMenuMinimized && <DemoStartPortfolioButton onClick={()=>{ billingStore.setShowPricingTableModal(true)}}>Reactivate my account<LinkArrowIcon /></DemoStartPortfolioButton>}
          </SidebarNav> : <div style={{flex:1}}></div> }
          <div style={{flex:1}}></div> 
          
           {userStore.isHavingSharedPortfolios && !userStore.isEmptySharedSideMenu && <PortfolioSettingsMenu /> }
           {userStore.isHavingSharedPortfolios && !userStore.isEmptySharedSideMenu && <SeparatorLine /> }
          <ul style={userStore.isHavingSharedPortfolios ? {margin: '8px 16px'} : {}}>
            {/* {userStore.isFreeTier && <UpgradeButton><UpgradeCrownIcon />Upgrade</UpgradeButton>} */}
            {userStore.isFreeTier && !uiStore.isSideMenuMinimized && !userStore.isEmptySharedSideMenu && <li><SideBarUpgradeButton onClick={()=>{billingStore.setShowPricingTableModal(true)}} ><UpgradeCrownIcon />Upgrade</SideBarUpgradeButton></li>}
            
            <li><UserProfileMenu /></li>
          </ul>
          </>}
    </SidebarWrapper>
  )}</Observer>)
}