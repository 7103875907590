import styled from "@emotion/styled/macro";
import { colors } from "../../../../../../common/styles/theme.styles";

export const OverviewInflowOutflowMenuItemList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
`;

export const OverviewInflowOutflowMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 11px 8px;
  border: 1px solid ${props => props.isSelected ? colors.darkGray1 : colors.lightGray2};
  border-radius: 10px;
`;

export const OverviewInflowOutflowMenuItemText = styled.div`
  flex:1;
  padding-left: 8px;
`;

export const OverviewInflowOutflowMenuItemTitle = styled.div`
  color: ${colors.darkGray1};
  font-family: Circular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const OverviewInflowOutflowMenuItemDescription = styled.div`
  color: ${colors.darkGray3};
  font-family: Circular;
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  white-space: pre-line;
`;