export const addRow = (currentValue) => {


    let updatedSchedule = [...currentValue];

    const maxValueOfTempId = Math.max(...currentValue.map(u =>  u.tempId || 0), 0);
    const newRowTempId = maxValueOfTempId + 1;

    const maxYears = currentValue.length > 1 ? currentValue[currentValue.length - 2].fromYear : 1;
    const lastRowYears = currentValue.length ? currentValue[currentValue.length - 1].fromYear : 1;
    // debugger;
    const newRow = {
        // value: currentValue.length > 0 ? currentValue[currentValue.length - 1].value : {baseValue: 0 , puaValue:0},
        fromYear : (maxYears + 10 < lastRowYears ) ? maxYears+5 : lastRowYears,
        // date: moment(mostRecentDate).add(1,'y').utc().set("D",15).startOf("D") || moment().utc().set("D",15).startOf("D"),
        // remarks: "",
        tempId: newRowTempId
    }
    updatedSchedule.splice(currentValue.length - 1, 0 ,newRow);
    if (maxYears + 10 > lastRowYears ){
        updatedSchedule[updatedSchedule.length-1].fromYear = lastRowYears+5;
    }
    if (currentValue.length > 1 && currentValue[currentValue.length - 2].totalValue && !currentValue[currentValue.length - 2].baseValue && !currentValue[currentValue.length - 2].puaValue){
        currentValue[currentValue.length - 2].baseValue = currentValue[currentValue.length - 2].totalValue;
    }
    return {updatedSchedule , newRowTempId };
}

export const updateRowValues = (row,updatedKey) => {
    const updatedRow = {...row};
    if (updatedKey === 'totalValue'){
        if (updatedRow.baseValue){
            if (updatedRow.totalValue < updatedRow.baseValue){
                updatedRow.baseValue = row.totalValue;
            }
            updatedRow.puaValue = updatedRow.totalValue - updatedRow.baseValue
        } else if (updatedRow.puaValue) {
            if (updatedRow.totalValue < updatedRow.puaValue){
                updatedRow.puaValue = updatedRow.totalValue;
            }
            updatedRow.baseValue = row.totalValue - updatedRow.puaValue
        }
    } else if(updatedKey === 'baseValue' && updatedRow.totalValue && !updatedRow.puaValue ) {
        updatedRow.puaValue = updatedRow.totalValue - updatedRow.baseValue;
    } else if(updatedKey === 'puaValue' && updatedRow.totalValue && !updatedRow.baseValue ) {
        updatedRow.baseValue = updatedRow.totalValue - updatedRow.puaValue;
    } 
    return updatedRow;
}

export const changeYearsFrom = (currentValue,updatedIndex,newYear) => {
    let updatedSchedule = currentValue.map((item,index)=>(index === updatedIndex ? {...item,fromYear:newYear} : item));
    const isNewYearBiggerThenNextYear = newYear > currentValue[updatedIndex+1].fromYear;
    if (isNewYearBiggerThenNextYear){
        updatedSchedule[updatedIndex+1].fromYear = newYear+1;
    }
    return updatedSchedule;
}

export const changeYearsTo = (currentValue, updatedIndex, newYear) => {
    let updatedSchedule = currentValue.map((item,index)=> ({...item}));
    updatedSchedule[updatedIndex+1].fromYear = newYear+1;
    return updatedSchedule;
}

export const deleteRow = (currentValue, deletedIndex) => {
    return currentValue.filter((item,index)=> index !== deletedIndex);    
}

