import { deleteData, getData, postData, putData } from "../../common/utils/network.utils";

export const getHoldingEntityData = async () => {
  return (await getData('beneficiary')).data;
}
export const createHoldingEntity = async (data) => {
  return (await postData('beneficiary', data));
}

export const updateHoldingEntity = async (data) => {
  return (await putData('beneficiary', data));
}

export const deleteHoldingEntity = async (id) => {
  return (await deleteData(`beneficiary/${id}`));
}